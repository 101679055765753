import React from 'react';
import PropTypes from 'prop-types';

const MentionEntryComponent = (props) => {
  const {
    mention,
    ...parentProps
  } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...parentProps}>
      <div className="metion-entry-list">
        <span className="default-profile rounded-circle mr-2">{mention.ShortName}</span>
        <span className="entry-name">
          <span>{mention.Name}</span>
          {' '}
          <br />
          <span>{mention.email}</span>
        </span>
      </div>
    </div>
  );
};

MentionEntryComponent.propTypes = {
  mention: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};
export default MentionEntryComponent;
