import React, { Component } from 'react';
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import { AgGridReact, Ag } from 'ag-grid-react';
import WOPopup from './WOPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
// import ApprovalPopup from './DeclineDealPopup';
// import DeclineDealPopup from './DeclineDealPopup';
import DealPopUp from './dealPopup';
import BtnCellRenderer from './BtnCellRenderer'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class DealApprovalList extends Component {
    constructor() {
        super()


        this.state = {
            Approval: {


                DealWOStepID: [],
                ApprovalType: null,
                ApprovalAction: false,

            },
            CanApprove: false, CanReject: false,

            rowSelected: [],
            userList: [], roleList: [], clientList: [], viewmore: false, prevLength: "", flag: false, IsApproved: false, IsRejected: false,
            columnDefs: [

                {
                    headerName: "", resizable: true, suppressFilter: true, headerCheckboxSelection: false, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
                    width: 150, suppressSizeBtnCellRendererToFit: true, suppressMovable: true, suppressSizeToFit: true,

                },

                {
                    headerName: "Approval Email", field: "ApprovalEmail", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true,
                    cellRenderer: "BtnCellRenderer",
                },
                { headerName: "Industry", field: "Industry", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Other Industry", field: "OtherIndustry", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },

 { headerName: "Client Name", field: "CoreBeatClientName", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Alias Client Name", field: "ClientName", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
  { headerName: "Delivery Centre/Delivery Location", field: "CoreBeatLocation", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Alias Delivery Centre", width: 280, field: "City", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Offering", field: "Offering", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Sub Offering", field: "Process", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Maturity Phase", field: "MaturityPhase", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                // { headerName: "Accenture MD", field: "AccentureMD", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Status", field: "Status", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Market Region", field: "ClientRegion", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Market Unit", field: "MarketUnit", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },

                { headerName: "Other Market Region", field: "OtherClientRegion", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Other Market Unit", field: "OtherMarketUnit", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },

                { headerName: "Project Name", field: "Project", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Delivery Region", field: "DeliveryRegion", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Other Delivery Region", field: "OtherDeliveryRegion", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Project's Prime Lead/ Managing Director", field: "AccentureMD", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Project's Service Delivery Lead", field: "AccentureSDL", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Users with Edit access", field: "EditAccessUsers", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Users with View access", field: "ViewAccessUsers", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
    { headerName: "Requested By", field: "CreatedUser", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
  { headerName: "Requested Date", field: "RequestedDate", suppressSizeToFit: true, resizable: true, suppressMovable: true, suppressSizeToFit: true, },
                // { headerName: "Requestor Remarks",  field: "RequestorRemarks", suppressSizeToFit: true, resizable: true },
                // { headerName: "Approver Remarks",  field: "ApproverRemarks", suppressSizeToFit: true, resizable: true },
            ],
            autoGroupColumnDef: {
                headerName: "hello",
                field: "DealName",
                width: 200,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true }
            },

            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'single',


                defaultColDef: {
                    sortable: true
                },
            }
        }
        this.onClose = this.onClose.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.Rejected = this.Rejected.bind(this);
        this.Approved = this.Approved.bind(this);
        this.getApproval = this.getApproval.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.onBtnExport = this.onBtnExport.bind(this);
        this.OnDealUpdate = this.OnDealUpdate.bind(this);

    }



    onRowSelected(event) {

        if (event.node.selected) {

            let rowSelected = [...this.state.rowSelected]
            rowSelected.push(event.data);
            this.setState({ rowSelected: rowSelected });

        }
        else {
            let rowSelected = [...this.state.rowSelected]
            rowSelected = rowSelected.filter((row) => row.DealID !== event.data.DealID)
            this.setState({ rowSelected: rowSelected });
        }

        // else {
        //     
        //     let rowSelected =[...this.state.rowSelected]
        //     rowSelected = rowSelected.filter((row) => row.DealID !== event.data.DealID)
        //     let deals = []
        //     let rowData = [];
        //     this.state.rowData.map(i => { deals.push(i.DealID) })
        //     this.state.rowSelected.map(selected => {
        //         if (deals.includes(selected.DealID)) {
        //             rowData.push(selected)
        //         }
        //     })
        //      this.setState({ rowSelected: rowData });
        // }
    }


    onCheckboxClicked(e, data) {

        if (e.target.checked) {
            this.state.rowSelected.push(data);
        }
        else {

            this.state.rowSelected = this.state.rowSelected.filter((row) => row.DealID !== data.DealID)
        }
    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }
    Rejected(e) {
        if (this.props.featureData.filter((data) => data.Submenu == "Deal Approval" && data.Operations.includes("Reject")).length > 0) {

            e.preventDefault();
            this.setState({ IsRejected: true })
        }
        else window.alert("Access Denied !!")


    }
    Approved(e) {
        if (this.props.featureData.filter((data) => data.Submenu == "Deal Approval" && data.Operations.includes("Approve")).length > 0) {

            e.preventDefault();
            this.setState({ IsApproved: true })
        }
        else window.alert("Access Denied !!")


    }


    onClose(e) {

        this.setState({ viewmore: false });
        this.setState({ IsApproved: false });
        this.setState({ IsRejected: false });
    }

    getApproval() {

        axiosInstance.get(`${LocalApiBaseUrl}Deal/GetDealsForApproval`,
            {

                params: {}
            })
            .then((response) => {

response.data.forEach(element => {
                        if(element.ClientName.trim().toUpperCase()===element.CoreBeatClientName.trim().toUpperCase())
element.ClientName='';

  if(element.City.trim().toUpperCase()===element.CoreBeatLocation.trim().toUpperCase())
element.City='';

                        // ValueDt.push('');
                    });

                this.setState({
                    rowData: response.data
                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
    }

    componentDidMount() {



        if (this.props.featureData.filter((data) => data.Submenu == "Deal Approval" && data.Operations.includes("Reject")).length > 0) {

            this.setState({ CanReject: true });
        }
        if (this.props.featureData.filter((data) => data.Submenu == "Deal Approval" && data.Operations.includes("Approve")).length > 0) {

            this.setState({ CanApprove: true });
        }

        var a = this.props;
        this.getApproval();
        this.setState({
            rowSelected: []

        })
    }

    componentWillMount() {

        this.setState({
            rowSelected: []

        })

        this.getApproval();
    }



    OnDealUpdate = (data) => {
        let deals = []
        let rowData = [];
        this.state.rowData.map(i => { deals.push(i.DealID) })

        this.state.rowSelected.map(selected => {
            if (deals.includes(selected.DealID)) {
if(!selected.ClientName)
selected.ClientName=selected.CoreBeatClientName;
if(!selected.City)
selected.City=selected.CoreBeatLocation;
                rowData.push(selected)
            }

        })

        let Actions;
        if (this.state.IsApproved) {
            Actions = "Approved";
        }

        if (this.state.IsRejected) {

            Actions = "Rejected";
        }

        // rowData.map(data => { data.ApproverRemarks = remarks })

        axiosInstance.post(`${LocalApiBaseUrl}Deal/AddEditDealInfo`, rowData,
            {
                params: { Action: Actions }
            }
        ).then((response) => {
            window.alert(response.data.StatusMessage)
            this.getApproval();

        })
        .catch((error) => {
            trycatchAlertPopup(error);
          });
  
        this.state.rowSelected = []


    }



    onActiveChange(event) {

        const user = this.state.user;
        user.IsActive = event.target.checked;
        this.setState({
            user: user
        })
    }

    onBtnExport() {


        let params = {
            columnKeys: ['OperatingGroup', 'Industry', 'OtherIndustry', 'CoreBeatClientName', 'ClientName','CoreBeatLocation', 'City', 'Offering', 'Process', 'MaturityPhase', 'Status','ClientRegion','OtherClientRegion','MarketUnit','OtherMarketUnit','Project','DeliveryRegion','OtherDeliveryRegion','AccentureMD','AccentureSDL','EditAccessUsers','ViewAccessUsers','CreatedUser','RequestedDate'],
            columnGroups: true,
            allColumns: false,
            skipHeader: false,
            fileName: 'Deal Approval',
            //   columnSeparator: document.querySelector("#columnSeparator").value
        };
        this.state.gridOptions.api.exportDataAsCsv(params);
    }




    render() {


        return (
            <div className="main-data">
                {/* <h3 className="grid-heading">Deal Approval</h3> */}

                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                            </div>
                            <div className="ml-auto mr-1">
                                {/* {this.props.featureData.filter((data) => data.Submenu == "Metric" && data.Operations.includes("Download")).length > 0 ? */}
                                <span className="btn btn-primary btn-sm py-1" onClick={this.onBtnExport}>
                                    <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Download
                                </span>
                                {/* : null} */}
                                {/* <button className="btn btn-primary btn-sm" id="btnsave" onClick={this.onBtnExport} disabled={this.state.active}>Download</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ag-theme-balham ag-table">
                            <AgGridReact
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                paginationPageSize={50}
                                floatingFilter={true}
                                onRowSelected={this.onRowSelected.bind(this)}
                                gridOptions={this.state.gridOptions}
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                                frameworkComponents={{
                                    BtnCellRenderer
                                }}>
                            </AgGridReact>
                        </div>
                    </div>
                </div>
                {/* {this.state.IsRejected === true || this.state.IsApproved === true ? <DeclineDealPopup data={this.state.rowSelected} show={true} onClose={this.onClose} onSubmit={this.OnDealUpdate} dealData={this.state.rowSelected} /> : null} */}
                {this.state.IsRejected === true || this.state.IsApproved === true ? <DealPopUp data={this.state.rowSelected} show={true} onClose={this.onClose} onSubmit={this.OnDealUpdate} dealData={this.state.rowSelected} /> : null}
                {/* <input type="checkbox" onClick={(e) => { this.toggle(e,this.params.data) }} /> Select All<br />  */}
                <div className="text-center mt-3 mb-2">
                    {this.state.rowSelected.length > 0 && this.state.CanApprove ? <button className="btn btn-primary btn-sm mx-2" onClick={(e) => { this.Approved(e) }}>Approve</button>
                        : <button className="btn btn-primary btn-sm mx-2" disabled={true} onClick={(e) => { this.dealpopup(e) }}>Approve</button>}
                    {this.state.rowSelected.length > 0 && this.state.CanReject ? <button className="btn btn-primary btn-sm mx-2" onClick={(e) => { this.Rejected(e) }} >Decline</button>
                        : <button className="btn btn-primary btn-sm mx-2" disabled={true} onClick={(e) => { this.decline(e) }} >Decline</button>}
                </div>

            </div>

        )
    }
}