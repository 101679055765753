import React, { Component } from 'react';
import { Line, Bar, Scatter } from 'react-chartjs-2';
import { store } from "../reducers/configureStore";
import axiosInstance from '../Shared/interceptor';
import {trycatchAlertPopup} from "../Shared/Constant";
import { LocalApiBaseUrl } from '../Shared/Constant';
import i18n from "i18next";

class IOJ_Initiative extends Component {
    constructor(props) {
        super(props)
        this.state = {
            graph1data: {
                labels: ["Not-Delivered", "Delivered"],
                datasets: [
                    {
                        type: "bar",
                        label: "Value",
                        fontColor: 'black',
                        borderColor: "#47b8d4 ",
                        backgroundColor: ["#47b8d4", "#84d9b7"],
                        fill: false,
                        data: [],
                        showLineValues: false
                    }
                ]
            },
            graph2data: {
                datasets: [
                    {
                        label: 'My First dataset',
                        fill: true,
                        fontColor: 'black',
                        showLine: false,  //!\\ Add this line
                        backgroundColor: 'rgba(75,192,192,0.4)',
                        pointBorderColor: 'rgba(75,192,192,1)',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                        pointHoverBorderWidth: 3,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        data: [
                            { x: 1, y: 75 },
                            { x: 2, y: 49 },
                            { x: 3, y: 90 },
                            { x: 4, y: 29 },
                            { x: 5, y: 36 },
                            { x: 6, y: 25 },
                            { x: 7, y: 18 },
                            { x: 1, y: 28 },
                            { x: 1, y: 38 },
                            { x: 1, y: 48 },
                            { x: 1, y: 58 },
                        ],
                        DealData: []
                    }
                ]
            },
            dsKpiData: [],
            UpperThresholdMean: [],
            LowerThresholdMean: [],
            displayFilter: true,
            displayGraphOne: false,
            displayGraphTwo: false,
            displayGraphThree: false,
            selectedGraph: null,
            filterError: "",
            text: "Initiative estimated values ($)",
            Unit: "$",
            MetricID: [],
            BOI: [],
            UnitNames: [],
            updatedAllData: [],
            Days: false,
            Dollar: true,
            selectedIndustry: "",
            labelForXAxis: "",
            IndustryOptions: [],
            OperatingGroupOptions: [],
            IndustryID: [],
            IndustryName:"",
            OperatingGroup: "All",
            KPIMappingID: null,

            currentDealId: null,
            maturityPhase: "",
            BOIImpacted: "",
            filterdData: {
                DeliveredData: [],
                unDeliveredData: [],
                DeliveredCount: null,
                unDeliveredCount: null
            },
            range: {
                from: null,
                to: null,
            },
            error: {
                maturityPhaseError: null,
                BoiError: null,
                rangeError: null,
                disableSubmit: false
            },
            graph3Error: '',
            event: [{ _index: null }],
            dealIndex: {
                Delivered: null,
                nonDelivered: null
            },
            mydealIndexDelivered: '',
            mydealIndexUnDelivered: '',
            ShowDeals: false

        }
        this.applayFilter = this.applayFilter.bind(this);
        this.GraphData = this.GraphData.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.onShowDealChange = this.onShowDealChange.bind(this);
        this.onUnitChange = this.onUnitChange.bind(this);


    }
    componentDidMount() {
            let samp = this.state.BOI;
            let currentDealId = null;
            axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetKpiMappingForBOI`, {
                params: { OrgEntityID: this.props.filterDetails.Client, LocationID: this.props.filterDetails.Locations, OfferingID: this.props.filterDetails.process, MonthDT:this.props.filterDetails.Month ,LanguageID: store.getState().CurrentFilter.languageData.id}
            })
                .then((response) => {
                    response.data.map((el) => {
                        samp.push(el);
                        currentDealId = el.DealID;
                    });
                    this.setState({ BOI: samp, currentDealId: currentDealId });
                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
          

        this.getIndustry();



    }

    getIndustry() {
        debugger
        let source = [];
        axiosInstance
            .get(`${LocalApiBaseUrl}Industry/GetIndustryList`)
            .then(response => {
                source = response.data;
                let sourceValues = source.map(x => (
                    <option
                        key={x.IndustryID}
                        value={x.IndustryID}
                    >
                        {x.IndustryName}
                    </option>
                ));
                this.setState({ IndustryOptions: sourceValues });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
    }	

    validateForm() {
        let maturityPhaseError = null;
        if (!this.state.maturityPhase.trim()) {
            maturityPhaseError = i18n.t("Ioj_initiative_compar_err_m1");
        }
        let BoiError = null;
        if (!this.state.BOIImpacted.trim()) {
            BoiError = i18n.t("Ioj_initiative_compar_err_m2");
        }
        let rangeError = null;
        if (this.state.range.from && this.state.range.to) {
            if (parseFloat(this.state.range.from) > parseFloat(this.state.range.to)) {
                rangeError = i18n.t("Ioj_initiative_compar_err_m3");
            }
        }
        let OperatingError = null;
        if (!this.state.OperatingGroup.trim()) {
            OperatingError = i18n.t("Ioj_initiative_compar_err_m4");
        }
        let IndustryError = null;
        if (!this.state.IndustryID.length) {
            IndustryError = i18n.t("Ioj_initiative_compar_err_m5");
        }


        if (maturityPhaseError || BoiError || rangeError || OperatingError || IndustryError) {
            this.setState({
                error: {
                    maturityPhaseError: maturityPhaseError,
                    BoiError: BoiError,
                    OperatingError: OperatingError,
                    IndustryError: IndustryError,

                    rangeError: rangeError,
                    disableSubmit: true

                }
            })
            return false;
        }
        else {
            this.setState({
                error: {
                    maturityPhaseError: maturityPhaseError,
                    BoiError: BoiError,
                    rangeError: rangeError,
                    disableSubmit: false

                }
            })
            return true;
        }


    }
    onShowDealChange(event) {


        this.setState({
            ShowDeals: event.target.checked
        })

        if (event.target.checked) {

        }
        else {


        }

    }

    onUnitChange(val) {

        let Unit = val;
        this.setState({ Unit: Unit }, this.applayFilter());

    }

    applayFilter() {


        if (this.validateForm()) {
            axiosInstance.get(`${LocalApiBaseUrl}IntelOpsCategory/GetIntelOpsData`, {
                params: { industryids: this.state.IndustryID.toString(), offeringId: this.props.filterDetails.process, maturityPhase: this.state.maturityPhase.trim(), BOIImpacted: this.state.BOIImpacted.trim(), from: this.state.range.from, to: this.state.range.to,LanguageID: store.getState().CurrentFilter.languageData.id }
            })
                .then((response) => {
                    if (response.data.length) {
                        let DeliveredValues = [];
                        let unDeliveredValues = [];
                        let OnHold = [];
                        let InFlight = [];
                        let Concept = [];

                        let Unit = this.state.Unit;
                        if (!this.state.UnitNames.includes(this.state.Unit)) {
                            this.state.Unit = "$"
                            Unit = "$"
                        }
                        if (this.state.Unit) {
                           


                            response.data.map((each) => {
                               
                                if (each.InitiativeStatus === "Delivered") {
                                    if (each.ValueUnit === this.state.Unit) {

                                        DeliveredValues.push(each);
                                    }
                                }
                                else {

                                    if (each.EstimatedUnit === this.state.Unit) {
                                        unDeliveredValues.push(each);
                                        if (each.InitiativeStatus === "OnHold") {
                                            OnHold.push(each)
                                        } if (each.InitiativeStatus === "InFlight") {
                                            InFlight.push(each)
                                        } if (each.InitiativeStatus === "Concept") {
                                            Concept.push(each)
                                        }
                                    }
                                }
                            })
                        }
                        
                        let DeliveredCount = DeliveredValues.length;
                        let unDeliveredCount = unDeliveredValues.length;
                        let OnHoldCount = OnHold.length;
                        let InFlightCount = InFlight.length;
                        let ConceptCount = Concept.length;
                        this.setState({
                            Unit: Unit,
                            filterdData: {
                                DeliveredData: DeliveredValues,
                                unDeliveredData: unDeliveredValues,
                                DeliveredCount: DeliveredCount,
                                unDeliveredCount: unDeliveredCount
                            },
                            graph1data: {
                                labels: [i18n.t("Not_Delivered"), i18n.t("Delivered")],
                                datasets: [
                                    {
                                        type: "bar",
                                        fontColor: 'black',
                                        label: i18n.t("In-Concept"),
                                        borderColor: "#e8ad41",
                                        backgroundColor: ["#e8ad41"],
                                        fill: false,
                                        data: [ConceptCount],
                                        showLineValues: false
                                    },
                                    {
                                        type: "bar",
                                        fontColor: 'black',
                                        label: i18n.t("On-Hold"),
                                        borderColor: "#ff846c",
                                        backgroundColor: ["#ff846c", "#84d9b7"],
                                        fill: false,
                                        data: [OnHoldCount],
                                        showLineValues: false
                                    },
                                    {
                                        type: "bar",
                                        fontColor: 'black',
                                        label: i18n.t("In-Flight"),
                                        borderColor: "#79acd0",
                                        backgroundColor: ["#79acd0",],
                                        fill: false,
                                        data: [InFlightCount],
                                        showLineValues: false
                                    },

                                    {
                                        type: "bar",
                                        label: i18n.t("Delivered"),
                                        fontColor: 'black',
                                        borderColor: "#84d9b7",
                                        backgroundColor: "#84d9b7",
                                        fill: false,
                                        data: [, DeliveredCount],
                                        showLineValues: false
                                    },

                                ]
                            },
                            displayFilter: false,
                            displayGraphOne: true,
                            displayGraphTwo: false,
                            displayGraphThree: false,
                            filterError: ""
                        })



                    }

                    else {
                        this.setState({
                            ...this.state, displayFilter: true,
                            displayGraphOne: false,
                            displayGraphTwo: false,
                            displayGraphThree: false,
                            filterError: i18n.t("Ioj_initiative_compar_err_m6")
                        })
                    }

                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
          

            if (this.state.OperatingGroup == "All") {
                this.setState({
                    labelForXAxis: i18n.t("Operating_Groups")
                });
            }
            else {
                this.setState({
                    labelForXAxis:  i18n.t("Deals")
                })
            }

            this.setState({
                text: i18n.t("Initiative_estimated_values") + "(" + this.state.Unit + ")"
            })

        }

    }


    onIndustryChange() {
        let errors = { ...this.state.error };
        errors.IndustryError = '';
        errors.disableSubmit = false;
        this.setState({
            error: errors,
            displayGraphOne: false,
            displayGraphTwo: false,
            displayGraphThree: false

        });
        let IndustryID = this.state.IndustryID;
        let IndustryName = this.state.IndustryName;
        var event = document.getElementById('IndustryName');
        IndustryID = event.options[event.selectedIndex].value;
        IndustryName = event.options[event.selectedIndex].label;
        this.setState({
            IndustryID: IndustryID,
            IndustryName:IndustryName
        })
    }
  

    onBOIChange(e) {

        let BOIList = [...this.state.BOI]
        let x = BOIList.filter(each => {
            if (parseInt(each.MetricID) === parseInt(e.target.options[e.target.selectedIndex].value)) return each

        })


        if (x.length) {
            let MetricID = x[0].MetricID;
            let UnitNames = ["$", x[0].MetricUnit];
            UnitNames = [...new Set(UnitNames.map(item => item))]
            let BOIImpacted = x[0].MetricName_En;
            let errors = { ...this.state.error };
            errors.BoiError = '';
            errors.disableSubmit = false;
            this.setState({
                MetricID: MetricID,
                UnitNames: UnitNames,
                BOIImpacted: BOIImpacted, error: errors, filterError: null, displayGraphOne: false,
                displayGraphTwo: false,
                displayGraphThree: false
            })
        }


    }
    onMPhaseChange(e) {

        let errors = { ...this.state.error };
        errors.maturityPhaseError = '';
        errors.disableSubmit = false;
        this.setState({
            maturityPhase: e.target.options[e.target.selectedIndex].value, error: errors, filterError: null, displayGraphOne: false,
            displayGraphTwo: false,
            displayGraphThree: false
        })
    }
    statusChange(e) {
        let value = e.target.options[e.target.selectedIndex].value;
        if (value === "unDelivered") {
            let event = this.state.event;
            event[0]._index = 0;
            this.GraphData(event, 1);
        }
        else {
            let event = this.state.event;
            event[0]._index = 1;
            this.GraphData(event, 1);
        }

    }
    onRangeChange(val, e) {
        let error = this.state.error;
        error.disableSubmit = false;
        if (val === "From") {
            let range = this.state.range;
            range.from = e.target.value;
            this.setState({
                range: range, error: error, filterError: null, displayGraphOne: false,
                displayGraphTwo: false,
                displayGraphThree: false
            })
        } else {
            let range = this.state.range;
            range.to = e.target.value;
            this.setState({
                range: range, error: error, filterError: null, displayGraphOne: false,
                displayGraphTwo: false,
                displayGraphThree: false
            })
        }
    }

    GraphData(event, val) {


        if (val === 1 && event.length) {
            if (event[0]._index === 0) {

                let nondData = [...this.state.filterdData.unDeliveredData];
               
                let updatedData = nondData;


                let mydealIndexUnDelivered = this.state.dealIndex;
                if (nondData.length) {
                    let unique = [...new Set(nondData.map(item => item.DealId))];
                    if (!mydealIndexUnDelivered.nonDelivered) {
                        mydealIndexUnDelivered.nonDelivered = unique.indexOf(parseInt(this.state.currentDealId)) + 1;
                    }
                    for (let i = 0; i < unique.length; i++) {
                        updatedData = updatedData.map((each) => {
                            if (each.DealId == unique[i]) {
                                let updatedDealId = i + 1;
                                let originalDealId = each.DealId;
                                return { ...each, originalDealId: originalDealId, updatedDealId: updatedDealId };
                            }
                            else {
                                return each;
                            }
                        })
                    }
                    let updatedDealData = updatedData;
                    let data = [];
                    let data2 = [];
                    let initiativeNameData = [];
                    let DealDataTest = [];
                    let datapointColors = [];
                    let uniqueDeal = [];
                    updatedData.map((each) => {
                        data.push({ x: each.updatedDealId, y: each.TargetValue })
                        initiativeNameData.push({ dealId: each.DealId, initiativeName: each.ProjectInitiativeName, value: each.TargetValue, originalDealId: each.originalDealId })

                        if (each.InitiativeStatus === "OnHold") {
                            datapointColors.push("#ff846c");
                        }
                        if (each.InitiativeStatus === "InFlight") {
                            datapointColors.push("#79acd0");
                        }
                        if (each.InitiativeStatus === "Concept") {
                            datapointColors.push("#e8ad41");
                        }
                    })


                    for (let i = 0; i < unique.length; i++) {
                        updatedDealData = updatedDealData.map((each) => {

                            return each;

                        })
                    }

                    updatedDealData.map((each) => {
                        DealDataTest.push({ x: each.updatedDealId, DealName: each.DealName })
                    })

                    uniqueDeal = DealDataTest.filter(function (item, pos) {
                        return DealDataTest.indexOf(item) == pos;
                    });

                    var filteredData = uniqueDeal.filter(function (el) {
                        return el != null;
                    });


                    let updatedAllData = updatedData;

                    let unique2 = [...new Set(updatedAllData.map(item => item.OperatingGroup))];
                    for (let i = 0; i < unique2.length; i++) {
                        updatedAllData = updatedAllData.map((each) => {
                            if (each.OperatingGroup == unique2[i]) {
                                each.Flag = i + 1;
                                return each;
                            }
                            else {
                                return each;
                            }
                        })
                    }

                    updatedAllData.map((each) => {
                        data2.push({ x: each.Flag, y: each.TargetValue })
                        if (each.InitiativeStatus === "OnHold") {
                            datapointColors.push("#ff846c");
                        }
                        if (each.InitiativeStatus === "InFlight") {
                            datapointColors.push("#79acd0");
                        }
                        if (each.InitiativeStatus === "Concept") {
                            datapointColors.push("#e8ad41");
                        }
                    })


                    if (this.state.OperatingGroup === "All") {
                        this.setState({
                            updatedAllData: updatedAllData,
                            graph2data: {
                                datasets: [
                                    {
                                        label: i18n.t("In-Flight"),
                                        fontColor: 'black',
                                        fill: true,
                                        showLine: false,  //!\\ Add this line
                                        backgroundColor: "#79acd0",
                                        pointBorderColor: datapointColors,
                                        pointBackgroundColor: datapointColors,
                                        pointBorderWidth: 3,
                                        pointHoverRadius: 5,
                                        pointHoverBackgroundColor: datapointColors,
                                        pointHoverBorderColor: datapointColors,
                                        pointHoverBorderWidth: 3,
                                        pointRadius: 3,
                                        pointHitRadius: 10,
                                        data: data2,
                                        DealData: filteredData
                                    },
                                    {
                                        label: i18n.t("On-Hold"),
                                        fontColor: 'black',
                                        backgroundColor: '#ff846c',
                                        pointBackgroundColor: '#ff846c'
                                    },
                                    {
                                        label: i18n.t("In-Concept"),
                                        fontColor: 'black',
                                        backgroundColor: '#e8ad41',
                                        pointBackgroundColor: '#e8ad41'
                                    }
                                ]
                            },
                            displayFilter: false,
                            displayGraphOne: false,
                            displayGraphTwo: true,
                            displayGraphThree: false,
                            selectedGraph: "unDelivered",
                            dealIndex: mydealIndexUnDelivered,
                            text: i18n.t("Initiative_estimated_values") + this.state.Unit,
                            initiativeNameData: initiativeNameData

                        })
                    }
                    else {
                        this.setState({
                            graph2data: {
                                datasets: [
                                    {
                                        label: i18n.t("In-Flight"),
                                        fontColor: 'black',
                                        fill: true,
                                        showLine: false,  //!\\ Add this line
                                        backgroundColor: "#79acd0",
                                        pointBorderColor: datapointColors,
                                        pointBackgroundColor: datapointColors,
                                        pointBorderWidth: 3,
                                        pointHoverRadius: 5,
                                        pointHoverBackgroundColor: datapointColors,
                                        pointHoverBorderColor: datapointColors,
                                        pointHoverBorderWidth: 3,
                                        pointRadius: 3,
                                        pointHitRadius: 10,
                                        data: data,
                                        DealData: filteredData
                                    },
                                    {
                                        label:i18n.t("On-Hold"),
                                        fontColor: 'black',
                                        backgroundColor: '#ff846c',
                                        pointBackgroundColor: '#ff846c'
                                    },
                                    {
                                        label: i18n.t("In-Concept"),
                                        fontColor: 'black',
                                        backgroundColor: '#e8ad41',
                                        pointBackgroundColor: '#e8ad41'
                                    }
                                ]
                            },
                            displayFilter: false,
                            displayGraphOne: false,
                            displayGraphTwo: true,
                            displayGraphThree: false,
                            selectedGraph: "unDelivered",
                            dealIndex: mydealIndexUnDelivered,
                            text: i18n.t("Initiative_estimated_values")  + this.state.Unit,
                            initiativeNameData: initiativeNameData


                        })
                    }

                }

            } else {


                let nondData = this.state.filterdData.DeliveredData;
                // nondData = nondData.filter((data) => data.EstimatedUnit === this.state.Unit)
                let updatedData = nondData;
                let updatedDealData = nondData;

                if (nondData.length) {
                    let unique = [...new Set(nondData.map(item => item.DealId))];
                    let mydealIndexDelivered = this.state.dealIndex;
                    if (!mydealIndexDelivered.Delivered) {
                        mydealIndexDelivered.Delivered = unique.indexOf(this.state.currentDealId) + 1;
                    }

                    for (let i = 0; i < unique.length; i++) {
                        updatedData = updatedData.map((each) => {
                            if (each.DealId == unique[i]) {
                                let updatedDealId = i + 1;
                                let originalDealId = each.DealId;
                                return { ...each, originalDealId: originalDealId, updatedDealId: updatedDealId };
                            }
                            else {
                                return each;
                            }
                        })
                    }
                    let data = [];
                    let data2 = [];
                    let initiativeNameData = [];

                    updatedData.map((each) => {
                        data.push({ x: each.updatedDealId, y: each.DeliveredValue });
                        initiativeNameData.push({ dealId: each.DealId, initiativeName: each.ProjectInitiativeName, value: each.TargetValue, originalDealId: each.originalDealId })
                    })

                    for (let i = 0; i < unique.length; i++) {
                        updatedData = updatedData.map((each) => {

                            return each;

                        })
                    }
                    let updatedAllData = updatedData;

                    let DealData = [];
                    let uniqueDeal = [];
                    updatedData.map((each) => {
                        DealData.push({ x: each.updatedDealId, DealName: each.DealName })
                    })

                    uniqueDeal = DealData.filter(function (item, pos) {
                        return DealData.indexOf(item) == pos;
                    });


                    var filteredData = uniqueDeal.filter(function (el) {
                        return el != null;
                    });


                    let unique2 = [...new Set(updatedAllData.map(item => item.OperatingGroup))];
                    for (let i = 0; i < unique2.length; i++) {
                        updatedAllData = updatedAllData.map((each) => {
                            if (each.OperatingGroup == unique2[i]) {
                                each.Flag = i + 1;
                                return each;
                            }
                            else {
                                return each;
                            }
                        })
                    }
                    updatedAllData.map((each) => {
                        data2.push({ x: each.Flag, y: each.DeliveredValue })

                    })



                    if (this.state.OperatingGroup === "All") {

                        this.setState({
                            updatedAllData: updatedAllData,

                            graph2data: {
                                datasets: [
                                    {
                                        label: i18n.t("Delivered"),
                                        fontColor: 'black',
                                        fill: true,
                                        showLine: false,  //!\\ Add this line
                                        backgroundColor: '#84d9b7',
                                        pointBorderColor: '#84d9b7',
                                        pointBackgroundColor: '#84d9b7',
                                        pointBorderWidth: 2,
                                        pointHoverRadius: 5,
                                        pointHoverBackgroundColor: '#84d9b7',
                                        pointHoverBorderColor: '#84d9b7',
                                        pointHoverBorderWidth: 3,
                                        pointRadius: 2,
                                        pointHitRadius: 10,
                                        data: data2,
                                        DealData: filteredData
                                    }
                                ]
                            },
                            displayFilter: false,
                            displayGraphOne: false,
                            displayGraphTwo: true,
                            displayGraphThree: false,
                            selectedGraph: "Delivered",
                            dealIndex: mydealIndexDelivered,
                            text: i18n.t("Initiative_delivered_values") + this.state.Unit,
                            initiativeNameData: initiativeNameData
                        })

                    }
                    else {


                        this.setState({
                            graph2data: {
                                datasets: [
                                    {
                                        label: i18n.t("Delivered"),
                                        fontColor: 'black',
                                        fill: true,
                                        showLine: false,  //!\\ Add this line
                                        backgroundColor: '#84d9b7',
                                        pointBorderColor: '#84d9b7',
                                        pointBackgroundColor: '#84d9b7',
                                        pointBorderWidth: 2,
                                        pointHoverRadius: 5,
                                        pointHoverBackgroundColor: '#84d9b7',
                                        pointHoverBorderColor: '#84d9b7',
                                        pointHoverBorderWidth: 3,
                                        pointRadius: 2,
                                        pointHitRadius: 10,
                                        data: data,
                                        DealData: filteredData
                                    }
                                ]
                            },
                            displayFilter: false,
                            displayGraphOne: false,
                            displayGraphTwo: true,
                            displayGraphThree: false,
                            selectedGraph: "Delivered",
                            dealIndex: mydealIndexDelivered,
                            text: i18n.t("Initiative_delivered_values") + this.state.Unit,
                            initiativeNameData: initiativeNameData
                        })



                    }


                }
            }
        }
        if (val === 2 && event.length) {


            if (this.state.selectedGraph === "unDelivered") {
                let nondData = this.state.filterdData.unDeliveredData;
                let grap2Data = { ...this.state.graph2data };

               
                if (nondData.length) {
                    
                    let DealId = nondData[event[0]._index].DealId;
                    let BOIImpacted = this.state.BOIImpacted;
                    let BoIUnit = "$"
                    if (this.state.UnitNames.length > 1) {
                        BoIUnit = this.state.UnitNames[1];
                    }
                    let data = {

                        dealId: DealId,
                        boi: BOIImpacted,
                        unit: BoIUnit
                    }


                    axiosInstance.post(`${LocalApiBaseUrl}IntelOpsCategory/GetInitiativesKpiMapping`, data)
                        .then((res) => {
                                   
                            
                            this.setState({
                                displayFilter: false,
                                displayGraphOne: false,
                                displayGraphTwo: true,
                                displayGraphThree: true,
                                graph2data: grap2Data
                            });

                            if (res.data[0]) {
                                axiosInstance.get(`${LocalApiBaseUrl}Analytics/GetKpiData?kpiMappingID=${res.data[0].KPIMappingID}`)
                                    .then(res => {
                                       

                                        let MontnDt = [''];
                                        let ValueDt = [null];
                                        if (res.data.length) {
                                            res.data.forEach(element => {
                                                MontnDt.push(element.PeriodDt);
                                                ValueDt.push(parseFloat(element.MetricValue));
                                            });
                                            let grapData = { ...this.state.graph2data };
                                            this.setState({
                                                temp: res.data,
                                                dsKpiData: {
                                                    labels: MontnDt,
                                                    datasets: [{
                                                        label: i18n.t("Metric"),
                                                        fontColor: 'black',
                                                        data: ValueDt, fill: true, lineTension: 0.1,
                                                        backgroundColor: 'rgba(75,192,192,0.4)',
                                                        borderColor: 'rgba(75,192,192,1)',
                                                        borderCapStyle: 'butt',
                                                        borderDash: [],
                                                        borderDashOffset: 0.0,
                                                        borderJoinStyle: 'miter',
                                                        pointBorderColor: 'rgba(75,192,192,1)',
                                                        pointBackgroundColor: '#fff',
                                                        pointBorderWidth: 1,
                                                        pointHoverRadius: 5,
                                                        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                                                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                                                        pointHoverBorderWidth: 2,
                                                        pointRadius: 1,
                                                        pointHitRadius: 10
                                                    }
                                                    ]
                                                },
                                                displayFilter: false,
                                                displayGraphOne: false,
                                                displayGraphTwo: true,
                                                displayGraphThree: true,
                                                graph2data: grapData,
                                                graph3Error: ''
                                            });
                                        }
                                        else {
                                            let grapData = { ...this.state.graph2data };
                                            let graph3Error = i18n.t("Ioj_initiative_compar_err_m7");
                                            this.setState({ graph3Error: graph3Error, graph2data: grapData })

                                        }
                                    })
                                    .catch((error) => {
                                        trycatchAlertPopup(error);
                                      });
                              
                              
                            }
                        })

                }

            } else {


                let nondData = this.state.filterdData.DeliveredData;
               
                let grap2Data = { ...this.state.graph2data };

                if (nondData.length) {


                    let KPIMappingID = null;
                    let DealId = nondData[event[0]._index].DealId;
                    let BOIImpacted = this.state.BOIImpacted;
                    let BoIUnit = "$"
                    if (this.state.UnitNames.length > 1) {
                        BoIUnit = this.state.UnitNames[1];
                    }

                    let data = {

                        dealId: DealId,
                        boi: BOIImpacted,
                        unit: BoIUnit
                    }


                    axiosInstance.post(`${LocalApiBaseUrl}IntelOpsCategory/GetInitiativesKpiMapping`, data)
                        .then((res) => {
                           
                            this.setState({
                                displayFilter: false,
                                displayGraphOne: false,
                                displayGraphTwo: true,
                                displayGraphThree: true,
                                graph2data: grap2Data
                            });
                            if (res.data[0]) {

                                axiosInstance.get(`${LocalApiBaseUrl}Analytics/GetKpiData?kpiMappingID=${res.data[0].KPIMappingID}`)
                                    
                                    .then(res => {
                                       
                                        let grapData = { ...this.state.graph2data };
                                        let MontnDt = [''];
                                        let ValueDt = [null];
                                        if (res.data.length) {
                                            res.data.forEach(element => {
                                                MontnDt.push(element.PeriodDt);
                                                ValueDt.push(parseFloat(element.MetricValue));
                                            });


                                            this.setState({
                                                temp: res.data,

                                                dsKpiData: {
                                                    labels: MontnDt,
                                                    datasets: [{
                                                        label: i18n.t("Metric"),
                                                        fontColor: 'black',
                                                        data: ValueDt, fill: true, lineTension: 0.1,
                                                        backgroundColor: 'rgba(75,192,192,0.4)',
                                                        borderColor: 'rgba(75,192,192,1)',
                                                        borderCapStyle: 'butt',
                                                        borderDash: [],
                                                        borderDashOffset: 0.0,
                                                        borderJoinStyle: 'miter',
                                                        pointBorderColor: 'rgba(75,192,192,1)',
                                                        pointBackgroundColor: '#fff',
                                                        pointBorderWidth: 1,
                                                        pointHoverRadius: 5,
                                                        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                                                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                                                        pointHoverBorderWidth: 2,
                                                        pointRadius: 1,
                                                        pointHitRadius: 10
                                                    }

                                                    ]
                                                },

                                                displayFilter: false,
                                                displayGraphOne: false,
                                                displayGraphTwo: true,
                                                displayGraphThree: true,
                                                graph2data: grapData,
                                                graph3Error: ''
                                            });
                                        }
                                        else {
                                            let grapData = { ...this.state.graph2data };
                                            let graph3Error = i18n.t("Ioj_initiative_compar_err_m7");
                                            this.setState({ graph3Error: graph3Error, graph2data: grapData })

                                        }
                                    })
                                    .catch((error) => {
                                        trycatchAlertPopup(error);
                                      });
                              
                              

                            }

                        })
                }
            }
        }


    }
    goBackToPreviousPage(val) {


        if (val === 1) {
            this.setState({
                ...this.state, displayFilter: true,
                displayGraphOne: false,
                displayGraphTwo: false,
                displayGraphThree: false
            })
        }
        if (val === 2) {
            this.setState({
                ...this.state, displayFilter: false,
                displayGraphOne: true,
                displayGraphTwo: false,
                displayGraphThree: false
            })
        }
        if (val === 3) {
            this.setState({
                ...this.state, displayFilter: false,
                displayGraphOne: false,
                displayGraphTwo: true,
                displayGraphThree: false
            })
        }
    }
    onTabClick(val) {
        if (val === "BOI") {
            this.setState({
                displayGraphOne: true,
                displayGraphTwo: false,
                displayGraphThree: false
            })
        } else {
            if (!this.state.selectedGraph) {
                let event = this.state.event;
                event[0]._index = 0;
                this.GraphData(event, 1);
            }
            else {
                if (this.state.selectedGraph === "unDelivered") {
                    let event = this.state.event;
                    event[0]._index = 0;
                    this.GraphData(event, 1);
                } else {
                    let event = this.state.event;
                    event[0]._index = 1;
                    this.GraphData(event, 1);
                }
            }

        }
    }
    render() {

        return (
            <>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="main-data mt-0">
                            <div className="row">
                                <div className="form-group col-lg-4">
                                    <label for="business_outcome_indicator">{i18n.t("Industry")}<span className="required">*</span></label>
                                    <select id="IndustryName" className="form-control" disabled={this.props.data || this.state.apivalue} ref="IndustryDropDown" onChange={this.onIndustryChange.bind(this)} value={this.state.IndustryID}>
                                        <option value="Cross Industry">Cross Industry</option>
                                                {this.state.IndustryOptions}
                                            </select>
                                </div>
                                <div className="form-group col-lg-4">
                                    <label for="business_outcome_indicator">{i18n.t("Business_Outcome_Indicator")}<span className="required">*</span></label>
                                    <select className="form-control" name="boi" id="business_outcome_indicator" onChange={this.onBOIChange.bind(this)} value={this.state.MetricID}>
                                    <option value="">{i18n.t("Select_Business_Outcome_Indicator")}</option>
                                        {this.state.BOI && this.state.BOI.map((el) => {
                                            return (<option value={el.MetricID}>{el.MetricName}</option>)
                                        })}
                                    </select>
                                    <span className="errormsg">{this.state.error.BoiError}</span>
                                </div>
                                <div className="form-group col-lg-4">
                                    <label for="Maturity_Phase">{i18n.t("Maturity_Phase")}<span className="required">*</span></label>
                                    <select className="form-control" name="boi" id="Maturity_Phase" onChange={this.onMPhaseChange.bind(this)} value={this.state.maturityPhase}>
                
                                    <option value="">{i18n.t("Select_Maturity_Phase")}</option>
                                    <option value="All">{i18n.t("All")}</option>
                                    <option value="Automated">{i18n.t("Automated")}</option>
                                    <option value="Foundation">{i18n.t("Foundation")}</option>
                                    <option value="Insights">{i18n.t("Insights")}</option>
                                    <option value="Intelligent">{i18n.t("Intelligent")}</option>
                                    </select>
                                    <span className="errormsg">{this.state.error.maturityPhaseError}</span>
                                </div>
                                {this.state.displayGraphTwo ? <div className="form-group col-lg-4">
                                    <label for="Initiative Status">{i18n.t("Initiative_Status")}<span className="required">*</span></label>
                                    <select className="form-control" name="Initiative_Status" id="Initiative Status" onChange={this.statusChange.bind(this)} value={this.state.selectedGraph}>
                                            <option value="unDelivered">{i18n.t("Not_Delivered")}</option>
                                           <option value="Delivered">{i18n.t("Delivered")}</option>
                                    </select>
                                </div> : null}
                                <div className="form-group col-lg-4">
                                    <label for="range">{i18n.t("iojReport_Range")}</label>
                                    <div className="form-row">
                                        <div className="col">
                                            <input className="form-control" type="number" name="min" placeholder={i18n.t("From")} onChange={this.onRangeChange.bind(this, "From")} value={this.state.range.from} /></div>
                                        <div className="col">
                                            <input className="form-control" type="number" name="max" placeholder={i18n.t("To")} onChange={this.onRangeChange.bind(this, "To")} value={this.state.range.to} />
                                        </div></div>
                                </div>
                               
                                <div className="col-md-12">
                                    <button type="submit" className="btn btn-primary btn-sm" name="submit" value="submit" disabled={this.state.error.disableSubmit} onClick={this.applayFilter} >{i18n.t("Submit")}</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2"></div>
                                <div className="col-sm-10">
                                    <span className="errormsg">{this.state.error.rangeError}</span>
                                    <span className="errormsg">{this.state.filterError}</span>
                                </div>
                                <div className="col-sm-2"></div>
                            </div>
                            {this.state.displayGraphOne || this.state.displayGraphTwo ?
                                <div className="Initiative_page1 ai-margin">
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <button type="submit" onClick={this.onTabClick.bind(this, "BOI")} className={`btn btn-primary btn-sm ag-bt1-radius ${!this.state.displayGraphOne ? "ag-bt-inactive" : ''}`} value="boi initiatives" name="Boi_initiatives">{i18n.t("BOI_Initiatives")}</button>
                                            <button type="submit" onClick={this.onTabClick.bind(this, "CDA")} value="cross deal comparison" className={`btn btn-primary btn-sm ag-bt2-radius ${!this.state.displayGraphTwo ? "ag-bt-inactive" : ''}`} name="cross_deal_comparison">{i18n.t("Cross_Deal_Comparison")}</button>
                                           
                                        </div>

                                        {this.state.OperatingGroup !== "All" && this.props.roledata.includes("Show Deals") ?

                                            <div className="col-sm-2">
                                                {/*  */}
                                                <label className="form-check">
                                                    <input className="form-check-input" type="checkbox" defaultChecked={this.state.ShowDeals} onChange={this.onShowDealChange} />
                                                    <span className="checkmark m-t-5"></span>
                                                    <span className="form-check-label">{i18n.t("Show_Deal_Name")}</span>
                                                </label>

                                            </div> : <div className="col-sm-2"> </div>}
                                       

                                        {this.state.displayGraphOne || this.state.displayGraphTwo ?
                                            <div className="col-sm-3">

                                            
                                                {this.state.UnitNames.map((row, index) => {
                                                    return (<button type="submit" onClick={this.onUnitChange.bind(this, row)} className={`btn btn-primary btn-sm ${index ? "ag-bt2-radius" : "ag-bt1-radius"} ${this.state.Unit != row ? "ag-bt-inactive" : ''}`} value={row} name="Boi_initiatives">{row}</button>)
                                                })
                                                }
                                            </div>
                                            : <div className="col-sm-3"> </div>}

                                    </div>
                                    <div className="ai-margin">
                                        <div className="row ">

                                            <div className="col-sm-6  ">
                                                {this.state.displayGraphOne ?
                                                    <Bar
                                                        title={""}
                                                        getElementAtEvent={event => this.GraphData(event, 1)}
                                                        data={this.state.graph1data}
                                                        redraw={true}
                                                        type='bar'
                                                        options={{
                                                            scales: {
                                                                xAxes: [{
                                                                    barThickness: 25,
                                                                    stacked: true,
                                                                    scaleLabel: {
                                                                        display: true,
                                                                        labelString: i18n.t("Initiative_Status"),
                                                                        crosshair: true,
                                                                    },
                                                                    marker: {
                                                                        enabled: false
                                                                    },
                                                                    gridLines: {
                                                                        drawOnChartArea: false
                                                                    },
                                                                    ticks: {
                                                                        fontColor: "black",
                                                                    }
                                                                }],

                                                                yAxes: [{
                                                                    type: "linear",

                                                                    gridLines: {
                                                                        drawOnChartArea: false
                                                                    },

                                                                    stacked: true,
                                                                    ticks: {
                                                                        suggestedMin: 0,
                                                                        fontColor: "black",
                                                                    },
                                                                    scaleLabel: {
                                                                        display: true,
                                                                        labelString: i18n.t("Number_of_Initiatives")
                                                                    },
                                                                    marker: {
                                                                        enabled: false
                                                                    }
                                                                }]
                                                            }


                                                        }}

                                                    /> : null}
                                                {this.state.displayGraphTwo ?
                                                    <Scatter data={this.state.graph2data} redraw={true}
                                                        type='scatter'
                                                        getElementAtEvent={event => this.GraphData(event, 2)}
                                                        options={{
                                                            responsive: true,
                                                            maintainAspectRatio: true,

                                                            plugins: {
                                                                datalabels: {
                                                                    display: false
                                                                }
                                                            },
                                                            scales: {
                                                                xAxes: [{
                                                                    ticks: {
                                                                        stepSize: 1,
                                                                        fontColor: "black",

                                                                        callback: function (label, index, labels) {

                                                                            let dealNameDel;
                                                                            let dealNamenonDel;
                                                                            let dealNameOthers;
                                                                            if (!(this.state.OperatingGroup === "All")) {

                                                                                if (label === 0) {
                                                                                    return '';
                                                                                } else {
                                                                                    if (this.state.selectedGraph === "Delivered" && label === this.state.dealIndex.Delivered) {


                                                                                        this.state.graph2data.datasets[0].DealData.filter((el) => {

                                                                                            if (el.x === this.state.dealIndex.Delivered) {

                                                                                                dealNameDel = el.DealName
                                                                                            }

                                                                                        })
                                                                                        if (this.state.ShowDeals) {
                                                                                            return dealNameDel;
                                                                                        } else {
                                                                                            return i18n.t("My_Deal");
                                                                                        }
                                                                                    }
                                                                                    else if (this.state.selectedGraph === "unDelivered" && label === this.state.dealIndex.nonDelivered) {

                                                                                        this.state.graph2data.datasets[0].DealData.filter((el) => {

                                                                                            if (el.x === label || el.x == label) {

                                                                                                dealNamenonDel = el.DealName;
                                                                                            }
                                                                                        })
                                                                                        if (this.state.ShowDeals) {
                                                                                            return dealNamenonDel;
                                                                                        } else {
                                                                                            return i18n.t("My_Deal");
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        this.state.graph2data.datasets[0].DealData.filter((el) => {

                                                                                            if (el.x === label || el.x == label) {

                                                                                                dealNameOthers = el.DealName;
                                                                                            }
                                                                                        })
                                                                                        if (this.state.ShowDeals) {
                                                                                            return dealNameOthers;
                                                                                        } else {
                                                                                            return i18n.t("Deal") + label;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            else {


                                                                                if (label === 0) {
                                                                                    return '';
                                                                                } else {
                                                                                    if (this.state.selectedGraph === "Delivered") {


                                                                                        this.state.updatedAllData.filter((el) => {

                                                                                            if (el.Flag === label) {

                                                                                                dealNameDel = el.OperatingGroup
                                                                                            }

                                                                                        })

                                                                                        return dealNameDel;

                                                                                    }
                                                                                    else if (this.state.selectedGraph === "unDelivered") {

                                                                                        this.state.updatedAllData.filter((el) => {

                                                                                            if (el.Flag === label) {

                                                                                                dealNameDel = el.OperatingGroup
                                                                                            }

                                                                                        })

                                                                                        return dealNameDel;
                                                                                    }

                                                                                }



                                                                            }

                                                                        }.bind(this)
                                                                    },
                                                                    stacked: true,
                                                                    scaleLabel: {
                                                                        display: true,
                                                                        labelString: this.state.labelForXAxis,
                                                                        crosshair: true,
                                                                    },
                                                                    marker: {
                                                                        enabled: false
                                                                    },
                                                                    gridLines: {
                                                                        drawOnChartArea: false
                                                                    },
                                                                }],

                                                                yAxes: [{
                                                                    type: "linear",

                                                                    gridLines: {
                                                                        drawOnChartArea: false
                                                                    },

                                                                    stacked: true,
                                                                    ticks: {
                                                                        suggestedMin: 0,
                                                                        fontColor: "black",
                                                                    },
                                                                    scaleLabel: {
                                                                        display: true,

                                                                        labelString: this.state.text
                                                                    },
                                                                    marker: {
                                                                        enabled: false
                                                                    }
                                                                }]
                                                            },
                                                            tooltips: {
                                                                enabled: true,

                                                                callbacks: {
                                                                    label: function (tooltipItems, data) {
                                                                        return this.state.initiativeNameData[tooltipItems.index].initiativeName + ' : ' + tooltipItems.yLabel;
                                                                    }.bind(this)
                                                                }
                                                            },

                                                        }}
                                                    /> : null}
                                            </div>
                                            <div className="col-sm-6">
                                                {this.state.graph3Error ? <span className="errormsg" >{this.state.graph3Error}</span> : null}
                                                {this.state.displayGraphThree && !this.state.graph3Error ?
                                                    <Line data={this.state.dsKpiData}
                                                        redraw="true"
                                                        options={{
                                                            maintainAspectRatio: true,
                                                            responsive: true,
                                                            tooltips: {
                                                                mode: 'index',
                                                                intersect: true,
                                                            },
                                                            hover: {
                                                                mode: 'index',
                                                                intersect: true
                                                            },
                                                            scales: {
                                                                yAxes: [{
                                                                    ticks: {
                                                                        precision: 1,
                                                                        fontColor: "black",
                                                                    },
                                                                    gridLines: {
                                                                        drawOnChartArea: false
                                                                    },

                                                                    scaleLabel: {
                                                                        display: true,
                                                                        labelString: this.state.Unit
                                                                    }


                                                                }],
                                                                xAxes: [{
                                                                    gridLines: {
                                                                        drawOnChartArea: false
                                                                    },
                                                                    ticks: {

                                                                        fontColor: "black"
                                                                    },
                                                                    scaleLabel: {
                                                                        display: true,
                                                                        labelString: i18n.t("Month")
                                                                    }
                                                                }]
                                                            },
                                                            legend: {
                                                                display: false,

                                                            }
                                                        }} />
                                                    : null}

                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                        </div></div></div>
            </>
        )
    }
}
export default IOJ_Initiative;
