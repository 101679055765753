import React from "react";

const RADIAN = Math.PI / 180;

const ChildView = (props, data) => {
  const { cx, cy, midAngle, outerRadius, index } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 0) * cos;
  const sy = cy + (outerRadius + 0) * sin;
  const mx = cx + (outerRadius + 0) * cos;
  const my = cy + (outerRadius + 0) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 50;
  const ey = my;
  return (
    <g>
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={data[index].fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={3} fill={data[index].fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 10}
        y={ey}
        fill="#212b36"
        fontSize={12}
        fontWeight="bold"
      >
        {data[index].count}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        fill="#212b36"
        fontSize={12}
        fontWeight="normal"
      >
        {data[index].name}
      </text>
    </g>
  );
};
export default ChildView;
