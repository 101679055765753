/* eslint-disable import/no-cycle */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle

import DashboardCntContainer from './DashboardCntContainer';

export default class Configurator extends Component {
  render() {
    const {
      getConfiguratorapicall, compDetails, SODetails, isRelatorBlock, attributes, filterDetails, isNewDeal, onTabChange, isBoLoaded, isHMLoaded, isIILoaded, isMPLoaded, isWOLoaded, isDDLoaded,
    } = this.props;

    return (
      <div className="main-component configurator-component">
        <div className="container-fluid">
          <DashboardCntContainer
            getConfiguratorapicall={getConfiguratorapicall}
            compDetails={compDetails}
            SODetails={SODetails}
            isRelatorBlock={isRelatorBlock}
            attributes={attributes}
            filterDetails={filterDetails}
            isNewDeal={isNewDeal}
            onTabChange={onTabChange}
            isBoLoaded={isBoLoaded}
            isHMLoaded={isHMLoaded}
            isWOLoaded={isWOLoaded}
            isIILoaded={isIILoaded}
            isDDLoaded={isDDLoaded}
            isMPLoaded={isMPLoaded}
          />
        </div>
      </div>
    );
  }
}

Configurator.propTypes = {
  getConfiguratorapicall: PropTypes.bool.isRequired,
  attributes: PropTypes.array.isRequired,
  filterDetails: PropTypes.object.isRequired,
  isRelatorBlock: PropTypes.bool.isRequired,
  compDetails: PropTypes.object.isRequired,
  SODetails: PropTypes.array.isRequired,
  isNewDeal: PropTypes.bool.isRequired,
  onTabChange: PropTypes.bool.isRequired,
  isBoLoaded: PropTypes.bool.isRequired,
  isHMLoaded: PropTypes.bool.isRequired,
  isIILoaded: PropTypes.bool.isRequired,
  isMPLoaded: PropTypes.bool.isRequired,
  isWOLoaded: PropTypes.bool.isRequired,
  isDDLoaded: PropTypes.bool.isRequired,
};
