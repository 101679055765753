import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import i18n from 'i18next';
import _ from 'lodash';

function ConfirmDeleteModalPopup(props) {
  const {
    isVisible,
    title,
    message,
    cancelButtonLabel,
    deleteButtonLabel,
    onClickCancelButton,
    onClickDeleteButton,
  } = props;

  const cancelButtonText = !_.isEmpty(cancelButtonLabel) ? cancelButtonLabel : i18n.t('Cancel');
  const deleteButtonText = !_.isEmpty(deleteButtonLabel) ? deleteButtonLabel : i18n.t('Delete');

  return (
    <Modal
      show={isVisible}
      onHide={onClickCancelButton}
      className="deletemoritem-modal"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="deletemoritem-title">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="deletenotification-body">
          <div className="deletenotification-icon">
            <span>
              <i className="fal fa-exclamation-circle" />
            </span>
          </div>
          <div className="deletenotification-data">
            <p>{message}</p>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="modal-btn deletemoritem-btns">
          <button onClick={onClickCancelButton} className="btn btn-default btn-sm mr-3">
            {cancelButtonText}
          </button>
          <button type="button" className="btn btn-danger btn-sm" onClick={onClickDeleteButton}>
            {deleteButtonText}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmDeleteModalPopup.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  deleteButtonLabel: PropTypes.string.isRequired,
  onClickCancelButton: PropTypes.func.isRequired,
  onClickDeleteButton: PropTypes.func.isRequired,
};

export default ConfirmDeleteModalPopup;
