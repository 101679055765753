import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel2';
import Dropdown from 'react-bootstrap/Dropdown';
import LoadingOverlay from 'react-loading-overlay';
import i18n from 'i18next';
import BeforeAfterDrilldownTimeLine from './BeforeAfterDrilldownTimeLine';
import BeforeAfterReasonGrowth from './BeforeAfterReasonGrowth';
import { dateFormatMethodTimeLineSeries, backendDateToJsDate } from '../Shared/commonFunctions';
import * as BeforeAfterActions from '../actions/BeforAfterMindMapActions';

import axiosInstance from '../Shared/interceptor';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import SynopsLoader from '../Shared/SynopsLoader';
import BeforeAfterHnMGraph from './BeforeAfterHnMGraph';
import Comments from '../comments/Comments';
import usePrevious from '../customHooks/usePrevious';
// import Comments from '../comments/Comments_new';
const RAGStatusMapping = {
  R: 'redColor',
  G: 'greenColor',
  A: 'amberColor',
  Y: 'greyColor',
};

const customCarousel = {
  margin: 8,
  dots: false,
  autoWidth: true,
  autoplay: false,
  loop: false,
  slideBy: 3,
  nav: true,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

const MetricsNodeMapping = {
  "IO": false,
  'DSP': false,
  'WC': false,
  "NPS": false,
  'HnM': false,
  BO: true,
  QA: true,
  COMP: true,
};

const CorouselNodes = [
  {
    key: 1,
    NodeName: 'IO',
    DisplayName: 'Intelligent Operations',
  },
  {
    key: 2,
    NodeName: 'NPS',
    DisplayName: 'NPS/CSAT',
  },
  {
    key: 3,
    NodeName: 'HnM',
    DisplayName: 'Human + Machine',
  },
  {
    key: 4,
    NodeName: 'COMP',
    DisplayName: 'Compliance',
  },
  {
    key: 5,
    NodeName: 'QA',
    DisplayName: 'Quality',
  },
  {
    key: 6,
    NodeName: 'BO',
    DisplayName: 'Business Outcomes',
  },
  {
    key: 7,
    NodeName: 'WC',
    DisplayName: 'Working Capital',
  },
  {
    key: 8,
    NodeName: 'DSP',
    DisplayName: 'DSP/DPO',
  },
];

function BeforeAfterDetail({
  node,
  handleMainPage,
  clientData,
  // REDUX STATES
  viewData,
  configrationData,
  MindMapJourney,
  dealId,
  // ACTIONS
  setDrillDownSectionData,
  setDrillDownJourneyData,
  setDrillDownMetricsJourneyData,
  setDrillDownReasonforGrowth,
  setDrillDownMetricsSeriesJourneyData,
  isConfiguratorMode,
  BeforeAfterUsers,
  CommentsImagesData,
  saveCommentsUserIcons,
  saveCommentsData,
  commentsData
}) {
  const [from, setFrom] = useState('Baseline');
  const [to, setTo] = useState('Current');
  const [currentNode, setCurrentNode] = useState(node);
  const [fromValue, setFromValue] = useState(
    configrationData.MindMapData[currentNode].BaselineYear
      ? backendDateToJsDate(configrationData.MindMapData[currentNode].BaselineYear)
      : '',
  );
  const [fromIndex, setfromIndex] = useState(0);
  const [toIndex, settoIndex] = useState(
    configrationData.MindMapData[currentNode].TimeseriesData.length + 1,
  );
  const [toValue, setToValue] = useState(
    configrationData.MindMapData[currentNode].CurrentYear
      ? backendDateToJsDate(configrationData.MindMapData[currentNode].CurrentYear)
      : '',
  );
  const [reasonForGrowth, setreasonForGrowth] = useState('Initiatives');

  const [selectedMetric, setselectedMetric] = useState('');
  const [metricSearch, setmetricSearch] = useState('');
  const [metricsData, setmetricsData] = useState({});
  const [searchGrowth, setsearchGrowth] = useState('');
  const [selectAllStatus, setselectAllStatus] = useState(false);
  const [apiLoading, setapiLoading] = useState(false);
  const [fromTimeSeries, setfromTimeSeries] = useState(null);
  const [toTimeSeries, settoTimeSeries] = useState(null);
  // const [commentsData, setCommentsData] = useState([]);
  const [isGetCommentsLoading, setIsGetCommentsLoading] = useState(false);
  const [isAddCommentsLoading, setIsAddCommentsLoading] = useState(false);
  const [isDeleteCommentsLoading, setIsDeleteCommentsLoading] = useState(false);
  // NEW STATE
  const prevDealId = usePrevious(dealId)

  const handleChangeFromTo = (value, monthYear, index, TimeseriesData = null) => {
    if (to && from) {
      setFrom(value);
      setFromValue(monthYear);
      setfromIndex(index);
      setTo('');
      setToValue('');
      settoIndex('');
      settoTimeSeries(null);
      if (value.includes('Timeseries')) {
        setfromTimeSeries(TimeseriesData);
      } else {
        setfromTimeSeries(null);
      }
    } else {
      if (!from && !to) {
        setFrom(value);
        setFromValue(monthYear);
        setfromIndex(index);
        if (value.includes('Timeseries')) {
          setfromTimeSeries(TimeseriesData);
        } else {
          setfromTimeSeries(null);
        }
        settoTimeSeries(null);
      }
      if (from && !to) {
        if (monthYear > fromValue) {
          setTo(value);
          setToValue(monthYear);
          settoIndex(index);
          if (value.includes('Timeseries')) {
            settoTimeSeries(TimeseriesData);
          } else {
            settoTimeSeries(null);
          }
        } else {
          setTo(from);
          setToValue(fromValue);
          settoIndex(fromIndex);
          if (from.includes('Timeseries')) {
            settoTimeSeries(fromTimeSeries);
          } else {
            settoTimeSeries(null);
          }
          setFrom(value);
          setFromValue(monthYear);
          setfromIndex(index);
          if (value.includes('Timeseries')) {
            setfromTimeSeries(TimeseriesData);
          } else {
            setfromTimeSeries(null);
          }
        }
      }
    }
  };
  const handleGoBack = () => {
    handleMainPage();
  };
  const setReduxReasonForGrowth = (newData) => {
    const { SectionId } = configrationData.MindMapData[currentNode];
    const ReasonforGrowth = reasonForGrowth;
    const FromMilestone = from;
    const ToMilestone = to;
    const Data = newData[reasonForGrowth];
    let CustomMetricId = 0;
    let KpiMappingID = null;
    if (selectedMetric) {
      CustomMetricId = selectedMetric.Id;
      KpiMappingID = selectedMetric.KpiMappingID;
    }
    const Journey = MetricsNodeMapping[currentNode] ? 'MetricsData' : 'SectionJourney';
    const payload = {
      SectionId,
      FromMilestone,
      ToMilestone,
      ReasonforGrowth,
      Data,
      CustomMetricId,
      KpiMappingID,
      Journey,
    };
    setDrillDownReasonforGrowth(payload);
  };
  const handleChangeReasonforGrowth = (value) => {
    if (isConfiguratorMode) {
      if (
        metricsData[value].length !== 0
        && metricsData[value].filter((a) => a !== undefined && a.isChecked).length
          === metricsData[value].length
      ) {
        setselectAllStatus(true);
      } else {
        setselectAllStatus(false);
      }
    }
    setmetricSearch('');
    setsearchGrowth('');
    setreasonForGrowth(value);
  };
  const handleCheckboxClick = (reasonfG, metric) => {
    const metricsDatatemp = metricsData;
    metricsDatatemp[reasonfG] = metricsDatatemp[reasonfG].map((a) => {
      if (a.label === metric.label) {
        a.isChecked = !a.isChecked;
      }
      return a;
    });
    if (
      metricsDatatemp[reasonfG].length !== 0
      && metricsDatatemp[reasonfG].filter((a) => a.isChecked).length
        === metricsDatatemp[reasonfG].length
    ) {
      setselectAllStatus(true);
    } else {
      setselectAllStatus(false);
    }

    setmetricsData({ ...metricsDatatemp });
    setReduxReasonForGrowth({ ...metricsDatatemp });
  };

  const handleSelectAll = (reasonfG) => {
    const selectAll = !selectAllStatus;
    const metricsDatatemp = metricsData;
    metricsDatatemp[reasonfG] = metricsDatatemp[reasonfG].map((a) => {
      a.isChecked = selectAll;
      return a;
    });
    setmetricsData({ ...metricsDatatemp });
    setselectAllStatus(selectAll);
    setReduxReasonForGrowth({ ...metricsDatatemp });
  };
  const handleChangeNode = (value) => {
    setselectedMetric('');
    setmetricsData({});
    setFrom('Baseline');
    setTo('Current');
    setCurrentNode(value);
  };

  const handleSelectDropdownOption = (value) => {
    setFrom('Baseline');
    setTo('Current');
    setreasonForGrowth('Initiatives');
    setmetricSearch('');
    setselectedMetric(value);
    const temp = configrationData.MindMapData[currentNode].Metrics.filter(
      (a) => a.MetricName === value.MetricName,
    )[0];
    setFromValue(temp.BaselineYear ? backendDateToJsDate(temp.BaselineYear) : '');
    setToValue(temp.CurrentYear ? backendDateToJsDate(temp.CurrentYear) : '');
    setfromIndex(0);
    settoIndex(temp.TimeseriesData.length + 1);
    setmetricsData({});
    const fromData = 'Baseline';
    const toData = 'Current';
    handleGetAPICall(value.Id, value.KPIMappingID, fromData, toData);
  };

  const handleMetricSearch = (event) => {
    setmetricSearch(event.target.value);
  };

  const handleSearchGrowth = (e) => {
    setsearchGrowth(e.target.value);
  };

  const handleCourselMovement = () => {
    const selectedStep = CorouselNodes.filter((row) => row.NodeName === currentNode);
    let selectedIndexVal;
    if (selectedStep[0] && selectedStep[0].key > 4) {
      selectedIndexVal = selectedStep[0].key - 2;
    } else {
      selectedIndexVal = 0;
    }
    const carousel = $('.owl-carousel');
    carousel.owlCarousel();
    carousel.trigger('to.owl.carousel', [selectedIndexVal, 1000, true]);
  };

  const handleMetricDataSorting = (response, sectionJourney, MetricJourney = false) => {
    let tempmetricsData = [];
    if (sectionJourney) {
      tempmetricsData = response;
    } else if (MetricJourney) {
      tempmetricsData = response.MetricsData[0].MetricJourney[0];
    } else {
      tempmetricsData = response.SectionJourney[0];
    }

    tempmetricsData.Initiatives = tempmetricsData.Initiatives.map((a) => ({
      ...a,
      label: a.InitiativeName,
    }));
    tempmetricsData.Metrics = tempmetricsData.Metrics.map((a) => ({
      ...a,
      label: a.MetricName,
    }));
    tempmetricsData.WorkOrchestration = tempmetricsData.WorkOrchestration.map((a) => ({
      ...a,
      label: a.WOStepName,
    }));
    tempmetricsData.HumanMachine = tempmetricsData.HumanMachine.map((a) => ({
      ...a,
      label: a.MetricName,
    }));
    setmetricsData(tempmetricsData);
    if (
      Object.keys(tempmetricsData).length > 0
      && tempmetricsData.Initiatives.filter((a) => a.isChecked).length
        === tempmetricsData.Initiatives.length
    ) {
      setselectAllStatus(true);
    } else {
      setselectAllStatus(false);
    }
    setreasonForGrowth('Initiatives');
  };

  const APICallNodeMetricSelected = (
    node,
    metricID = null,
    tempKPIMappingID = null,
    tempfromData,
    temptoData,
  ) => {
    setapiLoading(true);
    const { SectionId } = configrationData.MindMapData[node];
    const clientID = clientData.id;
    const fromData = tempfromData.includes('Timeseries') ? 'Timeseries' : tempfromData;
    const toData = temptoData.includes('Timeseries') ? 'Timeseries' : temptoData;
    let FromTimeSeriesId = 0;
    let ToTimeseriesId = 0;
    if (fromData === 'Timeseries') {
      FromTimeSeriesId = fromTimeSeries !== null ? (parseInt(fromTimeSeries.Id) !== 0 ? fromTimeSeries.Id : 0) : 0;
    }
    if (toData === 'Timeseries') {
      ToTimeseriesId = toTimeSeries !== null ? (parseInt(toTimeSeries.Id) !== 0 ? toTimeSeries.Id : 0) : 0;
    }

    axiosInstance
      .get(`${LocalApiBaseUrl}BeforeAndAfterMindmap/GetClientMindmapJourneyData`, {
        params: {
          OrgEntId: clientID,
          SectionId,
          FromMilestone: tempfromData,
          ToMilestone: temptoData,
          FromTimeSeriesId, // Id or 0 if it is timeseries
          ToTimeseriesId, // Id or 0 if it is timeseries
          MetricId: metricID,
          KpiMappingID: tempKPIMappingID,
          DealId: dealId,
        },
      })
      .then((response) => {
        const responseData = response.data;
        let journeyIdExist = false;
        if (!MetricsNodeMapping[currentNode]) {
          journeyIdExist = responseData.SectionJourney[0].JourneyId !== 0;
        } else {
          journeyIdExist = responseData.MetricsData[0].MetricJourney[0].JourneyId !== 0;
        }

        const KPIMappingID = tempKPIMappingID === null ? 0 : tempKPIMappingID;
        responseData.SectionJourney = MetricsNodeMapping[currentNode]
          ? []
          : responseData.SectionJourney;
        responseData.MetricsData = MetricsNodeMapping[currentNode] ? responseData.MetricsData : [];
        const journey = MetricsNodeMapping[currentNode] ? 'MetricsData' : 'SectionJourney';
        const DATA = journey === 'MetricsData'
          ? responseData[journey][0].MetricJourney[0]
          : responseData[journey][0];
        const obj = { ...DATA };
        if (tempfromData.includes('Timeseries') || to.includes('Timeseries')) {
          const fromTime = tempfromData.includes('Timeseries')
            ? journeyIdExist
              ? FromTimeSeriesId
              : parseInt(tempfromData.split('Timeseries')[1])
            : null;
          const toTime = temptoData.includes('Timeseries')
            ? journeyIdExist
              ? ToTimeseriesId
              : parseInt(temptoData.split('Timeseries')[1])
            : null;
          obj.FromTimeseriesID = fromTime;
          obj.ToTimeseriesID = toTime;
          obj.FromTimeseriesUniqueId = fromTimeSeries ? fromTimeSeries.uniqueId : null;
          obj.ToTimeseriesUniqueId = toTimeSeries ? toTimeSeries.uniqueId : null;
          obj.IsNewTimeseriesJourney = obj.JourneyId === 0;
        } else {
          obj.FromTimeseriesID = null;
          obj.ToTimeseriesID = null;
          obj.FromTimeseriesUniqueId = null;
          obj.ToTimeseriesUniqueId = null;
          obj.IsNewTimeseriesJourney = false;
        }

        if (journey !== 'MetricsData') {
          responseData.SectionJourney[0] = obj;
        } else {
          responseData.MetricsData[0].MetricJourney[0] = obj;
        }

        if (
          MindMapJourney.filter(
            (a) => parseInt(a.SectionId) === configrationData.MindMapData[currentNode].SectionId,
          ).length === 0
        ) {
          // NOT EXIST
          setDrillDownSectionData(responseData);
        } else {
          // EXIST
          const foundNode = MindMapJourney.filter(
            (a) => parseInt(a.SectionId) === configrationData.MindMapData[currentNode].SectionId,
          )[0];

          if (
            !MetricsNodeMapping[currentNode]
            && foundNode.SectionJourney.filter(
              (a) => a.FromMilestone === tempfromData && a.ToMilestone === temptoData,
            ).length === 0
          ) {
            // NOT EXIST and NON METRIC
            const payload = {
              SectionId,
              Data: responseData.SectionJourney[0],
            };
            setDrillDownJourneyData(payload);
          }
          if (
            MetricsNodeMapping[currentNode]
            && foundNode.MetricsData.filter(
              (a) => parseInt(a.KpiMappingID) === parseInt(KPIMappingID) && a.CustomMetricId === metricID,
            ).length === 0
          ) {
            // NOT Exist And Metric
            const payload = {
              SectionId,
              Data: responseData.MetricsData[0],
            };
            setDrillDownMetricsJourneyData(payload); // Adding Metrics
          }
          if (
            MetricsNodeMapping[currentNode]
            && foundNode.MetricsData.filter(
              (a) => parseInt(a.KpiMappingID) === parseInt(KPIMappingID) && a.CustomMetricId === metricID,
            ).length > 0
          ) {
            // Metric Exist And Metric
            const foundMetric = foundNode.MetricsData.filter(
              (a) => parseInt(a.KpiMappingID) === parseInt(KPIMappingID) && a.CustomMetricId === metricID,
            )[0];
            if (
              foundMetric.MetricJourney.filter(
                (a) => a.FromMilestone === tempfromData && a.ToMilestone === temptoData,
              ).length === 0
            ) {
              // Journey Does not exist And Metric
              const payload = {
                SectionId,
                CustomMetricId: metricID,
                KpiMappingID: KPIMappingID,
                Data: responseData.MetricsData[0].MetricJourney[0],
              };
              // ADDING TIME SERIES DATA TO THE SELECTED METRIC FOR THE GIVEN FROM AND TO
              setDrillDownMetricsSeriesJourneyData(payload);
            }
          }
        }
        const metricJourney = journey === 'MetricsData';
        handleMetricDataSorting(responseData, false, metricJourney);

        setreasonForGrowth('Initiatives');
        setapiLoading(false);
      })
      .catch((error) => {
        setapiLoading(false);
        setmetricsData({});
        trycatchAlertPopup(error);
      });
  };

  const getCommentsData = (clientId, sectionId, journeyId) => {
    setIsGetCommentsLoading(() => true);
    axiosInstance
      .get(`${LocalApiBaseUrl}BeforeAndAfterMindmap/GetClientMindmapComments`, {
        params: {
          OrgEntId: clientId,
          SectionId: sectionId,
          JourneyId: journeyId,
          DealId: dealId,
        },
      })
      .then((response) => {
        setIsGetCommentsLoading(() => false);
        if (response.data && response.data.Comments) {
          const Ids = [];
          response.data.Comments.forEach((each) => {
            Ids.push(each.UserName);
            if (each.Replies && each.Replies.length) {
              each.Replies.forEach((eachReply) => {
                Ids.push(eachReply.UserName);
              });
            }
          });
          let UpdatedUserNames = [...new Set(Ids)];
          UpdatedUserNames = UpdatedUserNames.filter((eachUser) => {
            if(!CommentsImagesData[eachUser]) {
              return eachUser;
            }
          });
          if(UpdatedUserNames.length) {
            let paramList = ''
            UpdatedUserNames.forEach((each, index) => {
              paramList += `Users=${each}${(index +1) !== UpdatedUserNames.length ? '&' :''}`
            });
          axiosInstance
            .post(`${LocalApiBaseUrl}User/GetPeopleImage?${paramList}`)
            .then((userData) => {

              if(userData.data) {
              const UpdatedUserData = {};
              userData.data.forEach((each) => { UpdatedUserData[each.User] = `data:image/jpeg;base64,${each.ImageURL}`; });
              saveCommentsUserIcons(UpdatedUserData);
              }
              
            });
          }
          saveCommentsData(response.data.Comments);
          // setCommentsData(() => response.data.Comments);
        }
      }).catch(() => {
        setIsGetCommentsLoading(() => false);
      });
  };

  const handleGetAPICall = (metricID = null, tempKPIMappingID = null, fromData, toData) => {
    const KPIMappingID = tempKPIMappingID === null ? 0 : tempKPIMappingID;
    const sectionID = configrationData.MindMapData[currentNode].SectionId;
    const journey = MetricsNodeMapping[currentNode] ? 'MetricsData' : 'SectionJourney';

    if (MindMapJourney.filter((a) => parseInt(a.SectionId) === parseInt(sectionID)).length > 0) {
      const foundNode = MindMapJourney.filter(
        (a) => parseInt(a.SectionId) === parseInt(sectionID),
      )[0];
      if (
        journey === 'SectionJourney'
        && foundNode.SectionJourney.filter(
          (a) => a.FromMilestone === fromData && a.ToMilestone === toData,
        ).length > 0
      ) {
        const seriesFoundData = foundNode.SectionJourney.filter(
          (a) => a.FromMilestone === fromData && a.ToMilestone === toData,
        )[0];
        handleMetricDataSorting(seriesFoundData, true);
      } else if (
        journey === 'MetricsData'
        && foundNode.MetricsData.filter(
          (a) => parseInt(a.KpiMappingID) === KPIMappingID && a.CustomMetricId === metricID,
        ).length > 0
      ) {
        const foundMetric = foundNode.MetricsData.filter(
          (a) => parseInt(a.KpiMappingID) === KPIMappingID && a.CustomMetricId === metricID,
        )[0];
        if (
          foundMetric.MetricJourney.filter(
            (a) => a.FromMilestone === fromData && a.ToMilestone === toData,
          ).length > 0
        ) {
          const metricJourney = foundMetric.MetricJourney.filter(
            (a) => a.FromMilestone === fromData && a.ToMilestone === toData,
          )[0];
          const load = {
            MetricsData: [
              {
                MetricJourney: [metricJourney],
              },
            ],
          };
          handleMetricDataSorting(load, false, true);
        } else {
          // NO Journey DATA IS PRESENT for the Metric
          APICallNodeMetricSelected(currentNode, metricID, KPIMappingID, fromData, toData);
        }
      } else {
        // NO Journey DATA IS PRESENT
        APICallNodeMetricSelected(currentNode, metricID, KPIMappingID, fromData, toData);
      }
    } else {
      // NO NODE DATA IS PRESENT
      APICallNodeMetricSelected(currentNode, metricID, KPIMappingID, fromData, toData);
    }
  };

  // NEW USEEFFECT

  // WHEN FROM AND TO VALUE IS CHANGED
  useEffect(() => {
    if (from && to && Object.keys(metricsData).length !== 0) {
      if (selectedMetric) {
        handleGetAPICall(selectedMetric.Id, selectedMetric.KPIMappingID, from, to);
      } else {
        handleGetAPICall(null, null, from, to);
      }
    }
    setsearchGrowth('');
  }, [from, to]);

  // Call for Comments api
  useEffect(() => {
    if (clientData && clientData.id && currentNode && viewData && viewData[currentNode] && viewData[currentNode].SectionId) {
      const currentSectionId = viewData[currentNode].SectionId;
      let JourneyId = 0;
      if (metricsData && metricsData.JourneyId) {
        JourneyId = metricsData.JourneyId;
      }
      getCommentsData(clientData.id, currentSectionId, JourneyId);
    }
    // if(prevDealId != dealId){
    //   handleGetAPICall(null, null, from, to);
    //   setselectedMetric('');
    //   setmetricsData({});
    //   setFrom('Baseline');
    //   setTo('Current');
    // }
  }, [currentNode, clientData, metricsData, viewData]);

  // WHEN NODE IS CHANGED
  useEffect(() => {
    setselectedMetric('');
    setselectAllStatus(false);
    setreasonForGrowth('Initiatives');
    setFrom('Baseline');
    setTo('Current');
    setsearchGrowth('');
    setmetricSearch('');

    setmetricsData({});
    if (!MetricsNodeMapping[currentNode.toString()]) {
      // NON Metric Node is Selected

      setfromIndex(0);
      settoIndex(configrationData.MindMapData[currentNode].TimeseriesData.length + 1);
      setFromValue(
        configrationData.MindMapData[currentNode].BaselineYear
          ? backendDateToJsDate(configrationData.MindMapData[currentNode].BaselineYear)
          : '',
      );
      setToValue(
        configrationData.MindMapData[currentNode].CurrentYear
          ? backendDateToJsDate(configrationData.MindMapData[currentNode].CurrentYear)
          : '',
      );
      handleGetAPICall(null, null, from, to);
    } else {
      setFromValue('');
      setToValue('');
      setfromIndex(0);
      settoIndex(0);
    }

    handleCourselMovement();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }, [currentNode, dealId]);

  // TRIGGERED WHEN CONFIGURATOR MODE IS CHANGED
  useEffect(() => {
    const fromData = 'Baseline';
    const toData = 'Current';
    setFrom('Baseline');
    setTo('Current');
    setmetricsData({});
    setsearchGrowth('');
    if (MetricsNodeMapping[currentNode] && selectedMetric) {
      const temp = configrationData.MindMapData[currentNode].Metrics.filter(
        (a) => a.MetricName === selectedMetric.MetricName,
      )[0];
      setFromValue(temp.BaselineYear ? backendDateToJsDate(temp.BaselineYear) : '');
      setToValue(temp.CurrentYear ? backendDateToJsDate(temp.CurrentYear) : '');
      setfromIndex(0);
      settoIndex(temp.TimeseriesData.length + 1);

      handleGetAPICall(selectedMetric.Id, selectedMetric.KPIMappingID, fromData, toData);
    } else if (!MetricsNodeMapping[currentNode]) {
      const temp = configrationData.MindMapData[currentNode];
      setFromValue(temp.BaselineYear ? backendDateToJsDate(temp.BaselineYear) : '');
      setToValue(temp.CurrentYear ? backendDateToJsDate(temp.CurrentYear) : '');
      setfromIndex(0);
      settoIndex(temp.TimeseriesData.length + 1);
      handleGetAPICall(null, null, fromData, toData);
    }
  }, [isConfiguratorMode]);

  // NEW USE EFFECT
  

  let metricsDropDownData =
    metricSearch !== ""
      ? configrationData.MindMapData[currentNode].Metrics.filter((a) =>
          a.MetricName.toLowerCase().includes(metricSearch.toLowerCase())
        )
    : configrationData.MindMapData[currentNode].Metrics;
  metricsDropDownData = metricsDropDownData.filter((a) => !a.isDelected);

  const sectionData = MetricsNodeMapping[currentNode.toString()]
    ? configrationData.MindMapData[currentNode].Metrics.filter(
      (a) => a.MetricName === selectedMetric.MetricName,
    )[0]
    : configrationData.MindMapData[currentNode];

  const newMetricsData = Object.keys(metricsData).length > 0 ? { ...metricsData } : {};
  newMetricsData[reasonForGrowth] = Object.keys(newMetricsData).length > 0
    ? searchGrowth !== ''
      ? newMetricsData[reasonForGrowth].filter((a) => a.label.toLowerCase().includes(searchGrowth.toLowerCase()))
      : newMetricsData[reasonForGrowth]
    : [];
  const originalSearchReasons = { ...metricsData };
  const deleteComment = (commentData) => {
    const currentSectionId = viewData[currentNode].SectionId;
    let JourneyId = 0;
    if (metricsData && metricsData.JourneyId) {
      JourneyId = metricsData.JourneyId;
    }
    setIsDeleteCommentsLoading(() => true);
    axiosInstance
      .delete(`${LocalApiBaseUrl}BeforeAndAfterMindmap/DeleteClientMindmapComment`, { params: { CommentId: commentData.CommentId } })
      .then((response) => {
        if (response.data) {
          getCommentsData(clientData.id, currentSectionId, JourneyId);
          setIsDeleteCommentsLoading(() => false);
        }
      }).catch(() => {
        setIsDeleteCommentsLoading(() => false);
      });
  };
  const onAddOrUpdateComment = (data) => {
    const currentSectionId = viewData[currentNode].SectionId;
    let JourneyId = 0;
    if (metricsData && metricsData.JourneyId) {
      JourneyId = metricsData.JourneyId;
    }
    const postData = {
      OrgEntId: clientData.id,
       SectionId: currentSectionId,
        JourneyId,
         Comments: [data],
    };
    setIsAddCommentsLoading(() => true);
    axiosInstance
      .post(`${LocalApiBaseUrl}BeforeAndAfterMindmap/PostClientMindmapComments?DealId=${dealId}`, postData)
      .then((response) => {
        if (response.data) {
          getCommentsData(clientData.id, currentSectionId, JourneyId);
          setIsAddCommentsLoading(() => false);
        }
      }).catch(() => {
        setIsAddCommentsLoading(() => false);
      });
  };
  const displayData = isConfiguratorMode ? configrationData.MindMapData : viewData;
  const unitofmeasurement = sectionData ?( MetricsNodeMapping[currentNode]
    ? sectionData.MetricUnit
    : sectionData.ValueUnit) : '';
  return (
    <div className="beforeafter-detail">
      {apiLoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />}

      <button className="btn btn-gradient-common timeline-btngradient" onClick={handleGoBack}>
        {clientData.name}
      </button>
      <div className="beforeafterlever-carousel">
        <OwlCarousel className="owl-carousel" options={customCarousel}>
          {CorouselNodes.map((nodeobj) => (
            <div className="item">
              <span
                onClick={() => {
                  handleChangeNode(nodeobj.NodeName);
                }}
                className={
                  currentNode === nodeobj.NodeName
                    ? 'beforeafterlever-name active'
                    : 'beforeafterlever-name'
                }
              >
                {( displayData && displayData[nodeobj.NodeName]  && displayData[nodeobj.NodeName].DisplayName) ? displayData[nodeobj.NodeName].DisplayName :  nodeobj.DisplayName}
              </span>
            </div>
          ))}
        </OwlCarousel>
      </div>
      {/* <div className="row">
        <div className="col-sm-9"> */}
      <div className="beforeafter-detailcontent">
        <div className="beforeafter-detailcontentleft">
          {MetricsNodeMapping[currentNode.toString()] && (
          <div>
            {configrationData.MindMapData[currentNode].Metrics.length > 0 ? (
              <h3>Please select any one metric...</h3>
            ) : isConfiguratorMode ? (
              <h3>{i18n.t('Please_config')}</h3>
            ) : (
              <h3>{i18n.t('performance_empty')}</h3>
            )}

            {configrationData.MindMapData[currentNode].Metrics.length > 0 && (
            <div className="purpleborder-dropdown mb-3 mt-3">
              <Dropdown
                className="metrictoggle-btn "
                onBlur={() => {
                  setmetricSearch('');
                }}
              >
                <Dropdown.Toggle>
                  {selectedMetric === '' ? (
                    'Metrics'
                  ) : (
                    <span
                      className={`rag-status-color ${
                        RAGStatusMapping[selectedMetric.RAGStatus]
                      }`}
                    >
                      {selectedMetric.MetricName}
                    </span>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="searchFilter mb-2">
                    <input
                      type="search"
                      placeholder="Search"
                      value={metricSearch}
                      onChange={handleMetricSearch}
                    />
                  </div>
                  <div className="dropdownlist-wrapper">
                    {configrationData.MindMapData[currentNode].Metrics.length > 0
                            && metricsDropDownData.length > 0 ? (
                        metricsDropDownData.map((item) => (
                          <Dropdown.Item
                            className={`rag-status-color ${RAGStatusMapping[item.RAGStatus]}`}
                            eventKey={item.MetricName}
                            active={selectedMetric.MetricName === item.MetricName}
                            onClick={() => handleSelectDropdownOption(item)}
                          >
                            {item.MetricName}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <p className="search-no-items text-center">No results found</p>
                      )}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            )}
          </div>
          )}
          {((MetricsNodeMapping[currentNode.toString()] && selectedMetric !== '')
                || MetricsNodeMapping[currentNode.toString()] === false) && (
                <>
                  {(sectionData.BaselineYear || sectionData.CurrentYear || sectionData.TargetYear ) ? (
                    <>
                      <h3>Please select any two intervals to see the reason for change…</h3>
                      {currentNode !== "IO" && unitofmeasurement !== undefined && unitofmeasurement.toLowerCase() !== 'string' && (
                        <p className="mappinglabel-unit">
                          Unit of Measurement:
                          {' '}
                          <span>
                          {unitofmeasurement}
                          </span>
                        </p>
                      )}
                      {currentNode === 'HnM' && from && to && (
                        <BeforeAfterHnMGraph from={from} to={to} sectionData={sectionData} />
                      )}

                      <BeforeAfterDrilldownTimeLine
                        from={from}
                        to={to}
                        setfromIndex={setfromIndex}
                        settoIndex={settoIndex}
                        handleChangeFromTo={handleChangeFromTo}
                        sectionData={sectionData}
                        nodeselected={currentNode}
                        fromIndex={fromIndex}
                        toIndex={toIndex}
                        isConfiguratorMode={isConfiguratorMode}
                      />
                      <div className="growthreasons-section">
                        <BeforeAfterReasonGrowth
                          reasonForGrowth={reasonForGrowth}
                          handleChange={handleChangeReasonforGrowth}
                          handleCheckboxClick={handleCheckboxClick}
                          metricsData={newMetricsData}
                          searchGrowth={searchGrowth}
                          selectAllStatus={selectAllStatus}
                          handleSelectAll={handleSelectAll}
                          from={from}
                          to={to}
                          isConfiguratorMode={isConfiguratorMode}
                          handleSearchGrowth={handleSearchGrowth}
                          originalSearchReasons={originalSearchReasons}
                        />
                      </div>
                    </>
                  ) : isConfiguratorMode ? (
                    <h3>{i18n.t('Please_config')}</h3>
                  ) : (
                    <h3>{i18n.t('performance_empty')}</h3>
                  )}
                </>
          )}
        </div>
        <div className="beforeafter-detailcontentright">
          
          <Comments
            comments={commentsData}
            suggestions={BeforeAfterUsers}
            userName={sessionStorage.getItem('EnterpriseID')}
            NodeName={viewData[currentNode].DisplayName || viewData[currentNode].SectionName}
            isApiLoading={isGetCommentsLoading || isAddCommentsLoading || isDeleteCommentsLoading}
            deleteComment={deleteComment}
            onAddOrUpdateComment={onAddOrUpdateComment}
            CommentsImagesData={CommentsImagesData}
            charLimit={120}
          />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  viewData: state.BeforeAfterMindMapReducer.viewData,
  configrationData: state.BeforeAfterMindMapReducer.configrationData,
  postData: state.BeforeAfterMindMapReducer.postData,
  MindMapJourney: state.BeforeAfterMindMapReducer.configrationData.MindMapJourney,
  isConfiguratorMode: state.BeforeAfterMindMapReducer.isConfiguratorMode,
  BeforeAfterUsers: state.BeforeAfterMindMapReducer.BeforeAfterUsers,
  CommentsImagesData: state.BeforeAfterMindMapReducer.CommentsImagesData,
  commentsData: state.BeforeAfterMindMapReducer.CommentsData,
  dealId: state.CurrentFilter.DealID,
});
const mapDispatchToProps = (dispatch) => ({
  setDrillDownSectionData: (data) => dispatch(BeforeAfterActions.setDrillDownSectionData(data)),
  setDrillDownJourneyData: (data) => dispatch(BeforeAfterActions.setDrillDownJourneyData(data)),
  setDrillDownMetricsJourneyData: (data) => dispatch(BeforeAfterActions.setDrillDownMetricsJourneyData(data)),
  setDrillDownReasonforGrowth: (data) => dispatch(BeforeAfterActions.setDrillDownReasonforGrowth(data)),
  setDrillDownMetricsSeriesJourneyData: (data) => dispatch(BeforeAfterActions.setDrillDownMetricsSeriesJourneyData(data)),
  saveCommentsUserIcons: (data) => dispatch(BeforeAfterActions.saveCommentsUserIcons(data)),
  saveCommentsData: (data) => dispatch(BeforeAfterActions.saveCommentsData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeforeAfterDetail);
