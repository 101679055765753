import React from "react";
import {
  // DashboardLandingView,
  SummaryWheelView,
  DashboardDetailedView,
  DashboardLandingView,
} from "svm-dashboard";
import PropTypes from "prop-types";

const NO_DATA_MESSAGE = "No data found";

class SVM extends React.Component {
  static defaultProps = {
    selectedYear: "",
    selectedProgram: "",
    startButton: true,
    isStart: false,
    isKnowMore: false,
    isInitialized: false,
    dashboardProgram: [],
    dashboardData: [],
    dashboardClientDetails: {},
    reportingYear: [],
    programFiscalYearRange: {
      min: 0,
      max: 0,
    },
    dbDimensionList: undefined,
    dimensionSubDimension: [],
    programID: "",
    dashboardProgramDetails: {},
    dashboardCommentary: {},
    programOptions: [],
  };

  static propTypes = {
    onStart: PropTypes.any,
    isKnowMore: PropTypes.any,
    dashboardClientDetails: PropTypes.any,
    dashboardData: PropTypes.any,
    programOptions: PropTypes.any,
    selectedProgram: PropTypes.any,
    yearOptions: PropTypes.any,
    selectedYear: PropTypes.any,
    onClickBackBtn: PropTypes.any,
    createLog: PropTypes.any,
    getAuthObj: PropTypes.any,
    fetchDashboardDataCallback: PropTypes.any,
    fetchClientDetailsCallback: PropTypes.any,
    onSelectProgramCallback: PropTypes.any,
    setSelectedReportingYearCallback: PropTypes.any,
    getDashboardDimensionsCallback: PropTypes.any,
    setCommentaryRequiredDataCallback: PropTypes.any,
    dbDimensionList: PropTypes.any,
    dimensionSubDimension: PropTypes.any,
    programID: PropTypes.any,
    dashboardCommentary: PropTypes.any,
    reportingYear: PropTypes.any,
    programFiscalYearRange: PropTypes.any,
    enterpriseID: PropTypes.any,
    isSuperAdmin: PropTypes.any,
    userProfiles: PropTypes.any,
    selectedClient: PropTypes.any,
    isInitialized: PropTypes.any,
    isStart: PropTypes.any,
    dashboardProgramDetails: PropTypes.any,
    onKnowMore: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      programID: null,
      isStart: false,
      startButton: null,
      selectedProgram: null,
      isKnowMore: false,
      dimensionSubDimension: [],
      boiProjectDetails: [],
      dbDimensionList: null,
      reportingYear: [],
      dashboardProgram: [],
    };
  }

  render() {
    return (
      <div>
        {this.props.isKnowMore ? (
          <DashboardDetailedView
            dbDimensionList={this.props.dbDimensionList} // main data - DashboardProjectDetails
            dimensionSubDimension={this.props.dimensionSubDimension} // main data - DashboardDimGraph
            programID={this.props.programID}
            dashboardCommentary={this.props.dashboardCommentary}
            reportingYear={this.props.reportingYear}
            programFiscalYearRange={this.props.programFiscalYearRange}
            // UserInformation
            //enterpriseID={this.props.enterpriseID} // default to "", Current User
            //isSuperAdmin={this.props.isSuperAdmin} // default to false, Set to false for non-SVM
            //userProfiles={this.props.userProfiles} // default to [], Set to empty array for non-SVM
            showBackButton={true} // default to false
            //showCommentary={true} // default to false
            noDataMessage={NO_DATA_MESSAGE}
            // Dropdown
            selectedClient={this.props.selectedClient}
            isInitialized={this.props.isInitialized}
            isStart={/* true */ this.props.isStart}
            isKnowMore={/* true */ this.props.isKnowMore}
            dashboardClientDetails={this.props.dashboardClientDetails}
            dashboardData={this.props.dashboardData}
            programOptions={this.props.programOptions}
            selectedProgram={this.props.selectedProgram}
            yearOptions={this.props.yearOptions}
            selectedYear={this.props.selectedYear}
            // Actions
            onClickBackBtn={this.props.onClickBackBtn}
            //createLog={this.props.createLog} // Logger function for SVM
            //enableLogger={true} // default to false
            getAuthObj={this.props.getAuthObj}
            // Callback
            fetchDashboardDataCallback={this.props.fetchDashboardDataCallback}
            fetchClientDetailsCallback={this.props.fetchClientDetailsCallback}
            onSelectProgramCallback={this.props.onSelectProgramCallback}
            setSelectedReportingYearCallback={
              this.props.setSelectedReportingYearCallback
            }
            getDashboardDimensionsCallback={
              this.props.getDashboardDimensionsCallback
            }
            setCommentaryRequiredDataCallback={
              this.props.setCommentaryRequiredDataCallback
            }
            //displayFilterLabel={true}
            selectAllOnFirstRender={this.props.selectAllOnFirstRender}
            updateSelectAllOnFirstRender={
              this.props.updateSelectAllOnFirstRender
            }
          />
        ) : (
          <SummaryWheelView
            dimensionSubDimension={this.props.dimensionSubDimension} // main data
            dashboardProgramDetails={this.props.dashboardProgramDetails}
            onKnowMore={this.props.onKnowMore}
            // isGlobal={false} - default to false when no value
            // isGlobalPreview={false} - default to false when no value
            //displayDownloadReport={true} // - default to false
            displayKnowMore={true} // - default to false
            // Dropdown
            selectedClient={this.props.selectedClient}
            isInitialized={this.props.isInitialized}
            isStart={/* true */ this.props.isStart}
            isKnowMore={this.props.isKnowMore}
            dashboardClientDetails={this.props.dashboardClientDetails}
            dashboardData={this.props.dashboardData}
            programOptions={this.props.programOptions}
            selectedProgram={this.props.selectedProgram}
            yearOptions={this.props.yearOptions}
            selectedYear={this.props.selectedYear}
            // Actions
            onClickBackBtn={this.props.onClickBackBtn}
            //createLog={this.props.createLog} // Logger function for SVM, Optional, not required if  enableLogger set to false or not declared
            //enableLogger={true} // default to false
            getAuthObj={this.props.getAuthObj}
            // Callback
            fetchDashboardDataCallback={this.props.fetchDashboardDataCallback}
            fetchClientDetailsCallback={this.props.fetchClientDetailsCallback}
            onSelectProgramCallback={this.props.onSelectProgramCallback}
            setSelectedReportingYearCallback={
              this.props.setSelectedReportingYearCallback
            }
            getDashboardDimensionsCallback={
              this.props.getDashboardDimensionsCallback
            }
            setCommentaryRequiredDataCallback={
              this.props.setCommentaryRequiredDataCallback
            }
            //displayFilterLabel={true}
            selectAllOnFirstRender={this.props.selectAllOnFirstRender}
            updateSelectAllOnFirstRender={
              this.props.updateSelectAllOnFirstRender
            }
            calledInFD={true}
          />
        )}
      </div>
    );
  }
}

export default SVM;
