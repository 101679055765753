export const INITIALISE_MIND_MAP = 'INITIALISE_MIND_MAP';
export const MINDMAP_CLICK = 'MINDMAP_CLICK';
export const SAVECLICK = 'SAVECLICK';

export function InitialiseState(data) {
  return {
    type: INITIALISE_MIND_MAP,
    payload: data,
  };
}

export function MindmapClick(data) {
  return {
    type: MINDMAP_CLICK,
    payload: data,
  };
}

export function SaveClick(data) {
  return {
    type: SAVECLICK,
    payload: data,
  };
}
