import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import {
  EditorState, convertToRaw, ContentState, CompositeDecorator, Modifier,
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import MentionEntryComponent from './mentionEntryComponent';
import mentionsStyles from './MentionsStyles.module.css';
import MentionComponent from './mentionComponent';

const TaggingUser = ({
  comment, suggestionsData, isEditMode, placeholderText, onClearComment, clearComment, saveEditComment, closeEditComment, taggedUserData, charLimit, ...props
}) => {
  const ref = useRef(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [taggedUsers, setTaggedUsers] = useState([]);
  
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: mentionsStyles,
      mentionPrefix: '@',
      mentionComponent: MentionComponent,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    const linkifyPlugin = createLinkifyPlugin();
    // eslint-disable-next-line no-shadow
    const plugins = [linkifyPlugin, mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);
  const Decorated = ({ children }) => <span className="other-user-bluetext">{children}</span>;

  const findWithRegex = (contentBlock, callback) => {
    const text = contentBlock.getText();
    taggedUserData && taggedUserData.map((tag) => `@${tag.Name}`).forEach((word) => {
      const matches = [...text.matchAll(word)];
      matches.forEach((match) => {
        callback(match.index, match.index + match[0].length);
      });
    });
  };

  const handleStrategy = (contentBlock, callback) => {
    findWithRegex(contentBlock, callback);
  };
  useEffect(() => {
    if (comment) {
      if (isEditMode) {
        const decorators = plugins.reduce((acc, p) => [...acc, ...p.decorators], []);
        const decorator = new CompositeDecorator([
          {
            strategy: handleStrategy,
            component: Decorated,
          }, ...decorators,
        ]);
        let newEditorState = EditorState.createWithContent(ContentState.createFromText(comment), decorator);
        newEditorState = EditorState.moveFocusToEnd(newEditorState);
        setEditorState(() => newEditorState);
      }
    }
  }, [comment, isEditMode]);
  useEffect(() => {
    setSuggestions(() => suggestionsData);
  }, [suggestionsData]);
  useEffect(() => {
    setTaggedUsers(() => taggedUserData);
  }, [taggedUserData]);
  useEffect(() => {
    const { onTaggedUserChange } = props;
    onTaggedUserChange(taggedUsers);
  }, [taggedUsers]);
  useEffect(() => {
    if (clearComment) {
      onClearComment();
    }
  }, [clearComment]);
  const onChange = useCallback((_editorState) => {
    const contentState = _editorState.getCurrentContent();
    const oldContent = editorState.getCurrentContent();
    const textLength = /\r?\n|\r/g.exec(contentState.getPlainText()) ? contentState.getPlainText().replace(/\r?\n|\r/g, ' ').length : contentState.getPlainText().length - 1;
    
    if ((contentState === oldContent) || (textLength <= (charLimit - 1))) {
      const { onCommentChange } = props;
      const rawData = convertToRaw(contentState);
      let enteredcommentText = '';
      rawData.blocks.forEach((word) => {
        enteredcommentText = rawData.blocks.length >= 2 ? `${enteredcommentText + word.text} ` : enteredcommentText + word.text;
      });
      if (enteredcommentText.length <= charLimit) {
        onCommentChange(enteredcommentText, taggedUsers);
        setEditorState(() => _editorState);
      }
    }
    if (textLength > (charLimit - 1)) {
      setEditorState(() => editorState);
    }
  }, [editorState, props, taggedUsers]);
  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);
  const onSearchChange = ({ value }) => {
    setSuggestions(() => suggestionsData.filter(
      (x) => x.name.split('.')[0].slice(0, value.length).toLowerCase().includes(value)
          || x.name.split('.')[0].slice(0, value.length).toUpperCase().includes(value)
          || x.name.split('.')[0].slice(0, value.length).includes(value)
          || (x.name.split('.')[1] && x.name.split('.')[1].slice(0, value.length).includes(value))
          || (x.name.split('.')[1] && x.name.split('.')[1].slice(0, value.length).toLowerCase().includes(value))
          || (x.name.split('.')[1] && x.name.split('.')[1].slice(0, value.length).toUpperCase().includes(value)),
    ));
  };

  const _handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

    if (currentContentLength  > charLimit - 1) {
      return 'handled';
    }
  }

  return (
    <div
      className="comments-editor"
      onClick={() => {
        ref.current.focus();
      }}
    >
      <Editor
        editorKey="editor"
        editorState={editorState}
        onChange={onChange}
        plugins={plugins}
        handleBeforeInput={_handleBeforeInput}
        placeholder={placeholderText || ''}
        ref={ref}
      />
      {isEditMode && (
      <div className="comments-controls">
        <span><i className="fal fa-times" onClick={closeEditComment} /></span>
        <span><i className="far fa-check" onClick={saveEditComment} /></span>
      </div>
      )}
      <div className="mention-entry-wrapper">
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={(item) => {
            setTaggedUsers(() => [...taggedUsers, { Name: item.name, Id: item.Id }]);
          }}
          entryComponent={MentionEntryComponent}
        />
      </div>
    </div>
  );
};
export default TaggingUser;
