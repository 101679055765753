export let WOtileReducers = (
  state = {
    tile: [],
  },
  action
) => {
  switch (action.type) {
    case "INITIALISEWO": {
      
      state = { ...state };
      state.tile= action.payload;
      
      state["tile"] = state["tile"].sort((a, b) =>
        a.SOCategorySeq !== b.SOCategorySeq
          ? a.SOCategorySeq > b.SOCategorySeq
            ? 1
            : -1
          : a.SOCategoryName.toLowerCase() > b.SOCategoryName.toLowerCase()
          ? 1
          : -1
      );
     
      state["tile"] = state["tile"].map((eachDetails) => {
        eachDetails.kpiData = eachDetails.kpiData.sort((a, b) =>
          a.MetricSeq > b.MetricSeq ? 1 : -1
        );
        return eachDetails;
      });
      
      state["tile"] = state["tile"].map((eachDetails) => {
        if(eachDetails.WOStepTools && eachDetails.WOStepTools.length){
          eachDetails.WOStepTools = eachDetails.WOStepTools.sort((a, b) =>
          a.ToolsMappingID > b.ToolsMappingID ? 1 : -1
        );
        }
      return eachDetails;
      });
      
      return state;
    }
    case "ADD_EDIT_TILE": {
      state = { ...state };
      state["tile"].filter(
        (data) => data.SOCategoryID == action.payload.SOCategoryID
      ).length > 0
        ? (state["tile"].filter(
            (data) => data.SOCategoryID == action.payload.SOCategoryID
          )[0] = action.payload)
        : state["tile"].push(action.payload);
      state["tile"].sort((a, b) =>
        a.SOCategorySeq !== b.SOCategorySeq
          ? a.SOCategorySeq > b.SOCategorySeq
            ? 1
            : -1
          : a.SOCategoryName.toLowerCase() > b.SOCategoryName.toLowerCase()
          ? 1
          : -1
      );

      return state;
    }
    case "Remove_TILE": {
      state = { ...state };
      state["tile"].filter((data) => 
        data.SOCategoryID === action.payload.SOCategoryID
      )[0].IsDeleted = true;

      return state;
    }
    case "Adding_Deleted_SubStep": {
      state = { ...state };
      state["tile"].filter((data) => 
        data.SOCategoryID === action.payload.SOCategoryID
      )[0].IsDeleted = false;
      state["tile"].filter((data) => 
        data.SOCategoryID === action.payload.SOCategoryID
      )[0].SOCategorySeq = action.payload.SOCategorySeq;

      return state;
    }
    case "ADD_EDIT_WO_METRIC": {
      state = { ...state };
      if (
        state["tile"]
          .filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0]
          .kpiData.filter(
            (data) => data.KPIMappingID == action.payload.kpiData.KPIMappingID
          ).length > 0
      ) {
        state["tile"].filter(
          (x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData = 
          state["tile"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.filter((data) => data.KPIMappingID != action.payload.kpiData.KPIMappingID);
      }
      state["tile"]
        .filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0]
        .kpiData.push(action.payload.kpiData);

      state["tile"]
        .filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0]
        .kpiData.sort((a, b) =>
          a.MetricSeq !== b.MetricSeq
            ? a.MetricSeq > b.MetricSeq
              ? 1
              : -1
            : a.MetricName.toLowerCase() > b.MetricName.toLowerCase()
            ? 1
            : -1
        );

      return state;
    }
    case "REMOVE_WO_METRIC": {
      state = { ...state };
      state["tile"].filter(
        (x) => x.SOCategoryID == action.payload.SOCategoryID
      )[0].kpiData = state["tile"]
        .filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0]
        .kpiData.filter(
          (data) => data.KPIMappingID != action.payload.kpiData.KPIMappingID
        );

      return state;
    }
    case "REMOVE_FT": {
      state = { ...state };
      state["tile"].filter(
        (data) => data.SOCategoryName === "Work Orchestration"
      )[0].SoCatDataAttr = state["tile"]
        .filter((data) => data.SOCategoryName === "Work Orchestration")[0]
        .SoCatDataAttr.filter((data) => data.AttributeName != action.payload);
      return state;
    }
    case "ADD_FT": {
      state = { ...state };
      state["tile"]
        .filter((data) => data.SOCategoryName === "Work Orchestration")[0]
        .SoCatDataAttr.push(action.payload);
      return state;
    }
    case "EDIT_WO_TOOLS":{
      state={...state}
      let SOCategoryID=action.payload.SOCategoryID
      let data=action.payload.wostepdata
      let currentStepTools =[...state["tile"].filter((x) => x.SOCategoryID === SOCategoryID)[0].WOStepTools];

      if(currentStepTools.length>0){
        let toolIndex=currentStepTools.findIndex(
          (x) => x.WOStepToolsID === data.WOStepToolsID
        );
       if(toolIndex>=0){///AVOID STATE MUTATION WHILE EDITING
        let Editdata =[...currentStepTools];
        Editdata[toolIndex]=data
        currentStepTools = Editdata;
        }
      }
      state["tile"].filter((x) => x.SOCategoryID ===SOCategoryID)[0].WOStepTools=[...currentStepTools]; 
      return state;
    }
    case "ADD_EDIT_WO_TOOLS": {
      state = { ...state }
      let SOCategoryID=action.payload.SOCategoryID
    let currentStepTools =[...state["tile"].filter((x) => x.SOCategoryID === SOCategoryID)[0].WOStepTools];
              
    action.payload.wostepdata.map((a)=>
    currentStepTools.push(a)
    );
    state["tile"].filter((x) => x.SOCategoryID ===SOCategoryID)[0].WOStepTools=[...currentStepTools];           
          return state;
    }
   
    case "REMOVE_WO_TOOLS": {
      state = { ...state };
      state["tile"].filter(
        (x) => x.SOCategoryID === action.payload.SOCategoryID
      )[0].WOStepTools = state["tile"]
        .filter((x) => x.SOCategoryID === action.payload.SOCategoryID)[0]
        .WOStepTools.filter((data) => {
          let temp = action.payload.WOStepTools;
          if (
            temp.WOStepToolsID !== null &&
            temp.WOStepToolsID !== undefined &&
            temp.WOStepToolsID !== ""
          ) {
            return data.WOStepToolsID !== temp.WOStepToolsID;
          }
          else{
            return data;
          } 
        });
      return {...state};
    }

    case "ADD_EDIT_WO_COMMENTS": {
      state = { ...state };
      state["tile"].filter(
        (x) => x.SOCategoryID == action.payload.FilterParams.SOCategoryID
      )[0].Comments = action.payload.Comments;
      return state;
    }

    case "UPDATE_ALL_TOOLS":{
      state = { ...state };
      let currentSteps;
      let AddEditTool = action.payload;
      let ApplicationName = AddEditTool.ApplicationName;
      currentSteps = state["tile"].filter((x)=>x.SOCategoryID !== 3 && x.WOStepTools.length > 0);
      currentSteps.map((step)=>{
          step.WOStepTools.map((tool)=>{
            if(tool.ApplicationName == ApplicationName){
              if(tool.ApplicationCategory != AddEditTool.ApplicationCategory || tool.ToolType != AddEditTool.ToolType || tool.ApplicationDescription != AddEditTool.ApplicationDescription ){
                tool.ApplicationCategory = AddEditTool.ApplicationCategory;
                tool.ToolType = AddEditTool.ToolType;
                tool.ApplicationDescription = AddEditTool.ApplicationDescription
              }
            }
          })
      });
      return state;
    }

    case "UPDATE_WOSTEP_SEQUENCE":{
      state = { ...state };
      let obj = state["tile"].find((x) => x.SOCategoryID == action.payload.SOCategoryID);
      if(obj)
      {
        obj.SOCategorySeq = action.payload.SOCategorySeq;
      }
      state["tile"].sort((a, b) =>
        a.SOCategorySeq !== b.SOCategorySeq
          ? a.SOCategorySeq > b.SOCategorySeq
            ? 1
            : -1
          : a.SOCategoryName.toLowerCase() > b.SOCategoryName.toLowerCase()
          ? 1
          : -1
      );
      
      return state;
    }

    case "UPDATE_WOSTEP_SEQUENCE":{
      state = { ...state };
      let obj = state["tile"].find((x) => x.SOCategoryID == action.payload.SOCategoryID);
      if(obj)
      {
        obj.SOCategorySeq = action.payload.SOCategorySeq;
      }
      state["tile"].sort((a, b) =>
        a.SOCategorySeq !== b.SOCategorySeq
          ? a.SOCategorySeq > b.SOCategorySeq
            ? 1
            : -1
          : a.SOCategoryName.toLowerCase() > b.SOCategoryName.toLowerCase()
          ? 1
          : -1
      );
      
      return state;
    }

    default:
      return state;
  }
};
