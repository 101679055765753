import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ContentHeader from './ContentHeader';
import BOContainer from './BO';
import HumanMachineTalent from './HumanMachine';
import WorkOrchestration from '../WorkOrchestration/WorkOrchestration_Common';
import DiverseDataContainer from './DiverseDataContainer';
import InsightsIntelligence from './InsightsIntelligence';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';

class DashboardCntContainer extends PureComponent {
  constructor() {
    super();
    this.state = {
      headerLabel: '',
      screen: 'Dashboard',
    };
  }

  componentDidMount() {
    const { compDetails } = this.props;
    this.getInIHeaderLabel(compDetails.component);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { compDetails } = this.props;
    if (!_.isNil(compDetails.component) && compDetails.component !== nextProps.compDetails.component) this.getInIHeaderLabel(nextProps.compDetails.component);
  }

    getInIHeaderLabel = (componentID) => {
      const { filterDetails, languageData } = this.props;
      if (!_.isNil(filterDetails) && filterDetails.process != null && parseInt(filterDetails.process, 10) > 0) {
        const filterProcess = filterDetails.process;
        let soCategory = 0;
        switch (componentID) {
          case 'BO':
            soCategory = 1;
            break;
          case 'HumanMachineTalent':
            soCategory = 2;
            break;
          case 'WorkOrchestration':
            soCategory = 3;
            break;
          case 'InsightsIntelligence':
            soCategory = 4;
            break;
          case 'DiverseData':
            soCategory = 5;
            break;
          default:
            break;
        }

        if (filterProcess !== 0 && soCategory !== 0) {
          let headerData = soCategory === 4 ? i18n.t('Key_Metrics&SLA+') : '';

          axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetHeaderLabelText`, {
            params: { offeringID: filterProcess, soCategoryID: soCategory, languageID: languageData.id },
          }).then((response) => {
            if (String(response.status) === '200') {
              if (response.data.length > 0) headerData = response.data[0].Value;
            }
            this.setState({ headerLabel: headerData });
          })
            .catch((error) => {
              trycatchAlertPopup(error);
            });
        }
      }
    }

    render() {
      const {
        compDetails,
        SODetails,
        filterDetails,
        isRelatorBlock,
        roledata,
        DashboardSubOfferingData,
        DasboardOfferingData,
      } = this.props;
      const {
        headerLabel,
        screen,
      } = this.state;
      return (
        <>
          <div className="row">
            {compDetails.title !== '' ? <ContentHeader DasboardOfferingData={DasboardOfferingData} DashboardSubOfferingData={DashboardSubOfferingData} titleDescription={compDetails} SODetails={SODetails} filterDetails={filterDetails} /> : null}
          </div>
          {compDetails.component === 'BO' ? <BOContainer headerLabel={headerLabel} isRelatorBlock={isRelatorBlock} SODetails={SODetails} filterDetails={filterDetails} roledata={roledata} /> : null}
          {compDetails.component === 'HumanMachineTalent' ? <HumanMachineTalent headerLabel={headerLabel} SODetails={SODetails} filterDetails={filterDetails} /> : null}
          {compDetails.component === 'WorkOrchestration' ? <WorkOrchestration screen={screen} headerLabel={headerLabel} SODetails={SODetails} filterDetails={filterDetails} /> : null}
          {compDetails.component === 'InsightsIntelligence' ? <InsightsIntelligence headerLabel={headerLabel} isRelatorBlock={isRelatorBlock} SODetails={SODetails} filterDetails={filterDetails} /> : null}
          {compDetails.component === 'DiverseData' ? <DiverseDataContainer headerLabel={headerLabel} SODetails={SODetails} filterDetails={filterDetails} /> : null}

        </>
      );
    }
}
DashboardCntContainer.propTypes = {
  compDetails: PropTypes.object.isRequired,
  filterDetails: PropTypes.object.isRequired,
  SODetails: PropTypes.array.isRequired,
  isRelatorBlock: PropTypes.bool.isRequired,
  roledata: PropTypes.array.isRequired,
  languageData: PropTypes.object.isRequired,
  DashboardSubOfferingData: PropTypes.array.isRequired,
  DasboardOfferingData: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  DashboardSubOfferingData: state.FilterReducer && state.FilterReducer.DashboardSubOfferingData ? state.FilterReducer.DashboardSubOfferingData : [],
  DasboardOfferingData: state.FilterReducer && state.FilterReducer.DasboardOfferingData ? state.FilterReducer.DasboardOfferingData : [],
});
export default connect(mapStateToProps)(DashboardCntContainer);
