import React from "react";
import { Modal } from "react-bootstrap";
import axiosInstance from "../Shared/interceptor";
import i18n from "i18next";
import _ from "lodash";
import { regexForTextAreas } from "../Shared/Constant";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class CategoryApprovalPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      errors: [],
      formIsValid: false,
      Approval: {
        ApprovalType: null,
        ApproverRemarks: "",
        CategoryId: "",
        ApprovalAction: false,
      },
      dealGlobalList: [],
    };

    this.onDealIsActiveChange = this.onDealIsActiveChange.bind(this);
    this.onGlobalIsActiveChange = this.onGlobalIsActiveChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    const { Approval } = this.state;
    this.setState(
      {
        Approval: {
          ...Approval,
          CategoryId: this.props.data.ID,
        },
      },
      () => {
        this.calculateGlobalDealCategories();
      }
    );
  }

  calculateGlobalDealCategories = () => {
    let data = this.props.allDataList;
    let toApproveList = data.filter(
      (a) =>
        a.OfferingID === this.props.data.OfferingID && String(a.CategoryType) === String(this.props.data.CategoryType)
    );

    //Calcualting Global Approved Category List
    let defaultGlobal = toApproveList.filter((a) => a.IsCustomCategory === false);
    let globalList = toApproveList.filter(
      (a) =>
        a.IsCustomCategory === true &&
        a.IsApproved === true &&
        a.IsRejected === false &&
        (a.ApprovalLevel == null || String(a.ApprovalLevel) === "Global")
    );
    let totalGlobalCount = defaultGlobal.length + globalList.length;

    // Taking All the DealIDs
    let dealIDList = toApproveList.filter(
      (a) => String(a.ApprovalLevel) === "Deal" && a.IsApproved === true && a.IsRejected === false
    );
    dealIDList = [...new Set(dealIDList.map((each) => each.DealID))];

    // Taking all the Global and Deal Count
    let dealGlobalList = [];
    dealGlobalList = dealIDList.map((each) => {
      let obj = {};
      obj.DealID = each;
      obj.GlobalCount = totalGlobalCount;
      obj.DealCount = toApproveList.filter(
        (a) =>
          a.DealID === each && a.IsApproved === true && a.IsRejected === false && String(a.ApprovalLevel) === "Deal"
      ).length;
      obj.TotalCount =
        totalGlobalCount +
        toApproveList.filter(
          (a) => a.DealID === each && a.IsApproved === true && a.IsRejected === false && a.ApprovalLevel === "Deal"
        ).length;
      return obj;
    });
    this.setState({
      dealGlobalList,
    });
  };

  validateForm() {
    const { Approval } = this.state;
    let errors = {};
    let formIsValid = true;

    if (Approval["ApprovalType"] === null && String(this.props.action) === "approve") {
      formIsValid = false;
      errors["ApprovalType"] = i18n.t("Choose one option");
    }
    if (Approval["ApproverRemarks"] == null) {
      formIsValid = false;
      errors["ApproverRemarks"] = i18n.t("Approver_Remarks_are_required");
    } else if (!Approval["ApproverRemarks"].trim()) {
      formIsValid = false;
      errors["ApproverRemarks"] = i18n.t("Approver_Remarks_are_required");
    } else {
      var isValid = regexForTextAreas.test(Approval["ApproverRemarks"]);
      if (!isValid) {
        formIsValid = false;
        errors["ApproverRemarks"] = i18n.t("Approver_Remarks_contains_Special_characters");
      }
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  onDealIsActiveChange(event) {
    const { Approval } = this.state;
    let errors = {};
    this.setState({
      errors: errors,
    });
    if (event.target.checked) {
      Approval.ApprovalType = "Deal";
      this.setState({
        Approval: Approval,
      });
    }
  }

  onGlobalIsActiveChange(event) {
    const { Approval } = this.state;
    let errors = {};
    this.setState({
      errors: errors,
    });
    if (event.target.checked) {
      Approval.ApprovalType = "Global";
      this.setState({
        Approval: Approval,
      });
    }
  }

  onRemarksChange(event) {
    const { Approval } = this.state;
    this.setState({ errors: {} });
    Approval.ApproverRemarks = event.target.value;
    this.setState({
      Approval: Approval,
    });
  }

  handleSave() {
    let { Approval } = this.state;
    if (this.validateForm()) {
      if (String(this.props.action) === "approve") {
        if (String(Approval.ApprovalType) === "Global") {
          //CHECKING IF THE CATEGORY NAME IS PRESENT IN ANY DEAL,IF SO IT IS NOT APPROVED AT DEAL LEVEL
          if(this.handleDuplicateCategoryNameGlobalApprove())
          {
            //CHECKING THE TOTAL COUNT OF CATEGORIES 
            if (this.handleGlobalApproval()) {
              Approval.ApprovalAction = true;
              this.setState({
                Approval: {
                  ApprovalAction: true,
                },
              });
              this.props.onSave(Approval);
              this.handleHide();
            } else {
              window.alert(`There are already ${this.props.categoriesLimit} approved categories for this offering`);
            }
          }
          else
          {
            window.alert(`Category Name already exists for another deal`);
          }
        }
        // Deal level Approval
        else {
          if (this.handleDealApproval()) {
            Approval.ApprovalAction = true;
            this.setState({
              Approval: {
                ApprovalAction: true,
              },
            });
            this.props.onSave(Approval);
            this.handleHide();
          } else {
            window.alert(`There are already ${this.props.categoriesLimit} approved categories for this offering`);
          }
        }
      } else {
        Approval.ApprovalAction = false;
        this.setState({
          Approval: {
            ApprovalType: "",
            ApprovalAction: false,
          },
        });
        this.props.onSave(Approval);
        this.handleHide();
      }
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }

  handleRejectHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }

  handleApproveHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }

  handleGlobalApproval = () => {
    //Checking if any of  the deals have >= 10 Categories,if true Global Level is not possible
    let status = false;
    let check = [...this.state.dealGlobalList].filter((each) => each.TotalCount >= this.props.categoriesLimit).length;
    if (!(check >= 1)) {
      status = true;
    }

    return status;
  };

  handleDealApproval = () => {
    //Checking whether the deal have >= 10 Categories,if true Deal Level is not possible
    let status = false;
    let found = [...this.state.dealGlobalList].filter((each) => each.DealID === this.props.data.DealID);
    if (found.length === 1) {
      if (found[0].TotalCount < this.props.categoriesLimit) {
        status = true;
      } else {
        status = false;
      }
    }
    if (found.length === 0) {
      status = true;
    }

    return status;
  };

  handleDuplicateCategoryNameGlobalApprove = ()=>
  {
    let { allDataList,data }  = this.props;

    //MODIFIYING DATA TO GET ONLY DATA RELATED TO OFFERING,CATEGORY TYPE AND CATEGORY NAME
    let toApproveList = allDataList.filter(
      (a) =>
        a.OfferingID === data.OfferingID && String(a.CategoryType) === String(data.CategoryType)
    );
    let modifiedList = toApproveList.filter((a) => a.IsCustomCategory === true);
    let checkData = modifiedList.filter((a)=> String(a.OfferingID) === String(data.OfferingID) && String(a.ID) !== String(data.ID) && String(a.CategoryType) === String(data.CategoryType) && 
    String(a.CategoryName) === String(data.CategoryName) && a.IsApproved === true
    )

    // CHECKING IF THERE IS CATEGORY NAME ALREADY EXIST AS DEAL APPROVAL IN ANOTHER DEAL
    let checkDealExist = checkData.filter(a=> String(a.ApprovalLevel) === "Deal").length===0?true:false
    return checkDealExist;
  }


  render() {
    const { action } = this.props;
    const { show, errors, Approval } = this.state;
    return (
      <>
        <Modal
          show={show}
          onHide={this.handleHide.bind(this)}
          className="modal fade"
          size="medium"
          backdrop="static"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            {String(action) === "approve" ? (
              <h4 className="modal-title" id="dealprofile" flag>
                Approval
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile" flag>
                Decline
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              {String(action) === "approve" ? (
                <div className="form-section">
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="foo"
                          value="bar2"
                          onChange={this.onDealIsActiveChange}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">Deal</span>
                      </label>
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="foo"
                          value="bar3"
                          onChange={this.onGlobalIsActiveChange}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">Global</span>
                      </label>
                    </div>
                  </div>
                  <span className="errorMsg">{errors.ApprovalType}</span>
                  <div className="form-group col-lg-6">
                    <label for="">
                      Approver Remarks<span className="required">*</span>
                    </label>
                    <textarea
                      maxLength="200"
                      className="form-control"
                      value={Approval.ApproverRemarks}
                      onChange={this.onRemarksChange.bind(this)}
                    />
                    <span className="errorMsg">{errors.ApproverRemarks}</span>
                  </div>
                </div>
              ) : (
                <div className="form-section">
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">
                        Approver Remarks<span className="required">*</span>
                      </label>
                      <textarea
                        maxLength="200"
                        className="form-control"
                        value={Approval.ApproverRemarks}
                        onChange={this.onRemarksChange.bind(this)}
                      />
                      <span className="errorMsg">{errors.ApproverRemarks}</span>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value="Save"
                />
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
