import React, { Component } from 'react';
import i18n from "i18next";
import { store } from "../reducers/configureStore";
import EnglishLiterals from "../EnglishLiterals.json";
import JapaneseLiterals from "../JapaneseLiterals.json";
import { LocalApiBaseUrl, PeoplePage, LocalApiBaseUrlImage } from '../Shared/Constant';
import { Link, NavLink } from 'react-router-dom';
import SOChartSection from './SOChartSection';
import ico_foundation from "../Images/ico-foundation.png"
import icon_automated from "../Images/icon-automated.png"
import ico_insights from "../Images/ico-insights.png"
import ico_intell from "../Images/ico-intell.png"
import ico_humanworkforce from "../Images/ico-humanworkforce.png"
import ico_work from "../Images/ico-work.png"
import ico_insightsandintelli from "../Images/ico-insightsandintelli.png"
import ico_diverse from "../Images/ico-diverse.png"
import accLogo from '../Images/acc-logo.png';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


const literals = {
    1: EnglishLiterals,
    2: JapaneseLiterals
  }
  let currentLanguageData = store.getState().CurrentFilter.languageData;
  let currentlangjson = literals[currentLanguageData.id];

export default class GOC extends Component {
    
    constructor() {
        super()
        this.state = {
            gocData: [],
            chartValue: "1",
            ProfileSrc: "",
            gocDetails: []
        }

        this.bindChart = this.bindChart.bind(this);
        this.getData = this.getData.bind(this);

    }

    bindChart(event) {
        let selectedChart = event.target.value;
        this.setState({ chartValue: selectedChart })
    }


    getData() {
        
        axiosInstance.get(`${LocalApiBaseUrl}Value/GetGocInfo`).then((response) => {
            this.setState({
                gocDetails: response.data
            })
        })
        .catch((error) => {
            trycatchAlertPopup(error);
          });
  
    }

    componentWillMount() {
    }

    GetProfilePic() {
      
            axiosInstance.post(`${LocalApiBaseUrl}User/DownloadPeopleImage`, null, {
                
            })
                .then((response) => {
                    let ProfileSrc = 'data:image/jpeg;base64,' + response.data;
                    this.setState({ ProfileSrc });
                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
        
    }

    componentDidMount() {
        
        this.GetProfilePic();
        this.getData();
        axiosInstance.get(`${LocalApiBaseUrl}Value/GetGOCData`)
            .then((response) => {

                this.setState({ gocData: response.data });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
    }

    render() {
        
        return (
            <>
            <nav class="navbar navbar-expand-lg fixed-top navbar-default navbar-light">
                <NavLink to="/clientselect" className="navbar-brand no-border">
                    <img src={LocalApiBaseUrlImage+"/SOImages/812161_SynOps_Gradient-Clover.jpg"} alt="Synops-logo" width="32" height="32" />
                    {/* <h1>SynOps Summary for GOC</h1> */}
                   <h1> <span>{i18n.t("SynOps_Summary_for_GOC")}</span></h1>


                </NavLink>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#topmenuDrop" aria-controls="topmenuDrop" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
                <div class="collapse navbar-collapse" id="topmenuDrop">
                    <ul class="navbar-nav ml-auto">
                    <li className="nav-item notify-item">
                        <Link to='' className="nav-link">
                            <span className="icon-notify"></span>
                        </Link>
                    </li>
                    <li className="nav-item logged-img">
                        <Link to={`${PeoplePage}${sessionStorage.getItem("EnterpriseID")}`} target="_blank" className="nav-link">
                            <span className="profileImg"><img src={this.state.ProfileSrc} alt="" width="24" height="24" /></span>
                        </Link>
                    </li>
                    </ul>
                </div>
            </nav>
                <div className="pageContainer">

                    <div className="contentCol pl-0">
                        <div className="contentHd">
                            <div className="BusOutcomes row">
                                <div className="col-lg-12">
                                    <h4 className="mb-4"> <span>{i18n.t("Intelligent_Operations_Journey")}</span></h4>

                                    <div className="clearfix">
                                        <Tabs
                                            defaultTab="one"
                                            onChange={(tabId) => {  }}
                                        >
                                            <TabList className="nav nav-tabs">
                                                <Tab tabFor="one" className="nav-item"><span>{i18n.t("Current_View")}</span></Tab>
                                                <Tab tabFor="two" className="nav-item"><span>{i18n.t("Year_end_View")}</span></Tab>

                                            </TabList>
                                            <TabPanel tabId="one" >
                                                <div id="currentview" className=" tab-pane active  ">
                                                            <div className="incidents  ml-0">
                                                                <div className="incidentsData">
                                                                    <div className="incidentsItems">
                                                                        <div className="itemsset" >
                                                                        <div className = "row" >
                                                                            <h3 className="col-lg-3"><span className="item-span"><img src={ico_foundation} /><span>{i18n.t("Foundation")}</span><b><span>{i18n.t("Stable")}</span></b></span><span className="item-number">{this.state.gocDetails.IOJ_Foundation != undefined ? this.state.gocDetails.IOJ_Foundation : null}<b><span>{i18n.t("Clients")}</span></b></span></h3>
                                                                            <h3 className="col-lg-3"><span className="item-span"><img src={icon_automated} /><span>{i18n.t("Automated")}</span><b><span>{i18n.t("Efficient")}</span></b></span><span className="item-number">{this.state.gocDetails.IOJ_Automated != undefined ? this.state.gocDetails.IOJ_Automated : null}<b><span>{i18n.t("Clients")}</span></b></span></h3>
                                                                            <h3 className="col-lg-3"><span className="item-span"><img src={ico_insights} /><span>{i18n.t("Insights")}</span><b><span>{i18n.t("Predictive")}</span></b></span><span className="item-number">{this.state.gocDetails.IOJ_Inslights != undefined ? this.state.gocDetails.IOJ_Inslights : null}<b><span>{i18n.t("Clients")}</span></b></span></h3>
                                                                            <h3 className="col-lg-3"><span className="item-span"><img src={ico_intell} /><span>{i18n.t("Intelligent")}</span><b><span>{i18n.t("Future-ready")}</span></b></span><span className="item-number">{this.state.gocDetails.IOJ_Intelligent != undefined ? this.state.gocDetails.IOJ_Intelligent : null}<b><span>{i18n.t("Clients")}</span></b></span></h3>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel tabId="two" >
                                                <div id="yearendview" className="tab-pane">
                                                            <div className="incidents  ml-0">
                                                                <div className="incidentsData">
                                                                    <div className="incidentsItems">
                                                                        <div className="itemsset" >
                                                                            <div className = "row" >
                                                                            <h3 className="col-lg-3"><span className="item-span"><img src={ico_foundation} /><span>{i18n.t("Foundation")}</span><b><span>{i18n.t("Stable")}</span></b></span><span className="item-number">{this.state.gocDetails.IOT_Foundation != undefined ? this.state.gocDetails.IOT_Foundation : null}<b><span>{i18n.t("Clients")}</span></b></span></h3>
                                                                            <h3 className="col-lg-3"><span className="item-span"><img src={icon_automated} /><span>{i18n.t("Automated")}</span><b><span>{i18n.t("Efficient")}</span></b></span><span className="item-number">{this.state.gocDetails.IOT_Automated != undefined ? this.state.gocDetails.IOT_Automated : null}<b><span>{i18n.t("Clients")}</span></b></span></h3>
                                                                            <h3 className="col-lg-3"><span className="item-span"><img src={ico_insights} /><span>{i18n.t("Insights")}</span><b><span>{i18n.t("Predictive")}</span></b></span><span className="item-number">{this.state.gocDetails.IOT_Inslights != undefined ? this.state.gocDetails.IOT_Inslights : null}<b><span>{i18n.t("Clients")}</span></b></span></h3>
                                                                            <h3 className="col-lg-3"><span className="item-span"><img src={ico_intell} /><span>{i18n.t("Intelligent")}</span><b><span>{i18n.t("Future-ready")}</span></b></span><span className="item-number">{this.state.gocDetails.IOT_Intelligent != undefined ? this.state.gocDetails.IOT_Intelligent : null}<b><span>{i18n.t("Clients")}</span></b></span></h3>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                </div>

                                            </TabPanel>

                                        </Tabs>



                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="contentHd">
                            <div className="BusOutcomes row">
                                <div className="col-lg-12">
                                    <h4> <span>{i18n.t("Business_Outcomes")}</span></h4>

                                    <div className="clearfix">
                                        <div className = "row">

                                        <div className="col-md-3 col-xs-12 BusOutcomesItems"><span>{i18n.t("Total_business_value_delivered")}</span>
                                            <h5>{this.state.gocDetails.BO_TotalBussinessValue != undefined ? this.state.gocDetails.BO_TotalBussinessValue : null}</h5>
                                        </div>
                                      
                                        <div className="col-md-3 col-xs-12 BusOutcomesItems"><span>{i18n.t("YTD_business_value_delivered")}</span>
                                            <h5>{this.state.gocDetails.BO_YTD != undefined ? this.state.gocDetails.BO_YTD : null}</h5>
                                        </div>
                                        <div className="col-md-3 col-xs-12 BusOutcomesItems"><span>{i18n.t("Delivered_projects")}</span>

                                            <h5>{this.state.gocDetails.BO_DeliveredProjects != undefined ? this.state.gocDetails.BO_DeliveredProjects : null}</h5>
                                        </div>
                                        <div className="col-md-3 col-xs-12 BusOutcomesItems"><span>{i18n.t("Active_projects")}</span>

                                            <h5>{this.state.gocDetails.BO_ActiveProjects != undefined ? this.state.gocDetails.BO_ActiveProjects : null}</h5>
                                        </div>
                                        <div className="col-md-3 col-xs-12 BusOutcomesItems"><span>{i18n.t("Clients")}</span>

                                            <h5>{this.state.gocDetails.BO_Clients != undefined ? this.state.gocDetails.BO_Clients : null}</h5>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="contentMain">
                            <div className="BusOutcomes row">
                                <div className="col-lg-12">

                            <Tabs
                                defaultTab="three"
                                onChange={(tabId) => {  }}
                            >
                                <TabList className="nav nav-tabs">
                                    <Tab tabFor="three" className="nav-item grey-tab"><span>{i18n.t("Value_Delivered")}</span></Tab>
                                    <Tab tabFor="four" className="nav-item grey-tab"><span>{i18n.t("SynOps_Applied_Now")}</span></Tab>
                                    <Tab tabFor="five" className="nav-item grey-tab"><span>{i18n.t("Talent_of_the_Future")}</span></Tab>
                                        </TabList>
                                        <TabPanel tabId="three" >
                                            <div className="box">
                                                <div className="mapBg p-15">
                                                    <div className="box-hd">
                                                        <div className="select-data2">
                                                            <div className="col-md-3 col-sm-6 col-xs-12">
                                                                <select ref="offering" name="" id="offerings" onChange={this.bindChart}>
                                                                        <option value="1">{i18n.t("Business_Outcomes_P_and_L")}</option>
                                                                        <option value="2">{i18n.t("Business_Value_NAV")}</option>
                                                                        <option value="3">{i18n.t("Business_Outcomes_by_SDO")}</option>
                                                                        <option value="4">{i18n.t("OG_P&L_$Mn")}</option>
                                                                        <option value="5">{i18n.t("BA_P_and_L")}</option>
                                                                        <option value="6">{i18n.t("Non_BA_P_and_L")}</option>
                                                                        <option value="7">{i18n.t("Offering-P&L_$_Mn")}</option>
                                                                        <option value="8">{i18n.t("YoY_Trend_P_and_L")}</option>
                                                                        <option value="9">{i18n.t("Intelligent_Operations_P_and_L")}</option>
                                    
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.gocData.length == 0 ? null : <SOChartSection propsGOCData={this.state.gocData} propsChartShow={this.state.chartValue} />}
                                                </div>
                                            </div>

                                </TabPanel>
                                <TabPanel tabId="four">
                                        <div id="menu1" className="tab-pane">
                                            <div className=" row mt-4">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="box-md">
                                                        <div className="box-md-hd"><img src={ico_humanworkforce} /><span>{i18n.t("MACHINE_TALENT")}</span>
</div>
                                                        <div className="box-md-data"><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#_of_Automations_Deployed")}</span></h5>
                                                            <h3>{this.state.gocDetails.HMT_AutomationsDeployed != undefined ? this.state.gocDetails.HMT_AutomationsDeployed : null}</h3>

                                                        </span></span><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#of_Foundation_BOTs")}</span></h5>

                                                            <h3>{this.state.gocDetails.HMT_FoundationBOTs != undefined ? this.state.gocDetails.HMT_FoundationBOTs : null}</h3>

                                                        </span></span></div>
                                                        <div className="box-md-data  "><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#of_RPA_BOTs")}</span></h5>

                                                            <h3>{this.state.gocDetails.HMT_RPABOTs != undefined ? this.state.gocDetails.HMT_RPABOTs : null}</h3>

                                                        </span></span><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#of_GPH_Users")}</span></h5>

                                                            <h3>{this.state.gocDetails.HMT_GPHUsers != undefined ? this.state.gocDetails.HMT_GPHUsers : null}</h3>

                                                        </span></span></div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6 col-lg-6">
                                                    <div className="box-md  ">
                                                        <div className="box-md-hd  "><img src={ico_work} /><span>{i18n.t("WORK_ORCHESTRATION")}</span></div>
                                                        <div className="box-md-data  "><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#of_ACN_Applications")}</span></h5>

                                                            <h3>{this.state.gocDetails.WO_ACNApplications != undefined ? this.state.gocDetails.WO_ACNApplications : null}</h3>

                                                        </span></span><span className="appliednowItem side-border side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#of_Tickets_Processed")}</span>
                                                            <span>{i18n.t("#(per month)")}</span>
                                                            </h5>

                                                            <h3>{this.state.gocDetails.WO_TicketsProcessed_Permonth != undefined ? this.state.gocDetails.WO_TicketsProcessed_Permonth : null}</h3>

                                                        </span></span></div>
                                                        <div className="box-md-data  "><span className="appliednowItem side-border side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#_of_Documents_Scanned")}</span></h5>

                                                            <h3>{this.state.gocDetails.WO_DocumentsScanned_Permonth != undefined ? this.state.gocDetails.WO_DocumentsScanned_Permonth : null}</h3>

                                                        </span></span><span className="appliednowItem side-border side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#of_Trxn_Processed_by_Automations")}</span>
                                                            <span>{i18n.t("#(per month)")}</span>
                                                            </h5>
                                                            <h3>{this.state.gocDetails.WO_TransactionsProcessedbyAutomations_Permonth != undefined ? this.state.gocDetails.WO_TransactionsProcessedbyAutomations_Permonth : null}</h3>

                                                        </span></span></div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="box-md">
                                                        <div className="box-md-hd"><img src={ico_insightsandintelli} /><span>{i18n.t("INSIGHTS_&_INTELLIGENCE")}</span></div>

                                                        <div className="box-md-data"><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#_of_AI_Advisor")}</span></h5>

                                                            <h3>{this.state.gocDetails.II_AIAdvisor != undefined ? this.state.gocDetails.II_AIAdvisor : null}</h3>

                                                        </span></span><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#_of_Analytics_Apps")}</span></h5>

                                                            <h3>{this.state.gocDetails.II_AnalyticsApps != undefined ? this.state.gocDetails.II_AnalyticsApps : null}</h3>

                                                        </span></span></div>
                                                        <div className="box-md-data  "><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#of_Clients_Covered")}</span></h5>

                                                            <h3>{this.state.gocDetails.II_ClientsCovered != undefined ? this.state.gocDetails.II_ClientsCovered : null}</h3>

                                                        </span></span><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("Business_Outcomes_Delivered")}</span></h5>

                                                            <h3>{this.state.gocDetails.II_BusinessOutcomesDelivered != undefined ? this.state.gocDetails.II_BusinessOutcomesDelivered : null}</h3>

                                                        </span></span></div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6 col-lg-6">
                                                    <div className="box-md  ">
                                                        <div className="box-md-hd"><img src={ico_diverse} /><span>{i18n.t("DIVERSE_DATA")}</span></div>

                                                        <div className="box-md-data"><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#of_Internal_Benchmarks_Developed")}</span></h5>

                                                            <h3>{this.state.gocDetails.DD_InternalBenchmarksDeveloped != undefined ? this.state.gocDetails.DD_InternalBenchmarksDeveloped : null}</h3>

                                                        </span></span><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#of_Data_Pointers_To_Train_AI_Engines")}</span></h5>

                                                            <h3>{this.state.gocDetails.DD_DataPointersToTrainAIEngines != undefined ? this.state.gocDetails.DD_DataPointersToTrainAIEngines : null}</h3>

                                                        </span></span></div>
                                                        <div className="box-md-data  "><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#of_External_Benchmarks_Tracked")}</span></h5>

                                                            <h3>{this.state.gocDetails.DD_ExternalBenchmarksTracked != undefined ? this.state.gocDetails.DD_ExternalBenchmarksTracked : null}</h3>

                                                        </span></span><span className="appliednowItem side-border"><span className="float-left">
                                                            <h5><span>{i18n.t("#of_experts_augmented_with_Applied_Intelligence")}</span></h5>

                                                            <h3>{this.state.gocDetails.DD_ExpertsAugmentedwithAppliedIntelligence != undefined ? this.state.gocDetails.DD_ExpertsAugmentedwithAppliedIntelligence : null}</h3>

                                                        </span></span></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                </TabPanel>
                                <TabPanel tabId="five">
                                        <div id="menu2" className="tab-pane">

                                            <div className="box talent">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs">
                                                            <div className="outcome-list border-rt">
                                                                <h5><span>{i18n.t("Training")}</span></h5>

                                                                <div className="outcome-table">
                                                                    <div className="outcome-row">
                                                                        <div className="outcome-divider lt-blue-clr">
                                                                            <p><span>{i18n.t("%of_R2TN_Completion")}</span></p>

                                                                            <span className="metric-digit">{this.state.gocDetails.TF_Training_R2TNCompletiond != undefined ? this.state.gocDetails.TF_Training_R2TNCompletiond : null}</span>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="outcome-row">
                                                                        <div className="outcome-divider lt-blue-clr">
                                                                            <p><span>{i18n.t("%of_Talent_of_the_Future_Trained")}</span></p>

                                                                            <span className="metric-digit">{this.state.gocDetails.TF_Training_TFTrained != undefined ? this.state.gocDetails.TF_Training_TFTrained : null}</span>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="outcome-row">
                                                                        <div className="outcome-divider lt-blue-clr">
                                                                            <p><span>{i18n.t("Training_Hours")}</span></p>

                                                                            <span className="metric-digit">{this.state.gocDetails.TF_Training_TrainingHours != undefined ? this.state.gocDetails.TF_Training_TrainingHours : null}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-8 col-xxs skill">
                                                            <div className="outcome-list">
                                                                {/* <h5>Niche Skills</h5> */}
                                                                <h5><span>{i18n.t("Niche_Skills")}</span></h5>

                                                                <div className="outcome-table">
                                                                    <div className="outcome-row">
                                                                        <div className="outcome-divider lt-blue-clr">
                                                                            <p><span>{i18n.t("#of_Analytics")}</span></p>

                                                                            <span className="metric-digit">{this.state.gocDetails.TF_NicheSkills_Analytics != undefined ? this.state.gocDetails.TF_NicheSkills_Analytics : null}</span>
                                                                        </div>
                                                                        <div className="outcome-divider lt-blue-clr">
                                                                            <p><span>{i18n.t("#of_Business_Excellence")}</span></p>

                                                                            <span className="metric-digit">{this.state.gocDetails.TF_NicheSkills_BusinessExcellence != undefined ? this.state.gocDetails.TF_NicheSkills_BusinessExcellence : null}</span>
                                                                        </div>
                                                                        <div className="outcome-divider lt-blue-clr">
                                                                            <p><span>{i18n.t("#of_Overall_Business_Advisors")}</span></p>

                                                                            <span className="metric-digit">{this.state.gocDetails.TF_NicheSkills_OverallBusinessAdvisors != undefined ? this.state.gocDetails.TF_NicheSkills_OverallBusinessAdvisors : null}</span>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="outcome-row">
                                                                        <div className="outcome-divider lt-blue-clr">
                                                                            <p><span>{i18n.t("#of_Artificial_Intelligence")}</span></p>

                                                                            <span className="metric-digit">{this.state.gocDetails.TF_NicheSkills_ArtificialIntelligence != undefined ? this.state.gocDetails.TF_NicheSkills_ArtificialIntelligence : null}</span>
                                                                        </div>
                                                                        <div className="outcome-divider lt-blue-clr">
                                                                            <p><span>{i18n.t("#of_Intelligent_Automation")}</span></p>

                                                                            <span className="metric-digit">{this.state.gocDetails.TF_NicheSkills_IntelligentAutomation != undefined ? this.state.gocDetails.TF_NicheSkills_IntelligentAutomation : null}</span>
                                                                        </div>
                                                                        <div className="outcome-divider lt-blue-clr">
                                                                            <p><span>{i18n.t("#of_Business_Advisor_for_F&A")}</span></p>

                                                                            <span className="metric-digit">{this.state.gocDetails.TF_NicheSkills_BusinessAdvisorforFA != undefined ? this.state.gocDetails.TF_NicheSkills_BusinessAdvisorforFA : null}</span>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="outcome-row">
                                                                        <div className="outcome-divider lt-blue-clr">
                                                                            <p><span>{i18n.t("#of_Industry_Certified_Resources")}</span></p>

                                                                            <span className="metric-digit">{this.state.gocDetails.TF_NicheSkills_IndustryCertifiedResources != undefined ? this.state.gocDetails.TF_NicheSkills_IndustryCertifiedResources : null}</span>
                                                                        </div>
                                                                        <div className="outcome-divider lt-blue-clr">
                                                                            <p><span>{i18n.t("#of_Tech_Masters")}</span></p>

                                                                            <span className="metric-digit">{this.state.gocDetails.TF_NicheSkills_TechMasters != undefined ? this.state.gocDetails.TF_NicheSkills_TechMasters : null}</span>
                                                                        </div>
                                                                        <div className="outcome-divider lt-blue-clr">
                                                                            <p><span>{i18n.t("#of_Business_Advisor_for_Procurement")}</span></p>

                                                                            <span className="metric-digit">{this.state.gocDetails.TF_NicheSkills_BusinessAdvisorforProcurement != undefined ? this.state.gocDetails.TF_NicheSkills_BusinessAdvisorforProcurement : null}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                </TabPanel>
                            </Tabs>

                            </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="footer-logo"><img src={accLogo} alt="" height="32" /><span className="copyright">&copy; {i18n.t("2019_Accenture.All_Rights_Reserved.")}</span></div>
                                <p className="copyright"><a href="">{i18n.t("Terms_of_Use")}</a><a href="">{i18n.t("Privacy_Statement")}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </>


        )
}
}
