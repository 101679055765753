import React, { Component } from 'react';
import { LocalApiBaseUrl } from '../Shared/Constant';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-blue.css';
import { store } from "../reducers/configureStore"
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import GOCList from './GOCList';
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class GOCContainer extends Component {
    constructor() {
        super()
        this.state = {

            roleData: [],
            userList: [], roleList: [], clientList: [], viewmore: false, rowSelected: [], featureData: [],
            isGOCClicked: true,
        }
        this.onClose = this.onClose.bind(this);
        this.onGOCClick = this.onGOCClick.bind(this);
        this.getRoleData = this.getRoleData.bind(this);
    }

    onClose(e) {

        this.setState({ viewmore: false });
    }

    getRoleData() {

        axiosInstance.get(`${LocalApiBaseUrl}User/GetUserFeature`, {
            params: {}
        })
            .then((response) => {

                store.dispatch({
                    type: 'INITIALISEROLE',

                    payload: response.data

                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });



        var data = store.getState();
        let roleData1 = data.roleReducers["roleReducers"];
        store.subscribe(() => {
            var data = store.getState();
            this.setState({ roleData: data.roleReducers["roleReducers"] })
            let featureDetails1 = data.roleReducers["roleReducers"];
            this.setState({ featureData: featureDetails1 })

        })
    }


    componentWillMount() {

        this.getRoleData();

    }

    componentDidMount() {

        this.getRoleData();

    }



    onGOCClick(e) {

        this.setState({
            isGOCClicked: true,
        });
    }

    render() {

        return (
            <div className="main-component">
                <div className="ag-theme-blue">
                    <div className="row">
                        <div className="col-lg-12 tab-list">
                            <ul className="nav nav-tabs">
                                {this.state.roleData.filter((data) => String(data.FeatureName) === "GOC Form" && data.Operations.includes("View")).length > 0 ? <li selection="[object Object]" className="nav-item"><a className="active" data-toggle="tab" onClick={this.onGOCClick}>GOC Form</a></li> : null}
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <GOCList filterDetails={this.props.filterDetails} isGOCClicked={this.state.isGOCClicked}></GOCList>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}