import React from 'react';
import { Modal } from 'react-bootstrap';
import {regexForTextAreas} from '../Shared/Constant';

export default class ClientRegionApprovalPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ClientRegion:{},
            errors: [],
            formIsValid: false,
        }
        this.onApproverRemarkChange = this.onApproverRemarkChange.bind(this);
        }

    componentWillMount() {
        const {data} = this.props;
        if (data) {
            this.setState({
                ClientRegion: data[0],
            })
        }
    }    

    onApproverRemarkChange(event) {
     const { ClientRegion } = this.state;
     ClientRegion.ApproverRemarks = event.target.value;
     this.setState({
         ...this.state,
         ClientRegion: ClientRegion,
         errors:{}
     });
  
    }
        
    handleSave(e) {
        const { ClientRegion } = this.state;
        if (this.validateForm()) {
            this.props.onSave(ClientRegion)
            this.props.onClose();
        }
    }


    validateForm() {
        const { ClientRegion } = this.state;
        let errors = {};
        let formIsValid = true;    
        if(ClientRegion["ApproverRemarks"] == null)
        {
            formIsValid = false;
            errors["ApproverRemarks"] = "Approver Remarks are required";
    
        }
        else if (!ClientRegion["ApproverRemarks"].trim()) {
            formIsValid = false;
            errors["ApproverRemarks"] = "Approver Remarks are required";

        }
        else {
            let isValid = regexForTextAreas.test(ClientRegion["ApproverRemarks"]);
            if (!isValid) {
                formIsValid = false;
                errors["ApproverRemarks"] = "Approver Remarks contains Special characters";
            }
        }
        if (formIsValid) {
            this.setState({
                formIsValid: true,
                errors: errors
            })
        }
        else {
            this.setState({
                formIsValid: false,
                errors: errors
            })
        }
        return formIsValid;

    }

    render() {
        const { show, type, data } = this.props;
        const { ClientRegion, errors } = this.state;
        return (
            <>
                {/* <Header /> */}
                <Modal show={show} onHide={()=>this.props.onClose()} class="modal fade" size="medium" backdrop="static" id="dealpopup">
                    <Modal.Header closeButton>
                     {String(type) === "Approve" ? <h4 className="modal-title" id="dealprofile" flag >Approval</h4>:
                     <h4 className="modal-title" id="dealprofile" flag >Decline</h4>}
                    </Modal.Header>
                    <Modal.Body>
                            <form>
                                <div className="form-section">
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label for="">Client Region Name<span className="required">*</span></label>
                                            <input type="text" className="form-control" id="name" readOnly  value={ClientRegion.ClientRegionName} />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label for="">Requestor Remarks</label>
                                            <textarea maxLength="200" readOnly className="form-control" id="remark"  value={ClientRegion.RequestorRemarks} />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label for="">Approver Remarks<span className="required">*</span></label>
                                            <textarea  maxLength="200" className="form-control" id="remark" onChange={this.onApproverRemarkChange} value={ClientRegion.ApproverRemarks} />
                                            <span className="errorMsg">{errors.ApproverRemarks}</span>
                                        </div>
                                       
                                    </div>

                                </div>


                            </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal-btn">
                                {!data ?
                                    <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Create" />
                                    : <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Update" />
                                }
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}