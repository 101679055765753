export let diverseMasterReducers = (state = {
    diverse: []
}, action) => {
    switch (action.type) {
        case "INITIALISEDIVERSE_MASTER": {
            state = { ...state }
            state["diverse"] = action.payload
            return state
        }
        case "ADD_DIVERSE_CATEGORY_MASTER": {
            
            state = { ...state }
            state["diverse"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID).length > 0 ?
                state["diverse"].filter((data) => data.SOCategoryID == action.payload.SOCategoryID)[0] = action.payload :
                state["diverse"].push(action.payload)
            return state
        }
        case "EDIT_DIVERSE_MASTER": {
            state = { ...state }

            let diverseEdit1 = state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData
            if (diverseEdit1.filter((x) => x.KPIMappingID == action.payload.KPIMappingID).length > 0) {

                let divIndex = diverseEdit1.filter((x) => x.KPIMappingID == action.payload.KPIMappingID)[0]

                var index = state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData.indexOf(divIndex)
                state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData[index] = action.payload
            }
            else {
                state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData.push(action.payload)
            }

            state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData.sort((a, b) => (a.MetricSeq > b.MetricSeq) ? 1 : -1)

            return state
        }

        case "ADD_DIVERSE_MASTER": {
            state = { ...state }
            if(state["diverse"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.filter((data) => data.KPIMappingID == action.payload.KPIMappingID).length > 0){
                state["diverse"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData = state["diverse"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.filter((data) => data.KPIMappingID != action.payload.KPIMappingID);
            }
                state["diverse"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.push(action.payload)
            state["diverse"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.sort((a, b) => (a.MetricSeq > b.MetricSeq) ? 1 : -1)
            return state
        }

        case "DELETE_DIVERSE_CATEGORY_MASTER": {
            state = { ...state }
            var a =state["diverse"].filter((x) => x.SOCategoryName !== action.payload);
            state["diverse"] =state["diverse"].filter((x) => x.SOCategoryName !== action.payload);
            return state
        }

        case "REMOVE_DIVERSE_MASTER": {
            
            state = { ...state }
            state["diverse"].filter((x) => x.SOCategoryID == action.payload.kpiDetails.SOCategoryID)[0].kpiData = state["diverse"].filter((x) => x.SOCategoryID == action.payload.kpiDetails.SOCategoryID)[0].kpiData.filter((data) => data.KPIMappingID != action.payload.kpiDetails.KPIMappingID)
            return state
        }

        case "REMOVE_DATA_MASTER": {
            

            state = { ...state }

            let attGroup = state["diverse"].filter((x) => x.ParentSOCategoryID == '')[0].SoCatDataAttr.filter((x) => x.AttributeID == action.payload.id && x.AttributeName == action.payload.name)[0]

            var index = state["diverse"].filter((x) => x.ParentSOCategoryID == '')[0].SoCatDataAttr.indexOf(attGroup)
            state["diverse"].filter((x) => x.ParentSOCategoryID == '')[0].SoCatDataAttr.splice(index, 1)


            return state

        }
        default: return state
    }
}
