import React, { Component } from "react";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import DealReport from "./DealReport";
import MonthlyView from "./Report2";
import DealUser from "./DealUser";
import UserLogin from "./UserLogin";
import DealSpecificReportView from "./DealSpecificReportView";
import TopUser from "./TopUsers";
import RegisteredUser from "./RegisteredUsers";
import ExtractConfiguratorData from "./ExtractConfiguratorData";
import QualityScorecard from "./QualityScorecard";
import FeatureReport from "./FeatureReport";
import IntegrationStatusReport from "./IntegrationStatusReport";
import { store } from "../reducers/configureStore";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { setUserFeatures} from "../Shared/Common"


axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class MasterContainer extends Component {
  componentDidMount() {
    let clientID =  JSON.parse(sessionStorage.getItem("clientSelection")) !== undefined && JSON.parse(sessionStorage.getItem("clientSelection")) !== null? JSON.parse(sessionStorage.getItem("clientSelection")).id:0
    setUserFeatures(clientID)
    store.subscribe(() => {
      let data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] }, () => {
        let featureDetails1 = this.state.roleData;

        let masterMenu = [];

        var test = featureDetails1.forEach((ele) => {
          if (ele.Operations != null && ele.Operations.count !== 0) {
            masterMenu.push(ele.FeatureName);
          }
        });

        let uniqueMenu = [];
        if (masterMenu != null) {
          uniqueMenu = masterMenu.filter(function(item, pos) {
            return masterMenu.indexOf(item) === pos;
          });
        }

        var filtered = uniqueMenu.filter(function(el) {
          return el != null;
        });

        let featureData = featureDetails1.map(function(el) {
          return el.FeatureName;
        });
        this.setState({ featureData: featureData });
      });
      //Create State Varaibles and use to provide rights
    });
  }



  render() {
    return (
      <>
        {
          <>
            {this.props.dealReportClicked ? (
              <DealReport filterDetails={this.props.filterDetails} />
            ) : null}
            {this.props.monthlyViewClicked ? (
              <MonthlyView filterDetails={this.props.filterDetails} />
            ) : null}
            {this.props.DealUserClicked ? (
              <DealUser filterDetails={this.props.filterDetails} />
            ) : null}
            {this.props.UserLoginClicked ? (
              <UserLogin filterDetails={this.props.filterDetails} />
            ) : null}
            {this.props.dealSpecificReportClicked ? (
              <DealSpecificReportView
                filterDetails={this.props.filterDetails}
              />
            ) : null}

            {this.props.TopUserClicked ? (
              <TopUser filterDetails={this.props.filterDetails} />
            ) : null}
            {this.props.FeatureReportClicked ? (
              <FeatureReport filterDetails={this.props.filterDetails} />
            ) : null}
            {this.props.RegisteredUserClicked ? (
              <RegisteredUser filterDetails={this.props.filterDetails} />
            ) : null}
            {this.props.ExtractConfiguratorClicked ? (
              <ExtractConfiguratorData
                filterDetails={this.props.filterDetails}
              />
            ) : null}
            {this.props.QualityScorecardClick ? (
              <QualityScorecard filterDetails={this.props.filterDetails} />
            ) : null}
            {this.props.IntegrationStatusReportClicked ? (
              <IntegrationStatusReport
                filterDetails={this.props.filterDetails}
              />
            ) : null}
          </>
        }
      </>
    );
  }
}
