/* eslint-disable */
import React, { useEffect } from 'react';
import '../CSS/singleChatComponent.css';
import chatImage from '../Images/chat.png';
import chatArrowDesign from '../Images/chat-arrow-design.svg';
import $ from 'jquery';

export const SingleChatComponent = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;

    script.onload = !(function (t, e, o, c, n, a) {
      var s = (window.nanorep = window.nanorep || {});
      (s = s[e] = s[e] || {}),
        (s.apiHost = a),
        (s.host = n),
        (s.path = c),
        (s.account = t),
        (s.protocol = "https:"),
        (s.on =
          s.on ||
          function () {
            (s._calls = s._calls || []), s._calls.push([].slice.call(arguments));
          });
      var p = s.protocol + "//" + n + c + o + "?account=" + t,
        l = document.createElement("script");
      (l.async = l.defer = !0),
        l.setAttribute("src", p),
        document.getElementsByTagName("head")[0].appendChild(l);
    })("accaho", "floatingWidget", "floating-widget.js", "/web/", "accaho.nanorep.co");
    
    $(document).ready(()=>{
        nanorep.floatingWidget.on({
          init: function () {
            this.setConfigId("2687478391");
            this.setKB("1439279781");
          },
        });
    })
    document.getElementById('single-chat-page').appendChild(script);
  }, []);

  return (
    <div id="single-chat-page" className="chat-parent">
      <div className="chat-purple-block" />
      <div className="chat-container">
        <div className='chat-image-wrapper'>
            <img src={chatImage} />
        </div>
        <div className="chat-info-container">
          <h2 className='chat-title'>Hi. I’m Athena,</h2>
          <p className='chat-description'>
            Your TfO Virtual Assistant. Select one of these top FAQs or ask me a question below. I'm
            here to help.
          </p>
          <div  className='chat-arrow-image-wrapper'>
            <img src={chatArrowDesign}/>
          </div>
        </div>
      </div>
    </div>
  );
};