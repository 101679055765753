import React from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import Button from 'react-bootstrap/Button';
import TaggingUser from './TaggingUser';

class UserReplies extends React.Component {
  constructor() {
    super();
    this.state = {
      replyComments: '',
      taggedUsers: [],
      taggedUserData: [],
    };
  }

    onCommentChange = (value) => {
      const { CommentText } = this.state;
      if (CommentText !== value && value.length <= 120) {
        this.setState({ replyComments: value });
      }
    }

    onTaggedUserChange = (users) => {
      this.setState({ taggedUsers: users });
    }

    onSaveComments = () => {
      const {
        onAddOrUpdateComment, commentData, userName, closeReplyForm, replyToUserName, suggestions,
      } = this.props;
      const { replyComments, taggedUsers } = this.state;
      let updatedComment = replyComments;
      let updatedTaggedUsers = [...taggedUsers];
      if (!replyComments.trim().startsWith(`@${replyToUserName}`) && replyToUserName !== userName) {
        updatedComment = `@${replyToUserName} ${replyComments}`;
        updatedTaggedUsers = [suggestions.filter((each) => each.Name === replyToUserName)[0], ...taggedUsers];
      }
      const commentsJson = {
        Comment: updatedComment,
        ParentId: commentData.CommentId,
        IsDeleted: false,
        IsEdited: false,
        IsRead: false,
        TaggedUsers: updatedTaggedUsers,
        Replies: [],
        UserName: userName,
      };
      if (replyComments.trim().length) {
        onAddOrUpdateComment(commentsJson);
        this.setState({
          replyComments: '',
          taggedUsers: [],
        });
        closeReplyForm();
      }
    }

    render() {
      const {
        replyToUserName, closeReplyForm, suggestions, commentedUser,charLimit,
      } = this.props;
      const { replyComments, taggedUserData } = this.state;
      return (
        <div className="usereplies">

          <TaggingUser
            onCommentChange={this.onCommentChange}
            onTaggedUserChange={this.onTaggedUserChange}
            comment=""
            charLimit={charLimit}
            isEditMode={false}
            taggedUserData={taggedUserData}
            placeholderText={`${'Replying to @'}${replyToUserName}`}
            suggestionsData={suggestions}
          />
          <div className="comments-reply-btns">
            <Button variant="default" onClick={() => closeReplyForm()} className="mr-1">Cancel</Button>
            <Button variant="primary" onClick={this.onSaveComments}>Reply</Button>
          </div>
        </div>
      );
    }
}

export default UserReplies;
