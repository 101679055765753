import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import LoadingOverlay from 'react-loading-overlay';
import { LocalApiBaseUrl, trycatchAlertPopup,idTokenstring } from '../Shared/Constant';
import UserPopUp from './userPopup';
import axiosInstance from '../Shared/interceptor';

import Spinner from '../Images/ajax-loader.gif';
import SynopsLoader from '../Shared/SynopsLoader';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;


export default class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecondaryApiLoading: false,
      isMainApiLoading: false,
      rowSelected: [],
      userList: [],
      roleList: [],
      clientList: [],
      offeringList: [],
      viewmore: false,
      columnDefs: [

        {
          headerName: 'Edit',
          suppressMovable: true,
          width: 80,
          cellRenderer: (params) => {
            const link = document.createElement('span');
            link.className = 'tbledit';
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener('click', (e) => {
              this.dealpopup(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
          suppressSizeToFit: true,
        },
        {
          headerName: 'User name', field: 'UserName', resizable: true, suppressMovable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'User Email', field: 'UserEmailAddress', resizable: true, suppressMovable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'Client Names', field: 'ClientNames', resizable: true, suppressMovable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'User Profile', field: 'UserProfile', resizable: true, suppressMovable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'Offering Names', field: 'OfferingNames', resizable: true, suppressMovable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'Is Admin',
          field: 'IsAdmin',
          resizable: false,
          suppressSizeToFit: true,
          suppressFilter: true,
          suppressMovable: true,
          cellRenderer: (params) => `<label class="form-check">
                        <input class="form-check-input" type="checkbox" disabled ${params.value ? 'checked' : ''}>
                        <span class="checkmark"></span></label>`,
        },
        {
          headerName: 'Is Guest',
          field: 'IsGuest',
          resizable: false,
          suppressSizeToFit: true,
          suppressFilter: true,
          suppressMovable: true,
          cellRenderer: (params) => `<label class="form-check">
                        <input class="form-check-input" type="checkbox" disabled ${params.value ? 'checked' : ''}>
                        <span class="checkmark"></span></label>`,
        },
        {
          headerName: 'Is Active',
          field: 'IsActive',
          resizable: false,
          suppressSizeToFit: true,
          suppressFilter: true,
          suppressMovable: true,
          cellRenderer: (params) => `<label class="form-check">
                        <input class="form-check-input" type="checkbox" disabled ${params.value ? 'checked' : ''}>
                        <span class="checkmark"></span></label>`,
        },
        {
          headerName: 'Is Capability',
          field: 'IsCapability',
          resizable: false,
          suppressSizeToFit: true,
          suppressFilter: true,
          suppressMovable: true,
          cellRenderer: (params) => `<label class="form-check">
                        <input class="form-check-input" type="checkbox" disabled ${params.value ? 'checked' : ''}>
                        <span class="checkmark"></span></label>`,
        },

      ],
      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: 'multiple',
        defaultColDef: {
          sortable: true,
        },

      },

    };
    this.onClose = this.onClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  dealpopup(e, data) {
    if (data != undefined) {
      // edit condition
      if (this.props.featureData.filter((data) => data.Submenu == 'Users' && data.Operations.includes('Edit')).length > 0) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data });
      } else window.alert('Access Denied !!');
    } else {
      // add condition
      if (this.props.featureData.filter((data) => data.Submenu == 'Users' && data.Operations.includes('Add')).length > 0) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data });
      } else window.alert('Access Denied !!');
    }
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  getUsers() {
    this.setState({ isMainApiLoading: true });
    axiosInstance.get(`${LocalApiBaseUrl}User/GetAllUsersDataList
        `, {

      params: {},
    })
      .then((response) => {
        this.setState({
          rowData: response.data,
          isMainApiLoading: false,
        });

        this.getRemainingData();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getRemainingData() {
    this.setState({ isSecondaryApiLoading: true });

    axiosInstance.get(`${LocalApiBaseUrl}User/GetUserAllMappings?IsAll=false&RowLength=50`, {

      params: {},
    })
      .then((response) => {
        const { rowData } = this.state;
        const j = 0;
        rowData.forEach((i) => {
          const k = response.data.find((id) => id.UserID == i.UserID);
          if (k != null) {
            i.ClientNames = k.ClientNames;
            i.UserProfile = k.UserProfile;
            i.OfferingNames = k.OfferingNames;
          }
        });
        this.setState({
          rowData,
        });
        this.state.gridOptions.api.setRowData(rowData);

        this.getFinalData();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getFinalData() {
    axiosInstance.get(`${LocalApiBaseUrl}User/GetUserAllMappings?IsAll=true&RowLength=50`, {

      params: {},
    })
      .then((response) => {
        const { rowData } = this.state;
        const j = 0;
        rowData.forEach((i) => {
          const k = response.data.find((id) => id.UserID == i.UserID);
          if (k != null) {
            i.ClientNames = k.ClientNames;
            i.UserProfile = k.UserProfile;
            i.OfferingNames = k.OfferingNames;
          }
        });
        this.setState({
          rowData,
          isSecondaryApiLoading: false,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentWillMount() {
    this.getUsers();

    if (this.props.featureData.filter((data) => data.Submenu == 'Users' && data.Operations.includes('Edit')).length == 0) {
      const columnDefs = this.state.columnDefs.filter((data) => data.headerName != 'Edit');
      this.setState({
        columnDefs,
      });
    }
  }

  handleSave(data) {
    axiosInstance.post(`${LocalApiBaseUrl}User/AddEditUserDetails`, data)
      .then((response) => {
        window.alert(response.data.StatusMessage);
        if(response.data.StatusMessage !== " The same UserEnterpiseID is already exist in Db. Please use some other UserEnterpiseID ")
        {
          this.handleAddEdit(response.data.UserDetails, data.UserID);
          this.state.gridOptions.api.setFilterModel(null);
          this.state.gridOptions.api.onFilterChanged();
        }
        // this.handleAddEdit(response.data.UserDetails, data.UserID);
        // this.state.gridOptions.api.setFilterModel(null);
        // this.state.gridOptions.api.onFilterChanged();
        // this.getUsers();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  handleAddEdit(data, ID) {
    let rowData1 = this.state.rowData;
    if (ID === 0) {
      rowData1 = [...rowData1, data];
      rowData1.sort((a, b) => ((a.UserName.toLowerCase() > b.UserName.toLowerCase()) ? 1 : -1));
    } else {
      rowData1 = rowData1.map((obj) => {
        if (obj.UserID == ID) {
          return data;
        }
        return obj;
      });
    }
    this.setState({
      rowData: rowData1,
    });
    this.state.gridOptions.api.setRowData(rowData1);
  }

  render() {
    return (
      <>
        <div className="tableFilter">
          <div className="form-section">
            <div className="adminform-row">
              <input type="text" className="form-control filter-search" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e); }} />
              {this.state.isSecondaryApiLoading && <img className="userlistloader" src={Spinner} alt="Loading..." width="20" height="20" />}
              {this.props.featureData.filter((data) => data.Submenu == 'Users' && data.Operations.includes('Add')).length > 0
                && (
                  <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.dealpopup(e); }}>
                    <span><i className="fal fa-plus-circle mr-1" /></span>
                    {' '}
                    Add User
                  </button>
                )}
            </div>
          </div>
        </div>
        {this.state.isMainApiLoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />}
        <div className="main-data">
          <div className="row">
            <div className="col-lg-12">
              <div className="ag-theme-balham ag-table">
                <AgGridReact
                  suppressMovable
                  enableSorting={false}
                  enableFilter
                  pagination
                  paginationPageSize={50}
                  floatingFilter
                  gridOptions={this.state.gridOptions}
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                  isApiLoaded={this.state.isLoaded}
                />
              </div>
            </div>
          </div>
          {this.state.viewmore ? <UserPopUp rowData={this.state.rowData} data={this.state.rowSelected} show={this.state.viewmore} onClose={this.onClose} filterDetails={this.props.filterDetails} onSave={this.handleSave} featureData={this.props.featureData} /> : null}
        </div>
      </>
    );
  }
}
