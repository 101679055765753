import React from "react";
import { Modal } from "react-bootstrap";
import { LocalApiBaseUrl, regexForTextAreas, regexForFields, regexForToolURL, ImageFormats,commonToolRegex  } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import { connect } from "react-redux";
import i18n from "i18next";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import { validateIsAllowedDomainFromURL, fetchAllowedDomains,isValidText,InvalidDescription } from '../Shared/commonFunctions';

function validateURLForWhitelisting(urlStr, allowedDomainsArray) {
  const isAllowed = validateIsAllowedDomainFromURL(urlStr, allowedDomainsArray);
  return isAllowed;
}

class ToolsPopUP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      specialCharmessage:InvalidDescription(),
      popupHeader: i18n.t("Add_Tool"),
      popupSaveButton: i18n.t("Create"),
      selectedfile:"",
      IconBase64:"",
      toolDetails: { id: 0, toolName: "", toolCategory: 0,toolCategoryName: "", synopsLevers: 0, url: "", moreInfo: "", description: "", isActive: true,isAAA:false, isFieldDisable: false, businessBenefit: "", icon: "", defaultUserID: "",fileName:"" , toolType: ""},
      toolErrors: { toolName: "", toolCategory: "", synopsLevers: "", url: "", moreInfo:"", description: "", businessBenefit: "", icon: "", defaultUserID: "", toolType: "" },
      toolLeversList: [],
      toolCategoryList: [], 
      showMoreInfo:false,
      showToolTypeList: false,
      isAPILoading: false,
      allowedDomainsArray: [],
    }
  }

  async componentDidMount() {
    this.setState({
      isAPILoading: true,
    });
    const allowedDomains = await fetchAllowedDomains();
    this.setState({
      allowedDomainsArray: allowedDomains,
      isAPILoading: false,
    });

    this.getToolMasterData();
    if (this.props.tooldata != undefined && this.props.tooldatatype == "Edit") {
      let data = this.props.tooldata;
      this.setState({
        popupHeader: i18n.t("Edit_Tool"),
        popupSaveButton: i18n.t("Update"),
        showToolTypeList:data.SOCategoryID==3?true:false,
        toolDetails: {
          id: data.ApplicationMappingID,
          toolName: data.ApplicationName,
          toolCategory: data.ApplicationCategoryID,
          synopsLevers: data.SOCategoryID,
          url: data.URL,
          moreInfo : data.MoreInfoURL,
          businessBenefit:data.BusinessBenefit,
          description: data.ApplicationDescription,
          icon:data.Icon,
          fileName: "",
          toolCategoryName : data.ApplicationCategory,
          defaultUserID: data.DefaultUserID,
          isActive: data.IsActive,
          isFieldDisable: true,
          toolType: data.Tooltype,
          isAAA:data.IsAAA != null ? data.IsAAA : false
        }
      });
      if(data.ApplicationCategoryID=="1"){
        this.setState({showMoreInfo:true})
      }else{
        this.setState({showMoreInfo:false})
      }
    }
    else {
      this.setState({
        popupHeader: i18n.t("Add_Tool"),
        popupSaveButton: i18n.t("Create"),
        toolDetails: {
          id: 0,
          toolName: "",
          toolCategory: 0,
          synopsLevers: 0,
          url: "",
          moreInfo:"",
          description: "",
          isActive: true,
          isFieldDisable: false,
          toolType: "",
          isAAA:false,
        }
      });
    }
  }

  getToolMasterData = () => {
    axiosInstance.get(`${LocalApiBaseUrl}ApplicationMapping/GetMasterDataForApplicationMapping`, {
      params: { languageID: this.props.languageData.id }
    })
      .then((response) => {
        const soCategoryList = response.data.SOCategoryList.map((dropValue, index) => {
          return <option key={dropValue.ID} selected="" value={dropValue.ID}>{dropValue.Value}</option>
        });

        const categoryList = response.data.CategoryList.map((dropValue, index) => {
          return <option key={dropValue.ID} selected="" value={dropValue.ID}>{dropValue.Value}</option>
        });

        this.setState({
          toolLeversList: soCategoryList, toolCategoryList: categoryList
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }

  onClose = () => {
    this.setState({ viewmore: false });
    this.props.onClose();
  }

  bindToolFieldChange = (e) => {
    const id = e.target.id;
    let data = this.state.toolDetails;
    switch (id) {
      case "txtToolName":
        data.toolName = e.target.value;
        break;
      case "ddToolCategrory":
        data.toolCategory = e.target.value;
        data.toolCategoryName = e.target.options[e.target.selectedIndex].text;
        if(e.target.value == "1"){
          this.setState({showMoreInfo:true})
        }
        else{
          this.setState({showMoreInfo:false})
        }
        break;
      case "ddSynopsLevers":
        data.synopsLevers = e.target.value;
        if (e.target.value == "3") { 	
          this.setState({ showToolTypeList: true }) 	
        }else{	
          this.setState({ showToolTypeList: false }) 	
        }
        break;
        
      case "txtToolUrl":
        data.url = e.target.value;
        break;
      case "txtToolMoreInfo":
        data.moreInfo=e.target.value;
        break;
      case "txtToolDescription":
        data.description = e.target.value;
        break;
      case "chkIsActive":
        data.isActive = e.target.checked;
        break;
        case "chkIsAAA":
        data.isAAA = e.target.checked;
        break;
      case "businessBenefit":
        data.businessBenefit = e.target.value;
        break;
      case "defaultUserID":
        data.defaultUserID = e.target.value;
        break;
        case "ddToolType":	
        data.toolType = e.target.value;
        break;

    }

    this.setState({
      toolDetails: data
    });
  }
  validateToolField = () => {
    const { allowedDomainsArray,specialCharmessage } = this.state;

    let isValid = false;
    let errorJSON = [];
    const data = this.state.toolDetails;

    if (data.toolName == undefined || data.toolName.trim() == "") {
      errorJSON[0] = i18n.t("ToolName_Required");
    }
    else {
      if (regexForFields.test(data.toolName.trim())) {
        errorJSON[0] = "";
        if(!isValidText(data.toolName.trim())) {
          errorJSON[0] = specialCharmessage;
        }
      }
      else {
        errorJSON[0] = i18n.t("ToolName_SpecialChar");
      }
    }

    if (data.toolCategory == undefined || parseInt(data.toolCategory) == 0) {
      errorJSON[1] = i18n.t("ToolCategory_Required");
    }
    else {
      errorJSON[1] = "";
    }

    if (data.synopsLevers == undefined || parseInt(data.synopsLevers) == 0) {
      errorJSON[2] = i18n.t("ToolSynopsLevers_Required");
    }
    else {
      errorJSON[2] = "";
    }

    if (data.description == undefined || data.description.trim() == "") {
      errorJSON[3] = i18n.t("ToolDescription_Required");
    }
    else {
      if (regexForFields.test(data.description.trim())) {
        errorJSON[3] = "";
        if(!isValidText(data.description.trim())) {
          errorJSON[3] = specialCharmessage;
        }
      }
      else {
        errorJSON[3] = i18n.t("ToolDescription_SpecialChar");
      }
    }
    if (regexForTextAreas.test(data.businessBenefit ? data.businessBenefit.trim() : "")) {
      errorJSON[5] = "";
      if(!isValidText(data.businessBenefit.trim())) {
        errorJSON[5] = specialCharmessage;
      }
    }
    else {
      if(!data.businessBenefit){
        errorJSON[5] = "";
      }else{
        errorJSON[5] =  i18n.t("BusinessBenefit_SpecialChar");
      }
    }
    if (regexForFields.test(data.defaultUserID ? data.defaultUserID.trim() : "")) {
      errorJSON[6] = "";
      if(!isValidText(data.defaultUserID.trim())) {
        errorJSON[6] = specialCharmessage;
      }
    }
    else {
      if(!data.defaultUserID){
        errorJSON[6] = "";
      }else{
        errorJSON[6] = i18n.t("defaultUserID_SpecialChar");
      }
     
    }
    if (data.fileName  && ImageFormats.includes(data.fileName ? data.fileName.split(".").pop() : "")) {
      if(parseFloat(this.state.selectedfile.size / 1024).toFixed(2) <= 10){
        errorJSON[7] = "";
    }else{
      errorJSON[7] = "Icon size should be less than 10KB";
        
    }
     
    }
    else {
      if(!data.fileName){
        errorJSON[7] = "";
      }else{
        errorJSON[7] = i18n.t("Icon_format_Error");
      }
      
    }
    if(data.url!=null){
    if(!commonToolRegex.test(data.url.trim().toLowerCase())){
      if (data.url != undefined && data.url.trim() != "" && !regexForToolURL.test(data.url.trim().toLowerCase())) {
        errorJSON[4] = i18n.t("Invalid_URL");
      } else {
        if (!validateURLForWhitelisting(data.url.trim(), allowedDomainsArray)) {
          errorJSON[4] = i18n.t('subdomain_or_domain_not_whitelisted');
        } else {
          errorJSON[4] = '';
        }
      }
    } else{
      errorJSON[4] =i18n.t("commonToolRegex_validatemessage");
    }
  }
  else{
    errorJSON[4] = '';
  }

  if(data.moreInfo!=null){
  if(!commonToolRegex.test(data.moreInfo.trim().toLowerCase())){
    if (data.moreInfo != undefined && data.moreInfo.trim() != "" && !regexForToolURL.test(data.moreInfo.trim().toLowerCase())) {
      errorJSON[9] = i18n.t("Invalid_URL");
    } else {
      if (!validateURLForWhitelisting(data.moreInfo.trim(), allowedDomainsArray)) {
        errorJSON[9] = i18n.t('subdomain_or_domain_not_whitelisted');
      } else {
        errorJSON[9] = '';
      }
    }
  }
  else{
    errorJSON[9] =i18n.t("commonToolRegex_validatemessage");
  }
}
else{
  errorJSON[9] = '';
}
      //code for toolType	
      if (this.state.showToolTypeList) {	
        if (data.toolType == undefined || data.toolType == "" ||  data.toolType == "0") {	
          errorJSON[8] = i18n.t("Tool Type is required");	
        }	
        else {	
          errorJSON[8] = "";	
        }	
      }else {	
        errorJSON[8] = "";	
      }
    this.setState({
      toolErrors: { toolName: errorJSON[0], toolCategory: errorJSON[1], synopsLevers: errorJSON[2], description: errorJSON[3], url: errorJSON[4], moreInfo: errorJSON[9], businessBenefit: errorJSON[5], defaultUserID: errorJSON[6],icon:errorJSON[7],toolType: errorJSON[8] }
    });

    if (errorJSON[0] == "" && errorJSON[1] == "" && errorJSON[2] == "" && errorJSON[3] == "" && errorJSON[4] == "" && errorJSON[5] == "" && errorJSON[6] === "" && errorJSON[7] === "" && errorJSON[8]=="" && errorJSON[9]=="")
      isValid = true;

    return isValid;
  }

  saveTool = () => {
    if (this.validateToolField()) {
      const tooldetails = this.state.toolDetails;
      const data = new FormData()
        if(tooldetails.fileName){
           
             data.append('icon', this.state.selectedfile, tooldetails.fileName)
        }
        else{
            if(tooldetails.icon){
                data.append('icon',null)  
                data.append('IconBase64', tooldetails.icon) 
            }else{
                data.append('icon', null)
                data.append('IconBase64',"") 
            }
            
        }
      data.append( "ApplicationMappingID", tooldetails.id);
      data.append( "OfferingID",  this.props.filterDetails.process);
      data.append( "ApplicationName", tooldetails.toolName.trim());
      data.append( "ApplicationCategory", tooldetails.toolCategoryName.trim());
      data.append( "ApplicationCategoryID", parseInt(tooldetails.toolCategory));
      data.append( "SOCategoryID", parseInt(tooldetails.synopsLevers));
      data.append( "URL", tooldetails.url!=null ? tooldetails.url.trim() : "");
      data.append( "MoreInfoURL", tooldetails.moreInfo!=null ? tooldetails.moreInfo.trim() : "");
      data.append( "ApplicationDescription", tooldetails.description!=null ? tooldetails.description.trim() : "");
      data.append( "IsActive", tooldetails.isActive);
      data.append("IsAAA",tooldetails.isAAA);
      data.append( "OrgEntityID",0);
      data.append( "businessBenefit", tooldetails.businessBenefit ? tooldetails.businessBenefit : "");
      data.append("defaultUserID", tooldetails.defaultUserID ? tooldetails.defaultUserID : "");
      data.append( "ClientID",this.props.clientData && this.props.clientData.lenght>0? this.props.clientData.id : 0);
      data.append( "IsMasterTool",true);
      data.append("ToolType", tooldetails.toolType);
       
      axiosInstance.post(`${LocalApiBaseUrl}ApplicationMapping/SaveApplicationMapping`, data).then((response) => {
        window.alert(response.data.StatusMessage);
        if (response.data.IsSuccess) {
          this.onClose();
        }
        else {
          this.setState({ showLoaderOnPage: false });
        }
      }).catch((error)=>{
trycatchAlertPopup(error);
        window.alert(this.ssErrMsg + error);
      });
    }
  }

  onIconSelect = (event) => {
    let data = this.state.toolDetails;
    let error = this.state.toolErrors;
    error.icon = "";
    data.icon = event.target.files[0];
    let fileName = event.target.value.split("\\").pop();
    data.fileName = fileName;
    this.setState({ asset: data,selectedfile: event.target.files[0],IconBase64:null,toolErrors:error});
}
  render() {
    const { isAPILoading } = this.state;
    return (
      <>
        <LoadingOverlay
          className="custom-loader"
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={isAPILoading}
        />

        {/* <Header /> */}
        <Modal show={this.state.show} className="modal fade" size="medium" id="dealpopup" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true" >
          <Modal.Header> {/*closeButton*/}
            <h4 className="modal-title">
              {this.state.popupHeader}
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">
                      {i18n.t("Tool_Name")} <span className="required">*</span>
                    </label>
                    <input id="txtToolName" type="text" maxlength="45" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.toolDetails.toolName} disabled={this.state.toolDetails.isFieldDisable}  />
                    <span className="errorMsg">
                      {this.state.toolErrors.toolName}
                    </span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">
                      {i18n.t("Tool_Category")} <span className="required">*</span>
                    </label>
                    <select id="ddToolCategrory" name="toolCategory" className="form-control" disabled={this.state.toolDetails.isFieldDisable} onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.toolDetails.toolCategory}>
                      <option value="0">{i18n.t("Select_Tool_Category")}</option>
                      {this.state.toolCategoryList}
                    </select>
                    <span className="errorMsg">
                      {this.state.toolErrors.toolCategory}
                    </span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">
                      {i18n.t("Synops_Levers")} <span className="required">*</span>
                    </label>
                    <select id="ddSynopsLevers" name="synopsLevers" className="form-control" disabled={this.state.toolDetails.isFieldDisable} onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.toolDetails.synopsLevers}>
                      <option value="0">{i18n.t("Select_Synops_Levers")}</option>
                      {this.state.toolLeversList}
                    </select>
                    <span className="errorMsg">
                      {this.state.toolErrors.synopsLevers}
                    </span>
                  </div>
                  {this.state.showToolTypeList &&
                    <div className="form-group col-lg-6">	
                      <label for="">	
                        {i18n.t("Tool_Type")} <span className="required">*</span>	
                      </label>	
                      <select id="ddToolType" name="tooltype" className="form-control" disabled={this.state.toolDetails.isFieldDisable} onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.toolDetails.toolType}>	
                        <option value="0">{i18n.t("Select_Tool_Type")}</option>	
                        <option value="AA">Advanced Analytics</option>	
                        <option value="AI">Artificial Intelligence</option>	
                        <option value="AT">Automation</option>	
                        <option value="WF">Workflow</option>	
                      </select>	
                      <span className="errorMsg">	
                        {this.state.toolErrors.toolType}	
                      </span>	
                    </div>	
                  }
                  <div className={'form-group col-lg-3 '+(this.state.showToolTypeList?'':'isActiveCSS')}>
                    <label class="form-check"><input type="checkbox" class="form-check-input" id="chkIsActive" checked={this.state.toolDetails.isActive} value={this.state.toolDetails.isActive} onChange={(e) => { this.bindToolFieldChange(e) }} />
                      <span class="checkmark"></span>
                      <span class="form-check-label">{i18n.t("Active")}</span>
                    </label>
                  </div>
                  <div className={'form-group col-lg-3 '+(this.state.showToolTypeList?'':'isActiveCSS')}>
                  <label class="form-check"><input type="checkbox" class="form-check-input" id="chkIsAAA" checked={this.state.toolDetails.isAAA} value={this.state.toolDetails.isAAA} onChange={(e) => { this.bindToolFieldChange(e) }} />
                      <span class="checkmark"></span>
                      <span class="form-check-label">{i18n.t("IsAAA")}</span>
                    </label>
                  </div>
                  <div className="form-group col-lg-12">
                    <label for="">
                      URL
                    </label>
                    <input id="txtToolUrl" type="url" maxlength="5000" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.toolDetails.url} />
                    <span className="errorMsg">
                      {this.state.toolErrors.url}
                    </span>
                  </div>
                  {this.state.showMoreInfo && (
                    <div className="form-group col-lg-12">
                      <label for="">
                        {i18n.t("More_Information")}
                      </label>
                      <input id="txtToolMoreInfo" type="moreInfo" maxlength="5000" placeholder="Tool Information URL" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.toolDetails.moreInfo} />
                      <span className="errorMsg">
                        {this.state.toolErrors.moreInfo}
                      </span>
                    </div>
                  )}
                  <div className="form-group col-lg-12">
                    <label for="">
                      {i18n.t("Description")} <span className="required">*</span>
                    </label>
                    <textarea id="txtToolDescription" rows="2" name="Description" maxLength="70" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.toolDetails.description} />
                    <span className="errorMsg">
                      {this.state.toolErrors.description}
                    </span>
                  </div>
                  <div className="form-group col-lg-12">
                    <label for="">
                      {i18n.t("Business_Benefit")}
                    </label>
                    <textarea id="businessBenefit" rows="2" name="Business_Benefit" maxLength="255" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.toolDetails.businessBenefit} />
                    <span className="errorMsg">
                      {this.state.toolErrors.businessBenefit}
                    </span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">
                      {i18n.t("Deafult_User_ID")}
                    </label>
                    <input id="defaultUserID" type="text" maxlength="100" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.toolDetails.defaultUserID} />
                    <span className="errorMsg">
                      {this.state.toolErrors.defaultUserID}
                    </span>
                  </div>
                     <div className="form-group custom-file-css col-md-6 mb-4">
                                        <p className={"mb-0"} style={{borderBottom:"0px",fontSize:"14px",paddingTop:"2px",fontWeight: "bold"}}>{i18n.t("Asset Icon")}:</p>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="customFile" accept="image/*" onChange={this.onIconSelect}  name="filename" />
                                                <label className="custom-file-label" for="customFile" >{this.state.toolDetails.fileName ? this.state.toolDetails.fileName : "Choose Icon"}</label>
                                                <span className="errorMsg">
                                                {this.state.toolErrors.icon}
                                            </span>
                                        </div>
                                        </div>
                                        {this.state.popupSaveButton !== "Create" &&  !this.state.toolDetails.fileName && this.state.toolDetails.icon ?
                                        <div className="form-group custom-file-css col-md-6 mt-4"> 
                                        <img src={"data:image/png;base64," + this.state.toolDetails.icon} width="30px" height="30px"></img>
                                        </div> : ""}
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                <input type="Button" className="btn btn-primary btn-sm" onClick={() => { this.saveTool() }} value={this.state.popupSaveButton} />
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    languageData: state.CurrentFilter.languageData,
    clientData: state.CurrentFilter.ClientData
  })
}
export default connect(mapStateToProps)(ToolsPopUP);