import React, { Component } from 'react';
import 'babel-polyfill';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LocalApiBaseUrl } from './Shared/Constant';
import axiosInstance from './Shared/interceptor';
import BaseContainer from './BaseContainer';
import IdleTimerContainer from './Authorization/IdleTimerContainer';
import {idTokenstring,idleTimedout} from './Shared/Constant';

class ParentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiStatus: false,
      userFeaturesApiStatus: false,
      languageData: {},
    };
  }


  componentDidMount() {
    axiosInstance
      .get(`${LocalApiBaseUrl}TranslatedLiterals/GetTranslatedTextByEnterpriseID`)
      .then((response) => {
        if (response.data) {
          const langId = response.data.LanguageMasterModel.LanguageID;
          const langCode = response.data.LanguageMasterModel.LanguageCode;
          const payload = {
            id: langId, langCode, messages: response.data.LanguageData, languageDropDown: [],
          };
          this.props.saveLanguageData(payload);
          this.setState({ apiStatus: true });
          const literals = {};
          literals[langCode] = { translation: response.data.LanguageData };
          i18n.use(initReactI18next).init({
            resources: literals,
            lng: langCode,
            fallbackLng: 'eng',

            interpolation: {
              escapeValue: false,
            },
          });
        }
      })
      .catch((err) => {
        const payload = {
          id: 1, langCode: 'eng', messages: {}, languageDropDown: [],
        };
        const literals = {};
        literals.eng = { translation: {} };
        i18n.use(initReactI18next).init({
          resources: literals,
          lng: 'eng',
          fallbackLng: 'eng',

          interpolation: {
            escapeValue: false,
          },
        });
        this.props.saveLanguageData(payload);
        this.setState({ apiStatus: true });
      });
    axiosInstance
      .get(`${LocalApiBaseUrl}User/GetUserFeature`, {
        params: {},
      })
      .then((response) => {
        this.props.saveUserFetauresData(response.data);
        this.setState({ userFeaturesApiStatus: true });
      })
      .catch((err) => {
        this.props.saveUserFetauresData([]);
        this.setState({ userFeaturesApiStatus: true });
      });
  }

  render() {
    return this.state.apiStatus && this.state.userFeaturesApiStatus ? (
      <Router>
        {sessionStorage.getItem('EnterpriseID') != undefined
        && sessionStorage.getItem('EnterpriseID') != null
        && sessionStorage.getItem('EnterpriseID') != '' ? (
          <>
          <IdleTimerContainer/>
          <BaseContainer userRole={this.props.userRole}/>
          </>
          ) : null}
      </Router>
    ) : (
      ''
    );
  }
}
const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
});
const mapDispatchToProps = (dispatch) => ({
  saveLanguageData: (data) => {
    dispatch({ type: 'LANGUAGE_DATA', payload: data });
  },
  saveUserFetauresData: (data) => {
    dispatch({ type: 'INITIALISEROLE', payload: data });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ParentContainer);
