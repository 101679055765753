import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import _, { parseInt } from 'lodash';
import i18n from 'i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../../Shared/Constant';
import axiosInstance from '../../Shared/interceptor';
import AdoptionMetricsModel from './AdoptionMetricsModel';
import { noop } from '../../Shared/helpers';

const AdoptionMetricsTab = ({
  initiativeId = null,
  adoptionFormValues = [],
  setAdoptionFormValues = noop,
  errors,
  CurrentFilter,
  languageData,
  handleChangeMonth = noop,
  months,
  selectedMonth,
  currentMonthYear,
  adoptionLink,
  handleChangeAdoptionLink,
  definition,
  handleDefinitionChange

}) => {
  
  const clientId = _.get(CurrentFilter, 'ClientData.id', ''); 
  const handleAdd = (i) => {
    if (adoptionFormValues.length >= 5) return;
    const index = i + 1;
    const monthyear = selectedMonth || currentMonthYear;
    setAdoptionFormValues([
      ...adoptionFormValues.slice(0, i),
      {
        ID: 0,
        CurrentUser: '',
        TargetUser: '',
        ConfigurationIntelOpsInitiativeID: 0,
        ConfIntelOpsAdoptionMetricMaster_ID: index,
        MonthYear: monthyear,
      },
      ...adoptionFormValues.slice(i + 1),
    ]);
  };

  const handleChange = (i, evnt) => {
    const { name, value } = evnt.target;

    setAdoptionFormValues([
      ...adoptionFormValues.slice(0, i),
      {
        ...adoptionFormValues[i],
        [name]: value,
      },
      ...adoptionFormValues.slice(i + 1),
    ]);
  };

  return (
    <>
      <div className="purpleborder-dropdown offeringlist-dropdownslist adoption-metrics-dateDropdown">
        <DropdownButton
          menuAlign="left"
          id="Monthsbutton"
          title={selectedMonth || currentMonthYear}
          className={classNames('mr-3', { selected: !_.isEmpty(selectedMonth) })}
        >
          <div className="offering-droplistwrapper">
            {months.map((eachMonth) => (
              <Dropdown.Item
                href="javascript:void(0)"
                className={classNames({
                  active: eachMonth === selectedMonth,
                })}
                onClick={() => handleChangeMonth(eachMonth)}
                onKeyDown={() => handleChangeMonth(eachMonth)}
              >

                <span
                  className="form-check-label"
                  role="button"
                  tabIndex={0}
                >
                  {eachMonth}
                </span>
              </Dropdown.Item>
            ))}
          </div>
        </DropdownButton>
        {/* END - MONTH DROPDOWN */}
      </div>
      <div className="initiative-formtabcontent">
        {errors &&  errors.adoptionLinkError && (
          <div className="errorMsg">{errors.adoptionLinkError}</div>
        )}

        <div className="leftinitiative-formfields Adoption-formfields">
          {adoptionFormValues.map((o, i) => (
            <AdoptionMetricsModel
              key={`adoption-metric-model-${i}`}
              i={i}
              o={o}
              handleChange={handleChange}
              handleAdd={handleAdd}
              handleChangeMonth={handleChangeMonth}
              months={months}
              selectedMonth={selectedMonth}
              adoptionFormValues={adoptionFormValues}
              currentMonthYear={currentMonthYear}
              adoptionLink={adoptionLink}
              handleChangeAdoptionLink={handleChangeAdoptionLink}
              definition={definition}
              handleDefinitionChange={handleDefinitionChange}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default AdoptionMetricsTab;
