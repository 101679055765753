//REACT IMPORTS
import React, { Component } from "react";
export default class LayoutType extends Component {
  //SETTING DEFAULT LAYOUT
  constructor(props) {
    super(props);
    this.state = {
      attributeCount: Number,
      IsDonutDisplay: true,
    };
  }

  componentWillMount() {
    this.selectLayout(3, true);
  }

  //SETTING LAYOUT BASED ON USER SELECTION
  selectLayout = (attributecount, isdonutdisplay) => {
    if (this.props.editActive) {
      let count = this.props.widgetEditData.AttributeCount;
      let isdonutDisplay = this.props.widgetEditData.IsDonutDisplay;
      this.setState({
        ...this.state,
        attributeCount: count,
        IsDonutDisplay: isdonutDisplay,
      });
    } else if (!this.props.editActive) {
      this.setState({
        ...this.state,
        attributeCount: attributecount,
        IsDonutDisplay: isdonutdisplay,
      });
      this.props.onLayoutChange(attributecount, isdonutdisplay);
    }
  };

  render() {
    let { widgetconfigData, editActive, widgetEditData } = this.props;
    return (
      <>
        <div
          disabled={editActive}
          style={
            editActive
              ? { pointerEvents: "none", opacity: "0.6", color: "#919eab" }
              : null
          }
          className="col-sm-3 ddrwidget-col ddrinsights-widget"
        >
          <h6>Select Widget Layout</h6>
          {widgetconfigData.map((layouttype, index) => (
            <div
              key={index}
              className={
                editActive
                  ? widgetEditData.Attributes.length ==
                      layouttype.AttributeCount &&
                    widgetEditData.IsDonutDisplay == layouttype.IsDonutDisplay
                    ? "outcome-divider gray-clr widget-activated"
                    : "outcome-divider gray-clr"
                  : this.state.attributeCount == layouttype.AttributeCount &&
                    this.state.IsDonutDisplay == layouttype.IsDonutDisplay
                  ? "outcome-divider gray-clr widget-activated"
                  : "outcome-divider gray-clr"
              }
            >
              {layouttype.IsDonutDisplay ? (
                <div
                  disabled={editActive}
                  className="dataTypeProcessed"
                  onClick={() =>
                    this.selectLayout(
                      layouttype.AttributeCount,
                      layouttype.IsDonutDisplay
                    )
                  }
                >
                  <div className="doughnutwidget-layout">
                    <div className="item doughnutwidget-insight">
                    <div class="item-left">
                    <div className="rd-progress p80">
                        <span>F2 <span className="rdmetric-digit-text">(F3)</span></span>
                        <div className="rd-slice">
                          <div className="rd-bar"></div>
                          <div class="rd-fill"></div>
                        </div> 
                      </div>
                    </div>
                    <div class="item-right"> <span className="doughnut-name">Field 1 (F1)</span></div>
                     
                     
                    </div>
                  </div>
                </div>
              ) : layouttype.AttributeCount == 2 ? (
                <div
                  disabled={editActive}
                  onClick={() =>
                    this.selectLayout(
                      layouttype.AttributeCount,
                      layouttype.IsDonutDisplay
                    )
                  }
                >
                  <div className="ddinsights-item twofield-insight source-fieldblock">
                    <div className="ddinsights-block">
                        <h6>Field 1 (F1)</h6>
                        <p>Field 2 (F2)</p>
                    </div>
                  </div>
                </div>
              ) : layouttype.AttributeCount == 3 ? (
                <div
                  disabled={editActive}
                  onClick={() =>
                    this.selectLayout(
                      layouttype.AttributeCount,
                      layouttype.IsDonutDisplay
                    )
                  }
                >
                  <div className="ddinsights-item source-fieldblock">
                    <div className="ddinsights-block">
                      <h6>Field 2 (F2)
                      <span>
                         F3
                        </span>
                      </h6>                      
                      <p>
                        F1                        
                        </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  disabled={editActive}
                  onClick={() =>
                    this.selectLayout(
                      layouttype.AttributeCount,
                      layouttype.IsDonutDisplay
                    )
                  }
                >
                  <div className="ddinsights-item threefield-insight">
                    <div className="ddinsights-block">
                      <div class="threeinsight-description">
                        <h6>F3 
                          <span>F4</span> 
                        </h6>
                        <p>Field 1 (F1)</p>
                      </div>
                      <div className="item-right">
                        <span>F2</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </>
    );
  }
}
