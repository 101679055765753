/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import $ from 'jquery';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import DiverseKpiContiner from './DiverseKpiContainer';
import axiosInstance from '../Shared/interceptor';

import '../CSS/DDstyle.css';
import References from '../Shared/References';

export default class DiverseDataContainer extends Component {
  constructor() {
    super();
    this.state = {
      metricRowDetails: [], // exploreLinkList: [], unused state
      exploreToolsList: [],
      exploreDrop: 'none',
    };
  }

  UNSAFE_componentWillMount() {
    const { filterDetails } = this.props;
    this.getDiverseData(filterDetails);
  }

  componentDidMount() {
    const { filterDetails } = this.props;
    this.getDiverseData(filterDetails);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { filterDetails } = this.props;
    if (filterDetails !== nextProps.filterDetails) {
      this.getDiverseData(nextProps.filterDetails);
    }
  }

  componentDidUpdate(prevProps) {
    const { isManintencneWindowOpen, RecordStatus, filterDetails } = this.props;
    $(document).on('click', '.explore_tools_root', (e) => {
      e.stopPropagation();
    });
    if (prevProps.isManintencneWindowOpen !== isManintencneWindowOpen || prevProps.RecordStatus !== RecordStatus) {
      this.getDiverseData(filterDetails);
    }
  }

  getDiverseData(offeringId) {
    const { isManintencneWindowOpen, RecordStatus, attributes } = this.props;
    const metricRowDetails = (
      <DiverseKpiContiner
        RecordStatus={RecordStatus}
        isManintencneWindowOpen={isManintencneWindowOpen}
        filterDetails={offeringId}
        attributes={attributes}
      />
    );

    this.setState({ metricRowDetails });
  }

  handleToggle = (index) => {
    const { filterDetails } = this.props;
    const { exploreToolsList } = this.state;
    const filterClient = filterDetails.Client;
    const filterProcess = filterDetails.process;
    const filterLocations = filterDetails.Locations;
    const SOCategoryID = 5;
    const appID = exploreToolsList[index].ApplicationMappingID;
    axiosInstance
      .get(`${LocalApiBaseUrl}ClickableTools/IncludeExcludeTool`, {
        params: {
          OrgEntityID: filterClient,
          OfferingID: filterProcess,
          LocationID: filterLocations,
          AppId: appID,
          SOCatId: SOCategoryID,
        },
      })
      .then((response) => {
        if (response.data.IsSuccess === true) {
          const data = exploreToolsList;
          data[index].IsEnable = !data[index].IsEnable;
          this.setState({
            exploreToolsList: data,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  viewMore() {
    const { filterDetails } = this.props;
    const { exploreDrop } = this.state;
    const filterClient = filterDetails.Client;
    const filterProcess = filterDetails.process;
    const filterLocations = filterDetails.Locations;
    const SOCategoryID = 5;

    const displayStatus = exploreDrop;
    if (displayStatus === 'none') {
      axiosInstance
        .get(`${LocalApiBaseUrl}ClickableTools/GetExploreTools`, {
          params: {
            OrgEntityID: filterClient,
            OfferingID: filterProcess,
            LocationID: filterLocations,
            SOCategoryID,
            IsAllTool: true,
          },
        })
        .then((res) => {
          this.setState({
            exploreToolsList: res.data,
            exploreDrop1: res.data.length === 0 ? i18n.t('Please add tools on Pulse Page') : '',
          });

          this.onClose();
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    } else {
      this.setState({ exploreDrop: 'none' });
    }
  }

  render() {
    /* eslint-disable no-nested-ternary */
    const { exploreToolsList, exploreDrop1, metricRowDetails } = this.state;
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="breadcrumb-title clearfix configurator-references-dropdown references-dropdown dashboard-references-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                data-toggle="dropdown"
                onClick={() => { this.viewMore(); }}
                role="button"
                tabIndex={0}
              >
                <span className="exploreTools">
                  {i18n.t('Explore_Tools_Advisor')}
                  <span>{i18n.t('Click on Explore Tools/Advisor to view Tools')}</span>
                </span>
                <span className="caret" />
              </a>

              <ul className="dropdown-menu explore_tools_root explore-tools-menu dropdown-menu-right custom-dropdown-menu">
                {exploreToolsList.length > 0
                  ? exploreToolsList.map((eachLinks, index) => (
                    <div className="explore_tools">
                      <a
                        className={
                            eachLinks.IsEnable
                              ? eachLinks.URL
                                ? 'dropdown-item'
                                : 'dropdown-item explore_tools_noCursor'
                              : 'dropdown-item disabled'
                          }
                        href={
                            eachLinks.URL
                              ? eachLinks.URL !== 'NA' && eachLinks.URL !== 'https://'
                                ? eachLinks.URL
                                : eachLinks.URL.substr
                              : 'javascript:void(0)'
                          }
                        target={eachLinks.URL && '_blank'}
                      >
                        {eachLinks.ApplicationName}
                      </a>
                      <div className="toggle-group toggle-tick">
                        <input
                          type="checkbox"
                          name={`datatypechange${index}`}
                          id={`datatypechange${index}`}
                          defaultChecked
                          onChange={() => {
                            this.handleToggle(index);
                          }}
                          checked={eachLinks.IsEnable}
                        />
                        <label htmlFor={`datatypechange${index}`}>Connection Status</label>
                        <div className="onoffswitch" aria-hidden="true">
                          <div className="onoffswitch-label">
                            <div className="onoffswitch-inner" />
                            <div className="onoffswitch-switch" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )) : (
                    <div>
                      <p>
                        {exploreDrop1}
                      </p>
                    </div>
                  )}
              </ul>
            </div>
            <div className="category-references dashboard-category-references">
              <References
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...this.props}
                SOCatID={5}
                leverName="Diverse Data"
                isConfig
                referenceScreen="DD"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-12">{metricRowDetails}</div>
      </div>
    );
  }
}
DiverseDataContainer.propTypes = {
  filterDetails: PropTypes.object.isRequired,
  isManintencneWindowOpen: PropTypes.bool.isRequired,
  RecordStatus: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired,
};