import React, { useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import { trycatchAlertPopup } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { LocalApiBaseUrl } from "../Shared/Constant";
import parse from 'react-html-parser';

function ClientStoryViewer(props) {

const { viewClientStoryModalStatus,viewClientStoryModalClose,clientData,currentFilter,
    storyviewdata,ClientStoryData}=props
let [url,setURL] = useState("");
let[CurrentIndex,setCurrentIndex]=useState(0);
let[currentViewerData,setcurrentViewerData]=useState({});
let[minimize,setminimize]=useState(false);
  
        
  const maximizeViewer = () => {
    document.getElementById("conMaximize").className = "fullScreen";
    setminimize(true);

  }
  const minimizeViewer = () => {
    document.getElementById("conMaximize").className = "";

    setminimize(false);
  }
const leftClientStory = () => {
    if (CurrentIndex != null) {
        if (CurrentIndex !== 0) {
          let pos = CurrentIndex - 1;
          let current = ClientStoryData[pos];
            setcurrentViewerData(current);
            setCurrentIndex(pos);
        }
      }
  };
  const rightClientStory = () => {
    if (CurrentIndex != null) {
      if (CurrentIndex !== ClientStoryData.length - 1) {
        let pos = CurrentIndex + 1;
        let current = ClientStoryData[pos];
          setcurrentViewerData(current);
          setCurrentIndex(pos);
      }
    }
  };
useEffect(() => {
  let parentId = '';
  let data = props.FilterData.filter((each) => each.OfferingID === props.currentFilter.OfferingId);
  parentId = data.length ? data[0].ParentID : "";
let version = props.storyviewdata.Version;
axiosInstance
  .get(`${LocalApiBaseUrl}IOJJourneyInitiative/GetPowerBIURL`, {
    params: { OrgEntityID:clientData.id,
      ParentID:parentId,
      ClientStoryID:storyviewdata.ID,
      Versions: version},
  })
  .then((response) => {
    setURL(response.data);
  })
  .catch((error) => {
    trycatchAlertPopup(error);
  });
  
let indexID=0;
    ClientStoryData.map((each, index) => {
        if (each.ID === storyviewdata.ID) {
          indexID = index;
        }
        return each;
      });

      setcurrentViewerData(storyviewdata);
      setCurrentIndex(indexID);
     
}, [])
let powerBiurl = url;
const Iframe =`<iframe id="conMaximize" width="100%" height="680" src="${powerBiurl}" frameborder="0" allowFullScreen=${true}></iframe>`;

    return (
        <div>
              <Modal
          show={viewClientStoryModalStatus}
          onHide={()=>viewClientStoryModalClose()}
          className="storytelling-modal"
        >
          <Modal.Header closeButton>
            <button type="button" className="btn btn-maximize-common mr-4" onClick={()=>maximizeViewer()}>
              {i18n.t("Maximize")}
              <i class="fas fa-compress-arrows-alt ml-1"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            {minimize ? (
              <div class="close-excel-common" onClick={()=>minimizeViewer()}>
                <i class="far fa-window-minimize"></i>
              </div>
            ) : (
              ""
            )}
            <div className="storytelling-modalbody">
              <ul>
                <li>{clientData.name}</li>
                <li>{currentFilter.OfferingName}</li>
              </ul>
              <h3>{currentViewerData.StoryName}</h3>
              <div className="storytelling-carousel">
              
              <div>{parse(Iframe)}</div>
              {
                    ClientStoryData.length >1 && <div className="casestudy-controllers">
                    <span className={CurrentIndex === 0 ?"left-controller" :"left-controller active"}  onClick={()=>leftClientStory()}>
                     
                    </span>
                    <span className={CurrentIndex === (ClientStoryData.length - 1) ? "right-controller" : "right-controller active"}  onClick={()=>rightClientStory()}>
                    
                    </span>
                  </div>
                }
               
              </div>
            </div>
          </Modal.Body>
        </Modal>  
        </div>
    )
}

export default ClientStoryViewer
