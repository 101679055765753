import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import LoadingOverlay from "react-loading-overlay";
import _ from "lodash";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGem, faCalendarWeek } from "@fortawesome/free-solid-svg-icons";
import SynopsLoader from "../Shared/SynopsLoader";
// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import { Currency } from "../Currency";
import { UINames } from "../SummaryViewUINames";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import OpenPowerBIReport from "../Shared/OpenPowerBI";
import ReactTooltip from "react-tooltip";
import TilesView from "./TilesView";
// IMPORT STYLES
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";

// IMPORT IMAGES
import GPHIcon from "../Images/assetsImages/gph.png";
import ROCIcon from "../Images/assetsImages/roc.png";
import ATCATIcon from "../Images/assetsImages/atcat.png";
import SQFIcon from "../Images/assetsImages/sqf.png";
import DFMIcon from "../Images/assetsImages/dfm.png";
import PMMIcon from "../Images/assetsImages/pmm.png";
import CashAppIcon from "../Images/assetsImages/caa.png";

import ACFIcon from "../Images/assetsImages/acf.png";
import SummaryViewDropdown from "./SummaryViewDropdown";
import MultiSelectDropDownComp from "./MultiSelectDropDownComp";
import SisenseGPHDashboard from '../Shared/SisenseGPHDashboard';


axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem(
  "msal.idtoken"
)}`;

//PLease add URL,image and Connector Name
const Assets = [
  {
    Name: "IWGPHDataLake",
    Url: "https://workforce.accenture.com/",
    Image: GPHIcon,
    Period: "(Last 7 days)",
    ShowNewUI: true,
  },
  {
    Name: "ACC",
    Url: "https://automationcc.accenture.com/",
    Image: ROCIcon,
    Period: "(Last 90 days)",
    ShowNewUI: true
  },
  {
    Name: "SQF",
    Url: "https://sqf.accenture.com/",
    Image: SQFIcon,
    Period: "(Last 90 days)",
    ShowNewUI: true
  },
  {
    Name: "ATCAT",
    Url: "https://paymentaccuracy-eme.accenture.com/SolarixWeb/default.aspx",
    Image: ATCATIcon,
  },
  {
    Name: "DFM",
    Url: "https://documentflowmanagereme.accenture.com/LOREAL_SSO/Default.aspx?page=HomePage.aspx",
    Image: DFMIcon,
  },
  {
    Name: "PMM",
    Url: "https://pmm.accenture.com/",
    Image: PMMIcon,
  },
  {
    Name: "CashApp",
    Url: "https://cashappadvisorus.accenture.com/",
    Image: CashAppIcon,
  },
  {
    Name: "ReconSmart",
    Url: "",
    Image: "",
    Period: "(Last 30 days)",
    ShowNewUI: true
  },

  {
    Name: "Digitracker",
    Url: "https://be-automation-portal.accenture.com/Digitization/GlobalDashboard/",
    Image: "",
  },

  {
    Name: "ACFDataLake",
    Url: "https://controlframework.accenture.com/",
    Image: ACFIcon,
  }
];


class AssetsSummaryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allLocation: {
        Location: "All",
        checked: true,
        filterApply: true,
      },
      allGroup:{
        filterApply: true,
      },
      flag: false,
      checkFlag: false,
      tempsource: "",
      PeriodDT: "",
      todayDate: "",
      startDate: "",
      endDate: "",
      countOfSubOffering: "",
      applyButtonDisable: false,
      collapsedElements: [],
      elementDetails: [],
      LocationElements: [],
      selectedAssest: "",
      filterApply: false,
      pmmFilterDetails: [],
      modalFullscreen: false,
      isLocationDropdownStatus: false,
      isAPILoading: false,
      ClientName: "",
      OfferingName: "",
      SubOfferingName: "",
      numberOfSubOfferings: 0,
      locations: [],
      AppliedLocations: [],
      filterAvailable: false,
      loginURLLink: "",
      assetImage: "",
      /* GROUPS STATE */
      groups: [],
      isGroupDropdownStatus: false,
      appliedGroups: [],
      allGroup: {
        Location: "All",
        checked: true,
        filterApply: true,
      },
      locationAPICall: false,
      groupAPICall: false,
      url:'',
      enableNewDashboardUI: true,
      toggleGPSStatsModal: false,
      newDashboardUIPeriodInfo: ''
    };
    this.wrapperRef = React.createRef(null);
    this.filteComp = React.createRef();
  }

  componentDidMount() {
    this.handleFetchClientNameAndOffering();
    this.handleFetchInitialDetails();
  }




  onLocationFetch = (selectedData) => {
    let data = this.state;
    let countChecked = data.locations.filter((loc) => loc.checked === true).length;
    if (String(selectedData) === "All") {
      let data = this.state;
      if (data.allLocation.checked === true) {
        let uncheckedLocations = data.locations.map((loc) => ({
          Location: loc.Location,
          LocationID: loc.LocationID,
          checked: false,
          type: "Location",
          label: loc.Location,
        }));
        let obj = {
          Location: "All",
          checked: false,
          filterApply: this.state.allLocation.filterApply,
        };
        this.setState({
          allLocation: obj,
          locations: uncheckedLocations,
          applyButtonDisable: true,
          locationAPICall: true,
        });
      } else {
        let checkedLocations = data.locations.map((loc) => ({
          Location: loc.Location,
          LocationID: loc.LocationID,
          checked: true,
          type: "Location",
          label: loc.Location,
        }));
        let obj = {
          Location: "All",
          checked: true,
          filterApply: this.state.allLocation.filterApply,
        };
        this.setState({
          allLocation: obj,
          locations: checkedLocations,
          applyButtonDisable: false,
          locationAPICall: true,
        });
      }
    } else {
      if (countChecked !== 5) {
        let obj = {
          Location: "All",
          checked: false,
          filterApply: this.state.allLocation.filterApply,
        };

        let checkedLocations = data.locations.map((loc) => {
          if (String(loc.Location) === String(selectedData)) {
            return {
              Location: loc.Location,
              LocationID: loc.LocationID,
              checked: !loc.checked,
              type: "Location",
              label: loc.Location,
            };
          } else {
            return {
              Location: loc.Location,
              LocationID: loc.LocationID,
              checked: loc.checked,
              type: "Location",
              label: loc.Location,
            };
          }
        });
        let currentCount = checkedLocations.filter((loc) => loc.checked === true);

        let disable = false;
        if (currentCount.length === 0 || currentCount.length > 5) {
          disable = true;
        }
        this.setState({
          allLocation: obj,
          locations: checkedLocations,
          applyButtonDisable: disable,
          locationAPICall: true,
        });
      }
      if (countChecked === 5) {
        let obj = {
          Location: "All",
          checked: false,
          filterApply: this.state.allLocation.filterApply,
        };

        let checkedLocations = data.locations.map((loc) => {
          if (String(loc.Location) === String(selectedData)) {
            return {
              Location: loc.Location,
              LocationID: loc.LocationID,
              checked: false,
              type: "Location",
              label: loc.Location,
            };
          } else {
            return {
              Location: loc.Location,
              LocationID: loc.LocationID,
              checked: loc.checked,
              type: "Location",
              label: loc.Location,
            };
          }
        });

        this.setState({
          allLocation: obj,
          locations: checkedLocations,
          applyButtonDisable: false,
          locationAPICall: true,
        });
      }
    }
  };



  onRegionFetch = (selectedData) => {
    let data = this.state;
    let countChecked = data.locations.filter((loc) => loc.checked === true).length;
    let selected = data.locations.filter((loc) => String(loc.Location) === String(selectedData));

    if (String(selectedData) === "All") {
      let data = this.state;
      if (data.allLocation.checked === true) {
        let uncheckedLocations = data.locations.map((loc) => ({
          Location: loc.Location,
          LocationID: loc.LocationID,
          checked: false,
        }));
        let obj = {
          Location: "All",
          checked: false,
          filterApply: this.state.allLocation.filterApply,
        };
        this.setState({
          allLocation: obj,
          locations: uncheckedLocations,
          applyButtonDisable: true,
          locationAPICall: true,
        });
      } else {
        let checkedLocations = data.locations.map((loc) => ({
          Location: loc.Location,
          LocationID: loc.LocationID,
          checked: true,
        }));
        let obj = {
          Location: "All",
          checked: true,
          filterApply: this.state.allLocation.filterApply,
        };
        this.setState({
          allLocation: obj,
          locations: checkedLocations,
          applyButtonDisable: false,
          locationAPICall: true,
        });
      }
    } else {
      /* if (countChecked !== 5) { */
      let obj = {
        Location: "All",
        checked: false,
        filterApply: this.state.allLocation.filterApply,
      };

      let checkedLocations = data.locations.map((loc) => {
        if (String(loc.Location) === String(selectedData)) {
          return {
            Location: loc.Location,
            LocationID: loc.LocationID,
            checked: !loc.checked,
          };
        } else {
          return {
            Location: loc.Location,
            LocationID: loc.LocationID,
            checked: loc.checked,
          };
        }
      });
      let currentCount = checkedLocations.filter((loc) => loc.checked === true);

      let disable = false;
      if (currentCount.length === 0) {
        disable = true;
      }

      this.setState({
        allLocation: obj,
        locations: checkedLocations,
        applyButtonDisable: disable,
        locationAPICall: true,
      });
    }
  };

  isLocationDropdownOverlay = () => {
    let data = this.state.AppliedLocations;
    let count = data.filter((loc) => loc.checked === true).length;
    let obj = {
      Location: "All",
      checked: false,
      filterApply: this.state.allLocation.filterApply,
    };
    if (count === data.length && this.state.allLocation.filterApply === true) {
      obj = {
        Location: "All",
        checked: true,
        filterApply: true,
      };
    }

    this.setState({
      isLocationDropdownStatus: false,
      locations: data,
      allLocation: obj,
      locationAPICall: false,
    });
  };

  isLocationDropdown = () => {
    this.setState({
      isLocationDropdownStatus: true,
      applyButtonDisable: false,
      locationAPICall: false,
    });
  };

  handleClear = () => {
    let data = this.state;
    let uncheckedLocations = data.locations.map((loc) => ({
      Location: loc.Location,
      LocationID: loc.LocationID,
      checked: false,
    }));
    let obj = {
      Location: "All",
      checked: false,
      filterApply: this.state.allLocation.filterApply,
    };

    this.setState({
      allLocation: obj,
      locations: uncheckedLocations,
      applyButtonDisable: true,
    });
  };

  handleApply = () => {
    const { locationAPICall } = this.state;
    if(locationAPICall) {
    this.setState({ isAPILoading: true, filterApply: true });
    const { locations, allLocation } = this.state;
    let LocationIDs = [];
    let LocationIDsNames = [];
    locations.map((loc) => {
      if (loc.checked) {
        LocationIDs.push(parseInt(loc.LocationID));
        let obj = {
          LocationName: loc.Location,
          LocationID: parseInt(loc.LocationID),
        };
        LocationIDsNames.push(obj);
      }
    });

    let selectedCount = locations.filter((loc) => loc.checked === true).length;
    let nonSelectedCount = locations.filter((loc) => loc.checked === false).length;
    if (
      (selectedCount === locations.length && allLocation.checked) ||
      (nonSelectedCount === locations.length && !allLocation.checked)
    ) {
      let obj = {
        Location: "All",
        checked: true,
        filterApply: true,
      };
      this.setState({
        isLocationDropdownStatus: false,
        filterApply: true,
        LocationElements: [],
        locations: locations.map((each) => ({ ...each, checked: true })),
        AppliedLocations: locations.map((each) => ({ ...each, checked: true })),
        allLocation: obj,
        isAPILoading: false,
        locationAPICall: false,
      });
    } else {
      let offeringId = store.getState().CurrentFilter.pulsePageData.offeringId;
      let cliendId = store.getState().CurrentFilter.ClientData.id;
      let data = {
        ToolName: this.state.selectedAssest,
        OrgEntityID: cliendId,
        OfferingID: offeringId,
        LocationIDSFilter: LocationIDs,
        LanguageID: parseInt(store.getState().CurrentFilter.languageData.id),
      };
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(`${LocalApiBaseUrl}PulseDashBoard/GetFilterSummaryView`, data)
          .then((response) => {
            let LocationElements = [];

            let temp = response.data;
            LocationIDsNames.map((loc) => {
              let obj = {
                LocationName: loc.LocationName,
                LocationID: loc.LocationID,
                elementDetails: [],
              };

              temp.map((element) => {
                if (String(element.LocationID) === String(loc.LocationID)) {
                  let obj2 = {
                    MetricName: element.MetricName,
                    Value: element.Value,
                    Unit: element.Unit,
                  };
                  obj.elementDetails.push(obj2);
                }
              });
              obj.elementDetails = obj.elementDetails.sort((a, b) =>
                a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1
              );
              obj.Groups = [{ groupName: "GP1" }, { groupName: "GP2" }, { groupName: "GP3" }];
              LocationElements.push(obj);
            });

            let locationElements = LocationElements;
            let selectedLocations = this.state.locations.filter((loc) => loc.checked === true);

            locationElements =
              selectedLocations.length !== 0
                ? locationElements.filter((data) => {
                    if (
                      selectedLocations.filter(
                        (loc) => String(loc.LocationID) === String(data.LocationID)
                      ).length !== 0
                    )
                      return data;
                  })
                : [];
            let obj = {
              Location: "All",
              checked: false,
              filterApply: false,
            };
            if (selectedLocations.length === 0) {
              obj = {
                Location: "All",
                checked: true,
                filterApply: true,
              };
            }

            this.setState(
              {
                isLocationDropdownStatus: false,
                filterApply: true,
                LocationElements: locationElements,
                AppliedLocations: this.state.locations,
                allLocation: obj,
                isAPILoading: false,
                locationAPICall: false,
              },
              () => {
                resolve();
              }
            );
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            reject();
          });
      });
    }
  }
  else
  {
    this.setState({
      isLocationDropdownStatus: false,
    })
  }
  };

  callBackOutsideClick = () =>{
    let { locations, groups,allLocation,locationAPICall, groupAPICall } = this.state;
    if(!allLocation.filterApply && !locations.every(a=>a.checked === false) && (locationAPICall || groupAPICall))
    {
      locations = locations.map(loc=>{
        if(groups.filter(grp=>grp.LocationName === loc.Location && grp.checked).length >0)
        {
          loc.checked = true;
        }
        else
        {
          loc.checked = false
        }
        return loc
      });


      this.setState({
        locations,
        locationAPICall:false,
        groupAPICall:false,
      },()=>{
        this.handleDropdownApply()
      })
    }
  }

  handleDropdownApply = () =>{
    
    let offeringId = store.getState().CurrentFilter.pulsePageData.offeringId;
    let cliendId = store.getState().CurrentFilter.ClientData.id;
    let { locations, groups,allLocation } = this.state;
    
      let resultList = []
      locations = locations.filter(a=>a.checked)
      locations.map((loc)=>{
        let obj ={}
        obj.locationName = loc.Location;
        obj.locationName_En = loc.Location;
        obj.locationId = 0;
        obj.GroupList=groups.filter((grp) => grp.LocationName === loc.Location && grp.checked).map((grp)=>({GroupName:grp.GroupName,GroupName_En:grp.GroupName_En}))
        resultList.push(obj);
      })
      
      allLocation.filterApply = false;
      this.setState({
        isAPILoading:true,
        allLocation
      })
      let data = {
        ToolName: this.state.selectedAssest,
        OrgEntityID: cliendId,
        OfferingID: offeringId,
        LocationIDSFilter: [],
        LanguageID: parseInt(store.getState().CurrentFilter.languageData.id),
        SelectedLocations:resultList,
        LocationID: 0,
      };
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(`${LocalApiBaseUrl}PulseDashBoard/GetFilterSummaryViewForIWGPH`, data).then((response)=>{
            this.setState({
              isAPILoading:false
            })
            let temp = response.data;
            let LocationElements = [];
            resultList.map((loc) => {
              let obj = {
                LocationName: loc.locationName,
                LocationID: loc.LocationID,
                elementDetails: [],
              };
  
              temp.map((element) => {
                if (String(element.LocationName) === String(loc.locationName)) {
                  let obj2 = {
                    MetricName: element.MetricName,
                    Value: element.Value,
                    Unit: element.Unit,
                    DataSource: element.DataSource
                  };
                  obj.elementDetails.push(obj2);
                }
              });
              obj.elementDetails = obj.elementDetails.sort((a, b) =>
                a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1
              );
             // obj.Groups = [{ groupName: "GP1" }, { groupName: "GP2" }, { groupName: "GP3" }];
             obj.Groups = loc.GroupList;
              LocationElements.push(obj);
            });
  
            let locationElements = LocationElements;
            this.setState({
              LocationElements,
            })
          })
    })
    
   
}


  handlePMMApply = () => {
    const { locationAPICall } = this.state;
    if(locationAPICall) {
    this.setState({ isAPILoading: true, filterApply: true,  });
    let LocationIDs = [];
    let LocationIDsNames = [];
    this.state.locations.map((loc) => {
      if (loc.checked) {
        LocationIDs.push(parseInt(loc.LocationID));
        let obj = {
          LocationName: loc.Location,
          LocationID: parseInt(loc.LocationID),
        };
        LocationIDsNames.push(obj);
      }
    });

    let selectedCount = this.state.locations.filter((loc) => loc.checked === true).length;
    if (selectedCount === this.state.locations.length && this.state.allLocation.checked === true) {
      let obj = {
        Location: "All",
        checked: true,
        filterApply: true,
      };
      this.setState({
        isLocationDropdownStatus: false,
        filterApply: true,
        pmmFilterDetails: [],
        AppliedLocations: this.state.locations,
        allLocation: obj,
        isAPILoading: false,
      });
    } else {
      let offeringId = store.getState().CurrentFilter.pulsePageData.offeringId;
      let cliendId = store.getState().CurrentFilter.ClientData.id;
      let data = {
        ToolName: this.state.selectedAssest,
        OrgEntityID: cliendId,
        OfferingID: offeringId,
        LocationIDSFilter: LocationIDs,
        LanguageID: parseInt(store.getState().CurrentFilter.languageData.id),
      };
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(`${LocalApiBaseUrl}PulseDashBoard/GetFilterSummaryViewPMM`, data)
          .then((response) => {
            let pmmFilterDetails = [];
            let responseData = response.data;
            let temp = [];
            LocationIDs.map((locId) => {
              if (responseData.filter((f) => String(f.LocationID) === String(locId)).length > 0) {
                let LocationData = responseData.filter(
                  (f) => String(f.LocationID) === String(locId)
                );
                let mainObjLoc = {
                  LocationName: "",
                  LocationID: null,
                  PMMDetails: [],
                };
                mainObjLoc.LocationName = LocationIDsNames.filter(
                  (ob) => String(ob.LocationID) === String(locId)
                )[0].LocationName;
                mainObjLoc.LocationID = locId;

                let TempData = LocationData;
                let ServiceComponents = [];
                TempData.map((obj) => {
                  ServiceComponents.push(obj.ServiceComponent);
                });
                ServiceComponents = [...new Set(ServiceComponents)];

                ServiceComponents.map((comp) => {
                  if (
                    TempData.filter((obj) => String(obj.ServiceComponent) === String(comp)).length >
                    0
                  ) {
                    let currentServiceComponent = TempData.filter(
                      (obj) => String(obj.ServiceComponent) === String(comp)
                    );
                    let metrics = currentServiceComponent.map((ob) => {
                      let metric = {
                        MetricName: "",
                        MetricName_En: "",
                        TargetScore: null,
                        CurrentScore: null,
                      };
                      metric.MetricName = ob.MetricName;
                      metric.MetricName_En = ob.MetricName_En;
                      metric.TargetScore = ob.TargetScore;
                      metric.CurrentScore = ob.CurrentScore;
                      return metric;
                    });

                    let mainObj = {
                      ServiceComponent: "",
                      ServiceComponent_En: "",
                      Metrics: [],
                    };
                    mainObj.ServiceComponent = comp;
                    mainObj.ServiceComponent_En = comp;
                    mainObj.Metrics = metrics;
                    mainObjLoc.PMMDetails.push(mainObj);
                  }
                });
                temp.push(mainObjLoc);
              }
            });

            temp = temp.map((data) => {
              data.PMMDetails.map((pmm) => {
                let obj = {
                  LocationName: data.LocationName,
                  LocationID: data.LocationID,
                  ServiceComponent: pmm.ServiceComponent,
                  Metrics: pmm.Metrics,
                };
                pmmFilterDetails.push(obj);
              });
            });

            // new sorting based on Region
            pmmFilterDetails = pmmFilterDetails.sort((a, b) =>
              a.ServiceComponent.toLowerCase() > b.ServiceComponent.toLowerCase() &&
              String(a.LocationName.toLowerCase()) === String(b.LocationName.toLowerCase())
                ? 1
                : -1
            );
            pmmFilterDetails = pmmFilterDetails.sort((a, b) =>
              a.ServiceComponent.toLowerCase() > b.ServiceComponent.toLowerCase() &&
              String(a.LocationName.toLowerCase()) === String(b.LocationName.toLowerCase())
                ? 1
                : -1
            );
            //two times sort for sorting based on Region

            let selectedLocations = this.state.locations.filter((loc) => loc.checked === true);

            let obj = {
              Location: "All",
              checked: false,
              filterApply: false,
            };
            if (selectedLocations.length === 0) {
              obj = {
                Location: "All",
                checked: true,
                filterApply: true,
              };
            }

            this.setState(
              {
                isLocationDropdownStatus: false,
                filterApply: true,
                allLocation: obj,
                AppliedLocations: this.state.locations,

                pmmFilterDetails,
                isAPILoading: false,
              },
              () => {
                resolve();
              }
            );
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            reject(error);
          });
      });
    }
  }
  else
  {
    this.setState({
      isLocationDropdownStatus: false,
    })
  }
  };

  powerbisummary = () => {
    const { selectedsummaryViewAsset } = this.props;
    const selectedSummaryViewAssetLowered = _.toLower(selectedsummaryViewAsset);
    let offeringId = store.getState().CurrentFilter.pulsePageData.offeringId;
    let cliendId = store.getState().CurrentFilter.ClientData.id;
    if (
      selectedSummaryViewAssetLowered === "iwgphdatalake" ||
      selectedSummaryViewAssetLowered.indexOf("(iwgphdatalake)") !== -1 ||
      selectedSummaryViewAssetLowered ===
        "Intelligent Workforce-Global Productivity Hub (IW-GPH)".toLowerCase()
        ||  selectedSummaryViewAssetLowered === "Quick Base".toLowerCase()

    ) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`${LocalApiBaseUrl}PulseDashBoard/GetPulsePowerBIData`, {
            params: {
              connectorName: selectedSummaryViewAssetLowered === "Quick Base".toLowerCase()?"QuickBase":"IWGPHDataLake",
              clientId: cliendId,
              OfferingID: offeringId,
              LanguageID: parseInt(store.getState().CurrentFilter.languageData.id),
            },
          })
          .then((response) => {
            this.setState(
              {
                tempsource: response.data,
                flag: true,
              },
              () => {
                resolve();
              }
            );
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            reject(error);
          });
      });
    } else if (
      selectedSummaryViewAssetLowered === "acc" ||
      selectedSummaryViewAssetLowered.indexOf("(acc)") !== -1 ||
      selectedSummaryViewAssetLowered === "Automation Command Center".toLowerCase()
    ) {
      {
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(`${LocalApiBaseUrl}PulseDashBoard/GetPulsePowerBIData`, {
              params: {
                connectorName: "ACC",
                clientId: cliendId,
                OfferingID: offeringId,
                LanguageID: parseInt(store.getState().CurrentFilter.languageData.id),
              },
            })
            .then((response) => {
              this.setState(
                {
                  tempsource: response.data,
                  flag: true,
                },
                () => {
                  resolve();
                }
              );
            })
            .catch((error) => {
              trycatchAlertPopup(error);
              reject(error);
            });
        });
      }
    } else if (
      selectedSummaryViewAssetLowered === "sqf" ||
      selectedSummaryViewAssetLowered.indexOf("(sqf)") !== -1 ||
      selectedSummaryViewAssetLowered === "Standard Quality Framework".toLowerCase()
    ) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`${LocalApiBaseUrl}PulseDashBoard/GetPulsePowerBIData`, {
            params: {
              connectorName: "SQF",
              clientId: cliendId,
              OfferingID: offeringId,
              LanguageID: parseInt(store.getState().CurrentFilter.languageData.id),
            },
          })
          .then((response) => {
            this.setState(
              {
                tempsource: response.data,
                flag: true,
              },
              () => {
                resolve();
              }
            );
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            reject(error);
          });
      });
    }
  };

  Close = () => {
    this.setState({ flag: false });
  };

  handleFetchClientNameAndOffering = () => {
    let data = store.getState();
    let clientName = data.CurrentFilter.ClientData.name;
    let offeringName = data.CurrentFilter.pulsePageData.Offering;
    let SubOfferingName = data.CurrentFilter.pulsePageData.SubOffering;
    let currentOffSuboff = data.CurrentFilter.pulsePageData;
    let currentOff = currentOffSuboff.Offering;
    let numberOfChildOfferings = data.CurrentFilter.PulseData.offerings.filter(
      (data) => String(data.OfferingName.toLowerCase()) === String(currentOff.toLowerCase())
    )[0].ChildOfferings.length;

    this.setState({
      ClientName: clientName,
      OfferingName: offeringName,
      SubOfferingName,
      numberOfSubOfferings: numberOfChildOfferings,
    });
  };

  handleFetchInitialDetails = () => {
    const { selectedsummaryViewAsset, selectedsummaryViewAssetURL, OpriaURL, Period } = this.props;
    let data = this.state;
    const selectedSummaryViewAssetLowered = _.toLower(selectedsummaryViewAsset);

    if (
      selectedSummaryViewAssetLowered === "iwgphdatalake" ||
      selectedSummaryViewAssetLowered.indexOf("(iwgphdatalake)") !== -1 ||
      selectedSummaryViewAssetLowered ===
        "Intelligent Workforce-Global Productivity Hub (IW-GPH)".toLowerCase()
    ) {
      data.selectedAssest = "IWGPHDataLake";
      this.setState({
        checkFlag: true,
      });
    } else if (
      selectedSummaryViewAssetLowered === "acc" ||
      selectedSummaryViewAssetLowered.indexOf("(acc)") !== -1 ||
      selectedSummaryViewAssetLowered === "Automation Command Center".toLowerCase()
    ) {
      data.selectedAssest = "ACC";
      this.setState({
        checkFlag: true,
      });
    } else if (
      selectedSummaryViewAssetLowered === "atcat" ||
      selectedSummaryViewAssetLowered.indexOf("(atcat)") !== -1 ||
      selectedSummaryViewAssetLowered === "ACN Transaction Compliance & Analytics".toLowerCase()
    ) {
      data.selectedAssest = "ATCAT";
    } else if (
      selectedSummaryViewAssetLowered === "sqf" ||
      selectedSummaryViewAssetLowered.indexOf("(sqf)") !== -1 ||
      selectedSummaryViewAssetLowered === "Standard Quality Framework".toLowerCase()
    ) {
      data.selectedAssest = "SQF";
      this.setState({
        checkFlag: true,
      });
    } else if (
      selectedSummaryViewAssetLowered === "dfm" ||
      selectedSummaryViewAssetLowered.indexOf("(dfm)") !== -1 ||
      selectedSummaryViewAssetLowered === "Document Flow Manager".toLowerCase()
    ) {
      data.selectedAssest = "DFM";
    } else if (
      selectedSummaryViewAssetLowered === "pmm" ||
      selectedSummaryViewAssetLowered.indexOf("(pmm)") !== -1 ||
      selectedSummaryViewAssetLowered === "Process Maturity Model".toLowerCase()
    ) {
      data.selectedAssest = "PMM";
    } else if (
      selectedSummaryViewAssetLowered === "caa" ||
      selectedSummaryViewAssetLowered.indexOf("(caa)") !== -1 ||
      selectedSummaryViewAssetLowered === "Cash Application Advisor".toLowerCase()
    ) {
      data.selectedAssest = "CashApp";
    } else if (
      selectedSummaryViewAssetLowered.includes("reconsmart") ||
      selectedSummaryViewAssetLowered === "Recon Smart".toLowerCase()
    ) {
      data.selectedAssest = "ReconSmart";
    } else if (
      selectedSummaryViewAssetLowered.includes("DIGIPORTAL".toLowerCase()) ||
      selectedSummaryViewAssetLowered.includes(
        "Digi Portal ( Intelligent Operations)".toLowerCase()
      ) ||
      selectedSummaryViewAssetLowered.includes(
        "Digi Portal (Intelligent Operations)".toLowerCase()
      ) ||
      selectedSummaryViewAssetLowered.includes("DIGI Portal".toLowerCase()) ||
      selectedSummaryViewAssetLowered.includes("Digi-Portal".toLowerCase()) ||
      selectedSummaryViewAssetLowered === "Digitracker".toLowerCase() ||
      selectedSummaryViewAssetLowered === "DIGI Portal".toLowerCase()
    ) {
      data.selectedAssest = "Digitracker";
    } else if (
      selectedSummaryViewAssetLowered.includes("acf") ||
      selectedSummaryViewAssetLowered.includes("(acf)") ||
      selectedSummaryViewAssetLowered.includes("Accenture Control Framework".toLowerCase()) ||
      selectedSummaryViewAssetLowered === "ACF Controls".toLowerCase()
    ) {
      data.selectedAssest = "ACFDataLake";
    }
    else if(
      selectedSummaryViewAssetLowered === "Quick Base".toLowerCase()
    ){
      data.selectedAssest = "QuickBase";
      this.setState({
        checkFlag: true,
      });
    } else {
      data.selectedAssest = selectedsummaryViewAsset;
    }

    const asset =
      Assets.filter((asset) => String(asset.Name) === String(data.selectedAssest)).length > 0
        ? Assets.filter((asset) => String(asset.Name) === String(data.selectedAssest))[0]
        : "";

    this.setState({
      isAPILoading: true,
      selectedAssest: data.selectedAssest,
      loginURLLink: asset.Url ? asset.Url : selectedsummaryViewAssetURL && selectedsummaryViewAssetURL.length ? selectedsummaryViewAssetURL : "",
      newDashboardUIPeriodInfo: asset.Period ? asset.Period : Period && Period.length ? `(${Period})` : '',
      enableNewDashboardUI: data.enableNewDashboardUI && (asset.ShowNewUI || (OpriaURL && OpriaURL.length)) ? true : false,
      assetImage: asset.Image,
    });

    let offeringId = store.getState().CurrentFilter.pulsePageData.offeringId;
    let cliendId = store.getState().CurrentFilter.ClientData.id;
    let apilink = "GetPulseSummaryData";
    if (String(data.selectedAssest) === "PMM") {
      apilink = "GetPulseSummaryDataPMM";
    }
    else if(String(data.selectedAssest) === "IWGPHDataLake" || String(data.selectedAssest) === "QuickBase") {
      apilink = "GetPulseSummaryDataForIWGPH";
    }
    if(!OpriaURL || (OpriaURL && !OpriaURL.length)){
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${LocalApiBaseUrl}PulseDashBoard/${apilink}`, {
          params: {
            ConnectorName: data.selectedAssest,
            ClientId: cliendId,
            OfferingId: offeringId,
            LanguageID: parseInt(store.getState().CurrentFilter.languageData.id),
            LocationId: 0,
          },
        })
        .then((response) => {
          let responseData = response.data;
          //let responseData = getAPIJson
          ///SINCE NEW API Doesn't have Backend Configuration
          responseData.filterAvailable =
            data.selectedAssest === "IWGPHDataLake" ? true : responseData.filterAvailable;
          
          let location = responseData.locationList;
          let groupsOriginal = responseData.locationList;
          let filterAvailable = responseData.filterAvailable;
          let periodData = responseData.PeriodDT;
          let countOfSubOffering = responseData.countOfSubOffering;
          let periodDTStart = "";
          let endDate = "";
          let startDate = "";

          groupsOriginal =
            groupsOriginal.length > 0
              ? groupsOriginal.map((each) => {
                  each.GroupList = each.GroupList.map((a) => ({
                    ...a,
                    LocationName: each.locationName_En,
                    type: "Group",
                    checked: true,
                    label: a.GroupName_En,
                    value:a.GroupName_En
                  }));
                  return each;
                })
              : [];
          let groups = groupsOriginal.map((each) => each.GroupList).flat(1);
          let appliedGroups = groups;

          let checkboxLocations = location.map((loc) => ({
            Location: loc.locationName,
            LocationID: loc.locationId,
            checked: true,
            type: "location",
            label: loc.locationName,
            value: loc.locationName,
          }));
          let uncheckboxLocations = location.map((loc) => ({
            Location: loc.locationName,
            LocationID: loc.locationId,
            checked: false,
            type: "location",
            label: loc.locationName,
            value: loc.locationName,
          }));
          checkboxLocations = checkboxLocations.sort((a, b) =>
            a.Location.toLowerCase() > b.Location.toLowerCase() ? 1 : -1
          );
          uncheckboxLocations = checkboxLocations.sort((a, b) =>
            a.Location.toLowerCase() > b.Location.toLowerCase() ? 1 : -1
          );

          let elements = [];
          if (String(data.selectedAssest) === "PMM") {
            let TempData = responseData.pmmDetails;
            let ServiceComponents = [];
            TempData.map((obj) => {
              ServiceComponents.push(obj.ServiceComponent);
            });
            ServiceComponents = [...new Set(ServiceComponents)];

            ServiceComponents.map((comp) => {
              if (
                TempData.filter((obj) => String(obj.ServiceComponent) === String(comp)).length > 0
              ) {
                let currentServiceComponent = TempData.filter(
                  (obj) => String(obj.ServiceComponent) === String(comp)
                );
                let metrics = currentServiceComponent.map((ob) => {
                  let metric = {
                    MetricName: "",
                    MetricName_En: "",
                    TargetScore: null,
                    CurrentScore: null,
                  };
                  metric.MetricName = ob.MetricName;
                  metric.MetricName_En = ob.MetricName_En;
                  metric.TargetScore = ob.TargetScore;
                  metric.CurrentScore = ob.CurrentScore;
                  return metric;
                });

                let mainObj = {
                  ServiceComponent: "",
                  ServiceComponent_En: "",
                  Metrics: [],
                };
                mainObj.ServiceComponent = comp;
                mainObj.ServiceComponent_En = comp;
                mainObj.Metrics = metrics;
                elements.push(mainObj);
              }
            });
          } else {
            // Ordering ROC Elements
            if (String(data.selectedAssest) === "ACC") {
              elements = responseData.elementDetails.sort((a, b) =>
                a.ElementOrder > b.ElementOrder ? 1 : -1
              );
            } else {
              elements = responseData.elementDetails.sort((a, b) =>
                a.MetricName > b.MetricName ? 1 : -1
              );
            }
          }

          if (periodData != null) {
            let Period = periodData.split("T")[0];
            let periodArray = Period.split("-");
            let Periodyyyy = periodArray[0];
            let Periodmm = periodArray[1];

            let Perioddd = "01";
            periodDTStart = Periodmm + "/" + Perioddd + "/" + Periodyyyy;

            // for Current Date
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyyy = today.getFullYear();

            endDate = "";
            startDate = "";

            let monthPeriodDT = Period.split("-")[1];
            monthPeriodDT = parseInt(monthPeriodDT);
            if (data.selectedAssest !== "ATCAT" && data.selectedAssest !== "DFM") {
              endDate = mm + "/" + dd + "/" + yyyy; // current Date for Data Current Month
              startDate = periodDTStart;
            }
            if (
              (data.selectedAssest === "ATCAT" || data.selectedAssest === "DFM") &&
              mm == Periodmm
            ) {
              let yearMonthDate = Period.split("-");
              let periodYear = yearMonthDate[0];
              let periodMonth = yearMonthDate[1];
              let previous = new Date(periodYear, periodMonth - 1, 0);
              let prevMonth = String(previous.getMonth() + 1).padStart(2, "0");
              let prevDate = String(previous.getDate()).padStart(2, "0");
              let prevYear = String(previous.getFullYear());
              endDate = prevMonth + "/" + prevDate + "/" + prevYear;
              startDate = prevMonth + "/" + "01" + "/" + prevYear;
            }
            if (
              (data.selectedAssest === "ATCAT" || data.selectedAssest === "DFM") &&
              mm != Periodmm
            ) {
              let yearMonthDate = Period.split("-");
              let periodYear = yearMonthDate[0];
              let periodMonth = yearMonthDate[1];
              let previous = new Date(periodYear, periodMonth, 0);
              let prevMonth = String(previous.getMonth() + 1).padStart(2, "0");
              let prevDate = String(previous.getDate()).padStart(2, "0");
              let prevYear = String(previous.getFullYear());
              endDate = prevMonth + "/" + prevDate + "/" + prevYear;
              startDate = periodDTStart;
            }
            if (data.selectedAssest === "Digitracker") {
              let startDay = "01";
              let startMonth = "09";
              let month = new Date().getMonth() + 1;
              let currentDate = new Date();
              let currentDay = String(currentDate.getDate()).padStart(2, "0");
              let currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
              let currentYear = currentDate.getFullYear();
              let year = parseInt(new Date().getFullYear());
              endDate = currentMonth + "/" + currentDay + "/" + currentYear;
              if (month < 9) {
                startDate = startMonth + "/" + startDay + "/" + (year - 1);
              } else if (month >= 9) {
                startDate = startMonth + "/" + startDay + "/" + year;
              }
            }
          }
          groups.sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1)
          checkboxLocations.sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1)
          this.setState(
            {
              locations: checkboxLocations,
              elementDetails: elements,
              isAPILoading: false,
              AppliedLocations: uncheckboxLocations,
              PeriodDT: periodDTStart,
              todayDate: endDate,
              startDate: startDate,
              endDate: endDate,
              filterAvailable: filterAvailable,
              countOfSubOffering,
              groupsOriginal,
              groups,
              appliedGroups,
              url:responseData.PowerBIUrl
            },
            () => {
              resolve();
            }
          );
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
    } else {
      this.setState({
        isAPILoading: false,
        url: this.props.OpriaURL
      });
    }
  };

  handleCollapse = (name) => {
    let collapseElements =
      this.state.collapsedElements.filter((d) => String(d) === String(name)).length === 1
        ? this.state.collapsedElements.filter((d) => String(d) !== String(name))
        : this.state.collapsedElements.push(name);

    this.setState({
      collapsedElements: collapseElements,
    });
  };

  handleModalClose = () => {
    this.isLocationDropdownOverlay();
    this.props.summaryModalClose();
    this.setState({
      checkFlag: false,
    });
  };
  selectall=(e,value)=>{

    let ischecked=e.target.checked;
    let { locations, groups, groupsOriginal,allLocation,allGroup,LocationElements } = this.state;
    allGroup.filterApply = ischecked
    if(value===0)
    {
      locations=locations.map(a=>({...a,checked:ischecked}))
      allLocation.filterApply = ischecked
      
      if(!ischecked)
      {
        groups = []
        LocationElements = []
      }
      
      else
      {
       groups = [...groupsOriginal].map((each) => each.GroupList).flat(1);
       groups=groups.map(a=>({...a,checked:ischecked}))
      }
    }
    
    else
    {
      groups=groups.map(a=>({...a,checked:ischecked}))
    }
    let locationAPICall= true;
    let groupAPICall = true;
    groups.sort((a,b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1);
    locations.sort((a,b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1);
   
this.setState({ locations, groups, allLocation, allGroup, LocationElements, locationAPICall, groupAPICall });
  }
  handleSelectedlocationOptions = (data, each, index) => {
  
    let { locations, groups,groupsOriginal,allGroup, allLocation } = this.state;

    allLocation.filterApply = false;
    let changedData = data.map((a) => {
      if (a.value == each.value) a.checked = !a.checked;
      return a;
    });
    if (each.type === "location") {
      locations = changedData;
      let found = changedData.filter(a=>a.value === each.value)
      let foundData = found.length >0? found[0].Location : ""
      let foundLocationChecked = found.length >0 ? found[0].checked:false; 
      if(!foundLocationChecked)
      {
        groups = [...groups].filter(a=>a.LocationName !== foundData)
      }
      else
      {
        let newGroups = groupsOriginal.filter(a=>a.locationName === foundData)[0].GroupList
        groups.push(...newGroups)
        groups.sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1)
      }
     
    } else {
      groups = changedData;
    }
    allGroup.filterApply = groups.every(a=>a.checked)
    groups.sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1)
    locations.sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1)
    let locationAPICall= true;
    let groupAPICall = true;
    this.setState({ locations, groups,allGroup, groupAPICall, locationAPICall, allLocation });
  };
  callBackClickInside = (index) =>{
    if(index === 0)
    {
      this.setState({
        locationAPICall:false,
      });
    }
    else
    {
      this.setState({
        groupAPICall:false,
      });
    }
  }

  handleModalSize = () => {
      this.setState({
        modalFullscreen: !this.state.modalFullscreen
      })
  }

  toggleGPSStatsModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        toggleGPSStatsModal: !prevState.toggleGPSStatsModal
      }
    })
  }
  


  render() {

    const { summaryStatus, selectedsummaryViewAsset, selectedsummaryViewAssetURL } = this.props;
    const {
      ClientName,
      OfferingName,
      SubOfferingName,
      allLocation,
      locations,
      elementDetails,
      LocationElements,
      AppliedLocations,
      isAPILoading,
      applyButtonDisable,
      selectedAssest,
      startDate,
      endDate,
      assetImage,
      filterAvailable,
      countOfSubOffering,
      pmmFilterDetails,
      isLocationDropdownStatus,
      groups,
      isGroupDropdownStatus,
      appliedGroups,
      allGroup,
      enableNewDashboardUI,
      gphStatModalFullscreen,
      newDashboardUIPeriodInfo 
    } = this.state;
    const shouldShowNewDashboardUI = enableNewDashboardUI;
    const isDashboardDetailedURLPresent = this.state.url && this.state.url.split(',')[1] && this.state.url.split(',')[1].length;
    let { loginURLLink } = this.state;
    if (selectedAssest === "ReconSmart") {
      loginURLLink = selectedsummaryViewAssetURL;
    }

    let subHeaderText = "";
    if (selectedAssest.includes("ACFDataLake")) {
      subHeaderText = (
        <span>
          {i18n.t("Data_for")} <b>{OfferingName}</b> - all {countOfSubOffering} sub offerings/towers
        </span>
      );
    } else if (selectedAssest.includes("IWGPHDataLake")||selectedAssest.includes("QuickBase")) {
      subHeaderText = (
        <span>
          {i18n.t("Data_for_all_the_suboff")} <b>{OfferingName}/{SubOfferingName}</b>{" "}
          {/* <span className="iwgph-tooltip">
            <FontAwesomeIcon
              data-tip
              data-for="gph-tooltip"
              onMouseLeave={() => {
                ReactTooltip.hide();
              }}
              icon={faInfoCircle}
            />
            <ReactTooltip
              place="right"
              type="dark"
              effect="solid"
              id={`gph-tooltip`}
            >
              This Belongs to Offering Level
            </ReactTooltip>
          </span> */}
        </span>
      );
    } else if (
      selectedAssest.includes("ACC") ||
      selectedAssest.includes("SQF") ||
      selectedAssest.includes("Digitracker")
    ) {
      subHeaderText = (
        <span>
          {i18n.t("Data_for")} <b>{ClientName}</b> - {SubOfferingName}
        </span>
      );
    } else if (selectedAssest.includes("PMM")) {
      subHeaderText = (
        <span>
          {i18n.t("Data_for")} <b>{ClientName}</b> - <b>{OfferingName}</b> -{" "}
          <b>{SubOfferingName}</b>
        </span>
      );
    } else if (selectedAssest.includes("ReconSmart")) {
      subHeaderText = (
        <span>
          {i18n.t("Data_for")} <b>{OfferingName}</b> - <b>{SubOfferingName}</b>
        </span>
      );
    } else {
      subHeaderText = (
        <span>
          {i18n.t("Data_for")} <b>{ClientName}</b>
        </span>
      );
    }
  
    return (
      <>
        {/* {this.state.flag && (
          <OpenPowerBIReport
            flag={this.state.flag}
            close={() => this.Close()}
            source={this.state.tempsource}
          />
        )} */}
        <Modal
          show={summaryStatus}
          onHide={this.handleModalClose}
          className={`modal fade summary-modal ${shouldShowNewDashboardUI ? "new-dashboard-ui-summary-modal":null}`}
          
          dialogClassName={shouldShowNewDashboardUI ? !this.state.modalFullscreen?"modal-100w":"modal-90w" : this.state.modalFullscreen?"modal-100w":"modal-90w"}
          backdrop="static"
        >
          <LoadingOverlay
            className="custom-loader"
            fadeSpeed={0}
            spinner={<SynopsLoader />}
            active={isAPILoading}
          />

          <Modal.Body>
            <div className="summary-header">
              
              <button type="button" class="close" onClick={this.handleModalClose}>
                <span aria-hidden="true">×</span>
                <span class="sr-only">{i18n.t("Close")}</span>
              </button>
              <button type="button" className={`btn btn-primary ${shouldShowNewDashboardUI ? "mr-2" : "mr-5"} pull-right`} onClick={()=> this.handleModalSize()}>
                {shouldShowNewDashboardUI ? !this.state.modalFullscreen? i18n.t('Minimize'): i18n.t('Maximize'):this.state.modalFullscreen? i18n.t('Minimize'): i18n.t('Maximize')}
                <i className="fas fa-compress-arrows-alt" />
              </button>

              {/* {this.state.checkFlag ? (
                <FontAwesomeIcon
                  className="graphical"
                  onClick={this.powerbisummary}
                  icon={faCalendarWeek}
                />
              ) : (
                ""
              )} */}
              {
                shouldShowNewDashboardUI &&  isDashboardDetailedURLPresent? 
                <button type="button" className="btn btn-primary mr-2 pull-right" onClick={()=> this.toggleGPSStatsModal()}>
                  {selectedAssest.includes("IWGPHDataLake") ? i18n.t('IW-GPH Detailed View') : `${selectedAssest} Detailed View`}
                </button>: null
              }
              {shouldShowNewDashboardUI && !_.isEmpty(loginURLLink) ? (
                <div className="btn btn-primary new-dashboard-ui-login-btn pull-right">
                  <a
                    href={loginURLLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-primary"
                  >
                    {selectedAssest.includes("IWGPHDataLake") ? i18n.t("IW-GPH Login") : i18n.t(`${selectedAssest} Login`)}
                  </a>
                </div>
                ) :null}
              <h4>
                {!_.isEmpty(assetImage) ? (
                  <img src={assetImage} alt="" width="50px" height="50px" />
                ) : (
                  <FontAwesomeIcon icon={faGem} />
                )}
                {selectedsummaryViewAsset}
              </h4>

              { shouldShowNewDashboardUI && newDashboardUIPeriodInfo.length ?
                <div className="filtered-value">
                  <span>
                    <b>
                      Showing data for all Offerings/Towers
                    </b>
                  </span>
                  <span>
                      {i18n.t("Period")} :{" "}
                      <b>
                       {newDashboardUIPeriodInfo}
                      </b>
                  </span>
                </div>
                :
                <div className="filtered-value">
                {subHeaderText}
                <span>
                  {i18n.t("Period")} :{" "}
                  <b>
                    {startDate} - {endDate}
                  </b>
                </span>
              </div> }
            </div>

            { !shouldShowNewDashboardUI ? <div className="summary-body">
              <div id="filter-accordion" className={filterAvailable ? "" : "filter-disabled"}>
                <div class="card">
                  <div class="card-header">
                    <div class="card-link" data-toggle="collapse" href="#collapse1">
                      {i18n.t("Filter")}
                    </div>
                  </div>
                  <div id="collapse1" class="collapse" data-parent="#filter-accordion">
                    <div class="card-body">
                      {/* NON PMM ASSETS */}
                      {selectedAssest !== "PMM" ? (
                      <div className="filters-section iwgph-filters">
                          {selectedAssest === "IWGPHDataLake" || selectedAssest === "QuickBase" ? (
                            <>
                              <MultiSelectDropDownComp
                                labelledBy="Location"
                                options={locations}
                                onChangeFun={this.handleSelectedlocationOptions}
                                value={locations.filter(a=>a.checked)}
                                selectall={this.selectall}
                                rowIndex={0}
                                selectAllStatus={allLocation.filterApply}
                                callBackOutsideClick={this.callBackOutsideClick}
                                checkboxDisabled={false}
                                callBackClickInside={this.callBackClickInside}
                              />
                              <MultiSelectDropDownComp
                                labelledBy="Groups"
                                options={groups}
                                onChangeFun={this.handleSelectedlocationOptions}
                                value={groups.filter(a=>a.checked)}
                                selectall={this.selectall}
                                rowIndex={1}
                                selectAllStatus={allGroup.filterApply}
                                callBackOutsideClick={this.callBackOutsideClick}
                                checkboxDisabled={false}
                                callBackClickInside={this.callBackClickInside}
                              />
                            </>
                          ) : (
                            <div className="location-filter singlefilter">
                              {selectedAssest.includes("DFM") ? (
                                <h6>{i18n.t("Document_Type")}</h6>
                              ) : selectedAssest.includes("CashApp") ? (
                                <h6>{i18n.t("Region")}</h6>
                              ) : selectedAssest.includes("Digitracker") ? (
                                <h6>{i18n.t("Tool_Type")}</h6>
                              ) : (
                                <h6>{i18n.t("Locations")}</h6>
                              )}

                              <div className="location-dropdown">
                                <span
                                  className="selected-location"
                                  onClick={this.isLocationDropdown}
                                >
                                  {AppliedLocations.filter((loc) => loc.checked === true).length >
                                    0 && allLocation.filterApply === false ? (
                                    AppliedLocations.filter((loc) => loc.checked === true)
                                      .length === 1 ? (
                                      <span>
                                        {
                                          AppliedLocations.filter((loc) => loc.checked === true)[0]
                                            .Location
                                        }
                                      </span>
                                    ) : (
                                      <span>
                                        {
                                          AppliedLocations.filter((loc) => loc.checked === true)[0]
                                            .Location
                                        }{" "}
                                        ...
                                      </span>
                                    )
                                  ) : (
                                    allLocation.Location
                                  )}
                                </span>
                                {isLocationDropdownStatus ? (
                                  <div className="location-check-list">
                                    <ul>
                                      <li>
                                        <label className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={allLocation.checked}
                                            onClick={() => {
                                              this.onLocationFetch("All");
                                            }}
                                          />
                                          <span className="checkmark"></span>
                                          <span className="form-check-label">{i18n.t("All")}</span>
                                        </label>
                                      </li>
                                      {locations.map((data) => (
                                        <li>
                                          <label className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              checked={data.checked}
                                              onClick={() => {
                                                this.onLocationFetch(data.Location);
                                              }}
                                            />
                                            <span className="checkmark"></span>
                                            <span className="form-check-label">
                                              {data.Location}
                                            </span>
                                          </label>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {isLocationDropdownStatus ? (
                                <div
                                  className="location-dropdown-overlay"
                                  onClick={this.handleApply}
                                ></div>
                              ) : (
                                ""
                              )}
                              <div className="selected-location-list">
                                <ul>
                                  {allLocation.filterApply === false &&
                                    AppliedLocations.filter((loc) => loc.checked === true).map(
                                      (data) => <li>{data.Location}</li>
                                    )}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        /* PMM ASSET */
                        <div className="filters-section">
                          <div className="location-filter singlefilter">
                            <h6>{i18n.t("Region")}</h6>
                            <div className="location-dropdown">
                              <span className="selected-location" onClick={this.isLocationDropdown}>
                                {AppliedLocations.filter((loc) => loc.checked === true).length >
                                  0 && allLocation.filterApply === false ? (
                                  AppliedLocations.filter((loc) => loc.checked === true).length ===
                                  1 ? (
                                    <span>
                                      {
                                        AppliedLocations.filter((loc) => loc.checked === true)[0]
                                          .Location
                                      }
                                    </span>
                                  ) : (
                                    <span>
                                      {
                                        AppliedLocations.filter((loc) => loc.checked === true)[0]
                                          .Location
                                      }{" "}
                                      ...
                                    </span>
                                  )
                                ) : (
                                  allLocation.Location
                                )}
                              </span>
                              {isLocationDropdownStatus ? (
                                <div className="location-check-list">
                                  <ul>
                                    <li>
                                      <label className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={allLocation.checked}
                                          onClick={() => {
                                            this.onRegionFetch("All");
                                          }}
                                        />
                                        <span className="checkmark"></span>
                                        <span className="form-check-label">All</span>
                                      </label>
                                    </li>
                                    {locations.map((data) => (
                                      <li>
                                        <label className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={data.checked}
                                            onClick={() => {
                                              this.onRegionFetch(data.Location);
                                            }}
                                          />
                                          <span className="checkmark"></span>
                                          <span className="form-check-label">{data.Location}</span>
                                        </label>
                                      </li>
                                    ))}
                                  </ul>
                                  {/* <div className="location-footer">
                                    <button
                                      type="button"
                                      class="btn btn-outline-secondary"
                                      onClick={this.handleClear}
                                    >
                                      {i18n.t("Clear")}
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      onClick={this.handlePMMApply}
                                      disabled={applyButtonDisable}
                                    >
                                      {i18n.t("Apply")}
                                    </button>
                                  </div> */}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            {isLocationDropdownStatus ? (
                              <div
                                className="location-dropdown-overlay"
                                onClick={this.handlePMMApply}
                              ></div>
                            ) : (
                              ""
                            )}
                            <div className="selected-location-list">
                              <ul>
                                {allLocation.filterApply === false &&
                                  AppliedLocations.filter((loc) => loc.checked === true).map(
                                    (data) => <li>{data.Location}</li>
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div> : null }

            {!selectedAssest.includes("PMM") && (
              <TilesView
                allLocation={allLocation}
                elementDetails={elementDetails}
                LocationElements={LocationElements}
                shouldShowNewDashboardUI={shouldShowNewDashboardUI}
                showGroupsTooltip = {selectedAssest === 'IWGPHDataLake' ? true : false }
                tempsource = {this.state.tempsource}
                powerbisummary = {this.powerbisummary}
                propsflag={this.state.flag}
                modalFullscreen={this.state.modalFullscreen}
                selectedsummaryViewAsset = {this.props.selectedsummaryViewAsset.toLowerCase()}
                close = {() => this.Close()}
                url = {this.state.url}
                selectedAssest={selectedAssest}
              />
            )}

            {selectedAssest.includes("PMM") &&
              pmmFilterDetails.length === 0 &&
              allLocation.filterApply === true && (
                <div className="filtered-records">
                  <p className="score-status">
                    ({i18n.t("Current_Score")}/{i18n.t("Target_Score")})
                  </p>
                  <div id="records-accordion" className="records-accordion">
                    {elementDetails.length !== 0 &&
                      elementDetails.map((data, index) => (
                        <div class="card">
                          <div class="card-header">
                            <div
                              class="card-link"
                              data-toggle="collapse"
                              href={`#Location${index}`}
                            >
                              {data.ServiceComponent}
                            </div>
                          </div>
                          <div id={`Location${index}`} class="collapse show">
                            <div class="card-body">
                              <div className="records-row flexbox-2 pmm-recordsrow">
                                {data.Metrics.map((element) => (
                                  <div className="record-divider gray-clr">
                                    <div className="record-indicate">
                                      {!_.isNil(UINames[element.MetricName.trim()]) ? (
                                        <div className="record-heading">
                                          <h4>{UINames[element.MetricName.trim()]}</h4>
                                          {UINames[element.MetricName.trim()].length > 31 ? (
                                            <div className="record-heading-tooltip">
                                              {UINames[element.MetricName.trim()]}
                                            </div>
                                          ) : null}
                                        </div>
                                      ) : (
                                        <div className="record-heading">
                                          <h4>{element.MetricName}</h4>
                                          {element.MetricName.length > 31 ? (
                                            <div className="record-heading-tooltip">
                                              {element.MetricName}
                                            </div>
                                          ) : null}
                                        </div>
                                      )}
                                      {element.CurrentScore != null &&
                                      element.TargetScore != null ? (
                                        <span className="record-digit">
                                          {element.CurrentScore}/{element.TargetScore}
                                        </span>
                                      ) : (
                                        <span className="record-digit">
                                          <span className="record-digit-text">
                                            {i18n.t("Data_is_not")}
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}

            {selectedAssest.includes("PMM") &&
              pmmFilterDetails.length > 0 &&
              allLocation.filterApply === false && (
                <div className="filtered-records">
                  <p className="score-status">
                    ({i18n.t("Current_Score")}/{i18n.t("Target_Score")})
                  </p>
                  <div id="records-accordion" className="records-accordion">
                    {pmmFilterDetails.length !== 0 &&
                      pmmFilterDetails.map((data, index) => (
                        <div class="card">
                          <div class="card-header">
                            <div
                              class="card-link"
                              data-toggle="collapse"
                              href={`#Location${index}`}
                            >
                              {data.ServiceComponent} | {data.LocationName}
                            </div>
                          </div>
                          <div id={`Location${index}`} class="collapse show">
                            <div class="card-body">
                              <div className="records-row flexbox-2 pmm-recordsrow">
                                {data.Metrics.map((element) => (
                                  <div className="record-divider gray-clr">
                                    <div className="record-indicate">
                                      {!_.isNil(UINames[element.MetricName.trim()]) ? (
                                        <div className="record-heading">
                                          <h4>{UINames[element.MetricName.trim()]}</h4>
                                          {UINames[element.MetricName.trim()].length > 31 ? (
                                            <div className="record-heading-tooltip">
                                              {UINames[element.MetricName.trim()]}
                                            </div>
                                          ) : null}
                                        </div>
                                      ) : (
                                        <div className="record-heading">
                                          <h4>{element.MetricName}</h4>
                                          {element.MetricName.length > 31 ? (
                                            <div className="record-heading-tooltip">
                                              {element.MetricName}
                                            </div>
                                          ) : null}
                                        </div>
                                      )}
                                      {element.CurrentScore != null &&
                                      element.TargetScore != null ? (
                                        <span className="record-digit">
                                          {element.CurrentScore}/{element.TargetScore}
                                        </span>
                                      ) : (
                                        <span className="record-digit">
                                          <span className="record-digit-text">
                                            {i18n.t("Data_is_not")}
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}

            {!shouldShowNewDashboardUI && !_.isEmpty(loginURLLink) && (
              <div className="login-btn">
                <a
                  href={loginURLLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-outline-primary"
                >
                  {i18n.t("Login")}
                </a>
              </div>
            )}
          </Modal.Body>
        </Modal>
        {this.state.toggleGPSStatsModal ? 
        <SisenseGPHDashboard 
          url = {this.state.url}
          toggleGPSStatsModalCall={this.toggleGPSStatsModal}
          toggleGPSStatsModal={this.state.toggleGPSStatsModal}
        />
        :null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pulseReduxData: state.CurrentFilter.pulsePageData,
    languageData: state.CurrentFilter.languageData,
    clientData: state.CurrentFilter.ClientData,
    pulseFilterData: state.CurrentFilter.PulseData,
    pulseMaxSequences: state.CurrentFilter.pulseMaxSequences,
    SelectedFilter: { ...state.CurrentFilter.SyncFilter },
  };
};

export default connect(mapStateToProps)(AssetsSummaryView);
