import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import URLWhiteListPopup from './URLWhiteListPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl } from '../Shared/Constant';
import SynopsLoader from '../Shared/SynopsLoader';
import LoadingOverlay from 'react-loading-overlay';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
  'adal.idtoken',
)}`;

export default class URLWhiteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewmore: false,
      isApiLoading: false,
      rowData: [],
      rowSelected: [],
      clientData:[],
      columnDefs: [
        {
          headerName: 'Edit',
          suppressMovable: true,
          width: 60,
          cellRenderer: (params) => {
            const link = document.createElement('span');
            link.className = 'tbledit';
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener('click', (e) => {
              this.dealpopup(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
        },
        {
          headerName: 'URL',
          width: 350,
          field: 'Domain',
          suppressMovable: true,
          resizable: false,
        },
        {
          headerName: 'Client Name', field: 'OrgEntityName', suppressMovable: true, resizable: false,
        },
        {
          headerName: 'Is Active',
          field: 'IsActive',
          resizable: false,
          suppressMovable: true,
          suppressFilter: true,

          cellRenderer: (params) => (`<label class="form-check">
                    <input class="form-check-input" disabled type="checkbox" ${params.value ? 'checked' : ''
            }>
                    <span class="checkmark"></span>
                     </label>`),

        },
      ],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: 'single',
        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this)
  }

  componentDidMount() {
    const { columnDefs } = this.state;
    this.getWhiteListURL();
    this.getClients();
    //this.getUserData();
    let tempcolumnDefs = columnDefs;
  }
  getClients() {
    axiosInstance
      .get(`${LocalApiBaseUrl}SO/GetOrgStructure`, {
        params: { LanguageId: 1 },
      })
      .then((response) => {        
       let clientData = response.data.map(each => ({'Name': each.OrgEntityName,'ID': each.OrgEntityID}) );
       this.setState({
         clientData,
       })
      })
  }
  handleSave(data) {
    const params = {
      id: data.ID,
      domain: data.Domain,
      isActive: data.isActive,
      orgEntityID: data.OrgEntityID,      
    };
    axiosInstance
      .post(`${LocalApiBaseUrl}Common/SaveEditWhiteListUrl`, params)
      .then((response) => {
        window.alert(response.data.StatusMessage);
        this.getWhiteListURL();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  onClose() {
    this.setState({ viewmore: false});    
    this.getWhiteListURL()
  }

  onFilterTextBoxChanged() {
    const { gridOptions } = this.state;
    gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }


  // getUserData(){
  //   this.setState({ isApiLoading: true });
  //   axiosInstance
  //     .get(`${LocalApiBaseUrl}User/GetUserDetails`)
  //     .then((res) => {
  //       if (res.data) {
  //         this.setState({ 
  //           isAdmin: res.data[0] === "True",
  //           isApiLoading:false
  //          });
  //       }
  //     })
  //     .catch((error) => {
  //       trycatchAlertPopup(error);
  //     });

  // }
  
  getWhiteListURL() {
    this.setState({ isApiLoading: true });
    axiosInstance
      .get(`${LocalApiBaseUrl}Common/GetWhiteListUrl?IsList=true`)
      .then((response) => {
        const data = response.data.sort((a, b) => ((a.CreatedTS.toLowerCase() > b.CreatedTS.toLowerCase()) ? 1 : -1));
        this.setState({
          rowData: data,
          isApiLoading:false
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  


  dealpopup = (e, data) => {
    if (data !== undefined) {
      // edit
        this.setState({ viewmore: true, rowSelected: data });
    }
    else{
      //add
        this.setState({ viewmore: true, rowSelected: data });
    }
  }

  render() {
    const {
      gridOptions, columnDefs, rowData, rowSelected, viewmore, clientData
    } = this.state;
    return (
      <div className="main-data">
        <div className="tableFilter">
          <div className="form-section">
            <div className="form-row">
              <div className="form-group col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  id="filter-text-box"
                  placeholder="Filter..."
                  onChange={(e) => this.onFilterTextBoxChanged(e)}
                />
              </div>

              
              <div className="form-group col-lg-3">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={(e) => {
                    this.dealpopup(e);
                  }}
                >
                  <span>
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </span>
                  {' '}
                  Add URL
                </button>
              </div>
            

            </div>
          </div>
        </div>
      {this.state.isApiLoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />}
        <div className="row">
          <div className="col-lg-12">
            <div className="ag-theme-balham ag-table">
              <AgGridReact
                enableSorting={true}
                enableFilter
                pagination
                paginationPageSize={50}
                floatingFilter
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                rowData={rowData}
                onFirstDataRendered={this.onFirstDataRendered}
              />
            </div>
          </div>
        </div>
        {viewmore ? (
          <URLWhiteListPopup
            rowData={rowData}
            data={rowSelected}
            show={viewmore}
            clientData={clientData}
            onClose={this.onClose}
            onSave={this.handleSave}
          />
        ) : null}
      </div>
    );
  }
}

URLWhiteList.propTypes = {
  featureData: PropTypes.array.isRequired,
};
