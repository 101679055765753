import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgGridReact } from 'ag-grid-react';
import { connect } from "react-redux";
import i18n from "i18next";
import {
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import ToolsExportCsv from './ToolsExportCsv';
import ToolsPopUp from "./ToolsPopUp";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import moment from "moment";

axiosInstance.defaults.headers.common[
    "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class Tools extends Component {
    constructor(props) {
        super(props);
		this.edit = i18n.t("Edit");
		this.toolName = i18n.t("Tool_Name");
		this.toolCategory = i18n.t("Tool_Category");
		this.synopsLevers = i18n.t("Synops_Levers");
        this.description = i18n.t("Description");
        this.isActive = i18n.t("Is_Active");
        this.state = {
            viewmore: false,
            isLoading:false,
            type: "",
            data: [],
            editData: [],
            columnDefs: [{
                    headerName: this.edit, 
					suppressMovable: true,
                    width: 80,
                    cellRenderer: (params) => {
                        if(this.props.roledata.includes("Edit")){
                            var link = document.createElement('span');
							link.className = 'tbledit';
							link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
							link.addEventListener('click', (e) => {
								this.addEditTool(e, 'Edit', params.data);
                            });
                        return link;
                     }
                    },
                    suppressFilter: true,
                    suppressSizeToFit: true
                },
                { headerName: this.toolName, field: "ApplicationName", sort: "asc", resizable: true, suppressFilter: false ,suppressMovable: true,suppressSizeToFit: true,},
                {
                    headerName: this.isActive, field: "IsActive", resizable: false,suppressMovable: true, suppressFilter: true,
                    cellRenderer: params => {
                        return `<label class="form-check">
                    <input class="form-check-input" disabled type="checkbox" ${params.value ? 'checked' : ''}>
                    <span class="checkmark"></span>
                     </label>`;
                    }
                },
                { headerName: this.toolCategory, field: "ApplicationCategory", resizable: true, suppressFilter: false,suppressMovable: true,suppressSizeToFit: true, },
                { headerName: this.synopsLevers, field: "SOCategoryName", resizable: true, suppressFilter: false,suppressMovable: true,suppressSizeToFit: true, },
                { headerName: "URL", field: "URL", resizable: true, suppressFilter: false ,suppressMovable: true,suppressSizeToFit: true,},             
                { headerName: this.description, field: "ApplicationDescription", resizable: true, suppressFilter: false ,suppressMovable: true,suppressSizeToFit: true,}                
            ],
            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'multiple',
                defaultColDef: {
                    sortable: true
                }
            }
        }
    }
	
    componentDidUpdate(prevProps){
        const {filterDetails} = this.props;
        if(filterDetails.process !== prevProps.filterDetails.process){
            this.getToolList();
        }
    }
    componentDidMount() {
        this.getToolList();
    }
	
	getToolList = () => {
        this.setState({
            isLoading:true
        })
        const param = {
            "OfferingID": this.props.filterDetails.process,
            "LanguageID": this.props.languageData.id
        };
        axiosInstance.post(`${LocalApiBaseUrl}ApplicationMapping/GetApplicationMappingByOfferingID`, param).then((response) => {
			this.setState({
				rowData: response.data.sort((a, b) => (a.ApplicationName > b.ApplicationName) ? 1 : -1),
                isLoading:false
			})
		})
        .catch((error) => {
            this.setState({
                isLoading:false
            })
            trycatchAlertPopup(error);
          });
  
	}

    addEditTool = (e, type, data) => {
        this.setState({ viewmore: true, type: type, editData: (type == "Edit" ? data : {}) });
    }

    onClose = () => {
        this.setState({ viewmore: false });
		this.getToolList();
    }
    onBtnExport=()=> {
        let currentdate = new Date();
    let formatDate = moment(currentdate).format("YYYYMMDD");
    let formatTime = moment(currentdate).format("HHmmss");
    let formatSheetName = `MTCToolReport_${formatDate}_${formatTime}`;
        let params = {
          columnKeys: ["ApplicationName", "IsActive","ApplicationCategory","SOCategoryName","URL","ApplicationDescription"],
          columnGroups: true,
          allColumns: false,
          skipHeader: false,
          fileName: formatSheetName,
        };
        this.state.gridOptions.api.exportDataAsCsv(params);
      }
    render() {
        let{isLoading,rowData}=this.state
        return (
            <div className="row">
            <LoadingOverlay
          spinner={<SynopsLoader />}
          active={isLoading}
        ></LoadingOverlay>
        <div className="mtcexport-download">
        <button className="btn" onClick={()=>this.onBtnExport()}>
          <i class="far fa-download download-icon-fix"></i>
        </button>
        </div>
                <div className="col-lg-12">
                    <div className="title-with-icon d-inline-flex mb-3">
                        <h2></h2>
                        <div className="">
                            {store.getState().CurrentFilter.languageData.id == 1 && this.props.roledata.includes("Add")?
                                <span onClick={this.addEditTool.bind(this, "Add", "")}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                                : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ag-theme-balham ag-table">
                                <AgGridReact suppressMovable={true}
                                    enableSorting={true}
                                    enableFilter={true}
                                    pagination={true}
                                    paginationPageSize={50}
                                    floatingFilter={true}
                                    gridOptions={this.state.gridOptions}
                                    columnDefs={this.state.columnDefs}
                                    rowData={this.state.rowData}>
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
                    {this.state.viewmore ? (
                        <ToolsPopUp tooldata={this.state.editData} tooldatatype={this.state.type} show={this.state.viewmore} onClose={this.onClose} addEditTool={this.addEditTool} filterDetails={this.props.filterDetails} />
                    ) : null}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        languageData: state.CurrentFilter.languageData
    })
}
export default connect(mapStateToProps)(Tools);
