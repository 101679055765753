import React, { Component } from 'react';
import { LocalApiBaseUrl } from '../Shared/Constant';
import { AgGridReact } from 'ag-grid-react';
import ClickableToolPopup from './ClickableToolPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class ClickableToolList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ClickableToolList: [], roleList: [], clientList: [], viewmore: false, rowSelected: [],
            columnDefs: [
                {
                    headerName: "Edit", suppressMovable: true,
                    width: 60,
                    cellRenderer: (params) => {
                        let link = document.createElement('span');
                        link.className = 'tbledit';
                        link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.dealpopup(e, 'Edit', params.data);
                        });
                        return link;
                    },
                    suppressFilter: true, resizable: true, suppressSizeToFit: true
                },
                { headerName: "Client Name", field: "OrgEntityName", suppressMovable: true, resizable: true, suppressSizeToFit: true },
                { headerName: "Delivery Center", field: "City", suppressMovable: true, resizable: true, suppressSizeToFit: true },
                { headerName: "Offering", field: "Offering", suppressMovable: true, resizable: true, suppressSizeToFit: true },
                { headerName: "Sub Offering", field: "Process", suppressMovable: true, resizable: true, suppressSizeToFit: true },
                { headerName: "SynOps Lever", field: "SOCategoryName", suppressMovable: true, resizable: true, suppressSizeToFit: true },
                { headerName: "Tool Name", field: "ApplicationName", suppressMovable: true, resizable: true, suppressSizeToFit: true },
                { headerName: "Description", field: "ApplicationDescription", suppressMovable: true, resizable: true, suppressSizeToFit: true },
                { headerName: "Category", field: "ApplicationCategory", suppressMovable: true, resizable: true, suppressSizeToFit: true },
                { headerName: "URL", field: "URL", suppressMovable: true, resizable: true, suppressSizeToFit: true },
                {
                    headerName: "Is Active", field: "IsActive", resizable: true, suppressMovable: true, suppressFilter: true,

                    cellRenderer: params => {
                        return `<label class="form-check">
                    <input class="form-check-input" disabled type="checkbox" ${params.value ? 'checked' : ''}>
                    <span class="checkmark"></span>
                     </label>`;
                    }, suppressSizeToFit: true
                },
            ],
            rowData: [],
            type: "",
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'multiple',
                defaultColDef: {
                    sortable: true
                },
            }
        }
        this.onClose = this.onClose.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.handleSave = this.handleSave.bind(this);

    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    onFilterTextBoxChanged(e) {
        const { gridOptions } = this.state;
        gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }

    dealpopup(e, type, data) {

        e.preventDefault();
        this.setState({ viewmore: true, type: type, rowSelected: (String(type) === "Edit" ? data : {}) })

    }


    onClose(e) {

        this.setState({ viewmore: false });//CLOSES CLICKABLETOOL POPUP
    }

    getTools() {

        axiosInstance.get(`${LocalApiBaseUrl}ClickableTools/GetMappedData`)
            .then((response) => {
                this.setState({
                    rowData: response.data

                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });


    }

    componentWillMount() {
        const { featureData } = this.props;
        this.getTools();
        let columnDefs = this.state.columnDefs;

        if (featureData.filter((data) => String(data.Submenu) === "ClickableTool" && data.Operations.includes("Edit")).length === 0) {
            columnDefs = columnDefs.filter((data) => String(data.headerName) !== "Edit");
            this.setState({
                columnDefs: columnDefs
            })

        }


        if (featureData.filter((data) => String(data.Submenu) === "ClickableTool" && data.Operations.includes("Delete")).length === 0) {
            columnDefs = columnDefs.filter((data) => String(data.headerName) !== "Delete");
            this.setState({
                columnDefs: columnDefs
            })

        }

    }

    handleSave(data) {
        if (data != null) {
            axiosInstance.post(`${LocalApiBaseUrl}ClickableTools/postToolsDealMapping`, data)
                .then((response) => {
                    window.alert(response.data.StatusMessage)
                    this.getTools();
                },
                    (error) => {
                        trycatchAlertPopup(error);
                    })
                .catch((error) => {
                    trycatchAlertPopup(error);
                });

        }
    }

    render() {
        const { gridOptions, columnDefs, rowData, viewmore, rowSelected, type } = this.state;
        const { featureData, filterDetails } = this.props;
        return (
            <div className="main-data">
                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                            </div>
                            {featureData.filter((data) => String(data.Submenu) === "ClickableTool" && data.Operations.includes("Add")).length > 0 &&
                                <div className="form-group col-lg-3">
                                    <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.dealpopup(e) }}><span><FontAwesomeIcon icon={faPlusCircle} /></span> Add Mapping</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ag-theme-balham ag-table">
                            <AgGridReact
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                paginationPageSize={50}
                                floatingFilter={true}
                                gridOptions={gridOptions}
                                columnDefs={columnDefs}
                                rowData={rowData}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                            </AgGridReact>
                        </div>
                    </div>
                </div>
               
                {viewmore ? <ClickableToolPopup filterDetails={filterDetails} tooldatatype={type} rowData={rowData} data={rowSelected} show={viewmore} onClose={this.onClose} onSave={this.handleSave} /> : null}
            </div>
        )
    }
}