import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { AgGridReact } from "ag-grid-react";
import DEIPopUp from "./DEIPopup";
import _ from "lodash";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class DEIList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelected: [],
      userList: [],
      roleList: [],
      clientList: [],
      offeringList: [],
      viewmore: false,
      columnDefs: [
        {
          headerName: "Deal name",
          field: "DealName",
          sort: "asc",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Client Names",
          field: "ClientName",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        { headerName: "Offerings", field: "Offering", resizable: true, suppressMovable: true, suppressSizeToFit: true },
        {
          headerName: "Sub Offerings",
          field: "SubProcess",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Delivery Location",
          field: "DeliveryLocation",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "DEI Client Names",
          field: "DEIClientName",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        { headerName: "DEI Tower", field: "Tower", resizable: true, suppressMovable: true, suppressSizeToFit: true },
        {
          headerName: "DEI Location",
          field: "DEIDeliveryLocation",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
      ],
      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",
        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  dealpopup(e, data) {
    if (!_.isNil(data)) {
      // edit condition
      if (
        this.props.featureData.filter((data) => String(data.Submenu) === "DEI" && data.Operations.includes("Edit"))
          .length > 0
      ) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data });
      } else window.alert("Access Denied !!");
    } else {
      // add condition
      if (
        this.props.featureData.filter((data) => String(data.Submenu) === "DEI" && data.Operations.includes("Add"))
          .length > 0
      ) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data });
      } else window.alert("Access Denied !!");
    }
  }
  onClose(e) {
    this.setState({ viewmore: false });
  }

  getDEI() {
    axiosInstance
      .get(`${LocalApiBaseUrl}DEIDataMapping/GetDEIDealInfo`)
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    this.getDEI();
  }

  componentWillMount() {
    this.getDEI();
  }

  handleSave(data) {
    axiosInstance
      .post(`${LocalApiBaseUrl}DEIDataMapping/PostDEIDataMapping`, data)
      .then((response) => {
        window.alert(response.data.StatusMessage);
        this.getDEI();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  render() {
    const { featureData, filterDetails } = this.props;
    const { gridOptions, columnDefs, rowData, viewmore, rowSelected } = this.state;
    return (
      <div className="main-data">
        <div className="tableFilter">
          <div className="form-section">
            <div className="form-row">
              <div className="form-group col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  id="filter-text-box"
                  placeholder="Filter..."
                  onChange={(e) => {
                    this.onFilterTextBoxChanged(e);
                  }}
                />
              </div>
              {featureData.filter((data) => String(data.Submenu) === "DEI" && data.Operations.includes("Add")).length >
                0 && (
                  <div className="form-group col-lg-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={(e) => {
                        this.dealpopup(e);
                      }}
                    >
                      <span>
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </span>{" "}
                      Add DEI Mapping
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ag-theme-balham ag-table">
              <AgGridReact
                enableSorting={true}
                enableFilter={true}
                pagination={true}
                paginationPageSize={50}
                floatingFilter={true}
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                rowData={rowData}
                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
              ></AgGridReact>
            </div>
          </div>
        </div>
        {viewmore ? (
          <DEIPopUp
            data={rowSelected}
            show={viewmore}
            onClose={this.onClose}
            filterDetails={filterDetails}
            onSave={this.handleSave}
          />
        ) : null}
      </div>
    );
  }
}
