import {PULSE_MOR_DATA_INITIALIZE,PULSE_CASESTUDY_DATA_INITIALIZE} from "../actions/PulseMORCaseStudyActions"

const PulseMORCaseStudyData={
    CaseStudyData:{},
    MORItemData:[],
    ItemDataLevel:{
        Level:"",
        CategoryID:0,
        ismorpulseapiloading:true
    },
    isCategoryData:false
}

export let PulseMORCaseStudyReducers=(state=PulseMORCaseStudyData,action)=>{
      switch(action.type){
       case PULSE_CASESTUDY_DATA_INITIALIZE:{
          state={...state}
          state["CaseStudyData"]=action.payload
          return state;
      }
      case PULSE_MOR_DATA_INITIALIZE:{
        state={...state}
        state["MORItemData"]=action.payload.MorItemData
        state["ItemDataLevel"]=action.payload.ItemDataLevel
        state["isCategoryData"]=action.payload.isCategoryData
        return state;
    }
    default:
        return state;
}
}