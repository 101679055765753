export const initialState = {
  DasboardOfferingData: [],
  DashboardSubOfferingData: [],
  ConfiguratorOfferingData: [],
  ConfiguratorSubOfferingData: [],
  MasterOfferings: [],
  MonthsData: [],
};

export const FilterReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'SAVE_DASHBOARD_OFFERING_DETAILS': {
      const updatedstate = { ...state };
      updatedstate.DasboardOfferingData = action.payload;
      return updatedstate;
    }
    case 'SAVE_DASHBOARD_SUB_OFFERING_DETAILS': {
      const updatedstate = { ...state };
      updatedstate.DashboardSubOfferingData = action.payload;
      return updatedstate;
    }
    case 'SAVE_CONFIGURATOR_OFFERING_DETAILS': {
      const updatedstate = { ...state };
      updatedstate.ConfiguratorOfferingData = action.payload;
      return updatedstate;
    }
    case 'SAVE_CONFIGURATOR_SUB_OFFERING_DETAILS': {
      const updatedstate = { ...state };
      updatedstate.ConfiguratorSubOfferingData = action.payload;
      return updatedstate;
    }
    case 'SAVE_MTC_OFFERING_DETAILS': {
      const updatedstate = { ...state };
      updatedstate.MasterOfferings = action.payload;
      return updatedstate;
    }
    case 'SAVE_MONTH_DATA':{
      const updatedstate = { ...state };
      updatedstate.MonthsData = action.payload;
      return updatedstate;
    }
    default:
      return state;
  }
};
