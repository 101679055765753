import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { filterSelectedSubOffering } from './commonFunctions';

class SubOffering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubOfferingTree: {},
    };
  }

  componentDidMount() {
    const { subOfferings, selectedSubOfferingId } = this.props;
    if (subOfferings.length && selectedSubOfferingId) {
      const filtersSubofferingData = filterSelectedSubOffering(
        subOfferings,
        selectedSubOfferingId,
      );
      // const selectedSubofferingObject = filtersSubofferingData.subofferingData[0];
      const selectedSubOfferingTree = filtersSubofferingData.selectedSubOfferingTree[0];
      this.setState({ selectedSubOfferingTree });
    }
  }

  componentDidUpdate(prveProps) {
    const { subOfferings, selectedSubOfferingId } = this.props;
    if (
      prveProps.subOfferings !== subOfferings
      || prveProps.selectedSubOfferingId !== selectedSubOfferingId
    ) {
      const filtersSubofferingData = filterSelectedSubOffering(
        [...subOfferings],
        selectedSubOfferingId,
      );
      // const selectedSubofferingObject = filtersSubofferingData.subofferingData[0];
      const selectedSubOfferingTree = filtersSubofferingData.selectedSubOfferingTree[0];
      this.setState({ selectedSubOfferingTree });
    }
  }

  createTogglerFunction = (event) => {
    event.target.parentNode
      .querySelector('.nested-ul')
      .classList.toggle('active');
    event.target.classList.toggle('caret-down');
  }

  render() {
    const {
      subOfferings,
      onSubOfferingChange,
      dropdownItem,
      selectedSubOfferingId,
      AllSubOffering,
      NoSubOffering
    } = this.props;
    const { selectedSubOfferingTree } = this.state;

    return (
      <ul id="nested-dropdown-container">
        {dropdownItem
        && (
        <li>
          <Dropdown.Item
            onClick={(e) => onSubOfferingChange({ target: { value: 0 } })}
            className={
                selectedSubOfferingId === 0
                  ? 'active'
                  : false
              }
          >
            {i18n.t('Select_Sub_Offering')}
          </Dropdown.Item>
        </li>
        )}

        {AllSubOffering
        && (
        <li>
          <Dropdown.Item
            onClick={(e) => onSubOfferingChange({ target: { value: i18n.t('All_Sub_Offerings') } })}
            className={
                selectedSubOfferingId === 0
                  ? 'active'
                  : false
              }
          >
            {i18n.t('All_Sub_Offerings')}
          </Dropdown.Item>
        </li>
        )}

        {subOfferings.map((subOff) => {
          if (subOff.IsSubOffering) {
            return (
              <li>
                <Dropdown.Item
                  className={
                    selectedSubOfferingTree.Process === subOff.Process
                      ? 'active'
                      : false
                  }
                  onClick={() => (selectedSubOfferingTree.Process === subOff.Process ? '' : onSubOfferingChange({ target: { value: subOff.ProcessID } }))}
                >
                  {subOff.Process}
                </Dropdown.Item>
              </li>
            );
          }
          const levelOneData = subOff.SubLevel;
          const selectedSubLevelOne = (selectedSubOfferingTree.SubLevel && selectedSubOfferingTree
            .SubLevel.length)
            ? selectedSubOfferingTree.SubLevel[0]
            : {};
          if (levelOneData && levelOneData.length) {
            return (
              <li className="nested-li">
                <span
                  className={`caret ${
                    selectedSubOfferingTree.Process === subOff.Process
                      ? 'caret-down'
                      : ''
                  }`}
                  onClick={this.createTogglerFunction}
                >
                  {subOff.Process}
                </span>
                <ul
                  className={`nested-ul ${
                    selectedSubOfferingTree.Process === subOff.Process ? 'active' : ''
                  }`}
                >
                  {levelOneData.map((eachLevelOne) => {
                    if (eachLevelOne.IsSubOffering) {
                      return (
                        <li>
                          <Dropdown.Item
                            className={`${
                              selectedSubLevelOne.Process
                              === eachLevelOne.Process
                                ? 'active'
                                : ''
                            }`}
                            style={{ paddingLeft: '24px' }}
                            onClick={() => (selectedSubLevelOne.Process
                              === eachLevelOne.Process ? '' : onSubOfferingChange({ target: { value: eachLevelOne.ProcessID } }))}
                          >
                            {eachLevelOne.Process}
                          </Dropdown.Item>
                        </li>
                      );
                    }
                    const levelTwoData = eachLevelOne.SubLevel;
                    const selectedSubLevelTwo = (selectedSubOfferingTree.SubLevel && selectedSubOfferingTree
                      .SubLevel.length && selectedSubOfferingTree
                      .SubLevel[0].SubLevel.length)
                      ? selectedSubOfferingTree.SubLevel[0].SubLevel[0]
                      : {};
                    return (
                      <li className="nested-li">
                        <span
                          className={`caret ${
                            selectedSubLevelOne.Process === eachLevelOne.Process
                              ? 'caret-down'
                              : ''
                          }`}
                          style={{ paddingLeft: '24px' }}
                          onClick={this.createTogglerFunction}
                        >
                          {eachLevelOne.Process}
                        </span>
                        <ul
                          className={`nested-ul ${
                            selectedSubLevelOne.Process === eachLevelOne.Process
                              ? 'active'
                              : ''
                          }`}
                        >
                          {levelTwoData.map((eachLevelTwo) => {
                            if (eachLevelTwo.IsSubOffering) {
                              return (
                                <li>
                                  <Dropdown.Item
                                    className={`${
                                      selectedSubLevelTwo.Process
                                        === eachLevelTwo.Process
                                        ? 'active'
                                        : ''
                                    }`}
                                    style={{ paddingLeft: '48px' }}
                                    onClick={() => (selectedSubLevelTwo.Process
                                      === eachLevelTwo.Process ? '' : onSubOfferingChange({ target: { value: eachLevelTwo.ProcessID } }))}
                                  >
                                    {eachLevelTwo.Process}
                                  </Dropdown.Item>
                                </li>
                              );
                            }
                            const levelThreeData = eachLevelTwo.SubLevel;
                            const selectedSubLevelThree = (selectedSubOfferingTree.SubLevel && selectedSubOfferingTree
                              .SubLevel.length && selectedSubOfferingTree
                              .SubLevel[0].SubLevel.length && selectedSubOfferingTree
                              .SubLevel[0].SubLevel[0].SubLevel.length)
                              ? selectedSubOfferingTree.SubLevel[0]
                                .SubLevel[0].SubLevel[0]
                              : {};
                            return (
                              <li className="nested-li">
                                <span
                                  className={`caret ${
                                    selectedSubLevelTwo.Process
                                        === eachLevelTwo.Process
                                      ? 'caret-down'
                                      : ''
                                  }`}
                                  onClick={this.createTogglerFunction}
                                  style={{ paddingLeft: '48px' }}
                                >
                                  {eachLevelTwo.Process}
                                </span>
                                <ul
                                  className={`nested-ul ${
                                    selectedSubLevelTwo.Process
                                        === eachLevelTwo.Process
                                      ? 'active'
                                      : ''
                                  }`}
                                >
                                  {levelThreeData.map((eachLevelThree) => {
                                    if (eachLevelThree.IsSubOffering) {
                                      return (
                                        <li>
                                          <Dropdown.Item
                                            className={`${
                                              selectedSubLevelThree.Process
                                                  === eachLevelThree.Process
                                                ? 'active'
                                                : ''
                                            }`}
                                            style={{ paddingLeft: '72px' }}
                                            onClick={() => (selectedSubLevelThree.Process
                                              === eachLevelThree.Process ? '' : onSubOfferingChange({ target: { value: eachLevelThree.ProcessID } }))}
                                          >
                                            {eachLevelThree.Process}
                                          </Dropdown.Item>
                                        </li>
                                      );
                                    }
                                    const levelFourData = eachLevelThree.SubLevel;
                                    const selectedSubLevelFour = (selectedSubOfferingTree.SubLevel && selectedSubOfferingTree
                                      .SubLevel.length && selectedSubOfferingTree
                                      .SubLevel[0].SubLevel.length && selectedSubOfferingTree
                                      .SubLevel[0].SubLevel[0].SubLevel.length && selectedSubOfferingTree
                                      .SubLevel[0].SubLevel[0].SubLevel[0]
                                      .SubLevel.length)
                                      ? selectedSubOfferingTree
                                        .SubLevel[0].SubLevel[0]
                                        .SubLevel[0].SubLevel[0]
                                      : {};
                                    return (
                                      <li className="nested-li">
                                        <span
                                          className={`caret ${
                                            selectedSubLevelThree.Process
                                                  === eachLevelThree.Process
                                              ? 'caret-down'
                                              : ''
                                          }`}
                                          onClick={this.createTogglerFunction}
                                          style={{ paddingLeft: '72px' }}
                                        >
                                          {eachLevelThree.Process}
                                        </span>
                                        <ul
                                          className={`nested-ul ${
                                            selectedSubLevelThree.Process
                                                  === eachLevelThree.Process
                                              ? 'active'
                                              : ''
                                          }`}
                                        >
                                          {levelFourData.map(
                                            (eachLevelFour) => {
                                              if (
                                                eachLevelFour.IsSubOffering
                                              ) {
                                                return (
                                                  <li>
                                                    <Dropdown.Item
                                                      className={`${
                                                        selectedSubLevelFour.Process
                                                              === eachLevelFour.Process
                                                          ? 'active'
                                                          : ''
                                                      }`}
                                                      style={{
                                                        paddingLeft:
                                                                '96px',
                                                      }}
                                                      onClick={() => (selectedSubLevelFour.Process
                                                        === eachLevelFour.Process ? '' : onSubOfferingChange({ target: { value: eachLevelFour.ProcessID } }))}
                                                    >
                                                      {
                                                              eachLevelFour.Process
                                                            }
                                                    </Dropdown.Item>
                                                  </li>
                                                );
                                              }
                                            },
                                          )}
                                        </ul>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }
        })}

        {NoSubOffering
        && (
        <li>
          <Dropdown.Item
            onClick={(e) => onSubOfferingChange({ target: { value: 'Blank' } })}
            className={
                selectedSubOfferingId === null
                  ? 'active'
                  : false
              }
          >
            {i18n.t('No_Sub_Offering')}
          </Dropdown.Item>
        </li>
        )}
      </ul>
    );
  }
}

SubOffering.propTypes = {
  selectedSubOfferingId: PropTypes.number.isRequired,
  onSubOfferingChange: PropTypes.func.isRequired,
  subOfferings: PropTypes.array.isRequired,
};
export default SubOffering;
