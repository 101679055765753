import React, { Component } from 'react';
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { LocalApiBaseUrl, regexForFields } from '../Shared/Constant';
import _ from "lodash";
import classNames from "classnames";
import { connect } from "react-redux";
import { store } from "../reducers/configureStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faInfoCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import i18n from "i18next";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Modal from "react-bootstrap/Modal";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import Alert from "react-bootstrap/Alert";




const maxLengthMetricDefinitionFields = 500;

function validateMetricDefinitionFieldValue(str) {
	return regexForFields.test(str);
}

// THE FIELDS FOR A SINGLE METRIC DEFINITION
const singleMetricDefinition = {
	isDisabledNumeratorRadioField: false,
	selectedDefinition: "numeratorDefinition",
	numeratorDefinition: "",
	isRequiredNumeratorDefinitionField: false,
	errMsgNumeratorDefinitionField: "",
	denominatorDefinition: "",
	isRequiredDenominatorDefinitionField: false,
	errMsgDenominatorDefinitionField: "",
	isEnabled: "yes",
};

class AddMetricDetails_MTC extends Component {
	constructor(props) {
		super(props);
		this.ERR_MSG_METRIC_DEF_INVALID_VALUE = i18n.t("MultiDefErrMsg1") + " " + `@ $ % & () \n \- _ , . ? ‘ ” \' \"/` + " " + i18n.t("MultiDefErrMsg2");

		this.state = {
			show: this.props.show,
			MetricNameOptions: [],
			valueConnector: "",
			apivalue: "",
			unitsData:[],
			viewmore: false,
			errors: {},
			formIsValid: false,
			woData: {
				SOCategoryID: 0,
				kpiData: {
					KPIMappingID: null,
					MetricSeq: null,
					MetricID: 0,
					MetricName: "",
					FrequencyID: 0,
					MetricUnit: "",
					MetricImage: "",
					KPIDesp: "",
					MetricDataID: 0,
					MetricDataDesp: "",
					MetricValue: null,
					RAGStatus: "",
					UpperThreshold: "",
					LowerThreshold: "",
					Month: "",
					metDataAttr: null,
					LeadingPractice: "",
				},
			},
			// MULTIPLE METRIC DEFINITIONS WHICH ARE DYNAMICALLY ADDED
			metricDefinitions: [
				{
					..._.cloneDeep(singleMetricDefinition),
					isRequiredNumeratorDefinitionField:
						props.defaultIsRequiredMetricDefinitionFields,
					isRequiredDenominatorDefinitionField:
						props.defaultIsRequiredMetricDefinitionFields,
				},
			],
			areAnyDuplicateMetricDefinitions: false,
			isAPILoading: false,
		};
		this.onNameChange = this.onNameChange.bind(this);
		this.onDescriptionChange = this.onDescriptionChange.bind(this);
		this.onSequenceChange = this.onSequenceChange.bind(this);
		this.onUnitChange = this.onUnitChange.bind(this);
		this.handleHide = this.handleHide.bind(this);
		this.handleRejectHide = this.handleRejectHide.bind(this);
		this.handleApproveHide = this.handleApproveHide.bind(this);
		this.handleSave = this.handleSave.bind(this);

	}

	getKpiMAppingDetails() {
		let samp = {};
		let valueConnector = "";
		const data = {
			OrgEntityID: this.props.filter.Client,
			LocationID: this.props.filter.Locations,
			OfferingID: this.props.filter.offering,
			SOCategoryID: this.props.SOCategoryID,
		};
		axiosInstance
			.get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
				params: {
					OrgEntityID: this.props.filter.Client,
					LocationID: this.props.filter.Locations,
					OfferingID: this.props.filter.process,
					SOCategoryID: this.props.SOCategoryID,
				},
			})
			.then((response) => {
				if (response.data.length != 0) {
					if (
						response.data[0].IsConnector == true &&
						response.data[0].IsTransaction == false
					) {
						valueConnector = true;
					}
				}
				this.setState({
					MetricNameOptions: response.data,
					valueConnector: valueConnector,
				});
			})
			.catch((error) => {
				trycatchAlertPopup(error);
			  });
	  	  
	}

	onClose(e) {
		this.props.modalClose(e);
	}

	componentDidMount() {
		const { defaultIsRequiredMetricDefinitionFields, kpiData } = this.props;

		let metricDefinitions = [];
		const metricDefinitionsFromProps = _.get(
			this.props,
			"kpiData.MetricDefination",
			[]
		);

		if (this.props.kpiData) {
			if (!_.isEmpty(metricDefinitionsFromProps)) {
				metricDefinitions = _.map(metricDefinitionsFromProps, (eachItem) => {
					const metricDef = {
						..._.cloneDeep(singleMetricDefinition),
						isDisabledNumeratorRadioField: kpiData.MetricUnit === "%",
						selectedDefinition: !_.isEmpty(eachItem.DenominatorDescription)
							? "numeratorAndDenominatorDefinition"
							: "numeratorDefinition",
						numeratorDefinition: eachItem.NumeratorDescription,
						isRequiredNumeratorDefinitionField: defaultIsRequiredMetricDefinitionFields,
						denominatorDefinition: eachItem.DenominatorDescription,
						isRequiredDenominatorDefinitionField: defaultIsRequiredMetricDefinitionFields,
						isEnabled: eachItem.IsEnabled ? "yes" : "",
						KPIMetricDefinatoinID: _.get(
							eachItem,
							"KPIMetricDefinatoinID",
							null
						),
					};
					return metricDef;
				});
			} else {
				metricDefinitions = [
					{ ..._.cloneDeep(this.state.metricDefinitions[0]) },
				];
			}
			this.setState({
				woData: {
					kpiData: this.props.kpiData,
				},
				errors: {},
				formIsValid: true,
				metricDefinitions,
			});
		}
		let unitsData = this.props.unitsData;
		

		this.setState({unitsData:unitsData});
	}
	componentDidUpdate(prevProps){
		if(prevProps.unitsData !== this.props.unitsData){
			this.setState({unitsData:this.props.unitsData})
		}
	}
	onNameChange(event) {
		const kpiData = this.state.woData.kpiData;
		kpiData.MetricName = event.target.value;
		this.setState({
			woData: {
				kpiData: kpiData,
			},
		});
	}
	onDescriptionChange(event) {
		const kpiData = this.state.woData.kpiData;
		kpiData.KPIDesp = event.target.value;
		this.setState({
			woData: {
				kpiData: kpiData,
			},
		});
	}
	onSequenceChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		const kpiData = this.state.woData.kpiData;
		kpiData.MetricSeq = event.target.value;
		this.setState({
			woData: {
				kpiData: kpiData,
			},
		});
	}
	onUnitChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		const kpiData = this.state.woData.kpiData;
		kpiData.MetricUnit =event.target.options[event.target.selectedIndex].value;
		this.setState(
			{
				woData: {
					kpiData: kpiData,
				},
			},
			() => {
				this.clearMetricDefinitionFieldsErrors();
			}
		);
	}

	handleSave(e) {
		const { metricDefinitions } = this.state;
		if (this.validateForm()) {
			const woData = this.state.woData;
			woData.SOCategoryID = this.props.selectedSubStepID;
			let filterProcess = this.props.filter.process;
			woData.kpiData.SOCategoryID = this.props.selectedSubStepID;

			let SUCC_MSG = i18n.t("SUCC_MSG1");
			let ERR_MSG = i18n.t("ERR_MSG1");

			if (!_.isEmpty(this.props.kpiData)) {
				SUCC_MSG = i18n.t("SUCC_MSG2");
				ERR_MSG = i18n.t("ERR_MSG2");

			}

			// ADD MetricDefinitions to the request parameters
			const metricDefinitionsForRequest = _.map(
				metricDefinitions,
				(eachItem) => {
					const metricDef = {
						NumeratorDescription: eachItem.numeratorDefinition,
						DenominatorDescription:
							eachItem.selectedDefinition === "numeratorDefinition"
								? ""
								: eachItem.denominatorDefinition,
						IsEnabled: eachItem.isEnabled === "yes" ? "true" : "false",
					};
					if (
						!_.isEmpty(String(_.get(eachItem, "KPIMetricDefinatoinID", "")))
					) {
						// IF THE METRIC DEFINITION IS BEING EDITED
						metricDef.KPIMetricDefinatoinID = eachItem.KPIMetricDefinatoinID;
					}
					return metricDef;
				}
			);
			woData.kpiData.MetricDefination = metricDefinitionsForRequest;
			this.setState({ isAPILoading: true });
			axiosInstance
				.post(
					`${LocalApiBaseUrl}Master/PostKpiMetricMasterTemplate`,
					(woData.kpiData instanceof Array) ? Object.assign({},woData.kpiData) : woData.kpiData,
					{
						params: {
							OfferingID: filterProcess,
							LanguageID: store.getState().CurrentFilter.languageData.id,
						},
					}
				)
				.then((response) => {
					store.dispatch({
						type: "ADD_EDIT_WO_METRIC_MASTER",
						payload: response.data.kPIdata,
					});
					this.setState({ isAPILoading: false }, () => {
						alert(SUCC_MSG);
						this.handleHide();
					});
					this.props.getOfferingAPICallback(true);
				})
				.catch((error) => {
					this.setState({ isAPILoading: false }, () => {
						trycatchAlertPopup(error);
					});
					this.props.getOfferingAPICallback(false);
				});
		}
	}

	handleHide(e) {
		this.setState({ show: false });
		if (e != undefined) {
			if (e.target.textContent != "×") {
				this.props.modalClose(e);
			} else {
				this.props.modalClose(e);
			}
		} else {
			this.props.modalClose(e);
		}
	}
	handleRejectHide(e) {
		this.setState({ show: false });
		if (e != undefined) {
			if (e.target.textContent != "×") {
				this.props.onRejectClose();
			}
		} else {
			this.props.onRejectClose();
		}
	}
	handleApproveHide(e) {
		this.setState({ show: false });
		if (e != undefined) {
			if (e.target.textContent != "×") {
				this.props.onApproveClose();
			}
		} else {
			this.props.onApproveClose();
		}
	}
	passToConfirmation(data) {
		this.props.enteredData(data.PoNumber);
	}

	validateForm() {
		const { metricDefinitions } = this.state;
		const { defaultIsRequiredLeadingPractice } = this.props;

		const kpiData = this.state.woData.kpiData;
		let errors = {};
		let formIsValid = true;
		if (kpiData["MetricName"]) {
			kpiData["MetricName"] = kpiData["MetricName"].trim();
		}
		if (!kpiData["MetricName"]) {
			formIsValid = false;
			errors["MetricName"] = i18n.t("addWoErrorM1");

		}

		if (kpiData["MetricSeq"]) {
			kpiData["MetricSeq"] = parseInt(kpiData["MetricSeq"], 10);
		}
		if (!kpiData["MetricSeq"]) {
			formIsValid = false;
			errors["MetricSeq"] = i18n.t("addWoErrorM5");

		} else if (!(kpiData["MetricSeq"] >= 0 && kpiData["MetricSeq"] <= 999999)) {
			formIsValid = false;
			errors["MetricSeqRange"] = i18n.t("addWoErrorM6");

		}
		if (kpiData["MetricUnit"]) {
			kpiData["MetricUnit"] = kpiData["MetricUnit"].trim();
		}
		if (!kpiData["MetricUnit"]) {
			formIsValid = false;
			errors["MetricUnit"] = i18n.t("Unit_is_required");
		}

		// VALIDATE "LEADING PRACTICE" FIELD
		const LeadingPractice = kpiData.LeadingPractice;
		if (defaultIsRequiredLeadingPractice && _.isEmpty(LeadingPractice)) {
			formIsValid = false;
			errors["LeadingPractice"] = i18n.t("Leading_Practice_ErrMsg");
		}

		// VALIDATE "METRIC DEFINITIONS"
		const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem) => {
			const selectedDefinition = eachItem.selectedDefinition;
			let errMsgNumeratorDefinitionField = "";
			let errMsgDenominatorDefinitionField = "";

			const numeratorDefinition = String(eachItem.numeratorDefinition).trim();
			const denominatorDefinition = String(
				eachItem.denominatorDefinition
			).trim();

			// FOR `numeratorDefinitionField` CHECK IF THE FIELD IS REQUIRED AND ITS VALUE IS NOT EMPTY
			if (
				_.isEmpty(numeratorDefinition)
			) {
				errMsgNumeratorDefinitionField = i18n.t("NumeratorDefReqd");

			} else if (
				!_.isEmpty(numeratorDefinition) &&
				!validateMetricDefinitionFieldValue(numeratorDefinition)
			) {
				errMsgNumeratorDefinitionField = this.ERR_MSG_METRIC_DEF_INVALID_VALUE;
			}

			if (selectedDefinition === "numeratorAndDenominatorDefinition") {
				// IF (FIELD IS REQUIRED AND VALUE IS EMPTY) OR (`numeratorDefinition` IS NOT EMPTY BUT `denominatorDefinition` IS EMPTY)
				if (
					(_.isEmpty(denominatorDefinition)) ||
					(!_.isEmpty(numeratorDefinition) && _.isEmpty(denominatorDefinition))
				) {
					errMsgDenominatorDefinitionField = i18n.t("DenominatorDefReqd");

				}
				if (
					!_.isEmpty(denominatorDefinition) &&
					_.isEmpty(numeratorDefinition)
				) {
					errMsgNumeratorDefinitionField = "Numerator Definition is required";
					errMsgNumeratorDefinitionField = i18n.t("NumeratorDefReqd");

				}
			}

			if (
				!_.isEmpty(denominatorDefinition) &&
				!validateMetricDefinitionFieldValue(denominatorDefinition)
			) {
				errMsgDenominatorDefinitionField = this.ERR_MSG_METRIC_DEF_INVALID_VALUE;
			}
			eachItem.errMsgNumeratorDefinitionField = errMsgNumeratorDefinitionField;
			eachItem.errMsgDenominatorDefinitionField = errMsgDenominatorDefinitionField;

			// IF THERE WAS SOME ERROR IN DYNAMICALLY ADDED METRIC DEFINITIONS THEN SET THE FORM TO INVALID
			if (
				!_.isEmpty(errMsgNumeratorDefinitionField) ||
				!_.isEmpty(errMsgDenominatorDefinitionField)
			) {
				formIsValid = false;
			}

			eachItem.numeratorDefinition = numeratorDefinition;
			eachItem.denominatorDefinition = denominatorDefinition;
			return eachItem;
		});

		// CHECK FOR DUPLICATE METRIC DEFINITIONS
		let areAnyDuplicateMetricDefinitions = false;
		_.forEach(modifiedMetricDefinitions, function (eachMetDef, index) {
			const modifiedNumeratorDefinition = String(
				eachMetDef.numeratorDefinition
			).trim();
			const modifiedDenominatorDefinition = String(
				eachMetDef.denominatorDefinition
			).trim();

			// FOR ALL THE SELECTED RADIO OPTIONS "Numerator Definition", CHECK FOR DUPLICATE VALUES
			const eqNumField = _.find(modifiedMetricDefinitions, function (
				eachItem,
				indx
			) {
				const foundNumeratorDefinition = String(
					eachItem.numeratorDefinition
				).trim();

				if (
					indx > index &&
					eachMetDef.selectedDefinition === "numeratorDefinition" &&
					eachItem.selectedDefinition === "numeratorDefinition" &&
					!_.isEmpty(modifiedNumeratorDefinition) &&
					!_.isEmpty(foundNumeratorDefinition) &&
					modifiedNumeratorDefinition === foundNumeratorDefinition
				) {
					return true;
				}
			});
			if (eqNumField) {
				areAnyDuplicateMetricDefinitions = true;
			}

			// FOR ALL THE SELECTED RADIO OPTIONS "Numerator & Denominator Definition", CHECK FOR DUPLICATE VALUES
			const eqNumDenoField = _.find(modifiedMetricDefinitions, function (
				eachItem,
				indx
			) {
				const foundNumeratorDefinition = String(
					eachItem.numeratorDefinition
				).trim();
				const foundDenominatorDefinition = String(
					eachItem.denominatorDefinition
				).trim();

				if (
					indx > index &&
					eachMetDef.selectedDefinition ===
					"numeratorAndDenominatorDefinition" &&
					eachItem.selectedDefinition === "numeratorAndDenominatorDefinition" &&
					!_.isEmpty(modifiedNumeratorDefinition) &&
					!_.isEmpty(foundNumeratorDefinition) &&
					modifiedNumeratorDefinition === foundNumeratorDefinition &&
					!_.isEmpty(modifiedDenominatorDefinition) &&
					!_.isEmpty(foundDenominatorDefinition) &&
					modifiedDenominatorDefinition === foundDenominatorDefinition
				) {
					return true;
				}
			});
			if (eqNumDenoField) {
				areAnyDuplicateMetricDefinitions = true;
			}
		});
		// IF THERE WERE ANY DUPLICATE METRIC DEFINITIONS THEN SET THE FORM VALID STATUS TO FALSE
		if (areAnyDuplicateMetricDefinitions) {
			formIsValid = false;
		}

		this.setState({
			errors: errors,
			metricDefinitions: modifiedMetricDefinitions,
			areAnyDuplicateMetricDefinitions,
		});
		if (formIsValid) {
			this.setState({
				formIsValid: true,
				errors: {},
			});
		} else {
			this.setState({
				formIsValid: false,
			});
		}
		return formIsValid;
	}

	// EXECUTED WHEN THE "LeadingPractice" FIELD VALUE WOULD BE CHANGED
	handleChangeLeadingPractice = (e) => {
		const LeadingPractice = e.target.value;
		this.setState((prevState) => ({
			woData: {
				...prevState.woData,
				kpiData: {
					...prevState.woData.kpiData,
					LeadingPractice,
				},
			},
		}));
	};

	/**
	 * When the unit of metric is "%" definition of both numerator & denominator should be mandatory.
	 * Numerator and Denominator fields are mandatory fields for BO, II & DD page. For  H&M & WO page keep them optional.
	 * So Disable "numerator" (radio) selection based on above condition.
	 */
	toggleRequiredMetricDefinitionFields = () => {
		const { defaultIsRequiredMetricDefinitionFields } = this.props;
		const {
			woData: { kpiData },
			metricDefinitions,
		} = this.state;
		const MetricUnit = kpiData.MetricUnit;

		let areRequiredMetricDefinitionFields = defaultIsRequiredMetricDefinitionFields;

		let isDisabledNumeratorDefinitionField = false;
		if (MetricUnit === _.trim("%")) {
			areRequiredMetricDefinitionFields = true;
			isDisabledNumeratorDefinitionField = true;
		}

		// FOR ALL THE DYNAMICALLY ADDED METRIC DEFINITIONS, TOGGLE DISABLE OF THE "NUMERATOR RADIO" FIELD
		const modifiedMetricDefinitions = _.map(
			metricDefinitions,
			(eachItem, index) => {
				eachItem.isDisabledNumeratorRadioField = isDisabledNumeratorDefinitionField;
				// IF THE "NUMERATOR RADIO" FIELD IS TO BE DISABLED, THEN SET SELECTED "NUMERATOR AND DENOMINATOR RADIO" FIELD
				if (isDisabledNumeratorDefinitionField) {
					eachItem.selectedDefinition = "numeratorAndDenominatorDefinition";
				}
				eachItem.isRequiredNumeratorDefinitionField = areRequiredMetricDefinitionFields;
				eachItem.isRequiredDenominatorDefinitionField = areRequiredMetricDefinitionFields;
				return {
					...eachItem,
				};
			}
		);
		this.setState({
			metricDefinitions: modifiedMetricDefinitions,
		});
	};

	// DYNAMICALLY ADDS A NEW ROW FOR ADDING METRIC DEFINITION
	addMetricDefinitionRow = () => {
		const { defaultIsRequiredMetricDefinitionFields } = this.props;
		const { metricDefinitions } = this.state;
		const clonedSingleMetricDefinition = _.cloneDeep(singleMetricDefinition);

		clonedSingleMetricDefinition.isRequiredNumeratorDefinitionField = defaultIsRequiredMetricDefinitionFields;
		clonedSingleMetricDefinition.isRequiredDenominatorDefinitionField = defaultIsRequiredMetricDefinitionFields;

		const currentMetricDefinitions = _.cloneDeep(metricDefinitions);
		currentMetricDefinitions.push(clonedSingleMetricDefinition);
		this.setState(
			{
				metricDefinitions: currentMetricDefinitions,
			},
			() => {
			}
		);
	};

	// REMOVES THE SELECTED DYNAMICALLY ADDED METRIC DEFINITION SECTION
	removeMetricDefinitionRow = (rowIndex) => {
		const { metricDefinitions } = this.state;
		const remainingMetricDefinitions = _.filter(
			metricDefinitions,
			(eachItem, index) => {
				return index !== rowIndex;
			}
		);
		this.setState({
			metricDefinitions: remainingMetricDefinitions,
		});
	};

	// EXECUTES WHEN THE ANY OF THE FIELD'S VALUE OF DYNAMICALLY ADDED METRIC DEFINITION WOULD BE CHANGED
	handleChangeMetricDefinitionField = (e, rowIndex, fieldName) => {
		const { metricDefinitions } = this.state;
		let fieldValue = e.target.value;
		if (fieldName === "isEnabled") {
			fieldValue = e.target.checked ? "yes" : "";
		}
		const modifiedMetricDefinitions = _.map(
			metricDefinitions,
			(eachItem, index) => {
				if (index === rowIndex) {
					eachItem[fieldName] = fieldValue;
				}
				return {
					...eachItem,
				};
			}
		);
		this.setState({
			metricDefinitions: modifiedMetricDefinitions,
		});
	};

	// REMOVES THE ERRORS OF DYNAMICALLY ADDED METRIC DEFINITIONS
	clearMetricDefinitionFieldsErrors = () => {
		const { metricDefinitions } = this.state;
		const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem) => {
			return {
				...eachItem,
				errMsgNumeratorDefinitionField: "",
				errMsgDenominatorDefinitionField: "",
			};
		});

		this.setState({
			metricDefinitions: modifiedMetricDefinitions,
		});
	};


	render() {
		const { defaultIsRequiredLeadingPractice } = this.props;
		const {metricDefinitions,areAnyDuplicateMetricDefinitions,woData: { kpiData },isAPILoading} = this.state;

		return (
			<>
				{isAPILoading && (
					<LoadingOverlay
						fadeSpeed={0}
						spinner={<SynopsLoader />}
						active
					/>
				)}
				<Modal show={this.state.show}
					onHide={this.props.modalClose}
					className="tooldata-pop"
					id="dealpopup"
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>
						{this.props.isScreenInEditMode ? i18n.t("Edit Metric") : i18n.t("Add Metric") }-{this.props.metricDesp}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="form-section">
								<div className="row">
									<div className="form-group col-lg-7">
										<label for="name">Metric Name<span className="required">*</span>
										</label>
										<input
											type="text"
											maxLength="100"
											className="form-control"
											id="name"
											onChange={this.onNameChange}
											value={this.state.woData.kpiData.MetricName}
											disabled={this.props.isScreenInEditMode}
										/>
										<span className="errorMsg">
											{this.state.errors.MetricName}
										</span>
									</div>
									<div className="form-group col-lg-5">
										<label for="unit">{i18n.t("Unit_of_Measure")}<span className="required">*</span>
										</label>
										<select className="form-control" value={this.state.woData.kpiData.MetricUnit}
											disabled={this.props.isScreenInEditMode} onChange={this.onUnitChange} >
									<option value="">{i18n.t("Please_Select_Unit")}</option>
										{this.state.unitsData.map((each)=>{
											return <option value={each.MetricUnit_En}>{each.MetricUnit}</option>
										})}
									</select>
										
										<span className="errorMsg">
											{this.state.errors.MetricUnit}
										</span>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-lg-12">
										<label for="description">{i18n.t("Description")}
										</label>
										<input
											id="description"
											maxlength="255"
											type="text"
											className="form-control"
											onChange={this.onDescriptionChange}
											value={this.state.woData.kpiData.KPIDesp}
										/>
									</div>
								</div>
								
								<div className="row">
									<div className="form-group col-lg-6">
										<label>
											{i18n.t("Sequence")} <span className="required">*</span>
										</label>
										<input
											type="number"
											className="form-control"
											onChange={this.onSequenceChange}
											value={this.state.woData.kpiData.MetricSeq}
										/>
										<span className="errorMsg">
											{this.state.errors.MetricSeq}
										</span>
										<div className="errorMsg">
											{this.state.errors.MetricSeqRange}
										</div>
									</div>

									<div className="form-group col-lg-6">
										<label>
											{i18n.t("Leading_Practice")}
											{defaultIsRequiredLeadingPractice && (
												<span className="required"> *</span>
											)}
										&nbsp;
										<OverlayTrigger
												key="top"
												placement="top"
												overlay={
													<Tooltip id="tooltip-top">
														{i18n.t("addBOMess2")}
													</Tooltip>
												}
											>
												<FontAwesomeIcon icon={faInfoCircle} />
											</OverlayTrigger>
										</label>
										<select
											value={kpiData.LeadingPractice}
											disabled={store.getState().CurrentFilter.languageData.id !== 1}
											onChange={(e) => this.handleChangeLeadingPractice(e)}
											className="form-control"
										>
											<option value="">
												{i18n.t("Select_Leading_Practice")}
											</option>
											<option value={i18n.t("Maximum")}>
												{i18n.t("Maximum")}
											</option>
											<option value={i18n.t("Minimum")}>
												{i18n.t("Minimum")}
											</option>
										</select>
										<div className="errorMsg">
											{this.state.errors.LeadingPractice}
										</div>
									</div>
								</div>
								{/** Check for duplicate values */}
								{areAnyDuplicateMetricDefinitions && (
									<div className="row">
										<div className="col">
											<Alert variant="danger">
												{i18n.t("DuplicateMetricDefinitionAlert")}
											</Alert>
										</div>
									</div>
								)}
								{/** */}
								<div className="row">
									<div className="col-lg-12">
										<div className="title-with-icon">
											<h6 className="wo-title">Metric Definition</h6>
											<div className="list-icon">
												{store.getState().CurrentFilter.languageData.id === 1 ? (
													<span
														id="rock"
														className="help-icon"
														onClick={() => this.addMetricDefinitionRow()}
													>
														<FontAwesomeIcon icon={faPlus} />
                                                    Add Definition
													</span>
												) : (
														""
													)}
											</div>
										</div>
									</div>
								</div>
								
								<div>
									{_.map(metricDefinitions, (eachMetricDefinition, index) => {
										const selectedDefinition =
											eachMetricDefinition.selectedDefinition;
										const isVisibleDenominatorDefinition =
											selectedDefinition === "numeratorAndDenominatorDefinition";
										return (
											<div className="mb-3" key={`definition_${index}`}>
												<div className="row">
													<div className="form-group col-lg-12 mb-1 definition-align">
														<label className="form-check mr-5">
															<input
																type="radio"
																name={`definition_${index}`}
																value="numeratorDefinition"
																onChange={(e) =>
																	this.handleChangeMetricDefinitionField(
																		e,
																		index,
																		"selectedDefinition"
																	)
																}
																checked={
																	selectedDefinition === "numeratorDefinition"
																}
															
																className="form-check-input"
															/>
															<span className="checkmark" />
															<span className="form-check-label">
																{i18n.t("Numerator_Definition")}
															</span>
														</label>

														<label className="form-check">
															<input
																type="radio"
																name={`definition_${index}`}
																value="numeratorAndDenominatorDefinition"
																onChange={(e) =>
																	this.handleChangeMetricDefinitionField(
																		e,
																		index,
																		"selectedDefinition"
																	)
																}
																checked={
																	selectedDefinition ===
																	"numeratorAndDenominatorDefinition"
																}

																className="form-check-input"
															/>
															<span className="checkmark" />
															<span className="form-check-label">
																{i18n.t("Numerator&Denominator_Definition")}
															</span>
														</label>

														<>
														<label className="add-definition-delete-toggleBtn">
														{store.getState().CurrentFilter.languageData.id === 1 && (

																<div className="toggle-group">
																	<input
																		type="checkbox"
																		value="yes"
																		checked={
																			String(eachMetricDefinition.isEnabled) ===
																			"yes"
																		}
																		onChange={(e) =>
																			this.handleChangeMetricDefinitionField(
																				e,
																				index,
																				"isEnabled"
																			)
																		}
																		id={`isEnabled_${index}`}
																	/>
																	<label htmlFor={`isEnabled_${index}`} />
																	<div className="onoffswitch" aria-hidden="true">
																		<div className="onoffswitch-label">
																			<div className="onoffswitch-inner" />
																			<div className="onoffswitch-switch" />
																		</div>
																	</div>
																</div>
														)}
														{_.isNil(
																_.get(
																	eachMetricDefinition,
																	"KPIMetricDefinatoinID",
																	null
																)
															) &&
																(store.getState().CurrentFilter.languageData
																	.id === 1 ? (
																		<span
																			className="del-row-icon list-icon"
																			onClick={() =>
																				this.removeMetricDefinitionRow(index)
																			}
																		>
																			<FontAwesomeIcon icon={faTrashAlt} />
																		</span>
																	) : (
																		""
																	))}
														</label>
														</>

													</div>
												</div>

												<div className="row">
													<div
														className={classNames(
															"form-group",
															{ "col-lg-6": isVisibleDenominatorDefinition },
															{ col: !isVisibleDenominatorDefinition }
														)}
													>
														<label>
															{i18n.t("Numerator_Definition")}

															<span className="required"> *</span>

														</label>
														<input
															type="text"
															value={eachMetricDefinition.numeratorDefinition}
															onChange={(e) =>
																this.handleChangeMetricDefinitionField(
																	e,
																	index,
																	"numeratorDefinition"
																)
															}
															className="form-control"
															maxLength={maxLengthMetricDefinitionFields}
														/>
														<div className="errorMsg">
															{
																eachMetricDefinition.errMsgNumeratorDefinitionField
															}
														</div>
													</div>

													{isVisibleDenominatorDefinition && (
														<div className="form-group col-lg-6">
															<label>
																{i18n.t("Denominator_Definition")}

																<span className="required"> *</span>

															</label>
															<input
																type="text"
																value={eachMetricDefinition.denominatorDefinition}
																onChange={(e) =>
																	this.handleChangeMetricDefinitionField(
																		e,
																		index,
																		"denominatorDefinition"
																	)
																}
																className="form-control"
																maxLength={maxLengthMetricDefinitionFields}
															/>
															<div className="errorMsg">
																{
																	eachMetricDefinition.errMsgDenominatorDefinitionField
																}
															</div>
														</div>
													)}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</form>
					</Modal.Body>
					<Modal.Footer>
						<div className="modal-btn">
							<button
								onClick={this.props.modalClose}
								className="btn btn-default brder-2 btn-sm mr-2"
							>
								{i18n.t("Cancel")}
							</button>

							<input
								type="Button"
								className="btn btn-primary btn-sm"
								onClick={() => this.handleSave()}
								value={
									this.props.isScreenInEditMode ? i18n.t("Update") : i18n.t("Add")
								}
							/>
						</div>


					</Modal.Footer>
				</Modal>
			</>

		)
	}

}

AddMetricDetails_MTC.defaultProps = {
	screenName: "WorkOrchestration",
	defaultIsRequiredLeadingPractice: false,
	defaultIsRequiredMetricDefinitionFields: false,
};
const mapStateToProps = (state) => {
    return ({
		languageData: state.CurrentFilter.languageData,
		unitsData: state.BOMasterReducers.unitsData
    })
}
export default connect(mapStateToProps)(AddMetricDetails_MTC);