import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { AgGridReact } from "ag-grid-react";
import GetDataPopup from "./GetDataPopup";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import moment from "moment";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class GetDataList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewmore: false,
      rowData: [],
      isApiLoading: false,
      columnDefs: [
        {
          headerName: "Source Client Name",
          field: "SourceClientName",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Source Offering",
          field: "SourceOfferingName",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Source Sub-Offering",
          field: "SourceSubOfferingName",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Source Location",
          field: "SourceLocationName",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Source Month",
          field: "SourceMonthDT",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Destination Client Name",
          field: "TargetClientName",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Destination Offering",
          field: "TargetOfferingName",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Destination Sub-Offering",
          field: "TargetSubOfferingName",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Destination Location",
          field: "TargetLocationName",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Destination Month",
          field: "TargetMonthDT",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Status",
          field: "Status",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Created By",
          field: "CreatedBy",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Created TS",
          field: "CreatedTS",
          suppressMovable: true,
          resizable: true,
          suppressSizeToFit: true,
        },
      ],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        defaultColDef: {
          sortable: true,
          filter: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  getDataHistory() {
    axiosInstance
      .get(`${LocalApiBaseUrl}CopyProfileData/GetCopyProfileDataHistory`)
      .then((response) => {
        let data = response.data;
        if (Object.keys(data).length > 0) {
          Object.keys(data).forEach((eachdata) => {
            data[eachdata].SourceMonthDT = moment(data[eachdata].SourceMonthDT).format("MMMM-YYYY");
            data[eachdata].TargetMonthDT = moment(data[eachdata].TargetMonthDT).format("MMMM-YYYY");
            data[eachdata].CreatedTS = moment
              .utc(data[eachdata].CreatedTS)
              .local()
              .format("DD-MMMM-YYYY HH:mm:ss A");
          });
        }
        this.setState({
          rowData: data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getDataPopup(e) {
    e.preventDefault();
    this.setState({ viewmore: true });
  }

  onClose() {
    this.setState({ viewmore: false });
  }

  onCopy = (CopyProfileDataModel) => {
    this.setState({ isApiLoading: true });

    axiosInstance
      .post(`${LocalApiBaseUrl}CopyProfileData/CopyProfileData`, CopyProfileDataModel)
      .then((response) => {
        window.alert(response.data.StatusMessage);
        if (response.data.IsSuccess) {
          this.onClose();
          this.setState({ isApiLoading: false });
          this.getDataHistory();
        } else {
          this.setState({ isApiLoading: false });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ isApiLoading: false });
        this.getDataHistory();
      });
  };

  componentDidMount() {
    this.getDataHistory();
  }

  render() {
    const { isApiLoading, gridOptions, columnDefs, rowData, viewmore } = this.state;
    return (
      <>
        {isApiLoading && <LoadingOverlay active={isApiLoading} spinner={<SynopsLoader />}></LoadingOverlay>}
        <div className="main-data">
          <div className="tableFilter">
            <div className="form-section">
              <div className="form-row">
                {this.props.featureData.filter(
                  (data) => String(data.Submenu) === "Get Data" && data.Operations.includes("Add")
                ).length > 0 ? (
                  <div className="form-group col-lg-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={(e) => {
                        this.getDataPopup(e);
                      }}
                    >
                      <span>
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </span>{" "}
                      Profile Mapping
                    </button>
                  </div>
                ) : (
                  <div> </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ag-theme-balham ag-table">
                <AgGridReact
                  pagination={true}
                  paginationPageSize={50}
                  floatingFilter={true}
                  gridOptions={gridOptions}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                ></AgGridReact>
              </div>
            </div>
          </div>
          {viewmore ? (
            <GetDataPopup rowData={rowData} show={viewmore} onClose={this.onClose} onCopy={this.onCopy} />
          ) : null}
        </div>
      </>
    );
  }
}
