import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { AgGridReact } from "ag-grid-react";
import i18n from "i18next";
import { connect } from "react-redux";
import UnitPopup from "./UnitPopup";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class UnitList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      rowSelected: [],
      columnDefs: [
        {
          headerName: i18n.t("Unit_of_Measure"),
          field: "MetricUnit",
          suppressMovable: true,
          resizable: false,
        },
        {
          headerName: i18n.t("Requestor_Remarks"),
          field: "RequestorRemarks",
          resizable: false,
        },
      ],
      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",
        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }
  dealpopup(e, data) {
    if (
      this.props.featureData.filter(
        (data) =>
          String(data.Submenu) === "Unit of Measurement" &&
          data.Operations.includes("Add")
      ).length > 0
    ) {
      e.preventDefault();
      this.setState({ viewmore: true, rowSelected: data });
    } else window.alert(i18n.t("Acces_Denied"));
  }
  onClose(e) {
    this.setState({ viewmore: false });
  }

  getUom() {
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetAllUnits`, {
        params: { languageId: this.props.languageData.id },
      })
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    this.getUom();
  }

  handleSave(data) {
    axiosInstance
      .post(`${LocalApiBaseUrl}SOFilter/AddMetricUnit`, data)
      .then(
        (response) => {
          window.alert(response.data.StatusMessage);
          this.getUom();
        },
        (error) => {
            trycatchAlertPopup(error);
        }
      )
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  render() {
    const { featureData } = this.props;
    const {
      gridOptions,
      columnDefs,
      rowData,
      viewmore,
      rowSelected,
    } = this.state;
    return (
      <div className="main-data">
        <div className="tableFilter">
          <div className="form-section">
            <div className="form-row">
              <div className="form-group col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  id="filter-text-box"
                  placeholder={i18n.t("Filter") + "..."}
                  onChange={(e) => {
                    this.onFilterTextBoxChanged(e);
                  }}
                />
              </div>
              {featureData.filter(
                (data) =>
                  String(data.Submenu) === "Unit of Measurement" &&
                  data.Operations.includes("Add")
              ).length > 0 && (
                  <div className="form-group col-lg-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={(e) => {
                        this.dealpopup(e);
                      }}
                    >
                      <span>
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </span>{" "}
                      {i18n.t("Add_Unit")}
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ag-theme-balham ag-table">
              <AgGridReact
                enableSorting={true}
                enableFilter={true}
                pagination={true}
                paginationPageSize={50}
                floatingFilter={true}
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                rowData={rowData}
                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
              ></AgGridReact>
            </div>
          </div>
        </div>
        {viewmore ? (
          <UnitPopup
            rowData={rowData}
            data={rowSelected}
            show={viewmore}
            onClose={this.onClose}
            onSave={this.handleSave}
          />
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
  };
};
export default connect(mapStateToProps)(UnitList);
