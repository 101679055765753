import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import DayWiseGraph from './DayWiseGraph';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl,idTokenstring } from '../Shared/Constant';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;


export default class DayWiseTrends extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dsKpiData: [],
      shown: false,
      Report: {
        StartDate: '',
      },
    };

    this.validateForm = this.validateForm.bind(this);
    this.GetDayData = this.GetDayData.bind(this);
  }

  componentDidMount() { }

  onButtonClick() {
    if (this.validateForm()) {
      this.GetDayData();
    }
  }

  onStartDateChange = (event) => {
    const { Report } = this.state;
    const tempReport = Report;
    tempReport.StartDate = event;

    this.setState({ StartDate: event });
    this.setState({
      Report: tempReport,
    });
  };

  GetDayData() {
    const { Report } = this.state;
    const date = Moment(Report.StartDate).format('YYYY-MM-DD');
    this.setState({
      shown: true,
    });
    axiosInstance
      .get(`${LocalApiBaseUrl}TrendReports/GetDayWiseVisitor?`, {
        params: { date },
      })
      .then((res) => {
        const UserIDLoginCount = [];
        const DatePeriod = [];
        res.data.forEach((element) => {
          UserIDLoginCount.push(element.UserIDLoginCount);
          DatePeriod.push(element.TimePeriod);
        });
        this.setState({
          dsKpiData: {
            labels: DatePeriod,
            datasets: [
              {
                data: UserIDLoginCount,
                fill: false,
                lineTension: 0.1,
                animationEnabled: true,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                theme: 'light2',
                vline: 'true',
              },
            ],
          },
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  validateForm() {
    const { Report } = this.state;
    let formIsValid = true;

    if (!Report.StartDate) {
      formIsValid = false;
    }
    return formIsValid;
  }

  render() {
    const {
      StartDate, shown, dsKpiData, Report,
    } = this.state;

    return (
      <>
        <div className="main-data">
          <div className="row">
            <div className="form-group col-lg-4 block-picker">
              <label className="datepicker-label" htmlFor="">
                Date
                <span className="required" />
              </label>
              <DatePicker
                className="form-control"
                selected={StartDate}
                autoComplete="off"
                id="StartDate"
                onChange={this.onStartDateChange}
                value={Report.StartDate}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <button className="btn btn-primary btn-sm" type="button" id="btn2" onClick={() => this.onButtonClick()}>
                Show Trends
              </button>
            </div>
          </div>
        </div>
        {shown ? <DayWiseGraph shown={shown} dsKpiData={dsKpiData} /> : null}
      </>
    );
  }
}
