/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-concat */
import React from 'react';
import {
  Modal, Tooltip, OverlayTrigger, Form,
} from 'react-bootstrap';
import _ from 'lodash';
import i18n from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import {
  LocalApiBaseUrl,
  regexForNumbersOnlyMTC,
  regexForNegativeNumbers,
  regexForFields, trycatchAlertPopup, regexForTextAreas,
} from '../Shared/Constant';

import { store } from '../reducers/configureStore';
import axiosInstance from '../Shared/interceptor';

import KpiPopUp from './KpiPopUp';

import { FormatDate, isValidText, InvalidDescription } from '../Shared/commonFunctions';
import { MetricValueThousandFormat, thousandSeparatorFormat } from '../Shared/Common';

export default class BO2_popup extends React.Component {
  constructor(props) {
    super(props);
    const {
      show, SelectValue, isRelatorBlock, attributes,
    } = this.props;
    this.state = {
      filter: {
        OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null,
      },
      show,
      showCategory: false,
      showaddCategory: false,
      iscatypedisable: false,
      isEdit: false,
      showaddCategoryType: false,
      showApproval: false,
      categories: [],
      CategoryDetails: { CategoryType: '', CategoryName: '' },
      apivalue: '',
      selectDropValue: '',
      selectCattypeValue: '',
      selectValue: SelectValue,
      Display: false,
      viewmore: false,
      CatNameID: 0,
      CatTypeID: 0,
      numeratorVal: 0,
      denomVal: 0,
      EditIndex: 0,
      isAddCatPopup: false,
      MetricNameOptions: [],
      criteriaOptions: [],
      valueConnector: false,
      con: false,
      valueEdit: false,
      valueConnectorEdit: false,
      errors: {},
      commentsError: '',
      formIsValid: false,
      IsBatDealLevelForOffering: false,
      KpiMetricConnectorID: false,
      allCategoryTypesAndNames: [],
      CategoryNameData: [],
      BaselineMonthReadOnly: true,
      categoriesLimit: 30, // Default
      data: {
        SOCategoryID: 0,
        kpiData: {
          KPIMappingID: 0,
          isRelatorBlock,
          FormulaList: [],
          formulaStr: '',
          MetricSeq: null,
          MetricID: null,
          valueExist: false,
          LeadingAndSimilarCheck: false,
          MetricName: '',
          FrequencyID: null,
          MetricUnit: '',
          MetricImage: '',
          KPIDesp: '',
          MetricDataID: null,
          Comments: '',
          MetricValue: '',
          RAGStatus: '',
          BaselineValue: '',
          BaseLineMonth: '',
          TargetValue: '',
          UpperThreshold: '',
          LeadingIndustry: '',
          SimilarIndustry: '',
          LowerThreshold: '',
          LeadingPractice: '',
          Month: '',
          metDataAttr: [
            {
              AttributeName:
                attributes.filter((z) => z.ApplicableForDD === false).length > 0
                  ? attributes.filter((z) => z.ApplicableForDD === false)[0].AttributeName1
                  : '',
              AttributeValue: '',
            },
            {
              AttributeName:
                attributes.filter((z) => z.ApplicableForDD === false).length > 0
                  ? attributes.filter((z) => z.ApplicableForDD === false)[0].AttributeName2
                  : '',
              AttributeValue: '',
            },
          ],
          ConnectorID: '',
          ConnectorNames: '',
          CriteriaID: '',
          SourceFieldName: '',
          SourceName: '',
          IsAccentureOwned: false,
          MetricDefination: [],
          MetricDefinitionID: '',
          CategoryDetails: [],
          IsMultiLevel: false,
          IsAggregation: false,
        },
      },
    };
    this.validateForm = this.validateForm.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSequenceChange = this.onSequenceChange.bind(this);
    this.onUnitChange = this.onUnitChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onValDescChange = this.onValDescChange.bind(this);
    this.onUpperChange = this.onUpperChange.bind(this);
    this.onLowerChange = this.onLowerChange.bind(this);
    this.maxLengthCheckThreshold = this.maxLengthCheckThreshold.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
    this.onSourceSystemChange = this.onSourceSystemChange.bind(this);
    this.onOtherSystemChange = this.onOtherSystemChange.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleRejectHide = this.handleRejectHide.bind(this);
    this.handleApproveHide = this.handleApproveHide.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onToolOwnerChange = this.onToolOwnerChange.bind(this);
    this.getGetConnectorMaster = this.getGetConnectorMaster.bind(this);
    this.onLeadingIndustryChange = this.onLeadingIndustryChange.bind(this);
    this.onSimilarIndustryChange = this.onSimilarIndustryChange.bind(this);
    this.handleCategoryTypeAddShow = this.handleCategoryTypeAddShow.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCategoryShow = this.handleCategoryShow.bind(this);
    this.handleCategoryEditShow = this.handleCategoryEditShow.bind(this);
    this.handleCategoryEdit = this.handleCategoryEdit.bind(this);
    this.handleCategoryClose = this.handleCategoryClose.bind(this);
    this.handleCategoryAdd = this.handleCategoryAdd.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleaddCategoryShow = this.handleaddCategoryShow.bind(this);
    this.handleaddCategoryClose = this.handleaddCategoryClose.bind(this);
    this.deleteCategoryType = this.deleteCategoryType.bind(this);
    this.editCategoryName = this.editCategoryName.bind(this);
    this.deleteCategoryName = this.deleteCategoryName.bind(this);
    this.handleaddCategoryNameClose = this.handleaddCategoryNameClose.bind(this);
    this.handleaddCategoryAdd = this.handleaddCategoryAdd.bind(this);
    this.handleApprovalClose = this.handleApprovalClose.bind(this);
    this.CatTypeSelect = this.CatTypeSelect.bind(this);
    this.getKpiMAppingDetails = this.getKpiMAppingDetails.bind(this);
    this.onBaselineValueChange = this.onBaselineValueChange.bind(this);
    this.onBaselineMonthChange = this.onBaselineMonthChange.bind(this);
    this.onTargetValueChange = this.onTargetValueChange.bind(this);
  }

  deleteCategoryName(catNameToDelete) {
    this.setState({ errors: {} });
    const { CatTypeID } = this.state;
    const { data, valueConnectorEdit, valueConnector } = this.state;
    const { kpiData } = data;
    const allCategoriesList = data.kpiData.CategoryDetails;
    const { CategoryNameData } = this.state;
    allCategoriesList.splice(
      allCategoriesList.findIndex((item) => item.CategoryName === catNameToDelete),
      1,
    );
    if (allCategoriesList.length === 0) {
      kpiData.MetricValue = '';
    }

    this.setState({
      CatNameID: 0,
      CategoryNameData: allCategoriesList.length === 0 ? [] : CategoryNameData,
      CatTypeID, // CHANGED
      data: {
        kpiData,
      },
    });
    if (
      kpiData.IsAggregation
      && !valueConnectorEdit
      && !valueConnector
      && !this.isSelectedMetricMappedToConnector
    ) {
      // calculate the metric value on the fly when new values added/ edited/deleted
      this.calculateAggregateValueByFormula();
    }
  }

  editCategoryName(item) {
    this.setState({
      showCategoryNameEditPopup: true,
    });
  }

  deleteCategoryType() {
    if (window.confirm(i18n.t('delete_Category_msg'))) {
      const { data, valueConnectorEdit, valueConnector } = this.state;
      const { kpiData } = data;
      kpiData.CategoryDetails = [];
      if (
        kpiData.IsAggregation
        && !valueConnectorEdit
        && !valueConnector
        && !this.isSelectedMetricMappedToConnector
      ) {
        // if there are no categories, clear out the value calculated using the formula
        kpiData.MetricValue = '';
      }

      this.setState({
        CatNameID: 0,
        CatTypeID: 0,
        CategoryNameData: [],
        data: {
          kpiData,
        },
      });
    }
  }

  onClose(e) {
    this.setState({ viewmore: false });
    this.props.onClose(e);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.callingFunction();
    }
  }

  callingFunction = async () => {
    await this.getKpiMAppingDetails();
    const { allCategoryTypesAndNames } = this.state;
    if (this.props.kpiData) {
      const kpiData = { ...this.props.kpiData };
      const CatTypeID = kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0
        ? kpiData.CategoryDetails[0].OfferingMultiLevelCategoryID
        : 0;

      const CatType = kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0
        ? kpiData.CategoryDetails[0].CategoryType
        : 0;

      const CategoryNameData = CatType && String(CatType) !== ''
        ? allCategoryTypesAndNames.filter(
          (eachValue) => String(eachValue.CategoryType) === String(CatType),
        )
        : []; // FETCHING CATEGORY NAME

      const { CategoryDetails } = this.state;
      CategoryDetails.CategoryType = CatType;
      this.setState({
        CategoryNameData,
        CategoryDetails,
        CatTypeID,
        iscatypedisable: true,
      });
    }
  };

  getCategoriesLimit = () => {
    const data = store.getState().CurrentFilter.multiLevelCategoriesLimit;
    this.setState({
      categoriesLimit: data,
    });
  };

  componentDidMount() {
    this.getGetConnectorMaster();
    this.callingFunction();
    this.getCategoriesLimit();
    const {
      attributes, isRelatorBlock, kpiData, responseMetricData,
    } = this.props;
    const data = store.getState();

    this.setState({ IsBatDealLevelForOffering: data.CurrentFilter.IsBatDealLevel }, () => {
      if (kpiData) {
        const attributeLabelsForBO = attributes.length > 0 ? attributes.filter((z) => z.ApplicableForDD === false) : [];

        const kpiData = _.cloneDeep({ ...this.props.kpiData });

        if (kpiData.CategoryDetails && kpiData.CategoryDetails.length === 0) {
          kpiData.IsMultiLevel = false;
        }
        if (responseMetricData.length > 0) {
          kpiData.FormulaList = responseMetricData[0].FormulaList;
        }
        kpiData.isRelatorBlock = isRelatorBlock;
        let valueExist = false;
        if (!kpiData.metDataAttr || kpiData.metDataAttr.length === 0) {
          kpiData.metDataAttr = [
            {
              AttributeName:
                attributeLabelsForBO.length > 0 ? attributeLabelsForBO[0].AttributeName1 : '',
              AttributeValue: null,
            },
            {
              AttributeName:
                attributeLabelsForBO.length > 0 ? attributeLabelsForBO[0].AttributeName2 : '',
              AttributeValue: null,
            },
          ];
        } else if (kpiData.metDataAttr[0].AttributeValue) {
          valueExist = true;
        }

        if (
          kpiData.MetricDefination
          && kpiData.MetricDefination.length > 0
          && kpiData.MetricDefination.filter(
            (x) => String(x.KPIMetricDefinatoinID) === String(kpiData.MetricDefinitionID),
          ).length === 0
        ) {
          kpiData.MetricDefinitionID = 0;
        }

        this.setState({
          data: {
            kpiData,
          },
          errors: {},
          formIsValid: true,
          valueExist,
          BaselineMonthReadOnly: !kpiData.BaselineValue,
        });
      }
    });
  }

  bindToolFieldChange = (e) => {
    const { CategoryDetails } = this.state;
    const { id } = e.target;
    const data = CategoryDetails;
    switch (id) {
      case 'categoryType':
        data.CategoryType = e.target.value;
        break;
      case 'categoryName':
        data.CategoryName = e.target.value;
        break;
      default:
        break;
    }

    this.setState({
      CategoryDetails: data,
    });
  };

  onNumeratorChange = (e) => {
    const { errors } = this.state;
    errors.NumeratorValue = '';
    this.setState({ errors });
    if (e.target.value.length <= 12) {
      this.setState({ numeratorVal: e.target.value });
    }
  };

  onDenomChange = (e) => {
    const { errors } = this.state;
    errors.Denominator = '';
    this.setState({ errors });
    if (e.target.value.length <= 12) {
      this.setState({ denomVal: e.target.value });
    }
  };

  async getKpiMAppingDetails() {
    const { data } = this.state;
    const { responseMetricData } = this.props;
    let valueConnectorEdit = false;
    let mappedConnectorName;
    const mappingData = responseMetricData;
    const { kpiData } = data;
    let iskpiConnector = false;
    let isaccentureOwned = false;
    if (mappingData.length > 0) {
      kpiData.FormulaList = mappingData[0].FormulaList;
    }

    if (this.props.kpiData) {
      const mappedConnector = mappingData.filter(
        (x) => String(x.KpiMappingID) === String(this.props.kpiData.KPIMappingID),
      );

      if (mappedConnector.length > 0) {
        iskpiConnector = parseInt(mappedConnector[0].KpiMetricConnectorID) !== 0;
        isaccentureOwned = mappedConnector[0].IsAccentureOwned;
        this.isConnectorMetricDataFound = mappedConnector[0].IsConnectorMetricDataFound;
        this.isSelectedMetricMappedToConnector = mappedConnector[0].IsConnector;
        this.selectedMetricConnectorLastUpdated = mappedConnector[0].ConnectorUpdatedTS;

        if (
          this.props.kpiData
          && (String(this.props.kpiData.ConnectorID) === String(undefined)
            || parseInt(this.props.kpiData.ConnectorID) === 0
            || this.props.kpiData.ConnectorNames == null
            || String(this.props.kpiData.ConnectorNames) === String(undefined))
        ) {
          if (
            mappedConnector.length > 0
            && String(mappedConnector[0].ConnectorName) !== ''
            && mappedConnector[0].ConnectorName != null
          ) {
            mappedConnectorName = mappedConnector[0].ConnectorName;
            const mappedConnectID = mappedConnector[0].ConnectorID;

            this.props.kpiData.ConnectorNames = mappedConnectorName;
            this.props.kpiData.ConnectorID = mappedConnectID;

            if (data) {
              kpiData.ConnectorNames = mappedConnectorName != null && String(mappedConnectorName) !== String(undefined)
                ? mappedConnectorName
                : kpiData.ConnectorNames;
              kpiData.ConnectorID = mappedConnectID > 0 ? mappedConnectID : kpiData.ConnectorID;
            }
          }
        }
      }

      if (mappedConnectorName != null) {
        valueConnectorEdit = true;
      }
    }
    this.setState(
      {
        MetricNameOptions: mappingData,
        valueConnectorEdit,
        allCategoryTypesAndNames: mappingData.length > 0 ? mappingData[0].CategoryList : [],
        KpiMetricConnectorID: iskpiConnector,
        IsAccentureOwned: isaccentureOwned,

        data: {
          kpiData,
        },
      },
      () => {},
    );
  }

  getGetConnectorMaster() {
    let source = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
      .then((response) => {
        source = response.data;
        const sourceValues = source.map((x) => (
          <option key={x.ConnectorID} value={x.ConnectorID}>
            {x.ConnectorName}
          </option>
        ));
        this.setState({ sourceOptions: sourceValues });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  onNameChange(event) {
    const { data, MetricNameOptions } = this.state;
    if (!event.target.value) {
      const { kpiData } = data;
      kpiData.KPIDesp = '';
      kpiData.MetricUnit = '';
      kpiData.MetricName = null;
      kpiData.MetricSeq = '';
      kpiData.KPIMappingID = '';
      kpiData.LeadingPractice = '';
      kpiData.IsMultiLevel = false;
      kpiData.IsAggregation = false;
      this.isConnectorMetricDataFound = true;
      this.isSelectedMetricMappedToConnector = false;
      this.selectedMetricConnectorLastUpdated = undefined;

      kpiData.LeadingAndSimilarCheck = false;
      if (kpiData.metDataAttr && kpiData.metDataAttr.length > 0) {
        kpiData.metDataAttr[0].AttributeValue = null;
        kpiData.metDataAttr[1].AttributeValue = null;
      }

      // RESET THE METRIC DEFINITION DROPDOWN OPTIONS AND SELECTED METRIC DEFINITION
      kpiData.MetricDefination = [];
      kpiData.MetricDefinitionID = '';

      this.setState({
        data: {
          kpiData,
        },
      });
    } else {
      const errors = {};
      this.setState({
        errors,
      });
      const { kpiData } = data;
      kpiData.IsMultiLevel = false;
      kpiData.IsAggregation = false;
      kpiData.CategoryDetails = [];
      kpiData.KPIMappingID = event.target.value;
      kpiData.SourceName = '';
      kpiData.SourceFieldName = '';
      kpiData.MetricValue = ''; // CHANGED
      if (kpiData.KPIMappingID !== '-1') {
        const selectedMetric = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0];
        this.isConnectorMetricDataFound = selectedMetric.IsConnectorMetricDataFound;
        this.isSelectedMetricMappedToConnector = selectedMetric.IsConnector;
        this.selectedMetricConnectorLastUpdated = selectedMetric.ConnectorUpdatedTS;
        kpiData.KPIDesp = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].MetricDescription;
        kpiData.MetricUnit = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].MetricUnit;
        kpiData.MetricName = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].MetricName;
        kpiData.MetricSeq = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].MetricSeq;
        kpiData.BaselineValue = this.state.MetricNameOptions.filter(
          (data) => data.KpiMappingID == event.target.value,
        )[0].BaselineValue;
        kpiData.BaseLineMonth = this.state.MetricNameOptions.filter(
          (data) => data.KpiMappingID == event.target.value,
        )[0].BaseLineMonth;
        const BaselineMonthReadOnly = !kpiData.BaselineValue;
        this.setState({ BaselineMonthReadOnly });
        kpiData.TargetValue = this.state.MetricNameOptions.filter(
          (data) => data.KpiMappingID == event.target.value,
        )[0].TargetValue;
        kpiData.LeadingPractice = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].LeadingPractice;

        kpiData.SOCategoryID = this.props.SOCategoryID;
        kpiData.LeadingAndSimilarCheck = false;
        if (kpiData.metDataAttr && kpiData.metDataAttr.length > 0) {
          kpiData.metDataAttr[0].AttributeValue = MetricNameOptions.filter(
            (data) => String(data.KpiMappingID) === String(event.target.value),
          )[0].Attribute1Value;
          kpiData.metDataAttr[1].AttributeValue = MetricNameOptions.filter(
            (data) => String(data.KpiMappingID) === String(event.target.value),
          )[0].Attribute2Value;
          if (kpiData.metDataAttr[0].AttributeValue) {
            kpiData.LeadingAndSimilarCheck = true;
          }
        }

        // SET KPI MULTIPLE DEFINITIONS
        kpiData.MetricDefination = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].MetricDefination;

        const kpiMetricDefinitions = _.get(this.state, 'data.kpiData.MetricDefination', []);

        const enabledMetricDefinitions = _.filter(
          kpiMetricDefinitions,
          (eachMetricDefinition) => String(eachMetricDefinition.IsEnabled) === 'true',
        );

        // RESET THE SELECTED METRIC DEFINITION
        kpiData.MetricDefinitionID = '';

        if (enabledMetricDefinitions.length === 0) {
          const kpiDat = data.kpiData;
          kpiDat.MetricDefinitionID = -1;
          this.setState({
            data: {
              kpiData: kpiDat,
            },
          });
        }
      } else {
        this.setState({
          viewmore: true,
          show: false,
        });
      }
      let con = '';
      let valueConnector = false;

      const metriName = MetricNameOptions.filter(
        (eachValue) => String(eachValue.KpiMappingID) === String(event.target.value),
      );
      if (metriName.length > 0) {
        const connectorName = metriName[0].ConnectorName;
        const connectorId = metriName[0].ConnectorID;
        const criteria = metriName[0].Criteria;
        const { MetricValue } = metriName[0];
        const { IsAccentureOwned } = metriName[0];
        const { KpiMetricConnectorID } = metriName[0];
        if (connectorName == null) {
          con = false;
          kpiData.ConnectorNames = connectorName;
          kpiData.ConnectorID = connectorId;
          kpiData.SourceFieldName = criteria;
          kpiData.MetricValue = '';
          kpiData.IsAccentureOwned = IsAccentureOwned;
          kpiData.KpiMetricConnectorID = KpiMetricConnectorID;
        } else {
          con = true;
          kpiData.ConnectorNames = connectorName;
          kpiData.ConnectorID = connectorId;
          kpiData.SourceFieldName = criteria;
          kpiData.MetricValue = MetricValue;
          kpiData.IsAccentureOwned = IsAccentureOwned;
          kpiData.KpiMetricConnectorID = KpiMetricConnectorID;

          if (metriName[0].KpiMetricConnectorID !== 0) {
            valueConnector = true;
          }
        }
      }

      const connectorOptions = metriName.map((eachProcess) => (
        <option key={eachProcess.ConnectorID} value={eachProcess.ConnectorID}>
          {eachProcess.ConnectorName}
        </option>
      ));
      const criteriaOptions = metriName.map((eachProcess, index) => (
        <option key={index} value={eachProcess.Criteria}>
          {eachProcess.Criteria}
        </option>
      ));

      this.setState({
        data: {
          kpiData,
        },
        con,
        connectorOptions,
        criteriaOptions,
        valueConnector,
      });
    }
  }

  onDescriptionChange(event) {
    const { data } = this.state;
    const { kpiData } = data;
    kpiData.KPIDesp = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onSequenceChange(event) {
    const { data } = this.state;
    const errors = {};
    this.setState({
      errors,
    });
    const { kpiData } = data;
    kpiData.MetricSeq = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onUnitChange(event) {
    const { data } = this.state;
    const errors = {};
    this.setState({
      errors,
    });
    const { kpiData } = data;
    kpiData.MetricUnit = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onValueChange(event) {
    const { data } = this.state;
    const errors = {};
    this.setState({
      errors,
    });
    const { kpiData } = data;
    if (event.target.value === '') {
      kpiData.MetricValue = event.target.value;
      this.setState({
        data: {
          kpiData,
        },
      });
    } else if (regexForNegativeNumbers.test(event.target.value)) {
      if (event.target.value === '-.') {
        kpiData.MetricValue = '-0.';
      } else {
        kpiData.MetricValue = event.target.value;
      }
      this.setState({
        data: {
          kpiData,
        },
      });
    }
  }

  onValDescChange(event) {
    const { commentsError, data } = this.state;
    const str = event.target.value;
    let err = commentsError;
    if (event.target.value) {
      if (regexForTextAreas.test(str)) {
        const { kpiData } = data;
        kpiData.Comments = event.target.value;
        if (str && str.length < 300) {
          err = '';
          this.setState({
            data: {
              kpiData,
            },
            commentsError: err,
          });
        } else {
          err = i18n.t('commentsErrorM1');
          this.setState({
            data: {
              kpiData,
            },
            commentsError: err,
          });
        }
      } else {
        const newdata = data.kpiData;
        newdata.Comments = event.target.value;
        err = i18n.t('commentsErrorM2');
        this.setState({
          data: {
            kpiData: newdata,
          },
          commentsError: err,
        });
      }
    } else {
      const { kpiData } = data;
      kpiData.Comments = event.target.value;
      err = '';
      this.setState({
        data: {
          kpiData,
        },
        commentsError: err,
      });
    }
  }

  onUpperChange(event) {
    const { data } = this.state;
    const { kpiData } = data;
    if (event.target.value === '') {
      kpiData.UpperThreshold = event.target.value;
      this.setState({
        data: {
          kpiData,
        },
      });
    } else if (regexForNumbersOnlyMTC.test(event.target.value)) {
      kpiData.UpperThreshold = event.target.value;
      this.setState({
        data: {
          kpiData,
        },
      });
    }
  }

  onLowerChange(event) {
    const { data } = this.state;
    const { kpiData } = data;
    if (event.target.value === '') {
      kpiData.LowerThreshold = event.target.value;
      this.setState({
        data: {
          kpiData,
        },
      });
    } else if (regexForNumbersOnlyMTC.test(event.target.value)) {
      kpiData.LowerThreshold = event.target.value;
      this.setState({
        data: {
          kpiData,
        },
      });
    }
  }

  onBaselineValueChange(event) {
    const { data } = this.state;
    const { kpiData } = data;
    if (event.target.value === '') {
      kpiData.BaselineValue = event.target.value;
      kpiData.BaseLineMonth = '';
      this.setState({
        data: {
          kpiData,
        },
        BaselineMonthReadOnly: true,
      });
    } else if (regexForNegativeNumbers.test(event.target.value)) {
      if (event.target.value === '-.') {
        kpiData.BaselineValue = '-0.';
      } else {
        kpiData.BaselineValue = event.target.value;
      }
      this.setState({
        data: {
          kpiData,
        },
        BaselineMonthReadOnly: false,
      });
    }
  }

  onBaselineMonthChange = (date) => {
    const { kpiData } = this.state.data;
    kpiData.BaseLineMonth = date;
    this.setState({
      data: {
        kpiData,
      },
    });
  };

  onTargetValueChange(event) {
    const { data } = this.state;
    const { kpiData } = data;
    if (event.target.value === '') {
      kpiData.TargetValue = event.target.value;
      this.setState({
        data: {
          kpiData,
        },
      });
    } else if (regexForNegativeNumbers.test(event.target.value)) {
      if (event.target.value === '-.') {
        kpiData.TargetValue = '-0.';
      } else {
        kpiData.TargetValue = event.target.value;
      }
      this.setState({
        data: {
          kpiData,
        },
      });
    }
  }

  maxLengthCheckThreshold = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };

  onLeadingIndustryChange(event) {
    const { data } = this.state;
    const { kpiData } = data;
    kpiData.metDataAttr[0].AttributeValue = event.target.value.trim();
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onSimilarIndustryChange(event) {
    const { data } = this.state;
    const { kpiData } = data;
    kpiData.metDataAttr[1].AttributeValue = event.target.value.trim();
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onStatusChange(event) {
    const { data } = this.state;
    const errors = {};
    this.setState({
      errors,
    });
    const { kpiData } = data;
    kpiData.RAGStatus = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onSourceChange(event) {
    const { data } = this.state;
    const { kpiData } = data;
    kpiData.ConnectorID = event.target.value;
    const index = event.nativeEvent.target.selectedIndex;
    kpiData.ConnectorNames = event.nativeEvent.target[index].text;
    kpiData.SourceName = '';
    kpiData.SourceFieldName = '';
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onToolOwnerChange(event) {
    const { data } = this.state;
    const { kpiData } = data;
    kpiData.IsAccentureOwned = event.target.checked;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onSourceSystemChange(event) {
    const { data } = this.state;
    const { kpiData } = data;
    kpiData.SourceFieldName = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onOtherSystemChange(event) {
    const { data } = this.state;
    const { kpiData } = data;
    kpiData.SourceName = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  bindAutoAggregationChange = (e) => {
    const { data, valueConnectorEdit, valueConnector } = this.state;
    const { kpiData } = data;

    kpiData.IsAggregation = e.target.checked;

    this.setState({
      data: {
        kpiData,
      },
    });

    if (
      kpiData.IsAggregation
      && !valueConnectorEdit
      && !valueConnector
      && !this.isSelectedMetricMappedToConnector
    ) {
      this.calculateAggregateValueByFormula();
    }
  };

  calculateAggregateValueByFormula = () => {
    const { data } = this.state;
    const { kpiData } = data;
    const metricUnit = kpiData.MetricUnit;
    let temp = 0;
    let formulaStr = '';
    if (!kpiData.FormulaList) kpiData.FormulaList = [];
    if (metricUnit.trim() === '%') {
      if (kpiData.FormulaList.length > 0) {
        formulaStr = kpiData.FormulaList.filter((z) => z.IsPercentageUnit === true)[0].Formula;
      }
    } else {
      if (kpiData.FormulaList.length > 0) {
        formulaStr = kpiData.FormulaList.filter((z) => z.IsPercentageUnit === false)[0].Formula;
      }
      kpiData.formulaStr = formulaStr;
      this.setState({
        data: {
          kpiData,
        },
      });
    }
    if (kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0) {
      let total = 0;
      let numeratorSum = 0;
      let denominatorSum = 0;

      switch (formulaStr.toUpperCase().trim()) {
        case 'SUM':
          kpiData.CategoryDetails.forEach((arrayItem) => {
            if (!arrayItem.Denominator) {
              temp = 1;
            } else {
              temp = arrayItem.Denominator;
            }
            total += arrayItem.NumeratorValue / temp;
          });

          break;
        case 'AVERAGE':
          kpiData.CategoryDetails.forEach((arrayItem) => {
            if (!arrayItem.Denominator) {
              temp = 1;
            } else {
              temp = arrayItem.Denominator;
            }
            total += arrayItem.NumeratorValue / temp;
          });

          total /= kpiData.CategoryDetails.length;
          break;
        case 'WEIGHTED AVERAGE':
          kpiData.CategoryDetails.forEach((arrayItem) => {
            if (!arrayItem.Denominator) {
              arrayItem.Denominator = 100;
            }
            numeratorSum += parseFloat(arrayItem.NumeratorValue);
            denominatorSum += parseFloat(arrayItem.Denominator);
          });

          total = (numeratorSum / denominatorSum) * 100;
          break;
        default:
          break;
      }
      if (formulaStr) {
        kpiData.MetricValue = total;
      }

      this.setState({
        data: {
          kpiData,
        },
      });
    }
  };

  onKeyPress(event) {
    return (
      (event.charCode >= 65 && event.charCode <= 90)
      || (event.charCode >= 97 && event.charCode <= 122)
    );
  }

  handleSave(e) {
    const { filterDetails, SOCategoryID } = this.props;
    if (this.validateForm()) {
      const { data } = this.state;
      const { filter } = this.state;
      filter.LocationID = filterDetails.Locations;
      filter.OrgEntityID = filterDetails.Client;
      filter.OfferingID = filterDetails.process;
      filter.MonthDT = filterDetails.Month;

      data.SOCategoryID = SOCategoryID;
      if (data.kpiData.BaseLineMonth != null && data.kpiData.BaseLineMonth != '') {
        const baselinemonth = `${new Date(data.kpiData.BaseLineMonth).getFullYear()
        }-${
          new Date(data.kpiData.BaseLineMonth).getMonth() + 1 < 10
            ? `0${new Date(data.kpiData.BaseLineMonth).getMonth() + 1}`
            : new Date(data.kpiData.BaseLineMonth).getMonth() + 1}`;
        data.kpiData.BaseLineMonth = baselinemonth;
      }
      this.setState({
        data,
      });

      const ConfiguratorSavedViewModel = {
        ConfiguratorDataModels: this.state.data,
        FiltersIDsVM: filter,
      };

      // Calling Auto Save Deal metrics service
      axiosInstance
        .post(
          `${LocalApiBaseUrl}Configurator/SaveConfiguratorDealMetrics`,
          ConfiguratorSavedViewModel,
        )
        .then((response) => {
          if (response.data.IsSuccess) {
            store.dispatch({
              type: 'ADD_BO',
              payload: response.data.ConfiguratorDataModels,
            });
            axiosInstance
              .get(`${LocalApiBaseUrl}Configurator/GetBusinessOutcomesQualityScore`, {
                params: {
                  LocationID: filterDetails.Locations,
                  OrgEntityID: filterDetails.Client,
                  OfferingID: filterDetails.process,
                  MonthDT: filterDetails.Month,
                  LanguageID: store.getState().CurrentFilter.languageData.id,
                },
              })
              .then((response) => {
                if (response.data) {
                  store.dispatch({
                    type: 'SAVE_QC_SCORE_OF_EACH_TAB',
                    payload: response.data,
                  });
                }

                this.handleHide(e);
              })
              .catch((error) => {
                trycatchAlertPopup(error);
                this.handleHide(e);
              });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.handleHide(e);
        });
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    const { onClose } = this.props;
    if (String(e) !== String(undefined)) {
      if (String(e.target.textContent) !== '×') {
        onClose(e);
      } else {
        onClose(e);
      }
    } else {
      onClose(encodeURIComponent);
    }
  }

  handleRejectHide(e) {
    const { onRejectClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {
      if (String(e.target.textContent) !== '×') {
        onRejectClose();
      }
    } else {
      onRejectClose();
    }
  }

  handleApproveHide(e) {
    const { onApproveClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {
      if (String(e.target.textContent) !== '×') {
        onApproveClose();
      }
    } else {
      onApproveClose();
    }
  }

  passToConfirmation(data) {
    const { enteredData } = this.props;
    enteredData(data.PoNumber);
  }

  validateForm() {
    const { data, commentsError } = this.state;
    const errordescription = InvalidDescription();
    const { attributes } = this.props;
    const { kpiData } = data;
    let attributeLabelsForBO = [];
    if (kpiData.isRelatorBlock) {
      attributeLabelsForBO = attributes.filter((z) => z.ApplicableForDD === false);
    }

    const isLeading = kpiData.metDataAttr && kpiData.metDataAttr.length > 0
      ? kpiData.metDataAttr[0].AttributeValue
      : '';
    const isSimilar = kpiData.metDataAttr && kpiData.metDataAttr.length > 0
      ? kpiData.metDataAttr[1].AttributeValue
      : '';
    const errors = {};
    let formIsValid = true;
    let err = '';
    if (kpiData.Comments) {
      if (!isValidText(kpiData.Comments)) {
        err = errordescription;
        formIsValid = false;
      } else if (kpiData.Comments.length > 300) {
        err = i18n.t('commentsErrorM1');
        formIsValid = false;
      }
      this.setState({
        commentsError: err,
      });
    }
    if (kpiData.SourceFieldName) {
      if (!isValidText(kpiData.SourceFieldName)) {
        formIsValid = false;
        errors.SourceFieldName = errordescription;
      }
    }

    if (kpiData.MetricSeq) kpiData.MetricSeq = parseInt(kpiData.MetricSeq, 10);
    if (!kpiData.MetricSeq) {
      formIsValid = false;
      errors.MetricSeq = i18n.t('addWoErrorM5');
    } else if (!(kpiData.MetricSeq >= 0 && kpiData.MetricSeq <= 999999)) {
      formIsValid = false;
      errors.MetricSeqRange = i18n.t('addWoErrorM6');
    }
    if (!kpiData.MetricName) {
      formIsValid = false;
      errors.MetricName = i18n.t('addWoErrorM1');
    }
    if (commentsError) {
      formIsValid = false;
    }

    if (this.isSelectedMetricMappedToConnector && !this.isConnectorMetricDataFound) {
      formIsValid = false;
      errors.MetricConnectorDataNotFound = `${i18n.t('Data_not_available_in_source_system')}.`;
      errors.IntegratedMetricsMessage = `${i18n.t('Integrated_metrics_will_get_auto_populated')}.`;
      if (this.selectedMetricConnectorLastUpdated) {
        errors.MetricConnectorLastPull = `${i18n.t('Last_pull_occurred_on')
        } ${
          FormatDate(this.selectedMetricConnectorLastUpdated)
        }.`;
      } else {
        errors.MetricConnectorLastPull = `${i18n.t('Last_pull_occurred')} "${i18n.t('Date_Unavailable')}”.`;
      }
    }

    if (!this.isSelectedMetricMappedToConnector) {
      if (!kpiData.MetricValue && kpiData.MetricValue !== 0) {
        formIsValid = false;
        errors.MetricValue = i18n.t('addBoErrorM1');
      } else if (
        !(kpiData.MetricValue >= -999999999999 && kpiData.MetricValue <= 999999999999)
      ) {
        formIsValid = false;
        errors.MetricValueRange = i18n.t('addBoErrorM6');
      }
    }

    if (!(kpiData.BaselineValue >= -99999999 && kpiData.BaselineValue <= 99999999)) {
      formIsValid = false;
      errors.BaselineValue = i18n.t('Baseline_validation');
    }

    if (!(kpiData.TargetValue >= -99999999 && kpiData.TargetValue <= 99999999)) {
      formIsValid = false;
      errors.TargetValue = i18n.t('target_validation');
    }

    if (!kpiData.RAGStatus) {
      formIsValid = false;
      errors.RAGStatus = i18n.t('addBoErrorM3');
    }
    if (!isLeading && data.kpiData.isRelatorBlock) {
      formIsValid = false;
      errors.LeadingIndustry = attributeLabelsForBO.length > 0
        ? attributeLabelsForBO[0].AttributeName1
        : `${'' + ' '}${i18n.t('is_Required')}`;
    } else {
    }

    if (!isSimilar && data.kpiData.isRelatorBlock) {
      formIsValid = false;
      errors.SimilarIndustry = attributeLabelsForBO.length > 0
        ? attributeLabelsForBO[0].AttributeName2
        : `${'' + ' '}${i18n.t('is_Required')}`;
    } else {
    }

    if (!kpiData.ConnectorID) {
      formIsValid = false;
      errors.ConnectorID = i18n.t('Source_is_required');
    }
    if (kpiData.MetricDataDesp) {
      const isValid = regexForFields.test(kpiData.MetricDataDesp);
      if (!isValid) {
        formIsValid = false;
        errors.ValueDescription = i18n.t('addBoErrorM4');
      }
    }

    // start validation for benchmark fields

    if (data.kpiData.isRelatorBlock) {
      if (
        (isLeading && !isLeading.toString().trim() && isSimilar && !isSimilar.toString().trim())
        || (!isLeading && !isSimilar)
      ) {
        // attribute values can never be left empty - fill NA
        formIsValid = false;
        errors.LeadingIndustry = i18n.t('AttributeValue_ErrMsg1');
        errors.SimilarIndustry = i18n.t('AttributeValue_ErrMsg1');
      }
      if (isLeading && isLeading.trim()) {
        if (Number.isNaN(parseFloat(isLeading.trim()))) {
          // it is a string
          if (isLeading.toLowerCase().trim() !== 'na') {
            formIsValid = false;

            errors.LeadingIndustry = i18n.t('AttributeValue_ErrMsg2');
          }
        } else {
          // attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456
          if (!regexForNumbersOnlyMTC.test(isLeading.trim())) {
            formIsValid = false;

            errors.LeadingIndustry = i18n.t('AttributeValue_ErrMsg2');
          }

          if (!(parseFloat(isLeading.trim()) >= 0 && parseFloat(isLeading.trim()) <= 999999)) {
            formIsValid = false;

            errors.LeadingIndustry = `${`${attributeLabelsForBO.length > 0 ? attributeLabelsForBO[0].AttributeName1 : ''}`
              + ' '}${
              i18n.t('addBoErrorM2')}`;
          }
        }
      }
      if (isSimilar && isSimilar.trim()) {
        if (Number.isNaN(parseFloat(isSimilar.trim()))) {
          // it is a string
          if (isSimilar.toLowerCase().trim() !== 'na') {
            formIsValid = false;

            errors.SimilarIndustry = i18n.t('AttributeValue_ErrMsg2');
          }
        } else {
          if (!regexForNumbersOnlyMTC.test(isSimilar.trim())) {
            formIsValid = false;

            errors.SimilarIndustry = i18n.t('AttributeValue_ErrMsg2');
          }

          if (!(parseFloat(isSimilar.trim()) >= 0 && parseFloat(isSimilar.trim()) <= 999999)) {
            formIsValid = false;

            errors.SimilarIndustry = `${`${attributeLabelsForBO.length > 0 ? attributeLabelsForBO[0].AttributeName2 : ''}`
              + ' '}${
              i18n.t('addBoErrorM2')}`;
          }
        }
      }
    }
    // end validation for benchmark fields

    // VALIDATE "METRIC DEFINITION" DROPDOWN FIELD
    if (
      kpiData.MetricDefination
      && kpiData.MetricDefination.length > 0
      && !kpiData.MetricDefinitionID
    ) {
      errors.MetricDefinitionID = i18n.t('addBoErrorM5');
      formIsValid = false;
    }

    // Validate multi level categories
    if (kpiData.IsMultiLevel) {
      if (kpiData.CategoryDetails.length === 0) {
        errors.MultiLevel = i18n.t('noCategories');
        formIsValid = false;
      }
    }

    // end validate multi level categories

    this.setState({
      errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  // WOULD BE EXECUTED WHEN THE "METRIC DEFINITION" DROPDOWN WOULD BE CHANGED
  handleChangeMetricDefinition = (e) => {
    const MetricDefinitionID = e.target.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        kpiData: {
          ...prevState.data.kpiData,
          MetricDefinitionID,
        },
      },
    }));
  };

  handleClick() {
    const { data } = this.state;
    const { kpiData } = data;
    kpiData.IsMultiLevel = !kpiData.IsMultiLevel;

    if (!kpiData.IsMultiLevel) {
      kpiData.IsAggregation = false;
    }
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  handleCategoryClose() {
    this.setState({
      showCategory: false,

      errors: {},
    });
  }

  handleCategoryShow() {
    this.setState({ errors: {} });
    const { CategoryDetails } = this.state;
    CategoryDetails.CategoryType = '';
    CategoryDetails.CategoryName = '';
    this.setState({
      showCategory: true,
      iscatypedisable: false,
      CategoryDetails,
      isEdit: false,
      CategoryNameData: [],
      CatNameID: 0,
      CatTypeID: 0,
      numeratorVal: '',
      denomVal: '',
    });
  }

  handleCategoryAdd() {
    const {
      data,
      numeratorVal,
      denomVal,
      CatTypeID,
      valueConnectorEdit,
      CatNameID,
      CategoryDetails,
      CategoryNameData,
      categoriesLimit,
    } = this.state;
    const { kpiData } = data;
    const error = {};
    if (!numeratorVal) {
      error.NumeratorValue = i18n.t('numeratorIsReqd');
    } else if (
      !(parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999)
    ) {
      error.NumeratorValueRange = i18n.t('addBoErrorM6');
    }
    if (kpiData.MetricUnit.trim() === '%') {
      if (!denomVal) {
        error.Denominator = i18n.t('denominatorIsReqd');
      } else if (parseFloat(denomVal) === 0) {
        error.Denominator = i18n.t('denomCannotZero');
      } else if (!(parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)) {
        error.DenominatorValueRange = i18n.t('addBoErrorM6');
      }
    } else if (!Number.isNaN(parseFloat(denomVal))) {
      if (parseFloat(denomVal) === 0) {
        error.Denominator = i18n.t('denomCannotzero');
      } else if (
        !(parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)
      ) {
        error.DenominatorValueRange = i18n.t('addBoErrorM6');
      }
    }
    if (String(CatTypeID) === '0') {
      error.CategoryType = i18n.t('catTypeReqd');
    }

    if (String(CatNameID) === '0') {
      error.CategoryName = i18n.t('catNameReqd');
    }
    this.setState({ errors: error });
    if (
      kpiData.MetricUnit.trim() === '%'
        ? CategoryDetails.CategoryType
          && String(CatTypeID) !== '0'
          && CategoryDetails.CategoryName
          && String(CatNameID) !== '0'
          && numeratorVal
          && parseFloat(numeratorVal) >= -999999999999
          && parseFloat(numeratorVal) <= 999999999999
          && denomVal
          && parseFloat(denomVal) !== 0
          && parseFloat(denomVal) >= -999999999999
          && parseFloat(denomVal) <= 999999999999
        : CategoryDetails.CategoryType
          && String(CatTypeID) !== '0'
          && CategoryDetails.CategoryName
          && String(CatNameID) !== '0'
          && numeratorVal
          && parseFloat(numeratorVal) >= -999999999999
          && parseFloat(numeratorVal) <= 999999999999
          && (!Number.isNaN(parseFloat(denomVal))
            ? parseFloat(denomVal) !== 0
              && parseFloat(denomVal) >= -999999999999
              && parseFloat(denomVal) <= 999999999999
            : true)
    ) {
      if (!kpiData.CategoryDetails) kpiData.CategoryDetails = [];

      if (
        kpiData.CategoryDetails.filter((cat) => cat.CategoryName === CategoryDetails.CategoryName)
          .length === 0
      ) {
        const { CategoryName } = CategoryDetails;

        const Catid = CategoryName
          ? CategoryNameData.filter((cat) => cat.CategoryName === CategoryName)[0].ID
          : null;

        if (!kpiData.CategoryDetails) kpiData.CategoryDetails = [];
        if (kpiData.CategoryDetails && kpiData.CategoryDetails.length < categoriesLimit) {
          kpiData.CategoryDetails.push({
            CategoryType: CategoryDetails.CategoryType,
            CategoryName: CategoryDetails.CategoryName,
            NumeratorValue: numeratorVal,
            Denominator: denomVal,
            ConfigurationDealMetricID: 8,
            OfferingMultiLevelCategoryID: Catid,
          });
          this.setState({
            showCategory: false,
            data: {
              kpiData,
            },
          });
        } else {
          const error = {};
          error.CategoryName = `${i18n.t('A_maximum_of')} ${categoriesLimit} ${i18n.t(
            'category_values_are',
          )}`;

          this.setState({ showCategory: true, errors: error });
        }

        if (
          kpiData.IsAggregation
          && !valueConnectorEdit
          && !this.isSelectedMetricMappedToConnector
        ) {
          // calculate the metric value on the fly when new values added/ edited/deleted
          this.calculateAggregateValueByFormula();
        }
      } else {
        const error = {};
        error.CategoryName = i18n.t('duplicateCatName');

        this.setState({ errors: error });
      }
    } else {
      this.setState({
        showCategory: true,
      });
    }
  }

  handleCategoryEditShow(catNameToEdit) {
    const { data, CategoryNameData, CatTypeID } = this.state;
    this.setState({ errors: {} });
    const allCategoriesList = data.kpiData.CategoryDetails;
    const index = allCategoriesList.findIndex((item) => item.CategoryName === catNameToEdit);
    const catnameid = CategoryNameData.filter((row) => row.CategoryName === catNameToEdit)[0].ID;
    const { CategoryName } = CategoryNameData.filter((row) => row.ID === catnameid)[0];
    const { CategoryDetails } = this.state;
    CategoryDetails.CategoryName = CategoryName;
    const numeratorVal = allCategoriesList[index].NumeratorValue;
    const denomVal = allCategoriesList[index].Denominator;
    this.setState({
      EditIndex: index,
      isEdit: true,
      showCategory: true,
      CatNameID: catnameid,
      CategoryDetails,
      CatTypeID,
      numeratorVal,
      denomVal,
    });
  }

  handleCategoryEdit() {
    const error = {};
    const {
      valueConnector,
      EditIndex,
      data,
      numeratorVal,
      denomVal,
      CatTypeID,
      valueConnectorEdit,
      CatNameID,
      CategoryDetails,
      CategoryNameData,
    } = this.state;
    const { kpiData } = data;
    let isCatnameError = false;
    if (!numeratorVal) {
      error.NumeratorValue = i18n.t('numeratorIsReqd');
    } else if (
      !(parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999)
    ) {
      error.NumeratorValueRange = i18n.t('addBoErrorM6');
    }
    if (kpiData.MetricUnit.trim() === '%') {
      if (!denomVal) {
        error.Denominator = i18n.t('denominatorIsReqd');
      } else if (parseFloat(denomVal) === 0) {
        error.Denominator = i18n.t('denomCannotZero');
      } else if (!(parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)) {
        error.DenominatorValueRange = i18n.t('addBoErrorM6');
      }
    } else if (!Number.isNaN(parseFloat(denomVal))) {
      if (parseFloat(denomVal) === 0) {
        error.Denominator = i18n.t('denomCannotzero');
      } else if (
        !(parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)
      ) {
        error.DenominatorValueRange = i18n.t('addBoErrorM6');
      }
    }

    if (!CategoryDetails.CategoryType) {
      error.CategoryType = i18n.t('catTypeReqd');
    }

    if (CatNameID === '0') {
      error.CategoryName = i18n.t('catNameReqd');
    }
    this.setState({ errors: error });
    if (
      kpiData.MetricUnit === '%'
        ? CategoryDetails.CategoryType
          && String(CatTypeID) !== '0'
          && CategoryDetails.CategoryName
          && String(CatNameID) !== '0'
          && numeratorVal
          && parseFloat(numeratorVal) >= -999999999999
          && parseFloat(numeratorVal) <= 999999999999
          && denomVal
          && parseFloat(denomVal) !== 0
          && parseFloat(denomVal) >= -999999999999
          && parseFloat(denomVal) <= 999999999999
        : CategoryDetails.CategoryType
          && String(CatTypeID) !== '0'
          && CategoryDetails.CategoryName
          && String(CatNameID) !== '0'
          && numeratorVal
          && parseFloat(numeratorVal) >= -999999999999
          && parseFloat(numeratorVal) <= 999999999999
          && (!Number.isNaN(parseFloat(denomVal))
            ? parseFloat(denomVal) !== 0
              && parseFloat(denomVal) >= -999999999999
              && parseFloat(denomVal) <= 999999999999
            : true)
    ) {
      const index = EditIndex;
      if (
        kpiData.CategoryDetails.filter((cat) => cat.CategoryName === CategoryDetails.CategoryName)
          .length === 0
      ) {
        kpiData.CategoryDetails[index].CategoryName = CategoryDetails.CategoryName;
        kpiData.CategoryDetails[index].NumeratorValue = numeratorVal;
        kpiData.CategoryDetails[index].Denominator = denomVal;
        kpiData.CategoryDetails[index].ConfigurationDealMetricID = 8;
        const Catid = kpiData.CategoryDetails[index].CategoryName
          && kpiData.CategoryDetails[index].CategoryName !== ''
          ? CategoryNameData.filter(
            (cat) => cat.CategoryName === kpiData.CategoryDetails[index].CategoryName,
          )[0].ID
          : null;

        kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID = Catid;
        this.setState({
          data: {
            kpiData,
          },
        });
        this.setState({
          showCategory: false,
        });
      } else if (
        kpiData.CategoryDetails.filter(
          (cat) => cat.CategoryName === kpiData.CategoryDetails[index].CategoryName,
        ).length === 1
      ) {
        if (
          kpiData.CategoryDetails[index].CategoryName.trim() === CategoryDetails.CategoryName.trim()
            ? false
            : kpiData.CategoryDetails.filter(
              (cat) => cat.CategoryName === CategoryDetails.CategoryName,
            ).length === 1
        ) {
          const error = {};
          error.CategoryName = i18n.t('duplicateCatName');
          isCatnameError = true;
          this.setState({ errors: error });
        } else {
          // eslint-disable-next-line no-self-assign
          kpiData.CategoryDetails[index].CategoryName = kpiData.CategoryDetails[index].CategoryName;
          kpiData.CategoryDetails[index].NumeratorValue = numeratorVal;
          kpiData.CategoryDetails[index].Denominator = denomVal;
          kpiData.CategoryDetails[index].ConfigurationDealMetricID = 8;
          kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID =
            // eslint-disable-next-line no-self-assign
            kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID;

          this.setState({
            data: {
              kpiData,
            },
          });
          this.setState({
            showCategory: false,
          });
        }
      } else {
        this.setState({
          showCategory: false,
        });
      }
      if (!isCatnameError && kpiData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector && !this.isSelectedMetricMappedToConnector) {
        // calculate the metric value on the fly when new values added/ edited/deleted
        this.calculateAggregateValueByFormula();
      }
    }
  }

  handleCategoryTypeAddShow() {
    this.setState({ errors: {} });
    const { CatTypeID } = this.state;
    const { CategoryDetails } = this.state;
    CategoryDetails.CategoryName = '';
    this.setState({
      CategoryDetails,
      showCategory: true,
      isEdit: false,
      iscatypedisable: true,
      CatNameID: 0,
      CatTypeID,
      numeratorVal: '',
      denomVal: '',
    });
  }

  handleaddCategoryNameClose() {
    this.setState({
      showaddCategory: false,
      errors: {},
    });
  }

  handleaddCategoryClose() {
    this.setState({
      showaddCategoryType: false,
      errors: {},
    });
  }

  handleaddCategoryShow() {
    this.setState({ showaddCategory: true });
  }

  removeDuplicates(arr) {
    /* NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
    const map = new Map();
    arr.forEach((v) => map.set(v.CategoryType, v));
    return [...map.values()];
  }

  CatTypeSelect(e) {
    const CatTypeID = e.target.value;
    this.setState({ CatTypeID });

    setTimeout(() => {
      if (String(CatTypeID) === 'addnew') {
        CategoryDetails.CategoryType = '';
        CategoryDetails.CategoryName = '';
        this.setState({
          errors: {},
          showCategory: false,
          showaddCategoryType: true,
          CategoryDetails,
        });
      } else if (String(CatTypeID) === '0') {
        // CHANGE2
        CategoryDetails.CategoryType = '';
        CategoryDetails.CategoryName = '';
        this.setState({
          CatNameID: 0,
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true,
          CategoryDetails,
        });
      } else if (String(CatTypeID) === 'addnewcatname') {
        this.setState({
          showCategory: false,
          showaddCategory: true,
        });
      } else {
        this.setState({
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true,
        });
      }
    }, 500);
    const { allCategoryTypesAndNames } = this.state;
    const CatType = CatTypeID && String(CatTypeID) !== '0' && String(CatTypeID) !== 'addnew'
      ? allCategoryTypesAndNames.filter(
        (eachValue) => String(eachValue.ID) === String(CatTypeID),
      )[0].CategoryType
      : []; // FETCHING individual CATEGORY TYPE

    let { CategoryDetails } = this.state;
    CategoryDetails.CategoryType = CatType;
    this.setState({
      CategoryDetails,
    });

    const CategoryNameData = CatType && String(CatType) !== ''
      ? allCategoryTypesAndNames.filter(
        (eachValue) => String(eachValue.CategoryType) === String(CatType),
      )
      : []; // FETCHING CATEGORY NAME

    this.setState({ CategoryNameData });
  }

  handleDropdownChange(e) {
    const CatNameID = e.target.value;
    this.setState({ errors: {}, CatNameID });

    setTimeout(() => {
      if (String(CatNameID) === 'addnew') {
        this.setState({
          showCategory: false,
          showaddCategoryType: true,
        });
      } else if (String(CatNameID) === 'addnewcatname') {
        if (!this.state.CategoryDetails.CategoryType) {
          // category type is empty

          const error = {};
          error.CategoryType = i18n.t('catTypeReqd');

          this.setState({ errors: error, CatNameID: 0 });
        } else {
          CategoryDetails.CategoryName = '';
          this.setState({
            showCategory: false,
            showaddCategory: true,
            CategoryDetails,
          });
        }
      } else {
        this.setState({
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true,
        });
      }
    }, 500);
    const CatName = CatNameID && String(CatNameID) !== '0' && String(CatNameID) !== 'addnewcatname'
      ? this.state.allCategoryTypesAndNames.filter(
        (eachValue) => String(eachValue.ID) === String(CatNameID),
      )[0].CategoryName
      : [];

    let { CategoryDetails } = this.state;
    CategoryDetails.CategoryName = CatName;
    this.setState({
      CategoryDetails,
    });
  }

  // called when new category name is added -- on clicking on the add button

  handleaddCategoryAdd() {
    const { CategoryDetails } = this.state;
    const { filterDetails } = this.props;
    const data = CategoryDetails;
    const errordescription = InvalidDescription();
    const param = {
      OfferingId: parseInt(filterDetails.process),
      CatType: data.CategoryType.toString().trim(),
      CatName: data.CategoryName.toString().trim(),
      LocationID: filterDetails.Locations,
      SOCategoryID: 1,
      OrgEntityID: filterDetails.Client,
    };
    const error = {};
    if (!data.CategoryType.trim()) {
      error.CategoryType = i18n.t('catTypeReqd');
    } else if (!isValidText(data.CategoryType.trim())) {
      error.CategoryType = errordescription;
    }

    if (!data.CategoryName.trim()) {
      error.CategoryName = i18n.t('catNameReqd');
    } else if (!isValidText(data.CategoryName.trim())) {
      error.CategoryName = errordescription;
    }

    this.setState({ errors: error });

    if (data.CategoryType.trim() && data.CategoryName.trim() && Object.entries(error).length == 0) {
      axiosInstance
        .post(`${LocalApiBaseUrl}MultiLevelApproval/SendCategoryTypeNameForApproval`, param, {})
        .then((response) => {
          if (
            response.data.IsSuccess
            && String(response.data.StatusMessage) === 'Sent For Approval'
          ) {
            this.setState({
              showaddCategory: false,
              showaddCategoryType: false,
              showApproval: true,
            });
          } else {
            window.alert(response.data.StatusMessage);
            this.setState({ showLoaderOnPage: false });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          window.alert(this.ssErrMsg + error);
        });
    }
  }

  handleApprovalClose() {
    this.setState({ showApproval: false });
  }

  getFormattedDate = (date) => {
    if (!date) return '';
    const newDate = new Date(date);
    return (
      `${[newDate.getMonth() + 1, newDate.getDate(), newDate.getFullYear()].join('/')
      } ${
        [newDate.getHours(), newDate.getMinutes(), newDate.getSeconds()].join(':')}`
    );
  };

  render() {
    const {
      showaddCategory,
      showaddCategoryType,
      data,
      show,
      MetricNameOptions,
      errors,
      valueConnector,
      KpiMetricConnectorID,
      BaselineMonthReadOnly,
      commentsError,
      IsBatDealLevelForOffering,
      con,
      connectorOptions,
      sourceOptions,
      valueConnectorEdit,
      showCategory,
      isEdit,
      iscatypedisable,
      allCategoryTypesAndNames,
      CatTypeID,
      CatNameID,
      CategoryNameData,
      numeratorVal,
      denomVal,
      CategoryDetails,
      showApproval,
      viewmore,
    } = this.state;
    const {
      kpiData, attributes, SOCategoryID, filterDetails,
    } = this.props;
    const currentDate = new Date();

    // list of all category types and names for this offering - to be displayed in add/edit popup

    const stateKpiData = data.kpiData;
    let items = [];
    if (stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0) {
      items = stateKpiData.CategoryDetails.map((item, key) => (
        <li>
          <span className="contentName">{item.CategoryName}</span>
          {stateKpiData.MetricUnit.trim() !== '%' && (
            <span className="contentValues">
              <span>
                {parseInt(thousandSeparatorFormat(item.NumeratorValue) % 1) !== 0
                  ? MetricValueThousandFormat(stateKpiData.MetricUnit, item.NumeratorValue)
                  : MetricValueThousandFormat(stateKpiData.MetricUnit, item.NumeratorValue)}
              </span>
              {item.Denominator && <span> | </span>}
              <span>
                {item.Denominator
                  ? parseInt(thousandSeparatorFormat(item.Denominator) % 1) !== 0
                    ? MetricValueThousandFormat(stateKpiData.MetricUnit, item.Denominator)
                    : MetricValueThousandFormat(stateKpiData.MetricUnit, item.Denominator)
                  : ''}
              </span>
              {' '}
            </span>
          )}

          {stateKpiData.MetricUnit.trim() === '%' && (
            <span className="contentValues">
              <span>
                {parseInt(
                  thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator) % 1,
                ) !== 0
                  ? thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator)
                  : thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator)}
              </span>
              {' '}
              {stateKpiData.MetricUnit}
            </span>
          )}

          <div className="item-list-hover">
            <span>
              <i onClick={() => this.deleteCategoryName(item.CategoryName)} className="far fa-times">
                {' '}
              </i>
              <i
                onClick={() => this.handleCategoryEditShow(item.CategoryName)}
                className="fas fa-edit"
              />
            </span>
          </div>
        </li>
      ));
    }
    let isScreenInEditMode = false;
    if (!_.isEmpty(kpiData)) {
      isScreenInEditMode = true;
    }

    // FILTER OUT THE METRIC DEFINITIONS THAT ARE "ENABLED"
    const kpiMetricDefinitions = _.get(this.state, 'data.kpiData.MetricDefination', []);

    const enabledMetricDefinitions = _.filter(
      kpiMetricDefinitions,
      (eachMetricDefinition) => String(eachMetricDefinition.IsEnabled) === 'true',
    );
    const attributeLabelsForBO = attributes.filter((z) => z.ApplicableForDD === false);

    return (
      <>
        {/* <Header /> */}
        <Modal
          show={show}
          onHide={this.handleHide}
          className="modal fade bo-configurator-modal enhance"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">
              {i18n.t('addBOMess1')}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-7">
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t('Name')}
                          {' '}
                          <span className="required">*</span>
                        </label>
                        <select
                          id="name"
                          disabled={isScreenInEditMode}
                          className="form-control"
                          ref="MetricNameDropdown"
                          onChange={this.onNameChange}
                          value={data.kpiData.KPIMappingID}
                        >
                          <option value="">{i18n.t('Select_Metric_Name')}</option>
                          {MetricNameOptions.map((row) => <option value={row.KpiMappingID}>{row.MetricName}</option>)}
                          <option value="-1">
                            &lt;
                            {i18n.t('Add_New')}
                            &gt;
                          </option>
                        </select>
                        <span className="errorMsg">{errors.MetricName}</span>
                      </div>

                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t('Unit_of_Measure')}
                          {' '}
                          <span className="required">*</span>

                        </label>
                        <input
                          type="text"
                          disabled
                          maxLength="50"
                          className="form-control"
                          value={data.kpiData.MetricUnit}
                        />
                        <div className="errorMsg">{errors.MetricUnit}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t('Sequence')}
                          {' '}
                          <span className="required">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.onSequenceChange}
                          value={data.kpiData.MetricSeq}
                        />
                        <div className="errorMsg">{errors.MetricSeq}</div>
                        <div className="errorMsg">{errors.MetricSeqRange}</div>
                      </div>

                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t('Leading_Practice')}
                          {' '}
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{i18n.t('addBOMess2')}</Tooltip>}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </OverlayTrigger>
                        </label>
                        <input
                          type="text"
                          disabled
                          maxLength="50"
                          className="form-control"
                          value={data.kpiData.LeadingPractice}
                        />
                        <div className="errorMsg">{errors.LeadingPractice}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label>
                          {' '}
                          {i18n.t('Description')}
                          {' '}
                        </label>
                        <input
                          type="text"
                          disabled
                          maxLength="255"
                          className="form-control"
                          value={data.kpiData.KPIDesp}
                        />
                        <div className="errorMsg">{errors.KPIDesp}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label>
                          {i18n.t('Metric_Definition')}
                          {' '}
                          <span className="required">*</span>
                          {' '}
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{i18n.t('addBOMess3')}</Tooltip>}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </OverlayTrigger>
                        </label>
                        {enabledMetricDefinitions.length > 0 ? (
                          <select
                            value={data.kpiData.MetricDefinitionID}
                            onChange={(e) => this.handleChangeMetricDefinition(e)}
                            className="form-control"
                          >
                            <option value="">
                              {i18n.t('Select_Metric_Definition')}
                              {' '}
                            </option>
                            {_.map(enabledMetricDefinitions, (eachMetricDefinition) => (
                              <option
                                value={eachMetricDefinition.KPIMetricDefinatoinID}
                                key={eachMetricDefinition.KPIMetricDefinatoinID}
                              >
                                {_.isEmpty(eachMetricDefinition.DenominatorDescription)
                                  ? eachMetricDefinition.NumeratorDescription
                                  : `${eachMetricDefinition.NumeratorDescription} / ${eachMetricDefinition.DenominatorDescription}`}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            value={i18n.t('Definition_Not_Available')}
                            disabled
                          />
                        )}
                        <div className="errorMsg">{errors.MetricDefinitionID}</div>
                      </div>
                    </div>

                    <div className="row border-box">
                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t('Value')}
                          {' '}
                          <span className="required">*</span>
                        </label>
                        {valueConnector ? (
                          <input
                            type="text"
                            maxLength="12"
                            className="form-control"
                            onChange={this.onValueChange}
                            value={data.kpiData.MetricValue}
                            readOnly
                          />
                        ) : KpiMetricConnectorID ? (
                          <input
                            type="text"
                            maxLength="12"
                            className="form-control"
                            onChange={this.onValueChange}
                            value={kpiData.MetricValue}
                            readOnly
                          />
                        ) : (
                          <input
                            readOnly={stateKpiData.IsAggregation}
                            type="text"
                            maxLength="12"
                            className="form-control"
                            onChange={this.onValueChange}
                            value={data.kpiData.MetricValue}
                            disabled={KpiMetricConnectorID}
                          />
                        )}
                        <div className="errorMsg">{errors.MetricValue}</div>
                        <div className="errorMsg">
                          {errors.MetricConnectorDataNotFound}
                          {errors.MetricConnectorLastPull ? (
                            <div>
                              {' '}
                              {errors.MetricConnectorLastPull}
                              {' '}
                            </div>
                          ) : (
                            ''
                          )}
                          {errors.MetricConnectorDataNotFound ? (
                            <div>{errors.IntegratedMetricsMessage}</div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="errorMsg">{errors.MetricValueRange}</div>
                      </div>

                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t('Status')}
                          {' '}
                          <span className="required">*</span>
                        </label>

                        <select
                          className="form-control"
                          value={data.kpiData.RAGStatus}
                          onChange={this.onStatusChange}
                        >
                          <option value="">
                            --
                            {i18n.t('Select')}
                            --
                          </option>
                          <option value="Grey">
                            {i18n.t('Grey')}
                            {' '}
                          </option>
                          <option value="Green">
                            {i18n.t('Green')}
                            {' '}
                          </option>
                          <option value="Red">
                            {i18n.t('Red')}
                            {' '}
                          </option>
                          <option value="Amber">
                            {i18n.t('Amber')}
                            {' '}
                          </option>
                        </select>
                        <div className="errorMsg">{errors.RAGStatus}</div>
                      </div>

                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t('Upper_Threshold')}
                          {' '}
                        </label>
                        <input
                          type="number"
                          maxLength="12"
                          onInput={this.maxLengthCheckThreshold}
                          className="form-control"
                          onChange={this.onUpperChange}
                          value={data.kpiData.UpperThreshold}
                        />
                      </div>

                      <div className="form-group col-lg-6">
                        <label>{i18n.t('Lower_Threshold')}</label>
                        <input
                          type="number"
                          maxLength="12"
                          onInput={this.maxLengthCheckThreshold}
                          className="form-control"
                          onChange={this.onLowerChange}
                          value={data.kpiData.LowerThreshold}
                        />
                      </div>

                      <div className="form-group col-lg-4">
                        <label>{i18n.t('Baseline_Value')}</label>
                        <input
                          type="text"
                          maxLength="12"
                          onInput={this.maxLengthCheckThreshold}
                          className="form-control"
                          onChange={this.onBaselineValueChange}
                          value={data.kpiData.BaselineValue}
                        />
                        <div className="errorMsg">{errors.BaselineValue}</div>
                      </div>

                      <div className="form-group col-lg-4">
                        <label htmlFor="">{i18n.t('Baseline_Month')}</label>
                        <div className="datepicker-label-wrapper">
                          <DatePicker
                            className="form-control"
                            id="BaseLineMonth"
                            maxDate={currentDate}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            selected={
                              Date.parse(this.state.data.kpiData.BaseLineMonth)
                                ? new Date(Date.parse(this.state.data.kpiData.BaseLineMonth))
                                : null
                            }
                            onSelect={(date) => {
                              this.onBaselineMonthChange(date);
                            }}
                            autoComplete="off"
                            readOnly={this.state.BaselineMonthReadOnly}
                          />
                        </div>
                      </div>

                      <div className="form-group col-lg-4">
                        <label>{i18n.t('Target_Value')}</label>
                        <input
                          type="text"
                          maxLength="12"
                          onInput={this.maxLengthCheckThreshold}
                          className="form-control"
                          onChange={this.onTargetValueChange}
                          value={data.kpiData.TargetValue}
                        />
                        <div className="errorMsg">{errors.TargetValue}</div>
                      </div>

                      <div className="form-group col-lg-12">
                        <label>{i18n.t('Comments')}</label>
                        <textarea
                          rows="4"
                          maxLength="300"
                          name="comment"
                          className="form-control"
                          onChange={this.onValDescChange}
                          value={data.kpiData.Comments}
                        />
                        <div className="errorMsg">{commentsError}</div>
                      </div>
                    </div>

                    {data.kpiData.isRelatorBlock ? (
                      <div className="row border-box">
                        <div className="form-group col-lg-6">
                          <label>
                            {attributeLabelsForBO.length > 0
                              ? attributeLabelsForBO[0].AttributeName1
                              : ''}
                            {' '}
                            <span className="required">*</span>
                            {' '}
                            {IsBatDealLevelForOffering && (
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-top">{i18n.t('addBMtooltip')}</Tooltip>
                                }
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </OverlayTrigger>
                            )}
                          </label>
                          <input
                            type="text"
                            xpattern="\d*"
                            maxLength="6"
                            className="form-control"
                            onChange={this.onLeadingIndustryChange}
                            value={
                              data.kpiData.metDataAttr
                              && data.kpiData.metDataAttr.length > 0
                              && data.kpiData.metDataAttr[0].AttributeValue
                            }
                            disabled={!IsBatDealLevelForOffering}
                          />
                          <div className="errorMsg">{errors.LeadingIndustry}</div>
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {attributeLabelsForBO.length > 0
                              ? attributeLabelsForBO[0].AttributeName2
                              : ''}
                            {' '}
                            <span className="required">*</span>
                            {' '}
                            {IsBatDealLevelForOffering && (
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-top">{i18n.t('addBMtooltip')}</Tooltip>
                                }
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </OverlayTrigger>
                            )}
                          </label>
                          <input
                            type="text"
                            xpattern="\d*"
                            maxLength="6"
                            className="form-control"
                            onChange={this.onSimilarIndustryChange}
                            value={
                              data.kpiData.metDataAttr
                              && data.kpiData.metDataAttr.length > 0
                              && data.kpiData.metDataAttr[1].AttributeValue
                            }
                            disabled={!IsBatDealLevelForOffering}
                          />
                          <div className="errorMsg">{errors.SimilarIndustry}</div>
                        </div>
                      </div>
                    ) : null}
                    <div className="row border-box">
                      <div className="form-group col-lg-4">
                        <label className="source-system-padding">
                          {i18n.t('Source_System')}
                          {' '}
                          <span className="required">*</span>
                        </label>
                        {con ? (
                          <select
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={data.kpiData.ConnectorID}
                          >
                            {connectorOptions}
                          </select>
                        ) : KpiMetricConnectorID ? (
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={kpiData.ConnectorNames}
                          />
                        ) : (
                          <select
                            className="form-control"
                            onChange={this.onSourceChange}
                            value={data.kpiData.ConnectorID}
                            disabled={KpiMetricConnectorID}
                          >
                            <option value="" />
                            {sourceOptions}
                            <option value="-1">{i18n.t('Others')}</option>
                            <option value="-2">{i18n.t('Manual')}</option>
                          </select>
                        )}
                        <div className="errorMsg">{errors.ConnectorID}</div>
                      </div>
                      <div className="form-group col-lg-4">
                        <label>
                          {i18n.t('Source_System_Fields')}
                          {' '}
                          <span className="required" />
                        </label>
                        {con || String(data.kpiData.ConnectorNames) === 'Manual' ? (
                          <input
                            onChange={this.onSourceSystemChange}
                            type="text"
                            maxLength="50"
                            className="form-control"
                            readOnly
                            value={data.kpiData.SourceFieldName}
                          />
                        ) : valueConnectorEdit ? (
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={kpiData.SourceFieldName}
                          />
                        ) : (
                          <input
                            onChange={this.onSourceSystemChange}
                            type="text"
                            maxLength="50"
                            className="form-control"
                            value={data.kpiData.SourceFieldName}
                          />
                        )}
                        <div className="errorMsg">{errors.SourceFieldName}</div>
                      </div>
                      <div className="form-group col-lg-4">
                        <label>{i18n.t('Other_Source_System')}</label>
                        {String(data.kpiData.ConnectorNames) === 'Others' ? (
                          <input
                            onChange={this.onOtherSystemChange}
                            type="text"
                            maxLength="50"
                            className="form-control"
                            value={data.kpiData.SourceName}
                          />
                        ) : (
                          <input
                            onChange={this.onOtherSystemChange}
                            type="text"
                            readOnly
                            maxLength="50"
                            className="form-control"
                            value={data.kpiData.SourceName}
                          />
                        )}
                        <div className="errorMsg">{errors.KPIDesp}</div>
                      </div>
                      <div className="form-group col-lg-12 mt-2">
                        <label className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="toolowner"
                            defaultChecked={data.kpiData.IsAccentureOwned}
                            onChange={this.onToolOwnerChange}
                            checked={data.kpiData.IsAccentureOwned}
                          />

                          <span className="checkmark" />
                          <span className="form-check-label">{i18n.t('Accenture_Owned')}</span>
                        </label>
                        <small className="form-text text-muted">
                          <b>
                            {i18n.t('Note')}
                            :
                          </b>
                          {' '}
                          {i18n.t('NoteMessage1')}
                        </small>
                      </div>
                    </div>
                  </div>
                  {/* start of multi level UI */}
                  <div className="form-group col-lg-5">
                    <div className="right-wrapper">
                      <div className="catergory-metric-sub">
                        <div className="sub-level">
                          <i className="fas fa-sitemap" />
                          {i18n.t('Category_Metric_Sub_Levels')}
                        </div>
                        <div className="toggle-switch">
                          <Form>
                            <Form.Check
                              onClick={this.handleClick}
                              disabled={!stateKpiData.MetricName}
                              checked={stateKpiData.IsMultiLevel}
                              type="switch"
                              id="custom-switch"
                              label=""
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="metric-sub-info">
                        <div
                          className={
                            stateKpiData.IsMultiLevel
                              ? 'disable-sub-level-none'
                              : 'disable-sub-level'
                          }
                        >
                          <i className="fas fa-sitemap" />
                          <p>{i18n.t('Enable_to_add_metric_categories_as_its_sub_level')}</p>
                        </div>
                        <div
                          className={
                            stateKpiData.IsMultiLevel ? 'enable-sub-level' : 'enable-sub-level-none'
                          }
                        >
                          <div className="check-box-info">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={stateKpiData.IsAggregation}
                                onChange={(e) => {
                                  this.bindAutoAggregationChange(e);
                                }}
                                id="customCheck"
                                name="example1"
                              />
                              <label className="custom-control-label" htmlFor="customCheck">
                                {i18n.t('Aggregate_category_values_to_metric_value')}
                              </label>
                            </div>
                          </div>
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={showCategory}
                            onHide={this.handleCategoryClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {isEdit
                                  ? i18n.t('Edit_Category_Details')
                                  : i18n.t('Add_Category_Details')}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                {!iscatypedisable ? (
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {' '}
                                      {i18n.t('Category_Type')}
                                      <span className="required"> *</span>
                                    </label>
                                    <select
                                      id="name"
                                      className="form-control"
                                      disabled={isEdit}
                                      onChange={this.CatTypeSelect.bind(this)}
                                      value={CatTypeID}
                                    >
                                      <option value="0">{i18n.t('Select_CategoryType')}</option>
                                      {this.removeDuplicates(allCategoryTypesAndNames).map((CT) => (
                                        <option value={CT.ID} key={CT.ID}>
                                          {CT.CategoryType}
                                        </option>
                                      ))}
                                      <option value="addnew">{i18n.t('Add_New')}</option>
                                    </select>
                                    <div className="errorMsg">{errors.CategoryType}</div>
                                  </div>
                                ) : (
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {i18n.t('Category_Type')}
                                      <span className="required"> *</span>
                                    </label>
                                    <select
                                      id="name"
                                      className="form-control"
                                      disabled={iscatypedisable}
                                      onChange={this.CatTypeSelect.bind(this)}
                                      value={CatTypeID}
                                    >
                                      <option value="0">{i18n.t('Select_CategoryType')}</option>
                                      {allCategoryTypesAndNames.map((CT) => <option value={CT.ID}>{CT.CategoryType}</option>)}
                                      <option value="addnew">{i18n.t('Add_New')}</option>
                                    </select>

                                    <div className="errorMsg">{errors.CategoryType}</div>
                                  </div>
                                )}
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t('Category_Name')}
                                    <span className="required"> *</span>
                                  </label>
                                  <select
                                    id="name"
                                    value={CatNameID}
                                    onChange={this.handleDropdownChange}
                                    className="form-control"
                                  >
                                    <option value="0">{i18n.t('Select_CategoryName')}</option>
                                    {CategoryNameData.map((CN) => <option value={CN.ID}>{CN.CategoryName}</option>)}
                                    <option value="addnewcatname">{i18n.t('Add_New')}</option>
                                  </select>
                                  <div className="errorMsg">{errors.CategoryName}</div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-lg-6">
                                  <label>
                                    {i18n.t('Numerator_Value')}
                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    type="number"
                                    maxLength="12"
                                    value={numeratorVal}
                                    onChange={this.onNumeratorChange}
                                    className="form-control"
                                  />
                                  <span className="errorMsg">{errors.NumeratorValue}</span>
                                  <span className="errorMsg">{errors.NumeratorValueRange}</span>
                                </div>
                                <div className="form-group col-lg-6">
                                  <label>
                                    {i18n.t('Denominator_Value')}
                                    {stateKpiData.MetricUnit.trim() === '%' && (
                                      <span className="required"> *</span>
                                    )}
                                  </label>
                                  <input
                                    type="number"
                                    maxLength="12"
                                    value={denomVal}
                                    onChange={this.onDenomChange}
                                    className="form-control"
                                  />
                                  <span className="errorMsg">{errors.Denominator}</span>
                                  <span className="errorMsg">{errors.DenominatorValueRange}</span>
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                type="button"
                                className="btn btn-default btn-sm mr-2"
                                onClick={this.handleCategoryClose}
                              >
                                {i18n.t('Cancel')}
                              </button>
                              {isEdit ? (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={this.handleCategoryEdit}
                                >
                                  {i18n.t('Update')}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={this.handleCategoryAdd}
                                >
                                  {i18n.t('Add')}
                                </button>
                              )}
                            </Modal.Footer>
                          </Modal>

                          <div className="add-category-infor-here">
                            <div
                              className={
                                stateKpiData.CategoryDetails
                                && stateKpiData.CategoryDetails.length > 0
                                  ? 'add-metric-head-none'
                                  : 'add-metric-head'
                              }
                            >
                              <div
                                type="button"
                                className="btn add-category-btn"
                                onClick={this.handleCategoryShow}
                              >
                                {i18n.t('Add_Category_Details')}
                              </div>
                              <div className="add-sub-level-metrics">
                                <i className="far fa-file-plus" />
                                <p>
                                  <span>*</span>
                                  {i18n.t('Add_category_details_as_sub_level_metrics')}
                                </p>
                              </div>
                            </div>
                            {stateKpiData.CategoryDetails
                              && stateKpiData.CategoryDetails.length > 0 && (
                                <div className="category-header">
                                  <span className="category-headername">
                                    {i18n.t('Category')}
                                    {' '}
                                    -
                                    {' '}
                                    {stateKpiData.CategoryDetails
                                    && stateKpiData.CategoryDetails.length > 0
                                      ? stateKpiData.CategoryDetails[0].CategoryType
                                      : ''}
                                  </span>
                                  <div className="category-actions">
                                    <div
                                      className="category-add"
                                      onClick={this.handleCategoryTypeAddShow}
                                    >
                                      <i className="fal fa-plus-circle mr-3" />
                                    </div>
                                    <span onClick={() => this.deleteCategoryType()}>
                                      <i className="fas fa-trash-alt delete-icon-fix" />
                                    </span>
                                  </div>
                                </div>
                            )}
                            <div
                              className={
                                stateKpiData.CategoryDetails
                                && stateKpiData.CategoryDetails.length > 0
                                && stateKpiData.IsMultiLevel
                                  ? 'category-lists'
                                  : 'category-lists-none'
                              }
                            >
                              <ul>{items}</ul>
                            </div>
                          </div>

                          {/* {Add Category Type} */}
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={showaddCategoryType}
                            onHide={this.handleaddCategoryClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {i18n.t('Add_Category_Type')}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t('New_Category')}
                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    id="categoryType"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={CategoryDetails.CategoryType}
                                  />

                                  <span className="errorMsg">{errors.CategoryType}</span>
                                </div>
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t('Name')}

                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    id="categoryName"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={CategoryDetails.CategoryName}
                                  />

                                  <span className="errorMsg">{errors.CategoryName}</span>
                                </div>
                                <p className="text-right newlyadded">
                                  {i18n.t('Newly_added_category_type_will_be_sent_for_approval')}
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                className="btn btn-default btn-sm mr-2"
                                onClick={this.handleaddCategoryClose}
                              >
                                {i18n.t('Cancel')}
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={this.handleaddCategoryAdd}
                              >
                                {i18n.t('Add')}
                              </button>
                            </Modal.Footer>
                          </Modal>

                          {/* {Add Category Name} */}
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={showaddCategory}
                            onHide={this.handleaddCategoryNameClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {' '}
                                {i18n.t('Add_Category_Name')}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t('Category_Type')}
                                    <span className="required"> *</span>
                                  </label>

                                  <input
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    id=""
                                    value={CategoryDetails.CategoryType}
                                    disabled
                                  />
                                </div>

                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t('Name')}

                                    <span className="required"> *</span>
                                  </label>

                                  <input
                                    id="categoryName"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={CategoryDetails.CategoryName}
                                  />

                                  <div className="errorMsg">{errors.CategoryName}</div>
                                </div>

                                <p className="text-right newlyadded">
                                  {' '}
                                  {i18n.t('Newly_added_category_name_will_be_sent_for_approval')}
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                className="btn btn-default btn-sm mr-2"
                                onClick={this.handleaddCategoryNameClose}
                              >
                                {i18n.t('Cancel')}
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={this.handleaddCategoryAdd}
                              >
                                {i18n.t('Add')}
                              </button>
                            </Modal.Footer>
                          </Modal>

                          {/* Approval Notification */}
                          <Modal
                            className="approvalModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={showApproval}
                            onHide={this.handleApprovalClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>{i18n.t('Approval_Notification')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                {CatTypeID === 'addnew' ? (
                                  <p className="approvalInfo">
                                    {i18n.t('Newly_added_category_type_is_sent_for_approval')}
                                  </p>
                                ) : (
                                  <p className="approvalInfo">
                                    {i18n.t('Newly_added_category_name_is_sent_for_approval')}
                                  </p>
                                )}
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>

                    <div className="errorMsg">{errors.MultiLevel}</div>
                  </div>

                  {/* end of multi level UI */}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              <button onClick={this.handleHide} className="btn btn-default btn-sm mr-2">
                {' '}
                {i18n.t('Cancel')}
              </button>
              {!kpiData ? (
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value={i18n.t('Create')}
                />
              ) : (
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value={i18n.t('Update')}
                />
              )}
            </div>
          </Modal.Footer>
        </Modal>
        {viewmore ? (
          <KpiPopUp
            SOCategoryID={SOCategoryID}
            show
            onClose={this.onClose.bind(this)}
            filterDetails={filterDetails}
            tabName="BO"
          />
        ) : null}
      </>
    );
  }
}
