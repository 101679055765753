import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import TaggingUser from '../comments/TaggingUser';
import HighlightTag from '../comments/HighlightTag';
import * as BeforeAfterActions from '../actions/BeforAfterMindMapActions';
import { LocalApiBaseUrl } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';

class BeforeAfterLandingPageComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taggedUserData: [],
      Comment: '',
      taggedUsers: [],
      isApiLoading: false,
      commentKey: 1,
      charLimit: 120,
      lastSentCommentData: { Comment: '', TaggedUsers: [] },
    };
  }

  onCommentChange = (value) => {
    const { Comment } = this.state;
    if (Comment !== value && value.length <= 120) {
      this.setState({ Comment: value });
    }
  }

  onTaggedUserChange = (users) => {
    this.setState({ taggedUsers: users });
  }

   onAddOrUpdateComment = () => {
     const {
       Comment, taggedUsers, lastSentCommentData, commentKey,
     } = this.state;
     if (Comment && Comment.trim()) {
       const {
         viewData, clientData, CommentsImagesData, saveCommentsUserIcons, currentNode, saveCommentsData, userName, onShowDoubleCommentsboxes, handleNodeClick, dealId,
       } = this.props;

       const currentSectionId = viewData[currentNode].SectionId;
       const commentsJson = {
         Comment,
         ParentId: 0,
         IsDeleted: false,
         IsEdited: false,
         IsRead: false,
         TaggedUsers: taggedUsers,
         Replies: [],
         UserName: userName,
       };
       const postData = {
         OrgEntId: clientData.id,
         SectionId: currentSectionId,
         JourneyId: 0,
         Comments: [commentsJson],
       };
       this.setState({ isApiLoading: true });
       axiosInstance
         .post(`${LocalApiBaseUrl}BeforeAndAfterMindmap/PostClientMindmapComments?DealId=${dealId}`, postData)
         .then((response) => {
           if (response.data) {
             axiosInstance
               .get(`${LocalApiBaseUrl}BeforeAndAfterMindmap/GetClientMindmapComments`, {
                 params: {
                   OrgEntId: clientData.id,
                   SectionId: currentSectionId,
                   JourneyId: 0,
                   DealId: dealId,
                 },
               })
               .then((response) => {
                 if (response.data && response.data.Comments) {
                   const Ids = [];
                   response.data.Comments.forEach((each) => {
                     Ids.push(each.UserName);
                     if (each.Replies && each.Replies.length) {
                       each.Replies.forEach((eachReply) => {
                         Ids.push(eachReply.UserName);
                       });
                     }
                   });
                   let UpdatedUserNames = [...new Set(Ids)];
                   UpdatedUserNames = UpdatedUserNames.filter((eachUser) => {
                     if (!CommentsImagesData[eachUser]) {
                       return eachUser;
                     }
                   });
                   if (UpdatedUserNames.length) {
                     let paramList = '';
                     UpdatedUserNames.forEach((each, index) => {
                       paramList += `Users=${each}${(index + 1) !== UpdatedUserNames.length ? '&' : ''}`;
                     });
                     axiosInstance
                       .post(`${LocalApiBaseUrl}User/GetPeopleImage?${paramList}`)
                       .then((userData) => {
                         if (userData.data) {
                           const UpdatedUserData = {};
                           userData.data.forEach((each) => { UpdatedUserData[each.User] = `data:image/jpeg;base64,${each.ImageURL}`; });
                           saveCommentsUserIcons(UpdatedUserData);
                         }
                       });
                   }
                   saveCommentsData(response.data.Comments);
                   lastSentCommentData.Comment = commentsJson.Comment;
                   lastSentCommentData.TaggedUsers = commentsJson.TaggedUsers;
                   this.setState({
                     isApiLoading: false, lastSentCommentData, taggedUsers: [], Comment: '', commentKey: commentKey + 1,
                   });
                   onShowDoubleCommentsboxes();
                 }
               }).catch(() => {
                 this.setState({ isApiLoading: false });
               });
           }
         }).catch(() => {
           this.setState({ isApiLoading: false });
         });
     }
   };

   render() {
     const {
       taggedUserData, Comment, isApiLoading, lastSentCommentData, commentKey,charLimit,
     } = this.state;
     const { suggestions, userName, handleNodeClick,currentNode } = this.props;
     return (
       <div className="mindmap-commentrysection">
         {isApiLoading && (
         <LoadingOverlay
           fadeSpeed={0}
           spinner={<div className="common-synops-loader" />}
           active
           text="Loading Data …"
         />
         )}
         {lastSentCommentData.Comment ? (
           <div className="mindmap-commentryform">
             <HighlightTag
               allreply=""
               comments={lastSentCommentData}
               searchText=""
               userName={userName}
             />
           </div>
         ) : null }
         <div className="mindmap-commentryform">
           <TaggingUser
             key={commentKey}
             placeholderText="Post your comment here.. "
             comment={Comment}
             onCommentChange={this.onCommentChange}
             onTaggedUserChange={this.onTaggedUserChange}
             isEditMode={false}
             charLimit={charLimit}
             taggedUserData={taggedUserData}
             suggestionsData={suggestions}
           />
           <span className="textarea-submiticon">
             <FontAwesomeIcon onClick={this.onAddOrUpdateComment} icon={faPaperPlane} />
           </span>
         </div>
         <span className="view-all-comments" role="button" tabIndex={0} onClick={() => handleNodeClick(currentNode)}>View All</span>
       </div>
     );
   }
}
const mapStateToProps = (state) => ({
  clientData: state.CurrentFilter.ClientData,
  viewData: state.BeforeAfterMindMapReducer.viewData,
  CommentsImagesData: state.BeforeAfterMindMapReducer.CommentsImagesData,
  dealId: state.CurrentFilter.DealID,
});

const mapDispatchToProps = (dispatch) => ({
  saveCommentsUserIcons: (data) => dispatch(BeforeAfterActions.saveCommentsUserIcons(data)),
  saveCommentsData: (data) => dispatch(BeforeAfterActions.saveCommentsData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BeforeAfterLandingPageComments);
