import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-blue.css";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import i18n from "i18next";
import UserTrendsList from "./UserTrendsList";
axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class UserTrendsContainer extends Component {
  constructor() {
    super();
    this.state = {
      roleData: [],
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      rowSelected: [],
      featureData: [],
      isDayWiseClicked: false,
      isWeekWiseClicked: false,
      pageHeader: "",
    };
    this.onClose = this.onClose.bind(this);
    this.onDayWiseClick = this.onDayWiseClick.bind(this);
    this.onWeekWiseClick = this.onWeekWiseClick.bind(this);
    this.getRoleData = this.getRoleData.bind(this);
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  getRoleData() {
    axiosInstance
      .get(`${LocalApiBaseUrl}User/GetUserFeature`, {
        params: {},
      })
      .then((response) => {

        store.dispatch({
          type: "INITIALISEROLE",

          payload: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    store.subscribe(() => {
      var data = store.getState();

      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let featureDetails1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: featureDetails1 }, () => {
        this.loadTabBasedOnURL();
      });
    });
  }

  componentWillMount() {
    this.getRoleData();
  }

  componentDidMount() {
    this.getRoleData();
  }

  componentDidUpdate(nextProps) {
    if (
      String(this.props.location.pathname) !==
      String(nextProps.location.pathname)
    )
      this.loadTabBasedOnURL();
  }

  onDayWiseClick(e) {
    this.setState({
      isDayWiseClicked: true,
      isWeekWiseClicked: false,
    });
  }

  onWeekWiseClick(e) {
    this.setState({
      isWeekWiseClicked: true,
      isDayWiseClicked: false,
    });
  }

  setTabOnLoad(tabName) {
    let tabData = [];
    switch (tabName) {
      case "Day_Wise":
        tabData = this.state.roleData.filter(
          (data) =>
            String(data.FeatureName) === "User Trends" &&
            String(data.Submenu) === "Day Wise" &&
            data.Operations.includes("View")
        );
        if (tabData.length > 0) {
          this.onDayWiseClick();
        }
        break;
      case "Week_Wise":
        tabData = this.state.roleData.filter(
          (data) =>
            String(data.FeatureName) === "User Trends" &&
            String(data.Submenu) === "Week Wise" &&
            data.Operations.includes("View")
        );
        if (tabData.length > 0) {
          this.onWeekWiseClick();
        }
        break;

      default:
        break;
    }

    this.setState({
      pageHeader: tabData.length > 0 ? tabData[0].Submenu : "",
    });
  }

  loadTabBasedOnURL = () => {
    const reqPath = this.props.location.pathname;
    const reqPathArr = reqPath.split("/");
    if (reqPath.startsWith("/admin/UserTrends") && reqPathArr.length === 4) {
      this.setTabOnLoad(reqPathArr[3]);
    }
  };

  render() {
    const { location, filterDetails } = this.props;
    const { pageHeader, isWeekWiseClicked, isDayWiseClicked } = this.state;
    return (
      <div className="main-component">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="grid-heading">
                {i18n.t("User_Trends") +
                  " > " +
                  (location.state
                    ? i18n.t(location.state.detail)
                    : i18n.t(pageHeader))}
              </h3>
              <div className="tab-list"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <UserTrendsList
                filterDetails={filterDetails}
                isWeekWiseClicked={isWeekWiseClicked}
                isDayWiseClicked={isDayWiseClicked}
              ></UserTrendsList>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
