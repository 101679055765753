const NotificationReducer = (state = {
  notifications: [],
  newNotificationsData: { hasNewNotification: false, notificationCount: 0 },
}, action) => {
  switch (action.type) {
    case 'NOTIFICATION_DATA': {
      state = { ...state };
      state.notifications = action.payload.notifications;
      state.newNotificationsData = action.payload.newNotificationsData;
      return state;
    }
    default: return state;
  }
};
export default NotificationReducer;
