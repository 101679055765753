import React, { Component } from 'react';
import { LocalApiBaseUrl } from '../Shared/Constant';
import { AgGridReact } from 'ag-grid-react';
import i18n from "i18next";
import { connect } from "react-redux";
import LocationPopup from './locationPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class LocationList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userList: [], roleList: [], clientList: [], viewmore: false, rowSelected: [],
            columnDefs: [
                { headerName: i18n.t("Delivery Center"), field: "DeliveryCentre", suppressMovable: true, resizable: false }


            ],
            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'multiple',
                defaultColDef: {
                    sortable: true
                },
            }
        }
        this.onClose = this.onClose.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onBtnExport = this.onBtnExport.bind(this);
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }
    dealpopup(e, data) {


        if (data != undefined) {
            // edit condition
            if (this.props.featureData.filter((data) => data.Submenu == "Delivery Center" && data.Operations.includes("Edit")).length > 0) {
                e.preventDefault();
                this.setState({ viewmore: true, rowSelected: data })
            }
            else window.alert(i18n.t("Acces_Denied"))

        }
        else {
            // add condition
            if (this.props.featureData.filter((data) => data.Submenu == "Delivery Center" && data.Operations.includes("Add")).length > 0) {

                e.preventDefault();
                this.setState({ viewmore: true, rowSelected: data })
            }
            else window.alert(i18n.t("Acces_Denied"))

        }



    }
    onClose(e) {

        this.setState({ viewmore: false });
    }

    deleteLocation(e, data) {


        if (this.props.featureData.filter((data) => data.Submenu == "Delivery Center" && data.Operations.includes("Delete")).length > 0) {

            if (window.confirm(i18n.t("delete_item_confirm"))) {
                axiosInstance.delete(`${LocalApiBaseUrl}Location/DeleteLocation`, {
                    params: {
                        LocationID: data.LocationId
                    }
                }).then((response) => {
                    window.alert(response.data.StatusMessage)
                    this.getLocations();

                })
                    .catch((error) => {
                        trycatchAlertPopup(error);
                    });


            }

        }
        else window.alert("Access Denied !!")

    }

    getLocations() {

        axiosInstance.get(`${LocalApiBaseUrl}DealOnboarding/GetMasterListLocation`,
            {
                params: {

                }
            })
            .then((response) => {
                this.setState({
                    rowData: response.data
                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });


    }

    componentWillMount() {

        let columnDefs = this.state.columnDefs;
        this.getLocations();

    }

    componentDidMount() {
        let columnDefs = this.state.columnDefs;


        this.getLocations();

        if (this.props.featureData.filter((data) => data.Submenu == "Delivery Center" && data.Operations.includes("Edit")).length == 0) {
            columnDefs = columnDefs.filter((data) => data.headerName != "Edit");
            this.setState({
                columnDefs: columnDefs
            })

        }


        if (this.props.featureData.filter((data) => data.Submenu == "Delivery Center" && data.Operations.includes("Delete")).length == 0) {
            columnDefs = columnDefs.filter((data) => data.headerName != "Delete");
            this.setState({
                columnDefs: columnDefs
            })

        }
    }

    onBtnExport() {


        let params = {
            columnKeys: ['DeliveryCentre'],
            columnGroups: true,
            allColumns: false,
            skipHeader: false,
            fileName: 'DeliveryCentreList',

        };
        this.state.gridOptions.api.exportDataAsCsv(params);
    }


    handleSave(data) {

        axiosInstance.post(`${LocalApiBaseUrl}Location/AddEditLocation?languageId=${this.props.languageData.id}`, data)
            .then((response) => {
                window.alert(response.data.StatusMessage)
                this.getLocations();
            },
                (error) => {
                    trycatchAlertPopup(error);
                })
            .catch((error) => {
                trycatchAlertPopup(error);
            });

        this.getLocations();

    }

    render() {

        return (
            <div className="main-data">

                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <input type="text" className="form-control" id="filter-text-box" placeholder={i18n.t("Filter") + "..."} onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                            </div>

                            <div className="ml-auto mr-1">
                                {this.props.featureData.filter((data) => data.Submenu == "Delivery Center" && data.Operations.includes("Download")).length > 0 ?
                                    <span className="btn btn-primary btn-sm py-1" onClick={this.onBtnExport}>
                                        <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Download
                                    </span> : null}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ag-theme-balham ag-table">
                            <AgGridReact
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                paginationPageSize={50}
                                floatingFilter={true}
                                gridOptions={this.state.gridOptions}
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                            </AgGridReact>
                        </div>
                    </div>
                </div>
                {this.state.viewmore ? <LocationPopup rowData={this.state.rowData} data={this.state.rowSelected} show={this.state.viewmore} onClose={this.onClose} onSave={this.handleSave} /> : null}
            </div>

        )
    }
}
const mapStateToProps = (state) => {
    return ({
        languageData: state.CurrentFilter.languageData
    })
}
export default connect(mapStateToProps)(LocationList);