import React from "react";
import { Modal } from "react-bootstrap";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import i18n from "i18next";
import { regexForTextAreas } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

export default class RejectPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      params: {
        Status: null,
        MonthDt: null,
        DealID: null,
        Remarks: null,
        rejectedByImplementationTeam: false
      },
      remarks: "",
      errors: {},
      formIsValid: false,
      filter: { OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null },
      postData: []
    };

    this.handleHide = this.handleHide.bind(this);
    this.handleRejectHide = this.handleRejectHide.bind(this);
    this.handleApproveHide = this.handleApproveHide.bind(this);
    this.handlePost = this.handlePost.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRemarksChange = this.onRemarksChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    let offeringValue = "";
    let value1 = [];
  }

  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }


  onRemarksChange(event) {
    const message = event.target.value;
    this.setState({
      remarks: message
    });
  }

  validateForm() {
    const remarks = this.state.remarks.trim();
    let errors = {};
    let formIsValid = true;

    var isValid = regexForTextAreas.test(remarks);
    if (!isValid) {
      formIsValid = false;
      errors["RemarkField"] = i18n.t("Special_Characters_not_allowed");
    }

    if (!remarks) {
      formIsValid = false;
      errors["RemarkField"] = i18n.t("Remarks_cannot_be_empty");
    }

    this.setState({
      errors: errors
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true
      });
    } else {
      this.setState({
        formIsValid: false
      });
    }
    return formIsValid;
  }
  handleSubmit() {
    if (this.validateForm()) {
      this.handlePost("Reject", this.props.rejectedByImplementationTeam);
    }
  }

  handlePost(status, rejectedByImplementationTeam) {
    var params = this.state.params;
    params.Status = status;
    params.MonthDt = this.props.filterDetails.Month;
    params.DealID = this.props.filterDetails.Client;
    params.Remarks = this.state.remarks;
    params.rejectedByImplementationTeam = rejectedByImplementationTeam;
    var filter = this.state.filter;

    params.MonthDt = this.props.filterDetails.Month;
    filter.LocationID = this.props.filterDetails.Locations;
    filter.OrgEntityID = this.props.filterDetails.Client;
    filter.OfferingID = this.props.filterDetails.process;
    filter.MonthDT = this.props.filterDetails.Month;
    this.setState({
      params: params,
      active: true
    });
    this.CreateSaveAndSubmitData();
    var data = {
      ConfiguratorDataModels: this.state.postData,
      ConfiguratorRequestParamsModel: this.state.params,
      FiltersIDsVM: filter
    };
    const { filterDetails } =this.props
    axiosInstance
      .post(`${LocalApiBaseUrl}Configurator/PostConfiguratorData`, data)
      .then(response => {
        if (response.data.IsSuccess) {
          switch (status) {
            case "Save":
              alert(i18n.t("dashboardCntContainerSucM1"));
              break;
            case "Submit":
              alert(i18n.t("dashboardCntContainerSucM4"));
              break;
            case "Publish":
              alert(i18n.t("dashboardCntContainerSucM5"));
            case "Reject":
              alert(i18n.t("Data_Rejected"));
              this.props.onStatus(filterDetails);
            default:
              break;
          }
          this.setState({ active: false });
        } else {
          alert(i18n.t("dashboardCntContainerSucM6")
          );
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });



    this.handleHide();
  }
  CreateSaveAndSubmitData() {
    this.state.postData = [];
    var data = store.getState();
    var dataParam = {
      TabName: null,
      TabData: []
    };

    var BOData = data.BOReducers["BO"];
    dataParam.TabName = "Business Outcome";
    dataParam.TabData = [...BOData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: []
    };
    var HMData = data.humanReducers["humanandmachine"];
    dataParam.TabName = "Human & Machine Talent";
    dataParam.TabData = [...HMData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: []
    };
    var IIData = data.intelligentInsightReducers["insightandintelligence"];
    dataParam.TabName = "Insights & Intelligence";
    dataParam.TabData = [...IIData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: []
    };
    var DiverseData = data.diverseReducers["diverse"];
    dataParam.TabName = "Diverse Data";
    dataParam.TabData = [...DiverseData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: []
    };
    var WOData = data.WOtileReducers["tile"];
    dataParam.TabName = "Work Orchestration";
    dataParam.TabData = [...WOData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: []
    };
    var IntelOpsData = data.intelligentReducers["IOJ"];
    dataParam.TabName = "IntelOps";
    dataParam.IntelTabData = [...IntelOpsData];
    this.state.postData.push(dataParam);
    var dataParam = {
      TabName: null,
      TabData: [],
  };
  var MaturityData = data.MaturityReducers["Maturity"];
  dataParam.TabName = "MaturityPlanData";
  dataParam.MaturityPlanTabData = [...MaturityData];
  this.state.postData.push(dataParam);

}

  render() {
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          className="modal fade"
          size="medium"
          id="dealpopup"
          backdrop = "static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">
              {i18n.t("Please_enter_the_Comments")}
            </h4>
          </Modal.Header>
          <Modal.Body>

            <form>
              <div className="form-section">
                <div className="form-group col-lg-12">
                  <div className="pull-left">
                  {i18n.t("Below_field_is_mandatory")}
                      <span className="required">*</span>
                  </div>
                  <textarea
                    className="form-control"
                    rows="5"
                    onChange={this.onRemarksChange}
                  ></textarea>
                  <span className="errorMsg">
                    {this.state.errors.RemarkField}
                  </span>
                </div>
              </div>
            </form>

          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              <input
                type="Button"
                className="btn btn-primary btn-sm"
                onClick={() => this.handleSubmit()}
                value={i18n.t("Submit")}
              />
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
