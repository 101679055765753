import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import i18n from 'i18next';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import LoadingOverlay from 'react-loading-overlay';
import { Modal } from 'react-bootstrap';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl,idTokenstring } from '../Shared/Constant';
import './JourneyInitiative.css';

import EmptyCriteria from '../Images/EmptyCriteria.svg';
import Pagination from './Pagination';
import SynopsLoader from '../Shared/SynopsLoader';

import DeleteModal from './DeleteModal';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;


class Outcomes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outcomesequence: '',
      isaddCriteriaDropdownStatus: false,
      iscriteriaStatusDropdownStatus: false,
      initiativeStage: this.props.initiativeStage,
      isAccess: this.props.isAccess,
      deleteAssessment: false,
      deleteAssessmentData: {},
      successNotificationModalStatus: false,
      showsuccessModal: false,
      selectedInitiative: 'All',
      addNewOptionStatus: false,
      selectedAssessmentCriteriaValue: '',
      selectedStatusvalue: '',
      selectedAssessmentCriteriaID: undefined,
      isApiLoading: false,
      outComeListData: [],
      currentPage: 1,
      postsPerPage: 10,
      allSequences: [],
    };
  }

  componentDidMount() {
    const { outComeListData, outComeSequenceData } = this.props;

    this.setState({
      outComeListData,
      allSequences: outComeSequenceData,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      getDataCounter, getOutcomes, outComeSequenceData, selectedOutcome,
    } = this.props;

    if (prevProps.outComeSequenceData !== outComeSequenceData) {
      this.setState({
        allSequences: outComeSequenceData,
      });
    }

    if (prevProps.getDataCounter !== getDataCounter) {
      getOutcomes();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { Datas } = nextProps;
    let currentPage = 1;
    if (nextProps.outComeListData.length >= this.props.outComeListData.length) {
      currentPage = this.state.currentPage;
    } else if (!Number.isInteger(nextProps.outComeListData.length / this.state.postsPerPage)) {
      currentPage = this.state.currentPage;
    } else {
      currentPage = (nextProps.outComeListData.length / this.state.postsPerPage) != 0 ? (nextProps.outComeListData.length / this.state.postsPerPage) : 1;
    }
    if (nextProps.filterChanged) {
      currentPage = 1;
    }

    this.setState({
      isAccess: Datas.isAccess,
      outComeListData: nextProps.outComeListData,
      currentPage,
    });
  }

  handleCurrentPage = (data) => {
    this.setState({
      currentPage: data,
    });
  };

  handlePostsPerPage = (data) => {
    this.setState({
      postsPerPage: data,
    });
  };

  deleteItemModalShow = (outcome) => {
    this.setState({ deleteAssessment: true, deleteAssessmentData: outcome });
  };

  deleteItemModalClose = () => {
    this.setState({ deleteAssessment: false });
  };

  hideOutcome = (outcome) => {
    this.setState({ isApiLoading: true });
    const { getOutcomes } = this.props;
    axiosInstance
      .post(`${LocalApiBaseUrl}IOJJourneyInitiative/ShowHideIOJOutcomes?ID=${outcome.ID}`)
      .then((response) => {
        if (response.data) {
          this.setState({

            isApiLoading: false,
          });
          this.props.settingFilterChangedTrue();
          getOutcomes();
        }
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });
  };

  successRemovalModalShow = () => {
    const { deleteAssessmentData } = this.state;
    const { getOutcomes } = this.props;
    this.setState({ isApiLoading: true });
    axiosInstance
      .delete(`${LocalApiBaseUrl}IOJJourneyInitiative/DeleteIOJOutcomes?ID=${deleteAssessmentData.OutComeID}`)
      .then((response) => {
        if (response.data) {
          this.setState({
            showsuccessModal: true,
            deleteAssessment: false,
            isApiLoading: false,
          });
          this.props.settingfilterChangedState();
          getOutcomes();
        }
      })
      .catch((error) => {
        this.setState({ isApiLoading: false, deleteAssessment: false });
        trycatchAlertPopup(error);
      });
  };

  changeSequenceOnDrop = (outcome, target) => {
    // IOJJourneyInitiative/UpdateOutcomeSequence?OutcomeID={id}&JourneyID={id}&IsConfigurator=true&TargetSequence=1
    axiosInstance
      .post(
        `${LocalApiBaseUrl}IOJJourneyInitiative/UpdateOutcomeSequence?OutcomeID=${outcome.OutComeID}&JourneyID=${outcome.ConfigurationIntelOpsJourneyID}&IsConfigurator=${true}&TargetSequence=${target}`,

      )
      .then((response) => {

      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  successRemovalModalClose = () => {
    this.setState({ showsuccessModal: false });

    this.setState({ stauts: false });
  };

  handleEditOutcome = (outcome) => {
    this.props.editOutcome(outcome);
  };

  handleAddChange = ({ target }) => {
    const OutcomeName = target.value;
    this.props.addOutcomeDataChange(OutcomeName);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const currentPage = (this.state.currentPage - 1) * this.state.postsPerPage;
    oldIndex += currentPage;
    newIndex += currentPage;
    const outComeList = this.state.outComeListData[oldIndex];
    let newOutcomeDataList = arrayMove(this.state.outComeListData, oldIndex, newIndex)
    newOutcomeDataList.map((each, index) => each.Sequence = index + 1);
    this.setState({
      outComeListData: newOutcomeDataList,
    },
      () => { this.props.SequenceChanged(newOutcomeDataList) });

    this.changeSequenceOnDrop(outComeList, newIndex + 1);
  };

  handleSelectDropdownOption = (value) => {
    this.props.handleSelectSequenceDropdownOption(value);
  }

  getOptionObjFromDropdownValue = (
    dropdownArray,
    selectedValue,
    fieldToCheckFor,
  ) => {
    let found;
    if (!_.isEmpty(fieldToCheckFor)) {
      found = _.find(dropdownArray, [`${fieldToCheckFor}`, selectedValue]);
    } else {
      found = _.find(dropdownArray, ['value', selectedValue]);
    }
    return found;
  }

  render() {
    const { history, selectedOutcome, OMIDEnabled } = this.props;

    const {
      outComeListData, deleteAssessment, isAccess, isApiLoading, currentPage,
      postsPerPage, allSequences, outcomesequence,
    } = this.state;
    const { addEditOutcomeFormStatus, errorMessageOutcome } = this.props;

    const indexOfLastPosts = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPosts - postsPerPage;

    const currentPosts = outComeListData.slice(indexOfFirstPost, indexOfLastPosts);

    const SortableContainer = sortableContainer(({ children }) => <div className="drag-drop-body">{children}</div>);
    const SortableItem = sortableElement(({ value }) => (
      <>
        <div className={value.IsHidden ? "drag-drop-columns hidden-row" : "drag-drop-columns"}>
          <DragHandle />
          <div className="columns-data"><span className="name-highlighted">{value.OutcomeDesc}</span></div>
          <div className="columns-data">
            <span>
              {value.Initiative != null
                ? value.Initiative
                : ''}
            </span>
          </div>

          {isAccess && history.location.pathname === '/admin/Configurator'
            ? (
              <div className="common-ellipsis-dropdown">
                <div className="dropdown">
                  <span type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" />
                  <div className="dropdown-menu dropdown-menu-right">
                    {OMIDEnabled
                      ? (
                        value.IsCustom ? (
                          <>
                            <a className="dropdown-item" href="javascript:void(0);" onClick={() => { this.handleEditOutcome(value); }}>
                              <span className="edit-icon" />
                              Edit
                            </a>
                            <a className="dropdown-item" href="javascript:void(0);" onClick={() => this.deleteItemModalShow(value)}>
                              <span className="delete-icon" />
                              Delete
                            </a>
                            <a className="dropdown-item " href="javascript:void(0);" onClick={() => this.hideOutcome(value)}>
                              <span className={value.IsHidden ? 'eye-icon' : 'eye-icon-slash'} />
                              {value.IsHidden ? 'Show' : 'Hide'}
                            </a>
                          </>
                        ) : (
                          <>
                            <a className="dropdown-item" href="javascript:void(0);" onClick={() => { this.handleEditOutcome(value); }}>
                              <span className="edit-icon" />
                              Edit
                            </a>
                            <a className="dropdown-item disabled" href="javascript:void(0);" >
                              <span className="delete-icon" />
                              Delete
                            </a>
                            <a className="dropdown-item disabled" href="javascript:void(0);" >
                              <span className={value.IsHidden ? 'eye-icon' : 'eye-icon-slash'} />
                              {value.IsHidden ? 'Show' : 'Hide'}
                            </a>
                          </>
                        )


                      )
                      : (
                        <>
                          <a className="dropdown-item disabled" href="javascript:void(0);">
                            <span className="edit-icon" />
                            Edit
                          </a>
                          <a className="dropdown-item disabled" href="javascript:void(0);">
                            <span className="delete-icon" />
                            Delete
                          </a>
                          <a className="dropdown-item " href="javascript:void(0);">
                            <span className="eye-icon" />
                            Show
                          </a>
                        </>
                      )}

                  </div>
                </div>
              </div>
            )
            : null}
        </div>
      </>
    ));
    const DragHandle = sortableHandle(() => (
      <span className="dragg-icon">
        {isAccess && history.location.pathname === '/admin/Configurator' ? (
          <>
            <i className="fas fa-ellipsis-v" />
            <i className="fas fa-ellipsis-v" />
          </>
        )
          : null}
      </span>
    ));

    return (
      <>
        {isApiLoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />}

        {outComeListData.length == 0 && addEditOutcomeFormStatus == '' ? (
          isAccess && history.location.pathname === '/admin/Configurator' && OMIDEnabled ? (
            <div className="emptycriteria-block">
              <img alt="" src={EmptyCriteria} width="78px" height="90px" />
              <h4>{i18n.t('Add_Outcomes')}</h4>
            </div>
          ) : (
            <div className="emptycriteria-block">
              <p>{i18n.t('No_Outcomes_Added')}</p>
            </div>
          )
        ) : null}

        {outComeListData.length && addEditOutcomeFormStatus == '' ? (
          <>
            <div className="drag-drop-table">
              <div className="drag-drop-header">
                <div className="drag-drop-columns">{i18n.t('Outcome')}</div>
                <div className="drag-drop-columns">{i18n.t('Initiative')}</div>
              </div>
              <SortableContainer onSortEnd={this.onSortEnd} helperClass="dragging-helper-class" useDragHandle>
                {currentPosts.map((value, index) => (
                  <SortableItem key={`item-${value}`} index={index} value={value} sortIndex={index} distance={1} lockAxis="y" />
                ))}
              </SortableContainer>
            </div>
            <div className="listOfAddCriteria listOfOutcome">
              {outComeListData.length > 10 && postsPerPage >= 10 && (
                <Pagination
                  totalPosts={outComeListData.length}
                  postsPerPage={postsPerPage}
                  currentPage={currentPage}
                  changePostsPerPage={this.handlePostsPerPage}
                  handleCurrentPage={this.handleCurrentPage}
                />
              )}
            </div>
          </>
        ) : null}

        {/* ADD and Edit Form */}
        {isAccess && addEditOutcomeFormStatus == 'Add' && (
          <>
            <div className="outcome-form">
              <h3>
                {addEditOutcomeFormStatus}
                {' '}
                {i18n.t('Outcome')}
              </h3>
              <div className="form-group-container">
                <div className="form-group-wrapper">
                  <div className="form-group border-group">
                    <label htmlFor="outcomeName" className="form-label">
                      {i18n.t('Outcome')}
                      <span className="required"> *</span>
                    </label>
                    <input
                      type="text"
                      name="outcomeName"
                      placeholder="Outcome"
                      className="form-control"
                      value={i18n.t(selectedOutcome.OutcomeDesc_En)}
                      onChange={(e) => this.handleAddChange(e)}
                      maxLength="150"
                    />
                  </div>
                  <span className="errorMsg">{errorMessageOutcome}</span>
                </div>

                <div className="form-group-wrapper">
                  <div className="form-group border-group">
                    <label htmlFor="displaysequence" className="form-label">
                      Display Sequence
                    </label>
                    <div className="initiative-dropdown">
                      <Dropdown
                        id="sequenceStatus"
                        name="sequenceStatus"
                        onSelect={(selectedOptionEventKey) => this.handleSelectDropdownOption(selectedOptionEventKey)}
                      >
                        <Dropdown.Toggle id="sequenceIdToggle">
                          {!_.isEmpty(selectedOutcome.Sequence) && !_.isEmpty(selectedOutcome.Sequence)
                            ? _.get(
                              this.getOptionObjFromDropdownValue(allSequences, selectedOutcome.Sequence),
                              'label',
                              '',
                            )
                            : i18n.t('Display_Sequence')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {_.map(allSequences, (eachItem) => (
                            <Dropdown.Item
                              eventKey={eachItem.value}
                              active={selectedOutcome.Sequence === eachItem.value}
                            >
                              {eachItem.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
        )}
        {isAccess && addEditOutcomeFormStatus == 'Edit' && (
          <div className="outcome-form">
            <h3>
              {addEditOutcomeFormStatus}
              {' '}
              {i18n.t('Outcome')}
            </h3>
            <div className="form-group-container">
              <div className="form-group-wrapper">
                <div className="form-group border-group">
                  <label htmlFor="outcomeName" className="form-label">
                    {i18n.t('Outcome')}
                    <span className="required"> *</span>
                  </label>
                  <input
                    type="text"
                    name="outcomeName"
                    placeholder="Outcome"
                    className="form-control"
                    value={selectedOutcome.OutcomeDesc_En}
                    onChange={(e) => this.handleAddChange(e)}
                    maxLength="150"
                    disabled={!selectedOutcome.IsCustom}
                  />
                  {' '}
                </div>
                <span className="errorMsg">{errorMessageOutcome}</span>
              </div>
              <div className="form-group-wrapper">
                <div className="form-group border-group">
                  <label htmlFor="displaysequence" className="form-label">
                    Display Sequence
                  </label>
                  <div className="initiative-dropdown">
                    <Dropdown
                      id="sequenceStatus"
                      name="sequenceStatus"
                      onSelect={(selectedOptionEventKey) => this.handleSelectDropdownOption(selectedOptionEventKey)}
                    >
                      <Dropdown.Toggle id="sequenceIdToggle">
                        {!_.isEmpty(String(selectedOutcome.Sequence)) && !_.isEmpty(String(selectedOutcome.Sequence))
                          ? _.get(
                            this.getOptionObjFromDropdownValue(allSequences, String(selectedOutcome.Sequence)),
                            'label',
                            '',
                          )
                          : i18n.t('Display_Sequence')}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {_.map(allSequences, (eachItem) => (
                          <Dropdown.Item
                            eventKey={eachItem.value}
                            active={String(selectedOutcome.Sequence) === eachItem.value}
                          >
                            {eachItem.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {deleteAssessment ? (
          <DeleteModal
            title="Delete_Outcome"
            Message="Are_you_Sure_you_Want_to_Delete"
            deleteAssessment={deleteAssessment}
            deleteItemModalClose={this.deleteItemModalClose}
            deleteItem={this.successRemovalModalShow}
          />
        ) : (
          ''
        )}
        {/* Delete MOR Item Modal Popup Start */}

        {/* Delete  Item Modal Popup End */}
        {/* Delete Notification Modal Popup Start */}
        <Modal
          show={this.state.showsuccessModal}
          onHide={this.successRemovalModalClose}
          className="successNotification-modal"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t('Notification')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="successnotification-body">
              <div className="successnotification-icon">
                <span className="mr-4">
                  <i className="fal fa-trash" />
                </span>
              </div>
              <div className="successnotification-data">
                <p>{i18n.t('Outcome_deleted_successfully')}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Delete Notification Modal Popup End */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getDataCounter: state.JourneyReducer.getDataCounter,
  Datas: state.JourneyReducer.Datas,
  OMIDEnabled: state.JourneyReducer.OMIDEnabled,
});
export default connect(
  mapStateToProps,
  '',
)(Outcomes);
