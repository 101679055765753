export const UINames = {
  //GPH Start
  Attendance: "Attendance",
  "Productive Utilization": "Productive Utilization",
  Efficiency: "Efficiency",
  ProductiveHours: "Productive (Hours/Seconds)",
  ShrinkageHrs: "Shrinkage (Hours/Seconds)",
  "Capacity Utilization": "Capacity Utilization",
  // GPH End
  ///////////////////////////////////
  // ROC Start
  "Success Transactions": "Success Transactions",
  "Failure Transactions": "Failure Transactions",
  "Total Transaction": "Total Transactions",
  AHT: "Average Handing Time (AHT)",
  Process: "Total Processes",
  // ROC End
  ////////////////////////////////////
  //ATCAT Start
  NoofInvoicedTransactionsProcessed: "Transactions Processed (Volume)",
  ValueOfInvoiceTransactionsProcessed: "Transactions Processed (Value)",
  NoOfInvoicesPreventedFromDuplicatePayment: "Invoices Prevented From Duplicate Payment (Volume)",
  ValueOfInvoicePreventedFromDuplicatePayment: "Invoices Prevented From Duplicate Payment (Value)",
  NoOfDuplicatePayments: "Duplicate Payments (Volume)",
  ValueOfDuplicatePayment: "Duplicate Payment (Value)",
  NoOfPotentialDuplicates: "Potential Duplicates (Volume)",
  ValueOfPotentialDuplicates: "Potential Duplicates (Value)",
  NoOfExceptionsMovedtoClaim: "Exceptions Moved To Claim (Volume)",
  NoOfExceptionsAgening: "Exceptions Agening (Volume)",
  NoOfClaimsAgening: "Claims Agening (Volume)",
  // ATCAT END
  ////////////////////////////////////
  // SQF Start
  "Audit Coverage": "Audit Coverage",
  Calibration_score: "Calibration Score",
  Calibration_session: "Calibration Session",
  Defects: "Defects",
  "Dispute Completion": "Dispute Completion",
  "Dispute Rate": "Dispute Rate",
  "Dispute Success Rate": "Dispute Success Rate",
  "Open Disputes": "Open Disputes",
  "Pending Audit>5Days": "Pending Audit>5Days",
  "Pending Audits": "Pending Audits",
  QA_Score: "QA Score",
  "Sigma level": "Sigma level",
  "SQF Adherence Rate": "SQF Adherence Rate",
  "SQF configuration rate": "SQF configuration rate",
  "Transactions Audited": "Transactions Audited",
  "Transactions Uploaded": "Transactions Uploaded",
  // SQF End
  ////////////////////////////
  //DFM Start
  "Volume_Inflow": "Volume Inflow",
  Volume_Open: "Volume Open",
  Volume_Processed: "Volume Processed",
  Volume_Backlog: "Volume Backlog",
  //DFM End
  /////////////////////////////
  //CAA Start
  "Payments Processed":"Payments Processed",
  "Minimal Touch Rate" :"Minimal Touch Rate",
  "Remittance Accuracy":"Remittance Accuracy",
  //CAA End
  //////////////////////////////
  //PMM Start
  "Policy":"Policy",
  "Practice":"Practice",
  "Platform":"Platform",
  //PMM End

};