import React, { Component } from 'react';
import { LocalApiBaseUrlImage } from '../Shared/Constant';



export default class SynopsHomePage extends Component {
    constructor(props) {

        super(props)

        this.state = { clientHide:false
        }


    }
        
    render() {

        return (
            <div>

                <div className="business-filter padding-big">
                    <div className="container-fluid">

                        <section className="py-md-8">
                                <div className = "row">
                                <div className="col-md-8 col-lg-8">
                                    <h1 className="display-3">Welcome to <span className="text-primary">SynOps</span>.</h1>
                                    <p className="lead">
                                        People, Technology, Data and Intelligence, working together—like never imagined before.</p>
                                    <h2 className="display-4">WHAT IS IT?</h2>
                                    <p className="lead">
                                        <span className="text-primary">SynOps</span> is an innovative human-machine operating ‘engine’ that orchestrates the optimal synergy of data,
                                         applied intelligence and digital technologies, to power our exceptional industry and functional talent
                                          to help organizations transform business operations, create exceptional user experiences and deliver
                                           results previously unimaginable.</p>
                                    <div className="row">
                                        <div className="col-md-12 button-section">
                                            <a href="https://in.accenture.com/accentureoperations/SynOps/" className="btn btn-primary"  target="_blank">
                                                View More </a>
                                       </div>
                                    </div>
                                </div>
                                <div className=" col-md-4 col-lg-4">
                                    <div><img src={LocalApiBaseUrlImage+"/SOImages/812161_SynOps_Gradient-Clover.jpg"} alt="SynOps large logo" /></div>
                                </div>
                                </div>
                        </section>
                    </div>
                </div>
                <div className="business-filter padding-big">
                <div className="container-fluid">

    <section className="py-md-8">
    <div className="col-md-12">
    <h2 className="display-4">WHAT DOES IT DO?</h2>
                                    <p className="lead">
                                    For our clients, the ultimate destination is to run <a target="_blank" href="https://www.accenture.com/us-en/insight-intelligent-operations"><span className="text-primary">intelligent operations</span></a>, where the right set 
                                    of streamlined processes, automation and analytics are applied and orchestrated appropriately. <strong>SynOps</strong> enables Intelligent Operations. <strong>SynOps</strong> provides the functional architecture and core
                                      capabilities, to provide organizations with a fit-for-purpose and flexible roadmap for achieving
                                       higher levels of efficiency and effectiveness across the enterprise and accelerate their journey 
                                       to <a target="_blank" href="https://www.accenture.com/us-en/insight-intelligent-operations"><span className="text-primary">intelligent operations.</span></a>
                     
                       </p>
                       <p className="lead">
                       SynOps enables companies to make their organizations more flexible, agile and responsive, helping them generate 
                       additional value by driving growth and scaling operations quickly. SynOps’ creates compound intelligence from 
                       emerging technologies powered by human expertise to deliver better insights, which lead to better-informed decisions, stronger outcomes—and results previously unimaginable.
                       </p>

                       <p className="lead">
                       Here’s just a few examples of the transformational business outcomes SynOps is delivering for clients, today:
                       </p>

                       <ul>
                           <li>We’ve helped a global Energy Company reduce the time it takes to research new services from 6 weeks, to just 5 days.</li>
                           <li>We’ve helped a global hotel chain to reduce the time it takes to process an invoice from 15 days, to 1.</li>
                           <li>We’ve helped a high-tech company increase its revenue 11 times over, in just 4 months.
</li>
                       </ul>
                       <h2 className="display-4">HOW DOES IT WORK?</h2>
                       <p className="lead">
                       SynOps harnesses data and insights from more than 1,000 Accenture client engagements and hundreds of
                        years of cumulative expertise across business functions, industries and domains. It plugs into and 
                        synthesizes system data from clients’ existing systems, and draws on external data to provide benchmarks, 
                        to show where there is high impact opportunity for business process reinvention. It applies advanced AI,
                         analytics, workflow management intelligence and delivers insights that lead to better, faster decision-making
                          at scale.
                       </p>
                       <p className="lead">
                           <strong>The client outcome is a commitment from Accenture to monetize break-through business outcomes that are made possible by SynOps.</strong>
                       </p>
    </div>
        </section>

        </div>
                </div>
              
            </div>

        )
    }
}