import React from "react";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import PropTypes from "prop-types";
const DragHandle = sortableHandle(() => (
  <span className="dragg-icon">
    <i className="fas fa-ellipsis-v" />
    <i className="fas fa-ellipsis-v" />
  </span>
));

const KeyOutcomesForm = sortableElement(
  ({
    sortIndex,
    desc,
    dataLength,
    addKeyOutcomes,
    deleteKeyOutcomes,
    uniqueId,
    outcomeDescriptionChange,
    error,
  }) => (
    <div className="dragg-wrapper">
      <DragHandle />
      <div className="row dragg-list">
        <div className="col-lg-12 dragg-list-right">
          <div className="form-section">
            <div className="form-group border-group">
              <label htmlFor="description" className="form-label">
                Outcome Description
                <span className="required">&nbsp;*</span>
              </label>
              <textarea
                id="description"
                name="description"
                rows="4"
                className="form-control"
                placeholder="Outcome Description"
                maxLength="500"
                value={desc}
                onChange={(e) => outcomeDescriptionChange(e, uniqueId)}
                required
              />
              <span className="suffix-text">{desc.length} /500 Characters</span>
            </div>
            <span className="errorMsg">{error}</span>
          </div>
        </div>
      </div>
      <span
        className={
          dataLength - 1 === sortIndex ? "add-delete-actions" : "add-delete-actions single-action"
        }
      >
        {dataLength - 1 === sortIndex && (
          <span className="add-icon" onClick={() => addKeyOutcomes()} />
        )}
        <span className="delete-icon" onClick={() => deleteKeyOutcomes(uniqueId)} />
      </span>
    </div>
  )
);

export default React.memo(KeyOutcomesForm);
