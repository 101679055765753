import React from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import jquery from "jquery";
import ReactTooltip from "react-tooltip";
import { store } from "../reducers/configureStore";
import MetricView from "../Configurator/MetricView";
window.$ = window.jQuery = jquery;

export default class MetricDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewmore: false,
      data: [],
      boData: {
        SOCategoryID: 0,
        kpiData: [
        ]
      },
    };
  }

  dealpopup = (data, e) => {
    e.preventDefault();
    this.setState({
      data: data
    });

    this.setState({
      viewmore: true
    }); 

    e.preventDefault();
  };

  onClose = e => {
    this.setState({ viewmore: false });
  };

  deleteKpi(data, e) {
    if (window.confirm("Are you sure you want to delete metric detail?")) {
      const boData = this.state.boData;
      boData.SOCategoryID = this.props.SOCategoryID;
      e.stopPropagation();

      boData.kpiData = data;
      this.setState({
        boData: boData
      });
      store.dispatch({
        type: "DELETE_BO",
        payload: this.state.boData
      });
    }
  }

  render() {
    
    const options = {
      loop: true,
      margin: 16,
      responsiveClass: "",
      autoWidth: true,
      dots: false,
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
            items: 3,
            nav: true,
            loop: false
        },
        1440: {
            items: 4,
            nav: true,
            loop: false
          },
          1600: {
              items: 4,
              nav: true,
              loop: false
          },
          1800: {
              items: 5,
              nav: true,
              loop: false
        }
      }
    };
    return ( 
      <>
      <div className="custom-carousel-padding">
        <OwlCarousel options={options} className="owl-carousel">
          {this.props.Details.map(eachDetails => (
            <MetricView
              isRelatorBlock={this.props.isRelatorBlock}
              data={eachDetails}
              SOCategoryID={this.props.SOCategoryID}
              type={this.props.SOCategoryName}
              kpiData={this.props.data}
              opsgrp={this.props.opsgrp}
              attributes={this.props.attributes}
              filterDetails={this.props.filterDetails}
              RecordStatus = {this.props.RecordStatus} isManintencneWindowOpen ={this.props.isManintencneWindowOpen} 
              responseMetricData = {this.props.responseMetricData}
            ></MetricView>
          ))}
        </OwlCarousel>
        <ReactTooltip place="bottom" effect="solid" className="tipcolor" />
        </div>
      </>
    );
  }
}