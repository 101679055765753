/* eslint-disable */
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';

 export default function Preloader() {
   const SynopsLoader = () => <div className="common-synops-loader"></div>
    return (    
        <LoadingOverlay
            fadeSpeed={0} spinner={<SynopsLoader />}
            active={true}
            spinner
            text={"Loading Data" + " …"}
        >
        </LoadingOverlay>
    );
  }

