import React from "react";
import References from "../Shared/References";

const ClientStoryReferences = ({
  SOCatID,
  leverName,
  isConfig,
  referenceScreen,
  Client,
  process,
  Locations,
  isClientStory,
}) => {
  const filterDetails = {
    Client,
    process,
    Locations,
  };
  return (
    <>
      <References
        filterDetails={filterDetails}
        SOCatID={SOCatID}
        leverName={leverName}
        isConfig={isConfig}
        referenceScreen={referenceScreen}
        isClientStory={isClientStory}
      />
    </>
  );
};

export default React.memo(ClientStoryReferences);
