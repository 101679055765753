export const SET_CLIENT_OPRIA_SISENSE_DETAILS = 'SET_CLIENT_OPRIA_SISENSE_DETAILS';
export const SET_SISENSE_SCRIPT = 'SET_SISENSE_SCRIPT';

export function setClientOpriaSisenseDetails(dataObj) {
  return {
    type: SET_CLIENT_OPRIA_SISENSE_DETAILS,
    payload: dataObj,
  };
}

export function setSisenseScript(data){
  return{
    type : SET_SISENSE_SCRIPT,
    payload: data,
  }
}
