import React, { useEffect } from 'react';
import { FDAnalyticsUrl } from '../Shared/Constant';

export default function FrontDoorAnalytics() {

  return (
    <div className="synops-frontdoor-analytics">
      <iframe
        id="synops-frontdoor-analytics"
        width="100%"
        height="500px"
        src={FDAnalyticsUrl}
        frameBorder="0"
        allowFullScreen
        title="synops-frontdoor-analytics"
      />
    </div>
  );
}
