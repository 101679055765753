import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

function SingleDropDownHnM({ labelledby, options, onSelectChange, selected, showAutomationType }) {
  return (
    <div className="iwgph-filters">
      <div className="formsection-fields form-section single-dropdown">
        <div className="form-group border-group">
          <label htmlFor="profile" className="form-label">
            {labelledby}
          </label>
          <div className="customform-dropdown">
            <DropdownButton id="dropdown-human" title={selected} onSelect={onSelectChange}>
              {options.length > 0 && showAutomationType &&
                options.map((each) => {
                  return (
                    <Dropdown.Item value={each.key} eventKey={each.key}>
                      {each.props.children}
                    </Dropdown.Item>
                  );
                })}
                {
                  !showAutomationType && <Dropdown.Item
                  value=""
                  eventKey=""
                  disabled
                >
                  No data available for this type
                </Dropdown.Item>
                }
            </DropdownButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleDropDownHnM;
