import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import i18n from 'i18next';
import MetricValueSkyIcon from '../Images/MetricValue-sky.svg';
import MetricValueIconPurple from '../Images/MetricValue-Purple.svg';

class BoiTrends extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      showEditData, showBoiEditButton, onShowEditDataClick, boiTrendsData,
    } = this.props;
    if (boiTrendsData && boiTrendsData.datasets && boiTrendsData.datasets.length) {
      boiTrendsData.datasets.map((each, index) => {
        const ctx = document.getElementById('LineChart');
        if (each.label === 'Current_Trends' && ctx) {
          const gradientOne = ctx.getContext('2d').createLinearGradient(0, 0, 0, 450);
          gradientOne.addColorStop(0, 'rgba(0, 186, 255,0.7)');
          gradientOne.addColorStop(0.5, 'rgb(0, 186, 255,0.2)');
          gradientOne.addColorStop(1, 'rgb(0, 186, 255,0.1)');
          boiTrendsData.datasets[index].backgroundColor = gradientOne;
        } else {
          const gradientOne = ctx.getContext('2d').createLinearGradient(0, 0, 0, 450);
          gradientOne.addColorStop(0, 'rgba(161, 0, 255,0.7)');
          gradientOne.addColorStop(0.5, 'rgb(161, 0, 255,0.2)');
          gradientOne.addColorStop(1, 'rgb(161, 0, 255,0.1)');
          boiTrendsData.datasets[index].backgroundColor = gradientOne;
        }
        return each;
      });
    }
    return (
      <div
        className={showEditData
          ? 'linechart-division'
          : 'linechart-division nocommentary-section'}
      >
        <div className="custom-chartlegend">
          <ul>
            <li>
              <span className="metric-legendvalue">
                <img src={MetricValueSkyIcon} alt={i18n.t('metric_value_for_past_and_future')} />
              </span>
              {i18n.t('metric_value_for_current_year')}
            </li>
            <li>
              <span className="metric-legendvalue">
                <img src={MetricValueIconPurple} alt={i18n.t('metric_value_for_past_and_future')} />
              </span>
              {i18n.t('metric_value_for_past_and_future')}
            </li>
          </ul>
        </div>
        {!showEditData && showBoiEditButton && (
        <div className="showedit-tab">
          <span onClick={() => onShowEditDataClick('edit')} aria-hidden="true">
            <i className="far fa-edit mr-1"> </i>
            {i18n.t('Edit_Data')}
          </span>
        </div>
        )}
        <Line
          id="LineChart"
          data={boiTrendsData}
          redraw="true"
          options={{
            maintainAspectRatio: false,
            legendCallback() {
              const lengendHTML = `<div className="custom-chartlegend">
              </div>`;
              return lengendHTML;
            },
            responsive: true,
            layout: {
              padding: {
                top: -40,
                left: 0,
                right: 40,
                bottom: 40,
              },
            },
            tooltips: {
              enabled: true,
              mode: 'single',
              intersect: true,
              custom(tooltip) {
                if (!tooltip) return;
                // disable displaying the color box;
                // eslint-disable-next-line no-param-reassign
                tooltip.displayColors = false;
              },

              callbacks: {
                title() {
                  return '';
                },
                label(tooltipItem) {
                  const Xlabel = tooltipItem.xLabel;
                  const monthNames = [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ];
                  const fullMonthNames = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                  ];
                  monthNames.indexOf(Xlabel.split(' ')[0]);
                  const label = `${
                    fullMonthNames[
                      monthNames.indexOf(Xlabel.split(' ')[0])
                    ]
                  }  ${Xlabel.split(' ')[1]} : ${tooltipItem.value}`;
                  return label;
                },
              },
            },
            hover: {
              mode: 'index',
              intersect: true,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    display: true,
                    precision: 1,
                    fontColor: '#a3a3a3',
                    fontFamily: 'Graphik-Medium',
                    fontSize: 10,
                    fontWeight: 500,
                  },
                  gridLines: {
                    drawOnChartArea: true,
                  },

                  scaleLabel: {
                    display: true,
                    labelString: '% Values',
                    color: '#637381',
                    fontSize: 12,
                    fontFamily: 'Graphik-Medium',
                    fontWeight: 500,
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    display: true,
                    fontColor: '#a3a3a3',
                    fontFamily: 'Graphik-Medium',
                    fontSize: 10,
                    fontWeight: 500,
                  },
                  gridLines: {
                    drawOnChartArea: true,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: i18n.t('Month'),
                    color: '#637381',
                    fontSize: 12,
                    fontFamily: 'Graphik-Medium',
                    fontWeight: 500,
                  },
                },
              ],
            },
            plugins: {
              datalabels: {
                align: 'right',
                offset: 1,
                display() {
                  return false;
                },
                borderRadius: 50,
                color: 'white',
                font: {
                  weight: 500,
                  size: 11,
                  family: 'Graphik-Medium',
                },

                padding: {
                  left: 4,
                  right: 4,
                  top: 4,
                  bottom: 4,
                },
              },
            },
            legend: {
              display: false,
            },
            annotation: {
              duration: 0,
              drawTime: 'afterDraw',
              annotations: [],
            },
          }}
        />
      </div>
    );
  }
}
BoiTrends.propTypes = {
  onShowEditDataClick: PropTypes.func.isRequired,
  showEditData: PropTypes.bool.isRequired,
  showBoiEditButton: PropTypes.bool.isRequired,
  boiTrendsData: PropTypes.object.isRequired,
};
export default BoiTrends;
