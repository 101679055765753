import React, { Component } from 'react';
import { LocalApiBaseUrl } from '../Shared/Constant';
import DiverseKpiContiner from './DiverseKpiContainer';
import i18n from "i18next";
import References from "../Shared/References"
import "./reference.css";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

export default class DiverseDataContainer extends Component {
    constructor() { 
        super()
        this.state = { metricRowDetails: [],
          exploreLinkList: [],
            exploreDrop: "none",
           }
    }

    getDiverseData(offeringId) {
        let metricRowDetails = <DiverseKpiContiner filterDetails={offeringId} />
      

        this.setState({ metricRowDetails })
    }
    componentWillMount() {
        this.getDiverseData(this.props.filterDetails)
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.filterDetails !== nextProps.filterDetails) {
            this.getDiverseData(nextProps.filterDetails)
        }
    }
    viewMore(){
       
      let filterClient = this.props.filterDetails.Client;
      let filterProcess = this.props.filterDetails.process;
      let filterLocations = this.props.filterDetails.Locations;
 
      let SOCategoryID = 5


  let displayStatus = this.state.exploreDrop;
  if (displayStatus == "none") {
  
      axiosInstance
      .get(`${LocalApiBaseUrl}ClickableTools/GetExploreTools`, {
        params: {
          OrgEntityID:filterClient,
          OfferingID:filterProcess,
          LocationID:filterLocations,
          SOCategoryID: SOCategoryID,
          IsAllTool:false,
        }
      })
      .then(res => {
      
             
        let exploreLinkList = res.data.map(eachLinks => {
          return (
            <a className={ eachLinks.URL ?"dropdown-item":"dropdown-item explore_tools_noCursor"}  href={ eachLinks.URL? (eachLinks.URL !== "NA"&&eachLinks.URL !== "https://"?eachLinks.URL:eachLinks.URL.substr):"javascript:void(0)"} target={eachLinks.URL && "_blank"}>
              {eachLinks.ApplicationName}
            </a>
          );

        } ); 
   
        this.setState({ exploreLinkList,
          exploreDrop1: res.data.length==0?"No tools available":""
          });
      
        this.onClose();
      })
      .catch((error)=>{
        trycatchAlertPopup(error)});

  } 
  else {
      this.setState({ exploreDrop: "none" });
    }
  }
    componentDidMount(){

    }

    render() {
      const { exploreLinkList } = this.state;
        return <div className="row">
            <div className="col-lg-12">
                <div className="breadcrumb-title clearfix  references-dropdown dashboard-references-dropdown">
                <div className="dropdown">

                <a  className="dropdown-toggle"
                                    data-toggle="dropdown"
                                    onClick={() => { this.viewMore() }}>
                                    <span className="exploreTools">
                                 {i18n.t("Explore_Tools_Advisor")}
                                    <span>{i18n.t("Click on Explore Tools/Advisor to view Tools")}</span>
                                    </span>
                                <span className="caret"></span>
                                </a>
     
               <ul className="dropdown-menu explore_tools_root explore-tools-menu dropdown-menu-right custom-dropdown-menu">
               {exploreLinkList.length > 0 ?
                                    this.state.exploreLinkList:(<div><p>
                                    {this.state.exploreDrop1}
                                    </p></div>)}
          
                  </ul>

                     </div>
                     <div className="category-references dashboard-category-references">
                     {<References 
                    {...this.props} 
                    SOCatID={5}
                    leverName="Diverse Data"
                    referenceScreen="DD"
                    />
                    }  
                    </div> 
          </div>
                  </div>
        <div className="col-lg-12">
            {this.state.metricRowDetails}
        </div>
       
    </div>
   
    }
}

