import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { AgGridReact } from "ag-grid-react";
import ClientRegionApprovalPopup from "./ClientRegionApprovalPopup";
import axiosInstance from "../Shared/interceptor";
import _ from "lodash";
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class ClientRegionApprovalList extends Component {
  constructor() {
    super();

    this.state = {
      Approval: {
        ApproveType: null,
        ApproverRemarks: null,
        ClientRegionName: "",
        CreatedBy: "",
        CreatedTS: "",
        ID: "",
        IsActive: false,
        RequestorRemarks: "",
        Status: "",
        UpdatedBy: "",
        UpdatedTS: "",
      },
      CanApprove: false,
      CanReject: false,
      rowSelected: [],
      rowData: [],
      viewmore: false,
      columnDefs: [
        {
          headerName: "",
          resizable: true,
          suppressFilter: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 100,
        },
        {
          headerName: "Client Region",
          field: "ClientRegionName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },

        {
          headerName: "Status",
          field: "Status",
          resizable: true,
          suppressFilter: true,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
      ],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",
        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.onBtnExport = this.onBtnExport.bind(this);
  }

  onRowSelected(event) {
    let { rowSelected } = this.state;
    if (event.node.selected) {
      rowSelected = [...this.state.rowSelected];
      rowSelected.push(event.data);
      this.setState({ rowSelected: rowSelected });
    } else {
      rowSelected = [...this.state.rowSelected];
      rowSelected = rowSelected.filter((row) => row.ID !== event.data.ID);
      this.setState({ rowSelected: rowSelected });
    }
  }

  onCheckboxClicked(e, data) {
    let { rowSelected } = this.state;
    if (e.target.checked) {
      rowSelected.push(data);
    } else {
      this.setState({
        rowSelected: rowSelected.filter((row) => row.ID !== data.ID),
      });
    }
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  dealpopup(e) {
    const { rowSelected, Approval } = this.state;
    if (
      this.props.featureData.filter(
        (data) => String(data.Submenu) === String("Client Region") && data.Operations.includes("Approve")
      ).length > 0
    ) {
      if (rowSelected.length > 1) {
        let ID = rowSelected.map((row) => row.ID);
        let params = {
          ClientRegionId: ID,
          Action: "Approve",
          Comments: "Approved",
        };
        axiosInstance
          .post(`${LocalApiBaseUrl}ClientRegionApproval/PostApproveRejectClientRegion`, null, { params: params })
          .then((response) => {
            window.alert(response.data.StatusMessage);
            this.getApproval();
            this.setState({
              ...this.state,
              rowSelected: [],
              Approval: {
                ApproveType: null,
                ApproverRemarks: null,
                ClientRegionName: "",
                CreatedBy: "",
                CreatedTS: "",
                ID: "",
                IsActive: false,
                RequestorRemarks: "",
                Status: "",
                UpdatedBy: "",
                UpdatedTS: "",
              },
            });
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      } else {
        e.preventDefault();
        this.setState({
          ...this.state,
          viewmore: true,
          Approval: {
            ...Approval,
            ApproveType: "Approve",
          },
        });
      }
    } else window.alert("Access Denied !!");
  }

  decline(e) {
    if (
      this.props.featureData.filter(
        (data) => String(data.Submenu) === "Delivery Region" && data.Operations.includes("Reject")
      ).length > 0
    ) {
      if (this.state.rowSelected.length > 1) {
        window.alert("User is advised to select one row at a time");
      } else {
        e.preventDefault();
        this.setState({
          viewmore: true,
          Approval: {
            ...this.state.Approval,
            ApproveType: "Reject",
          },
        });
      }
    } else window.alert("Access Denied");
  }

  onClose() {
    this.setState({
      viewmore: false,
    });
  }

  getApproval() {
    axiosInstance
      .get(`${LocalApiBaseUrl}ClientRegionApproval/GetAllClientRegionForApproval`)
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    if (
      this.props.featureData.filter(
        (data) => String(data.Submenu) === "Client Region" && data.Operations.includes("Reject")
      ).length > 0
    ) {
      this.setState({ CanReject: true });
    }
    if (
      this.props.featureData.filter(
        (data) => String(data.Submenu) === "Client Region" && data.Operations.includes("Approve")
      ).length > 0
    ) {
      this.setState({ CanApprove: true });
    }

    this.getApproval();
    this.setState({
      rowSelected: [],
    });
  }

  componentWillMount() {
    this.setState({
      rowSelected: [],
    });

    this.getApproval();
  }

  handleSave(data) {
    if (!_.isNil(data)) {
      let params = {
        ClientRegionId: this.state.rowSelected[0].ID,
        Action: this.state.Approval.ApproveType,
        Comments: data.ApproverRemarks,
      };
      axiosInstance
        .post(`${LocalApiBaseUrl}ClientRegionApproval/PostApproveRejectClientRegion`, null, { params: params })
        .then((response) => {
          window.alert(response.data.StatusMessage);
          this.getApproval();
          this.setState({
            ...this.state,
            rowSelected: [],
            Approval: {
              ApproveType: null,
              ApproverRemarks: null,
              ClientRegionName: "",
              CreatedBy: "",
              CreatedTS: "",
              ID: "",
              IsActive: false,
              RequestorRemarks: "",
              Status: "",
              UpdatedBy: "",
              UpdatedTS: "",
            },
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  onActiveChange(event) {
    const user = this.state.user;
    user.IsActive = event.target.checked;
    this.setState({
      user: user,
    });
  }

  onBtnExport() {
    let params = {
      columnKeys: ["ClientRegionName", "Status"],
      columnGroups: true,
      allColumns: false,
      skipHeader: false,
      fileName: "Client Region",
    };
    this.state.gridOptions.api.exportDataAsCsv(params);
  }

  render() {
    const { gridOptions, columnDefs, rowData, viewmore, rowSelected, Approval, CanApprove, CanReject } = this.state;
    return (
      <div className="main-data">
        <div className="tableFilter">
          <div className="form-section">
            <div className="form-row">
              <div className="form-group col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  id="filter-text-box"
                  placeholder="Filter..."
                  onChange={(e) => {
                    this.onFilterTextBoxChanged(e);
                  }}
                />
              </div>
              <div className="ml-auto mr-1">
                <span className="btn btn-primary btn-sm py-1" onClick={this.onBtnExport}>
                  <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Download
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ag-theme-balham ag-table">
              <AgGridReact
                enableSorting={true}
                enableFilter={true}
                pagination={true}
                paginationPageSize={50}
                floatingFilter={true}
                onRowSelected={this.onRowSelected.bind(this)}
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                rowData={rowData}
                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
              ></AgGridReact>
            </div>
          </div>
        </div>
        {viewmore && (
          <ClientRegionApprovalPopup
            data={rowSelected}
            show={viewmore}
            type={Approval.ApproveType}
            onClose={this.onClose}
            onSave={this.handleSave}
          />
        )}
        <div className="text-center mt-3 mb-2">
          <button
            className="btn btn-primary btn-sm mx-2"
            disabled={rowSelected.length > 0 && CanApprove ? false : true}
            onClick={(e) => this.dealpopup(e)}
          >
            Approve
          </button>
          <button
            className="btn btn-primary btn-sm mx-2"
            disabled={rowSelected.length > 0 && CanReject ? false : true}
            onClick={(e) => this.decline(e)}
          >
            Decline
          </button>
        </div>
      </div>
    );
  }
}
