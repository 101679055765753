import React, { Component } from 'react';
import i18n from 'i18next';
import _ from 'lodash';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import OfferingSubOffering from './OfferingSubOffering';
import { LocalApiBaseUrl, trycatchAlertPopup } from './Constant';
import SynopsLoader from './SynopsLoader';
import { store } from '../reducers/configureStore';
import axiosInstance from './interceptor';
import { filterSelectedSubOffering } from './commonFunctions';

class ConfiguratorFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offeringData: [],
      subOfferingsList: [],
      filterDetails: {
        selectedOffering: '',
        selectedSubOffering: '',
        selectedOfferingName: '',
        selectedSubOfferingName: '',
      },
    };
    this.OfferingValues = [];
    this.LocationValues = [];
    this.monthValues = [];
    this.MaturityPhase = '';
  }

  componentDidMount() {
    this.getOfferings();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { MaturityPhase } = this.props;
    if (nextProps.MaturityPhase == MaturityPhase) this.MaturityPhase = '';
    else this.MaturityPhase = nextProps.MaturityPhase;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.languageData.id !== this.props.languageData.id) {
      this.getOfferings();
    }
  }

  componentWillUnmount() {
    const { applyMTCFilters } = this.props;
    applyMTCFilters({ offering: '', process: '' });
    this.clearReduxData();
  }

  onOfferingChange = (event) => {
    const { offeringData, filterDetails } = this.state;
    const { applyMTCFilters } = this.props;
    const selectedOffering = event.target.value;
    let subOfferingsList = [];
    if (selectedOffering !== '0') {
      this.clearReduxData();
      offeringData.forEach((eachValue) => {
        if (parseInt(eachValue.OfferingID, 10) === parseInt(event.target.value, 10)) {
          subOfferingsList = eachValue.ChildOfferings;
          filterDetails.selectedOfferingName = eachValue.OfferingName;
          filterDetails.selectedOffering = selectedOffering;
          filterDetails.selectedSubOfferingName = i18n.t('Select_Sub_Offerings');
          if (subOfferingsList.length) {
            const filtersSubofferingData = filterSelectedSubOffering(_.cloneDeep(subOfferingsList), 0);
            const { firstSuboffering } = filtersSubofferingData;
            filterDetails.selectedSubOfferingName = firstSuboffering[0].Process;
            filterDetails.selectedSubOffering = firstSuboffering[0].OfferingID;
            applyMTCFilters({ offering: event.target.value, process: firstSuboffering[0].OfferingID });
          }
        }
      });
    }
    this.setState({ subOfferingsList, filterDetails });
  };

  onSubofferingChange = (event) => {
    const { subOfferingsList, filterDetails } = this.state;
    const filtersSubofferingData = filterSelectedSubOffering(_.cloneDeep(subOfferingsList), parseInt(event.target.value, 10));
    const { subofferingData } = filtersSubofferingData;
    const { applyMTCFilters } = this.props;
    if (subofferingData) {
      this.clearReduxData();
      filterDetails.selectedSubOffering = event.target.value;
      filterDetails.selectedSubOfferingName = subofferingData[0].Process;
      applyMTCFilters({ offering: filterDetails.selectedOffering, process: event.target.value });
    }
    this.setState({ filterDetails });
  }

  getOfferings = () => {
    const { isMasterConfiguratorFilter, languageData } = this.props;
    isMasterConfiguratorFilter('disable client');
    let offeringData = [];

    axiosInstance
      .get(`${LocalApiBaseUrl}Offering/GetOfferingsList`, {
        params: { LanguageID: languageData.id, FeatureName: 'Master Template Configurator' },
      })
      .then((response) => {
        offeringData = response.data;
        this.OfferingValues = offeringData;

        store.dispatch({
          type: 'INITIALISE_BO_MASTER',
          payload: [],
        });
        store.dispatch({
          type: 'INITIALISE_MASTER',
          payload: [],
        });
        store.dispatch({
          type: 'INITIALISEINSIGHT_MASTER',
          payload: [],
        });
        store.dispatch({
          type: 'INITIALISEDIVERSE_MASTER',
          payload: [],
        });
        store.dispatch({
          type: 'INITIALISEWO_MASTER',
          payload: [],
        });
        store.dispatch({
          type: 'SAVE_MTC_OFFERING_DETAILS',
          payload: response.data,
        });
        this.setState({ offeringData });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  clearReduxData = () => {
    store.dispatch({
      type: 'INITIALISE_BO_MASTER',
      payload: [],
    });
    store.dispatch({
      type: 'INITIALISE_MASTER',
      payload: [],
    });
    store.dispatch({
      type: 'INITIALISEINSIGHT_MASTER',
      payload: [],
    });
    store.dispatch({
      type: 'INITIALISEDIVERSE_MASTER',
      payload: [],
    });

    store.dispatch({
      type: 'INITIALISEWO_MASTER',
      payload: [],
    });
  }

  subHeaderOpen = (event) => {
    const { subHeaderOpenCallback } = this.props;
    subHeaderOpenCallback(true);
    event.preventDefault();
  }

  subHeaderClose = (event) => {
    const { subHeaderCloseCallback } = this.props;
    subHeaderCloseCallback(false);
    event.preventDefault();
  }

  render() {
    const { filterDetails, offeringData, subOfferingsList } = this.state;
    const { subHeaderOpen, showLoaderOnPage, subHeaderClose } = this.props;
    return (
      <div id="subnav-header-id" className="subnav-header" onMouseEnter={subHeaderOpen} onMouseLeave={subHeaderClose}>
        <div className="container-fluid p-0">
          <LoadingOverlay
            fadeSpeed={0}
            spinner={<SynopsLoader />}
            active={showLoaderOnPage}
          />
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex align-items-center">
                <div className="offeringfilter-dropdowns d-flex align-items-center">
                  <span className="mr-4">Filter By</span>
                  <OfferingSubOffering
                    selectedOffering={filterDetails.selectedOfferingName}
                    offerings={offeringData}
                    offeringsData={offeringData}
                    selectedSubOffering={filterDetails.selectedSubOfferingName}
                    subOfferings={subOfferingsList}
                    selectedSubOfferingId={filterDetails.selectedSubOffering}
                    onOfferingChange={this.onOfferingChange}
                    onSubOfferingChange={this.onSubofferingChange}
                    subHeaderOpen={this.subHeaderOpen}
                    subHeaderClose={this.subHeaderClose}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
});
export default connect(mapStateToProps)(ConfiguratorFilters);
