import React, { Component } from 'react';
import PropTypes from 'prop-types';
import noCommentsIcon from '../Images/emptychat-icon.svg';

class DefaultScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { userName, NodeName } = this.props;
    return (
      <div className="no-comments-section">
        <img src={noCommentsIcon} alt="noCommentsIcon" />
        <h6 className="mt-2">
          Hi
          {' '}
          {userName}
          !
        </h6>
        <p className="mb-0">Add your comment for</p>
        <p className="comments-metric-name">{NodeName}</p>
      </div>
    );
  }
}

DefaultScreen.defaultProps = {
  userName: '',
  NodeName: '',
};

DefaultScreen.propTypes = {
  userName: PropTypes.string,
  NodeName: PropTypes.string,
};
export default DefaultScreen;
