import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import MetricComments from "../Dashboard/MetricComments";
import DiverseInsights from "./DiverseInsights";
import { LocalApiBaseUrl, getPrevMonth } from "../Shared/Constant";
import i18n from "i18next";
import emptycomponent from "../Images/Empty State.svg";
import DiversePopUp from "../Configurator/diverse_popup";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import SynopsLoader from "../Shared/SynopsLoader";
import LoadingOverlay from "react-loading-overlay";
import DDBatView from "./DDBatView";
import moment from "moment";

import funnelIcon from "../Images/funnel-icon.svg";
import { ValueUnit, ValueUnitNoRoundOff, MetricValueUnitFormat, CommonMetriTileFix } from "../Shared/Common";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import _, { truncate } from "lodash";

class DiverseKpiContiner extends Component {
  constructor() {
    super();
    this.state = {
      commentPopUp: false,
      filtername: [],
      metricDetails: [],
      showSaveBtn: false,
      ddInitialFlag:true,
      selectedDataType: store.getState().CurrentFilter.FetchFromBAT ? "BenchmarkAnalytics" : "frontDoorConfigData",
      viewMore2: false,
      editdiverse: false,
      diverseData: [],
      diverse1: [],
      kpiDetails: [],
      kpiMappingResponse: [],
      sourceOptions: [],
    };

    this.onClose = this.onClose.bind(this);
  }

  dealpopup(e) {
    e.preventDefault();

    this.setState({ viewMore2: true });
  }

  editDiverse = (kpiID, e) => {
    e.preventDefault();

    this.setState({ editdiverse: true, kpiID: kpiID });
  };

  deleteDiverse = (kpiID, configuratorDealmetricsID, e) => {
    if (window.confirm("Are you sure you want to delete metric detail?")) {
      e.preventDefault();
      axiosInstance
        .delete(`${LocalApiBaseUrl}Configurator/DeleteDealMetrics?DealMetricsId=${configuratorDealmetricsID}`)
        .then((response) => {
          if (response.data) {
            axiosInstance
              .get(`${LocalApiBaseUrl}Configurator/GetDiverseDataQualityScore`, {
                params: {
                  LocationID: this.props.filterDetails.Locations,
                  OrgEntityID: this.props.filterDetails.Client,
                  OfferingID: this.props.filterDetails.process,
                  MonthDT: this.props.filterDetails.Month,
                  LanguageID: store.getState().CurrentFilter.languageData.id,
                },
              })
              .then((response) => {
                if (response.data) {
                  store.dispatch({
                    type: "SAVE_QC_SCORE_OF_EACH_TAB",
                    payload: response.data,
                  });
                  this.loadDiverse();
                }
                this.loadDiverse();
              })
              .catch((error) => {
                trycatchAlertPopup(error);
              });
            store.dispatch({
              type: "REMOVE_DIVERSE",

              payload: { kpiID: kpiID },
            });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }

    e.preventDefault();
  };

  onClose() {
    this.setState({ viewMore2: false, editdiverse: false });
  }

  getKpiMetrics(
    offeringIdparam = {
      Client: 11,

      process: 2,

      Locations: 5,

      Month: getPrevMonth(),
    }
  ) {
    let offeringId = this.props.filterDetails != undefined ? Number(this.props.filterDetails) : 2;

    let filterProcess = offeringIdparam.process == undefined ? 3 : offeringIdparam.process;

    let filterLocations = offeringIdparam.Locations == undefined ? 5 : offeringIdparam.Locations;

    let filterClient = offeringIdparam.Client == undefined ? 11 : offeringIdparam.Client;

    let dateArray = [];

    if (offeringIdparam.Month) {
      dateArray = offeringIdparam.Month == 0 ? [] : offeringIdparam.Month.split("-");
    }

    let todayDate = "";

    if (dateArray.length == 2) {
      todayDate = "1-" + dateArray[0] + "-" + dateArray[1];
    } else {
      todayDate = offeringIdparam.Month;
    }
  }

  onCommentClick = (kpiDetails, val) => {
    if (val === 1) {
      this.setState({
        kpiDetails: kpiDetails,
        commentPopUp: true,
      });
    } else {
      this.setState({
        commentPopUp: false,
      });
    }
  };

  displaydiverseDetails() {
    let diverse = [...this.state.diverseData];
    //sorting the kpidata by metricName ascending before  rendering
    if (diverse[1] && diverse[1].kpiData.length > 0) {
      diverse[1].kpiData.sort((a, b) =>
        a.MetricName.replace(/[\u200B-\u200D\uFEFF]/g, "").toLowerCase() >
        b.MetricName.replace(/[\u200B-\u200D\uFEFF]/g, "").toLowerCase()
          ? 1
          : -1
      );
    }
    let leadMetrics =
      diverse[1] != undefined
        ? diverse[1].kpiData.map((kpiDetails) => {
            let statusColor = "item gray-color";
            let Description = kpiDetails.KPIDesp ? kpiDetails.KPIDesp : "";
            if (kpiDetails != undefined) {
              if (kpiDetails.RAGStatus != undefined && kpiDetails.RAGStatus != null) {
                switch (kpiDetails.RAGStatus.toLowerCase()) {
                  case "grey":
                    statusColor = "item gray-color";

                    break;

                  case "red":
                    statusColor = "item red-color";

                    break;

                  case "orange":
                    statusColor = "item amber-color";

                    break;

                  case "green":
                    statusColor = "item green-color";

                    break;

                  case "amber":
                    statusColor = "item amber-color";

                    break;

                  default:

                  // code block
                }
              }
            }
            const formattertwo = new Intl.NumberFormat("en", {
              useGrouping: true,
            });
            const options = {
              loop: true,
              margin: 16,
              responsiveClass: "",
              dots: false,
              responsive: {
                0: {
                  items: 1,
                  nav: true,
                },
                600: {
                  items: 2,
                  nav: false,
                },
                1000: {
                  items: 3,
                  nav: true,
                  loop: false,
                },
                1200: {
                  items: 3,
                  nav: true,
                  loop: false,
                },
                1400: {
                  items: 3,
                  nav: true,
                  loop: false,
                },
                1600: {
                  items: 4,
                  nav: true,
                  loop: false,
                },
              },
            };
            let MetricValue = "";
            let MetricValueUnitFormatData = "";
            if(kpiDetails.MetricUnit.includes('$') || kpiDetails.MetricUnit.includes('€') || kpiDetails.MetricUnit.includes('£') || kpiDetails.MetricUnit.includes('₹') || kpiDetails.MetricUnit.includes('SGD') || kpiDetails.MetricUnit.includes('R$')) {
              MetricValue = ValueUnitNoRoundOff(kpiDetails.MetricValue);
              } 
              else {
                MetricValue = ValueUnit(kpiDetails.MetricValue);
              }
            MetricValueUnitFormatData = MetricValueUnitFormat(kpiDetails.MetricUnit.trim(), MetricValue);

            return (
              <>
                <div className={`${statusColor}`}>
                  <div className="item-left">
                    <p className="tile-metric-value">{MetricValueUnitFormatData}</p>

                    <p className="tile-metric-desc" data-tip data-for={"customTooltip" + kpiDetails.KPIMappingID}>
                      {kpiDetails.MetricName}
                    </p>
                  </div>

                  <div className="item-right">
                    {Description ? (
                      <p
                        className="tile-metric-name"
                        data-tip
                        data-for={"customTooltip_desc" + kpiDetails.KPIMappingID}
                      >
                        {Description}
                      </p>
                    ) : (
                      <p className="tile-metric-name">&nbsp;</p>
                    )}
                    <div className="metric-lead-lagg">
                      {kpiDetails.metDataAttr.map((eachDetails, index) => {
                        let attributesForDD =
                          this.props.attributes.length > 0
                            ? this.props.attributes.filter((z) => z.ApplicableForDD)
                            : [];
                        let metricName =
                          attributesForDD.length > 0
                            ? index === 0
                              ? attributesForDD[0].AttributeName1
                              : attributesForDD[0].AttributeName2
                            : "";
                        let metricData = eachDetails.AttributeValue;
                        let unit = kpiDetails.MetricUnit.trim();
                        return (
                          <>
                            {index === 0 && (
                              <>
                                <p>
                                  <span className="lead-lagg-label">{metricName}</span>
                                  <span className="lead-lagg-val">
                                    {metricData != "" && !isNaN(metricData)
                                      ? `${
                                          Number.isInteger(Number(metricData))
                                            ? unit === "$"
                                              ? unit + formattertwo.format(metricData)
                                              : formattertwo.format(metricData)
                                            : unit === "$"
                                            ? unit + formattertwo.format(Number(metricData).toFixed(2))
                                            : formattertwo.format(Number(metricData).toFixed(2))
                                        }`
                                      : metricData}
                                    {metricData != "" && !isNaN(metricData) && unit.trim() !== "$" && (
                                      <>
                                        {unit.trim() !== "$" && (unit.trim() !== "%" ? <span></span> : <span></span>)}
                                      </>
                                    )}
                                  </span>
                                </p>
                              </>
                            )}

                            {index === 1 && (
                              <>
                                <p>
                                  <span className="lead-lagg-label">{metricName}</span>
                                  <span className="lead-lagg-val">
                                    {metricData != "" && !isNaN(metricData)
                                      ? `${
                                          Number.isInteger(Number(metricData))
                                            ? unit === "$"
                                              ? unit + formattertwo.format(metricData)
                                              : formattertwo.format(metricData)
                                            : unit === "$"
                                            ? unit + formattertwo.format(Number(metricData).toFixed(2))
                                            : formattertwo.format(Number(metricData).toFixed(2))
                                        }`
                                      : metricData}
                                    {metricData != "" && !isNaN(metricData) && unit.trim() !== "$" && (
                                      <>
                                        {unit.trim() !== "$" && (unit.trim() !== "%" ? <span></span> : <span></span>)}
                                      </>
                                    )}
                                  </span>
                                </p>
                              </>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="dropdown dropleft ondropOpenColor">
                    <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                    <div className="dropdown-menu">
                      <a
                        href="javascript:void(0)"
                        className="dropdown-item"
                        onClick={this.onCommentClick.bind(this, kpiDetails, 1)}
                      >
                        <span className="info-icon"></span>
                        {i18n.t("Info")}
                      </a>
                      {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                      (this.props.RecordStatus &&
                        this.props.RecordStatus[0] === "Publish" &&
                        !this.props.isManintencneWindowOpen) ||
                      this.props.roleReducers.filter((each) => {
                        if (each.FeatureName === "Configurator" && each.Operations.length) {
                          return each;
                        }
                      }).length === 0 ||
                      (this.props.RecordStatus &&
                        this.props.RecordStatus[0] === "Save" &&
                        this.props.roleReducers.filter((each) => {
                          if (
                            each.FeatureName === "Configurator" &&
                            each.Operations.filter((op) => op === "Submit").length
                          ) {
                            return each;
                          }
                        }).length === 0) ||
                      (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                      (this.props.RecordStatus &&
                        this.props.RecordStatus[1] === "True" &&
                        !this.props.isManintencneWindowOpen) ||
                      (this.props.RecordStatus &&
                        this.props.RecordStatus[0] === "Publish" &&
                        !this.props.isManintencneWindowOpen) ||
                      this.props.roleReducers.filter((each) => {
                        if (each.FeatureName === "Configurator" && each.Operations.length) {
                          return each;
                        }
                      }).length === 0 ||
                      (this.props.RecordStatus &&
                        this.props.RecordStatus[0] === "Save" &&
                        this.props.roleReducers.filter((each) => {
                          if (
                            each.FeatureName === "Configurator" &&
                            each.Operations.filter((op) => op === "Submit").length
                          ) {
                            return each;
                          }
                        }).length === 0) ||
                      (this.props.RecordStatus &&
                        this.props.RecordStatus[0] === "Submit" &&
                        this.props.roleReducers.filter((each) => {
                          if (
                            each.FeatureName === "Configurator" &&
                            each.Operations.filter((op) => op === "Publish").length
                          ) {
                            return each;
                          }
                        }).length === 0) ? (
                        ""
                      ) : (
                        <a
                          href="javascript:void(0)"
                          className="dropdown-item"
                          onClick={(e) =>
                            this.deleteDiverse(kpiDetails.KPIMappingID, kpiDetails.ConfigurationDealMetricID, e)
                          }
                        >
                          <span className="delete-icon"></span>
                          {i18n.t("Remove")}
                        </a>
                      )}
                      {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                      (this.props.RecordStatus &&
                        this.props.RecordStatus[0] === "Publish" &&
                        !this.props.isManintencneWindowOpen) ||
                      this.props.roleReducers.filter((each) => {
                        if (each.FeatureName === "Configurator" && each.Operations.length) {
                          return each;
                        }
                      }).length === 0 ||
                      (this.props.RecordStatus &&
                        this.props.RecordStatus[0] === "Save" &&
                        this.props.roleReducers.filter((each) => {
                          if (
                            each.FeatureName === "Configurator" &&
                            each.Operations.filter((op) => op === "Submit").length
                          ) {
                            return each;
                          }
                        }).length === 0) ||
                      (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                      (this.props.RecordStatus &&
                        this.props.RecordStatus[1] === "True" &&
                        !this.props.isManintencneWindowOpen) ||
                      (this.props.RecordStatus &&
                        this.props.RecordStatus[0] === "Publish" &&
                        !this.props.isManintencneWindowOpen) ||
                      this.props.roleReducers.filter((each) => {
                        if (each.FeatureName === "Configurator" && each.Operations.length) {
                          return each;
                        }
                      }).length === 0 ||
                      (this.props.RecordStatus &&
                        this.props.RecordStatus[0] === "Save" &&
                        this.props.roleReducers.filter((each) => {
                          if (
                            each.FeatureName === "Configurator" &&
                            each.Operations.filter((op) => op === "Submit").length
                          ) {
                            return each;
                          }
                        }).length === 0) ||
                      (this.props.RecordStatus &&
                        this.props.RecordStatus[0] === "Submit" &&
                        this.props.roleReducers.filter((each) => {
                          if (
                            each.FeatureName === "Configurator" &&
                            each.Operations.filter((op) => op === "Publish").length
                          ) {
                            return each;
                          }
                        }).length === 0) ? (
                        ""
                      ) : (
                        <a
                          href="javascript:void(0)"
                          className="dropdown-item"
                          onClick={(e) => this.editDiverse(kpiDetails.KPIMappingID, e)}
                        >
                          <span className="edit-icon"></span>
                          {i18n.t("Edit")}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })
        : null;

    if (!this.state.commentPopUp) {
      this.setState({ List: leadMetrics });
    } else {
      return leadMetrics;
    }
  }
  loadDiverse = () => {
    var data = this.props.storeData; 
    this.setState(
      {
        filtername: data.CurrentFilter.Filter.offeringName,
        diverse1: data.diverseReducers["diverse"][1] != undefined ? { ...data.diverseReducers["diverse"][1] } : null,
        diverseData: [...data.diverseReducers["diverse"]],
      },
      () => {
        this.displaydiverseDetails();
        this.getKpiMAppingDetails();
      }
    );
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isManintencneWindowOpen !== this.props.isManintencneWindowOpen) {
      this.displaydiverseDetails();
      CommonMetriTileFix();
    }
    if(this.props.storeData.diverseReducers.diverse !== prevProps.storeData.diverseReducers.diverse){

      this.loadDiverse();
    }

    if (this.props != prevProps) {
      this.getKpiMetrics(this.props.filterDetails);
      this.loadDiverse();
      // this.setState({
      //   selectedDataType: this.props.FetchFromBAT ? "BenchmarkAnalytics" : "frontDoorConfigData",
      // });
    }

    if (this.state.viewMore2 != prevState.viewMore2 || this.state.editdiverse != prevState.editdiverse) {
      this.getKpiMetrics(this.props.filterDetails);
      this.loadDiverse();
    }
  }
 
  
  componentDidMount() {
    this.getKpiMetrics(this.props.filterDetails);

    this.loadDiverse();
  }
  // RADIO BUTTON  HANDLE CHANGE FUNCTIONALITY FOR DD IS  START HERE //
 
  handleRadioChange = (e) => {
    const selectedOption = e.target.value;
    let selectedDataType = false;
    let userMsg, userResponse, showSaveBtn;
    if (selectedOption === "BenchmarkAnalytics") {
      selectedDataType = true;
      userMsg =
        "“By this action, Diverse data page for the deal would start reflecting metric data as configured in BAT. Kindly review the data as shown and click on Save if you would want to continue with BAT data.";
      userResponse = window.alert(userMsg);

      if (userResponse) {
        showSaveBtn = true;

      }
    }
    this.setState({
      selectedDataType: selectedOption,
      userMsg: userMsg,
      showSaveBtn: showSaveBtn,
    },
    () => {
      store.dispatch({
        type: "selectedTabDiverseDta",
        payload:selectedOption ,
      });
    }
    );

  };

  SubmitButton = (e) => {
    const {ConfiguratorID,ID} = this.props;

    // BAT-DiverseData API CALL START HERE //
    const { languageID } = this.props;
    const FetchFromBAT = this.state.selectedDataType === "frontDoorConfigData" ? false : true;

    let params = {
      ID: ID,
      ConfiguratorID: ConfiguratorID,
      Month: this.props.filterDetails.Month,
      FetchFromBAT: FetchFromBAT,
      IsActive: true,
    };
    axiosInstance
      .post(
        `${LocalApiBaseUrl}BAT/PostDiverseDataFetchSatus?OrgEntityID=${this.props.filterDetails.Client}&OfferingID=${this.props.filterDetails.process}&LocationID=${this.props.filterDetails.Locations}&MonthDT=${this.props.filterDetails.Month}&languageID=${languageID}`,
        params
      )
      .then((response) => {
        window.alert(response.data.StatusMessage);
        this.setState({ showLoaderOnPage: false });
        if (response.data.IsSuccess) {
          axiosInstance
            .get(`${LocalApiBaseUrl}Configurator/GetDiverseDataQualityScore`, {
              params: {
                LocationID: this.props.filterDetails.Locations,
                OrgEntityID: this.props.filterDetails.Client,
                OfferingID: this.props.filterDetails.process,
                MonthDT: this.props.filterDetails.Month,
                LanguageID: store.getState().CurrentFilter.languageData.id,
              },
            })
            .then((response) => {
              if (response.data) {
                store.dispatch({
                  type: "SAVE_QC_SCORE_OF_EACH_TAB",
                  payload: response.data,
                });
                this.loadDiverse();
              }
              this.loadDiverse();
            })
            .catch((error) => {
              trycatchAlertPopup(error);
            });
          this.setState({
            FetchFromBAT,
          });
          store.dispatch({
            type: "FETCHFROMBAT",
            payload: this.state.FetchFromBAT,
          });
        }
      })
      .catch((error) => {
        window.alert(error.response.data.StatusMessage);
        trycatchAlertPopup(error);
        this.setState({ showLoaderOnPage: false });
      });
  };

  componentWillReceiveProps(nextProps) {
    this.getKpiMetrics(nextProps.filterDetails);
    if(this.state.ddInitialFlag && store.getState().CurrentFilter.FetchFromBAT){
      this.setState({
        ddInitialFlag:false,
        selectedDataType: store.getState().CurrentFilter.FetchFromBAT  ? "BenchmarkAnalytics" : "frontDoorConfigData",
  
      });  
      }
    this.setState({
      filtername: store.getState().CurrentFilter.Filter.offeringName,
      

    });
  }

  getKpiMAppingDetails = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
        params: {
          OrgEntityID: this.props.filterDetails.Client,
          LocationID: this.props.filterDetails.Locations,
          OfferingID: this.props.filterDetails.process,
          SOCategoryID: this.state.diverseData[1] && this.state.diverseData[1].SOCategoryID,
          MonthDT: this.props.filterDetails.Month,
        },
      })
      .then((response) => {
        this.setState({
          kpiMappingResponse: response.data,
        });

        this.getGetConnectorMaster();
      });
  };
  getGetConnectorMaster = () => {
    let source = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
      .then((response) => {
        source = response.data;
        let sourceValues = source.map((x) => (
          <option key={x.ConnectorID} value={x.ConnectorID}>
            {x.ConnectorName}
          </option>
        ));
        this.setState({ sourceOptions: sourceValues });
      })
      .catch((error) => {
        window.alert(error.response.data.Message);
        trycatchAlertPopup(error);
      });
  };

  render() {
    const AllowBATDataFetchOption = store.getState().CurrentFilter.AllowBATDataFetchOption.AllowBATDataFetchOption;
    const IsToggleGrayedOut = store.getState().CurrentFilter.IsToggleGrayedOut;
    const FetchFromBAT = store.getState().CurrentFilter.FetchFromBAT;
    const ShowBannerMessage = store.getState().CurrentFilter.ShowBannerMessage;

    const MonthToShowOnBannerMessage = store.getState().CurrentFilter.MonthToShowOnBannerMessage;

    let MonthToShowOnBannerMessageTemp = MonthToShowOnBannerMessage;

    let monthDate = MonthToShowOnBannerMessage ? MonthToShowOnBannerMessageTemp.split("T") : "";

    let finalDate = monthDate[0];

    const monthAndYear = moment(finalDate).format("MMMM YYYY");

    const ReadonlyUser = this.props.roleReducers.filter((each) => {
      if (each.FeatureName === "Configurator" && each.Operations.length === 1 && each.Operations.includes("View")) {
        return each;
      }
    });

    const ReadonlyUserDetails = ReadonlyUser.length != 0 ? ReadonlyUser[0].Operations.includes("View") : "";

    let diverse = [...this.state.diverseData];

    const options = {
      loop: true,
      margin: 16,
      dots: false,
      autoplay: false,
      loop: false,
      nav: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        600: {
          items: 2,
          nav: false,
        },
        1000: {
          items: 3,
          nav: true,
          loop: false,
        },
        1200: {
          items: 4,
          nav: true,
          loop: false,
        },
        1400: {
          items: 4,
          nav: true,
          loop: false,
        },
        1600: {
          items: 5,
          nav: true,
          loop: false,
        },
      },
    };
    //BAT-DiverseData CONFIGURATION  FOR BAT CHANGES IN THE LANDING PAGE START HERE //
     return this.state.diverse1 != null ? (
      <>
        <div className="ddcontainer-tabs">
          <Tabs defaultActiveKey="performance">
            <Tab
              eventKey="performance"
              title={this.state.diverseData.length > 0 && this.state.diverseData[1].SOCategoryName}
            >

              {AllowBATDataFetchOption === true ? (
                <div className="fetch-data-container">
                  <div className="fetch-data-label">Fetch Data from:</div>
                  <div className="fetch-category-list">
                  <label className={IsToggleGrayedOut ? "form-check mr-3 disabled" : "form-check mr-3"}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="bat"
                        value="BenchmarkAnalytics"
                        onChange={this.handleRadioChange}
                        checked={this.state.selectedDataType === "BenchmarkAnalytics"}
                      />
                      <span className="checkmark"></span>
                      <span
                        className={
                          this.state.selectedDataType === "BenchmarkAnalytics"
                            ? "form-check-label active"
                            : "form-check-label"
                        }
                      >
                        Benchmark Analytics
                      </span>
                    </label>
                    <label className={IsToggleGrayedOut ? "form-check mr-3 disabled" : "form-check mr-3"}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="frontDoor"
                        value="frontDoorConfigData"
                        onChange={this.handleRadioChange}
                        checked={this.state.selectedDataType=== "frontDoorConfigData"}
                      />
                      <span className="checkmark"></span>
                      <span
                        className={
                          this.state.selectedDataType === "frontDoorConfigData"
                            ? "form-check-label active"
                            : "form-check-label"
                        }
                      >
                        Front Door Config Data
                      </span>
                    </label>
                  </div>
                  {!IsToggleGrayedOut ? (
                    <button
                      className="btn btn-primary btn-sm  button-get-data mr-4"
                      id="btnsubmit"
                      data-tip
                      data-for={"moreActionTooltip"}
                      onClick={this.SubmitButton}
                    >
                      {i18n.t("Save")}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="title-with-icon align-icon">
                {this.state.selectedDataType === "frontDoorConfigData" && (
                  <div className="list-icon">
                    {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                    (this.props.RecordStatus &&
                      this.props.RecordStatus[0] === "Publish" &&
                      !this.props.isManintencneWindowOpen) ||
                    this.props.roleReducers.filter((each) => {
                      if (each.FeatureName === "Configurator" && each.Operations.length) {
                        return each;
                      }
                    }).length === 0 ||
                    (this.props.RecordStatus &&
                      this.props.RecordStatus[1] === "True" &&
                      !this.props.isManintencneWindowOpen) ||
                    (this.props.RecordStatus &&
                      this.props.RecordStatus[0] === "Publish" &&
                      !this.props.isManintencneWindowOpen) ||
                    this.props.roleReducers.filter((each) => {
                      if (each.FeatureName === "Configurator" && each.Operations.length) {
                        return each;
                      }
                    }).length === 0 ||
                    (this.props.RecordStatus &&
                      this.props.RecordStatus[0] === "Save" &&
                      this.props.roleReducers.filter((each) => {
                        if (
                          each.FeatureName === "Configurator" &&
                          each.Operations.filter((op) => op === "Submit").length
                        ) {
                          return each;
                        }
                      }).length === 0) ||
                    (this.props.RecordStatus &&
                      this.props.RecordStatus[0] === "Submit" &&
                      this.props.roleReducers.filter((each) => {
                        if (
                          each.FeatureName === "Configurator" &&
                          each.Operations.filter((op) => op === "Publish").length
                        ) {
                          return each;
                        }
                      }).length === 0) ? (
                      ""
                    ) : (
                      <span onClick={(e) => this.dealpopup(e)}>
                        <FontAwesomeIcon icon={faPlus} />
                        Add metric
                      </span>
                    )}
                  </div>
                )}
              </div>

              {this.state.selectedDataType === "frontDoorConfigData" ? (
                <div className="dd-benchmarkslist">
                  {ShowBannerMessage && _.isEmpty(this.state.List) ? (
                    <div class="pulse-dashboard">
                      <div class="select-filter-msg">
                        <img src={funnelIcon} />
                        <p class="mb-0">
                          {" "}
                          You have chosen to fetch data from month – "{monthAndYear}". For this month, data
                          <br /> was flowing from Benchmark Analytics and was not saved in frontdoor.
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="custom-tilecard ddtile-wrapper">
                    <div className="tilecard-wrapper no-carousel">{this.state.List}</div>
                  </div>
                </div>
              ) : (
                <div className="custom-tilecard">
                  <div className="tilecard-wrapper">
                  <DDBatView attributes={this.props.attributes} selectedDataType={this.state.selectedDataType} />

                  </div>
                </div>
              )}
            </Tab>
            <Tab eventKey="insights" title="Other Data Insights">
              <DiverseInsights
                isManintencneWindowOpen={this.props.isManintencneWindowOpen}
                filterDetails={this.props.filterDetails}
                RecordStatus={this.props.RecordStatus}
              />
            </Tab>
          </Tabs>
          <ReactTooltip place="bottom" effect="solid" id={"moreActionTooltip"}>
            <span>Clicking on this button would save just the Diverse data config preference selection</span>
          </ReactTooltip>
        </div>

        {this.state.viewMore2 ? (
          <DiversePopUp
            filtername={this.state.filtername}
            show={this.state.viewMore2}
            onClose={this.onClose}
            type={this.state.type}
            filterDetails={this.props.filterDetails}
            SOCategoryID={this.state.diverseData[1].SOCategoryID}
            SOCategoryName={this.state.diverse1.SOCategoryName}
            attributes={this.props.attributes}
            kpiMappingResponse={this.state.kpiMappingResponse}
            sourceOptions={this.state.sourceOptions}
          />
        ) : null}

        {this.state.editdiverse ? (
          this.state.kpiMappingResponse.length == 0 ? (
            <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
          ) : (
            <DiversePopUp
              filtername={this.state.filtername}
              show={this.state.editdiverse}
              onClose={this.onClose}
              type={this.state.type}
              editDiverse={[...this.state.diverseData]}
              kpiID={this.state.kpiID}
              edit={this.state.editdiverse}
              filterDetails={this.props.filterDetails}
              SOCategoryID={this.state.diverseData[1].SOCategoryID}
              SOCategoryName={this.state.diverse1.SOCategoryName}
              attributes={this.props.attributes}
              kpiMappingResponse={this.state.kpiMappingResponse}
              sourceOptions={this.state.sourceOptions}
            />
          )
        ) : null}

        {this.state.commentPopUp && (
          <MetricComments
            isDD={true}
            info={this.state.kpiDetails}
            show={true}
            onClose={this.onCommentClick.bind(this, 2)}
          />
        )}
      </>
    ) : (
      <div className="emptymetric-msg">
        <img alt="" src={emptycomponent} width="80px" height="80px" />
        <h6>{i18n.t("No category available")}</h6>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    roleReducers: state.roleReducers.roleReducers,
    FetchFromBAT: state.CurrentFilter.FetchFromBAT,
    ShowBannerMessage: state.CurrentFilter.ShowBannerMessage,
    MonthToShowOnBannerMessage: state.CurrentFilter.MonthToShowOnBannerMessage,
    languageID:state.CurrentFilter.languageData.id,
    ConfiguratorID:state.CurrentFilter.ConfiguratorID,
    ID:state.CurrentFilter.ID,
    storeData:state,
    QcOverallScore: { ...state.CurrentFilter.QcOverallScore },
    tabButton: state.CurrentFilter.selectedDataType,
  };
};
export default connect(
  mapStateToProps,
  ""
)(DiverseKpiContiner);
