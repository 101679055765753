import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { userReducers } from './userReducers';
import { humanReducers } from './humanReducers';
import { humanMasterReducers } from './humanMasterReducers';
import { BOMasterReducers } from './BOMasterReducers';
import { WOtileMasterReducers } from './WOMasterReducers';
import { intelligentInsightMasterReducers } from './intelligentInsightMasterReducers';
import { diverseMasterReducers } from './diverseMasterReducers';
import { intelligentReducers } from './intelligentReducers';
import { WOtileReducers } from './WOReducers';
import { intelligentInsightReducers } from './intelligentInsightReducers';
import { BOReducers } from './BOReducers';
import { diverseReducers } from './diverseReducers';
import { roleReducers } from './roleFeatureReducers';
// eslint-disable-next-line import/no-cycle
import { CurrentFilter } from './CurrentFilters';
import BATReducer, { BATUsersReducer } from './BATReducer';
import { MaturityReducers } from './MaturityReducers';
import { JourneyReducer } from './JourneyReducer';
import { ClientAndFilterReducer } from './ClientAndFilterReducer';
import appReducer from './appReducer';
import { PulseMORCaseStudyReducers } from './PulseMORCaseStudyReducers';
import NotificationReducer from './NotificationReducer';
import { FilterReducer } from './FilterReducer';
import { mindMapReducer } from './mindMapReducer';
import BeforeAfterMindMapReducer from './BeforeAfterMindMap';
import userLoginDataReducer from './userLoginDataReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  app: appReducer,
  humanReducers,
  CurrentFilter,
  intelligentReducers,
  WOtileReducers,
  intelligentInsightReducers,
  BOReducers,
  diverseReducers,
  userReducers,
  roleReducers,
  BOMasterReducers,
  humanMasterReducers,
  intelligentInsightMasterReducers,
  diverseMasterReducers,
  WOtileMasterReducers,
  BATScreensStates: BATReducer,
  BATUsersReducer,
  MaturityReducers,
  JourneyReducer,
  ClientAndFilterReducer,
  PulseMORCaseStudyReducers,
  NotificationReducer,
  FilterReducer,
  mindMapReducer,
  BeforeAfterMindMapReducer,
  userLoginDataReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['BATScreensStates'], // THE STATES MENTIONED HERE WOULD ONLY BE PERSISTED
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware()));
export const persistor = persistStore(store);
