import React, { Component } from 'react';
import { store } from "../reducers/configureStore"
import ConnectorContainer from './ConnectorContainer';
import { connect } from "react-redux";
import { dataAutomationSubMenuOrder } from '../Shared/Constant';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import axiosInstance from '../Shared/interceptor';
import i18n from "i18next";
import { TRACKING_MODULES, PARAM_NAMES, userTracker, createParamObject } from '../Shared/Common';
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;




class ConnectorList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Submenu: [],
            roleData: [],
            featureData: [],
            isConnnectorClicked: false,
            isOneTimeSetupClicked: false,
            isDealFilterClicked: false,
            isDealConnectorClicked: false,
            isConnectorFieldMasterDataClicked: false,
            isToolconfiguratorClicked: false,
            pageHeader: "",
        }
        this.onConnectorClick = this.onConnectorClick.bind(this);
        this.onOneTimeSetupClick = this.onOneTimeSetupClick.bind(this);
        this.getRoleData = this.getRoleData.bind(this);
        this.setDefaultTabOnLoad = this.setDefaultTabOnLoad.bind(this);
        this.onDealFilterClick = this.onDealFilterClick.bind(this);
        this.onDealConnectorClick = this.onDealConnectorClick.bind(this);
        this.onConnectorFieldMasterDataClick = this.onConnectorFieldMasterDataClick.bind(this);
        this.onToolconfiguratorClicked = this.onToolconfiguratorClicked.bind(this);

    }






    getRoleData() {
        let data = store.getState();
        let roleData1 = data.roleReducers["roleReducers"];
        this.setState({ featureData: roleData1 })

    }


    setDefaultTabOnLoad() {
        this.setState({
            isConnnectorClicked: false, isOneTimeSetupClicked: false, isDealFilterClicked: false, isDealConnectorClicked: false,
            isConnectorFieldMasterDataClicked: false, isToolconfiguratorClicked: false
        });

        if (this.props.featureData.filter((data) => String(data.Submenu) === "Configure Source Parameter" && data.Operations.length != 0).length > 0) {

            this.setState({

                isConnnectorClicked: true
            });

        }
        else if (this.props.featureData.filter((data) => String(data.Submenu) === "Deal Filter" && data.Operations.length != 0).length > 0) {

            this.setState({

                isDealFilterClicked: true
            });

        }
    }

    setTabOnLoad(tabName) {
        let tabData = [];
        let pageName = "";
        switch (tabName) {
            case "Build_Query":
                tabData = this.props.featureData.filter((data) => data.Submenu == "Build Query" && data.Operations.length != 0)
                if (tabData.length > 0)
                    this.onDealConnectorClick();
                pageName = TRACKING_MODULES.DATA_AUTOMATION_BUILD_QUERY;
                break;
            case "Configure_Source_Parameter":
                tabData = this.props.featureData.filter((data) => String(data.Submenu) === "Configure Source Parameter" && data.Operations.length != 0)
                if (tabData.length > 0)
                    this.onConnectorClick();
                pageName = TRACKING_MODULES.DATA_AUTOMATION_METRIC_AUTOMATION;
                break;
            case "Connector_Field_Master_Data":
                tabData = this.props.featureData.filter((data) => String(data.Submenu) === "Connector Field Master Data" && data.Operations.length != 0)
                if (tabData.length > 0)
                    this.onConnectorFieldMasterDataClick();
                break;
            case "One-Time_Setup":
                tabData = this.props.featureData.filter((data) => String(data.Submenu) === "One-Time Setup" && data.Operations.length != 0)
                if (tabData.length > 0)
                    this.onOneTimeSetupClick();
                pageName = TRACKING_MODULES.DATA_AUTOMATION_ONE_TIME_SETUP;
                break;
            case "Tool_Configurator":
                tabData = this.props.featureData.filter((data) => String(data.Submenu) === "Tool Configurator" && data.Operations.length != 0)
                if (tabData.length > 0)
                    this.onToolconfiguratorClicked();
                pageName = TRACKING_MODULES.DATA_AUTOMATION_HM_TOOL_CONFIGURATOR
                break;
        }

        let pageHeader = "";
        const pageHeaderStatic = (this.props.location.state ? this.props.location.state.detail : (tabData && tabData.length > 0 ? tabData[0].Submenu : ""));
        const titleData = dataAutomationSubMenuOrder.filter(f => { return f.name == pageHeaderStatic });
        pageHeader = titleData.length > 0 && titleData[0].displayName != "" ? titleData[0].displayName : pageHeaderStatic

        this.setState({
            pageHeader
        })

          userTracker(
            pageName,
            false,
            null,
            null,
            createParamObject(
              PARAM_NAMES.OFFERING,
              store.getState().CurrentFilter.SyncFilter.OfferingName
            ),
            createParamObject(
              PARAM_NAMES.SUB_OFFERING,
              store.getState().CurrentFilter.Common.SubOfferingName
            ),
            createParamObject(
              PARAM_NAMES.LOCATION,
              store.getState().CurrentFilter.Location.locationName,
            ),
          );
    }

    loadTabBasedOnURL = () => {
        const reqPath = this.props.location.pathname;
        const reqPathArr = reqPath.split("/");
        if (reqPath.startsWith("/admin/Connector") && reqPathArr.length == 4) {
            this.setTabOnLoad(reqPathArr[3])
        }
    }


    componentDidMount() {
        this.loadTabBasedOnURL();
    }


    componentDidUpdate(prevProps) {
        if (this.props.location.pathname != prevProps.location.pathname)
            this.loadTabBasedOnURL();
    }


    onConnectorClick(e) {
        this.setState({
            isConnnectorClicked: true,
            isOneTimeSetupClicked: false,
            isDealFilterClicked: false,
            isDealConnectorClicked: false,
            isConnectorFieldMasterDataClicked: false,
            isToolconfiguratorClicked: false
        });
    }

    onOneTimeSetupClick(e) {
        this.setState({
            isConnnectorClicked: false,
            isOneTimeSetupClicked: true,
            isDealFilterClicked: false,
            isDealConnectorClicked: false,
            isConnectorFieldMasterDataClicked: false,
            isToolconfiguratorClicked: false
        });
    }

    onDealFilterClick(e) {
        this.setState({
            isConnnectorClicked: false,
            isOneTimeSetupClicked: false,
            isDealFilterClicked: true,
            isDealConnectorClicked: false,
            isConnectorFieldMasterDataClicked: false,
            isToolconfiguratorClicked: false
        });
    }

    onDealConnectorClick(e) {
        this.setState({
            isConnnectorClicked: false,
            isOneTimeSetupClicked: false,
            isDealFilterClicked: false,
            isDealConnectorClicked: true,
            isConnectorFieldMasterDataClicked: false,
            isToolconfiguratorClicked: false

        });
    }

    onConnectorFieldMasterDataClick(e) {
        this.setState({
            isConnnectorClicked: false,
            isOneTimeSetupClicked: false,
            isDealFilterClicked: false,
            isDealConnectorClicked: false,
            isConnectorFieldMasterDataClicked: true,
            isToolconfiguratorClicked: false
        });
    }

    onToolconfiguratorClicked(e) {
        this.setState({
            isConnnectorClicked: false,
            isOneTimeSetupClicked: false,
            isDealFilterClicked: false,
            isDealConnectorClicked: false,
            isConnectorFieldMasterDataClicked: false,
            isToolconfiguratorClicked: true
        });
    }


    render() {
        return (
            <div className="main-component">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="grid-heading">
                                {i18n.t("Data Automation") + " > " + i18n.t(this.state.pageHeader)}
                            </h3>
                            <div className="tab-list">
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ConnectorContainer filterDetails={this.props.filterDetails} ConnectorClicked={this.state.isConnnectorClicked} OneTimeSetupClicked={this.state.isOneTimeSetupClicked} DealFilterClicked={this.state.isDealFilterClicked} DealConnectorClicked={this.state.isDealConnectorClicked} ConnectorFieldMasterDataClicked={this.state.isConnectorFieldMasterDataClicked} ToolconfiguratorClicked={this.state.isToolconfiguratorClicked} selectedClientId={this.props.selectedClientId} />
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
const mapStateToProps = state => {
    return {
        featureData: state.roleReducers.roleReducers
    };
};
export default connect(mapStateToProps)(ConnectorList);