import React, { Component } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import AddComments from './AddComments';
import OtherUserComments from './OtherUserComments';
import LoggedUserComments from './LoggedUserComments';
import DefaultScreen from './DefaultScreen';
import Preloader from './Preloader';
import './comments.css';

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      scrollElementIds: [],
      SearchDataNotFound: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { searchText, SearchDataNotFound } = this.state;
    const { comments } = this.props;
    if ((searchText && document.getElementsByClassName('search-style').length === 0 && !SearchDataNotFound)) {
      this.setState({ SearchDataNotFound: true });
    } else if (!searchText && SearchDataNotFound) {
      this.setState({ SearchDataNotFound: false });
    }
    if (prevProps.comments !== comments) {
      this.setState({ SearchDataNotFound: false, searchText: '', scrollElementIds: [] });
    }
  }

 onSearch = (e) => {
   this.setState({ scrollElementIds: [], SearchDataNotFound: false, searchText: e.target.value });
 }

 setScrollElementId = (id) => {
   const { scrollElementIds } = this.state;
   scrollElementIds.push(id);
   this.setState({ scrollElementIds }, () => {
     const currentScrollableElement = document.getElementById(`custom-comments-${scrollElementIds[0]}`);
     if (currentScrollableElement) {
       const topPos = currentScrollableElement.getBoundingClientRect().top;
       document.getElementById('custom-comments-container').scrollTo({ top: (document.getElementById('custom-comments-container').getBoundingClientRect().top + topPos), behavior: 'smooth' });
     //  document.getElementById('custom-comments-header').scrollIntoView(true);
     }
   });
 }

 render() {
   const {
     suggestions, comments, userName, deleteComment, onAddOrUpdateComment, NodeName, isApiLoading, CommentsImagesData, charLimit,
   } = this.props;
   let updatedCommentsData = [];
   updatedCommentsData = comments.map((each) => {
     const commentParentUser = suggestions.filter((user) => user.Name === each.UserName);
     const clonedEachValue = each;
     if (commentParentUser.length) {
       clonedEachValue.ShortName = commentParentUser[0].ShortName;
       clonedEachValue.Image = CommentsImagesData[each.UserName] ? CommentsImagesData[each.UserName] : '';
     }
     if (each.Replies && each.Replies.length) {
       clonedEachValue.Replies = each.Replies.map((eachRep) => {
         const commentChildUser = suggestions.filter((user) => user.Name === eachRep.UserName);
         const clonedeachRepValue = eachRep;
         if (commentChildUser.length) {
           clonedeachRepValue.ShortName = commentChildUser[0].ShortName;
           clonedeachRepValue.Image = CommentsImagesData[eachRep.UserName] ? CommentsImagesData[eachRep.UserName] : '';
         }
         return clonedeachRepValue;
       });
     }
     return clonedEachValue;
   });
   const { searchText, SearchDataNotFound } = this.state;
   const filteredSuggestions = suggestions;
   return (

     <div className="comments-wrapper">
       {isApiLoading && <Preloader />}
       <div id="custom-comments-header" className="comments-header">
         <h5>{i18n.t('Comments')}</h5>
         <div className="comments-filter">
           <input type="search" placeholder="Search for key words…" value={searchText} onChange={this.onSearch} />
         </div>
       </div>
       { SearchDataNotFound ? <p>{i18n.t('No results found ...')}</p> : (
         <div id="custom-comments-container" className="comments-container">
           {updatedCommentsData && updatedCommentsData.length ? updatedCommentsData.map((each) => {
             if (each.UserName !== userName) { return <OtherUserComments charLimit={charLimit} setScrollElementId={this.setScrollElementId} onAddOrUpdateComment={onAddOrUpdateComment} commentData={each} userName={userName} suggestions={filteredSuggestions} searchId="" deleteComment={deleteComment} searchText={searchText} scrollAuto="" />; }
             return <LoggedUserComments charLimit={charLimit} onAddOrUpdateComment={onAddOrUpdateComment} setScrollElementId={this.setScrollElementId} commentData={each} userName={userName} suggestions={filteredSuggestions} searchId="" searchText={searchText} scrollAuto="" deleteComment={deleteComment} />;
           }) : <DefaultScreen userName={userName} NodeName={NodeName} /> }
         </div>
       )}

       <AddComments
         key={comments.length}
         suggestions={filteredSuggestions}
         searchText={searchText}
         charLimit={charLimit}
         scrollBottom={this.scrollBottom}
         onAddOrUpdateComment={onAddOrUpdateComment}
         userName={userName}
       />
     </div>
   );
 }
}
Comments.defaultProps = {
  onAddOrUpdateComment: () => {},
  userName: '',
  NodeName: '',
  CommentsImagesData: {},
};
Comments.propTypes = {
  onAddOrUpdateComment: PropTypes.func,
  userName: PropTypes.string,
  NodeName: PropTypes.string,
  suggestions: PropTypes.object.isRequired,
  charLimit: PropTypes.number.isRequired,
  comments: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired,
  isApiLoading: PropTypes.bool.isRequired,
  CommentsImagesData: PropTypes.object,
};
export default Comments;
