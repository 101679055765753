import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Tabs, Tab, TabList, TabPanel,
} from 'react-web-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt, faTrashAlt, faLayerGroup, faFlag,
} from '@fortawesome/free-solid-svg-icons';
import { Overlay, Popover, Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import i18n from 'i18next';
import { IOJData, LocalApiBaseUrl, trycatchAlertPopup } from './Constant';
import Camerawhite from '../Images/LeftPanelIcons/camera-white.svg';
import DatabaseWhite from '../Images/LeftPanelIcons/database-white.svg';

import SynopsLoader from './SynopsLoader';
import { store } from '../reducers/configureStore';
import axiosInstance from './interceptor';
import SubOfferingDropdowns from './SubOfferingDropdowns';
import { filterSelectedSubOffering } from './commonFunctions';

const emptySSErrors = {
  Name: '',
  Client: '',
  Offering: '',
  SubOffering: '',
  Location: '',
  LandingPag: '',
};
function SnapshotProfile({
  compDetails,
  filterDetails,
  MaturityPhase,
  filterObj,
  OperatingGropus,
  languageData,
  profileListParent,
  offeringData,
  setDefaultSelectedProfileName,
  defaultSelectedProfileName,
  Client,
  SelectedFilter,
  snapshotProfilesRedux,
  getAllProfileAgainstUser,
  history,
  roleData,
}) {
  const SnapshotDropdownPopoverTarget = useRef(null);
  const [isSnapshotDropdownPopoverStatus, setIsSnapshotDropdownPopoverStatus] = useState(false);
  const [profileList, setProfileList] = useState(profileListParent);
  const [snapshotList, setSnapshotList] = useState([]);
  const ssDelSuccMsg = i18n.t('Snapshot_Del_Success_Msg');
  const ssErrMsg = i18n.t('Snapshot_Error_Msg');
  const ssIDReqMsg = i18n.t('Snapshot_Req_Msg');
  const ssSelectClient = i18n.t('Select_Client');
  const [showSnapshotPopup, setShowSnapshotPopup] = useState(false);
  const [ssOffering, setSSOffering] = useState([]);
  const [ssProcess, setSSProcess] = useState([]);
  const [ssLocation, setSSLocation] = useState([]);
  const [ssLandingPage, setSSLandingPage] = useState([]);
  const [ssClientID, setSSClientID] = useState('');
  const [ssOfferID, setSSOfferID] = useState('');
  const [ssSubOfferID, setssSubOfferID] = useState('');
  const [ssSubOffer, setSSSubOffer] = useState('');
  const [ssLocationID, setSSLocationID] = useState('');
  const [ssLandingPageID, setSSLandingPageID] = useState('');
  const [ssIsDefault, setSSIsDefault] = useState(false);
  const [ssName, setSSName] = useState('');
  const [selectedSnapshotID, setSelectedSnapshotID] = useState(0);
  const [ssErrors, setSSErrors] = useState(emptySSErrors);
  const [disableCreate, setDisableCreate] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);

  // STATE

  useEffect(() => {
    //setProfileList(profileListParent);
    filterProfileList(profileListParent)
  }, [profileListParent]);

  useEffect(()=>{
    //setProfileList(snapshotProfilesRedux)
    filterProfileList(snapshotProfilesRedux)

  },[snapshotProfilesRedux])

  useEffect(()=>{
    if(profileList.length >0)
    {
      filterProfileList(profileList)
    }
  },[offeringData])

  useEffect(()=>{
    if(!Client)
    {
      filterProfileList([])
    }
  },[Client])

  // USE EFFECT

  // FUNCTION START

  const filterProfileList = ((TprofileList) => {
    if (offeringData.length > 0) {
      const tempProfileList = TprofileList.filter((a) => offeringData.some((b) => {
        const OfferingName = b.OfferingName_En === a.Offering_En;
        const subofferings = filterSelectedSubOffering(_.cloneDeep(b.ChildOfferings), a.OfferingID);
        const subofferingData = subofferings.subofferingData.length > 0;
        if (OfferingName && subofferingData) return true;
        return false;
      }));
      setProfileList(tempProfileList);
    } else {
      setProfileList(profileList);
    }
  });

  const isSnapshotDropdownPopoverClose = () => {
    setIsSnapshotDropdownPopoverStatus(false);
  };
  const isSnapshotDropdownPopoverShow = () => {
    setIsSnapshotDropdownPopoverStatus(true);
  };

  const changeSnapshotSelection = (id) => {
    if (profileList && profileList.length > 0) {
      const defaultSS = profileList.filter((f) => f.SnapshotID === id);
      if (defaultSS.length > 0) {
        if (
          offeringData.filter((o) => o.OfferingName_En === defaultSS[0].Offering_En).length > 0
        ) {
          const filterData = {
            OfferingId: offeringData.filter((o) => o.OfferingName_En === defaultSS[0].Offering_En)[0].OfferingID,
            ProcessId: defaultSS[0].OfferingID,
            LocationId: defaultSS[0].LocationID,
            Month: '',
            LocationOMID: defaultSS[0].PageName === 'Journey Initiatives' ? ['All'] : [],
            IsDataPublishForLocation: false,
            isOMIDAvailable: false,
            OfferingName: defaultSS[0].Offering_En,
          };

          const screen = defaultSS[0].PageName.trim();
          if (
            !(
              screen === compDetails
              && String(SelectedFilter.ProcessId) === String(defaultSS[0].OfferingID)
              && String(SelectedFilter.LocationId) === String(defaultSS[0].LocationID)
              && String(SelectedFilter.OfferingName) === String(defaultSS[0].Offering_En)
            )
          ) {
            setDefaultSelectedProfileName(defaultSS[0].SnapShotName, true, filterData, screen);
            isSnapshotDropdownPopoverClose();
          }
        }
      }
    }
  };

  const deleteSnapshot = async (ssID) => {
    const client = Client;
    if (client !== undefined && client > 0 && client !== '') {
      if (parseInt(ssID) > 0) {
        const ssDeleteConfrim = i18n.t('Snapshot_Delete_Confirm');
        if (window.confirm(ssDeleteConfrim)) {
          setIsApiLoading(true);
          axiosInstance
            .delete(`${LocalApiBaseUrl}Snapshot/DeleteSnapshotByID`, {
              params: { SnapshotID: ssID },
            })
            .then((response) => {
              setIsApiLoading(false);
              getAllProfileAgainstUser(ssID);
              if (response.data.StatusCode) {
                window.alert(ssDelSuccMsg);
              } else {
                window.alert(ssErrMsg);
              }
            })
            .catch((error) => {
              setIsApiLoading(false);
              trycatchAlertPopup(error);
            });
        }
      } else {
        window.alert(ssIDReqMsg);
      }
    } else {
      window.alert(ssSelectClient);
    }
  };

  const addSnapshotPopupRender = () => {
    setSSClientID(Client);
    setSSOfferID(0);
    setssSubOfferID(0);
    setSSSubOffer('');
    setSSLocationID(0);
    setSSLandingPageID(0);
    setSSIsDefault(false);
    setSSName('');
    setShowSnapshotPopup(true);
    setSSOffering([]);
    setSSProcess([]);
    setSSLocation([]);
    setSSErrors(emptySSErrors);
    getOfferingByClient(Client);
    getLandingPageDropdownData();
  };

  const getOfferingByClient = (orgID) => {
    if (orgID != undefined && orgID > 0 && orgID != '') {
      const screen = history.location.pathname === '/admin/Configurator' ? 'Configurator' : 'Dashboard';
      axiosInstance
        .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
          params: { OrgEntityID: orgID, LanguageID: languageData.id, featureName: screen },
        })
        .then((response) => {
          if (history.location.pathname === '/admin/Configurator') {
            setSSOffering(response.data);
          } else {
            setSSOffering(response.data.filter((each) => each.IsNew !== true));
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  const getLocationByClient_Offering = (Client, offering) => {
    if (Client && offering) {
      axiosInstance
        .get(`${LocalApiBaseUrl}SOFilter/GetLocation`, {
          params: { OrgEntityID: Client, OfferingID: offering, LanguageID: languageData.id },
        })
        .then((response) => {
          if (history.location.pathname === '/admin/Configurator') {
            setSSLocation(response.data);
          } else {
            setSSLocation(response.data.filter((each) => each.IsNew !== true));
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };
  const checkIfPulsePageAccessAllowed = () => {
    const foundPermissionObj = _.find(roleData, { FeatureName: 'PulsePage' });

    let hasAccessPermission = false;
    if (!_.isEmpty(foundPermissionObj)) {
      const allowedOperations = foundPermissionObj.Operations;

      // ACCESS WOULD ONLY BE ALLOWED IF THE USER HAS EITHER "VIEW" OR "EDIT" PERMISSION
      if (allowedOperations.indexOf('View') || allowedOperations.indexOf('Edit')) {
        hasAccessPermission = true;
      }
    }

    return hasAccessPermission;
  };
  const getLandingPageDropdownData = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}Snapshot/GetLandingPage`)
      .then((response) => {
        const data = [...response.data];
        const pulseAccess = checkIfPulsePageAccessAllowed();
        const newData = [];

        data.forEach((a) => {
          if (a.PageName === 'Pulse' && pulseAccess) {
            newData.push(a);
          }
          if (a.PageName !== 'Pulse') {
            newData.push(a);
          }
        });
        setSSLandingPage(newData);
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };
  const onsnapshotSubOfferingChange = (e) => {
    setSSLocation([]);

    let selectedSuboffering = [];
    if (e.target.value != 0) {
      const filtersSubofferingData = filterSelectedSubOffering([...ssProcess], e.target.value);
      selectedSuboffering = filtersSubofferingData.subofferingData[0];
    }

    const data = {
      ssSubOfferID: e.target.value,
      ssSubOffer: selectedSuboffering.Process ? selectedSuboffering.Process : '',
      ssLocationID: 0,
    };
    setssSubOfferID(data.ssSubOfferID);
    setSSSubOffer(data.ssSubOffer);
    if (ssClientID != 0 && e.target.value != 0) {
      getLocationByClient_Offering(ssClientID, e.target.value);
    }
  };
  const bindAddSSPopupData = (e) => {
    const { id } = e.target;
    let data = {};
    switch (id) {
      // Obsolete - Previous individual [Save] function
      case 'txtSSSaveName':
        data = {
          ssName: e.target.value,
        };
        setSSName(data.ssName);
        break;
      // Obsolete - Previous individual [Save] function
      // Save Pop-up
      case 'txtSSName':
        data = {
          ssName: e.target.value,
        };
        setSSName(data.ssName);
        break;
      case 'ddSSClient':
        setSSOffering([]);
        setSSProcess([]);
        setSSLocation([]);
        setSSSubOffer('');
        setSSOfferID(0);
        setssSubOfferID(0);
        setSSLocationID(0);
        setSSLandingPageID(0);
        setSSIsDefault(false);

        data = {
          ssClientID: e.target.value,
          ssOfferID: 0,
          ssSubOfferID: 0,
          ssLocationID: 0,
        };
        setSSClientID(data.ssClientID);
        getOfferingByClient(e.target.value);
        break;
      case 'ddSSOffer':
        setSSProcess([]);
        setSSLocation([]);

        let processData = [];
        if (e.target.value != 0) {
          processData = ssOffering
            && ssOffering.filter((eachValue) => eachValue.OfferingID == e.target.value)[0]
              .ChildOfferings;
        }

        data = {
          ssOfferID: e.target.value,
          ssProcess: processData,
          ssSubOfferID: 0,
          ssLocationID: 0,
          ssSubOffer: '',
        };
        setSSOfferID(data.ssOfferID);
        setSSProcess(data.ssProcess);
        setSSSubOffer(data.ssSubOffer);
        setssSubOfferID(data.ssSubOfferID);

        break;
      case 'ddSSLocation':
        data = {
          ssLocationID: e.target.value,
        };
        setSSLocationID(data.ssLocationID);

        break;
      case 'ddSSLandingPage':
        data = {
          ssLandingPageID: e.target.value,
        };
        setSSLandingPageID(data.ssLandingPageID);
        break;
      case 'chkSSIsDefault':
        data = {
          ssIsDefault: e.target.value,
        };
        setSSIsDefault(data.ssIsDefault);
        break;
      default:
        break;
    }
  };

  const checkStringForOnlySpecialCharacter = (str) => {
    let isValid = false;

    const onlySpecialCharRegex = /[~!@#$%^&*()-_+=,;.?"':/\{}|<>]/;
    const hasAlphanumericRegex = /[A-Za-z0-9]/;

    if (onlySpecialCharRegex.test(str)) {
      if (str.match(hasAlphanumericRegex) == null) isValid = true;
    }

    return isValid;
  };

  const saveSnapshot = () => {
    const errorJSON = [];
    if (ssName.trim() == '') {
      errorJSON[0] = i18n.t('Snapshot_Add_ReqName');
    } else if (checkStringForOnlySpecialCharacter(ssName.trim())) {
      errorJSON[0] = i18n.t('Name_CanNotContain_OnlySpecialCharacters');
    } else {
      errorJSON[0] = '';
    }

    if (parseInt(ssClientID) == 0) {
      errorJSON[1] = i18n.t('Snapshot_Add_ReqClient');
    } else {
      errorJSON[1] = '';
    }

    if (parseInt(ssOfferID) == 0) {
      errorJSON[2] = i18n.t('Snapshot_Add_ReqOffer');
    } else {
      errorJSON[2] = '';
    }

    if (parseInt(ssSubOfferID) == 0) {
      errorJSON[3] = i18n.t('Snapshot_Add_ReqSubOffer');
    } else {
      errorJSON[3] = '';
    }

    if (parseInt(ssOfferID) == 0 && parseInt(ssSubOfferID) == 0) {
      errorJSON[4] = i18n.t('Snapshot_Add_ReqLocation');
    } else if (parseInt(ssLocationID) == 0) {
      errorJSON[4] = i18n.t('Snapshot_Add_ReqLocation');
    } else {
      errorJSON[4] = '';
    }

    if (parseInt(ssLandingPageID) == 0) {
      errorJSON[5] = i18n.t('Landing_Page_is_Required');
    } else {
      errorJSON[5] = '';
    }
    setSSErrors({
      Name: errorJSON[0],
      Client: errorJSON[1],
      Offering: errorJSON[2],
      SubOffering: errorJSON[3],
      Location: errorJSON[4],
      LandingPage: errorJSON[5],
    });

    if (
      errorJSON[0] === ''
      && errorJSON[1] === ''
      && errorJSON[2] === ''
      && errorJSON[3] === ''
      && errorJSON[4] === ''
      && errorJSON[5] === ''
    ) {
      const snapshot = {
        SnapshotID: 0,
        SnapShotName: ssName.trim(),
        OrgEntityID: parseInt(ssClientID),
        OrgEntityName: '',
        DealID: 0,
        OfferingID: parseInt(ssSubOfferID),
        Offering: '',
        Process: ssSubOffer,
        LocationID: parseInt(ssLocationID),
        LandingPageId: parseInt(ssLandingPageID),
        City: '',
        IsDefault: document.getElementById('chkSSIsDefault').checked,
        CreatedBy: null,
        CreatedTS: new Date(),
        LastUpdatedBy: null,
        LastUpdatedTS: new Date(),
        UserID: 0,
      };

      saveSnapshotApiCall(snapshot, true);
    }
  };

  const saveSnapshotApiCall = (snapshot) => {
    if (
      snapshot != null
      && snapshot.SnapShotName.trim() !== ''
      && snapshot.OrgEntityID > 0
      && snapshot.OfferingID > 0
      && snapshot.LocationID > 0
      && snapshot.LandingPageId > 0
    ) {
      setDisableCreate(true);
      setIsApiLoading(true);
      axiosInstance
        .post(`${LocalApiBaseUrl}Snapshot/AddEditSnapShot`, snapshot)
        .then((response) => {
          setDisableCreate(false);
          setIsApiLoading(false);
          window.alert(i18n.t(response.data.StatusMessage));
          if (response.data.IsSuccess) {
            closeAddNameSSPopup();

            if (snapshot.OrgEntityID === Client) getAllProfileAgainstUser();
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          setDisableCreate(false);
          setIsApiLoading(false);
        });
    }
  };

  const closeAddNameSSPopup = () => {
    setShowSnapshotPopup(false);
  };

  const saveSnapshotFromFilter = () => {
    let processID = 0;
    let locationID = 0;
    if (document.getElementById('process') != null) { processID = document.getElementById('process').value; }
    if (document.getElementById('Locations') != null) { locationID = document.getElementById('Locations').value; }

    if (ssName.trim() == '') {
      setSSErrors({
        Name: i18n.t('Snapshot_Add_ReqName'),
      });
    } else if (checkStringForOnlySpecialCharacter(ssName.trim())) {
      setSSErrors({
        Name: i18n.t('Name_CanNotContain_OnlySpecialCharacters'),
      });
    } else {
      setSSErrors({ SaveName: '' });

      const snapshot = {
        SnapshotID: 0,
        SnapShotName: ssName.trim(),
        OrgEntityID: parseInt(Client),
        OrgEntityName: '',
        DealID: 0,
        OfferingID: processID,
        Offering: '',
        Process: '',
        LocationID: locationID,
        City: '',
        IsDefault: false,
        CreatedBy: null,
        CreatedTS: new Date(),
        LastUpdatedBy: null,
        LastUpdatedTS: new Date(),
        UserID: 0,
      };

      saveSnapshotApiCall(snapshot);
    }
  };

  /*   const getAllProfileAgainstUser = () => {
    const client = Client;

    let loadDefaultSnapshot = false;
    return new Promise((resolve, reject) => {
      if (client != undefined && client > 0 && client != "") {
        axiosInstance
          .get(`${LocalApiBaseUrl}Snapshot/GetSnapshotsByUserID`, {
            params: { OrgEntityID: client, LanguageID: languageData.id },
          })
          .then((response) => {
            store.dispatch({
              type: "SNAPSHOT",
              payload: response.data,
            });
            setProfileList(response.data);
            setDefaultSelectedProfileName(
              response.data.length == 0 ? "" : defaultSelectedProfileName,
              false,
              {},""
            );

            let defaultSelectedProfileNametemp = defaultSelectedProfileName;
            if (response.data != null && response.data.length > 0) {
              const defaultSS = response.data.filter((f) => {
                return f.IsDefault === true;
              });
              if (defaultSS.length > 0) {
                defaultSelectedProfileNametemp = defaultSS[0].SnapShotName;

                loadDefaultSnapshot =
                  SelectedFilter &&
                  (!SelectedFilter.OfferingId ||
                    !SelectedFilter.ProcessId ||
                    (!SelectedFilter.LocationId &&
                      SelectedFilter.OfferingId ===
                        offeringData.filter((o) => {
                          return o.OfferingName_En === defaultSS[0].Offering_En;
                        })[0].OfferingID &&
                      SelectedFilter.ProcessId === defaultSS[0].OfferingID))
                    ? true
                    : false;
                if (loadDefaultSnapshot) {
                  store.dispatch({
                    type: "SYNC_CURRENTFILTER",
                    payload: {
                      OfferingId: offeringData.filter((o) => {
                        return o.OfferingName_En === defaultSS[0].Offering_En;
                      })[0].OfferingID,
                      ProcessId: defaultSS[0].OfferingID,
                      LocationId: defaultSS[0].LocationID,
                      Month: "",
                      LocationOMID: [],
                      IsDataPublishForLocation: false,
                      isOMIDAvailable: false,
                      OfferingName: defaultSS[0].Offering_En,
                    },
                  });
                  setDefaultSelectedProfileName(
                    loadDefaultSnapshot ? defaultSelectedProfileNametemp : "",
                    false,
                    {},""
                  );
                }
              }
            }
            resolve();
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            reject();
          });
      } else {
        window.alert(ssSelectClient);
        reject();
      }
    });
  }; */

  const setSnapshotToDefault = (ssID) => {
    const client = Client;
    if (client != undefined && client > 0 && client != '') {
      if (parseInt(ssID) > 0) {
        axiosInstance
          .post(`${LocalApiBaseUrl}Snapshot/SetSnapshotAsDefault?SnapshotId=${ssID}`)
          .then((response) => {
            window.alert(i18n.t(response.data.StatusMessage)); // response.data.StatusMessage

            getAllProfileAgainstUser();
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      } else {
        window.alert(ssIDReqMsg);
      }
    } else {
      window.alert(ssSelectClient);
    }
  };

  // FUNCTION END

  return (
    <>
      <div
        className={`custom-headerdropdown mr-3 ${
          defaultSelectedProfileName != '' ? 'selected' : ''
        }`}
        ref={SnapshotDropdownPopoverTarget}
      >
        <span
          data-tip
          data-for="selectprofile"
          className="dropdown-toggle snapshot-toggle"
          onClick={isSnapshotDropdownPopoverShow}
        >
          <img src={Camerawhite} className="mr-2" />
          {defaultSelectedProfileName != '' ? defaultSelectedProfileName : i18n.t('Select Profile')}
          <ReactTooltip
            place="bottom"
            type="dark"
            effect="float"
            id="selectprofile"
            className="snap-tooltip"
          >
            <span>Save your favorite profile</span>
          </ReactTooltip>
        </span>

        <Overlay
          target={SnapshotDropdownPopoverTarget.current}
          show={isSnapshotDropdownPopoverStatus}
          onHide={isSnapshotDropdownPopoverClose}
          placement="bottom-end"
          container={SnapshotDropdownPopoverTarget.current}
          rootClose
          rootCloseEvent="click"
        >
          <Popover className="snapshot-popover">
            <Popover.Content>
              <div className="snapshot-select">
                <div className="snapshot-select-header">
                  <h5>{i18n.t('My Profile')}</h5>
                  <div className="newsnapshot-link">
                    <span>
                      <a href="#" onClick={() => addSnapshotPopupRender()}>
                        <i className="far fa-plus mr-2" />
                        {i18n.t('New')}
                      </a>
                    </span>
                  </div>
                </div>
                <ul className="snapshot-links">
                  {profileList.map((m) => (
                    <li
                      className={
                          m.IsDefault === true
                            ? 'snapshot-linklist default-snapshot'
                            : 'snapshot-linklist'
                        }
                    >
                      <div
                        className="snapshot-name"
                        onClick={() => changeSnapshotSelection(m.SnapshotID)}
                      >
                        <p data-tip data-for={`ssInfo_${m.SnapshotID}`}>
                          {m.SnapShotName.length > 19
                            ? `${m.SnapShotName.slice(0, 16)}...`
                            : m.SnapShotName}
                        </p>
                        <ReactTooltip
                          place="bottom"
                          type="dark"
                          effect="float"
                          id={`ssInfo_${m.SnapshotID}`}
                          className="snap-tooltip"
                        >
                          <span>
                            <strong>{m.SnapShotName}</strong>
                            <br />
                            <span>
                              {i18n.t('Offering')}
                              :
                              {m.Offering}
                            </span>
                            <br />
                            <span>
                              {i18n.t('Sub_Offering')}
                              :
                              {m.Process}
                            </span>
                            <br />
                            <span>
                              {i18n.t('Location')}
                              :
                              {m.City}
                            </span>
                            <br />
                            <span>
                              {i18n.t('Landing Page')}
                              :
                              {m.PageName}
                            </span>
                          </span>
                        </ReactTooltip>
                      </div>
                      <div className="snapshotnav-links">
                        <ul>
                          <li className={m.IsDefault ? 'default-tagicon' : ''}>
                            <div className="snaplink-icon snaptag-icon mr-2">
                              <a
                                data-tip
                                data-for="Tagicon1"
                                onClick={() => {
                                  setSnapshotToDefault(m.SnapshotID);
                                }}
                                className="bookmark-icon"
                              >
                                {' '}
                              </a>
                              <ReactTooltip
                                place="bottom"
                                type="dark"
                                effect="float"
                                id="Tagicon1"
                                className="snap-tooltip"
                              >
                                <span>Set as Default</span>
                              </ReactTooltip>
                            </div>
                          </li>
                          <li>
                            <div className="snaplink-icon snaptrash-icon">
                              <a
                                data-tip
                                data-for="Deleteicon1"
                                onClick={() => {
                                  deleteSnapshot(m.SnapshotID);
                                }}
                                className="delete-icon"
                              />
                              <ReactTooltip
                                place="bottom"
                                type="dark"
                                effect="float"
                                id="Deleteicon1"
                                className="snap-tooltip"
                              >
                                <span>Delete</span>
                              </ReactTooltip>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>
      {compDetails !== 'Journey Initiatives'
      && parseInt(filterDetails.selectedOffering)
      && parseInt(filterDetails.selectedSubOffering)
      && parseInt(filterDetails.selectedLocation)
      && filterDetails.selectedMonth
      && MaturityPhase ? (
        <div className="d-flex align-items-center">
          <p className="mr-2 pr-2 automation-text">
            <img src={DatabaseWhite} className="mr-2" />
            {IOJData.map((e) => {
              if (e.IOJName === MaturityPhase) {
                return e.IOJNameValue;
              }
            })}
          </p>
          <p>
            {' '}
            {IOJData.map((e) => {
              if (e.IOJName === MaturityPhase) {
                return <p>{e.IOJDesc}</p>;
              }
            })}
          </p>
        </div>
        ) : null}

      <Modal className="modal fade" id="newSnap" show={showSnapshotPopup}>
        <Modal.Header>
          <h4 className="modal-title">{i18n.t('New Profile')}</h4>

          <button
            type="button"
            className="close"
            onClick={() => {
              closeAddNameSSPopup();
            }}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">{i18n.t('Close')}</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {isApiLoading ? <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active /> : ''}
          <form>
            <div className="form-section">
              <div className="row">
                <div className="form-group col-lg-6">
                  <label htmlFor="">
                    {i18n.t('Name')}
                    {' '}
                    <span className="required">*</span>
                  </label>
                  <input
                    id="txtSSName"
                    className="form-control"
                    type="text"
                    name=""
                    maxLength="100"
                    value={ssName}
                    defaultValue={ssName}
                    onChange={(e) => {
                      bindAddSSPopupData(e);
                    }}
                  />
                  <span className="errorMsg">{ssErrors.Name}</span>
                </div>
                <div className="form-group col-lg-6">
                  <label htmlFor="">
                    {i18n.t('Client')}
                    {' '}
                    <span className="required">*</span>
                  </label>
                  <select
                    id="ddSSClient"
                    className="form-control"
                    name=""
                    value={ssClientID}
                    defaultChecked={ssClientID}
                    onChange={(e) => {
                      bindAddSSPopupData(e);
                    }}
                  >
                    <option value="0">{i18n.t('Select_Client')}</option>
                    {OperatingGropus
                      && OperatingGropus.map((client) => (
                        <option
                          key={client.OrgEntityID}
                          value={client.OrgEntityID}
                          name={client.OrgEntityImage}
                        >
                          {client.OrgEntityName}
                        </option>
                      ))}
                  </select>
                  <span className="errorMsg">{ssErrors.Client}</span>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <label htmlFor="">
                    {i18n.t('Offering')}
                    {' '}
                    <span className="required">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="ddSSOffer"
                    name=""
                    value={ssOfferID}
                    defaultChecked={ssOfferID}
                    onChange={(e) => {
                      bindAddSSPopupData(e);
                    }}
                  >
                    <option value="0">{i18n.t('Select_Offering')}</option>
                    {ssOffering
                      && ssOffering.map((o) => (
                        <option key={o.OfferingID} value={o.OfferingID} name={o.OfferingName}>
                          {o.OfferingName}
                        </option>
                      ))}
                  </select>
                  <span className="errorMsg">{ssErrors.Offering}</span>
                </div>
                <div className="form-group col-lg-6">
                  <SubOfferingDropdowns
                    selectedSubOffering={ssSubOffer}
                    subOfferings={ssProcess}
                    onSubOfferingChange={(e) => onsnapshotSubOfferingChange(e)}
                    defaultToggleName={i18n.t('Select_Sub_Offerings')}
                    selectedSubOfferingId={ssSubOfferID}
                    required
                    labelfor=""
                    labelClassName=""
                    disabledValue={false}
                    dropdownItem
                    labelpresent
                    SubOfferingDropdownsClass
                  />
                  <span className="errorMsg">{ssErrors.SubOffering}</span>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <label htmlFor="">
                    {i18n.t('Location')}
                    {' '}
                    <span className="required">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="ddSSLocation"
                    name=""
                    value={ssLocationID}
                    defaultChecked={ssLocationID}
                    onChange={(e) => bindAddSSPopupData(e)}
                  >
                    <option value="0">{i18n.t('Select_Location')}</option>

                    {ssLocation
                      && ssLocation.map((o) => (
                        <option key={o.LocationID} value={o.LocationID} name={o.City}>
                          {o.City}
                        </option>
                      ))}
                  </select>
                  <span className="errorMsg">{ssErrors.Location}</span>
                </div>

                <div className="form-group col-lg-6">
                  <label htmlFor="">
                    {i18n.t('Landing Page')}
                    {' '}
                    <span className="required">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="ddSSLandingPage"
                    name=""
                    value={ssLandingPageID}
                    defaultChecked={ssLandingPageID}
                    onChange={(e) => bindAddSSPopupData(e)}
                  >
                    <option value="0">{i18n.t('Select_LandingPage')}</option>
                    {ssLandingPage.length > 0
                      && ssLandingPage.map((o) => (
                        <option key={o.PageID} value={o.PageID} name={o.PageName}>
                          {o.PageName}
                        </option>
                      ))}
                  </select>
                  <span className="errorMsg">{ssErrors.LandingPage}</span>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-lg-6 label-adjust">
                  <label className="form-check">
                    <input
                      className="form-check-input"
                      id="chkSSIsDefault"
                      type="checkbox"
                      value={ssIsDefault}
                      defaultChecked={ssIsDefault}
                      onChange={(e) => bindAddSSPopupData(e)}
                    />
                    <span className="checkmark" />
                    <span className="form-check-label">{i18n.t('Is_Default')}</span>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => {
                saveSnapshot();
              }}
              disabled={disableCreate}
            >
              {i18n.t('Create')}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  CurrentFilter: { ...state.CurrentFilter },
  compDetails: state.CurrentFilter.Tab.title,
  SelectedFilter: { ...state.CurrentFilter.SyncFilter },
  snapshotProfilesRedux: [...state.CurrentFilter.snapshotProfile],
  roleData: state.roleReducers.roleReducers,
});
const mapDispatchToProps = (dispatch) => ({
  updateSyncFilter: (data) => new Promise((resolve, reject) => {
    dispatch({ type: 'SYNC_CURRENTFILTER', payload: data });

    resolve();
  }),
});
export default connect(mapStateToProps, mapDispatchToProps)(SnapshotProfile);
