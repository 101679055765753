import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";

export default function SingleSelectDropDown({ labelledBy, onChangeFun, selected, options,rowIndex }) {

  const [filterOptions, setFilteroptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    setFilteroptions(options);
  },[options])
  const setFilter =(e) => {
   
    if(e.target.value !== ""){
    let filterData = options.filter( (f) => {
     return(
       f.RoleName.toUpperCase().includes(e.target.value.toUpperCase())
     )
   })
   setFilteroptions(filterData);
 }
 else{
   setFilteroptions([...options])
 }
   setSearchValue(e.target.value);

 }

  return (
    <>
      <label htmlFor="profile" className="form-label">
        {labelledBy} <span className="required">*</span>
      </label>
      <div className="customform-dropdown">
        <Dropdown id="role" onSelect={(e) => onChangeFun(e,rowIndex)} value={selected.value}>
          <Dropdown.Toggle className={selected.label !== undefined ? '' : 'not-selected'}>{selected.label !== undefined ? selected.label : labelledBy}</Dropdown.Toggle>
          <Dropdown.Menu>
          
          <div class="searchFilter user-profile-search">
                     <input
                       id="search"
                       placeholder="Search ..."
                       type="search"
                       value = {searchValue}
                       onChange={((e)=>{setFilter(e)})}
                     />
              </div>
            
            <div className="offering-droplistwrapper">
              {filterOptions.map((each) => {
                return (
                  <Dropdown.Item value={each.value} eventKey={each.label}>
                    {each.label}
                  </Dropdown.Item>
                );
              })}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}
