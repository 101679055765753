import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import i18n from "i18next";
export default class AddMoreToolPopup extends Component {
  constructor(props) {
    super(props);
    this.state={
      search:'',
      filteredData:[]
    }
    this.setTools=this.setTools.bind(this);
    this.searchTools=this.searchTools.bind(this);
  }

  searchTools(e){
   let filterData = this.props.toolList.filter((tool)=>tool.ApplicationName == e.target.value)
   this.setState({
    search:e.target.value,
    filteredData:filterData
  });
   
  }
  setTools() {
    let {toolList,showAllTools,screen}= this.props;
    let searchString = this.state.search.trim().toLowerCase();
    if (searchString.length > 0) {
      toolList = toolList.filter(function(search) {
        return search.ApplicationName.toLowerCase().match( searchString )})
    }
    let ATTools = toolList.filter((tool) => tool.ToolType === "AT");
    let AITools = toolList.filter((tool) => tool.ToolType === "AI");
    let WFTools = toolList.filter((tool) => tool.ToolType === "WF");
    let AATools = toolList.filter((tool) => tool.ToolType === "AA");
    let toolcatalog = (
      <>
        <div className="catalog-list">
        <h6>{i18n.t("Automation")}• { screen === "Dashboard" ? showAllTools ? ATTools.length : ATTools.filter((tool)=>tool.IsMapped === true).length : ATTools.length  }</h6>
        <div className="radius-tools">
          {ATTools.map((tool,index) => 
            screen === "Dashboard" ? showAllTools 
          ? <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
          : tool.IsMapped && <span className="active">{tool.ApplicationName}</span> 
          : <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
          )}
        </div>
        </div>
        <div className="catalog-list">
        <h6>{i18n.t("Workflow")}• {screen === "Dashboard" ? showAllTools ? WFTools.length : WFTools.filter((tool)=>tool.IsMapped === true).length : WFTools.length}</h6>
        <div className="radius-tools">
          {WFTools.map((tool,index) => 
            screen === "Dashboard" ? showAllTools 
          ? <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
          : tool.IsMapped && <span className="active">{tool.ApplicationName}</span> 
          : <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
          )}
          </div>
        </div>
        <div className="catalog-list">
          <h6>{i18n.t("Advanced_Analytics")}• {screen === "Dashboard" ? showAllTools ? AATools.length : AATools.filter((tool)=>tool.IsMapped === true).length : AATools.length}</h6>
          <div className="radius-tools">
          {AATools.map((tool,index) => 
            screen === "Dashboard" ? showAllTools 
          ? <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
          : tool.IsMapped && <span className="active">{tool.ApplicationName}</span> 
          : <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
          )}
          </div>
        </div>
        <div className="catalog-list">
        <h6>{i18n.t("Artificial_Intelligence")}• {screen === "Dashboard" ? showAllTools ? AITools.length : AITools.filter((tool)=>tool.IsMapped === true).length : AITools.length}</h6>
        <div className="radius-tools">
          {AITools.map((tool,index) => 
            screen === "Dashboard" ? showAllTools 
          ? <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
          : tool.IsMapped && <span className="active">{tool.ApplicationName}</span> 
          : <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
          )}
          </div>
        </div>
      </>
    );

    return toolcatalog;
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.modalClose}
        className="tooldata-pop moretool-popup"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="title-with-icon">
              <h4>Tools Catalog</h4>
              <div className="searchFilter">
                <input type="search" placeholder="Search" value={this.state.search} onChange={this.searchTools}/>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="moretool-data">
            {this.setTools()}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
