import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import $ from "jquery";
import _ from "lodash";
import { HasValue } from "../Shared/commonFunctions";
import { LocalApiBaseUrl,LocalApiBaseUrlImage } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { screeNamesForUserTracking, logUserTrackingData, validateIsAllowedDomainFromURL, fetchAllowedDomains } from "../Shared/commonFunctions";
import {
  faFileAlt,
  faVideo,
  faTv,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { filterSelectedSubOffering } from "../Shared/commonFunctions";
import { Tabs, Tab } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import AddContact from "./AddContact";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import i18n from "i18next";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import "./story.css";
import VideoGrey from "../Images/video-grey.svg";
import GuideGrey from "../Images/guide-grey.svg";
import VideoGrad from "../Images/video-grad.svg";
import GuideGrad from "../Images/guide-grad.svg";
import PasswordGrad from "../Images/copypassword-gradient.svg";
import PasswordGrey from "../Images/copypassword-grey.svg";
import AddGrad from "../Images/adddemo-gradient.svg";
import DeleteGrad from "../Images/deletedemo-gradient.svg";
import EditGrad from "../Images/editdemo-gradient.svg";
import AddGrey from "../Images/adddemo-grey.svg";
import DeleteGrey from "../Images/deletedemo-grey.svg";
import EditGrey from "../Images/editdemo-grey.svg";
import DeleteIcon from "../Images/exclamation-triangle-grad.svg";
import Success from "../Images/Success.svg";
import defaultofferingImage from "../Images/DefaultClientCard.svg";

class StoryView extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.offeringContacts = this.offeringContacts.bind(this);
    this.onGroupOfferingChange = this.onGroupOfferingChange.bind(this);
    this.offeringChange = this.offeringChange.bind(this);
    this.deleteContact = this.deleteContact.bind(this);
    this.editContact = this.editContact.bind(this);
    this.addContact = this.addContact.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = {
      showofferingContactsBtn: true,
      selectedGroupOffering: "",
      selectedOffering: "",
      selectedOfferingData: {},
      offeringData: [],
      storyData:{
        DemoName:"",        
        DemoURL:"",
        ScriptURL:"",
        VideoURL:"",
        DemoPassword:"",
      },
      storyDetails: [],
      filterDemoData: [],
      errors:{
        DemoName: "",
        DemoLink: "",
        DemoPassword: "",
        ScriptLink: "",
        VideoLink: "",
      },
      inputs:{
        isDemoName: false,
        isDemoLink: false,
        isDemoPassword: false,
        isScriptLink: false,
        isVideoLink: false,
      },
      editData: "",
      show: false,
      initialscreenload: true,
      offeringcontactClicked: false,
      addContactClicked: false,
      editContactClicked: false,
      showLoaderOnPage: false,
      isAdmin: false,
      addDemoStatus: false,
      deleteDemoStatus: false,
      editDemoStatus: false,
      passwordcopiedStatus: false,
      deleteID: 0,
      deleteAllId: 0,
      addEditID: 0,
      offID: 0,
      demoSearchText: "",
      offeringSearchText: "",
      allSuboffering: "",
      currentOffering: "",
      isPasswordHover:false,
      passwordImg:PasswordGrey,
      offeringDataDup: [],
      allowedDomainsArray: [],
      offeringSelection:"",
    };
    
  }
  handleScroll = () => {
    ReactTooltip.hide();
  };

  addDemoModalShow = (offeringId,subOffering,offering) => {
    const offerId = offeringId;
    
    this.setState({ 
      addDemoStatus: true,
      show: true,
      offID: offerId,
      currentOffering: offering,
      allSuboffering: subOffering,
      addEditID : 0,
      storyData:{
        DemoName:"",        
        DemoURL:"",
        ScriptURL:"",
        VideoURL:"",
        DemoPassword:"",
        }, 
      });
  };
  editDemoModalShow=(editID,offeringId,subOffering,offering)=>{
    const story_data = this.state.storyDetails.filter(story =>{return story.StoryID == editID});
    
    this.setState({ 
      editDemoStatus: true,
      addEditID: editID ,
      currentOffering: offering,
      allSuboffering: subOffering,
      offID: offeringId,
      storyData:story_data[0]
    });
  };
  addDemoModalClose = () => {
    this.setState({
      addDemoStatus: false,
      editDemoStatus: false,
      currentOffering: "", allSuboffering: "",
      errors: { DemoName: "", DemoLink: "", DemoPassword: "", ScriptLink: "", VideoLink: "", }
    });

  };
  editDemoModalClose=()=>{
    this.setState({
      editDemoStatus: false,
      addDemoStatus: false,
      errors: { DemoName: "", DemoLink: "", DemoPassword: "", ScriptLink: "", VideoLink: "", }
    });
   
  }
  deleteDemoModalShow = (delID,all,subOffering) => {
    const id = delID;
    this.setState({ deleteDemoStatus: true});
    all=="deleteAll"?this.setState({deleteAllId:id,allSuboffering: subOffering }):this.setState({deleteID:id});
  };
  deleteDemoModalClose = () => {
    this.setState({ deleteDemoStatus: false,allSuboffering: "" });
  };
  passwordcopiedShow = (password) => {
    navigator.clipboard.writeText(password);
    this.setState({ passwordcopiedStatus: true });
      setTimeout(() => {        
        $('.passwordcopy-notification').fadeOut('slow');
      }, 3000);
     
      setTimeout(() => {
        this.setState({ passwordcopiedStatus: false });
      }, 3000);
  };

  clearSearch(field){
    field == "demoSearch"?
    this.setState({demoSearchText: ""}) :
    this.setState({offeringSearchText: ""})
  }

  onFilterTextBoxChanged(e) {
    let id = e.target.id;
    id=="demoSearch"?
      this.setState({demoSearchText: e.target.value}) :
      this.setState({offeringSearchText: e.target.value})

    // this.getDefaultData()
}
    onOutsideClick = (e) =>{
      let id = e.target.id;
    let inputs = {...this.state.inputs}
    switch(id){
      case "demoUrl":
        inputs.isDemoLink = false;
        this.setState({inputs});
        break;
      case "videoUrl": 
        inputs.isVideoLink = false;
        this.setState({inputs});
        break;
      case "demoName":
        inputs.isDemoName = false;
        this.setState({inputs});
        break;
      case "demoPassword":
        inputs.isDemoPassword = false;
        this.setState({inputs});
        break;
      case "scriptUrl":
        inputs.isScriptLink = false;
        this.setState({inputs});
        break;
    }
    }
  OnFocusTextarea = (e) => {
    let id = e.target.id;
    let inputs = {...this.state.inputs}
    switch(id){
      case "demoUrl":
        inputs.isDemoLink = true;
        this.setState({inputs});
        break;
      case "videoUrl": 
        inputs.isVideoLink = true;
        this.setState({inputs});
        break;
      case "demoName":
        inputs.isDemoName = true;
        this.setState({inputs});
        break;
      case "demoPassword":
        inputs.isDemoPassword = true;
        this.setState({inputs});
        break;
      case "scriptUrl":
        inputs.isScriptLink = true;
        this.setState({inputs});
        break;
    }
  }

    bindPopupData = (e) => {
    let id = e.target.id;
    let storyData = {...this.state.storyData}
    switch(id){
      case "demoUrl":
        storyData.DemoURL = e.target.value;
        this.setState({storyData});
        break;
      case "videoUrl": 
      storyData.VideoURL = e.target.value;
        this.setState({storyData});
        break;
      case "demoName":
        storyData.DemoName = e.target.value;
        this.setState({storyData});
        break;
      case "demoPassword":
        storyData.DemoPassword = e.target.value;
        this.setState({storyData});
        break;
      case "scriptUrl":
        storyData.ScriptURL = e.target.value;
        this.setState({storyData});
        break;
    
    }
  }
   isUrlValid=(userInput)=> {
    let res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return false;
    else
        return true;
      }

    validateData=()=>{
      const { allowedDomainsArray } = this.state;

      let errorJSON = [];
      if (this.state.storyData.DemoName.trim() == "") {
        errorJSON[0] = "Demo Name is required";
      } else {
          errorJSON[0] = "";      
      }

      if (this.state.storyData.DemoURL.trim() == "") {
        errorJSON[1] = "Demo Link is required";
      } else {
        if(this.isUrlValid(this.state.storyData.DemoURL.trim())){
          const isAllowed = validateIsAllowedDomainFromURL(this.state.storyData.DemoURL, allowedDomainsArray);
          if (!isAllowed) {
            errorJSON[1] = i18n.t('subdomain_or_domain_not_whitelisted');
          } else {
            errorJSON[1] = ""; 
          }
        }else{
          errorJSON[1] = "Please enter a valid URL"; 
        }     
      }

      if (this.state.storyData.ScriptURL.trim() == "") {
        errorJSON[2] = "";
      } else {
      if(this.isUrlValid(this.state.storyData.ScriptURL.trim())){
        const isAllowed = validateIsAllowedDomainFromURL(this.state.storyData.ScriptURL, allowedDomainsArray);
        if (!isAllowed) {
          errorJSON[2] = i18n.t('subdomain_or_domain_not_whitelisted');
        } else {
          errorJSON[2] = "";
        }
      }else{
        errorJSON[2] = "Please enter a valid URL"; 
      }   
    }
    if (this.state.storyData.VideoURL.trim() == "") {
      errorJSON[3] = "";
    } else {
      if(this.isUrlValid(this.state.storyData.VideoURL.trim())){
        const isAllowed = validateIsAllowedDomainFromURL(this.state.storyData.VideoURL, allowedDomainsArray);
        if (!isAllowed) {
          errorJSON[3] = i18n.t('subdomain_or_domain_not_whitelisted');
        } else {
          errorJSON[3] = "";
        }
      }else{
        errorJSON[3] = "Please enter a valid URL"; 
      } 
    }

      this.setState({
        errors:{
          DemoName: errorJSON[0],
          DemoLink: errorJSON[1],
          ScriptLink: errorJSON[2],
          VideoLink: errorJSON[3]
        }      
      });

      if(errorJSON[0]=="" && errorJSON[1]=="" && errorJSON[2]=="" && errorJSON[3]==""){
        let offeringData = {
          StoryID:parseInt(this.state.addEditID),
          OfferingId:parseInt(this.state.offID),
          DemoName:this.state.storyData.DemoName,
          DemoURL:this.state.storyData.DemoURL,
          ScriptURL:this.state.storyData.ScriptURL,
          VideoURL:this.state.storyData.VideoURL,
          DemoPassword:this.state.storyData.DemoPassword
        };
        this.addEditOffering(offeringData);
      }

    }

 
  addEditOffering=(offeringData)=>{  
    if (offeringData!=null && this.state.addEditID>=0){
      axiosInstance
        .post(
          `${LocalApiBaseUrl}SynopsStory/AddEditSynopsOfferingStoryDemo`,offeringData
        )
        .then((response) => { 
          if(response.data.IsSuccess){        
          this.getDefaultData();
          let message = this.state.addEditID?"Demo link updated successfully":"Demo link added successfully";
          this.setState({ addDemoStatus: false,editDemoStatus:false, addEditID: 0, allSuboffering: ""}); 
          window.alert(message);
          }else{
          window.alert(response.data.StatusMessage);
          }
          
        })
        .catch((error) => {
          this.setState({ showLoaderOnPage: false });
          trycatchAlertPopup(error);
        });
    } else {
      this.setState({ showLoaderOnPage: false });
    }
  }

  deleteAllOfferings=()=>{
    const id = this.state.deleteAllId;
    this.setState({ deleteDemoStatus: false, deleteAllId:0, allSuboffering:"" }); 
    const allSuboffering = this.state.allSuboffering;
    if (id>0){
      axiosInstance
        .delete(
          `${LocalApiBaseUrl}SynopsStory/DeleteSynopsOfferingDemoByOffering?offeringID=${id}`
        )
        .then((response) => {
          this.getDefaultData();         
          window.alert("Demo deleted successfully");
          
        })
        .catch((error) => {
          this.setState({ showLoaderOnPage: false });
          trycatchAlertPopup(error);
        });
    } else {
      this.setState({ showLoaderOnPage: false });
    } 
  }

  deleteOffering=()=>{
    const id = this.state.deleteID;
    this.setState({ deleteDemoStatus: false }); 
    if (id>0){
      axiosInstance
        .delete(
          `${LocalApiBaseUrl}SynopsStory/DeleteSynopsOfferingDemo?demoID=${id}`  
        )
        .then((response) => {
          this.getDefaultData();         
          window.alert("Demo deleted successfully");
          
        })
        .catch((error) => {
          this.setState({ showLoaderOnPage: false });
          trycatchAlertPopup(error);
        });
    } else {
      this.setState({ showLoaderOnPage: false });
    }
  }

  deleteContact(suboffering) {
    let OfferingContactId = suboffering.OfferingContacts[0].OfferingContactId;
    this.setState({ showLoaderOnPage: true });
    if (window.confirm("Are you sure you want to delete the contact?")) {
      axiosInstance
        .delete(
          `${LocalApiBaseUrl}SynopsStory/DeleteSynopsOfferingContact?OfferingContactId=${OfferingContactId}`
        )
        .then((response) => {
          this.setState({ showLoaderOnPage: false });
          this.getDefaultData();
        })
        .catch((error) => {
          this.setState({ showLoaderOnPage: false });
          trycatchAlertPopup(error);
        });
    } else {
      this.setState({ showLoaderOnPage: false });
    }
  }

  onGroupOfferingChange(e) {
    this.setState({ selectedGroupOffering: e });
    let selectedoff = this.state.offeringData.find(
      (groupoff) => groupoff.OfferingGroupName == e
    ).Offerings[0].OfferingName;
    this.setState({
      selectedOffering: selectedoff,
      offeringcontactClicked: !this.state.initialscreenload,
      addContactClicked: false,
      editContactClicked: false,
    });
  }

  offeringChange(e) {
    let selectedOffering = this.state.offeringData
      .filter((x) => x.OfferingGroupName == this.state.selectedGroupOffering)[0]
      .Offerings.filter((y) => y.OfferingName == e)[0];
    let showofferingContactsBtn =
      selectedOffering &&
      selectedOffering.ChildOfferings &&
      selectedOffering.ChildOfferings.length > 0;
    this.setState({
      selectedOffering: e,
      showofferingContactsBtn: showofferingContactsBtn,
      selectedOfferingData: selectedOffering,
      offeringcontactClicked: !this.state.initialscreenload,
      addContactClicked: false,
      editContactClicked: false,
    });
  }

  offeringContacts() {
    this.setState({
      initialscreenload: false,
      offeringcontactClicked: true,
    });
  }

  backToHome() {
    this.setState({
      initialscreenload: true,
      offeringcontactClicked: false,
      addContactClicked: false,
      editContactClicked: false,
    });
  }

  addContact() {
    this.setState({
      initialscreenload: false,
      addContactClicked: true,
      offeringcontactClicked: false,
    });
  }

  editContact(editData) {
    let contactsdata = editData.OfferingContacts[0];
    let editdata = {
      SubOffering: editData.Process,
      OfferingLeadName: contactsdata.OfferingLeadName ? contactsdata.OfferingLeadName : "",
      OfferingLeadEmailId: contactsdata.OfferingLeadEmailId ? contactsdata.OfferingLeadEmailId : "",
      CTOName: contactsdata.CTOName ? contactsdata.CTOName : "",
      CTOEmailId: contactsdata.CTOEmailId ? contactsdata.CTOEmailId : "",
      SMEName: contactsdata.SMEName ? contactsdata.SMEName : "",
      SMEEmailId: contactsdata.SMEEmailId ? contactsdata.SMEEmailId : "",
    };
    this.setState({
      initialscreenload: false,
      editContactClicked: true,
      offeringcontactClicked: false,
      editData: editdata,
    });
  }

  onClose() {
    this.setState({
      offeringcontactClicked: true,
      addContactClicked: false,
      editContactClicked: false,
    });
    this.getDefaultData();
  }


  getDefaultData() {
    this.setState({ showLoaderOnPage: true });
    axiosInstance
      .get(`${LocalApiBaseUrl}SynopsStory/GetSynopsStoryOfferings?LanguageID=1`)
      .then((response) => {
        if (response.data) {
          response.data.sort((a, b) =>
            a.OfferingGroupName > b.OfferingGroupName
              ? 1
              : b.OfferingGroupName > a.OfferingGroupName
                ? -1
                : 0
          );
          
          const groupOffering = this.state.selectedGroupOffering?this.state.selectedGroupOffering:response.data[0].OfferingGroupName;
          let  selectedOffering  = this.state.selectedOffering;
          if(selectedOffering.length===0){
            selectedOffering = response.data.find(
              (groupoff) =>
                groupoff.OfferingGroupName == groupOffering
            ).Offerings[0].OfferingName
          }
          
          this.setState({
            showLoaderOnPage: false,
            offeringData: response.data,
            selectedGroupOffering: groupOffering,
            selectedOffering,
          },() => {
            this.GetAllChildOfferings();
          });

          
          axiosInstance
            .get(`${LocalApiBaseUrl}User/GetUserDetails`)
            .then((res) => {
              if (res.data) {
                this.setState({ isAdmin: res.data[0] });
              }
            })
            .catch((error) => {
              trycatchAlertPopup(error);
            });

        }
      })
      .catch((error) => {
        this.setState({ showLoaderOnPage: false });
      });
  }
  async componentDidMount(){
    this.setState({
      showLoaderOnPage: true,
    });
    const allowedDomains = await fetchAllowedDomains();
    this.setState({
      allowedDomainsArray: allowedDomains,
      showLoaderOnPage: false,
    });

    this.handleUserNavigation();
  }
  componentWillMount() {
    this.getDefaultData();
    // this.handleUserNavigation();
  }

  trackNavigation = (offering, subOff, btnType, e) => {
    this.handleUserNavigation(offering, subOff, btnType);
  }

  handleUserNavigation = (Nav1, Nav2, Nav3) => {
    const { languageData } = this.props;
    const data = {
      PageName: screeNamesForUserTracking.SynOpsDemo,
      Client: null,
      Language: languageData.langCode,
      Navigation1: Nav1,
      Navigation2: Nav2,
      Navigation3: Nav3
    };
    logUserTrackingData(data);
  };
  handleMouseEnter = (e) => {
    e.currentTarget.src = PasswordGrad;
  }
  handleMouseLeave = (e) => {
    e.currentTarget.src = PasswordGrey;
  }
  GetAllChildOfferings = (e) => {
    let storyList =[];
    let offeringDataDup = _.cloneDeep(this.state.offeringData);
    offeringDataDup.map((each) => {
      each.Offerings.map((eachOffering) => {
        let ChildOfferings = [];
        var data = filterSelectedSubOffering(eachOffering.ChildOfferings)
        ChildOfferings.push(...data.allLeafNodes);
        eachOffering.ChildOfferings = ChildOfferings;
      })
    });
    offeringDataDup.forEach(element => {
      element.Offerings.forEach(suboffering => {
        suboffering.ChildOfferings.map(story => {
          return storyList.push.apply(storyList, story.OfferingDemos)
        });
      });
    });
    this.setState({offeringDataDup, storyDetails:storyList})
  }
  render() {  
    let list, ChildOff;
    let ChildOfferings = [];
    if (this.state.offeringData.length > 0) {
      let ParentOff = this.state.offeringData.filter(
        (grp) => grp.OfferingGroupName == this.state.selectedGroupOffering
      )[0];
      let SubOff = ParentOff.Offerings.filter(
        (off) => off.OfferingName === this.state.selectedOffering
      )[0];
      ChildOff = SubOff.ChildOfferings.filter((sub) => sub.Process);
      var data = filterSelectedSubOffering(SubOff.ChildOfferings)
      ChildOfferings.push(...data.allLeafNodes);
      let listtemp = ChildOfferings.map((suboffering) => suboffering.OfferingContacts.filter((contact) => contact.IsActive == true));
      list = Array.prototype.concat.apply([], listtemp);

    }
    return (
      <>
        <div className="story-content">
          <div className="container-fluid">
            <LoadingOverlay
              fadeSpeed={0}
              spinner={<SynopsLoader />}
              active={this.state.showLoaderOnPage}
            // text={i18n.t("Please_wait")}
            ></LoadingOverlay>
            <div className="row">
              <div className="col-lg-12">
                {this.state.offeringcontactClicked ||
                  this.state.addContactClicked ||
                  this.state.editContactClicked ? (
                  <h3 className="offering-status">
                    <span onClick={this.backToHome.bind(this)}>
                      <i class="fas fa-chevron-left"></i> Back
                    </span>
                    Offering Contacts
                  </h3>
                ) : (
                  <h3>SynOps Demo</h3>
                )}
                <div className="story-tabs">
                  {this.state.initialscreenload &&
                    this.state.showofferingContactsBtn && (
                      <span
                        className="offering-btn"
                        onClick={this.offeringContacts}
                      >
                        Offering Contacts
                      </span>
                    )}

                  {this.state.isAdmin == "True" && this.state.offeringcontactClicked && (
                    <button
                      className="btn btn-outline-primary offering-btn"
                      onClick={this.addContact}
                    >
                      Add Contact
                    </button>
                  )}
                  <Tabs
                    defaultActiveKey={
                      this.state.selectedGroupOffering
                        ? this.state.selectedGroupOffering 
                        : "Functional Offerings"
                    }
                    onSelect={this.onGroupOfferingChange}
                  >
                    {this.state.offeringDataDup.map((tab) => (
                      <Tab
                        eventKey={tab.OfferingGroupName}
                        title={tab.OfferingGroupName}
                      >
                        <div className="storysub-tabs">
                          <Tabs
                            activeKey={this.state.selectedOffering}
                            onSelect={this.offeringChange}
                          >
                            {tab.Offerings.map((offering) => (
                              <Tab
                                eventKey={offering.OfferingName}
                                title={
                                  <span className="offering-navtab">
                                    <img
                                      src={ offering.ImageName 
                                        ? `${LocalApiBaseUrlImage}${offering.ImageName}`   
                                        : `${defaultofferingImage}`                                   
                                      }
                                      height="20px"
                                      width="30px"
                                    />
                                    <span>{offering.OfferingName}</span>
                                  </span>
                                }
                              >
                                {/* Offering List Screen Content */}
                                {!this.state.addContactClicked && !this.state.editContactClicked && !this.state.offeringcontactClicked &&
                                <div className="storymain-content" ref={this.myRef} onScroll={this.handleScroll}>
                                  <div class="storycontent-table">
                                    <div class="storycontent-row storycontent-header">
                                      <div class="storycontent-cell offering-cell">
                                        <DropdownButton id="dropdown-offering" title="Sub Offerings">
                                          <Dropdown.ItemText>
                                            <div class="searchFilter">
                                              <input
                                                id="offeringSearch"
                                                placeholder="Search ..."
                                                type="search"
                                                value={this.state.offeringSearchText}
                                                onChange={(e) => { this.onFilterTextBoxChanged(e) }}
                                              />
                                              <i class="fal fa-times clear-search" onClick={()=>{this.clearSearch("offeringSearch")}}></i>
                                            </div>
                                          </Dropdown.ItemText>

                                        </DropdownButton>
                                      </div>
                                      <div class="storycontent-cell demo-cell">
                                        <DropdownButton id="dropdown-offering" title="Demo">
                                          <Dropdown.ItemText>
                                            <div class="searchFilter">

                                              <input
                                                id="demoSearch"
                                                placeholder="Search ..."
                                                type="search"
                                                value={this.state.demoSearchText}
                                                onChange={(e) => { this.onFilterTextBoxChanged(e) }}
                                              />
                                              <i class="fal fa-times clear-search" onClick={() => { this.clearSearch("demoSearch") }}></i>
                                            </div>
                                          </Dropdown.ItemText>
                                        </DropdownButton>
                                      </div>
                                      <div class="storycontent-cell password-cell">Password</div>
                                      <div class="storycontent-cell userguide-cell text-center">User Guide</div>
                                      <div class="storycontent-cell videolink-cell text-center">Video Link</div>
                                      {this.state.isAdmin =="True" && <div class="storycontent-cell storylinks-cell"></div>}
                                    </div>
                                  
                                    {offering.ChildOfferings.length > 0 ? (
                                             
                                      offering.ChildOfferings.filter(f => { 
                                        
                                        return (
                                          this.state.demoSearchText == "" || ((f.OfferingDemos.length > 1 && f.OfferingDemos.findIndex(a => a.DemoName.toUpperCase().includes(this.state.demoSearchText.toUpperCase()))>-1) || (f.OfferingDemos.length == 1 &&
                                            f.OfferingDemos[0].DemoName.toUpperCase().includes(this.state.demoSearchText.toUpperCase())) )
                                        ) &&
                                          (this.state.offeringSearchText == "" || f.Process.toUpperCase().includes(this.state.offeringSearchText.toUpperCase()))
                                      }).map(
                                        (suboffering) => (
                                          (suboffering.OfferingDemos.length <= 1 ?
                                            <div className="storycontent-row storycontent-data">
                                              <div className="storycontent-cell offering-cell">
                                                {suboffering.Process}
                                              </div>
                                              {suboffering.OfferingDemos.length == 0 ? (
                                               <div className="storycontent-cell comingsoon-cell"></div>
                                                  
                                              ) : (
                                                <>
                                                  <div class="storycontent-cell demo-cell">
                                                    <span onClick={this.trackNavigation.bind('', offering.OfferingName, suboffering.Process, 'Demo')} data-tip data-for={"tooltip" + suboffering.OfferingDemos[0].StoryID} >
                                                      <a href={suboffering.OfferingDemos[0].DemoURL} target="_blank">
                                                        {suboffering.OfferingDemos[0].DemoName.length<=25?suboffering.OfferingDemos[0].DemoName:suboffering.OfferingDemos[0].DemoName.slice(0,25)+"..."}
                                                        </a>
                                                      
                                                      {suboffering.OfferingDemos[0].DemoName.length>25 &&
                                                      <ReactTooltip place="bottom" effect="solid" id={"tooltip" + suboffering.OfferingDemos[0].StoryID}>
                                                        <span>{suboffering.OfferingDemos[0].DemoName}</span>
                                                       </ReactTooltip>
                                                        }
                                                     </span>
                                                   </div>
                                                   {suboffering.OfferingDemos[0].DemoPassword ?
                                                   <div class="storycontent-cell password-cell password-copied" >
                                                      <img src={this.state.passwordImg} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={()=>this.passwordcopiedShow(suboffering.OfferingDemos[0].DemoPassword)} className="mr-1" />                                                   
                                                     <span data-tip data-for={"tooltip" + suboffering.OfferingDemos[0].DemoPassword}>
                                                     {suboffering.OfferingDemos[0].DemoPassword.length<=15 ? suboffering.OfferingDemos[0].DemoPassword:suboffering.OfferingDemos[0].DemoPassword.slice(0,15)+"..."}
                                                     {suboffering.OfferingDemos[0].DemoPassword.length>15 &&
                                                     <ReactTooltip place="bottom" effect="solid" id={"tooltip" + suboffering.OfferingDemos[0].DemoPassword}>
                                                        <span className="passwordCopy">{suboffering.OfferingDemos[0].DemoPassword}</span>
                                                       </ReactTooltip>
                                                    } 
                                                    </span>
                                                   </div>
                                                   :
                                                   <div class="storycontent-cell password-cell" >
                                                   </div>}

                                                   <div class="storycontent-cell userguide-cell text-center">
                                                     <span onClick={this.trackNavigation.bind('', offering.OfferingName, suboffering.Process, 'User Guide')}>
                                                       {suboffering.OfferingDemos[0].ScriptURL? 
                                                       <a href={suboffering.OfferingDemos[0].ScriptURL} target="_blank">
                                                           <img src={GuideGrad} />
                                                       </a>
                                                       :
                                                       <img src={GuideGrey} />
                                                        }
                                                     </span>
                                                   </div>
                                                  
                                                   <div class="storycontent-cell videolink-cell text-center">
                                                   <span onClick={this.trackNavigation.bind('', offering.OfferingName, suboffering.Process, 'Video')}>
                                                       {suboffering.OfferingDemos[0].VideoURL?
                                                       <a href={suboffering.OfferingDemos[0].VideoURL} target="_blank">
                                                         <img src={VideoGrad} />
                                                       </a>
                                                       :
                                                       <img src={VideoGrey} />
                                                        }
                                                     </span>
                                                   </div>
                                                   </>
                                                      )}
                                                  
                                                   {this.state.isAdmin == "True" ?
                                                   <div class="storycontent-cell storylinks-cell">
                                                     <span data-tip data-for="adddemo" onClick={()=>this.addDemoModalShow(suboffering.ProcessID,suboffering.Process,offering.OfferingName)}>
                                                       <img src={AddGrad} />
                                                       <ReactTooltip place="bottom" effect="solid" id="adddemo">
                                                         <span>Add Demo</span>
                                                       </ReactTooltip>
                                                     </span>
                                                     {suboffering.OfferingDemos.length > 0?
                                                     <>
                                                     <span data-tip data-for="editdemo" onClick={()=>this.editDemoModalShow(suboffering.OfferingDemos[0].StoryID,suboffering.ProcessID,suboffering.Process,offering.OfferingName)}>
                                                            <img src={EditGrad} />
                                                       <ReactTooltip place="bottom" effect="solid" id="editdemo">
                                                         <span>Edit Demo</span>
                                                       </ReactTooltip>
                                                     </span>
                                                     <span data-tip data-for="deletedemo" onClick={()=>this.deleteDemoModalShow(suboffering.OfferingDemos[0].StoryID,"deleteSingle")}><img src={DeleteGrad} />
                                                     
                                                       <ReactTooltip place="bottom" effect="solid" id="deletedemo">
                                                         <span>Delete Demo</span>
                                                       </ReactTooltip>
                                                     </span>
                                                     </>
                                                     :
                                                     <>
                                                     <span data-tip data-for="editdemo" >
                                                            <img className="greylink-image" src={EditGrey} />
                                                       <ReactTooltip place="bottom" effect="solid" id="editdemo">
                                                         <span>Edit Demo</span>
                                                       </ReactTooltip>
                                                     </span>
                                                     <span data-tip data-for="deletedemo" ><img className="greylink-image" src={DeleteGrey} />
                                                     
                                                       <ReactTooltip place="bottom" effect="solid" id="deletedemo">
                                                         <span>Delete Demo</span>
                                                       </ReactTooltip>
                                                     </span>
                                                     </>
                                                  }
                                                 </div>
                                                 :
                                                //  <div class="storycontent-cell storylinks-cell"></div>
                                                null
                                                   }

                                                  
                                                  
                                             </div>
                                               :
                                               
                                               <div class="storycontent-accordion">
                                               
                                               <div className="accordion" id="accordionExample">
                                                 <div className="card">
                                                   <div className="card-header" id="headingOne">
                                                     <div className="card-link" data-toggle="collapse" data-target={"#collapseOne"+suboffering.ProcessID} aria-expanded="true" aria-controls="collapseOne">
                                                       <span>{suboffering.Process} ({suboffering.OfferingDemos.length})</span>
                                                     </div>
                                                     {this.state.isAdmin == "True" ?
                                                     <div className="storyaccordion-right">
                                                       <span data-tip data-for="adddemo"  onClick={()=>this.addDemoModalShow(suboffering.ProcessID,suboffering.Process,offering.OfferingName)}>
                                                         <img src={AddGrad} />
                                                         <ReactTooltip place="bottom" effect="solid" id="adddemo">
                                                           <span>Add Demo</span>
                                                         </ReactTooltip>
                                                       </span>
                                                       <span data-tip data-for="editdemo"><img className="greylink-image" src={EditGrey} />
                                                         <ReactTooltip place="bottom" effect="solid" id="editdemo">
                                                           <span>Edit Demo</span>
                                                         </ReactTooltip>
                                                       </span>
                                                       <span data-tip data-for="deletedemo" onClick={()=>this.deleteDemoModalShow(suboffering.ProcessID,"deleteAll",suboffering.Process)}><img src={DeleteGrad} />
                                                         <ReactTooltip place="bottom" effect="solid" id="deletedemo">
                                                           <span>Delete Demo</span>
                                                         </ReactTooltip>
                                                       </span>
                                                     </div>
                                                     :
                                                    //  <div className="storyaccordion-right"></div>
                                                    null
                                                      }
                                                   </div>
                                                        <div id={"collapseOne"+suboffering.ProcessID} className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                        <div className="card-body">
                                                    {suboffering.OfferingDemos.filter(f=>{return (this.state.demoSearchText=="" || 
                                                    f.DemoName.toUpperCase().includes( this.state.demoSearchText.toUpperCase())
                                                    )}).map(
                                                      (OfferingDemo)=>(
                                                          <div class="storycontent-row storycontent-data">
                                                            <div class="storycontent-cell offering-cell"></div>
                                                            <div class="storycontent-cell demo-cell">
                                                              <span onClick={this.trackNavigation.bind('', offering.OfferingName, suboffering.Process, 'Demo')} data-tip data-for={"tooltip"+OfferingDemo.StoryID}>
                                                              <a href={OfferingDemo.DemoURL} target="_blank">
                                                                {OfferingDemo.DemoName.length <=25 ? OfferingDemo.DemoName:OfferingDemo.DemoName.slice(0,25)+"..."}
                                                                </a>
                                                              {OfferingDemo.DemoName.length>25 &&
                                                                <ReactTooltip place="bottom" effect="solid" id={"tooltip"+OfferingDemo.StoryID}>
                                                                  <span>{OfferingDemo.DemoName}</span>
                                                                </ReactTooltip>
                                                                }
                                                              </span>
                                                            </div>
                                                            {OfferingDemo.DemoPassword ?
                                                            <div class="storycontent-cell password-cell password-copied" >
                                                              
                                                              <img src={this.state.passwordImg} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={()=>this.passwordcopiedShow(OfferingDemo.DemoPassword)} className="mr-1" />
                                                              <span data-tip data-for={"tooltip"+OfferingDemo.DemoPassword}>
                                                                {OfferingDemo.DemoPassword.length<=15?OfferingDemo.DemoPassword : OfferingDemo.DemoPassword.slice(0,15)+"..."}
                                                              {OfferingDemo.DemoPassword.length>15 &&
                                                                <ReactTooltip place="bottom" effect="solid" id={"tooltip"+OfferingDemo.DemoPassword}>
                                                                <span className="passwordCopy">{OfferingDemo.DemoPassword}</span>
                                                              </ReactTooltip>
                                                                }
                                                                </span>
                                                              </div>
                                                              :
                                                              <div class="storycontent-cell password-cell">
                                                              </div>
                                                              }
                                                            <div class="storycontent-cell userguide-cell text-center">
                                                                <span onClick={this.trackNavigation.bind('', offering.OfferingName, suboffering.Process, 'User Guide')}>
                                                                  {OfferingDemo.ScriptURL?
                                                                  <a href={OfferingDemo.ScriptURL} target="_blank">
                                                                    <img src={GuideGrad} />
                                                                  </a>
                                                                  :
                                                                  <img src={GuideGrey} />
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div class="storycontent-cell videolink-cell text-center">
                                                                <span onClick={this.trackNavigation.bind('', offering.OfferingName, suboffering.Process, 'Video')}>
                                                                  {OfferingDemo.VideoURL?
                                                                  <a href={OfferingDemo.VideoURL} target="_blank">
                                                                    <img src={VideoGrad} />
                                                                  </a>
                                                                  :
                                                                  <img src={VideoGrey} />
                                                                  }
                                                                </span>
                                                                
                                                              </div>
                                                              {this.state.isAdmin == "True" ?
                                                            <div class="storycontent-cell storylinks-cell">
                                                              <span data-tip data-for="adddemo"><img className="greylink-image" src={AddGrey} />
                                                                <ReactTooltip place="bottom" effect="solid" id="adddemo">
                                                                  <span>Add Demo</span>
                                                                </ReactTooltip>
                                                              </span>
                                                              <span data-tip data-for="editdemo" 
                                                                onClick={()=>this.editDemoModalShow(OfferingDemo.StoryID,suboffering.ProcessID,suboffering.Process,offering.OfferingName)}>
                                                                  <img src={EditGrad} />
                                                                <ReactTooltip place="bottom" effect="solid" id="editdemo">
                                                                  <span>Edit Demo</span>
                                                                </ReactTooltip>
                                                              </span>
                                                              <span data-tip data-for="deletedemo" onClick={()=>this.deleteDemoModalShow(OfferingDemo.StoryID,"deleteSingle")}><img src={DeleteGrad} />
                                                                <ReactTooltip place="bottom" effect="solid" id="deletedemo">
                                                                  <span>Delete Demo</span>
                                                                </ReactTooltip>
                                                              </span>
                                                            </div>
                                                            :
                                                            // <div class="storycontent-cell storylinks-cell"></div>   
                                                            null                                                           
                                                              }
                                                          </div>                                                           
                                                     
                                                     )
                                                     )}
                                                   
                                                     </div>
                                                   </div>
                                                  </div>
                                                </div>
                                             </div>
                                             
                                               )
                                        )
                                       )
                                    ):null}
                                    
                                    
                                  </div>
                                </div>
                                }
                                {/* Demo Enhancenemnt HTML End */}
                                {this.state.addContactClicked ? (
                                  <AddContact
                                    onClose={this.onClose}
                                    subOfferingList={ChildOff}
                                    subofferingLeafNodes={ChildOfferings}
                                    addContactStatus={
                                      this.state.addContactClicked
                                    }
                                    editContactStatus={
                                      this.state.editContactClicked
                                    }
                                  />
                                ) : (
                                  this.state.editContactClicked && (
                                    <AddContact
                                      onClose={this.onClose}
                                      addContact={this.addContact}
                                      editContact={this.editContact}
                                      editData={this.state.editData}
                                      subOfferingList={ChildOff}
                                      subofferingLeafNodes={ChildOfferings}
                                      addContactStatus={
                                        this.state.addContactClicked
                                      }
                                      editContactStatus={
                                        this.state.editContactClicked
                                      }
                                    />
                                  )
                                )}
                                  
                                {/* Contact List Screen*/}
                                {this.state.offeringcontactClicked ? (
                                  list === undefined || list.length == 0 ? (
                                    <div className="contact-info">
                                      <span>
                                        <i class="fas fa-comments-alt"></i>
                                      </span>
                                      {this.state.isAdmin == "True" ? (
                                        <p>
                                          Add Contact Information for the Offering{" "}
                                        </p>
                                      ) : (
                                        <p>Contact Information Coming Soon…</p>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="offeringContacts">
                                      {offering.ChildOfferings.map(
                                        (suboffering) =>
                                          suboffering.OfferingContacts.length >
                                          0 && (
                                            <div className="contactsList">
                                              <div className="contactsHead">
                                                <h2>{suboffering.Process}</h2>
                                                {this.state.isAdmin == "True" && (
                                                  <p className="contact-icons">
                                                    <span
                                                      onClick={() =>
                                                        this.editContact(
                                                          suboffering
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faEdit}
                                                      />
                                                    </span>
                                                    <span
                                                      onClick={() =>
                                                        this.deleteContact(
                                                          suboffering
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faTrashAlt}
                                                      />
                                                    </span>
                                                  </p>
                                                )}
                                              </div>
                                              {suboffering.OfferingContacts.map(
                                                (contact) => (
                                                  <>
                                                    <div className="contactsInfo">
                                                      <p className="leadName">
                                                        Offering Lead
                                                      </p>
                                                      {contact.OfferingLeadName && (
                                                        <p className="leadEmail">
                                                          <span>
                                                            {
                                                              contact.OfferingLeadName
                                                            }
                                                          </span>
                                                          <span>
                                                            {" "}
                                                            (
                                                            {
                                                              contact.OfferingLeadEmailId
                                                            }
                                                            )
                                                          </span>
                                                        </p>
                                                      )}
                                                    </div>
                                                    <div className="contactsInfo">
                                                      <p className="leadName">
                                                        CTO
                                                      </p>
                                                      {contact.CTOName && (
                                                        <p className="leadEmail">
                                                          <span>
                                                            {contact.CTOName}
                                                          </span>
                                                          <span>
                                                            {" "}
                                                            ({contact.CTOEmailId})
                                                          </span>
                                                        </p>
                                                      )}
                                                    </div>
                                                    <div className="contactsInfo">
                                                      <p className="leadName">
                                                        SME
                                                      </p>
                                                      {contact.SMEName && (
                                                        <p className="leadEmail">
                                                          <span>
                                                            {contact.SMEName}
                                                          </span>
                                                          <span>
                                                            {" "}
                                                            ({contact.SMEEmailId})
                                                          </span>
                                                        </p>
                                                      )}
                                                    </div>
                                                  </>
                                                )
                                              )}
                                            </div>
                                          )
                                      )}
                                    </div>
                                  )
                                ) : null}
                              </Tab>
                            ))}
                          </Tabs>
                        </div>
                      </Tab>
                    ))}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* AddDemo Modal Start */}
        <Modal
          className="addDemoModal"
          backdrop ="static"
          show={this.state.addDemoStatus || this.state.editDemoStatus}
          onHide={this.addDemoModalClose && this.editDemoModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.addDemoStatus?"Add SynOps Demo":"Edit Synops Demo"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="demo-form">
              <div className="row">
                <div className="col-sm-6 pr-0">
                  <div className="form-section">
                    <div className="form-group border-group disabled">
                      <label htmlFor="Offering" className="form-label">
                        Offering
                      </label>
                      <div className="initiative-dropdown">
                        <Dropdown id="Offering">
                          <Dropdown.Toggle>{this.state.currentOffering}</Dropdown.Toggle>
                                                        
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-section">
                    <div className="form-group border-group disabled">
                      <label htmlFor="SubOffering" className="form-label">
                        Sub Offering
                      </label>
                      <div className="initiative-dropdown">
                        <Dropdown id="SubOffering">
                          <Dropdown.Toggle>{this.state.allSuboffering}</Dropdown.Toggle>
                          
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 pr-0">
                  <div className="form-section">
                    <div className={
                      this.state.inputs.isDemoName 
                      ? "form-group border-group onFocusColor"
                      : "form-group border-group"
                      }
                      >
                      <label htmlFor="DemoName" className="form-label">
                        Demo Name
                      </label>
                      <input
                        id="demoName"
                        type="text"
                        className="form-control"
                        placeholder="Demo Name"
                        maxLength="50"
                        value={this.state.storyData.DemoName}
                        onChange={(e) => this.bindPopupData(e)}
                        onFocus={(e) => this.OnFocusTextarea(e)}
                        onBlur={(e) => this.onOutsideClick(e)}
                      />
                      <span className="errorMsg">{this.state.errors.DemoName}</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-section">
                    <div className={
                      this.state.inputs.isDemoLink
                      ? "form-group border-group linkoverflow-class onFocusColor"
                      : "form-group border-group linkoverflow-class"
                    }>
                      <label htmlFor="DemoLink" className="form-label">
                        Demo Link
                      </label>
                      <input
                        id="demoUrl"
                        type="text"
                        className="form-control"
                        placeholder="Demo Link"
                        maxLength="5000"       
                        value={this.state.storyData.DemoURL}
                        onChange={(e) => this.bindPopupData(e)}     
                        onFocus={(e) => this.OnFocusTextarea(e)}
                        onBlur={(e) => this.onOutsideClick(e)}            
                      />
                      <span className="errorMsg">{this.state.errors.DemoLink}</span>

                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 pr-0">
                  <div className="form-section">
                    <div className={
                      this.state.inputs.isDemoPassword
                      ? "form-group border-group onFocusColor"
                      : "form-group border-group"
                    }>
                      <label htmlFor="DemoPassword" className="form-label">
                        Demo Password
                      </label>
                      <input
                        id="demoPassword"
                        type="text"
                        className="form-control"
                        placeholder="Demo Password"
                        maxLength="50"                     
                        value={this.state.storyData.DemoPassword}
                        onChange={(e) => this.bindPopupData(e)}
                        onFocus={(e) => this.OnFocusTextarea(e)}
                        onBlur={(e) => this.onOutsideClick(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-section">
                    <div className={
                      this.state.inputs.isScriptLink
                      ? "form-group border-group linkoverflow-class onFocusColor"
                      : "form-group border-group linkoverflow-class"
                    }>
                      <label htmlFor="GuideLink" className="form-label">
                        User Guide Link
                      </label>
                      <input
                        id="scriptUrl"
                        type="text"
                        className="form-control"
                        placeholder="User Guide Link"
                        maxLength="5000"
                        value={this.state.storyData.ScriptURL}
                        onChange={(e) => this.bindPopupData(e)}
                        onFocus={(e) => this.OnFocusTextarea(e)}
                        onBlur={(e) => this.onOutsideClick(e)}
                      />
                      <span className="errorMsg">{this.state.errors.ScriptLink}</span>

                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 pr-0">
                  <div className="form-section">
                    <div className={
                      this.state.inputs.isVideoLink
                      ? "form-group border-group linkoverflow-class onFocusColor"
                      : "form-group border-group linkoverflow-class"
                    }>
                      <label htmlFor="VideoLink" className="form-label">
                        Video Link
                      </label>
                      <input
                        id="videoUrl"
                        type="text"
                        className="form-control"
                        placeholder="Video Link"
                        maxLength="5000"
                        value={this.state.storyData.VideoURL}
                        onChange={(e) => this.bindPopupData(e)}
                        onFocus={(e) => this.OnFocusTextarea(e)}
                        onBlur={(e) => this.onOutsideClick(e)}
                      />
                      <span className="errorMsg">{this.state.errors.VideoLink}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="modal-btn">
            <Button variant="default mr-2" onClick={this.state.addDemoStatus?this.addDemoModalClose:this.editDemoModalClose}>
              Cancel
            </Button>
            {this.state.storyData.DemoName && this.state.storyData.DemoURL? 
            <Button variant="primary" onClick={this.validateData}>
              {this.state.addEditID == 0 ? "Create":"Update"}
              </Button>
              :
              <Button variant="primary" disabled>
              {this.state.addEditID == 0 ? "Create":"Update"}
              
             
            </Button>
            }
          </Modal.Footer>
        </Modal>
        {/* AddDemo Modal End */}
        {/* DeleteDemo Modal Start */}
        <Modal
          className="addDemoModal"
          show={this.state.deleteDemoStatus}
          onHide={this.deleteDemoModalClose}
        >
          <Modal.Header>
            <Modal.Title>Delete Demo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="deletenotification-body mb-4">
              <div className="deletenotification-icon">
                <span>
                  <img src={DeleteIcon} />
                </span>
              </div>
              <div className="deletenotification-data">
                {this.state.allSuboffering ?
                <>
                <p>
                  You are about to delete all demo links for {this.state.allSuboffering}.
                  </p>
                  <p>
                  Are you sure you want to delete it?
                </p>
                </>
                :
                <p>
                Are you sure you want to delete demo link?
                </p>
                }
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-btn">
            <Button variant="default mr-2" onClick={this.deleteDemoModalClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.state.deleteAllId==0?this.deleteOffering:this.deleteAllOfferings}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* DeleteDemo Modal End */}
          {/* Password Copy Notification */}
         {this.state.passwordcopiedStatus && 
           <div className="passwordcopy-notification">
             <img src={Success}/>
             <h6>Password copied to clipboard</h6>
           </div>
  }
         
        {/* Password Copy Notification */}
      </>
    );
  }
}

//export default StoryView;
const mapStateToProps = state => {
  return {
    languageData: state.CurrentFilter.languageData,
  };
};
export default connect(mapStateToProps)(StoryView);
