import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import _ from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import { Button, Overlay, Popover } from "react-bootstrap";
import $ from "jquery";

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import { BATSetClientSimulationFlowInitiatedFromScreen } from "../actions/BATActions";
import {
  ROUTE_CONSTANTS,
  formatDate,
  BATAllowedMinDateMoment,
  BATDefaultStartDateMoment,
  BATDefaultEndDateMoment,
  BATDateFormat,
} from "../Shared/Constant";

import AssessmentSelectionModal from "./AssessmentSelectionModal";
import ConsolidatedViewModal from "./ConsolidatedViewModal";
import BenchmarkSelectionModal from "./BenchmarkSelectionModal";

// IMPORT STYLES/IMAGES
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";

class BatCommonHeader extends Component {
  constructor(props) {
    super(props);

    let startDateMoment = BATDefaultStartDateMoment;
    if (!_.isEmpty(_.get(props, "startDate", ""))) {
      startDateMoment = moment(props.startDate, BATDateFormat);
    }
    let endDateMoment = BATDefaultEndDateMoment;
    if (!_.isEmpty(_.get(props, "endDate", ""))) {
      endDateMoment = moment(props.endDate, BATDateFormat);
    }

    this.state = {
      clientDetails: {
        name: _.get(props, "clientName", ""),
        industry: _.get(props, "clientIndustry", ""),
        marketUnit: _.get(props, "clientMarketUnit", ""),
      },
      offeringDetails: {
        name: _.get(props, "offeringName", ""),
        type: _.get(props, "offeringType", ""),
      },
      subOfferingDetails: {
        id: _.get(props, "subOfferingId", ""),
        name: _.get(props, "subOfferingName", ""),
        isSubOfferingTVEEnabled: _.get(props, "isSubOfferingTVEEnabled", false),
      },
      renderedFromScreen: _.get(props, "renderedFromScreen", ""),
      BATAssessSubOfferingID: _.get(props, "BATAssessSubOfferingID", ""),
      BATAssessClientID: _.get(props, "BATAssessClientID", ""),
      clientCount: _.get(props, "clientCount", ""),
      ClientLogo: _.get(props, "ClientLogo", ""),
      isVisibleDatepickerDropdownPopover: false,
      showOfferingSubofferingModal: false,
      showConsolidatedModal: false,
      startDate: startDateMoment.toDate(),
      endDate: BATDefaultEndDateMoment.toDate(),
      formattedStartDate: formatDate(startDateMoment),
      formattedEndDate: formatDate(endDateMoment),
      tempPreviousFormattedStartDate: formatDate(startDateMoment),
      tempPreviousFormattedEndDate: formatDate(endDateMoment),
      isStartEndDateSelected: true,
      tempStartDate: startDateMoment.toDate(),
      tempEndDate: endDateMoment.toDate(),
      formattedTempStartDate: formatDate(startDateMoment),
      formattedTempEndDate: formatDate(endDateMoment),
      headertoParentStateObj: [],
      isVisibleBenchmarkSelectionModal: false,
    };
    this.startDatepickerRef = React.createRef();
    this.endDatepickerRef = React.createRef();
    this.datepickerDropdownPopoverTarget = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { offeringName, offeringType, subOfferingId, subOfferingName } = this.props;

    if (prevProps.offeringName !== offeringName) {
      this.setState({
        offeringDetails: {
          name: offeringName,
          type: offeringType,
        },
      });
    }

    if (prevProps.subOfferingName !== subOfferingName) {
      this.setState({
        subOfferingDetails: {
          id: subOfferingId,
          name: subOfferingName,
        },
      });
    }
  }
  componentDidMount() {
    let this_ = this;
    $(window).on("scroll", function() {
      this_.setState({
        isVisibleDatepickerDropdownPopover: false,
      });
    });
  }
  hideDatepickerDropdownPopover = () => {
    this.setState({
      isVisibleDatepickerDropdownPopover: false,
    });
  };
  toggleDatepickerDropdownPopover = () => {
    const { isVisibleDatepickerDropdownPopover } = this.state;
    this.setState({
      isVisibleDatepickerDropdownPopover: !isVisibleDatepickerDropdownPopover,
    });
  };

  getOfferingSubofferingModal = () => {
    let { clientDetails } = this.state;
    this.setState({
      showOfferingSubofferingModal: clientDetails.name ? true : false,
    });
    let isVisibleSubOfferingSelectionModal = clientDetails.name ? false : true;
    if (isVisibleSubOfferingSelectionModal) {
      this.props.callbackHeaderStateChange(isVisibleSubOfferingSelectionModal);
    }
  };
  closeOfferingSubofferingModal = () => {
    this.setState({
      showOfferingSubofferingModal: false,
    });
  };

  handleClientVsConsolidatedDropdownShow = () => {
    this.setState({
      showConsolidatedModal: true,
    });
  };

  handleClientSubSubmit = (subofferingsData) => {
    const { consolidatedOffSuboffSelection } = this.props;
    consolidatedOffSuboffSelection(subofferingsData);
  };

  // EVENT HANDLER FOR CALENDAR ICON IN DATEPICKER FIELDS, OPENS UP THE PICKER WHEN CLICKED
  handleClickDatepickerIcon = (fieldName) => {
    const datepickerElement =
      fieldName === "startDate" ? this.startDatepickerRef.current : this.endDatepickerRef.current;
    datepickerElement.setFocus(true);
  };
  handleUpdateDateClickField = (tempStart, tempEnd) => {
    const formattedStartDate = !_.isNil(tempStart) ? formatDate(moment(tempStart)) : "";
    const formattedEndDate = !_.isNil(tempEnd) ? formatDate(moment(tempEnd)) : "";

    this.setState({
      startDate: tempStart,
      formattedStartDate: formattedStartDate,
      endDate: tempEnd,
      formattedEndDate: formattedEndDate,
      isVisibleDatepickerDropdownPopover: false,
    });
    const { tempPreviousFormattedStartDate, tempPreviousFormattedEndDate } = this.state;

    //IF EITHER THE START DATE OR END DATE HAS CHANGED FROM THE PREVIOUS VALUES OR THEIR VALUES ARE EMPTY THEN VALIDATE THE VALUES AND IF VALUES ARE FILLED THEN ONLY EXECUTE THE API CALL
    if (
      formattedStartDate !== tempPreviousFormattedStartDate ||
      formattedEndDate !== tempPreviousFormattedEndDate ||
      _.isEmpty(formattedStartDate) ||
      _.isEmpty(formattedEndDate)
    ) {
      this.setState(
        {
          tempPreviousFormattedStartDate: formattedStartDate,
          tempPreviousFormattedEndDate: formattedEndDate,
        },
        () => {
          setTimeout(() => {
            let errMsg = "";
            if (_.isEmpty(formattedStartDate) && _.isEmpty(formattedEndDate)) {
              errMsg = 'Both "Start Month" and "End Month" should be filled to retrieve the results';
            } else if (_.isEmpty(formattedStartDate) && !_.isEmpty(formattedEndDate)) {
              errMsg = '"Start Month" should be filled to retrieve the results';
            } else if (!_.isEmpty(formattedStartDate) && _.isEmpty(formattedEndDate)) {
              errMsg = '"End Month" should be filled to retrieve the results';
            }

            if (!_.isEmpty(errMsg)) {
              window.alert(errMsg);
            } else {
              this.setState(
                {
                  dataToSend: {
                    formattedStartDate: formattedStartDate,
                    formattedEndDate: formattedEndDate,
                  },
                },
                () => {
                  this.props.callbackHeaderStateChange(this.state.dataToSend);
                }
              );
            }
          }, 50);
        }
      );
    }
  };

  handleChangeDateField = (date, isStartEndDateSelected) => {
    this.setState({
      [isStartEndDateSelected ? "tempStartDate" : "tempEndDate"]: date,
      [isStartEndDateSelected ? "formattedTempStartDate" : "formattedTempEndDate"]: formatDate(moment(date)),
    });
  };
  handleClearDate = () => {
    let { startDate, endDate, formattedEndDate, formattedStartDate } = this.state;
    this.setState({
      tempStartDate: startDate,
      tempEndDate: endDate,
      formattedTempStartDate: formattedStartDate,
      formattedTempEndDate: formattedEndDate,
    });
  };
  // HIDES THE "DATE SELECTION DROPDOWN" WHEN CLICKED OUTSIDE THE POPOVER
  hideDatepickerDropdownPopover = () => {
    this.setState({
      isVisibleDatepickerDropdownPopover: false,
    });
  };
  toggleDatepickerDropdownPopover = () => {
    const { isVisibleDatepickerDropdownPopover, startDate, endDate, formattedStartDate, formattedEndDate } = this.state;
    this.setState(
      {
        isVisibleDatepickerDropdownPopover: !isVisibleDatepickerDropdownPopover,
        isStartEndDateSelected: true,
      },
      () => {
        if (!isVisibleDatepickerDropdownPopover) {
          this.setState({
            tempStartDate: startDate,
            tempEndDate: endDate,
            formattedTempStartDate: formattedStartDate,
            formattedTempEndDate: formattedEndDate,
          });
        }
      }
    );
  };
  // SELECTS "START DATE" OR "END DATE"
  selectStartDate = () => {
    this.setState({
      isStartEndDateSelected: true,
    });
  };
  selectEndDate = () => {
    this.setState({
      isStartEndDateSelected: false,
    });
  };

  handleCallbackHeaderStateChange = (headerStateObj) => {
    let { formattedEndDate, formattedStartDate, clientCount } = this.state;
    let checkForFlag = Object.keys(headerStateObj).length;
    if (checkForFlag == 1) {
      this.setState({
        showOfferingSubofferingModal: false,
        showConsolidatedModal: false,
      });
    } else {
      this.setState(
        {
          showOfferingSubofferingModal: false,
          showConsolidatedModal: false,
          ClientLogo: headerStateObj.clientDetails
            ? headerStateObj.clientDetails.logo
              ? headerStateObj.clientDetails.logo
              : null
            : null,
          headertoParentStateObj: {
            offerings: headerStateObj.offerings ? headerStateObj.offerings : [],
            functionalOfferings: headerStateObj.functionalOfferings ? headerStateObj.functionalOfferings : [],
            industryOfferings: headerStateObj.industryOfferings ? headerStateObj.industryOfferings : [],
            offering: headerStateObj.offeringDetails ? headerStateObj.offeringDetails : [],
            subOffering: headerStateObj.subOfferingDetails ? headerStateObj.subOfferingDetails : [],
            BATAssessSubOfferingID: headerStateObj.BATAssessSubOfferingID ? headerStateObj.BATAssessSubOfferingID : "",
            clientDetails: headerStateObj.clientDetails ? headerStateObj.clientDetails : {},
            ClientLogo: headerStateObj.clientDetails
              ? headerStateObj.clientDetails.logo
                ? headerStateObj.clientDetails.logo
                : null
              : null,
            formattedEndDate: formattedEndDate,
            formattedStartDate: formattedStartDate,
            clientCount: clientCount,
            subOfferingDataCollectionPercentage: headerStateObj.CollectionStatus ? headerStateObj.CollectionStatus : 0,
          },
        },
        () => {
          this.redirectScreen();
        }
      );
    }
  };
  redirectScreen = () => {
    let { history, renderedFromScreen, renderedFromScreenData, callbackHeaderStateChange } = this.props;
    let redirectLocationData = {};
    let redirectScreenDatatest = this.state.headertoParentStateObj;
    const clientName = redirectScreenDatatest.clientDetails ? redirectScreenDatatest.clientDetails.name : "";
    const BATAssessClientID = redirectScreenDatatest.clientDetails ? redirectScreenDatatest.clientDetails.id : "";
    const clientIndustry = redirectScreenDatatest.clientDetails
      ? redirectScreenDatatest.clientDetails.BATIndustryName
      : "";
    const clientMarketUnit = redirectScreenDatatest.clientDetails
      ? redirectScreenDatatest.clientDetails.BATMarketUnitName
      : "";
    const offeringName = redirectScreenDatatest.offering.name;
    const offeringType = redirectScreenDatatest.offering.type;
    const offeringId = redirectScreenDatatest.offering.id;
    const subOfferingId = redirectScreenDatatest.subOffering.id;
    const subOfferingName = redirectScreenDatatest.subOffering.name;
    const isSubOfferingTVEEnabled = redirectScreenDatatest.subOffering.isSubOfferingTVEEnabled;
    const BATAssessSubOfferingID = redirectScreenDatatest.BATAssessSubOfferingID;
    const startDate = redirectScreenDatatest.formattedStartDate;
    const endDate = redirectScreenDatatest.formattedEndDate;
    const clientCount = redirectScreenDatatest.clientCount;
    const ClientLogo = redirectScreenDatatest.ClientLogo ? redirectScreenDatatest.ClientLogo : null;
    const offerings = redirectScreenDatatest.offerings ? redirectScreenDatatest.offerings : [];
    const functionalOfferings = redirectScreenDatatest.functionalOfferings
      ? redirectScreenDatatest.functionalOfferings
      : [];
    const industryOfferings = redirectScreenDatatest.industryOfferings ? redirectScreenDatatest.industryOfferings : [];

    let redirectScreenData = {
      isSubOfferingTVEEnabled,
      ClientLogo,
      offerings,
      functionalOfferings,
      industryOfferings,
      offeringId,
      clientName,
      BATAssessClientID,
      clientIndustry,
      clientMarketUnit,
      offeringName,
      offeringType,
      subOfferingId,
      subOfferingName,
      BATAssessSubOfferingID,
      startDate,
      endDate,
      clientCount,
    };
    let { subOfferingDataCollectionPercentage } = redirectScreenDatatest;
    if (renderedFromScreen === "BatView") {
      BATSetClientSimulationFlowInitiatedFromScreen("BATOfferingDetailsScreen", renderedFromScreenData);

      if (redirectScreenData.clientName) {
        if (subOfferingDataCollectionPercentage === 0) {
          redirectLocationData = {
            pathname: `/${ROUTE_CONSTANTS.BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT}`,
            state: { assessmentMetricDetailsScreenData: redirectScreenData },
          };
          history.push(redirectLocationData);
        } else if (subOfferingDataCollectionPercentage !== 0) {
          redirectLocationData = {
            pathname: `/${ROUTE_CONSTANTS.BAT_CLIENT_PERFORMANCE_VIEW}`,
            state: { clientPerformanceViewScreenData: redirectScreenData },
          };
          history.push(redirectLocationData);
        }
      } else {
        callbackHeaderStateChange(redirectScreenData);
      }
    } else if (renderedFromScreen === "ClientPerformanceView") {
      if (redirectScreenData.clientName) {
        if (subOfferingDataCollectionPercentage !== 0) {
          callbackHeaderStateChange(redirectScreenData);
        } else if (subOfferingDataCollectionPercentage === 0) {
          redirectLocationData = {
            pathname: `/${ROUTE_CONSTANTS.BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT}`,
            state: { assessmentMetricDetailsScreenData: redirectScreenData },
          };
          history.push(redirectLocationData);
        }
      } else {
        redirectLocationData = {
          pathname: `/${ROUTE_CONSTANTS.BAT_OFFERING_BENCHMARK_DETAILS}`,
          state: { batViewScreenData: redirectScreenData },
        };
        history.push(redirectLocationData);
      }
    } else if (renderedFromScreen === "ProvideMetricDetails") {
      if (redirectScreenData.clientName) {
        if (subOfferingDataCollectionPercentage !== 0) {
          redirectLocationData = {
            pathname: `/${ROUTE_CONSTANTS.BAT_CLIENT_PERFORMANCE_VIEW}`,
            state: { clientPerformanceViewScreenData: redirectScreenData },
          };
          history.push(redirectLocationData);
        } else if (subOfferingDataCollectionPercentage === 0) {
          callbackHeaderStateChange(redirectScreenData);
        }
      } else {
        redirectLocationData = {
          pathname: `/${ROUTE_CONSTANTS.BAT_OFFERING_BENCHMARK_DETAILS}`,
          state: { batViewScreenData: redirectScreenData },
        };
        history.push(redirectLocationData);
      }
    }
  };

  handleAssessmentOptionClick = (selectedScreenName) => {
    const {
      history,
      renderedFromScreen,
      BATSetClientSimulationFlowInitiatedFromScreen,
      renderedFromScreenData,
    } = this.props;
    const { clientCount } = this.state;

    if (renderedFromScreen === "BatView") {
      BATSetClientSimulationFlowInitiatedFromScreen("BATOfferingDetailsScreen", renderedFromScreenData);
    }

    const flowInitiatedScreen = "BatCommonHeader";
    let location = {};
    if (selectedScreenName === "takeAssessment") {
      location = {
        pathname: `/${ROUTE_CONSTANTS.BAT_TAKE_ASSESSMENT}`,
        state: { renderedFromScreenData, flowInitiatedScreen, clientCount },
      };
    } else if (selectedScreenName === "viewAssessment") {
      const viewAssessmentScreenData = {
        flowInitiatedScreen,
        clientCount,
      };
      location = {
        pathname: `/${ROUTE_CONSTANTS.BAT_VIEW_ASSESSMENT}`,
        state: { renderedFromScreenData, viewAssessmentScreenData },
      };
    }

    history.push(location);
  };

  handleChangeContractPeriodDropdown = (selectedValue) => {
    const { onChangeContractPeriod } = this.props;
    onChangeContractPeriod(selectedValue);
  };

  showBenchmarkSelectionModal = () => {
    this.setState({
      isVisibleBenchmarkSelectionModal: true,
    });
  };

  hideBenchmarkSelectionModal = (dataObj) => {
    let { selectedIndustryId, selectedRegionId, selectedBenchmark, onBenchmarkSelectionChange } = this.props;
    const selectedIndustryIdFromModal = _.get(dataObj, "selectedIndustryId", "");
    const selectedRegionIdFromModal = _.get(dataObj, "selectedRegionId", "");
    const selectedBenchmarkFromModal = _.get(dataObj, "selectedBenchmark", "");

    let executeAPIWithChangedSelection = false;
    // IF THERE WAS ANY CHANGE MADE IN THE SELECTION
    if (
      !_.isEmpty(dataObj) &&
      ((!_.isEmpty(String(selectedIndustryIdFromModal)) &&
        String(selectedIndustryId) !== String(selectedIndustryIdFromModal)) ||
        (!_.isEmpty(String(selectedRegionIdFromModal)) &&
          String(selectedRegionId) !== String(selectedRegionIdFromModal)) ||
        (!_.isEmpty(String(selectedBenchmarkFromModal)) &&
          String(selectedBenchmark) !== String(selectedBenchmarkFromModal)))
    ) {
      executeAPIWithChangedSelection = true;
      selectedIndustryId = selectedIndustryIdFromModal;
      selectedRegionId = selectedRegionIdFromModal;
      selectedBenchmark = selectedBenchmarkFromModal;
    }

    this.setState(
      {
        isVisibleBenchmarkSelectionModal: false,
      },
      () => {
        if (executeAPIWithChangedSelection) {
          onBenchmarkSelectionChange({
            selectedIndustryId,
            selectedRegionId,
            selectedBenchmark,
          });
        }
      }
    );
  };

  render() {
    const {
      contractPeriod,
      selectedIndustryId,
      selectedRegionId,
      selectedBenchmark,
      isSubOfferingTVEEnabled,
      sharedUserDetails = [],
      createdBy,
    } = this.props;
    const {
      isVisibleDatepickerDropdownPopover,
      startDate,
      endDate,
      isStartEndDateSelected,
      tempStartDate,
      tempEndDate,
      formattedStartDate,
      formattedTempStartDate,
      formattedTempEndDate,
      formattedEndDate,
      BATAssessClientID,
      BATAssessSubOfferingID,
      clientDetails,
      offeringDetails,
      subOfferingDetails,
      renderedFromScreen,
      clientCount,
      isVisibleBenchmarkSelectionModal,
      showOfferingSubofferingModal,
      showConsolidatedModal,
    } = this.state;
    let showName = this.props.clientName ? this.props.clientName : "Consolidated Analysis";
    const filteredSharedUser = sharedUserDetails.filter((user) => user.UserName === sessionStorage.getItem("EnterpriseID"))
    const isMyAssessment = createdBy === sessionStorage.getItem("EnterpriseID");
    const isFromBatView = this.props.renderedFromScreen ==="BatView";
    const isNewAssessment = this.props.renderedFromScreen === "ProvideMetricDetails";
    const isFromClientPerformanceView = this.props.renderedFromScreen ==="ClientPerformanceView";
    const isFromPreviousScreen = (isFromClientPerformanceView || isFromBatView);
    return (
      <>
        <div className="BatCommonHeader">
          <div className="analyticsFilter bat-offering-details-screen subnav-header" id="subnav-header-id">
            <div className="row analyticsFilterHeader">
              <div className=" newbatOffering d-flex align-items-center">
                <Link to={`/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`} className="backPush subnavheader-back">
                  Back
                </Link>
                <div className="divider"></div>
                <div className="batOfferingOptions">
                  <span className="batOfferingOptions-span" onClick={this.handleClientVsConsolidatedDropdownShow}>
                    <span className="no-border">{showName}</span>
                  </span>
                </div>
                <div className="divider"></div>       
                {filteredSharedUser.length > 0 || isMyAssessment || isFromPreviousScreen || isNewAssessment? (
                  <div className="batOfferingOptions">
                    <span
                      className="batOfferingOptions-span"
                      onClick={() => {
                        this.getOfferingSubofferingModal();
                      }}
                    >
                      {offeringDetails.name} / {subOfferingDetails.name}
                    </span>
                  </div>
                ) : null}
                <div className="divider"></div>
                {/* New Datepicker Design */}

                <div
                  className={
                    sharedUserDetails.filter(
                      (each) => each.UserName === sessionStorage.getItem("EnterpriseID") && each.AccessLevelID === 1
                    ).length
                      ? "chooseOption calenderChooseOption disabled "
                      : "chooseOption calenderChooseOption "
                  }
                >
                  <Overlay
                    target={this.datepickerDropdownPopoverTarget.current}
                    show={isVisibleDatepickerDropdownPopover}
                    placement="bottom"
                    rootClose
                    rootCloseEvent="click"
                    onHide={this.hideDatepickerDropdownPopover}
                  >
                    <Popover id="popover-basic4" className="calenderPopover">
                      <Popover.Content>
                        <div className="popContent">
                          <div className="popContentDisplay">
                            <div
                              className={isStartEndDateSelected ? "calenderPopoverSpanActive" : "calenderPopoverSpan"}
                              target={this.startDatepickerRef}
                              disabled={
                                sharedUserDetails.filter(
                                  (each) =>
                                    each.UserName === sessionStorage.getItem("EnterpriseID") && each.AccessLevelID === 1
                                ).length
                                  ? true
                                  : false
                              }
                              onClick={this.selectStartDate}
                            >
                              <div className="CalenderDatepickerLabel">Start Date</div>
                              <div className="CalenderDatepickerDate">{formattedTempStartDate}</div>
                            </div>
                            <div
                              className={isStartEndDateSelected ? "calenderPopoverSpan" : "calenderPopoverSpanActive"}
                              target={this.endDatepickerRef}
                              disabled={
                                sharedUserDetails.filter(
                                  (each) =>
                                    each.UserName === sessionStorage.getItem("EnterpriseID") && each.AccessLevelID === 1
                                ).length
                                  ? true
                                  : false
                              }
                              onClick={this.selectEndDate}
                            >
                              <div className="CalenderDatepickerLabel">End Date</div>
                              <div className="CalenderDatepickerDate">{formattedTempEndDate}</div>
                            </div>
                          </div>
                          {isStartEndDateSelected ? (
                            <div>
                              <DatePicker
                                selected={tempStartDate ? tempStartDate : startDate}
                                disabled={
                                  sharedUserDetails.filter(
                                    (each) =>
                                      each.UserName === sessionStorage.getItem("EnterpriseID") &&
                                      each.AccessLevelID === 1
                                  ).length
                                    ? true
                                    : false
                                }
                                onChange={(date) => this.handleChangeDateField(date, isStartEndDateSelected)}
                                dateFormat="MMM yyyy"
                                showMonthYearPicker
                                strictParsing
                                inline
                                className="calenderDatepicker"
                                minDate={BATAllowedMinDateMoment.toDate()}
                                maxDate={
                                  !_.isNil(tempEndDate ? tempEndDate : endDate)
                                    ? moment(tempEndDate ? tempEndDate : endDate).toDate()
                                    : moment(new Date()).toDate()
                                }
                                placeholderText="Start Month"
                                ref={this.startDatepickerRef}
                              />
                            </div>
                          ) : (
                            <DatePicker
                              selected={tempEndDate ? tempEndDate : endDate}
                              disabled={
                                sharedUserDetails.filter(
                                  (each) =>
                                    each.UserName === sessionStorage.getItem("EnterpriseID") && each.AccessLevelID === 1
                                ).length
                                  ? true
                                  : false
                              }
                              onChange={(date) => this.handleChangeDateField(date, isStartEndDateSelected)}
                              dateFormat="MMM yyyy"
                              showMonthYearPicker
                              strictParsing
                              inline
                              className="calenderDatepicker"
                              minDate={
                                !_.isNil(tempStartDate ? tempStartDate : startDate)
                                  ? moment(tempStartDate ? tempStartDate : startDate).toDate()
                                  : null
                              }
                              maxDate={new Date()}
                              placeholderText="End Month"
                              ref={this.endDatepickerRef}
                            />
                          )}
                        </div>
                      </Popover.Content>
                      <div className="popover-footer">
                        <div className="modal-btn temp">
                          <Button
                            variant="default"
                            className="btn-sm mr-auto"
                            onClick={() => {
                              this.handleClearDate();
                            }}
                          >
                            Clear
                          </Button>
                          <Button
                            variant="primary"
                            className="btn-sm"
                            onClick={() => this.handleUpdateDateClickField(tempStartDate, tempEndDate)}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </Popover>
                  </Overlay>
                  <div
                    className={
                      sharedUserDetails.filter(
                        (each) => each.UserName === sessionStorage.getItem("EnterpriseID") && each.AccessLevelID === 1
                      ).length
                        ? "calenderSpan disabled "
                        : "calenderSpan"
                    }
                  >
                    <span
                      className={isVisibleDatepickerDropdownPopover ? "active" : ""}
                      ref={this.datepickerDropdownPopoverTarget}
                      onClick={this.toggleDatepickerDropdownPopover}
                    >
                      {formattedStartDate === formattedEndDate
                        ? formattedStartDate
                        : formattedStartDate + " - " + formattedEndDate}
                    </span>
                  </div>
                </div>
                {/* NEW DATEPICKER DESIGN END */}

                {/* START - "CONTRACT PERIOD" DROPDOWN WHICH WOULD BE VISIBLE WHEN THE HEADER IS RENDERED FROM "PROVIDE METRIC DETAILS" SCREEN IN TVE FLOW */}
                {isSubOfferingTVEEnabled && renderedFromScreen === "ProvideMetricDetails" && (
                  <div className="contract-dropdown-section">
                    <Dropdown onSelect={(selectedOption) => this.handleChangeContractPeriodDropdown(selectedOption)}>
                      <Dropdown.Toggle id="contract-dropdown">
                        Contract -{" "}
                        {_.toNumber(contractPeriod) === 1 ? `${contractPeriod} year` : `${contractPeriod} years`}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {_.times(5, (index) => {
                          const optionValue = index + 1;
                          return (
                            <Dropdown.Item
                              key={optionValue}
                              eventKey={optionValue}
                              active={_.toNumber(contractPeriod) === optionValue}
                            >
                              {optionValue === 1 ? `${optionValue} year` : `${optionValue} years`}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
                {/* END - "CONTRACT PERIOD" DROPDOWN */}

                {/* START - "BENCHMARK SELECTION" CLICKING ON WHICH WOULD DISPLAY MODAL FOR BENCHMARK SELECTION. THIS WOULD ONLY VISIBLE WHEN THE HEADER IS RENDERED FROM "CLIENT PERFORMANCE VIEW" SCREEN IN TVE FLOW */}
                {isSubOfferingTVEEnabled && renderedFromScreen === "ClientPerformanceView" && (
                  <div
                    className={
                      sharedUserDetails.filter(
                        (each) => each.UserName === sessionStorage.getItem("EnterpriseID") && each.AccessLevelID === 1
                      ).length
                        ? "quartileSpan disabled"
                        : "quartileSpan"
                    }
                  >
                    <span
                      className={
                        sharedUserDetails.filter(
                          (each) => each.UserName === sessionStorage.getItem("EnterpriseID") && each.AccessLevelID === 1
                        ).length
                          ? "optionSpan disabled"
                          : "optionSpan"
                      }
                      disabled={
                        sharedUserDetails.filter(
                          (each) => each.UserName === sessionStorage.getItem("EnterpriseID") && each.AccessLevelID === 1
                        ).length
                          ? true
                          : false
                      }
                      onClick={() => this.showBenchmarkSelectionModal()}
                    >
                      {_.toLower(selectedBenchmark) === "lower quartile" ? "Bottom Quartile" : selectedBenchmark}{" "}
                      Benchmark
                      <i className="fa fa-filter" aria-hidden="true" />
                    </span>
                  </div>
                )}
                {/* END - "BENCHMARK SELECTION" */}
              </div>

              {renderedFromScreen === "BatView" && (
                <div className="simulationView assessment-dropdown-btn">
                  <Dropdown>
                    <Dropdown.Toggle id="assessment-dropdown">Assessment</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        eventKey="takeAssessment"
                        onSelect={() => this.handleAssessmentOptionClick("takeAssessment")}
                      >
                        <i className="fal fa-plus-circle"></i>Take Assessment
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="viewAssessment"
                        onSelect={() => this.handleAssessmentOptionClick("viewAssessment")}
                      >
                        <i className="fal fa-list-ul"></i>View Assessment
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}

              {showOfferingSubofferingModal && (
                <AssessmentSelectionModal
                  clientCount={clientCount}
                  BATAssessClientID={BATAssessClientID}
                  clientName={clientDetails.name}
                  BATAssessSubOfferingID={BATAssessSubOfferingID}
                  offeringName={offeringDetails.name}
                  callbackHeaderStateChange={(headerStateObj) => this.handleCallbackHeaderStateChange(headerStateObj)}
                />
              )}

              {showConsolidatedModal && (
                <ConsolidatedViewModal
                  showConsolidatedModal={showConsolidatedModal}
                  clientCount={clientCount}
                  selectedBATAssessClientID={this.props.BATAssessClientID}
                  selectedOfferingName={offeringDetails.name}
                  selectedSubOfferingName={subOfferingDetails.name}
                  callbackHeaderStateChange={(headerStateObj) => this.handleCallbackHeaderStateChange(headerStateObj)}
                />
              )}
            </div>
          </div>
        </div>

        {isVisibleBenchmarkSelectionModal && (
          <BenchmarkSelectionModal
            onClose={(dataObj) => this.hideBenchmarkSelectionModal(dataObj)}
            offeringDetails={offeringDetails}
            subOfferingDetails={subOfferingDetails}
            industryId={selectedIndustryId}
            regionId={selectedRegionId}
            benchmark={selectedBenchmark}
            startDate={formattedStartDate}
            endDate={formattedEndDate}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    BATSetClientSimulationFlowInitiatedFromScreen: (screenName, screenData) =>
      dispatch(BATSetClientSimulationFlowInitiatedFromScreen(screenName, screenData)),
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(BatCommonHeader)
);
