import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

function CustomTooltipForAgGrid(props) {
  const { type, message } = props;

  let returnContent = null;

  if (_.isEmpty(type)) {
    returnContent = null;
  } else if (type === "content") {
    returnContent = <div className="ag-grid-custom-tooltip-no-error">{message}</div>;
  } else if (type === "error") {
    returnContent = <div className="ag-grid-custom-tooltip">{message}</div>;
  }

  return returnContent;
}

CustomTooltipForAgGrid.defaultProps = {
  type: "",
  message: "",
};

CustomTooltipForAgGrid.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

export default CustomTooltipForAgGrid;
