import {
    LOGIN_USER_DATA_INITIALIZE
}from "../actionTypes/LoginAction";

let initialUserData={};

 let userLoginDataReducer=(state=initialUserData,action)=>{
    switch(action.type){
        case LOGIN_USER_DATA_INITIALIZE:{
            state={...state};
            state={...action.payload}
            return state;
        }
        default:
            return state;
    }
}
export default userLoginDataReducer;