import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faUser,
  faPaperPlane,
  faEdit,
  faTrashAlt,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import SynopsLoader from "../Shared/SynopsLoader";
import _ from "lodash";
import i18n from "i18next";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { regexForFields, LocalApiBaseUrl } from "../Shared/Constant";
import { FormatDateWithoutUTC } from "../Shared/commonFunctions";
import LoadingOverlay from "react-loading-overlay";

class CommentsPopOver extends Component {
  constructor() {
    super();
    this.state = {
      MetricName: "",
      CommentaryID: 0,
      KPIMappingID: "",
      displayAddComments: true,
      apiCallStatus: false,
      commentsData: [],
      editData: {},
      filteredCommentsData: [],
      Comment: "",
      sortby: false,
      CommentaryFeatures: [],
    };
    this.messagesEndRef = React.createRef();
  }
  onHistoryClick = () => {
    this.props.morcommentHistoryShow(this.state.commentsData);
  };
  closeChat = () => {
    this.props.morcommentwindowClose();
  };
  showEdit = (data) => {
    this.setState({
      displayAddComments: false,
      editData: data,
      Comment: data.CommentText,
      CommentaryID: data.MORCommentID,
    });
  };
  hideEdit = () => {
    this.setState({ displayAddComments: true, Comment: "", CommentaryID: 0 });
  };

  sortChangeEvent = () => {
    let { sortby, filteredCommentsData } = this.state;
    this.setState({ sortby: !sortby, filteredCommentsData: this.sortBy(filteredCommentsData, !sortby) });
  };

  sortBy = (commentsData, sortby) => {
    let filteredCommentsData = [...commentsData];
    if (!sortby) {
      filteredCommentsData.sort(function(a, b) {
        return new Date(a.UpdatedTS) > new Date(b.UpdatedTS)
          ? 1
          : new Date(b.UpdatedTS) > new Date(a.UpdatedTS)
          ? -1
          : 0;
      });
    } else {
      filteredCommentsData.sort(function(a, b) {
        return new Date(a.UpdatedTS) < new Date(b.UpdatedTS)
          ? 1
          : new Date(b.UpdatedTS) < new Date(a.UpdatedTS)
          ? -1
          : 0;
      });
    }
    return filteredCommentsData;
  };
  onCommentChange = (e) => {
    if (e.target.value) {
      if (regexForFields.test(e.target.value)) {
        this.setState({ Comment: e.target.value });
      }
    } else {
      this.setState({ Comment: e.target.value });
    }
  };

  addComment = () => {
    const { filterProps,addEditCommentsProp } = this.props;
    const { Comment } = this.state;

    if (Comment.trim()) {
      let body = {
        ...addEditCommentsProp,
        MORCommentID: 0,
        CommentText: Comment,
      };
      this.setState({ apiCallStatus: true });

        axiosInstance.post(`${LocalApiBaseUrl}MOR/AddNewMORComments`, body).then((response) => {
        this.setState({ apiCallStatus: false, Comment: "", CommentaryID: 0 });

        this.generateMORComments();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    }
  };
  editComment = () => {
    const { filterProps,addEditCommentsProp } = this.props;
    const { Comment, CommentaryID } = this.state;
    if (this.state.Comment.trim()) {
      let body = {
        ...addEditCommentsProp,
        MORCommentID: CommentaryID,
        CommentText: Comment,
      };
      this.setState({ apiCallStatus: true });
        axiosInstance.post(`${LocalApiBaseUrl}MOR/EditMORComments`, body).then((response) => {
        this.setState({ apiCallStatus: false });
        this.generateMORComments();
            this.hideEdit();
        })
            .catch((error) => {
                trycatchAlertPopup(error);
            });

    }
  };
  onDelete = (id) => {
    this.setState({ apiCallStatus: true });
    axiosInstance
        .post(`${LocalApiBaseUrl}MOR/DeleteMORComments?MORCommentID=${id}`)
      .then((response) => {
        if (response.data.IsSuccess) {
          this.setState({
            apiCallStatus: false,
          });
          this.generateMORComments();
        }
      })
        .catch((error)=>{
            trycatchAlertPopup(error);
            this.setState({ apiCallStatus: false });
        });
  };

  generateMORComments = () => {
    // this.setState({ apiCallStatus: true });
    //Get api Call for MOR Comments
    const { filterProps } = this.props;

    let body = {
      ...filterProps,
    };
      axiosInstance.post(`${LocalApiBaseUrl}MOR/GetNewMORComments`, body).then((response) => {
          if (response.data) {
              let commentsData = response.data;
              let filteredCommentsData = [];
              response.data.map((each) => {
                  if (each.IsDeleted === false) {
                      filteredCommentsData.push(each);
                  }
              });
              filteredCommentsData = this.sortBy(filteredCommentsData, this.state.sortby);
              this.setState(
                  {
                      commentsData: commentsData,
                      filteredCommentsData: filteredCommentsData,
                      apiCallStatus: false,
                  },
                  () => {
                      this.scrollToBottom();
                  }
              );
          }
          })
          .catch((error) => {
              trycatchAlertPopup(error);
          });
  };

  scrollToBottom = () => {
    if (!_.isEmpty(this.messagesEndRef.current)) {
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  componentDidMount() {
    this.generateMORComments();
  }

  render() {
    return (
      <div className="comment-overlay">
        <LoadingOverlay
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={this.state.apiCallStatus}
        >
          <div className="comment-window">
            <div className="comments-header">
              <div className="title-with-icon">
                <h5>
                  {i18n.t("Comments")} <span>({this.state.filteredCommentsData.length})</span>
                </h5>
                <div className="list-icon">
                  <span onClick={this.closeChat}>
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </div>
              </div>
              {/* <div className="outcome-name">{this.state.MetricName}</div> */}
            </div>

            {this.state.displayAddComments ? (
              <div className="comment-section">
                <div className="title-with-icon action-links">
                  
                  <p
                    onClick={this.sortChangeEvent}
                    className={`float-right sortby  p-r-20 ${
                      this.state.sortby ? "sortby-icon-up" : "sortby-icon-down"
                    }`}
                  >
                    {i18n.t("Sort_By")}
                  </p>
                  <p onClick={this.onHistoryClick}>{i18n.t("View_History")} </p>
                </div>
                <div className="comments-body">
                  {this.state.filteredCommentsData.map((each, pos) => {
                    return (
                      <div className="user-specific-comment">
                        <div className="user-info">
                          <div className="user-profile">
                            <span>
                              <FontAwesomeIcon icon={faUser} />
                            </span>
                          </div>
                          <div className="profile-info">
                            <h6>{each.CreatedBy} </h6>
                            <span className="time-info">
                              <ul>
                                {each.IsEdited ? <li className="edited-label">{i18n.t("Edited")}</li> : ""}
                                <li>{FormatDateWithoutUTC(each.UpdatedTS)}</li>
                              </ul>
                            </span>
                          </div>
                          {sessionStorage.getItem("EnterpriseID") === each.CreatedBy && !this.props.archiveLayoutStatus ? (
                            <div className="user-actions">
                              <span onClick={this.showEdit.bind(this, each)}>
                                <FontAwesomeIcon icon={faEdit} />
                              </span>
                              <span onClick={this.onDelete.bind(this, each.MORCommentID)}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </span>
                            </div>

                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className="user-comment"
                          ref={pos === this.state.filteredCommentsData.length - 1 ? this.messagesEndRef : null}
                        >
                          <p>{each.CommentText} </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {!this.props.archiveLayoutStatus?
                <div className="comments-footer">
                  <div className="user-profile">
                    <span>
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                  </div>
                  <div className="comment-area">
                    <textarea
                    maxlength="200"
                      rows="1"
                      className="form-control"
                      placeholder={i18n.t("Add_Comment")}
                      value={this.state.Comment}
                      onChange={this.onCommentChange}
                    />
                    <span className={this.state.Comment ? "enable-button" : ""} onClick={this.addComment}>
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </span>
                  </div>
                </div>
               :""}
              </div>
            ) : (
              <div className="comment-editsection">
                <div className="title-with-icon edit-links">
                  <h6>
                    <span onClick={this.hideEdit}>
                      <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    </span>
                    {i18n.t("Edit_Comment")}
                  </h6>
                </div>
                <div className="comments-body">
                  <div className="user-specific-comment">
                    <div className="user-info">
                      <div className="user-profile">
                        <span>
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                      </div>
                      <div className="profile-info">
                        <h6>{this.state.editData.CreatedBy} </h6>
                        <span className="time-info">
                          <ul>
                            <li>{FormatDateWithoutUTC(this.state.editData.UpdatedTS)}</li>
                          </ul>
                        </span>
                      </div>
                    </div>
                    <div className="user-comment">
                      <div className="comment-area">
                        <textarea
                        maxlength="200"
                          rows="4"
                          className="form-control"
                          placeholder={i18n.t("Edit_Comment")}
                          value={this.state.Comment}
                          onChange={this.onCommentChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comments-footer radius-btn">
                  <button className="btn btn-outline-secondary" onClick={this.hideEdit}>
                    {i18n.t("Cancel")}
                  </button>
                  <button className="btn btn-primary" onClick={this.editComment}>
                    {i18n.t("Update")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default CommentsPopOver;
