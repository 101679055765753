/* eslint-disable no-script-url */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, regexForFields, LocalApiBaseUrl } from '../Shared/Constant';
import { dateFormatMethod } from '../Shared/commonFunctions';
import SynopsLoader from '../Shared/SynopsLoader';
import EmptyStateIcon from '../Images/emptychat-icon.svg';
import { store } from '../reducers/configureStore';
import ellipseIcon from '../Images/ellipsis-v.svg';
import ellipseSelectedIcon from '../Images/ellipsis-v-selected-icon.svg';
import Delete from '../Images/delete-comment.svg';
import Edit from '../Images/edit-comment.svg';
import EditSelected from '../Images/editSelected-icon.svg';
import DeleteSelected from '../Images/deleteSelected-icon.svg';

class InsightsChatWindow extends Component {
  constructor() {
    super();
    this.state = {
      ID: 0,
      apiCallStatus: false,
      commentsData: [],
      filteredCommentsData: [],
      Comment: '',
      editComment: '',
      monthData: [],
      defaultCommentMonth: '',
      IsAdmin: false,
      AddEditDeleteAccess: false,
    //   MetricName: '',                            // unused state var
    //   KPIMappingID: '',                          // unused state var
    //   displayAddComments: true,                  // unused state var
    //   editData: {},                              // unused state var
    //   CommentaryFeatures: [],                    // unused state var
    //   query: '',                                 // unused state var
    //   onSearch: false,                           // unused state var
    //   searchCommentsData: [],                    // unused state var
    //   newNotificationList: [],                   // unused state var
    };
    this.monthValues = [];
  }

  componentDidMount() {
    const { commentryRole } = this.props;
    let AddEditDeleteAccess = false;
    let CommentaryFeatures = [];
    commentryRole.forEach((item) => {
      if (item.FeatureName === 'Configurator') {
        CommentaryFeatures = item.Operations;
      }
    });
    if (CommentaryFeatures.includes('Submit') || CommentaryFeatures.includes('Publish')) {
      AddEditDeleteAccess = true;
    }
    this.setState({
    //   KPIMappingID: this.props.kpiMappingId,     // unused state var
    //   CommentaryFeatures: CommentaryFeatures,       // unused state var
      AddEditDeleteAccess,
    });
    this.getMonths();
    this.onGetKpi();
    this.GetUserDetails();
  }

  componentDidUpdate(prevProps) {
    const { monthComment, SelectedCommentMonth } = this.props;
    if (JSON.stringify(monthComment) !== JSON.stringify(prevProps.monthComment)) {
      this.getMonths();
    }
    if (SelectedCommentMonth !== prevProps.SelectedCommentMonth) {
      this.onGetKpi();
    }
  }

    showEdit = (data) => {
      this.setState({
        // displayAddComments: false,           // unused state var
        // editData: data,                      // unused state var
        editComment: data.Comment,
        ID: data.ID,
      });
    }

    hideEdit = () => {
      this.setState({
        // displayAddComments: true,            // unused state var
        editComment: '', ID: 0,
      });
    }

    saveComment = (isEdit) => {
      const {
        
        ID,
        commentsData: commentsDataFromState,
        filteredCommentsData: filteredCommentsDataFromState,
      } = this.state;
      const Comment = isEdit ? this.state.editComment : this.state.Comment;

      const {
        SelectedCommentMonth, kpiMappingId, languageData, GetKpiData,
      } = this.props;
      if (Comment.trim()) {
        let month_year = [];
        const monthNames = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        const period = SelectedCommentMonth;
        const objDate = new Date(period);
        month_year = period.split(' ');
        month_year[0] = `${monthNames[objDate.getMonth()]}`;
        month_year = month_year.join('-');
        const body = {
          ID,
          Comment,
        };
        this.setState({ apiCallStatus: true });
        axiosInstance
          .post(`${LocalApiBaseUrl}Analytics/AddEditMetricComments?kpiMappingID=${kpiMappingId}&PeriodDT=${month_year}&LanguageID=${parseInt(languageData.id, 10)}`, body)
          .then((response) => {
            if (response.data) {
              if (response.data.MetricDataCommentsEntity) {
                if (ID === 0) {
                  const commentsData = commentsDataFromState.filter((each) => each.ID !== response.data.MetricDataCommentsEntity.ID);
                  const filteredCommentsData = filteredCommentsDataFromState.filter((each) => each.ID !== response.data.MetricDataCommentsEntity.ID);
                  commentsData.push(response.data.MetricDataCommentsEntity);
                  filteredCommentsData.push(response.data.MetricDataCommentsEntity);
                  this.setState(
                    {
                      Comment: '',
                      commentsData,
                      filteredCommentsData,
                      apiCallStatus: false,
                      ID: 0,
                    //   editData: {},                      // unused state var
                    //   displayAddComments: true,          // unused state var
                    },
                    () => {
                      GetKpiData();
                    },
                  );
                } else {
                  const filtercomments = filteredCommentsDataFromState.map((each, index) => {
                    if (each.ID === ID) {
                      filteredCommentsDataFromState[index] = response.data.MetricDataCommentsEntity;
                    }
                    return filteredCommentsDataFromState[index];
                    if(isEdit)
                    this.setState({editComment: ''});
                    else
                    this.setState({Comment: ''});
                  });
                  this.setState(
                    {
                      
                      filteredCommentsData: filtercomments,
                      apiCallStatus: false,
                      ID: 0,
                      // editData: {},
                      // displayAddComments: true,
                    },
                    () => {
                      GetKpiData();
                    },
                  );
                }
              }
            }
          }).catch((error) => {
            trycatchAlertPopup(error);
            this.setState({ apiCallStatus: false });
          });
      }
    }

    onGetKpi = () => {
      const { SelectedCommentMonth, commentData: commentDataFromProps } = this.props;
      this.setState({ apiCallStatus: true });
      let commentData = [];
      commentData = commentDataFromProps;
      if (commentData) {
        const commentsData = commentData;
        const filteredCommentsData = commentsData.filter((each) => each.PeriodDt === SelectedCommentMonth).length > 0 && commentsData.filter((each) => each.PeriodDt === SelectedCommentMonth)[0].CommentList;

        this.setState({
          commentsData,
          filteredCommentsData,
          apiCallStatus: false,
        });
      }
    }

    onCommentChange = (e) => {
      if (e.target.value) {
        if (regexForFields.test(e.target.value)) {
          this.setState({ Comment: e.target.value });
        }
      } else {
        this.setState({ Comment: e.target.value });
      }
    }

    onEditCommentChange = (e) => {
      if (e.target.value) {
        if (regexForFields.test(e.target.value)) {
          this.setState({ editComment: e.target.value });
        }
      } else {
        this.setState({ editComment: e.target.value });
      }
    }

    onDelete = (id) => {
      const { commentsData: commentsDataFromState, filteredCommentsData: filteredCommentsDataFromState } = this.state;
      const { GetKpiData } = this.props;
      this.setState({ apiCallStatus: true });
      axiosInstance.post(`${LocalApiBaseUrl}Analytics/DeleteComments?ID=${id}`)
        .then((response) => {
          if (response.data.IsSuccess) {
            const commentsData = commentsDataFromState.filter((each) => each.ID !== id);
            const filteredCommentsData = filteredCommentsDataFromState.filter((each) => each.ID !== id);
            GetKpiData();
            this.setState({
              commentsData,
              filteredCommentsData,
              apiCallStatus: false,
            });
          }
        }).catch((error) => {
          trycatchAlertPopup(error);
          this.setState({ apiCallStatus: false });
        });
    }

    GetUserDetails = () => {
      const EnterpriseID = sessionStorage.getItem('EnterpriseID');
      if (EnterpriseID !== undefined) {
        axiosInstance.get(`${LocalApiBaseUrl}User/GetUserDetails `, {
          params: {},
        }).then((response) => {
          if (response.data[0] === 'True') {
            this.setState({ IsAdmin: true });
          }
        })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      }
    }

    getMonths=() => {
      // this.setState({ showLoaderOnPage: true });         // unused state var
      const { monthComment } = this.props;
      const { SelectedCommentMonth: SelectedCommentMonthFromState } = this.state;
      // let monthValues = '';
      // let month = [];
      // var today = new Date();
      // var d;
      // let defaultMonth;
      const month = [];
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      let monthData = [];
      let monLength;
      let filteredMonthData = [];

      monthData = monthComment;
      if (monthData) {
        filteredMonthData = monthData.filter((i) => Number.isNaN(Number(i)));
        monLength = filteredMonthData.length;
      }
      if (filteredMonthData) {
        filteredMonthData.map((dropValue, index) => {
          const objDate = new Date(dropValue);
          month[(monLength - 1) - index] = `${monthNames[objDate.getMonth()]} 20${objDate.getDate()}`;
          return (
            <option
              key={month[(monLength - 1) - index]}
              selected={month[(monLength - 1) - index] === SelectedCommentMonthFromState}
              value={month[(monLength - 1) - index]}
            >
              {month[(monLength - 1) - index]}
            </option>
          );
        });
        // monthValues = monthValues.reverse();
        const defaultMonth = month[0];
        this.setState({ monthData: month, defaultCommentMonth: defaultMonth }, () => {
          const { monthData: stateMonthData } = this.state;
          const { SelectedCommentMonth } = this.props;
          if (
            SelectedCommentMonth
              && stateMonthData.filter((each) => each === SelectedCommentMonth).length
          ) {
            this.MonthChange(SelectedCommentMonth);
          } else {
            this.MonthChange(stateMonthData[0]);
          }
        });
      }
    }

    MonthChange = (event) => {
      const { monthData } = this.state;
      const { SelectedCommentMonth } = this.props;
      if (SelectedCommentMonth !== event) {
        if (event && event !== undefined) {
          monthData.forEach((each) => {
            if (each === event) {
              store.dispatch({
                type: 'SYNC_COMMENTFILTER',
                payload: event,
              });
            }
          });
        } else {
          store.dispatch({
            type: 'SYNC_COMMENTFILTER',
            payload: event,
          });
        }
        setTimeout(() => {
          this.onGetKpi();
        }, 500);
      }
    };

    render() {
      const {
        monthData,
        apiCallStatus,
        defaultCommentMonth,
        filteredCommentsData,
        ID,
        IsAdmin,
        AddEditDeleteAccess,
        Comment,
        editComment,
      } = this.state;
      const { SelectedCommentMonth, newNotificationList, kpiName } = this.props;

      return (

        <LoadingOverlay
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={apiCallStatus}
        >
          <div className="charts-commentsection">
            <div className="comments-header">
              <div className="title-with-icon">
                <h5>{i18n.t('Comments')}</h5>
              </div>
            </div>
            <div className="comment-section">
              <div className="comment-yeardropdown">
                <DropdownButton id="dropdown-offering" title={SelectedCommentMonth || defaultCommentMonth}>
                  <div className="offering-droplistwrapper">
                    {monthData.map((each) => (
                      <Dropdown.Item
                        href="javascript:void(0);"
                        onClick={() => this.MonthChange(each)}
                        value={each}
                        active={each === SelectedCommentMonth}
                      >
                        {each}
                      </Dropdown.Item>
                    ))}
                  </div>
                </DropdownButton>
              </div>
              <div className="comments-body">
                {filteredCommentsData && filteredCommentsData.length > 0
                  ? filteredCommentsData.map((each) => (
                    <div className="user-specific-comment">
                      {(ID !== each.ID)
                        ? (
                          <div className={sessionStorage.getItem('EnterpriseID') === each.CreatedBy ? 'user-info usermessage' : 'user-info'}>

                            <div className="user-profile">
                              <span>
                                <FontAwesomeIcon icon={faUser} />
                              </span>
                              {newNotificationList
                                            && newNotificationList.map((eachNotification) => (eachNotification.ID === each.ID && sessionStorage.getItem('EnterpriseID') !== eachNotification.CreatedBy
                                              && <p className="newcomment-notify" />))}
                            </div>
                            <div className="user-comment">
                              <div className="profile-info">
                                <h6>
                                  {each.CreatedBy}
                                  {' '}
                                </h6>
                                {IsAdmin
                                                || (AddEditDeleteAccess && sessionStorage.getItem('EnterpriseID') === each.CreatedBy)
                                  ? (
                                    <div className="user-actionsicons">
                                      <DropdownButton
                                        id="edit-delete-dropdown"
                                        className="edit-delete-dropdown d-flex float-right"
                                        title={(
                                          <span>
                                            <img className="default-ellipsis" src={ellipseIcon} alt="Ellipse Icon" />
                                            {' '}
                                            <img className="ellpsis-selected" src={ellipseSelectedIcon} alt="Ellipse Selected Icon" />
                                          </span>
                                                )}
                                      >
                                        <Dropdown.Item onClick={() => this.showEdit(each)}>
                                          <img className="edit-comment-selected" src={EditSelected} alt="Edit Comment" />
                                          <img className="edit-comment" src={Edit} alt="Edit Comment" />
                                          {i18n.t('Edit')}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.onDelete(each.ID)}>
                                          <img className="delete-comment-selected" src={DeleteSelected} alt="Delete Comment" />
                                          <img className="delete-comment" src={Delete} alt="Delete Comment" />
                                          {i18n.t('Delete')}
                                        </Dropdown.Item>

                                      </DropdownButton>
                                    </div>
                                  ) : ''}
                              </div>
                              <span className="time-info">
                                <ul>
                                  {each.IsEdited ? <li className="edited-label">{i18n.t('Edited')}</li> : ''}
                                  <li>{dateFormatMethod(each.UpdatedTS)}</li>
                                </ul>
                              </span>

                              <p>
                                {each.Comment}
                                {' '}
                              </p>
                            </div>

                          </div>
                        )
                        : (
                          <div className="commentry-edit">

                            <textarea rows="4" maxLength="120" className="form-control" placeholder={i18n.t('Edit_Comment')} value={editComment} onChange={this.onEditCommentChange} />

                            <div className="commentedit-btns">
                              <span onClick={this.hideEdit} role="button" tabIndex="0" aria-label="Hide Edit"><i className="fal fa-times mr-2" /></span>
                              <span onClick={() => this.saveComment(true)} role="button" tabIndex="0" aria-label="Save comment"><i className="far fa-check" /></span>
                            </div>
                          </div>
                        )}
                    </div>
                  ))
                  : (
                    <div className="empty-commentsection">
                      <img src={EmptyStateIcon} width="70px" height="50px" className="mb-3" alt="Empty state Icon" />
                      <h6>
                        {i18n.t('Hi')}
                        {' '}
                        {sessionStorage.getItem('EnterpriseID')}
                        {' '}
                        !
                      </h6>
                      {AddEditDeleteAccess
                        ? (
                          <p>
                            {i18n.t('Add your comment for')}
                            <br />
                            <b>{kpiName}</b>
                          </p>
                        ) : ''}
                    </div>
                  )}
              </div>
              <div className="comments-footer">
                <div className="comment-area">
                  <textarea rows="1" maxLength="120" className="form-control" placeholder={i18n.t('Add_a_Comment')} disabled={!AddEditDeleteAccess} value={Comment} onChange={this.onCommentChange} />
                </div>
                <div className="commentarea-actions">
                  <p />
                  <button className="btn btn-primary" disabled={!AddEditDeleteAccess} onClick={() => this.saveComment(false)} type="button">{i18n.t('Comment')}</button>
                </div>
              </div>

            </div>

          </div>
        </LoadingOverlay>
      );
    }
}
const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  commentryRole: [...state.roleReducers.roleReducers],
  SelectedCommentMonth: state.CurrentFilter.SelectedCommentMonth,
});
const mapDispatchToProps = (dispatch) => ({
  updateSyncFilter: (data) => new Promise((resolve) => {
    dispatch({ type: 'SYNC_COMMENTFILTER', payload: data });
    resolve();
  }),
});

InsightsChatWindow.propTypes = {
  commentryRole: PropTypes.array.isRequired,
  monthComment: PropTypes.array.isRequired,
  SelectedCommentMonth: PropTypes.string.isRequired,
  GetKpiData: PropTypes.func.isRequired,
  kpiMappingId: PropTypes.number.isRequired,
  languageData: PropTypes.object.isRequired,
  commentData: PropTypes.array.isRequired,
  newNotificationList: PropTypes.array.isRequired,
  kpiName: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsChatWindow);
