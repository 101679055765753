/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import jquery from 'jquery';
import {
  ValueUnit, ValueUnitNoRoundOff, MetricValueUnitFormat, toolThreshold, CommonMetriTileFix,
} from '../Shared/Common';
import MetricComments from './MetricComments';
import TrendGraphsHnM from '../Analytics/TrendGraphsHnM';

window.$ = jquery;
window.jQuery = jquery;

export default function HumanMetricDetails(props) {
  const [commentPopUp, setcommentPopUp] = useState(false);
  const [viewGraph, setviewGraph] = useState(false);
  const [KpiId, setKpiId] = useState(0);

  function onCommentClick(val, data) {
    if (val === 1) {
      return setcommentPopUp(data);
    }

    return setcommentPopUp('');
  }

  function handleGraph(val, Id, e) {
    if (val === 1) {
      e.preventDefault(Id, e);
      handleKpiId(Id);
      return setviewGraph(true);
    }

    handleKpiId(0);
    return setviewGraph(false);
  }

  function handleKpiId(Id) {
    return setKpiId(Id);
  }

  useEffect(() => {
    CommonMetriTileFix();
  });

  return props.Details.map((eachDetails) => {
    let metricName = '';
    let unit = '';
    let metricData = '';
    let Description = '';
    const ValueBlock = true;
    let KPIMappingID = '';
    let RelatorBlock = props.isRelatorBlock;
    let MetricValueUnitFormatData = '';

    if (eachDetails !== undefined) {
      metricName = eachDetails.MetricName;
      unit = eachDetails.MetricUnit;
      unit = unit.trim();
      if (eachDetails.metDataAttr === null) {
        RelatorBlock = false;
      }
    }
    let statusColor = 'gray-clr';

    metricData = eachDetails.MetricValue;
    if(unit.includes('$') || unit.includes('€') || unit.includes('£') || unit.includes('₹') || unit.includes('SGD') || unit.includes('R$')) {
      metricData = ValueUnitNoRoundOff(metricData);
    } 
    else {
      metricData = ValueUnit(metricData);
    }
    MetricValueUnitFormatData = MetricValueUnitFormat(unit, metricData);

    Description = eachDetails.KPIDesp
      ? eachDetails.KPIDesp
      : '';
    unit = eachDetails.MetricUnit;
    unit = unit.trim();
    KPIMappingID = eachDetails.KPIMappingID;

    if (eachDetails !== undefined) {
      if (eachDetails.RAGStatus
        !== undefined && eachDetails.RAGStatus != null) {
        switch (eachDetails.RAGStatus.toLowerCase()) {
          case 'grey':
            statusColor = 'item gray-color';
            break;
          case 'red':
            statusColor = 'item red-color';
            break;
          case 'orange':
            statusColor = 'item amber-color';
            break;
          case 'green':
            statusColor = 'item green-color';
            break;
          case 'amber':
            statusColor = 'item amber-color';
            break;
          default:
        // code block
        }
      }
    }
    return (
      <>
        <div className={`${statusColor}`} data-place="bottom" data-tip={toolThreshold(eachDetails)}>
          <div className="item-left">
            {ValueBlock ? (
              <>
                <p className="tile-metric-value">
                  {MetricValueUnitFormatData}
                </p>
              </>
            ) : null}
            <p className="tile-metric-desc" data-tip data-for={`customTooltip${eachDetails.KPIMappingID}`}>
              {metricName}
            </p>
          </div>

          <div className="item-right">
            {Description ? (
              <p className="tile-metric-name" data-tip data-for={`customTooltip_desc${eachDetails.KPIMappingID}`}>
                {Description}
              </p>
            ) : (
              <p className="tile-metric-name">&nbsp;</p>
            )}
          </div>

          {
            <div className="dropdown dropleft ondropOpenColor">
              <span type="button" className="dropdown-toggle" data-toggle="dropdown" />
              <div className="dropdown-menu">
                <a
                  href="javascript:void(0)"
                  className="dropdown-item"
                  onClick={() => onCommentClick(1, KPIMappingID)}
                >
                  <span className="info-icon" />
                  {i18n.t('Info')}
                </a>
                <a
                  href="javascript:void(0)"
                  className="dropdown-item"
                  onClick={(e) => handleGraph(1, KPIMappingID, e)}
                >
                  <span className="insights-new-icon" />
                  {i18n.t('Insights')}
                </a>
              </div>
            </div>
                }
          {RelatorBlock ? (
            <>
              <span className="parent-metric-details">
                {eachDetails.metDataAttr.map(
                  (arrayElt) => arrayElt.AttributeValue
                          && arrayElt.AttributeValue !== 'null'
                          && arrayElt.AttributeValue.toUpperCase() !== 'NA' && (
                            <span className="metric-details">
                              {arrayElt.AttributeName}
                              {' '}
                              :
                              {' '}
                              {arrayElt.AttributeValue}
                            </span>
                  ),
                )}
              </span>
            </>
          ) : null}
        </div>

        {commentPopUp === KPIMappingID ? (
          <MetricComments
            info={eachDetails}
            show
            onClose={() => onCommentClick(2, KPIMappingID)}
            isMachine={props.Tab === 'Machine Workforce'}
          />
        ) : null}
        {KpiId === KPIMappingID ? (
          <TrendGraphsHnM
            show
            onClose={() => handleGraph(2, KPIMappingID)}
            kpiMappingId={KPIMappingID}
            kpiName={metricName}
            kpiUnit={unit}
            info={eachDetails}
            dealFilter={props.dealFilter}
          />
        ) : null}
      </>
    );
  });
}
