export const PULSE_MOR_DATA_INITIALIZE = 'PULSE_MOR_DATA_INITIALIZE';
export const PULSE_CASESTUDY_DATA_INITIALIZE = 'PULSE_CASESTUDY_DATA_INITIALIZE';

export function InitializeCaseStudyData(CaseStudyData) {
  return {
    type: PULSE_CASESTUDY_DATA_INITIALIZE,
    payload: CaseStudyData,
  };
}
export function InitializeMORData(MORItemData, ItemDataLevel, isCategoryData) {
  return {
    type: PULSE_MOR_DATA_INITIALIZE,
    payload: { MorItemData: MORItemData, ItemDataLevel, isCategoryData },
  };
}
