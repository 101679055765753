import React, { Component } from "react";
import { LocalApiBaseUrl } from "./Constant";
import axiosInstance from "./interceptor";
import { trycatchAlertPopup } from "./Constant";
import { connect } from "react-redux";
import { store } from "../reducers/configureStore";
import i18n from "i18next";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
import $ from "jquery";
import Moment from "moment";
import userImg from "../Images/user_img.svg";
import { MorDocInfo } from "./Constant";
import MORDocViewer from "../MonthlyOperationalReview/MORDocViewer";
import AddEditReferenceModal from "../Dashboard/AddEditReferenceModal";

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


$(document).ready(function(){
  $(document).on("focus", "#dropdownMenuLink", function(e) {
    ReactTooltip.hide(this.Tooltip)
  });
});
class References extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roledata: [],
      addReferenceStatus: false,
      editReferenceStatus: false,
      selectedReferences: {
        DocDescription: "",
        DocName: "",
        DocURL: "",
      },
      adminReferences: false,
      referencesExist: false,
      allmonth: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      MorItemData: [],
      ViewerItemDetails: {
        ItemId: 0,
        ItemName: "",
        ItemLink: "",
        UpdatedTS: "",
        CreatedBy: "",
      },
      accessControl: "View",
      Doctype: {
        Id: 0,
        DocName: "",
        DocLink: "",
        DocDescription: "",
      },
      referenceDetailsData: [],
      referenceFlag: false,
      IOJSectionHierarchyID: 0,
      empty: false,
      toolTipData: {
        DocDescription: "",
        CreatedBy: "",
        CreatedTS: "",
      },
    };
    this.Tooltip = React.createRef();
  }

  editReferenceModalShow = (e, ItemData, index) => {
    this.setState({
      selectedReferences: {
        ...this.state.selectedReferences,
        DocDescription: ItemData.DocDescription,
        DocName: ItemData.DocName,
        DocURL: ItemData.DocURL,
        ...ItemData,
      },
      editReferenceStatus: true,
    });
  };

  addReferenceModalShow = () => {
    this.setState({ addReferenceStatus: true });
  };

  handleAddReferenceClose = () => {
    this.setState({ addReferenceStatus: false });
  };

  handleEditReferenceClose = () => {
    this.setState({ editReferenceStatus: false });
  };

  onItemCardClick = (e, ItemData, index) => {
    this.setState({
      isMORShowDocViewer: true,
      ViewerItemDetails: {
        ...this.state.ViewerItemDetails,
        ItemId: ItemData.MORItemID,
        ...ItemData,
      },
    });
  };

  handleView = () => {
    this.setState({
      isMORShowDocViewer: true,
    });
  };

  closeViewer = () => {
    this.setState({
      isMORShowDocViewer: false,
    });
  };

  GenerateExtensionIcon = (link) => {
    var matches = MorDocInfo.filter((a, index) => {
      if (
        link
          .trim()
          .toUpperCase()
          .includes(a.key.trim().toUpperCase())
      ) {
        return a;
      } else {
        return;
      }
    });

    if (matches.length > 0) {
      return matches[0];
    } else {
      if (
        link
          .trim()
          .toUpperCase()
          .includes("Doc.aspx".trim().toUpperCase()) &&
        !MorDocInfo.map((a) => a.key.toUpperCase()).includes(
          link.trim().toUpperCase()
        )
      ) {
        return MorDocInfo.filter((a) => a.key === "Word")[0];
      }
      return MorDocInfo.filter((a) => a.key === "Invalid")[0];
    }
  };

  dateFormatMethod = (dateTime) => {
    let conv = dateTime.replace("T", " ");

    let tempData = Moment.utc(conv)
      .local()
      .format("D MMM YYYY");
    let tempTime = Moment.utc(conv)
      .local()
      .format("hh:mm A");

    let result = tempData + ", " + tempTime.toLowerCase();

    return result;
  };

  fetchInitialDetails = () => {
    this.setState({
      isAPILoading: true,
    });
    let IOJSectionHierarchyIDState = this.state.IOJSectionHierarchyID;
    let filterClient =
      this.props.filterDetails == undefined
        ? this.props.offeringIdParam.Client
        : this.props.filterDetails.Client;
    let filterProcess =
      this.props.filterDetails == undefined
        ? this.props.offeringIdParam.process
        : this.props.filterDetails.process;
    let filterLocations =
      this.props.filterDetails == undefined
        ? this.props.offeringIdParam.Locations
        : this.props.filterDetails.Locations;
    let SOCatID = this.props.SOCatID;
    let IOJSectionHierarchyID =
      this.props.IOJSectionHierarchyID == undefined
        ? IOJSectionHierarchyIDState
        : this.props.IOJSectionHierarchyID;
    let languageId = parseInt(store.getState().CurrentFilter.languageData.id);
    axiosInstance
      .get(`${LocalApiBaseUrl}ReferenceDocument/GetReferences`, {
        params: {
          SOCatId: SOCatID,
          IOJSectionHierarchyID: IOJSectionHierarchyID,
          OrgEntityID: filterClient,
          OfferingId: filterProcess,
          LocationId: filterLocations,
          LanguageId: languageId,
        },
      })
      .then((response) => {
        let empty = false;
        if (response.data.length == 0) {
          empty = true;
        }
        let data = response.data;
        data = data.map((d) => {
          let obj = {
            ...d,
          };
          obj.CreatedTS = this.dateFormatMethod(obj.CreatedTS);
          return obj;
        });
        this.setState({
          referenceDetailsData: data,
          isAPILoading: false,
          empty: empty,
          referenceFlag: true,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
    let categoryForReference = `MORCategoryForReferencesFor${this.props.referenceScreen}`;
    let data = {
      OrgEntityID: filterClient,
      SubOfferingID: filterProcess,
      MORReferenceValue: categoryForReference,
      LanguageID: languageId,
    };
    axiosInstance
      .post(`${LocalApiBaseUrl}MOR/GetAllMORItemForReference`, data, {
        params: {},
      })
      .then((response) => {
        let MORItems = response.data;
        let MORItemsModel = MORItems.sort((a, b) =>
          a.ItemName.toLowerCase() > b.ItemName.toLowerCase() ? 1 : -1
        );
        MORItemsModel = MORItemsModel.map((a, index) => {
          return {
            ...a,
            DocName: a.ItemName,
            DocName_En: a.ItemName_En,
            //IsChecked: false,
            Month:
              a.Month != null ? this.state.allmonth[parseInt(a.Month) - 1] : "",
            MonthYear:
              a.Month != null && a.Year != null
                ? this.state.allmonth[parseInt(a.Month) - 1] + " " + a.Year
                : "",
            ExtensionAndIcon:
              a.ItemLink !== "" && a.ItemLink != null
                ? this.GenerateExtensionIcon(a.ItemLink)
                : "",
          };
        });
        this.setState({
          MorItemData: MORItemsModel,
        });
      })
      .catch((error) => {
        this.setState({
          isAPILoading: false,
        });
        trycatchAlertPopup(error);
      });
  };

  deleteReferenceDetails = (e, each) => {
    let id = each.ID;
    const referenceDeleteConfrim = "Are you sure you want to delete?"; //i18n.t("Reference_Delete_Confirm");
    if (window.confirm(referenceDeleteConfrim)) {
      axiosInstance
        .delete(`${LocalApiBaseUrl}ReferenceDocument/DeleteReference`, {
          params: {
            RefId: id,
          },
        })
        .then((response) => {
          window.alert(response.data.StatusMessage);
          // this.fetchInitialDetails();
          if (response.data.IsSuccess === true) {
            let data = this.state.referenceDetailsData;
            data = data.filter((a) => a.ID !== id);
            this.setState({
              referenceDetailsData: data,
            });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  getFeatureDetails() {
    let featureDetails1 = store.getState().roleReducers.roleReducers;
    let operationArray;
    featureDetails1.map((i) => {
      if (i.FeatureName === "References") {
        operationArray = i.Operations;
        return operationArray;
      }
    });

    this.setState({ roledata: operationArray });
  }
  handleDisplayReferencesWindow = () => {
    let roleData = store.getState().roleReducers.roleReducers;
    let referencesExist =
      roleData.filter(
        (data) =>
          data.FeatureName === "References" && data.Operations.includes("View")
      ).length > 0
        ? true
        : false;
    let admin = false;
    let reference = roleData.filter(
      (data) => data.FeatureName === "References"
    );
    if (reference.length > 0) {
      let roles = roleData.filter(
        (data) => data.FeatureName === "References"
      )[0];
      admin = roles.Operations.includes("Post") ? true : false;
    }
    if (admin) {
      this.setState({
        accessControl: "Edit",
      });
    } else {
      this.setState({
        accessControl: "View",
      });
    }
    this.setState({
      referencesExist: referencesExist,
      adminReferences: admin,
    });
  };

  handleMORToggle = (e, each, index) => {
    let MORId = each.MORItemID;
    let HideStatus = !each.HideFromReferences;
    axiosInstance
      .post(
        `${LocalApiBaseUrl}MOR/HideFromReferences`,
        {},
        {
          params: {
            MORItemID: MORId,
            HideFromReferences: HideStatus,
          },
        }
      )
      .then((response) => {
        if (response.data.IsSuccess === true) {
          let data = this.state.MorItemData;
          data[index].HideFromReferences = !data[index].HideFromReferences;
          this.setState({
            MorItemData: data,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  showInfoData = (index) => {
    let temp = [...this.state.referenceDetailsData];
    this.setState({
      toolTipData: {
        DocDescription: temp[index].DocDescription,
        CreatedBy: temp[index].CreatedBy,
        CreatedTS: temp[index].CreatedTS,
      },
    });
  };

  componentDidMount() {
    this.getFeatureDetails();
    this.handleDisplayReferencesWindow();
    this.fetchInitialDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const { toolTipData } = this.state;
    $(document).on("click", ".delete-icon", function(e) {
      e.stopPropagation();
    });
    $(document).on("click", ".summary-icon", function(e) {
      e.stopPropagation();
    });
    $(document).on("click", ".custom-toggle-tickbtn", function(e) {
      e.stopPropagation();
    });
    if (JSON.stringify(toolTipData) !== JSON.stringify(prevState.toolTipData)) {
      ReactTooltip.rebuild()
      this.setState({
        toolTipData: { ...toolTipData },
      });
    }
  }

  render() {
    const {
      Doctype,
      MORItemData,
      selectedReferences,
      editReferenceStatus,
      referencesExist,
      accessControl,
      adminReferences,
      toolTipData,
      MorItemData,
      referenceDetailsData,
      isMORShowDocViewer,
      addReferenceStatus,
      ViewerItemDetails,
      referenceFlag,
    } = this.state;
    const { isConfig, SOCatID, leverName, isClientStory } = this.props;
    const listDocChar = 30;
    return (
      (referencesExist) && (
        // adminReferences ?
        // (
        <>
          <div className="nav-item dropdown mr-0">
            <a
              class="dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {i18n.t("References")}
            </a>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuLink"
            >
              {this.state.accessControl !== "View" && <a
                className="add-references"        
              >
              <span style={{cursor : "pointer"}} onClick={this.addReferenceModalShow}>
                <span>
                  <i class="fal fa-plus"></i>
                </span>
                Add Reference
              </span>
              </a>}
              <div className="references-overflow">
                {MorItemData &&
                  _.map(MorItemData, (each, index) => {
                    return (
                      (isConfig || !each.HideFromReferences) && (
                        <div className="d-flex align-items-center">
                          <a
                            //class="dropdown-item"
                            className={
                              each.HideFromReferences
                                ? "dropdown-item disabled"
                                : each.URL
                                  ? "dropdown-item"
                                  : "dropdown-item explore_tools_noCursor"
                            }
                            onClick={(e) =>
                              this.onItemCardClick(e, each, index)
                            }
                            key={index}
                          >
                            <div>
                              <span
                                className={`reference-icons ${each.ExtensionAndIcon.Style}`}
                              >
                                <img
                                  src={each.ExtensionAndIcon.ListIcon}
                                  alt="MORdoc"
                                  width="13px"
                                  height="18px"
                                />
                              </span>
                              <span>{`${
                                each.ItemName.length > listDocChar
                                  ? each.ItemName.slice(0, listDocChar - 5) +
                                    "..." +
                                    each.ExtensionAndIcon.Extension
                                  : each.ItemName +
                                    each.ExtensionAndIcon.Extension
                              }`}</span>
                            </div>
                          </a>
                          {isConfig && (
                            <div className="toggle-group toggle-tick custom-toggle-tickbtn">
                              <input
                                type="checkbox"
                                name={"datatypechange" + index}
                                id={"datatypechange" + index}
                                //defaultChecked={true}
                                onChange={(e) => {
                                  this.handleMORToggle(e, each, index);
                                }}
                                checked={!each.HideFromReferences}
                              />
                              <label htmlFor={"datatypechange" + index}>
                                Connection Status
                              </label>
                              <div className="onoffswitch" aria-hidden="true">
                                <div className="onoffswitch-label">
                                  <div className="onoffswitch-inner"></div>
                                  <div className="onoffswitch-switch"></div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    );
                  })}
                {referenceDetailsData &&
                  _.map(referenceDetailsData, (each, index) => {
                    return (
                      <a class="dropdown-item">
                        <div>
                          <a
                            href={each.DocURL}
                            target={each.DocURL && "_blank"}
                            data-tip={each.DocName}
                            data-for="reference-link"
                            data-tip-disable={each.DocName.length <= listDocChar}
                          >
                            <span className="reference-icons reference-links"></span>
                            {each.DocName.length > listDocChar ? each.DocName.slice(0, listDocChar - 5) +  "..." : each.DocName}
                            <ReactTooltip place="bottom" type="dark" effect="float" id="reference-link"/>
                          </a>
                        </div>
                        <div className="references-actions">
                          {this.state.accessControl !== "View" && (
                            <span
                              data-tip
                              data-for="editIcon"
                              onClick={(e) =>
                                this.editReferenceModalShow(e, each, index)
                              }
                              className="edit-icon"
                            >
                              <ReactTooltip
                                place="bottom"
                                type="dark"
                                effect="float"
                                id="editIcon"
                                className="snap-tooltip"
                              >
                                <span className="ml-0">Edit</span>
                              </ReactTooltip>
                            </span>
                          )}
                          {accessControl !== "View" && (
                            <span
                              data-tip
                              data-for="deleteIcon"
                              onClick={(e) =>
                                this.deleteReferenceDetails(e, each)
                              }
                              className={"delete-icon"}
                            >
                              <ReactTooltip
                                place="bottom"
                                type="dark"
                                effect="float"
                                id="deleteIcon"
                                className="snap-tooltip"
                              >
                                <span className="ml-0">Delete</span>
                              </ReactTooltip>
                            </span>
                          )}
                          <span
                            data-tip="custom show"
                            data-event="click focus"
                            data-for="infoIcon tooltip"
                            className="summary-icon"
                            ref ={ ref => this.Tooltip = ref}
                            onClick={this.showInfoData.bind(this, index)}
                          >
                         
                      <ReactTooltip
                      place="left"
                      type="dark"
                      effect="solid"
                      id="infoIcon tooltip"
                      className="reference-info-tooltip"
                      clickable={true}
                      isCapture={true}
                    >
                      <div className="reference-info">
                        <div className="info-description">
                          <h2>Description</h2>
                          <p>{toolTipData.DocDescription}</p>
                        </div>
                        <div className="info-author">
                          <h2>Added By</h2>
                          <p>
                            <img src={userImg} />{" "}
                            {toolTipData.CreatedBy}
                          </p>
                        </div>
                        <div className="info-date">
                          <h2>Added On</h2>
                          <p>{toolTipData.CreatedTS}</p>
                        </div>
                      </div>
                    </ReactTooltip>
                    </span>
                        </div>
                      </a>
                    );
                  })}
                  {_.isEmpty(MorItemData) && _.isEmpty(referenceDetailsData) && <div className="no-references-text"><p>{referenceFlag ? i18n.t("No references available") : i18n.t("Loading....")}</p></div>}
              </div>
            </div>
          </div>
          {isMORShowDocViewer && (
            <MORDocViewer
              isMORShowDocViewer={isMORShowDocViewer}
              ViewerItemDeatils={ViewerItemDetails}
              DocDetails={Doctype}
              isMORDoc={MorDocInfo}
              allData={MORItemData}
              closeViewer={this.closeViewer}
              AllViewerItemDeatils={MorItemData.filter((a) => !a.IsFolder && a.HideFromReferences !== true)}
            />
          )}
          {addReferenceStatus && (
            <AddEditReferenceModal
              {...this.props}
              SOCatID={SOCatID}
              status="Add"
              show={addReferenceStatus}
              fetchInitialDetails={this.fetchInitialDetails}
              leverName={leverName}
              close={this.handleAddReferenceClose}
              isClientStory={isClientStory}
            />
          )}
          {editReferenceStatus && (
            <AddEditReferenceModal
              {...this.props}
              data={selectedReferences}
              SOCatID={this.props.SOCatID}
              status="Edit"
              show={editReferenceStatus}
              fetchInitialDetails={this.fetchInitialDetails}
              leverName={leverName}
              isClientStory={isClientStory}
              close={this.handleEditReferenceClose}
            />
          )}
        </>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
  };
};

export default connect(mapStateToProps)(References);
