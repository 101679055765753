import React from 'react';
import ReactTooltip from 'react-tooltip';

import Dropdown from 'react-bootstrap/Dropdown';

import _, { parseInt } from 'lodash';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { LocalApiBaseUrl } from '../../Shared/Constant';
import axiosInstance from '../../Shared/interceptor';
import SubOfferingDropdowns from '../../Shared/SubOfferingDropdowns';
import { filterSelectedSubOffering } from '../../Shared/commonFunctions';

class AssociationsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formInitialValues: '',
      subofferingList: [],
      locationList: [],
      omidList: [],
      filteredData: {
        selectedOffering: '',
        selectedSubOffering: '',
        selectedLocation: '',
        selectedOfferingName: i18n.t('Offering'),
        selectedSubOfferingName: i18n.t('Sub_Offering'),
        selectedLocationName: i18n.t('Location'),
        selecetedOMIDName: i18n.t('Process_OM_ID'),
        selecetedOMID: '',
      },
      validationMessage: {
        selectedOffering: '',
        selectedSubOffering: '',
        selectedLocation: '',
        selecetedOMID: '',
        associationMessage: '',
      },
      associationList: [],
      editMode: false,
    };
  }

  componentDidMount() {
    const { associationData } = this.props;
    if (associationData.associationList.length) {
      associationData.associationList = associationData.associationList.map(
        (each) => {
          if (!each.isEditMode) {
            each.isEditMode = false;
          }
          return each;
        },
      );
    }
    this.setState({
      associationList: associationData.associationList,
      filteredData: associationData.filteredData,
    });
    if (associationData.filteredData.selectedOffering) {
      this.onOfferingChange(
        associationData.filteredData.selectedOffering,
        associationData.filteredData.selectedOfferingName,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { associationData } = this.props;
    const { filteredData } = this.state;
    if (
      prevProps.associationData.filteredData !== associationData.filteredData
    ) {
      if (
        filteredData
        && associationData.filteredData
        && parseInt(associationData.filteredData.selectedOffering)
          !== parseInt(filteredData.selectedOffering)
      ) {
        this.onOfferingChange(
          associationData.filteredData.selectedOffering,
          associationData.filteredData.selectedOfferingName,
        );
      }
    }
  }

  onOfferingChange = (id, name) => {
    const { offeringData, setAssociationData, associationData } = this.props;
    const { filteredData, validationMessage, associationList } = this.state;
    filteredData.selectedOffering = id;
    filteredData.selectedSubOffering = '';
    filteredData.selectedLocation = '';
    filteredData.selectedOfferingName = name;
    filteredData.selectedSubOfferingName = i18n.t('Sub_Offering');
    filteredData.selectedLocationName = i18n.t('Location');
    filteredData.selecetedOMID = '';
    filteredData.selecetedOMIDName = i18n.t('Process_OM_ID');
    let subofferingList = [];
    validationMessage.selectedOffering = '';
    validationMessage.selectedSubOffering = '';
    validationMessage.selectedLocation = '';
    validationMessage.selecetedOMID = '';
    validationMessage.associationMessage = '';
    offeringData.forEach((each) => {
      if (parseInt(each.OfferingID) === parseInt(id)) {
        subofferingList = each.ChildOfferings;
      }
    });
    if (
      associationData.filteredData.selectedOffering === id
      && associationData.filteredData.selectedSubOffering
    ) {
      this.oneditSubOfferingChange(
        associationData.filteredData.selectedSubOffering,
        associationData.filteredData.selectedSubOfferingName,
      );
    } else {
      setAssociationData({ filteredData, associationList });
    }

    this.setState({ filteredData, subofferingList, validationMessage });
  };

  oneditSubOfferingChange = (id, name) => {
    const { ClientData, setAssociationData, associationData } = this.props;
    const { filteredData, validationMessage, associationList } = this.state;
    const filtersSubofferingData = filterSelectedSubOffering([...this.state.subofferingList], id);
    const selectedSuboffering = filtersSubofferingData.subofferingData[0];
    filteredData.selectedSubOffering = id;
    filteredData.selectedLocation = '';
    filteredData.selectedSubOfferingName = name;
    filteredData.selectedLocationName = i18n.t('Location');
    filteredData.selecetedOMID = '';
    filteredData.selecetedOMIDName = i18n.t('Process_OM_ID');
    validationMessage.selectedOffering = '';
    validationMessage.selectedSubOffering = '';
    validationMessage.selectedLocation = '';
    validationMessage.selecetedOMID = '';
    validationMessage.associationMessage = '';
    this.setState({ filteredData, validationMessage });
    if (associationData.filteredData.selectedSubOffering !== id) {
      setAssociationData({ filteredData, associationList });
    }
    this.getOMIDLocations(ClientData.id, id);
  };

  onSubOfferingChange = (e) => {
    const { ClientData, setAssociationData, associationData } = this.props;
    const { filteredData, validationMessage, associationList } = this.state;
    const filtersSubofferingData = filterSelectedSubOffering([...this.state.subofferingList], e.target.value);
    const selectedSuboffering = filtersSubofferingData.subofferingData[0];

    filteredData.selectedSubOffering = e.target.value;
    filteredData.selectedLocation = '';
    filteredData.selectedSubOfferingName = selectedSuboffering.Process;
    filteredData.selectedLocationName = i18n.t('Location');
    filteredData.selecetedOMID = '';
    filteredData.selecetedOMIDName = i18n.t('Process_OM_ID');
    validationMessage.selectedOffering = '';
    validationMessage.selectedSubOffering = '';
    validationMessage.selectedLocation = '';
    validationMessage.selecetedOMID = '';
    validationMessage.associationMessage = '';
    this.setState({ filteredData, validationMessage });
    if (associationData.filteredData.selectedSubOffering !== e.target.value) {
      setAssociationData({ filteredData, associationList });
    }
    this.getOMIDLocations(ClientData.id, e.target.value);
  };

  getOMIDLocations = (Client, offering) => {
    const { languageData, associationData } = this.props;
    axiosInstance
      .post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetJourneyOMIDs`, {
        OrgEntityID: Client,
        OfferingID: offering,
        LanguageID: languageData.id,
        IsConfigurator: true,
      })
      .then((response) => {
        if (response.data) {
          this.setState({ locationList: response.data.IOJOMIDs }, () => {
            if (
              associationData.filteredData.selectedSubOffering === offering
              && associationData.filteredData.selectedLocation
            ) {
              this.onLocationChange(
                associationData.filteredData.selectedLocation,
                associationData.filteredData.selectedLocationName,
              );
            }
          });
        }
      })
      .catch((error) => {
        window.alert(error.response.data.Message);
        console.log(error);
      });
  };

  onLocationChange = (id, name) => {
    const { setAssociationData, associationData } = this.props;
    const {
      filteredData,
      locationList,
      validationMessage,
      associationList,
    } = this.state;
    let omidLists = [];
    filteredData.selectedLocation = id;
    filteredData.selectedLocationName = name;
    filteredData.selecetedOMID = '';
    filteredData.selecetedOMIDName = i18n.t('Process_OM_ID');
    locationList.forEach((each) => {
      if (parseInt(each.locID) === parseInt(id)) {
        omidLists = each.IOJOMIDDatas;
      }
    });
    validationMessage.selectedOffering = '';
    validationMessage.selectedSubOffering = '';
    validationMessage.selectedLocation = '';
    validationMessage.selecetedOMID = '';
    validationMessage.associationMessage = '';
    if (
      associationData.filteredData.selectedLocation === id
      && associationData.filteredData.selecetedOMID
    ) {
      this.onOmidChange(associationData.filteredData.selecetedOMID);
    } else {
      setAssociationData({ filteredData, associationList });
    }
    this.setState({ filteredData, omidList: omidLists, validationMessage });
  };

  onOmidChange = (id) => {
    const { setAssociationData } = this.props;
    const { filteredData, validationMessage, associationList } = this.state;
    filteredData.selecetedOMID = id;
    filteredData.selecetedOMIDName = id;
    validationMessage.selectedOffering = '';
    validationMessage.selectedSubOffering = '';
    validationMessage.selectedLocation = '';
    validationMessage.selecetedOMID = '';
    validationMessage.associationMessage = '';
    setAssociationData({ filteredData, associationList });
    this.setState({ filteredData, validationMessage });
  };

  componentWillUnmount() {
    const { setAssociationData } = this.props;
    const {
      filteredData,
      validationMessage,
      associationList,
      omidList,
    } = this.state;
    setAssociationData({
      filteredData,
      associationList,
      validationMessage,
      omidList,
    });
  }

  clearAssociation = () => {
    const { setAssociationData } = this.props;
    let {
      filteredData, validationMessage, associationList, omidList,
    } = this.state;
    associationList = associationList.map((each) => {
      each.isEditMode = false;
      return each;
    });
    omidList = [];
    filteredData = {
      selectedOffering: '',
      selectedSubOffering: '',
      selectedLocation: '',
      selectedOfferingName: i18n.t('Offering'),
      selectedSubOfferingName: i18n.t('Sub_Offering'),
      selectedLocationName: i18n.t('Location'),
      selecetedOMIDName: i18n.t('Process_OM_ID'),
      selecetedOMID: '',
    };
    validationMessage = {
      selectedOffering: '',
      selectedSubOffering: '',
      selectedLocation: '',
      selecetedOMID: '',
      associationMessage: '',
    };
    this.setState({
      filteredData, validationMessage, omidList, editMode: false, associationList,
    });
    setAssociationData({
      filteredData, associationList, validationMessage, omidList,
    });
  };

  addAssociation = () => {
    const { filteredData, associationList } = this.state;
    const {
      offeringData,
      ClientData,
      values,
      setAssociationData,
      languageData,
      journeyStageId,
      formType,
    } = this.props;
    if (this.validateAssociation()) {
      const removedEditModeData = associationList.filter(
        (each) => !each.isEditMode,
      );
      const associationDetails = [...removedEditModeData];
      const currentDetails = {
        ConfigurationIntelOpsInitiativeID: 0,
        InitiativeName: values.initiativeName.trim(),
        SubOfferingID: filteredData.selectedSubOffering,
        Offering: offeringData.filter(
          (each) => parseInt(each.OfferingID)
            === parseInt(filteredData.selectedOffering),
        )[0].OfferingName_En,

        OrgentityID: ClientData.id,
        LocationID: filteredData.selectedLocation,
        IOJStageID: journeyStageId,
        OMID: filteredData.selecetedOMID,
      };
      associationDetails.push(currentDetails);
      axiosInstance
        .post(
          `${LocalApiBaseUrl}IOJJourneyInitiative/CheckValidationIOJAssociation?LanguageID=${
            languageData.id
          }&IsDraft=${formType === 'draftEdit'}`,
          associationDetails,
        )
        .then((response) => {
          if (response.data) {
            const finalData = response.data;
            if (response.data.length) {
              response.data = response.data.map((each) => {
                if (!each.isEditMode) {
                  each.isEditMode = false;
                }
              });
            }
            this.setState(
              {
                filteredData: {
                  selectedOffering: '',
                  selectedSubOffering: '',
                  selectedLocation: '',
                  selectedOfferingName: i18n.t('Offering'),
                  selectedSubOfferingName: i18n.t('Sub_Offering'),
                  selectedLocationName: i18n.t('Location'),
                  selecetedOMIDName: i18n.t('Process_OM_ID'),
                  selecetedOMID: '',
                },
                associationList: finalData,
                omidList: [],
                locationList: [],
                subofferingList: [],
                editMode: false,
              },
              () => {
                setAssociationData({
                  filteredData: {
                    selectedOffering: '',
                    selectedSubOffering: '',
                    selectedLocation: '',
                    selectedOfferingName: i18n.t('Offering'),
                    selectedSubOfferingName: i18n.t('Sub_Offering'),
                    selectedLocationName: i18n.t('Location'),
                    selecetedOMIDName: i18n.t('Process_OM_ID'),
                    selecetedOMID: '',
                  },
                  associationList: finalData,
                });
              },
            );
          }
        })
        .catch((error) => {
          window.alert(error.response.data.Message);
          console.log(error);
        });
    }
  };

  onEditAssociation = (data) => {
    let { associationList, editMode } = this.state;
    const { offeringData, setAssociationData } = this.props;

    const filteredData = {
      selectedOffering: '',
      selectedSubOffering: '',
      selectedLocation: '',
      selectedOfferingName: i18n.t('Offering'),
      selectedSubOfferingName: i18n.t('Sub_Offering'),
      selectedLocationName: i18n.t('Location'),
      selecetedOMIDName: i18n.t('Process_OM_ID'),
      selecetedOMID: '',
    };

    associationList = associationList.map((each) => {
      if (
        each.Offering === data.Offering
        && each.SubOfferingID === data.SubOfferingID
        && each.LocationID === data.LocationID
        && each.OMID === data.OMID
      ) {
        filteredData.selectedOffering = offeringData.filter(
          (offering) => offering.OfferingName === data.Offering,
        )[0].OfferingID;
        filteredData.selectedOfferingName = data.Offering;
        filteredData.selectedSubOffering = data.SubOfferingID;
        filteredData.selectedSubOfferingName = data.Process;
        filteredData.selectedLocation = data.LocationID;
        filteredData.selectedLocationName = data.City;
        filteredData.selecetedOMIDName = data.OMID;
        filteredData.selecetedOMID = data.OMID;
        each.isEditMode = true;
      }
      return each;
    });
    setAssociationData({
      filteredData,
      associationList,
    });
    this.setState({ editMode: !editMode });
  };

  validateAssociation = () => {
    const {
      filteredData,
      validationMessage,
      omidList,
      associationList,
    } = this.state;
    const {
      SelectedFilter,
      values,
      journeyStageId,
      isInitativeManagement,
    } = this.props;
    let formValid = true;
    if (!filteredData.selectedOffering) {
      validationMessage.selectedOffering = i18n.t('offering_required');
      formValid = false;
    } else if (
      isInitativeManagement
      && (_.isEmpty(journeyStageId) || parseInt(journeyStageId) <= 0)
    ) {
      validationMessage.associationMessage = i18n.t(
        'initiative_stage_required',
      );
      formValid = false;
    } else if (!filteredData.selectedSubOffering) {
      validationMessage.selectedOffering = '';
      validationMessage.selectedSubOffering = i18n.t('sub_offering_required');
      formValid = false;
    } else if (!filteredData.selectedLocation) {
      validationMessage.selectedOffering = '';
      validationMessage.selectedSubOffering = '';
      validationMessage.selectedLocation = i18n.t('location_required');
      formValid = false;
    } else if (omidList.length && !filteredData.selecetedOMID) {
      validationMessage.selectedOffering = '';
      validationMessage.selectedSubOffering = '';
      validationMessage.selectedLocation = '';
      validationMessage.selecetedOMID = i18n.t('omid_required');
      formValid = false;
    } else {
      validationMessage.selectedOffering = '';
      validationMessage.selectedSubOffering = '';
      validationMessage.selectedLocation = '';
      validationMessage.selecetedOMID = '';
      formValid = true;
    }
    if (associationList.length) {
      const validAssocation = associationList.filter((each) => !each.isEditMode).filter((each) => each.Offering === filteredData.selectedOfferingName)
        .filter(
          (subOff) => parseInt(subOff.SubOfferingID)
              === parseInt(filteredData.selectedSubOffering),
        )
        .filter(
          (loc) => parseInt(loc.LocationID)
              === parseInt(filteredData.selectedLocation),
        )
        .filter((omid) => {
          if (omid.OMID && filteredData.selecetedOMID) {
            if (
              parseInt(omid.OMID) === parseInt(filteredData.selecetedOMID)
            ) {
              return omid;
            }
          } else if (!omid.OMID && !filteredData.selecetedOMID) {
            return omid;
          }
        }).length === 0;
      if (!validAssocation) {
        formValid = false;
      }
      if (!formValid) {
        validationMessage.associationMessage = i18n.t('association_exist');
      }
    }
    if (
      parseInt(SelectedFilter.LocationId)
        === parseInt(filteredData.selectedLocation)
      && parseInt(SelectedFilter.OfferingId)
        === parseInt(filteredData.selectedOffering)
      && parseInt(SelectedFilter.ProcessId)
        === parseInt(filteredData.selectedSubOffering)
      && (SelectedFilter.LocationOMID[0] === 'All'
        ? filteredData.selecetedOMID === ''
        : parseInt(SelectedFilter.LocationOMID[0])
          === parseInt(filteredData.selecetedOMID))
    ) {
      validationMessage.associationMessage = i18n.t('association_exist');
      formValid = false;
    }
    if (!values.initiativeName.trim()) {
      validationMessage.associationMessage = i18n.t('initiative_name_required');
      formValid = false;
    }
    this.setState({ validationMessage });
    return formValid;
  };

  onDeleteAssociation = (val) => {
    let { associationList } = this.state;
    associationList = associationList.filter((each, index) => index !== val);
    this.setState({ associationList });
  };

  handleToggleChange = (e, val) => {
    let { associationList } = this.state;
    associationList = associationList.map((each, index) => {
      if (index === val) {
        each.IsToggle = !each.IsToggle;
      }
      return each;
    });
    this.setState({ associationList });
  };

  render() {
    const { offeringData, formType } = this.props;
    const {
      filteredData,
      subofferingList,
      locationList,
      omidList,
      validationMessage,
      associationList,
      editMode,
    } = this.state;
    return (
      <div className="initiative-formtabcontent">
        <div className="initiative-formfields d-flex">
          {/* START - LEFT SIDE FORM SECTION */}
          <div className="leftinitiative-formfields">
            <form>
              <div className="form-section">
                <div className="form-group border-group">
                  <label htmlFor="Offering" className="form-label">
                    {i18n.t('Offering')}
                    {' '}
                    {filteredData.selectedOffering ? (
                      <span className="required">*</span>
                    ) : (
                      ''
                    )}
                  </label>
                  <div className="initiative-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle id="Offering">
                        {filteredData.selectedOfferingName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {offeringData.map((each) => (
                          <Dropdown.Item
                            value={each.OfferingID}
                            onClick={this.onOfferingChange.bind(
                              this,
                              each.OfferingID,
                              each.OfferingName,
                            )}
                          >
                            {each.OfferingName}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <p className="errorMsg">{validationMessage.selectedOffering}</p>
                <div className="form-group border-group">
                  <SubOfferingDropdowns
                    selectedSubOffering={filteredData.selectedSubOfferingName}
                    subOfferings={subofferingList}
                    onSubOfferingChange={(e) => this.onSubOfferingChange(e)}
                    defaultToggleName={filteredData.selectedSubOfferingName}
                    selectedSubOfferingId={filteredData.selectedSubOffering}
                    required={filteredData.selectedOffering}
                    labelClassName="form-label"
                    labelfor="SubOffering"
                    disabledValue={false}
                    dropdownItem={false}
                    labelpresent
                    SubOfferingDropdownsClass={false}
                  />
                </div>
                <p className="errorMsg">
                  {validationMessage.selectedSubOffering}
                </p>
                <div className="form-group border-group">
                  <label htmlFor="Location" className="form-label">
                    {i18n.t('Location')}
                    {' '}
                    {filteredData.selectedOffering ? (
                      <span className="required">*</span>
                    ) : (
                      ''
                    )}
                  </label>
                  <div className="initiative-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle id="Location">
                        {filteredData.selectedLocationName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {locationList.map((each) => (
                          <Dropdown.Item
                            value={each.locID}
                            onClick={this.onLocationChange.bind(
                              this,
                              each.locID,
                              each.locname,
                            )}
                          >
                            {each.locname}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <p className="errorMsg">{validationMessage.selectedLocation}</p>
                <div className="form-group border-group">
                  <label htmlFor="Process" className="form-label">
                    {i18n.t('Process_OM_ID')}
                    {' '}
                    {(!omidList.length && filteredData.selectedLocation)
                    || !filteredData.selectedOffering ? (
                        ''
                      ) : (
                        <span className="required">*</span>
                      )}
                    {/* Process/OM ID */}
                  </label>
                  <div className="initiative-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle disabled={!omidList.length} id="Process">
                        {filteredData.selecetedOMIDName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {omidList.map((each) => (
                          <Dropdown.Item
                            value={each.OMID}
                            onClick={this.onOmidChange.bind(this, each.OMID)}
                          >
                            {each.OMID}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <p className="errorMsg">{validationMessage.selecetedOMID}</p>
                <p className="errorMsg">
                  {validationMessage.associationMessage}
                </p>
              </div>
            </form>
            <div className="initiative-addcancel-btns">
              <button
                type="button"
                onClick={this.clearAssociation}
                className="btn btn-outline-primary mr-2"
              >
                {i18n.t('Cancel')}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.addAssociation}
              >
                {editMode ? i18n.t('Update') : i18n.t('Add')}
              </button>
            </div>
          </div>
          {/* END - LEFT SIDE FORM SECTION */}

          {/* START - RIGHT SIDE SECTION */}
          <div className="rightinitiative-formfields">
            <h6>{i18n.t('Associations_Added')}</h6>
            <div className="customscroll-form">
              {associationList
                && associationList.map((each, index) => (
                  <>
                    <div className="association-togglegroup d-flex align-items-center">
                      <div className="form-group border-group">
                        <div className="mectricimpacted-section">
                          <label className="form-label">
                            <span>{each.Offering}</span>
                            <span>{each.Process}</span>
                          </label>
                          <ul className="metricvalues-list">
                            <li>{each.City}</li>
                            {each.OMID && <li>{each.OMID}</li>}
                          </ul>
                        </div>
                        {(formType !== 'nonDraftEdit'
                            || parseInt(each.ID) === 0) && (
                            <div className="mectricimpacted-morelink">
                              <a
                                className="morelink-icon"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span>
                                  <i className="fal fa-ellipsis-v" />
                                </span>
                              </a>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <a
                                  className="dropdown-item edit-icon"
                                  onClick={this.onEditAssociation.bind(
                                    this,
                                    each,
                                  )}
                                >
                                  {i18n.t('Edit')}
                                </a>
                                <a
                                  className={`dropdown-item delete-icon ${each.isEditMode ? ' disabled' : ''}`}
                                  onClick={this.onDeleteAssociation.bind(this, index)}
                                >
                                  {i18n.t('Delete')}
                                </a>
                              </div>
                            </div>
                        )}
                      </div>
                      {formType === 'nonDraftEdit'
                          && parseInt(each.ID) !== 0 && (
                            <div
                              className="toggle-group toggle-tick"
                              data-tip
                              data-for={`toggleswitch-tooltip${index}`}
                            >
                              <input
                                type="checkbox"
                                id={`isActive + ${index}`}
                                checked={each.IsToggle}
                                onChange={(e) => {
                                  this.handleToggleChange(e, index);
                                }}
                              />
                              <label htmlFor={`isActive + ${index}`}>
                                {i18n.t('Is_Active')}
                              </label>
                              <div className="onoffswitch" aria-hidden="true">
                                <div className="onoffswitch-label">
                                  <div className="onoffswitch-inner" />
                                  <div className="onoffswitch-switch" />
                                </div>
                                {each.IsToggle ? (
                                  <ReactTooltip
                                    className="association-tooltip"
                                    place="bottom"
                                    type="dark"
                                    effect="solid"
                                    id={`toggleswitch-tooltip${index}`}
                                  >
                                    <span>
                                      {i18n.t('association_toggle_message_two')}
                                    </span>
                                  </ReactTooltip>
                                ) : (
                                  <ReactTooltip
                                    className="association-tooltip"
                                    place="bottom"
                                    type="dark"
                                    effect="solid"
                                    id={`toggleswitch-tooltip${index}`}
                                  >
                                    <span>
                                      {i18n.t('association_toggle_message_one')}
                                    </span>
                                  </ReactTooltip>
                                )}
                              </div>
                            </div>
                      )}
                    </div>
                    {!each.StatusType && each.StatusMessage && (
                    <p className="errorMsg">
                      {i18n.t('association_exist')}
                    </p>
                    )}
                  </>
                ))}
            </div>
          </div>
          {/* END - RIGHT SIDE SECTION */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  offeringData: state.ClientAndFilterReducer.configFilterData.offeringData,
  ClientData: state.CurrentFilter.ClientData,
  SelectedFilter: { ...state.CurrentFilter.SyncFilter },
});

export default connect(mapStateToProps)(AssociationsTab);
