import React from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { LocalApiBaseUrl } from "../Shared/Constant";
import i18n from "i18next";
import { connect } from "react-redux";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import SynopsLoader from "../Shared/SynopsLoader";
import _ from "lodash";

class AddAsserts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      isAPILoading: false,
      addassertDetails: this.props.details,
      assetData: [],
      tools: [],
      filteredAssets: [],
      selectedAssets: [],
      assetOwned: "Accenture",
      filter: "",
      showAddNewAsset: false,
      maxSequence: this.props.maxSequences,
    };
  }
  handleHide = () => {
    this.props.hideAddAsserts(false);
  };
  onAssetOwnedChange = (event) => {
    let assets = this.state.assetData.filter((each) => {
      let returnVal = false;
      if (each.AssestType === event.target.value) {
        returnVal = true;
        return each;
      }
      return returnVal;
    });

    let data;
    if (!_.isNil(assets[0])) {
      assets = assets[0].applicationMasterModels.map((data) => ({
        ...data,
        checked: false,
      }));
      data = assets;
    } else {
      data = [];
    }

    this.setState({
      assetOwned: event.target.value,
      filteredAssets: data,
      selectedAssets: [],
      filter: "",
    });
  };
  componentDidMount() {
    this.setState({
      isAPILoading: true,
    });
    axiosInstance
      .get(`${LocalApiBaseUrl}PulseDashBoard/GetAllAsset`, {
        params: {
          languageID: this.props.languageData.id,
          OrgEntityID: this.props.clientData.id,
          OfferingID: this.props.offeringId,
          SoCategoryID: this.props.TileID,
        },
      })
      .then((response) => {
        this.setState({
          isAPILoading: false,
        });
        if (response.data) {
          let ApplicationMappingIDs = [];
          let pulsePageData = [...this.props.pulsePageData];
          pulsePageData.map((eachDetail) => {
            if (eachDetail.TileName_En === this.props.details.tabName_En) {
              eachDetail.pulseDashBoardApplicaionModels.map((eachval) => {
                ApplicationMappingIDs.push(eachval.ApplicationMappingID);
              });
            }
          });
          let assertDetails = [];

          let wholeData = [...response.data];
          wholeData.map((each) => {
            if (each.OfferingId === this.props.offeringId) {
              each.AssetsData.map((asset) => {
                if (asset.SoCategoryName_En === this.props.details.tabName_En) {
                  assertDetails = asset.ToolsDetails;
                }
              });
            }
          });
          let assetsdata = [];

          assertDetails &&
            assertDetails.filter((each) => {
              let tools = { ...each, applicationMasterModels: [] };

              each.applicationMasterModels.filter((tool) => {
                if (
                  !ApplicationMappingIDs.includes(tool.ApplicationMappingID)
                ) {
                  tool["checked"] = false;
                  tools.applicationMasterModels.push(tool);
                }
                return tool;
              });

              assetsdata.push(tools);
            });
          let assets = [];

          if (assetsdata.length) {
            assets = assetsdata.filter((each) => {
              let returnVal = false;
              if (each.AssestType === this.state.assetOwned) {
                returnVal = true;

                each["checked"] = false;
                return each;
              }
              return returnVal;
            });
          }
          if (assets.length > 0) {
            assets = assets[0].applicationMasterModels.map((data) => ({
              ...data,
              checked: false,
            }));
          }

          this.setState({
            filteredAssets: assets,
            assetData: assetsdata,
            tools: response.data,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  fliterAsserts = (event) => {
    let updatedData = [];
    let assets = this.state.assetData.filter((each) => {
      if (each.AssestType === this.state.assetOwned) {
        each.applicationMasterModels.map((data) => {
          if (
            data.ApplicationName_En.toUpperCase().includes(
              event.target.value.toUpperCase()
            )
          ) {
            updatedData.push(data);
          }
        });
      }
    });
    this.setState({ filter: event.target.value, filteredAssets: updatedData });
  };

  onAssetSelection = (details, event) => {
    if (event.target.checked) {
      let selectedAssets = [...this.state.selectedAssets];
      let assets = this.state.filteredAssets.map((each) => {
        if (each.ApplicationMappingID === details.ApplicationMappingID) {
          each["checked"] = true;
          return each;
        } else {
          return each;
        }
      });
      let mainAssets = this.state.assetData.map((eachval) => {
        if (eachval.AssestType === this.state.assetOwned) {
          eachval.applicationMasterModels.map((tool) => {
            if (tool.ApplicationMappingID === details.ApplicationMappingID) {
              tool["checked"] = true;
            }
            return tool;
          });
        }
        return eachval;
      });
      selectedAssets.push(details);
      this.setState({
        selectedAssets: selectedAssets,
        filteredAssets: assets,
        assets: mainAssets,
      });
    } else {
      let selectedAssets = [...this.state.selectedAssets];
      let assets = this.state.filteredAssets.map((each) => {
        if (each.ApplicationMappingID === details.ApplicationMappingID) {
          each["checked"] = false;
          return each;
        } else {
          return each;
        }
      });
      let mainAssets = this.state.assetData.map((eachval) => {
        if (eachval.AssestType === this.state.assetOwned) {
          eachval.applicationMasterModels.map((tool) => {
            if (tool.ApplicationMappingID === details.ApplicationMappingID) {
              tool["checked"] = false;
            }
            return tool;
          });
        }
        return eachval;
      });
      let updatedAssets = selectedAssets.filter(
        (each) => each.ApplicationMappingID !== details.ApplicationMappingID
      );
      this.setState({
        selectedAssets: updatedAssets,
        filteredAssets: assets,
        assets: mainAssets,
      });
    }
  };
  onToolSelection = () => {
    let selectedasset = this.state.selectedAssets;
    let maxSequence = this.state.maxSequence;
    let indexMax = maxSequence.findIndex((f) => {
      return String(f.SOCategory) === String(this.props.TileID);
    });

    let maxSeq = indexMax > -1 ? maxSequence[indexMax].maxSequence : 0;
    selectedasset
      .sort((a, b) =>
        a.ApplicationName_En.toLowerCase() > b.ApplicationName_En.toLowerCase()
          ? 1
          : -1
      )
      .forEach((each) => {
        maxSeq = maxSeq === 999999 ? 999999 : maxSeq + 1;
        each.SequenceNumber = maxSeq;
      });

    maxSequence[indexMax] = { ...maxSequence[indexMax], maxSequence: maxSeq };

    const toolData = {
      offering: this.props.details.Offering,
      subOffering: this.props.details.SubOffering,
      offeringId: this.props.offeringId,
      lever: this.props.details.tabName_En,
      data: this.state.selectedAssets,
    };
    const apiData = {
      ApplicationMappingID: this.state.selectedAssets,
      OfferingID: this.props.offeringId,
      OrgEntityID: this.props.clientData.id,
    };
    this.props.handleLoader(true);
    axiosInstance
      .post(`${LocalApiBaseUrl}PulseDashBoard/AutoSaveToolMapping`, apiData)
      .then((response) => {
        this.props.handleLoader(false);
        if (response.data.IsSuccess) {
          this.props.getPulseQualityScore();
        } else {
          window.alert(response.data.StatusMessage);
          this.props.handleLoader(false);
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
    store.dispatch({
      type: "PULSE_ADD_TOOL",
      payload: toolData,
    });

    store.dispatch({
      type: "PULSE_MAX_SEQUENCE",
      payload: maxSequence,
    });
    this.handleHide();
  };
  render() {
    return (
      <>
        <LoadingOverlay
          className="custom-loader"
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={this.state.isAPILoading}
        ></LoadingOverlay>
        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          className="modal fade wo-configurator-modal"
          bsSize="medium"
          id="addPulseAssets"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">
              {this.state.addassertDetails.tabName
                ? this.state.addassertDetails.tabName
                : ""}{" "}
              - {i18n.t("Add_Asset")}
              &nbsp;&nbsp;
              {String(this.state.addassertDetails.tabName) ===
              "Human + Machine Talent" ? (
                <span>
                  <OverlayTrigger
                    key="top"
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {"Resource (Human/Machine) utilization tools"}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </OverlayTrigger>
                </span>
              ) : (
                ""
              )}
              {String(this.state.addassertDetails.tabName) ===
              "Work Orchestration" ? (
                <span>
                  <OverlayTrigger
                    key="top"
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {"Workflow/ Process flow related tools"}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </OverlayTrigger>
                </span>
              ) : (
                ""
              )}
              {String(this.state.addassertDetails.tabName) ===
              "Insights & Intelligence" ? (
                <span>
                  <OverlayTrigger
                    key="top"
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {"Business Insights and business decision making tools"}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </OverlayTrigger>
                </span>
              ) : (
                ""
              )}
              {String(this.state.addassertDetails.tabName) ===
              "Diverse Data" ? (
                <span>
                  <OverlayTrigger
                    key="top"
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-top">{"Benchmarking tools"}</Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </OverlayTrigger>
                </span>
              ) : (
                ""
              )}
            </h4>
          </Modal.Header>
          <div className="p-l-30 addPulseAssets-margin">
            <div className="row">
              <div className="col-sm-4 p-t-2">
                <label className="form-check">
                  <input
                    type="radio"
                    name={`AssetsOwned`}
                    value="Accenture"
                    onChange={this.onAssetOwnedChange}
                    checked={
                      this.state.assetOwned === "Accenture" ? true : false
                    }
                    className="form-check-input"
                  />
                  <span className="checkmark" />
                  <span className="form-check-label assets-radio-align">
                    {i18n.t("Accenture Assets")}
                  </span>
                </label>
              </div>
              <div className="col-sm-3 p-t-2">
                <label className="form-check">
                  <input
                    type="radio"
                    name={`AssetsOwned`}
                    value="Client"
                    onChange={this.onAssetOwnedChange}
                    checked={this.state.assetOwned === "Client" ? true : false}
                    className="form-check-input"
                  />
                  <span className="checkmark" />
                  <span className="form-check-label assets-radio-align">
                    {i18n.t("Client Assets")}
                  </span>
                </label>
              </div>
              <div className="col-sm-5">
                <div className="assetsFilter">
                  <input
                    type="search"
                    value={this.state.filter}
                    placeholder={"Search"}
                    onChange={this.fliterAsserts}
                  />
                </div>
              </div>
            </div>
          </div>

          <Modal.Body>
            {this.state.filteredAssets
              ? this.state.filteredAssets.map((each, index) => {
                  return (
                    <div
                      className={`row form-check addPulseAssets-margin ${
                        index !== 0 ? "p-t-10" : ""
                      }`}
                      key={index}
                    >
                      <div className="col-sm-1">
                        <label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="toolowner"
                            checked={each.checked}
                            onChange={this.onAssetSelection.bind(this, each)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-11 form-check">
                        <h4 style={{ fontStyle: "bold" }} className="m-b-0">
                          {each.URL ? (
                            <a
                              href={
                                each.URL.startsWith("www")
                                  ? "https://" + each.URL
                                  : each.URL
                              }
                              target="_blank"
                            >
                              {each.ApplicationName}
                            </a>
                          ) : (
                            each.ApplicationName
                          )}
                        </h4>
                        <p>{each.ApplicationDescription}</p>
                      </div>
                    </div>
                  );
                })
              : null}
          </Modal.Body>
          {
            <Modal.Footer>
              <div>
                <button
                  onClick={this.props.showAddNewAsset}
                  className="btn btn-primary btn-sm mr-2"
                >
                  {i18n.t("Add New Asset")}
                </button>
                <span className="float-right">
                  <button
                    onClick={() => {
                      this.handleHide();
                    }}
                    className="btn btn-default brder-2 btn-sm mr-2"
                  >
                    {i18n.t("Cancel")}
                  </button>
                  {this.state.filteredAssets.filter((each) => {
                    if (each.checked === true) {
                      return each;
                    }
                  }).length ? (
                    <button
                      onClick={() => {
                        this.onToolSelection();
                      }}
                      className="btn btn-primary btn-sm mr-2"
                    >
                      {i18n.t("Select")}
                    </button>
                  ) : (
                    <button
                      disabled="disabled"
                      className="btn btn-primary btn-sm mr-2"
                    >
                      {i18n.t("Select")}
                    </button>
                  )}
                </span>
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    pulseWholeData: [...state.CurrentFilter.PulseData.data],
    clientData: state.CurrentFilter.ClientData,
    languageData: state.CurrentFilter.languageData,
    maxSequence: state.CurrentFilter.pulseMaxSequence,
  };
};

export default connect(
  mapStateToProps,
  ""
)(AddAsserts);
