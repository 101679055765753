import {
  JOURNEY_INITIATIVE_DATA_INITIALIZE,
  JOURNEY_INITIATIVE_DATA_ADD,
  JOURNEY_INITIATIVE_DATA_REMOVE,
  JOURNEY_INITIATIVE_BOI_LIST_INITIALIZE,
  JOURNEY_INITIATIVE_II_METRICS_INITIALIZE,
  JOURNEY_INITIATIVE_DATAS,
  JOURNEY_INITIATIVE_DATA_REMOVE_FROM_INITATIVEDETAIL,
  JOURNEY_INITIATIVE_DATA_IOJCATEGORY,
  JOURNEY_INITIATIVE_DATA_HIDDEN,
  JOURNEY_INITIALIZE_IOJ_DATA,
  JOURNEY_CLEAR_DATAS,
  JOURNEY_CLEAR_BOIIDATAS,
  SAVE_JOURNEY_TAB_DATA,
  INCREMENT_GET_DATA_COUNTER_VALUE,
  STORE_ACCESS,
  JOURNEY_INITIALIZE_IOJOM_RATING_SCORE,
  JOURNEY_SET_OMIDSTATUS,
  JOURNEY_INITIALIZE_OMIDLIST,
  JOURNEY_ALL_SELECTION,
  API_LOAD,
  JOURNEY_SET_SUBOFFERING_NAME,
  JOURNEY_SET_ISACTION,
  JOURNEY_STATUS_DATA_INITIALIZE
} from "../actions/JourneyActions";

const initialJourneyState = {
  Datas: {
    isAction:false,
    isAccess:false,
    isGetData:false,
    viewData: {},
    StageData: [],
    BoiList: [],
    IImetrics:[],
    isInitativeManagement:false,
    filterDetails:{},
    isDisableAdd:false,
    initiativeMangementInitiativesModified:[],
    initiativeMangementInitiativesStatic:[],
    originalStatusData:[],
  },
  JourneyInitiatives: [],
  IOJCategory:[],
  IOJData:[],
  JourneyData:{ConfiguratorID:"",IOJGlidePathAllModels:[],IsIOJCategory:false,},
  RatingScore:{
    IOJRating:"", 
    IOJScore:"",
    OMRating:"",
  },
  getDataCounter:0,
  OMIDEnabled:true,  
  ActiveToggleCount:0,
  OMIDDataList:[],
  allSelected : false,
  subOfferingData : {
    subOfferingName : '',
    subOfferingID : ''
  }
};

export let JourneyReducer = (state = initialJourneyState, action) => {
  switch (action.type) {
    case STORE_ACCESS:{
      state = { ...state };
      state["Datas"]["isAccess"]=action.payload.isAccess
      return state;
    }
    case JOURNEY_SET_ISACTION:{
      state = { ...state };
      if(action.payload.isAction){
      state["Datas"]["isAction"]=action.payload.isAction
      state["Datas"]["filterDetails"] ={};
      }
      return state;
    }
    case JOURNEY_CLEAR_BOIIDATAS:{
      state = { ...state };
      state["Datas"]={
        isGetData:action.payload.isGetData,
        viewData:state["Datas"]["viewData"],
        StageData:action.payload.isGetData?[]:state["Datas"]["StageData"],
        BoiList: [],
        IImetrics:[],
      }
      return state;
    }
    
    case JOURNEY_INITIATIVE_DATAS: {
      state = { ...state };
      state["Datas"]["viewData"] = action.payload.viewData;
      state["Datas"]["StageData"] = action.payload.StageData;
      state["Datas"]["isInitativeManagement"] = action.payload.isInitativeManagement;
      state["Datas"]["filterDetails"] = action.payload.filterDetails;
      state["Datas"]["initiativeMangementInitiativesModified"] = action.payload.initiativeMangementInitiativesModified
      state["Datas"]["initiativeMangementInitiativesStatic"] = action.payload.initiativeMangementInitiativesStatic;
      state["Datas"]["isDisableAdd"]=action.payload.isDisableAdd;
      state["Datas"]["originalStatusData"]=action.payload.originalStatusData
      return state;
    }

    case JOURNEY_STATUS_DATA_INITIALIZE: {
      state = { ...state };
      state["Datas"]["originalStatusData"]=action.payload.originalStatusData
      return state;
    }

    case JOURNEY_INITIATIVE_DATA_INITIALIZE: {
      state = { ...state };
      state["JourneyInitiatives"] = action.payload;
      return state;
    }

    case JOURNEY_INITIATIVE_DATA_ADD: {
      state = { ...state };
      if(state["Datas"]["StageData"].length>0){
      let StageIndex=state["Datas"]["StageData"].findIndex(
        (x) => x.IntelOpsInitiativesID === action.payload.IntelOpsInitiativesID
      );
     if(StageIndex>=0){///AVOID STATE MUTATION WHILE EDITING
      let Editdata =[...state["Datas"]["StageData"]];
      Editdata[StageIndex]=action.payload
      state["Datas"]["StageData"] = Editdata;
      }
    }

      let index = state["JourneyInitiatives"].findIndex(
        (x) => x.IntelOpsInitiativesID === action.payload.IntelOpsInitiativesID
      );

      if (index>=0) {///AVOID STATE MUTATION WHILE EDITING
        let data =[...state["JourneyInitiatives"]];
        data[index]=action.payload
        state["JourneyInitiatives"] = data;
      } else {
        let data =[action.payload,...state["JourneyInitiatives"]];
        state["JourneyInitiatives"] = data;
      }
      return state;
    }

    case JOURNEY_INITIATIVE_DATA_REMOVE: {
      state = { ...state };
      state["JourneyInitiatives"] = state["JourneyInitiatives"].filter((e) => e.IntelOpsInitiativesID != action.payload.deletedInitiative);
     

      return state;
    }
    case JOURNEY_INITIATIVE_DATA_REMOVE_FROM_INITATIVEDETAIL: {
      state = { ...state };
      state["JourneyInitiatives"] = state["JourneyInitiatives"].filter((e) => e.IntelOpsInitiativesID != action.payload.deletedInitiative);
      state["Datas"]["StageData"] = state["Datas"]["StageData"].filter((e) => e.IntelOpsInitiativesID != action.payload.deletedInitiative);
      

      return state;
    }

    case JOURNEY_INITIATIVE_BOI_LIST_INITIALIZE:{
      state={...state};
      state["Datas"]["BoiList"] = action.payload;

      return state;
    }
    case JOURNEY_INITIATIVE_II_METRICS_INITIALIZE:{
      state = {...state};
      state["Datas"]["IImetrics"] = action.payload;

      return state;
    }
    case JOURNEY_INITIATIVE_DATA_IOJCATEGORY:{
      state = {...state};
      state["IOJCategory"] = action.payload;

      return state;
    }
    case JOURNEY_INITIATIVE_DATA_HIDDEN:{
      state = {...state};
      state["JourneyInitiatives"] = [...state["JourneyInitiatives"]].map(each=>{
        if(each.IntelOpsInitiativesID == action.payload.IntelOpsInitiativesID)
        {
          each.IsHidden =!each.IsHidden;
        }
        return each;
      })
      return state; 
    }
    case JOURNEY_INITIALIZE_IOJ_DATA:{
      state = {...state};
      state["IOJData"] = action.payload;

      return state;
    }
    case SAVE_JOURNEY_TAB_DATA:{
      state={...state};
      state["JourneyData"] = action.payload;
      return state;
    }
    case INCREMENT_GET_DATA_COUNTER_VALUE:{
      state={...state};
      state["getDataCounter"] = state["getDataCounter"] + 1;
      return state;
    }
    case JOURNEY_CLEAR_DATAS:{
      state = {...state};
      if(action.payload)
      {
        state["Datas"].viewData ={};
        state["Datas"].StageData = [];
      }
      return state;
      
    }

    case JOURNEY_INITIALIZE_IOJOM_RATING_SCORE:{
      state = {...state};
      if(action.payload)
      {
        state["RatingScore"]["IOJRating"] =action.payload.CurrentRating;
        state["RatingScore"]["IOJScore"] = action.payload.CurrentScore;
        state["RatingScore"]["OMRating"] = action.payload.OMCurrentRating;
      }
      return state;
      
    }
    case JOURNEY_SET_OMIDSTATUS:{
      state = {...state};
      state["OMIDEnabled"] = action.payload.ShowOnFrontDoor;      
      state["ActiveToggleCount"] = action.payload.ActiveToggleCount;
      return state;
    }
    case JOURNEY_INITIALIZE_OMIDLIST:{
      state={...state};
      state["OMIDDataList"] = action.payload;
      return state;
    }
    case JOURNEY_ALL_SELECTION:{
      state={...state};
      state["allSelected"] = action.payload
      return state;
    }
    case API_LOAD :{
      state = {...state};
      state["apiload"] = action.payload;
      return state;
    }
    case JOURNEY_SET_SUBOFFERING_NAME : {
      state = {...state};
      state["subOfferingData"].subOfferingID = action.payload.subOfferingID;
      state["subOfferingData"].subOfferingName = action.payload.subOfferingName;
      return state;
    }
   

    default:
      return state;
  }
};
