import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import TaggingUser from './TaggingUser';

class AddComments extends React.Component {
  constructor() {
    super();
    this.state = {
      CommentText: '',
      sendTextClear: false,
      taggedUsers: [],
      taggedUserData: [],
      clearCommentText: false,
    };
  }

  onCommentChange = (value) => {
    const { CommentText } = this.state;
    const { charLimit } = this.props;
    if (CommentText !== value && value.length <= charLimit) {
      this.setState({ CommentText: value });
    }
  }

  onTaggedUserChange = (users) => {
    this.setState({ taggedUsers: users });
  }

    clearText =() => {
      this.setState({ sendTextClear: false });
    }

    onClearComment = () => {
      this.setState({ clearCommentText: false });
    }

    saveComment = () => {
      const { onAddOrUpdateComment, userName } = this.props;
      const { CommentText, taggedUsers } = this.state;
      const commentsJson = {
        CommentId: 0,
        Comment: CommentText,
        ParentId: null,
        IsDeleted: false,
        IsEdited: false,
        IsRead: false,
        TaggedUsers: taggedUsers,
        Replies: [],
        UserName: userName,
      };
      if (CommentText.trim().length) {
        onAddOrUpdateComment(commentsJson);
        this.setState({
          CommentText: '',
          sendTextClear: false,
          taggedUsers: [],
          clearCommentText: true,
        });
      }
    }

    render() {
      const { suggestions, charLimit } = this.props;
      const {
        CommentText, sendTextClear, clearCommentText, taggedUserData,
      } = this.state;
      return (
        <div className="comments-footer">
          <TaggingUser
            key={clearCommentText ? 'clear' : 'no-clear'}
            onCommentChange={this.onCommentChange}
            onTaggedUserChange={this.onTaggedUserChange}
            suggestionsData={suggestions}
            comment=""
            isEditMode={false}
            clearText={this.clearText}
            sendTextClear={sendTextClear}
            onClearComment={this.onClearComment}
            clearComment={clearCommentText}
            taggedUserData={taggedUserData}
            charLimit={charLimit}
          />
          <div className="comments-actions">
            <p className="mb-0">{`${120 - CommentText.length}/120 characters`}</p>
            <button type="button" className="btn btn-primary" onClick={this.saveComment}>{i18n.t('Comment')}</button>
          </div>
        </div>
      );
    }
}
AddComments.defaultProps = {
  onAddOrUpdateComment: () => {},
  userName: '',
};
AddComments.propTypes = {
  onAddOrUpdateComment: PropTypes.func,
  userName: PropTypes.string,
  suggestions: PropTypes.object.isRequired,
  charLimit: PropTypes.number.isRequired,
};
export default AddComments;
