import React, { useEffect } from "react";
import {PowerBIEmbed} from "powerbi-client-react";
import {models} from "powerbi-client";
import axiosInstance from "./interceptor";

function EmbedPowerBI({powerbiurl}) {

  const [embedUrl, setEmbedUrl] = React.useState("");

  const pbiUrl = new URL(powerbiurl || "https://app.powerbi.com/reportEmbed");

  const searchParams = new URLSearchParams(pbiUrl.search);

  const reportId = searchParams.get("reportId");

  const getPBIToken = () => {
    return localStorage.getItem("access_token_powerbi");
  }

  useEffect(() => {
    axiosInstance.get(`https://api.powerbi.com/v1.0/myorg/reports/${reportId}`, {
      headers: {
        Authorization: "Bearer " + getPBIToken(),
      },
    }).then((response) => {
      setEmbedUrl(response.data.embedUrl);
    }).catch((error) => {
      console.log(error);
    });
  }, [reportId]);

  const embedConfig = {
    type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
    id: reportId,
    embedUrl: embedUrl,
    accessToken: getPBIToken(),
    tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
      background: models.BackgroundType.Transparent,
    }
  };

  return (
    <PowerBIEmbed
      key={reportId}
      embedConfig={embedConfig}
      cssClassName={"reportClass"}
    />
  );
}

export default EmbedPowerBI;
