import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import "./connectorMaster.css";
import { AgGridReact } from "ag-grid-react";
import OneTimeForm from "./OneTimeForm";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import Dropdown from "react-bootstrap/Dropdown";
import AddMappingForm from "./AddMappingForm";
import DraftForm from "./DraftForm";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import UserAccessDeniedScreen from "../Shared/UserAccessDeniedScreen"
import i18n from "i18next";
import { connect } from "react-redux";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class OneTimeList extends Component {
  constructor(props) {
    super(props);
    this.childToParent = this.childToParent.bind(this);
    this.state = {
      commonadmin: false,
      isload: false,
      userRole: {},
      rowSelected: [],
      userList: [],
      roleList: [],
      clientList: [],
      offeringList: [],
      viewmore: false,
      dataoperation: false,
      DEIDealOptions: [],
      errors: {},
      formIsValid: false,
      userAccessDenied: false,
      Connector: {
        DealID: 0,
        ConnectorID: 0,
        Status: "Approved",
      },
      clonecolumnDefs: [],
      columnDefs: [
        {
          headerName: "Edit",
          suppressMovable: true,
          width: 160,
          lockPosition: true,
          cellRenderer: (params) => {
            if(["Bat", "PMM", "SVM"].includes(params.data.sourceConnectorName)) {
              var link = document.createElement("span");
              link.className = "tbledit-disabled";
              link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
              return link;
            }
            var link = document.createElement("span");
            link.className = "tbledit";
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.connectorpopup(e, params.data, false);
            });
            return link;
          },
          suppressFilter: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Delete",
          suppressMovable: true,
          width: 160,
          lockPosition: true,

          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tbldel";
            link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.deleteProfile(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "View",
          suppressMovable: true,
          lockPosition: true,
          width: 160,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tblview";
            link.innerHTML = '<i class="fas fa-eye"></i>';
            link.addEventListener("click", (e) => {
              this.connectorpopup(e, params.data, true);
            });
            return link;
          },
          suppressFilter: false,
          filter: false,
          suppressSizeToFit: true,
        },
        {
          headerName: "Source System",
          field: "sourceConnectorName",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 160,
        },
        {
          headerName: "Synops Client Name",
          field: "destinationClientName",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 160,
        },
        {
          headerName: "Synops Offering",
          field: "destinationOfferingName",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 160,
        },
        {
          headerName: "Synops Sub-Offering",
          field: "destinationSubOfferingName",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 160,
        },
        {
          headerName: "Synops Location",
          field: "destinationLocationName",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 160,
        },
        {
          headerName: "Source Client Name",
          field: "sourceClientName",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 160,
        },
        {
          headerName: "Source Offering",
          field: "sourceOfferingName",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 160,
        },
        {
          headerName: "Source Sub-Offering",
          field: "sourceSubOfferingName",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 160,
        },
        {
          headerName: "Source Location",
          field: "sourceLocationName",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 160,
        },
        {
          headerName: "Status",
          field: "Status",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 160,
        },
        {
          headerName: "Approver Remarks",
          field: "Remarks",
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 160,
        },
      ],
      rowData: [],
      dummy: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",
        defaultColDef: {
          sortable: true,
        },
      },
      showMapping: false,
      showDrafts: false,
    };
    this.onClose = this.onClose.bind(this);
    this.onDEIDealChange = this.onDEIDealChange.bind(this);
    this.connectorpopup = this.connectorpopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onBtnExport = this.onBtnExport.bind(this);
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onSourceChange(event) {
    const Connector = this.state.Connector;
    Connector.ConnectorID = event.target.value;
    var index = event.nativeEvent.target.selectedIndex;
    Connector.ConnectorName = event.nativeEvent.target[index].text;
    Connector.SourceName = "";
    Connector.SourceFieldName = "";
    this.setState({
      Connector: Connector,
    });
    if (Connector.ConnectorID >= 0 && this.state.Connector.DealID >= 0) {
      this.fetchAllData();
    }
  }

  GetDEIDeal() {
    let samp = {};
    var EnterpriseID = sessionStorage.getItem("EnterpriseID");
    if (EnterpriseID != undefined) {
      this.setState({
        isload: true,
      })
      axiosInstance
        .get(`${LocalApiBaseUrl}Deal/GetDeals`, {
          params: {
            FeatureName: "One-Time Setup"
            , OrgEntityID: store.getState().CurrentFilter.ClientData.id
          },
        })

        .then((response) => {
          this.setState({ DEIDealOptions: response.data }, () => {
            this.getGetConnectorMaster();
          });
        })
        .catch((error) => {
          this.setState(
            {
              isload: false,
            },
            () => {
              trycatchAlertPopup(error);
            }
          );
        });
    }
  }
  fetchAllData() {
    if (store.getState().CurrentFilter.ClientData != undefined) {
      let DealID =
        this.state.Connector.DealID && this.state.Connector.DealID !== "0"
          ? [parseInt(this.state.Connector.DealID)]
          : this.state.DEIDealOptions.filter((a) => a.OrgEntityID === store.getState().CurrentFilter.ClientData.id).map(
            (a) => a.DealID
          );
      let ConnectorID =
        this.state.Connector.ConnectorID && this.state.Connector.ConnectorID !== "0"
          ? [parseInt(this.state.Connector.ConnectorID)]
          : this.state.sourceOptions && this.state.sourceOptions.map((a) => parseInt(a.key));
      let Status = [this.state.Connector.Status];
      const params = {
        DealID: DealID,
        ConnectorID: ConnectorID,
        Status: Status,
      };
      this.setState({
        isload: true,
      });
      axiosInstance
        .post(`${LocalApiBaseUrl}ConnectorMapping/GetOneTimeSetupGridData`, params, {
          params: {},
        })
        .then((response) => {
          const modifiedResponse = response.data.map(each => {
            if(each.sourceClientName)
              each.sourceClientName=each.sourceClientName.trim();
            if(each.sourceConnectorName === "SVM"){
              if(each.ProgramName === null || each.ProgramName.length === 0) return each;
              const programName = each.ProgramName;
              const clientOffering = programName.split("_")
              
              const offeringSuboff = clientOffering[1].includes("-") ? clientOffering[1].split("-") : [clientOffering[1]];
          
             
              each.sourceOfferingName = offeringSuboff[0];
              each.sourceSubOfferingName = offeringSuboff[1] !== undefined ?offeringSuboff[1]:"";
            }
            
            return each;
          })

          this.setState({
            rowData: modifiedResponse,
            isload: false,
          });
        })
        .catch((error) => {
          this.setState(
            {
              isload: false,
            },
            () => {
              trycatchAlertPopup(error);
            }
          );
        });
    }
    else {
      window.alert("Client selection is mandatory !!");
      this.setState({ isload: false })
    }
  }
  getGetConnectorMaster() {
    let source = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
      .then((response) => {
        source = response.data;
        let sourceValues = source.map((x) => (
          <option key={x.ConnectorID} value={x.ConnectorID}>
            {x.ConnectorName}
          </option>
        ));
        this.setState({ sourceOptions: sourceValues }, () => {
          this.fetchAllData();

        });
      })
      .catch((error) => {
        this.setState(
          {
            isload: false,
          },
          () => {
            trycatchAlertPopup(error);
          }
        );
      });
  }

  deleteProfile(e, data) {
    if (
      this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("Delete"))
        .length > 0
    ) {

      if (data.sourceConnectorName === "IOJ" ? window.confirm(i18n.t("If you are deleting one time setup for any IOJ journey, all the initiatives for that journey will get deleted permanently. Are you sure you want to continue?")) : window.confirm(i18n.t("Are you sure you want to delete the mapping?"))) {
        axiosInstance
          .delete(`${LocalApiBaseUrl}ConnectorMapping/DisableOneTimeSetupData`, {
            params: { dealId: data.DealID, sourceId: data.sourceConnectorID },
          })
          .then((response) => {
            window.alert(response.data.StatusMessage);
            this.fetchAllData();
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });


      }
    } else window.alert(" UnAuthorised Access Denied !!");
  }

  connectorpopup(e, data, status) {
    if (data != undefined) {
      if (store.getState().CurrentFilter.ClientData != undefined) {
        e.preventDefault();
        let rowSelected = JSON.parse(JSON.stringify(data));
        rowSelected.sourceClientName = rowSelected.sourceClientName ? rowSelected.sourceClientName.split("|") : [];
        rowSelected.sourceOfferingName = rowSelected.sourceOfferingName
          ? rowSelected.sourceOfferingName.split("|")
          : [];
        rowSelected.sourceSubOfferingName = rowSelected.sourceSubOfferingName
          ? rowSelected.sourceSubOfferingName.split("|")
          : [];
        rowSelected.sourceLocationName = rowSelected.sourceLocationName
          ? rowSelected.sourceLocationName.split("|")
          : [];

        this.setState({ viewmore: true, rowSelected: rowSelected, dataoperation: status });
      } else window.alert("Client selection is mandatory !!");
    } else {
      // add condition
      if (store.getState().CurrentFilter.ClientData != undefined) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data, dataoperation: status });
      } else window.alert("Client selection is mandatory !!");
    }
  }

  handleSave(data) {
    axiosInstance.post(`${LocalApiBaseUrl}ConnectorMapping/EditOneTimeSetup`, data).then(
      (response) => {
        window.alert(response.data.StatusMessage);
        let x = this.state.Connector;
        if (this.state.Connector.DealID >= 0 && this.state.Connector.ConnectorID >= 0) {
          this.fetchAllData();
        }
      },
      (error) => {
        //console.log(error);
      }
    )
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }
  onDEIDealChange(event) {
    let soCatAtt = null;
    this.setState({ errors: {} });
    let Connector = this.state.Connector;
    Connector.DealID = event.target.value;
    this.setState({
      Connector: Connector,
    });

    if (this.state.Connector.ConnectorID >= 0 && Connector.DealID >= 0) {
      this.fetchAllData();
    }
  }
  addMappingShow = () => {
    if (store.getState().CurrentFilter.ClientData != undefined) {
      this.setState({
        showMapping: true,
        showDrafts: false,
      });
    }
    else {
      window.alert("Client selection is mandatory !!");
    };
  }
  draftsShow = () => {
    if (store.getState().CurrentFilter.ClientData != undefined) {
      this.setState({
        showDrafts: true,
        showMapping: false,
      });
    }
    else {
      window.alert("Client selection is mandatory !!");
    };
  };
  ModifyColumnsdef() {
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetUserAccessDetails`)
      .then((res) => {
        if (res.data) {

        //console.log("USER ROLE:", res.data);
          this.setState({ userRole: res.data }, () => {
            let { IsAdmin, IsCapability, IsGuest } = this.state.userRole;

            let commonadmin = /* false */ IsAdmin || IsCapability;
            console.log("SATATUS:", commonadmin);
          //console.log("SATATUS:", commonadmin);
            let columnDefs = this.state.columnDefs;
            let clonecolumnDefs = columnDefs;
          //console.log("FEATURE MASTER DATA:", this.props.featureData.filter((data) => data.Submenu == "One-Time Setup"));

            if (
              this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("Edit"))
                .length == 0 &&
              this.state.Connector.Status === "Approved" &&
              !commonadmin
            ) {
              columnDefs = columnDefs.filter((data) => data.headerName != "Edit");
            }
            if (
              this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("Delete"))
                .length == 0 &&
              this.state.Connector.Status === "Approved" &&
              !commonadmin
            ) {
              columnDefs = columnDefs.filter((data) => data.headerName != "Delete");
            }
            if (
              this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("View"))
                .length == 0 &&
              this.state.Connector.Status === "Approved" &&
              commonadmin
            ) {
              columnDefs = columnDefs.filter((data) => data.headerName != "View");
            }
            this.setState({
              columnDefs: columnDefs,
              clonecolumnDefs: clonecolumnDefs,
            });
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    if (this.props.userAccessDeniedScreen === "One-Time Setup") {
      this.setState({
        userAccessDenied: true
      })
    }
    else {
      this.GetDEIDeal();
      this.ModifyColumnsdef();
    }


  }
  componentDidUpdate(prevProps) {
    if (prevProps.userAccessDeniedScreen !== this.props.userAccessDeniedScreen) {
      if (this.props.userAccessDeniedScreen === "One-Time Setup") {
        this.setState({
          userAccessDenied: true
        })
      }
      else {
        this.setState({
          userAccessDenied: false
        })
      }
    }
  }
  childToParent(selectedTab, draftInfo) {

    if (selectedTab === "Draft") {
      this.setState({ showDrafts: false });

      if (draftInfo != undefined) {
        this.setState({ showDrafts: false, showMapping: true, draftInfo: draftInfo });
      }
    }

    if (selectedTab === "AddMapping") this.setState({ showMapping: false, draftInfo: [] });
  }
  onStatusChange(event) {
    this.setState({ columnDefs: [] });
    let { IsAdmin, IsCapability, IsGuest } = this.state.userRole;
    let commonadmin = /* false */ IsAdmin || IsCapability;
    let Connector = this.state.Connector;
    let columnDefs = this.state.columnDefs;
    Connector.Status = event.target.value;
    if (
      this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("Edit"))
        .length !== 0 &&
      Connector.Status === "Approved" &&
      !commonadmin
    ) {
      columnDefs = this.state.clonecolumnDefs.filter((data) => data.headerName != "Edit");
    } else if (
      this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("Edit"))
        .length !== 0 &&
      Connector.Status === "Rejected" &&
      !commonadmin
    ) {
      columnDefs = this.state.clonecolumnDefs;
    }
    if (
      this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("Delete"))
        .length !== 0 &&
      Connector.Status === "Approved" &&
      !commonadmin
    ) {
      columnDefs = this.state.clonecolumnDefs.filter((data) => data.headerName != "Delete");
    } else if (
      this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("Delete"))
        .length !== 0 &&
      Connector.Status === "Rejected" &&
      !commonadmin
    ) {
      columnDefs = this.state.clonecolumnDefs;
    }
    if (
      this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("View"))
        .length !== 0 &&
      Connector.Status === "Approved" &&
      !commonadmin
    ) {
      columnDefs = this.state.clonecolumnDefs; /* .filter((data) => data.headerName != "View"); */
    } else if (
      this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("View"))
        .length !== 0 &&
      Connector.Status === "Rejected" &&
      !commonadmin
    ) {
      columnDefs = this.state.clonecolumnDefs.filter((data) => data.headerName != "View");
    }

    if (
      this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("Edit"))
        .length !== 0 &&
      this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("Delete"))
        .length !== 0 &&
      Connector.Status === "Approved" &&
      !commonadmin
    ) {
      columnDefs = this.state.clonecolumnDefs.filter(
        (data) => data.headerName != "Edit" && data.headerName != "Delete"
      );
    } else if (
      this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("Edit"))
        .length !== 0 &&
      this.props.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.includes("Delete"))
        .length !== 0 &&
      Connector.Status === "Rejected" &&
      !commonadmin
    ) {
      columnDefs = this.state.clonecolumnDefs.filter((data) => data.headerName != "View");
    }

    this.setState({
      Connector: Connector,
      columnDefs: columnDefs,
    });
    if (this.state.Connector.ConnectorID >= 0 && this.state.Connector.DealID >= 0 && Connector.Status) {
      this.fetchAllData();
    }
  }
  onBtnExport() {
    let params = {
      columnKeys: [
        "sourceConnectorName",
        "destinationClientName",
        "destinationOfferingName",
        "destinationSubOfferingName",
        "destinationLocationName",
        "sourceClientName",
        "sourceOfferingName",
        "sourceSubOfferingName",
        "sourceLocationName",
        "Status",
        "Remarks"
      ],
      columnGroups: true,
      allColumns: false,
      skipHeader: false,
      fileName: "One-Time Setup",
    };
    this.state.gridOptions.api.exportDataAsCsv(params);
  }
  render() {
    const { showMapping, showDrafts } = this.state;
    return (
      <>
        <LoadingOverlay
          active={this.state.isload}
          spinner={<SynopsLoader />}
        >
          {
            !this.state.userAccessDenied ?

              <div className="main-data">
                {this.props.featureData.filter(
                  (data) => data.Submenu == "One-Time Setup" && data.Operations.includes("Add")
                ).length > 0 && (
                    <div style={{ display: "inline-block", width: "100%" }}>
                      <div style={{ float: "right" }} className="oneTime-section-right">
                        <div className="mapping-dropdown-btn">
                          <Dropdown>
                            <Dropdown.Toggle id="mapping-dropdown">Mappings</Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="addMapping" onSelect={this.addMappingShow}>
                                <i class="fal fa-plus-circle"></i>Add Mapping
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="drafts" onSelect={this.draftsShow}>
                                <i class="fal fa-list-ul"></i>Drafts
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="tableFilter">
                  <div className="form-section">
                    <div className="form-row">
                      <div className="form-group col-lg-3">
                        <label>
                          Deal <span className="required">*</span>
                        </label>
                        <select
                          id="OperatingGroup"
                          className="form-control"
                          disabled={this.props.data}
                          ref="OperatingGroupDropDown"
                          onChange={this.onDEIDealChange.bind(this)}
                          value={this.state.Connector.DealID}
                        >
                          <option value="0">ALL</option>
                          {this.state.DEIDealOptions.map((row) => {
                            return (
                              <option value={row.DealID}>
                                {row.ClientName + "  -  " + row.Process + "  -  " + row.City}
                              </option>
                            );
                          })}
                        </select>
                        <div className="errorMsg">{this.state.errors.DealID}</div>
                      </div>

                      <div className="form-group col-lg-3">
                        <label>
                          Source System<span className="required ">*</span>
                        </label>
                        <select
                          className="form-control"
                          onChange={this.onSourceChange}
                          value={this.state.Connector.ConnectorID}
                        >
                          <option value="0">ALL</option>
                          {this.state.sourceOptions}
                        </select>
                        <div className="errorMsg">{this.state.errors.ConnectorID}</div>
                      </div>
                      <div className="form-group col-lg-3">
                        <label>
                          Status <span className="required">*</span>
                        </label>
                        <select
                          id="StatusGroup"
                          className="form-control"
                          ref="OperatingGroupDropDown"
                          onChange={this.onStatusChange.bind(this)}
                          value={this.state.Connector.Status}
                        >
                          <option value="Approved">Approved</option>
                          <option value="Rejected">Rejected</option>
                        </select>
                        <div className="errorMsg">{this.state.errors.DealID}</div>
                      </div>

                      <div className="form-group col-lg-3 setup-download">
                        <button class="setup-Btn" onClick={this.onBtnExport}>
                          <i class="fa fa-download"></i>Download
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ag-theme-balham ag-table">
                      {!this.state.isload && <AgGridReact
                        enableSorting={true}
                        enableFilter={true}
                        pagination={true}
                        paginationPageSize={50}
                        floatingFilter={true}
                        gridOptions={this.state.gridOptions}
                        columnDefs={this.state.columnDefs
                          .sort((a, b) => a.index - b.index)
                          .map((exemple, index, array) => exemple)}
                        rowData={this.state.rowData}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                      ></AgGridReact>}
                    </div>
                  </div>
                </div>

                {this.state.viewmore ? (
                  <OneTimeForm
                    data={this.state.rowSelected}
                    Status={this.state.Connector.Status}
                    OfferingId={this.state.Connector.OfferingId}
                    show={this.state.viewmore}
                    onClose={this.onClose}
                    dataoperation={this.state.dataoperation}
                    filterDetails={this.props.filterDetails}
                    userRole={this.state.userRole}
                    onSave={this.handleSave}
                  />
                ) : null}
              </div> : <UserAccessDeniedScreen />}
          {showMapping && (
            <AddMappingForm
              childToParent={this.childToParent}
              showMapping={this.state.showMapping}
              showDrafts={this.state.showDrafts}
              dealInfo={this.state.draftInfo}
              userRole={this.state.userRole}
              isClientOnboarding={false}
            />
          )}

          {showDrafts && (
            <DraftForm
              childToParent={this.childToParent}
              showMapping={this.state.showMapping}
              showDrafts={this.state.showDrafts}
            />
          )}
        </LoadingOverlay>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    userAccessDeniedScreen: state.roleReducers.userDeniedScreen,
  };
};
export default connect(mapStateToProps)(OneTimeList);