import React, { Component } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SubOffering  from "./SubOffering";
import i18n from "i18next";

class SubOfferingDropdowns extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var toggler = document.getElementsByClassName("caret");
    var i;

    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function() {
        this.parentElement
          .querySelector(".nested-ul")
          .classList.toggle("active");
        this.classList.toggle("caret-down");
      });
    }
  }
  render() {
    const {
      onSubOfferingChange,
      subOfferings,
      dropdownItem,
      selectedSubOffering,
      defaultToggleName,
      labelpresent,
      selectedSubOfferingId,
      SubOfferingDropdownsClass
    } = this.props;

    return(
      <>
        {labelpresent && <label for={this.props.labelfor} className={this.props.labelClassName}>
            {i18n.t("Sub_Offering")} 
            {this.props.required && <span className="required">*</span>}
        </label>}
        <DropdownButton
          className="selectsuboffering-dropdown nested-dropdown-wrapper"
          title={this.props.selectedSubOffering ?
            this.props.selectedSubOffering :
            this.props.defaultToggleName}
          disabled={this.props.disabledValue}
        >
          <div className={SubOfferingDropdownsClass 
                          ? "offering-droplistwrapper suboffering-dropdown-style" 
                          : "offering-droplistwrapper"}
          >            
            <SubOffering 
             subOfferings={subOfferings}
             onSubOfferingChange={(e)=>onSubOfferingChange(e)}
             dropdownItem={dropdownItem}
             selectedSubOffering={selectedSubOffering}
             defaultToggleName={defaultToggleName}
             selectedSubOfferingId={selectedSubOfferingId}
            />
          </div>
        </DropdownButton> 
      </>
    );
  }
}
export default SubOfferingDropdowns;
