import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ToolListContainer from "./ToolListContainer";
import MetricListContainer from "./MetricListContainer";
import AddToolPopUp from "../CommonComponents/AddToolPopUp";
import { store } from "../reducers/configureStore";

import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { connect } from "react-redux";
import i18n from 'i18next';
import SelectToolPopup from "./SelectToolPopup";
import SubStepListContainer from './SubStepListContainer';
class StepDetailsDataContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoaderonPage:false,
            isDataLoaded:true,
            toolsList:[],
            editToolConfStatus: false,
            editMetricConfStatus: false,
            editMetricStatus: false,
            isScreenInEditMode: false,
            iseditActive: false,
            addMetricStatus: false,
            addMetricConfStatus: false,
            addToolStatus: false,
            addToolConfStatus: false,
            addNewToolStatus: false,
            metricRowDetails: [],
            toolDetails: [],
            kpiData: [],
            woData: {
                SOCategoryID: this.props.selectedSubStep !==0 ? this.props.selectedSubStep : this.props.SOCategoryID,
                Comments: this.props.Comments,
            },
            startDate: new Date(),
            addSubStepStatus: false,
            addSubStepConfStatus: false,
        }
        this.addMetricModalToggler = this.addMetricModalToggler.bind(this);
        this.addToolModalToggler = this.addToolModalToggler.bind(this);
        this.addNewToolToggler = this.addNewToolToggler.bind(this);
        this.editToolModalToggler = this.editToolModalToggler.bind(this);
        this.editMetricModalToggler = this.editMetricModalToggler.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.selectToolModalToggler = this.selectToolModalToggler.bind(this);
        this.selectToolModalTogglerClose = this.selectToolModalTogglerClose.bind(this);
        this.addSubStepModalToggler = this.addSubStepModalToggler.bind(this);
    }

    //Popup Toogglers start 
    editMetricModalToggler(row) {
        let { screen } = this.props;
        if (screen === "SuperAdmin") {
            this.state.editMetricStatus ?
                this.setState({ editMetricStatus: false })
                : this.setState({ editMetricStatus: true, kpiData: row });
        }
        else {
            this.state.editMetricConfStatus ?
                this.setState({ editMetricConfStatus: false })
                : this.setState({ editMetricConfStatus: true, kpiData: row });
        }
    }

    editToolModalToggler(data) {
        let { screen } = this.props;
        if (screen === "SuperAdmin") {
            this.state.isScreenInEditMode ?
                this.setState({ isScreenInEditMode: false, toolDetails: this.props.toolDetails }) :
                this.setState({ isScreenInEditMode: true, toolDetails: data })
        }
        else {
            this.state.editToolConfStatus ?
                this.setState({ editToolConfStatus: false, toolDetails: this.props.toolDetails }) :
                this.setState({ editToolConfStatus: true, toolDetails: data })
        }
    }

    addMetricModalToggler() {
        let { screen } = this.props;
        if (screen === "SuperAdmin") {
            this.state.addMetricStatus ?
                this.setState({ addMetricStatus: false }) :
                this.setState({ addMetricStatus: true })
        } else {
            this.state.addMetricConfStatus ?
                this.setState({ addMetricConfStatus: false }) :
                this.setState({ addMetricConfStatus: true });
        }
    }

    addToolModalToggler() {
        let { screen } = this.props;
        if (screen === "SuperAdmin") {
            this.state.addToolStatus ?
                this.setState({ addToolStatus: false }) :
                this.setState({ addToolStatus: true })
        } else {
            this.state.selectToolStatus ?
                this.setState({ selectToolStatus: false }) :
                this.setState({ selectToolStatus: true });
        }
    }

    addSubStepModalToggler() {
        let { screen } = this.props;
        if (screen === "SuperAdmin") {
            this.state.addSubStepStatus ?
                this.setState({ addSubStepStatus: false }) :
                this.setState({ addSubStepStatus: true })
        } else {
            this.state.addSubStepConfStatus ?
                this.setState({ addSubStepConfStatus: false }) :
                this.setState({ addSubStepConfStatus: true });
        }
    }

    addNewToolToggler() {
        this.state.addNewToolStatus ?
            this.setState({ addNewToolStatus: false })
            : this.setState({ addNewToolStatus: true, addToolStatus: false })
    }
    //Popup Toogglers Ends   

    handleCommentChange(e) {
        let woData = this.state.woData;
        woData.SOCategoryID = this.props.selectedSubStep !==0 ? this.props.selectedSubStep : this.props.SOCategoryID;
        woData.Comments = e.target.value;
        this.setState({ woData: woData });

    }
    selectToolModalToggler() {
        this.setState({ selectToolStatus: true })
    }
    selectToolModalTogglerClose() {
        this.setState({ selectToolStatus: false })
    }
    sendComment = () => {
        let currentData = {
            "Comments": this.state.woData.Comments, "FilterParams": {
                "OrgEntityID": this.props.filter.Client,
                "OfferingID": this.props.filter.process,
                "LocationID": this.props.filter.Locations,
                "MonthDT": this.props.filter.Month,
                "SOCategoryID": this.props.selectedSubStep === 0 ? this.props.SOCategoryID : this.props.selectedSubStep
            }
        }
        axiosInstance.post(`${LocalApiBaseUrl}Configurator/AddEditWOComments`, currentData).then((response) => {
            if (response.data) {
                axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetWorkOrchestrationQualityScore`, {
                    params: {
                        LocationID: this.props.filter.Locations,
                        OrgEntityID: this.props.filter.Client,
                        OfferingID: this.props.filter.process,
                        MonthDT: this.props.filter.Month,
                        LanguageID: store.getState().CurrentFilter.languageData.id
                    }
                }
                )
                    .then((response) => {
                        if (response.data) {
                            store.dispatch({
                                type: "SAVE_QC_SCORE_OF_EACH_TAB",
                                payload: response.data
                            });
                        }


                    }).catch((error)=>{
trycatchAlertPopup(error);

                    });
                store.dispatch({
                    type: "ADD_EDIT_WO_COMMENTS",
                    payload: currentData,
                });

            }
        }).catch((error)=>{
trycatchAlertPopup(error);
            window.alert(this.ssErrMsg + error);
        });
    }
    getKpiMAppingDetails = () => {
        return axiosInstance
          .get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
            params: {
              OrgEntityID: this.props.filter.Client,
              LocationID: this.props.filter.Locations,
              OfferingID: this.props.filter.process,
              SOCategoryID: this.props.selectedSubStep,
              MonthDT: this.props.filter.Month,
            },
          })
          .then((response) => {
            this.setState({
              kpiMappingResponse: response.data
            });
          })
    }
    setStartDate(data) {
        this.setState({startDate:data});
    }
    getPulseMtcMergedTool=()=>{
        this.setState({
         showLoaderonPage:true
        })
       axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetWOMergeTools`, {
           params: {
             OrgEntityID: this.props.filter.Client,
             OfferingID: this.props.filter.process,
             LocationID: this.props.filter.Locations,
             SOCategoryID: this.props.selectedSubStep,
             LanguageID: store.getState().CurrentFilter.languageData.id,
           },
         })
         .then((response) => {
             let mergedToolList=response.data;
             mergedToolList=mergedToolList.filter(a=>a.isAlsoPulseTool===false).map((a) => {
               return {
                 ...a,
                 IsChecked: false,
                 ExpectedDeploymentDate:"",
                 DeploymentDate:""
               };
             });
             this.setState({
                isDataLoaded:false,
               showLoaderonPage:false,
               toolsList:mergedToolList
              })
         })
         .catch((error) => {
           this.setState({
            isDataLoaded:false,
             showLoaderonPage:false
            })
           trycatchAlertPopup(error);
         });
   }
  componentDidUpdate(prevProps){
    let { screen} = this.props;
    if(screen === "Configurator" && this.props.selectedSubStep !== undefined && this.props.selectedSubStep !== 0 && this.props.selectedSubStep !== prevProps.selectedSubStep){
        this.getPulseMtcMergedTool();
        this.getKpiMAppingDetails();
        this.setState({
            woData: {
                SOCategoryID: this.props.selectedSubStep ? this.props.selectedSubStep : 0,
                Comments: this.props.Comments,
            }
        })
    }
    if(screen === "Configurator" && this.props.selectedSubStep === 0 && this.props.selectedSubStep !== prevProps.selectedSubStep){        
        this.setState({
            woData: {
                SOCategoryID: this.props.selectedSubStep ? this.props.selectedSubStep : 0,
                Comments: this.props.Comments,
            }
        })
    }
  }

  deleteConfigSubStep = (soCatId) => {
    const { subStepData } = this.props;

    if (subStepData.length > 1) { 
      const filterProcess = this.props.filter.process;
      const data = subStepData.find((ele) => ele.SOCategoryID === soCatId);
      const sOCategoryID = data.SOCategoryID;
      if(window.confirm(i18n.t('All the tools and metrics mapped to this sub-step will be deleted permanently'))){
        axiosInstance
        .delete(`${LocalApiBaseUrl}Configurator/DeleteSubStepData?OrgEntityId=${this.props.filter.Client}&OfferingId=${this.props.filter.process}&LocationId=${this.props.filter.Locations}&MonthDT=${this.props.filter.Month}&SOCategoryId=${sOCategoryID}`)

        .then((response) => {
            window.alert(i18n.t('SynOps WO sub-step has been deleted successfully'));            
            let woData = Object.assign({}, [...this.props.subStepData]);
            let deleteStep = [...this.props.subStepData].filter((data) => data.SOCategoryID == soCatId)[0];
            store.dispatch({
                type: "Remove_TILE",
                payload: deleteStep,
            });
            this.props.card(0);
        })
        .catch((error) => {
            trycatchAlertPopup(error);
        });
      }
    }
    else{
      window.alert(i18n.t('alert_message_when_deleting_onlysubstep')+ ' ' +this.props.metricDesp);
    }
  }

    render() {
        let { screen, toolData, metricDesp, deleteStep, stepToggleHandler, metricActive,
            metricSeq, metricData, subStepData, selectedSubStep, roledata, SOCategoryID } = this.props;

            let {selectToolModalToggler,selectToolModalTogglerClose,selectToolStatus, addSubStepStatus, addSubStepConfStatus, editSubStepStatus, editSubStepConfStatus}=this.state
            let deleteRole = this.props.roleReducers.filter(f => {
                return (f.FeatureName == "Master Template Configurator" && f.Operations.includes("Delete"))
              })
            let  addRole =this.props.roleReducers.filter(f => {
                return (f.FeatureName == "Master Template Configurator" && f.Operations.includes("Add"))
              })
              
        return (
            <>
                <div className="stepdetail-data">
                    <div className="title-with-icon">
                        <h3 className="stage-heading">
                            <span className="num-tools">{metricSeq}</span>
                            {metricDesp}
                        </h3>
                        {screen === "SuperAdmin" ? (
                            deleteRole.length>0 ?(
                            <div className="list-icon">
                                <span className="help-icon" onClick={() => deleteStep(metricSeq)}>Delete Step</span>
                            </div>
                            ):null
                            
                        ) : screen === "Configurator" ? (
                            <div className="list-icon">
                                <div className="form-group">
                                    <label></label>
                                    <div className="toggle-group">
                                        <input
                                            type="checkbox"
                                            name="datatypechange"
                                                disabled={((this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] ===  "True" && !this.props.isManintencneWindowOpen ) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0))}
                                            id="datatypechange"
                                            defaultChecked={metricActive}
                                            onChange={() => stepToggleHandler(SOCategoryID)}
                                        />
                                        <label htmlFor="datatypechange">Connection Status</label>
                                        <div className="onoffswitch" aria-hidden="true">
                                            <div className="onoffswitch-label">
                                                <div className="onoffswitch-inner"></div>
                                                <div className="onoffswitch-switch"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className={metricActive === false ? "stepdetails-content disabled" : "stepdetails-content"}>
                        <div className="row">
                            <div className="col-lg-9 tool-border">
                                <div className="title-with-icon">
                                    <div className="orch-sidebar">
                                        <div className="rainbow-tools">
                                            <p>
                                                <span>{i18n.t("AT")}</span> {i18n.t("Automation")}
                                            </p>
                                            <p>
                                                <span>{i18n.t("WF")}</span> {i18n.t("Workflow")}
                                            </p>
                                            <p>
                                                <span>{i18n.t("AA")}</span> {i18n.t("Advanced_Analytics")}
                                            </p>
                                            <p>
                                                <span>{i18n.t("AI")}</span> {i18n.t("Artificial_Intelligence")}
                                            </p>
                                        </div>
                                    </div>
                                    {screen === "Dashboard" ? null : (
                                        screen === "SuperAdmin" 
                                        ? (this.props.roledata != undefined &&
                                            store.getState().CurrentFilter.languageData.id == 1 &&
                                            this.props.roledata.includes("Add") && (
                                                <div className="list-icon">
                                                    <span className="help-icon" onClick={this.addSubStepModalToggler}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                            {i18n.t('Add Sub-Step')}
                                                    </span>
                                                </div>)
                                            )
                                        : ((this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] ===  "True" && !this.props.isManintencneWindowOpen ) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0)) ? "" :   
                                           <div className="list-icon">
                                               {selectedSubStep !==0 &&
                                                <span className="help-icon" onClick={() => this.deleteConfigSubStep(selectedSubStep)}>
                                                    <FontAwesomeIcon icon={faTrashAlt} /> {i18n.t('Delete Sub-Step')}
                                                </span>}
                                                <span className="help-icon" onClick={this.addSubStepModalToggler}>
                                                    <FontAwesomeIcon icon={faPlus} /> {i18n.t('Add Sub-Step')}
                                                </span>
                                            </div>

                                    )}
                                </div>

                                    <SubStepListContainer
                                        StepName={metricDesp}
                                        screen={screen}
                                        roledata={roledata}
                                        subStepData={this.props.subStepData}
                                        addSubStepStatus= {addSubStepStatus}
                                        addSubStepConfStatus= {addSubStepConfStatus}
                                        addSubStepModalToggler ={this.addSubStepModalToggler}
                                        editSubStepStatus= {editSubStepStatus}
                                        editSubStepConfStatus= {editSubStepConfStatus}
                                        SOCategoryID={this.props.SOCategoryID}
                                        filter = {this.props.filter}  
                                        card={this.props.card}                                            
                                        getOfferingAPICallback={this.props.getOfferingAPICallback}    
                                        selectedSubStepID={selectedSubStep}
                                        RecordStatus={this.props.RecordStatus} 
                                        isManintencneWindowOpen={this.props.isManintencneWindowOpen}  
                                    />

                            </div>
                            <div className="col-lg-3">                                                              
                                <div className="title-with-icon">
                                    <h4 className="wo-title">Tools</h4>
                                    {screen === "Dashboard" || selectedSubStep == 0 ? null : (
                                        ((this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] ===  "True" && !this.props.isManintencneWindowOpen ) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0)) ? "" :
                                            <div className="list-icon">
                                            {screen === "SuperAdmin" && (
                                                addRole.length>0 &&
                                                <span className="help-icon" onClick={this.addToolModalToggler}>
                                                    <FontAwesomeIcon icon={faPlus} /> Add Tool
                                                </span>
                                            )}
                                            {screen === "Configurator" && (
                                                <span className="help-icon" onClick={this.selectToolModalToggler}>
                                                    <FontAwesomeIcon icon={faPlus} /> Select Tool
                                                </span>
                                            )}
                                            </div>
                                    )}
                                </div>

                                <ToolListContainer
                                    toolData={toolData}
                                    RecordStatus={this.props.RecordStatus} isManintencneWindowOpen={this.props.isManintencneWindowOpen}
                                    toolDetails={this.state.toolDetails}
                                    addToolModalToggler={this.addToolModalToggler}
                                    addNewToolToggler={this.addNewToolToggler}
                                    editToolModalToggler={this.editToolModalToggler}
                                    addToolStatus={this.state.addToolStatus}
                                    addToolConfStatus={this.state.addToolConfStatus}
                                    editToolConfStatus={this.state.editToolConfStatus}
                                    screen={screen}
                                    filter={this.props.filter}
                                    metricDesp={this.props.metricDesp}
                                    SOCategoryID={this.props.SOCategoryID}
                                    LanguageID={this.props.LanguageID}
                                    getOfferingAPICallback={this.props.getOfferingAPICallback}
                                    selectedSubStepID={selectedSubStep}
                                />
                                <div className="title-with-icon mx-1">
                                    <h4 className="wo-title">Metric</h4>
                                    {screen === "Dashboard" || selectedSubStep === 0 ? null : (((this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] ===  "True" && !this.props.isManintencneWindowOpen ) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0)) ? "" :
                                       (screen === "Configurator" || addRole.length>0)?(
                                       <div className="list-icon">
                                            <span className="help-icon" onClick={this.addMetricModalToggler}>
                                                <FontAwesomeIcon icon={faPlus} /> Add Metric
                                            </span>
                                        </div>
                                       ):null
                                    )}
                                </div> 
                                <MetricListContainer
                                    metricData={metricData}
                                    RecordStatus={this.props.RecordStatus} isManintencneWindowOpen={this.props.isManintencneWindowOpen}
                                    kpiData={this.state.kpiData}
                                    addMetricModalToggler={this.addMetricModalToggler}
                                    addMetricStatus={this.state.addMetricStatus}
                                    addMetricConfStatus={this.state.addMetricConfStatus}
                                    editMetricStatus={this.state.editMetricStatus}
                                    editMetricConfStatus={this.state.editMetricConfStatus}
                                    editMetricModalToggler={this.editMetricModalToggler}
                                    screen={screen}
                                    filter={this.props.filter}
                                    metricDesp={this.props.metricDesp}
                                    SOCategoryID={this.props.SOCategoryID}
                                    kpiMappingResponse={this.state.kpiMappingResponse}
                                    getOfferingAPICallback={this.props.getOfferingAPICallback}
                                    dealFilter={this.props.filter}
                                    selectedSubStepID={selectedSubStep}
                                />
                                {screen === 'Configurator' && 
                                    <div className="comment-area">
                                        <div class="title-with-icon">
                                            <h4 class="wo-title">Comment</h4>
                                        </div>
                                    <textarea placeholder={selectedSubStep === 0 ? i18n.t('Enter step comment') : i18n.t('Enter sub-step comment')} className="form-control" maxLength="100" value={this.state.woData.Comments !== null ? this.state.woData.Comments : ''} onBlur={this.sendComment} onChange={(e) => this.handleCommentChange(e)} disabled={((this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] ===  "True" && !this.props.isManintencneWindowOpen ) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0))} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.addNewToolStatus &&
                    <AddToolPopUp
                        show={this.state.addNewToolStatus}
                        filter={this.props.filter}
                        SOCategoryID={3}
                        onClose={this.addNewToolToggler}
                        screen={this.props.screen}
                        lever={"WO"}
                        getOfferingAPICallback={this.props.getOfferingAPICallback}
                        tooldata={this.props.toolDetails} />
                }
                {this.state.isScreenInEditMode &&
                    <AddToolPopUp
                        show={this.state.isScreenInEditMode}
                        filter={this.props.filter}
                        SOCategoryID={3}
                        tooldatatype={"Edit"}
                        onClose={this.editToolModalToggler}
                        screen={this.props.screen}
                        lever={"WO"}
                        getOfferingAPICallback={this.props.getOfferingAPICallback}
                        tooldata={this.state.toolDetails} />
                }
                { this.state.selectToolStatus &&
                  <SelectToolPopup
                  showLoaderonPage={this.state.showLoaderonPage}
                  isDataLoaded={this.state.isDataLoaded}
                  toolsList={this.state.toolsList}
                  filter={this.props.filter}
                      selectToolModalToggler={this.selectToolModalToggler} 
                      selectToolModalTogglerClose={this.selectToolModalTogglerClose}  
                      selectToolStatus={selectToolStatus}  
                      SOCategoryID={this.props.SOCategoryID}
                      selectedSubStepID={selectedSubStep}
                  />
                }
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        languageData: state.CurrentFilter.languageData,
      clientData: state.CurrentFilter.ClientData,
      roleReducers: state.roleReducers.roleReducers,
      SelectedFilter: { ...state.CurrentFilter.SyncFilter },
      WoStepData: [...state.WOtileReducers.tile],
    };
};


export default connect(mapStateToProps, "")(StepDetailsDataContainer);
