import _ from "lodash";

const configObj = {
  auth: {
    clientId: process.env.REACT_APP_CONFIG_CLIENTID,
    authority: process.env.REACT_APP_CONFIG_API_URL,
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
};

// REMOVE SOME PROPERTIES AS THEY ARE NOT NEEDED FOR PRODUCTION ENVIRONMENT
/* if (process.env.NODE_ENV === 'production') {
    _.unset(configObj, 'resource');

} */

export const msalConfig = {
  ...configObj,
  expireOffsetSeconds: 1000,
  loadFrameTimeout: 500,
  navigateToLoginRequestUrl: true,
  cacheLocation: "sessionStorage",
};

console.log("CONFIG OBJECT:", msalConfig);
// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  //scopes: ["User.Read"],
  scopes: [process.env.REACT_APP_CONFIG_CLIENTID+'/user_impersonation'] ,
  // extraScopesToConsent: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
};
export const loginRequestPowerBi = {
  scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"]
};
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
