import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Found from "../Images/intel-4.png";
import Auto from "../Images/intel-3.png";
import Smrt from "../Images/intel-2.png";
import intlgnt from "../Images/intel-1.png";
import i18n from "i18next";

class DealSummaryReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: this.props.show
        }

    }
    handleHide = () => {
        this.setState({ ...this.state, show: false })
        this.props.close();
    }
    render() {
        return (
            <Modal show={this.state.show} onHide={this.handleHide} backdrop="static" dialogClassName="dealReportModal" bsSize="xl" className="modal fade v-center" id="dealpopup">
                <Modal.Header closeButton className="no-border">
                <h3>{i18n.t("Intelligent_Operations_Overview")}</h3>
                </Modal.Header >
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="report-box deal-foundation">
                                <p><span className="imageAlignment"><img src={Found} alt="found-Image"></img></span><span>{i18n.t("Foundation")}</span></p>
                            </div>
                            <div>
                            {this.props.categories.map((e) => {
                                if (e.MaturityPhase === "Foundation") {
                                    return (
                                        <div>
                                            <p className={`deal-summary p-l-20 ${this.props.CategoryStatusOptions.filter(each => { if (each.value === e.CategoryStatus) { return each } })[0].color}`}>
                                             {e.CategoryName}</p>
                                                <p className="p-l-20">{e.CategoryOutcome}</p>

                                        </div>
                                    )
                                }

                            })}
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="report-box deal-automation">
                            <p><span className="imageAlignment"><img src={Auto} alt="found-Image"></img></span><span>{i18n.t("Automated")}</span></p>
                            </div>
                            <div>
                            {this.props.categories.map((e) => {
                                if (e.MaturityPhase === "Automated") {
                                    return (
                                        <div>
                                            <p className={`deal-summary p-l-20 ${this.props.CategoryStatusOptions.filter(each => { if (each.value === e.CategoryStatus) { return each } })[0].color}`}>
                                               {e.CategoryName}</p>
                                                <p className="p-l-20">{e.CategoryOutcome}</p>

                                        </div>
                                    )
                                }

                            })}
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="report-box deal-insights">
                            <p><span className="imageAlignment"><img src={Smrt} alt="found-Image"></img></span><span>{i18n.t("Insights")}</span></p>
                            </div>
                            <div>
                            {this.props.categories.map((e) => {
                                if (e.MaturityPhase === "Insights") {
                                    return (
                                        <div>
                                            <p className={`deal-summary p-l-20 ${this.props.CategoryStatusOptions.filter(each => { if (each.value === e.CategoryStatus) { return each } })[0].color}`}>{e.CategoryName}</p>
                                                <p className="p-l-20">{e.CategoryOutcome}</p>

                                        </div>
                                    )
                                }

                            })}
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="report-box deal-intelligent">
                            <p><span className="imageAlignment"><img src={intlgnt} alt="found-Image"></img></span><span>{i18n.t("Intelligent")}</span></p>
                            </div>
                            <div>
                            {this.props.categories.map((e) => {
                                if (e.MaturityPhase === "Intelligent") {
                                    return (
                                        <div>
                                            <p className={`deal-summary p-l-20 ${this.props.CategoryStatusOptions.filter(each => { if (each.value === e.CategoryStatus) { return each } })[0].color}`}>
                                                {e.CategoryName}</p>
                                                <p className="p-l-20">{e.CategoryOutcome}</p>

                                        </div>
                                    )
                                }

                            })}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
export default DealSummaryReport;