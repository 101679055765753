import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { AgGridReact } from "ag-grid-react";
import OfferingPopup from "./offeringPopup";
import axiosInstance from "../Shared/interceptor";
import _ from "lodash";
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class OfferingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      rowSelected: [],
      columnDefs: [
        {
          headerName: "Edit",
          suppressMovable: true,
          width: 80,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tbledit";
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.dealpopup(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
        },
        {
          headerName: "Delete",
          suppressMovable: true,
          width: 100,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tbldel";
            link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.deleteOffering(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
        },

        { headerName: "Offering Name", field: "Offering", suppressMovable: true, resizable: true },
        { headerName: "Process Name", field: "Process", suppressMovable: true, resizable: true },
        { headerName: "Status", field: "Status", suppressMovable: true, resizable: false },
        {
          headerName: "Is Active",
          field: "IsActive",
          resizable: false,
          suppressFilter: true,
          suppressSizeToFit: true,
          suppressMovable: true,
          cellRenderer: (params) => {
            return `<label className="form-check">
                        <input className="form-check-input" type="checkbox" disabled ${params.value ? "checked" : ""}>
                        <span className="checkmark"></span>
                         </label>`;
          },
        },
      ],
      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",
        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }
  dealpopup(e, data) {
    if (!_.isNil(data)) {
      // edit condition
      if (
        this.props.featureData.filter(
          (data) => String(data.Submenu) === "Offerings" && data.Operations.includes("Edit")
        ).length > 0
      ) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data });
      } else window.alert("Access Denied !!");
    } else {
      // add condition
      if (
        this.props.featureData.filter((data) => String(data.Submenu) === "Offerings" && data.Operations.includes("Add"))
          .length > 0
      ) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data });
      } else window.alert("Access Denied !!");
    }
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  deleteOffering(e, data) {
    if (
      this.props.featureData.filter(
        (data) => String(data.Submenu) === "Offerings" && data.Operations.includes("Delete")
      ).length > 0
    ) {
      if (window.confirm("Are you sure you want to delete the item?")) {
        axiosInstance
          .delete(`${LocalApiBaseUrl}Offering/DeleteOfferingNew`, {
            params: {
              OfferingID: data.OfferingId,
            },
          })
          .then((response) => {
            window.alert(response.data.StatusMessage);
            this.getOfferings();
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      }
    } else window.alert("Access Denied !!");
  }

  getOfferings() {
    axiosInstance
      .get(`${LocalApiBaseUrl}Offering/GetOfferings`)
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
        this.getEditOption();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getEditOption() {
    if (this.state.rowData.length > 0)
      for (let i = 0; i < this.state.rowData.length; i++) {
        let columnDefs = this.state.columnDefs;
        if (String(this.state.rowData[i].Status) === "Approved") {
          if (
            this.props.featureData.filter(
              (data) => String(data.Submenu) === "Offerings" && data.Operations.includes("Edit")
            ).length === 0
          ) {
            columnDefs = columnDefs.filter((data) => String(data.headerName) !== "Edit");
          }
        }
        if (
          this.props.featureData.filter(
            (data) => String(data.Submenu) === "Offerings" && data.Operations.includes("Delete")
          ).length === 0
        ) {
          columnDefs = columnDefs.filter((data) => String(data.headerName) !== "Delete");
        }
        this.setState({
          columnDefs: columnDefs,
        });
      }
  }

  componentWillMount() {
    this.getOfferings();
  }

  componentDidMount() {
    this.getOfferings();
  }

  handleSave(data, val) {
    if (val == "Save") {
      axiosInstance
        .post(`${LocalApiBaseUrl}Offering/AddOffering`, data)
        .then(
          (response) => {
            window.alert(response.data.StatusMessage);
            this.getOfferings();
          },
          (error) => {
            trycatchAlertPopup(error);
          }
        )
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
    else {
      axiosInstance
        .post(`${LocalApiBaseUrl}Offering/EditOffering`, data)
        .then(
          (response) => {
            window.alert(response.data.StatusMessage);
            this.getOfferings();
          },
          (error) => {
            trycatchAlertPopup(error);
          }
        )
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
    data = [];
    this.getOfferings();
  }

  // handleSave(data) {
  //   axiosInstance
  //     .post(`${LocalApiBaseUrl}Offering/AddEditOffering`, data)
  //     .then(
  //       (response) => {
  //         window.alert(response.data.StatusMessage);
  //         this.getOfferings();
  //       },
  //       (error) => {

  //       }
  //     )
  //     .catch((error) => {
  //       trycatchAlertPopup(error);
  //     });

  //   this.getOfferings();
  // }

  render() {
    const { gridOptions, columnDefs, rowData, viewmore, rowSelected } = this.state;

    return (
      <>
        <div className="tableFilter">
          <div className="form-section">
            <div className="adminform-row">
              <input type="text" className="form-control filter-search" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
              {this.props.featureData.filter((data) => data.Submenu == "Offerings" && data.Operations.includes("Add")).length > 0 &&

                <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.dealpopup(e) }}><span><i className="fal fa-plus-circle mr-1"></i></span> Add Offering</button>

              }
            </div>
          </div>
        </div>
        <div className="main-data">
          <div className="row">
            <div className="col-lg-12">
              <div className="ag-theme-balham ag-table">
                <AgGridReact
                  enableSorting={true}
                  enableFilter={true}
                  pagination={true}
                  paginationPageSize={50}
                  floatingFilter={true}
                  gridOptions={this.state.gridOptions}
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                </AgGridReact>
              </div>
            </div>
          </div>
          {viewmore ? <OfferingPopup data={this.state.rowSelected} show={this.state.viewmore} onClose={this.onClose} onSave={this.handleSave} allOfferingsData={this.state.rowData} /> : null}
        </div>
      </>
    )
  }
}
