
export  const Currency =
{
    "AFN": "؋",

  "AMD": "դր",
  "BHD": ".د.ب",
  "BDT": "৳",
  "BTN": "Nu.",
  "BND": "$",
  "KHR": "៛",
  "CNY": "¥",
  "CYP": "£",
  "INR": "₹",
  "IDR": "Rp",
  "IRR": "﷼",
  "IQD": "ع.د",
  "ILS": "₪",
  "JPY": "¥",
  "KZT": "лв",
  "KWD": "د.ك",
  "KGS": "лв",
  "LAK": "₭",
  "LBP": "£",
  "MYR": "RM",
  "MVR": "Rf",
  "MNT": "₮",
  "MMK": "K",
  "NPR": "₨",
  "KPW": "₩",
  "OMR": "﷼",
  "PKR": "₨",
  "JOD": "د.ا",
  "PHP": "₱",
  "QAR": "﷼",
  "SAR": "﷼",
  "SGD": "$",
  "KRW": "₩",
  "LKR": "₨",
  "SYP": "£",
  "TWD": "NT$",
  "TJS": "ЅM",
  "THB": "฿",
  "TRY": "₺",
  "TMM": "T",
  "AED": "د.إ",
  "UZS": "лв",
  "VND": "₫",
  "YER": "﷼",
  "DZD": "دج",
  "AOA": "Kz",
  "BWP": "P",
  "BIF": "FBu",
  "CVE": "$",
  "KMF": "CF",
  "CDF": "FC",
  "DJF": "Fdj",
  "EGP": "£",
  "ERN": "ናቕፋ",
  "ETB": "ብር",
  "XAF": "FCFA",
  "GMD": "D",
  "GHC": "GH₵",
  "GNF": "FG",
  "KES": "KSh,",
  "LSL": "L",
  "LRD": "$",
  "LYD": "ل.د",
  "MGA": "Ar",
  "MWK": "MK",
  "MRO": "UM",
  "MUR": "₨",
  "MAD": "DH",
  "MZN": "MT",
  "NAD": "$",
  "XOF": "CFA",
  "NGN": "₦",
  "RWF": "FRw",
  "STD": "Db",
  "SCR": "₨",
  "SLL": "Le",
  "SOS": "S",
  "ZAR": "R",
  "SSP": "£",
  "SDG": "SD",
  "SZL": "E",
  "TZS": "TSh",
  "TND": "د.ت",
  "UGX": "USh",
  "ZMK": "ZK",
  "ZWD": "$",
  "ALL": "Lek",
  "AZN": "₼",
  "BYR": "Br",
  "BAM": "KM",
  "BGN": "лв",
  "HRK": "kn",
  "CZK": "Kč",
  "DKK": "kr.",
  "EEK": "EEK",
  "GEL": "ლ",
  "HUF": "Ft",
  "ISK": "kr",
  "LVL": "Ls",
  "CHF": "CHF",
  "LTL": "Lt",
  "MKD": "ден",
  "MTL": "₤",
  "MDL": "L",
  "NOK": "kr",
  "PLN": "zł",
  "RON": "lei",
  "RUB": "₽",
  "RSD": "Дин.",
  "SKK": "Sk",
  "SEK": "kr",
  "UAH": "₴",
  "GBP": "£",
  "EUR": "€",
  "BSD": "$",
  "BBD": "$",
  "BZD": "BZ$",
  "CAD": "$",
  "CRC": "₡",
  "CUC": "$",
  "DOP": "RD$",
  "GTQ": "Q",
  "HTG": "G",
  "HNL": "L",
  "JMD": "J$",
  "MXN": "$",
  "NIO": "C$",
  "PAB": "B/.",
  "XCD": "$",
  "TTD": "TT$",
  "ARS": "$",
  "BOB": "$b",
  "BRL": "R$",
  "CLP": "$",
  "COP": "$",
  "GYD": "$",
  "PYG": "Gs",
  "PEN": "S/.",
  "SRD": "$",
  "UYU": "$U",
  "VEF": "Bs",
  "FJD": "$",
  "AUD": "$",
  "NZD": "$",
  "USD": "$",
  "PGK": "K",
  "WST": "WS$",
  "SBD": "$",
  "TOP": "T$",
    "VUV": "VT"
}
