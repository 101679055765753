import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Radar } from "react-chartjs-2";
import parse from 'react-html-parser'
import './QCScore.css';

class TabQCScore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            summaryHelpStatus: false,
            QcOverallScore:{},
            chatData:{},
            selectedData:{},
            hoverdValue:false,
            hoveredValueIndex:0,
            RandomOptions : {
                legend: {
                  display: true,
                  labels: {
                     usePointStyle: true,
                    boxWidth:"5px",
                  }
                },
                maintainAspectRatio: false,
                responsive:true,
                height:"500px",
                scale: {
                  pointLabels: {
                    fontColor: "#3c4858",
                    fontFamily: "graphik",
                    fontSize: 12,
                  },
                  reverse: false,
                  ticks: {
                    align:'left',
                    beginAtZero: true,
                    stepSize: 25,
                    fontColor: "#4a4a4a",
                    fontSize: 10,
                    fontStyle: "normal",
                    fontFamily: "graphik",
                    z: 0,
                    padding: 10,
                  },
                },
                plugins: {
                  datalabels: {
                    display: false,
                  },
                },
                tooltips: {
                    enabled: true,
                    backgroundColor: '#ffffff00',
                    custom : function(tooltipModel) 
                 {
                    if( tooltipModel.opacity > 0 ) {
                    } else {
                        this.clearHoverdValues()
                    }
                 }.bind(this),
                    callbacks: {
                        label: function (tooltipItems, data) {
                            return;
                        }.bind(this),
                        title: function(tooltipItem, data) {
                            return "";
                          },
                          labelColor: function(tooltipItem, chart) {
                            return {
                                backgroundColor: '#ffffff00'
                            }
                        },
                    }
                },
                
              }
        };
        this.summaryHelpModalShow = this.summaryHelpModalShow.bind(this);
        this.summaryHelpModalClose = this.summaryHelpModalClose.bind(this);
        this.QCScoreModalShow = this.QCScoreModalShow.bind(this);
        this.QCScoreModalClose = this.QCScoreModalClose.bind(this);

    }
    onDataHover =(tooltipItems,data)=>{
        let datasetIndex = tooltipItems.tooltipItems;
        let selectedIndex = tooltipItems.index;
 
        if(datasetIndex !== 0){
            let value = tooltipItems.value;
            let chatData = this.state.chatData;
              
                chatData.datasets[1].pointHoverBackgroundColor = value < 50 ? "#d5002f" : (value >= 50 && value <= 75) ? "#e6b019" : " #7ec344";
                chatData.datasets[1].pointHoverBorderColor = value < 50 ? "#d5002f" : (value >= 50 && value <= 75) ? "#e6b019" : " #7ec344";
                chatData.datasets[1].pointHoverColor = value < 50 ? "#d5002f" : (value >= 50 && value <= 75) ? "#e6b019" : " #7ec344";
                chatData.datasets[1].pointBackgroundColor = value < 50 ? "#d5002f" : (value >= 50 && value <= 75) ? "#e6b019" : " #7ec344";
                chatData.datasets[1].pointHighlightStroke = value < 50 ? "#d5002f" : (value >= 50 && value <= 75) ? "#e6b019" : " #7ec344";
            
            
            this.setState({chatData:chatData,hoverdValue:true,hoveredValueIndex:selectedIndex})
        }
        
    }
    summaryHelpModalShow() {
        this.setState({ summaryHelpStatus: true });
    }
    summaryHelpModalClose() {
        this.setState({ summaryHelpStatus: false });
    }
    QCScoreModalShow() {
        this.setState({ QCScoreModalStatus: true });
    }
    QCScoreModalClose() {
        this.setState({ QCScoreModalStatus: false });
    }
    clearHoverdValues = ()=>{
        
        this.setState({hoverdValue:false})
    }
   
    componentDidMount() {
      
      let lablesData = [];
      let  tragetSocreData = [];
      let  acheivedScoreData = [];
      let colors =[];
      let selectedData = {};
      if(this.props.QcOverallScore && this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length){
       this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.map((each)=>{
           if(each.LeverName_En === this.props.selectedTab){
            selectedData = each;
           each.AchievedScore < 50 ?  colors.push("#d5002f")  : (each.AchievedScore >= 50 && each.AchievedScore <= 75) ? colors.push("#e6b019")  : colors.push("#7ec344"); 
           }else{
               colors.push("#ffffff00")
           }

        lablesData.push(each.LeverName);
        tragetSocreData.push(each.TargetScore)
        acheivedScoreData.push(each.AchievedScore)
       })
    }



       let Data = {
        labels: lablesData,
        datasets: [
          {
            label: "Target Score",
            backgroundColor: "rgba(0, 186, 255, 0.2)",
            pointBackgroundColor: "#00baff",
            pointHighlightStroke: "#00baff",
            borderColor: "#00baff",
            data:tragetSocreData,
            borderWidth: 2,
            pointBorderWidth: 0,
            pointHoverRadius: 0,
            pointHoverBackgroundColor: "#00baff",
            pointHoverBorderColor:  "#00baff",
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
          },
          {
            label: "Achieved Score",
            backgroundColor: "rgba(156, 106, 222, 0.2)",
            pointBackgroundColor: colors, 
            pointBorderColor: colors,
            pointHighlightStroke: "#9c6ade",
            borderColor: "#9c6ade",
            data: acheivedScoreData,
            borderWidth: 2,
            pointBorderWidth: 0,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: colors,
            pointHoverBorderColor: colors,
            pointHoverBorderWidth: 0,
            pointRadius: 5,
            pointHitRadius: 5,
          },
        ],
      };
      this.setState({QcOverallScore:this.props.QcOverallScore,chatData:Data,selectedData:selectedData}) 
    }


    render() {
        return (
            this.state.QcOverallScore && this.state.QcOverallScore.QualityScoreDatas ? <>
                <Modal
                    show={true}
                    onHide={this.props.showQcGraph.bind(this,false,"")}
                    className="modal fade qcscorestatus-modal"
                    backdrop = "static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.selectedData.LeverName} - Quality Score</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="qcscorestaus-body">
                            <div className="qcscore-graphstatus">
                            {this.props.selectedTab  !== "Pulse Page" ? <div className="qcscore-graph tab-graph">
                                <Radar data={this.state.chatData} options={this.state.RandomOptions} width={435} height={800} />
                                </div>: ""}
                                <div className="qcscore-graphdata">
                                    <span className={(this.state.selectedData.AchievedScore < 50 ? "qcscore-percent red-status" : (this.state.selectedData.AchievedScore >=50 && this.state.selectedData.AchievedScore <= 75 ? "qcscore-percent orange-status" : "qcscore-percent green-status")) + ( this.props.selectedTab  === "Pulse Page" ? " qcscore-pulse-page" : "") }>
                                        <span>{this.state.selectedData.AchievedScore}{this.state.selectedData.Unit}</span> Achieved Score
             </span>
                                    <span className={ ( this.props.selectedTab  === "Pulse Page" ? "qcscore-percent blue-status qcscore-pulse-page" : "qcscore-percent blue-status")}>
                                        <span>{this.state.selectedData.TargetScore}{this.state.selectedData.Unit}</span> Target Score
             </span>
                                </div>
                            </div>
                            <div className="qcscorestatus-help">
                                <h6><span onClick={this.summaryHelpModalShow}><i class="far fa-question-circle"></i></span> Help</h6>
                                {this.state.selectedData.HelpText && typeof this.state.selectedData.HelpText === "string" ? <div>{parse(this.state.selectedData.HelpText)}</div> : null} 
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </> : <></>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        QcOverallScore: state.CurrentFilter.QcOverallScore,
    };
  };
  
  
  export default connect(mapStateToProps,"")(TabQCScore);
