import React from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';
import { getMonthAndTime } from './utilities/utils';
import TaggingUser from './TaggingUser';
import HighlightTag from './HighlightTag';
import UserReplies from './UserReplies';

class LoggedUserComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parentCommentEditMode: false,
      showReplyForm: false,
      editComments: '',
      taggedUsers: [],
      showChildCommentEditForm: false,
      editCommentId: 0,
      showReplies: false,
      showReplyCommentsCount: 2,
    };
  }

  componentDidUpdate(prevProps) {
    const { searchText, commentData } = this.props;
    const { showReplyCommentsCount } = this.state;
    if (prevProps.searchText !== searchText && showReplyCommentsCount <= 2 && commentData.Replies.length > 2) {
      if (commentData.Replies.filter((each, index) => {
        if (index > 1 && each.Comment.includes(searchText)) { return each; }
      }).length) {
        this.setState({ showReplyCommentsCount: commentData.Replies.length, showReplies: true });
      }
    }
  }

   handleReplies = (value) => {
     const { commentData } = this.props;
     this.setState({ showReplies: value });
     if (value === true) {
       this.setState({ showReplyCommentsCount: commentData.Replies.length });
     } else {
       this.setState({ showReplyCommentsCount: 2 });
     }
   }

   onEditCommentClick = (data) => {
     this.setState({ editCommentId: data.CommentId });
   }

   onCommentChange = (value) => {
     const { editComments } = this.state;
     if (editComments !== value && value.length <= 120) {
       this.setState({ editComments: value });
     }
   }

onTaggedUserChange = (users) => {
  this.setState({ taggedUsers: users });
}

  onParentCommentEditMode = (commentData) => {
    this.setState({
      parentCommentEditMode: true, showChildCommentEditForm: false, editComments: commentData.Comment, editCommentId: commentData.CommentId,
    });
  }

  saveEditComment = () => {
    const { editComments, taggedUsers, editCommentId } = this.state;
    const { onAddOrUpdateComment, commentData } = this.props;
    if (editCommentId && editCommentId === commentData.CommentId) {
      commentData.Comment = editComments;
      commentData.TaggedUsers = taggedUsers;
      commentData.IsEdited = true;
      onAddOrUpdateComment(commentData);
    } else {
      const currentEditedComment = commentData.Replies.filter((each) => each.CommentId === editCommentId)[0];
      currentEditedComment.Comment = editComments;
      currentEditedComment.TaggedUsers = taggedUsers;
      currentEditedComment.IsEdited = true;
      onAddOrUpdateComment(currentEditedComment);
    }
    this.closeEditComment();
  }

  closeEditComment = () => {
    this.setState({
      parentCommentEditMode: false,
      showChildCommentEditForm: false,
      showReplyForm: false,
      editComments: '',
      editCommentId: 0,
      taggedUsers: [],
    });
  }

  closeReplyForm = () => {
    this.setState({
      showReplyForm: false,
      parentCommentEditMode: false,
      showChildCommentEditForm: false,
      editCommentId: 0,
      editComments: '',
    });
  }

   showReplyForm = () => {
     this.setState({
       showReplyForm: true,
       parentCommentEditMode: false,
       showChildCommentEditForm: false,
       editCommentId: 0,
       editComments: '',
     });
   }

   onShowChildCommentEditForm = (rep) => {
     this.setState({
       showChildCommentEditForm: true,
       showReplyForm: false,
       parentCommentEditMode: false,
       editCommentId: rep.CommentId,
       editComments: rep.Comment,
     });
   }

   render() {
     const {
       parentCommentEditMode, showReplyCommentsCount, showReplies, showReplyForm, showChildCommentEditForm, editCommentId,
     } = this.state;
     const {
       searchText, suggestions, commentData, userName, deleteComment, onAddOrUpdateComment, setScrollElementId, charLimit,
     } = this.props;
     const replyCommentsData = showReplyCommentsCount === 2 ? commentData.Replies.slice(0, showReplyCommentsCount) : commentData.Replies;
     return (
       <div className="from-message">
         <div className="message-text">
           {commentData.IsActive ? (
             <>
               {!parentCommentEditMode ? (
                 <>
                   <p className="mb-0">
                     <span>{getMonthAndTime(commentData.CreatedTS)}</span>
                     {commentData.IsEdited && commentData.IsActive && <span className="edited-text pl-1">Edited</span>}
                     {commentData.Replies && !commentData.Replies.length ? (
                       <DropdownButton title="">
                         <Dropdown.Item className="comments-edit" onClick={() => this.onParentCommentEditMode(commentData)}>Edit</Dropdown.Item>
                         <Dropdown.Item className="comments-delete" onClick={() => deleteComment(commentData)}>Delete</Dropdown.Item>
                       </DropdownButton>
                     ) : null}
                   </p>
                   <HighlightTag
                     allreply=""
                     setScrollElementId={setScrollElementId}
                     comments={commentData}
                     searchText={searchText}
                     userName={userName}
                     handleReplies={this.handleReplies}
                   />
                 </>
               ) : (
                 <TaggingUser
                   comment={commentData.Comment}
                   onCommentChange={this.onCommentChange}
                   onTaggedUserChange={this.onTaggedUserChange}
                   isEditMode
                   charLimit={charLimit}
                   suggestionsData={suggestions}
                   saveEditComment={this.saveEditComment}
                   closeEditComment={this.closeEditComment}
                   taggedUserData={commentData.TaggedUsers}
                 />
               ) }
               {!showReplies && (
               <>
                 <p className="reply mb-0"><span onKeyDown={() => this.showReplyForm()} role="button" tabIndex={0} onClick={() => this.showReplyForm()}>Reply</span></p>
                 {showReplyForm
                   ? (
                     <UserReplies
                       closeReplyForm={this.closeReplyForm}
                       commentData={commentData}
                       suggestions={suggestions}
                       charLimit={charLimit}
                       userName={userName}
                       replyToUserName={userName}
                       onAddOrUpdateComment={onAddOrUpdateComment}
                     />
                   )
                   : null}
               </>
               )}
             </>
           ) : <p>This message is deleted</p> }
           {commentData.Replies ? (
             <div className="reply-back">
               {replyCommentsData.map((rep) => {
                 if (rep.UserName !== userName) {
                   return (
                     <div className={`message-text to-reply-back ${rep.IsNew ? 'new-message' : ''}`}>
                       {rep.Image
                         ? <img src={rep.Image} alt="profile" width="32" height="32" className="rounded-circle mr-1" />
                         : <span className="default-profile rounded-circle mr-1">{rep.ShortName}</span>}
                       <div className="message-reply-content">
                         <p className="mb-0">
                           <span className="name">{rep.UserName}</span>
                           <span>{getMonthAndTime(rep.CreatedTS)}</span>
                           {rep.IsEdited && rep.IsActive && <span className="edited-text pl-1">Edited</span>}
                         </p>
                         {rep.IsActive ? (
                           <HighlightTag
                             allreply=""
                             setScrollElementId={setScrollElementId}
                             comments={rep}
                             searchText={searchText}
                             userName={userName}
                           />
                         ) : <p>This message is deleted</p> }
                       </div>

                     </div>
                   );
                 }
                 return (
                   <>
                     {showChildCommentEditForm && editCommentId === rep.CommentId
                       ? (
                         <TaggingUser
                           placeholderText=""
                           comment={rep.Comment}
                           onCommentChange={this.onCommentChange}
                           onTaggedUserChange={this.onTaggedUserChange}
                           isEditMode
                           charLimit={charLimit}
                           saveEditComment={this.saveEditComment}
                           closeEditComment={this.closeEditComment}
                           suggestionsData={suggestions}
                           taggedUserData={rep.TaggedUsers}
                         />
                       )
                       : (
                         <div className="message-text">
                           <div className="message-reply-content">
                             {rep.IsActive ? (
                               <>
                                 <p className="mb-0">
                                   <span>{getMonthAndTime(rep.CreatedTS)}</span>
                                   {rep.IsEdited && rep.IsActive && <span className="edited-text pl-1">Edited</span>}
                                   <DropdownButton title="">
                                     <Dropdown.Item className="comments-edit" onClick={() => this.onShowChildCommentEditForm(rep)}>Edit</Dropdown.Item>
                                     <Dropdown.Item className="comments-delete" onClick={() => deleteComment(rep)}>Delete</Dropdown.Item>
                                   </DropdownButton>
                                 </p>
                                 <HighlightTag
                                   allreply=""
                                   setScrollElementId={setScrollElementId}
                                   comments={rep}
                                   searchText={searchText}
                                   userName={userName}
                                   handleReplies={this.handleReplies}
                                 />
                               </>
                             ) : <p>This message is deleted</p> }
                           </div>
                         </div>
                       )}
                   </>
                 );
               })}
             </div>
           ) : null}
           {commentData.Replies && commentData.Replies.length > 2
            && showReplies
             ? (
               <div>
                 <p className="view-hide-btn" role="presentation" onKeyDown={() => this.handleReplies(false)} onClick={() => this.handleReplies(false)}>Hide Replies</p>
               </div>
             ) : null }
           {commentData.Replies && commentData.Replies.length > 2
            && !showReplies
             ? (
               <div>
                 <p className="view-hide-btn" onClick={() => this.handleReplies(true)} role="presentation" onKeyDown={() => this.handleReplies(true)}>{`${commentData.Replies.length - 2 > 0 ? 'More Replies' : 'More Reply'}`}</p>
               </div>
             ) : null}
         </div>
       </div>
     );
   }
}
LoggedUserComments.propTypes = {
  commentData: PropTypes.object.isRequired,
  suggestions: PropTypes.object.isRequired,
  searchText: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  setScrollElementId: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  onAddOrUpdateComment: PropTypes.func.isRequired,
  charLimit: PropTypes.number.isRequired,
};
export default LoggedUserComments;
