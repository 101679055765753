import React from "react";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import axiosInstance from "../Shared/interceptor";
import { regexForNumbersOnly, regexForToolURL, commonToolRegex } from "../Shared/Constant";
import _ from "lodash";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class DetailsPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      date: new Date().getDate(), //Current Date

      Detail: {
        ConfigKey: false,
        ConfigValue: null,
        IsActive: false,
        Description: null,
      },
      errors: [],
      formIsValid: false,
    };

    this.onConfigValueChange = this.onConfigValueChange.bind(this);
  }

  onConfigValueChange(event) {
    let errors = {};
    const Detail = this.state.Detail;
    Detail.ConfigValue = event.target.value;
    this.setState({
      errors: errors,
      Detail: Detail,
    });
    
  }

  handleSave(e) {
    if (this.validateForm()) {
      this.props.onSave(this.state.Detail);

      this.handleHide();
    }
  }
  handleHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
    this.props.onSave();
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  componentDidMount() {
    this.setState({
      show: this.props.show,
    });
  }

  validateForm() {
    const Detail = this.state.Detail;
    let errors = {};
    let formIsValid = true;

    if (!Detail["ConfigValue"]) {
      formIsValid = false;
      errors["ConfigValue"] = "Value is required";
    }  

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  componentWillMount() {
    if (this.props.data) {
      this.setState({
        Detail: this.props.data,
      });
    }
  }

  render() {
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          backdrop="static"
          className="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            {!this.props.data ? (
              <h4 className="modal-title" id="dealprofile">
                Add Detail
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile">
                Edit Detail
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">
                      ConfigKey<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      disabled
                      value={this.state.Detail.ConfigKey}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">
                      Description<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      disabled
                      value={this.state.Detail.Description}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">
                      Config Value<span className="required">*</span>
                    </label>
                      <textarea
                       
                        className="form-control"
                        id="name"
                        onChange={this.onConfigValueChange}
                        value={this.state.Detail.ConfigValue}
                      />
                   
                    <span className="errorMsg">{this.state.errors.ConfigValue}</span>
                  </div>
                  <div className="form-group col-lg-6 label-adjust">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        disabled
                        defaultChecked={this.state.Detail.IsActive}
                      />
                      <span className="checkmark"></span>
                      <span className="form-check-label">Is Active</span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                {!this.props.data ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Create"
                  />
                ) : (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Update"
                  />
                )}
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
