import React, { Component } from 'react';
import i18n from 'i18next';
import SubStepPopUp from './SubStepPopUp';
import WO_Details from './WorkOrchestrationDetails';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';

export default class SubStepListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  deleteSubStep = (key) => {
    const { subStepData } = this.props;

    if (subStepData.length > 1) { 
      const filterProcess = this.props.filter.process;
      const data = subStepData.find((ele) => ele.key === key);
      const sOCategoryID = data.SOCategoryID;
      if(window.confirm(i18n.t('All the tools and metrics mapped to this sub-step will be deleted permanently'))){
        axiosInstance.post(`${LocalApiBaseUrl}Master/DeleteSOCategoryMasterTemplate?soCategoryId=${sOCategoryID}&offeringId=${filterProcess}`)

          .then((response) => {
            this.props.getOfferingAPICallback(true);          
            window.alert(i18n.t('SynOps WO sub-step has been deleted successfully'));
            this.props.card(0);
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      }
    }
    else{
      window.alert(i18n.t('alert_message_when_deleting_onlysubstep') +  ' ' + this.props.StepName);
    }
  }

  generateDetails(metricRowDetails) {
    const {
      screen,
      card,
      toolCat,
      filter,
      roledata,
      deleteStep,
      selectedStep,
      StepName,
      selectedSubStepID,
      getOfferingAPICallback,
      RecordStatus,
      isManintencneWindowOpen
    } = this.props;
    const Data = [...metricRowDetails];
    const dtlength = Data.length;
    const no_per_exact = Math.floor(dtlength / 4);
    const no_of_row = dtlength % 4 === 0 ? no_per_exact : no_per_exact + 1;
    let rowdone = false;
    let rowTheList = [];
    let mainIndex;
    let count = 0;

    return Data.map((data, index) => {
      data.key = index + 1;
      mainIndex = index;
      rowTheList = rowdone ? [] : rowTheList;
      switch (screen) {
        case 'Configurator':
          if ((index + 1) % 3 === 0 || index === dtlength - 1) {
            rowdone = true;
            count += 1;
          } else {
            rowdone = false;
          }

          rowTheList.push(data);
          if (count % 2 === 0 && count !== 0 && rowdone) {
            rowTheList = rowTheList.reverse();
          }

          if (rowdone) {
            return (
              <div className="timeline-row">
                {rowTheList.map((row, index) => {
                const each= {...row};
                return(
                  <WO_Details
                    screen={screen}
                    selectedStep={selectedStep}
                    generateData={each}
                    no_per_row={3}
                    card={card}
                    index={index}
                    main={mainIndex}
                    StepName={StepName}
                    isScreenInEditMode
                    selectedSubStepID={selectedSubStepID}
                    filter={filter}
                    RecordStatus={RecordStatus} 
                    isManintencneWindowOpen={isManintencneWindowOpen}                      
                  />
                );
              })}
              </div>
            );
          }
          break;
        case 'SuperAdmin':
          if ((index + 1) % 3 === 0 || index === dtlength - 1) {
            rowdone = true;
            count += 1;
          } else {
            rowdone = false;
          }

          rowTheList.push(data);
          if (count % 2 === 0 && count !== 0 && rowdone) {
            rowTheList = rowTheList.reverse();
          }

          if (rowdone) {
            return (
              <div className="timeline-row">
                {rowTheList.map((row, index) => (
                  <WO_Details
                    getOfferingAPICallback={getOfferingAPICallback}
                    screen={screen}
                    deleteStep={this.deleteSubStep}
                    selectedStep={selectedStep}
                    filter={filter}
                    generateData={row}
                    no_per_row={3}
                    card={card}
                    index={index}
                    main={mainIndex}
                    roledata={roledata}
                    SubStepContainer
                    StepName={StepName}
                    isScreenInEditMode
                    selectedSubStepID={selectedSubStepID}
                  />
                ))}
              </div>
            );
          }
          break;
        case 'Dashboard':
          if ((index + 1) % 3 === 0 || index == dtlength - 1) {
            rowdone = index !== 0;
            rowdone = dtlength === 1 ? true : rowdone;
            if (rowdone == true) count += 1;
          } else {
            rowdone = false;
          }
          rowTheList.push(data);
          if (count % 2 == 0 && count != 0 && rowdone) {
            rowTheList = rowTheList.reverse();
          }
          if (rowdone) {
            return (
              <div className="timeline-row">
                {rowTheList.map((row, index) => (
                  <WO_Details
                    screen={screen}
                    ToolsCategory={toolCat}
                    selectedStep={selectedStep}
                    generateData={row}
                    no_per_row={3}
                    card={card}
                    index={index}
                    main={mainIndex}
                    selectedSubStepID={selectedSubStepID}
                  />
                ))}
              </div>
            );
          }
          break;
        default:
          return null;
      }
    });
  }

  render() {
    const {
      screen, addSubStepStatus, addSubStepConfStatus, addSubStepModalToggler, subStepData, StepName, SOCategoryID, filter, getOfferingAPICallback, selectedSubStepID
    } = this.props;

    return (
      <>
        <div className="row">
          <div
            className="timeline-highlight configurator-timeline substep-container"
          >
            {this.generateDetails(subStepData)}
          </div>
          {addSubStepStatus
            && (
              <SubStepPopUp
                StepName={StepName}
                show={addSubStepStatus}
                modalClose={addSubStepModalToggler}
                filter={filter}
                SOCategoryID={SOCategoryID}
                getOfferingAPICallback={getOfferingAPICallback}
                screen={screen}
                selectedSubStepID={selectedSubStepID}
              />
            )}
          {addSubStepConfStatus
            && (
              <SubStepPopUp
                StepName={StepName}
                show={addSubStepConfStatus}
                modalClose={addSubStepModalToggler}
                filter={filter}
                SOCategoryID={SOCategoryID}
                getOfferingAPICallback={getOfferingAPICallback}
                screen={screen}
                selectedSubStepID={selectedSubStepID}
              />
            )}
        </div>
      </>
    );
  }
}
