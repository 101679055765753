import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import { store } from "../reducers/configureStore";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import moment from "moment";
import i18n from "i18next";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
const currentDate = new Date();
const today = new Date().toJSON().substring(0, 10);


function SelectToolPopup(props) {

    let [ToolTipdata,setToolTipdata]=useState({});
    let[showLoaderonPage,setshowLoaderonPage]=useState(false);
    let[isDataLoaded,setisDataLoaded]=useState(true);
    let[toolsList,settoolsList]=useState([]);
    let[isDisableSubmit,setisDisableSubmit]=useState(true);

    const ExampleCustomInput = ({ value, onClick }) => (
        <span className="example-custom-input" onClick={onClick}>
            <FontAwesomeIcon icon={faCalendarAlt} />
        </span>
    );

    useEffect(() => {
        let{showLoaderonPage,toolsList,WoStepData,isDataLoaded}=props
        setshowLoaderonPage(showLoaderonPage);
        setisDataLoaded(isDataLoaded);
        if(toolsList.length>0){
          let mergedToolList=[...toolsList];
          
          let WoStepToolsData=WoStepData.filter(a=>a.SOCategoryID===
            props.selectedSubStepID)[0].WOStepTools
           
            let AllApplicationMappingIDs=[]
            WoStepToolsData.map((a,index)=>{
              const ToolID=a.ToolID!=null && a.ToolID>0?a.ToolID:a.ApplicationMappingID;
                     if(ToolID>0){
                      AllApplicationMappingIDs=[...AllApplicationMappingIDs,ToolID]; 
                     }
                     if(a.PulseToolsConfigDetailsID>0 && mergedToolList.some(b=>b.PulseToolsConfigDetailsID===a.PulseToolsConfigDetailsID)){
                      let ApplicationMappingID=mergedToolList.filter(b=>b.PulseToolsConfigDetailsID===a.PulseToolsConfigDetailsID)[0].ApplicationMappingID 
                      AllApplicationMappingIDs=[...AllApplicationMappingIDs,ApplicationMappingID];
                    }
                    return a;
            });


            mergedToolList=mergedToolList.filter((a)=>{
                return !AllApplicationMappingIDs.some(b=>b===a.ApplicationMappingID);
            });
            /* mergedToolList=mergedToolList.filter((a)=>{
              if(a.Custom==="MTCTool"){
                if(!WoStepToolsData.some(b=>b.ToolID===a.ApplicationMappingID)){
                  return a;
                }
              }s
              if(a.Custom==="PulseTool"){
    
                if(!WoStepToolsData.some(b=>b.PulseToolsConfigDetailsID===a.PulseToolsConfigDetailsID)){
                  return a;
                }
              } 
            }); */
        settoolsList(mergedToolList);
        }
    }, [props])

    useEffect(()=>{
        if(toolsList.length>0){
          var isDisableSubmit=toolsList.some(a=>a.IsChecked);
          setisDisableSubmit(!isDisableSubmit);
        }   
        else{
          setisDisableSubmit(true);
        }
    },[toolsList])

    const dataforTooltip = (e, Tipdata) => {
      setToolTipdata(Tipdata);
      ReactTooltip.rebuild();
    };

    const closepopup=()=>{
props.selectToolModalTogglerClose();
}
const submitData=()=>{
  setshowLoaderonPage(true);
  let WOdata=toolsList.filter(a=>a.IsChecked).map((a,index)=>{
    a.ExpectedDeploymentDate=a.ExpectedDeploymentDate==="" ?null:a.ExpectedDeploymentDate;
    a.DeploymentDate=a.DeploymentDate==="" ?null:a.DeploymentDate;
    a.SOCategoryID = props.selectedSubStepID;
    return a;
  });
  let currentData = {
    FilterParams: {
      OrgEntityID: props.filter.Client,
      OfferingID: props.filter.process,
      LocationID: props.filter.Locations,
      MonthDT: props.filter.Month,
    },
    WO: WOdata
  };
  axiosInstance
    .post(`${LocalApiBaseUrl}Configurator/InsertUpdateWOToolsOnSteps`,currentData)
    .then((response) => {
      if (response.data) {
        setshowLoaderonPage(false);
        axiosInstance
          .get(`${LocalApiBaseUrl}Configurator/GetWorkOrchestrationQualityScore`, {
            params: {
              LocationID: props.filter.Locations,
              OrgEntityID: props.filter.Client,
              OfferingID: props.filter.process,
              MonthDT: props.filter.Month,
              LanguageID: store.getState().CurrentFilter.languageData.id,
            },
          })
          .then((response) => {
            if (response.data) {
              store.dispatch({
                type: "SAVE_QC_SCORE_OF_EACH_TAB",
                payload: response.data,
              });
            }
          })
          .catch((error) => {
            setshowLoaderonPage(false);
            trycatchAlertPopup(error);
          });
      store.dispatch({ type: "ADD_EDIT_WO_TOOLS", payload:
        {
          wostepdata:response.data.wostepdata,
          SOCategoryID:props.selectedSubStepID 
        }});
        props.selectToolModalTogglerClose(); 
      }
    })
    .catch((error) => {
      setshowLoaderonPage(false);
      trycatchAlertPopup(error);
    });
}
const handleCheckbox=(e,data)=>{
    e.stopPropagation();
    let isChecked = e.target.checked;
    let mergeTooldetails=[...toolsList];

    mergeTooldetails = mergeTooldetails.map((a, index) =>
    a.Custom==="MTCTool" ? a.ApplicationMappingID === data.ApplicationMappingID
          ? { ...a, IsChecked: isChecked,DeploymentDate:isChecked?a.DeploymentDate:
            "",ExpectedDeploymentDate:isChecked?a.ExpectedDeploymentDate:"" }:a
          : a.Custom==="PulseTool"
          ?
          a.PulseToolsConfigDetailsID === data.PulseToolsConfigDetailsID
          ?{ ...a, IsChecked: isChecked,DeploymentDate:isChecked?a.DeploymentDate:
            "",ExpectedDeploymentDate:isChecked?a.ExpectedDeploymentDate:"" }:a
          :a
      );
      settoolsList(mergeTooldetails);
}
const handleDeploymentDateChange=(data,date,dateType)=>{
  ReactTooltip.hide();
    let mergeTooldetails=[...toolsList];
    let inputDate=new Date();
    if (
        moment(date)
          .format()
          .includes("+")
      ) {
        inputDate = moment(date)
        .format("MMMM D, YYYY")
          .split("+")[0];
      } else {
        inputDate = moment(date).format("MMMM D, YYYY");
      }

    mergeTooldetails = mergeTooldetails.map((a, index) =>
    a.Custom==="MTCTool" ? a.ApplicationMappingID === data.ApplicationMappingID
          ? { ...a, 
            [dateType]: inputDate 
          }:a
          : a.Custom==="PulseTool"
          ?
          a.PulseToolsConfigDetailsID === data.PulseToolsConfigDetailsID
          ?{ ...a, [dateType]: inputDate }:a
          :a
      );
      settoolsList(mergeTooldetails);
}
/* useEffect(() => {

}, [toolsList]) */

    return (
        <div>
        <LoadingOverlay
          className="custom-loader"
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={showLoaderonPage}
        ></LoadingOverlay>
        {props.selectToolStatus && (
                    <Modal show={props.selectToolStatus} onHide={closepopup} 
                    className="tooldata-pop selecttool-modal"
                    backdrop="static"
                    >
                    <Modal.Header>
                        <Modal.Title>{i18n.t("Select_Tool_for_Step")}</Modal.Title>
                    </Modal.Header>
                    {toolsList.length>0 ?(
                    <Modal.Body>
                        <div className="selecttool-list">
                            <ul>
                            {toolsList.map((each,index)=>(
                                <li className="d-flex justify-content-between" key={index}>
                                    <label className="form-check">
                                        <input className="form-check-input" type="checkbox" name="foo"
                                         onChange={(e) => {
                                           e.stopPropagation();
                                          handleCheckbox(e, each);
                                         }}
                                        checked={each.IsChecked}

                                        />
                                        <span className="checkmark"></span>
                                        <div className="form-check-label">
                                            <h6 className="wo-title active">{each.ApplicationName}</h6>
                                            <p>{each.ApplicationDescription}</p>
                                        </div>
                                    </label>
                                    <div className="select-tool-datepicker" style={{display:"flex",alignItems:"center"}}>
                                        <div 
                                        className="mr-4"
                                        data-tip
                                          data-for="Wo-tooltip"
                                          onMouseEnter={(e) => {
                                            dataforTooltip(e,{name:"Deployment Date",data:each.DeploymentDate});
                                          }}
                                          onMouseLeave={() => {
                                            ReactTooltip.hide();
                                          }}>
                                          <div className={each.IsChecked?
                                        _.isEmpty(each.DeploymentDate)?"lighter-disabled":""
                                        :
                                        "disabled-calender"}>
                                        <DatePicker
                                        maxDate={currentDate}
                                          selected={
                                            each.DeploymentDate
                                              ? new Date(
                                                each.DeploymentDate)
                                                :""}
                                            onChange={(date) =>  
                                            handleDeploymentDateChange(each,date,"DeploymentDate")
                                            }
                                            customInput={<ExampleCustomInput />}
                                            popperPlacement="auto"
                                        />
                                        </div>
                                        </div>
                                        <div
                                          data-tip
                                          data-for="Wo-tooltip"
                                          onMouseEnter={(e) => {
                                            dataforTooltip(e,{name:"Expected Deployment Date",data:each.ExpectedDeploymentDate});
                                          }}
                                          onMouseLeave={() => {
                                            ReactTooltip.hide();
                                          }}
                                        >
                                        <div className={each.IsChecked?
                                        _.isEmpty(each.ExpectedDeploymentDate)?"lighter-disabled":""
                                        :
                                        "disabled-calender"}>
                                         <DatePicker
                                         minDate={currentDate}
                                            selected={
                                            each.ExpectedDeploymentDate
                                              ? new Date(
                                                each.ExpectedDeploymentDate)
                                                :""}
                                            onChange={(date) =>  
                                            handleDeploymentDateChange(each,date,"ExpectedDeploymentDate")
                                            }
                                            customInput={<ExampleCustomInput />}
                                            popperPlacement="auto"
                                        />
                                        </div>
                                        </div>
                                        {/* <p>{each.DeploymentDate===""?i18n.t("Deployment_Date"):each.DeploymentDate}</p> */}
                                    </div>
                                </li>
                            ))}
                            </ul>
                            {Object.entries(ToolTipdata).length!==0 && (
                <ReactTooltip
                    id="Wo-tooltip"
                    place="bottom"
                    type="dark"
                    effect="solid"
                    className="infoiconmor-tooltip"
                >
            {!_.isEmpty(ToolTipdata.data) && ToolTipdata.data!=="Invalid date"?
            <>
            <span>{ToolTipdata.name}:</span>
            <span className="d-block">{moment(ToolTipdata.data).format('DD/MM/YYYY')}</span>
            </>
            :<span>{ToolTipdata.name}</span>}
          </ReactTooltip>
        )}
                        </div>
                        </Modal.Body>
                    ):
                    (
                      !isDataLoaded && (
                      <div className="notool-message">
                              <h2>{i18n.t("No_Tool_Data_Message")}</h2>
                      </div>
                      )
                    )}
                   { !isDataLoaded && (
                    <Modal.Footer>
                        <div className="modal-btn d-flex justify-content-between align-items-center">
                            <p className="mb-0">
                                <span><i class="fa fa-info-circle" aria-hidden="true"></i>{i18n.t("New_tool_Pulse")}</span>
                            </p>
                            <div>
                                <button className="btn btn-default mr-2" onClick={()=>closepopup()}>{i18n.t("Cancel")}</button>
                                <button className="btn btn-primary border-0" 
                                disabled={isDisableSubmit}
                                onClick={()=>submitData()} >{i18n.t("Select")}</button>
                            </div>
                        </div>
                    </Modal.Footer>
                   )}
                </Modal>
        
        )}
        </div>
    )
}
const mapStateToProps = (state) => {
  return {
      languageData: state.CurrentFilter.languageData,
      clientData: state.CurrentFilter.ClientData,
      roleReducers: state.roleReducers.roleReducers,
      SelectedFilter: { ...state.CurrentFilter.SyncFilter },
      WoStepData: [...state.WOtileReducers.tile],
  };
};
export default connect(
  mapStateToProps,
  ""
)(SelectToolPopup);
