import React from "react";
import { toolThreshold } from "../Shared/Common";
import TrendGraphs from "../Analytics/TrendGraphs";
import i18n from "i18next";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import MetricComments from "../Dashboard/MetricComments";
import { connect } from "react-redux";
import { ValueUnit, ValueUnitNoRoundOff, MetricValueUnitFormat, CommonMetriTileFix} from "../Shared/Common";
import $ from 'jquery';
class MetricView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			viewGraph: false,
			eachDetails: this.props.data,
			boData: {
				SOCategoryID: 0,
				kpiData: [
		
				],
			},
			commentPopUp: false,
		};
    this.handleGraph = this.handleGraph.bind(this);
	}


	onClose = (e) => {
		this.setState({
       viewmore: false,
       viewGraph: false
      });
	};

  handleGraph(name, Id, mUnit, e) {
		let kpiMapping = Id;
		e.preventDefault(Id, e);
		this.setState({ viewGraph: true, kpiMapping, name, mUnit });
	}

	
	onCommentClick = (val) => {
		if (val === 1) {
			this.setState({
				commentPopUp: true,
			});
		} else {
			this.setState({
				commentPopUp: false,
			});
		}
	};
	dealpopup = (data, e) => {
		e.preventDefault();

		this.setState({
			data: data,
		});

		this.setState({
			viewmore: true,
		});

		e.preventDefault();
	};

  openChat = (KPIMappingID) => {
		this.props.saveCommentHistoryId({ 
			SOCategoryID: this.props.SOCategoryID,
			KPIMappingID: KPIMappingID, showHistory: false 
		})
	}

  componentDidMount(){
    CommonMetriTileFix();
  }
	render() {
    
		let eachDetails = this.props.data;
    
		let BenchmarkName=eachDetails.metDataAttr.map((e)=>{
			return e.AttributeName
		}
		 );
     
	let BenchmarkValue=eachDetails.metDataAttr.map((e)=>{
		return e.AttributeValue
	}
	 );
		const options = {
      loop: true,
      margin: 24,
      responsiveClass: "",
      dots: false,
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
            items: 3,
            nav: true,
            loop: false
        },
        1440: {
            items: 4,
            nav: true,
            loop: false
          },
          1600: {
              items: 4,
              nav: true,
              loop: false
          },
          1800: {
              items: 5,
              nav: true,
              loop: false
        }
      }
    };


		let AttrValue = "";
		let metricName = "";
		let unit = "";
		let metricData = "";
		let Description = "";
		let leadingIndustryValue = "";
		let similarIndustryValue = "";
		let ValueBlock = true;
		let RelatorBlock = this.props.isRelatorBlock;
	
    let MetricValueUnitFormatData = "";
    let middleAlign = "";

		metricName = eachDetails.MetricName;
		unit = eachDetails.MetricUnit;
		unit=unit.trim();
		if (eachDetails.metDataAttr != null) {
			if (eachDetails.metDataAttr && eachDetails.metDataAttr.length > 0) {
				leadingIndustryValue = eachDetails.metDataAttr[0] ? eachDetails.metDataAttr[0].AttributeValue : "";
				similarIndustryValue = eachDetails.metDataAttr[1] ? eachDetails.metDataAttr[1].AttributeValue : "";

				//For old data which has Attribute name and attribute value as empty strings/ null

        if(eachDetails.metDataAttr[0]){
          eachDetails.metDataAttr[0].AttributeName =
            this.props.attributes && this.props.attributes.length > 0
            ? this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName1
            : eachDetails.metDataAttr[0].AttributeName;
        }

            if(eachDetails.metDataAttr[1]){
              eachDetails.metDataAttr[1].AttributeName =
                this.props.attributes && this.props.attributes.length > 0
                ? this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName2
                : eachDetails.metDataAttr[1].AttributeName;
            }
			}

			
		}
		
		let mappingId = eachDetails.KPIMappingID;
		let statusColor = "item gray-color";
		if (eachDetails != undefined) {
      if(unit.includes('$') || unit.includes('€') || unit.includes('£') || unit.includes('₹') || unit.includes('SGD') || unit.includes('R$')) {
				metricData = ValueUnitNoRoundOff(eachDetails.MetricValue);
			  } 
			  else {
				metricData = ValueUnit(eachDetails.MetricValue);
			  }
      MetricValueUnitFormatData = MetricValueUnitFormat(unit, metricData);
      
      middleAlign = (!leadingIndustryValue || leadingIndustryValue.toUpperCase() == "NA") && (!similarIndustryValue || similarIndustryValue.toUpperCase() == "NA")|| !RelatorBlock?
      ' middle-align':'';
		
			Description = eachDetails.KPIDesp ? eachDetails.KPIDesp : "";
      
			
      if (eachDetails.RAGStatus != undefined && eachDetails.RAGStatus != null) {
        switch (eachDetails.RAGStatus.toLowerCase()) {
          case "grey":
            statusColor = "item gray-color";
            break;
          case "red":
            statusColor = "item red-color";
            break;
          case "orange":
            statusColor = "item amber-color";
            break;
          case "green":
            statusColor = "item green-color";
            break;
          case "amber":
            statusColor = "item amber-color";
            break;
          default:
          // code block
        }
      }
    }
  
    const formattertwo=new Intl.NumberFormat('en',{
      useGrouping:true
    })
    return (
      <div className={`${statusColor}`} data-place="bottom" data-tip={toolThreshold(eachDetails)}>
       
        
          <div className="item-left">
            {ValueBlock ? (
              <>
                <p className="tile-metric-value">
                 {MetricValueUnitFormatData}
                  
              
               </p>
              </>
            ) : null}
            <p className="tile-metric-desc" data-tip data-for={'customTooltip' + eachDetails.KPIMappingID}>
            
              {metricName}
           
            </p>
          </div>
					   
            {this.state.viewGraph ? (
                <TrendGraphs
                  show={true}
                  onClose={this.onClose}
                  kpiMappingId={this.state.kpiMapping}
                  kpiName={this.state.name}
                  kpiUnit={this.state.mUnit}
                  Month={this.props.data.Month}
                  roledata={this.props.roledata}
                  dealFilter={this.props.dealFilter}
                  info={eachDetails}
                  roleReducers={this.props.roleReducers}
                  ClientData = {this.props.ClientData}
                  FilterDetails = {this.props.FilterDetails}
                />
              ) : null}
              
           <div className={RelatorBlock?'item-right isRelatorBlock'+middleAlign:'item-right'+middleAlign}>
            {Description ? (
               <p className="tile-metric-name" data-tip data-for={'customTooltip_desc' + eachDetails.KPIMappingID}>
                
                  {Description}    
                 
             </p>
            ) : (
              <p className="tile-metric-name">&nbsp;</p>
            )}

            {RelatorBlock && (
            <div className="metric-lead-lagg">
            {BenchmarkValue[0] && BenchmarkName[0] && (BenchmarkValue.length>0 &&  BenchmarkValue[0].toUpperCase()!=='NA' && (
              <p>
                <span className="lead-lagg-label">{BenchmarkName[0]}</span>
                <span className="lead-lagg-val">{BenchmarkValue[0]}</span>
              </p>
            ))}
              {BenchmarkValue[1] && BenchmarkName[1] && (BenchmarkValue.length>0 && BenchmarkValue[1].toUpperCase()!=='NA' && (
              <p>
                <span className="lead-lagg-label">{BenchmarkName[1]}</span>
                <span className="lead-lagg-val">{BenchmarkValue[1]}</span>
              </p>
              ))}
            </div>
              )}
          </div>
          {this.state.commentPopUp ? (
            <MetricComments
         
              isRelatorBlock={this.props.isRelatorBlock}
              info={eachDetails}
              show={this.state.commentPopUp}
              onClose={this.onCommentClick.bind(this, 2)}
            />
          ) : null}
          <div className="dropdown dropleft ondropOpenColor">
            <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
            <div className="dropdown-menu">
              <a href="javascript:void(0)" className="dropdown-item" onClick={this.onCommentClick.bind(this, 1)}>
                <span className="info-icon"></span>{i18n.t("Info")}
              </a>
             
                  <a
                    href="javascript:void(0)"
                    className="dropdown-item"
                    onClick={(e) =>
                      this.handleGraph(metricName, mappingId, unit, e)
                    }
                  >
                    <span className="insights-new-icon"></span>{i18n.t("Insights")}
                  </a>
                  {this.props.roleReducers.filter(each=>each.FeatureName === "Commentary" && (each.Operations.includes("View") || each.Operations.includes("Post")) ).length >0 && 
                  <a
                  href="javascript:void(0)"
                  className="dropdown-item"
                  onClick={(e) =>
                    this.openChat(eachDetails.KPIMappingID)
                 }
                 >     
                  <span className="comments-new-icon"></span>{i18n.t("Comments")}
                </a>
                  }
                  
             
            </div>
          </div>
      </div>
    );
	}
}
const mapStateToProps = (state) => {
	return { 
	  roleReducers: state.roleReducers.roleReducers,
    ClientData: state.CurrentFilter.ClientData,
    FilterDetails: state.CurrentFilter.SyncFilter,

	};
  };
  const mapDispatchToProps = (dispatch) => {
    return ({
      saveCommentHistoryId: (data) => { 
        dispatch({ type: 'SELECTED_METRIC_COMMENT_HISTORY', payload: data 
      }) }
    })
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(MetricView);