import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import Moment from "moment";
import "chartjs-plugin-datalabels";
import "react-datepicker/dist/react-datepicker.css";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { months } from "moment";
axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class WeekWiseTrends extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dsKpiData: [],
      errors: [],
      shown: false,
      formIsValid: false,
      weekWiseChart: {
        StartMonth: new months(),
      },
    };
    this.validateForm = this.validateForm.bind(this);
  }

  validateForm() {
    let weekChart = this.state.weekWiseChart;
    let errors = {};
    let formIsValid = true;

    if (!weekChart["StartMonth"]) {
      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });

    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  onStartMonthChange = (event) => {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const weekWiseChart = this.state.weekWiseChart;
    weekWiseChart.StartMonth = event;

    this.setState({ StartMonth: event });
    this.setState({
      weekWiseChart: weekWiseChart,
    });
  };

  handleSave() {
    if (this.validateForm()) {
      this.GetWeekData();
    }
  }

  componentDidMount() {}
  GetWeekData() {
    this.setState({
      shown: true,
    });

    var date = Moment(this.state.weekWiseChart.StartMonth).format("MM/YYYY");
    axiosInstance
      .get(`${LocalApiBaseUrl}TrendReports/GetWeekVisitor?`, {
        params: { date: date },
      })
      .then((res) => {

        let UserIDLoginCount = [];
        let DatePeriod = [];
        res.data.forEach((element) => {
          UserIDLoginCount.push(element.UserIDLoginCount);
          DatePeriod.push(element.DatePeriod);
        });
        this.setState({
          dsKpiData: {
            labels: DatePeriod,
            datasets: [
              {
                data: UserIDLoginCount,
                fill: false,
                lineTension: 0.1,
                animationEnabled: true,
                backgroundColor: "rgba(75,192,192,0.4)",
                borderColor: "rgba(75,192,192,1)",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "rgba(75,192,192,1)",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "rgba(75,192,192,1)",
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                theme: "light2",
                vline: "true",
              },
            ],
          },
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  render() {
    const { StartMonth, shown, dsKpiData } = this.state;
    return (
      <>
        <div className="main-data">
          <div className="row">
            <div className="form-group col-lg-4 block-picker">
              <label className="datepicker-label" htmlFor="">
                Start Month<span className="required"></span>
              </label>
              <DatePicker
                className="form-control"
                selected={StartMonth}
                autoComplete="off"
                onChange={this.onStartMonthChange.bind(this)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                id="StartDate"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <button
                className="btn btn-primary btn-sm"
                id="btn2"
                onClick={() => this.handleSave()}
              >
                Show Traffic
              </button>
            </div>
          </div>
        </div>
        {shown ? (
          <div className="whiteBgGraph mt-2 mb-4">
            <Line
              data={dsKpiData}
              options={{
                maintainAspectRatio: true,
                responsive: true,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        precision: 1,
                      },
                      gridLines: {
                        drawOnChartArea: false,
                      },

                      scaleLabel: {
                        display: true,
                        labelString: "Number of visitor",
                      },
                    },
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        drawOnChartArea: false,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: "Week",
                      },
                      stripLines: [
                        {
                          startValue: 1935,
                          endValue: 1945,
                          color: "#d8d8d8",
                        },
                      ],
                    },
                  ],
                },
                legend: { display: false },
              }}
            />
          </div>
        ) : null}
      </>
    );
  }
}
