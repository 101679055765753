import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
// import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ellipseIcon from '../Images/ellipsis-v.svg';
import ellipseSelectedIcon from '../Images/ellipsis-v-selected-icon.svg';
import Delete from '../Images/delete-comment.svg';
import Edit from '../Images/edit-comment.svg';
import EditSelected from '../Images/editSelected-icon.svg';
import DeleteSelected from '../Images/deleteSelected-icon.svg';
import BeforeAfterCommentsAndTimeseriesWrapper from './BeforeAfterCommentsAndTimeseriesWrapper';

const BeforeAfterList = ({
  metricData, isConfiguratorMode, onMetricClick, onDeleteClick,
  stepName, showTimeline, timeLineData, onEditClick, onCloseMetricTimeSeriesView,
  ...props
}) => {
  const [pagnitationData, setPagnitationData] = useState({ showPagnitation: false, totalPages: 1, currentPage: 1 });

  useEffect(() => {
    const clonedPagnitationData = _.cloneDeep(pagnitationData);
    if (metricData && metricData.Metrics && metricData.Metrics.length) {
      if (metricData.Metrics.length / 5 > 1) {
        clonedPagnitationData.showPagnitation = true;
        clonedPagnitationData.totalPages = Math.ceil(metricData.Metrics.length / 5);
        if (pagnitationData.currentPage > clonedPagnitationData.totalPages) {
          clonedPagnitationData.currentPage = clonedPagnitationData.totalPages;
        }
      } else {
        clonedPagnitationData.showPagnitation = false;
        clonedPagnitationData.totalPages = 1;
        clonedPagnitationData.currentPage = 1;
      }
      if (pagnitationData.showPagnitation !== clonedPagnitationData.showPagnitation || pagnitationData.totalPages !== clonedPagnitationData.totalPages) {
        setPagnitationData(() => clonedPagnitationData);
      }
    }
  }, [pagnitationData, metricData]);

  const onDeleteMetricAction = (data) => {
    if (window.confirm(`Are you sure you want to delete ${stepName} / ${data.MetricName}?`)) {
      onDeleteClick({ MetricData: data, name: stepName });
    }
  };

  const createMetricElement = (eachMetric, index, lastNode) => {
    let ragColor = '';
    if (eachMetric.RAGStatus === 'G') {
      ragColor = 'ragstatus-green';
    } else if (eachMetric.RAGStatus === 'A') {
      ragColor = 'ragstatus-amber';
    } else if (eachMetric.RAGStatus === 'Y') {
      ragColor = 'ragstatus-grey';
    } else {
      ragColor = 'ragstatus-red';
    }
    return (
      <li>
        <div className={`mindmaplevel-dropdownvalue ${ragColor} ${eachMetric.isDeletedFromPublish && isConfiguratorMode ? 'mindmapdropdownvalue-deleted' : ''}`}>
          <span data-tip data-for={`menutooltip-${`${stepName}-${index}`}`} role="button" tabIndex="0" onKeyDown={() => onMetricClick(eachMetric, stepName)} onClick={() => onMetricClick(eachMetric, stepName)}>
            {eachMetric.MetricName && eachMetric.MetricName.length > 40 ? eachMetric.MetricName.substring(0, 38).concat('...') : eachMetric.MetricName}
          </span>
          {

eachMetric.MetricName && eachMetric.MetricName.length > 40

            && (
            <ReactTooltip place={lastNode ? 'top' : 'bottom'} type="dark" effect="solid" id={`menutooltip-${`${stepName}-${index}`}`} className="menulist-tooltips">
              {eachMetric.MetricName}
            </ReactTooltip>
            )
          }

          {isConfiguratorMode && (
          <DropdownButton
            id="edit-delete-dropdown"
            className="edit-delete-dropdown d-flex float-right"
            drop="end"
            title={(
              <span>
                <img className="default-ellipsis" alt="ellipsis" src={ellipseIcon} />
                {' '}
                <img className="ellpsis-selected" alt="ellipsis-selected" src={ellipseSelectedIcon} />
              </span>
)}
          >
            <Dropdown.Item onClick={() => onEditClick(eachMetric, stepName)}>
              <img className="edit-comment-selected" alt="edit-comment-selected" src={EditSelected} />
              <img className="edit-comment" alt="edit-comment" src={Edit} />
              Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onDeleteMetricAction(eachMetric)}>
              <img className="delete-comment-selected" alt="delete-comment-selected" src={DeleteSelected} />
              <img className="delete-comment" alt="delete-comment" src={Delete} />
              Delete
            </Dropdown.Item>
          </DropdownButton>
          )}
          {/* Onclick close button Condition Start */}
          {!showTimeline && timeLineData && (
          <div className="subtree-timelineclose">
            <span onClick={() => onCloseMetricTimeSeriesView()}><i className="fal fa-times" /></span>
          </div>
          ) }
          {/* Onclick close button Condition End */}
        </div>
      </li>
    );
  };
  const onPreviousData = () => {
    const clonedPagnitationData = _.cloneDeep(pagnitationData);
    if (clonedPagnitationData.currentPage > 1) {
      clonedPagnitationData.currentPage -= 1;
      setPagnitationData(() => clonedPagnitationData);
    }
  };
  const onNextData = () => {
    const clonedPagnitationData = _.cloneDeep(pagnitationData);
    if (clonedPagnitationData.currentPage < clonedPagnitationData.totalPages) {
      clonedPagnitationData.currentPage += 1;
      setPagnitationData(() => clonedPagnitationData);
    }
  };
  let listData = [];
  if (metricData && metricData.Metrics && metricData.Metrics.length) {
    if (metricData.Metrics.length > 5) {
      listData = _.sortBy(metricData.Metrics, ['Sequence', 'MetricName']).slice((pagnitationData.currentPage - 1) * 5, (metricData.Metrics.length + 1));
    } else {
      listData = _.sortBy(metricData.Metrics, ['Sequence', 'MetricName']);
    }
  }
  return (
    <>

      {showTimeline && metricData && metricData.Metrics && metricData.Metrics.length
        ? (
          <ul className="submenu-treelinks">
            { listData.map((eachMetric, index) => {
              if (index < 5) {
                const isLastNode = listData.length === index + 1 || index === 4;
                const currentMetricData = eachMetric;
                return createMetricElement(currentMetricData, index, isLastNode);
              }
            })}
            {pagnitationData.showPagnitation
            && (
            <div className="submenu-paginationlinks">
              <div className={`submenu-treenav ${pagnitationData.currentPage === 1 ? 'disabled' : ''}`}><i className={`far fa-chevron-left ${pagnitationData.currentPage === 1 ? 'disabled' : ''}`} onClick={onPreviousData} /></div>
              <div className="submenu-treenumber">
                <span>{pagnitationData.currentPage}</span>
                /
                <span>{pagnitationData.totalPages}</span>
              </div>
              <div className={`submenu-treenav ${pagnitationData.totalPages === pagnitationData.currentPage ? 'disabled' : ''}`}><i className={`far fa-chevron-right ${pagnitationData.totalPages === pagnitationData.currentPage ? 'disabled' : ''}`} onClick={onNextData} /></div>
            </div>
            )}
          </ul>
        ) : null }

      {/* { showTimeline && metricData && metricData.Metrics && metricData.Metrics.length && isConfiguratorMode
        ? <SortableList distance={1} items={metricData.Metrics} onMetricClick={onMetricClick} stepName={stepName} isConfiguratorMode={isConfiguratorMode} onSortEnd={onSortEnd} onEditClick={onEditClick} /> : null } */}
      {!showTimeline && timeLineData ? (
        <>
          <ul className="submenu-treelinks">
            {createMetricElement(timeLineData)}

          </ul>
          <div className="onclick-timelinedata">
            <BeforeAfterCommentsAndTimeseriesWrapper timeLineData={timeLineData} stepName={stepName} />
          </div>
        </>
      ) : null }

    </>

  );
};

export default BeforeAfterList;
