import React, { Component } from "react";
import axiosInstance from "../Shared/interceptor";
import { store } from "../reducers/configureStore";
import CategoryApprovalList from "./CategoryApprovalList";
import OneTimeSetupApprovalList from "./OneTimeSetupApprovalList";
import ClientRegionApprovalList from "./ClientRegionApprovalList";
import DeliveryRegionApprovalList from "./DeliveryRegionApprovalList";
import ClickableToolApproval from "./ClickableToolApprovalList";
import ToolApprovalList from "./ToolApprovalList";
import MetricDetail from "../Approval/MetricDetailList";
import WorkOrchestation from "./WorkOrchestationList";
import InsightsAndIntellegence from "./InsightAndIntellegenceList";
import IndustryApproval from "./IndustryApprovalList";
import OfferingApproval from "./OfferingApprovalList";
import DealApprovalList from "./DealApprovalList";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;

export default class MasterContainer extends Component {
  constructor() {
    super();
    this.state = {
      roleData: [],

      featureData: [],
    };

    this.getRoleData = this.getRoleData.bind(this);
  }

  getRoleData() {
    var data = store.getState();
    let roleData1 = data.roleReducers["roleReducers"];
    this.setState({ featureData: roleData1 });

    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 });
    });
  }

  componentWillMount() {
    this.getRoleData();
  }

  componentDidMount() {
    this.getRoleData();
  }
  componentDidUpdate(prevProps){
    const {
      filterDetails,
      detailClicked,
      iiClicked,
      woClicked,
      LocationClicked,
      ClientClicked,
      DealClicked,
      IndustryClicked,
      OfferingClicked,
      ClickableToolClicked,
      CategoryClicked,
      ClientRegionClicked,
      DeliveryRegionClicked,
      ToolClicked,
      OneTimeSetupClicked,
      } = this.props;
    if(
      filterDetails !== prevProps.filterDetails ||
      detailClicked !== prevProps.detailClicked ||
      iiClicked !== prevProps.iiClicked ||
      woClicked !== prevProps.woClicked ||
      LocationClicked !== prevProps.LocationClicked ||
      ClientClicked !== prevProps.ClientClicked ||
      DealClicked !== prevProps.DealClicked ||
      IndustryClicked !== prevProps.IndustryClicked ||
      OfferingClicked !== prevProps.OfferingClicked ||
      ClickableToolClicked !== prevProps.ClickableToolClicked ||
      CategoryClicked !== prevProps.CategoryClicked ||
      ClientRegionClicked !== prevProps.ClientRegionClicked ||
      DeliveryRegionClicked !== prevProps.DeliveryRegionClicked ||
      ToolClicked !== prevProps.ToolClicked ||
      OneTimeSetupClicked !== prevProps.OneTimeSetupClicked 
      ){
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { featureData } = this.state;
    const {
      detailClicked,
      filterDetails,
      iiClicked,
      woClicked,
      DealClicked,
      IndustryClicked,
      OfferingClicked,
      ClickableToolClicked,
      CategoryClicked,
      OneTimeSetupClicked,
      ToolClicked,
      ClientRegionClicked,
      DeliveryRegionClicked,
    } = this.props;
    return (
      <>
        {
          <>
            {detailClicked &&
            featureData.filter((data) => String(data.Submenu) === "Metric" && data.Operations.length !== 0).length >
              0 ? (
              <MetricDetail filterDetails={filterDetails} featureData={featureData} />
            ) : null}
            {iiClicked &&
            featureData.filter((data) => String(data.Submenu) === "Metric Group" && data.Operations.length !== 0)
              .length > 0 ? (
              <InsightsAndIntellegence filterDetails={filterDetails} featureData={featureData} />
            ) : null}
            {woClicked &&
            featureData.filter(
              (data) => String(data.Submenu) === "Work Orchestration Step" && data.Operations.length !== 0
            ).length > 0 ? (
              <WorkOrchestation filterDetails={filterDetails} featureData={featureData} />
            ) : null}
            {DealClicked &&
            featureData.filter((data) => String(data.Submenu) === "Deal Approval" && data.Operations.length !== 0)
              .length > 0 ? (
              <DealApprovalList filterDetails={filterDetails} featureData={featureData} />
            ) : null}
            {IndustryClicked &&
            featureData.filter((data) => String(data.Submenu) === "Industry Approval" && data.Operations.length !== 0)
              .length > 0 ? (
              <IndustryApproval filterDetails={filterDetails} featureData={featureData} />
            ) : null}
            {OfferingClicked &&
            featureData.filter((data) => String(data.Submenu) === "Offering Approval" && data.Operations.length !== 0)
              .length > 0 ? (
              <OfferingApproval filterDetails={filterDetails} featureData={featureData} />
            ) : null}
            {ClickableToolClicked &&
            featureData.filter(
              (data) => String(data.Submenu) === "ClickableTool Approval" && data.Operations.length !== 0
            ).length > 0 ? (
              <ClickableToolApproval filterDetails={filterDetails} featureData={featureData} />
            ) : null}
            {CategoryClicked &&
            featureData.filter((data) => String(data.Submenu) === "Category Approval" && data.Operations.length !== 0)
              .length > 0 ? (
              <CategoryApprovalList filterDetails={filterDetails} featureData={featureData} />
            ) : null}
            {OneTimeSetupClicked &&
            featureData.filter(
              (data) => String(data.Submenu) === "OneTimeSetup Approval" && data.Operations.length !== 0
            ).length > 0 ? (
              <OneTimeSetupApprovalList filterDetails={filterDetails} featureData={featureData} />
            ) : null}
            {ToolClicked &&
            featureData.filter((data) => String(data.Submenu) === "CustomTool Approval" && data.Operations.length !== 0)
              .length > 0 ? (
              <ToolApprovalList filterDetails={filterDetails} featureData={featureData} />
            ) : null}{" "}
            {/* new code */}
            {ClientRegionClicked &&
            featureData.filter((data) => String(data.Submenu) === "Client Region" && data.Operations.length !== 0)
              .length > 0 ? (
              <ClientRegionApprovalList filterDetails={filterDetails} featureData={featureData} />
            ) : null}
            {DeliveryRegionClicked &&
            featureData.filter((data) => String(data.Submenu) === "Delivery Region" && data.Operations.length !== 0)
              .length > 0 ? (
              <DeliveryRegionApprovalList filterDetails={filterDetails} featureData={featureData} />
            ) : null}
          </>
        }
      </>
    );
  }
}
