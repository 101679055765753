import React, { Component } from "react";
import _ from "lodash";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import BeforeAfterList from "./BeforeAfterList";
import "../CSS/mindmap.css";
import * as BeforeAfterActions from "../actions/BeforAfterMindMapActions";
import BeforeAfterListDropdown from "./BeforeAfterListDropdown";
import axiosInstance from "../Shared/interceptor";
import { LocalApiBaseUrl, trycatchAlertPopup } from "../Shared/Constant";
import BeforeAfterDetail from "./BeforeAfterDetail";
import SynopsLoader from "../Shared/SynopsLoader";
import { getInitialsFromUserEID } from "../Shared/commonFunctions";
import ReactTooltip from 'react-tooltip';
import BeforeAfterCommentsAndTimeseriesWrapper from "./BeforeAfterCommentsAndTimeseriesWrapper";
import BeforeAfterLandingPageComments from "./BeforeAfterLandingPageComments";

const MetricsNodeMapping = {
  "IO": false,
  "DSP": false,
  "WC": false,
  "NPS": false,
  "HnM": false,
  BO: true,
  Quality: true,
  Compliance: true,
  1:true, // BO
  2:false, //HnM
  3:true, // compliance
  4:true, // quality
  5:false, // nps
  6:false, // wc
  7:false, // dsp
  8:false, // IO
};
const CommentsClasses = {
  "IO": "ioscore-comments",
  "DSP": "dsp-dpo-comments",
  "WC": "working-capital-comments",
  "NPS": "nps-csat-comments",
  "HnM": "human-machine-comments",
  BO: "boi-comments",
  Quality: "quality-comments",
  Compliance: "compliance-comments",
};
const ConfigureFormClasses = {
  "IO": "ioscore-form",
  "DSP": "dsp-dpo-form",
  "WC": "working-capital-form",
  "NPS": "nps-csat-form",
  "HnM": "human-machine-form",
  BO: "boi-form",
  Quality: "quality-form",
  Compliance: "compliance-form",
};
class BeforeAfterUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionData: {},
      nodeSelected: "",
      showAddCommentsNode: "",
      hoveredSection: "",
      showSectionAddOrEditForm: "",
      editFormData: { isEditMode: false, editFormData: {} },
      isApiCallInprogress: false,
      isPostApiCallInProgress: false,
      showMetricList: { BO: true, QA: true, COMP: true },
      showTimeSericeForMetric: { stepName: "", timeSeriesData: {} },
      showSingleComment: false,
      zoomVal: 0.1,
      zoomDefaultVal: 1,
      commentsData:{}
    };
  }

  componentDidMount() {
    const { clientData, setConfigureMode } = this.props;
    setConfigureMode(false);
    if (clientData.id) {
      this.getBeforeAfterLandingPageData();
      this.getBeforeAfterUsers();
    }
  }

  componentDidUpdate(prevProps) {
    const { viewData, clientData, configrationData, isConfiguratorMode, postBeforeAfterApi, dealId, configureBeforeAfterMindClick } =
      this.props;
    if (prevProps.viewData !== viewData && !isConfiguratorMode) {
      this.setState({ sectionData: viewData, commentsData: viewData });
      // if(_.isEmpty(this.state.commentsData)){
      //   this.setState({ commentsData: viewData })
      // }
    }
    if (prevProps.configrationData !== configrationData && isConfiguratorMode) {
      this.setState({ sectionData: configrationData });
    }
    if (prevProps.isConfiguratorMode !== isConfiguratorMode ) {
      if (!isConfiguratorMode) {
        this.setState({
          sectionData: viewData,
          showSectionAddOrEditForm: "",
          showAddCommentsNode: "",
          hoveredSection: "",
          editFormData: { isEditMode: false, editFormData: {} },
          showTimeSericeForMetric: { stepName: "", timeSeriesData: {} },
          showMetricList: { BO: true, QA: true, COMP: true },
        });
      } else {
        this.setState({ sectionData: configrationData, showAddCommentsNode: "" });
      }
    }
    if (prevProps.clientData !== clientData && clientData.id) {
      this.getBeforeAfterLandingPageData();
      this.getBeforeAfterUsers();
    }
    if (postBeforeAfterApi !== prevProps.postBeforeAfterApi && postBeforeAfterApi) {
      this.postMindMapData();
    }
    if(prevProps.dealId != dealId){
      this.getBeforeAfterLandingPageData();
      configureBeforeAfterMindClick(false);
      this.setState({
        sectionData: viewData,
        showSectionAddOrEditForm: "",
        showAddCommentsNode: "",
        hoveredSection: "",
        editFormData: { isEditMode: false, editFormData: {} },
        showTimeSericeForMetric: { stepName: "", timeSeriesData: {} },
        showMetricList: { BO: true, QA: true, COMP: true },
        commentsData: viewData,
      });
    }

  }

  getBeforeAfterLandingPageData = () => {
    const { clientData, handleFormData, setBeforAfterPostApiStatus, dealId } = this.props;
    this.setState({ isApiCallInprogress: true });
    
    axiosInstance
      .get(`${LocalApiBaseUrl}BeforeAndAfterMindmap/GetClientMindmapData`, {
        params: { OrgEntityID: clientData.id, DealId: dealId},
      })
      .then((response) => {
        if (response.data) {
          let responseData = response.data;

          // ADDING UNIQUE ID TO ALL THE TIMESERIES DATA
          responseData = [...responseData].map((node) => {
            if (!MetricsNodeMapping[node.SectionId]) {
              node.TimeseriesData = [...node.TimeseriesData].map((time) => ({
                ...time,
                uniqueId: uuidv4(),
              }));
            } else {
              node.Metrics = [...node.Metrics].map((metr) => {
                metr.TimeseriesData = [...metr.TimeseriesData].map((time) => ({
                  ...time,
                  uniqueId: uuidv4(),
                }));
                return metr;
              });
            }
            return node;
          });
          this.createDataSets(responseData);
          response.data.forEach((item) => {
            const tempArray = [];
            tempArray.push(item);
            handleFormData(item.SectionName, tempArray);
          });
        }
        setBeforAfterPostApiStatus(false);
        this.setState({ isApiCallInprogress: false });
      })
      .catch(() => {
        this.createDataSets([]);
        this.setState({ isApiCallInprogress: false });
      });
  };

  createDataSets = (jsonData) => {
    const { saveViewData, saveConfigData } = this.props;
    const dataObject = {};
    jsonData.forEach((eachData) => {
      dataObject[eachData.SectionKey] = eachData;
    });
    saveViewData(dataObject);
    saveConfigData(dataObject);
  };

  handleAddForm = (name) => {
    // const { handleForm } = this.props;
    this.setState({
      showSectionAddOrEditForm: name,
      showAddCommentsNode: "",
      showMetricList: { BO: true, QA: true, COMP: true },
      editFormData: { isEditMode: false, editFormData: {} },
    });
    // handleForm(name, true);
  };

  showAddIcon = (val) => {
    const { isConfiguratorMode } = this.props;
    const { hoveredSection } = this.state;
    if (isConfiguratorMode) {
      if (hoveredSection === val) {
        this.setState({ hoveredSection: "" });
      } else {
        this.setState({ hoveredSection: val });
      }
    }
  };

  checkFormStatus(name) {
    if (this.props.isFormOpen) {
      const tempIndex = this.props.isFormOpen.findIndex((a) => a.name === name);
      if (this.props.isFormOpen[tempIndex] !== undefined) {
        return this.props.isFormOpen[tempIndex].state;
      }
    }
    //
  }

  handleNodeClick = (value) => {
    this.setState({
      nodeSelected: value,
      showSectionAddOrEditForm: "",
      showAddCommentsNode: "",
      editFormData: { isEditMode: false, editFormData: {} },
      showTimeSericeForMetric: { stepName: "", timeSeriesData: {} },
      showMetricList: { BO: true, QA: true, COMP: true },
    });
  };

  handleMainPage = () => {
    const { nodeSelected, commentsData } = this.state;
    commentsData[nodeSelected].isNewComment = false;
    this.setState({
      nodeSelected: "",
      commentsData,
    });
  };

  onMetricClick = (metricData, SectionName) => {
    const { showTimeSericeForMetric, showMetricList } = this.state;
    showTimeSericeForMetric.stepName = SectionName;
    showMetricList.BO = SectionName !== "BO";
    showMetricList.COMP = SectionName !== "COMP";
    showMetricList.QA = SectionName !== "QA";
    showTimeSericeForMetric.timeSeriesData = metricData;
    this.setState({ showTimeSericeForMetric, showMetricList });
  };

  onCloseMetricTimeSeriesView = () => {
    this.setState({
      showTimeSericeForMetric: { stepName: "", timeSeriesData: {} },
      showMetricList: { BO: true, QA: true, COMP: true },
    });
  };

  onEditClick = (data, step) => {
    this.setState({
      showSectionAddOrEditForm: step,
      editFormData: { isEditMode: true, editFormData: data },
    });
  };

  validateMindMapData = () => {
    const { configrationData } = this.props;
    const formError = { BO: 0, QA: 0, COMP: 0 };
    let formValid = true;
    if (Object.keys(configrationData).length) {
      Object.keys(configrationData).map((eachKey) => {
        if (eachKey === "BO" || eachKey === "COMP" || eachKey === "QA") {
          configrationData[eachKey].Metrics.map((eachMetric) => {
            if (eachMetric.isDeletedFromPublish) {
              formError[eachKey] += 1;
              formValid = false;
            }
          });
        }
      });
    }
    if (!formValid) {
      alert(
        `Please delete the red highlighted Metrics from following Nodes \n${
          formError.BO > 0 ? `BOI: ${formError.BO} \n` : ""
        }${formError.QA > 0 ? `Quality: ${formError.QA} \n` : ""}${
          formError.COMP > 0 ? `Compliance: ${formError.COMP} \n` : ""
        }`
      );
    }
    return formValid;
  };

  validateMindMapJourney = () => {
    let formValid = true;
    const { configrationDataMindJourney } = this.props;
    let nodeNames = [];
    configrationDataMindJourney.forEach((node) => {
      if (MetricsNodeMapping[node.SectionId]) {
        node.MetricsData.forEach((metr) => {
          metr.MetricJourney.forEach((jour) => {
            if (
              jour.Initiatives.filter((a) => a.isRemoved && a.isChecked).length > 0 ||
              jour.Metrics.filter((a) => a.isRemoved && a.isChecked).length > 0 ||
              jour.WorkOrchestration.filter((a) => a.isRemoved && a.isChecked).length > 0 ||
              jour.HumanMachine.filter((a) => a.isRemoved && a.isChecked).length > 0
            ) {
              nodeNames.push(node);
            }
          });
        });
      } else {
        node.SectionJourney.forEach((jour) => {
          if (
            jour.Initiatives.filter((a) => a.isRemoved && a.isChecked).length > 0 ||
            jour.Metrics.filter((a) => a.isRemoved && a.isChecked).length > 0 ||
            jour.WorkOrchestration.filter((a) => a.isRemoved && a.isChecked).length > 0 ||
            jour.HumanMachine.filter((a) => a.isRemoved && a.isChecked).length > 0
          ) {
            nodeNames.push(node);
          }
        });
      }
    });
    nodeNames = nodeNames.map((a) => a.SectionName);
    nodeNames = _.uniq(nodeNames);
    if (nodeNames.length > 0) {
      formValid = false;
    }
    if (!formValid) {
      const names = nodeNames.join(", \n");
      alert(
        `Please uncheck the red highlighted Initiatives/Metrics/Steps from following Nodes- ${names}`
      );
    }
    return formValid;
  };

  modifyMindmapJourneyPostData = (mindMapPostData) => {
    let mindmapJourney = mindMapPostData.MindMapJourney;
    mindmapJourney = [...mindmapJourney].map((node) => {
      if (!MetricsNodeMapping[node.SectionId]) {
        node.SectionJourney = [...node.SectionJourney].map((jour) => {
          if (
            jour.FromMilestone.includes("Timeseries") ||
            jour.ToMilestone.includes("Timeseries")
          ) {
            jour.FromMilestone = jour.FromMilestone.includes("Timeseries")
              ? "Timeseries"
              : jour.FromMilestone;
            jour.ToMilestone = jour.ToMilestone.includes("Timeseries")
              ? "Timeseries"
              : jour.ToMilestone;
          }
          return jour;
        });
      } else {
        node.MetricsData = [...node.MetricsData].map((metr) => {
          metr.MetricJourney = [...metr.MetricJourney].map((jour) => {
            if (
              jour.FromMilestone.includes("Timeseries") ||
              jour.ToMilestone.includes("Timeseries")
            ) {
              jour.FromMilestone = jour.FromMilestone.includes("Timeseries")
                ? "Timeseries"
                : jour.FromMilestone;
              jour.ToMilestone = jour.ToMilestone.includes("Timeseries")
                ? "Timeseries"
                : jour.ToMilestone;
            }            
            return jour;
          });
          return metr;
        });
      }
      return node;
    });
    const newData = { ...mindMapPostData };
    newData.MindMapJourney = [...mindmapJourney];
    return newData;
  };

  getBeforeAfterUsers = () => {
    const { clientData, saveBeforeAfterUsers } = this.props;
    axiosInstance
      .get(
        `${LocalApiBaseUrl}BeforeAndAfterMindmap/GetBeforeAfterUsers?OrgEntId=${clientData.id}`
      )
      .then((response) => {
        if (response.data) {
          const updatedUserData = response.data.map((each) => {
            const shorName = getInitialsFromUserEID(each.Name);
            each.ShortName = shorName;
            each.name = each.Name;
            each.Image = "";
            return each;
          });
          saveBeforeAfterUsers(updatedUserData);
        }
      })
      .catch((err) => {
        trycatchAlertPopup(err);
      });
  };

  postMindMapData = () => {
    const {
      clientData,
      mindMapPostData,
      setBeforAfterPostApiStatus,
      configureBeforeAfterMindClick,
      dealId,
    } = this.props;
    if (this.validateMindMapJourney()) {
      if (this.validateMindMapData()) {
        if (
          clientData.id &&
          (mindMapPostData.MindMapData.length || mindMapPostData.MindMapJourney.length)
        ) {
          this.setState({ isPostApiCallInProgress: true, nodeSelected: "" });

          const mindMapPost = this.modifyMindmapJourneyPostData(mindMapPostData);
          axiosInstance
            .post(
              `${LocalApiBaseUrl}BeforeAndAfterMindmap/PostClientMindmapData?OrgEntityID=${clientData.id}&DealId=${dealId}`,
              mindMapPost
            )
            .then((response) => {
              this.getBeforeAfterLandingPageData();
              configureBeforeAfterMindClick(false);
              this.setState({ isPostApiCallInProgress: false });
            })
            .catch(() => {
              this.setState({ isPostApiCallInProgress: false });
            });
        } else if (clientData.id) {
          configureBeforeAfterMindClick(false);
        } else {
          alert("Please Select Client to Save Data");
        }
      } else {
        this.setState({ isPostApiCallInProgress: false });
      }
    } else {
      this.setState({ isPostApiCallInProgress: false });
    }
    // configureBeforeAfterMindClick(false);
    setBeforAfterPostApiStatus(false);
  };

  handleDeleteMetric = (obj) => {
    const { onDeleteMetric, configrationData, removeDrillDownJourneyDataMetric } = this.props;
    onDeleteMetric(obj);

    const { SectionId } = configrationData[obj.name];
    const MetricID = obj.MetricData.Id;
    const { KPIMappingID } = obj.MetricData;
    const { MetricName } = obj.MetricData;
    const payload = {
      SectionId,
      MetricID,
      KPIMappingID,
      MetricName,
    };
    removeDrillDownJourneyDataMetric(payload);
  };

  onShowDoubleCommentsboxes = () => {
    this.setState({ showSingleComment: false });
  };

  onAddComments = (value) => {
    const { showAddCommentsNode, showSingleComment } = this.state;
    this.setState({
      showAddCommentsNode: showAddCommentsNode === value ? "" : value,
      showSectionAddOrEditForm: "",
      showSingleComment: showAddCommentsNode !== value,
    });
  };

  defaultModeView = () => {
    document.querySelector(".mindmap-parentsection").removeAttribute("style");
    this.setState({ zoomDefaultVal: 1 });
  };

  zoomINFunction = () => {
    const zoomElement = document.querySelector(".mindmap-parentsection");
    const { zoomDefaultVal, zoomVal } = this.state;
    if (zoomVal < 1 && zoomDefaultVal >= 0.6) {
      const selectedVal = zoomDefaultVal - zoomVal;
      this.setState({ zoomDefaultVal: selectedVal });
      zoomElement.style.transform = `scale(${selectedVal})`;
      zoomElement.style.transformOrigin = `50% ${-(selectedVal * 40)}% 0px`;
    }
  };

  defaultModeView = () => {
    if (document.querySelector(".mindmap-parentsection")) {
      document.querySelector(".mindmap-parentsection").removeAttribute("style");
      this.setState({ zoomDefaultVal: 1 });
    }
  };

  zoomOutFunction = () => {
    const zoomElement = document.querySelector(".mindmap-parentsection");
    if (zoomElement) {
      const { zoomDefaultVal, zoomVal } = this.state;
      if (zoomVal >= 0.1 && zoomDefaultVal < 1) {
        const selectedVal = zoomDefaultVal + zoomVal;
        this.setState({ zoomDefaultVal: selectedVal });
        zoomElement.style.transform = `scale(${selectedVal})`;
        zoomElement.style.transformOrigin = `50% ${-(selectedVal * 40)}% 0px`;
      }
    }
  };

  render() {
    const {
      BeforeAfterMindMap,
      clientData,
      roleData,
      isConfiguratorMode,
      onDeleteMetric,
      BeforeAfterUsers,
       viewData,
    } = this.props;
    // const isConfiguratorMode = BeforeAfterMindMap.hasConfigureAccess && BeforeAfterMindMap.showConfigureMode;
    const {
      sectionData,
      nodeSelected,
      showMetricList,
      showTimeSericeForMetric,
      hoveredSection,
      isPostApiCallInProgress,
      isApiCallInprogress,
      showSectionAddOrEditForm,
      editFormData,
      showAddCommentsNode,
      showSingleComment,
      commentsData,
    } = this.state;
    const boiClassesList = ["one-metrics", "two-metrics", "three-metrics", "four-metrics"];
    let boiClasses = "";
    if (sectionData.BO && sectionData.BO.Metrics && sectionData.BO.Metrics.length) {
      if (sectionData.BO.Metrics.length >= 5) {
        boiClasses = "five-metrics";
      } else {
        boiClasses = boiClassesList[sectionData.BO.Metrics.length - 1];
      }
    }
    if (!showMetricList.BO) {
      boiClasses = "boi-metric-time-series";
    }
    const IsMetricExist =
      (sectionData["IO"] && sectionData["IO"].BaselineYear) ||
      (sectionData["DSP"] && sectionData["DSP"].BaselineYear) ||
      (sectionData["HnM"] && sectionData["HnM"].BaselineYear) ||
      (sectionData["NPS"] && sectionData["NPS"].BaselineYear) ||
      (sectionData.BO && sectionData.BO.Metrics && sectionData.BO.Metrics.length) ||
      (sectionData.QA && sectionData.QA.Metrics && sectionData.QA.Metrics.length) ||
      (sectionData.COMP &&
        sectionData.COMP.Metrics &&
        sectionData.COMP.Metrics.length) ||
      (sectionData["WC"] && sectionData["WC"].BaselineYear)
        ? true
        : false;
    return (
      <>
        {(isApiCallInprogress || isPostApiCallInProgress) && (
          <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
        )}
        {!nodeSelected && (
          <div
            className={`mindmap-section beforafterUI ${
              showSectionAddOrEditForm === "IO" ? "IOscore-margin" : ""
            } ${
              sectionData["IO"] &&
              sectionData["IO"].BaselineYear 
                ? "IOscore-timeline-margin"
                : ""
            } `}
          >
            {!isConfiguratorMode && (
              <div className="zoom-controls">
                <span className="zoom-out" onClick={() => this.zoomOutFunction()}>
                  <i className="fas fa-plus" />
                </span>
                <span className="zoom-in" onClick={() => this.zoomINFunction()}>
                  <i className="fas fa-minus" />
                </span>
                <span className="zoom-default" onClick={() => this.defaultModeView()}>
                  <i className="fas fa-expand" />
                </span>
              </div>
            )}

            <div
              className={`mindmap-parentsection ${
                !IsMetricExist && !showSectionAddOrEditForm ? "no-timeline-series" : ""
              } ${showSectionAddOrEditForm ? ConfigureFormClasses[showSectionAddOrEditForm] : ""} ${
                showSingleComment ? "single-box" : ""
              } ${showAddCommentsNode ? CommentsClasses[showAddCommentsNode] : ""} ${boiClasses}`}
            >
              {/* dsp-dpo-comments ioscore-comments nps-csat-comments working-capital-comments human-machine-comments boi-comments quality-comments compliance-comments */}
              <div className="mindmap-btnsection">
                <button type="button" className="btn btn-gradient-common nonconfiguremode-btn">
                  {clientData.name}
                </button>
              </div>
              <ul className="mindmap-links nonconfiguremode-mindmaplinks">
                <li className="mindmap-linkname topleft-child center-top">
                  <span
                    className="mindmap-name"
                    onMouseOver={() => this.showAddIcon("IO")}
                    onClick={() => {
                      this.handleNodeClick("IO");
                    }}
                  >
                    {sectionData["IO"] && sectionData["IO"].DisplayName
                      ? sectionData["IO"].DisplayName
                      : "Intelligent Operations"}
                  </span>
                  {isConfiguratorMode && hoveredSection === "IO" ? (
                    <span className="addmindmap-links">
                      {sectionData["IO"] && sectionData["IO"].BaselineYear ? (
                        <>
                          <span>
                            <i
                              className="fal fa-pen"
                              onClick={() => this.handleAddForm("IO")}
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <span>
                            <i
                              className="fas fa-plus"
                              onClick={() => this.handleAddForm("IO")}
                            />
                          </span>
                        </>
                      )}
                    </span>
                  ) : null}
                  <div className="commenticon-block">
                    {commentsData && commentsData["IO"] && commentsData["IO"].isNewComment ? (
                      <span className="newcomment-notification" />
                    ) : (
                      ""
                    )}
                    <div
                      className="commenticon-blockcircle"
                      onClick={() => this.onAddComments("IO")}
                    >
                      <i className="fal fa-comment" />
                    </div>
                  </div>
                  {showSectionAddOrEditForm === "IO" ? (
                    <BeforeAfterListDropdown
                      name="IO"
                      closeForm={this.handleAddForm}
                      editFormData={editFormData}
                    />
                  ) : (
                    <BeforeAfterCommentsAndTimeseriesWrapper
                      handleNodeClick={this.handleNodeClick}
                      onShowDoubleCommentsboxes={this.onShowDoubleCommentsboxes}
                      userName={sessionStorage.getItem("EnterpriseID")}
                      suggestions={BeforeAfterUsers}
                      showAddComments={showAddCommentsNode === "IO"}
                      sectionData={sectionData}
                      node="IO"
                    />
                  )}
                  <div className="topleft-childlink mindmap-namelinks" />
                </li>
                <li className="mindmap-linkname topleft-child">
                  <span
                    data-tip data-for={`DSP/DPO`}
                    className="mindmap-name"
                    onMouseOver={() => this.showAddIcon("DSP")}
                    onClick={() => {
                      this.handleNodeClick("DSP");
                    }}
                  >
                    {(sectionData["DSP"] && sectionData["DSP"].DisplayName
                      ? sectionData["DSP"].DisplayName
                      : "DSP").length>20 ? (sectionData["DSP"] && sectionData["DSP"].DisplayName
                      ? sectionData["DSP"].DisplayName
                      : "DSP").slice(0,20) + "...":(sectionData["DSP"] && sectionData["DSP"].DisplayName
                      ? sectionData["DSP"].DisplayName
                      : "DSP") }
                  </span>
                  {(sectionData["DSP"] && sectionData["DSP"].DisplayName
                      ? sectionData["DSP"].DisplayName
                      : "DSP").length >20 &&
                  <ReactTooltip
                place="bottom"
                type="dark"
                effect="solid"
                id={`DSP/DPO`}
                className="kpi-name"
              >
                {sectionData["DSP"] && sectionData["DSP"].DisplayName
                      ? sectionData["DSP"].DisplayName
                      : "DSP"}
              </ReactTooltip>
            }
                  {isConfiguratorMode && hoveredSection === "DSP" && (
                    <span className="addmindmap-links">
                      {sectionData["DSP"] && sectionData["DSP"].BaselineYear ? (
                        <>
                          <span>
                            <i
                              className="fal fa-pen"
                              onClick={() => this.handleAddForm("DSP")}
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <span>
                            <i
                              className="fas fa-plus"
                              onClick={() => this.handleAddForm("DSP")}
                            />
                          </span>
                        </>
                      )}
                    </span>
                  )}
                  {showSectionAddOrEditForm === "DSP" ? (
                    <BeforeAfterListDropdown
                      name="DSP"
                      closeForm={this.handleAddForm}
                      editFormData={editFormData}
                    />
                  ) : (
                    <>
                      <BeforeAfterCommentsAndTimeseriesWrapper
                        handleNodeClick={this.handleNodeClick}
                        onShowDoubleCommentsboxes={this.onShowDoubleCommentsboxes}
                        userName={sessionStorage.getItem("EnterpriseID")}
                        suggestions={BeforeAfterUsers}
                        showAddComments={showAddCommentsNode === "DSP"}
                        sectionData={sectionData}
                        node="DSP"
                      />
                    </>
                  )}
                  <div className="commenticon-block">
                    {commentsData && commentsData["DSP"] && commentsData["DSP"].isNewComment ? (
                      <span className="newcomment-notification" />
                    ) : (
                      ""
                    )}
                    <div
                      className="commenticon-blockcircle"
                      onClick={() => this.onAddComments("DSP")}
                    >
                      <i className="fal fa-comment" />
                    </div>
                  </div>

                  <div className="topleft-childlink mindmap-namelinks" />
                </li>
                <li className="mindmap-linkname topright-child">
                  <span
                    className="mindmap-name"
                    onMouseOver={() => this.showAddIcon("BO")}
                    onClick={() => {
                      this.handleNodeClick("BO");
                    }}
                  >
                    {sectionData["BO"] && sectionData["BO"].DisplayName
                      ? sectionData["BO"].DisplayName
                      : "Business Outcomes"}
                  </span>
                  {/* Condition to add links start */}
                  {isConfiguratorMode && hoveredSection === "BO" && (
                    <span className="addmindmap-links" onClick={() => this.handleAddForm("BO")}>
                      <span>
                        <i className="fas fa-plus" />
                      </span>
                    </span>
                  )}

                  {/* Condition to add links End */}
                  {showSectionAddOrEditForm === "BO" ? (
                    <BeforeAfterListDropdown
                      name="BO"
                      closeForm={this.handleAddForm}
                      editFormData={editFormData}
                    />
                  ) : (
                    <>
                      <BeforeAfterList
                        onDeleteClick={this.handleDeleteMetric}
                        onCloseMetricTimeSeriesView={this.onCloseMetricTimeSeriesView}
                        stepName="BO"
                        showTimeline={showMetricList.BO}
                        timeLineData={showTimeSericeForMetric.timeSeriesData}
                        onMetricClick={this.onMetricClick}
                        isConfiguratorMode={isConfiguratorMode}
                        metricData={sectionData.BO}
                        onEditClick={this.onEditClick}
                      />
                      {showAddCommentsNode === "BO" ? (
                        <div
                          className="metric-scenario-commentsblock"
                          style={{
                            marginTop:
                              showTimeSericeForMetric.stepName === "BO"
                                ? 200
                                : sectionData.BO && sectionData.BO.Metrics.length > 5
                                ? 290
                                : 50 *
                                    (sectionData.BO.Metrics.length
                                      ? sectionData.BO.Metrics.length
                                      : 0) +
                                  40,
                          }}
                        >
                          <BeforeAfterLandingPageComments
                            handleNodeClick={this.handleNodeClick}
                            onShowDoubleCommentsboxes={this.onShowDoubleCommentsboxes}
                            userName={sessionStorage.getItem("EnterpriseID")}
                            currentNode="BO"
                            suggestions={BeforeAfterUsers}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  <div className="commenticon-block">
                    {commentsData && commentsData.BO && commentsData.BO.isNewComment ? (
                      <span className="newcomment-notification" />
                    ) : (
                      ""
                    )}
                    <div
                      className="commenticon-blockcircle"
                      onClick={() => this.onAddComments("BO")}
                    >
                      <i className="fal fa-comment" />
                    </div>
                  </div>
                  <div className="topright-childlink mindmap-namelinks" />
                </li>
                <li className="mindmap-linkname middleleft-child">
                  <span
                    className="mindmap-name"
                    onMouseOver={() => this.showAddIcon("WC")}
                    onClick={() => {
                      this.handleNodeClick("WC");
                    }}
                    data-tip data-for={`Working_Capital`}
                  >
                   {(sectionData["WC"] && sectionData["WC"].DisplayName
                    ? sectionData["WC"].DisplayName
                    : "WC").length>20 ? (sectionData["WC"] && sectionData["WC"].DisplayName
                    ? sectionData["WC"].DisplayName
                    : "WC").slice(0,20) +"...":(sectionData["WC"] && sectionData["WC"].DisplayName
                    ? sectionData["WC"].DisplayName
                    : "Working Capital")}
                  </span>
                  {
                    (sectionData["WC"] && sectionData["WC"].DisplayName
                    ? sectionData["WC"].DisplayName
                    : "Working Capital").length>20 &&
                  <ReactTooltip
                place="bottom"
                type="dark"
                effect="solid"
                id={`Working_Capital`}
                className="kpi-name"
              >
                {(sectionData["WC"] && sectionData["WC"].DisplayName
                      ? sectionData["WC"].DisplayName
                      : "Working Capital")}
              </ReactTooltip>
        }
                  {isConfiguratorMode && hoveredSection === "WC" ? (
                    <span className="addmindmap-links">
                      {sectionData["WC"] &&
                      sectionData["WC"].BaselineYear ? (
                        <>
                          <span>
                            <i
                              className="fal fa-pen"
                              onClick={() => this.handleAddForm("WC")}
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <span>
                            <i
                              className="fas fa-plus"
                              onClick={() => this.handleAddForm("WC")}
                            />
                          </span>
                        </>
                      )}
                    </span>
                  ) : null}
                  <div className="commenticon-block">
                    {commentsData &&
                    commentsData["WC"] &&
                    commentsData["WC"].isNewComment ? (
                      <span className="newcomment-notification" />
                    ) : (
                      ""
                    )}
                    <div
                      className="commenticon-blockcircle"
                      onClick={() => this.onAddComments("WC")}
                    >
                      <i className="fal fa-comment" />
                    </div>
                  </div>
                  {showSectionAddOrEditForm === "WC" ? (
                    <BeforeAfterListDropdown
                      name="WC"
                      closeForm={this.handleAddForm}
                      editFormData={editFormData}
                    />
                  ) : (
                    <BeforeAfterCommentsAndTimeseriesWrapper
                      handleNodeClick={this.handleNodeClick}
                      onShowDoubleCommentsboxes={this.onShowDoubleCommentsboxes}
                      userName={sessionStorage.getItem("EnterpriseID")}
                      suggestions={BeforeAfterUsers}
                      showAddComments={showAddCommentsNode === "WC"}
                      sectionData={sectionData}
                      node="WC"
                    />
                  )}
                  <div className="middleleft-childlink mindmap-namelinks" />
                </li>
                <li className="mindmap-linkname middleright-child">
                  <span
                    className="mindmap-name"
                    onMouseOver={() => this.showAddIcon("HnM")}
                    onClick={() => {
                      this.handleNodeClick("HnM");
                    }}
                  >
                    {sectionData["HnM"] &&
                    sectionData["HnM"].DisplayName
                      ? sectionData["HnM"].DisplayName
                      : "Human + Machine"}
                  </span>
                  {isConfiguratorMode && hoveredSection === "HnM" && (
                    <span className="addmindmap-links">
                      {sectionData["HnM"] &&
                      sectionData["HnM"].BaselineYear ? (
                        <>
                          <span>
                            <i
                              className="fal fa-pen"
                              onClick={() => this.handleAddForm("HnM")}
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <span>
                            <i
                              className="fas fa-plus"
                              onClick={() => this.handleAddForm("HnM")}
                            />
                          </span>
                        </>
                      )}
                    </span>
                  )}

                  {showSectionAddOrEditForm === "HnM" ? (
                    <BeforeAfterListDropdown
                      name="HnM"
                      closeForm={this.handleAddForm}
                      editFormData={editFormData}
                    />
                  ) : (
                    <>
                      <BeforeAfterCommentsAndTimeseriesWrapper
                        handleNodeClick={this.handleNodeClick}
                        onShowDoubleCommentsboxes={this.onShowDoubleCommentsboxes}
                        userName={sessionStorage.getItem("EnterpriseID")}
                        suggestions={BeforeAfterUsers}
                        sectionData={sectionData}
                        showAddComments={showAddCommentsNode === "HnM"}
                        node="HnM"
                        suggestions={BeforeAfterUsers}
                      />
                    </>
                  )}

                  <div className="commenticon-block">
                    {commentsData &&
                    commentsData["HnM"] &&
                    commentsData["HnM"].isNewComment ? (
                      <span className="newcomment-notification" />
                    ) : (
                      ""
                    )}
                    <div
                      className="commenticon-blockcircle"
                      onClick={() => this.onAddComments("HnM")}
                    >
                      <i className="fal fa-comment" />
                    </div>
                  </div>

                  <div className="middleright-childlink mindmap-namelinks" />
                </li>
                <li className="mindmap-linkname bottomleft-child">
                  <span
                    data-tip data-for={`NPS`}
                    className="mindmap-name"
                    onMouseOver={() => this.showAddIcon("NPS")}
                    onClick={() => {
                      this.handleNodeClick("NPS");
                    }}
                  >
                    {(sectionData["NPS"] && sectionData["NPS"].DisplayName
                    ? sectionData["NPS"].DisplayName
                    : "NPS").length >20 ? (sectionData["NPS"] && sectionData["NPS"].DisplayName
                    ? sectionData["NPS"].DisplayName
                    : "NPS").slice(0,20) +"...":(sectionData["NPS"] && sectionData["NPS"].DisplayName
                    ? sectionData["NPS"].DisplayName
                    : "NPS/CSAT")}
                  </span>
                  {
                    (sectionData["NPS"] && sectionData["NPS"].DisplayName
                    ? sectionData["NPS"].DisplayName
                    : "NPS/CSAT").length>20 &&
                  <ReactTooltip
                place="bottom"
                type="dark"
                effect="solid"
                id={`NPS`}
                className="kpi-name"
              >
                {sectionData["NPS"] && sectionData["NPS"].DisplayName
                      ? sectionData["NPS"].DisplayName
                      : "NPS/CSAT"}
              </ReactTooltip>
        }
                  {isConfiguratorMode && hoveredSection === "NPS" && (
                    <span className="addmindmap-links">
                      {sectionData["NPS"] && sectionData["NPS"].BaselineYear ? (
                        <>
                          <span>
                            <i
                              className="fal fa-pen"
                              onClick={() => this.handleAddForm("NPS")}
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <span>
                            <i
                              className="fas fa-plus"
                              onClick={() => this.handleAddForm("NPS")}
                            />
                          </span>
                        </>
                      )}
                    </span>
                  )}

                  {showSectionAddOrEditForm === "NPS" ? (
                    <BeforeAfterListDropdown
                      name="NPS"
                      closeForm={this.handleAddForm}
                      editFormData={editFormData}
                    />
                  ) : (
                    <>
                      <BeforeAfterCommentsAndTimeseriesWrapper
                        handleNodeClick={this.handleNodeClick}
                        onShowDoubleCommentsboxes={this.onShowDoubleCommentsboxes}
                        userName={sessionStorage.getItem("EnterpriseID")}
                        suggestions={BeforeAfterUsers}
                        showAddComments={showAddCommentsNode === "NPS"}
                        sectionData={sectionData}
                        node="NPS"
                      />
                    </>
                  )}
                  <div className="commenticon-block">
                    {commentsData && commentsData["NPS"] && commentsData["NPS"].isNewComment ? (
                      <span className="newcomment-notification" />
                    ) : (
                      ""
                    )}
                    <div
                      className="commenticon-blockcircle"
                      onClick={() => this.onAddComments("NPS")}
                    >
                      <i className="fal fa-comment" />
                    </div>
                  </div>
                  <div className="bottomleft-childlink mindmap-namelinks">
                    <div className="doublelinkelement" />
                  </div>
                </li>
                <li className="mindmap-linkname bottom-child">
                  <span
                    className="mindmap-name"
                    onMouseOver={() => this.showAddIcon("QA")}
                    onClick={() => {
                      this.handleNodeClick("QA");
                    }}
                  >
                    {sectionData["QA"] && sectionData["QA"].DisplayName
                      ? sectionData["QA"].DisplayName
                      : "Quality"}
                  </span>
                  {/* Condition to add links start */}
                  {isConfiguratorMode && hoveredSection === "QA" && (
                    <span
                      className="addmindmap-links"
                      onClick={() => this.handleAddForm("QA")}
                    >
                      <span>
                        <i className="fas fa-plus" />
                      </span>
                    </span>
                  )}

                  {/* Condition to add links End */}
                  {/* List for Sub level Mindmap links start */}
                  {/* <BeforeAfterList /> */}
                  {/* List for Sub level Mindmap links end */}
                  {showSectionAddOrEditForm === "QA" ? (
                    <BeforeAfterListDropdown
                      name="QA"
                      closeForm={this.handleAddForm}
                      editFormData={editFormData}
                    />
                  ) : (
                    <>
                      <BeforeAfterList
                        onDeleteClick={onDeleteMetric}
                        onCloseMetricTimeSeriesView={this.onCloseMetricTimeSeriesView}
                        stepName="QA"
                        showTimeline={showMetricList.QA}
                        timeLineData={showTimeSericeForMetric.timeSeriesData}
                        onMetricClick={this.onMetricClick}
                        isConfiguratorMode={isConfiguratorMode}
                        metricData={sectionData.QA}
                        onEditClick={this.onEditClick}
                      />
                      {showAddCommentsNode === "QA" ? (
                        <div
                          className="metric-scenario-commentsblock"
                          style={{
                            marginTop:
                              showTimeSericeForMetric.stepName === "QA"
                                ? 200
                                : sectionData.QA && sectionData.QA.Metrics.length > 5
                                ? 290
                                : 50 *
                                    (sectionData.QA.Metrics.length
                                      ? sectionData.QA.Metrics.length
                                      : 0) +
                                  40,
                          }}
                        >
                          <BeforeAfterLandingPageComments
                            handleNodeClick={this.handleNodeClick}
                            onShowDoubleCommentsboxes={this.onShowDoubleCommentsboxes}
                            userName={sessionStorage.getItem("EnterpriseID")}
                            currentNode="QA"
                            suggestions={BeforeAfterUsers}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  <div className="commenticon-block">
                    {commentsData && commentsData.QA && commentsData.QA.isNewComment ? (
                      <span className="newcomment-notification" />
                    ) : (
                      ""
                    )}
                    <div
                      className="commenticon-blockcircle"
                      onClick={() => this.onAddComments("QA")}
                    >
                      <i className="fal fa-comment" />
                    </div>
                  </div>

                  <div className="bottom-childlink mindmap-namelinks" />
                </li>
                <li className="mindmap-linkname bottomright-child">
                  <span
                    className="mindmap-name"
                    onMouseOver={() => this.showAddIcon("COMP")}
                    onClick={() => {
                      this.handleNodeClick("COMP");
                    }}
                  >
                    {sectionData["COMP"] && sectionData["COMP"].DisplayName
                      ? sectionData["COMP"].DisplayName
                      : "Compliance"}
                  </span>
                  {/* Condition to add links start */}
                  {isConfiguratorMode && hoveredSection === "COMP" ? (
                    <span
                      className="addmindmap-links"
                      onClick={() => this.handleAddForm("COMP")}
                    >
                      <span>
                        <i className="fas fa-plus" />
                      </span>
                    </span>
                  ) : null}
                  {/* Condition to add links End */}
                  {/* List for Sub level Mindmap links start */}
                  {showSectionAddOrEditForm === "COMP" ? (
                    <BeforeAfterListDropdown
                      name="COMP"
                      closeForm={this.handleAddForm}
                      editFormData={editFormData}
                    />
                  ) : (
                    <>
                      <BeforeAfterList
                        onDeleteClick={onDeleteMetric}
                        onCloseMetricTimeSeriesView={this.onCloseMetricTimeSeriesView}
                        showTimeline={showMetricList.COMP}
                        timeLineData={showTimeSericeForMetric.timeSeriesData}
                        stepName="COMP"
                        onMetricClick={this.onMetricClick}
                        isConfiguratorMode={isConfiguratorMode}
                        metricData={sectionData.COMP}
                        onEditClick={this.onEditClick}
                      />
                      {showAddCommentsNode === "COMP" ? (
                        <div
                          className="metric-scenario-commentsblock"
                          style={{
                            marginTop:
                              showTimeSericeForMetric.stepName === "COMP"
                                ? 200
                                : sectionData.COMP &&
                                  sectionData.COMP.Metrics.length > 5
                                ? 290
                                : 50 *
                                    (sectionData.COMP.Metrics.length
                                      ? sectionData.COMP.Metrics.length
                                      : 0) +
                                  40,
                          }}
                        >
                          <BeforeAfterLandingPageComments
                            handleNodeClick={this.handleNodeClick}
                            onShowDoubleCommentsboxes={this.onShowDoubleCommentsboxes}
                            userName={sessionStorage.getItem("EnterpriseID")}
                            currentNode="COMP"
                            suggestions={BeforeAfterUsers}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  <div className="commenticon-block">
                    {commentsData && commentsData.COMP && commentsData.COMP.isNewComment ? (
                      <span className="newcomment-notification" />
                    ) : (
                      ""
                    )}
                    <div
                      className="commenticon-blockcircle"
                      onClick={() => this.onAddComments("COMP")}
                    >
                      <i className="fal fa-comment" />
                    </div>
                  </div>

                  {/* List for Sub level Mindmap links end */}

                  <div className="bottomright-childlink mindmap-namelinks">
                    <div className="doublelinkelement" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
        {nodeSelected && (
          <BeforeAfterDetail
            clientData={clientData}
            roleData={roleData}
            node={nodeSelected}
            handleMainPage={this.handleMainPage}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  clientData: state.CurrentFilter.ClientData,
  roleData: state.roleReducers.roleReducers,
  viewData: state.BeforeAfterMindMapReducer.viewData,
  configrationData: state.BeforeAfterMindMapReducer.configrationData.MindMapData,
  configrationDataMindJourney: state.BeforeAfterMindMapReducer.configrationData.MindMapJourney,
  isConfiguratorMode: state.BeforeAfterMindMapReducer.isConfiguratorMode,
  postData: state.BeforeAfterMindMapReducer.postData,
  isFormOpen: state.BeforeAfterMindMapReducer.isFormOpen,
  mindMapPostData: state.BeforeAfterMindMapReducer.postData,
  BeforeAfterUsers: state.BeforeAfterMindMapReducer.BeforeAfterUsers,
  dealId: state.CurrentFilter.DealID,
});

const mapDispatchToProps = (dispatch) => ({
  saveViewData: (data) => dispatch(BeforeAfterActions.saveViewData(data)),
  saveConfigData: (data) => dispatch(BeforeAfterActions.saveConfigData(data)),
  handleForm: (name, data) => dispatch(BeforeAfterActions.handleForm(name, data)),
  handleFormData: (name, data) => dispatch(BeforeAfterActions.handleFormData(name, data)),
  onDeleteMetric: (data) => dispatch(BeforeAfterActions.onDeleteMetric(data)),
  removeDrillDownJourneyDataMetric: (data) =>
    dispatch(BeforeAfterActions.removeDrillDownJourneyDataMetric(data)),
  setConfigureMode: (data) => dispatch(BeforeAfterActions.setConfigureMode(data)),
  saveBeforeAfterUsers: (data) => dispatch(BeforeAfterActions.saveBeforeAfterUsers(data)),
});
BeforeAfterUI.propTypes = {
  saveViewData: PropTypes.func.isRequired,
  saveConfigData: PropTypes.func.isRequired,
  setBeforAfterPostApiStatus: PropTypes.func.isRequired,
  configureBeforeAfterMindClick: PropTypes.func.isRequired,
  viewData: PropTypes.object.isRequired,
  configrationData: PropTypes.object.isRequired,
  clientData: PropTypes.object.isRequired,
  BeforeAfterMindMap: PropTypes.object.isRequired,
  roleData: PropTypes.object.isRequired,
  isConfiguratorMode: PropTypes.bool.isRequired,
  postBeforeAfterApi: PropTypes.bool.isRequired,
  languageData: PropTypes.object.isRequired,
  mindMapPostData: PropTypes.object.isRequired,
  handleForm: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(BeforeAfterUI);
