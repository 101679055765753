
import React, { Component } from 'react';

class SynopsLoader extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
render() {
          return (
            <div className="common-synops-loader"></div>
        )
    }
}
export default SynopsLoader;