import React, { PureComponent } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import DashboardCntContainer from './DashboardCntContainer';
import { createBreadCrumbs } from '../Shared/commonFunctions';

class SuperAdmin extends PureComponent {
  render() {
    const {
      compDetails, SODetails, filterDetails, roledata, MasterOfferings,
    } = this.props;
    let subOfferings = [];
    if (MasterOfferings && MasterOfferings.length && filterDetails && filterDetails.offering) {
      MasterOfferings.forEach((each) => {
        if (parseInt(each.OfferingID, 10) === parseInt(filterDetails.offering, 10)) {
          subOfferings = each.ChildOfferings;
        }
      });
    }
    return (
      <div className="main-component mtc-component">
        <div className="container-fluid">
          {filterDetails && filterDetails.offering && filterDetails.process && MasterOfferings ? createBreadCrumbs(subOfferings, filterDetails.offering, filterDetails.process, MasterOfferings) : ''}
          <h2 className="mtclayout-title">{ `${i18n.t('Master Template Configurator')} > ${i18n.t(compDetails.title)}`}</h2>
          <DashboardCntContainer compDetails={compDetails} SODetails={SODetails} filterDetails={filterDetails} roledata={roledata} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  MasterOfferings: state.FilterReducer && state.FilterReducer.MasterOfferings ? state.FilterReducer.MasterOfferings : [],
});
export default connect(mapStateToProps)(SuperAdmin);
