import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";
import i18n from "i18next";
import $ from 'jquery'

class Navigation extends Component {
    constructor(props) {
        super(props)
        this.boMenu = document.createElement('div');
    }
    componentDidMount() {
        let boPortal = document.getElementById('boMenu');
        boPortal.appendChild(this.boMenu);
    }
    componentWillUnmount() {
        let boPortal = document.getElementById('boMenu');
        if (typeof(boPortal) != 'undefined' && boPortal != null) {
            boPortal.removeChild(this.boMenu);
        }
    }

    activeLinks = (e) => {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        let menuDetails = this.props.Nav;
        let activeElements = document.getElementsByClassName("tab-link active")
        let activeArray = [...activeElements];
        let filterImage = menuDetails.filter(menu => menu.itemName == e.currentTarget.id)[0]
            
        let deactivateClass = activeArray.length != 0 ? activeArray.map((eachActive) => {

            eachActive.className = eachActive.className.replace(/\bactive\b/g, "");
           

        }) : "";

        e.currentTarget.className = e.currentTarget.className + " active";
       
        this.props.changeContent(e)
    }
    render() {
        let GenarateNavi = this.props.Nav.map((name, index) => {
            let clstab = (index == 0 && this.props.menu == "IOJ") ? "tab-link active" : "tab-link"
            let clsTabHide = (this.props.menu == "Dashboard" && name.itemName == "IntelOps") ? "tab-link-hide" : ""
            let allClass = clstab + " " + clsTabHide
            if(window.location.pathname === "/admin/Configurator" && name.itemName === "Initiative Comparison"){
                
            }else{
                if(window.location.pathname !== "/admin/MasterTemplateConfigurator" && name.itemName === "Tools"){

                }else{
                    return <li key={name.itemName}>
                {this.props.storeData.CurrentFilter.Tab.title === name.itemName  && !this.props.storeData.CurrentFilter.Tab.ioj  ?
                 <a role="button" id={name.itemName} className="tab-link active" onClick={(e) => this.activeLinks(e)}>
              
                 <i className={name.image}></i> <span>{name.itemValue}</span> </a> :
            <a role="button" id={name.itemName} className={allClass} onClick={(e) => this.activeLinks(e)}>
             
                <i className={name.image}></i> <span>{name.itemValue}</span>
               
    </a> }
        </li>
                }
            
            }
        });    
    let GenarateNaviSub;
    if(this.props.SubNav) {
    GenarateNaviSub = this.props.SubNav.map((name, index) => {
        let clstab = (index == 0 && this.props.menu == "IOJ") ? "tab-link active" : "tab-link"
        let clsTabHide = (this.props.menu == "Dashboard" && name.itemName == "IntelOps") ? "tab-link-hide" : ""
        let allClass = clstab + " " + clsTabHide
        if((window.location.pathname === "/admin/Configurator" && name.itemName === "Initiative Comparison")|| (window.location.pathname === "/initiativedetails" && name.itemName === "Initiative Comparison")){
        }else{
        return <li key={name.itemName}>
             {this.props.storeData.CurrentFilter.Tab.title === name.itemName  && this.props.storeData.CurrentFilter.Tab.ioj  ?
                  <a role="button" id={name.itemName} className="tab-link active" onClick={(e) => this.activeLinks(e)}>
             
                  <i className={name.image}></i> <span>{name.itemValue}</span>
                  </a> :

        <a role="button" id={name.itemName} className={allClass} onClick={(e) => this.activeLinks(e)}>
           
            <i className={name.image}></i> <span>{name.itemValue}</span>
            
</a> }
    </li>
       }
    });
}
    let withoutbo = (this.props.menu == "IOJ") ? "outcome-tab without-bo" : "outcome-tab";
    if (this.props.menu == "Configurator") {
        withoutbo = "outcome-tab";
    }
    if (this.props.menu == "SuperAdmin") {
        withoutbo = "outcome-tab";
    }

    return (
        <> {ReactDOM.createPortal(
            <>
                
                    <>
                        <h4>{i18n.t("SynOps_Delivered")}</h4>
                        <div className={withoutbo}>
                            <ul>
                                {this.props.menu == "Dashboard" || this.props.menu == "Configurator" || this.props.menu == "SuperAdmin" ? <li>
                                {(!this.props.storeData.CurrentFilter.Tab.title || this.props.storeData.CurrentFilter.Tab.title === "Business Outcomes" ) && !this.props.storeData.CurrentFilter.Tab.ioj  ?
                                    <a role="button" id="Business Outcomes" className="tab-link active" onClick={(e) => this.activeLinks(e)}>
                                        <i className="icon-target"></i> <span>{i18n.t("Business_Outcomes")}</span>
                                       
                                    </a> :  <a role="button" id="Business Outcomes" className="tab-link" onClick={(e) => this.activeLinks(e)}>
                                        <i className="icon-target"></i> <span>{i18n.t("Business_Outcomes")}</span></a> }
                                </li> : null}
                                {GenarateNavi}
                            </ul>
                            { this.props.SubNav &&
                            <>
                                <h4>{i18n.t("Intelligent_Operations_Journey")}</h4>
                                <ul>
                                    {GenarateNaviSub}
                                </ul>
                                </>}
                        </div>
                    </>
                
            </>, this.boMenu)}
        </>
    )
}
}
const mapStateToProps = (state) =>{
    return ({
        storeData:{...state},
        languageData: { ...state.CurrentFilter.languageData },
        roleReducerData: state.roleReducers.roleReducers,
    })
  }
  export default connect(mapStateToProps)(Navigation)