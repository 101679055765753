import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import i18n from "i18next";

function OMIDDropdown({ filterDetails, onIojOmidSelection, header, allOmids }) {
  const [searchedOMID, setSearchedOMID] = useState("");

  const clearSearchOMID = () => {
    setSearchedOMID("");
  };

  const searchOMID = (e) => {
    setSearchedOMID(e.target.value);
  };

  const handleChecked = (e) => {
    let selectedOMIDs;

    if (e.target.checked) selectedOMIDs = [...filterDetails.selectedIojOmids, e.target.value];
    else selectedOMIDs = filterDetails.selectedIojOmids.filter((item) => item !== e.target.value);

    console.log(selectedOMIDs);

    onIojOmidSelection(selectedOMIDs);
  };

  return (
    <DropdownButton
      menuAlign="left"
      id="OmidLocationbutton"
      title={
        filterDetails.selectedIojOmids.length > 0
          ? header
          : i18n.t("Select Location")
      }
      className={
        filterDetails.selectedIojOmids.length > 0
          ? "custom-headerdropdown selected mr-3"
          : "custom-headerdropdown mr-3"
      }
      // disabled={filterDetails.selectedSubOffering === 'All'}
    >
      <div className="offering-droplistwrapper">
        <Dropdown.ItemText>
          <div className="searchFilter">
            <input
              placeholder="Search ..."
              type="search"
              value={searchedOMID}
              onChange={searchOMID}
            />
            <i className="fal fa-times clear-search" onClick={clearSearchOMID} />
          </div>
        </Dropdown.ItemText>
        <Dropdown.ItemText
          onClick={() => {
            const omids = [];
            allOmids.forEach((item) => {
              item.omids.forEach((omid) => {
                omids.push(omid);
              });
            });
            onIojOmidSelection(omids);
          }}
        >
          <div className="offering-item omid-select">All Locations</div>
        </Dropdown.ItemText>
          {allOmids.map((item) => (
            <>
            <Dropdown.ItemText
            key={item.location}
            onClick={() => {
              const omids = [];
              allOmids.filter(subitem => subitem.location === item.location).forEach((item) => {
                item.omids.forEach((omid) => {
                  omids.push(omid);
                });
              });
              onIojOmidSelection([...new Set([...filterDetails.selectedIojOmids, ...omids])]);
            }}
            >
            <div className="offering-item omid-select">{item.location}</div>
          </Dropdown.ItemText>
          
            {item.omids.filter((item) => item.includes(searchedOMID)).map((omid) => (
              <Dropdown.ItemText
              className={filterDetails.selectedIojOmids.includes(omid) ? "active" : ""}
              key={omid}
            >
              <div className={filterDetails.selectedIojOmids.includes(omid) ? "omid-selected": ""}>
                <input
                  type="checkbox"
                  id={omid}
                  name={omid}
                  value={omid}
                  checked={filterDetails.selectedIojOmids.includes(omid)}
                  onChange={handleChecked}
                />
                <label className='omid-check-title' htmlFor={omid}>{omid}</label>
              </div>
            </Dropdown.ItemText>
            ))}
          </>
          ))}
      </div>
    </DropdownButton>
  );
}

export default OMIDDropdown;
