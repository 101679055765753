import { combineReducers } from "redux";
import { SET_CLIENT_OPRIA_SISENSE_DETAILS,SET_SISENSE_SCRIPT } from "../actions/appActions";

const clientOpriaSisenseDetailsInitialState = {};

const clientOpriaSisenseDetailsReducer = (state = clientOpriaSisenseDetailsInitialState, action) => {
  switch (action.type) {
    case SET_CLIENT_OPRIA_SISENSE_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
      case SET_SISENSE_SCRIPT:
        const updatedState = {...state};
        updatedState.sisenseObj = action.payload
        return updatedState;
    default:
      return state;
  }
};



const appReducer = combineReducers({
  clientOpriaSisenseDetails: clientOpriaSisenseDetailsReducer,
});

export default appReducer;
