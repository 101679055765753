export let diverseReducers = (state = {
    diverse: []
}, action) => {
    switch (action.type) {
        case "INITIALISEDIVERSE": {
            state = { ...state }
            state["diverse"] = action.payload

            state["diverse"]= state["diverse"].map(eachDetails => {
                eachDetails.kpiData.sort((a, b) => (a.KPIMappingID > b.KPIMappingID) ? 1 : -1)
                return eachDetails;
            })
            return state
        }
        case "ADD_DIVERSE": {

            state = { ...state }
            let diverseEdit1 = state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData
            if (diverseEdit1.filter((x) => x.KPIMappingID == action.payload.KPIMappingID).length > 0) {
                let divIndex = diverseEdit1.filter((x) => x.KPIMappingID == action.payload.KPIMappingID)[0]

                var index = state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData.indexOf(divIndex)
                state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData[index] = action.payload
            }
            else {
                state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData.push(action.payload)
            }

            state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData.sort((a, b) => (a.KPIMappingID > b.KPIMappingID) ? 1 : -1)

            return state
        }

        case "ADD_DATAPROCESSED": {
            state = { ...state }
            state["diverse"].filter((x) => x.ParentSOCategoryID == '')[0].Widget = action.payload;
            return state

        }

        case "UPDATE_DATATYPEPROCESSED": {

            state = { ...state }
            state["diverse"].filter((x) => x.ParentSOCategoryID == 0)[0] = action.payload

            return state

        }


        case "REMOVE_DIVERSE": {


            state = { ...state }

            let i = state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData.filter((x) => x.KPIMappingID == action.payload.kpiID)[0]

            var index = state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData.indexOf(i)
            state["diverse"].filter((x) => x.ParentSOCategoryID == '5')[0].kpiData.splice(index, 1)

            return state

        }

        case "REMOVE_DATA": {

            state = { ...state }
            state["diverse"].filter((x) => x.ParentSOCategoryID == '')[0].Widget = action.payload;
            return { ...state }


        }
        default: return state
    }
}
