import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import React, { Component } from "react";

import Download from "../Shared/download.js";
export default class BtnCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridOptions: this.props.node.gridOptionsWrapper.gridOptions,
    };
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
    this.onBtnExport = this.onBtnExport.bind(this);
  }
  onBtnExport() {
    let params = {
      columnKeys: [
        "OperatingGroup",
        "Industry",
        "ClientName",
        "City",
        "Offering",
        "Process",
        "MaturityPhase",
        "AccentureMD",
        "Status",
      ],
      columnGroups: true,
      allColumns: false,
      skipHeader: false,
      fileName: "Deal ApprovalMail",
    };
    this.state.gridOptions.api.exportDataAsCsv(params);
  }

  downloadEmail = (dealIDParam) => {
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/DownloadEmailOnButtonClick`, {
        params: {
          dealID: dealIDParam,
        },
      })
      .then((response) => {
        if (response.data) {
          if (response.data.FileName && response.data.FileSize) {
            let hrefpart = "data:application/octet-stream;base64,";
            var link = document.createElement("a");
            link.href = hrefpart + response.data.FileContents;
            link.download = response.data.FileName;
           

            Download(link.href, response.data.FileName);
          }
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  btnClickedHandler() {
    let dealID = this.props.node.data.DealID;

    this.downloadEmail(dealID);
  }
  render() {
    const isClientProject = this.props.node.data.ClientCountry ? false : true;

    if (isClientProject) {
      return (
        <span onClick={this.btnClickedHandler}>
          <i class="fas fa-envelope-open-text" /* style={style} */></i>
        </span>
      );
    } else {
      //dont show email icon if deal created from old screen
      return <span></span>;
    }
  }
}
