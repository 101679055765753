import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import $ from 'jquery';
import _ from 'lodash';
import {
  Tabs, Tab, TabList, TabPanel,
} from 'react-web-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt, faTrashAlt, faLayerGroup, faFlag,
} from '@fortawesome/free-solid-svg-icons';
import { Overlay, Popover, Modal } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ReactTooltip from 'react-tooltip';
import LoadingOverlay from 'react-loading-overlay';
import i18n from 'i18next';
import OfferingSubOffering from './OfferingSubOffering';
import { LocalApiBaseUrl, trycatchAlertPopup, IOJData,idTokenstring } from './Constant';
import {
  HasValue, screeNamesForUserTracking, logUserTrackingData, filterSelectedSubOffering,
} from './commonFunctions';
import axiosInstance from './interceptor';
import SnapshotProfile from './snapshotProfile';

import { store } from '../reducers/configureStore';
import SynopsLoader from './SynopsLoader';

import Camerawhite from '../Images/LeftPanelIcons/camera-white.svg';
import DatabaseWhite from '../Images/LeftPanelIcons/database-white.svg';
import { setDefaultSnapshot, setMonth } from './FiltersHelper';

import { InitializeOMIDListData, selectedSubofferingName } from '../actions/JourneyActions';
import SubOfferingDropdowns from './SubOfferingDropdowns';
import { TRACKING_MODULES, PARAM_NAMES, userTracker, createParamObject } from '../Shared/Common';
import OMIDDropdown from './OMIDDropdown';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;


class Filters extends Component {
  constructor(props) {
    super(props);
    let previousMonth = new Date();
      previousMonth.setMonth(previousMonth.getMonth() - 1);
      let parsedPreviousMonth =
        previousMonth.toLocaleString("default", { month: "long" })+
        "-" +
        previousMonth.getFullYear();
    this.state = {
      updatedJson: [
        {
          AllowBATDataFetchOption: false,
          ApprovalAction: false,
          ApproverRemarks: null,
          AvailableForDealCreation: null,
          ChildOfferings: [
            {
              ProcessID: 3,
              AllowBATDataFetchOption: true,
              ApprovalAction: false,
              ApproverRemarks: null,
              AvailableForDealCreation: null,
              ChildOfferings: null,
              CreatedTS: '2018-12-21T08:46:10.37',
              CreatedUser: 'admin',
              InvisionAppLink: '',
              InvisionAppPassword: '',
              IsActive: true,
              IsArrowBlock: false,
              IsBatDealLevel: true,
              IsDetailsLink: true,
              IsIntelOpsCategory: false,
              IsNew: false,
              IsNotificationBlock: false,
              IsRelatorBlock: true,
              IsValueBlock: true,
              OfferingID: 3,
              OfferingIDs: null,
              OfferingImage: '/SOImages/Offerings/F&A.svg',
              OfferingName: null,
              OfferingName_En: null,
              OfferingTypeID: 0,
              OfferingTypeName: null,
              ParentOfferingID: 1,
              ParentOfferingName: null,
              Process: 'Order to Cash',
              Process_En: 'Order to Cash',
              RequestorRemarks: null,
              Status: null,
              UpdatedTS: '2021-08-03T06:41:59.04',
              UpdatedUser: 'anjasa.datta',
              ViewScript: null,
              ViewVideo: null,
              IsSubOffering: true,
            },
            {
              ID: 177,
              ParentID: 157,
              Level: 2,
              Name: 'Example Sublevel 1',
              Name_En: 'Example Sublevel 1',
              IsSubOffering: false,
              SubLevel: [
                {
                  ID: 178,
                  ParentID: 177,
                  Level: 3,
                  Name: 'Example Sublevel 2',
                  Name_En: 'Example Sublevel 2',
                  IsSubOffering: false,
                  SubLevel: [
                    {
                      ID: 179,
                      ParentID: 178,
                      Level: 4,
                      Name: 'Example Sublevel 3',
                      Name_En: 'Example Sublevel 3',
                      IsSubOffering: false,
                      SubLevel: [
                        {
                          ID: 180,
                          ParentID: 179,
                          Level: 5,
                          Name: 'Example Sublevel 4',
                          Name_En: 'Example Sublevel 4',
                          IsSubOffering: false,
                          SubLevel: [
                            {
                              AllowBATDataFetchOption: true,
                              IsSubOffering: true,
                              ApprovalAction: false,
                              ApproverRemarks: null,
                              AvailableForDealCreation: null,
                              ChildOfferings: null,
                              CreatedTS: '2018-12-21T08:46:27.74',
                              CreatedUser: 'admin',
                              InvisionAppLink: '',
                              InvisionAppPassword: '',
                              IsActive: true,
                              IsArrowBlock: false,
                              IsBatDealLevel: false,
                              IsDetailsLink: true,
                              IsIntelOpsCategory: false,
                              IsNew: false,
                              IsNotificationBlock: false,
                              IsRelatorBlock: false,
                              IsValueBlock: true,
                              ProcessID: 6,
                              OfferingID: 6,
                              OfferingIDs: null,
                              OfferingImage: '/SOImages/Offerings/F&A.svg',
                              OfferingName: null,
                              OfferingName_En: null,
                              OfferingTypeID: 0,
                              OfferingTypeName: null,
                              ParentOfferingID: 1,
                              ParentOfferingName: null,
                              Process: 'Record to Report',
                              Process_En: 'Record to Report',
                              RequestorRemarks: null,
                              Status: null,
                              UpdatedTS: '2021-08-03T04:03:55.807',
                              UpdatedUser: 'anjasa.datta',
                              ViewScript: null,
                              ViewVideo: null,
                            },
                            {
                              AllowBATDataFetchOption: true,
                              IsSubOffering: true,
                              ApprovalAction: false,
                              ApproverRemarks: null,
                              AvailableForDealCreation: null,
                              ChildOfferings: null,
                              CreatedTS: '2018-12-21T08:46:20.25',
                              CreatedUser: 'admin',
                              InvisionAppLink: '',
                              InvisionAppPassword: '',
                              IsActive: true,
                              IsArrowBlock: false,
                              IsBatDealLevel: false,
                              IsDetailsLink: true,
                              IsIntelOpsCategory: false,
                              IsNew: false,
                              IsNotificationBlock: false,
                              IsRelatorBlock: true,
                              IsValueBlock: true,
                              ProcessID: 5,
                              OfferingID: 5,
                              OfferingIDs: null,
                              OfferingImage: '/SOImages/Offerings/F&A.svg',
                              OfferingName: null,
                              OfferingName_En: null,
                              OfferingTypeID: 0,
                              OfferingTypeName: null,
                              ParentOfferingID: 1,
                              ParentOfferingName: null,
                              Process: 'Procure to Pay',
                              Process_En: 'Procure to Pay',
                              RequestorRemarks: null,
                              Status: null,
                              UpdatedTS: '2021-07-21T01:15:46.833',
                              UpdatedUser: 'laxmi.chandana.voona',
                              ViewScript: null,
                              ViewVideo: null,
                            },
                          ],
                        },
                      ],
                    },

                  ],
                },
              ],
            },
          ],
          CreatedTS: '0001-01-01T00:00:00',
          CreatedUser: null,
          InvisionAppLink: null,
          InvisionAppPassword: null,
          IsActive: false,
          IsArrowBlock: false,
          IsBatDealLevel: false,
          IsDetailsLink: false,
          IsIntelOpsCategory: false,
          IsNew: false,
          IsNotificationBlock: false,
          IsRelatorBlock: false,
          IsValueBlock: false,
          OfferingID: 1,
          OfferingIDs: null,
          OfferingImage: null,
          OfferingName: 'Finance & Accounting',
          OfferingName_En: 'Finance & Accounting',
          OfferingTypeID: 0,
          OfferingTypeName: null,
          ParentOfferingID: null,
          ParentOfferingName: null,
          Process: null,
          Process_En: null,
          RequestorRemarks: null,
          Status: null,
          UpdatedTS: '0001-01-01T00:00:00',
          UpdatedUser: null,
          ViewScript: null,
          ViewVideo: null,
        },
      ],
      isApiLoading: false,
      process: '',
      Client: '',
      MaturityPhase: this.props.MaturityPhase,
      selectedOffering: '',
      processEnable: '',
      locationEnable: '',
      monthEnable: '',
      filterObj: {
        offering: '', process: '', Locations: '', Month: '', LocationOMID: [], isOMIDAvailable: null,
      },
      snapshotList: [],
      clientList: [],
      ssClientID: '',
      ssOfferID: '',
      ssSubOfferID: '',
      ssSubOffer: '',
      ssLocationID: '',
      ssLandingPageID: '',
      ssIsDefault: false,
      ssName: '',
      selectedSnapshotID: 0,
      snapShotName: '',
      ssErrors: {
        Name: '', Client: '', Offering: '', SubOffering: '', Location: '',
      },
      isVisibleAddNameSSPopup: false,
      showLoaderOnPage: false,
      OfferingValues: [],
      SubOfferingValues: [],
      processOptions: [],
      LocationData: [],
      monthData: [],
      selectedOMIDs: [],
      OMIDProcess: '',
      OMIDJSON: {},
      filteredOMIDJSON: {},
      searchedOMID: '',
      locValues: [],
      locationValueNew: [],
      isDefaultSnapshotSet: false,
      locationOmidEnable: false,
      isOverlayLocationEnabled: false,
      isIOJFilterActive: false,
      filterDetails: {
        selectedOffering: '',
        selectedSubOffering: '',
        selectedLocation: '',
        selectedMonth: '',
        selectedOfferingName: '',
        selectedSubOfferingName: '',
        selectedLocationName: '',
        selectedMonthName: '',
        selecetedOMID: '',
        selectedOMIDProcess: '',
        selectedIojMonth: parsedPreviousMonth,
        selectedIojOmids: [],
      },
      subOfferingsList: [],
      offeringData: [],
      profileList: [],
      ssDelSuccMsg: i18n.t('Snapshot_Del_Success_Msg'),
      ssDefSuccMsg: i18n.t('Snapshot_Default_Success_Msg'),
      ssErrMsg: i18n.t('Snapshot_Error_Msg'),
      ssIDReqMsg: i18n.t('Snapshot_Req_Msg'),
      ssSelectClient: i18n.t('Select_Client'),
      ssSnapshotExists: i18n.t('Snapshot_Exists'),
      ssOSLRequired: i18n.t('Offering_Suboffering_Location_Required'),
      ssAlreadyDefault: i18n.t('Snapshot_Already_Default'),
      defaultSelectedProfileName: '',
      showSnapshotPopup: false,
      ssOffering: [],
      ssProcess: [],
      ssLocation: [],
    };

    this.tooltipRef = React.createRef();
    this.displayMonths = this.displayMonths.bind(this);
    this.getMonths = this.getMonths.bind(this);
    this.OfferingValues = [];
    this.subOfferingValues = [];
    this.LocationValues = [];
    this.monthValues = [];
    this.MaturityPhase = '';
    this.loadFirst = 0;
    const that = this;
    this.syncFilterCopy = {
      OfferingId: 0,
      OfferingName: '',
      ProcessId: 0,
      LocationId: 0,
      Month: null,
      LocationOMID: [],
      isOMIDAvailable: null,
    };

    this.isSnapshotDropdownPopoverClose = this.isSnapshotDropdownPopoverClose.bind(this);
    this.isSnapshotDropdownPopoverShow = this.isSnapshotDropdownPopoverShow.bind(this);
    this.SnapshotDropdownPopoverTarget = React.createRef();
  }

  AllMonths = [
    "January-2023",
    "February-2023",
    "March-2023",
    "April-2023",
    "May-2023",
    "June-2023",
    "July-2023",
    "August-2023",
    "September-2023",
    "October-2023"
  ];

  isSnapshotDropdownPopoverClose() {
    this.setState({ isSnapshotDropdownPopoverStatus: false });
  }

  isSnapshotDropdownPopoverShow() {
    this.setState({ isSnapshotDropdownPopoverStatus: true });
  }

  componentDidUpdate(prevProps, prevState) {
    ReactTooltip.rebuild();
    const {
      MaturityPhase, SelectedFilter, selectedClientId, languageData, filterClient, compDetails, location,
    } = this.props;
    const {
      filterDetails, offeringData, LocationData, subOfferingsList, snapshotClicked, OMIDJSON,
    } = this.state;

    if(prevProps.compDetails !== "IOJ Scorecard" && compDetails === "IOJ Scorecard") {
      this.getojOfferingsDetails();
    }

    if (!snapshotClicked && location.state !== undefined && (
      (
        prevProps.location.state === undefined
      )
      || (location.state.Tab !== undefined
      && location.state.ClientID !== undefined
      && prevProps.location.state.Tab !== undefined
      && prevProps.location.state.ClientID !== undefined
      && prevProps.location.state.Tab !== ''
      && location.state.Tab !== ''
      && location.state.ClientID !== prevProps.location.state.ClientID
      )
    ) // WHEN CLIENT SELECT HAPPENS AT THE HEADER
    ) {
      this.handleDefaultSnapshotScreen(this.state.offeringData);
    }

    if ((prevProps.languageData.id !== languageData.id || (String(prevProps.selectedClientId) !== '0' && selectedClientId !== 0 && String(prevProps.selectedClientId) !== String(selectedClientId))) && this.props.path !== '/ClientStory') { // IF CLIENT OR LANGUAGE ID CHANGES
      this.setState({
        offeringData: [],
        profileList: [],
      }, () => {
        this.loadProfileOnBasisOfFilter();
        this.getOfferingsDetails();
      });
    }
    if(this.props.path === '/ClientStory' && prevProps.path !== '/ClientStory'){
      this.getClientStoryOfferingsDetails();
    }
    if (
      offeringData.length && offeringData.length > 0
      && prevState.offeringData !== offeringData
      && SelectedFilter.OfferingId
      && SelectedFilter.OfferingId !== 'All' // CALLING OFFERING CHANGE INITIALLY
    ) {
      this.onOfferingChange({ target: { value: SelectedFilter.OfferingId } }, SelectedFilter.OfferingName);
    }
    if (
      SelectedFilter
      && prevProps.SelectedFilter
      && (offeringData.length > 0 // WHEN THERE IS CHANGE IN THE OFFERING ID
        && SelectedFilter.OfferingId
        && String(SelectedFilter.OfferingId) !== String(prevProps.SelectedFilter.OfferingId))
    ) {
      this.onOfferingChange({ target: { value: SelectedFilter.OfferingId } }, SelectedFilter.OfferingName);
    } else if (
      prevProps.SelectedFilter
      && SelectedFilter
      && SelectedFilter.ProcessId !== 'All'
      && filterDetails.selectedSubOffering !== 'All'
      && (subOfferingsList.length
        && SelectedFilter.ProcessId
        && SelectedFilter.ProcessId !== prevProps.SelectedFilter.ProcessId
        && SelectedFilter.ProcessId !== filterDetails.selectedSubOffering) // WHEN THERE IS CHANGE IN THE SUBOFFERING
    ) {
      this.onSubofferingChange({ target: { value: SelectedFilter.ProcessId } });
    } else if (
      prevProps.SelectedFilter
      && SelectedFilter
      && (LocationData.length
        && SelectedFilter.LocationId
        && SelectedFilter.LocationId !== prevProps.SelectedFilter.LocationId
        && SelectedFilter.LocationId !== filterDetails.selectedLocation)
        && snapshotClicked
    ) {
      if (SelectedFilter.LocationOMID.length > 0 && SelectedFilter.LocationOMID.filter((a) => a === 'All').length > 0) // WHEN SNAPSHOT IS CLICKED IN IOJ SCREEN
      {
        this.fetchOMIDRecords(selectedClientId, SelectedFilter);
      } else if (!SelectedFilter.LocationOMID.filter((a) => a === 'All').length > 0) // WHEN SNAPSHOT IS CLICK NOT IN IOJ SCREEN
      {
        this.onLocationChange({ target: { value: SelectedFilter.LocationId } });
      } else { // SELECTING OMIDS ON SNAPSHOT CHANGE
        this.onOMIDChange('All', SelectedFilter.LocationId);
      }
    }
    if (
      prevProps.SelectedFilter
      && SelectedFilter
      && SelectedFilter !== prevProps.SelectedFilter
      && (SelectedFilter.OfferingId !== prevProps.SelectedFilter.OfferingId
        || SelectedFilter.ProcessId !== prevProps.SelectedFilter.ProcessId)
        && SelectedFilter.ProcessId
      && SelectedFilter.OfferingId !== 'All'
      && SelectedFilter.ProcessId !== 'All'
      && SelectedFilter.LocationOMID.filter((a) => a === 'All').length === 0 // GETTING LOCATIONS IN NON IOJ SCREEN
      && !snapshotClicked
    ) {
      this.getLocations(this.state.Client, SelectedFilter.ProcessId);
    }
    if (
      LocationData.length
      && prevState.LocationData !== LocationData // DIFFERENT LOCATION IS SELECTED IN NON IOJ SCREEN
      && SelectedFilter.LocationId
      && !filterDetails.selectedLocation && !snapshotClicked && filterDetails.selectedSubOfferingName !== '' && filterDetails.selectedOfferingName !== ''
    ) {
      this.onLocationChange({ target: { value: SelectedFilter.LocationId } });
    }
    let previousMonth = new Date();
      previousMonth.setMonth(previousMonth.getMonth() - 1);
      let parsedPreviousMonth =
        previousMonth.toLocaleString("default", { month: "long" })+
        "-" +
        previousMonth.getFullYear();
    if (this.state.Client !== selectedClientId) { // DIFFERENT CLIENT SELECTED
      this.setState({ Client: selectedClientId });
      this.setState({
        selectedOMIDs: [],
        OMIDJSON: {},
        filteredOMIDJSON: {},
        offeringData: [],
        filterDetails: {
          selectedOffering: '',
          selectedSubOffering: '',
          selectedLocation: '',
          selectedMonth: '',
          selectedOfferingName: '',
          selectedSubOfferingName: '',
          selectedLocationName: '',
          selectedMonthName: '',
          selecetedOMID: '',
          selectedIojMonth: parsedPreviousMonth,
          selectedIojOmids: [],
        },
        LocationData: [],
        subOfferingsList: [],
        monthData: [],
        MaturityPhase: '',
        offeringData1: [],
      });
    }
    if (prevProps.compDetails !== compDetails && compDetails === 'Journey Initiatives') {
      const obj = { // WHEN NAVIGATING SCREEN FRON NON IOJ SCREEN TO IOJ SCREEN
        subOfferingID: filterDetails.selectedSubOffering,
        subOfferingName: filterDetails.selectedSubOfferingName,
      };
      store.dispatch(selectedSubofferingName(obj));
      if (offeringData.length > 0 && SelectedFilter.OfferingId !== 'All' && SelectedFilter.ProcessId !== 'All' && SelectedFilter.OfferingId !== '' && SelectedFilter.OfferingId !== '' && String(SelectedFilter.OfferingId) !== '0') { this.fetchOMIDRecords(selectedClientId, SelectedFilter); }
    }
    if (
      offeringData.length > 0
      && prevProps.compDetails !== compDetails
      && compDetails !== 'Journey Initiatives'
      && prevProps.compDetails === 'Journey Initiatives' // Going From IOJ Screen to Dashboard Screen
    ) {
      const { filterDetails } = this.state;
      if (filterDetails.selectedOffering === 'All') {
        filterDetails.selectedOffering = '';
        filterDetails.selectedOfferingName = i18n.t('Select_Offering');
        filterDetails.selectedSubOffering = '';
        filterDetails.selectedSubOfferingName = i18n.t('Select_Sub_Offerings');
        filterDetails.selectedLocation = '';
        filterDetails.selectedLocationName = i18n.t('Select_Location');
      } else if (filterDetails.selectedSubOffering === 'All') {
        filterDetails.selectedSubOffering = '';
        filterDetails.selectedSubOfferingName = i18n.t('Select_Sub_Offerings');
        filterDetails.selectedLocation = '';
        filterDetails.selectedLocationName = i18n.t('Select_Location');
      } else if (filterDetails.selectedLocation === 'All') {
        filterDetails.selectedLocation = '';
        filterDetails.selectedLocationName = i18n.t('Select_Location');
      }
      this.setState({ filterDetails });
      if (!SelectedFilter.Month && SelectedFilter.LocationId) {
        this.getMonths(this.state.Client, SelectedFilter.ProcessId, SelectedFilter.LocationId);
        if (LocationData.length === 0) {
          this.getLocations(this.state.Client, SelectedFilter.ProcessId);
        }
      }
    }

    if (prevProps.MaturityPhase !== MaturityPhase) {
      this.setState({ MaturityPhase });
    }
    if (snapshotClicked) {
      /* if (
        compDetails !== "Journey Initiatives" &&
        LocationData.length &&
        prevState.LocationData !== LocationData &&           //DIFFERENT LOCATION IS SELECTED IN NON IOJ SCREEN
        SelectedFilter.LocationId &&
        !filterDetails.selectedLocation  && filterDetails.selectedSubOfferingName !=="" && filterDetails.selectedOfferingName !==""
      ) {
        this.onLocationChange({ target: { value: SelectedFilter.LocationId } });
      } */

      if (compDetails === 'Journey Initiatives'
      && SelectedFilter.ProcessId !== '' && String(SelectedFilter.ProcessId) !== '0'
        && SelectedFilter.ProcessId === prevProps.SelectedFilter.ProcessId
        && prevProps.SelectedFilter.LocationId !== ''
        && SelectedFilter.LocationId !== ''
        && String(SelectedFilter.LocationId) !== '0'
        && SelectedFilter.LocationId !== prevProps.SelectedFilter.LocationId
        && OMIDJSON.IOJOMIDs !== undefined
        && OMIDJSON.IOJOMIDs.length > 0
      ) // SAME OFFERING AND SUBOFFERING BUT LOCATION CHANGED
      {
        this.onOMIDChange('All', SelectedFilter.LocationId);
      }
    }
  }

  async fetchOMIDRecords(Client, syncFilter) {
    const { filterDetails, snapshotClicked } = this.state;
    if (
      this.props.compDetails === 'Journey Initiatives'
      && syncFilter.ProcessId
      && syncFilter.ProcessId != '0'
      && syncFilter.ProcessId != 'All'
    ) {
      let filteredOMIDs = [];
      const selectedOMIDs = [];
      let filteredOMIDJSON = {};
      syncFilter.LocationOMID = snapshotClicked ? ['All'] : selectedOMIDs;
      const OMIDJSON = (filteredOMIDJSON = await this.getOMIDLocations(Client, syncFilter.ProcessId));
      if (syncFilter.LocationId && HasValue(syncFilter.LocationId) && syncFilter.LocationId !== 'All') {
        selectedOMIDs.push('All');
        syncFilter.LocationOMID = selectedOMIDs;
        filteredOMIDs = OMIDJSON.IOJOMIDs.filter((e) => e.locID == syncFilter.LocationId)[0];
        if (filteredOMIDs && filteredOMIDs.IOJOMIDDatas.length) {
          syncFilter.isOMIDAvailable = true;
        } else {
          syncFilter.isOMIDAvailable = false;
        }
      }
      const filterData = {
        offering: syncFilter.OfferingId,
        process: syncFilter.ProcessId,
        Locations: syncFilter.LocationId,
        Month: syncFilter.Month,
        LocationOMID: syncFilter.LocationOMID,
        iojSoreMonth: syncFilter.iojSoreMonth,
        iojOmids: syncFilter.iojOmids,
      };
      this.props.updateSyncFilter(syncFilter);
      this.props.applyFilters(filterData);
      filterDetails.selecetedOMID = 'All';
      this.setState({
        selectedOMIDs, OMIDJSON, filteredOMIDJSON, filterObj: filterData, searchedOMID: '',
      },()=>{
        if(!this.state.snapshotClicked && !syncFilter.LocationId){
          if(this.state.LocationData.length === 1){
             this.onOMIDChange('All', this.state.LocationData[0].LocationID)
          }
      }
        if(this.state.snapshotClicked)
        {
          this.onOMIDChange("All", syncFilter.LocationId)
        }
      });
    }
  }

  getOMIDText = () => {
    const allLocationOmids = [
      { location: "Global - North America", omids: ["9556","9557", "4321"] },
      { location: "Bangalore", omids: ["1234"] },
    ];
    const { filterDetails } = this.state;

    let checker = (arr, target) => target.every(v => arr.includes(v));

    const isAll = filterDetails.selectedIojOmids.length === allLocationOmids.map((a) => a.omids).flat().length;

    const isAllInALocation = allLocationOmids.some((a) => 
      checker(filterDetails.selectedIojOmids, a.omids));


    const isMultiple = filterDetails.selectedIojOmids.length > 1;

    let omidText = '';

    if(isAll) {
      omidText = "";
    } else if(isAllInALocation) {
      omidText = "All";
    }
    else if(isMultiple) {
      omidText = "Multiple";
    }
    else {
      omidText = filterDetails.selectedIojOmids[0];
    }

    return omidText;

  }

  onOMIDChange = (OMID_ID, loc_ID, Process) => {
    const selectedOMIDs = [];
    let filteredOMIDs = [];
    let OMIDProcess;
    const { filterDetails } = this.state;

    const syncFilter = { ...this.props.SelectedFilter };
    const locationChanged = syncFilter.LocationId !== loc_ID;
    syncFilter.Month = locationChanged ? '' : syncFilter.Month;
    syncFilter.LocationId = loc_ID;

    selectedOMIDs.push(OMID_ID);
    OMIDProcess = Process;

    if (loc_ID === 'All') {
      syncFilter.isOMIDAvailable = null;
      syncFilter.LocationOMID = ['All'];
      filterDetails.selecetedOMID = 'All';
      filterDetails.selectedLocationName = i18n.t('All_Locations');
      filterDetails.selectedLocation = 'All';
      if (locationChanged) {
        filterDetails.selectedMonth = '';
        filterDetails.selectedMonthName = i18n.t('Select_Month');
      }
    } else {
      syncFilter.LocationOMID = selectedOMIDs;
      filterDetails.selecetedOMID = OMID_ID;
      filterDetails.selectedOMIDProcess = Process;
      filterDetails.selectedLocation = loc_ID;
      filteredOMIDs = this.state.OMIDJSON.IOJOMIDs.filter((e) => {
        if (e.locID == loc_ID) {
          filterDetails.selectedLocationName = e.locname;
          return e;
        }
      })[0];
      if (filteredOMIDs !== undefined && filteredOMIDs.IOJOMIDDatas !== undefined && filteredOMIDs.IOJOMIDDatas.length === 0) {
        syncFilter.isOMIDAvailable = false;
      } else {
        syncFilter.isOMIDAvailable = true;
      }
      if (locationChanged) {
        filterDetails.selectedMonth = '';
        filterDetails.selectedMonthName = i18n.t('Select_Month');
      }
    }
    const filterData = {
      offering: syncFilter.OfferingId,
      process: syncFilter.ProcessId,
      Locations: syncFilter.LocationId,
      Month: syncFilter.Month,
      LocationOMID: syncFilter.LocationOMID,
      iojSoreMonth: syncFilter.iojSoreMonth,
      iojOmids: syncFilter.iojOmids,
    };
    this.setState({
      selectedOMIDs, filterObj: filterData, filterDetails, OMIDProcess,
    }, () => {
      // Set the Filter values n REDUX to sync the other pages filters
      this.props.updateSyncFilter(syncFilter);

      this.props.applyFilters(filterData);
      this.loadProfileOnBasisOfFilter();
      if (this.state.snapshotClicked) {
        this.setState({
          snapshotClicked: false,
        });
      }
    });
  };

  async getOMIDLocations(Client, offering) {
    // API CALL TO GET OMID LOCATION DATA
    this.setState({
      isApiLoading: true
    })
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetJourneyOMIDs`, {
          OrgEntityID: Client,
          OfferingID: offering,
          LanguageID: this.props.languageData.id,
          IsConfigurator: false,
        })
        .then((response) => {
          store.dispatch(InitializeOMIDListData(response.data.IOJOMIDs));
          const APIdata = response.data;
          const FilteredData = {
            IOJOMIDs: APIdata.IOJOMIDs.length > 0 ? APIdata.IOJOMIDs.filter((a) => !a.IsNew) : [],
            isAllLocOmids: response.data.isAllLocOmids,
          };
          this.setState({
            isApiLoading: false
          })
          resolve(FilteredData);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({
            isApiLoading: false
          })
        });
    });
  }

  searchOMID = (event) => {
    const filteredOMIDJSON = {};
    filteredOMIDJSON.IOJOMIDs = this.state.OMIDJSON.IOJOMIDs.filter((client) => {
      if (client.locname.toLowerCase().indexOf(event.target.value.toLowerCase().trim()) !== -1) {
        return client;
      }
      return null;
    });
    this.setState({ filteredOMIDJSON, searchedOMID: event.target.value });
  };

  clearSearchOMID = () => {
    this.setState({ searchedOMID: '', filteredOMIDJSON: this.state.OMIDJSON });
  };

  getOfferingsDetails = () => {
    const {
      CurrentFilter, SelectedFilter, compDetails, languageData, snapshotProfilesRedux,
    } = this.props;

    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        params: {
          OrgEntityID: CurrentFilter.ClientData.id, LanguageID: languageData.id, featureName: 'Dashboard', IsCalledForFlatJSONStructure: false, IsNew: false,
        },
      })
      .then((response) => {
        if (response.data) {
          if (snapshotProfilesRedux.length > 0) {
            this.handleDefaultSnapshotScreen(response.data);
          }
          const { updatedJson } = this.state;
          store.dispatch({
            type: 'SAVE_DASHBOARD_OFFERING_DETAILS',
            payload: response.data,
          });
          this.setState({ offeringData: response.data }, () => {
            // Load all Snapshot

            this.loadProfileOnBasisOfFilter();
            this.getAllProfileAgainstUser();
          });
        }
      })
      .catch((error) => {
        store.dispatch({
          type: 'SAVE_DASHBOARD_OFFERING_DETAILS',
          payload: [],
        });
        trycatchAlertPopup(error);
      });
  };

  getojOfferingsDetails = () => {
    const offData = [
      {
        "OfferingName": "Finance and Accounting",
        "ChildOfferings": [
          {
            "SubLevel": [
              {
                "SubLevel": [],
                "Level": 3,
                "ProcessID": 2,
                "IsSubOffering": true,
                "ParentID": 235,
                "Name": null,
                "OfferingID": 2,
                "OfferingTypeID": 0,
                "OfferingTypeName": null,
                "ParentOfferingID": null,
                "ParentOfferingName": "Finance and Accounting",
                "OfferingName": null,
                "Process": "Financial Analysis/Planning",
                "OfferingName_En": null,
                "Process_En": "Financial Analysis/Planning",
                "IsActive": true,
                "IsValueBlock": true,
                "IsRelatorBlock": false,
                "IsNotificationBlock": false,
                "IsArrowBlock": false,
                "IsDetailsLink": true,
                "IsIntelOpsCategory": false,
                "IsBatDealLevel": false,
                "AvailableForDealCreation": null,
                "InvisionAppLink": "",
                "ViewScript": null,
                "ViewVideo": null,
                "AllowBATDataFetchOption": true,
                "InvisionAppPassword": "",
                "OfferingImage": "/SOImages/Offerings/F&A.svg",
                "CreatedUser": "admin",
                "CreatedTS": "2018-12-21T08:46:34.013",
                "UpdatedUser": "meghana.bhagya.rao",
                "UpdatedTS": "2019-10-22T10:37:56.193",
                "Status": null,
                "RequestorRemarks": null,
                "ApproverRemarks": null,
                "ApprovalAction": false,
                "IsNew": false
              }
    
            ],
            "Level": 2,
            "ProcessID": 235,
            "IsSubOffering": false,
            "ParentID": 1,
            "Name": null,
            "OfferingID": 235,
            "OfferingTypeID": 0,
            "OfferingTypeName": null,
            "ParentOfferingID": null,
            "ParentOfferingName": "Finance and Accounting",
            "OfferingName": null,
            "Process": "Strategic Finance",
            "OfferingName_En": null,
            "Process_En": "Strategic Finance",
            "IsActive": true,
            "IsValueBlock": false,
            "IsRelatorBlock": false,
            "IsNotificationBlock": false,
            "IsArrowBlock": false,
            "IsDetailsLink": false,
            "IsIntelOpsCategory": false,
            "IsBatDealLevel": false,
            "AvailableForDealCreation": null,
            "InvisionAppLink": null,
            "ViewScript": null,
            "ViewVideo": null,
            "AllowBATDataFetchOption": false,
            "InvisionAppPassword": null,
            "OfferingImage": null,
            "CreatedUser": null,
            "CreatedTS": null,
            "UpdatedUser": null,
            "UpdatedTS": null,
            "Status": null,
            "RequestorRemarks": null,
            "ApproverRemarks": null,
            "ApprovalAction": false,
            "IsNew": false
          },
          
        ],
        "IsSubOffering": false,
        "OfferingID": 1,
        "Level": 0,
        "OfferingTypeID": 0,
        "OfferingTypeName": null,
        "ParentOfferingID": null,
        "ParentID": 193,
        "ParentOfferingName": null,
        "Process": null,
        "OfferingName_En": "Finance and Accounting",
        "Process_En": null,
        "IsActive": true,
        "IsValueBlock": null,
        "IsRelatorBlock": null,
        "IsNotificationBlock": null,
        "IsArrowBlock": null,
        "IsDetailsLink": null,
        "IsIntelOpsCategory": null,
        "IsBatDealLevel": null,
        "AvailableForDealCreation": null,
        "InvisionAppLink": null,
        "ViewScript": null,
        "ViewVideo": null,
        "AllowBATDataFetchOption": false,
        "InvisionAppPassword": null,
        "OfferingImage": null,
        "CreatedUser": null,
        "CreatedTS": null,
        "UpdatedUser": null,
        "UpdatedTS": null,
        "Status": null,
        "RequestorRemarks": null,
        "ApproverRemarks": null,
        "ApprovalAction": false,
        "IsNew": false
      },
      
    ]
    store.dispatch({
      type: 'SAVE_DASHBOARD_OFFERING_DETAILS',
      payload: offData,
    });
    this.setState({ offeringData: offData });
  }


  getClientStoryOfferingsDetails = () => {
    const {
      CurrentFilter, SelectedFilter, compDetails, languageData, snapshotProfilesRedux,
    } = this.props;

    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        params: {
          OrgEntityID: CurrentFilter.ClientData.id, LanguageID: languageData.id, featureName: 'Client Story', IsCalledForFlatJSONStructure: false, IsNew: true,
        },
      })
      .then((response) => {
        if (response.data) {
          const { updatedJson } = this.state;
          store.dispatch({
            type: 'SAVE_DASHBOARD_OFFERING_DETAILS',
            payload: response.data,
          });
          this.setState({ offeringData: response.data });
        }
      })
      .catch((error) => {
        store.dispatch({
          type: 'SAVE_DASHBOARD_OFFERING_DETAILS',
          payload: [],
        });
        trycatchAlertPopup(error);
      });
  };

  getOfferingByClient = (orgID) => {
    if (orgID != undefined && orgID > 0 && orgID != '') {
      axiosInstance
        .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
          params: {
            OrgEntityID: orgID, LanguageID: this.props.languageData.id, featureName: 'Dashboard', IsCalledForFlatJSONStructure: false, IsNew: false,
          },
        })
        .then((response) => {
          this.setState({
            ssOffering: response.data.filter((each) => each.IsNew !== true),
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  getLocationByClient_Offering = (Client, offering) => {
    if (Client && offering) {
      axiosInstance
        .get(`${LocalApiBaseUrl}SOFilter/GetLocation`, {
          params: { OrgEntityID: Client, OfferingID: offering, LanguageID: this.props.languageData.id },
        })
        .then((response) => {
          this.setState({ ssLocation: response.data.filter((each) => each.IsNew !== true)},
          ()=>{
          }
          );

        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  changeHeaderText(selectedOption) {
    this.props.changeHeaderText(selectedOption);
  }

  getLocations = (Client, offering) => {
    let LocationData = [];
    const { SelectedFilter, compDetails } = this.props;
    const { filterDetails } = this.state;
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${LocalApiBaseUrl}SOFilter/GetLocation`, {
        // params: { OrgEntityID: 11 }
          params: { OrgEntityID: Client, OfferingID: offering, LanguageID: this.props.languageData.id },
        })
        .then((response) => {
          LocationData = response.data.filter((e) => !e.IsNew);
          let locationName;
          const locationId = store.getState().CurrentFilter.SyncFilter.LocationId;

          LocationData.filter((eachValue) => {
            if (eachValue.LocationID == locationId) {
              locationName = eachValue.City_En;
            }
          });
          store.dispatch({
            type: 'LOCATION_DETAILS_CURRENTFILTER',
            payload: { LocationId: locationId, locationName },
          });

        this.setState({ LocationData },async () => {
          if(!this.state.snapshotClicked && !SelectedFilter.LocationId && compDetails !== "Journey Initiatives"){
              if(this.state.LocationData.length === 1){
                await this.onLocationChange({ target: { value: this.state.LocationData[0].LocationID } });
              }
          }
            if (
              SelectedFilter
            && SelectedFilter.LocationId
            && SelectedFilter.LocationId !== 'All'
            && String(SelectedFilter.OfferingId) === String(filterDetails.selectedOffering)
            && String(SelectedFilter.ProcessId) === String(filterDetails.selectedSubOffering)
            && compDetails !== 'Journey Initiatives'
            ) {
              await this.onLocationChange({ target: { value: SelectedFilter.LocationId } });
              resolve();
            } else {
              if (SelectedFilter && SelectedFilter.LocationId === 'All' && compDetails === 'Journey Initiatives') {
                await this.onLocationChange({ target: { value: 'All' } });
              }
              resolve();
            }
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject();
        });
    });
  };

 handleDefaultSnapshotScreen = (offeringData) => {
   const { location, onTabChange, snapshotProfilesRedux } = this.props;
   if (location.state !== undefined && location.state.Tab !== undefined && location.state.Tab !== '') {
     const profileData = this.filterProfileList(snapshotProfilesRedux, offeringData);
     if (profileData.filter((a) => a.IsDefault === true).length > 0) {
       this.setState({
         snapshotClicked: true,
       }, () => {
         if (this.props.compDetails !== location.state.Tab) { onTabChange(location.state.Tab); }
       });
     } else {
       const emptySyncFilter = {
         OfferingId: '',
         ProcessId: '',
         LocationId: '',
         Month: '',
         LocationOMID: [],
         IsDataPublishForLocation: false,
         isOMIDAvailable: false,
         OfferingName: '',
       };
       this.props.updateSyncFilter(emptySyncFilter);
       if (this.checkIfPulsePageAccessAllowed) {
         this.navigatetoPulseScreen();
       }

       this.setState({
         snapshotClicked: false,
       });
     }
   } else {
     this.setState({
       snapshotClicked: false,
     });
   }
 }

 componentDidMount() {
   const { filterClient, selectedClientId } = this.props;
   if(this.props.path === '/ClientStory'){
    this.getClientStoryOfferingsDetails();
  }else if (filterClient && selectedClientId != undefined) {
     this.getOfferingsDetails();
   }

   this.setState({
     Client: this.props.selectedClientId,
   });
 }

 async getMonths(Client, Process, Locations) {
   this.setState({ showLoaderOnPage: true });
   let monthValues = '';
   const month = [];
   const monthNames = [
     'January',
     'February',
     'March',
     'April',
     'May',
     'June',
     'July',
     'August',
     'September',
     'October',
     'November',
     'December',
   ];
   let monthData = [];

   return new Promise((resolve, reject) => {
     axiosInstance
       .get(`${LocalApiBaseUrl}SOFilter/GetMonth`, {
         params: { OrgEntityID: Client, LocationId: Locations, OfferingId: Process },
       })
       .then((response) => {
         this.setState({ showLoaderOnPage: false });
         monthData = response.data;
         monthValues = monthData.map((dropValue, index) => {
           const objDate = new Date(dropValue);
           month[index] = `${monthNames[objDate.getMonth()]}-${objDate.getFullYear()}`;
           return (
             <option
               key={month[index]}
               selected={month[index] == this.props.CurrentFilter.SyncFilter.Month}
               value={month[index]}
             >
               {month[index]}
             </option>
           );
         });

         this.setState({ monthValues, monthData: month }, () => {
           const { monthData } = this.state;
           const { SelectedFilter } = this.props;
           if (
             SelectedFilter
              && SelectedFilter.Month
              && monthData.filter((each) => each === SelectedFilter.Month).length
           ) {
             this.MonthChange({ target: { value: SelectedFilter.Month } });
           } else if (SelectedFilter.LocationId && SelectedFilter.LocationId !== 'All') {
             this.MonthChange({ target: { value: monthData[0] } });
           }
           resolve();
         });
       })
       .catch((error) => {
         trycatchAlertPopup(error);
         this.setState({ showLoaderOnPage: false });
         reject();
       });
   });
 }

 displayMonths() {
   const monthNames = [
     'January',
     'February',
     'March',
     'April',
     'May',
     'June',
     'July',
     'August',
     'September',
     'October',
     'November',
     'December',
   ];

   const today = new Date();
   let d;
   let defaultMonth;
   const month = [];

   for (let i = 3; i > 0; i -= 1) {
     d = new Date(today.getFullYear(), today.getMonth() - i);
     defaultMonth = monthNames[d.getMonth()];
     month[i] = `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
   }

   const monthValues = month.map((x) => <option key={x}>{x}</option>);
   this.setState({ monthValues, defaultMonth });
 }

  handleUserNavigation = (locName) => {
    const { languageData, CurrentFilter } = this.props;
    const { subOfferingsList, filterDetails } = this.state;
    const filtersSubofferingData = filterSelectedSubOffering([...subOfferingsList],CurrentFilter.Common.ProcessId );
    const { firstSuboffering } = filtersSubofferingData;
    const selectedSuboffering = filtersSubofferingData.subofferingData[0];

    const lang = languageData.langCode == 'eng' ? 'en' : languageData.langCode;
    const client = CurrentFilter.ClientData.name;
    const offeringId = CurrentFilter.SyncFilter.OfferingName;
    const subOfferingName = selectedSuboffering.Process_En;
    const locationName = locName;
    const data = {
      PageName: screeNamesForUserTracking.ClientSelect,
      Client: client,
      Language: lang,
      Navigation1: offeringId,
      Navigation2: subOfferingName,
      Navigation3: locationName,
    };
    logUserTrackingData(data);
  };

  getAllProfileAgainstUser = () => {
    const { SelectedFilter, CurrentFilter } = this.props;
    const client = CurrentFilter.ClientData.id;

    let loadDefaultSnapshot = false;
    if (client != undefined && client > 0 && client != '') {
      axiosInstance
        .get(`${LocalApiBaseUrl}Snapshot/GetSnapshotsByUserID`, {
          params: { OrgEntityID: client, LanguageID: this.props.languageData.id },
        })
        .then((response) => {
          store.dispatch({
            type: 'SNAPSHOT',
            payload: response.data,
          });

          this.setState({
            profileList: response.data,
            defaultSelectedProfileName: response.data.length == 0 ? '' : this.state.defaultSelectedProfileName,
          });

          let { defaultSelectedProfileName } = this.state;
          if (response.data != null && response.data.length > 0) {
            const defaultSS = response.data.filter((f) => f.IsDefault === true);
            if (defaultSS.length > 0) {
              defaultSelectedProfileName = defaultSS[0].SnapShotName;

              loadDefaultSnapshot = !!(SelectedFilter
                && (!SelectedFilter.OfferingId
                  || !SelectedFilter.ProcessId
                  || (!SelectedFilter.LocationId
                    && (SelectedFilter.OfferingName
                      === this.state.offeringData.filter((o) => o.OfferingName_En === defaultSS[0].Offering_En)[0].OfferingName_En
                      && SelectedFilter.ProcessId === defaultSS[0].OfferingID))));
              const defaultObj = {
                OfferingId: this.state.offeringData.filter((o) => o.OfferingName_En === defaultSS[0].Offering_En)[0].OfferingID,
                ProcessId: defaultSS[0].OfferingID,
                LocationId: defaultSS[0].LocationID,
                Month: '',
                LocationOMID: defaultSS[0].PageName !== 'Journey Initiatives' ? [] : ['All'],
                IsDataPublishForLocation: false,
                isOMIDAvailable: false,
                OfferingName: defaultSS[0].Offering_En,
              };
              if (loadDefaultSnapshot && (String(SelectedFilter.ProcessId) !== String(defaultObj.ProcessId)
                   || String(SelectedFilter.LocationId) !== String(defaultObj.LocationId)
                    || SelectedFilter.LocationOMID !== defaultObj.LocationOMID)
              ) {
                store.dispatch({
                  type: 'SYNC_CURRENTFILTER',
                  payload: {
                    OfferingId: defaultObj.OfferingId,
                    ProcessId: defaultObj.ProcessId,
                    LocationId: defaultObj.LocationId,
                    Month: '',
                    LocationOMID: defaultObj.LocationOMID,
                    IsDataPublishForLocation: false,
                    isOMIDAvailable: false,
                    OfferingName: defaultSS[0].Offering_En,
                  },
                });
                this.setState({ defaultSelectedProfileName: loadDefaultSnapshot ? defaultSelectedProfileName : '' }, () => {
                  if (loadDefaultSnapshot) {
                    this.handleDefaultSnapshotScreen(this.state.offeringData);
                  }

                  this.loadProfileOnBasisOfFilter();
                });
              }
            }
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    } else {
      window.alert(this.state.ssSelectClient);
    }
  };

  getProfileListAPI = (deletedID = 0) => {
    const client = this.state.Client;
    const { defaultSelectedProfileName } = this.state;
    return new Promise((resolve, reject) => {
      if (client != undefined && client > 0 && client != '') {
        axiosInstance
          .get(`${LocalApiBaseUrl}Snapshot/GetSnapshotsByUserID`, {
            params: { OrgEntityID: client, LanguageID: this.props.languageData.id },
          })
          .then((response) => {
            store.dispatch({
              type: 'SNAPSHOT',
              payload: response.data,
            });

            this.setState({
              profileList: response.data,
              defaultSelectedProfileName: response.data.length == 0 ? '' : this.state.defaultSelectedProfileName,
            }, () => {
              if (deletedID !== 0) {
                const ss = response.data.filter((f) => f.SnapShotName === defaultSelectedProfileName);
                if (ss.length === 0) {
                  this.setState({
                    defaultSelectedProfileName: '',
                  });
                }
              } else {
                this.loadProfileOnBasisOfFilter();
              }
              resolve();
            });
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            reject();
          });
      } else {
        window.alert(this.state.ssSelectClient);
        reject();
      }
    });
  }

  changeSnapshotOpenPopup = (client, showClientError) => {
    showClientError = showClientError == undefined ? false : showClientError;
    let snapShotData = [];
    const { history } = this.props;
    if (client != undefined && client > 0 && client != '') {
      this.setState({ showLoaderOnPage: true });
      axiosInstance
        .get(`${LocalApiBaseUrl}Snapshot/GetSnapshotsByUserID`, {
          params: { OrgEntityID: client, LanguageID: this.props.languageData.id },
        })
        .then((response) => {
          snapShotData = response.data;
          const that = this;
          let i = 0;
          for (i = 0; i < snapShotData.length; i++) {
            const offerData = that.OfferingValues.filter((f) => f.OfferingName == snapShotData[i].Offering);
            if (offerData.length > 0) {
              const processData = offerData[0].ChildOfferings.filter((e) => e.OfferingID == snapShotData[i].OfferingID);
              snapShotData[i].OfferingID = offerData[0].OfferingID;
              if (processData.length > 0) {
                snapShotData[i].ProcessID = processData[0].OfferingID != undefined ? processData[0].OfferingID : 0;
              }
            }
          }

          this.setState({
            snapshotList: snapShotData,
            showLoaderOnPage: false,
          });

          if (this.loadFirst == 0) {
            setDefaultSnapshot(snapShotData, history, this);
            this.loadFirst = 1;
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({ showLoaderOnPage: false });
        });
    } else if (showClientError) {
      window.alert(this.ssSelectClient);
    }
  };

  changeSnapshotSelection = (id) => {
    const profileList = [...this.state.profileList];

    if (profileList && profileList.length > 0) {
      const defaultSS = profileList.filter((f) => f.SnapshotID === id);
      if (defaultSS.length > 0) {
        const filterData = {
          OfferingId: this.state.offeringData.filter((o) => o.OfferingName_En === defaultSS[0].Offering_En)[0].OfferingID,
          ProcessId: defaultSS[0].OfferingID,
          LocationId: defaultSS[0].LocationID,
          Month: '',
          LocationOMID: [],
          IsDataPublishForLocation: false,
          isOMIDAvailable: false,
          OfferingName: defaultSS[0].Offering_En,
        };

        store.dispatch({
          type: 'SYNC_CURRENTFILTER',
          payload: filterData,
        });

        setTimeout(() => {
          this.getOfferingsDetails();
        }, 500);

        this.setState(
          {
            defaultSelectedProfileName: defaultSS[0].SnapShotName,
          },
          () => {
            this.isSnapshotDropdownPopoverClose();
          },
        );
      }
    }
  };

  enableLocationOmid = () => {
    this.setState({
      locationOmidEnable: true,
      isOverlayLocationEnabled: true,
    });
  };

  locationOverlayDisabled() {
    setTimeout(() => {
      this.setState({
        locationOmidEnable: false,
        isOverlayLocationEnabled: false,
      });
    }, 200);
  }

  onOfferingChange = (event, OfferingName = '') => {
    const { offeringData, filterDetails } = this.state;
    const { SelectedFilter, compDetails } = this.props;
    let selectedOfferingName = '';
    let subOfferingsList = [];
    let isRelatorBlock;
    let isBatDeallevel;
    let isIojBlock;
    const selectedOffering = event.target.value;
    let tempSelectedOffering = '';
    tempSelectedOffering = this.state.offeringData.filter((a) => OfferingName === a.OfferingName_En).length > 0
      ? this.state.offeringData.filter((a) => OfferingName === a.OfferingName_En)[0] : {};

    let syncFilter;
    const compareFirst = OfferingName !== '' ? OfferingName : parseInt(SelectedFilter.OfferingId, 10);
    const compareSecond = OfferingName !== '' ? tempSelectedOffering.OfferingName_En : parseInt(selectedOffering, 10);
    const currentfilter = { offeringId: OfferingName !== '' ? tempSelectedOffering.OfferingID : selectedOffering, offeringName: null };
    if (String(compareFirst) === String(compareSecond)) {
      syncFilter = SelectedFilter;
      if (OfferingName !== '') { syncFilter.OfferingId = tempSelectedOffering.OfferingID; }
    } else {
      syncFilter = {
        OfferingId: 0,
        OfferingName: '',
        ProcessId: 0,
        LocationId: 0,
        Month: null,
        LocationOMID: [],
        isOMIDAvailable: null,
      };
      SelectedFilter.OfferingId = 0;
      SelectedFilter.OfferingName = '';
      SelectedFilter.ProcessId = 0
    }
    const filterObj = {
      Locations: '0',
      Month: '0',
      LocationOMID: [],
      offering: '',
      process: '',
    };
    const comp10 = OfferingName !== '' ? parseInt(tempSelectedOffering.OfferingID, 10) : parseInt(event.target.value, 10);
    const comp = OfferingName !== '' ? tempSelectedOffering.OfferingID : event.target.value;
    const hasOfferingId = offeringData.filter(
      (eachValue) => parseInt(eachValue.OfferingID, 10) === parseInt(comp10),
    )[0];
    if (selectedOffering !== '0' && selectedOffering !== 'All' && hasOfferingId) {
      offeringData.forEach((eachValue) => {
        if (parseInt(eachValue.OfferingID, 10) === parseInt(comp10)) {
          selectedOfferingName = eachValue.OfferingName_En;
          filterObj.offering = comp;
          subOfferingsList = eachValue.ChildOfferings ? _.cloneDeep(eachValue.ChildOfferings) : [];
          syncFilter.OfferingName = selectedOfferingName;
          syncFilter.OfferingId = comp;
          currentfilter.offeringName = selectedOfferingName;
          filterDetails.selectedOfferingName = eachValue.OfferingName;
          filterDetails.selectedOffering = comp;
          filterDetails.selectedSubOfferingName = i18n.t('Select_Sub_Offerings');
          filterDetails.selectedLocationName = i18n.t('Select_Location');
          filterDetails.selecetedOMID = '';
          filterDetails.selectedMonthName = i18n.t('Select_Month');
          filterDetails.selectedLocation = '';
          filterDetails.selectedMonth = '';
          store.dispatch({
            type: 'SAVE_DASHBOARD_SUB_OFFERING_DETAILS',
            payload: _.cloneDeep(eachValue.ChildOfferings),
          });
          if (eachValue.ChildOfferings.length) {
            const filtersSubofferingData = filterSelectedSubOffering([...subOfferingsList], SelectedFilter.ProcessId);
            const { firstSuboffering } = filtersSubofferingData;
            const selectedSubofferingFromRedux = filtersSubofferingData.subofferingData[0];
            if (SelectedFilter && SelectedFilter.ProcessId && SelectedFilter.ProcessId !== 'All') {
              syncFilter.ProcessId = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.OfferingID
                : firstSuboffering[0].OfferingID;
              filterObj.process = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.OfferingID
                : firstSuboffering[0].OfferingID;
              filterDetails.selectedSubOfferingName = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.Process
                : firstSuboffering[0].Process;
              filterDetails.selectedSubOffering = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.OfferingID
                : firstSuboffering[0].OfferingID;
              isRelatorBlock = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.IsRelatorBlock
                : firstSuboffering[0].IsRelatorBlock;
              isBatDeallevel = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.IsBatDealLevel
                : subOfferingsList[0].IsBatDealLevel;
              isIojBlock = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.IsIntelOpsCategory
                : firstSuboffering[0].IsIntelOpsCategory;
            } else if (SelectedFilter && SelectedFilter.OfferingId === 'All' && compDetails === 'Journey Initiatives') {
              syncFilter.ProcessId = 'All';
              filterObj.process = 'All';
              filterDetails.selectedSubOfferingName = 'All';
              filterDetails.selectedSubOffering = 'All';
              isRelatorBlock = '';
              isBatDeallevel = '';
              isIojBlock = '';
            }
            if (
              !SelectedFilter
              || !SelectedFilter.OfferingId
              || (selectedOffering !== 'All' && SelectedFilter.ProcessId === 'All')
            ) {
              syncFilter.ProcessId = firstSuboffering[0].OfferingID;
              filterObj.process = firstSuboffering[0].OfferingID;
              filterDetails.selectedSubOfferingName = firstSuboffering[0].Process;
              filterDetails.selectedSubOffering = firstSuboffering[0].OfferingID;
              isRelatorBlock = firstSuboffering[0].IsRelatorBlock;
              isBatDeallevel = firstSuboffering[0].IsBatDealLevel;
              isIojBlock = firstSuboffering[0].IsIntelOpsCategory;
            }
            store.dispatch({
              type: 'OFFERING_ISBATDEALLEVEL',
              payload: isBatDeallevel,
            });

            this.setState({
              IsRelatorBlockForOffering: isRelatorBlock,
            });

            store.dispatch({
              type: 'COMMON_SUBOFFERING_CURRENTFILTER',
              payload: {
                ProcessId: filterDetails.selectedSubOffering,
                SubOfferingName: filterDetails.selectedSubOfferingName,
              },
            });
            store.dispatch({
              type: 'ALLOWBATDATAFETCHOPTION',
              payload: { AllowBATDataFetchOption: firstSuboffering[0].AllowBATDataFetchOption },
            });
            this.getLocations(this.state.Client, syncFilter.ProcessId);
          } else {
            filterDetails.selectedSubOffering = '';
          }
        }
      });
    } else {
      filterObj.process = '';
      filterObj.offering = '';
      syncFilter.OfferingName = '';
      syncFilter.OfferingId = this.props.compDetails === 'Journey Initiatives' && comp === 'All' ? 'All' : 0;
      syncFilter.ProcessId = this.props.compDetails === 'Journey Initiatives' && comp === 'All' ? 'All' : 0;
      syncFilter.Month = '';
      syncFilter.LocationId = this.props.compDetails === 'Journey Initiatives' && comp === 'All' ? 'All' : 0;

      syncFilter.LocationOMID = this.props.compDetails === 'Journey Initiatives' && comp === 'All' ? ['All'] : [];
      if (comp === 'All') {
        filterDetails.selectedOfferingName = i18n.t('All_offering');
        filterDetails.selectedSubOfferingName = i18n.t('All_sub_offering');
        filterDetails.selectedLocationName = i18n.t('All_Locations');
        filterDetails.selectedMonthName = i18n.t('Select_Month');
        filterDetails.selectedMonth = '';
        filterDetails.selectedOffering = 'All';
        filterDetails.selectedSubOffering = 'All';
        filterDetails.selectedLocation = 'All';
        filterDetails.selecetedOMID = 'All';
        syncFilter.OfferingId = 'All';
      } else {
        filterDetails.selectedOfferingName = i18n.t('Select_Offering');
        filterDetails.selectedSubOfferingName = i18n.t('Select_Sub_Offerings');
        filterDetails.selectedLocationName = i18n.t('Select_Location');
        filterDetails.selecetedOMID = '';
        filterDetails.selectedMonthName = i18n.t('Select_Month');
        filterDetails.selectedOffering = '';
        filterDetails.selectedSubOffering = '';
        filterDetails.selectedLocation = '';
        filterDetails.selectedMonth = '';
      }

      store.dispatch({
        type:"SAVE_DEALID",
        payload: 0,
      })
    }

    if (!comp || comp === 'All') {
      this.props.changeHeaderText('');
    } else {
      this.props.changeHeaderText(selectedOfferingName);
    }

    this.props.getSelectedRelatorBlock(isRelatorBlock);
    this.props.getSelectedIoj(isIojBlock);
    this.props.updateSyncFilter(syncFilter).then(() => {
      this.setState({
        filterDetails,
        subOfferingsList: subOfferingsList || [],
        LocationOptions: [],
        LocationData: [],
        monthData: [],
        locationEnable: '',
        monthEnable: '',
        monthValues: [],
        OMIDJSON: {},
        filteredOMIDJSON: {},
        selectedOMIDs: [],
        offering: '',
        process: '',
        processEnable: this.props.compDetails === 'Journey Initiatives' && comp == 'All' ? 'All' : '',
        processOptions: [],
        selectedOffering: this.props.compDetails === 'Journey Initiatives' && comp == 'All' ? 'All' : '',
      });
    });
    if (this.props.compDetails === 'Journey Initiatives') {
      this.fetchOMIDRecords(this.props.filterClient, syncFilter);
    }

    this.loadProfileOnBasisOfFilter();
  };

  onLocationChange =async (event) => {
    const { LocationData, filterDetails } = this.state;
    const { SelectedFilter, compDetails } = this.props;
    const LocationDetails = { LocationId: '', locationName: null };
    const hasLocationId = LocationData.filter(
      (eachValue) => parseInt(eachValue.LocationID) === parseInt(event.target.value),
    )[0];
    return new Promise(async (resolve, reject) => {
      if (event.target.value && event.target.value !== 'All' && hasLocationId) {
        this.setState({
          monthData: [],
        });
        
        await Promise.all(LocationData.map(async (each) => {
          if (parseInt(each.LocationID) === parseInt(event.target.value)) {
            if (parseInt(SelectedFilter.LocationId) !== parseInt(event.target.value)) {
              SelectedFilter.Month = '';
            }
            if(parseInt(filterDetails.selectedLocation) !== parseInt(event.target.value)){
              this.handleUserNavigation(each.City_En);
            }
            LocationDetails.LocationId = each.LocationID;
            LocationDetails.locationName = each.City;
            filterDetails.selectedLocationName = each.City;
            filterDetails.selecetedOMID = '';
            filterDetails.selectedMonthName = i18n.t('Select_Month');
            filterDetails.selectedLocation = each.LocationID;
            filterDetails.selectedMonth = '';
            SelectedFilter.LocationId = each.LocationID;
            SelectedFilter.LocationOMID = [];
            SelectedFilter.isOMIDAvailable = null;
            if (!this.state.snapshotClicked) {
              this.props.updateSyncFilter(SelectedFilter);
            }
            if (compDetails !== 'Journey Initiatives') {
              await this.getMonths(this.state.Client, filterDetails.selectedSubOffering, event.target.value);
            }
            
          }
        }));
      } else if (LocationData.length) {
        LocationDetails.LocationId = '';
        LocationDetails.locationName = '';
        filterDetails.selectedLocationName = i18n.t('Select_Location');
        filterDetails.selecetedOMID = '';
        filterDetails.selectedMonthName = i18n.t('Select_Month');
        filterDetails.selectedLocation = '';
        filterDetails.selectedMonth = '';
        SelectedFilter.LocationId = '';
        SelectedFilter.Month = '';
        SelectedFilter.LocationOMID = [];
        SelectedFilter.isOMIDAvailable = null;
        if (!this.state.snapshotClicked) {
          this.props.updateSyncFilter(SelectedFilter);
        }
      } else if (compDetails !== 'Journey Initiatives') {
        this.getLocations(this.state.Client, SelectedFilter.ProcessId);
      }
      this.setState({ filterDetails });
      store.dispatch({
        type: 'LOCATION_DETAILS_CURRENTFILTER',
        payload: LocationDetails,
      });

      this.loadProfileOnBasisOfFilter();
      resolve();
    });
  };

  onSubofferingChange = async (event) => {
    const { SelectedFilter, compDetails } = this.props;
    const { subOfferingsList, filterDetails } = this.state;
    const filtersSubofferingData = filterSelectedSubOffering([...subOfferingsList], event.target.value);
    const { firstSuboffering } = filtersSubofferingData;
    const selectedSuboffering = filtersSubofferingData.subofferingData[0];
    if (event.target.value && event.target.value !== 'All' && selectedSuboffering) {
      filterDetails.selectedSubOfferingName = selectedSuboffering.Process;
      filterDetails.selectedSubOffering = selectedSuboffering.OfferingID;
      filterDetails.selectedLocationName = i18n.t('Select_Location');
      filterDetails.selecetedOMID = '';
      filterDetails.selectedMonthName = i18n.t('Select_Month');
      filterDetails.selectedLocation = '';
      filterDetails.selectedMonth = '';
      SelectedFilter.ProcessId = selectedSuboffering.OfferingID;
      if (!this.state.snapshotClicked) {
        SelectedFilter.LocationId = '';
      }

      SelectedFilter.Month = '';
      SelectedFilter.LocationOMID = [];
      SelectedFilter.isOMIDAvailable = null;

      store.dispatch({
        type: 'OFFERING_ISBATDEALLEVEL',
        payload: selectedSuboffering.IsBatDealLevel,
      });

      this.setState({
        IsRelatorBlockForOffering: selectedSuboffering.IsRelatorBlock,
        LocationData: [],
        monthData: [],
      });
      store.dispatch({
        type: 'COMMON_SUBOFFERING_CURRENTFILTER',
        payload: {
          ProcessId: filterDetails.selectedSubOffering,
          SubOfferingName: filterDetails.selectedSubOfferingName,
        },
      });
      /*      if(this.state.snapshotClicked)
      {
        this.props.updateSyncFilter(SelectedFilter);
      } */
      this.props.updateSyncFilter(SelectedFilter);
      await this.getLocations(this.state.Client, selectedSuboffering.OfferingID);
      if (compDetails === 'Journey Initiatives') {
        await this.fetchOMIDRecords(this.props.filterClient, SelectedFilter);
      }

      this.props.getSelectedRelatorBlock(selectedSuboffering.IsRelatorBlock);
      this.props.getSelectedIoj(selectedSuboffering.IsIntelOpsCategory);
    } else {
      if (event.target.value === 'All') {
        filterDetails.selectedSubOfferingName = i18n.t('All_sub_offering');
        filterDetails.selectedSubOffering = 'All';
        filterDetails.selectedLocationName = i18n.t('All_Locations');
        filterDetails.selecetedOMID = '';
        filterDetails.selectedMonthName = i18n.t('Select_Month');
        filterDetails.selectedLocation = 'All';
        filterDetails.selectedMonth = '';
        SelectedFilter.ProcessId = 'All';
        SelectedFilter.LocationId = 'All';
        SelectedFilter.Month = '';
        SelectedFilter.LocationOMID = ['All'];
        SelectedFilter.isOMIDAvailable = null;
      } else {
        filterDetails.selectedSubOfferingName = i18n.t('Select_Sub_Offerings');
        filterDetails.selectedSubOffering = '';
        filterDetails.selectedLocationName = i18n.t('Select_Location');
        filterDetails.selecetedOMID = '';
        filterDetails.selectedMonthName = i18n.t('Select_Month');
        filterDetails.selectedLocation = '';
        filterDetails.selectedMonth = '';
        SelectedFilter.ProcessId = '';
        SelectedFilter.LocationId = '';
        SelectedFilter.Month = '';
        SelectedFilter.LocationOMID = [];
        SelectedFilter.isOMIDAvailable = null;
      }
      this.props.updateSyncFilter(SelectedFilter);
    }

    /* if(!this.state.snapshotClicked)
      {
        this.props.updateSyncFilter(SelectedFilter);
      } */
    const obj = {
      subOfferingID: filterDetails.selectedSubOffering,
      subOfferingName: filterDetails.selectedSubOfferingName,
    };
    store.dispatch(selectedSubofferingName(obj));
    this.loadProfileOnBasisOfFilter();
    this.setState({ filterDetails });

    // setUserFeatures(SelectedFilter.ProcessId);
  };

  onIOJSocreMonthChange = (event) => {
    const { SelectedFilter, applyFilters } = this.props;
    
    const { filterDetails } = this.state;
    this.AllMonths.forEach((each) => {
      if (each === event.target.value) {
        filterDetails.selecetedOMID = '';
        // filterDetails.selectedMonthName = event.target.value;
        // filterDetails.selectedMonth = event.target.value;
        filterDetails.selectedIojMonth = event.target.value;
        SelectedFilter.iojSoreMonth = event.target.value;
        applyFilters({
          Locations: SelectedFilter.LocationId,
          Month: SelectedFilter.Month,
          LocationOMID: [],
          offering: SelectedFilter.OfferingId,
          process: SelectedFilter.ProcessId,
          iojSoreMonth: event.target.value,
          iojOmids: SelectedFilter.iojOmids,
        });
      }
    });

    this.props.updateSyncFilter(SelectedFilter);
 
  }

  onIojOmidSelection = (omids) => {
    const { SelectedFilter, applyFilters } = this.props;
    
    const { monthData, filterDetails } = this.state;
  
    filterDetails.selectedIojOmids = [...omids];
    SelectedFilter.iojOmids = [...omids];
    applyFilters({
      Locations: SelectedFilter.LocationId,
      Month: SelectedFilter.Month,
      LocationOMID: SelectedFilter.LocationOMID,
      offering: SelectedFilter.OfferingId,
      process: SelectedFilter.ProcessId,
      iojSoreMonth: SelectedFilter.iojSoreMonth,
      iojOmids: [...omids],
    });
    
    this.props.updateSyncFilter(SelectedFilter);
  }

  MonthChange = (event) => {
    const { monthData, filterDetails } = this.state;
    const { SelectedFilter, applyFilters } = this.props;
    if (event.target.value && event.target.value !== 'All') {
      monthData.forEach((each) => {
        if (each === event.target.value) {
          filterDetails.selecetedOMID = '';
          filterDetails.selectedMonthName = event.target.value;
          filterDetails.selectedMonth = event.target.value;
          SelectedFilter.Month = event.target.value;
          applyFilters({
            Locations: SelectedFilter.LocationId,
            Month: event.target.value,
            LocationOMID: [],
            offering: SelectedFilter.OfferingId,
            process: SelectedFilter.ProcessId,
            iojSoreMonth: SelectedFilter.iojSoreMonth,
            iojOmids: SelectedFilter.iojOmids,
          });
        }
      });
    } else {
      filterDetails.selecetedOMID = '';
      filterDetails.selectedMonthName = i18n.t('Select_Month');
      filterDetails.selectedMonth = '';
      SelectedFilter.Month = '';
    }
    this.props.updateSyncFilter(SelectedFilter);
    if (this.state.snapshotClicked) {
      this.setState({
        snapshotClicked: false,
      });
    }
    this.loadProfileOnBasisOfFilter();
    this.setState({ filterDetails });
    // BAT-DiveseData  API CALL TO UPDATE THE BAT STATUS FOR DD LEVER TO UPDATE THE REDUX //
    const Client = this.props.selectedClientId;
    if(Client && SelectedFilter.ProcessId && SelectedFilter.LocationId && event.target.value) {
    // axiosInstance
    //   .get(`${LocalApiBaseUrl}BAT/GetDiverseDataFetchSatus`, {
    //     params: {
    //       OrgEntityID: Client,
    //       OfferingID: SelectedFilter.ProcessId,
    //       LocationID: SelectedFilter.LocationId,
    //       MonthDT: event.target.value,
    //       LanguageID: store.getState().CurrentFilter.languageData.id,
    //     },
    //   })
    //   .then((response) => {
    //     const { FetchFromBAT } = response.data;
    //     const { ShowBannerMessage } = response.data;
    //     this.setState({ showLoaderOnPage: false, FetchFromBAT, ShowBannerMessage });
    //     if (response) {
    //       store.dispatch({
    //         type: 'FETCHFROMBAT',
    //         payload: this.state.FetchFromBAT,
    //       });
    //       store.dispatch({
    //         type: 'SHOWBANNERMESSAGE',
    //         payload: this.state.ShowBannerMessage,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     trycatchAlertPopup(error);
    //     this.setState({ showLoaderOnPage: false });
    //   });
    }
  else
    {
      this.setState({ showLoaderOnPage: false });
    } 

    let pageName = '';
    switch (this.props.compDetails) {
      case "Business Outcomes":
        pageName = TRACKING_MODULES.APPLIED_NOW_BUSINESS_OUTCOMES;
        break;
      case "Human + Machine Talent":
        pageName = TRACKING_MODULES.APPLIED_NOW_HUMAN_MACHINE_TALENT;
        break;
      case "Work Orchestration":
        pageName = TRACKING_MODULES.APPLIED_NOW_WORK_ORCHESTRATION;
        break;
      case "Insights & Intelligence":
        pageName = TRACKING_MODULES.APPLIED_NOW_INSIGHT_INTELLIGENCE;
        break;
      case "Diverse Data":
        pageName = TRACKING_MODULES.APPLIED_NOW_DIVERSE_DATA;
        break;
        case "IOJ Scorecard":
        pageName = TRACKING_MODULES.APPLIED_NOW_IOJ_SCORECARD;
        break;
      case "Journey Initiatives":
        pageName = TRACKING_MODULES.APPLIED_NOW_JOURNEY_INITIATIVES;
        break;
      case "Value Realization":
        pageName = TRACKING_MODULES.APPLIED_NOW_VALUE_REALIZATION;
        break;
      case "Maturity Plan":
        pageName = TRACKING_MODULES.APPLIED_NOW_MATURITY_PLAN;
        break;
    }

    userTracker(
      pageName,
      false,
      null,
      null,
      createParamObject(
        PARAM_NAMES.OFFERING,
        this.state.filterDetails.selectedOfferingName
      ),
      createParamObject(
        PARAM_NAMES.SUB_OFFERING,
        this.state.filterDetails.selectedSubOfferingName
      ),
      createParamObject(
        PARAM_NAMES.LOCATION,
        this.state.filterDetails.selectedLocationName
      ),
      createParamObject(
        PARAM_NAMES.MONTH,
        event.target.value
      ),
    );
  };

  filterProfileList = (TprofileList, offeringData) => {
    if (offeringData.length > 0) {
      const tempProfileList = TprofileList.filter((a) => offeringData.some((b) => {
        const OfferingName = b.OfferingName_En === a.Offering_En;
        const subofferings = filterSelectedSubOffering(_.cloneDeep(b.ChildOfferings), a.OfferingID);
        const subofferingData = subofferings.subofferingData.length > 0;
        if (OfferingName && subofferingData) return true;
        return false;
      }));
      return tempProfileList;
    }
    return TprofileList;
  };

  loadProfileOnBasisOfFilter = () => {
    const { SelectedFilter, CurrentFilter, snapshotProfilesRedux } = this.props;
    let profileData = snapshotProfilesRedux || CurrentFilter.snapshotProfile;
    if (profileData && profileData.length > 0) {
      profileData = this.filterProfileList(profileData, this.state.offeringData);
      const dataSS = profileData.filter((f) => String(f.OfferingID) === String(SelectedFilter.ProcessId) && String(f.LocationID) === String(SelectedFilter.LocationId), // && currentScreen === f.PageName
      );
      let ssName = '';
      if (dataSS !== null && dataSS.length > 0) {
        ssName = dataSS[0].SnapShotName;
        this.setState({ defaultSelectedProfileName: ssName });
      } else {
        this.setState({ defaultSelectedProfileName: '' });
      }
    }
  };

  subHeaderOpen = (event) => {
    this.props.subHeaderOpenCallback(true);
    event.preventDefault();
  };

  subHeaderClose = (event) => {
    this.props.subHeaderCloseCallback(false);
    event.preventDefault();
  };

  setDefaultSelectedProfileName = (data, snapshotClick, filterdata, screen = '') => {
    const { SelectedFilter } = this.props;

    this.setState({
      defaultSelectedProfileName: data,
    }, () => {
      if (snapshotClick === true) {
        this.setState({ snapshotClicked: true }, () => {
          if (screen !== 'Pulse' && !(filterdata.OfferingName === SelectedFilter.OfferingName
            && String(filterdata.ProcessId) === String(SelectedFilter.ProcessId)
            && String(filterdata.LocationId) === String(SelectedFilter.LocationId) // No CHANGE ON TAB CHANGE
          )) {
            store.dispatch({
              type: 'SYNC_CURRENTFILTER',
              payload: filterdata,
            });
          }
          if (screen === 'Pulse') {
            if (this.checkIfPulsePageAccessAllowed()) {
              store.dispatch({
                type: 'SYNC_CURRENTFILTER',
                payload: filterdata,
              });
              this.navigatetoPulseScreen();
            }
          } else {
            this.props.onTabChange(screen);
          }
          this.loadProfileOnBasisOfFilter();
        });
      }
    });
  }

  checkIfPulsePageAccessAllowed = () => {
    const { roleData } = this.props;

    const foundPermissionObj = _.find(roleData, { FeatureName: 'PulsePage' });

    let hasAccessPermission = false;
    if (!_.isEmpty(foundPermissionObj)) {
      const allowedOperations = foundPermissionObj.Operations;

      // ACCESS WOULD ONLY BE ALLOWED IF THE USER HAS EITHER "VIEW" OR "EDIT" PERMISSION
      if (allowedOperations.indexOf('View') || allowedOperations.indexOf('Edit')) {
        hasAccessPermission = true;
      }
    }

    return hasAccessPermission;
  };

  navigatetoPulseScreen = () => {
    const { history } = this.props;
    history.push({
      pathname: '/landing',
      state: { pulseMenu: true },
    });
  }

  render() {
    console.log('SelectedFilter', this.props.SelectedFilter);
    console.log("filterDetails", this.state.filterDetails);
    const { snapshotList } = this.state;
    const { clientList } = this.state;

    const a = this.props.selectedClientId;
    const renderSelectedLocation = () => {
      if (this.state.selectedOMIDs.toString()) {
        const filteredLoc = this.state.OMIDJSON.IOJOMIDs.filter(
          (e) => e.locID == this.props.CurrentFilter.SyncFilter.LocationId,
        )[0];

        if (filteredLoc != undefined) {
          return filteredLoc.locname;
        }
        return 'Select Location'; // this.state.selectedOMIDs.toString();
      }
      if (this.props.CurrentFilter.SyncFilter === undefined) return 'Select Location';
      if (this.props.CurrentFilter.SyncFilter.LocationId === 'All') {
        return 'All locations';
      } return 'Select Location';
    };
    const {
      subOfferingsList, offeringData, filterDetails, LocationData, monthData, MaturityPhase,
    } = this.state;
    const { compDetails } = this.props;
    const offeringClass = classNames({
      'custom-headerdropdown mr-3': true,
    });
    const subOfferingClass = classNames({
      'custom-headerdropdown mr-3': true,
    });
    return (
      <div>
        {this.state.isApiLoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />}
        <div
          id="subnav-header-id"
          className="subnav-header fd-config-wrapper"
          onMouseEnter={this.subHeaderOpen}
          onMouseLeave={this.subHeaderClose}
        >
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="d-flex align-items-center">
                  <div className="offeringfilter-dropdowns d-flex align-items-center">
                    <span className="mr-4">{i18n.t('Filter By')}</span>
                    <OfferingSubOffering
                      path = {this.props.path}
                      selectedOffering={this.state.filterDetails.selectedOfferingName}
                      offerings={this.state.offeringData}
                      offeringsData={this.state.offeringData}
                      selectedSubOffering={this.state.filterDetails.selectedSubOfferingName}
                      selectedSubOfferingId={this.state.filterDetails.selectedSubOffering}
                      subOfferings={this.state.subOfferingsList}
                      onOfferingChange={this.onOfferingChange}
                      onSubOfferingChange={this.onSubofferingChange}
                      subHeaderOpen={this.subHeaderOpen}
                      subHeaderClose={this.subHeaderClose}
                      compDetails={compDetails}
                    />

                    {compDetails !== 'Journey Initiatives' && compDetails !== 'IOJ Scorecard' && (
                      <DropdownButton
                        menuAlign="left"
                        id="Locationbutton"
                        title={
                          filterDetails.selectedLocationName
                            ? filterDetails.selectedLocationName
                            : i18n.t('Select_Location')
                        }
                        className={
                          filterDetails.selectedLocationName !== ''
                          && filterDetails.selectedLocationName !== i18n.t('Select_Location')
                            ? 'custom-headerdropdown selected mr-3'
                            : 'custom-headerdropdown mr-3'
                        }
                        disabled={!LocationData.length}
                      >
                        <div className="offering-droplistwrapper">
                          {LocationData.map((each) => (
                            <Dropdown.Item
                              href="javascript:void(0);"
                              className={
                              parseInt(each.LocationID) === parseInt(filterDetails.selectedLocation)
                                ? 'active'
                                : ''
                            }
                              onClick={() => (parseInt(each.LocationID) === parseInt(filterDetails.selectedLocation) ? '' : this.onLocationChange({ target: { value: each.LocationID } }))}
                              onKeyDown={() => (parseInt(each.LocationID) === parseInt(filterDetails.selectedLocation) ? '' : this.onLocationChange({ target: { value: each.LocationID } }))}
                            >

                              <span
                                className="form-check-label"
                                role="button"
                                tabIndex={0}

                              >
                                {each.City}
                              </span>
                            </Dropdown.Item>
                          ))}
                        </div>
                      </DropdownButton>
                    )}
                    {compDetails === 'Journey Initiatives' && (
                      <DropdownButton
                        menuAlign="left"
                        id="OmidLocationbutton"
                        title={
                          filterDetails.selectedLocationName
                            ? filterDetails.selectedLocationName
                            : i18n.t('Select_Location')
                        }
                        className={
                          filterDetails.selectedLocationName !== ''
                          && filterDetails.selectedLocationName !== i18n.t('Select_Location')
                            ? 'custom-headerdropdown selected mr-3'
                            : 'custom-headerdropdown mr-3'
                        }
                        disabled={filterDetails.selectedSubOffering === 'All'}
                      >
                        <div className="offering-droplistwrapper">
                          <Dropdown.ItemText>
                            <div className="searchFilter">
                              <input
                                placeholder="Search ..."
                                type="search"
                                value={this.state.searchedOMID}
                                onChange={this.searchOMID}
                              />
                              <i className="fal fa-times clear-search" onClick={this.clearSearchOMID} />
                            </div>
                          </Dropdown.ItemText>

                          <Dropdown.Item
                            href="javascript:void(0);"
                            className={filterDetails.selectedLocation === 'All' ? 'active' : ''}
                            onClick={() => (filterDetails.selectedLocation === 'All' ? '' : this.onOMIDChange('All Locations', 'All'))}
                            onKeyDown={() => (filterDetails.selectedLocation === 'All' ? '' : this.onOMIDChange('All Locations', 'All'))}
                          >

                            <span
                              className="form-check-label"
                              role="button"
                              tabIndex={0}

                            >
                              {i18n.t('All_Locations')}
                            </span>
                          </Dropdown.Item>
                          {
                          // this.state.filteredOMIDJSON !== {} &&
                           this.state.filteredOMIDJSON.IOJOMIDs
                            && this.state.filteredOMIDJSON.IOJOMIDs.map((eachLocation) => (
                              <>
                                <Dropdown.ItemText className="omid-loc-label">
                                  {eachLocation.locname}
                                </Dropdown.ItemText>

                                <Dropdown.Item
                                  href="javascript:void(0);"
                                  className={parseInt(eachLocation.locID) === parseInt(filterDetails.selectedLocation)
                                          && filterDetails.selecetedOMID === 'All' ? 'active' : ''}
                                  onClick={() => parseInt(eachLocation.locID) === parseInt(filterDetails.selectedLocation)
                                    && filterDetails.selecetedOMID === 'All' ? '' : this.onOMIDChange('All', eachLocation.locID)}
                                  onKeyDown={() => parseInt(eachLocation.locID) === parseInt(filterDetails.selectedLocation)
                                    && filterDetails.selecetedOMID === 'All' ? '' : this.onOMIDChange('All', eachLocation.locID)}
                                >

                                  <span
                                    className="form-check-label"
                                    role="button"
                                    tabIndex={0}

                                  >
                                    {i18n.t('All')}
                                  </span>
                                </Dropdown.Item>
                                {eachLocation.IOJOMIDDatas.map((eachOMID, index) => (
                                  <Dropdown.Item
                                    href="javascript:void(0);"
                                    className={parseInt(eachLocation.locID)
                                      === parseInt(filterDetails.selectedLocation)
                                      && parseInt(filterDetails.selecetedOMID) === parseInt(eachOMID.OMID) ? 'active' : ''}
                                    onClick={() => parseInt(eachLocation.locID)
                                      === parseInt(filterDetails.selectedLocation)
                                      && parseInt(filterDetails.selecetedOMID) === parseInt(eachOMID.OMID) ? '' : this.onOMIDChange(eachOMID.OMID, eachLocation.locID, eachOMID.Process)}
                                    onKeyDown={() => parseInt(eachLocation.locID)
                                      === parseInt(filterDetails.selectedLocation)
                                      && parseInt(filterDetails.selecetedOMID) === parseInt(eachOMID.OMID) ? '' : this.onOMIDChange(eachOMID.OMID, eachLocation.locID, eachOMID.Process)}
                                  >

                                    <span
                                      data-tip
                                      data-for={`omid-tooltip-${eachOMID.OMID}`}
                                      className="form-check-label"
                                      role="button"
                                      tabIndex={0}
                                    >
                                      {eachOMID.OMID}
                                    </span>
                                    {eachOMID.OMLite && <span className="omid-flagtext">{i18n.t('Lite')}</span>}
                                    <ReactTooltip
                                      place="bottom"
                                      type="dark"
                                      effect="solid"
                                      id={`omid-tooltip-${eachOMID.OMID}`}
                                    >
                                      <span>{eachOMID.Process}</span>
                                    </ReactTooltip>
                                  </Dropdown.Item>
                                ))}
                              </>
                            ))}
                        </div>
                      </DropdownButton>
                    )}

                    {compDetails === 'IOJ Scorecard' && (
                      <OMIDDropdown filterDetails={filterDetails} onIojOmidSelection={this.onIojOmidSelection}/>
                    )}
                    {compDetails !== 'Journey Initiatives' && compDetails !== "IOJ Scorecard" && compDetails !== 'Value Realization' && this.props.path !== '/ClientStory'  && (
                      <DropdownButton
                        menuAlign="left"
                        id="Monthbutton"
                        title={filterDetails.selectedMonth ? filterDetails.selectedMonth : i18n.t('Select_Month')}
                        className={
                          filterDetails.selectedMonth !== '' && filterDetails.selectedMonth !== i18n.t('Select_Month')
                            ? 'custom-headerdropdown selected mr-3'
                            : 'custom-headerdropdown mr-3'
                        }
                        disabled={!monthData.length}
                      >
                        <div className="offering-droplistwrapper">
                          {monthData.map((each) => (
                            <Dropdown.Item
                              href="javascript:void(0);"
                              className={each === filterDetails.selectedMonth ? 'active' : ''}
                              onClick={() => (each === filterDetails.selectedMonth  ? '' : this.MonthChange({ target: { value: each } }))}
                              onKeyDown={() => (each === filterDetails.selectedMonth  ? '' : this.MonthChange({ target: { value: each } }))}
                            >

                              <span
                                className="form-check-label"
                                role="button"
                                tabIndex={0}
                              >
                                {each}
                              </span>
                            </Dropdown.Item>
                          ))}
                        </div>
                      </DropdownButton>
                    )}
                  </div>

                  <div className="filterdata-options ml-auto d-flex align-items-center">
                    {
                        compDetails !== 'Journey Initiatives' && compDetails !== 'IOJ Scorecard' && this.state.Client && this.props.path !== '/ClientStory'

                        && (
                        <SnapshotProfile
                          compDetails={compDetails}
                          filterDetails={filterDetails}
                          MaturityPhase={MaturityPhase}
                          OperatingGropus={this.props.OperatingGropus}
                          languageData={this.props.languageData}
                          profileListParent={this.state.profileList}
                          offeringData={this.state.offeringData}
                          defaultSelectedProfileName={this.state.defaultSelectedProfileName}
                          setDefaultSelectedProfileName={this.setDefaultSelectedProfileName}
                          Client={this.state.Client}
                          SelectedFilter={this.props.SelectedFilter}
                          onTabChange={this.props.onTabChange}
                          navigatetoPulseScreen={this.navigatetoPulseScreen}
                          getAllProfileAgainstUser={this.getProfileListAPI}
                          {...this.props}
                        />
                        )
                      }

                  </div>

                  {compDetails === 'Journey Initiatives'
                  && this.state.selectedOMIDs[0] !== 'All Locations'
                  && this.state.selectedOMIDs.length ? (
                    <>
                      <div className="filterdata-options ml-auto d-flex align-items-center">
                        {this.state.Client
                      && (
                      <SnapshotProfile
                        compDetails={compDetails}
                        filterDetails={filterDetails}
                        MaturityPhase={MaturityPhase}
                        OperatingGropus={this.props.OperatingGropus}
                        languageData={this.props.languageData}
                        profileListParent={this.state.profileList}
                        offeringData={this.state.offeringData}
                        defaultSelectedProfileName={this.state.defaultSelectedProfileName}
                        setDefaultSelectedProfileName={this.setDefaultSelectedProfileName}
                        Client={this.state.Client}
                        SelectedFilter={this.props.SelectedFilter}
                        onTabChange={this.props.onTabChange}
                        navigatetoPulseScreen={this.navigatetoPulseScreen}
                        getAllProfileAgainstUser={this.getProfileListAPI}
                        {...this.props}
                      />
                      )}
                        <div
                          className="omid-enable-toggle"
                          style={{ cursor: this.state.selectedOMIDs[0] !== 'All' && 'pointer' }}
                        >
                          <span
                            data-tip
                            data-for="omid-toggle-tooltip"
                            data-tip-disable={this.state.selectedOMIDs[0] === 'All'}
                          >
                            OMID:
                            {' '}
                            {this.state.selectedOMIDs[0]}
                          </span>
                          <ReactTooltip place="left" effect="solid" id="omid-toggle-tooltip">
                            <span>{this.state.OMIDProcess}</span>
                          </ReactTooltip>
                        </div>
                      </div>
                    </>
                    ) : null}

                  {compDetails === 'IOJ Scorecard' ? (
                    <>
                      <div className="filterdata-options ml-auto d-flex align-items-center">
                      <DropdownButton
                        menuAlign="left"
                        id="Monthbutton"
                        title={filterDetails.selectedIojMonth || i18n.t('Select_Month')}
                        className={
                          filterDetails.selectedIojMonth !== '' && filterDetails.selectedIojMonth !== i18n.t('Select_Month')
                            ? 'custom-headerdropdown selected mr-3'
                            : 'custom-headerdropdown mr-3'
                        }
                        // disabled={!monthData.length}
                      >
                        <div className="offering-droplistwrapper">
                          {this.AllMonths.map((each) => (
                            <Dropdown.Item
                              href="javascript:void(0);"
                              className={each === filterDetails.selectedIojMonth ? 'active' : ''}
                              onClick={() => (each === filterDetails.selectedIojMonth  ? '' : this.onIOJSocreMonthChange({ target: { value: each } }))}
                              onKeyDown={() => (each === filterDetails.selectedIojMonth  ? '' : this.onIOJSocreMonthChange({ target: { value: each } }))}
                            >

                              <span
                                className="form-check-label"
                                role="button"
                                tabIndex={0}
                              >
                                {each}
                              </span>
                            </Dropdown.Item>
                          ))}
                        </div>
                      </DropdownButton>
                        {this.getOMIDText() && <div
                          className="omid-enable-toggle"
                          style={{ cursor: this.state.selectedOMIDs[0] !== 'All' && 'pointer' }}
                        >
                          <span
                            data-tip
                            data-for="omid-toggle-tooltip"
                            data-tip-disable={this.state.selectedOMIDs[0] === 'All'}
                          >
                            OMID:
                            {' '}
                            {this.getOMIDText()}
                            {/* { */}
                          </span>
                         
                        </div>}
                      </div>
                    </>
                    ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  CurrentFilter: { ...state.CurrentFilter },
  compDetails: state.CurrentFilter.Tab.title,
  SelectedFilter: { ...state.CurrentFilter.SyncFilter },
  snapshotProfilesRedux: [...state.CurrentFilter.snapshotProfile],
  roleData: state.roleReducers.roleReducers,
});
const mapDispatchToProps = (dispatch) => ({
  saveCommentHistoryId: (data) => {
    dispatch({ type: 'SELECTED_METRIC_COMMENT_HISTORY', payload: data });
  },
  updateSyncFilter: (data) => new Promise((resolve, reject) => {
    dispatch({ type: 'SYNC_CURRENTFILTER', payload: data });
    resolve();
  }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);
