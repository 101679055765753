import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { IOJNavigation as getPrevMonth, LocalApiBaseUrl, trycatchAlertPopup,idTokenstring } from '../Shared/Constant';
import { createBreadCrumbs } from '../Shared/commonFunctions';
import AddPMMService from './MaturityPlan/AddPMMService';
import { addBOdata, addIIdata, ClearDatas_ViewDataStageData } from '../actions/JourneyActions';
import JourneyInitiativeContainer from '../JourneyInitiative/JourneyInitiativeContainer';
import InitiativeDetails from '../JourneyInitiative/InitiativeDetails';
import IOJ_Initiative from './IOJ_Initiative_Comparison';
import IOJ_ValueRealization from './IOJ_ValueRealization';
import '../CSS/IojStyle.css';
import axiosInstance from '../Shared/interceptor';

import DealReport from './DealReport';

import { store } from '../reducers/configureStore';
import IOJScoreCard from './IOJScoreCard';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;


class IOJ extends Component {
  constructor() {
    super();
    this.state = {
      Tab: 'IOJMaturityRoadmap',
      isFD: window.location.pathname === '/Dashboard',
      boData: [],
      isStage: null,
      isShowInitiativeDeatils: false,
      EachInitiativeDetail: [],
      IOJStatus: true,
      intelOpsDetails: [],
      filterDetails: [],
      roledata: [],
    };
    this.getIntelOps = this.getIntelOps.bind(this);
    this.getFeatureDetails = this.getFeatureDetails.bind(this);
    this.ShowInitativeDetails = this.ShowInitativeDetails.bind(this);
    this.HideInitativeDeatils = this.HideInitativeDeatils.bind(this);
  }

  getFeatureDetails() {
    const featureDetails1 = this.props.roledata;
    let operationArray;
    featureDetails1.map((i) => {
      if (i.FeatureName === 'Intelligent Operations Journey') {
        operationArray = i.Operations;
        return operationArray;
      }
    });


    this.setState({ roledata: operationArray });
  }

  getIntelOps(offeringIdparam = {
    Client: 0, process: 0, Locations: 0, Month: getPrevMonth(),
  }) {
    const filterProcess = offeringIdparam.process == undefined ? 0 : offeringIdparam.process;
    const filterLocations = offeringIdparam.Locations == undefined ? 0 : offeringIdparam.Locations;
    const filterClient = offeringIdparam.Client == undefined ? 0 : offeringIdparam.Client;
    const dateArray = offeringIdparam.Month == 0 || offeringIdparam.Month == undefined ? [] : offeringIdparam.Month.split('-');
    let todayDate = '';
    if (dateArray.length == 2) {
      todayDate = `1-${dateArray[0]}-${dateArray[1]}`;
    } else {
      todayDate = offeringIdparam.Month;
    }
    if (filterProcess != 0 && filterLocations != 0 && filterClient != 0 && todayDate != 0) {
      const filterClient = this.props.filterDetails.Client;
      const filterProcess = this.props.filterDetails.process;
      const filterLocations = this.props.filterDetails.Locations;
      const filterDate = this.props.filterDetails.Month;
    }
  }

  getBOApiData() {
    this.setState({
      active: true,
    });
    const boData = [];
    const { isFD } = this.state;
    const filterClient = this.props.filterDetails.Client;
    const filterProcess = this.props.filterDetails.process;
    const filterLocations = this.props.filterDetails.Locations;
    const filterDate = this.props.filterDetails.Month;
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetBusinessOutcomes`, {
        params: {
          OrgEntityID: filterClient,
          LocationID: filterLocations,
          MonthDT: filterDate,
          OfferingID: filterProcess,
          LanguageID: parseInt(this.props.languageData.id),
        },
      })
      .then((response) => {
        this.setState({ active: false });
        if (isFD) {
          this.props.addBOdataForIOJ(response.data);
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getInsightsIntelligenceDetails() {
    const { isFD } = this.state;
    const filterClient = this.props.filterDetails.Client;
    const filterProcess = this.props.filterDetails.process;
    const filterLocations = this.props.filterDetails.Locations;
    const filterDate = this.props.filterDetails.Month;
    if (filterProcess != 0 && filterLocations != 0 && filterClient != 0 && filterDate != 0) {
      axiosInstance
        .get(`${LocalApiBaseUrl}SOFilter/GetInsightsAndIntelligence`, {
          params: {
            OrgEntityID: filterClient,
            LocationID: filterLocations,
            MonthDT: filterDate,
            OfferingID: filterProcess,
            LanguageID: parseInt(this.props.languageData.id),
          },
        })
        .then((response) => {
          if (isFD) {
            this.props.addIIdataForIOJ(response.data);
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  componentDidMount() {
    const { SyncFilter } = this.props;
    if (SyncFilter && SyncFilter.ProcessId && SyncFilter.OfferingId && SyncFilter.LocationId && SyncFilter.Month) {
      const { isFD } = this.state;
      if (isFD) {
        this.props.addBOdataForIOJ([]);
        this.props.addIIdataForIOJ([]);
        this.getBOApiData();
        this.getInsightsIntelligenceDetails();
      }
    }
    this.setState({ intelOpsDetails: this.props.iojData });
  }

  componentWillMount() {
    this.getFeatureDetails();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isIOJLoaded !== this.props.isIOJLoaded) {
      this.setState({ intelOpsDetails: this.props.iojData });
    }
  }

  ShowInitativeDetails(e) {
    this.setState({
      isShowInitiativeDeatils: true,
    });
  }

  setTabstatus=(isInitativeManagement) => {
    this.setState({
      Tab: isInitativeManagement ? 'InitiativeManagement' : 'IOJMaturityRoadmap',
    });
  }

  HideInitativeDeatils(status, isInitativeManagement) {
    let { isStage, isShowInitiativeDeatils } = this.state;

    if (status) {
      isStage = true;
    } else {
      isStage = false;
      store.dispatch(ClearDatas_ViewDataStageData(true));
    }
    isShowInitiativeDeatils = false;
    this.setState({
      isStage,
      isShowInitiativeDeatils,
      Tab: isInitativeManagement ? 'InitiativeManagement' : 'IOJMaturityRoadmap',
    });
  }

  render() {
    const { Tab } = this.state;
    const {
      compDetails, DashboardSubOfferingData, DasboardOfferingData, SyncFilter,
    } = this.props;
    return (
      <>
        <div className="main-component ioj-component">
          {createBreadCrumbs(DashboardSubOfferingData, SyncFilter.OfferingId, SyncFilter.ProcessId, DasboardOfferingData) }
          {compDetails && compDetails.component !== 'IOJ Scorecard' && <h2 className="stage-heading">{compDetails.tabName}</h2>}
          {compDetails && compDetails.component === 'IOJ Scorecard' ? (
            <IOJScoreCard/>
          ) : null}

          {compDetails && compDetails.component === 'Journey Initiatives' ? (

            !this.state.isShowInitiativeDeatils ? (
              <JourneyInitiativeContainer
                Tab={Tab}
                setTabstatus={this.setTabstatus}
                ShowInitativeDetails={this.ShowInitativeDetails}
                isStage={this.state.isStage}
                filterDetails={this.props.filterDetails}
                compDetails={compDetails}
              />
            ) : (
              <InitiativeDetails
                Tab={Tab}
                EachInitativeData={this.state.EachInitiativeDetail}
                HideInitativeDeatils={this.HideInitativeDeatils}
                filterDetails={this.props.filterDetails}
                compDetails={compDetails}
              />
            )
          ) : null}

          {compDetails && compDetails.component === 'Value Realization' ? (
            <IOJ_ValueRealization filterDetails={this.props.filterDetails} />
          ) : null}

          {compDetails && compDetails.component === 'Maturity Plan' ? (
            <AddPMMService filterDetails={this.props.filterDetails} />
          ) : null}
          {compDetails && compDetails.component === 'Initiative Comparison' ? (
            <IOJ_Initiative filterDetails={this.props.filterDetails} roledata={this.state.roledata} />
          ) : null}
          {compDetails && compDetails.component === 'Deal Report' ? (
            <DealReport filterDetails={this.props.filterDetails} roledata={this.state.roledata} />
          ) : null}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  iojData: state.CurrentFilter.iojData,
  languageData: state.CurrentFilter.languageData,
  SyncFilter: state.CurrentFilter.SyncFilter,
  DasboardOfferingData: state.FilterReducer && state.FilterReducer.DasboardOfferingData ? state.FilterReducer.DasboardOfferingData : [],
  DashboardSubOfferingData: state.FilterReducer && state.FilterReducer.DashboardSubOfferingData ? state.FilterReducer.DashboardSubOfferingData : [],
});
const mapDispatchToProps = (dispatch) => ({
  addBOdataForIOJ: (payload) => dispatch(addBOdata(payload)),
  addIIdataForIOJ: (payload) => dispatch(addIIdata(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IOJ);
