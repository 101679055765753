import React, { useEffect, useState, useRef, useCallback } from "react";
import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
import LoadingOverlay from "react-loading-overlay";
import classNames from "classnames";
import Chart from "chart.js";

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import SynopsLoader from "../Shared/SynopsLoader";
import { LocalApiBaseUrl, validateBATFieldValue, numberFormatter } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

import Caret from "../Images/tve_Icons/Carat.svg";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem(
  "msal.idtoken"
)}`;

function TotalValueEnabledModal(props) {
  const {
    sharedAccessDetail,
    BATAssessSubOfferingID,
    metricData: {
      clientMetricValue: clientValueFromProps,
      metricBenchmarkValue,
      userEnteredBenchmarkValue,
      leadingPractice,
      metricID,
      metricUnit,
      formulasForTVECalculation,
      volumetricDetails,
      metricName,
      metricUnitFormatted,
    },
    onClose,
    contractPeriod: contractPeriodFromProps,
    benchmarkName,
    calculateMetricImprovementOpportunity,
    calculateMetricTVE,
  } = props;

  const errMsgInvalidNumericValue = "Invalid Numeric Value";
  const errMsgContractPeriodValue = "Contract period value should be between 1 and 5 years";
  const suffixLabelsForYear = ["", "st Year", "nd Year", "rd Year", "th Year", "th Year"];
  const lineChartRef = useRef(null);
  const benchmarkTextBoxRef = useRef(null);
  const metricOpportunityEmptyData = {
    opportunityValue: "",
    performanceIndicator: "",
  };
  const metricTVEEmptyData = {
    totalValueEnabled: "",
    totalValueEnabledFormatted: "",
    totalValueEnabledSuffixText: "",
    totalValueEnabledUOM: "",
  };

  function generateYearWiseEmptyData(contractPeriod) {
    const yoyData = [];
    _.times(contractPeriod, (index) => {
      yoyData.push({ Year: index + 1, Value: null });
    });
    return yoyData;
  }

  function getImprovedPercentageValue(initialValue, percentageImprovement, leadingPractice) {
    let improvementPercentValue = (initialValue * percentageImprovement) / 100;

    // IF THE LEADING PRACTICE OF THE METRIC IS "MINIMUM" THEN THE IMPROVEMENT IS CALCULATED BY SUBTRACTING THE "DERIVED PERCENTAGE VALUE" ELSE IT HAS TO BE ADDED
    let improvedFinalValue = initialValue - improvementPercentValue;

    if (leadingPractice === "maximum") {
      improvedFinalValue = initialValue + improvementPercentValue;
    }

    return improvedFinalValue;
  }

  // IF BENCHMARK VALUE ENTERED BY THE USER IS EMPTY THEN TAKE THE VALUE OF BENCHMARK RECEIVED FROM THE API IF IT IS THERE
  let benchmarkValueFromProps = userEnteredBenchmarkValue;
  if (_.isNil(userEnteredBenchmarkValue) || _.isEmpty(String(userEnteredBenchmarkValue))) {
    benchmarkValueFromProps = metricBenchmarkValue;
  }

  ////////////////////////////////   STATES   ////////////////////////////////////
  const [isAPILoading, setAPILoading] = useState(false);
  const [prevBenchmarkValue, setPrevMetricBenchmarkValue] = useState(
    String(benchmarkValueFromProps)
  );
  const [benchmarkValue, setMetricBenchmarkValue] = useState(String(benchmarkValueFromProps));
  const [isValidBenchmarkValue, setValidBenchmarkValue] = useState(true);
  const [prevClientValue, setPrevClientValue] = useState(String(clientValueFromProps));
  const [clientValue, setClientValue] = useState(String(clientValueFromProps));
  const [isValidClientValue, setValidClientValue] = useState(true);
  const [prevContractPeriod, setPrevContractPeriod] = useState(String(contractPeriodFromProps));
  const [contractPeriod] = useState(String(contractPeriodFromProps));
  const [haveValuesChanged, setHaveValuesChanged] = useState(false);
  const [metricOpportunityData, setMetricOpportunityData] = useState(metricOpportunityEmptyData);
  const [metricTVEData, setMetricTVEData] = useState(metricTVEEmptyData);
  const [isVisibleDeepDiveView, setVisibleDeepDiveView] = useState(false);
  const [percentImprovementEachYearValue, setPercentImprovementEachYearValue] = useState("");
  const [calculateYoYDataBy, setCalculateYoYDataBy] = useState(""); // "percentage" OR "manualValueInput"
  const [rawYearWiseData, setRawYearWiseData] = useState(
    generateYearWiseEmptyData(contractPeriodFromProps)
  );
  const [tempRawYearWiseData, setTempRawYearWiseData] = useState(
    generateYearWiseEmptyData(contractPeriodFromProps)
  );
  const [yearWiseDataArr, setYearWiseDataArr] = useState([]);
  const [snapshotOfDataWhenYoYDataLastCalculated, setSnapshotOfDataWhenYoYDataLastCalculated] =
    useState({
      benchmarkValue,
      clientValue,
      contractPeriod,
    });

  ////////////////////////////////   EFFECTS   ////////////////////////////////////
  useEffect(() => {
    calculateMetricOpportunity();
    updateMetricTVE();
    fetchMetricYoYData();

    // FOCUS THE BENCHMARK TEXT BOX ON COMPONENT MOUNT
    benchmarkTextBoxRef.current.focus();
    // SET THE CHART TO UNDEFINED WHEN COMPONENT UNMOUNTS
    return () => {
      window.batTVEMetricBenchmarkChart = undefined;
    };
  }, []);

  useEffect(() => {
    // CHECK IF THE BENCHMARK VALUE, CLIENT VALUE OR THE CONTRACT PERIOD HAVE CHANGED FROM THE ONES THAT WERE RECEIVED FROM PROPS, IF YES THEN SET THE FLAG `haveValuesChanged` ACCORDINGLY
    let areValuesChanged = false;

    if (
      String(prevBenchmarkValue) !== String(benchmarkValueFromProps) ||
      String(prevClientValue) !== String(clientValueFromProps) ||
      String(prevContractPeriod) !== String(contractPeriodFromProps)
    ) {
      areValuesChanged = true;
    }
    setHaveValuesChanged(areValuesChanged);

    // WHEN ANY OF THE VALUES "BENCHMARK VALUE", "CLIENT VALUE" OR "CONTRACT PERIOD" ARE CHANGED FROM THEIR PREVIOUS VALUES OR THE METRIC OPPORTUNITY PERFORMANCE INDICATOR TURNS OUT TO BE "BETTER", THEN RESET ALL THE YEAR WISE DATA (WHICH WOULD TRIGGER RE-CALCULATION OF YoY DATA ARRAY) AS IN THIS CASE THE OLDER YoY VALUES CALCULATED BASED ON PREVIOUS VALUES STAND INVALID
    if (
      String(prevBenchmarkValue) !==
        String(snapshotOfDataWhenYoYDataLastCalculated.benchmarkValue) ||
      String(prevClientValue) !== String(snapshotOfDataWhenYoYDataLastCalculated.clientValue) ||
      String(prevContractPeriod) !==
        String(snapshotOfDataWhenYoYDataLastCalculated.contractPeriod) ||
      metricOpportunityData.performanceIndicator === "better"
    ) {
      const yearWiseEmptyData = generateYearWiseEmptyData(prevContractPeriod);
      setRawYearWiseData(_.cloneDeep(yearWiseEmptyData));
      setTempRawYearWiseData(_.cloneDeep(yearWiseEmptyData));
      setPercentImprovementEachYearValue("");
      setCalculateYoYDataBy("");
      executeAPISaveYoYData(true);
    }
  }, [
    prevBenchmarkValue,
    prevClientValue,
    prevContractPeriod,
    snapshotOfDataWhenYoYDataLastCalculated,
    metricOpportunityData,
  ]);

  useEffect(() => {
    if (!isVisibleDeepDiveView) {
      renderChart();
    }
  }, [
    isVisibleDeepDiveView,
    metricOpportunityData,
    metricTVEData,
    prevContractPeriod,
    yearWiseDataArr,
  ]);

  useEffect(() => {
    // WHEN SWITCHING TO "Deep Dive View" SET THE VALUE OF THE `tempRawYearWiseData` FROM `rawYearWiseData`
    if (isVisibleDeepDiveView) {
      setTempRawYearWiseData(_.cloneDeep(rawYearWiseData));
    }
  }, [isVisibleDeepDiveView]);

  useEffect(() => {
    const yearWiseEmptyData = generateYearWiseEmptyData(prevContractPeriod);
    setTempRawYearWiseData(_.cloneDeep(yearWiseEmptyData));
  }, [calculateYoYDataBy]);

  useEffect(() => {
    prepareYearWiseData();
  }, [
    prevBenchmarkValue,
    prevClientValue,
    prevContractPeriod,
    percentImprovementEachYearValue,
    rawYearWiseData,
    tempRawYearWiseData,
    isVisibleDeepDiveView,
    calculateYoYDataBy,
  ]);

  ////////////////////////////////   EVENT HANDLERS   ////////////////////////////////////
  //API CALL TO GET ANY SAVED YEAR ON YEAR VALUE FOR SPECIFIC COMBINATION OF BATAssessSubOfferingID AND BATMetricID
  function fetchMetricYoYData() {
    setAPILoading(true);
    axiosInstance
      .get(
        `${LocalApiBaseUrl}BATAssessment/Get_BATAssessmentClientMetricYoYValue?BATAssessSubOfferingID=${BATAssessSubOfferingID}&BATMetricID=${metricID}`
      )
      .then(async (response) => {
        setAPILoading(false);
        let responseData = response.data;

        if (!_.isEmpty(responseData)) {
          let calculateYoYDataBy = "";
          let percentageImprovement = responseData.YearlyImprovement;

          if (!_.isNil(percentageImprovement) && !_.isEmpty(String(percentageImprovement))) {
            setPercentImprovementEachYearValue(String(percentageImprovement));
            calculateYoYDataBy = "percentage";

            const YearWiseValuesArr = [];
            _.times(contractPeriod, (index) => {
              let initialValue = _.toNumber(clientValue);
              if (index !== 0) {
                initialValue = YearWiseValuesArr[index - 1].Value;
              }

              const calculatedImprovedValue = getImprovedPercentageValue(
                initialValue,
                _.toNumber(percentageImprovement),
                leadingPractice
              );

              YearWiseValuesArr.push({
                Year: index + 1,
                Value: calculatedImprovedValue,
              });
            });

            setRawYearWiseData(YearWiseValuesArr);
          } else if (!_.isEmpty(responseData.YearWiseValuesArr)) {
            setRawYearWiseData(responseData.YearWiseValuesArr);
            calculateYoYDataBy = "manualValueInput";
          }
          setCalculateYoYDataBy(calculateYoYDataBy);
        }
      })
      .catch((error) => {
        setAPILoading(false);
        trycatchAlertPopup(error);
      });
  }

  function executeAPISaveYoYData(saveEmptyValuesSilently = false) {
    // WHEN THE FLAG `saveEmptyValuesSilently` IS SET TO `true` THEN SAVE ALL THE EMPTY VALUES TO DB BYPASSING ALL THE VALIDATIONS. THIS WILL BE DONE IN THE CASE TO RESET THE YoY DATA WHEN ANY OF THE VALUES "BENCHMARK VALUE", "CLIENT VALUE" OR "CONTRACT PERIOD" ARE CHANGED FROM THEIR PREVIOUS VALUES AS IN THIS CASE THE OLDER YoY VALUES CALCULATED BASED ON PREVIOUS VALUES STAND INVALID
    let areAllDataValid = true;
    let yearlyImprovementValue = "";
    let yearWiseValuesArr = [];

    if (!saveEmptyValuesSilently) {
      if (calculateYoYDataBy === "percentage") {
        areAllDataValid = isValidValue(percentImprovementEachYearValue);
        if (areAllDataValid) {
          yearlyImprovementValue = percentImprovementEachYearValue;
        } else {
          areAllDataValid = false;
          window.alert('Please enter a non-empty numeric value for "Improvement Each Year"');
        }
      } else if (calculateYoYDataBy === "manualValueInput") {
        // CHECK IF THERE ARE ANY INVALID VALUES FOR "INITIAL" OR "FINAL" VALUES FOR ANY OF THE YoY DATA
        areAllDataValid = _.isEmpty(
          _.filter(
            yearWiseDataArr,
            (eachYoYData) =>
              !isValidValue(eachYoYData.initialValue) || !isValidValue(eachYoYData.finalValue)
          )
        );
        if (areAllDataValid) {
          yearWiseValuesArr = _.map(yearWiseDataArr, (eachYoYData, index) => ({
            year: index + 1,
            Value: eachYoYData.finalValue,
          }));
        } else {
          areAllDataValid = false;
          window.alert('Please enter non-empty numeric values for "Year Wise Improvement"');
        }
      } else {
        // WHEN NO RADIO OPTION TO CALCULATE THE YoY DATA IS SELECTED THEN SHOW ERROR
        areAllDataValid = false;
        window.alert('Please select an option to calculate the "Year On Year Valuation"');
      }
    }

    if (areAllDataValid) {
      const reqData = {
        BATAssessSubOfferingID,
        BATMetricID: metricID,
        YearlyImprovement: yearlyImprovementValue,
        YearWiseValuesArr: yearWiseValuesArr,
      };

      !saveEmptyValuesSilently && setAPILoading(true);
      axiosInstance
        .post(`${LocalApiBaseUrl}BATAssessment/AddEdit_BATAssessmentClientMetricYoYValue`, reqData)
        .then((response) => {
          if (!saveEmptyValuesSilently) {
            setAPILoading(false);
            setVisibleDeepDiveView(false);
            setRawYearWiseData(_.cloneDeep(tempRawYearWiseData));
            setSnapshotOfDataWhenYoYDataLastCalculated({
              benchmarkValue,
              clientValue,
              contractPeriod,
            });
          }
        })
        .catch((error) => {
          setAPILoading(false);
          trycatchAlertPopup(error);
        });
    }
  }

  function prepareYearWiseData() {
    const rawYearWiseDataForCalculation = !isVisibleDeepDiveView
      ? rawYearWiseData
      : tempRawYearWiseData;

    const yearWiseDataArray = [];

    // CALCULATE THE YoY DATA ONLY IF THE ALL THE ESSENTIAL VALUES ARE VALID AND METRIC OPPORTUNITY PERFORMANCE INDICATOR IS NOT "BETTER"
    if (
      isValidValue(benchmarkValue) &&
      isValidValue(clientValue) &&
      isValidContractPeriodValue(contractPeriod) &&
      metricOpportunityData.performanceIndicator !== "better"
    ) {
      const benchmarkValueParsed = _.toNumber(benchmarkValue);
      const clientValueParsed = _.toNumber(clientValue);
      const contractPeriodParsed = _.toNumber(contractPeriod);

      _.times(contractPeriodParsed, (index) => {
        const currentYearNumber = index + 1;
        let eachYearOpportunityData = {};
        let eachYearTVEData = {};
        let eachYearInitialValue = null;
        let eachYearFinalValue = null;
        let tveForDisplay = "-";
        let isInitialValueSubstitutedJustForCalculation = false;
        let initialValueBeforeSubstitutionForCalculation;

        // FOR THE FIRST YEAR, THE INITIAL VALUE IS THE CLIENT VALUE
        if (currentYearNumber === 1) {
          eachYearInitialValue = clientValueParsed;
        } else {
          // FOR THE SUBSEQUENT YEARS, THE INITIAL VALUE OF THE CURRENT YEAR IS THE FINAL VALUE OF THE PREVIOUS YEAR
          eachYearInitialValue = yearWiseDataArray[index - 1].finalValue;
        }

        // IF THE YoY DATA IS TO BE CALCULATED BY "PERCENTAGE IMPROVEMENT EACH YEAR", THEN CALCULATE THE IMPROVEMENT IN "FINAL VALUE" FOR EACH YEAR BASED ON THE "INITIAL VALUE"
        if (calculateYoYDataBy === "percentage" && isValidValue(percentImprovementEachYearValue)) {
          const percentImprovementEachYearValueParsed = _.toNumber(percentImprovementEachYearValue);
          eachYearFinalValue = getImprovedPercentageValue(
            eachYearInitialValue,
            percentImprovementEachYearValueParsed,
            leadingPractice
          );
        } else if (
          calculateYoYDataBy === "manualValueInput" &&
          !_.isEmpty(rawYearWiseDataForCalculation)
        ) {
          const currentYearDataFromYoYData = _.find(rawYearWiseDataForCalculation, {
            Year: currentYearNumber,
          });
          if (currentYearDataFromYoYData) {
            eachYearFinalValue = currentYearDataFromYoYData.Value;
          }
        }

        // FOR THE LAST YEAR, THE FINAL VALUE IS ALWAYS SET TO THE BENCHMARK VALUE
        if (currentYearNumber === contractPeriodParsed) {
          eachYearFinalValue = benchmarkValueParsed;

          // IF FOR THE LAST YEAR, "INITIAL VALUE" IS EMPTY, THEN SUBSTITUTE ITS VALUE TO THE "CLIENT VALUE" SO THAT THE OPPORTUNITY AND TVE CAN BE CALCULATED WHICH WOULD BE ANYWAY EQUAL TO THE OPPORTUNITY AND TVE OF THE METRIC
          if (_.isNil(eachYearInitialValue) || _.isEmpty(String(eachYearInitialValue))) {
            initialValueBeforeSubstitutionForCalculation = eachYearInitialValue;
            eachYearInitialValue = clientValueParsed;
            isInitialValueSubstitutedJustForCalculation = true;
          }
        }

        if (_.isFinite(eachYearInitialValue)) {
          eachYearInitialValue = _.isInteger(eachYearInitialValue)
            ? eachYearInitialValue
            : _.toNumber(parseFloat(eachYearInitialValue).toFixed(2));
        }
        if (_.isFinite(eachYearFinalValue)) {
          eachYearFinalValue = _.isInteger(eachYearFinalValue)
            ? eachYearFinalValue
            : _.toNumber(parseFloat(eachYearFinalValue).toFixed(2));
        }

        // IF BOTH VALUES ARE VALID THEN ONLY CALCULATE THE OPPORTUNITY VALUE AND TVE
        if (isValidValue(eachYearInitialValue) && isValidValue(eachYearFinalValue)) {
          // CALCULATE IMPROVEMENT OPPORTUNITY VALUE
          eachYearOpportunityData = calculateMetricImprovementOpportunity({
            metricLeadingPractice: leadingPractice,
            clientValue: eachYearInitialValue,
            benchmarkValue: eachYearFinalValue,
          });

          eachYearTVEData = calculateMetricTVE({
            metricID: metricID,
            metricUnit: metricUnit,
            formulasForTVECalculation: formulasForTVECalculation,
            volumetricDetails: volumetricDetails,
            clientValue: eachYearInitialValue,
            benchmarkValue: eachYearFinalValue,
          });

          // FORMAT TVE FOR DISPLAY
          if (
            !_.isEmpty(String(eachYearTVEData.totalValueEnabled)) &&
            eachYearOpportunityData.performanceIndicator !== "better"
          ) {
            tveForDisplay = eachYearTVEData.totalValueEnabledFormatted;

            // IF THE TVE UNIT OF MEASUREMENT IS "$" THEN PREFIX THE TEXT WITH A DOLLAR SIGN
            if (metricTVEData.totalValueEnabledUOM === "$") {
              tveForDisplay = `$${tveForDisplay}`;
            }
              else if (metricTVEData.totalValueEnabledUOM === "%"){
                tveForDisplay = `${tveForDisplay}%`;
              }
            
          }
        }

        const eachYearData = {
          opportunityData: eachYearOpportunityData,
          tveData: eachYearTVEData,
          tveForDisplay: tveForDisplay,
          initialValue: isInitialValueSubstitutedJustForCalculation
            ? initialValueBeforeSubstitutionForCalculation
            : eachYearInitialValue,
          finalValue: eachYearFinalValue,
          yearDisplayLabel: `${currentYearNumber}${_.get(suffixLabelsForYear, currentYearNumber)}`,
        };

        yearWiseDataArray.push(eachYearData);
      });
    } else {
      _.times(_.toNumber(prevContractPeriod), (index) => {
        const currentYearNumber = index + 1;

        const eachYearData = {
          opportunityData: {},
          tveData: {},
          tveForDisplay: "-",
          initialValue: null,
          finalValue: null,
          yearDisplayLabel: `${currentYearNumber}${_.get(suffixLabelsForYear, currentYearNumber)}`,
        };
        yearWiseDataArray.push(eachYearData);
      });
    }

    setYearWiseDataArr(yearWiseDataArray);
  }

  function renderChart() {
    const { performanceIndicator: metricPerformanceIndicator } = metricOpportunityData;
    const { totalValueEnabledUOM: metricTotalValueEnabledUOM } = metricTVEData;

    let contractPeriodParsed = _.toNumber(prevContractPeriod);

    const xAxisLabelsArr = [""];

    if (isValidContractPeriodValue(contractPeriod)) {
      // PREPARE X-AXIS LABELS ARRAY
      _.times(contractPeriodParsed, (index) => {
        xAxisLabelsArr.push(`${index + 1}${_.get(suffixLabelsForYear, index + 1)}`);
      });
    }

    // PREPARE DYNAMIC LABEL TEXT OF Y-Axis WITH METRIC NAME AND ITS UNIT
    let yAxisLabel = metricName;
    if (!_.isEmpty(metricUnitFormatted)) {
      yAxisLabel =
        metricTotalValueEnabledUOM === "$"
          ? "Total Value Enabled (TVE) in USD"
          : yAxisLabel + "(" + metricUnitFormatted + ")";
    }

    let chartData = [];
    let annotationToMap = [];
    let dataForTooltip = [];
    let tveForAllYearsArray = [];

    // TO GET STRAIGHT HORIZONTAL LINE IN CHART, IN CASE IF BV IS MISSING- ELSE GENERATE DATA TO BE USED FOR POINTS
    if (!isValidValue(benchmarkValue) || metricPerformanceIndicator === "better") {
      annotationToMap[0] = {
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-0",
        value: metricTotalValueEnabledUOM === "$" ? 0 : clientValue,
        borderColor: "rgb(161, 0, 255)",
        borderWidth: 4,
        label: {
          enabled: false,
        },
      };
    } else if (
      !_.isEmpty(yearWiseDataArr) &&
      isValidValue(benchmarkValue) &&
      isValidValue(clientValue) &&
      isValidContractPeriodValue(contractPeriod)
    ) {
      // LOOP OVER YEAR WISE DATA TO PREPARE CHART DATA
      chartData = _.map(yearWiseDataArr, (currentYearData, currentYearIndex) => {
        let tveForDisplayForCurrentYear = currentYearData.tveForDisplay;
        const metricValueForCurrentYear = currentYearData.finalValue;

        // "METRIC VALUES" FOR THE ALL THE YEARS IS TO BE DISPLAYED AS DATA POINTS ON THE CHART, SO SET THE `metricValueForCurrentYear` TO BE PUSHED IN THE CHART DATA ARRAY
        let chartDataToPush = metricValueForCurrentYear;

        let { opportunityValue: opportunityValueForCurrentYear } = currentYearData.opportunityData;

        let {
          totalValueEnabled: totalValueEnabledForCurrentYear,
          totalValueEnabledFormatted: totalValueEnabledFormattedForCurrentYear,
        } = currentYearData.tveData;

        let totalValueEnabledParsed = totalValueEnabledForCurrentYear;
        if (!_.isNil(totalValueEnabledParsed) && !_.isEmpty(String(totalValueEnabledParsed))) {
          totalValueEnabledParsed = _.toNumber(totalValueEnabledParsed);
          totalValueEnabledParsed = !_.isInteger(totalValueEnabledParsed)
            ? parseFloat(totalValueEnabledParsed).toFixed(2)
            : totalValueEnabledParsed;
        }

        const currentYearTVE =
          !_.isNil(totalValueEnabledParsed) && !_.isEmpty(String(totalValueEnabledParsed))
            ? _.toNumber(totalValueEnabledParsed)
            : null;
        tveForAllYearsArray.push(currentYearTVE);

        // WHEN THE UNIT OF MEASUREMENT OF THE TVE OF THE METRIC IS "$" THEN INSTEAD OF DISPLAYING THE "METRIC VALUE" AS CHART DATA DISPLAY THE CUMULATIVE TVE
        if (metricTotalValueEnabledUOM === "$") {
          let cumulativeTVE = currentYearTVE;

          if (currentYearIndex !== 0) {
            // TO DERIVE THE CURRENT YEAR CUMULATIVE TVE, SUM UP THE CURRENT YEAR TVE WITH THE TVEs OF ALL THE PREVIOUS YEARS SUMMED UP
            // FOR THE CURRENT YEAR, TAKE THE DATA ARRAY `tveForAllYearsArray` TILL THE PREVIOUS YEARS TO DERIVE THE CUMULATIVE TVE
            const previousAllYearsTVESummedUp = _.take(
              tveForAllYearsArray,
              currentYearIndex
            ).reduce((result, eachYearTVE) => {
              let sum = result;
              if (!_.isNil(eachYearTVE) && !_.isEmpty(String(eachYearTVE))) {
                sum = sum + eachYearTVE;
              }
              return sum;
            });

            // FOR THE CUMULATIVE TVE OBTAINED TILL ALL THE PREVIOUS YEARS, THE CUMULATIVE TVE FOR THE CURRENT YEAR CAN BE DERIVED BY SUMMING UP ALL THE PREVIOUS YEAR TVE VALUES WITH THE CURRENT YEAR TVE
            if (!_.isNil(previousAllYearsTVESummedUp) && !_.isNil(cumulativeTVE)) {
              const calculatedValue = previousAllYearsTVESummedUp + currentYearTVE;
              cumulativeTVE = _.toNumber(
                !_.isInteger(calculatedValue)
                  ? parseFloat(calculatedValue).toFixed(2)
                  : calculatedValue
              );
            }
          }
          chartDataToPush = cumulativeTVE;
        }

        // SET DATA FOR TOOLTIP
        dataForTooltip.push({
          metricValue: metricValueForCurrentYear,
          opportunityValue:
            opportunityValueForCurrentYear < 0 ||
            _.isNil(opportunityValueForCurrentYear) ||
            _.isEmpty(String(opportunityValueForCurrentYear))
              ? "-"
              : opportunityValueForCurrentYear,
          totalValueEnabledFormatted: totalValueEnabledFormattedForCurrentYear,
          TVEToShow: tveForDisplayForCurrentYear,
        });

        return chartDataToPush;
      });

      let chartFirstDataValue = clientValue;
      if (metricTotalValueEnabledUOM === "$") {
        chartFirstDataValue = 0;
      }
      chartData = _.concat([chartFirstDataValue], chartData);
    }

    const chartContext = lineChartRef.current.getContext("2d");

    // IF THE CHART WAS ALREADY RENDERED BEFORE THEN DESTROY IT
    if (!_.isNil(window.batTVEMetricBenchmarkChart)) {
      window.batTVEMetricBenchmarkChart.destroy();
    }

    // LINE CHART
    window.batTVEMetricBenchmarkChart = new Chart(chartContext, {
      type: "line",
      data: {
        labels: xAxisLabelsArr,
        datasets: [
          {
            data: chartData,
            pointRadius: [0, 6, 6, 6, 6, 6],
            spanGaps: true,
            label: "Client Value",
            backgroundColor: "rgba(161, 0, 255,0.1)",
            borderColor: "rgb(161, 0, 255)",
            lineTension: 0,
            fill: "start",
            pointHoverRadius: [0, 10, 10, 10, 10, 10],
            pointBorderColor: "#fff",
            pointBackgroundColor: "rgb(161, 0, 255)",
            pointBorderWidth: 2,
            datalabels: {
              align: "top",
              display: true,
              formatter: function (value, dataLabelModel) {
                // IF THE METRIC TVE UNIT OF MEASUREMENT IS "$" THEN FORMAT THE DATA LABELS PREFIXED WITH A DOLLAR SIGN
                return metricTotalValueEnabledUOM === "$" ? `$${numberFormatter(value, 2)}` : value;
              },
            },
          },
        ],
      },
      options: {
        annotation: {
          annotations: annotationToMap,
        },
        layout: {
          padding: {
            right: 30,
            top: 25,
          },
        },
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: false,
          position: "top",
        },
        tooltips: {
          mode: "nearest",
          intersect: true,
          enabled: true,
          // CUSTOM TOOLTIP TO USE HTML IN TOOLTIP BODY
          custom: function (tooltip) {
            if (!tooltip) {
              return;
            } else {
              let tooltipEl = document.getElementById("chartjs-tooltip");
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "chartjs-tooltip";
                tooltipEl.innerHTML = "<div class='tooltip-body'></div>";
                this._chart.canvas.parentNode.appendChild(tooltipEl);
              }
              // Hide if no tooltip
              if (tooltip.opacity === 0) {
                //tooltipEl.style.opacity = 0;
                tooltipEl.style.display = "none";
                return;
              }
              else{
                tooltipEl.style.display = "initial";
              }
              // Set caret Position
              tooltipEl.classList.remove("above", "below", "no-transform");
              if (tooltip.yAlign) {
                tooltipEl.classList.add(tooltip.yAlign);
              } else {
                tooltipEl.classList.add("no-transform");
              }

              // DON'T DISPLAY THE TOOLTIP FOR THE FIRST POINT
              if (
                tooltip.body &&
                !_.isEmpty(tooltip.dataPoints) &&
                _.get(tooltip, "dataPoints.0.index", "") !== 0
              ) {
                const dataPoint = tooltip.dataPoints[0];

                // HTML CONTENT FOR CUSTOM TOOLTIP
                const tooltipContent = `
                  <div class='tve-tooltip-content' id ="tveChart">
                  
                    <div class='tve-datalabel'>
                      <span>Opportunity: </span>
                      <span class='tve-datalabel-value'>
                        ${dataForTooltip[dataPoint.index - 1].opportunityValue}%
                      <span/>
                    </div>
                    <div class='tve-datalabel'>
                      <span>TVE: </span>
                      <span class='tve-datalabel-value'>
                        ${dataForTooltip[dataPoint.index - 1].TVEToShow}
                      <span/>
                    </div>
                    <div class='tve-datalabel'>
                      <span>Metric Value: </span>
                      <span class='tve-datalabel-value'>
                        ${dataForTooltip[dataPoint.index - 1].metricValue}${metricUnitFormatted}
                      <span/>
                    </div>
                  </div>
                `;

                const tableRoot = tooltipEl.querySelector(".tooltip-body");
                tableRoot.innerHTML = tooltipContent;
                const positionY = this._chart.canvas.offsetTop;
                const positionX = this._chart.canvas.offsetLeft;
                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = positionX + tooltip.caretX + "px";
                tooltipEl.style.top = positionY + tooltip.caretY + "px";
                tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
                tooltipEl.style.fontSize = tooltip.bodyFontSize + "px";
                tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
                tooltipEl.style.padding = tooltip.yPadding + "px " + tooltip.xPadding + "px";
              }
            }
          },
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        plugins: {
          filler: {
            propagate: false,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Contract Period",
                fontColor: "rgb(33, 43, 54)",
                fontSize: 12,
                padding: 10,
                fontFamily: "Graphik-Medium",
              },
              ticks: {
                fontColor: "rgb(99, 115, 129)",
                fontFamily: "Graphik-Medium",
                fontSize: 12,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: yAxisLabel,
                fontColor: "rgb(33, 43, 54)",
                fontSize: 12,
                padding: 10,
                fontFamily: "Graphik-Medium",
              },
              ticks: {
                fontColor: "rgb(99, 115, 129)",
                fontFamily: "Graphik-Medium",
                fontSize: 12,
                beginAtZero: false,
                callback: function (tickLabel, index, ticks) {
                  const tickLabelFormatted = numberFormatter(tickLabel, 2);
                  // IF THE METRIC TVE UNIT OF MEASUREMENT IS "$" THEN FORMAT THE DATA LABELS PREFIXED WITH A DOLLAR SIGN
                  return metricTotalValueEnabledUOM === "$"
                    ? `$${tickLabelFormatted}`
                    : tickLabelFormatted;
                },
              },
            },
          ],
        },
      },
    });
  }

  function calculateMetricOpportunity() {
    let dataToSetInState = metricOpportunityEmptyData;

    if (isValidValue(benchmarkValue) && isValidValue(clientValue)) {
      // CALCULATE IMPROVEMENT OPPORTUNITY VALUE
      const { opportunityValue, performanceIndicator } = calculateMetricImprovementOpportunity({
        metricLeadingPractice: leadingPractice,
        clientValue: clientValue,
        benchmarkValue: benchmarkValue,
      });

      dataToSetInState = {
        opportunityValue,
        performanceIndicator,
      };
    }
    setMetricOpportunityData(dataToSetInState);
  }

  function updateMetricTVE() {
    let dataToSetInState = metricTVEEmptyData;

    if (isValidValue(benchmarkValue) && isValidValue(clientValue)) {
      // CALCULATE TOTAL VALUE ENABLED (TVE)
      const {
        totalValueEnabled,
        totalValueEnabledFormatted,
        totalValueEnabledSuffixText,
        totalValueEnabledUOM,
      } = calculateMetricTVE({
        metricID: metricID,
        metricUnit: metricUnit,
        formulasForTVECalculation: formulasForTVECalculation,
        volumetricDetails: volumetricDetails,
        clientValue: clientValue,
        benchmarkValue: benchmarkValue,
      });

      dataToSetInState = {
        totalValueEnabled,
        totalValueEnabledFormatted,
        totalValueEnabledSuffixText,
        totalValueEnabledUOM,
      };
    }
    setMetricTVEData(dataToSetInState);
  }

  function handleBlurMetricBenchmarkValue(event) {
    const isValid = validateBATFieldValue(benchmarkValue);

    if (isValid && prevBenchmarkValue !== benchmarkValue) {
      setPrevMetricBenchmarkValue(benchmarkValue);
      calculateMetricOpportunity();
      updateMetricTVE();
    }
    setValidBenchmarkValue(isValid);
    isVisibleDeepDiveView && prepareYearWiseData();
  }

  function handleBlurClientValue(event) {
    const isValid = validateBATFieldValue(clientValue);

    if (isValid && prevClientValue !== clientValue) {
      setPrevClientValue(clientValue);
      calculateMetricOpportunity();
      updateMetricTVE();
    }
    setValidClientValue(isValid);
    isVisibleDeepDiveView && prepareYearWiseData();
  }

  // VALIDATES THE GIVEN VALUE FOR NON-EMPTY NUMERIC VALUE
  function isValidValue(val) {
    const isValid = !_.isEmpty(String(val)) && validateBATFieldValue(val);
    return isValid;
  }

  // VALIDATES CONTRACT PERIOD VALUE
  function isValidContractPeriodValue(val) {
    let isValid = isValidValue(val);
    if (isValid && (_.toNumber(val) < 1 || _.toNumber(val) > 5)) {
      isValid = false;
    }
    return isValid;
  }

  function handleBlurPercentImprovementEachYear(event) {
    const isValid = validateBATFieldValue(percentImprovementEachYearValue);

    if (!isValid) {
      event.preventDefault();
      window.alert(errMsgInvalidNumericValue);
    } else {
      prepareYearWiseData();
    }
  }

  function handleChangeYoYInputField(fieldName, fieldValue, yearIndex) {
    let fieldValueToSet = String(fieldValue);
    if (_.isEmpty(fieldValueToSet)) {
      fieldValueToSet = null;
    }

    const clonedTempYearWiseData = _.cloneDeep(tempRawYearWiseData);
    if (fieldName === "initialValue") {
      _.set(clonedTempYearWiseData, `${yearIndex - 1}.Value`, fieldValueToSet);
    } else {
      _.set(clonedTempYearWiseData, `${yearIndex}.Value`, fieldValueToSet);
    }
    setTempRawYearWiseData(clonedTempYearWiseData);
  }

  function handleBlurYoYInputField(fieldName, fieldValue, yearIndex) {
    const isValid = validateBATFieldValue(fieldValue);

    if (!isValid) {
      window.alert(errMsgInvalidNumericValue);
    }
  }

  function handleClickCancelDeepDiveView() {
    setVisibleDeepDiveView(false);
    setTempRawYearWiseData(_.cloneDeep(rawYearWiseData));
  }

  function handleCloseModal() {
    let dataToSend = {
      haveValuesChanged: haveValuesChanged,
      metricID: metricID,
      userEnteredBenchmarkValue: prevBenchmarkValue,
      metricValue: prevClientValue,
      contractPeriod: prevContractPeriod,
    };
    onClose(dataToSend);
  }

  // CHECK IF THE CURRENT USER HAS THE NECESSARY PERMISSION FOR THE ASSESSMENT
  const isAccessDisabled =
    sharedAccessDetail.filter(
      (user) => user.UserName === sessionStorage.getItem("EnterpriseID") && user.AccessLevelID === 1
    ).length !== 0;

  // PREPARE THE VALUES OF "OPPORTUNITY" AND "TVE" FOR DISPLAY
  let opportunityValueForDisplay = "-";
  let tveForDisplay = "-";

  opportunityValueForDisplay =
    metricOpportunityData.opportunityValue < 0 ||
    _.isEmpty(String(metricOpportunityData.opportunityValue))
      ? "-"
      : metricOpportunityData.opportunityValue;

  if (
    !_.isEmpty(String(metricTVEData.totalValueEnabled)) &&
    metricOpportunityData.performanceIndicator !== "better"
  ) {
    tveForDisplay = metricTVEData.totalValueEnabledFormatted;

    // IF THE TVE UNIT OF MEASUREMENT IS "$" THEN PREFIX THE TEXT WITH A DOLLAR SIGN
    if (metricTVEData.totalValueEnabledUOM === "$") {
      tveForDisplay = `$${tveForDisplay}`;
    }
    else if (metricTVEData.totalValueEnabledUOM === "%"){
      tveForDisplay = `${tveForDisplay}%`;
    }

  }

  return (
    <>
      {/* IF API IS LOADING THEN SHOW THE LOADER */}
      {isAPILoading && <LoadingOverlay spinner={<SynopsLoader />} active />}

      <Modal
        show
        centered
        onHide={() => handleCloseModal()}
        backdrop="static"
        dialogClassName="modal-90w"
        className="totalValueEnabledkModal"
      >
        <Modal.Header closeButton>
          <span className="header-modal">Total Value Enabled for {metricName}</span>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div className="row">
              {/* START - LEFT SECTION */}
              <div className="col-lg-3 totalValueEnable-fieldSection">
                <div className="opportunity-section mb-4">
                  <div className="fieldSection-title">Opportunity</div>
                  <div className={classNames("sales-text")}>
                    {opportunityValueForDisplay}
                    {opportunityValueForDisplay === "-" ? <span>&nbsp;%</span> : <span>%</span>}
                  </div>
                </div>

                <div className="tve-value-section mb-4">
                  <div className="fieldSection-title">Total Value Enabled</div>
                  
                  <div className="sales-text">{tveForDisplay}</div>
                  {tveForDisplay !== "-" && (
                    <span className="revenue-text">
                      {metricTVEData.totalValueEnabledSuffixText}
                    </span>
                  )}
                </div>

                <div className="topQuartile-section mb-4">
                  <div className="fieldSection-title">
                    {_.toLower(benchmarkName) === "lower quartile"
                      ? "Bottom Quartile"
                      : benchmarkName}{" "}
                    Benchmark
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Benchmark Value"
                      value={benchmarkValue}
                      onChange={(e) => setMetricBenchmarkValue(e.target.value)}
                      onBlur={(e) => handleBlurMetricBenchmarkValue(e)}
                      className={classNames(
                        "value-box-tve",
                        { disabled: isAccessDisabled },
                        { "active-box": !isValidBenchmarkValue }
                      )}
                      disabled={isAccessDisabled}
                      maxLength="15"
                      ref={benchmarkTextBoxRef}
                    />
                    <span>{metricUnitFormatted}</span>
                    {!isValidBenchmarkValue && (
                      <div className="invalidMsg">
                        Invalid input. Only numeric values are allowed.
                      </div>
                    )}
                  </div>
                </div>

                <div className="clientValue-section mb-4">
                  <div className="fieldSection-title">Client Value</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Client Value"
                      value={clientValue}
                      onChange={(e) => setClientValue(e.target.value)}
                      onBlur={(e) => handleBlurClientValue(e)}
                      className={classNames(
                        "value-box-tve",
                        { disabled: isAccessDisabled },
                        { "active-box": !isValidClientValue }
                      )}
                      disabled={isAccessDisabled}
                      maxLength="15"
                    />
                    <span>{metricUnitFormatted}</span>
                    {!isValidClientValue && (
                      <div className="invalidMsg">
                        Invalid input. Only numeric values are allowed.
                      </div>
                    )}
                  </div>
                </div>

                <div className="contractPeriod-section">
                  <p>
                    Contract Period:&nbsp;
                    <span>{contractPeriod}</span> Years
                  </p>
                  <div></div>
                </div>
              </div>
              {/* END - LEFT SECTION */}

              {/* START - RIGHT SECTION */}
              <div className="col-lg-9">
                {!isVisibleDeepDiveView ? (
                  <div className="deepDive-btn-wrap">
                    <div
                      className={classNames("deepDive-btn", {
                        disabled: opportunityValueForDisplay === "-" || isAccessDisabled,
                      })}
                      onClick={() => {
                        if (opportunityValueForDisplay !== "-" || isAccessDisabled) {
                          setVisibleDeepDiveView(true);
                        }
                      }}
                    >
                      <span>Scenario Planner</span>
                    </div>
                  </div>
                ) : (
                  <div className="tve-container-header">
                    <div className="tve-header-title">Year On Year Valuation</div>
                    <div className="tve-container-btns">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => handleClickCancelDeepDiveView()}
                      >
                        Cancel
                      </button>

                      <button
                        type="button"
                        className={classNames("save-btn", {
                          disabled: _.isEmpty(calculateYoYDataBy),
                        })}
                        onClick={() => executeAPISaveYoYData()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}

                <div className="tve-canvas-container">
                  <div className={classNames({ "deep-dive-view": isVisibleDeepDiveView })}>
                    {/* GRAPH SECTION */}
                    <div
                      className="tve-graph-container"
                      style={isVisibleDeepDiveView ? { display: "none" } : {}}
                    >
                      <div className="position-relative">
                        <canvas
                          id="chart"
                          width="400"
                          height="200"
                          ref={lineChartRef}
                          className="mt-4 mb-5"
                          
                        />
                      </div>
                    </div>

                    {/* START - SECTION DEEP DIVE VIEW */}
                    {isVisibleDeepDiveView && (
                      <div>
                        {/* START - SECTION IMPROVEMENT PERCENTAGE */}
                        <div>
                          <div className="valuation-year-title">
                            <label className="form-check">
                              {" "}
                              <input
                                type="radio"
                                name="calculateYoYDataBy"
                                className="form-check-input"
                                checked={calculateYoYDataBy === "percentage"}
                                onChange={() => setCalculateYoYDataBy("percentage")}
                              />
                              <span className="checkmark" />
                              <span className="form-check-label">Improvement Each Year</span>
                            </label>
                          </div>

                          <div
                            className={classNames("each-year-section", {
                              disabled: calculateYoYDataBy !== "percentage",
                            })}
                          >
                            <input
                              type="text"
                              value={percentImprovementEachYearValue}
                              onChange={(e) => setPercentImprovementEachYearValue(e.target.value)}
                              onBlur={(e) => handleBlurPercentImprovementEachYear(e)}
                              disabled={calculateYoYDataBy !== "percentage"}
                              maxLength="15"
                            />
                            <span>% each year</span>
                          </div>
                        </div>
                        {/* END - SECTION IMPROVEMENT PERCENTAGE */}

                        {/* START - SECTION YoY DATA */}
                        <div>
                          <div className="valuation-year-title">
                            <label className="form-check">
                              {" "}
                              <input
                                type="radio"
                                name="calculateYoYDataBy"
                                className="form-check-input"
                                checked={calculateYoYDataBy === "manualValueInput"}
                                onChange={() => setCalculateYoYDataBy("manualValueInput")}
                              />
                              <span className="checkmark" />
                              <span className="form-check-label">
                                Year Wise Improvement (Metric Value)
                              </span>
                            </label>
                          </div>

                          <div
                            className={classNames("row", "yearwise-value-section", {
                              disabled: calculateYoYDataBy !== "manualValueInput",
                            })}
                          >
                            {/* LOOP OVER YEAR WISE DATA ARRAY */}
                            {_.map(yearWiseDataArr, (currentYearData, currentYearIndex) => {
                              let areAllNecessaryValuesValid = true;
                              if (
                                !isValidValue(benchmarkValue) ||
                                !isValidValue(clientValue) ||
                                !isValidContractPeriodValue(contractPeriod)
                              ) {
                                areAllNecessaryValuesValid = false;
                              }

                              const initialValue = areAllNecessaryValuesValid
                                ? currentYearData.initialValue
                                : "-";
                              const finalValue = areAllNecessaryValuesValid
                                ? currentYearData.finalValue
                                : "-";
                              const tveForDisplayYoY = areAllNecessaryValuesValid
                                ? currentYearData.tveForDisplay
                                : "-";

                              // DISABLE THE "Initial Value" INPUT OF THE FIRST YoY BECAUSE IT WILL ALWAYS BE SET TO THE "CLIENT VALUE"
                              const isDisabledInitialValueInput =
                                currentYearIndex === 0 || !areAllNecessaryValuesValid;

                              // DISABLE THE "Final Value" INPUT OF THE LAST YoY BECAUSE IT WILL ALWAYS BE SET TO THE "BENCHMARK VALUE"
                              const isDisabledFinalValueInput =
                                currentYearIndex === _.toNumber(contractPeriod) - 1 ||
                                !areAllNecessaryValuesValid;

                              return (
                                <div className="tve-metric-box-section" key={currentYearIndex}>
                                  <label className="year-label">
                                    {currentYearData.yearDisplayLabel}
                                  </label>

                                  <div className="value-box-wrap">
                                    <div
                                      className={classNames("tve-metric-value", {
                                        disabled:
                                          isDisabledInitialValueInput ||
                                          calculateYoYDataBy !== "manualValueInput",
                                      })}
                                    >
                                      <div className="tve-form-label">
                                        <label className="form-label">Initial Value</label>
                                      </div>
                                      <div>
                                        <input
                                          type="text"
                                          value={
                                            _.isNil(initialValue) || _.isEmpty(String(initialValue)) ? "" : initialValue
                                          }
                                          onChange={(e) =>
                                            handleChangeYoYInputField("initialValue", e.target.value, currentYearIndex)
                                          }
                                          onBlur={(e) =>
                                            handleBlurYoYInputField("initialValue", e.target.value, currentYearIndex)
                                          }
                                          disabled={
                                            isDisabledInitialValueInput || calculateYoYDataBy !== "manualValueInput"
                                          }
                                          className="value-box-yearwise"
                                          maxLength="15"
                                          data-tip
                                          data-for={"tooltip" + initialValue}
                                        />
                                         {initialValue && initialValue.length > 7 ? (
                                          <ReactTooltip
                                            place="bottom"
                                            effect="solid"
                                            id={"tooltip" + initialValue}
                                          >
                                            <span style={{ color: "white" }}>{initialValue}</span>
                                          </ReactTooltip>
                                        ) : null }
                                      </div>                                   
                                    </div>

                                    <div
                                      className={classNames("tve-metric-value", {
                                        disabled:
                                          isDisabledFinalValueInput ||
                                          calculateYoYDataBy !== "manualValueInput",
                                      })}
                                    >
                                      <div className="tve-form-label">
                                        <label className="form-label">Final Value</label>
                                      </div>
                                      <div>
                                        <input
                                          type="text"
                                          value={
                                            _.isNil(finalValue) || _.isEmpty(String(finalValue))
                                              ? ""
                                              : finalValue
                                          }
                                          onChange={(e) =>
                                            handleChangeYoYInputField(
                                              "finalValue",
                                              e.target.value,
                                              currentYearIndex
                                            )
                                          }
                                          onBlur={(e) =>
                                            handleBlurYoYInputField(
                                              "finalValue",
                                              e.target.value,
                                              currentYearIndex
                                            )
                                          }
                                          disabled={
                                            isDisabledFinalValueInput ||
                                            calculateYoYDataBy !== "manualValueInput"
                                          }
                                          className="value-box-yearwise"
                                          maxLength="15"
                                          data-tip
                                          data-for={"tooltip" + finalValue}
                                          tabIndex={currentYearIndex + 1}
                                        />

                                        {finalValue && finalValue.length > 7 ? (
                                          <ReactTooltip
                                            place="bottom"
                                            effect="solid"
                                            id={"tooltip" + finalValue}
                                          >
                                            <span style={{ color: "white" }}>{finalValue}</span>
                                          </ReactTooltip>
                                        ): null}
                                      </div>
                                    </div>

                                    <div className="tve-enabled-box">
                                      <div className="text-label">Total Value Enabled</div>

                                      <div className="tve-enabled-text">
                                        {tveForDisplayYoY !== "-" && (
                                          <img src={Caret} alt="Caret" />
                                        )}

                                        <span data-tip data-for={"tooltip" + tveForDisplayYoY}>
                                          {tveForDisplayYoY.length <= 7
                                            ? tveForDisplayYoY
                                            : tveForDisplayYoY.slice(0, 7) + "..."}

                                          {tveForDisplayYoY.length > 7 && (
                                            <ReactTooltip
                                              place="bottom"
                                              effect="solid"
                                              id={"tooltip" + tveForDisplayYoY}
                                            >
                                              <span style={{ color: "white" }}>
                                                {tveForDisplayYoY}
                                              </span>
                                            </ReactTooltip>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* END - SECTION YoY DATA */}
                      </div>
                    )}
                    {/* END - SECTION DEEP DIVE VIEW */}
                  </div>
                </div>
              </div>
              {/* END - RIGHT SECTION */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TotalValueEnabledModal;
