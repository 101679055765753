import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";
import i18n from "i18next";
import { Multiselect } from "multiselect-react-dropdown";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Label,
  ResponsiveContainer,
  LabelList,
  CartesianGrid,
} from "recharts";

const pieColor = ["#9c6ade", "#018eff", "#bd3aff"];

const pieColor2 = ["#9c6ade", "#bd3aff", "#018eff", "#018eff"];

const pieColor3 = ["#01bbee", "#fe9b00", "#8e6635", "#548235", "#7030a0"];

const RADIAN = Math.PI / 180;

const tempYOYCVD = {};

class CustomTooltip extends Component {
  constructor() {
    super();
    this.state = {
      data: {
        label: "",
        fill: "",
      },
      bar3Data: [],
    };
  }

  componentWillReceiveProps = (nextProps) => {
    let tempLabel = "";
    let tempFill = "";
    let tempData = [];
    if (nextProps.active && nextProps.payload!=undefined) {
      if (nextProps.origin == "bar 1") {
        tempLabel = nextProps.payload[0].payload.label;
        tempFill = nextProps.payload[0].fill;
      } else if (nextProps.origin == "bar 2") {
        //
        tempLabel = nextProps.payload[0].payload.name;
        tempFill = nextProps.payload[0].fill;
      } else if (nextProps.origin == "bar 3") {
        tempData = nextProps.payload;
        tempLabel = nextProps.label;
      } else {
        //pie
        tempLabel = nextProps.payload[0].name;
        tempFill = nextProps.payload[0].payload.fill;
      }

      this.setState({
        data: {
          ...this.state.data,
          label: tempLabel,
          fill: tempFill,
        },
        bar3Data: tempData,
      });
    }
  };

  render() {
    if (this.props.active && this.props.payload!=undefined) {
      return (
        <div
          className={
            this.props.origin == "bar 3" ? "custom-tooltip-main-container cust-bar3" : "custom-tooltip-main-container"
          }
        >
          {this.props.origin == "bar 3" ? (
            <div>
              <div className="custom-tooltip-lbl-container">
                <div className="custom-tooltip-label-bar3">{this.state.data.label}</div>
              </div>
              <div className="custom-tooltip-val-container-bar3">
                {this.state.bar3Data.map((item, index) => {
                  return (
                    <Row lg={1} md={1} sm={1} xs={1} className="tool-tip-data">
                      <div className="custom-tooltip-bar3-subcontainer">
                        <div className="custom-tooltip-box3" style={{ backgroundColor: item.fill }} />
                        <div className="custom-tooltip-label">{item.name}</div>
                        <div className="custom-tooltip-label">${parseFloat(item.value).toFixed(1)}</div>
                      </div>
                    </Row>
                  );
                })}
              </div>
            </div>
          ) : (
            <div>
              <div className="custom-tooltip-lbl-container">
                <div className="custom-tooltip-box" style={{ backgroundColor: this.state.data.fill }} />
                <div className="custom-tooltip-label">{this.state.data.label}</div>
              </div>
              <div className="custom-tooltip-val-container">
                {this.props.payload.map((item, index) => {
                  return (
                    <Row lg={1} md={1} sm={1} xs={1} className="tool-tip-data">
                      <div>
                        <div className="custom-tooltip-label">${parseFloat(item.value).toFixed(1)}</div>
                      </div>
                    </Row>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

class ValueRealizationGraphs extends Component {
  constructor() {
    super();
    this.state = {
      boiBarHeight: "",
      byDeliveryLocCBVSettings: {
        ticks: 5,
        maxVal: 0,
      },
      byDeliveryLocNPSettings: {
        ticks: 5,
        maxVal: 0,
      },
      byDeliveryLocNCSettings: {
        ticks: 5,
        maxVal: 0,
      },
      byClientSettings: {
        ticks: 5,
        maxVal: 0,
      },
      byOfferingSettings: {
        ticks: 5,
        maxVal: 0,
      },
      byOutcomeSettings: {
        ticks: 5,
        maxVal: 0,
      },
      businessOutcomePnL: [
        {
          value: 0,
          name: "",
        },
      ],
      businessValueNav: [
        {
          value: 0,
          name: "",
        },
      ],
      businessOutcomesSDO: [
        {
          value: 0,
          name: "",
        },
      ],
      yoyTrendPnL: [
        {
          name: "",
          northAmerica: 0,
          europe: 0,
          growthMarket: 0,
          value: "",
          yoyTotal: 0,
        },
      ],
      yoyTrendBusinessValue: [
        {
          name: "",
          marketUnits: [
            {
              MarketUnitName: "",
              MarketUnitValue: 0,
            },
          ],
          yoyTotal: 0,
        },
      ],
      offeringPnL: [
        {
          value: 0,
          name: "",
          offeringTotal: 0,
        },
      ],
      businessOutcomesInd: [
        {
          value: 0,
          name: "",
          boiTotal: 0,
        },
      ],
      clientPnL: [
        {
          value: 0,
          name: "",
          clientTotal: 0,
        },
      ],
      keyDiffMetrics: {
        annualized: [],
        numberofProject: [],
        clientCount: [],
      },
      businessOutcomePnLSum: 0,
      businessValueNavSum: 0,
      businessOutcomesSDOSum: 0,
      yoyTrendPnLSum: 0,
      yoyTrendBusinessValueSum: 0,
      offeringPnLSum: 0,
      businessOutcomesIndSum: 0,
      clientPnLSum: 0,
      keyDiffMetricsSum: 0,
      yoyCVDconvertedData: [],
      yoyCVDlabels: [],
      yoyVICconvertedData: [],
      yoyVIClabels: [],
    };
  }

  componentDidMount() {
    let dataset = this.props.dataset;
    this.getClientValueDelivered(dataset.ClientValueDelivereds);
    this.getClientValueDeliveredByMarkets(dataset.ClientValueDeliveredByMarkets);
    this.getValueImpactingClient(dataset.ValueImpactingClient);
    this.getYoyClientValueDelivered(dataset.YOYClientValueDelivered);
    this.getYoyValueImpactingClient(dataset.YOYValueImpactingClient);
    this.getByOutcomeLever(dataset.ByOutcomeLeversData);
    this.getByDeliveryLocation(dataset.ByDeliveryLocationData);
  }

  componentWillReceiveProps(nextProps) {
    let dataset = nextProps.dataset;
    this.getClientValueDelivered(dataset.ClientValueDelivereds);
    this.getClientValueDeliveredByMarkets(dataset.ClientValueDeliveredByMarkets);
    this.getValueImpactingClient(dataset.ValueImpactingClient);
    this.getYoyClientValueDelivered(dataset.YOYClientValueDelivered);
    this.getYoyValueImpactingClient(dataset.YOYValueImpactingClient);
    this.getByOutcomeLever(dataset.ByOutcomeLeversData);
    this.getByDeliveryLocation(dataset.ByDeliveryLocationData);
  }

  getClientValueDelivered = (data) => {
    let list = [];
    let finList = [];
    if (data !== null) {
      list = data
        .filter((x) => x.BusinessValueNavMnTotal !== 0)
        .map((obj) => {
          return {
            value: this.convertToParseFloat(obj.BusinessValueNavMnTotal),
            name: obj.BusinessValueTypeCode.toLowerCase(),
          };
        });
      finList = this.fixDashboardDataPosition("donut a", list);

      let total =
        finList.length !== 0
          ? finList
              .map((obj) => {
                return obj.value;
              })
              .reduce((total, n) => {
                return total + n;
              })
              .toFixed(1)
          : 0;

      this.setState({
        businessValueNav: finList,
        businessValueNavSum: "$" + total,
      });
    } else {
      this.setState({
        businessValueNav: [],
      });
    }
  };

  getClientValueDeliveredByMarkets = (data) => {
    let list = [];
    let finList = [];
    if (data !== null) {
      list = data
        .filter((x) => x.Total !== 0)
        .map((obj) => {
          return {
            value: this.convertToParseFloat(obj.Total),
            name: obj.GeoAreaCode.toLowerCase(),
          };
        });

      finList = this.fixDashboardDataPosition("donut b", list);

      let total =
        finList.length !== 0
          ? finList
              .map((obj) => {
                return obj.value;
              })
              .reduce((total, n) => {
                return total + n;
              })
              .toFixed(1)
          : 0;

      this.setState({
        businessOutcomesSDO: finList,
        businessOutcomesSDOSum: "$" + total,
      });
    } else {
      this.setState({
        businessOutcomesSDO: [],
      });
    }
  };

  getValueImpactingClient = (data) => {
    let list = [];
    let finList = [];

    if (data !== null) {
      list = data
        .filter((x) => x.Total !== 0)
        .map((obj) => {
          return {
            value: this.convertToParseFloat(obj.Total),
            name: obj.BusinessValueTypeCode.toLowerCase(),
          };
        });

      finList = this.fixDashboardDataPosition("donut a", list);
      let total =
        finList.length !== 0
          ? finList
              .map((obj) => {
                return obj.value;
              })
              .reduce((total, n) => {
                return total + n;
              })
              .toFixed(1)
          : 0;

      this.setState({
        businessOutcomePnL: finList,
        businessOutcomePnLSum: "$" + total,
      });
    } else {
      this.setState({
        businessOutcomePnL: [],
      });
    }
  };

  convertData(data) {
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let obj = { FiscalYear: data[i].FiscalYear, Total: "$" + data[i].Total.toFixed(1) };
      // loop throgh comodities
      for (let j = 0; j < data[i].MarketUnit.length; j++) {
        let commodity = data[i].MarketUnit[j];
        obj[commodity.MarketUnitName] = commodity.MarketUnitValue.toFixed(1);
      }
      arr.push(obj);
    }
    return arr;
  }

  getLabels(data) {
    let arr = [];
    _.each(data, (obj) => {
      arr = arr.concat(obj.MarketUnit);
    });
    let grouped = _.groupBy(arr, "MarketUnitName");
    return Object.keys(grouped);
  }

  getYoyClientValueDelivered = (data) => {
    if (data !== null) {
      var convertedData = this.convertData(data);
      var labels = this.getLabels(data);
      this.setState({
        yoyCVDconvertedData: convertedData,
        yoyCVDlabels: labels,
      });
    } else {
      this.setState({
        yoyCVDconvertedData: [],
      });
    }
  };

  getYoyValueImpactingClient = (data) => {
    if (data !== null) {
      var convertedData = this.convertData(data);
      var labels = this.getLabels(data);
      this.setState({
        yoyVICconvertedData: convertedData,
        yoyVIClabels: labels,
      });
    } else {
      this.setState({
        yoyVICconvertedData: [],
      });
    }
  };

  getByOffering = (data) => {
    let list = [];
    let maxValArr = [];
    let maxVal = 0;
    let ticksArr = [];
    let testData = 0;
    if (data !== null) {
      for (var counter = 0; counter < data.length; counter++) {
        var item = data[counter];
        list.push({
          value: item.Total.toFixed(1),
          name: item.Description,
          offeringTotal: "$" + item.Total.toFixed(1),
        });
        maxValArr = maxValArr.concat(parseFloat(item.Total.toFixed(1)));
      }
      maxVal = Math.max(...maxValArr);
      for (var i = 0; i <= Math.round(maxVal / 500) + 1; i++) {
        ticksArr.push(testData);
        testData = testData + 500;
      }

      this.setState({
        offeringPnL: list,
        byOfferingSettings: {
          ...this.state.byOfferingSettings,
          ticks: ticksArr,
          maxVal: testData - 500,
        },
      });
    } else {
      this.setState({
        offeringPnL: [],
      });
    }
  };

  getByOutcomeLever = (data) => {
    let list = [];
    let maxValArr = [];
    let maxVal = 0;
    let ticksArr = [];
    let testData = 0;
    if (data !== null) {
      for (var counter = 0; counter < data.length; counter++) {
        var item = data[counter];
        list.push({
          value: item.Total.toFixed(1),
          name: item.BusinessOutcomesIndicator,
          boiTotal: "$" + item.Total.toFixed(1),
        });
        maxValArr = maxValArr.concat(parseFloat(item.Total.toFixed(1)));
      }
      maxVal = Math.max(...maxValArr);
      for (var i = 0; i <= Math.round(maxVal / 500) + 1; i++) {
        ticksArr.push(testData);
        testData = testData + 500;
      }
      this.setState({
        businessOutcomesInd: list,
        boiBarHeight: data.length,
        byOutcomeSettings: {
          ...this.state.byOutcomeSettings,
          ticks: ticksArr,
          maxVal: testData - 500,
        },
      });
    } else {
      this.setState({
        businessOutcomesInd: [],
      });
    }
  };

  getByClient = (data) => {
    let list = [];
    let maxValArr = [];
    let maxVal = 0;
    let ticksArr = [];
    let testData = 0;
    if (data !== null) {
      for (var counter = 0; counter < data.length; counter++) {
        var item = data[counter];
        list.push({
          value: item.Total.toFixed(1),
          name: item.Client,
          clientTotal: "$" + item.Total.toFixed(1),
        });
        maxValArr = maxValArr.concat(parseFloat(item.Total.toFixed(1)));
      }
      maxVal = Math.max(...maxValArr);
      for (var i = 0; i <= Math.round(maxVal / 500) + 1; i++) {
        ticksArr.push(testData);
        testData = testData + 500;
      }

      this.setState({
        clientPnL: list,
        byClientSettings: {
          ...this.state.byClientSettings,
          ticks: ticksArr,
          maxVal: testData - 500,
        },
      });
    } else {
      this.setState({
        clientPnL: [],
      });
    }
  };

  getByDeliveryLocation = (data) => {
    let annualized = [];
    let numberofProject = [];
    let clientCount = [];

    let maxValAnnArr = [];
    let maxValAnn = 0;
    let ticksArrAnn = [];
    let testDataAnn = 0;

    let maxValNPArr = [];
    let maxValNP = 0;
    let ticksArrNP = [];
    let testDataNP = 0;

    let maxValCCArr = [];
    let maxValCC = 0;
    let ticksArrCC = [];
    let testDataCC = 0;

    if (data !== null) {
      data.forEach(function(obj) {
        annualized.push({
          value: obj.Annualized.toFixed(1),
          label: obj.Country,
          total: "$" + obj.Annualized.toFixed(1),
        });
        maxValAnnArr = maxValAnnArr.concat(parseFloat(obj.Annualized.toFixed(1)));
      });
      maxValAnn = Math.max(...maxValAnnArr);
      for (var i = 0; i <= Math.round(maxValAnn / 500) + 1; i++) {
        ticksArrAnn.push(testDataAnn);
        testDataAnn = testDataAnn + 500;
      }

      data.forEach(function(obj) {
        numberofProject.push({
          value: obj.NumberofProject,
          label: obj.Country,
        });
        maxValNPArr = maxValNPArr.concat(parseFloat(obj.NumberofProject));
      });
      maxValNP = Math.max(...maxValNPArr);
      for (var i = 0; i <= Math.round(maxValNP / 100) + 1; i++) {
        ticksArrNP.push(testDataNP);
        testDataNP = testDataNP + 100;
      }

      data.forEach(function(obj) {
        clientCount.push({
          value: obj.ClientCount,
          label: obj.Country,
        });
        maxValCCArr = maxValCCArr.concat(parseFloat(obj.ClientCount));
      });
      maxValCC = Math.max(...maxValCCArr);
      for (var i = 0; i <= Math.round(maxValCC / 20) + 1; i++) {
        ticksArrCC.push(testDataCC);
        testDataCC = testDataCC + 20;
      }

      let keyDiffMetrics = {
        annualized: annualized,
        numberofProject: numberofProject,
        clientCount: clientCount,
      };
      this.setState({
        keyDiffMetrics: keyDiffMetrics,
        byDeliveryLocCBVSettings: {
          ...this.state.byDeliveryLocCBVSettings,
          ticks: ticksArrAnn,
          maxVal: testDataAnn - 500,
        },
        byDeliveryLocNPSettings: {
          ...this.state.byDeliveryLocNPSettings,
          ticks: ticksArrNP,
          maxVal: testDataNP - 100,
        },
        byDeliveryLocNCSettings: {
          ...this.state.byDeliveryLocNCSettings,
          ticks: ticksArrCC,
          maxVal: testDataCC - 20,
        },
      });
    } else {
      this.setState({
        keyDiffMetrics: {
          annualized: [],
          numberofProject: [],
          clientCount: [],
        },
      });
    }
  };

  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x1 = cx + radius * Math.cos(-midAngle * RADIAN);
    const y1 = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x1} y={y1} className="customLabel" textAnchor={x1 > cx ? "start" : "end"} dominantBaseline="central">
        {"$" + value.toFixed(1)}
      </text>
    );
  };

  convertToParseFloat = (value) => {
    let tempValue = (value / 1).toFixed(1) == "0.0" ? value / 1 : (value / 1).toFixed(1);
    tempValue = parseFloat(tempValue);
    return tempValue;
  };

  fixDashboardDataPosition = (origin, list) => {
    let finList = [];
    switch (origin) {
      case "donut a":
        if (list.findIndex((x) => x.name == "cash optimization") != -1) {
          finList.push({
            value: list[list.findIndex((x) => x.name == "cash optimization")].value,
            name: list[list.findIndex((x) => x.name == "cash optimization")].name,
          });
        }
        if (list.findIndex((x) => x.name == "top line growth") != -1) {
          finList.push({
            value: list[list.findIndex((x) => x.name == "top line growth")].value,
            name: list[list.findIndex((x) => x.name == "top line growth")].name,
          });
        }
        if (list.findIndex((x) => x.name == "operating cost optimization") != -1) {
          finList.push({
            value: list[list.findIndex((x) => x.name == "operating cost optimization")].value,
            name: list[list.findIndex((x) => x.name == "operating cost optimization")].name,
          });
        }
        return finList;
      case "donut b":
        if (list.findIndex((x) => x.name == "north america") != -1) {
          finList.push({
            value: list[list.findIndex((x) => x.name == "north america")].value,
            name: list[list.findIndex((x) => x.name == "north america")].name,
          });
        }
        if (list.findIndex((x) => x.name == "europe") != -1) {
          finList.push({
            value: list[list.findIndex((x) => x.name == "europe")].value,
            name: list[list.findIndex((x) => x.name == "europe")].name,
          });
        }
        if (list.findIndex((x) => x.name == "growth markets") != -1) {
          finList.push({
            value: list[list.findIndex((x) => x.name == "growth markets")].value,
            name: list[list.findIndex((x) => x.name == "growth markets")].name,
          });
        }
        return finList;
    }
  };

  collapseChart = (chartName) => {

    let tempByOffering = false;
    let tempByOutcome = false;
    let tempByClient = false;
    let tempByDeliveryLoc = false;

    switch (chartName) {
      case "byOffering":
        tempByOffering = !(this.state == undefined ? this.collapsible.byOffering : this.state.collapsible.byOffering);
        break;
      case "byOutcome":
        tempByOutcome = !(this.state == undefined ? this.collapsible.byOutcome : this.state.collapsible.byOutcome);
        break;
      case "byClient":
        tempByClient = !(this.state == undefined ? this.collapsible.byClient : this.state.collapsible.byClient);
        break;
      case "byDeliveryLoc":
        tempByDeliveryLoc = !(this.state == undefined
          ? this.collapsible.byDeliveryLoc
          : this.state.collapsible.byDeliveryLoc);
        break;
    }
    this.setState({
      collapsible: {
        ...this.state.collapsible,
        byOffering: tempByOffering,
        byOutcome: tempByOutcome,
        byClient: tempByClient,
        byDeliveryLoc: tempByDeliveryLoc,
      },
    });
  };

  render() {
    const { isVisibleDatepickerDropdownPopover, startDate } = this.state;
    const yearOptions = [
      { label: "2012", value: "2012" },
      { label: "2013", value: "2013" },
      { label: "2014", value: "2014" },
      { label: "2015", value: "2015" },
      { label: "2016", value: "2016" },
      { label: "2017", value: "2017" },
      { label: "2018", value: "2018" },
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
    ];
    const multiselectYearPicker = (
      <React.Fragment>
        <Multiselect
          options={yearOptions}
          isObject={true}
          displayValue="label"
          showCheckbox={true}
          closeOnSelect={false}
          selectAll={true}
          showSelectAll={true}
        />
      </React.Fragment>
    );
    return (
      <>
        <div className="valueRealization-container">
          {/* Donut Charts */}
          <div className="db-donuts-main-container">
            <Row className="db-graphs-container">
              <Col md={4} sm={4} className="db-sub-container cvd">
                <div className="db-header-container">
                  <label className="db-header-label">{i18n.t("Client_Value_Delivered_$Mn")}</label>
                </div>
                <div className="">
                  <div className="bo-dashboard-donut-first-ul">
                    <ResponsiveContainer height={400} width="100%">
                      {this.state.businessValueNav.length !== 0 ? (
                        <PieChart margin={{ top: 10, right: 0, left: 0, bottom: 90 }}>
                          <Pie
                            data={this.state.businessValueNav}
                            dataKey="value"
                            innerRadius={60}
                            outerRadius={90}
                            fill="#82ca9d"
                            label={this.renderCustomizedLabel}
                            isAnimationActive={false}
                            labelLine={false}
                            minAngle={7}
                          >
                            {this.state.businessValueNav.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  entry.name == "cash optimization"
                                    ? pieColor[0]
                                    : entry.name == "operating cost optimization"
                                    ? pieColor[2]
                                    : pieColor[1]
                                }
                              />
                            ))}
                            <Label
                              className="db-donut-label"
                              value={this.state.businessValueNavSum}
                              position="center"
                            />
                          </Pie>
                          <Tooltip
                            content={<CustomTooltip origin={"pie"} />}
                            offset={0}
                            allowEscapeViewBox={{ x: false, y: false }}
                          />
                          <Legend verticalAlign="bottom" iconSize={12} iconType="square" />
                        </PieChart>
                      ) : (
                        <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                      )}
                    </ResponsiveContainer>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={4} className="db-sub-container cvd-markets">
                <div className="db-header-container">
                  <label className="db-header-label">{i18n.t("Client_Value_Delivered_by_Markets_$Mn")}</label>
                </div>
                <div className="">
                  <div className="bo-dashboard-donut-first-ul">
                    <ResponsiveContainer height={400} width="100%">
                      {this.state.businessOutcomesSDO.length !== 0 ? (
                        <PieChart margin={{ top: 10, right: 0, left: 0, bottom: 90 }}>
                          <Pie
                            data={this.state.businessOutcomesSDO}
                            dataKey="value"
                            innerRadius={60}
                            outerRadius={90}
                            fill="#82ca9d"
                            label={this.renderCustomizedLabel}
                            isAnimationActive={false}
                            labelLine={false}
                            minAngle={7}
                          >
                            {this.state.businessOutcomesSDO.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  entry.name == "europe"
                                    ? pieColor2[2]
                                    : entry.name == "north america"
                                    ? pieColor2[0]
                                    : pieColor2[1]
                                }
                              />
                            ))}
                            <Label
                              className="db-donut-label"
                              value={this.state.businessOutcomesSDOSum}
                              position="center"
                            />
                          </Pie>
                          <Tooltip
                            content={<CustomTooltip origin={"pie"} />}
                            offset={0}
                            allowEscapeViewBox={{ x: false, y: false }}
                          />
                          <Legend verticalAlign="bottom" iconSize={12} iconType="square" />
                        </PieChart>
                      ) : (
                        <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                      )}
                    </ResponsiveContainer>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={4} className="db-sub-container vic">
                <div className="db-header-container">
                  <label className="db-header-label">{i18n.t("Value_Impacting_Client_P&L_$Mn")}</label>
                </div>
                <div className="">
                  <div className="bo-dashboard-donut-first-ul">
                    <ResponsiveContainer height={400} width="100%">
                      {this.state.businessOutcomePnL.length !== 0 ? (
                        <PieChart margin={{ top: 10, right: 0, left: 0, bottom: 90 }}>
                          <Pie
                            data={this.state.businessOutcomePnL}
                            dataKey="value"
                            innerRadius={60}
                            outerRadius={90}
                            fill="#8884d8"
                            label={this.renderCustomizedLabel}
                            isAnimationActive={false}
                            labelLine={false}
                            minAngle={7}
                          >
                            {this.state.businessOutcomePnL.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  entry.name == "cash optimization"
                                    ? pieColor[0]
                                    : entry.name == "operating cost optimization"
                                    ? pieColor[2]
                                    : pieColor[1]
                                }
                              />
                            ))}
                            <Label
                              className="db-donut-label"
                              value={this.state.businessOutcomePnLSum}
                              position="center"
                            />
                          </Pie>
                          <Tooltip
                            content={<CustomTooltip origin={"pie"} />}
                            offset={0}
                            allowEscapeViewBox={{ x: false, y: false }}
                          />
                          <Legend verticalAlign="bottom" iconSize={12} iconType="square" />
                        </PieChart>
                      ) : (
                        <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                      )}
                    </ResponsiveContainer>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          {/* YOY Stack Charts */}
          <div className="db-donuts-main-container">
            <Row className="db-graphs-container">
              <Col md={6} sm={6} className="db-yoy-sub-container yoy-cvd">
                <div className="db-header-container">
                  <label className="db-header-label">{i18n.t("YOY_Client_Value_Delivered_$Mn")}</label>
                </div>
                <div className="">
                  <div className="bo-dashboard-bar-third yoy-bar">
                    {this.state.yoyCVDconvertedData.length !== 0 ? (
                      <ResponsiveContainer height={260} width="100%">
                        <BarChart
                          layout="vertical"
                          data={this.state.yoyCVDconvertedData}
                          margin={{ top: 25, right: 50, left: -160, bottom: 0 }}
                          barSize={15}
                          barGap={0}
                          barCategoryGap={"1%"}
                        >
                          <XAxis type="number" tickLine={false} axisLine={false} allowDecimals={false} />
                          <YAxis
                            type="category"
                            dataKey="FiscalYear"
                            width={200}
                            interval={0}
                            tickLine={true}
                            axisLine={true}
                          />
                          <CartesianGrid strokeDasharray="0" horizontal={false} />
                          <Legend verticalAlign="bottom" iconSize={12} iconType="square" />
                          <Tooltip
                            content={<CustomTooltip origin={"bar 3"} />}
                            offset={0}
                            allowEscapeViewBox={{ x: false, y: false }}
                          />

                          {this.state.yoyCVDlabels.map((entry, index) => (
                            <Bar key={index} dataKey={entry} stackId="a" fill={pieColor2[index]} stroke="#fff">
                              {index === this.state.yoyCVDlabels.length - 1 ? (
                                <LabelList dataKey="Total" position="right" />
                              ) : null}
                            </Bar>
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                    )}
                  </div>
                </div>
              </Col>

              <Col md={6} sm={6} className="db-yoy-sub-container yoy-vic">
                <div className="db-header-container">
                  <label className="db-header-label">{i18n.t("YOY_Value_Impacting_Client_P&L_$Mn")}</label>
                </div>
                <div className="">
                  <div className="bo-dashboard-bar-third yoy-bar">
                    {this.state.yoyVICconvertedData.length !== 0 ? (
                      <ResponsiveContainer height={280} width="100%">
                        <BarChart
                          layout="vertical"
                          data={this.state.yoyVICconvertedData}
                          margin={{ top: 25, right: 50, left: -15, bottom: 15 }}
                          barSize={15}
                          barGap={0}
                          barCategoryGap={"1%"}
                        >
                          <XAxis type="number" tickLine={false} axisLine={false} allowDecimals={false} />
                          <YAxis type="category" dataKey="FiscalYear" interval={0} tickLine={true} axisLine={true} />
                          <CartesianGrid strokeDasharray="0" horizontal={false} />
                          <Legend verticalAlign="bottom" iconSize={12} iconType="square" />
                          <Tooltip
                            content={<CustomTooltip origin={"bar 3"} />}
                            offset={0}
                            allowEscapeViewBox={{ x: false, y: false }}
                          />
                          {this.state.yoyVIClabels.map((entry, index) => (
                            <Bar key={index} dataKey={entry} stackId="a" fill={pieColor2[index]} stroke="#fff">
                              {index === this.state.yoyVIClabels.length - 1 ? (
                                <LabelList dataKey="Total" position="right" />
                              ) : null}
                            </Bar>
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          {/* Collapsible Charts  */}
          <div className="valuerealization-dashboard">
            <div className="valuerealization-accordions">
              <div id="accordion" className="valuerealization-accordion">
                <div class="card">
                  <div class="card-header">
                    <div class="card-link" data-toggle="collapse" href="#collapse2">
                      <div className="card-link-icon">
                        <span>
                          <i class="fas fa-chevron-down"></i>
                        </span>
                      </div>
                      <div className="header-content">
                        <div className="header-link">
                          <h6>{i18n.t("By_Outcome_Levers_$Mn")}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="collapse2" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                      <div>
                        <Col md={12} sm={12}>
                          <div className="">
                            <div className="bo-dashboard-bar-second" id="boi">
                              {this.state.businessOutcomesInd.length !== 0 &&
                              this.state.businessOutcomesInd[0].value != "" ? (
                                <ResponsiveContainer
                                  height={this.state.boiBarHeight >= 10 ? 33 * this.state.boiBarHeight : 330}
                                  // height={300}
                                  width="100%"
                                >
                                  <BarChart
                                    layout="vertical"
                                    data={this.state.businessOutcomesInd}
                                    margin={{
                                      top: 15,
                                      right: 50,
                                      left: -10,
                                      // left: 0,
                                      bottom: 10,
                                    }}
                                    barSize={15}
                                    barCategoryGap={50}
                                  >
                                    <XAxis
                                      type="number"
                                      tickLine={false}
                                      axisLine={false}
                                      domain={[0, this.state.byOutcomeSettings.maxVal]}
                                      allowDecimals={false}
                                      ticks={this.state.byOutcomeSettings.ticks}
                                    />
                                    <YAxis
                                      type="category"
                                      dataKey="name"
                                      width={270}
                                      // width={200}
                                      interval={0}
                                      tickLine={true}
                                      axisLine={true}
                                    />
                                    <CartesianGrid strokeDasharray="0" horizontal={false} />
                                    <Tooltip
                                      content={<CustomTooltip origin={"bar 2"} />}
                                      offset={0}
                                      allowEscapeViewBox={{ x: false, y: false }}
                                    />
                                    <Bar name="value" dataKey="value" stackId="a" fill="#9c6ade">
                                      <LabelList dataKey="boiTotal" position="right" />
                                    </Bar>
                                  </BarChart>
                                </ResponsiveContainer>
                              ) : (
                                <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                              )}
                            </div>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <div class="card-link collapsed" data-toggle="collapse" href="#collapse4">
                      <div className="card-link-icon">
                        <span>
                          <i class="fas fa-chevron-down"></i>
                        </span>
                      </div>
                      <div className="header-content">
                        <div className="header-link">
                          <h6>{i18n.t("By_Delivery_Location_$Mn")}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="collapse4" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                      <div>
                        <Col md={12} sm={12}>
                          <div className="">
                            <div className="bo-dashboard-bar-first">
                              {this.state.keyDiffMetrics.annualized.length !== 0 &&
                              this.state.keyDiffMetrics.annualized[0].value != "" ? (
                                <ResponsiveContainer className="coll-responsive-container" height={330} width="100%">
                                  <BarChart
                                    layout="vertical"
                                    data={this.state.keyDiffMetrics.annualized}
                                    margin={{
                                      top: 15,
                                      right: 50,
                                      left: 10,
                                      bottom: 10,
                                    }}
                                    barSize={15}
                                  >
                                    <XAxis
                                      type="number"
                                      tickLine={false}
                                      axisLine={false}
                                      domain={[0, this.state.byDeliveryLocCBVSettings.maxVal]}
                                      allowDecimals={false}
                                      ticks={this.state.byDeliveryLocCBVSettings.ticks}
                                    />
                                    <YAxis
                                      type="category"
                                      dataKey="label"
                                      width={120}
                                      interval={0}
                                      tickLine={true}
                                      axisLine={true}
                                    />
                                    <CartesianGrid strokeDasharray="0" horizontal={false} />
                                    <Tooltip
                                      content={<CustomTooltip origin={"bar 1"} />}
                                      offset={0}
                                      allowEscapeViewBox={{ x: false, y: false }}
                                    />
                                    <Bar name="" dataKey="value" stackId="a" fill="#9c6ade">
                                      <LabelList dataKey="total" position="right" />
                                    </Bar>
                                  </BarChart>
                                </ResponsiveContainer>
                              ) : (
                                <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                              )}
                              <label>{i18n.t("Client_Business_Value_Impact_$ Mn")}</label>
                            </div>
                            <div className="bo-dashboard-bar-first">
                              {this.state.keyDiffMetrics.numberofProject.length !== 0 &&
                              this.state.keyDiffMetrics.numberofProject[0].value != "" ? (
                                <ResponsiveContainer className="coll-responsive-container" height={330} width="100%">
                                  <BarChart
                                    layout="vertical"
                                    data={this.state.keyDiffMetrics.numberofProject}
                                    margin={{
                                      top: 15,
                                      right: 50,
                                      left: 10,
                                      bottom: 10,
                                    }}
                                    barSize={15}
                                  >
                                    <XAxis
                                      type="number"
                                      tickLine={false}
                                      axisLine={false}
                                      domain={[0, this.state.byDeliveryLocNPSettings.maxVal]}
                                      allowDecimals={false}
                                      ticks={this.state.byDeliveryLocNPSettings.ticks}
                                    />
                                    <YAxis
                                      type="category"
                                      dataKey="label"
                                      width={120}
                                      interval={0}
                                      tickLine={true}
                                      axisLine={true}
                                    />
                                    <CartesianGrid strokeDasharray="0" horizontal={false} />
                                    <Tooltip
                                      content={<CustomTooltip origin={"bar 1"} />}
                                      offset={0}
                                      allowEscapeViewBox={{ x: false, y: false }}
                                    />
                                    <Bar
                                      name=""
                                      dataKey="value"
                                      stackId="a"
                                      fill="#9c6ade"
                                      label={{ position: "right" }}
                                    />
                                  </BarChart>
                                </ResponsiveContainer>
                              ) : (
                                <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                              )}
                              <label>{i18n.t("Number_of_Projects")}</label>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ValueRealizationGraphs;
