import React, { Component } from 'react';
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import i18n from "i18next";
import Modal from "react-bootstrap/Modal";
import { LocalApiBaseUrl } from "../Shared/Constant"
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";



export default class SelectNewTool extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toolslist:null,
            showLoaderonPage:false
        }
        this.handleCheckChildElement = this.handleCheckChildElement.bind(this);
        this.handleClickSelect = this.handleClickSelect.bind(this);
    }

    handleCheckChildElement = (event) => {
        let {id, checked} = event.target;
        let tools = this.state.toolslist;
        tools.filter((tool)=>tool.ApplicationMappingID == id)[0].IsSelected = checked;
        tools.filter((tool)=>tool.ApplicationMappingID == id)[0].IsActive = checked;
        this.setState({toolslist: tools})
    }

    handleClickSelect = () => {
        let tools = this.state.toolslist.slice();
        let params = {
            WOStepToolsModel:tools,
            OfferingID:this.props.filter.process, 
            SOCategoryID:this.props.selectedSubStepID
        }
        this.setState({showLoaderonPage:true});
        axiosInstance.post(`${LocalApiBaseUrl}Master/PostWOStepToolsMasterTemplateMapping`,params)
        .then((response) => {
          this.setState({
              showLoaderonPage:false
          })
          this.props.getOfferingAPICallback(true);
          this.props.modalClose();
        }).catch(error=>{
            trycatchAlertPopup(error);
            this.setState({
                showLoaderonPage:false
            })
        })
       
    }
  
    componentDidMount(){
        let params = {
            languageID: this.props.LanguageID,
            OfferingID:this.props.filter.process, 
            SOCategoryID: this.props.selectedSubStepID
        }
        axiosInstance.get(`${LocalApiBaseUrl}Master/GetWOStepToolsMasterTemplate`,
        {params: params})
        .then(response =>{
        this.setState({toolslist: response.data});
        }).catch((error) => {
            trycatchAlertPopup(error);
          });
  
    }

   
  
    render() {  
        return (
            <>
                <LoadingOverlay className="custom-loader" fadeSpeed={0} spinner={<SynopsLoader />} active={this.state.showLoaderonPage}></LoadingOverlay>

                <Modal show={this.props.show} onHide={this.props.modalClose} className="tooldata-pop" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Select Tool for Step</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="selecttool-list">
                            <ul>
                                { this.state.toolslist ? this.state.toolslist.map((tool,index) => {
                                    return(
                                    <li>
                                        <label className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={tool.IsActive && tool.IsSelected} onClick={this.handleCheckChildElement} name="foo" id={tool.ApplicationMappingID} value="bar2" />
                                            <span className="checkmark"></span>
                                            <div className="form-check-label">
                                            <h6 className="wo-title">{tool.ApplicationName}</h6>
                                                <ul className="tool-sublist">
                                                    <li>{tool.ApplicationCategory}</li>
                                                    <li>{tool.ToolType}</li>
                                                </ul>
                                                <p>{tool.ApplicationDescription}</p>
                                            </div>
                                        </label>
                                    </li>
                                    )
                            }
                            ) : null 
                            }
                              </ul>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>

                        <div className="modal-btn tool-btn">
                            <button className="btn btn-default brder-2 btn-sm mr-2" onClick={()=>this.props.addNewToolToggler()}>
                                Add New Tool
                            </button>
                            <div>
                                <button
                                    onClick={this.props.modalClose}
                                    className="btn btn-default brder-2 btn-sm mr-2"
                                >
                                    {i18n.t("Cancel")}
                                </button>

                                <input
                                    type="Button"
                                    className="btn btn-primary btn-sm"
                                    value={i18n.t("Select")}
                                    onClick={this.handleClickSelect}
                                
                                />
                            </div>
                        </div>


                    </Modal.Footer>
                </Modal>
            </>

        )
    }

}  