import React, { Component } from "react";
import axiosInstance from "../Shared/interceptor";
import { Modal, Row, Col, Container } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { sisenseSettings } from "./sisense";
import SynopsLoader from "../Shared/SynopsLoader";

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


const sisenseIsLoaded = () => {
  return (
    typeof window.Sisense !== "undefined" && typeof window.prism !== "undefined"
  );
};

class AssetsLinkURLModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isApiLoading: false,
      currentApp: null,
    };
  }

  componentDidMount() {
    if (!this.props.isIFrame && this.props.enableAssetsLinkStatus) {
      this.setState({ isApiLoading: true }, () => this.ConnectJS());
    }
  }

  loadSisensejs = () => {
    const isLoaded = sisenseIsLoaded();
    if (!isLoaded) {
      let count = this.state.count + 1;
      this.setState({ count });
      if (count < 3) {
        setTimeout(this.ConnectJS(), 5000);
      } else {
        this.setState({ isApiLoading: false });

      }
    } else {
      const Sisense = window.Sisense;
      Sisense.connect(sisenseSettings.server).then((app) => {
        this.setState(
          {
            currentApp: app,
            isApiLoading: false,
            isConnected: true,
          },
          () => this.loadDash()
        );
      });
    }
  };

  ConnectJS = () => {
    this.setState({ showLoaderOnPage: true });
    const isLoaded = sisenseIsLoaded();
    if (!isLoaded) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = sisenseSettings.server + "/js/sisense.js";

      script.async = true;
      script.onload = this.loadSisensejs;
      document.body.appendChild(script);
    }
  };

  loadDash = () => {
    let dashboardId = this.props.linkURL.split("/");
    let dashId = dashboardId[dashboardId.length - 1];
    this.state.currentApp.dashboards
      .load(dashId)
      .then((dash) => this.afterLoadDash(dash));
  };

  afterLoadDash = (db) => {
    let widgets = [];
    const layout = db.$$model.layout.columns[0].cells;

    const wRoot = document.getElementById("wgtContainer");
    if (wRoot) wRoot.innerHTML = "";
    let i = 0;
    layout.forEach((col) => {
      i++;
      const row = this.createRow(i);
      col.subcells.forEach((cell) => {
        const width = cell.width ? cell.width : 100;
        row.appendChild(this.createLayout(i, cell.elements[0], width));
        widgets.push(cell.elements[0].widgetid);
      });
      if (wRoot) {
        wRoot.appendChild(row);
      }
      if (i === layout.length)
        setTimeout(() => this.loadWgt(db, widgets), 1000);
    });
  };

  createRow = (i) => {
    const row = document.createElement("div");
    row.className = "row";
    row.id = "row" + i;
    return row;
  };

  createLayout = (i, el, width) => {
    const headerDiv = document.createElement("div");
    headerDiv.innerHTML = "title";
    headerDiv.id = "head" + el.widgetid;
    headerDiv.style.padding = "5px 20px";
    headerDiv.style.color = "white";
    headerDiv.style.backgroundImage =
      "linear-gradient(rgb(34, 145, 255), rgb(3, 48, 198))";

    const div = document.createElement("div");
    div.id = el.widgetid;

    div.style.height =
      el.height === 512
        ? "100%"
        : el.height.toString().replace("px", "") + "px";

    div.style.backgroundColor = "rgb(0, 0, 68)";

    const pdiv = document.createElement("div");
    pdiv.className = "layout row" + i;
    pdiv.style.width = "calc(" + width + "% - 4px)";
    pdiv.style.margin = "2px";

    pdiv.appendChild(headerDiv);
    pdiv.appendChild(div);

    return pdiv;
  };

  loadWgt = (db, widgets) => {
    let i = 0;
    widgets.forEach((wgt) => {
      i++;
      const div = document.getElementById(wgt);
      const oWgt = db.widgets.get(wgt);
      if (oWgt) {
        const head = document.getElementById("head" + wgt);
        if (oWgt.title.length > 0 && oWgt.title !== "RICHTEXT_MAIN.TITLE")
          head.innerHTML = oWgt.title;
        else if (head) head.style.display = "none";
        if (!oWgt.container) oWgt.container = div;
        else {
          const clone = Object.assign(oWgt);
          clone.$$container = null;
          clone.container = div;
        }
      }
      if (widgets.length === i) {
        db.refresh();
        setTimeout(() => {
          this.getQueryResults(db);
        }, 5000);
      }
    });
  };
  getQueryResults = (db) => {
    db.widgets.$$widgets.forEach((wgt) => {
      let wTxt;
      if (wgt.queryResult.length) {
        wTxt = wgt.queryResult[0][0].Value;
      } else {
        wTxt = wgt.queryResult.value ? wgt.queryResult.value.text : "";
      }
    });
  };

  render() {
    const { enableAssetsLinkStatus, disabledAssetsLink, isIFrame } = this.props;

    const { isApiLoading } = this.state;
    return (
      <>
        {isApiLoading && (
          <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
        )}
        <div id={sisenseSettings.applicationDivId}>
          <Modal
            show={enableAssetsLinkStatus}
            onHide={disabledAssetsLink}
            className="assets-link-modal"
            animation={true}
            size="large"
            backdrop={false}
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {isIFrame ? (
                <iframe
                  width="100%"
                  height="100%"
                  src="https://app.powerbi.com/reportEmbed?reportId=5d707b4c-bb5d-41cd-b55a-50b444945699&autoAuth=true&ctid=e0793d39-0939-496d-b129-198edd916feb&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
                  frameborder="0"
                  allowFullScreen={true}
                ></iframe>
              ) : (
                <Container fluid>
                  <Row style={{ backgroundColor: "rgb(0,0,60)" }}>
                    <Col>
                      <div id="wgtContainer"></div>
                    </Col>
                  </Row>
                </Container>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

export default AssetsLinkURLModal;
