import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import MetricDetails from './MetricDetails';
// import { getPrevMonth } from '../Shared/Constant';

export default class BOMetricDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { metricDetails: [] };
  }

  componentDidMount() {
    this.getKpiMetrics(
      // this.props.SODetails, this.props.filterDetails
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getKpiMetrics(nextProps.SODetails, nextProps.filterDetails);
  }

  getKpiMetrics() {
    const { SODetails } = this.props;
    this.setState({
      metricDetails: !_.isNil(SODetails.kpiData) ? SODetails.kpiData : null,
    });
  }

  render() {
    const {
      SODetails, isRelatorBlock, roledata, filterDetails,
    } = this.props;
    const { metricDetails } = this.state;
    return (
      <div className="custom-tilecard">
        <div className="tilecard-wrapper">
          <h2>
            <span>{SODetails.SOCategoryName}</span>
          </h2>
          {metricDetails.length !== 0 ? (
            <MetricDetails
              isRelatorBlock={isRelatorBlock}
              SOCategoryID={SODetails.SOCategoryID}
              Details={metricDetails}
              roledata={roledata}
              dealFilter={filterDetails}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

BOMetricDetails.propTypes = {
  roledata: PropTypes.array.isRequired,
  SODetails: PropTypes.object.isRequired,
  filterDetails: PropTypes.object.isRequired,
  isRelatorBlock: PropTypes.bool.isRequired,
};
