export let userReducers = (state = {
    userReducers: []
}, action) => {
    switch (action.type) {
        case "INITIALISEUSER": {
            state = { ...state }
            state["userReducers"] = action.payload
            return state
        }
        case 'ADDUSER':
        {
            state = { ...state }
            state["userReducers"].push(action.payload)
            return state
        }default:return state;

    }

}