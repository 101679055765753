import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import jquery from 'jquery';
import ReactTooltip from 'react-tooltip';
import MetricView from '../Dashboard/MetricView';
window.$ = window.jQuery = jquery;

export default class MetricDetails extends React.Component {

    constructor(props){

        
        super(props);
        this.state ={ 
            viewGraph: false

        }
        this.handleGraph = this.handleGraph.bind(this);
        
    }

      handleGraph(name,Id,e){
        let kpiMapping = Id
      e.preventDefault(Id,e);
      this.setState({viewGraph:true,kpiMapping , name})
    }

    onClose = (e) => {

        this.setState({ viewGraph: false,kpiMapping:0 , name:""});

    }
render(){
 
    const options = {
        loop: true,
        margin: 16,
        responsiveClass: "",
        autoWidth: true,
        dots: false,
        responsive: {
          0: {
            items: 1,
            nav: true
          },
          600: {
            items: 2,
            nav: false
          },
          1000: {
              items: 3,
              nav: true,
              loop: false
          },
          1440: {
              items: 4,
              nav: true,
              loop: false
            },
            1600: {
                items: 4,
                nav: true,
                loop: false
            },
            1800: {
                items: 5,
                nav: true,
                loop: false
          }
        }
      };
    return<>
     <div className="custom-carousel-padding">
    <OwlCarousel options={options} className="owl-carousel">
    {this.props.Details.map((eachDetails,index) => 
          <MetricView          
            SOCategoryID = {this.props.SOCategoryID} 
            key = {index+"_"+eachDetails.MessagesCount} 
            isRelatorBlock={this.props.isRelatorBlock} 
            dealFilter ={this.props.dealFilter} 
            roledata={this.props.roledata} 
            data={eachDetails}>            
          </MetricView> 
        )}              
        </OwlCarousel>

        <ReactTooltip place="bottom" effect="solid" className="tipcolor" />
        </div>
    </>
  }
}
