import React from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import axiosInstance from "../Shared/interceptor";
import { regexForTextAreas } from "../Shared/Constant";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class WOPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      errors: [],
      formIsValid: false,
      Approval: {
        ApproverRemarks: "",
        DealWOStepID: [],
        ApprovalType: "Deal",
        ApprovalAction: false,
      },
    };

    this.onDealIsActiveChange = this.onDealIsActiveChange.bind(this);
    this.onGlobalIsActiveChange = this.onGlobalIsActiveChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  validateForm() {
    const Approval = this.state.Approval;
    let errors = {};
    let formIsValid = true;

    if (!Approval["ApprovalType"] && this.props.action === "Approve") {
      formIsValid = false;
      errors["error"] = "Choose one option";
    }

    if (!Approval["ApproverRemarks"].trim() && !(this.props.action === "Approve")) {
      formIsValid = false;
      errors["ApproverRemarks"] = "Approver Remarks are required";
    } else {
      var isValid = regexForTextAreas.test(Approval["ApproverRemarks"]);
      if (!isValid && !(this.props.action === "Approve")) {
        formIsValid = false;
        errors["ApproverRemarks"] = "Approver Remarks contains Special characters";
      }
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  onDealIsActiveChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    if (event.target.checked) {
      const Approval = this.state.Approval;
      Approval.ApprovalType = "Deal";
      this.setState({
        Approval: Approval,
      });
    }
  }

  onGlobalIsActiveChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    if (event.target.checked) {
      const Approval = this.state.Approval;

      Approval.ApprovalType = "Global";
      this.setState({
        Approval: Approval,
      });
    }
  }

  onRemarksChange(event) {
    this.setState({ errors: {} });

    const Approval = this.state.Approval;

    Approval.ApproverRemarks = event.target.value;
    this.setState({
      Approval: Approval,
    });
  }

  handleSave() {
    if (this.validateForm()) {
      const { Approval } = this.state

      if (this.props.action === "Approve") {
        Approval.ApprovalAction = true;
        this.props.onSave(Approval);
          this.handleHide();
      } else {
        Approval.ApprovalType = null;
        Approval.ApprovalAction = false;
        this.props.onSave(Approval);
        this.handleHide();
        
      }
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  componentDidMount() {
    this.props.data.forEach((row) => {
      this.state.Approval.DealWOStepID.push(row.DealWOStepID);
    });
  }

  componentWillMount() {
    this.setState({
      Approval: {
        ApproverRemarks: "",
        DealWOStepID: [],
        ApprovalType: "Deal",
        ApprovalAction: false,
      },
    });
  }

  render() {
    const { action } = this.props;
    const { show, errors, Approval } = this.state;

    return (
      <>
        <Modal
          show={show}
          onHide={this.handleHide.bind(this)}
          backdrop="static"
          className="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            {action === "Approve" ? (
              <h4 className="modal-title" id="dealprofile" flag>
                Approval
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile" flag>
                Decline
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              {action === "Approve" ? (
                <div className="form-section">
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="foo"
                          checked={true}
                          value="bar2"
                          onChange={this.onDealIsActiveChange}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">Deal</span>
                      </label>
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="foo"
                          value="bar3"
                          disabled={true}
                          onChange={this.onGlobalIsActiveChange}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">Global</span>
                      </label>
                    </div>
                  </div>
                  <span className="errorMsg">{errors.error}</span>
                </div>
              ) : null}

              {action === "Approve" ? (
                <div className="form-section">
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">
                        Approver Remarks<span className="required"></span>
                      </label>

                      <textarea
                        maxLength="200"
                        className="form-control"
                        value={Approval.ApproverRemarks}
                        onChange={this.onRemarksChange.bind(this)}
                      />
                      <span className="errorMsg">{errors.ApproverRemarks}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="form-section">
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">
                        Approver Remarks<span className="required">*</span>
                      </label>

                      <textarea
                        maxLength="200"
                        className="form-control"
                        value={Approval.ApproverRemarks}
                        onChange={this.onRemarksChange.bind(this)}
                      />

                      <span className="errorMsg">{errors.ApproverRemarks}</span>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value="Save"
                />
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
