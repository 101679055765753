import React, { Component } from "react";
import CorouselDiv from "./CorouselDiv";
import OwlCarousel from "react-owl-carousel2";
import StepDetailsDataContainer from './StepDetailsDataContainer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button} from 'react-bootstrap';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import $ from 'jquery';
import { store } from "../reducers/configureStore";
import {RemoveDuplicates} from "../Shared/commonFunctions";

export default class StepDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            SOCategoryID: 0,
            cardSelectedSubStepSOCatID: 0,
            subStepData:[],
            ToolData: [],
            MetricData: [],
            subStepComment: '',
        };
    }    
  
  componentDidMount() {
    this.getSubStepData();
  }

    //Setting Active steps on Corousel 
    componentDidUpdate (prevProps) {
        setTimeout(() => {
            $('.stepdetails-carousel .owl-nav').removeClass('disabled');
        }, 100);
        $('.stepdetails-carousel .owl-nav').click(function(event) {
            $(this).removeClass('disabled');
        });
        var selectedStep = this.props.metricRowDetails.filter(row => row.SOCategoryID == this.props.selectedStep);
        var selectedIndexVal;
        if(selectedStep[0] && selectedStep[0].key >4){
            selectedIndexVal = (selectedStep[0].key)-2;
        }
        else {
            selectedIndexVal = 0;
        }
        if (this.props.selectedStep !== prevProps.selectedStep){            
            this.setState({
                cardSelectedSubStepSOCatID: 0
            },()=>{this.getSubStepData()});
        }
        var carousel = $(".owl-carousel");
        carousel.owlCarousel();
        carousel.trigger("to.owl.carousel", [selectedIndexVal, 300, true]);        
    }

    componentWillReceiveProps() {
        this.getSubStepData();
      }

    getSubStepData() {        
        const {metricRowDetails, selectedStep} = this.props;
        const {cardSelectedSubStepSOCatID} = this.state;
        let subStepDataDup  = [];
        let toolData = [], metricData =[];
        metricRowDetails.forEach((eachData) => {
            if(eachData.ParentSOCategoryID === selectedStep && eachData.IsDeleted === false){        
                subStepDataDup.push(eachData);
            }
        });
        if (cardSelectedSubStepSOCatID === 0) {
            subStepDataDup.map((each) => {
                if(each.WOStepTools && each.WOStepTools.length){
                    each.WOStepTools.forEach((eachTool) => { 
                        toolData.push({...eachTool});
                    })
                }
                if(each.kpiData.length){
                    each.kpiData.forEach((eachkpiData) => {
                        metricData.push({...eachkpiData});
                    })
                }
            })
        }
        else{
            subStepDataDup.forEach((each) => {
                if(each.SOCategoryID === cardSelectedSubStepSOCatID){
                    if(each.WOStepTools && each.WOStepTools.length){
                        each.WOStepTools.forEach((eachTool) => { 
                            toolData.push({...eachTool});
                        })
                    }
                    if(each.kpiData.length){
                        each.kpiData.forEach((eachkpiData) => {
                            metricData.push({...eachkpiData});
                        })
                    }
                }
            })
        }
        toolData = RemoveDuplicates(toolData,"ApplicationName");
        this.setState({
            subStepData: subStepDataDup, ToolData: toolData, MetricData: metricData
        });
        
    }

    onSubStepCardClick = (count, SOCatID) => {
        let { screen, metricRowDetails } = this.props;
        let storeData = store.getState();
        let state = this.state;
        let { subStepData } = this.state;
        switch (screen) {
            case "Configurator":
                if(count > 0){
                    state.SOCategoryID = subStepData.filter(
                        (data) => data.SOCategoryName !== "Work Orchestration"
                    )[count - 1].SOCategoryID;
                    state.cardSelectedSubStepSOCatID = subStepData.filter(
                        (data) => data.SOCategoryName !== "Work Orchestration"
                    )[count - 1].SOCategoryID;
                    state.subStepComment = subStepData.filter(
                        (data) => data.SOCategoryName !== "Work Orchestration"
                    )[count - 1].Comments;
                    this.setState({
                        SOCategoryID: state.SOCategoryID,
                        cardSelectedSubStepSOCatID: state.cardSelectedSubStepSOCatID,
                        subStepComment: state.subStepComment,
                    },()=>this.getSubStepData());
                } else {
                    this.setState({
                        SOCategoryID: 0,
                        cardSelectedSubStepSOCatID: 0,
                    },()=>this.getSubStepData());
                }                
                break;
            case "SuperAdmin":
                if (count > 0) {
                    state.SOCategoryID = subStepData.filter(
                    (data) => data.SOCategoryName !== "Work Orchestration"
                    )[count - 1].SOCategoryID;
                    state.cardSelectedSubStepSOCatID = subStepData.filter(
                        (data) => data.SOCategoryName !== "Work Orchestration"
                    )[count - 1].SOCategoryID;
                    this.setState({
                        SOCategoryID: state.SOCategoryID,
                        cardSelectedSubStepSOCatID: state.cardSelectedSubStepSOCatID,
                    },()=>this.getSubStepData());
                } else {
                    this.setState({
                        SOCategoryID: 0,
                        cardSelectedSubStepSOCatID: 0,
                    },()=>this.getSubStepData());
                }
                break;
            case 'Dashboard':
                state.SOCategoryID = subStepData.filter(
                    (data) => data.SOCategoryName !== "Work Orchestration"
                )[count - 1].SOCategoryID;
                state.cardSelectedSubStepSOCatID = subStepData.filter(
                    (data) => data.SOCategoryName !== "Work Orchestration"
                )[count - 1].SOCategoryID;
                state.subStepComment = subStepData.filter(
                    (data) => data.SOCategoryName !== "Work Orchestration"
                )[count - 1].Comments;
                this.setState({
                    SOCategoryID: state.SOCategoryID,
                    cardSelectedSubStepSOCatID: state.cardSelectedSubStepSOCatID,
                    subStepComment: state.subStepComment,
                },()=>this.getSubStepData());
                break;

            default:
                return null;
        }
    }

    render() {

        const { MetricData, ToolData, cardSelectedSubStepSOCatID} = this.state;

        const customCarousel = {
            margin: 14,
            dots: false,
            autoWidth: true,
            autoplay: false,
            loop: false,
            slideBy: 3,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                700: {
                    items: 3,
                },
                1000: {
                    items: 4,
                }
            },
        };
        let { screen, metricRowDetails, FoundationTools, selectedStep, stepToggleHandler, toolDataConf,
            filter, deleteStep, addStepModalShow, card, carouselData, roledata } = this.props;
 return (
            <>
                {metricRowDetails.length == 0 && screen === 'SuperAdmin' ? (
                    <div className="tool-data">
                        <div className="add-toolicon">
                            <Button className="show-pop" onClick={addStepModalShow}>
                                <span> <FontAwesomeIcon icon={faPlus} /></span>
                            </Button>
                            <h6>There is no workflow step yet</h6>

                            <div className="add-step" onClick={addStepModalShow}>Add Step</div>
                        </div>
                    </div>
                ):(
                        <div className="wo-tabcontent">
                            <div className="step-details">
                                {screen !== "SuperAdmin" ? (
                                    <div className="foundation-tools">
                                        <h6>Foundation Tools</h6>
                                        {FoundationTools !=undefined ? FoundationTools.map((row)=><p>{row}</p>):null}
                                    </div>
                                ):null}
                                <div className="stepdetails-carousel">
                                    <OwlCarousel className="owl-carousel" options={customCarousel}>
                                        {carouselData.map((metric) => (
                                            <CorouselDiv
                                                screen= {screen}
                                                key={metric.key}
                                                metricDesp={metric.SOCategoryName}
                                                metricSeq={metric.key}
                                                metricActive = {metric.IsActive}
                                                selectedStep={metric.SOCategoryID === selectedStep && metric.key}
                                                SOCategorySeq={metric.SOCategorySeq}
                                                SOCategoryDesp={metric.SOCategoryDesp}
                                                SOCategoryType={metric.SOCategoryType}
                                                SOCategoryTypeID={metric.SOCategoryID}
                                                SOCategoryID={metric.SOCategoryID}
                                                ParentSOCategoryID={metric.ParentSOCategoryID}
                                                filterDetails={this.props.filterDetails}
                                                filter={this.props.filter}
                                                RecordStatus={this.props.RecordStatus} isManintencneWindowOpen={this.props.isManintencneWindowOpen}
                                                
                                                click={() => card(metric.key, metric.SOCategoryID)}
                                                
                                                
                                            />
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </div>
                            {
                                metricRowDetails.map((data)=>{
                                    if(data.SOCategoryID === selectedStep){                                        
                                            return <StepDetailsDataContainer
                                                screen={screen}
                                                RecordStatus = {this.props.RecordStatus} isManintencneWindowOpen ={this.props.isManintencneWindowOpen}
                                                toolData={ToolData && ToolData.length>0  ? (ToolData.sort((a,b)=> a.ApplicationName.toLowerCase() > b.ApplicationName.toLowerCase()
                                                    ? 1
                                                    : -1)) : ToolData}
                                                metricActive={data.IsActive}
                                                stepToggleHandler={stepToggleHandler}
                                                deleteStep={deleteStep}
                                                metricDesp={data.SOCategoryName}
                                                metricSeq={data.key}
                                                metricData={MetricData}
                                                Comments={this.state.cardSelectedSubStepSOCatID === 0 ? data.Comments : this.state.subStepComment}
                                                filter={filter}
                                                LanguageID={this.props.LanguageID}
                                                SOCategoryID={data.SOCategoryID}
                                                getOfferingAPICallback={this.props.getOfferingAPICallback}
                                                roledata={roledata}
                                                subStepData={this.state.subStepData}
                                                selectedSubStep={this.state.cardSelectedSubStepSOCatID}
                                                card={this.onSubStepCardClick}
                                            />
                                    }                           
                            })}

                        </div>
                    )}
            </>
        );
    }
}
