import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import i18n from "i18next";
// import DownCaretIcon from "../Images/configCaretDown-icon.svg";
import PropTypes from "prop-types";

function ClientDropdown({ selectedClients, handleDropdownChange, allClients }) {
  const [searchText, setSearchText] = useState("");

  const filteredClient = allClients
    .sort((a, b) => {
      if (a.OrgEntityName.trim() < b.OrgEntityName.trim()) {
        return -1;
      }
      if (a.OrgEntityName.trim() > b.OrgEntityName.trim()) {
        return 1;
      }
      return 0;
    })
    .filter((client) => {
      if(client.OrgEntityName)
      return client.OrgEntityName.toLowerCase().includes(searchText.toLowerCase())
      else return false;
    });


  return (
    <Dropdown className={selectedClients.OrgEntityName ? "selected" : ""}>
      <Dropdown.Toggle className="client-dropdown" variant="light">
        <div>
          <div className="client-dropdown-header">{i18n.t("Client Name *")}</div>
          <div className="client-dropdown-body">
            {i18n.t(!selectedClients.OrgEntityName ? "Select Client Name" : selectedClients.OrgEntityName)}
          </div>
        </div>
        <div>{/* <img className="float-right mt-3" src={DownCaretIcon} /> */}</div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="client-dropdown-menu">
        <div className="searchFilter">
          <input
            id="search"
            placeholder="Search ..."
            type="search"
            autoComplete="off"
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
          />
        </div>

        <div className="dropdown-wrapper client-dropdown-wrapper">
          {filteredClient.length === 0 && <div>No Records Found</div>}
          {filteredClient.map((o, i) => (
            <Dropdown.Item
              onClick={() => handleDropdownChange(o)}
              className={`client-dropdown-item ${
                selectedClients.OrgEntityID === o.OrgEntityID ? "active" : ""
              }`}
              key={`cxl-approvers-dropdown-item-${i}`}
            >
              <span>{o.OrgEntityName}</span>
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

ClientDropdown.propTypes = {
  selectedClients: PropTypes.object,
  handleDropdownChange: PropTypes.func,
  allClients: PropTypes.array,
};

export default ClientDropdown;
