
import React from "react";
import { Modal } from "react-bootstrap";
import { LocalApiBaseUrl, regexForFields, regexForToolURL, commonToolRegex } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { connect } from "react-redux";
import i18n from "i18next";

class ToolsPopUP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            buttdis: false,
            popupHeader: i18n.t("Add_Tool"),
            popupSaveButton: i18n.t("Create"),
            DestinationClient: "",
            selectedOffering: "",
            offeringData: [],
            processData: [],
            user: {
                UserName: ""
            },
            processValue: {
                proID: 0
            },
            toolDetails: { toolName: "", OfferingID: 0, OrgEntityID: 0, LocationID: 0, toolCategory: 0, synopsLevers: 0, url: "", description: "", DefaultUserID: "", BusinessBenefit: "", isActive: true, isClickableTool: true, isFieldDisable: false, toolType: "" },
            toolErrors: { toolName: "", toolCategory: "", synopsLevers: "", url: "", description: "", BusinessReason: "", toolType: "" },
            toolLeversList: [],
            toolCategoryList: []
        }
    }

    componentDidMount() {
        const { tooldata, tooldatatype } = this.props;
        this.getToolMasterData();
        if (String(tooldata) !== String(undefined) && String(tooldatatype) === "Edit") {
            let data = tooldata;

            this.setState({
                popupHeader: i18n.t("Edit_Tool"),
                popupSaveButton: i18n.t("Update"),
                toolDetails: {
                    id: data.ApplicationMappingID,
                    toolName: data.ApplicationName,
                    toolCategory: data.ApplicationCategoryID,
                    synopsLevers: data.SOCategoryID,
                    url: data.URL,
                    description: data.ApplicationDescription,
                    isActive: data.IsActive,
                    isClickableTool: data.isClickableTool,
                    isFieldDisable: true,
                    toolType: data.Tooltype,
                }
            });
        }
        else {
            this.setState({
                popupHeader: i18n.t("Add_Tool"),
                popupSaveButton: i18n.t("Create"),
                toolDetails: {
                    id: 0,
                    toolName: "",
                    toolCategory: 0,
                    synopsLevers: 0,
                    url: "",
                    description: "",
                    isActive: true,
                    isClickableTool: true,
                    isFieldDisable: false,
                    toolType: ""
                }
            });
        }
    }

    getToolMasterData = () => {
        const { languageData } = this.props;
        axiosInstance.get(`${LocalApiBaseUrl}ApplicationMapping/GetMasterDataForApplicationMapping`, {
            params: { languageID: languageData.id }
        })
            .then((response) => {
                const soCategoryList = response.data.SOCategoryList.map((dropValue, index) => {
                    return <option key={dropValue.ID} selected="" value={dropValue.ID}>{dropValue.Value}</option>
                });

                const categoryList = response.data.CategoryList.map((dropValue, index) => {
                    return <option key={dropValue.ID} selected="" value={dropValue.ID}>{dropValue.Value}</option>
                });

                this.setState({
                    toolLeversList: soCategoryList, toolCategoryList: categoryList
                });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });

    }

    onClose = () => {
        this.setState({ viewmore: false });
        this.props.onClose();
    }

    bindToolFieldChange = (e) => {
        const { toolDetails, processValue } = this.state;
        const id = e.target.id;
        let data = toolDetails;
        switch (id) {
            case "txtToolName":
                data.toolName = e.target.value;
                break;
            case "ddToolCategrory":
                data.toolCategory = e.target.value;
                break;
            case "SubOffering":
                processValue.proID = e.target.value;
                
                break;
            case "ddSynopsLevers":
                data.synopsLevers = e.target.value;
                break;
            case "txtToolUrl":
                data.url = e.target.value;
                break;
            case "txtToolDescription":
                data.description = e.target.value;
                break;
            case "txtToolbusinessreason":
                data.BusinessBenefit = e.target.value;
                break;
            case "txtTooldefaultuserid":
                data.DefaultUserID = e.target.value;
                break;
            case "ddToolType":
                data.toolType = e.target.value;
                break;
            default:
                break;
        }

        this.setState({
            toolDetails: data
        });
    }

    validateToolField = () => {
        const { toolDetails } = this.state;
        const { SOCategoryName } = this.props;
        let isValid = false;
        let errorJSON = [];
        const data = toolDetails;

        if (String(data.toolName) === String(undefined) || String(data.toolName.trim()) === "") {
            errorJSON[0] = i18n.t("ToolName_Required");
        }
        else {
            if (regexForFields.test(data.toolName.trim())) {
                errorJSON[0] = "";
            }
            else {
                errorJSON[0] = i18n.t("ToolName_SpecialChar");
            }
        }

        if (String(data.toolCategory) === String(undefined) || parseInt(data.toolCategory) === 0) {
            errorJSON[1] = i18n.t("ToolCategory_Required");
        }
        else {
            errorJSON[1] = "";
        }


        if (String(data.description) === String(undefined) || String(data.description.trim()) === "") {
            errorJSON[2] = i18n.t("ToolDescription_Required");
        }
        else {
            if (regexForFields.test(data.description.trim())) {
                errorJSON[2] = "";
            }
            else {
                errorJSON[2] = i18n.t("ToolDescription_SpecialChar");
            }
        }
        if (!commonToolRegex.test(data.url.trim().toLowerCase())) {
            if (String(data.url) !== String(undefined) && String(data.url.trim()) !== "" && !regexForToolURL.test(data.url.trim().toLowerCase())) {
                errorJSON[3] = i18n.t("Invalid_URL");
            }
            else {
                errorJSON[3] = "";
            }
        }
        else {
            errorJSON[3] = i18n.t("commonToolRegex_validatemessage");
        }
        //code for toolType	
        if (String(SOCategoryName) === "Work Orchestration") {
            if (String(data.toolType) === String(undefined) || String(data.toolType) === "" || String(data.toolType) === "0") {
                errorJSON[4] = i18n.t("Tool Type is required");
            }
            else {
                errorJSON[4] = "";
            }
        }
        else {
            errorJSON[4] = "";
        }

        this.setState({
            toolErrors: { toolName: errorJSON[0], toolCategory: errorJSON[1], description: errorJSON[2], url: errorJSON[3], toolType: errorJSON[4] }
        });

        if (String(errorJSON[0]) === "" && String(errorJSON[1]) === "" && String(errorJSON[2]) === "" && String(errorJSON[3]) === "" && String(errorJSON[4]) === "")
            isValid = true;

        return isValid;
    }

    saveTool = () => {
        const { toolDetails } = this.state;
        const { offeringID, SOCategoryID, clientID, LocationID } = this.props;
        if (this.validateToolField()) {
            const data = toolDetails;
            const param = {
                "OfferingID": parseInt(offeringID),
                "ApplicationName": data.toolName.trim(),
                "ApplicationCategoryID": parseInt(data.toolCategory),
                "SOCategoryID": parseInt(SOCategoryID),
                "OrgEntityID": parseInt(clientID),
                "LocationID": parseInt(LocationID),
                "URL": data.url.trim(),
                "ApplicationDescription": data.description.trim(),
                "DefaultuserID": toolDetails.DefaultUserID,
                "BusinessBenefit": toolDetails.BusinessBenefit,
                "ToolType": toolDetails.toolType,
            };
            this.setState({ buttdis: true })
            axiosInstance.post(`${LocalApiBaseUrl}ClickableTools/SavenewClickableTool`, param).then((response) => {
                window.alert(response.data.StatusMessage);
                if (response.data.IsSuccess) {
                    this.setState({ buttdis: false })
                    this.onClose();
                }
                else {
                    this.setState({ buttdis: false })
                    this.setState({ showLoaderOnPage: false });
                }
            }).catch((error) => {
                trycatchAlertPopup(error);
                window.alert(this.ssErrMsg + error);
            });
        }
    }

    render() {
        const { show, popupHeader, toolDetails, toolErrors, toolCategoryList, buttdis, popupSaveButton } = this.state;
        const { clientname, offeringName, subofferingname, LocationName, SOCategoryName } = this.props;
        return (
            <>
                {/* <Header /> */}
                <Modal show={show} className="modal fade" size="medium" id="dealpopup" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true" >

                    <Modal.Header> {/*closeButton*/}
                        <h4 className="modal-title">
                            {popupHeader}
                        </h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>

                    <Modal.Body>
                        <form>
                            <div className="form-section">

                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="">
                                            {i18n.t("Tool Name")} <span className="required">*</span>
                                        </label>
                                        <input id="txtToolName" type="text" maxlength="45" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={toolDetails.toolName} />
                                        <span className="errorMsg">
                                            {toolErrors.toolName}
                                        </span>
                                    </div>



                                    <div className="form-group col-lg-6">
                                        <label for="">Client Name</label>
                                        <input type="text" maxlength="200" className="form-control" id="" value={clientname} disabled />
                                    </div>




                                    <div className="form-group col-lg-6">
                                        <label for="">Offering</label>
                                        <input type="text" maxlength="200" className="form-control" id="" value={offeringName} disabled />
                                    </div>


                                    <div className="form-group col-lg-6">
                                        <label for="">Sub Offering</label>
                                        <input type="text" maxlength="200" className="form-control" id="" value={subofferingname} disabled />
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label for="">Delivery Center</label>
                                        <input type="text" maxlength="200" className="form-control" id="" value={LocationName} disabled />
                                    </div>


                                    <div className="form-group col-lg-6">
                                        <label for="">
                                            {i18n.t("Tool Category")} <span className="required">*</span>
                                        </label>
                                        <select id="ddToolCategrory" name="toolCategory" className="form-control" disabled={toolDetails.isFieldDisable} onChange={(e) => { this.bindToolFieldChange(e) }} value={toolDetails.toolCategory}>
                                            <option value="0">{i18n.t("Select Tool Category")}</option>
                                            {toolCategoryList}
                                        </select>
                                        <span className="errorMsg">
                                            {toolErrors.toolCategory}
                                        </span>
                                    </div>


                                    <div className="form-group col-lg-6">
                                        <label for="">Synops Lever</label>
                                        <input type="text" maxlength="200" className="form-control" id="" value={SOCategoryName} disabled />
                                    </div>
                                    {String(SOCategoryName) === "Work Orchestration" &&
                                        <div className="form-group col-lg-6">
                                            <label for="">
                                                {i18n.t("Tool_Type")} <span className="required">*</span>
                                            </label>
                                            <select id="ddToolType" name="tooltype" className="form-control" disabled={toolDetails.isFieldDisable} onChange={(e) => { this.bindToolFieldChange(e) }} value={toolDetails.toolType}>
                                                <option value="0">{i18n.t("Select_Tool_Type")}</option>
                                                <option value="AA">Advanced Analytics</option>
                                                <option value="AI">Artificial Intelligence</option>
                                                <option value="AT">Automation</option>
                                                <option value="WF">Workflow</option>
                                            </select>
                                            <span className="errorMsg">
                                                {toolErrors.toolType}
                                            </span>
                                        </div>
                                    }



                                    <div className="form-group col-lg-6">
                                        <label for="">Business Benefit</label>
                                        <input id="txtToolbusinessreason" type="text" maxlength="255" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={toolDetails.BusinessBenefit} />
                                    </div>


                                    <div className="form-group col-lg-12">
                                        <label for="">Default User ID<span className="required"></span></label>
                                        <div class="input-group">
                                            <input id="txtTooldefaultuserid" type="text" maxlength="100" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={toolDetails.DefaultUserID} />
                                        </div>
                                    </div>


                                    <div className="form-group col-lg-12">
                                        <label for="">
                                            URL
                                        </label>
                                        <input id="txtToolUrl" type="url" maxlength="5000" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={toolDetails.url} />
                                        <span className="errorMsg">
                                            {toolErrors.url}
                                        </span>
                                    </div>



                                    <div className="form-group col-lg-12">
                                        <label for="">
                                            {i18n.t("Tool Description")} <span className="required">*</span>
                                        </label>
                                        <textarea id="txtToolDescription" rows="2" name="Description" maxLength="70" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={toolDetails.description} />
                                        <span className="errorMsg">
                                            {toolErrors.description}
                                        </span>
                                    </div>



                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    {
                        <Modal.Footer>
                            <div className="modal-btn">
                                <input type="Button" disabled={buttdis} className="btn btn-primary btn-sm" onClick={() => { this.saveTool() }} value={popupSaveButton} />
                            </div>
                        </Modal.Footer>
                    }
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        languageData: state.CurrentFilter.languageData
    })
}
export default connect(mapStateToProps)(ToolsPopUP);