import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";


import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class RegisteredUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadUrl: null,
      formIsValid: false,
      active: false,
      viewmore: false,
      process: "",
      Client: "",
      MaturityPhase: this.props.MaturityPhase,
      processOptions: [],

      Report: {
        StartDate: new Date(),
        EndDate: new Date(),
      },
    };

    this.QueryString = this.QueryString.bind(this);
    this.generateExcelReport = this.generateExcelReport.bind(this);
  }

  componentDidMount() {
    this.QueryString();
  }

  generateExcelReport() {
    var that = this;
    var data = {};
    let constructedURL = LocalApiBaseUrl + "Report/GetRegisteredUser";

    this.setState({
      active: true,
    });

    axiosInstance
      .post(constructedURL, data, {
        responseType: "blob",
      })
      .then((response) => {
        this.setState({ active: false });
        if (response.status !== 200) {
          alert("An error has occurred!");
        }
        let fileNameFromHeaders = response.headers.filename;
        if (!window.navigator.msSaveOrOpenBlob) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", fileNameFromHeaders);

          document.body.appendChild(link);
          link.click();
        } else {
          // BLOB FOR EXPLORER 11
        }
      })
      .catch(function (error) {
        that.setState({ active: false });
        trycatchAlertPopup(error);
      });
  }

  QueryString() {
    var A =
      "Report/GetRegisteredUser?EnterpriseId=" +
      sessionStorage.getItem("EnterpriseID");
    var B = LocalApiBaseUrl;
    let downloadUrl = this.state.downloadUrl;

    downloadUrl = B + A;

    this.setState({
      downloadUrl: downloadUrl,
    });
  }

  render() {
    return (
      <div className="main-data">
        <div className="row">
          <div className="form-group col">
            <button
              className="btn btn-primary btn-sm"
              id="btnsave"
              onClick={this.generateExcelReport}
              disabled={this.state.active}
            >
              Generate Report
            </button>
            <LoadingOverlay
              active={this.state.active}
              spinner={<SynopsLoader />}
            ></LoadingOverlay>
          </div>
        </div>
      </div>
    );
  }
}
