import { data } from "jquery";
import {
    LOGIN_USER_DATA_INITIALIZE
}from "../actionTypes/LoginAction";

export function initializeUserAccountData(data){
return {
    type:LOGIN_USER_DATA_INITIALIZE,
    payload: data,
};
}