import React from 'react';
import { LocalApiBaseUrl } from '../Shared/Constant';
import i18n from "i18next";
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";


axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;

var a = 'Data/GetDataTemplate';
var b = LocalApiBaseUrl;
var downloadUrl = b+a;

export default class ExcelUpload extends React.Component {
    constructor() {
        super()
        this.state = { selectedFile: null, loaded: 0, code: '', result: '', viewUpload: false ,displayProgress:false};
        this.handleselectedFile = this.handleselectedFile.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
    }

    handleselectedFile(event) {
        
        let selectedFile = event.target.files[0];
        this.setState({ selectedFile });
    }
    handleMessage(event) {
        event.target.value = null;
        this.setState({ result: '', selectedFile: null, size: null });
    }

    handleUpload() {
      
      

        if (this.state.selectedFile != null) {            
          
                if(String(this.state.selectedFile.type) === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'||String(this.state.selectedFile.type) === 'application/vnd.ms-excel'){
                let size = this.state.selectedFile.size;
                let fileSize = size / 1048576;
                
                if (fileSize <= 5) {
                    const data = new FormData()
                    data.append('file', this.state.selectedFile,this.state.selectedFile.name)
                    this.setState ({displayProgress:true});
                     
    
    
                    axiosInstance.post(`${LocalApiBaseUrl}Data/UploadDataFile`, data)
                        .then(res => {
                           
                           
                            let code = res.status;
                           
                            let result = <p className='help-block'>{res.data}</p>
                            if (String(code) === "200") {
                                this.fileInput.value = "";
                            }
                            this.setState({ code, result, selectedFile: null ,displayProgress:false });
                            
                        })
                        .catch((error) => {
                            trycatchAlertPopup(error);
                          });
                  
                }
                else {
                    alert(i18n.t("ExcelMessage_Maximum"))
                }
            
            }
            else{
                alert(i18n.t("ExcelMessage_Choose"))
            }
        }
        else {
            alert(i18n.t("ExcelMessage_Default"))
        }
    }


    render() {
        const {
            displayProgress,
            result
        } = this.state;
        return (
            <div className="main-component">
                <div className="container-fluid">
                
                    <div className="row">
                    <a href={downloadUrl} style ={{float:'right'}}>{i18n.t("Download_Template")}</a> 
                        <div className="col-lg-12">
                            <div className="file-upload">
                                <div className="form-group">
                                    <label for="exampleInputFile">{i18n.t("File input")}</label>
                                    <input type="file" name="" id="exampleInputFile" accept=".xlsx" onChange={this.handleselectedFile} ref={ref => this.fileInput = ref} onClick={this.handleMessage} />
                                   
                                </div>
                                <button onClick={this.handleUpload} className="btn btn-primary btn-block b" >{i18n.t("Upload")}</button>
                                <>{displayProgress !== false?<span>{i18n.t("Uploading")}...<progress  /> </span>:null}</>
                                {result}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}