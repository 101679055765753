import React,{useEffect} from 'react'
import Success from "../Images/Success.svg";
import { Modal, Carousel, Button } from "react-bootstrap";
import DeleteIcon from "../Images/exclamation-triangle-grad.svg";
import i18n from "i18next";
import $ from "jquery";

function ClientStoryNotification({
    isshow,formdata,isdeleteshow,
    closedeleteNotification,
    deleteStory,
}) {


const closeNotification=()=>{
  closedeleteNotification();
}
const deletedata=()=>{
  deleteStory();
}
    return (
        <div>
{isdeleteshow &&(
<Modal className="addDemoModal" show={isdeleteshow} onHide={()=>closeNotification()}>
          <Modal.Header>
            <Modal.Title>{i18n.t("Delete_Section")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="deletenotification-body mb-4">
              <div className="deletenotification-icon">
                <span>
                  <img src={DeleteIcon} />
                </span>
              </div>
              <div className="deletenotification-data">
                <p>
                {i18n.t("You are about to delete the user story")}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-btn">
            <Button variant="outline-secondary mr-2" onClick={()=>closeNotification()}>
            {i18n.t("Cancel")}
            </Button>
            <Button  onClick={()=>deletedata()}variant="danger">
            {i18n.t("Delete")}
            </Button>
       
          </Modal.Footer>
        </Modal>
)}

            {/* Success Saved Story Notification Start */}
        {isshow &&
          <div className="passwordcopy-notification storytelling">
            <img src={Success} alt="Sucess"/>
            <h6 id="ShowPopUp">{formdata.StoryName} {i18n.t("Story_with_version")} {formdata.Version} {i18n.t("has_been_saved")}. View the story by clicking on ‘View Story’ option</h6>
          </div>
        }
        {/* {this.state.updateStoryStatus &&

          </div>
        }
        {/* {this.state.updateStoryStatus &&
          <div className="passwordcopy-notification storytelling">
            <img src={Success} />
            <h6>ForceTen Story with version V{this.state.version} has been updated .</h6>
          </div>
        } */}
        {/* Success Saved Story Notification End */}
        </div>
    )
}

export default ClientStoryNotification
