import React from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import jquery from "jquery";
import { store } from "../reducers/configureStore";
import BO2_popup from "../SuperAdmin/BO2_popup";
window.$ = window.jQuery = jquery;

export default class MetricDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewmore: false,
      data: [],
      boData: {
        SOCategoryID: 0,
        kpiData: {
          KPIMappingID: 0
        }
      }
    };
  }

    dealpopup = (data, e) => {

    e.preventDefault();


    this.setState({
      data: data
    });

    this.setState({
      viewmore: true
    });

    e.preventDefault();
  };

  onClose = e => {
    this.setState({ viewmore: false });
  };

  deleteKpi(data, e) {
    if (window.confirm("Are you sure you want to delete metric detail?")) {
      const boData = this.state.boData;
      boData.SOCategoryID = this.props.SOCategoryID;

      boData.kpiData = data;
      this.setState({
        boData: boData
      });

      axiosInstance
        .post(
          `${LocalApiBaseUrl}Master/DeleteKpiMetricMasterTemplate?kpiMappingId=${data.KPIMappingID}`
        )
        .then(response => {
          window.alert(response.data.StatusMessage);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });



      store.dispatch({
        type: "DELETE_BO_MASTER",
        payload: this.state.boData
      });
    }
  }

    render() {

      const options = {
        loop: true,
        margin: 24,
        responsiveClass: "",
        dots: false,
        responsive: {
          0: {
            items: 1,
            nav: true
          },
          600: {
            items: 2,
            nav: false
          },
          1000: {
              items: 3,
              nav: true,
              loop: false
          },
          1440: {
              items: 4,
              nav: true,
              loop: false
            },
            1600: {
                items: 4,
                nav: true,
                loop: false
            },
            1800: {
                items: 5,
                nav: true,
                loop: false
          }
        }
      };
    return (
      <>
      <div className="custom-carousel-padding">
        <OwlCarousel options={options} className={`owl-carousel`}>
          {this.props.Details.map(eachDetails => {
            let AttrValue = "";
            let metricName = "";
            let unit = "";
            let metricData = "";
            let Description = "";
            let ToolTip = "";
            let ValueBlock = true;
           
            let attributeLabelsForBO = this.props.attributeLabels.filter(z=>z.ApplicableForDD===false);
            let BenchmarkName=[];
            if(attributeLabelsForBO.length>0)
              BenchmarkName=[attributeLabelsForBO[0].AttributeName1,attributeLabelsForBO[0].AttributeName2];
              
           let BenchmarkValue =[eachDetails.Attribute1Value_En,eachDetails.Attribute2Value_En];
          
            
            metricName = eachDetails.MetricName;
            unit = eachDetails.MetricUnit;
            unit = unit.trim();

            let statusColor = "item gray-color";
            if (eachDetails != undefined) {
              ToolTip =
                "Lower Threshold:" +
                (eachDetails.LowerThreshold || "NA") +
                " , Upper Threshold:" +
                (eachDetails.UpperThreshold || "NA");

              metricData = eachDetails.MetricValue;

                  Description =
                  eachDetails.KPIDesp
                    ? eachDetails.KPIDesp
                    : ""; 
                  
                    if (eachDetails.RAGStatus != undefined && eachDetails.RAGStatus != null) {
                      switch (eachDetails.RAGStatus.toLowerCase()) {
                        case "grey":
                          statusColor = "item gray-color";
                          break;
                        case "red":
                          statusColor = "item red-color";
                          break;
                        case "orange":
                          statusColor = "item amber-color";
                          break;
                        case "green":
                          statusColor = "item green-color";
                          break;
                        case "amber":
                          statusColor = "item amber-color";
                          break;
                        default:
                        // code block
                      }
                    }
            }

            return (
              <div className={`${statusColor}`} data-place="bottom" data-tip={ToolTip}>
             
                      <div className="item-left">   
                      {ValueBlock ? (
                        <>
                          <p className="tile-metric-value">                            
                            {
                              (unit)                              
                            }
                        </p>
              </>
            ) : null}        
                        <p className="tile-metric-desc" data-tip data-for={'customTooltip' + eachDetails.KPIMappingID}>
                            {metricName}
                        </p>
                      </div> 
                      <div className="item-right">
                          {Description ? (
                            <p className="tile-metric-name" data-tip data-for={'customTooltip_desc' + eachDetails.KPIMappingID}>
                              {Description}
                            </p>
                          ) : (
                            <p className="tile-metric-name">&nbsp;</p>
                          )}
                         
                  </div>
                  {this.props.roledata != undefined &&
                  (this.props.roledata.includes("Edit") ||
                    this.props.roledata.includes("Delete")) ? (
                      <div className="dropdown dropleft">
                      <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                        <div className="dropdown-menu">
                        {this.props.roledata.includes("Delete")&&
                        <a
                          href="javascript:void(0)"
                          className="dropdown-item"
                          onClick={this.deleteKpi.bind(this, eachDetails)}
                        >
                          <span className="delete-icon"></span>Remove
                        </a>
                        }
                        {this.props.roledata.includes("Edit")&&
                        <a
                          href="javascript:void(0)"
                          className="dropdown-item"
                          onClick={this.dealpopup.bind(this, eachDetails)}
                        >
                          <span className="edit-icon"></span>Edit
                        </a>
                        }
                        </div>
                      </div>
                      ): (
                    <>
                    </>
                  )     
                  
                  }
                
              </div>
            );
          })}

      </OwlCarousel>
      </div>
        {this.state.viewmore ? (
          <BO2_popup
            SOCategoryID={this.props.SOCategoryID}
            type={this.props.SOCategoryName}
            kpiData={this.state.data}
            show={this.state.viewmore}
            isRelatorBlockForOffering={this.props.isRelatorBlockForOffering}
            // attributeLabels={this.props.attributeLabels}
            onClose={this.onClose.bind(this)}
            opsgrp={this.props.opsgrp}
            filterDetails={this.props.filterDetails}
            ShowPop={
              this.props.roledata != undefined &&
              (this.props.roledata.includes("Edit") ||
                this.props.roledata.includes("Add"))
            }
            roledata={this.props.roledata}
          />
        ) : null}
      </>
    );
  }
}
