import React, { Component } from "react";
import BOContainer from "../SuperAdmin/BO";
import HumanMachineTalent from "../SuperAdmin/HumanMachine";
import WorkOrchestration from "../WorkOrchestration/WorkOrchestration_Common";
import Tools from "./Tools";
import i18n from "i18next";
import DiverseDataContainer from "./DiverseDataContainer";
import InsightsIntelligence from "../SuperAdmin/InsightsIntelligence";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import { LocalApiBaseUrl, getPrevMonth } from "../Shared/Constant";
import { setUserFeatures} from "../Shared/Common"
import { store } from "../reducers/configureStore";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import _ from "lodash";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class DashboardCntContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SuperAdmin",
      params: {
        Status: null,
        MonthDt: null,
        DealID: null,
      },
      active: false,
      filter: { OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null },
      postData: [],
      roledata: [],
      featureData: [],
      saveEnable: "",
      submitEnable: "",
      publishEnable: "",
      publishStatus: false,
      saveStatus: false,
      submitStatus: false,
      headerLabel: "",
      isActive: false,
      isRefreshFromWO: false,
    };
    this.handlePost.bind(this);
    this.getOfferingAPICallback = this.getOfferingAPICallback.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { filterDetails, compDetails } = this.props;
    if (
      this.props.compDetails.component != undefined &&
      this.props.compDetails.component != prevProps.compDetails.component
    ) {
      this.getInIHeaderLabel(prevProps.compDetails.component);
    }
    if (
      filterDetails &&
      prevProps.filterDetails &&
      filterDetails.offering &&
      filterDetails.process &&
      (filterDetails.offering !== prevProps.filterDetails.offering ||
        filterDetails.process !== prevProps.filterDetails.process)
    ) {
      this.getBOApiData(filterDetails);
    }
  }
  componentDidMount() {
    const { filterDetails, compDetails } = this.props;
    if (filterDetails && filterDetails.offering && filterDetails.process) {
      this.getInIHeaderLabel(compDetails.component);
      this.getBOApiData(filterDetails);
    }
    this.getFeatureDetails();
    this.getUom();
    if (this.props.filterDetails) {
      if (this.props.filterDetails.process !== 0) this.getAttributes(this.props.filterDetails.process);
    }
  }
  getOfferingAPICallback(flag) {
    let _self = this;
    _self.setState(
      {
        isRefreshFromWO: flag,
      },
      function() {
        if (_self.state.isRefreshFromWO) {
          _self.getBOApiData(_self.props.filterDetails);
        }
      }
    );
  }

  getUom() {
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetAllUnits`, {
        params: { languageId: store.getState().CurrentFilter.languageData.id },
      })
      .then((response) => {
        store.dispatch({
          type: "SAVE_STANDARD_UNITS",
          payload: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });


  }
    getBOApiData =  async (
    offeringIdparam = {
      Client: 0,
      process: 0,
      Locations: 0,
      Month: getPrevMonth(),
    }
  ) => {
    let filterProcess = this.props.filterDetails == undefined ? 0 : this.props.filterDetails.offering;
    let todayDate = "";

    if (
      (store.getState().BOMasterReducers["BO"].length == 0 &&
        store.getState().humanMasterReducers["humanandmachine"].length == 0 &&
        store.getState().intelligentInsightMasterReducers["insightandintelligence"].length == 0 &&
        store.getState().diverseMasterReducers["diverse"].length == 0 &&
        store.getState().WOtileMasterReducers["tile"].length == 0) ||
      this.state.isRefreshFromWO
    ) {
      this.setState({ isActive: true });
      await setUserFeatures(undefined,offeringIdparam.process)
      axiosInstance
        .get(`${LocalApiBaseUrl}Offering/GetOfferingTemplate`, {
          params: { OfferingId: offeringIdparam.process, LanguageID: store.getState().CurrentFilter.languageData.id },
        })
        .then((response) => {
          if (response.data != null) {
            store.dispatch({
              type: "OFFERING_ISRELATORBLOCK",
              payload: response.data[0].IsRelatorBlock,
            });
            store.dispatch({
              type: "INITIALISE_BO_MASTER",
              payload: response.data[0].TabData,
            });
            store.dispatch({
              type: "INITIALISE_MASTER",
              payload: response.data[1].TabData,
            });
            store.dispatch({
              type: "INITIALISEINSIGHT_MASTER",
              payload: response.data[2].TabData,
            });
            store.dispatch({
              type: "INITIALISEDIVERSE_MASTER",
              payload: response.data[3].TabData,
            });
            store.dispatch({
              type: "INITIALISEWO_MASTER",
              payload: response.data[4].TabData,
            });
            this.setState({
              boData: response.data[0].TabData,
              isRelatorBlockForOffering: response.data[0].IsRelatorBlock,
              isActive: false,
            });
          }
        }).catch((error)=>{
            trycatchAlertPopup(error);
          this.setState({ isActive: false });
        });
    } else {
      var data = store.getState();
      this.setState({ boData: data.BOMasterReducers["BO"] });
    }
    this.setState({ isRefreshFromWO: false });
  };

  getInIHeaderLabel = (componentID) => {
    if (
      this.props.filterDetails != undefined &&
      this.props.filterDetails.process != null &&
      parseInt(this.props.filterDetails.process) > 0
    ) {
      let filterProcess = this.props.filterDetails.process;
      let soCategory = 0;
      switch (componentID) {
        case "BO":
          soCategory = 1;
          break;
        case "HumanMachineTalent":
          soCategory = 2;
          break;
        case "WorkOrchestration":
          soCategory = 3;
          break;
        case "InsightsIntelligence":
          soCategory = 4;
          break;
        case "DiverseData":
          soCategory = 5;
          break;
      }

      if (filterProcess != 0 && soCategory != 0) {
        let headerData = soCategory == 4 ? i18n.t("Key_Metrics&SLA+") : "";

        axiosInstance
          .get(`${LocalApiBaseUrl}Configurator/GetHeaderLabelText`, {
            params: {
              offeringID: filterProcess,
              soCategoryID: soCategory,
              languageID: store.getState().CurrentFilter.languageData.id,
            },
          })
          .then((response) => {
            if (response.status == "200") {
              if (response.data.length > 0) headerData = response.data[0].Value;
            }

            this.setState({ headerLabel: headerData });
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
  
  
      }
    }
  };

  SaveClick() {
    this.handlePost("Save");
  }

  SubmitClick() {
    this.handlePost("Submit");
  }

  PublishClick() {
    this.handlePost("Publish");
  }
  ResetClick() {
    this.handleReset();
  }
  LastMonthClick() {
    this.handleLastMonth();
  }
  handleLastMonth() {
    var filter = this.state.filter;
    filter.LocationID = this.props.filterDetails.Locations;
    filter.OrgEntityID = this.props.filterDetails.Client;
    filter.OfferingID = this.props.filterDetails.process;
    filter.MonthDT = this.props.filterDetails.Month;
    this.setState({
      active: true,
    });

    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetLastMonthData`, {
        params: {
          OrgEntityID: filter.OrgEntityID,
          LocationID: filter.LocationID,
          MonthDT: filter.MonthDT,
          OfferingID: filter.OfferingID,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          store.dispatch({
            type: "INITIALISE_BO",
            payload: response.data[0].TabData,
          });
          store.dispatch({
            type: "INITIALISE",
            payload: response.data[1].TabData,
          });
          store.dispatch({
            type: "INITIALISEINSIGHT",
            payload: response.data[2].TabData,
          });
          store.dispatch({
            type: "INITIALISEDIVERSE",
            payload: response.data[3].TabData,
          });
          store.dispatch({
            type: "INITIALISEIOJ",
            payload: response.data[5].IntelTabData == null ? [] : response.data[5].IntelTabData,
          });
          store.dispatch({
            type: "INITIALISEWO",
            payload: response.data[4].TabData,
          });
        }
        if (response.status == "200") {
          this.setState({ active: false });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });


  }
  handleReset() {
    var filter = this.state.filter;
    filter.LocationID = this.props.filterDetails.Locations;
    filter.OrgEntityID = this.props.filterDetails.Client;
    filter.OfferingID = this.props.filterDetails.process;
    filter.MonthDT = this.props.filterDetails.Month;
    this.setState({
      active: true,
    });
    if (filter.OrgEntityID && filter.LocationID && filter.MonthDT && filter.OfferingID) {
      axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetConfiguratorData`, {
          params: {
            OrgEntityID: filter.OrgEntityID,
            LocationID: filter.LocationID,
            MonthDT: filter.MonthDT,
            OfferingID: filter.OfferingID,
          },
        })
        .then((response) => {
          store.dispatch({
            type: "INITIALISE_BO_MASTER",
            payload: response.data[0].TabData,
          });
          store.dispatch({
            type: "INITIALISE_MASTER",
            payload: response.data[1].TabData,
          });
          store.dispatch({
            type: "INITIALISEINSIGHT_MASTER",
            payload: response.data[2].TabData,
          });
          store.dispatch({
            type: "INITIALISEDIVERSE_MASTER",
            payload: response.data[3].TabData,
          });
          store.dispatch({
            type: "INITIALISEWO_MASTER",
            payload: response.data[4].TabData,
          });
          if (response.status == "200") {
            this.setState({ active: false });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });


    }
  }

  getFeatureDetails() {
    let operation = [];
    let featureDetails1 = this.props.roledata;
    let operationArray;
    featureDetails1.map((i) => {
      if (i.FeatureName === "Master Template Configurator") {
        operationArray = i.Operations;
        return operationArray;
      }
    });

    this.setState({ roledata: operationArray });
  }

  handlePost(status) {
    var params = this.state.params;
    params.Status = status;
    params.MonthDt = this.props.filterDetails.Month;
    params.DealID = this.props.filterDetails.Client;
    var filter = this.state.filter;

    params.MonthDt = this.props.filterDetails.Month;
    filter.LocationID = this.props.filterDetails.Locations;
    filter.OrgEntityID = this.props.filterDetails.Client;
    filter.OfferingID = this.props.filterDetails.process;
    filter.MonthDT = this.props.filterDetails.Month;
    this.setState({
      params: params,
      active: true,
    });
    this.CreateSaveAndSubmitData();
    var data = {
      ConfiguratorDataModels: this.state.postData,
      ConfiguratorRequestParamsModel: this.state.params,
      FiltersIDsVM: filter,
    };
    axiosInstance.post(`${LocalApiBaseUrl}Configurator/PostConfiguratorData`, data).then((response) => {
      if (response.data.IsSuccess) {
        switch (status) {
          case "Save":
            alert("Data saved successfully");
            break;
          case "Submit":
            alert("Data submitted successfully");
            break;
          case "Publish":
            alert("Data published successfully");
          default:
            break;
        }
        this.setState({ active: false });
      } else {
        alert("Data Could not updated successfully, please contact System Administrator");
      }
    })
    .catch((error) => {
      trycatchAlertPopup(error);
    });

  }

  CreateSaveAndSubmitData() {
    this.state.postData = [];
    var data = store.getState();
    var dataParam = {
      TabName: null,
      TabData: [],
    };

    var BOData = data.BOReducers["BO"];
    dataParam.TabName = "Business Outcome";
    dataParam.TabData = [...BOData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    var HMData = data.humanReducers["humanandmachine"];
    dataParam.TabName = "Human & Machine Talent";
    dataParam.TabData = [...HMData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    var IIData = data.intelligentInsightReducers["insightandintelligence"];
    dataParam.TabName = "Insights & Intelligence";
    dataParam.TabData = [...IIData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    var DiverseData = data.diverseReducers["diverse"];
    dataParam.TabName = "Diverse Data";
    dataParam.TabData = [...DiverseData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    var WOData = data.WOtileReducers["tile"];
    dataParam.TabName = "Work Orchestration";
    dataParam.TabData = [...WOData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
  }

  getAttributes = (selectedOffering) => {
    axiosInstance
      .get(`${LocalApiBaseUrl}Attribute/GetAttributeCategory`, {
        params: { OfferingID: selectedOffering },
      })

      .then((response) => {
        let mappingData = response.data;
        let finalMappingData = mappingData.filter((attr) => attr.IsActive == true);

        this.setState({ attributeLabels: finalMappingData }, () => {
          //add the labels into the store

          store.dispatch({
            type: "INITIALISE_ATTRIBUTE_LABELSMTC",
            payload: finalMappingData,
          });

          //All offerings will have attribute labels
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });


  };

  render() {
    return (
      <>
        {!_.isNil(this.props.filterDetails) &&
        (_.has(this.props.filterDetails, "process") &&
          !_.isNil(this.props.filterDetails.process) &&
          this.props.filterDetails.process != "0") ? (
          <>
            <LoadingOverlay
              fadeSpeed={10}
              spinner={<SynopsLoader />}
              active={this.state.isActive}
            >
              {this.props.compDetails != undefined && this.props.compDetails.component === "BO" ? (
                <BOContainer
                  SODetails={this.props.SODetails}
                  filterDetails={this.props.filterDetails}
                  roledata={this.state.roledata}
                  active={this.state.isActive}
                />
              ) : null}
              {this.props.compDetails != undefined && this.props.compDetails.component === "HumanMachineTalent" ? (
                <HumanMachineTalent
                  SODetails={this.props.SODetails}
                  filterDetails={this.props.filterDetails}
                  roledata={this.state.roledata}
                />
              ) : null}
              {this.props.compDetails != undefined && this.props.compDetails.component === "WorkOrchestration" ? (
                <WorkOrchestration
                  screen={this.state.screen}
                  SODetails={this.props.SODetails}
                  filterDetails={this.props.filterDetails}
                  roledata={this.state.roledata}
                  getOfferingAPICallback={this.getOfferingAPICallback}
                />
              ) : null}
              {this.props.compDetails != undefined && this.props.compDetails.component === "InsightsIntelligence" ? (
                <InsightsIntelligence
                  headerLabel={this.state.headerLabel}
                  SODetails={this.props.SODetails}
                  filterDetails={this.props.filterDetails}
                  roledata={this.state.roledata}
                  fullroledata={this.props.roledata}
                />
              ) : null}
              {this.props.compDetails != undefined && this.props.compDetails.component === "DiverseData" ? (
                <DiverseDataContainer
                  SODetails={this.props.SODetails}
                  filterDetails={this.props.filterDetails}
                  roledata={this.state.roledata}
                />
              ) : null}
              {this.props.compDetails != undefined && this.props.compDetails.component === "Tools" ? (
                <Tools
                  SODetails={this.props.SODetails}
                  filterDetails={this.props.filterDetails}
                  roledata={this.state.roledata}
                />
              ) : null}
            </LoadingOverlay>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}
