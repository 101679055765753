import React, { Component } from "react";
import i18n from "i18next";
import _ from "lodash";
import moment from "moment";
import { store } from "../../reducers/configureStore";
import ViewPMM from "./ViewPMM";
import axiosInstance from "../../Shared/interceptor";
import { LocalApiBaseUrl } from "../../Shared/Constant";
import References from "../../Shared/References";
import servicecomponentIcon from "../../Images/ServiceComponentIcon.svg";

const singleServiceComponent = {
  ServiceComponentID: 0,
  ServiceComponent: "",
  AssessmentDate: "",
  IsConfigured: false,
  IsChecked: false,
  PMMGraphData: [],
};

export default class AddPMMService extends Component {
  constructor() {
    super();
    this.removeServiceModalShow = this.removeServiceModalShow.bind(this);
    this.removeServiceModalClose = this.removeServiceModalClose.bind(this);
    this.addServiceModalShow = this.addServiceModalShow.bind(this);
    this.addServiceModalClose = this.addServiceModalClose.bind(this);
    this.addServiceModalSave = this.addServiceModalSave.bind(this);
    this.successServiceModalClose = this.successServiceModalClose.bind(this);
    this.handlesuggestionChange = this.handlesuggestionChange.bind(this);
    this.state = {
      dataStatus: "",
      removeServiceStatus: false,
      addServiceStatus: false,
      successServiceStatus: false,
      suggetionServiceStatus: false,
      viewPMMStatus: false,
      allChecked: false,
      PMMData: [],
      selectedServiceCompForRemoval: [],
      selectedServiceComp: [],
      selectedModelServiceComp: [],
      serviceComponentDetails: [],
      modifiedServiceComponentDetails: [],
      suggestionList: [],
      graphData: [],
      checkedItems: new Map(),
    };
  }

  /* STAND ALONE METHODS */
  componentDidMount() {
    this.GetMaturityData();
  }

  dispatchToStore(payload) {
    store.dispatch({
      type: "INITIALISEMaturity",
      payload: payload,
    });
  }

  componentWillReceiveProps(nextprops) {
   
    this.MapStoreDetailsToState();
  }
  GetMaturityData() {
    let filterClient = this.props.filterDetails.Client;
    let filterProcess = this.props.filterDetails.process;
    let filterLocations = this.props.filterDetails.Locations;
    let todayDate = this.props.filterDetails.Month;
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetMaturityPlanData`, {
        params: {
          OrgEntityID: filterClient,
          OfferingID: filterProcess,
          LocationID: filterLocations,
          MonthDT: todayDate,
          LanguageID: parseInt(store.getState().CurrentFilter.languageData.id),
        },
      })
      .then((response) => {

        this.setState({ PMMData: response.data }, () => {
          this.MapStoreDetailsToState();
        });
      });
  }
  MapStoreDetailsToState() {
    let modifiedServiceComponentDetails = _.map(
      this.state.PMMData,
      (eachItem) => {
        const serviceComponent = {
          ..._.cloneDeep(singleServiceComponent),
          ServiceComponentID: eachItem.ServiceComponentID,
          ServiceComponent: eachItem.ServiceComponent,
          AssessmentDate: moment
            .utc(eachItem.AssessmentDate)
            .local()
            .format("DD-MM-YY"),
          IsConfigured: eachItem.IsChecked,
          IsChecked: eachItem.IsChecked,
          PMMGraphData: eachItem.PMMGraphData,
        };
        return serviceComponent;
      }
    );

    let suggestionList = _.orderBy(
      modifiedServiceComponentDetails,
      function(o) {
        return new moment(o.AssessmentDate);
      },
      ["desc"]
    );

    suggestionList = _.map(suggestionList, (each) => {
      return {
        ...each,
        IsChecked: false,
      };
    });

    this.setState({
      serviceComponentDetails: this.state.PMMData,
      suggestionList: suggestionList,
      modifiedServiceComponentDetails,
      selectedServiceComp: modifiedServiceComponentDetails.filter(
        (item) => item.IsChecked === true
      ),
      selectedModelServiceComp: modifiedServiceComponentDetails.filter(
        (item) => item.IsChecked === true
      ),
      allChecked: modifiedServiceComponentDetails.every(
        (item) => item.IsChecked
      ),
    });
  }

  renderList = () => {
    //ADD SERVICE COMPONENT LIST
   
    return this.state.modifiedServiceComponentDetails.map((item) => (
      <li>
        <label
          className={`form-check ${item.IsChecked ? "labelselected" : " "} `}
        >
          <div className="service-name">
            <div className="service-icon">
              <img src={servicecomponentIcon} alt="" width="36" height="36" />
            </div>
            <div className="service-label">
              <span className="form-check-label">{item.ServiceComponent}</span>
              <span className="assessment-date">
                {i18n.t("Assessment_Date")}: {item.AssessmentDate}
              </span>
            </div>
          </div>
          <input
            key={item.ServiceComponentID}
            type="checkbox"
            name={item.ServiceComponent}
            value={item.ServiceComponent}
            className="form-check-input"
            checked={item.IsChecked}
            onChange={this.onAddServiceCompChange}
          />
          <span className="checkmark"></span>
        </label>
      </li>
    ));
  };

  /* REMOVE SERVICE COMPONENTS METHODS */
  removeServiceModalShow(type, input) {
   
    let {
      modifiedServiceComponentDetails,
      allChecked,
      selectedServiceComp,
      selectedModelServiceComp,
      selectedServiceCompForRemoval,
    } = this.state;

    if (type === "alert") {
      this.setState({
        removeServiceStatus: true,
        selectedServiceCompForRemoval: input,
      });
    } else {
      modifiedServiceComponentDetails = modifiedServiceComponentDetails.map(
        (item) =>
          item.ServiceComponent ===
          selectedServiceCompForRemoval.ServiceComponent
            ? { ...item, IsChecked: false }
            : item
      );
      allChecked = modifiedServiceComponentDetails.every(
        (item) => item.IsChecked
      );
      selectedServiceComp = selectedModelServiceComp = modifiedServiceComponentDetails.filter(
        (item) => item.IsChecked === true
      );

      this.setState(
        {
          modifiedServiceComponentDetails,
          allChecked,
          selectedModelServiceComp,
          selectedServiceComp,
          removeServiceStatus: false,
          selectedServiceCompForRemoval: [],
        },
        () => {
          this.dispatchToStore(this.state.modifiedServiceComponentDetails);
        }
      );
    }
  }
  removeServiceModalClose() {
   
    this.setState({
      removeServiceStatus: false,
      selectedServiceCompForRemoval: [],
    });
  }

  /* ADD SERVICE MODEL METHODS */
  addServiceModalShow() {
   
    this.setState({ addServiceStatus: true });
  }
  onAddServiceCompChange = (e) => {
   
    let itemName = e.target.name;
    let checked = e.target.checked;
    let {
      modifiedServiceComponentDetails,
      allChecked,
      selectedModelServiceComp,
    } = this.state;

    if (itemName === "checkAll") {
      allChecked = checked;
      modifiedServiceComponentDetails = modifiedServiceComponentDetails.map(
        (item) => ({
          ...item,
          IsChecked: checked,
        })
      );
    } else {
      modifiedServiceComponentDetails = modifiedServiceComponentDetails.map(
        (item) =>
          item.ServiceComponent === itemName
            ? { ...item, IsChecked: checked }
            : item
      );
      allChecked = modifiedServiceComponentDetails.every(
        (item) => item.IsChecked
      );
    }
    selectedModelServiceComp = modifiedServiceComponentDetails.filter(
      (item) => item.IsChecked === true
    );

    this.setState({
      modifiedServiceComponentDetails,
      allChecked,
      selectedModelServiceComp,
    });
  };
  addServiceModalSave() {
   
    let { modifiedServiceComponentDetails, selectedServiceComp } = this.state;
    selectedServiceComp = modifiedServiceComponentDetails.filter(
      (item) => item.IsChecked === true
    );

    //***** */ To dispatch the data to the reducers *****//
    this.dispatchToStore(modifiedServiceComponentDetails);
    this.setState({
      successServiceStatus: true,
      addServiceStatus: false,
      selectedServiceComp,
    });
  }
  addServiceModalClose() {
   
    let {
      modifiedServiceComponentDetails,
      allChecked,
      selectedServiceComp,
    } = this.state;

    modifiedServiceComponentDetails = _.map(
      modifiedServiceComponentDetails,
      (modifiedComp, index) => {
        let selectedComp = _.find(selectedServiceComp, function(each) {
          return each.ServiceComponentID === modifiedComp.ServiceComponentID;
        });
        if (!_.isNil(selectedComp)) {
          return {
            ...selectedComp,
          };
        } else {
          return {
            ...modifiedComp,
            IsChecked: false,
          };
        }
      }
    );

    allChecked = modifiedServiceComponentDetails.every(
      (item) => item.IsChecked
    );

    this.setState({
      addServiceStatus: false,
      selectedModelServiceComp: selectedServiceComp,
      modifiedServiceComponentDetails,
      allChecked,
    });
  }
  // REFERENCE HANDLE CHANGE //

  /* COMPONENT ADDITION SUCCESS MESSAGE */
  successServiceModalClose() {
   
    this.setState({
      successServiceStatus: false,
    });
  }

  /* SUGGESTION SELECTION METHOD */
  handlesuggestionChange = (e) => {
   
    let itemName = e.target.name;
    let checked = e.target.checked;
    let {
      modifiedServiceComponentDetails,
      allChecked,
      selectedServiceComp,
      selectedModelServiceComp,
    } = this.state;

    modifiedServiceComponentDetails = modifiedServiceComponentDetails.map(
      (item) =>
        item.ServiceComponent === itemName
          ? { ...item, IsChecked: checked }
          : item
    );

    selectedModelServiceComp = selectedServiceComp = modifiedServiceComponentDetails.filter(
      (item) => item.IsChecked === true
    );
    allChecked = modifiedServiceComponentDetails.every(
      (item) => item.IsChecked
    );
    this.setState({
      allChecked,
      modifiedServiceComponentDetails,
      successServiceStatus: true,
      selectedServiceComp,
      selectedModelServiceComp,
    });

    this.dispatchToStore(modifiedServiceComponentDetails);
  };

  /* SHOW GRAPH COMPONENT METHOD */
  viewComponentGraphs(serviceComponent, event) {
   
    this.setState({ viewPMMStatus: true, graphData: serviceComponent }, () => {
      // this.Hidestagestatus();
    });
  }

  closePMMassessment(e) {
    this.setState({
      viewPMMStatus: false,
    });
  }


  render() {
    return (
      <>
        {this.state.viewPMMStatus === false ? (
          <div className="addPMM-service">
            <div className="category-references dashboard-category-references ioj-maturityreferences">
              <References 
              {...this.props}
              IOJSectionHierarchyID={3} 
              SOCatID={0} 
              leverName="Maturity Plan" 
              referenceScreen="MaturityPlan" />
            </div>
            <div className="maturity-plan">
              <div className="row">
                <div className="col-lg-12">
                  {this.state.selectedServiceComp.length !== 0 ? (
                    <>
                      <div className="addservice-component">
                        <h4 className="stage-heading">
                          {i18n.t("Service_Component")}
                        </h4>
                      </div>
                      <div className="service-component-list">
                        {this.state.selectedServiceComp.map((item, key) => {
                          if (item.IsChecked) {
                            return (
                              <div className="service-component dashboard-maturity">
                                <div className="service-icon">
                                  {/* <img src={servicecomponentIcon} alt="" width="48" height="48" /> */}
                                </div>
                                <div className="service-data">
                                  <h4>{item.ServiceComponent}</h4>
                                  <p>
                                    {i18n.t("Assessment_Date")}:{" "}
                                    <span>{item.AssessmentDate}</span>
                                  </p>
                                  <p>
                                    {i18n.t("Score")}:{" "}
                                    <span>
                                      {item.PMMGraphData[0].OverallScore}
                                    </span>
                                  </p>
                                </div>

                                <span
                                  className="view-btn eye-icon"
                                  onClick={this.viewComponentGraphs.bind(
                                    this,
                                    item
                                  )}
                                >
                                  <span>{i18n.t("View_Assessment")}</span>
                                </span>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </>
                  ) : (
                    <div className="title-card">
                      <h3>{i18n.t("Maturity Plan")}</h3>
                      <small className="form-text text-muted">
                        <b>{i18n.t("Note")}:</b>
                        {i18n.t("PMM Graph yet to be Configured")}
                      </small>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="viewPMM-service">
            <ViewPMM
              graphData={this.state.graphData}
              closePMMassessment={this.closePMMassessment.bind(this)}
            />
          </div>
        )}
      </>
    );
  }
}
