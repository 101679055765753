import React, { Component } from "react";
import axiosInstance from "../Shared/interceptor";
import { store } from "../reducers/configureStore";
import DayWiseTrends from "../DayWiseTrends/DayWiseTrends";
import WeekWiseTrends from "./WeekWiseTrends";

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class UserTrendsList extends Component {
  constructor() {
    super();
    this.state = {
      roleData: [],

      featureData: [],
    };

    this.getRoleData = this.getRoleData.bind(this);
  }

  getRoleData() {
    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let featureDetails1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: featureDetails1 });
    });
  }

  componentWillMount() {
    this.getRoleData();
  }

  componentDidMount() {
    this.getRoleData();
  }

  render() {
    const { isDayWiseClicked, filterDetails, isWeekWiseClicked } = this.props;
    const { featureData } = this.state;
    return (
      <>
        {
          <>
            {isDayWiseClicked &&
            featureData.filter(
              (data) =>
                String(data.Submenu) === "Day Wise" &&
                data.Operations.includes("View")
            ).length > 0 ? (
              <DayWiseTrends filterDetails={filterDetails} />
            ) : null}
            {isWeekWiseClicked &&
            featureData.filter(
              (data) =>
                String(data.Submenu) === "Week Wise" &&
                data.Operations.includes("View")
            ).length > 0 ? (
              <WeekWiseTrends filterDetails={filterDetails} />
            ) : null}
          </>
        }
      </>
    );
  }
}
