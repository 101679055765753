import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { regexForTextAreas } from "../Shared/Constant";
import { regexForFields } from '../Shared/Constant';
import i18n from "i18next";
import DealSummaryReport from "./DealSummaryReport";
import DealDetailedReport from "./DealDetailedReport";
import axiosInstance from '../Shared/interceptor';
import {trycatchAlertPopup} from "../Shared/Constant";
import { LocalApiBaseUrl } from '../Shared/Constant';
import LoadingOverlay from 'react-loading-overlay';
import SynopsLoader from "../Shared/SynopsLoader";

class DealReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Period: { From: null, To: new Date() },
            Status: [{ name: "Delivered", value: false,id:"Delivered" }, { name: "In-Flight", value: false,id:"Inflight"}, { name: "In-Concept", value: false ,id:"Concept"}, { name: "On-Hold", value: false, id:"Onhold" }],
            CategoryStatusOptions: [{ name: "Outcome delivered", color: "green", value: "1",id:"Outcome_delivered" },
            { name: "Outcome yet to be achieved", color: "orange-clr", value: "2",id:"Outcome_yet_to_be_achieved" },
            { name: "Outcome not achieved", color: "red-clr", value: "3",id:"Outcome_not_achieved" }],
            CategoryStatus: "",
            categories: [],
            CreatedUser: sessionStorage.getItem("EnterpriseID"),
            dispalyInitiatives: false,
            getInitiativesApiCall: false,
            addCategoryApiCall: false,
            deleteCategoryApiCall: false,
            clientName:null,
            GetIOJCategorySuggestionApiCall: false,
            groupByCategories: [],
            groupByCategoriesSuggestions: [],
            CategoryName: "",
            CategoryOutcome: '',
            MaturityPhase: 'Foundation',
            generateSummaryReport: false,
            generateDetailedReport: false,
            categorySuggestions: [],
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            recentCategories: [],
            initiativesApiResponse: [],
            errors: {
                InitiativesError: '',
                period: { From: '', To: '' },
                status: "",
                CategoryOutcome: '',
                CategoryName: '',
                CategoryStatus: '',
                addCategoryApiCallError: '',
                deleteCategoryApiCallError: ''
            }
        }
    }
    handelecCalendarIcon = (val) => {
        if (val === "From") {
            document.getElementById('DealReportFromDate').click();
        } else {
            document.getElementById('DealReportToDate').click();
        }

    }
    handleDateChange = (val, date) => {
        let dat = date;
        let period = this.state.Period;
        if (val === 1) {
            if (dat <= this.state.Period.To) {
                let err = this.state.errors;
                err.addCategoryApiCallError="";
                err.period.From = "";
                period.From = date;
                this.setState({ Period: period, errors: err, dispalyInitiatives: false, })
            }
            else {
                let err = this.state.errors;
                err.addCategoryApiCallError="";
                err.period.From = "From date should not be greater than To date";
                period.From = date;
                this.setState({ Period: period, errors: err, dispalyInitiatives: false, })
            }

        } else {
            if (dat >= this.state.Period.From) {
                let err = this.state.errors;
                err.period.From = "";
                err.addCategoryApiCallError="";
                period.To = date;
                this.setState({ Period: period, errors: err, dispalyInitiatives: false, })
            }
            else {
                let err = this.state.errors;
                err.addCategoryApiCallError="";
                err.period.From = "To date should not be lesser than From date";
                period.To = date;
                this.setState({ Period: period, errors: err, dispalyInitiatives: false, })
            }

        }

    }
    onStatusChange = (val, event) => {
        let err = this.state.errors;
        err.status = "";
        err.addCategoryApiCallError="";
        let updatedStatus = this.state.Status.map((e) => {
            if (e.name === val) {
                e.value = event.target.checked
                return e;
            }
            else {
                return e;
            }
        })
        this.setState({ Status: updatedStatus, errors: err, dispalyInitiatives: false, })
    }
    onCategoryStatus = (val) => {
        this.setState({ CategoryStatus: val, showCategoryDropdown: false });
    }
    initiativeCategory = (e) => {
        let err = this.state.errors;
        if (e.target.value) {
            if (regexForFields.test(e.target.value)) {
                err.CategoryName = "";
                err.addCategoryApiCallError="";
                let recentCategories = this.state.recentCategories;
                let filteredCategories = [];
                if (recentCategories.length) {
                    filteredCategories = recentCategories.filter((each) => { if (each.CategoryName.toLowerCase().startsWith(e.target.value.toLowerCase())) { return each } });
                    this.groupBy(filteredCategories, "Suggestions",this.state.MaturityPhase);
                }
                this.setState({ CategoryName: e.target.value, errors: err, categorySuggestions: filteredCategories })
            } else {
                err.addCategoryApiCallError="";
                err.CategoryName = "Initiative Category should not contain special characters";
                let recentCategories = this.state.recentCategories;
                let filteredCategories = [];
                if (recentCategories.length) {
                    filteredCategories = recentCategories.filter((each) => { if (each.CategoryName.toLowerCase().startsWith(e.target.value.toLowerCase())) { return each } });
                    this.groupBy(filteredCategories,"Suggestions",this.state.MaturityPhase);
                }
                this.setState({ CategoryName: e.target.value, errors: err, categorySuggestions: filteredCategories })
            }
        } else {
            err.CategoryName = "";
            err.addCategoryApiCallError="";
            let recentCategories = this.state.recentCategories;
            let filteredCategories = [];
            if (recentCategories.length) {
                filteredCategories = recentCategories.filter((each) => { if (each.CategoryName.toLowerCase().startsWith(e.target.value.toLowerCase())) { return each } });
                this.groupBy(filteredCategories, "Suggestions",this.state.MaturityPhase);
            }
            this.setState({ CategoryName: e.target.value, errors: err, categorySuggestions: filteredCategories })
        }
    }
    CategoryOutcome = (e) => {
        let err = this.state.errors;
        if (e.target.value) {
            if (regexForTextAreas.test(e.target.value)) {
                err.CategoryOutcome = "";
                err.addCategoryApiCallError="";
                this.setState({ CategoryOutcome: e.target.value, errors: err })
            } else {
                err.addCategoryApiCallError="";
                err.CategoryOutcome = "Category Outcome should not contain special characters";
                this.setState({ CategoryOutcome: e.target.value, errors: err })
            }
        } else {
            err.addCategoryApiCallError="";
            err.CategoryOutcome = "";
            this.setState({ CategoryOutcome: e.target.value, errors: err })
        }

    }
    MaturityPhaseChange = (e) => {
        let updatedInitiatives = this.state.initiativesApiResponse.map((e) => {
            e.valueChecked = false;
            return e;
        })
        this.groupBy(this.state.recentCategories, "",e.target.options[e.target.selectedIndex].value);
        this.setState({ ...this.state, MaturityPhase: e.target.options[e.target.selectedIndex].value, initiativesApiResponse: updatedInitiatives })
    }
    onInitiativeSelect = (val, event) => {
        let initiatives = this.state.initiativesApiResponse;
        if (initiatives.length) {
            let updatedInitiatives = initiatives.map((e) => {
                if (e.ProjectInitiativeName == val) {
                    e.valueChecked = event.target.checked;
                    return e;
                }
                else {
                    return e;
                }
            })
            this.setState({ ...this.state, initiativesApiResponse: updatedInitiatives });
        }
    }
    clearValues = () => {
        let updatedInitiatives = this.state.initiativesApiResponse.map((e) => {
            e.valueChecked = false;
            return e;
        })

        this.setState({ ...this.state, CategoryStatus: "", CategoryName: "", CategoryOutcome: '', initiativesApiResponse: updatedInitiatives, errors: { ...this.state.errors, CategoryOutcome: "", CategoryStatus: "", CategoryName: "",addCategoryApiCallError:"" } });
    }
    addCategory = () => {
        if( this.state.CategoryName.trim().length &&  this.state.CategoryOutcome.trim().length){
        this.setState({ ...this.state, addCategoryApiCall: true });
        let intelOpsIDs = [];
        this.state.initiativesApiResponse.filter((e) => {
            if (e.valueChecked === true) {
                intelOpsIDs.push(e.ID)
            }
        })

        let fromdate = this.state.months[new Date(this.state.Period.From).getMonth()] + "-" + new Date(this.state.Period.From).getFullYear();
        let todate = this.state.months[new Date(this.state.Period.To).getMonth()] + "-" + new Date(this.state.Period.To).getFullYear();
        let data = {
            "IsStatusDelivered": this.state.Status.filter((e) => { if (e.name === "Delivered") return e })[0].value ? 1 : 0,
            "IsStatusInConcept": this.state.Status.filter((e) => { if (e.name === "In-Concept") return e })[0].value ? 1 : 0,
            "IsStatusInFlight": this.state.Status.filter((e) => { if (e.name === "In-Flight") return e })[0].value ? 1 : 0,
            "IsStatusOnHold": this.state.Status.filter((e) => { if (e.name === "On-Hold") return e })[0].value ? 1 : 0,
            "InitiativePeriodFrom": fromdate,
            "MaturityPhase": this.state.MaturityPhase,
            "InitiativePeriodTo": todate,
            "CategoryName": this.state.CategoryName,
            "CategoryOutcome": this.state.CategoryOutcome,
            "CategoryStatus": this.state.CategoryStatus,
            "InitiativesId": intelOpsIDs,
            "FiltersIDsVM": {
                "OrgEntityID": this.props.filterDetails.Client,
                "OfferingID": this.props.filterDetails.process,
                "LocationID": this.props.filterDetails.Locations,
                "MonthDT": this.props.filterDetails.Month
            }

        }
        axiosInstance.post(`${LocalApiBaseUrl}IoJDealReport/AddIoJDealReportCategory`, data)
            .then((response) => {
                if (response.data.IsSuccess) {
                    this.groupBy(response.data.iOJDealReportCategoryModels,"",this.state.MaturityPhase);
                    this.setState({ ...this.state, addCategoryApiCall: false, errors: { ...this.state.errors, addCategoryApiCallError: '' }, recentCategories: response.data.iOJDealReportCategoryModels })
                } else {
                    this.setState({ ...this.state, addCategoryApiCall: false, errors: { ...this.state.errors, addCategoryApiCallError: "Error occured please try again" } })
                }
            }
            ).catch((error) => {
                this.setState({ ...this.state, addCategoryApiCall: false, errors: { ...this.state.errors, addCategoryApiCallError: `${error.message}` } })
                trycatchAlertPopup(error);
      
            })

        let addedCategories = this.state.categories;
        if (addedCategories.length) {
            let categoryExisted = addedCategories.filter((e) => {
                if (e.CategoryName === this.state.CategoryName && e.MaturityPhase === this.state.MaturityPhase) {
                    return e;
                }
            })
            if (categoryExisted.length) {
                let updatedCategories = addedCategories.map((e) => {
                    if (e.CategoryName === this.state.CategoryName && e.MaturityPhase === this.state.MaturityPhase) {
                        let intelOps = this.state.initiativesApiResponse.filter((each) => {
                            if (each.valueChecked === true) return each;
                        })
                        e.CategoryStatus = this.state.CategoryStatus;
                        e.CategoryOutcome = this.state.CategoryOutcome;
                        e.intelOps = intelOps;
                        return e;
                    } else {
                        return e;
                    }
                })
                this.setState({ ...this.state, categories: updatedCategories }, () => { this.clearValues() })
            } else {
                let intelOps = this.state.initiativesApiResponse.filter((e) => {
                    if (e.valueChecked === true) return e;
                })

                addedCategories.push({ CategoryStatus: this.state.CategoryStatus, CategoryName: this.state.CategoryName, CategoryOutcome: this.state.CategoryOutcome, intelOps: intelOps, MaturityPhase: this.state.MaturityPhase });
                this.setState({ ...this.state, categories: addedCategories }, () => { this.clearValues() })
            }
        } else {
            let intelOps = this.state.initiativesApiResponse.filter((e) => {
                if (e.valueChecked === true) return e;
            })

            addedCategories.push({ CategoryStatus: this.state.CategoryStatus, CategoryName: this.state.CategoryName, CategoryOutcome: this.state.CategoryOutcome, intelOps: intelOps, MaturityPhase: this.state.MaturityPhase });
            this.setState({ ...this.state, categories: addedCategories }, () => { this.clearValues() })
        }
    }else{
        if(!this.state.CategoryName.trim().length){
            this.setState({errors: { ...this.state.errors, addCategoryApiCallError:"Initiative category is a required field"}});
        }else{
            this.setState({errors: { ...this.state.errors, addCategoryApiCallError:"Category outcome is a required field"}})
        }
        
    }
    }
    deleteCategory = (name, phase) => {
        this.setState({ ...this.state, deleteCategoryApiCall: true });
        let categories = this.state.categories;
        let Id = null;
        this.state.recentCategories.filter((eachValue) => { if (eachValue.CategoryName === name && eachValue.MaturityPhase === phase && eachValue.CreatedUser === this.state.CreatedUser) { Id = eachValue.CategorySnapShotID } });
        axiosInstance.post(`${LocalApiBaseUrl}IoJDealReport/DeleteIOjDealReportCategory?CategoryID=${Id}`)
            .then((response) => {
                if (response.data.IsSuccess) {
                    this.groupBy(response.data.iOJDealReportCategoryModels, "",this.state.MaturityPhase);
                    this.setState({ ...this.state, deleteCategoryApiCall: false, errors: { ...this.state.errors, deleteCategoryApiCallError: '' }, recentCategories: response.data.iOJDealReportCategoryModels })
                } else {
                    this.setState({ ...this.state, deleteCategoryApiCall: false, errors: { ...this.state.errors, deleteCategoryApiCallError: "Error occured please try again" } })
                }

            }
            ).catch((error) => {
                this.setState({ ...this.state, deleteCategoryApiCall: false, errors: { ...this.state.errors, deleteCategoryApiCallError: `${error.message}` } })
                trycatchAlertPopup(error);
      
            })
        let updatedCategories = categories.filter((e) => { if (e.MaturityPhase === phase, e.CategoryName === name) { } else { return e } });
        this.setState({ ...this.state, categories: updatedCategories })
    }
    selectCategory = (data) => {
        let uncheckInitiatives = this.state.initiativesApiResponse.map((e) => {
            e.valueChecked = false;
            return e;
        });
        let updatedinitiativesApiResponse = uncheckInitiatives.map((e) => {
            data.intelOps.map((each) => {
                if (e.ProjectInitiativeName === each.ProjectInitiativeName && each.MaturityPhase === this.state.MaturityPhase) {
                    e.valueChecked = true;
                }
            })
            return e;
        });
        let filteredCategories = this.state.recentCategories.filter((each) => { 
            if (each.CategoryName.toLowerCase().startsWith(data.CategoryName.toLowerCase())) { return each } });
        this.setState({ ...this.state,CategoryStatus: data.CategoryStatus, CategoryName: data.CategoryName, CategoryOutcome: data.CategoryOutcome, initiativesApiResponse: updatedinitiativesApiResponse },()=>{ this.groupBy(filteredCategories, "Suggestions",this.state.MaturityPhase)})
        
    }
    generateReport = (val) => {
        if (val === 1) {
            this.setState({ ...this.state, generateSummaryReport: true, generateDetailedReport: false, })
        } else {
            this.setState({ ...this.state, generateSummaryReport: false, generateDetailedReport: true, })
        }
    }
    closeReport = () => {
        this.setState({ ...this.state, generateSummaryReport: false, generateDetailedReport: false, })
    }
    getInitiatives = () => {
        this.setState({ ...this.state, getInitiativesApiCall: true, GetIOJCategorySuggestionApiCall: true })
        let selectedStatus = ""
        this.state.Status.map((e) => {
            if (e.value === true) {
                if (selectedStatus) {
                    selectedStatus = selectedStatus + "," + e.id;
                } else {
                    selectedStatus = e.id;
                }

                return e;
            }
        })
        let fromdate = this.state.months[new Date(this.state.Period.From).getMonth()] + "-" + new Date(this.state.Period.From).getFullYear();
        let todate = this.state.months[new Date(this.state.Period.To).getMonth()] + "-" + new Date(this.state.Period.To).getFullYear();
        let data = {
            "InitiativeStatus": selectedStatus,
            "From": fromdate,
            "To": todate,
            "FiltersIDsVM": {
                "OrgEntityID": this.props.filterDetails.Client,
                "OfferingID": this.props.filterDetails.process,
                "LocationID": this.props.filterDetails.Locations,
                "MonthDT": this.props.filterDetails.Month
            }

        }
        axiosInstance.post(`${LocalApiBaseUrl}IoJDealReport/GetIoJDealReportInitiatives`, data)
            .then((response) => {
                let updatedInitiatives = [];
                if (response.data.length) {
                    let clientName = response.data[0].DealName;
                    updatedInitiatives = response.data.map((e) => { return { ...e, valueChecked: false } });
                    this.setState({ ...this.state,clientName:clientName, initiativesApiResponse: updatedInitiatives, errors: { ...this.state.errors, InitiativesError: '' }, getInitiativesApiCall: false, dispalyInitiatives: true, categories: [], MaturityPhase: 'Foundation',CategoryName:"",CategoryOutcome:"",CategoryStatus:"" });
                }
                else {
                    let error = "Data not found, Please try with some other filters";
                    this.setState({ ...this.state, initiativesApiResponse: updatedInitiatives, errors: { ...this.state.errors, InitiativesError: error }, getInitiativesApiCall: false });
                }
            }).catch((error) => {
                this.setState({ ...this.state, initiativesApiResponse: [], errors: { ...this.state.errors, InitiativesError: `${error.message}` }, getInitiativesApiCall: false });
                trycatchAlertPopup(error);
      

            })
        axiosInstance.post(`${LocalApiBaseUrl}IoJDealReport/GetIOJCategorySuggestion`, data)
            .then((response) => {

                if (response.data.length) {
                    this.setState({ GetIOJCategorySuggestionApiCall: false, recentCategories: response.data });
                    this.groupBy(response.data, "",this.state.MaturityPhase);
                } else {
                    this.setState({ GetIOJCategorySuggestionApiCall: false, recentCategories: [], groupByCategories: [],groupByCategoriesSuggestions:[] });
                }
            }).catch((error) => {
                this.setState({ GetIOJCategorySuggestionApiCall: false, recentCategories: [], groupByCategories: [],groupByCategoriesSuggestions:[] });
                trycatchAlertPopup(error);
      

            })

    }
    groupBy = (objectArray,catsugg,phase) => {
       
        let group = [];
        objectArray.map((e) => {
            if (group.length) {
                let index = group.findIndex(x => x.name === e.CategoryName && e.MaturityPhase === phase);
                if (index === -1) {
                    group.push({ name: e.CategoryName, value: [e] ,MaturityPhase:e.MaturityPhase})
                } else {
                    group.map(val => {
                        if (val.name === e.CategoryName) {
                            val.value.push(e)
                        }
                    })
                }
            } else {
                group.push({ name: e.CategoryName, value: [e],MaturityPhase:e.MaturityPhase })
            }
        })
        if (catsugg === "Suggestions") {
            let currentstate = this.state;
            currentstate.groupByCategoriesSuggestions = group;
            this.setState({ currentstate})
        } else {
            let currentstate = this.state;
            currentstate.groupByCategories = group;
            this.setState({ currentstate})
        }

    }
    render() {
        return (
            <LoadingOverlay
                fadeSpeed={0}
                spinner={<SynopsLoader />}
                active={this.state.addCategoryApiCall || this.state.deleteCategoryApiCall}
                
            >
                <div className="ioj-deal-report">
                    <div className="row outcome-highlight">
                        <div className="col-md-12">
                            <LoadingOverlay
                                fadeSpeed={0}
                                spinner={<SynopsLoader />}
                                active={this.state.getInitiativesApiCall}
                                
                            >
                                <div class="row">
                                    <div className="col-md-5">
                                        <label id="period" className="ioj-display" for="period">{i18n.t("Initiative_Project_Period")}<span className="required">*</span></label>
                                        <div className="date-picker-holder p-r-20" tabIndex="5">
                                            <DatePicker className={this.state.Period.From ? "custom-date-picker p-t-10" : "custom-date-picker"} id="DealReportFromDate" selected={this.state.Period.From} onSelect={this.handleDateChange.bind(this, 1)} value={this.state.Period.From} dateFormat="MMM, yyyy" showMonthYearPicker />
                                            <span className={this.state.Period.From ? "dP-withData" : "dp-hover"}>{i18n.t("From")}</span>
                                            <FontAwesomeIcon className="calender-placment" onClick={this.handelecCalendarIcon.bind(this, "From")} icon={faCalendarAlt} />
                                        </div>
                                        <div className="date-picker-holder" tabIndex="5">
                                            <DatePicker className={this.state.Period.To ? "custom-date-picker p-t-10" : "custom-date-picker"} id="DealReportToDate" selected={this.state.Period.To} onSelect={this.handleDateChange.bind(this, 2)} value={this.state.Period.From} dateFormat="MMM, yyyy" showMonthYearPicker />
                                            <span className={this.state.Period.To ? "dP-withData" : "dp-hover"}>{i18n.t("To")}</span>
                                            <FontAwesomeIcon className="calender-placment" onClick={this.handelecCalendarIcon.bind(this, "To")} icon={faCalendarAlt} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <label id="period" className="ioj-display" for="period">{i18n.t("Initiative_Project_Status")}<span className="required">*</span></label>
                                        {this.state.Status.map((e) => {
                                            return (
                                                <label className="form-check">
                                                    <input className="form-check-input" type="checkbox" defaultChecked={e.value} onChange={this.onStatusChange.bind(this, e.name)} />
                                                    <span className="checkmark m-t-5"></span>
                                                    <span className="form-check-label">{i18n.t(e.name)}</span>
                                                </label>
                                            )
                                        })}

                                    </div>
                                    <div className="col-md-3 p-30">
                                        {this.state.Period.From && this.state.Period.To && this.state.Status.filter((e) => { if (e.value === true) return e }).length && !this.state.errors.period.From && !this.state.errors.period.To ?
                                            <button type="submit" onClick={this.getInitiatives} className={`initiativesButton`} value="getInitiatives" name="GetInitiatives">{i18n.t("Get_Initiatives")}</button> :
                                            <button type="submit" onClick={() => { }} className={`initiativesDisabledButton`} value="getInitiatives" name="GetInitiatives">{i18n.t("Get_Initiatives")}</button>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                        <p className="errorMsg">{this.state.errors.period.From || this.state.errors.InitiativesError || this.state.errors.period.To || this.state.errors.status ? `${this.state.errors.period.From || this.state.errors.period.To || this.state.errors.status || this.state.errors.InitiativesError}` : null}</p>
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </div>
                    {this.state.dispalyInitiatives ?
                        <div className="row">
                            <div className="col-md-8 border-rgiht">
                                <p className="font-bold-13">{i18n.t("dealReport_message1")}</p>
                                <div className="row outcome-highlight p-b-50">
                                    <div className="col-md-6">
                                        <input type="text" className="textbox" maxlength="50" onChange={this.initiativeCategory.bind(this)} value={this.state.CategoryName} name="initiativeCategory" placeholder={i18n.t("Initiative_Category")}></input>
                                        <p className="errorMsg">{this.state.errors.CategoryName}</p>
                                        <textarea placeholder={i18n.t("Category_Outcome")} rows="4" maxlength="250" name="CategoryOutcome" value={this.state.CategoryOutcome} onChange={this.CategoryOutcome.bind(this)} className="text-area" />
                                        <p className="errorMsg">{this.state.errors.CategoryOutcome}</p>
                                        <div className={`textbox ${this.state.showCategoryDropdown ? "focus" : ''}`} tabindex="0" autofocus="true"  id="CategoryStatusmenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            {!this.state.CategoryStatus ? <div className="category-status-item-selected"><p>{i18n.t("Category_Status")}</p></div> :
                                                this.state.CategoryStatusOptions.map(e => {
                                                    if (this.state.CategoryStatus === e.value) {
                                                        return (<div className="category-status-item-selected">
                                                            <p className={`category-status ${e.color}`} >{i18n.t(e.id)}</p>
                                                        </div>

                                                        )
                                                    }
                                                })
                                            }
                                        </div>

                                        <div className="outcome-highlight dropdown-menu" aria-labelledby="CategoryStatusmenu">
                                            {this.state.CategoryStatusOptions.map(e => {
                                                return (<div className={`category-status-item dropdown-item ${e.value === this.state.CategoryStatus ? "active" : ''}`}>
                                                    <p className={`category-status ${e.color}`} onClick={this.onCategoryStatus.bind(this, e.value)}>{i18n.t(e.id)}</p>
                                                </div>
                                                )
                                            })}
                                        </div>
                                        <p className="errorMsg">{this.state.errors.addCategoryApiCallError}</p>
                                        {this.state.CategoryName && this.state.CategoryOutcome && this.state.CategoryStatus && !this.state.errors.CategoryName && !this.state.errors.CategoryOutcome && this.state.initiativesApiResponse.filter((e) => { if (e.valueChecked === true) { return e; } }).length ?
                                            <button type="submit" onClick={this.addCategory} className={`IBWithBackground m-t-10`} value="addCategory" name="addCategory">{i18n.t("Add_Category")}</button> :
                                            <button type="submit" className={`IBDWithBackground m-t-10`} value="addCategory" name="addCategory">{i18n.t("Add_Category")}</button>}

                                        {this.state.CategoryName || this.state.CategoryOutcome || this.state.CategoryStatus || this.state.initiativesApiResponse.filter((e) => { if (e.valueChecked === true) { return e; } }).length ?
                                            <button type="submit" onClick={this.clearValues} className={`initiativesButton m-t-10 m-l-20`} value="clear" name="clear">{i18n.t("Clear")}</button> :
                                            <button type="submit" className={`initiativesDisabledButton m-t-10 m-l-20`} value="clear" name="clear">{i18n.t("Clear")}</button>}
                                    </div>
                                    <div className="col-md-6">
                                        <select name="MaturityPhase" className="selectbox" onChange={this.MaturityPhaseChange} value={this.state.MaturityPhase}>                                                
                                                    <option value="Foundation">{i18n.t("Foundation_Initiatives")}</option>
                                                    <option value="Automated">{i18n.t("Automated_Initiatives")}</option>
                                                    <option value="Insights">{i18n.t("Insights_Initiatives")}</option>
                                                    <option value="Intelligent">{i18n.t("Intelligent_Initiatives")}</option>
                                        </select>
                                        {this.state.initiativesApiResponse.length ? this.state.initiativesApiResponse.map((e) => {
                                            if (e.MaturityPhase === this.state.MaturityPhase) {
                                                return (
                                                    <label className="form-check width-alignmet display-block">
                                                        <input className="form-check-input" type="checkbox" checked={e.valueChecked} onChange={this.onInitiativeSelect.bind(this, e.ProjectInitiativeName)} />
                                                        <span className="checkmark m-t-5"></span>
                                                        <span className="form-check-label">{e.ProjectInitiativeName}</span>
                                                    </label>
                                                )

                                            }
                                        }) : null}
                                    </div>
                                </div>
                                {this.state.categories.length ?
                                    <div className="report-data">
                                        <p>{i18n.t("Report_Data")}</p>
                                        <div id="accordion" >
                                            {this.state.categories.map((e, index) => {
                                                return (
                                                    <div className={`outcome-highlight position-relative`} >
                                                        <div className={` ${e.CategoryStatus === "1" ? "color-alignment green p-l-10" : ''}${e.CategoryStatus === "2" ? "color-alignment orange-clr p-l-10" : ''}${e.CategoryStatus === "3" ? "color-alignment red-clr p-l-10 " : ''}`}>
                                                            <a class={`${index === 0 ? "card-link downArrow " : "card-link collapsed downArrow"}`} data-toggle="collapse" href={`${"#collapse" + index}`}>
                                                                {e.CategoryName} <span className="p-l-20 p-r-20">|</span> {e.MaturityPhase} <span className="p-l-20 p-r-20">|</span> {e.intelOps.length} {i18n.t("Initiatives")}</a>
                                                            <FontAwesomeIcon className="trash-icon-alignment" onClick={this.deleteCategory.bind(this, e.CategoryName, e.MaturityPhase)} icon={faTrashAlt} />
                                                            <div id={`${"collapse" + index}`} class="collapse" data-parent="#accordion">
                                                                <div class="card-body-custom">
                                                                    <p>{e.CategoryOutcome}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                        </div>
                                        {this.state.generateSummaryReport ? <DealSummaryReport period={this.state.Period} client = {this.state.clientName} CategoryStatusOptions={this.state.CategoryStatusOptions} categories={this.state.categories} show={this.state.generateSummaryReport} close={this.closeReport} /> : null}
                                        {this.state.generateDetailedReport ? <DealDetailedReport period={this.state.Period} client = {this.state.clientName} CategoryStatusOptions={this.state.CategoryStatusOptions} categories={this.state.categories} show={this.state.generateDetailedReport} close={this.closeReport} /> : null}
                                        <button type="submit" onClick={this.generateReport.bind(this, 1)} className={`IBWithBackground m-15`} value="generateSummaryReport" name="generateSummaryReport">{i18n.t("Generate_Summary_Report")}</button>
                                        <button type="submit" onClick={this.generateReport.bind(this, 2)} className={`IBWithBackground m-15`} value="generateDetailedReport" name="generateDetailedReport">{i18n.t("Generate_Detailed_Report")}</button>
                                    </div> : null}
                            </div>
                            <div className="col-md-4">
                                {!this.state.CategoryName ?
                                    <div id="accordionTwo" >
                                        <p> {i18n.t("Recent_Categories")}</p>
                                        {this.state.groupByCategories.length ? this.state.groupByCategories.map((each, i) => {
                                             if (each.MaturityPhase === this.state.MaturityPhase){
                                                return <div className="suggestions">
                                                <h6>{each.name}</h6>
                                                {each.value.map((e, index) => {
                                                    if (e.MaturityPhase === this.state.MaturityPhase) {
                                                        return (
                                                            <React.Fragment>
                                                                <a class={`${index === 0 ? "card-link downArrow1" : "card-link collapsed downArrow1"}`} data-toggle="collapse" href={`${"#collapseOf" + i + index}`}>
                                                                    {e.MaturityPhase} |  {e.intelOps.length} {i18n.t("Initiatives_By")} {e.CreatedUser}</a>
                                                                <div id={`${"collapseOf" + i + index}`} class="collapse" data-parent="#accordionTwo">
                                                                    <div class="card-body">
                                                                        <p>{e.CategoryOutcome}</p>
                                                                        <button type="submit" onClick={this.selectCategory.bind(this, e)} className={`IBWithBackground m-t-10`} value="selectCategory" name="selectCategory">{i18n.t("Select_Category")}</button>
                                                                    </div>

                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    }

                                                })}
                                            </div>
                                             }
                                          
                                        }) : null}
                                    </div>
                                    :

                                    <div id="accordionTwo" >
                                        <p>{i18n.t("Category_Suggestions")}</p>
                                        {this.state.groupByCategoriesSuggestions.length ? this.state.groupByCategoriesSuggestions.map((each, i) => {
                                              if (each.MaturityPhase === this.state.MaturityPhase){
                                            return <div className="suggestions">
                                                <h6>{each.name}</h6>
                                                {each.value.map((e, index) => {
                                                    if (e.MaturityPhase === this.state.MaturityPhase) {
                                                        return (
                                                            <React.Fragment>
                                                                <a class={`${index === 0 ? "card-link downArrow1" : "card-link collapsed downArrow1"}`} data-toggle="collapse" href={`${"#collapseOf" + i + index}`}>
                                                                    {e.MaturityPhase} |  {e.intelOps.length} {i18n.t("Initiatives_By")} {e.CreatedUser}</a>
                                                                <div id={`${"collapseOf" + i + index}`} class="collapse" data-parent="#accordionTwo">
                                                                    <div class="card-body">
                                                                        <p>{e.CategoryOutcome}</p>
                                                                        <button type="submit" onClick={this.selectCategory.bind(this, e)} className={`IBWithBackground m-t-10`} value="selectCategory" name="selectCategory">{i18n.t("Select_Category")}</button>
                                                                    </div>

                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    }

                                                })}
                                            </div>
                                              }
                                        }) : null}
                                        
                                    </div>}
                            </div>
                        </div> : null}
                </div>
            </LoadingOverlay>

        );
    }
}

export default DealReport;


