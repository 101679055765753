import React, { Component } from 'react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import Chart from 'chart.js';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-annotation';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import {
  Modal, Button, Overlay, Popover,
} from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import Moment from 'moment';
import i18n from 'i18next';
import LoadingOverlay from 'react-loading-overlay';
import {
  Tabs, Tab, TabPanel, TabList,
} from 'react-web-tabs';
import { connect } from 'react-redux';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl,idTokenstring } from '../Shared/Constant';

import InsightsChatWindow from './InsightsChatWindow';
import HideChat from '../Images/hide-chat.svg';
import ShowChat from '../Images/show-chat.svg';
import { store } from '../reducers/configureStore';
import SynopsLoader from '../Shared/SynopsLoader';
import MetricValueIcon from '../Images/MetricValue-Border.svg';
import LowerQuartileIcon from '../Images/LowerQuartile-Border.svg';
import MedianIcon from '../Images/Median-Border.svg';
import UpperQuartileIcon from '../Images/UpperQuartile-Border.svg';
import BaselineIcon from '../Images/Baseline-Border.svg';
import TargetIcon from '../Images/Target-Border.svg';
import MetricDeepDive from './MetricDeepDive';
import { dateFormatMethod2 } from '../Shared/commonFunctions';
import { forNum } from '../Shared/Common';
import BoiTrendsWrapper from './BoiTrendsWrapper';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;

function formatDate(momentObj) {
  const dateFormat = 'MMM YYYY';
  return momentObj.format(dateFormat);
}
let minDate = new Date();
if (new Date().getMonth() === 11) {
// special logic only if month is december. start date needs to be jan 2020

  const yearPart = new Date().getFullYear();
  const monthPart = 12 - new Date().getMonth() - 1; // 12 -11 - 1 to show jan
  const dayPart = 1;

  minDate = new Date(yearPart.toString(), monthPart.toString(), dayPart.toString(), '00', '00', '00');
} else {
  minDate = `${new Date().getFullYear() - 1}-${new Date().getMonth() + 2 < 10 ? `0${new Date().getMonth() + 2}` : new Date().getMonth() + 2}`;
}
const allowedMinDate = new Date(minDate);

class TrendGraphsHnM extends Component {
  constructor(props) {
    super(props);
    const dateBeforeOneYear = Moment().subtract(12, 'months');
    const defaultStartDateMoment1 = dateBeforeOneYear.isAfter(allowedMinDate)
      ? dateBeforeOneYear
      : allowedMinDate;
    const defaultEndDateMoment1 = Moment();

    const defaultStartDateMoment = Moment(defaultStartDateMoment1);
    const defaultEndDateMoment = Moment(defaultEndDateMoment1);
    const { show } = this.props;
    this.state = {
      show,
      temp: [],
      dsKpiData: [],
      autoCompleteDdlClientsList: [],
      defaultDate: true,
      startDate: defaultStartDateMoment.toDate(),
      endDate: defaultEndDateMoment.toDate(),
      formattedStartDate: formatDate(defaultStartDateMoment),
      formattedEndDate: formatDate(defaultEndDateMoment),
      isStartEndDateSelected: true,
      tempStartDate: defaultStartDateMoment.toDate(),
      tempEndDate: defaultEndDateMoment.toDate(),
      formattedTempStartDate: formatDate(defaultStartDateMoment),
      formattedTempEndDate: formatDate(defaultEndDateMoment),
      isVisibleDatepickerDropdownPopover: false,
      showCanvas: false,
      showErrorMsg: false,
      showComments: true,
      isApiLoading: false,
      isMetricOn: true,
      isLowerOn: true,
      isMedianOn: true,
      isUpperOn: true,
      isBaselineOn: true,
      isTargetOn: true,
      strikedLegentData: [],
      backgroundColorValues: [''],
      baselineMonthValue: [],
      metricBorderColorValues: [''],
      newNotificationList: [],
      fullmonthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      UpperThresholdMean: [],
      LowerThresholdMean: [],
      BaselineMean: [],
      TargetMean: [],
    };

    this.datepickerDropdownPopoverTarget = React.createRef();
    this.insightMetricChart = React.createRef();
    this.startDatepickerRef = React.createRef();
    this.endDatepickerRef = React.createRef();
    this.handleHide = this.handleHide.bind(this);
    this.onShowHideButtonClick = this.onShowHideButtonClick.bind(this);
    this.getKpiData = this.getKpiData.bind(this);
    this.MetricClick = this.MetricClick.bind(this);
    this.LowerClick = this.LowerClick.bind(this);
    this.MedianClick = this.MedianClick.bind(this);
    this.UpperClick = this.UpperClick.bind(this);
    this.BaselineClick = this.BaselineClick.bind(this);
    this.TargetClick = this.TargetClick.bind(this);
    this.onLegendClick = this.onLegendClick.bind(this);
    this.GetNotifications = this.GetNotifications.bind(this);
  }

  componentDidMount() {
    this.getKpiData();
    store.dispatch({
      type: 'SYNC_COMMENTFILTER',
      payload: null,
    });
  }

  componentDidUpdate(prevProps) {
    const { SelectedCommentMonth, CommentMonthChange } = this.props;
    const { temp, dsKpiData } = this.state;
    const backgroundColorValues = [''];
    if (SelectedCommentMonth !== prevProps.SelectedCommentMonth) {
      if (temp.length > 0 && dsKpiData.datasets.length > 0) {
        temp.forEach((each) => {
          if (each.PeriodDt === SelectedCommentMonth) {
            backgroundColorValues.push('#66e0ff');
          } else {
            backgroundColorValues.push('white');
          }
        });
        const { labels } = dsKpiData;
        const colorChange = dsKpiData.datasets;
        const metricColor = colorChange[0];
        metricColor.pointBackgroundColor = backgroundColorValues;
        colorChange[0] = metricColor;
        this.setState({
          dsKpiData: {
            labels,
            datasets: colorChange,
          },
          backgroundColorValues,
          isMetricOn: !!CommentMonthChange,
          isLowerOn: !!CommentMonthChange,
          isMedianOn: !!CommentMonthChange,
          isUpperOn: !!CommentMonthChange,
          isBaselineOn: !!CommentMonthChange,
          isTargetOn: !!CommentMonthChange,
          strikedLegentData: [],
        });
      }
    }
  }

  handleHide() {
    const { onClose } = this.props;
    this.setState({ show: false });
    onClose();
  }

  getKpiData() {
    const {
      endDate, startDate, defaultDate, metricBorderColorValues, backgroundColorValues,
    } = this.state;
    const { kpiMappingId, kpiUnit, CommentMonthChange } = this.props;
    // const months = this.props.Month;  // unused variable
    let UpperThresholdMean = '';
    let LowerThresholdMean = '';
    const UpperThresholdMeanArray = [];
    const LowerThresholdMeanArray = [];
    let baselineMean = '';
    let targetMean = '';
    const baselineMeanArray = [];
    const targetMeanArray = [];
    const trendAvgArray = [];
    const updatedDate2 = `${new Date().getFullYear() - 1}-${(new Date().getMonth()) < 10 ? `0${new Date().getMonth() + 2}` : (new Date().getMonth() + 2)}`;
    const enddate = new Date(endDate);
    const startdate = new Date(startDate);
    const d1 = `${enddate.getFullYear()}-${enddate.getMonth() + 1}`;
    const s1 = `${startdate.getFullYear()}-${startdate.getMonth() + 1}`;
    this.setState({ isApiLoading: true });
    const start1 = (startdate.getFullYear() === enddate.getFullYear()) && (startdate.getMonth() === enddate.getMonth()) ? updatedDate2 : s1;
    const languageId = store.getState().CurrentFilter.languageData.id;
    axiosInstance.get(`${LocalApiBaseUrl}Analytics/GetKpiDataForHMTrendGraph`, {
      params: {
        kpiMappingID: kpiMappingId, LanguageID: languageId, StartDate: start1, endDate: d1,
      },
    })
      .then((res) => {
        if (res.data.length < 3 && defaultDate) {
          this.setState({
            showErrorMsg: true,
            temp: res.data,
            isApiLoading: false,
          });
        } else if (res.data.length < 3) {
          alert(i18n.t('Enough_data_is_not_available,_please_try_with_some_other_date_range'));
          this.setState({
            showCanvas: true,
            temp: res.data,
            isApiLoading: false,
            dsKpiData: {
              labels: [],
              datasets: [],
            },
          });
        } else {
          const MontnDt = [''];
          const ValueDt = [];
          const baselineMonthValue = [];
          res.data.forEach((element) => {
            MontnDt.push(element.PeriodDt);
            ValueDt.push(parseFloat(element.MetricValue));
            baselineMonthValue.push(element.BaseLineMonth);
          });
          for (let i = res.data.length - 1; i >= 0; i -= 1) {
            if (
              (res.data[i].UpperThrehold !== null && res.data[i].UpperThrehold.trim() !== '')
                          || (res.data[i].LowerThrehold !== null && res.data[i].LowerThrehold.trim() !== '')
            ) {
              UpperThresholdMean = res.data[i].UpperThrehold;
              LowerThresholdMean = res.data[i].LowerThrehold;
              break;
            }
          }
          for (let i = res.data.length - 1; i >= 0; i -= 1) {
            if (
              (res.data[i].BaselineValue !== null && res.data[i].BaselineValue.trim() !== '')
                          || (res.data[i].TargetValue !== null && res.data[i].TargetValue.trim() !== '')
            ) {
              baselineMean = res.data[i].BaselineValue;
              targetMean = res.data[i].TargetValue;

              break;
            }
          }

          const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ];
          if (res.data.length > 0) {
            res.data.forEach((each, index) => {
              const objDate = new Date(each.PeriodDt);
              res.data[index].PeriodDt = `${monthNames[objDate.getMonth()]} 20${objDate.getDate()}`;
            });
          }
          if (baselineMonthValue.length > 0) {
            const { fullmonthNames } = this.state;
            for (let i = 0; i < baselineMonthValue.length; i += 1) {
              if (baselineMonthValue[i] != null && baselineMonthValue[i] !== '') {
                const objDate = new Date(baselineMonthValue[i]);
                baselineMonthValue[i] = `${fullmonthNames[objDate.getMonth()]} ${objDate.getFullYear()}`;
              }
            }
          }

          const sum = ValueDt.reduce((previous, current) => current + previous, 0);
          const avg = sum / ValueDt.length;

          res.data.forEach(() => {
            trendAvgArray.push(avg);
            UpperThresholdMeanArray.push(parseFloat(UpperThresholdMean));
            LowerThresholdMeanArray.push(parseFloat(LowerThresholdMean));
            baselineMeanArray.push(parseFloat(baselineMean));
            targetMeanArray.push(parseFloat(targetMean));
          });
          const kpiMUnit = kpiUnit;
          this.setState({
            baselineMonthValue,
            isApiLoading: false,
            showCanvas: true,
            temp: res.data,
            UpperThresholdMean,
            LowerThresholdMean,
            BaselineMean: baselineMean,
            TargetMean: targetMean,
            isMetricOn: !!CommentMonthChange,
            isLowerOn: !!CommentMonthChange,
            isMedianOn: !!CommentMonthChange,
            isUpperOn: !!CommentMonthChange,
            isBaselineOn: !!CommentMonthChange,
            isTargetOn: !!CommentMonthChange,
            strikedLegentData: [],
            dsKpiData: {
              labels: MontnDt,
              datasets: [{
                label: kpiMUnit,
                data: [null, ...ValueDt],
                fill: false,
                lineTension: 0,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: '#66e0ff',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                floating: true,
                pointDotRadius: 8,
                datalabels: {
                  align: 'top',
                  enabled: true,
                  display: false,
                },
                pointBorderColor: metricBorderColorValues,
                pointBackgroundColor: backgroundColorValues,
                pointBorderWidth: 2,
                pointHoverRadius: 8,
                pointHoverBackgroundColor: 'white',
                pointHoverBorderColor: '#66e0ff',
                pointHoverBorderWidth: 2,
                pointRadius: 6,
                pointHitRadius: 20,
              },
              {
                label: i18n.t('Median'),
                fill: false,
                // data: [this.state.trendAvg],
                data: [avg, ...trendAvgArray],
                backgroundColor: 'rgba(255, 200, 44, 0.1)',
                lineTension: 0.1,
                borderColor: 'rgb(255, 200, 44)',
                borderCapStyle: 'butt',
                borderWidth: 1.5,
                borderDash: [5, 3],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(255, 200, 44)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 0,
                pointHoverBackgroundColor: 'rgb(255, 255, 25)',
                pointHoverBorderColor: 'rgb(255, 200, 44)',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                datalabels: {
                  backgroundColor: 'rgb(255, 200, 44)',
                  borderColor: 'rgb(255, 200, 44)',
                },
              },
              {
                label: i18n.t('Upper_Threshold'),
                fill: false,
                // data: [this.state.UpperThresholdMean],
                data: [UpperThresholdMean, ...UpperThresholdMeanArray],

                backgroundColor: 'rgba(126, 195, 68, 0.1)',
                lineTension: 0.1,
                borderColor: 'rgb(126, 195, 68)',
                borderCapStyle: 'butt',
                borderWidth: 0,
                borderDash: [5, 3],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(126, 195, 68)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 0,
                pointHoverBackgroundColor: 'rgb(255, 255, 255)',
                pointHoverBorderColor: 'rgb(126, 195, 68)',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                datalabels: {
                  backgroundColor: 'rgb(126, 195, 68)',
                  borderColor: 'rgb(126, 195, 68)',
                },
              },
              {
                label: i18n.t('Lower_Threshold'),
                fill: false,
                // data: [this.state.LowerThresholdMean],
                data: [LowerThresholdMean, ...LowerThresholdMeanArray],

                backgroundColor: 'rgba(213, 0, 47, 0.1)',
                lineTension: 0.1,
                borderColor: 'rgb(213, 0, 47)',
                borderCapStyle: 'butt',
                borderDash: [5, 3],
                borderWidth: 0,
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(213, 0, 47)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 0,
                pointHoverBackgroundColor: 'rgb(255, 255, 255)',
                pointHoverBorderColor: 'rgb(213, 0, 47)',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                datalabels: {
                  backgroundColor: 'rgb(213, 0, 47)',
                  borderColor: 'rgb(213, 0, 47)',
                },
              },
              {
                label: i18n.t('Baseline'),
                fill: false,
                // data: [this.state.LowerThresholdMean],
                data: [baselineMean, ...baselineMeanArray],

                backgroundColor: 'rgba(181, 192, 202, 0.1)',
                lineTension: 0.1,
                borderColor: 'rgb(181, 192, 202)',
                borderCapStyle: 'butt',
                borderDash: [5, 3],
                borderWidth: 1.5,
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(181, 192, 202)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointHoverBackgroundColor: 'rgb(255, 255, 255)',
                pointHoverBorderColor: 'rgb(181, 192, 202)',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                datalabels: {
                  backgroundColor: 'rgb(181, 192, 202)',
                  borderColor: 'rgb(181, 192, 202)',
                },
              },
              {
                label: i18n.t('Target'),
                fill: false,
                // data: [this.state.LowerThresholdMean],
                data: [targetMean, ...targetMeanArray],

                backgroundColor: 'rgba(160, 85, 245, 0.1)',
                lineTension: 0.1,
                borderColor: 'rgb(160, 85, 245)',
                borderCapStyle: 'butt',
                borderDash: [5, 3],
                borderWidth: 0,
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(160, 85, 245)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 0,
                pointHoverBackgroundColor: 'rgb(255, 255, 255)',
                pointHoverBorderColor: 'rgb(160, 85, 245)',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                datalabels: {
                  backgroundColor: 'rgb(160, 85, 245)',
                  borderColor: 'rgb(160, 85, 245)',
                },
              },
              ],
            },
          },
          () => {
            this.GetNotifications();
          });
        }
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });
  }

    // EVENT HANDLER FOR CALENDAR ICON IN DATEPICKER FIELDS, OPENS UP THE PICKER WHEN CLICKED
    handleUpdateDateClickField = (tempStart, tempEnd) => {
      const d1 = tempStart.getMonth();
      const d2 = tempEnd.getMonth();
      const d3 = tempStart.getFullYear() === tempEnd.getFullYear() ? (12 - d1) - (12 - d2) + 1 : (12 - d1) + d2 + 1;
      if (d3 > 2) {
        const formattedStartDate = !_.isNil(tempStart)
          ? formatDate(Moment(tempStart))
          : '';
        const formattedEndDate = !_.isNil(tempEnd)
          ? formatDate(Moment(tempEnd))
          : '';
        this.setState({
          startDate: tempStart,
          formattedStartDate,
          endDate: tempEnd,
          formattedEndDate,
          isVisibleDatepickerDropdownPopover: false,
        });

        // IF EITHER THE START DATE OR END DATE HAS CHANGED FROM THE PREVIOUS VALUES
        // OR THEIR VALUES ARE EMPTY THEN VALIDATE THE VALUES
        // AND IF VALUES ARE FILLED THEN ONLY EXECUTE THE API CALL
        if (
          formattedStartDate !== null
                || formattedEndDate !== null
                || _.isEmpty(formattedStartDate)
                || _.isEmpty(formattedEndDate)
        ) {
          this.setState(
            {
              // tempPreviousFormattedStartDate: formattedStartDate,    // unused state var
              // tempPreviousFormattedEndDate: formattedEndDate,        // unused state var
            },
            () => {
              setTimeout(() => {
                let errMsg = '';
                if (_.isEmpty(formattedStartDate) && _.isEmpty(formattedEndDate)) {
                  errMsg = `${i18n.t('Both')} "${i18n.t('Start_Month')}" ${i18n.t('and')} "${i18n.t('End_Month')}" ${i18n.t('should_be_filled_to_retrieve_the_results')}`;
                } else if (
                  _.isEmpty(formattedStartDate)
                                && !_.isEmpty(formattedEndDate)
                ) {
                  errMsg = `"${i18n.t('Start_Month')}" ${i18n.t('should_be_filled_to_retrieve_the_results')}`;
                } else if (
                  !_.isEmpty(formattedStartDate)
                                && _.isEmpty(formattedEndDate)
                ) {
                  errMsg = `"${i18n.t('End_Month')}" ${i18n.t('should_be_filed_to_retrieve_the_results')}`;
                }
                if (!_.isEmpty(errMsg)) {
                  window.alert(errMsg);
                } else {
                  this.setState({
                    defaultDate: false,
                  });
                  this.getKpiData();
                }
              }, 50);
            },
          );
        }
      } else {
        alert(i18n.t('Please-_select_minimum_three_months_to_view_the_graph'));
      }
    };

    handleChangeDateField = (date, isStartEndDateSelected) => {
      this.setState({
        [isStartEndDateSelected ? 'tempStartDate' : 'tempEndDate']: date,
        [isStartEndDateSelected
          ? 'formattedTempStartDate'
          : 'formattedTempEndDate']: formatDate(Moment(date)),
      });
    };

    handleClearDate = () => {
      const {
        startDate, endDate, formattedStartDate, formattedEndDate,
      } = this.state;
      this.setState({
        tempStartDate: startDate,
        tempEndDate: endDate,
        formattedTempStartDate: formattedStartDate,
        formattedTempEndDate: formattedEndDate,
      });
    };

    // HIDES THE "DATE SELECTION DROPDOWN" WHEN CLICKED OUTSIDE THE POPOVER
    hideDatepickerDropdownPopover = () => {
      this.setState({
        isVisibleDatepickerDropdownPopover: false,
      });
    };

    toggleDatepickerDropdownPopover = () => {
      const {
        isVisibleDatepickerDropdownPopover, startDate, endDate, formattedStartDate, formattedEndDate,
      } = this.state;
      this.setState(
        {
          isVisibleDatepickerDropdownPopover: !isVisibleDatepickerDropdownPopover,
          isStartEndDateSelected: true,
        },
      );
      if (!isVisibleDatepickerDropdownPopover) {
        this.setState({
          tempStartDate: startDate,
          tempEndDate: endDate,
          formattedTempStartDate: formattedStartDate,
          formattedTempEndDate: formattedEndDate,
        });
      }
    };

    // SELECTS "START DATE" OR "END DATE"
    selectStartDate = () => {
      this.setState({
        isStartEndDateSelected: true,
      });
    };

    selectEndDate = () => {
      this.setState({
        isStartEndDateSelected: false,
      });
    };

    onLegendClick = (data) => {
      const { dsKpiData } = this.state;
      const kpiData = dsKpiData.datasets;
      const { labels } = dsKpiData;
      let newkpiData = dsKpiData.datasets;
      const { strikedLegentData } = this.state;
      let strikedlegend = false;
      strikedLegentData.forEach((each) => {
        if (each.label === data) {
          strikedlegend = true;
        }
      });
      if (strikedlegend) {
        let index;
        strikedLegentData.forEach((each, ind) => {
          if (each.label === data) {
            newkpiData.push(each);
            index = ind;
          }
        });
        strikedLegentData[index] = [];
      } else {
        newkpiData = [];
        kpiData.forEach((each) => {
          if (each.label !== data) {
            newkpiData.push(each);
          } else {
            strikedLegentData.push(each);
          }
        });
      }
      this.setState({
        dsKpiData: {
          labels,
          datasets: newkpiData,
        },
        strikedLegentData,
      });
    }

    onShowHideButtonClick = () => {
      const { showComments, dsKpiData } = this.state;
      const dataset = dsKpiData.datasets;
      const label = dsKpiData.labels;
      this.setState({
        showComments: !showComments,
        dsKpiData: {
          labels: label,
          datasets: dataset,
        },
      });
    }

    MetricClick() {
      this.setState((prevState) => ({
        isMetricOn: !prevState.isMetricOn,
      }));
    }

    LowerClick() {
      this.setState((prevState) => ({
        isLowerOn: !prevState.isLowerOn,
      }));
    }

    MedianClick() {
      this.setState((prevState) => ({
        isMedianOn: !prevState.isMedianOn,
      }));
    }

    UpperClick() {
      this.setState((prevState) => ({
        isUpperOn: !prevState.isUpperOn,
      }));
    }

    BaselineClick() {
      this.setState((prevState) => ({
        isBaselineOn: !prevState.isBaselineOn,
      }));
    }

    TargetClick() {
      this.setState((prevState) => ({
        isTargetOn: !prevState.isTargetOn,
      }));
    }

    GetNotifications() {
      const { dsKpiData } = this.state;
      const { kpiMappingId } = this.props;
      axiosInstance.get(`${LocalApiBaseUrl}Analytics/GetNotifications?IsRead=true&LanguageID=${parseInt(store.getState().CurrentFilter.languageData.id, 10)}`)
        .then((response) => {
          if (response.data) {
            response.data.forEach((each) => {
              const borderColor = [];
              const date = dateFormatMethod2(each.PeriodDT).split(',')[0];
              const date2 = date.split(' ');
              const formatteddate = `${date2[1]}-${date2[2]}`;
              dsKpiData.labels.forEach((each1) => {
                if (each1 === formatteddate && sessionStorage.getItem('EnterpriseID') !== each.CreatedBy && kpiMappingId === each.KPIMappingID) {
                  borderColor.push('rgb(220, 6, 6)');
                } else {
                  borderColor.push('rgb(0, 186, 255)');
                }
              });
              const { labels } = dsKpiData;
              const colorChange = dsKpiData.datasets;
              const metricColor = colorChange[0];
              metricColor.pointBorderColor = borderColor;
              colorChange[0] = metricColor;
              this.setState({
                dsKpiData: {
                  labels,
                  datasets: colorChange,
                },
                metricBorderColorValues: borderColor,
              });
              this.setState({
                newNotificationList: response.data,
              });
            });
          }
        });
    }

    render() {
      const {
        kpiName,
        info,
        kpiUnit,
        dealFilter,
        SyncFilter,
        Common,
        kpiMappingId,
        roleData,
        ClientData,
      } = this.props;
      const {
        isVisibleDatepickerDropdownPopover,
        startDate,
        endDate,
        isStartEndDateSelected,
        tempStartDate,
        tempEndDate,
        autoCompleteDdlClientsList,
        show,
        showCanvas,
        isUpperOn,
        formattedTempStartDate,
        BaselineMean,
        formattedTempEndDate,
        formattedStartDate,
        baselineMonthValue,
        formattedEndDate,
        isTargetOn,
        showErrorMsg,
        TargetMean,
        showComments,
        isApiLoading,
        LowerThresholdMean,
        isMetricOn,
        isBaselineOn,
        isLowerOn,
        dsKpiData,
        UpperThresholdMean,
        newNotificationList,
        temp,
        isMedianOn,
      } = this.state;
      const { IsMultiLevel, MetricID } = info;
      let ToolTip = '';
      ToolTip = `${SyncFilter.OfferingName} - ${Common.SubOfferingName}`;
      const dealListarr = [];
      autoCompleteDdlClientsList.forEach((arrayItem) => {
        dealListarr.push({ name: arrayItem.OrgEntityName, id: arrayItem.OrgEntityID, abbr: arrayItem.OrgEntityName.substr(0, 2) });
      });
      Chart.defaults.global.defaultFontColor = 'black';
      const kpiMUnit = kpiUnit;
      return (
        <Modal show={show} onHide={this.handleHide} dialogClassName="trendGraphModal" size="XL" className="modal fade v-center trendGraph-comments start-end-date-picker" id="dealpopup" backdrop="static">

          <Modal.Header closeButton className="no-border justify-content-center">
            <h4 className="modal-title" id="dealprofile">
              {' '}
              {kpiName}
              {' '}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              defaultTab="one"
            >
              <TabList>
                <Tab tabFor="one">
                  <span data-tip={ToolTip} data-for={"tooltip"}>{i18n.t('Monthly_Trend_12_Months')}</span>
                </Tab>
                <Tab tabFor="two">
                  <span data-tip={ToolTip} data-for={"tooltip"}>{i18n.t('Metric_Deep_Dive')}</span>
                </Tab>
                <Tab tabFor="HMTrends">
                  <span data-tip={ToolTip} data-for={"tooltip"}>{i18n.t('HM_Trends')}</span>
                </Tab>
              </TabList>
              <TabPanel tabId="one" className="start-end-datepicker-monthly">
                {showCanvas
                  ? (
                    <div className="analyticsFilter no-whiteShadow">
                      <div className="chooseOption">
                        <Overlay
                          target={this.datepickerDropdownPopoverTarget.current}
                          show={isVisibleDatepickerDropdownPopover}
                          placement="bottom"
                          rootClose
                          rootCloseEvent="click"
                          onHide={this.hideDatepickerDropdownPopover}
                        >
                          <Popover id="popover-basic4" className="calenderPopover">
                            <Popover.Content>
                              <div className="popContent">
                                <div className="popContentDisplay">
                                  <div
                                    className={
                                                            isStartEndDateSelected
                                                              ? 'calenderPopoverSpanActive'
                                                              : 'calenderPopoverSpan'
                                                        }
                                    target={this.startDatepickerRef}
                                    onClick={this.selectStartDate}
                                    role="button"
                                    tabIndex="0"
                                  >
                                    <div className="CalenderDatepickerLabel">
                                      {i18n.t('Start_Date')}
                                    </div>
                                    <div className="CalenderDatepickerDate">
                                      {formattedTempStartDate}
                                    </div>
                                  </div>
                                  <div
                                    className={
                                                            isStartEndDateSelected
                                                              ? 'calenderPopoverSpan'
                                                              : 'calenderPopoverSpanActive'
                                                        }
                                    target={this.endDatepickerRef}
                                    onClick={this.selectEndDate}
                                    role="button"
                                    tabIndex="0"
                                  >
                                    <div className="CalenderDatepickerLabel">
                                      {i18n.t('End_Date')}
                                    </div>
                                    <div className="CalenderDatepickerDate">
                                      {formattedTempEndDate}
                                    </div>
                                  </div>
                                </div>
                                {isStartEndDateSelected ? (
                                  <div>
                                    <DatePicker
                                      selected={
                                                                tempStartDate || startDate
                                                            }
                                      onChange={(date) => this.handleChangeDateField(
                                        date,
                                        isStartEndDateSelected,
                                      )}
                                      dateFormat="MMM yyyy"
                                      showMonthYearPicker
                                      strictParsing
                                      inline
                                      className="calenderDatepicker"
                                      minDate={allowedMinDate}
                                      maxDate={
                                                                !_.isNil(tempEndDate || endDate)
                                                                  ? Moment(
                                                                    tempEndDate || endDate,
                                                                  ).toDate()
                                                                  : Moment(new Date()).toDate()
                                                            }
                                      placeholderText="Start Month"
                                      ref={this.startDatepickerRef}
                                    />
                                  </div>
                                ) : (
                                  <DatePicker
                                    selected={tempEndDate || endDate}
                                    onChange={(date) => this.handleChangeDateField(
                                      date,
                                      isStartEndDateSelected,
                                    )}
                                    dateFormat="MMM yyyy"
                                    showMonthYearPicker
                                    strictParsing
                                    inline
                                    className="calenderDatepicker"
                                    minDate={
                                                                !_.isNil(
                                                                  tempStartDate || startDate,
                                                                )
                                                                  ? Moment(
                                                                    tempStartDate || startDate,
                                                                  ).toDate()
                                                                  : null
                                                            }
                                    maxDate={new Date()}
                                    placeholderText={i18n.t('End_Month')}
                                    ref={this.endDatepickerRef}
                                  />
                                )}
                              </div>
                            </Popover.Content>
                            <div className="popover-footer">
                              <div className="modal-btn temp">
                                <Button
                                  variant="default"
                                  className="btn-sm mr-auto"
                                  onClick={() => {
                                    this.handleClearDate();
                                  }}
                                >
                                  {i18n.t('Clear')}
                                </Button>
                                <Button
                                  variant="primary"
                                  className="btn-sm"
                                  onClick={() => this.handleUpdateDateClickField(
                                    tempStartDate,
                                    tempEndDate,
                                  )}
                                >
                                  {i18n.t('Update')}
                                </Button>
                              </div>
                            </div>
                          </Popover>
                        </Overlay>
                        <span
                          className={isVisibleDatepickerDropdownPopover ? 'active' : ''}
                          ref={this.datepickerDropdownPopoverTarget}
                          onClick={this.toggleDatepickerDropdownPopover}
                          role="button"
                          tabIndex="0"
                        >
                          {formattedStartDate === formattedEndDate
                            ? formattedStartDate
                            : `${formattedStartDate
                            } - ${
                              formattedEndDate}`}
                          <i
                            className="fal fa-calendar-alt"
                            style={{ marginLeft: '10px' }}
                          />
                        </span>
                      </div>
                    </div>
                  )
                  : null}
                {!showErrorMsg && (showComments
                  ? (
                    <div className="showcomments-tab">
                      <img src={HideChat} className="mr-1" alt="Hide Chat" />
                      <span onClick={this.onShowHideButtonClick} role="button" tabIndex="0">{i18n.t('Hide Comments')}</span>
                    </div>
                  )
                  : (
                    <div className="showcomments-tab">
                      <img src={ShowChat} className="mr-1" alt="Show Chat" />
                      <span onClick={this.onShowHideButtonClick} role="button" tabIndex="0">{i18n.t('Show Comments')}</span>
                    </div>
                  )
                )}
                {isApiLoading ? <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active /> : ''}
                {!showErrorMsg && (
                  <div className="linechart-commentry">
                    <div className={showComments
                      ? 'linechart-division'
                      : 'linechart-division nocommentary-section'}
                    >
                      <div className="custom-chartlegend">
                        <ul>
                          <li
                            onClick={() => {
                              this.onLegendClick(kpiUnit);
                              this.MetricClick();
                            }}
                            role="menuitem"
                            tabIndex="0"
                          >
                            <span className="metric-legendvalue">
                              <img src={MetricValueIcon} alt="metric value icon" />
                            </span>
                            {isMetricOn ? kpiUnit : (<s className="legend-strike-through">{kpiUnit}</s>)}
                          </li>

                          {LowerThresholdMean && (
                          <li
                            onClick={() => {
                              this.onLegendClick(i18n.t('Lower_Threshold'));
                              this.LowerClick();
                            }}
                            role="menuitem"
                            tabIndex="0"
                          >
                            <span className="lowerquartile-legendvalue crossed-line">
                              <img src={LowerQuartileIcon} alt="Lower Quartile Icon" />
                            </span>
                            {isLowerOn ? i18n.t('Lower_Threshold') : (<s className="legend-strike-through">{i18n.t('Lower_Threshold')}</s>)}
                          </li>
                          )}

                          {
                            <li
                              onClick={() => {
                                this.onLegendClick(i18n.t('Median'));
                                this.MedianClick();
                              }}
                              role="menuitem"
                              tabIndex="0"
                            >
                              <span className="median-legendvalue">
                                <img src={MedianIcon} alt="Media Icon" />
                              </span>
                              {isMedianOn ? i18n.t('Median') : (<s className="legend-strike-through">{i18n.t('Median')}</s>)}
                            </li>
}

                          {UpperThresholdMean && (
                          <li
                            onClick={() => {
                              this.onLegendClick(i18n.t('Upper_Threshold'));
                              this.UpperClick();
                            }}
                            role="menuitem"
                            tabIndex="0"
                          >
                            <span className="upperquartile-legendvalue crossed-line">
                              <img src={UpperQuartileIcon} alt="Upper Quartile Icon" />
                            </span>
                            {isUpperOn ? i18n.t('Upper_Threshold') : (<s className="legend-strike-through">{i18n.t('Upper_Threshold')}</s>)}
                          </li>
                          )}

                          {BaselineMean && (
                          <li
                            onClick={() => {
                              this.onLegendClick(i18n.t('Baseline'));
                              this.BaselineClick();
                            }}
                            role="menuitem"
                            tabIndex="0"
                          >
                            <span className="baseline-legendvalue crossed-line">
                              <img src={BaselineIcon} alt="BaseLine Icon" />
                            </span>
                            {isBaselineOn ? i18n.t('Baseline') : (<s className="legend-strike-through">{i18n.t('Baseline')}</s>)}
                          </li>
                          )}

                          {TargetMean && (
                          <li
                            onClick={() => {
                              this.onLegendClick(i18n.t('Target'));
                              this.TargetClick();
                            }}
                            role="menuitem"
                            tabIndex="0"
                          >
                            <span className="target-legendvalue crossed-line">
                              <img src={TargetIcon} alt="Target Icon" />
                            </span>
                            {isTargetOn ? i18n.t('Target') : (<s className="legend-strike-through">{i18n.t('Target')}</s>)}
                          </li>
                          )}
                        </ul>
                      </div>
                      {baselineMonthValue[0] && (
                      <h6 className="Baseline-month">
                        {i18n.t('Baseline_Month')}
                        :
                        {baselineMonthValue[0]}
                      </h6>
                      )}
                      <Line
                        id="LineChart"
                        redraw="true"
                        data={dsKpiData}
                        options={{
                          maintainAspectRatio: false,
                          responsive: true,
                          onClick: (e, element) => {
                            if (element.length > 0) {
                              const monthNames = [
                                'Jan',
                                'Feb',
                                'Mar',
                                'Apr',
                                'May',
                                'Jun',
                                'Jul',
                                'Aug',
                                'Sep',
                                'Oct',
                                'Nov',
                                'Dec',
                              ];
                              let monYear;
                              if (element.length > 0) {
                                const ind = element[0]._index;
                                const tempMonYear = dsKpiData.labels[ind];
                                const objDate = new Date(tempMonYear);
                                monYear = `${monthNames[objDate.getMonth()]} 20${objDate.getDate()}`;
                                store.dispatch({
                                  type: 'SYNC_COMMENTFILTER',
                                  payload: monYear,
                                });
                              }
                              const backgroundColorValues = [''];
                              temp.forEach((each) => {
                                if (each.PeriodDt === monYear) {
                                  backgroundColorValues.push('#66e0ff');
                                } else {
                                  backgroundColorValues.push('white');
                                }
                              });
                              this.setState({
                                backgroundColorValues,
                              });
                            }
                          },
                          tooltips: {
                            mode: 'nearest',
                            intersect: true,
                            enabled: true,
                            borderWidth: 3,
                            filter(tooltipItem, data) {
                              const tooltipDatasetIndex = tooltipItem.datasetIndex;
                              const datasets = _.get(
                                data.datasets,
                                `${tooltipDatasetIndex}`,
                              );
                              return _.get(datasets, 'type', '') !== 'line';
                            },
                            callbacks: {
                              label(tooltipItem, data) {
                                const tooltipDatasetIndex = tooltipItem.datasetIndex;
                                let valWithUnit;
                                const { yLabel } = tooltipItem;
                                valWithUnit = yLabel;
                                if (!_.isEmpty(kpiMUnit)) {
                                  valWithUnit = kpiMUnit === '$'
                                    ? `$${yLabel}`
                                    : `${yLabel} ${kpiMUnit}`;
                                }
                                if (tooltipDatasetIndex !== 0) {
                                  const datasets = _.get(
                                    data.datasets,
                                    `${tooltipDatasetIndex}`,
                                  );
                                  valWithUnit = `${datasets.label}: ${valWithUnit}`;
                                }
                                return valWithUnit;
                              },
                            },
                          },
                          // testing for tooltip end
                          hover: {
                            mode: 'index',
                            intersect: false,
                          },
                          scales: {
                            yAxes: [{
                              ticks: {
                                precision: 1,
                                // max: yAxisMaxVal,
                                beginAtZero: false,
                                callback(value) {
                                  let valWithUnit = value;
                                  if (!_.isEmpty(kpiMUnit)) {
                                    valWithUnit = kpiMUnit === '$'
                                      ? `$${value}`
                                      : `${value}`;
                                  }

                                  return valWithUnit;
                                },
                              },
                              gridLines: {
                                drawOnChartArea: true,
                              },
                              scaleLabel: {
                                display: true,
                                labelString: `${i18n.t('Metric_Unit')}(${kpiMUnit})`,
                                color: '#637381',
                                fontSize: 12,
                                fontFamily: 'Graphik-Medium',
                                fontWeight: 500,
                              },
                            }],
                            xAxes: [{
                              gridLines: {
                                drawOnChartArea: true,
                              },
                              scaleLabel: {
                                display: true,
                                labelString: i18n.t('Timeframe'),
                                color: '#637381',
                                fontSize: 12,
                                fontFamily: 'Graphik-Medium',
                                fontWeight: 500,
                              },
                            }],
                          },
                          layout: {
                            padding: {
                              top: -40,
                              left: 0,
                              right: 40,
                              bottom: 40,
                            },
                          },
                          plugins: {
                            datalabels: {
                              // display: function (context) {
                              //     // if (context.dataset.label === kpiMUnit) return true;
                              //     // else return false;
                              // },
                              align: 'right',
                              offset: 1,
                              formatter(value) {
                                return forNum(value, 2);
                              },
                              display(context) {
                                if (context.dataIndex === context.dataset.data.length - 1 && context.dataset.label === i18n.t('Lower_Threshold')) return true;
                                if (context.dataIndex === context.dataset.data.length - 1 && context.dataset.label === i18n.t('Median')) return true;
                                if (context.dataIndex === context.dataset.data.length - 1 && context.dataset.label === i18n.t('Upper_Threshold')) return true;
                                if (context.dataIndex === context.dataset.data.length - 1 && context.dataset.label === i18n.t('Baseline')) return true;
                                if (context.dataIndex === context.dataset.data.length - 1 && context.dataset.label === i18n.t('Target')) return true;
                                return false;
                              },
                              borderRadius: 50,
                              color: 'white',
                              font: {
                                weight: 500,
                                size: 11,
                                family: 'Graphik-Medium',
                              },

                              padding: {
                                left: 4,
                                right: 4,
                                top: 4,
                                bottom: 4,
                              },
                            },
                          },
                          legend: {
                            display: false,
                          },
                        }}
                      />
                    </div>
                    {kpiMappingId && showComments && !showErrorMsg && (
                    <>
                      <div className="commentrychat-division">
                        <InsightsChatWindow
                          GetKpiData={this.getKpiData}
                          monthComment={dsKpiData.labels}
                          commentData={temp}
                          kpiMappingId={kpiMappingId}
                          kpiName={kpiName}
                          newNotificationList={newNotificationList}
                        />
                      </div>
                    </>
                    )}
                  </div>
                )}
                {showErrorMsg
                  ? (
                    <div align="center" className="emptyView">
                      {i18n.t('Enough_data_is_not_available_for_generating_the_graph')}
                    </div>
                  ) : null}
              </TabPanel>
              <TabPanel tabId="two">
                <MetricDeepDive
                  IsMultiLevel={IsMultiLevel}
                  CategoryInfo={info.CategoryDetails}
                  kpiUnit={kpiUnit}
                  currentMonth={dealFilter.Month}
                  dealFilter={dealFilter}
                  metricID={MetricID}
                  kpiMappingId={this.props.kpiMappingId}
                />
              </TabPanel>
              <TabPanel tabId="HMTrends">
                <div className="linechart-commentry">
                  <BoiTrendsWrapper ClientData={ClientData} kpiMappingId={kpiMappingId} Month={SyncFilter.Month} FilterDetails={SyncFilter} roleReducers={roleData} />
                </div>
              </TabPanel>
            </Tabs>
            <ReactTooltip place="top" className="tipcolor" id="tooltip" />
          </Modal.Body>
        </Modal>
      );
    }
}
const mapStateToProps = (state) => ({
  Common: { ...state.CurrentFilter.Common },
  SyncFilter: { ...state.CurrentFilter.SyncFilter },
  SelectedCommentMonth: state.CurrentFilter.SelectedCommentMonth,
  CommentMonthChange: state.CurrentFilter.CommentMonthChange,
  roleData: state.roleReducers.roleReducers,
  ClientData: state.CurrentFilter.ClientData,
});

TrendGraphsHnM.propTypes = {
  info: PropTypes.object.isRequired,
  kpiName: PropTypes.string.isRequired,
  kpiMappingId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  dealFilter: PropTypes.object.isRequired,
  kpiUnit: PropTypes.number.isRequired,
  SyncFilter: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  SelectedCommentMonth: PropTypes.string.isRequired,
  CommentMonthChange: PropTypes.string.isRequired,
  Common: PropTypes.object.isRequired,
  ClientData: PropTypes.object.isRequired,
  roleData: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
  '',
)(TrendGraphsHnM);
