import React, { Component } from 'react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import Chart from 'chart.js';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-annotation';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {
  Modal, Button, Overlay, Popover,
} from 'react-bootstrap';
import Moment from 'moment';
import i18n from 'i18next';
import axiosInstance from '../Shared/interceptor';
import { LocalApiBaseUrl, trycatchAlertPopup,idTokenstring } from '../Shared/Constant';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;

function formatDate(momentObj) {
  const dateFormat = 'MMM YYYY';
  return momentObj.format(dateFormat);
}
let minDate = new Date();
if (new Date().getMonth() === 11) {
  // special logic only if month is december. start date needs to be jan 2020

  const yearPart = new Date().getFullYear();
  const monthPart = 12 - new Date().getMonth() - 1; // 12 -11 - 1 to show jan
  const dayPart = 1;

  minDate = new Date(yearPart.toString(), monthPart.toString(), dayPart.toString(), '00', '00', '00');
} else {
  const current_month = new Date().getMonth();
  const yearPart = current_month < 6 ? (new Date().getFullYear() - 1) : new Date().getFullYear();
  // const monthPart = current_month + 7 < 10 ? ('0' + (current_month + 7)) : (current_month + 7 >= 10 && current_month + 7 <= 12 ) ? (current_month + 7) : (current_month - 5);
  let monthPart;
  if (current_month + 7 < 10) {
    monthPart = (`0${current_month + 7}`);
  } else if (current_month + 7 >= 10 && current_month + 7 <= 12) {
    monthPart = current_month + 7;
  } else {
    monthPart = current_month - 5;
  }
  minDate = new Date(yearPart, monthPart);
}
const allowedMinDate = new Date(minDate.toString());

class TrendGraphHnMJourney extends Component {
  constructor(props) {
    super(props);
    const dateBeforeOneYear = Moment().subtract(12, 'months');
    const defaultStartDateMoment1 = dateBeforeOneYear.isAfter(allowedMinDate)
      ? dateBeforeOneYear
      : allowedMinDate;
    const defaultEndDateMoment1 = Moment();

    const defaultStartDateMoment = Moment(defaultStartDateMoment1);
    const defaultEndDateMoment = Moment(defaultEndDateMoment1);

    const { show } = this.props;
    this.state = {
      show,
      dsKpiData: [],
      autoCompleteDdlClientsList: [],
      startDate: '',
      endDate: '',
      defaultDate: true,
      formattedStartDate: formatDate(defaultStartDateMoment),
      formattedEndDate: formatDate(defaultEndDateMoment),
      isStartEndDateSelected: true,
      tempStartDate: defaultStartDateMoment.toDate(),
      tempEndDate: defaultEndDateMoment.toDate(),
      formattedTempStartDate: formatDate(defaultStartDateMoment),
      formattedTempEndDate: formatDate(defaultEndDateMoment),
      isVisibleDatepickerDropdownPopover: false,
      showCanvas: false,
      showErrorMsg: false,
      // temp: [],                                                              // unused state var
      // dsKpiData2: [],                                                        // unused state var
      // labels: [],                                                            // unused state var
      // similarAvg: 0,                                                         // unused state var
      // maxMetricValue: 0,                                                     // unused state var
      // minMetrixValue: 0,                                                     // unused state var
      // OfferingName: '',                                                      // unused state var
      // EntityName: [],                                                        // unused state var
      // DealValue: [],                                                         // unused state var
      // value: 0,                                                              // unused state var
      // ProcessName: '',                                                       // unused state var
      // dealOnelabel: '',                                                      // unused state var
      // dealOnevalue: '',                                                      // unused state var
      // dealTwolabel: '',                                                      // unused state var
      // dealTwovalue: '',                                                      // unused state var
      // stoldDealOneValue: 0,                                                  // unused state var
      // stoldDealTwoValue: 0,                                                  // unused state var
      // percentageChangeDealOne: 0,                                            // unused state var
      // percentageChangeDealTwo: 0,                                            // unused state var
      // diffdeal1: 0,                                                          // unused state var
      // diffdeal2: 0,                                                          // unused state var
      // selValueDdl: '',                                                       // unused state var
      // dealTwoDataPoints: [],                                                 // unused state var
      // dealOneDataPoints: [],                                                 // unused state var
      // numMonths: 0,                                                          // unused state var
      //   dealComparisonGraphData: {                                           // unused state var
      //     labels: [],                                                        // unused state var
      //     datasets: []                                                       // unused state var
      //   },                                                                   // unused state var
      // operatingGroup: null,                                                  // unused state var
      // IndustryGroup: null,                                                   // unused state var
      // clientRegion: null,                                                    // unused state var
      // deliveryLocation: null,                                                // unused state var
      // maturityPhase: null,                                                   // unused state var
      //       filter: {                                                        // unused state var
      //  og: 'All', cr: 'All', dl: 'All', mp: 'All', io: 'All'                 // unused state var
      // },                                                                     // unused state var
      // period: { from: '', to: '' },                                          // unused state var
      // wholedata: null,                                                       // unused state var
      // filtereddata: null,                                                    // unused state var
      // loading: false,                                                        // unused state var
      // ShowDeals: false,                                                      // unused state var
      // tempFormattedStartDate: '',                                            // unused state var
      // tempFormattedEndDate: '',                                              // unused state var
      // tempPreviousFormattedStartDate: formatDate(defaultStartDateMoment),    // unused state var
      // tempPreviousFormattedEndDate: formatDate(defaultEndDateMoment),        // unused state var
    };

    this.datepickerDropdownPopoverTarget = React.createRef();
    this.insightMetricChart = React.createRef();
    this.startDatepickerRef = React.createRef();
    this.endDatepickerRef = React.createRef();
    this.handleHide = this.handleHide.bind(this);
    this.getKpiData = this.getKpiData.bind(this);
  }

  componentDidMount() {
    this.getKpiData();
  }

  handleHide() {
    const { onClose } = this.props;
    this.setState({ show: false });
    onClose();
  }

  getKpiData() {
    const { kpiMappingId: kpiMappingIdFromProps, kpiUnit } = this.props;
    const { endDate, startDate, defaultDate } = this.state;
    const kpiMappingId = kpiMappingIdFromProps;
    const updatedDate2 = `${new Date().getFullYear() - 1}-${(new Date().getMonth()) < 10 ? `0${new Date().getMonth() + 2}` : (new Date().getMonth() + 2)}`;
    const enddate = new Date(endDate);
    const startdate = new Date(startDate);
    const d1 = `${enddate.getFullYear()}-${enddate.getMonth() + 1}`;
    const s1 = `${startdate.getFullYear()}-${startdate.getMonth() + 1}`;
    const start1 = (String(startdate.getFullYear()) === String(enddate.getFullYear())) && String(startdate.getMonth()) === String(enddate.getMonth()) ? updatedDate2 : s1;
    axiosInstance.get(`${LocalApiBaseUrl}Analytics/GetKpiDataForHMJTrendGraph`, {
      params: { kpiMappingID: kpiMappingId, StartDate: start1, endDate: d1 },
    })
      .then((res) => {
        if (res.data.length === 0 && defaultDate) {
          this.setState({
            showErrorMsg: true,
            // temp: res.data  // unused state var
          });
        } else if (res.data.length === 0) {
          alert(i18n.t('Enough_data_is_not_available,_please_try_with_some_other_date_range'));
          this.setState({
            showCanvas: true,
            // temp: res.data,
            dsKpiData: {
              labels: [],
              datasets: [],
            },
          }, () => {
            this.showInsightChart();
          });
        } else {
          const MontnDt = [''];
          const ValueDt = [];
          res.data.forEach((element) => {
            MontnDt.push(element.PeriodDt);
            ValueDt.push(parseFloat(element.MetricValue));
          });
          const kpiMUnit = kpiUnit;
          this.setState({
            showCanvas: true,
            // temp: res.data,  // unused state var
            dsKpiData: {
              labels: MontnDt,
              datasets: [{
                label: kpiMUnit,
                data: [null, ...ValueDt],
                fill: false,
                lineTension: 0,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: '#66e0ff',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                floating: true,
                pointDotRadius: 8,
                datalabels: {
                  align: 'top',
                  enabled: true,
                },
                pointBorderColor: '#66e0ff',
                pointBackgroundColor: 'white',
                pointBorderWidth: 2,
                pointHoverRadius: 8,
                pointHoverBackgroundColor: 'white',
                pointHoverBorderColor: '#66e0ff',
                pointHoverBorderWidth: 2,
                pointRadius: 6,
                pointHitRadius: 20,
              },
              ],
            },
          }, () => {
            this.showInsightChart();
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

    // EVENT HANDLER FOR CALENDAR ICON IN DATEPICKER FIELDS, OPENS UP THE PICKER WHEN CLICKED
    handleUpdateDateClickField = (tempStart, tempEnd) => {
      const d1 = tempStart.getMonth();
      const d2 = tempEnd.getMonth();
      const d3 = String(tempStart.getFullYear()) === String(tempEnd.getFullYear()) ? (12 - d1) - (12 - d2) + 1 : (12 - d1) + d2 + 1;
      if (d3 > 2) {
        const formattedStartDate = !_.isNil(tempStart)
          ? formatDate(Moment(tempStart))
          : '';
        const formattedEndDate = !_.isNil(tempEnd)
          ? formatDate(Moment(tempEnd))
          : '';
        this.setState({
          startDate: tempStart,
          formattedStartDate,
          endDate: tempEnd,
          formattedEndDate,
          isVisibleDatepickerDropdownPopover: false,
        });

        // IF EITHER THE START DATE OR END DATE HAS CHANGED FROM THE PREVIOUS VALUES
        // OR THEIR VALUES ARE EMPTY THEN VALIDATE THE VALUES
        // AND IF VALUES ARE FILLED THEN ONLY EXECUTE THE API CALL
        if (
          formattedStartDate !== null
                || formattedEndDate !== null
                || _.isEmpty(formattedStartDate)
                || _.isEmpty(formattedEndDate)
        ) {
          this.setState(
            {
              // tempPreviousFormattedStartDate: formattedStartDate,        // unused state var
              // tempPreviousFormattedEndDate: formattedEndDate,            // unused state var
            },
            () => {
              setTimeout(() => {
                let errMsg = '';
                if (_.isEmpty(formattedStartDate) && _.isEmpty(formattedEndDate)) {
                  errMsg = `${i18n.t('Both')} "${i18n.t('Start_Month')}" ${i18n.t('and')} "${i18n.t('End_Month')}" ${i18n.t('should_be_filled_to_retrieve_the_results')}`;
                } else if (
                  _.isEmpty(formattedStartDate)
                                && !_.isEmpty(formattedEndDate)
                ) {
                  errMsg = `"${i18n.t('Start_Month')}" ${i18n.t('should_be_filled_to_retrieve_the_results')}`;
                } else if (
                  !_.isEmpty(formattedStartDate)
                                && _.isEmpty(formattedEndDate)
                ) {
                  errMsg = `"${i18n.t('End_Month')}" ${i18n.t('should_be_filed_to_retrieve_the_results')}`;
                }
                if (!_.isEmpty(errMsg)) {
                  window.alert(errMsg);
                } else {
                  this.setState({
                    defaultDate: false,
                  });
                  this.getKpiData();
                }
              }, 50);
            },
          );
        }
      } else {
        alert(i18n.t('Please-_select_minimum_three_months_to_view_the_graph'));
      }
    };

    handleChangeDateField = (date, isStartEndDateSelected) => {
      this.setState({
        [isStartEndDateSelected ? 'tempStartDate' : 'tempEndDate']: date,
        [isStartEndDateSelected
          ? 'formattedTempStartDate'
          : 'formattedTempEndDate']: formatDate(Moment(date)),
      });
    };

    handleClearDate = () => {
      const {
        startDate, endDate, formattedStartDate, formattedEndDate,
      } = this.state;
      this.setState({
        tempStartDate: startDate,
        tempEndDate: endDate,
        formattedTempStartDate: formattedStartDate,
        formattedTempEndDate: formattedEndDate,
      });
    };

    // HIDES THE "DATE SELECTION DROPDOWN" WHEN CLICKED OUTSIDE THE POPOVER
    hideDatepickerDropdownPopover = () => {
      this.setState({
        isVisibleDatepickerDropdownPopover: false,
      });
    };

    toggleDatepickerDropdownPopover = () => {
      const {
        isVisibleDatepickerDropdownPopover, startDate, endDate, formattedStartDate, formattedEndDate,
      } = this.state;
      this.setState(
        {
          isVisibleDatepickerDropdownPopover: !isVisibleDatepickerDropdownPopover,
          isStartEndDateSelected: true,
        },
      );
      if (!isVisibleDatepickerDropdownPopover) {
        this.setState({
          tempStartDate: startDate,
          tempEndDate: endDate,
          formattedTempStartDate: formattedStartDate,
          formattedTempEndDate: formattedEndDate,
        });
      }
    };

    // SELECTS "START DATE" OR "END DATE"
    selectStartDate = () => {
      this.setState({
        isStartEndDateSelected: true,
      });
    };

    selectEndDate = () => {
      this.setState({
        isStartEndDateSelected: false,
      });
    };

    showInsightChart = () => {
      const { kpiUnit } = this.props;
      const { dsKpiData } = this.state;
      this.ctx2 = this.insightMetricChart.current.getContext('2d');
      if (!_.isNil(this.chart2)) {
        this.chart2.destroy();
      }
      const kpiMUnit = kpiUnit;

      const maxFromDataValues = dsKpiData.datasets.length > 0 ? _.max(dsKpiData.datasets[0].data) : [];
      const yAxisMaxVal = parseInt(maxFromDataValues, 10) + (parseInt(maxFromDataValues, 10) / dsKpiData.datasets[0].data.length) + 10;

      this.chart2 = new Chart(this.ctx2, {
        type: 'line',
        data: {
          labels: dsKpiData.labels,
          datasets: dsKpiData.datasets,
        },
        options: {
          maintainAspectRatio: true,
          responsive: true,
          tooltips: {
            mode: 'nearest',
            intersect: true,
            enabled: true,
            borderWidth: 3,
            filter(tooltipItem) {
              const tooltipDatasetIndex = tooltipItem.datasetIndex;
              const datasets = _.get(
                `${tooltipDatasetIndex}`,
              );
              return _.get(datasets, 'type', '') !== 'line';
            },
            callbacks: {
              label(tooltipItem) {
                const tooltipDatasetIndex = tooltipItem.datasetIndex;
                let valWithUnit;
                const { yLabel } = tooltipItem.yLabel;
                valWithUnit = yLabel;
                if (!_.isEmpty(kpiMUnit)) {
                  valWithUnit = kpiMUnit === '$' ? `$${yLabel}` : `${yLabel} ${kpiMUnit}`;
                }
                if (tooltipDatasetIndex !== 0) {
                  const datasets = _.get(
                    `${tooltipDatasetIndex}`,
                  );
                  valWithUnit = `${datasets.label}: ${valWithUnit}`;
                }
                return valWithUnit;
              },
            },
          },
          // testing for tooltip end
          hover: {
            mode: 'index',
            intersect: false,
          },
          layout: {
            padding: {
              top: 20,
              bottom: 20,
            },
          },
          scales: {
            yAxes: [{
              ticks: {
                precision: 1,
                max: yAxisMaxVal,
                beginAtZero: false,
                callback(value, index, values) {
                  let valWithUnit = value;
                  if (index === 0) {
                    if (values[1] + 1.5 > values[0]) {
                      return (`${valWithUnit}    `);
                    }
                    return valWithUnit;
                  }
                  if (!_.isEmpty(kpiMUnit)) {
                    valWithUnit = kpiMUnit === '$' ? `$${value}` : `${value}`;
                  }
                  return valWithUnit;
                },
              },
              gridLines: {
                drawOnChartArea: true,
              },
              scaleLabel: {
                display: true,
                labelString: `${i18n.t('Metric_Unit')}(${kpiMUnit})`,
                color: '#637381',
                fontSize: 18,
                fontStyle: 'bold',
              },
            }],
            xAxes: [{
              gridLines: {
                drawOnChartArea: true,
              },
              scaleLabel: {
                display: true,
                labelString: i18n.t('Timeframe'),
                color: '#637381',
                fontSize: 18,
                fontStyle: 'bold',
              },
            }],
          },
          legend: {
            display: false,
          },
        },
      });
    }

    render() {
      const {
        kpiName,
      } = this.props;
      const {
        isVisibleDatepickerDropdownPopover,
        autoCompleteDdlClientsList,
        startDate,
        endDate,
        isStartEndDateSelected,
        tempStartDate,
        tempEndDate,
        show,
        showCanvas,
        formattedTempStartDate,
        formattedTempEndDate,
        formattedStartDate,
        formattedEndDate,
        showErrorMsg,
      } = this.state;
      const dealListarr = [];
      autoCompleteDdlClientsList.forEach((arrayItem) => {
        dealListarr.push({ name: arrayItem.OrgEntityName, id: arrayItem.OrgEntityID, abbr: arrayItem.OrgEntityName.substr(0, 2) });
      });
      Chart.defaults.global.defaultFontColor = 'black';
      return (
        <Modal show={show} onHide={this.handleHide} dialogClassName="trendGraphModal" size="XL" className="modal fade v-center" id="dealpopup" backdrop="static">
          <Modal.Header closeButton className="no-border justify-content-center">
            <h4 className="modal-title" id="dealprofile">
              {' '}
              {kpiName}
              {' '}
            </h4>
          </Modal.Header>
          <Modal.Body>
            {showCanvas
              ? (
                <div className="analyticsFilter no-whiteShadow px-0 py-2">
                  <div className="chooseOption mb-10">
                    <Overlay
                      target={this.datepickerDropdownPopoverTarget.current}
                      show={isVisibleDatepickerDropdownPopover}
                      placement="bottom"
                      rootClose
                      rootCloseEvent="click"
                      onHide={this.hideDatepickerDropdownPopover}
                    >
                      <Popover id="popover-basic4" className="calenderPopover">
                        <Popover.Content>
                          <div className="popContent">
                            <div className="popContentDisplay">
                              <div
                                className={isStartEndDateSelected ? 'calenderPopoverSpanActive' : 'calenderPopoverSpan'}
                                target={this.startDatepickerRef}
                                onClick={this.selectStartDate}
                                role="button"
                                tabIndex="0"
                              >
                                <div className="CalenderDatepickerLabel">
                                  {i18n.t('Start_Date')}
                                </div>
                                <div className="CalenderDatepickerDate">
                                  {formattedTempStartDate}
                                </div>
                              </div>
                              <div
                                className={isStartEndDateSelected ? 'calenderPopoverSpan' : 'calenderPopoverSpanActive'}
                                target={this.endDatepickerRef}
                                onClick={this.selectEndDate}
                                role="button"
                                tabIndex="0"
                              >
                                <div className="CalenderDatepickerLabel">
                                  {i18n.t('End_Date')}
                                </div>
                                <div className="CalenderDatepickerDate">
                                  {formattedTempEndDate}
                                </div>
                              </div>
                            </div>
                            {isStartEndDateSelected ? (
                              <div>
                                <DatePicker
                                  selected={
                                                                tempStartDate || startDate
                                                            }
                                  onChange={(date) => this.handleChangeDateField(
                                    date,
                                    isStartEndDateSelected,
                                  )}
                                  dateFormat="MMM yyyy"
                                  showMonthYearPicker
                                  strictParsing
                                  inline
                                  className="calenderDatepicker"
                                  minDate={allowedMinDate}
                                  maxDate={!_.isNil(tempEndDate || endDate) ? Moment(tempEndDate || endDate).toDate() : Moment(new Date()).toDate()}
                                  placeholderText="Start Month"
                                  ref={this.startDatepickerRef}
                                />
                              </div>
                            ) : (
                              <DatePicker
                                selected={tempEndDate || endDate}
                                onChange={(date) => this.handleChangeDateField(
                                  date,
                                  isStartEndDateSelected,
                                )}
                                dateFormat="MMM yyyy"
                                showMonthYearPicker
                                strictParsing
                                inline
                                className="calenderDatepicker"
                                minDate={!_.isNil(tempStartDate || startDate) ? Moment(tempStartDate || startDate).toDate() : null}
                                maxDate={new Date()}
                                placeholderText={i18n.t('End_Month')}
                                ref={this.endDatepickerRef}
                              />
                            )}
                          </div>
                        </Popover.Content>
                        <div className="popover-footer">
                          <div className="modal-btn temp">
                            <Button
                              variant="default"
                              className="btn-sm mr-auto"
                              onClick={() => {
                                this.handleClearDate();
                              }}
                            >
                              {i18n.t('Clear')}
                            </Button>
                            <Button
                              variant="primary"
                              className="btn-sm"
                              onClick={() => this.handleUpdateDateClickField(
                                tempStartDate,
                                tempEndDate,
                              )}
                            >
                              {i18n.t('Update')}
                            </Button>
                          </div>
                        </div>
                      </Popover>
                    </Overlay>
                    <span
                      className={isVisibleDatepickerDropdownPopover ? 'active' : ''}
                      ref={this.datepickerDropdownPopoverTarget}
                      onClick={this.toggleDatepickerDropdownPopover}
                      role="button"
                      tabIndex="0"
                    >
                      {formattedStartDate === formattedEndDate
                        ? formattedStartDate
                        : `${formattedStartDate
                        } - ${
                          formattedEndDate}`}
                      <i
                        className="fal fa-calendar-alt"
                        style={{ marginLeft: '10px' }}
                      />
                    </span>
                  </div>
                </div>
              )
              : null}
            {showCanvas
              ? (
                <canvas
                  id="myChart2"
                  width="400"
                  height="200"
                  className="mt-4 mb-5"
                  ref={this.insightMetricChart}
                />
              )
              : null}
            {showErrorMsg
              ? (
                <div align="center" className="emptyView">
                  {i18n.t('Enough_data_is_not_available_for_generating_the_graph')}
                </div>
              ) : null}
            <ReactTooltip place="top" className="tipcolor" />
          </Modal.Body>
        </Modal>
      );
    }
}
TrendGraphHnMJourney.propTypes = {
  kpiName: PropTypes.bool.isRequired,
  kpiUnit: PropTypes.bool.isRequired,
  kpiMappingId: PropTypes.bool.isRequired,
  onClose: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  // boiTrendsDates: PropTypes..isRequired,
};
export default TrendGraphHnMJourney;
