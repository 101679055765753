import React, { Component } from "react";
import i18n from "i18next";
import "./JourneyInitiative.css";

export class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
        postsPerPage:this.props.postsPerPage,
        totalPosts:this.props.totalPosts,
        currentPage:this.props.currentPage,
        pageNumbers:[],
    };
  }

  pageNumbersSetting = (totalPosts,postsPerPage)=>{
    let pageNumbers=[]
    for(let i=1;i<=Math.ceil(totalPosts/postsPerPage);i++)
    {
      pageNumbers.push(i)
    }
    this.setState({
        pageNumbers,
    })
  }
  componentDidMount(){
      let postsPerPage = this.props.postsPerPage;
      let total = this.props.totalPosts;
      
    this.pageNumbersSetting(total,postsPerPage);
  }
  handleLeftPagination = ()=>{
      if(this.state.currentPage != 1)
      {
          let currentPage = this.state.currentPage;
          currentPage -=1;
          this.setState({
            currentPage,
          },()=>{
              this.props.handleCurrentPage(currentPage);
          })
      }
  }
  handleRightPagination = () =>{
    if(this.state.currentPage != this.state.pageNumbers.length)
    {
        let currentPage = this.state.currentPage;
          currentPage +=1;
          this.setState({
            currentPage,
          },()=>{
              this.props.handleCurrentPage(currentPage);
          })
    }
  }
  handleResetPage1 = ()=>{
      this.setState({
        currentPage:1,
      },()=>{
        this.props.handleCurrentPage(1);
      })
  }

  handlePostsPerPage = (e)=>{
      this.setState({
          postsPerPage:e.target.value,
          currentPage:1
      },()=>{
        this.props.handleCurrentPage(1)
        this.props.changePostsPerPage(e.target.value)

      })
  }

  changeCurrentPage =(data) =>{
    this.setState({
        currentPage:data
    },()=>{
        this.props.handleCurrentPage(data)
    })
  }

  componentWillReceiveProps(nextProps){
    let postsPerPage = nextProps.postsPerPage;
    let total = nextProps.totalPosts;
    let currentPage = nextProps.currentPage 
    if(currentPage != undefined)
    {
      this.setState({
        currentPage,
      })
    }
    
    this.pageNumbersSetting(total,postsPerPage);
  }



  render() {
      const { postsPerPage,totalPosts,pageNumbers,currentPage } = this.state;
      
    return (
      <div className="pagination-list">
        <ul className="pagination">
          <li>
            <span className="page-link pagination-icon" onClick={this.handleResetPage1}>
              <i class="fas fa-arrow-alt-to-left"></i>
            </span>
          </li>
          <li onClick={this.handleLeftPagination}>
            <span className="page-link pagination-icon">
              <i class="fas fa-arrow-alt-left"></i>
            </span>
          </li>
          {
              pageNumbers.map(each=><li onClick={()=>{this.changeCurrentPage(each)}}>
                <span className={currentPage == each?"page-link active":"page-link"}>{each}</span>
              </li>)
          }
          <li onClick={this.handleRightPagination}>
            <span className="page-link pagination-icon">
              <i class="fas fa-arrow-alt-right"></i>
            </span>
          </li>
        </ul>
        <div className="select-custom">
          <span>{i18n.t("Page_Size")}</span>
          <select className="dropdown" value={postsPerPage} onChange={(e)=>{this.handlePostsPerPage(e)}}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
        </div>
      </div>
    );
  }
}

export default Pagination;
