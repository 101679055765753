import React from "react";
import { Modal } from "react-bootstrap";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import MultiSelect from "react-multi-select-component";
import i18n from "i18next";

const overrideStrings = {
  selectSomeItems: "Select",
  allItemsAreSelected: "All items selected.",
  selectAll: "Select All ",
  search: "Search",
};

export default class OneTimeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      apivalue: "",
      Display: false,
      viewmore: false,
      errors: {},
      formIsValid: false,
      SourceDetails: [],
      isPropsLoaded: false,

      SourceClientOptions: [],
      selectedClientOption: [],
      SourceOfferingOptions: [],
      selectedOfferingOption: [],
      SourceSubOfferingOptions: [],
      selectedSubOfferingOption: [],
      SourceLocationOptions: [],
      selectedLocationsOption: [],

      filterDisplay: { cl: false, off: false, sub: false, loc: false },
      friendlyNames: { cl: "", off: "", sub: "", loc: "" },
      multiselect: { cl: false, off: false, sub: false, loc: false },
      isApiCalled: { cl: false, off: false, sub: false, loc: false },

      OfferingOptions: [],
      LocationOptions: [],
      processOptions: [],
      DestinationOfferingValueID: 0,

      OneTimeSetupModel: {
        isActive: true,
        destinationClientId: 0,
        destinationClientName: "",

        destinationOfferingId: 0,
        destinationOfferingName: "",

        destinationSubOfferingId: 0,
        destinationSubOfferingName: "",

        destinationLocationId: 0,
        destinationLocationName: "",

        sourceConnectorID: 0,
        sourceConnectorName: "",

        sourceDealID: 0,
        sourceDealName: "",

        sourceClientId: 0,
        sourceClientName: [],

        sourceOfferingId: 0,
        sourceOfferingName: [],

        sourceSubOfferingId: 0,
        sourceSubOfferingName: [],

        sourceLocationId: 0,
        sourceLocationName: [],
      },
      programDetailsForSVM:{
        programList:[],
        selectedProgramOption:[],
      }
    };
    this.validateForm = this.validateForm.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getGetConnectorMaster = this.getGetConnectorMaster.bind(this);
  }

  ////////---- Stand alone Methods

  componentDidMount() {
    this.getGetConnectorMaster();
    if (this.props.data) {
      let OneTimeSetupModel = this.props.data;
      this.setState({
        show: this.props.show,
        OneTimeSetupModel: OneTimeSetupModel,
        errors: {},
        formIsValid: true,
      });
      this.getFilterDetails(this.props.data.sourceConnectorID);
    } else {
      var Client = store.getState().CurrentFilter.ClientData;
      let OneTimeSetupModel = this.state.OneTimeSetupModel;
      OneTimeSetupModel.destinationClientName = Client.name;
      OneTimeSetupModel.destinationClientId = Client.id;
      this.getOffering(Client.id);

      this.setState({
        show: this.props.show,
        OneTimeSetupModel: OneTimeSetupModel,
        errors: {},
        formIsValid: true,
      });
    }
  }
  handleReset() {}
  handleSave(e) {
    if(String(e) === "update" && this.state.OneTimeSetupModel.sourceConnectorName === "IOJ"){
      if (window.confirm(i18n.t("If you are updating one time setup for any journey, all the initiatives for that journey will get deleted permanently. Are you sure you want to continue?"))){
        if (this.validateForm()) {
          let OneTimeSetupModel = this.state.OneTimeSetupModel;
          OneTimeSetupModel.sourceClientName = this.state.OneTimeSetupModel.sourceClientName.toString();
          OneTimeSetupModel.sourceOfferingName = this.state.OneTimeSetupModel.sourceOfferingName.join("|");
          OneTimeSetupModel.sourceSubOfferingName = this.state.OneTimeSetupModel.sourceSubOfferingName.join("|");
          OneTimeSetupModel.sourceLocationName = this.state.OneTimeSetupModel.sourceLocationName.join("|");
          this.props.onSave(OneTimeSetupModel);
          this.handleHide();
        }
      }
    }
    else{
      if (this.validateForm()) {
        let OneTimeSetupModel = this.state.OneTimeSetupModel;
        OneTimeSetupModel.sourceClientName = this.state.OneTimeSetupModel.sourceClientName.toString();
        OneTimeSetupModel.sourceOfferingName = this.state.OneTimeSetupModel.sourceOfferingName.join("|");
        OneTimeSetupModel.sourceSubOfferingName = this.state.OneTimeSetupModel.sourceSubOfferingName.join("|");
        OneTimeSetupModel.sourceLocationName = this.state.OneTimeSetupModel.sourceLocationName.join("|");
        if(OneTimeSetupModel.sourceConnectorName === "SVM" ){
          OneTimeSetupModel.ProgramID = this.state.programDetailsForSVM.selectedProgramOption[0].value
          OneTimeSetupModel.ProgramName = this.state.programDetailsForSVM.selectedProgramOption[0].label
        }

        this.props.onSave (OneTimeSetupModel);
        this.handleHide();
      }
    }
    }
   
  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }
  validateForm() {
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    let errors = {};
    let formIsValid = true;

    if (this.props.data) {
      if (!OneTimeSetupModel["destinationOfferingName"]) {
        formIsValid = false;
        errors["destinationOfferingId"] = "Offering  is required";
      }
    } else if (this.state.DestinationOfferingValueID == 0) {
      formIsValid = false;
      errors["destinationOfferingId"] = "Offering  is required";
    }

    if (!OneTimeSetupModel["destinationSubOfferingId"]) {
      formIsValid = false;
      errors["destinationSubOfferingId"] = "Sub Offering is required";
    }
    if (!OneTimeSetupModel["destinationLocationId"]) {
      formIsValid = false;
      errors["destinationLocationId"] = "Location is required";
    }

    if (!OneTimeSetupModel["sourceConnectorID"]) {
      formIsValid = false;
      errors["sourceConnectorID"] = "Source is required";
    }
    if (OneTimeSetupModel["sourceClientName"].length == 0 && this.state.filterDisplay.cl) {
      formIsValid = false;
      errors["sourceClientName"] = this.state.friendlyNames.cl + " is required";
    }

    if (OneTimeSetupModel["sourceLocationName"].length == 0 && this.state.filterDisplay.loc) {
      formIsValid = false;
      errors["sourceLocationName"] = this.state.friendlyNames.loc+" is required";
    }

    if (OneTimeSetupModel["sourceOfferingName"].length == 0 && this.state.filterDisplay.off) {
      formIsValid = false;
      errors["sourceOfferingName"] = this.state.friendlyNames.off+ " is required";
    }

    if (OneTimeSetupModel["sourceSubOfferingName"].length == 0 && this.state.filterDisplay.sub) {
      formIsValid = false;
      errors["sourceSubOfferingName"] = this.state.friendlyNames.sub+" is required";
    }

    if(OneTimeSetupModel.sourceConnectorName === "SVM" && (this.state.programDetailsForSVM.selectedProgramOption.length === 0 || this.state.programDetailsForSVM.selectedProgramOption[0].value === null)){
      formIsValid = false;
      errors["sourceProgramName"] = "Program Name is Required"
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  ///------------ON Deal side Get Methods
  getLocation(processValueID) {
    let samp = {};
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetLocation`, {
        params: { OrgEntityID: this.state.OneTimeSetupModel.destinationClientId, OfferingID: processValueID },
      })
      .then((response) => {
        if (this.props.data != null) {
        } else {
          this.setState({
            LocationOptions: response.data,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }
  getOffering(OrgEntityID) {
    let samp = {};
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        params: { OrgEntityID: OrgEntityID, featureName: "One-Time Setup" },
      })
      .then((response) => {
        this.setState({
          OfferingOptions: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }

  ///------------ON Deal side Change Methods
  onDestinationOfferingsChange(event) {
    this.setState({ errors: {} });
    let DestinationOfferingValueID = event.target.value;
    let processOptions = event.target.value
      ? this.state.OfferingOptions.filter((data) => data.OfferingID == event.target.value)[0].ChildOfferings
      : [];
    this.setState({
      DestinationOfferingValueID: DestinationOfferingValueID,
      processOptions: processOptions,
    });
  }
  onDestinationProcessChange() {
    this.setState({ errors: {} });
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    var event = document.getElementById("Process");
    OneTimeSetupModel.destinationSubOfferingId = event.options[event.selectedIndex].value;
    OneTimeSetupModel.destinationSubOfferingName = event.options[event.selectedIndex].label;
    this.getLocation(OneTimeSetupModel.destinationSubOfferingId);
    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
    });
  }
  onDestinationLocationChange(event) {
    this.setState({ errors: {} });
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    var event = document.getElementById("Location");
    OneTimeSetupModel.destinationLocationId = event.options[event.selectedIndex].value;
    OneTimeSetupModel.destinationLocationName = event.options[event.selectedIndex].label;
    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
    });
  }

  ///------------ON Source side Get Methods
  getGetConnectorMaster() {
    let source = [];
    axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`).then((response) => {
      source = response.data;
      let sourceValues = source.map((x) => (
        <option key={x.ConnectorID} value={x.ConnectorID}>
          {x.ConnectorName}
        </option>
      ));
      this.setState({ sourceOptions: sourceValues });
    })
    .catch((error) => {
      trycatchAlertPopup(error);
    });


  }
  getFilterDetails(ConnectorId) {
    let filterDisplay = this.state.filterDisplay;
    let friendlyNames = this.state.friendlyNames;
    let multiselect = this.state.multiselect;
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/SourceSystemFilter`, {
        params: { sourceId: ConnectorId },
      })
      .then((row) => {
        let cell = [];
        if (row.data.response[0].FieldName === "Client") {
          cell = row.data.response[0];
          filterDisplay.cl = cell.isVisible;
          friendlyNames.cl = cell.friendlyName;
          multiselect.cl = cell.multiSelection;
        }
        if (row.data.response[1].FieldName === "Offering") {
          cell = row.data.response[1];
          filterDisplay.off = cell.isVisible;
          friendlyNames.off = cell.friendlyName;
          multiselect.off = cell.multiSelection;
        }
        if (row.data.response[2].FieldName === "SubOffering") {
          cell = row.data.response[2];
          filterDisplay.sub = cell.isVisible;
          friendlyNames.sub = cell.friendlyName;
          multiselect.sub = cell.multiSelection;
        }
        if (row.data.response[3].FieldName === "Location") {
          cell = row.data.response[3];
          filterDisplay.loc = cell.isVisible;
          friendlyNames.loc = cell.friendlyName;
          multiselect.loc = cell.multiSelection;
        }

        this.setState({
          filterDisplay: filterDisplay,
          friendlyNames: friendlyNames,
          multiselect: multiselect,
        });

        this.filterSelectionControl();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }
  filterSelectionControl() {
    let isApiCalled = this.state.isApiCalled;
    let isVisible = this.state.filterDisplay;

    if (isVisible.cl && !isApiCalled.cl) {
      if(this.props.data.sourceConnectorName === "SVM"){
        this.getSourceClientsForSvm();               
      }else{
        this.getSourceClients();
      }
      isApiCalled.cl = true;
      this.setState({
        isApiCalled: isApiCalled,
      });
    } else if (isVisible.off && !isApiCalled.off) {
      this.getSourceOfferings();
      isApiCalled.off = true;
      this.setState({
        isApiCalled: isApiCalled,
      });
    } else if (isVisible.sub && !isApiCalled.sub) {
      this.getSourceSubOfferings();
      isApiCalled.sub = true;
      this.setState({
        isApiCalled: isApiCalled,
      });
    } else if (isVisible.loc && !isApiCalled.loc) {
      this.getSourceLocations();
      isApiCalled.loc = true;
      this.setState({
        isApiCalled: isApiCalled,
      });
    }
  }

  async getSourceDataForSvm(clientId = 0,isProgram = false) {
    return new Promise((resolve, reject) => {    
      axiosInstance
        .get(`${LocalApiBaseUrl}ConnectorMapping/GetSourceProgram?ClientId=${clientId}&IsProgram=${isProgram}`)

        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  }

  getSourceProgram = async(clientId,first = 0) =>{
       
    const {programDetailsForSVM} = this.state;
    if(clientId !== null){
      const programData = await this.getSourceDataForSvm(clientId,true)
    
      const programList = programData.data.ProgramList.map(e => {
        return {label:e.ProgramName,value:e.ProgramID}
      })  
      
      programDetailsForSVM.programList = programList
      
    }else{
      programDetailsForSVM.programList = [];
      
    }
    if(!first)
      programDetailsForSVM.selectedProgramOption = [];

    this.setState({
      programDetailsForSVM 
    })
   
  }
  
  getSourceClientsForSvm= async()=> {

    const svmClients = await this.getSourceDataForSvm();

    const {programDetailsForSVM} = this.state;    

       let SourceClientOptions = [];
        let selectedClientOption = [];
        let sortedClients = svmClients.data.clientList.sort((a, b) => (a.name > b.name ? 1 : -1));

        sortedClients.map((row, i) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.ClientID;
            SourceClientOptions.push(dummy);

            if (this.props.data && this.state.isPropsLoaded == false) {
              if (this.state.OneTimeSetupModel.sourceClientName.includes(row.name)) {
                selectedClientOption.push(dummy);
                programDetailsForSVM.selectedProgramOption = [{ "label":this.state.OneTimeSetupModel.ProgramName,"value":this.state.OneTimeSetupModel.ProgramID}]
                this.getSourceProgram(row.ClientID,1)
              }
            }
          }
        });

        this.setState({ SourceClientOptions: SourceClientOptions, selectedClientOption: selectedClientOption, programDetailsForSVM });

        if (this.props.data && this.state.isPropsLoaded == false) {
          this.filterSelectionControl();
        }
      

  }

  getSourceClients() {
    let SourceClientOptions = [];
    let sourceId = this.state.OneTimeSetupModel.sourceConnectorID;

    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetSourceClient?sourceId=${sourceId}`)

      .then((response) => {
        let SourceClientOptions = [];
        let selectedClientOption = [];
        let sortedClients = response.data.clientList.sort((a, b) => (a.name > b.name ? 1 : -1));

        sortedClients.map((row, i) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            SourceClientOptions.push(dummy);

            if (this.props.data && this.state.isPropsLoaded == false) {
              if (this.state.OneTimeSetupModel.sourceClientName.includes(row.name)) {
                selectedClientOption.push(dummy);
              }
            }
          }
        });
        
        this.setState({ SourceClientOptions: SourceClientOptions, selectedClientOption: selectedClientOption });
        if (this.props.data && this.state.isPropsLoaded == false) {
          this.filterSelectionControl();
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }
  getSourceOfferings() {
    let sourceId = this.state.OneTimeSetupModel.sourceConnectorID;
    let clientName = this.state.OneTimeSetupModel.sourceClientName.toString();
    clientName = encodeURIComponent(clientName);
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetSourceOffering?sourceId=${sourceId}&clientName=${clientName}`)
      .then((response) => {
        let SourceOfferingOptions = [];
        let selectedOfferingOption = [];
        let sortedOffering = response.data.offeringList.sort((a, b) => (a.name > b.name ? 1 : -1));

        sortedOffering.map((row, i) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            dummy.id = row.OrgEntityID;
            SourceOfferingOptions.push(dummy);

            if (this.props.data && this.state.isPropsLoaded == false) {
              if (this.state.OneTimeSetupModel.sourceOfferingName.includes(row.name)) {
                selectedOfferingOption.push(dummy);
              }
            }
          }
        });
        this.setState({ SourceOfferingOptions: SourceOfferingOptions, selectedOfferingOption: selectedOfferingOption });
        if (this.props.data && this.state.isPropsLoaded == false) {
          this.filterSelectionControl();
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }
  getSourceSubOfferings() {
    let sourceId = this.state.OneTimeSetupModel.sourceConnectorID;
    let clientName = this.state.OneTimeSetupModel.sourceClientName.toString();
    clientName = encodeURIComponent(clientName);
    let offeringName = this.state.OneTimeSetupModel.sourceOfferingName.join("|");
    offeringName = encodeURIComponent(offeringName);

    axiosInstance
      .get(
        `${LocalApiBaseUrl}ConnectorMapping/GetSourceSubOffering?sourceId=${sourceId}&clientName=${clientName}&offeringName=${offeringName}`
      )

      .then((response) => {
        let SourceSubOfferingOptions = [];
        let selectedSubOfferingOption = [];

        let sortedSubOffering = response.data.subOfferingList.sort((a, b) => (a.name > b.name ? 1 : -1));

        sortedSubOffering.map((row, i) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            SourceSubOfferingOptions.push(dummy);

            if (this.props.data && this.state.isPropsLoaded == false) {
              if (this.state.OneTimeSetupModel.sourceSubOfferingName.includes(row.name)) {
                selectedSubOfferingOption.push(dummy);
              }
            }
          }
        });
        this.setState({
          SourceSubOfferingOptions: SourceSubOfferingOptions,
          selectedSubOfferingOption: selectedSubOfferingOption,
        });
        if (this.props.data && this.state.isPropsLoaded == false) {
          this.filterSelectionControl();
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }
  getSourceLocations() {
    let sourceId = this.state.OneTimeSetupModel.sourceConnectorID;
    let clientName = this.state.OneTimeSetupModel.sourceClientName.toString();
    let offeringName = this.state.OneTimeSetupModel.sourceOfferingName.join("|");
    let subOfferingname = this.state.OneTimeSetupModel.sourceSubOfferingName.join("|");

    // new method
    let data = {};
    data.sourceId = sourceId;
    data.clientName = clientName;
    data.offeringName = offeringName;
    data.subOfferingname = subOfferingname;
    axiosInstance.post(`${LocalApiBaseUrl}ConnectorMapping/GetSourceLocation`, data).then((response) => {
      let SourceLocationOptions = [];
      let selectedLocationsOption = [];
      let sortedlocation = response.data.sort((a, b) => (a.name > b.name ? 1 : -1));
      sortedlocation.map((row, i) => {
        if (row.name != "") {
          let dummy = [];
          dummy.label = row.name;
          dummy.value = row.name;
          SourceLocationOptions.push(dummy);
          if (this.props.data && this.state.isPropsLoaded == false) {
            if (this.state.OneTimeSetupModel.sourceLocationName.includes(row.name)) {
              selectedLocationsOption.push(dummy);
            }
          }
        }
      });
      this.setState({ SourceLocationOptions: SourceLocationOptions, selectedLocationsOption: selectedLocationsOption });
      if (this.props.data && this.state.isPropsLoaded == false) {
        this.filterSelectionControl();
      }
    })
    .catch((error) => {
      trycatchAlertPopup(error);
    });
  }

  ///------------ ON Source side Change Methods
  onSourceChange(event) {
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    let isApiCalled = this.state.isApiCalled;
    let filterDisplay = this.state.filterDisplay;

    OneTimeSetupModel.sourceConnectorID = event.target.value;
    let index = event.nativeEvent.target.selectedIndex;
    OneTimeSetupModel.sourceConnectorName = event.nativeEvent.target[index].text;

    filterDisplay.loc = false;
    filterDisplay.sub = false;
    filterDisplay.off = false;
    filterDisplay.cl = false;
    isApiCalled.loc = false;
    isApiCalled.sub = false;
    isApiCalled.off = false;
    isApiCalled.cl = false;
    OneTimeSetupModel.sourceLocationName = [];
    OneTimeSetupModel.sourceSubOfferingName = [];
    OneTimeSetupModel.sourceOfferingName = [];
    OneTimeSetupModel.sourceClientName = [];

    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
      isPropsLoaded: true,
      isApiCalled: isApiCalled,
      filterDisplay: filterDisplay,
      SourceLocationOptions: [],
      selectedLocationsOption: [],
      SourceSubOfferingOptions: [],
      selectedSubOfferingOption: [],
      SourceOfferingOptions: [],
      selectedOfferingOption: [],
      SourceClientOptions: [],
      selectedClientOption: [],
    });

    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
    });

    // callingFilterDescription function
    this.getFilterDetails(event.target.value);

  }
  onSourceProgramSelect = (e) =>{
    const {programDetailsForSVM} = this.state;
      
      if(e.length>0){
        programDetailsForSVM.selectedProgramOption = [e[e.length -1]];
      }
      else{
        programDetailsForSVM.selectedProgramOption = [];
      }
      this.setState({
        programDetailsForSVM,
      })
  }
  onSourceClientSelect(selectedList) {
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    let isApiCalled = this.state.isApiCalled;
    isApiCalled.loc = false;
    isApiCalled.sub = false;
    isApiCalled.off = false;
    OneTimeSetupModel.sourceLocationName = [];
    OneTimeSetupModel.sourceSubOfferingName = [];
    OneTimeSetupModel.sourceOfferingName = [];

    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
      errors: {},

      isApiCalled: isApiCalled,
      SourceLocationOptions: [],
      selectedLocationsOption: [],
      SourceSubOfferingOptions: [],
      selectedSubOfferingOption: [],
      SourceOfferingOptions: [],
      selectedOfferingOption: [],
      isPropsLoaded: true,
    });

    let selectedClientOption = this.state.selectedClientOption;
    if (this.state.multiselect.cl) {
      let tempClient = [];
      let tempClientValue = [];
      let len = selectedList.length;

      if (len !== 0) {
        for (let i = 0; i < len; i++) {
          tempClient.push(selectedList[i]);
          tempClientValue.push(selectedList[i].value);
        }
      } else {
        isApiCalled.loc = true;
        isApiCalled.sub = true;
        isApiCalled.off = true;
      }
      selectedClientOption = tempClient;
      OneTimeSetupModel.sourceClientName = tempClientValue;
    } else {
      let tempClient = [];
      let tempClientValue = [];
      let len = selectedList.length;
      if (len !== 0) {
        tempClient.push(selectedList[len - 1]);
        tempClientValue.push(selectedList[len - 1].value);
      } else {
        isApiCalled.loc = true;
        isApiCalled.sub = true;
        isApiCalled.off = true;
      }
      
      selectedClientOption = tempClient;
      OneTimeSetupModel.sourceClientName = tempClientValue;
      if(OneTimeSetupModel.sourceConnectorName === "SVM" ){
        if(tempClient.length>0){
          OneTimeSetupModel.sourceClientId = tempClient[0].value;
          this.getSourceProgram(tempClient[0].value)
        }else{
          OneTimeSetupModel.sourceClientId = null;
          this.getSourceProgram(0)
        }
          
      }
    }
    this.setState({
      selectedClientOption: selectedClientOption,
      OneTimeSetupModel: OneTimeSetupModel,
      isApiCalled: isApiCalled,
    });

    this.filterSelectionControl();
  }
  onSourceOfferingSelect(selectedList) {
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    let isApiCalled = this.state.isApiCalled;
    isApiCalled.loc = false;
    isApiCalled.sub = false;
    OneTimeSetupModel.sourceLocationName = [];
    OneTimeSetupModel.sourceSubOfferingName = [];

    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
      isPropsLoaded: true,
      errors: {},

      isApiCalled: isApiCalled,
      SourceLocationOptions: [],
      selectedLocationsOption: [],
      SourceSubOfferingOptions: [],
      selectedSubOfferingOption: [],
    });
    let selectedOfferingOption = this.state.selectedOfferingOption;
    if (this.state.multiselect.off) {
      let tempOffering = [];
      let tempOfferingValue = [];
      let len = selectedList.length;

      if (len !== 0) {
        for (let i = 0; i < len; i++) {
          tempOffering.push(selectedList[i]);
          tempOfferingValue.push(selectedList[i].value);
        }
      } else {
        isApiCalled.loc = true;
        isApiCalled.sub = true;
      }
      selectedOfferingOption = tempOffering;
      OneTimeSetupModel.sourceOfferingName = tempOfferingValue;
    } else {
      let tempOffering = [];
      let tempOfferingValue = [];
      let len = selectedList.length;
      if (len !== 0) {
        tempOffering.push(selectedList[len - 1]);
        tempOfferingValue.push(selectedList[len - 1].value);
      } else {
        isApiCalled.loc = true;
        isApiCalled.sub = true;
      }

      selectedOfferingOption = tempOffering;
      OneTimeSetupModel.sourceOfferingName = tempOfferingValue;
    }
    this.setState({
      selectedOfferingOption: selectedOfferingOption,
      OneTimeSetupModel: OneTimeSetupModel,
      isApiCalled: isApiCalled,
    });
    this.filterSelectionControl();
  }
  onSourceProcessSelect(selectedList) {
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    let isApiCalled = this.state.isApiCalled;
    isApiCalled.loc = false;
    OneTimeSetupModel.sourceLocationName = [];

    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
      isPropsLoaded: true,
      errors: {},
      isApiCalled: isApiCalled,
      SourceLocationOptions: [],
      selectedLocationsOption: [],
    });

    let selectedSubOfferingOption = this.state.selectedSubOfferingOption;
    if (this.state.multiselect.sub) {
      let tempProcess = [];
      let tempProcessValue = [];
      let len = selectedList.length;

      if (len !== 0) {
        for (let i = 0; i < len; i++) {
          tempProcess.push(selectedList[i]);
          tempProcessValue.push(selectedList[i].value);
        }
      } else {
        isApiCalled.loc = true;
      }
      selectedSubOfferingOption = tempProcess;
      OneTimeSetupModel.sourceSubOfferingName = tempProcessValue;
    } else {
      let tempProcess = [];
      let tempProcessValue = [];

      let len = selectedList.length;
      if (len !== 0) {
        tempProcess.push(selectedList[len - 1]);
        tempProcessValue.push(selectedList[len - 1].value);
      } else {
        isApiCalled.loc = true;
      }

      selectedSubOfferingOption = tempProcess;
      OneTimeSetupModel.sourceSubOfferingName = tempProcessValue;
    }
    this.setState({
      selectedSubOfferingOption: selectedSubOfferingOption,
      OneTimeSetupModel: OneTimeSetupModel,
      isApiCalled: isApiCalled,
    });
    this.filterSelectionControl();
  }
  onSourceLocationSelect(selectedList) {
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    let selectedLocationsOption = this.state.selectedLocationsOption;
    if (this.state.multiselect.loc) {
      let tempLocation = [];
      let tempLocationValue = [];
      let len = selectedList.length;
      for (let i = 0; i < len; i++) {
        tempLocation.push(selectedList[i]);
        tempLocationValue.push(selectedList[i].value);
      }
      selectedLocationsOption = tempLocation;
      OneTimeSetupModel.sourceLocationName = tempLocationValue;
    } else {
      let tempLocation = [];
      let tempLocationValue = [];
      let len = selectedList.length;
      tempLocation.push(selectedList[len - 1]);
      tempLocationValue.push(selectedList[len - 1].value);
      selectedLocationsOption = tempLocation;
      OneTimeSetupModel.sourceLocationName = tempLocationValue;
    }
    this.setState({
      selectedLocationsOption: selectedLocationsOption,
      isPropsLoaded: true,
      errors: {},
      OneTimeSetupModel: OneTimeSetupModel,
    });
    this.filterSelectionControl();
  }

  render() {
    let { IsAdmin, IsCapability, IsGuest } = this.props.userRole;
    const programMulteSelectBar = (
      <>
        <MultiSelect
          options={this.state.programDetailsForSVM.programList}
          value={this.state.programDetailsForSVM.selectedProgramOption}
          onChange={this.props.dataoperation ? "" : (e)=> this.onSourceProgramSelect(e) }
          labelledBy="Program Name"
          disabled={this.props.dataoperation}
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={false}
        />
      </>
    );
    const clientMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={this.state.SourceClientOptions}
          value={this.state.selectedClientOption}
          onChange={this.props.dataoperation ? "" : this.onSourceClientSelect.bind(this)}
          labelledBy="OrgEntityName"
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={this.state.multiselect.cl}
        />
      </React.Fragment>
    );
    const offeringMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={this.state.SourceOfferingOptions}
          value={this.state.selectedOfferingOption}
          onChange={this.props.dataoperation ? "" : this.onSourceOfferingSelect.bind(this)}
          labelledBy="OrgEntityName"
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={this.state.multiselect.off}
        />
      </React.Fragment>
    );
    const subOfferingMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={this.state.SourceSubOfferingOptions}
          value={this.state.selectedSubOfferingOption}
          onChange={this.props.dataoperation ? "" : this.onSourceProcessSelect.bind(this)}
          labelledBy="OrgEntityName"
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={this.state.multiselect.sub}
        />
      </React.Fragment>
    );
    const locationMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={this.state.SourceLocationOptions}
          value={this.state.selectedLocationsOption}
          onChange={this.props.dataoperation ? "" : this.onSourceLocationSelect.bind(this)}
          labelledBy="OrgEntityName"
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={this.state.multiselect.loc}
        />
      </React.Fragment>
    );
    return (
      <>
        {/* IF API IS LOADING THEN SHOW THE LOADER */}

        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          backdrop="static"
          scrollable={true}
          className="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">
              One Time Setup
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <p className="fs-medium">
                  Mapping source connector fields with its corresponding fields in SynOps for fetching metric data
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3 className="grid-heading border-0 mt-2 mb-3">SynOps Details</h3>
                      </div>
                      <div className="form-group col-lg-12">
                        <label for="">Destination System</label>
                        <input type="text" maxlength="200" className="form-control" id="" value="SynOps" disabled />
                      </div>
                      <div className="form-group col-lg-12">
                        <label for="">Client Name</label>
                        <input
                          type="text"
                          maxlength="200"
                          className="form-control"
                          id=""
                          value={this.state.OneTimeSetupModel.destinationClientName}
                          disabled
                        />
                      </div>
                      {!this.props.data ? (
                        <div className="form-group col-lg-12">
                          <label for="">
                            Offering<span className="required">*</span>
                          </label>
                          <select
                            id="Offering"
                            className="form-control"
                            ref="OfferingDropDown"
                            disabled={this.props.data || this.state.apivalue}
                            onChange={this.onDestinationOfferingsChange.bind(this)}
                            value={this.state.DestinationOfferingValueID}
                          >
                            <option value="0">Select Offering</option>
                            {this.state.OfferingOptions.map((row) => {
                              return <option value={row.OfferingID}>{row.OfferingName}</option>;
                            })}
                          </select>
                          {<div className="errorMsg">{this.state.errors.destinationOfferingId}</div>}
                        </div>
                      ) : (
                        <div className="form-group col-lg-12">
                          <label for="">
                            Offering<span className="required">*</span>
                          </label>
                          <input
                            id="Offering"
                            className="form-control"
                            ref="OfferingDropDown"
                            disabled={true}
                            value={this.state.OneTimeSetupModel.destinationOfferingName}
                          ></input>
                        </div>
                      )}
                      {!this.props.data ? (
                        <div className="form-group col-lg-12">
                          <label for="">
                            Sub Offering<span className="required">*</span>
                          </label>
                          <select
                            id="Process"
                            className="form-control"
                            ref="ProcessDropDown"
                            disabled={this.props.data || this.state.apivalue}
                            onChange={this.onDestinationProcessChange.bind(this)}
                            value={this.state.OneTimeSetupModel.destinationSubOfferingId}
                          >
                            <option value="0">Select Sub Offering</option>
                            {this.state.processOptions.map((row) => {
                              return <option value={row.OfferingID}>{row.Process}</option>;
                            })}
                          </select>
                          <span className="errorMsg">{this.state.errors.destinationSubOfferingId}</span>
                        </div>
                      ) : (
                        <div className="form-group col-lg-12">
                          <label for="">
                            Sub Offering<span className="required">*</span>
                          </label>
                          <input
                            id="Process"
                            className="form-control"
                            ref="ProcessDropDown"
                            disabled={true}
                            value={this.state.OneTimeSetupModel.destinationSubOfferingName}
                          ></input>
                        </div>
                      )}
                      {!this.props.data ? (
                        <div className="form-group col-lg-12">
                          <label for="">
                            Location <span className="required">*</span>
                          </label>
                          <select
                            id="Location"
                            className="form-control"
                            disabled={this.props.data || this.state.apivalue}
                            ref="LocationDropdown"
                            onChange={this.onDestinationLocationChange.bind(this)}
                            value={this.state.OneTimeSetupModel.destinationLocationId}
                          >
                            <option value="0">Select Location</option>
                            {this.state.LocationOptions.map((row) => {
                              if (row.IsActive) {
                                return <option value={row.LocationID}>{row.City}</option>;
                              }
                            })}
                          </select>
                          <span className="errorMsg">{this.state.errors.destinationLocationId}</span>
                        </div>
                      ) : (
                        <div className="form-group col-lg-12">
                          <label for="">
                            Location <span className="required">*</span>
                          </label>
                          <input
                            id="Location"
                            className="form-control"
                            ref="ProcessDropDown"
                            disabled={true}
                            value={this.state.OneTimeSetupModel.destinationLocationName}
                          ></input>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3 className="grid-heading border-0 mt-2 mb-3">Source Details</h3>
                      </div>
                      <div className="form-group col-lg-12">
                        {" "}
                        {/* copy Paste field: vignesh */}
                        <label for="" className="source-system-padding">
                          Source System<span className="required ">*</span>
                        </label>
                        <select
                          className="form-control"
                          disabled={this.props.data}
                          onChange={this.onSourceChange}
                          value={this.state.OneTimeSetupModel.sourceConnectorID}
                        >
                          <option value="0">Select Source</option>
                          {this.state.sourceOptions}
                          <option value="-1">Others</option>
                          <option value="-2">Manual</option>
                        </select>
                        <div className="errorMsg">{this.state.errors.sourceConnectorID}</div>
                      </div>
                      {this.state.filterDisplay.cl ? (
                        <div className="form-group col-lg-12">
                          <label for="">
                            {this.state.friendlyNames.cl}
                            <span className="required">*</span>
                          </label>
                          <div className="custom-multi-select">{clientMultipleselectbar}</div>

                          <span className="errorMsg">{this.state.errors.sourceClientName}</span>
                        </div>
                      ) : null}
                      {this.state.filterDisplay.cl && this.state.OneTimeSetupModel.sourceConnectorName ==="SVM"? (
                        <div className="form-group col-lg-12">
                          <label for="">
                            Program Name
                            <span className="required">*</span>
                          </label>
                          <div className="custom-multi-select">{programMulteSelectBar}</div>

                          <span className="errorMsg">{this.state.errors.sourceProgramName}</span>
                        </div>
                      ) : null}
                      {this.state.filterDisplay.off ? (
                        <div className="form-group col-lg-12">
                          <label for="">
                            {this.state.friendlyNames.off}
                            <span className="required">*</span>
                          </label>
                          <div className="custom-multi-select">{offeringMultipleselectbar}</div>

                          <span className="errorMsg">{this.state.errors.sourceOfferingName}</span>
                        </div>
                      ) : null}

                      {this.state.filterDisplay.sub ? (
                        <div className="form-group col-lg-12">
                          <label for="">
                            {this.state.friendlyNames.sub}
                            <span className="required">*</span>
                          </label>
                          <div className="custom-multi-select">
                            {subOfferingMultipleselectbar}
                            <span className="errorMsg">{this.state.errors.sourceSubOfferingName}</span>
                          </div>
                        </div>
                      ) : null}

                      {this.state.filterDisplay.loc ? (
                        <div className="form-group col-lg-12">
                          <label for="">
                            {this.state.friendlyNames.loc}
                            <span className="required">*</span>
                          </label>
                          <div className="custom-multi-select">{locationMultipleselectbar}</div>

                          <span className="errorMsg">{this.state.errors.sourceLocationName}</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                {!this.props.data ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Create"
                  />
                ) : !this.props.dataoperation ? (
                  this.props.Status === "Approved" ? (
                    <input
                      type="Button"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.handleSave("update")}
                      value="Update"
                    />
                  ) : (
                    <input
                      type="Button"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.handleSave()}
                      value="Submit"
                    />
                  )
                ) : (
                  ""
                )}
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
