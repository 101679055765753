import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { AgGridReact } from "ag-grid-react";
import IndustryApprovalPopup from "./IndustryApprovalPopup";
import axiosInstance from "../Shared/interceptor";
import _ from "lodash";
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class WOApprovalList extends Component {
  constructor() {
    super();

    this.state = {
      Approval: {
        IndustryID: [],
        Industry: "",
        OperatingGroup: "",
        RequestorRemarks: "",
        ApproverRemarks: "",
        Status: "",
        ApprovalAction: true,
        ApproveType: null,
      },
      CanApprove: false,
      CanReject: false,

      rowSelected: [],
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      prevLength: "",
      flag: false,
      columnDefs: [
        {
          headerName: "",
          resizable: true,
          suppressFilter: true,
          headerCheckboxSelection: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          width: 100,
          suppressMovable: true,
          suppressSizeToFit: true,
        },

        {
          headerName: " Industry",
          field: "Industry",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: " Status",
          field: "Status",
          resizable: true,
          suppressFilter: true,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
      ],

      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "single",

        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.onBtnExport = this.onBtnExport.bind(this);
  }

  onRowSelected(event) {
    let { rowSelected } = this.state;

    if (event.node.selected) {
      rowSelected = [...rowSelected];
      rowSelected.push(event.data);
      this.setState({ rowSelected: rowSelected });
    } else {
      rowSelected = [...rowSelected];
      rowSelected = rowSelected.filter((row) => row.IndustryId !== event.data.IndustryId);
      this.setState({ rowSelected: rowSelected });
    }
  }

  onCheckboxClicked(e, data) {
    let { rowSelected } = this.state;

    if (e.target.checked) {
      rowSelected.push(data);
    } else {
      this.setState({
        rowSelected: rowSelected.filter((row) => row.IndustryID !== data.IndustryID),
      });
    }
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  dealpopup(e) {
    const { rowSelected } = this.state;
    if (rowSelected.length > 0) {
      if (
        this.props.featureData.filter(
          (data) => String(data.Submenu) === "Industry Approval" && data.Operations.includes("Approve")
        ).length > 0
      ) {
        e.preventDefault();
        this.setState({
          viewmore: true,
          Approval: {
            ApproveType: "Approve",
          },
        });
      } else window.alert("Access Denied !!");
    } else {
      window.alert("Select Industry for Approval");
    }
  }

  decline(e) {
    const { rowSelected, Approval } = this.state;

    if (rowSelected.length > 0) {
      if (
        this.props.featureData.filter(
          (data) => String(data.Submenu) === "Industry Approval" && data.Operations.includes("Reject")
        ).length > 0
      ) {
        e.preventDefault();
        this.setState({ viewmore: true, ApproveType: "Reject" });
        Approval.ApproveType = "Reject";
      } else window.alert("Access Denied !!");
    } else {
      window.alert("Select Industry for Rejection");
    }
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  getApproval() {
    axiosInstance
      .get(`${LocalApiBaseUrl}IndustryApproval/GetIndustryForApproval`, {
        params: {},
      })
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    if (
      this.props.featureData.filter(
        (data) => String(data.Submenu) === "Industry Approval" && data.Operations.includes("Reject")
      ).length > 0
    ) {
      this.setState({ CanReject: true });
    }
    if (
      this.props.featureData.filter(
        (data) => String(data.Submenu) === "Industry Approval" && data.Operations.includes("Approve")
      ).length > 0
    ) {
      this.setState({ CanApprove: true });
    }

    this.getApproval();
    this.setState({
      rowSelected: [],
    });
  }

  componentWillMount() {
    this.setState({
      rowSelected: [],
    });

    this.getApproval();
  }

  handleSave(data) {
    if (!_.isNil(data)) {
      axiosInstance
        .post(`${LocalApiBaseUrl}IndustryApproval/PostIndustryAfterApproval`, data)
        .then((response) => {
          window.alert(response.data.StatusMessage);
          this.getApproval();
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });

      this.setState({
        rowSelected: [],
      });
    }
  }

  onActiveChange(event) {
    const { user } = this.state;
    user.IsActive = event.target.checked;
    this.setState({
      user: user,
    });
  }

  onBtnExport() {
    let params = {
      columnKeys: ["Industry", "OperatingGroup", "RequestorRemarks", "Status"],
      columnGroups: true,
      allColumns: false,
      skipHeader: false,
      fileName: "Industry",
    };
    this.state.gridOptions.api.exportDataAsCsv(params);
  }

  render() {
    const { gridOptions, columnDefs, rowData, viewmore, rowSelected, CanApprove, CanReject, Approval } = this.state;
    return (
      <div className="main-data">
        <div className="tableFilter">
          <div className="form-section">
            <div className="form-row">
              <div className="form-group col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  id="filter-text-box"
                  placeholder="Filter..."
                  onChange={(e) => {
                    this.onFilterTextBoxChanged(e);
                  }}
                />
              </div>
              <div className="ml-auto mr-1">
                <span className="btn btn-primary btn-sm py-1" onClick={this.onBtnExport}>
                  <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Download
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ag-theme-balham ag-table">
              <AgGridReact
                enableSorting={true}
                enableFilter={true}
                pagination={true}
                paginationPageSize={50}
                floatingFilter={true}
                onRowSelected={this.onRowSelected.bind(this)}
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                rowData={rowData}
                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
              ></AgGridReact>
            </div>
          </div>
        </div>
        {viewmore ? (
          <IndustryApprovalPopup
            data={rowSelected}
            show={viewmore}
            type={Approval.ApproveType}
            onClose={this.onClose}
            onSave={this.handleSave}
          />
        ) : null}

        <div className="text-center mt-3 mb-2">
          {rowSelected.length > 0 && CanApprove ? (
            <button
              className="btn btn-primary btn-sm mx-2"
              onClick={(e) => {
                this.dealpopup(e);
              }}
            >
              Approve
            </button>
          ) : (
            <button
              className="btn btn-primary btn-sm mx-2"
              disabled={true}
              onClick={(e) => {
                this.dealpopup(e);
              }}
            >
              Approve
            </button>
          )}
          {rowSelected.length > 0 && CanReject ? (
            <button
              className="btn btn-primary btn-sm mx-2"
              onClick={(e) => {
                this.decline(e);
              }}
            >
              Decline
            </button>
          ) : (
            <button
              className="btn btn-primary btn-sm mx-2"
              disabled={true}
              onClick={(e) => {
                this.decline(e);
              }}
            >
              Decline
            </button>
          )}
        </div>
      </div>
    );
  }
}
