import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import ClientRegionPopup from './ClientRegionPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl,idTokenstring } from '../Shared/Constant';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
  idTokenstring,
)}`;


export default class ClientRegionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewmore: false,
      rowData: [],
      rowSelected: [],
      columnDefs: [
        {
          headerName: 'Edit',
          suppressMovable: true,
          width: 60,
          cellRenderer: (params) => {
            const link = document.createElement('span');
            link.className = 'tbledit';
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener('click', (e) => {
              this.dealpopup(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
        },
        {
          headerName: 'Delete',
          suppressMovable: true,
          width: 80,
          cellRenderer: (params) => {
            const link = document.createElement('span');
            link.className = 'tbldel';
            link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
            link.addEventListener('click', (e) => {
              this.deleteClientRegion(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
        },
        {
          headerName: 'Client Region',
          width: 350,
          field: 'ClientRegionName',
          suppressMovable: true,
          resizable: false,
        },
        {
          headerName: 'Status', field: 'Status', suppressMovable: true, resizable: false,
        },
        {
          headerName: 'Is Active',
          field: 'IsActive',
          resizable: false,
          suppressMovable: true,
          suppressFilter: true,

          cellRenderer: (params) => (`<label class="form-check">
                    <input class="form-check-input" disabled type="checkbox" ${params.value ? 'checked' : ''
            }>
                    <span class="checkmark"></span>
                     </label>`),

        },
      ],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: 'single',
        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.deleteClientRegion = this.deleteClientRegion.bind(this);
    this.onBtnExport = this.onBtnExport.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { columnDefs } = this.state;
    const { featureData } = this.props;
    this.getClientRegion();
    let tempcolumnDefs = columnDefs;
    if (
      featureData.filter(
        (data) => String(data.Submenu) === 'Client Region' && data.Operations.includes('Edit'),
      ).length === 0
    ) {
      tempcolumnDefs = columnDefs.filter((data) => data.headerName !== 'Edit');
      this.setState({
        columnDefs: tempcolumnDefs,
      });
    }
  }

  handleSave(data) {
    const params = {
      ClientRegionName: data.ClientRegionName,
      IsActive: data.IsActive,
      RequestorRemarks: data.RequestorRemarks,
    };
    axiosInstance
      .post(`${LocalApiBaseUrl}ClientRegionApproval/AddEditClientRegion`, params)
      .then((response) => {
        window.alert(response.data.StatusMessage);
        this.getClientRegion();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    this.getClientRegion();
  }

  onBtnExport() {
    const { gridOptions } = this.state;
    const params = {
      columnKeys: ['ClientRegionName', 'Status', 'IsActive'],
      columnGroups: true,
      allColumns: false,
      skipHeader: false,
      fileName: 'ClientRegionList',
    };
    gridOptions.api.exportDataAsCsv(params);
  }

  onClose() {
    this.setState({ viewmore: false });
  }

  onFilterTextBoxChanged() {
    const { gridOptions } = this.state;
    gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  getClientRegion() {
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetAllClientRegion`)
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  dealpopup(e, data) {
    const { featureData } = this.props;
    if (data !== undefined) {
      // edit condition
      if (featureData.filter((temp) => String(temp.Submenu) === 'Client Region' && temp.Operations.includes('Edit')).length > 0) {
        this.setState({ viewmore: true, rowSelected: data });
      } else window.alert('Access Denied !!');
    }
    // add condition
    if (featureData.filter((a) => a.Submenu === 'Client Region' && a.Operations.includes('Add')).length > 0) {
      this.setState({ viewmore: true, rowSelected: data });
    } else window.alert('Access Denied !!');
  }

  deleteClientRegion(e, data) {
    const { featureData } = this.props;
    if (
      featureData.filter(
        (temp) => String(data.Submenu) === 'Client Region' && temp.Operations.includes('Delete'),
      ).length > 0
    ) {
      if (window.confirm('Are you sure you want to delete the item?')) {
        axiosInstance
          .delete(`${LocalApiBaseUrl}ClientRegionApproval/DeleteClientRegion`, {
            params: {
              ClientRegionId: data.ID,
            },
          })
          .then((response) => {
            window.alert(response.data.StatusMessage);
            this.getClientRegion();
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      }
    } else window.alert('Access Denied !!');
  }

  render() {
    const {
      featureData,
    } = this.props;
    const {
      gridOptions, columnDefs, rowData, rowSelected, viewmore,
    } = this.state;
    return (
      <div className="main-data">
        <div className="tableFilter">
          <div className="form-section">
            <div className="form-row">
              <div className="form-group col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  id="filter-text-box"
                  placeholder="Filter..."
                  onChange={(e) => this.onFilterTextBoxChanged(e)}
                />
              </div>
              {featureData.filter(
                (data) => String(data.Submenu) === 'Client Region' && data.Operations.includes('Add'),
              ).length > 0 && (
                  <div className="form-group col-lg-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={(e) => {
                        this.dealpopup(e);
                      }}
                    >
                      <span>
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </span>
                      {' '}
                      Add Client Region
                    </button>
                  </div>
                )}
              <div className="ml-auto mr-1">
                {featureData.filter(
                  (data) => String(data.Submenu) === 'Client Region' && data.Operations.includes('Download'),
                ).length > 0 ? (
                  <span
                    className="btn btn-primary btn-sm py-1"
                    aria-hidden="true"
                    onClick={this.onBtnExport}
                  >
                    <FontAwesomeIcon icon={faFileDownload} className="mr-1" />
                    Download
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ag-theme-balham ag-table">
              <AgGridReact
                enableSorting
                enableFilter
                pagination
                paginationPageSize={50}
                floatingFilter
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                rowData={rowData}
                onFirstDataRendered={this.onFirstDataRendered}
              />
            </div>
          </div>
        </div>
        {viewmore ? (
          <ClientRegionPopup
            rowData={rowData}
            data={rowSelected}
            show={viewmore}
            onClose={this.onClose}
            onSave={this.handleSave}
          />
        ) : null}
      </div>
    );
  }
}

ClientRegionList.propTypes = {
  featureData: PropTypes.array.isRequired,
};
