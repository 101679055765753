import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import i18n from "i18next";
import { Carousel } from "react-bootstrap";
import AddToolModal_Conf from "./AddToolModal_Conf";
import SelectNewTool from "./SelectNewTool";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

import { LocalApiBaseUrl } from "../Shared/Constant";
import emptycomponent from "../Images/Empty State.svg";
import { connect } from "react-redux";
import emptyFeatureListIcon from "../Images/EmptyFeatureList.svg";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class ToolListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toolDetails: {},
      woData: {
        SOCategoryID: 0,
        WOStepTools: [],
      },
      viewAllModalStatus: false,
    };
    this.handleURLClick = this.handleURLClick.bind(this);
  }

  handleURLClick(url) {
    url && window.open(url, "_blank");
  }

  deleteTool(data, e) {
    if (window.confirm("Are you sure you want to delete tool?")) {
      const woData = this.state.woData;
      woData.SOCategoryID = this.props.selectedSubStepID;
      woData.WOStepTools = data;
      let params = data.WOStepToolsID;
      this.setState({ woData: woData });
      this.props.screen === "SuperAdmin" &&
        axiosInstance
          .post(`${LocalApiBaseUrl}Master/DeleteWOStepToolMasterTemplate?WOStepToolsId=${params}`)
          .then((response) => {
            this.props.getOfferingAPICallback(true);
            store.dispatch({
              type: "REMOVE_WO_TOOL_MASTER",
              payload: this.state.woData,
            });
          });

      if (this.props.screen === "Configurator") {
        axiosInstance
          .delete(`${LocalApiBaseUrl}Configurator/DeleteWOToolsOnSteps?stepToolID=${params}`)
          .then((response) => {
            if (response.data) {
              axiosInstance
                .get(`${LocalApiBaseUrl}Configurator/GetWorkOrchestrationQualityScore`, {
                  params: {
                    LocationID: this.props.filter.Locations,
                    OrgEntityID: this.props.filter.Client,
                    OfferingID: this.props.filter.process,
                    MonthDT: this.props.filter.Month,
                    LanguageID: store.getState().CurrentFilter.languageData.id,
                  },
                })
                .then((response) => {
                  if (response.data) {
                    store.dispatch({
                      type: "SAVE_QC_SCORE_OF_EACH_TAB",
                      payload: response.data,
                    });
                  }
                })
                .catch((error) => {
                  trycatchAlertPopup(error);
                });        
               
              store.dispatch({
                type: "REMOVE_WO_TOOLS",
                payload: this.state.woData,
              });
            }
          })
          .catch((error) => {
              trycatchAlertPopup(error);
          });
      }
    }
  }

  viewAllModalShow = () => {
    this.setState({ viewAllModalStatus: true });
  };

  viewAllModalClose = () => {
    this.setState({ viewAllModalStatus: false });
  };

  getToolData(row) {
    const { 
      editToolModalToggler,
      screen,
      selectedSubStepID } = this.props;
    let accessValue = (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
    (this.props.RecordStatus &&
      this.props.RecordStatus[1] === "True" &&
      !this.props.isManintencneWindowOpen) ||
    (this.props.RecordStatus &&
      this.props.RecordStatus[0] === "Publish" &&
      !this.props.isManintencneWindowOpen) ||
    this.props.roleReducers.filter((each) => {
      if (each.FeatureName === "Configurator" && each.Operations.length) {
        return each;
      }
    }).length === 0 ||
    (this.props.RecordStatus &&
      this.props.RecordStatus[0] === "Save" &&
      this.props.roleReducers.filter((each) => {
        if (
          each.FeatureName === "Configurator" &&
          each.Operations.filter((op) => op === "Submit").length
        ) {
          return each;
        }
      }).length === 0) ||
    (this.props.RecordStatus &&
      this.props.RecordStatus[0] === "Submit" &&
      this.props.roleReducers.filter((each) => {
        if (
          each.FeatureName === "Configurator" &&
          each.Operations.filter((op) => op === "Publish").length
        ) {
          return each;
        }
      }).length === 0) ? true : false;
    let deleteRole =  screen === "SuperAdmin" ? this.props.roleReducers.filter(f => {
      return (f.FeatureName === "Master Template Configurator" && f.Operations.includes("Delete"))
    }).length>0:true
    let  editRole =screen === "SuperAdmin" ? this.props.roleReducers.filter(f => {
      return (f.FeatureName === "Master Template Configurator" && f.Operations.includes("Edit"))
    }).length >0:true
    
    let allToolData=
    <>
      <div className="item">
        <div className="tools-wo-wrapper">
          <div className="tools-header">
              <span className="tools-name">{row.ApplicationName}</span>
              <span className="tools-type">
                  <span>{row.ToolType}</span>
              </span>
          </div>
          <div className="tools-desc">{row.ApplicationDescription}</div>
          {screen !== "SuperAdmin" &&
            (row.DeploymentDate !== "Invalid date" && row.ExpectedDeploymentDate !== "Invalid date") && (
              <div className="wotool-date-wrapper">
                <div className="wotool-date">
                  <div className="date-label">
                    {"Deployment Date:"}
                  </div>
                  <div className="deployed-date">
                    {row.DeploymentDate
                        ? row.DeploymentDate !== "Invalid date" && 
                        new Date(row.DeploymentDate).toLocaleDateString()
                        : '-'
                    }
                  </div>
                </div>
                <div className="wotool-date">
                  <div className="date-label">
                    {"Expected Deployment Date:"}
                  </div>
                  <div className="deployed-date">
                    {row.ExpectedDeploymentDate
                      ? row.ExpectedDeploymentDate !== "Invalid date" && 
                      new Date(row.ExpectedDeploymentDate).toLocaleDateString()
                      : '-'
                    } 
                  </div>                  
                </div>
              </div>
            )
          }
        </div>
        {deleteRole || editRole ?( 
          <div>
          {this.props.screen !== "Dashboard" ? (
            this.props.screen === 'Configurator' ? (
              <div className="dropdown dropleft">
                <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                <div className="dropdown-menu">
                  {accessValue ? (
                    ""
                  ) : (selectedSubStepID !==0 &&
                  <>
                    {deleteRole && 
                      <a href="javascript:void(0)" className="dropdown-item" onClick={(e) => this.deleteTool(row, e)}>
                        <span className="delete-icon"></span>{i18n.t("Remove")}
                      </a>
                    }
                    {editRole && 
                      <a href="javascript:void(0)" className="dropdown-item" onClick={() => editToolModalToggler(row)}>
                        <span className="edit-icon"></span>{i18n.t("Edit")}
                      </a>
                    }
                  </>
                  )}  
                  <a href="javascript:void(0)" className="dropdown-item" style={row.URL ? null : { pointerEvents: "none", opacity: "0.6", color: "#919eab" }} onClick={() => this.handleURLClick(row.URL)}>
                    <span className="link-icon">{i18n.t("Link")}</span>
                  </a>
              </div>
              </div>)
              : ( accessValue ? ( 
                    ""
                  ) : (selectedSubStepID !==0 &&                    
                    <div className="dropdown dropleft">
                    <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                    <div className="dropdown-menu">
                      <>
                        {deleteRole && 
                          <a href="javascript:void(0)" className="dropdown-item" onClick={(e) => this.deleteTool(row, e)}>
                            <span className="delete-icon"></span>{i18n.t("Remove")}
                          </a>
                        }
                        {editRole && 
                          <a href="javascript:void(0)" className="dropdown-item" onClick={() => editToolModalToggler(row)}>
                            <span className="edit-icon"></span>{i18n.t("Edit")}
                          </a>
                        }
                        </>                        
                  </div>
                  </div>
                  )
             )): (
                    <div className="dropdown dropleft">
                      <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                      <div className="dropdown-menu">
                        <a href="javascript:void(0)" className="dropdown-item" style={row.URL ? null : { pointerEvents: "none", opacity: "0.6", color: "#919eab" }} onClick={() => this.handleURLClick(row.URL)}>
                        <span className="link-icon">{i18n.t("Link")}</span>
                        </a>
                      </div>
                    </div>
                  ) 
              }
        </div>):null}
      </div>
    </>
    return allToolData;
  }

  render() {
  
    

    let {
      addToolStatus,
      addToolConfStatus,
      toolData,
      addToolModalToggler,
      addNewToolToggler,
      editToolConfStatus,
      editToolModalToggler,
      screen,
      selectedSubStepID
    } = this.props;
    let allToolsData = ((toolData.length > 12) ? toolData.slice(0,12) : toolData);

    return (
      <>
        {toolData == null || toolData.length == 0 ? (
          <div className="tool-data">
            <div className="add-toolicon">
              {screen === "Dashboard" ||
              ((this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                (this.props.RecordStatus &&
                  this.props.RecordStatus[1] === "True" &&
                  !this.props.isManintencneWindowOpen) ||
                (this.props.RecordStatus &&
                  this.props.RecordStatus[0] === "Publish" &&
                  !this.props.isManintencneWindowOpen) ||
                this.props.roleReducers.filter((each) => {
                  if (each.FeatureName === "Configurator" && each.Operations.length) {
                    return each;
                  }
                }).length === 0 ||
                (this.props.RecordStatus &&
                  this.props.RecordStatus[0] === "Save" &&
                  this.props.roleReducers.filter((each) => {
                    if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) {
                      return each;
                    }
                  }).length === 0) ||
                (this.props.RecordStatus &&
                  this.props.RecordStatus[0] === "Submit" &&
                  this.props.roleReducers.filter((each) => {
                    if (
                      each.FeatureName === "Configurator" &&
                      each.Operations.filter((op) => op === "Publish").length
                    ) {
                      return each;
                    }
                  }).length === 0)) ? (
                    this.props.screen !== "Dashboard" ? (
                <Button className="show-pop" disabled={selectedSubStepID === 0 ? true :false }>
                   <img src={emptycomponent} alt="" width="130" height="130" /></Button>):null
                
              ) : (
                this.props.screen !== "Dashboard" ? (
                <Button className="show-pop" onClick={() => addToolModalToggler()} disabled={selectedSubStepID === 0 ? true :false }>
                   <img src={emptycomponent} alt="" width="130" height="130" /></Button>):null
                
              )}
              {this.props.screen === 'Dashboard' 
                  ? <h6 className="tool-metric-empty text-center">
                      <img src={emptyFeatureListIcon} 
                          className="mb-1"
                          width="40" 
                          height="40"
                          alt={i18n.t('No_tools_available')}
                      /><br />
                      {selectedSubStepID === 0 
                          ? i18n.t("There is no tool available for this Step")
                          : i18n.t("There is no tool available for this sub-step")}
                    </h6>
                  : (<h6>
                    {selectedSubStepID === 0 
                      ? i18n.t("Please select the sub step and click on '+' to add tools.")
                      : i18n.t("Please click on '+' to add step tool")}
                    </h6>)
                }
            </div>
          </div>
        ) : (          
          <div className={toolData && toolData.length >1 ? "metric-datalist" : "metric-datalist single-item-carousel"}>
            <div className="or-carousel-container">
              <Carousel>
                {allToolsData.map((row, index) => (
                  <Carousel.Item key={index}>
                    <div className="custom-tilecard">
                      <div className="tilecard-wrapper no-carousel">
                        {this.getToolData(row)}
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel> 
              {toolData && toolData.length > 12 && 
                <div className="oritems-seeall" onClick={() => this.viewAllModalShow()}>
                  {i18n.t('View All')}
                </div>
              }
              <Modal
                show={this.state.viewAllModalStatus}
                onHide={() => this.viewAllModalClose()}
                className="wo-viewAll-modal"
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{i18n.t('All Tools')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="modal-tilecard-wrapper">
                    <div className="custom-tilecard">
                      <div className="tilecard-wrapper no-carousel">
                        {toolData.map((row, index) => {
                          return (
                            <>
                              {this.getToolData(row)}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        )}
        {addToolConfStatus && (
          <AddToolModal_Conf
            filter={this.props.filter}
            show={addToolConfStatus}
            modalClose={addToolModalToggler}
            screen={this.props.screen}
            metricDesp={this.props.metricDesp}
            tooldatatype={"Add"}
            SOCategoryID={this.props.SOCategoryID}
            addNewToolToggler={addNewToolToggler}
            selectedSubStepID={selectedSubStepID}
          />
        )}
        {editToolConfStatus && (
          <AddToolModal_Conf
            filter={this.props.filter}
            show={editToolConfStatus}
            modalClose={editToolModalToggler}
            screen={this.props.screen}
            metricDesp={this.props.metricDesp}
            tooldatatype={"Edit"}
            SOCategoryID={this.props.SOCategoryID}
            tooldata={this.props.toolDetails}
            addNewToolToggler={addNewToolToggler}
            selectedSubStepID={selectedSubStepID}
          />
        )}
        {addToolStatus && (
          <SelectNewTool
            show={addToolStatus}
            modalClose={addToolModalToggler}
            addNewToolToggler={addNewToolToggler}
            filter={this.props.filter}
            SOCategoryID={this.props.SOCategoryID}
            LanguageID={this.props.LanguageID}
            getOfferingAPICallback={this.props.getOfferingAPICallback}
            selectedSubStepID={selectedSubStepID}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    roleReducers: state.roleReducers.roleReducers,
  };
};

export default connect(
  mapStateToProps,
  ""
)(ToolListContainer);
