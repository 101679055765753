import React from "react";

import MultiSelectDropDown from "./MultiSelectDropDown";
import _ from "lodash";
import SingleSelectDropDown from "./SingleSelectDropDown";


export default class UserRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }



  render() {
    const { sourceRoleOptions,
         addProfileRow, 
         rowIndex,
          user,
          deleteProfileRow,
          selectedprofile,
          clientOptions,
          selectedSubOfferingOptions,
          selectedClientOptions,
          offeringOptions,
          selectedOfferingOptions,
          subOfferingOptions,
          profileDetailprops,
        //FUNCTIONS
        handleProfileSelect,
        handleSelectedClientOptions,
        handleSelectedOfferingOptions,
        handleSelectedSubOfferingOptions,
        } = this.props;
    return (
      <>
        <div key={rowIndex} className="formsection-fields form-section">
          <div className="form-group border-group">
            <SingleSelectDropDown
              selected={selectedprofile}
              options={sourceRoleOptions}
              value={selectedprofile}
              onChangeFun={handleProfileSelect}
              labelledBy="User Profile"
              rowIndex={rowIndex}
            />
          </div>

          <div className="form-group border-group">
            {/* Format of {...obj,label:"",value:""} */}
            <MultiSelectDropDown
              labelledBy="Client Name"
              options={clientOptions}
              onChangeFun={handleSelectedClientOptions}
              value={selectedClientOptions}
              rowIndex={rowIndex}
            />
          </div>
          <div className="form-group border-group">
            <MultiSelectDropDown
              labelledBy="Offering"
              options={offeringOptions}
              onChangeFun={handleSelectedOfferingOptions}
              value={selectedOfferingOptions}
              rowIndex={rowIndex}
            />
          </div>
          <div className="form-group border-group">
            <MultiSelectDropDown
              labelledBy="Sub Offering"
              options={subOfferingOptions}
              onChangeFun={handleSelectedSubOfferingOptions}
              value={selectedSubOfferingOptions}
              rowIndex={rowIndex}
            />
          </div>
          <div className="form-fieldactions">

            {rowIndex !== 0 && (
              <span className="trash-icon" onClick={(e) => deleteProfileRow(rowIndex)}>
                <i className="fal fa-trash-alt mr-2"></i>
              </span>
            )}
            {(user.ProfileDetails.length - 1) === rowIndex && (
             !_.isEmpty(selectedprofile) && selectedOfferingOptions.length > 0 &&
             selectedSubOfferingOptions.length > 0 && selectedClientOptions.length > 0 ?
             <span id="add" onClick={(e) => addProfileRow(rowIndex,profileDetailprops)}>
               <i className="fal fa-plus-circle"></i>
             </span>
             :
             <span id="add">
               <i className="fal fa-plus-circle disabled"></i>
             </span>
            )}
          </div>
        </div>
      </>
    );
  }
}
