import React from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { LocalApiBaseUrl } from "../Shared/Constant";

import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { regexForConfMasterFields } from "../Shared/Constant";
import { regexForTextAreas } from "../Shared/Constant";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class IndustryPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      Industry: {
        IndustryID: "0",
        Industry: "",
        RequestorRemarks: "",
        ApproverRemarks: "",
        OperatingGroup: "",
        IsActive: false,
        CreatedUser: null,
        CreatedTS: null,
        UpdatedUser: null,
        UpdatedTS: null,
        DealInfoes: null,
        Status: "",
      },
      errors: [],
      formIsValid: false,
    };

    this.onIndustryChange = this.onIndustryChange.bind(this);
    this.onRemarkChange = this.onRemarkChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  onIndustryChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const Industry = this.state.Industry;
    Industry.Industry = event.target.value;
    this.setState({
      Industry: Industry,
    });
  }
  onRemarkChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    let Industry = this.state.Industry;
    Industry.RequestorRemarks = event.target.value;
    this.setState({
      Industry: Industry,
    });
  }

  getIndustryList() {
    let source = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}Industry/GetIndustryList`)
      .then((response) => {
        source = response.data;
        let sourceValues = source.map((x) => (
          <option key={x.IndustryID} value={x.IndustryID}>
            {x.Industry}
          </option>
        ));
        this.setState({ IndustryOptions: sourceValues });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  onStatusChange(event) {
    const Industry = this.state.Industry;
    Industry.IsActive = event.target.checked;
    this.setState({
      Industry: Industry,
    });
  }
  handleSave(e) {
    if (this.validateForm()) {
      this.props.onSave(this.state.Industry);

      this.handleHide();
    }
  }
  handleHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  componentDidMount() {
    // this.validateForm();
    this.getIndustryList();
    this.setState({
      show: this.props.show,
    });
  }

  validateForm() {
    const Industry = this.state.Industry;
    let errors = {};
    let formIsValid = true;

    if (!Industry["Industry"].trim()) {
      formIsValid = false;
      errors["Industry"] = "Industry is required";
    } else {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.

      var isValid = regexForConfMasterFields.test(Industry["Industry"]);
      if (!isValid) {
        formIsValid = false;
        errors["Industry"] = "Industry contains Special characters";
      }
    }

    if (Industry["RequestorRemarks"] == null) {
      formIsValid = false;
      errors["RequestorRemarks"] = "Requestor Remarks are required";
    } else if (!Industry["RequestorRemarks"].trim()) {
      formIsValid = false;
      errors["RequestorRemarks"] = "Requestor Remarks are required";
    } else {
      isValid = regexForTextAreas.test(Industry["RequestorRemarks"]);
      if (!isValid) {
        formIsValid = false;
        errors["RequestorRemarks"] = "Requestor Remarks contains Special characters";
      }
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  componentWillMount() {
    if (this.props.data) {
      this.setState({
        Industry: this.props.data,
      });
    }
  }

  render() {
    const { show, apivalue, Industry, errors } = this.state;

    return (
      <>
        {/* <Header /> */}
        <Modal
          show={show}
          onHide={this.handleHide.bind(this)}
          backdrop="static"
          classIndustry="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            {!this.props.data ? (
              <h4 className="modal-title" id="dealprofile">
                Add Industry
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile">
                Edit Industry
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  {this.props.data != null ? (
                    <div className="form-group col-lg-6">
                      <label for="">
                        Industry Name<span className="required">*</span>
                      </label>
                      <input
                        id="Industry"
                        className="form-control"
                        disabled={this.props.data || apivalue}
                        ref="IndustryDropDown"
                        value={this.state.Industry.Industry}
                      />
                      <span className="errorMsg">{errors.Industry}</span>
                    </div>
                  ) : (
                    <div className="form-group col-lg-6">
                      <label for="">
                        Industry Name<span className="required">*</span>
                      </label>

                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="name"
                        onChange={this.onIndustryChange.bind(this)}
                        value={Industry.Industry}
                      />
                      <span className="errorMsg">{errors.Industry}</span>
                    </div>
                  )}
                  {this.props.data ? (
                    <div className="form-group col-lg-6">
                      <label for="">
                        Requestor Remarks<span className="required">*</span>
                      </label>
                      <textarea
                        maxLength="200"
                        readOnly
                        className="form-control"
                        id="remarks"
                        onChange={this.onRemarkChange.bind(this)}
                        value={Industry.RequestorRemarks}
                      />
                      <span className="errorMsg">{errors.RequestorRemarks}</span>
                    </div>
                  ) : (
                    <div className="form-group col-lg-6">
                      <label for="">
                        Requestor Remarks<span className="required">*</span>
                      </label>
                      <textarea
                        maxLength="200"
                        className="form-control"
                        id="remarks"
                        onChange={this.onRemarkChange.bind(this)}
                        value={Industry.RequestorRemarks}
                      />
                      <span className="errorMsg">{errors.RequestorRemarks}</span>
                    </div>
                  )}
                  {this.props.data ? (
                    this.props.data.Status === "Approved" || this.props.data.Status === "Rejected" ? (
                      <div className="form-group col-lg-6">
                        <label for="">
                          Approver Remarks<span className="required"></span>
                        </label>
                        <textarea
                          maxLength="200"
                          className="form-control"
                          id="ClientContact1"
                          disabled={this.props.data || apivalue}
                          value={this.props.data.ApproverRemarks}
                        />
                      </div>
                    ) : null
                  ) : null}
                  {this.props.data ? (
                    this.props.data.Status === "Approved" ? (
                      <div className="form-group col-lg-6">
                        <label className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={this.onStatusChange}
                            defaultChecked={Industry.IsActive}
                          />
                          <span className="checkmark"></span>
                          <span className="form-check-label">Is Active</span>
                        </label>
                      </div>
                    ) : (
                      <div className="form-group col-lg-6 label-adjust">
                        <label className="form-check">
                          <input
                            className="form-check-input"
                            readOnly
                            type="checkbox"
                            onChange={this.onStatusChange}
                            defaultChecked={Industry.IsActive}
                          />
                          <span className="checkmark"></span>
                          <span className="form-check-label">Is Active</span>
                        </label>
                      </div>
                    )
                  ) : (
                    <div className="form-group col-lg-6">
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={this.onStatusChange}
                          defaultChecked={Industry.IsActive}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">Is Active</span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                {!this.props.data ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Create"
                  />
                ) : this.props.data.Status === "Approved" ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Update"
                  />
                ) : null}
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
