import React, { Component } from "react";
import {CommonToolTip}   from "../Shared/Common";
import { connect } from "react-redux";
import OwlCarousel from 'react-owl-carousel2';
import _ from "lodash";
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import {
    LocalApiBaseUrl,
    getPrevMonth
} from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
axiosInstance.defaults.headers.common[
    "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class DiverseInsights extends Component {
    constructor() {
        super();
        this.state = { metrics: [], widgetData: [] };
    }
    getSoCategoryDataAttribute = (
        offeringIdparam = {
            Client: 11,
            process: 2,
            Locations: 5,
            Month: getPrevMonth()
        }
    ) => {
        let filterProcess =
        _.isNil(offeringIdparam.process) ? 3 : offeringIdparam.process;
        let filterLocations =
        _.isNil(offeringIdparam.Locations) ? 5 : offeringIdparam.Locations;
        let filterClient =
        _.isNil(offeringIdparam.Client) ? 11 : offeringIdparam.Client;
        let dateArray =
        _.isNil(offeringIdparam.Month) === 0 ? [] : offeringIdparam.Month.split("-");
        let todayDate = "";
        if (dateArray.length === 2) {
           
            todayDate = "1-" + dateArray[0] + "-" + dateArray[1];
        } else {
            todayDate = offeringIdparam.Month;
        }
        axiosInstance
            .get(`${LocalApiBaseUrl}SOFilter/GetDiverseData`, {
                params: {
                    OrgEntityID: filterClient,
                    OfferingID: filterProcess,
                    LocationID: filterLocations,
                    MonthDT: todayDate,
                    LanguageID: this.props.languageData.id
                }
            })
            .then(response => {
                let result = response.data[0].Widget;
                result = result.sort(function (a, b) { return (a.WidgetDisplayOrder) - (b.WidgetDisplayOrder); });
                //adding isError property to the response object
                result = result.map((item, index) => {
                    item.AddNewWidget = item.AddNewWidget.sort(function (a, b) {
                        return (a.RecordSequenceNumber) - (b.RecordSequenceNumber);
                    });
                    for (let ii = 0; ii < item.WidgeFields.length; ii++) {
                        let dataArr = item.WidgeFields[ii].Data;
                        dataArr = dataArr.sort(function (a, b) {
                            return (a.RecordSequenceNumber) - (b.RecordSequenceNumber);
                        });
                    }
                    return item;
                });
                this.setState({ widgetData: result });
            })
            .catch((error)=>{
trycatchAlertPopup(error);

            });
    };
    componentWillMount() {
        this.getSoCategoryDataAttribute(this.props.filterDetails);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.filterDetails !== nextProps.filterDetails) {
            this.getSoCategoryDataAttribute(nextProps.filterDetails);
        }
    }
    MetricValueUnitFormat =(Unitvalue,value,AttributeValueDigit)=>{
        Unitvalue=Unitvalue.trim();
        let returnValue="";
        if(Number.isInteger(Number(value)) && AttributeValueDigit!==0){
          if((Unitvalue === "$" || Unitvalue === "₹" || Unitvalue === "R$" || Unitvalue === "€" ||Unitvalue === "£" || Unitvalue === "SGD")){
            returnValue=  Unitvalue + (AttributeValueDigit!==0 ? AttributeValueDigit:"")
    
          }else if(Unitvalue === "$ Mn" || Unitvalue === "₹ Mn"||Unitvalue === "R$ Mn" || Unitvalue === "€$ Mn"||Unitvalue === "SGD Mn")
          {
            returnValue=Unitvalue.split(" ")[0] + (AttributeValueDigit!==0 ? AttributeValueDigit:"") + "Mn"
          }else{
            returnValue=AttributeValueDigit + Unitvalue
          }
    
        }else {
          returnValue= (Unitvalue === "$" ? Unitvalue + (AttributeValueDigit!==0 ? AttributeValueDigit:""): (AttributeValueDigit!==0 ? AttributeValueDigit:""))
    
        }
        return returnValue;
  
    }
    render() {
        const options = {
            loop: true,
            margin: 16,
            responsiveClass: "",
            dots: false,
            autoWidth: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                600: {
                    items: 2,
                    nav: false
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: false
                },
                1200: {
                    items: 3,
                    nav: true,
                    loop: false
                },
                1400: {
                    items: 3,
                    nav: true,
                    loop: false
                },
                1600: {
                    items: 4,
                    nav: true,
                    loop: false
                }
            }
        };
        return (
            <>
                {this.state.widgetData && this.state.widgetData.map((wData, index) => {
                    return <>
                      <div key={"widget" + index}>
                <div className="custom-tilecard dd-insights-card ddinsights-dashboard">
                  <div className="tilecard-wrapper">
                      <h2><span>{wData.TileHeader}</span></h2>
                      <div className="custom-carousel-padding">
                            <OwlCarousel options={options} className={`owl-carousel`}>
                                {!wData.IsDonutDisplay ?
                                    wData.WidgeFields.map((wObj, i) => {
                                        let AttributeValueDigit="";
                                         AttributeValueDigit = wObj.Data[1].AttributeValue
                                        return wObj.Data && wObj.Data.length === 2  ?
                                        <div className="item gray-color twoitem-widget" data-place="bottom" data-tip='Lower Threshold: NA, Upper Threshold: NA'>
                                          <div className="item-top">
                                        {wObj.Data[0].AttributeValue.length > 18 ?
                   <CommonToolTip
                     id={'attribute_1'}
                     text={wObj.Data[0].AttributeValue}
                     length={18}
                     element={"p"}
                     place={"bottom"}
                   /> :
                 <p>{wObj.Data[0].AttributeValue}</p>
               }
                                            </div>
                                            <div className="item-bottom">
                                            <p>{AttributeValueDigit!=null ? AttributeValueDigit:""}</p>
                                        </div>
                                       </div>
                                   
                                            : wObj.Data && wObj.Data.length === 3 ?
                                            <div className="item gray-color threeitem-widget" data-place="bottom" data-tip='Lower Threshold: NA, Upper Threshold: NA'>
                                             <div className="item-top">
             
             <p className="custom-widget-tooltip">                  
                   {wObj.Data[1].AttributeValue.length > 7 ?
                    <CommonToolTip
                      id={'attribute_2'}
                      text={wObj.Data[1].AttributeValue}
                      length={7}
                      element={"b"}
                      place={"bottom"}                      
                    />: 
                    <b>{wObj.Data[1].AttributeValue}</b>}
                  
                  {wObj.Data[2].AttributeValue.length > 28 ?
                    <CommonToolTip
                      id={'attribute_2'}
                      text={wObj.Data[2].AttributeValue}
                      length={28}
                      element={"span"}
                      place={"bottom"}
                    />: 
                   <span> 
                   {" "+wObj.Data[2].AttributeValue}
                   </span>}
 
                 </p>
               </div>
               <div className="item-bottom">
                 {wObj.Data[0].AttributeValue.length > 40 ?
                    <CommonToolTip
                      id={'attribute_2'}
                      text={wObj.Data[0].AttributeValue}
                      length={40}
                      element={"p"}
                      place={"bottom"}
                    />:
                 <p>{wObj.Data[0].AttributeValue}</p>
                 }
             </div>
                                          </div> 
                                                : 
                                                   
                                              <div className="item gray-color fourlayout-widget" data-place="bottom" data-tip='Lower Threshold: NA, Upper Threshold: NA'>
                                                                           <div className="item-left">
          <p className="mtcinsight-value custom-widget-tooltip"> 
          {wObj.Data[2].AttributeValue.length > 7 ?
                   <CommonToolTip
                     id={'attribute_3'}
                     text={wObj.Data[2].AttributeValue}
                     length={7}
                     element={"b"}
                     place={"bottom"}
                   />:
              <b>{wObj.Data[2].AttributeValue}</b>}
              {wObj.Data[3].AttributeValue.length > 15?
                   <CommonToolTip
                     id={'attribute_3'}
                     text={" "+wObj.Data[3].AttributeValue}
                     length={15}
                     element={"span"}
                     place={"bottom"}
                   />:
              <span> {" "+wObj.Data[3].AttributeValue} </span>}
          </p>
              {wObj.Data[0].AttributeValue.length > 45 ?
                   <CommonToolTip
                     id={'attribute_3'}
                     text={wObj.Data[0].AttributeValue}
                     length={45}
                     element={"p"}
                     place={"bottom"}
                     class={"mtcinsights-desc"}
                   />:
              <p className="mtcinsights-desc" >{wObj.Data[0].AttributeValue}</p>}
              </div>
              <div className="item-right">
              {wObj.Data[1].AttributeValue.length > 40 ?
                   <CommonToolTip
                     id={'attribute_3'}
                     text={wObj.Data[1].AttributeValue}
                     length={40}
                     element={"p"}
                     place={"bottom"}
                     class={"mtcinsights-name"}
                   />:
              <p className="mtcinsights-name">{wObj.Data[1].AttributeValue}</p>}
          </div>
                                             
                                            </div> 
                                                
                                    })
                                    :
                                    wData.WidgeFields.map((wObj, i) => {
                                        let AttributeValueDigit="";
                                         AttributeValueDigit = wObj.Data[1].AttributeValue;
                                         AttributeValueDigit=   
                                        (Math.round(wObj.Data[1].AttributeValue)>100)
                                        ?100:AttributeValueDigit;
                                        
                                     return <div className="item gray-color" data-place="bottom" data-tip='Lower Threshold: NA, Upper Threshold: NA'>
                                           
                                        <div className="item doughnutwidget-insight">
                                        <div className="item-left">
                                        <div className="rd-data">
                                        <div className={`rd-progress ${`${
                                                Number.isInteger(Number(AttributeValueDigit))
                                                  ? "p" + AttributeValueDigit
                                                  : "p" + Math.round(AttributeValueDigit)
                                              }`}`}
                                            >
                    <span>
                    {wObj.Data[1].AttributeValue.length > 3 ?
                   <CommonToolTip
                     id={'attribute_0'}
                     text={wObj.Data[1].AttributeValue}
                     length={3}
                     element={"b"}
                     place={"bottom"}                     
                   />: 
                      <b>  {wObj.Data[1].AttributeValue}</b>}

                  {wObj.Data[2].AttributeValue.length > 2 ?
                   <CommonToolTip
                     id={'attribute_0'}
                     text={wObj.Data[2].AttributeValue}
                     length={2}
                     element={"span"}
                     place={"bottom"}
                     class={"metric-digit-text"}
                   />: 
                        <span className="metric-digit-text">{wObj.Data[2].AttributeValue}</span>}
                    </span>
                    <div className="rd-slice"><div className="rd-bar"></div>
                        <div class="rd-fill"></div>
                    </div>
                </div>
                </div>
              </div>
              <div className="item-right">
              {wObj.Data[0].AttributeValue.length > 46 ?
                   <CommonToolTip
                     id={'attribute_0'}
                     text={wObj.Data[0].AttributeValue}
                     length={46}
                     element={"span"}
                     place={"bottom"}
                     class={"doughnut-name"}
                   />: 
                <span className="doughnut-name">{wObj.Data[0].AttributeValue}</span>}
               </div> 
                                        </div>
                                        </div>
                                       
                                    })
                                }
                            </OwlCarousel>
                            </div>
                        </div>
                        </div>
                        </div>
                       
                    </>
                })
                }
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        languageData: state.CurrentFilter.languageData,
        clientData: state.CurrentFilter.ClientData,
    })
}
export default connect(mapStateToProps)(DiverseInsights);
