import "@babel/plugin-proposal-object-rest-spread";
import cloneDeep from "lodash/cloneDeep";

export let intelligentInsightMasterReducers = (
  state = {
    insightandintelligence: []
  },
  action
) => {
  switch (action.type) {
    case "INITIALISEINSIGHT_MASTER": {
      
      state = { ...state };
      state["insightandintelligence"] = action.payload;
      state["insightandintelligence"].sort((a, b) => (a.SOCategoryName_En > b.SOCategoryName_En) ? 1 : -1)
      state["insightandintelligence"].map(eachDetails => {
        eachDetails.kpiData.sort((a, b) => (a.KPIMappingID > b.KPIMappingID) ? 1 : -1)
    })
      return state;
    }

    case "ADD_APPADVISORS_MASTER": {
      state = { ...state };
      state["insightandintelligence"].filter(
        x => x.SOCategoryName.toLowerCase() == action.payload.SOCategoryName.toLowerCase()
      ).length > 0
        ? (state["insightandintelligence"].filter(
            data => data.SOCategoryName.toLowerCase() == action.payload.SOCategoryName.toLowerCase()
          )[0] = action.payload)
        : state["insightandintelligence"].push(action.payload);
      return state;
    }

    case "ADD_PROCESSINSIGHTS_MASTER" :{
        state = {...state}
        state["insightandintelligence"].filter((x)=>x.SOCategoryName.toLowerCase() == action.payload.SOCategoryName.toLowerCase()).length > 0 ?
        state["insightandintelligence"].filter((data)=> data.SOCategoryName.toLowerCase() == action.payload.SOCategoryName.toLowerCase())[0] = action.payload :
        state["insightandintelligence"].push(action.payload)
        return state
    }

    case "Remove_APPADVISORS_MASTER": {
      state = { ...state };
      var a = state["insightandintelligence"].filter(x => x.SOCategoryName !== action.payload);
      state["insightandintelligence"] = state["insightandintelligence"].filter(
        x => x.SOCategoryName !== action.payload
      );
      return state;
    }

    case "REMOVE_KPI_MASTER": {
      state = { ...state };
      var a = state["insightandintelligence"].filter(
        x => x.SOCategoryName == action.payload.SOCategoryName
      );
      state["insightandintelligence"].filter(
        x => x.SOCategoryName == action.payload.SOCategoryName
      )[0].kpiData = state["insightandintelligence"]
        .filter(x => x.SOCategoryName == action.payload.SOCategoryName)[0]
        .kpiData.filter(data => data.MetricName != action.payload.MetricName);

      return state;
    }

    case "ADD_EDIT_KPI_MASTER": {
      state = { ...state };

      if (
        state["insightandintelligence"]
          .filter(x => x.SOCategoryID == action.payload.SOCategoryID)[0]
          .kpiData.filter(
            data => data.KPIMappingID == action.payload.KPIMappingID
          ).length > 0
      ) {
          state["insightandintelligence"]
              .filter(x => x.SOCategoryID == action.payload.SOCategoryID)[0]
              .kpiData =
              state["insightandintelligence"]
                  .filter(x => x.SOCategoryID == action.payload.SOCategoryID)[0]
                  .kpiData.filter(
                      data => data.KPIMappingID != action.payload.KPIMappingID
                  );
      }

        state["insightandintelligence"]
          .filter(x => x.SOCategoryID == action.payload.SOCategoryID)[0]
              .kpiData.push(action.payload);

        state["insightandintelligence"]
          .filter(x => x.SOCategoryID == action.payload.SOCategoryID)[0]
          .kpiData.sort((a, b) => (a.MetricSeq > b.MetricSeq ? 1 : -1));
    
      return state;
    }

    case "ADD_EDIT_KPI1_MASTER": {
      state = { ...state };
      if (
        state["insightandintelligence"]
          .filter(x => x.SOCategoryID == action.payload.SOCategoryID)[0]
          .kpiData.filter(
            data => data.KPIMappingID == action.payload.KPIMappingID
          ).length == 0
      ) {
        state["insightandintelligence"]
          .filter(x => x.SOCategoryID == action.payload.SOCategoryID)[0]
          .kpiData.push(action.payload);
      }
      return state;
    }

    default:
      return state;
  }
};
