import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Tabs } from "react-web-tabs";
import LoadingOverlay from "react-loading-overlay";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import classNames from "classnames";

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import SynopsLoader from "../Shared/SynopsLoader";
import axiosInstance from "../Shared/interceptor";
import { LocalApiBaseUrl, BATOfferingIcons, trycatchAlertPopup } from "../Shared/Constant";

class ConsolidatedViewModal extends Component {
  constructor(props) {
    super(props);
    const selectedBATAssessClientID = _.get(props, "selectedBATAssessClientID", "");

    this.state = {
      isAPILoading: false,
      offeringsForConsolidatedAnalysis: [],
      allClientsWithNewOpportunity: [],
      isSelectedOptionConsolidatedAnalysis: _.isEmpty(String(selectedBATAssessClientID)),
      isSelectedOptionClientsWithNewOpportunity: !_.isEmpty(String(selectedBATAssessClientID)),
      selectedBATAssessClientID,
      selectedOfferingName: _.get(props, "selectedOfferingName", ""),
      selectedSubOfferingName: _.get(props, "selectedSubOfferingName", ""),
      clientCount: _.get(props, "clientCount", ""),
      hoveredOfferingName: "",
    };
  }

  componentDidMount() {
    this.setState({ isAPILoading: true });
    // FETCH THE DATA FROM THE API
    Promise.all([this.fetchOfferingSummary(), this.fetchAssessmentDetailsOfClients(), this.getSharedAssessmentData()])
      .then(() => {
        this.setState({ isAPILoading: false });
      })
      .catch(() => {
        this.setState({ isAPILoading: false });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedBATAssessClientID, selectedOfferingName } = this.state;

    // WHEN THE SELECTION CHANGE IN "CLIENT WITH NEW OPPORTUNITY" WOULD BE MADE THEN
    if (prevState.selectedBATAssessClientID !== selectedBATAssessClientID) {
      this.setState({
        selectedOfferingName: "",
        selectedSubOfferingName: "",
        hoveredOfferingName: "",
      });
    }

    // WHEN THE SELECTION CHANGE IN "OFFERING" WOULD BE MADE THEN
    if (prevState.selectedOfferingName !== selectedOfferingName) {
      this.setState({
        selectedSubOfferingName: "",
      });
    }
  }

  // FETCHING CONSOLIDATE ANALYSIS DATA
  fetchOfferingSummary = () => {
    return new Promise((resolve, reject) => {
      const { clientCount } = this.state;
      const requestData = {
        clientCount: _.toNumber(clientCount),
      };

      axiosInstance
        .post(`${LocalApiBaseUrl}BAT/GetOfferingSummary`, requestData)
        .then((response) => {
          const responseData = response.data;

          const modifiedOfferingSummaryData = _.map(responseData, (eachRecord) => {
            let logo = eachRecord.logo;
            let subOfferings = eachRecord.towerNames;

            if (!_.isEmpty(subOfferings)) {
              subOfferings = _.map(subOfferings, (eachItem, index) => {
                const subOfferingName = eachItem.tower;
                return {
                  id: eachItem.towerID,
                  name: subOfferingName,
                  key: subOfferingName,
                  isEnabled: eachItem.isEnabled,
                };
              });
            }

            return {
              ...eachRecord,
              logo,
              subOfferings,
            };
          });

          // FILTER OUT UNIQUE OFFERINGS
          const offerings = _.map(modifiedOfferingSummaryData, (eachRecord) => ({
            ...eachRecord,
            id: eachRecord.offeringId,
            name: eachRecord.offering,
          })).filter((value, index, self) => {
            return self.indexOf(value) === index;
          });

          this.setState(
            {
              offeringsForConsolidatedAnalysis: offerings,
            },
            () => {
              resolve(response.data);
            }
          );
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  };

  // FETCHING CLIENTS WITH NEW OPPORTUNITIES DATA

  getSharedAssessmentData = ()=> {
    return new Promise((resolve, reject) => {
    axiosInstance
      .get(`${LocalApiBaseUrl}BATAssessment/GetSharedBATAssessmentsForCollaboration`)
      .then((response) => {
        let responseData = response.data;
        if (responseData) {
          const { allClientsWithNewOpportunity } = this.state;
          responseData.forEach(e=> {
            allClientsWithNewOpportunity.push(e);
          });
          this.setState({
            allClientsWithNewOpportunity,
          },
          () => {
            resolve(responseData);
          });
        }
      })
      .catch((error) => {
        this.setState({ isAPILoading: false }, () => {
          trycatchAlertPopup(error);
        });
      });
    });
  }

  fetchAssessmentDetailsOfClients = () => {
    return new Promise((resolve, reject) => {
      // FETCH THE DATA FROM THE API
      axiosInstance
        .get(`${LocalApiBaseUrl}BATAssessment/GetClientAssessmentViewDetails`, {
          params: {},
        })
        .then((response) => {
          const responseData = response.data;
          const { allClientsWithNewOpportunity } = this.state;
          responseData.forEach(e=> {
            allClientsWithNewOpportunity.push(e);
          });

          this.setState(
            {
              allClientsWithNewOpportunity,
            },
            () => {
              resolve(responseData);
            }
          );
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  };

  // HANDLING IMAGE CHANGE ON HOVER
  handleHoverOffering = (offeringName) => {
    this.setState({
      hoveredOfferingName: offeringName,
    });
  };

  handleBlurOffering = () => {
    this.setState({
      hoveredOfferingName: "",
    });
  };

  handleModalHide = () => {
    const { callbackHeaderStateChange } = this.props;

    const dataToSendForCallback = {
      showConsolidatedModal: false,
    };
    callbackHeaderStateChange(dataToSendForCallback);
  };

  handleClickOptionConsolidatedAnalysis = () => {
    this.setState({
      isSelectedOptionClientsWithNewOpportunity: false,
      isSelectedOptionConsolidatedAnalysis: true,
      selectedBATAssessClientID: "",
      selectedOfferingName: "",
      selectedSubOfferingName: "",
    });
  };

  handleClickOptionClientsWithNewOpportunity = () => {
    this.setState({
      isSelectedOptionClientsWithNewOpportunity: true,
      isSelectedOptionConsolidatedAnalysis: false,
      selectedBATAssessClientID: "",
      selectedOfferingName: "",
      selectedSubOfferingName: "",
    });
  };

  handleClickClient = (selectedClientObj) => {
    const selectedBATAssessClientID = _.toNumber(selectedClientObj.BATAssessClientID);

    this.setState({
      selectedBATAssessClientID,
    });
  };

  handleClickOffering = (offeringName) => {
    this.setState({
      selectedOfferingName: offeringName,
    });
  };

  handleClickSubOffering = (selectedSubOfferingObj) => {
    const { callbackHeaderStateChange } = this.props;
    let {
      offeringsForConsolidatedAnalysis,
      clientCount,
      isSelectedOptionConsolidatedAnalysis,
      isSelectedOptionClientsWithNewOpportunity,
      allClientsWithNewOpportunity,
      selectedBATAssessClientID,
    } = this.state;

    let dataToSendForCallback = {
      offeringDetails: {
        id: selectedSubOfferingObj.offeringDetails.offeringId,
        name: selectedSubOfferingObj.offeringDetails.offeringName,
        type: selectedSubOfferingObj.offeringDetails.offeringType,
      },
      subOfferingDetails: {
        id: selectedSubOfferingObj.subOfferingId,
        name: selectedSubOfferingObj.subOfferingName,
        isSubOfferingTVEEnabled: selectedSubOfferingObj.isTVEEnabled,
      },
      clientCount,
    };

    if (isSelectedOptionConsolidatedAnalysis) {
      const functionalOfferings = offeringsForConsolidatedAnalysis.filter(function(eachOffering) {
        return eachOffering.offeringType === "Functional";
      });
      const industryOfferings = offeringsForConsolidatedAnalysis.filter(function(eachOffering) {
        return eachOffering.offeringType === "Industrial";
      });

      dataToSendForCallback = {
        ...dataToSendForCallback,
        offerings: offeringsForConsolidatedAnalysis,
        functionalOfferings,
        industryOfferings,
      };
    } else if (isSelectedOptionClientsWithNewOpportunity) {
      const selectedClient = _.find(allClientsWithNewOpportunity, {
        BATAssessClientID: _.toNumber(selectedBATAssessClientID),
      });

      dataToSendForCallback = {
        ...dataToSendForCallback,
        clientDetails: {
          id: selectedClient.BATAssessClientID,
          name: selectedClient.BATClientName,
          BATIndustryName: selectedClient.BATIndustryName,
          BATMarketUnitName: selectedClient.BATGeographyName,
          logo: selectedClient.ClientLogo ? selectedClient.ClientLogo : null,
        },
        BATAssessSubOfferingID: selectedSubOfferingObj.BATAssessSubOfferingID,
        startDate: selectedSubOfferingObj.StartDate,
        endDate: selectedSubOfferingObj.EndDate,
        CollectionStatus: selectedSubOfferingObj.CollectionStatus,
      };
    }

    callbackHeaderStateChange(dataToSendForCallback);
  };

  render() {
    const { showConsolidatedModal } = this.props;
    const {
      isAPILoading,
      isSelectedOptionClientsWithNewOpportunity,
      isSelectedOptionConsolidatedAnalysis,
      selectedOfferingName,
      selectedSubOfferingName,
      offeringsForConsolidatedAnalysis,
      hoveredOfferingName,
      allClientsWithNewOpportunity,
      selectedBATAssessClientID,
    } = this.state;

    let offeringsForDisplay = [];
    let subOfferingsForDisplay = [];
    let functionalOfferingsForDisplay = [];
    let industryOfferingsForDisplay = [];

    // WHEN "CLIENT WITH NEW OPPORTUNITY" IS SELECTED
    if (
      isSelectedOptionClientsWithNewOpportunity &&
      !_.isEmpty(String(selectedBATAssessClientID)) &&
      !_.isEmpty(allClientsWithNewOpportunity) &&
      !_.isEmpty(offeringsForConsolidatedAnalysis)
    ) {
      const filteredDataObjForSelectedClient = _.find(allClientsWithNewOpportunity, {
        BATAssessClientID: selectedBATAssessClientID,
      });

      if(filteredDataObjForSelectedClient!== undefined){
        offeringsForDisplay = _.map(filteredDataObjForSelectedClient.OfferingDetails, (eachOffering) => {
          const offeringName = eachOffering.BATOfferingName;
          const foundOfferingObj = _.find(offeringsForConsolidatedAnalysis, { offering: offeringName });
          const sequence = foundOfferingObj.metricSequence;
          const logoImageKey = foundOfferingObj.logo;
  
          const offeringObj = {
            ...eachOffering,
            offeringId: eachOffering.BATOfferingID,
            offeringName,
            sequence,
            logoImageKey,
            offeringType: eachOffering.BATOfferingType,
            isEnabled: foundOfferingObj.isEnabled,
          };
  
          const subOfferings = _.map(eachOffering.SubOfferingDetails, (eachItem) => ({
            ...eachItem,
            subOfferingId: eachItem.BATSubOfferingID,
            subOfferingName: eachItem.BATSubOfferingName,
            isTVEEnabled: eachItem.IsTVEEnabled,
            offeringDetails: offeringObj,
          }));
  
          return {
            ...offeringObj,
            subOfferings,
          };
        });
      }      
    } else if (isSelectedOptionConsolidatedAnalysis && !_.isEmpty(offeringsForConsolidatedAnalysis)) {
      // WHEN "Consolidated Analysis" OPTION IS SELECTED
      offeringsForDisplay = _.map(offeringsForConsolidatedAnalysis, (eachOffering) => {
        const offeringObj = {
          ...eachOffering,
          offeringName: eachOffering.offering,
          sequence: eachOffering.metricSequence,
          logoImageKey: eachOffering.logo,
        };

        const subOfferings = _.map(eachOffering.towerNames, (eachItem) => ({
          ...eachItem,
          subOfferingId: eachItem.towerID,
          subOfferingName: eachItem.tower,
          isTVEEnabled: eachItem.hasTVEEnabledMetrics,
          offeringDetails: offeringObj,
        }));

        return {
          ...offeringObj,
          subOfferings,
        };
      });
    }

    // SORT THE OFFERINGS
    offeringsForDisplay = _.sortBy(offeringsForDisplay, "sequence");

    // FILTER OUT THE SUB-OFFERINGS TO BE DISPLAYED FOR THE SELECTED OFFERING
    if (!_.isEmpty(offeringsForDisplay) && !_.isEmpty(selectedOfferingName)) {
      const selectedOfferingObj = _.find(offeringsForDisplay, { offeringName: selectedOfferingName });
      subOfferingsForDisplay = selectedOfferingObj.subOfferings;
    }

    // FILTER THE FUNCTIONAL AND INDUSTRY OFFERINGS
    functionalOfferingsForDisplay = _.filter(offeringsForDisplay, { offeringType: "Functional" });
    industryOfferingsForDisplay = _.filter(
      offeringsForDisplay,
      (eachOffering) => eachOffering.offeringType === "Industrial" || eachOffering.offeringType === ""
    );

    // RENDER THE LAYOUT FOR OFFERING LIST
    const renderLayoutForOfferingList = (offeringsArray) => {
      const layout = _.map(offeringsArray, (eachOffering) => {
        const offeringName = eachOffering.offeringName;
        const offeringLogoObj = BATOfferingIcons.filter(
          (eachBATOfferingIcon) => eachBATOfferingIcon.key.toLowerCase() === eachOffering.logoImageKey.toLowerCase()
        )[0];
        const isSelected = selectedOfferingName === offeringName;
        const isDisabled = !eachOffering.isEnabled;

        return (
          <li
            className={classNames({ selected: isSelected }, { disabled: isDisabled })}
            onClick={() => {
              !isSelected && !isDisabled && this.handleClickOffering(offeringName);
            }}
            onMouseOver={() => {
              !isDisabled && this.handleHoverOffering(offeringName);
            }}
            onMouseOut={() => {
              !isDisabled && this.handleBlurOffering();
            }}
            key={offeringName}
          >
            <span className="logo">
              {offeringLogoObj ? (
                <img
                  src={hoveredOfferingName === offeringName ? offeringLogoObj.iconWhite : offeringLogoObj.iconBlack}
                  alt={offeringName}
                />
              ) : (
                <img alt={offeringName} />
              )}
            </span>
            <span className="label-name">{offeringName}</span>
          </li>
        );
      });

      return layout;
    };

    return (
      <Modal
        show={showConsolidatedModal}
        centered
        onHide={() => this.handleModalHide()}
        backdrop="static"
        dialogClassName="modal-90w"
        className="clientVsConsolidatedModal offeringCategoryModal"
      >
        <Modal.Header closeButton className="justify-content-center">
          <span className="header-modal">Select a Client…</span>
        </Modal.Header>

        {/* IF API IS LOADING THEN SHOW THE LOADER */}
        {isAPILoading && <LoadingOverlay className="custom-loader" spinner={<SynopsLoader />} active />}

        <Modal.Body>
          <div>
            <Tabs>
              <div className="row">
                <div className="col-lg-3 offeringName clientName">
                  <ul>
                    <li
                      className={isSelectedOptionConsolidatedAnalysis ? "selected" : ""}
                      onClick={this.handleClickOptionConsolidatedAnalysis}
                    >
                      <span>Consolidated Analysis</span>
                    </li>

                    <li
                      className={isSelectedOptionClientsWithNewOpportunity ? "selected" : ""}
                      onClick={this.handleClickOptionClientsWithNewOpportunity}
                    >
                      <span>Clients with new Opportunities</span>
                    </li>
                  </ul>
                </div>

                {/* START - DISPLAY CLIENTS WITH NEW OPPORTUNITY */}
                {isSelectedOptionClientsWithNewOpportunity && (
                  <div className="col-lg-3 offeringName clientName">
                    <ul>
                      {_.map(allClientsWithNewOpportunity, (eachClient, index) => {
                        const BATAssessClientID = eachClient.BATAssessClientID;
                        const clientName = eachClient.BATClientName;
                        const logoEncodedString = eachClient.ClientLogo;
                        const isSelected = String(selectedBATAssessClientID) === String(BATAssessClientID);

                        return (
                          <li
                            className={classNames({ selected: isSelected })}
                            onClick={() => {
                              !isSelected && this.handleClickClient(eachClient);
                            }}
                            key={BATAssessClientID}
                          >
                            <span>
                              <span
                                className={!_.isEmpty(logoEncodedString) ? "" : "client-no-icon"}
                                style={{ height: "24px", width: "24px", marginRight: "2px" }}
                              >
                                {!_.isEmpty(logoEncodedString) && (
                                  <img
                                    src={"data:image/png;base64," + logoEncodedString}
                                    height="32px"
                                    width="32px"
                                    alt="Logo"
                                  />
                                )}
                              </span>
                            </span>
                            <span className="label-name">{clientName}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {/* END - DISPLAY CLIENTS WITH NEW OPPORTUNITY */}

                {/* START - DISPLAY OFFERINGS */}
                {(!_.isEmpty(functionalOfferingsForDisplay) || !_.isEmpty(industryOfferingsForDisplay)) && (
                  <div className="col-lg-3 offeringName">
                    {!_.isEmpty(functionalOfferingsForDisplay) && (
                      <>
                        <div className="category-name">Functional</div>

                        <div>
                          <ul className="subOffering-list">
                            {renderLayoutForOfferingList(functionalOfferingsForDisplay)}
                          </ul>
                        </div>
                      </>
                    )}

                    {!_.isEmpty(industryOfferingsForDisplay) && (
                      <>
                        <div className="category-name">Industry</div>

                        <div>
                          <ul className="subOffering-list">
                            {renderLayoutForOfferingList(industryOfferingsForDisplay)}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {/* START - DISPLAY SUB-OFFERINGS */}
                {!_.isEmpty(subOfferingsForDisplay) && (
                  <div className="col-lg-3 offeringName subOfferingCatgy">
                    <ul>
                      {_.map(subOfferingsForDisplay, (eachSubOffering) => {
                        const subOfferingName = eachSubOffering.subOfferingName;
                        const isSelected = selectedSubOfferingName === subOfferingName;

                        return (
                          <li
                            className={classNames({ selected: isSelected })}
                            onClick={() => {
                              !isSelected && this.handleClickSubOffering(eachSubOffering);
                            }}
                            key={subOfferingName}
                          >
                            <span>{subOfferingName}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {/* END - DISPLAY SUB-OFFERINGS */}
              </div>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withRouter(ConsolidatedViewModal);
