import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import MultiSelect from "react-multi-select-component";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import i18n from "i18next";

import Select from "react-dropdown-select";

const overrideStrings = {
    "selectSomeItems": "Select",
    "allItemsAreSelected": "All items selected.",
    "selectAll": "Select All ",
    "search": "Search"
}

axiosInstance.defaults.headers.common[
    "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class Report2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterDisplay: { cl: false, off: false, sub: false, loc: false },
            multiselect: { cl: false, off: false, sub: false, loc: false },
            isApiCalled: { cl: false, off: false, sub: false, loc: false },
            errors: [],
            formIsValid: false,
            temp: [],
            SourceClientOptions: [], selectedClientOption: [],
            downloadUrl: null,
            viewmore: false,
            StartDate: null,
            EndDate: null,
            changeStartDate: null,
            changeEndDate: null,
            focusedInput: null,
            active: false,
            isDisable: false,
            publishRange: [],
            OneTimeSetupModel: {
                isActive: true,
                destinationClientId: 0,
                destinationClientName: "",
                sourceClientId: 0,
                sourceClientName: [],
            },
            process: "",
            Client: "",
            MaturityPhase: this.props.MaturityPhase,
            processOptions: [],
            ClientOptions: [],
            publishedDataOptions: [
                {
                    value: "Latest Published Month",
                    label: "Latest Published Month"
                },
                {
                    value: "Last 3 Published Month",
                    label: "Last 3 Published Month"
                },
                {
                    value: "Last 6 Published Months",
                    label: "Last 6 Published Months"
                },
                {
                    value: "All Published Months",
                    label: "All Published Months"
                },
            ],

            Report: {
                ClientIds: [],
                StartDate: new Date(),
                EndDate: new Date(),
                publishRange: "",
                includeDemoDeal: false
            }
        };


        this.validateForm = this.validateForm.bind(this);
        this.getClients = this.getClients.bind(this);
        this.generateExcelReport = this.generateExcelReport.bind(this);
        this.MaturityPhase = "";
    }

    QueryString() {
        if (this.validateForm()) {
            var A = "Data/GetDealSpecificReportView?";
            var B = LocalApiBaseUrl;
            var startDate = Moment(this.state.Report.StartDate).format("YYYY-MM-DD");
            var endDate = Moment(this.state.Report.EndDate).format("YYYY-MM-DD");
            var clientId = this.state.Report.ClientIds.toString();
            var publishRange = this.state.Report.publishRange;
            let downloadUrl = this.state.downloadUrl;
            const includeDemoDeal = this.state.Report.includeDemoDeal;

            downloadUrl =
                B +
                A +
                "startdate=" +
                startDate +
                "&enddate=" +
                endDate +
                "&ClientId=" +
                clientId +
                "&includeDemoDeal=" +
                includeDemoDeal +
                "&publishRange=" +
                publishRange;
            this.setState({
                downloadUrl: downloadUrl
            });
        }
    }

    onStartDateChange = event => {
        const { Report } = this.state;
        Report.EndDate = new Date();
        let errors = {};
        this.setState({
            errors: errors,
            Report,
            EndDate: null
        });
        Report.StartDate = event;

        this.setState({ StartDate: event });
        this.setState({
            Report: Report,
            changeStartDate: event,
            changeEndDate: ""
        });
        this.QueryString();
    };
    onEndDateChange = event => {

        let errors = {};
        this.setState({
            errors: errors,
            EndDate: event
        });
        const Report = this.state.Report;
        Report.EndDate = event;
        this.setState({
            Report: Report,
            changeEndDate: event
        });

        this.QueryString();
    };



    generateExcelReport() {
        if (this.validateForm()) {
            var that = this;
            var startDate = Moment(this.state.Report.StartDate).format("YYYY-MM-DD");
            var endDate = Moment(this.state.Report.EndDate).format("YYYY-MM-DD");
            var clientId = this.state.Report.ClientIds.toString();
            var publishRange = this.state.Report.publishRange;
            const includeDemoDeal = this.state.Report.includeDemoDeal;

            var data = {
                startdate: startDate,//"2021-01-13T00:00:00.000Z", //startDate,
                enddate: endDate,//"2021-01-14T00:00:00.000Z",// endDate,
                clientId: clientId,//"50",// clientId,
                publishRange: publishRange,//"Last 3 Published Months",//publishRange,
                includeDemoDeal: includeDemoDeal// true//includeDemoDeal
            };
            let constructedURL = LocalApiBaseUrl + "Data/GetDealSpecificReportView";

            if ((startDate !== endDate && data.clientId !== 0) ||
                (data.publishRange != null && String(data.publishRange) !== "" && data.clientId !== 0)) {
                this.setState({
                    active: true
                });
                axiosInstance
                    .post(constructedURL, data, {
                        responseType: "blob"
                    })

                    .then(response => {
                        if (response.status !== 200) {
                            alert("An error has occurred!");
                        }

                        this.setState({ active: false });

                        let fileNameFromHeaders = response.headers.filename;
                        if (!window.navigator.msSaveOrOpenBlob) {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;

                            link.setAttribute("download", fileNameFromHeaders);

                            document.body.appendChild(link);
                            link.click();
                        }
                        else {
                            // BLOB FOR EXPLORER 11
                            // const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), fileNameFromHeaders);
                        }
                    })
                    .catch(function (error) {
                        that.setState({ active: false });
                        trycatchAlertPopup(error);
                    });
            }
        }
    }

    getClients() {
        axiosInstance
            .get(`${LocalApiBaseUrl}SO/GetOrgStructure`, {
                params: { featureName: "Deal Summary" },
            })
            .then((response) => {
                let SourceClientOptions = []
                let selectedClientOption = []
                response.data.forEach((row, i) => {
                    let dummy = [];
                    dummy.label = row.OrgEntityName;
                    dummy.value = row.OrgEntityID;
                    SourceClientOptions.push(dummy)

                    if (this.props.data && String(this.state.isPropsLoaded) === false) {
                        if (this.state.OneTimeSetupModel.sourceClientName.includes(row.name)) {
                            selectedClientOption.push(dummy)
                        }
                    }
                })
                this.setState({
                    SourceClientOptions: SourceClientOptions,
                    selectedClientOption: selectedClientOption
                });
                if (this.props.data && this.state.isPropsLoaded === false) {
                    this.filterSelectionControl();
                }

            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });

        // this.QueryString();
    }

    componentDidMount() {
        this.getClients();
    }
    OnOfferingChange(event) {
        let Report = this.state.Report;
        var offering = document.getElementById("Offering");
        if (offering) {
            let tempOffering = [];
            let countOffering = [];
            var len = offering.length;
            for (var i = 0; i < len; i++) {
                var opt = offering.options[i];

                if (opt.selected) {
                    tempOffering.push(opt);
                    countOffering.push(opt.value);
                }
            }

            Report.OfferingIds = countOffering;
            this.setState({
                countOffering: countOffering
            })

            // this.state.countOffering = countOffering;

            this.setState({
                Report: Report
            });
        }
        this.QueryString();
    }

    onClientChange(selectedList) {
        let OneTimeSetupModel = this.state.OneTimeSetupModel;
        let isApiCalled = this.state.isApiCalled;
        isApiCalled.loc = false; isApiCalled.sub = false; isApiCalled.off = false;


        this.setState({
            OneTimeSetupModel: OneTimeSetupModel,
            isPropsLoaded: true,
            publishRange: []
        })


        let selectedClientOption = this.state.selectedClientOption;
        let tempClient = [];
        let tempClientValue = [];
        var len = selectedList.length;
        let Report = this.state.Report;
        for (var i = 0; i < len; i++) {
            tempClient.push(selectedList[i]);
            tempClientValue.push(selectedList[i].value);
        }
        Report.ClientIds = tempClientValue;
        selectedClientOption = tempClient;
        OneTimeSetupModel.sourceClientName = tempClientValue;
        this.setState({
            selectedClientOption: selectedClientOption,
            OneTimeSetupModel: OneTimeSetupModel,
            Report: Report
        })

        this.filterSelectionControl();
    }

    filterSelectionControl() {
        let isApiCalled = this.state.isApiCalled;
        let isVisible = this.state.filterDisplay;

        if (isVisible.cl && !isApiCalled.cl) {
            this.getSourceClients();
            isApiCalled.cl = true;
            this.setState({
                isApiCalled: isApiCalled
            });
            this.QueryString();
        }
    }

    validateForm() {

        let errors = {};
        let Report = this.state.Report;
        let formIsValid = true;

        if (this.state.changeStartDate != null && String(this.state.changeStartDate) !== "" && this.state.changeEndDate != null && String(this.state.changeEndDate) !== "") {
            if (!Report["ClientIds"].length) {
                errors["ClientIds"] = i18n.t("Please select Client");
                formIsValid = false;
            }

        }
        else if (Report["publishRange"].length) {
            if (!Report["ClientIds"].length) {
                errors["ClientIds"] = i18n.t("Please select Client");
                formIsValid = false;
            }

        }
        else {
            if (this.state.changeStartDate == null || String(this.state.changeStartDate) === "") {
                errors["StartDate"] = i18n.t("Please select Start Date");
                formIsValid = false;
            }
            if (this.state.changeEndDate == null || String(this.state.changeEndDate) === "") {
                errors["EndDate"] = i18n.t("Please select End Date");
                formIsValid = false;
            }

            if (!Report["PublishedRange"]) {
                errors["PublishedRange"] = i18n.t("Please select Published Range");
                formIsValid = false;
            }
            if (!Report["ClientIds"].length) {
                errors["ClientIds"] = i18n.t("Please select Client");
                formIsValid = false;
            }
        }
        this.state.errors = errors;

        if (formIsValid) {
            this.setState({
                formIsValid: true
            });
        } else {
            this.setState({
                formIsValid: false
            });
        }
        return formIsValid;
    }
    onPublishedDataChange(e) {
        let Report = this.state.Report;
        Report.StartDate = new Date();
        Report.EndDate = new Date();
        Report.publishRange = e.length > 0 ? e[0].value : "";
        this.setState({
            Report: Report,
            isDisable: true,
            errors: {
                Status: "",
                StartDate: "",
                EndDate: ""
            },
            changeStartDate: "",
            changeEndDate: "",
            publishRange: e
        })
    }
    onIsDemoCheck = (e) => {
        const report = this.state.Report;
        report.includeDemoDeal = e.target.checked;

        this.setState({
            Report: report,
        });
    }

    render() {
        const clientMultipleselectbar = (
            <React.Fragment>
                <MultiSelect
                    options={this.state.SourceClientOptions}
                    value={this.state.selectedClientOption}
                    onChange={this.onClientChange.bind(this)}
                    labelledBy="OrgEntityName"
                    shouldToggleOnHover={false}
                    overrideStrings={overrideStrings}
                    hasSelectAll={this.state.multiselect}
                />
            </React.Fragment>
        );
        const publishedDataFilter = (
            <React.Fragment>
                <Select
                    values={this.state.publishRange}
                    onChange={this.onPublishedDataChange.bind(this)}
                    options={this.state.publishedDataOptions}
                    placeholder={i18n.t("Select")}
                />
            </React.Fragment>
        );
        return (
            <div className="main-data">
                <div className="row">
                    <div className="form-group col-lg-4 block-picker">
                        <label className="datepicker-label" htmlFor="">Start Date<span className="required"></span></label>
                        <DatePicker
                            className="form-control"
                            selected={this.state.isDisable ? '' : this.state.StartDate}
                            autoComplete="off"
                            id="StartDate"
                            onChange={this.onStartDateChange.bind(this)}
                            value={this.state.Report.StartDate}
                            disabled={this.state.isDisable}
                        />
                        <span className="errorMsg">{this.state.errors.StartDate}</span>
                    </div>
                    <div className="form-group col-lg-4 block-picker">
                        <label className="datepicker-label" htmlFor="">End Date<span className="required"></span></label>
                        <DatePicker className="form-control"
                            selected={this.state.isDisable ? '' : this.state.EndDate}
                            minDate={this.state.Report.StartDate}
                            autoComplete="off"
                            id="EndDate"
                            onChange={this.onEndDateChange.bind(this)}
                            value={this.state.Report.EndDate}
                            disabled={this.state.isDisable}
                        />
                        <span className="errorMsg">{this.state.errors.EndDate}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-4">
                        <label htmlFor="Client">Client<span className="required"></span></label>
                        <div className="custom-multi-select">
                            {clientMultipleselectbar}
                        </div>
                        <span className="errorMsg">{this.state.errors.ClientIds}</span>
                    </div>
                    <div className="form-group col-lg-4">
                        <label htmlFor="Published Data">Published Data<span className="required"></span></label>
                        <div className="single-select-dropdown">
                            {publishedDataFilter}
                        </div>
                        <span className="errorMsg">{this.state.errors.PublishedRange}</span>
                    </div>
                    <div className="form-group col-lg-4">
                        <label className="form-check dealData-check-top">
                            <input className="form-check-input" type="checkbox"
                                onChange={(e) => this.onIsDemoCheck(e)}
                                defaultChecked={this.state.Report.includeDemoDeal} />
                            <span className="checkmark"></span>
                            <span className="form-check-label">Include Demo Deal</span>
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <button className="btn btn-primary btn-sm" id="btnsave" onClick={this.generateExcelReport} disabled={this.state.active}>Generate Report</button>
                        <LoadingOverlay
                            active={this.state.active}
                            spinner={<SynopsLoader />}
                        ></LoadingOverlay>
                    </div>
                </div>
            </div>
        );
    }
}
