import React, { Component } from "react";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import ProgressBar from "react-bootstrap/ProgressBar";
import i18n from "i18next";
import ReactSpeedometer from "react-d3-speedometer";
import { Radar, Bubble } from "react-chartjs-2";
import HamburgerMenu from "../../Images/HamburgerMenu.svg";
import Closeblue from "../../Images/Close-blue.svg";
import _ from "lodash";
import $ from "jquery";
const redBackground = "#fbb4b5";  
const redBorder = "#d5002f";
const amberBackground = "#ffe699";
const amberBorder = "#ffc82c";
const greenBackground = "#b1db8e";
const greenBorder = "#7ec344";

const style = {
  "font-size": "18px",
  "font-weight": "bold",
  "font-family": "graphik-medium",
  color: "#212b36",
  "letter-spacing": "-0.25px",
  padding: "3px",
};

const options = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  scale: {
    pointLabels: {
      fontColor: "#000",
      fontFamily: "Graphik-Bold",
      fontSize: 12,
      fontStyle: "bold",
    },
    reverse: false,
    ticks: {
      align: "left",
      beginAtZero: true,
      max: 4,
      min: 0,
      stepSize: 1,
      fontColor: "#000",
      fontSize: 11,
      fontStyle: "normal",
      fontFamily: "Graphik",
      z: 1,
      padding: 10,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
};

/* BUBBLE GRAPH */
let bubbleDatasets = {};
const bubbleCoordinate = { x: 0, y: 0, r: 13, label: "", ProjectDetails: "" };
let bubbleOptions = {
  responsive: true,
  title: {
    display: false,
    text: "Project Prioritization Grid",
  },
  legend: { 
    display: false,
  },
  tooltips: {
    mode: "index",
    intersect: true,
    enabled: false,
    position: "nearest",
    custom: function(tooltip) {
      const tooltipDataPoints = tooltip.dataPoints;
      // const pos= tooltip.dataPoints[0].label;
      if (!_.isNil(tooltipDataPoints)) {
        const currentDataPoint = _.first(tooltipDataPoints);
        if (!_.isNil(currentDataPoint)) {

          let timeValue = currentDataPoint.xLabel;
          let y = currentDataPoint.yLabel;
          var impact = "";
          if (y > 50) {
            impact = "H";
          }
          if (y > 25 && y <= 50) {
            impact = "M";
          }
          if (y <= 25 && y !== 0) {
            impact = "L";
          }
          if (y === 0) {
            impact = "0";
          }

          // Tooltip Element
          var tooltipEl = document.getElementById("chartjs-bubblePMMTooltip");
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-bubblePMMTooltip";
            tooltipEl.innerHTML = "<div class='bubblePMMTooltip-body'></div>";
            this._chart.canvas.parentNode.appendChild(tooltipEl);
          }
          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltip.yAlign) {
            tooltipEl.classList.add(tooltip.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          if (tooltip.body) {
            let tooltipContent = `
                <div class='bubbleTooltip-content'>
                    <p>${
                      bubbleDatasets.datasets[0].data[
                        tooltip.dataPoints[0].index
                      ].ProjectDetails
                    }</p>
                    <div class='bubbleTooltip-values'>
                        <div>${i18n.t("Time") +
                          "(" +
                          i18n.t("Months") +
                          ")"}:${timeValue}</div>

                        <div>${i18n.t("Impact")}(%):${
              bubbleDatasets.datasets[0].data[tooltip.dataPoints[0].index].y
            }</div>
                    </div>
                </div>
              `;

            var tableRoot = tooltipEl.querySelector(".bubblePMMTooltip-body");
            tableRoot.innerHTML = tooltipContent;
          }

          var positionY = this._chart.canvas.offsetTop;
          var positionX = this._chart.canvas.offsetLeft;

          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = positionX + tooltip.caretX + "px";
          tooltipEl.style.top = positionY + tooltip.caretY + "px";
          tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
          tooltipEl.style.fontSize = tooltip.bodyFontSize + "px";
          tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
          tooltipEl.style.padding =
            tooltip.yPadding + "px " + tooltip.xPadding + "px";
        } else {
          
          removeChartCustomTooltip();
        }
      } else {
        
        removeChartCustomTooltip();
      }
    },
  },

  scales: {
    xAxes: [
      {
        gridLines: {
          drawOnChartArea: false,
          drawTicks: false,
        },
        scaleLabel: {
          display: true,
          labelString: "Time (Months)", // UILiteral_Change
          fontFamily: "Graphik-Medium",
          fontSize: 14,
          fontStyle: "normal",
          fontColor: "#000",
          padding: {
            left: 0,
            top: 30,
            right: 0,
            bottom: 0,
          },
        },
        ticks: {
          beginAtZero: true,
          reverse: false,
          max: 12,
          min: 0,
          stepSize: 1,
          padding: 15,
          fontFamily: "Graphik",
          fontSize: 14,
          fontStyle: "normal",
          fontColor: "#000",
        },
      },
    ],
    yAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          labelString: "Impact on efficiencies", //UILiteral_Change
          fontFamily: "Graphik-Medium",
          fontSize: 14,
          fontStyle: "normal",
          fontColor: "#000",
          padding: {
            left: 0,
            top: 0,
            right: 0,
            bottom: 30,
          },
        },
        gridLines: {
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 50,
          stepSize: 1,
          suggestedMin: 0,
          suggestedMax: 50,
          padding: 10,
          fontFamily: "Graphik",
          fontSize: 14,
          fontStyle: "normal",
          fontColor: "#000",
          // callback: function(label, index, labels) {
          //   debugger;
          //   if (label == 0) {
          //     return 0;
          //   } else if (label == 25) {
          //     return "Low";
          //   } else if (label == 50) {
          //     return "Medium";
          //   } else if (label == 75) return "High";
        },
      },
    ],
  },

  plugins: {
    datalabels: {
      color: "white",
    },
  },
};

// REMOVE THE DYNAMICALLY CREATED CUSTOM TOOLTIP ELEMENT FROM THE DOM
function removeChartCustomTooltip() {
  const tooltipEl = document.getElementById("chartjs-bubblePMMTooltip");
  if (tooltipEl) {
    $(tooltipEl).remove();
  }
}

class ViewPMM extends Component {
  constructor() {
    super();
    this.bubblegridShow = this.bubblegridShow.bind(this);
    this.bubblegridClose = this.bubblegridClose.bind(this);

    this.state = {
      bubblegridStatus: false,
      graphData: [],
      Character: "@",
      RadarLeverData: {
        RadarPolicydata: {},
        RadarPracticedata: {},
        RadarPlatformdata: {},
      },
      gapReportTabData:[],
      PMMAssessmentLeverData: {
        PolicyPMMAssessmentData: [],
        PracticePMMAssessmentData: [],
        PlatformPMMAssessmentData: [],
      },
      GAPReportLeverData: {
        PolicyGAPReportData: [],
        PracticeGAPReportData: [],
        PlatformGAPReportData: [],
      },
      ProjectDetailsDatasets: [],
      ProjectDetailsOptions: [],
      ProjectDetailsGraphData: [],
      tabIdDetails: "pmmAssessment",
      subtabDetails: "PMMPolicy",
      gapreportDetails: "GapReportPolicy",
      overallScore: 0,
    };
  }
  bubblegridShow() {
    this.setState({ bubblegridStatus: true });
  }

  Radargraph() {
    const {graphData} = this.state;

    const transformedGapReportData = []
    graphData.PMMGraphData[1].MaturityElementGraphDatas.forEach(eachTab =>{
      let radarData = {
        labels: eachTab.MaturityElementDatas.map((e,index)=> String.fromCharCode(this.state.Character.charCodeAt(0) + (index + 1))),
        datasets:[
          {
            label: "As - is dataset",
            backgroundColor: "transparent",
            pointBackgroundColor: "rgba(90, 90, 90,1)",
            pointHighlightStroke: "rgba(90, 90, 90,1)",
            borderColor: "rgba(90, 90, 90,0.7)",
            data: eachTab.MaturityElementDatas.map(
              (item) => item.AsIsPMMScore
            ),
            borderWidth: 2,
          },
          {
            label: "To - be dataset",
            backgroundColor: "transparent",
            pointBackgroundColor: "rgba(0, 142, 255,1)",
            pointHighlightStroke: "rgba(0, 142, 255,1)",
            borderColor: "rgba(0, 142, 255,0.7)",
            data: eachTab.MaturityElementDatas.map(
              (item) => item.ToBePMMScore
            ),
            borderWidth: 2,
          },
        ]
      }
      transformedGapReportData.push(radarData);
    })
    
    this.setState({
      gapReportTabData: transformedGapReportData,
    });

  
  }

  componentDidMount() {
    let { PMMAssessmentLeverData, GAPReportLeverData } = this.state;

    let PolicyPMMAssessmentData = this.props.graphData.PMMGraphData[0]
      .MaturityElementGraphDatas
      ? this.props.graphData.PMMGraphData[0].MaturityElementGraphDatas.filter(
          (item) => item.LeverName_En === "Policy"
        )[0].MaturityElementDatas
      : [];

    let PracticePMMAssessmentData = this.props.graphData.PMMGraphData[0]
      .MaturityElementGraphDatas
      ? this.props.graphData.PMMGraphData[0].MaturityElementGraphDatas.filter(
          (item) => item.LeverName_En === "Practice"
        )[0].MaturityElementDatas
      : [];

    let PlatformPMMAssessmentData = this.props.graphData.PMMGraphData[0]
      .MaturityElementGraphDatas
      ? this.props.graphData.PMMGraphData[0].MaturityElementGraphDatas.filter(
          (item) => item.LeverName_En === "Platform"
        )[0].MaturityElementDatas
      : [];

    let PolicyGAPReportData = this.props.graphData.PMMGraphData[1]
      .MaturityElementGraphDatas
      ? this.props.graphData.PMMGraphData[1].MaturityElementGraphDatas.filter(
          (item) => item.LeverName_En === "Policy"
        )[0].MaturityElementDatas
      : [];

    let PracticeGAPReportData = this.props.graphData.PMMGraphData[1]
      .MaturityElementGraphDatas
      ? this.props.graphData.PMMGraphData[1].MaturityElementGraphDatas.filter(
          (item) => item.LeverName_En === "Practice"
        )[0].MaturityElementDatas
      : [];
    let PlatformGAPReportData = this.props.graphData.PMMGraphData[1]
      .MaturityElementGraphDatas
      ? this.props.graphData.PMMGraphData[1].MaturityElementGraphDatas.filter(
          (item) => item.LeverName_En === "Platform"
        )[0].MaturityElementDatas
      : [];

    let ProjectDetailsGraphData = this.props.graphData.PMMGraphData
      ? this.props.graphData.PMMGraphData.filter(
          (item) => item.GraphTabName === "Project_Prioritization_Grid"
        )[0].ProjectDetailsGraphDatas
      : [];

    PMMAssessmentLeverData.PolicyPMMAssessmentData = PolicyPMMAssessmentData;
    PMMAssessmentLeverData.PracticePMMAssessmentData = PracticePMMAssessmentData;
    PMMAssessmentLeverData.PlatformPMMAssessmentData = PlatformPMMAssessmentData;

    GAPReportLeverData.PolicyGAPReportData = PolicyGAPReportData;
    GAPReportLeverData.PracticeGAPReportData = PracticeGAPReportData;
    GAPReportLeverData.PlatformGAPReportData = PlatformGAPReportData;
    this.setState(
      {
        graphData: this.props.graphData,
        PMMAssessmentLeverData: PMMAssessmentLeverData,
        GAPReportLeverData: GAPReportLeverData,
      },
      () => {
        this.Radargraph();
        this.ProjectPrioritizationGraph(ProjectDetailsGraphData);
      }
    );
  }

  ProjectPrioritizationGraph(ProjectDetailsGraphData) {
    if (!_.isEmpty(ProjectDetailsGraphData)) {
      // constructing 'data' for bubble graph
      let data = _.map(ProjectDetailsGraphData, (eachItem, index) => {
        return {
          ..._.cloneDeep(bubbleCoordinate),
          x: eachItem.TimeMonths,
          y: eachItem.ImpactOnBOI,
          label: eachItem.ProjectID,
          ProjectDetails: eachItem.ProjectDetails,
        };
      });

      let backgroundColor = _.map(
        ProjectDetailsGraphData,
        (eachItem, index) => {
          let colour = "";
          switch (eachItem.Priority) {
            case "P1":
              colour = greenBackground;
              break;

            case "P2":
              colour = amberBackground;
              break;

            case "P3":
              colour = redBackground;
              break;

            default:
              colour = greenBackground;
          }

          return colour;
        }
      );

      let borderColor = _.map(ProjectDetailsGraphData, (eachItem, index) => {
        let colour = "";
        switch (eachItem.Priority) {
          case "P1":
            colour = greenBorder;
            break;

          case "P2":
            colour = amberBorder;
            break;

          case "P3":
            colour = redBorder;
            break;

          default:
            colour = greenBorder;
        }

        return colour;
      });

      bubbleDatasets = {
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            radius: 13,
            borderWidth: 2,
          },
        ],
      };

      // constructing 'options' for bubble graph

      bubbleOptions.scales.xAxes[0].ticks.max =
        _.maxBy(ProjectDetailsGraphData, "TimeMonths").TimeMonths + 1;
      bubbleOptions.scales.xAxes[0].scaleLabel.labelString =
        i18n.t("Time") + "(" + i18n.t("Months") + ")";

      bubbleOptions.scales.yAxes[0].ticks.max = bubbleOptions.scales.yAxes[0].ticks.suggestedMax =
        _.maxBy(ProjectDetailsGraphData, "ImpactOnBOI").ImpactOnBOI + 5;
      bubbleOptions.scales.yAxes[0].scaleLabel.labelString = i18n.t(
        "ProjectGraph_Yaxis"
      );

      this.setState({
        ProjectDetailsOptions: bubbleOptions,
        ProjectDetailsDatasets: bubbleDatasets,
        ProjectDetailsGraphData: ProjectDetailsGraphData,
      });
    }
  }

  bubblegridClose() {
    this.setState({ bubblegridStatus: false });
  }
  onClickBack = (e) => {
    this.props.closePMMassessment(e);
  };
  handlechangeTab(e, Score) {
    let tabIdDetails = e.target.value;

    if (String(tabIdDetails) !== "") {
      this.setState({
        tabIdDetails,
      });
    }
  }

  handlechangeSubTabPmm = (e) => {
    let subtabDetails = e.target.value;
    if (String(subtabDetails) !== "") {
      this.setState({
        subtabDetails,
      });
    }
  };

  handlechangeSubTabGapReport = (e) => {
    let gapreportDetails = e.target.value;
    if (String(gapreportDetails) !== "") {
      this.setState({
        gapreportDetails,
      });
    }
  };

  render() {
    const { graphData } = this.state;
    let { tabIdDetails, gapreportDetails } = this.state;
    let overallScore =
      graphData.PMMGraphData !== undefined &&
      graphData.PMMGraphData.map((each, index) => {
        return each.OverallScore ? each.OverallScore : 0;
      });

    let PolicyPMMAssessmentDataLeverAsIsScore =
      graphData.PMMGraphData !== undefined &&
      graphData.PMMGraphData[0].MaturityElementGraphDatas
        ? graphData.PMMGraphData[0].MaturityElementGraphDatas.filter(
            (item) => item.LeverName_En === "Policy"
          )[0].LeverAsIsScore
        : [];
    let PracticePMMAssessmentDataAsIsScore =
      graphData.PMMGraphData !== undefined &&
      graphData.PMMGraphData[0].MaturityElementGraphDatas
        ? graphData.PMMGraphData[0].MaturityElementGraphDatas.filter(
            (item) => item.LeverName_En === "Practice"
          )[0].LeverAsIsScore
        : [];

    let PlatformPMMAssessmentDataAsIsScore =
      graphData.PMMGraphData !== undefined &&
      graphData.PMMGraphData[0].MaturityElementGraphDatas
        ? graphData.PMMGraphData[0].MaturityElementGraphDatas.filter(
            (item) => item.LeverName_En === "Platform"
          )[0].LeverAsIsScore
        : [];
    let PolicyGAPReportDataToBe =
      graphData.PMMGraphData !== undefined &&
      graphData.PMMGraphData[1].MaturityElementGraphDatas
        ? graphData.PMMGraphData[1].MaturityElementGraphDatas.filter(
            (item) => item.LeverName_En === "Policy"
          )[0].LeverToBeScore
        : [];

    let PracticeGAPReportDataToBe =
      graphData.PMMGraphData !== undefined &&
      graphData.PMMGraphData[1].MaturityElementGraphDatas
        ? graphData.PMMGraphData[1].MaturityElementGraphDatas.filter(
            (item) => item.LeverName_En === "Practice"
          )[0].LeverToBeScore
        : [];
    let PlatformGAPReportDataToBe =
      graphData.PMMGraphData !== undefined &&
      graphData.PMMGraphData[1].MaturityElementGraphDatas
        ? graphData.PMMGraphData[1].MaturityElementGraphDatas.filter(
            (item) => item.LeverName_En === "Platform"
          )[0].LeverToBeScore
        : [];
    return (
      <div>
        <div className="pmm-view-header">
          <button type="button" className="backbtn" onClick={this.onClickBack}>
            <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
          </button>
          <li style={style}>{graphData.ServiceComponent} </li>
          {/* <div className="select-component select-custom">
            <select name="component" id="component" className="component-name">
              <option value="orderManagement">Order Management</option>
              <option value="component1">component 1</option>
              <option value="component2">component 2</option>
              <option value="component3">component 3</option>
            </select>
          </div> */}
        </div>
        <div className="pmm-view-body">
          <div className="">
            {String(tabIdDetails) !== "projectGrid" &&
            String(tabIdDetails) !== "gapReport" ? (
              <div class="pmm-overallscore">
                <div className="guage-chart">
                  <ReactSpeedometer
                    width={150}
                    height={120}
                    value={overallScore[0]}
                    segments={4}
                    minValue={0}
                    maxValue={4}
                    segmentColors={["#d5002f", "#f49342", "#ffc82c", "#7ec344"]}
                    needleColor={"#000000"}
                    needleHeightRatio={0.6}
                    ringWidth={15}
                  />
                </div>
                <span>
                  {i18n.t("Overall_Score")}: <b>{overallScore[0]}</b>
                </span>
              </div>
            ) : String(tabIdDetails) !== "projectGrid" &&
              String(tabIdDetails) !== "pmmAssessment" ? (
              <div class="pmm-overallscore">
                <div className="guage-chart">
                  <ReactSpeedometer
                    width={150}
                    height={120}
                    value={overallScore[1]}
                    segments={4}
                    minValue={0}
                    maxValue={4}
                    segmentColors={["#d5002f", "#f49342", "#ffc82c", "#7ec344"]}
                    needleColor={"#000000"}
                    needleHeightRatio={0.6}
                    ringWidth={15}
                  />
                </div>
                <span>
                  {i18n.t("Overall_Score")}: <b>{overallScore[1]}</b>
                </span>
              </div>
            ) : (
              ""
            )}
            <Tabs defaultTab="pmmAssessment">
              <TabList className="main-tabs">
                {graphData.PMMGraphData !== undefined &&
                  graphData.PMMGraphData.map((each, index) => {
                    return (
                      <Tab
                        tabFor={
                          each.GraphTabName === "PMM_Assessment"
                            ? "pmmAssessment"
                            : each.GraphTabName === "GAP_Report"
                            ? "gapReport"
                            : "projectGrid"
                        }
                        value={
                          each.GraphTabName === "PMM_Assessment"
                            ? "pmmAssessment"
                            : each.GraphTabName === "GAP_Report"
                            ? "gapReport"
                            : "projectGrid"
                        }
                        onClick={(e) => this.handlechangeTab(e, each)}
                      >
                        {i18n.t(each.GraphTabName)}
                      </Tab>
                    );
                  })}
              </TabList>
              
              <div className="pmm-tab-body">
                <TabPanel
                  tabId="pmmAssessment"
                  value="PMMPolicy"
                  onClick={(e) => this.handlechangeSubTabPmm(e)}
                >
                  <Tabs defaultTab="policy">
                    <TabList className="sub-tabs">

                    {graphData.PMMGraphData!== undefined && graphData.PMMGraphData[0].MaturityElementGraphDatas.map((eachTab)=>{
                      return(
                      <Tab
                        tabFor={eachTab.LeverName_En.toLowerCase()}
                        //value="PMMPolicy"
                        onClick={(e) => this.handlechangeSubTabPmm(e)}
                      >
                        {i18n.t(eachTab.LeverName_En)}
                      </Tab>
                      )
                    })}                   
                    </TabList>
                    {/*   )})} */}
                    <div>
                      {graphData.PMMGraphData!== undefined && graphData.PMMGraphData[0].MaturityElementGraphDatas.map((eachTab)=>{
                        return(
                          <TabPanel tabId={eachTab.LeverName_En.toLowerCase()}>
                          <div className="tab-content">
                            <div className="pmm-policy-score">
                              <span>
                                {i18n.t(`${eachTab.LeverName}_Score`)}:{" "}
                                <b>{eachTab.LeverAsIsScore}</b>
                              </span>
                            </div>

                            <ul>
                              {eachTab.MaturityElementDatas.map(
                                  (each) => {
                                    return (
                                      <li>
                                        <div className="question-text">
                                          <p>{each.PMMMaturityElement}</p>
                                        </div>

                                        <div className="progress-PMM">
                                          <span className="react-progress-bar">
                                            {each.AsIsPMMScore === 1 && (
                                              <ProgressBar>
                                                <ProgressBar
                                                  now={25}
                                                  className="applyRed"
                                                />
                                                <ProgressBar
                                                  now={0}
                                                  className="applyOrange"
                                                />
                                                <ProgressBar
                                                  now={0}
                                                  className="applyYellow"
                                                />
                                                <ProgressBar
                                                  now={0}
                                                  className="applyGreen"
                                                />
                                                <span
                                                  className="rounded-span"
                                                  style={{ left: "25%" }}
                                                >{`${each.AsIsPMMScore}`}</span>
                                              </ProgressBar>
                                            )}
                                            {each.AsIsPMMScore === 2 && (
                                              <ProgressBar>
                                                <ProgressBar
                                                  now={25}
                                                  className="applyRed"
                                                />
                                                <ProgressBar
                                                  now={25}
                                                  className="applyOrange"
                                                />
                                                <ProgressBar
                                                  now={0}
                                                  className="applyYellow"
                                                />
                                                <ProgressBar
                                                  now={0}
                                                  className="applyGreen"
                                                />
                                                <span
                                                  className="rounded-span"
                                                  style={{ left: "50%" }}
                                                >{`${each.AsIsPMMScore}`}</span>
                                              </ProgressBar>
                                            )}
                                            {each.AsIsPMMScore === 3 && (
                                              <ProgressBar>
                                                <ProgressBar
                                                  now={25}
                                                  className="applyRed"
                                                />
                                                <ProgressBar
                                                  now={25}
                                                  className="applyOrange"
                                                />
                                                <ProgressBar
                                                  now={25}
                                                  className="applyYellow"
                                                />
                                                <ProgressBar
                                                  now={0}
                                                  className="applyGreen"
                                                />
                                                <span
                                                  className="rounded-span"
                                                  style={{ left: "75%" }}
                                                >{`${each.AsIsPMMScore}`}</span>
                                              </ProgressBar>
                                            )}
                                            {each.AsIsPMMScore === 4 && (
                                              <ProgressBar>
                                                <ProgressBar
                                                  now={25}
                                                  className="applyRed"
                                                />
                                                <ProgressBar
                                                  now={25}
                                                  className="applyOrange"
                                                />
                                                <ProgressBar
                                                  now={25}
                                                  className="applyYellow"
                                                />
                                                <ProgressBar
                                                  now={25}
                                                  className="applyGreen"
                                                />
                                                <span
                                                  className="rounded-span"
                                                  style={{
                                                    left: "100%",
                                                    marginLeft: "-25px",
                                                  }}
                                                >{`${each.AsIsPMMScore}`}</span>
                                              </ProgressBar>
                                            )}
                                          </span>
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                            </ul>
                            <div className="pmm-policy-legends">
                              <ul>
                                <li>
                                  <span className="base-color"></span>
                                  {i18n.t("Base")} (0-0.99)
                                </li>
                                <li>
                                  <span className="average-color"></span>
                                  {i18n.t("Average")} (1-1.99)
                                </li>
                                <li>
                                  <span className="aboveaverage-color"></span>
                                  {i18n.t("Above_Average")} (2-2.99)
                                </li>
                                <li>
                                  <span className="bestpractice-color"></span>
                                  {i18n.t("Best_Practice")} (>2.99)
                                </li>
                              </ul>
                            </div>
                          </div>
                        </TabPanel>
                        )
                      })}
                    </div>
                  </Tabs>
                </TabPanel>
                <TabPanel tabId="gapReport">
                  <Tabs defaultTab="policy">
                    <TabList className="sub-tabs">
                    {graphData.PMMGraphData!== undefined && graphData.PMMGraphData[1].MaturityElementGraphDatas.map((eachTab)=>{
                      return(
                      <Tab
                        tabFor={eachTab.LeverName_En.toLowerCase()}
                        //value="PMMPolicy"
                        onClick={(e) => this.handlechangeSubTabGapReport(e)}
                      >
                        {i18n.t(eachTab.LeverName_En)}
                      </Tab>
                      )
                    })}  
                      {/* <Tab
                        tabFor="policy"
                        value="GapReportPolicy"
                        onClick={(e) => this.handlechangeSubTabGapReport(e)}
                      >
                        {i18n.t("Policy")}
                      </Tab>
                      <Tab
                        tabFor="practice"
                        value="GapReportPractice"
                        onClick={(e) => this.handlechangeSubTabGapReport(e)}
                      >
                        {i18n.t("Practice")}
                      </Tab>
                      <Tab
                        tabFor="platform"
                        value="GapReportPlatform"
                        onClick={(e) => this.handlechangeSubTabGapReport(e)}
                      >
                        {i18n.t("Platform")}
                      </Tab> */}
                    </TabList>
                    <div>

                      {graphData.PMMGraphData!== undefined && graphData.PMMGraphData[1].MaturityElementGraphDatas.map((eachTab,index)=>{
                        return(
                          <TabPanel tabId={eachTab.LeverName_En.toLowerCase()}>
                        <div>
                          <div className="gapReport-content row">
                            <div className="col-lg-5">
                              <div className="gapReport-graph">
                                <Radar
                                  data={
                                    this.state.gapReportTabData[index]
                                  }
                                  options={options}
                                  width={336}
                                  height={337}
                                />
                              </div>
                            </div>

                            <div className="col-lg-7">
                              <div className="gapReport-data">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>{i18n.t("Description")}</th>
                                        <th>{i18n.t("AsIs")}</th>
                                        <th>{i18n.t("ToBe")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {eachTab.MaturityElementDatas.map(
                                          (each, index) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td>
                                                    {String.fromCharCode(
                                                      this.state.Character.charCodeAt(
                                                        0
                                                      ) +
                                                        (index + 1)
                                                    )}
                                                  </td>
                                                  <td>
                                                    {each.PMMMaturityElement}
                                                  </td>
                                                  <td>{each.AsIsPMMScore}</td>
                                                  <td>{each.ToBePMMScore}</td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )}
                                      {/* Policy Score Display */}

                                      {String(gapreportDetails) ===
                                        "GapReportPolicy" &&
                                      String(tabIdDetails) === "gapReport" ? (
                                        <tr className="policyscore-displayrow">
                                          <td></td>
                                          <td>{i18n.t(`${eachTab.LeverName_En}_Score`)}</td>
                                          <td>
                                            {
                                              eachTab.LeverAsIsScore
                                            }
                                          </td>
                                          <td>{eachTab.LeverToBeScore}</td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                        )
                      })}

                     
                    </div>
                  </Tabs>
                </TabPanel>
                <TabPanel tabId="projectGrid">
                  <div className="bubble-chart">
                    <div className="grid-details">
                      <img
                        src={HamburgerMenu}
                        onClick={this.bubblegridShow}
                        alt=""
                        width="21"
                        height="18"
                      />
                    </div>
                    {this.state.bubblegridStatus === true ? (
                      <div className="bubblechart-data">
                        <div className="grid-heading">
                          {" "}
                          {/* <h4>Project Prioritization Grid Details</h4> */}
                          <h4>{i18n.t("Prioritization_Grid_Details")}</h4>
                          <div className="grid-close">
                            <img
                              src={Closeblue}
                              onClick={this.bubblegridClose}
                              alt=""
                              width="15"
                              height="15"
                            />
                          </div>
                        </div>

                        <div className="bubblechart-table">
                          <div class="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>P#</th> {/*UILiteral_Change */}
                                  <th>{i18n.t("Description")}</th>
                                  <th>
                                    {i18n.t("Time")}{" "}
                                    <span>({i18n.t("Months")})</span>
                                  </th>
                                  <th>
                                    {i18n.t("Impact")} <span>(%)</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!_.isNil(this.state.ProjectDetailsGraphData) &&
                                  this.state.ProjectDetailsGraphData.map(
                                    (each, index) => {
                                      return (
                                        <tr>
                                          <td>{each.ProjectID}</td>
                                          <td>{each.ProjectDetails}</td>
                                          <td>{each.TimeMonths}</td>
                                          <td>{each.ImpactOnBOI}</td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="custom-legend">
                      <div id="bubbleLegend">
                        {" "}
                        <span className="vertical-text">
                          {i18n.t("Impact")}
                        </span>
                        <svg height="100" width="150">
                          <path
                            d="M0 0 L0 100 L80 0 Z"
                            fill="#7ec344"
                            stroke="#95a5a6"
                            stroke-width="1"
                          />
                          <path
                            d="M0 100 L80 0 L150 0 L150 50Z"
                            fill="#ffc82c"
                            stroke="#95a5a6"
                            stroke-width="1"
                          />
                          <path
                            d="M150 100 L0 100 L150 50 Z"
                            fill="#d5002f"
                            stroke="#95a5a6"
                            stroke-width="1"
                          />
                          <text x="3" y="30" font-size="10" color="#ffffff">
                            {i18n.t("Priority")} 1{/* Priority 1 */}
                          </text>
                          <text x="80" y="30" font-size="10" color="#ffffff">
                            {i18n.t("Priority")} 2{/* Priority 2 */}
                          </text>
                          <text x="82" y="85" font-size="10" color="#ffffff">
                            {i18n.t("Priority")} 3{/* Priority 3 */}
                          </text>
                          {/* Sorry, your browser does not support inline SVG. */}
                          {i18n.t("inlineSVGerrorMsg")}
                        </svg>
                        <span className="timeCls">{i18n.t("Time")}</span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Bubble data={bubbleDatasets} options={bubbleOptions} />
                    </div>
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}
export default ViewPMM;
