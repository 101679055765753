/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import axiosInstance from "../../Shared/interceptor";
import { trycatchAlertPopup } from "../../Shared/Constant";
import { store } from "../../reducers/configureStore";
import i18n from "i18next";
import "./HelpFeatures.css";
import EmptyImage from "../../Images/EmptyImage.svg";
import UploadReplaceImage from "../../Images/UploadReplaceImage.svg";
import Success from "../../Images/Success.svg";
import { LocalApiBaseUrl,regexForStartWithSpecialCharcterinText } from "../../Shared/Constant";
import { isValidEmbedLink, validateIsAllowedDomainFromURL, fetchAllowedDomains } from "../../Shared/commonFunctions";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../../Shared/SynopsLoader";
import { connect } from "react-redux";
import _ from 'lodash';
import extractUrls from 'extract-urls';

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class AddHelpFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Add",
      isBenefitsDropdownStatus: false,
      addfeatureNotificationStatus: false,
      FeatureName: "",
      Description: "",
      DisplayImage: "",
      selectedfile: "",
      check: [],
      demoLink: "",
      userGuideLink: "",
      errors: {
        FeatureName: "",
        Description: "",
        UserGuide: "",
        DemoLink: "",
      },
      filePreview: "",

      selectedViewBySection: "All",

      allBenefits: false,
      Benefits: [],

      tempBenefits: [],
      tempallBenefits: false,
      allBeneficiary: false,
      tempallBeneficiary: false,
      tempBeneficiary: [],
      Beneficiary: [],
      allSynopsFD: false,
      tempallSynopsFD: false,
      tempSynopsFrontSection: [],
      SynopsFrontSection: [],
      ViewbySection: [],
      buttonDisable: false,
      fileName: "",
      maxImageSize: "",
      isAPILoading: false,
      allowedDomainsArray: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.addfeatureNotificationModalClose = this.addfeatureNotificationModalClose.bind(this);
    this.fileInput = React.createRef();
  }

  fetchEditData = () => {
    const { history } = this.props;
    let data = history.location.state.detail;
    let {
      FeatureName,
      Description,
      userGuideLink,
      demoLink,
      ViewbySection,
      tempBenefits,
      Benefits,
      tempBeneficiary,
      Beneficiary,
      selectedViewBySection,
      SynopsFrontSection,
      tempSynopsFrontSection,
      DisplayImage,
      allBeneficiary,
      allBenefits,
      allSynopsFD,
    } = this.state;
    Benefits = [];
    Beneficiary = [];
    SynopsFrontSection = [];
    FeatureName = data.TopicName;
    Description = data.TopicDescription;
    userGuideLink = data.DocLink;
    demoLink = data.VideoLink;
    DisplayImage = data.DisplayImage;
    selectedViewBySection = data.DisplayCategory;
    ViewbySection = ViewbySection.map((each) => {
      if (String(each.Name) === String(selectedViewBySection)) {
        each.isChecked = true;
      } else {
        each.isChecked = false;
      }
      return each;
    });
    tempBenefits.forEach((each) => {
      if (data.BenefitIds.filter((d) => String(d) === String(each.ID)).length > 0) {
        each.isChecked = true;
        Benefits.push(each);
      }
    });
    if (Benefits.length === tempBenefits.length && tempBenefits.length !== 0) {
      allBenefits = true;
    } else {
      allBenefits = false;
    }

    tempBeneficiary.forEach((each) => {
      if (data.BeneficiaryIds.filter((d) => String(d) === String(each.ID)).length > 0) {
        each.isChecked = true;
        Beneficiary.push(each);
      }
    });
    if (Beneficiary.length === tempBeneficiary.length && tempBeneficiary.length !== 0) {
      allBeneficiary = true;
    } else {
      allBeneficiary = false;
    }

    tempSynopsFrontSection.forEach((each) => {
      if (data.SynopsFDSection.filter((d) => String(d) === String(each.Name)).length > 0) {
        each.isChecked = true;
        SynopsFrontSection.push(each);
      }
    });
    if (SynopsFrontSection.length === tempSynopsFrontSection.length && tempSynopsFrontSection.length !== 0) {
      allSynopsFD = true;
    } else {
      allSynopsFD = false;
    }

    this.setState({
      title: "Edit",
      FeatureName,
      Description,
      userGuideLink,
      demoLink,
      selectedViewBySection,
      Benefits,
      Beneficiary,
      SynopsFrontSection,
      DisplayImage,
      allSynopsFD,
      allBeneficiary,
      allBenefits,
    });
  };

  fetchInitialData = () => {
    const { history } = this.props;
    let languageid = store.getState().CurrentFilter.languageData.id;
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${LocalApiBaseUrl}UserGuide/GetPopupDropdownData`, {
          params: {
            LanguageID: languageid,
            SectionName: "Help",
          },
        })
        .then((response) => {
          let benefits = response.data.Benefits.sort((a, b) =>
            a.SequenceForHelpSection > b.SequenceForHelpSection ? 1 : -1
          );
          benefits = benefits.map((each) => ({ ...each, isChecked: false }));
          let beneficiaries = response.data.Beneficiary.sort((a, b) =>
            a.SequenceForHelpSection > b.SequenceForHelpSection ? 1 : -1
          );
          beneficiaries = beneficiaries.map((each) => ({ ...each, isChecked: false }));
          let synopsFd = response.data.SynopsFrontSection.sort((a, b) =>
            a.SequenceForHelpSection > b.SequenceForHelpSection ? 1 : -1
          );
          synopsFd = synopsFd.map((each) => ({ ...each, isChecked: false }));
          let viewBy = response.data.ViewbySection;
          viewBy = viewBy.map((each) => ({
            Name: each,
            isChecked: false,
          }));

          this.setState(
            {
              tempBenefits: benefits,
              tempBeneficiary: beneficiaries,
              tempSynopsFrontSection: synopsFd,
              ViewbySection: viewBy,
            },
            () => {
              if (String(history.location.state) !== String(undefined)) {
                this.fetchEditData();
              }
              resolve();
            }
          );
        })
        .catch((error) => {
          reject();
        });
    });
  };

  fetchImageSize = () => {
    axiosInstance.get(`${LocalApiBaseUrl}UserGuide/ReturnMaxCoverImageSizeConfigKeys`).then((response) => {
      this.setState({
        maxImageSize: response.data.MaxCoverImageSizeInKB,
      });
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });


  };

  async componentDidMount() {
    const { clientData } = this.props;
    const reqData = {
      OrgEntityID: clientData.id
    };
    this.setState({
      isAPILoading: true,
    });
    const allowedDomains = await fetchAllowedDomains(reqData);
    this.setState({
      allowedDomainsArray: allowedDomains,
      isAPILoading: false,
    });

    this.fetchInitialData();
    this.fetchImageSize();
    window.scrollTo(0, 0)
  }
  isValidText=(text)=> {
    return regexForStartWithSpecialCharcterinText.test(text);
  }
  validateForm = () => {
    const { FeatureName, Description, userGuideLink, demoLink, allowedDomainsArray } = this.state;
    const InvalidFeature=i18n.t("Invalid_Feature");
    const InvalidDescription=i18n.t("Invalid_Description");
    let formValid = true;
    let errors = {};
    errors.UserGuide = '';
    errors.DemoLink = '';

    if (String(FeatureName.trim()) === "") {
      formValid = false;
      errors["FeatureName"] = i18n.t("addWoErrorM1");
    }
    else{
      if (!this.isValidText(FeatureName.trim())) {
        formValid = false;
        errors["FeatureName"] = InvalidFeature;
      }
     }

    if (String(Description.trim()) === "") {
      formValid = false;
      errors["Description"] = i18n.t("addWoErrorM3");
    }
 else{
      if (!this.isValidText(Description.trim())) {
        formValid = false;
        errors["Description"] = InvalidDescription;
      }
    }
    const userGuideLinkValue = _.trim(String(userGuideLink));
    if (!_.isEmpty(userGuideLinkValue)) {
      const { isValid, errMsg } = isValidEmbedLink(userGuideLinkValue);
      errors.UserGuide = errMsg;
      // IF THE EMBED LINK IS VALID, THE EXTRACT THE URL AND VALIDATE IT AGAINST WHITELISTED DOMAINS
      if (isValid) {
        const urls = extractUrls(userGuideLinkValue);
        const isAllowed = validateIsAllowedDomainFromURL(urls[0], allowedDomainsArray);
        if (!isAllowed) {
          errors.UserGuide = i18n.t('subdomain_or_domain_not_whitelisted');
        }
      }
    }

    const demoLinkValue = _.trim(String(demoLink));
    if (!_.isEmpty(demoLinkValue)) {
      const { isValid, errMsg } = isValidEmbedLink(demoLinkValue);
      errors.DemoLink = errMsg;
      // IF THE EMBED LINK IS VALID, THE EXTRACT THE URL AND VALIDATE IT AGAINST WHITELISTED DOMAINS
      if (isValid) {
        const urls = extractUrls(demoLinkValue);
        const isAllowed = validateIsAllowedDomainFromURL(urls[0], allowedDomainsArray);
        if (!isAllowed) {
          errors.DemoLink = i18n.t('subdomain_or_domain_not_whitelisted');
        }
      }
    }

    this.setState({
      errors,
    });

    return formValid;
  };

  handleSubmit() {
    if (String(this.validateForm()) === String(true)) {
      this.setState({ buttonDisable: true });
      const {
        FeatureName,
        Description,
        userGuideLink,
        demoLink,
        Benefits,
        Beneficiary,
        SynopsFrontSection,
        selectedViewBySection,
        DisplayImage,
        fileName,
        selectedfile
      } = this.state;
      const { history } = this.props;
      let data = {};
      data["ID"] = String(history.location.state) !== String(undefined) ? history.location.state.detail.ID : 0;
      data["TopicName"] = FeatureName.trim();
      data["TopicDescription"] = Description.trim();
      data["DocLink"] = userGuideLink ? userGuideLink.trim() : '';
      data["VideoLink"] = demoLink ? demoLink.trim() : '';
      data["DisplayCategory"] = selectedViewBySection;
      data["BenefitIds"] = Benefits.map((each) => each.ID).toString();
      data["BeneficiaryIds"] = Beneficiary.map((each) => each.ID).toString();
      data["SynopsFDSection"] =
        SynopsFrontSection.map((each) => each.ID).length > 0 ? SynopsFrontSection.map((each) => each.ID) : [];


      const formData = new FormData();
      if (fileName) {
        formData.append("DisplayImage", selectedfile, fileName);
      } else {
        if (DisplayImage) {
          formData.append("DisplayImage", null);
          formData.append("CoverImageBase64", DisplayImage);
        } else {
          formData.append("DisplayImage", null);
          formData.append("CoverImageBase64", "");
        }
      }
      formData.append("ID", data.ID);
      formData.append("TopicName", data.TopicName);
      formData.append("TopicDescription", data.TopicDescription);
      formData.append("DocLink", data.DocLink);
      formData.append("VideoLink", data.VideoLink);
      formData.append("DisplayCategory", data.DisplayCategory);
      formData.append("BenefitIds", data.BenefitIds);
      formData.append("BeneficiaryIds", data.BeneficiaryIds);

      formData.append("SynopsFDSectionstring", data.SynopsFDSection.toString());
      let languageid = store.getState().CurrentFilter.languageData.id;

      axiosInstance
        .post(`${LocalApiBaseUrl}UserGuide/AddEditFunctionalityDetails?LanguageID=${languageid}`, formData)
        .then((response) => {
          if (response.data.IsSuccess) {
            this.setState({ addfeatureNotificationStatus: true, buttonDisable: false });
          } else {
            alert(response.data.StatusMessage);
            this.setState({ buttonDisable: false });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });

    }
  }
  addfeatureNotificationModalClose() {
    this.setState({ addfeatureNotificationStatus: false }, () => {
      this.navigateToHelpFeatureList();
    });
  }

  isBenefitseDropdown = () => {
    let { tempallBenefits, allBenefits, Benefits, tempBenefits } = this.state;
    tempallBenefits = allBenefits;
    tempBenefits = tempBenefits.map((each) => {
      if (Benefits.filter((d) => String(d.ID) === String(each.ID)).length > 0) {
        each.isChecked = true;
      } else {
        each.isChecked = false;
      }
      return each;
    });
    if (
      tempBenefits.filter((each) => String(each.isChecked) === String(true)).length === tempBenefits.length &&
      tempBenefits.length !== 0
    ) {
      tempallBenefits = true;
    }

    this.setState({ isBenefitsDropdownStatus: true, tempBenefits, tempallBenefits });
  };
  isBenefitsDropdownOverlay = () => {
    this.setState({ isBenefitsDropdownStatus: false });
  };

  isSynopsFDDropdownOverlay = () => {
    this.setState({ isSynopsFDDropdownStatus: false });
  };
  isViewBySectionDropdown = () => {
    this.setState({ isViewBySectionDropdownStatus: true });
  };
  isViewBySectionDropdownOverlay = () => {
    this.setState({ isViewBySectionDropdownStatus: false });
  };
  handleBenefitsApply = () => {
    let { tempallBenefits, tempBenefits, Benefits, allBenefits } = this.state;
    allBenefits = tempallBenefits;
    Benefits = [];
    tempBenefits.forEach((each) => {
      if (each.isChecked) {
        Benefits.push(each);
      }
    });
    this.setState(
      {
        Benefits,
        allBenefits,
      },
      () => {
      }
    );
  };

  onClickFetchfeatureSearch3 = (selectedData) => (evt) => {
    this.setState({ selectedfeatureSearch: selectedData });
    setTimeout(() => {
      this.isViewBySectionDropdownOverlay();
    }, 100);
  };
  handleBeneficiaryDropDown = () => {
    let { tempBeneficiary, tempallBeneficiary, allBeneficiary, Beneficiary } = this.state;
    tempallBeneficiary = allBeneficiary;
    tempBeneficiary = tempBeneficiary.map((each) => {
      if (Beneficiary.filter((d) => String(d.ID) === String(each.ID)).length > 0) {
        each.isChecked = true;
      } else {
        each.isChecked = false;
      }
      return each;
    });
    if (
      tempBeneficiary.filter((each) => String(each.isChecked) === String(true)).length === tempBeneficiary.length &&
      tempBeneficiary.length !== 0
    ) {
      tempallBeneficiary = true;
    }

    this.setState({ tempallBeneficiary, tempBeneficiary, isBeneficiaryDropdownStatus: true });
  };
  handleBenefitsChange = (index) => {
    let { tempBenefits, tempallBenefits } = this.state;
    tempallBenefits = false;
    tempBenefits[index].isChecked = !tempBenefits[index].isChecked;
    if (tempBenefits.filter((each) => String(each.isChecked) === String(true)).length === tempBenefits.length) {
      tempallBenefits = true;
    }
    this.setState({
      tempallBenefits,
      tempBenefits,
    },
      () => {
        this.handleBenefitsApply()
      });
  };
  handleAllBenefitsChange = () => {
    let { tempBenefits, tempallBenefits } = this.state;
    tempallBenefits = !tempallBenefits;
    if (tempallBenefits) {
      tempBenefits = tempBenefits.map((each) => ({ ...each, isChecked: true }));
      this.setState({ tempBenefits, tempallBenefits },
        () => {
          this.handleBenefitsApply()
        });
    } else {
      tempBenefits = tempBenefits.map((each) => ({ ...each, isChecked: false }));
      this.setState({ tempBenefits, tempallBenefits },
        () => {
          this.handleBenefitsApply()
        });
    }
  };
  handleBeneficiary = (index) => {
    let { tempBeneficiary, tempallBeneficiary } = this.state;
    tempBeneficiary[index].isChecked = !tempBeneficiary[index].isChecked;
    tempallBeneficiary = false;
    if (tempBeneficiary.filter((each) => String(each.isChecked) === String(true)).length === tempBeneficiary.length) {
      tempallBeneficiary = true;
    }

    this.setState({
      tempBeneficiary,
      tempallBeneficiary,
    },
      () => {
        this.handleBeneficiaryApply()
      });
  };
  handleBeneficiaryAll = () => {
    let { tempallBeneficiary, tempBeneficiary } = this.state;
    tempallBeneficiary = !tempallBeneficiary;
    if (tempallBeneficiary) {
      tempBeneficiary = tempBeneficiary.map((each) => ({ ...each, isChecked: true }));
    } else {
      tempBeneficiary = tempBeneficiary.map((each) => ({ ...each, isChecked: false }));
    }
    this.setState({
      tempallBeneficiary,
      tempBeneficiary,
    },
      () => {
        this.handleBeneficiaryApply()
      });
  };
  handleBeneficiaryApply = () => {
    let { tempBeneficiary, tempallBeneficiary, allBeneficiary, Beneficiary } = this.state;
    allBeneficiary = tempallBeneficiary;
    Beneficiary = [];
    [...tempBeneficiary].forEach((each) => {
      if (each.isChecked) {
        Beneficiary.push(each);
      }
    });
    this.setState(
      {
        Beneficiary,
        allBeneficiary,
      },
      () => {
      }
    );
  };
  isBeneficiaryDropdownOverlay = () => {
    this.setState({ isBeneficiaryDropdownStatus: false });
  };

  isSynopsFDDropdown = () => {
    let { tempallSynopsFD, allSynopsFD, tempSynopsFrontSection, SynopsFrontSection } = this.state;
    tempallSynopsFD = allSynopsFD;

    tempSynopsFrontSection = tempSynopsFrontSection.map((each) => {
      if (SynopsFrontSection.filter((d) => String(d.ID) === String(each.ID)).length > 0) {
        each.isChecked = true;
        return each;
      } else {
        each.isChecked = false;
        return each;
      }
    });
    if (
      tempSynopsFrontSection.filter((each) => String(each.isChecked) === String(true)).length === tempSynopsFrontSection.length &&
      tempSynopsFrontSection.length !== 0
    ) {
      tempallSynopsFD = true;
    }

    this.setState({ isSynopsFDDropdownStatus: true, tempallSynopsFD, tempSynopsFrontSection });
  };
  handleSynopsFD = (index) => {
    let { tempSynopsFrontSection, tempallSynopsFD } = this.state;
    tempallSynopsFD = false;
    tempSynopsFrontSection[index].isChecked = !tempSynopsFrontSection[index].isChecked;
    if (tempSynopsFrontSection.filter((each) => String(each.isChecked) === String(true)).length === tempSynopsFrontSection.length) {
      tempallSynopsFD = true;
    }
    this.setState({
      tempSynopsFrontSection,
      tempallSynopsFD,
    },
      () => {
        this.onSynopsFDApply()
      });
  };
  handleSynopsFDAll = () => {
    let { tempallSynopsFD, tempSynopsFrontSection } = this.state;
    tempallSynopsFD = !tempallSynopsFD;
    if (tempallSynopsFD) {
      tempSynopsFrontSection = tempSynopsFrontSection.map((each) => ({ ...each, isChecked: true }));
    } else {
      tempSynopsFrontSection = tempSynopsFrontSection.map((each) => ({ ...each, isChecked: false }));
    }
    this.setState({ tempallSynopsFD, tempSynopsFrontSection },
      () => {
        this.onSynopsFDApply()
      });
  };
  onSynopsFDApply = () => {
    let { tempallSynopsFD, allSynopsFD, SynopsFrontSection, tempSynopsFrontSection } = this.state;

    allSynopsFD = tempallSynopsFD;
    SynopsFrontSection = [];
    [...tempSynopsFrontSection].forEach((each) => {
      if (each.isChecked) {
        SynopsFrontSection.push(each);
      }
    });

    this.setState(
      {
        allSynopsFD,
        SynopsFrontSection,
      },
      () => {
      }
    );
  };
  handleViewBySection = (index) => {
    const { ViewbySection } = this.state;
    let data = ViewbySection[index].Name;

    this.setState(
      {
        selectedViewBySection: data,
      },
      () => {
        this.isViewBySectionDropdownOverlay();
      }
    );
  };
  navigateToHelpFeatureList = () => {
    const { history } = this.props;
    const location = {
      pathname: `/helpfeatures`,
    };
    history.push(location);
  };
  handleCancel = () => {
    this.navigateToHelpFeatureList();
  };
  handleFeatureName = (e) => {
    let value = e.target.value;
    this.setState({ FeatureName: value, errors: {} });
  };
  handleDescription = (e) => {
    let value = e.target.value;
    this.setState({ Description: value, errors: {} });
  };
  onIconSelect = (event) => {

    let maxImageSize = this.state.maxImageSize;
    let errors = this.state.errors;
    errors["icon"] = "";
    let DisplayImage = event.target.files[0];
    let fileName = event.target.value.split("\\").pop();
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.readAsDataURL(file);

    reader.onload = () => {

      let idxDot = file.name.lastIndexOf(".") + 1;
      let extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
      if (String(extFile) === "jpg" || String(extFile) === "jpeg" || String(extFile) === "png") {
        if (Math.round(file.size / 1000) <= maxImageSize) {
          this.setState({
            filePreview: reader.result,
            DisplayImage: DisplayImage,
            selectedfile: event.target.files[0],
            IconBase64: null,
            fileName: fileName,
          });
        } else {
          errors["icon"] = "File Size should be less than " + maxImageSize + " KB";
        }
      } else {
        errors["icon"] = "Only jpg/jpeg and png files are allowed!";
      }
      if (String(errors["icon"]) !== "") {
        this.setState({
          errors: errors,
        });
      }
    };
  };

  handleRemoveImage = () => {
    this.fileInput.current.value = null;
    this.setState({
      filePreview: "",
      DisplayImage: "",
      selectedfile: "",
      IconBase64: null,
      fileName: "",
    });
  };

  render() {

    const {
      title,
      userGuideLink,
      demoLink,
      selectedViewBySection,
      allSynopsFD,
      tempallSynopsFD,
      SynopsFrontSection,
      tempallBeneficiary,
      tempBeneficiary,
      Beneficiary,
      allBeneficiary,
      tempallBenefits,
      tempBenefits,
      Benefits,
      allBenefits,
      FeatureName,
      Description,
      buttonDisable,
      DisplayImage,
      filePreview,
      fileName,
      errors,
      isBenefitsDropdownStatus,
      isBeneficiaryDropdownStatus,
      isSynopsFDDropdownStatus,
      tempSynopsFrontSection,
      isViewBySectionDropdownStatus,
      ViewbySection,
      addfeatureNotificationStatus,
      isAPILoading,
    } = this.state;

    return (
      <>
        {/* IF API IS LOADING THEN SHOW THE LOADER */}
        {isAPILoading && (
          <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
        )}

        <div className="helpfeatures-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="helpfeatures-heading">
                  <h5>
                    {title} {i18n.t("Feature")}
                  </h5>
                  <div className="addhelpfeature-btns">
                    <button type="button" class="btn btn-default mr-2" onClick={this.handleCancel}>
                      {i18n.t("Cancel")}
                    </button>
                  </div>
                </div>
                <div className="addfeature-form">
                  <div className="addfeature-imgblock">
                    <div class="upload-featurewrapper">
                      {DisplayImage && String(fileName) === "" ? (
                        <img alt="" width="134px" height="134px" src={"data:image/png;base64," + DisplayImage} />
                      ) : filePreview ? (
                        <img alt="" width="134px" height="134px" src={filePreview} />
                      ) : (
                        <img alt="" src={EmptyImage} width="134px" height="134px" />
                      )}
                      <button class="btn">
                        <div className="replace-block">
                          <label className="replace-block-btn" for="uploadwrapper">
                            <img alt="" src={UploadReplaceImage} title="" />
                            <span>{i18n.t("Upload")}</span>
                          </label>
                          <input type="file" name="myfile" id="uploadwrapper" title="" onChange={this.onIconSelect} ref={this.fileInput} />
                        </div>
                      </button>
                    </div>

                    {(DisplayImage || fileName || filePreview) && (
                      <div className="replace-block" onClick={this.handleRemoveImage}>
                        {/* <img src={UploadReplaceImage} title="ReplaceImage" /> */}
                        <span className="tbldel">
                          <i class="fas fa-trash-alt delete-icon-fix"></i>
                        </span>
                        <span>{i18n.t("Remove")}</span>
                      </div>
                    )}

                    {fileName && <p>{fileName}</p>}
                    <span className="errorMsg">{errors.icon}</span>
                  </div>
                  <div className="addfeature-formfields">
                    <div className="formfield-info">
                      <h3>{i18n.t("Basic_Info")}</h3>
                      <div className="form-section">
                        <div className="form-group border-group">
                          <label for="Name" class="form-label">
                            {i18n.t("Feature_Name")}
                            <span className="required">*</span>
                          </label>
                          <input
                            maxLength="250"
                            type="text"
                            placeholder="Feature Name"
                            class="form-control"
                            Name="Feature"
                            value={FeatureName}
                            onChange={(e) => {
                              this.handleFeatureName(e);
                            }}
                          />
                        </div>
                        <span className="errorMsg">{errors.FeatureName}</span>

                        <div className="form-group border-group">
                          <label for="description" class="form-label">
                            {i18n.t("Description_Feature")}
                            <span className="required">*</span>
                          </label>
                          <textarea
                            maxLength="1000"
                            rows="4"
                            placeholder="Description of the Feature"
                            class="form-control"
                            Name="Description"
                            value={Description}
                            onChange={(e) => {
                              this.handleDescription(e);
                            }}
                          />
                        </div>
                        <span className="errorMsg">{errors.Description}</span>
                      </div>
                    </div>
                    <div className="formfield-info">
                      <h3>{i18n.t("Benefits")}</h3>
                      <div className="form-section">
                        <div className="form-group border-group">
                          <label for="Name" class="form-label">
                            {i18n.t("Benefits")}
                          </label>
                          <div className="featuresearch-dropdown">
                            <div className="featuresearch-dropdownname" onClick={this.isBenefitseDropdown}>
                              {" "}
                              {allBenefits ? (
                                <span>{i18n.t("All_Selected")}</span>
                              ) : Benefits.length !== 0 ? (
                                <span>
                                  {Benefits.map((each, ind) => {
                                    if (String(ind) !== String(Benefits.length - 1)) {
                                      return <span>{each.Name},</span>;
                                    } else {
                                      return <span>{each.Name}</span>;
                                    }
                                  })}{" "}
                                </span>
                              ) : (
                                <span>{i18n.t("Select_Benefits")}</span>
                              )}
                            </div>
                            {isBenefitsDropdownStatus ? (
                              <div className="featuresearch-list">
                                <ul>
                                  <li>
                                    <label className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        Name="checkAll"
                                        checked={tempallBenefits}
                                        onChange={this.handleAllBenefitsChange}
                                      />
                                      <span className="checkmark"></span>
                                      <span className="form-check-label">{i18n.t("Select_All")}</span>
                                    </label>
                                  </li>
                                  {tempBenefits.map((each, index) => {
                                    return (
                                      <li>
                                        <label className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            key={each.ID}
                                            Name={each.Name}
                                            value={each.Name}
                                            checked={each.isChecked}
                                            onChange={() => {
                                              this.handleBenefitsChange(index);
                                            }}
                                          />
                                          <span className="checkmark"></span>
                                          <span className="form-check-label">{each.Name}</span>
                                        </label>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {isBenefitsDropdownStatus ? (
                            <div className="viewby-dropdown-overlay" onClick={this.isBenefitsDropdownOverlay}></div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group border-group">
                          <label for="Name" class="form-label">
                            {i18n.t("Beneficiary")}
                          </label>
                          <div className="featuresearch-dropdown">
                            <div className="featuresearch-dropdownname" onClick={this.handleBeneficiaryDropDown}>
                              {" "}
                              {String(allBeneficiary) === String(true) ? (
                                <span> {i18n.t("All_Selected")}</span>
                              ) : Beneficiary.length !== 0 ? (
                                <span>
                                  {Beneficiary.map((each, ind) => {
                                    if (String(ind) !== String(Beneficiary.length - 1)) {
                                      return <span>{each.Name},</span>;
                                    } else {
                                      return <span>{each.Name}</span>;
                                    }
                                  })}{" "}
                                </span>
                              ) : (
                                <span>{i18n.t("Select_Beneficiary")}</span>
                              )}
                            </div>
                            {isBeneficiaryDropdownStatus ? (
                              <div className="featuresearch-list">
                                <ul>
                                  <li>
                                    <label className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        Name="checkAll"
                                        checked={tempallBeneficiary}
                                        onChange={this.handleBeneficiaryAll}
                                      />
                                      <span className="checkmark" checked={tempallBeneficiary}></span>
                                      <span className="form-check-label">{i18n.t("Select_All")}</span>
                                    </label>
                                  </li>
                                  {tempBeneficiary.map((each, index) => {
                                    return (
                                      <li>
                                        <label className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            key={each.ID}
                                            Name={each.Name}
                                            value={each.Name}
                                            checked={each.isChecked}
                                            onChange={() => {
                                              this.handleBeneficiary(index);
                                            }}
                                          />
                                          <span className="checkmark"></span>
                                          <span className="form-check-label">{each.Name}</span>
                                        </label>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {isBeneficiaryDropdownStatus ? (
                            <div className="viewby-dropdown-overlay" onClick={this.isBeneficiaryDropdownOverlay}></div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="formfield-info">
                      <h3>{i18n.t("Additional_Info")}</h3>
                      <div className="form-section">
                        <div className="form-group border-group">
                          <label for="Name" class="form-label">
                            {i18n.t("SynOps_Front_Section")}
                          </label>
                          <div className="featuresearch-dropdown">
                            <div className="featuresearch-dropdownname" onClick={this.isSynopsFDDropdown}>
                              {String(allSynopsFD) === String(true) ? (
                                <span>{i18n.t("All_Selected")}</span>
                              ) : SynopsFrontSection.length !== 0 ? (
                                <span>
                                  {SynopsFrontSection.map((each, ind) => {
                                    if (String(ind) !== String(SynopsFrontSection.length - 1)) {
                                      return <span>{each.Name},</span>;
                                    } else {
                                      return <span>{each.Name}</span>;
                                    }
                                  })}{" "}
                                </span>
                              ) : (
                                <span>
                                  {i18n.t("Select")} {i18n.t("SynOps_Front_Section")}{" "}
                                </span>
                              )}{" "}
                            </div>
                            {isSynopsFDDropdownStatus ? (
                              <div className="featuresearch-list">
                                <ul>
                                  <li>
                                    <label className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        Name="checkAll"
                                        checked={tempallSynopsFD}
                                        onChange={this.handleSynopsFDAll}
                                      />
                                      <span className="checkmark"></span>
                                      <span className="form-check-label">{i18n.t("Select_All")}</span>
                                    </label>
                                  </li>
                                  {tempSynopsFrontSection.map((each, index) => {
                                    return (
                                      <li>
                                        <label className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            key={each.ID}
                                            Name={each.Name}
                                            value={each.Name}
                                            checked={each.isChecked}
                                            onChange={() => {
                                              this.handleSynopsFD(index);
                                            }}
                                          />
                                          <span className="checkmark"></span>
                                          <span className="form-check-label">{each.Name}</span>
                                        </label>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {isSynopsFDDropdownStatus ? (
                            <div className="viewby-dropdown-overlay" onClick={this.isSynopsFDDropdownOverlay}></div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group border-group">
                          <label for="Name" class="form-label">
                            {i18n.t("View_by_Sect")}
                          </label>
                          <div className="featuresearch-dropdown" onClick={this.isViewBySectionDropdown}>
                            <div className="featuresearch-dropdownname">
                              {" "}
                              {String(selectedViewBySection) !== "" ? (
                                <span>{selectedViewBySection}</span>
                              ) : (
                                <span>
                                  {i18n.t("Select")} {i18n.t("View_by_Sect")}
                                </span>
                              )}{" "}
                            </div>
                            {isViewBySectionDropdownStatus ? (
                              <div className="featuresearch-list">
                                <ul>
                                  {ViewbySection.map((each, index) => {
                                    return (
                                      <li
                                        onClick={() => {
                                          this.handleViewBySection(index);
                                        }}
                                      >
                                        {each.Name}
                                      </li>
                                    );
                                  })}
                                  <li></li>
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {isViewBySectionDropdownStatus ? (
                            <div
                              className="viewby-dropdown-overlay"
                              onClick={this.isViewBySectionDropdownOverlay}
                            ></div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="formfield-info">
                      <h3>{i18n.t("Links")}</h3>
                      <div className="form-section">
                        <div className="form-group border-group fileupload-linkicon">
                          <label for="Name" class="form-label">
                            {i18n.t("User_Guide_Link")}
                          </label>
                          <input
                            maxLength="5000"
                            value={userGuideLink}
                            type="text"
                            class="form-control"
                            Name="UserGuide"
                            onChange={(e) => {
                              this.setState({ userGuideLink: e.target.value });
                            }}
                          />
                        </div>
                        <span className="errorMsg">{!_.isEmpty(errors.UserGuide) && errors.UserGuide}</span>

                        <div className="form-group border-group fileupload-linkicon">
                          <label for="Name" class="form-label">
                            {i18n.t("Demo_Link")}
                          </label>
                          <input
                            maxLength="5000"
                            value={demoLink}
                            type="text"
                            class="form-control"
                            Name="DemoLink"
                            onChange={(e) => {
                              this.setState({ demoLink: e.target.value });
                            }}
                          />
                        </div>
                        <span className="errorMsg">{!_.isEmpty(errors.DemoLink) && errors.DemoLink}</span>
                      </div>
                    </div>
                    <div className="addfeature-formbtns">
                      <button
                        type="button"
                        class="btn btn-primary mr-2"
                        disabled={buttonDisable}
                        onClick={this.handleSubmit}
                      >
                        {String(title) === "Add" ? "Add" : "Update"}
                      </button>
                      <button type="button" class="btn btn-default" onClick={this.handleCancel}>
                        {i18n.t("Cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={addfeatureNotificationStatus}
          onHide={this.addfeatureNotificationModalClose}
          className="helpfeatureNotification-modal centered"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t("Notification")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="helpfeaturenotification-body">
              <div className="helpfeaturenotification-icon">
                <span>
                  <img alt="" src={Success} width="40px" height="40px" />
                </span>
              </div>
              <div className="helpfeaturenotification-data">
                <p>
                  {i18n.t("Feature")} {FeatureName} {String(title) === "Add" ? i18n.t("Added_Success") : i18n.t("Updated_Success")}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientData: state.CurrentFilter.ClientData,
  };
};

export default connect(mapStateToProps)(AddHelpFeatures);
