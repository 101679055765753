import React, { Component } from 'react';
import axiosInstance from '../Shared/interceptor';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import './JourneyInitiative.css';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { compose } from 'redux';
import LoadingOverlay from 'react-loading-overlay';

import moment from 'moment';
import Workbook from 'react-excel-workbook';
import i18n from 'i18next';
import { Modal } from 'react-bootstrap';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import { deepCopyData, isEmptyObject } from '../Shared/commonFunctions';
import CustomMultiSelect from '../CommonComponents/CustomMultiSelect';
import DeleteModal from './DeleteModal';
import { trycatchAlertPopup, LocalApiBaseUrl,idTokenstring } from '../Shared/Constant';
import {
  DeleteInitiative,
  InitializeInitiativedatas,
  InitializeIOJData,
  saveJourneyData,
  actionStatus,
  setStatusData,
} from '../actions/JourneyActions';
import SynopsLoader from '../Shared/SynopsLoader';
import EmptyCriteria from '../Images/EmptyCriteria.svg';
import { store } from '../reducers/configureStore';
import Pagination from './Pagination';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;


const singleStageData = {
  Name: '',
  ID: 0,
  checked: true,
  value: '',
};
class Initiative extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modifiedStageData: [],
      staticStageData: [],
      originalStageData: [],
      isAccess: this.props.isAccess,
      isShowInitiativeDeatils: false,
      isdisablemethodcalled: false,
      EachInitiativeDetail: {},
      isImpactTypeDropdownStatus: false,
      isImpactOutcomeDropdownStatus: false,
      isBOOutcomeIndicatorDropdownStatus: false,
      isInitiativePhaseDropdownStatus: false,
      isInitiativeUnitDropdownStatus: false,
      isSynopsLeverDropdownStatus: false,
      isInitiativeStatusDropdownStatus: false,
      isLeversStatusDropdownStatus: false,
      isStatusFilterDropdownStatus: false,
      isStageFilterDropdownStatus: false,
      selectedInitiative: 'All',
      initiativeList: [],
      BoiList: [],
      edit: false,
      configuratorStatus: this.props.configuratorStatus,
      editInitiativeData: {},
      currentPage: 1,
      postsPerPage: 10,
      selectedLevers: [],
      excelData: [],
      initativeDetails: [],
      metricDetails: [],
      items: [],
      templeverData: [
        {
          Name: i18n.t('Diverse Data'),
          checked: true,
          SOCategoryID: 5,
        },
        {
          Name: i18n.t('Insights_Intelligence'),
          SOCategoryID: 4,
          checked: true,
        },
        {
          Name: i18n.t('Human_Machine Talent'),
          checked: true,
          SOCategoryID: 2,
        },
        {
          Name: i18n.t('Work Orchestration'),
          checked: true,
          SOCategoryID: 3,
        },
      ],
      leversData: [
        {
          Name: i18n.t('Diverse Data'),
          checked: true,
          SOCategoryID: 5,
        },
        {
          Name: i18n.t('Insights_Intelligence'),
          SOCategoryID: 4,
          checked: true,
        },
        {
          Name: i18n.t('Human_Machine Talent'),
          checked: true,
          SOCategoryID: 2,
        },
        {
          Name: i18n.t('Work Orchestration'),
          checked: true,
          SOCategoryID: 3,
        },
      ],
      allLever: true,
      tempallLever: true,
      tempStatusData: [],
      statusData: [],
      originalStatusData: [],
      allStatus: true,
      allStage: true,
      tempAllStatus: true,
      tempAllStage: true,
      disableLever: false,
      disableStatus: false,
      disableStage: false,
      UNALTERED_initiativeList: [],
      isApiLoading: false,
      deleteInitiativeStatus: false,
      deleteInitiativeDataID: '',
      showsuccessModal: false,
      exportDisable: false,
    };
    this.inputRef = React.createRef();
    this.GetExceldata = this.GetExceldata.bind(this);
  }

  isImpactTypeDropdown = () => {
    this.setState({ isImpactTypeDropdownStatus: true });
  };

  isImpactTypeDropdownOverlay = () => {
    this.setState({ isImpactTypeDropdownStatus: false });
  };

  onClickFetchImpactTypeInitiative = (selectedData) => (evt) => {
    this.setState({ selectedInitiative: selectedData });
    setTimeout(() => {
      this.isImpactTypeDropdownOverlay();
    }, 100);
  };

  GetExceldata = () => {
    const {
      currentFilter,
      languageData,
      clientData,
      history,
      initiativeStageId,
      isInitativeManagement,
    } = this.props;
    const { statusData, leversData, staticStageData } = this.state;
    const IOJStageIDs = isInitativeManagement
      ? staticStageData.map((a) => a.ID)
      : parseInt(initiativeStageId) === 5 ? [1, 2, 3, 4] : [initiativeStageId]; // IN CASE ALL IS SELECTED THEN IOJ-STAGE ID -1,2,3,4

    const data = {
      OrgEntityID: clientData.id,
      OfferingID:
        currentFilter.ProcessId === 'All' ? 0 : currentFilter.ProcessId,
      LocationID:
        currentFilter.LocationId === 'All' ? 0 : currentFilter.LocationId,
      OMID:
        currentFilter.LocationOMID[0] === 'All'
          ? 0
          : currentFilter.LocationOMID[0],
      LanguageID: languageData.id,
      OfferingName: !currentFilter.OfferingName
        ? 'All'
        : currentFilter.OfferingName,
      IsConfigurator:
        history.location.pathname === '/admin/Configurator',
      SoCategoryIDs: leversData.map((each) => each.SOCategoryID),
      InitiativeStatusID: statusData.map((each) => each.ID),
      IOJStageIDs,
    };
    this.setState({
      exportDisable: true,
    });
    axiosInstance
      .post(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetExportInitiativeList`,
        data,
      )
      .then((response) => {
        const responseData = response.data;
        const data = [...responseData];
        let initativeDetails = [];
        let metricDetails = [];
        const excelData = [];
        const tempinitativeDetails = data[0].InitiativeReportLists;
        const tempMetricDetails = data[1].BOIMetricExcels;
        const singleInitiativeDetail = [{ Message: 'Data Not available' }];

        const singleMetricDetail = [{ Message: 'Data Not available' }];

        initativeDetails = tempinitativeDetails.length === 0
          ? singleInitiativeDetail
          : [...tempinitativeDetails].map((each) => {
            const obj = { ...each };
            obj['Initiative Impact'] = obj['Initiative Impact'] != null
              ? obj['Initiative Impact']
                .map((d) => d.InitiativeImpact)
                .join(', ')
              : obj.InitiativeImpacts;
            obj['Assessment Criteria'] = obj['Assessment Criteria'] != null
              ? obj['Assessment Criteria']
                .map((d) => d.AssessmentCriteria)
                .join(', ')
              : obj.InitiativeAssessments;
            delete obj.IntelOpsInitiativesID;
            delete obj.InitiativeStatusId;
            delete obj.InitiativeSourceId;
            delete obj.SoCategoryID;

            return obj;
          });

        metricDetails = tempMetricDetails.length === 0
          ? singleMetricDetail
          : [...tempMetricDetails].map((each) => {
            const obj = { ...each };
            delete obj.MetricTypeImpactedId;

            return obj;
          });

        excelData.push({
          TabName: responseData[0].TabName,
          Data: initativeDetails,
        });
        excelData.push({
          TabName: responseData[1].TabName,
          Data: metricDetails,
        });

        this.setState(
          {
            excelData,
            initativeDetails,
            metricDetails,
          },
          () => {
            this.inputRef.current.click();
            this.setState({
              exportDisable: false,
            });
          },
        );
      })
      .catch((error) => {
        this.setState({
          exportDisable: false,
        });
        trycatchAlertPopup(error);
      });
  };

  fetchInitiativeStatusDropdownData = () => {
    const { languageData } = this.props;

    return axiosInstance
      .get(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetInitiativeStatusList?LanguageId=${languageData.id}`,
      )
      .then((response) => {
        const data = response.data.map((each) => {
          const obj = {};
          obj.Name = each.StatusName;
          obj.ID = each.ID;
          obj.checked = true;
          obj.value = each.StatusName_En;
          return obj;
        });
        data.push({
          Name: i18n.t('In_Draft'),
          ID: data.length + 1,
          checked: true,
          value: 'In Draft',
        });

        this.props.storeStatusData({ originalStatusData: data });
        this.setState(
          {
            originalStatusData: data,
            tempStatusData: data,
            statusData: data,
          },
          () => {
            this.handleSetStatusFilter();
          },
        );
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  handlePostsPerPage = (data) => {
    this.setState({
      exportDisable: true,
    });
    const stageID = this.props.initiativeStageId;
    axiosInstance
      .post(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetExportInitiativeList?IOJStageID=${stageID}`,
        data,
      )
      .then((response) => {
        const responseData = response.data;
        const data = [...responseData];
        let initativeDetails = [];
        let metricDetails = [];
        const excelData = [];
        const tempinitativeDetails = data[0].InitiativeReportLists;
        const tempMetricDetails = data[1].BOIMetricExcels;
        const singleInitiativeDetail = [{ Message: 'Data Not available' }];

        const singleMetricDetail = [{ Message: 'Data Not available' }];

        initativeDetails = tempinitativeDetails.length === 0
          ? singleInitiativeDetail
          : [...tempinitativeDetails].map((each) => {
            const obj = { ...each };
            obj['Initiative Impact'] = obj['Initiative Impact'] != null
              ? obj['Initiative Impact']
                .map((d) => d.InitiativeImpact)
                .toString()
              : obj.InitiativeImpacts;
            obj['Assessment Criteria'] = obj['Assessment Criteria'] != null
              ? obj['Assessment Criteria']
                .map((d) => d.AssessmentCriteria)
                .toString()
              : obj.InitiativeAssessments;
            delete obj.IntelOpsInitiativesID;
            delete obj.InitiativeStatusId;
            delete obj.InitiativeSourceId;
            delete obj.SoCategoryID;

            return obj;
          });

        metricDetails = tempMetricDetails.length === 0
          ? singleMetricDetail
          : [...tempMetricDetails].map((each) => {
            const obj = { ...each };
            delete obj.MetricTypeImpactedId;

            return obj;
          });

        excelData.push({
          TabName: responseData[0].TabName,
          Data: initativeDetails,
        });
        excelData.push({
          TabName: responseData[1].TabName,
          Data: metricDetails,
        });

        this.setState(
          {
            excelData,
            initativeDetails,
            metricDetails,
          },
          () => {
            this.inputRef.current.click();
            this.setState({
              exportDisable: false,
            });
          },
        );
      })
      .catch((error) => {
        this.setState({
          exportDisable: false,
          postsPerPage: data,
        });
        trycatchAlertPopup(error);
      });
  };

  handleCurrentPage = (data) => {
    this.setState({
      currentPage: data,
    });
  };

  handleAddEditPopup = (message) => {
    this.setState({
      message,
      showNotificationModal: true,
    });
  };

  closeNotificationModal = () => {
    this.setState(
      {
        showNotificationModal: false,
        message: '',
      },
      () => {
        this.handleResetFilters();
      },
    );
  };

  componentWillUnmount() {
    const { isInitativeManagement, Datas } = this.props;
    let { viewData } = Datas;
    viewData = viewData && !isEmptyObject(viewData) ? viewData : {};
    if (isInitativeManagement) {
      this.storeAllData(viewData);
    }
  }

  componentDidMount() {
    const {
      IOJGlidePathAllModels, isInitativeManagement, Datas, currentFilter, initiativeStageId, languageData, clientData, configuratorStatus,
    } = this.props;
    const { filterDetails } = Datas;
    if (
      isInitativeManagement
      && filterDetails
      && !isEmptyObject(filterDetails)
    ) {
      this.setStoreDataToFilter(Datas);
    } else {
      this.fetchInitiativeStatusDropdownData();
      this.setState({
        isAccess: this.props.Datas.isAccess,
      });
      const initiativeData = !_.isNil(this.props.initiativeStage)
        ? String(this.props.initiativeStage) === 'All'
          ? this.props.allInitiativeData
          : this.props.allInitiativeData.filter(
            (each) => String(each.StageName_En) === String(this.props.initiativeStage),
          )
        : this.props.allInitiativeData.filter(
          (each) => String(each.StageName_En)
            === String(this.props.datas.viewData.MaturityPhase_En),
        );
      let modifiedStageData = [];
      if (
        IOJGlidePathAllModels !== undefined
        && IOJGlidePathAllModels.length > 0
      ) {
        modifiedStageData = _.map(IOJGlidePathAllModels, (eachItem) => {
          const StageData = {
            ..._.cloneDeep(singleStageData),
            Name: eachItem.IOJStageName,
            ID: eachItem.IOJStageID,
            checked: true,
            value: eachItem.IOJStageName_En,
          };
          return StageData;
        });
      }

      modifiedStageData = modifiedStageData.filter((e) => e.Name !== 'All');
      this.disableAddInitiativeButton(modifiedStageData);
      this.getAllInitiative();

      this.setState({
        // addInitiativeFormStatus: this.props.addFormFunctionality,
        modifiedStageData,
        staticStageData: modifiedStageData,
        originalStageData: modifiedStageData,
        UNALTERED_initiativeList: initiativeData,
        configuratorStatus: this.props.configuratorStatus,

      });
    }
  }

  setStoreDataToFilter = (Datas) => {
    const { filterDetails } = Datas;
    if (filterDetails && !isEmptyObject(filterDetails)) {
      const { leverFilter } = filterDetails;
      const { statusFilter } = filterDetails;
      const { stageFilter } = filterDetails;
      const { initiativeMangementInitiativesModified } = Datas;
      const { initiativeMangementInitiativesStatic } = Datas;
      this.disableAddInitiativeButton(stageFilter.modifiedStageData);
      this.setState({
        allLever: leverFilter.allLever,
        leversData: leverFilter.leversData,
        tempallLever: leverFilter.tempallLever,
        templeverData: leverFilter.templeverData,
        allStatus: statusFilter.allStatus,
        statusData: statusFilter.statusData,
        tempAllStatus: statusFilter.tempAllStatus,
        tempStatusData: statusFilter.tempStatusData,
        allStage: stageFilter.allStage,
        modifiedStageData: stageFilter.modifiedStageData,
        staticStageData: stageFilter.staticStageData,
        tempAllStage: stageFilter.tempAllStage,
        initiativeList: initiativeMangementInitiativesModified,
        UNALTERED_initiativeList: initiativeMangementInitiativesStatic,
        configuratorStatus: this.props.configuratorStatus,
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isdisablemethodcalled } = this.state;
    if (!isdisablemethodcalled) {
      const { Datas, isInitativeManagement } = nextProps;
      this.setState({
        isAccess: Datas.isAccess,
      });
      if (this.props.currentFilter !== nextProps.currentFilter) {
        this.setFilterToInitialState();
      }

      /* if(isInitativeManagement && this.props.currentFilter===nextProps.currentFilter){
this.setStoreDataToFilter(Datas);
} */
      let initiativeData = [];
      let staticinitiativeData = [];
      const { filterDetails } = Datas;
      if (
        isInitativeManagement
        && filterDetails
        && !isEmptyObject(filterDetails)
        && this.props.currentFilter === nextProps.currentFilter
      ) {
        initiativeData = Datas.initiativeMangementInitiativesModified;
        staticinitiativeData = Datas.initiativeMangementInitiativesStatic;
      } else {
        initiativeData = !_.isNil(nextProps.initiativeStage)
          ? nextProps.initiativeStage === 'All'
            ? nextProps.allInitiativeData
            : nextProps.allInitiativeData.filter(
              (each) => String(each.StageName_En)
                === String(nextProps.initiativeStage),
            )
          : nextProps.allInitiativeData.filter(
            (each) => String(each.StageName_En)
              === String(nextProps.datas.viewData.MaturityPhase_En),
          );
        staticinitiativeData = initiativeData;
      }

      let currentPage = 1;
      if (initiativeData.length >= this.props.allInitiativeData.length) {
        currentPage = this.state.currentPage;
      } else if (
        !Number.isInteger(initiativeData.length / this.state.postsPerPage)
      ) {
        currentPage = this.state.currentPage;
      } else {
        currentPage = initiativeData.length / this.state.postsPerPage !== 0
          ? initiativeData.length / this.state.postsPerPage
          : 1;
      }
      if (nextProps.filterChanged) {
        currentPage = 1;
      }
      this.setState(
        {
          UNALTERED_initiativeList: staticinitiativeData,
          initiativeList: initiativeData,
          configuratorStatus: this.props.configuratorStatus,
          currentPage,
        },
        () => {
          if (!isInitativeManagement) this.handleSetStatusFilter();
        },
      );
      if (
        !isInitativeManagement
        && String(this.props.initiativeStageId)
        !== String(nextProps.initiativeStageId)
      ) {
        this.setFilterToInitialState();
      }
    }
  }

  setFilterToInitialState = () => {
    // const{originalStatusData,originalStageData}=this.state;
    const { IOJGlidePathAllModels, Datas } = this.props;
    const { originalStatusData } = Datas;
    let modifiedStageData = [];
    if (
      IOJGlidePathAllModels !== undefined
      && IOJGlidePathAllModels.length > 0
    ) {
      modifiedStageData = _.map(IOJGlidePathAllModels, (eachItem) => {
        const StageData = {
          ..._.cloneDeep(singleStageData),
          Name: eachItem.IOJStageName,
          ID: eachItem.IOJStageID,
          checked: true,
          value: eachItem.IOJStageName_En,
        };
        return StageData;
      });
    }

    this.disableAddInitiativeButton(modifiedStageData);

    const leverdata = [
      {
        Name: i18n.t('Diverse Data'),
        checked: true,
        SOCategoryID: 5,
      },
      {
        Name: i18n.t('Insights_Intelligence'),
        SOCategoryID: 4,
        checked: true,
      },
      {
        Name: i18n.t('Human_Machine Talent'),
        checked: true,
        SOCategoryID: 2,
      },
      {
        Name: i18n.t('Work Orchestration'),
        checked: true,
        SOCategoryID: 3,
      },
    ];
    this.setState({
      tempStatusData: originalStatusData,
      statusData: originalStatusData,
      leversData: leverdata,
      templeverData: leverdata,
      allLever: true,
      tempallLever: true,
      allStatus: true,
      tempAllStatus: true,
      staticStageData: modifiedStageData,
      modifiedStageData,
      tempAllStage: true,
      allStage: true,
    });
  };

  getJourneyInitiativeContainerData = () => {
    const {
      currentFilter, languageData, clientData, history,
    } = this.props;
    this.setState({ isApiLoading: true });
    const data = {
      OrgEntityID: clientData.id,
      OfferingID:
        currentFilter.ProcessId === 'All' ? 0 : currentFilter.ProcessId,
      LocationID:
        currentFilter.LocationId === 'All' ? 0 : currentFilter.LocationId,
      OMID:
        currentFilter.LocationOMID[0] === 'All'
          ? 0
          : currentFilter.LocationOMID[0],
      LanguageID: languageData.id,
      OfferingName: !currentFilter.OfferingName
        ? 'All'
        : currentFilter.OfferingName,
      IsConfigurator:
        history.location.pathname === '/admin/Configurator',
    };

    store.dispatch(
      saveJourneyData({
        ConfiguratorID: '',
        IOJGlidePathAllModels: [],
        IsIOJCategory: false,
      }),
    );
    axiosInstance
      .post(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetNewAllGlidePathData`,
        data,
      )
      .then((response) => {
        if (response.data) {
          store.dispatch(saveJourneyData(response.data));
          store.dispatch(
            InitializeIOJData(response.data.IOJGlidePathAllModels),
          );
        }
        this.setState({ isApiLoading: false });
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });
  };

  editIoj(type, id, maturity, status, IOJStageID) {
    this.setState(
      {
        edit: true,
        id,
        type,
        maturity,
        isApiLoading: true,
      },
      () => {
        this.props.onEditInitiative({
          formMode: 'edit',
          initiativeId: id,
          formType: status === 'In Draft' ? 'draftEdit' : 'nonDraftEdit',
          stageName: maturity,
          IOJStageID,
        });
      },
    );
  }

  getAllInitiative = () => {
    const {
      currentFilter, initiativeStageId, clientData, configuratorStatus, languageData,
    } = this.props;

    if (!this.state.isApiLoading) {
      this.setState({
        isApiLoading: true,
      });
    }

    axiosInstance
      .post(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetExpandedViewData?IOJStageID=${initiativeStageId}&LanguageId=${languageData.id}`,
        {
          OrgEntityID: clientData.id,
          OfferingID: currentFilter.ProcessId === 'All' ? 0 : currentFilter.ProcessId,
          LocationID: currentFilter.LocationId === 'All' ? 0 : currentFilter.LocationId,
          OMID: currentFilter.LocationOMID[0] === 'All' ? 0 : currentFilter.LocationOMID[0],
          OfferingName: currentFilter.OfferingName ? currentFilter.OfferingName : 'All',
          IsConfigurator: configuratorStatus,
        },
      )
      .then((response) => {
        this.setState({
          isApiLoading: false,
          initiativeList: response.data,
          UNALTERED_initiativeList: response.data,
        });
      }).catch((error) => {
        this.setState({
          isApiLoading: false,
        });

        trycatchAlertPopup(error);
      });
  }

  deleteIoj = (Id) => {
    this.setState({
      deleteInitiativeDataID: Id,
      deleteInitiativeStatus: true,
    });
  };

  GetIOJQcscore = () => {
    axiosInstance
      .get(
        `${LocalApiBaseUrl}Configurator/GetIntelligentOperationsJourneyQualityScore`,
        {
          params: {
            LocationID: this.props.filterDetails.Locations,
            OrgEntityID: this.props.filterDetails.Client,
            OfferingID: this.props.filterDetails.process,
            OMID:
              this.props.filterDetails.LocationOMID.length === 0
                || this.props.filterDetails.LocationOMID[0] === 'All'
                ? 0
                : this.props.filterDetails.LocationOMID[0],
            LanguageID: store.getState().CurrentFilter.languageData.id,
          },
        },
      )
      .then((response) => {
        if (response.data) {
          store.dispatch({
            type: 'SAVE_QC_SCORE_OF_EACH_TAB',
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  deleteIOJApiCall = () => {
    this.props.storeActionStatus({ isAction: true });
    this.deleteItemModalClose();
    const { deleteInitiativeDataID } = this.state;
    const { isInitativeManagement } = this.props;
    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .delete(
        `${LocalApiBaseUrl}IOJJourneyInitiative/DeleteInitiatives?ID=${deleteInitiativeDataID}`,
      )
      .then((response) => {
        this.setState({
          isApiLoading: false,
        });
        if (isInitativeManagement) {
          this.setFilterToInitialState();
        }

        this.GetIOJQcscore();

        this.props.settingfilterChangedState();
        this.props.fetchJourneyInitiativeData();
        this.getJourneyInitiativeContainerData();

        this.props.deleteInitiative(deleteInitiativeDataID);
        this.setState(
          {
            showsuccessModal: true,
          },
          () => {
            this.handleResetFilters();
          },
        );

        // Make The get api call or refresh the state for the updated initiatives.
      });
  };

  handleResetFilters = () => {
    const data = this.state;
    this.setState({
      templeverData: [
        {
          Name: i18n.t('Diverse Data'),
          checked: true,
          SOCategoryID: 5,
        },
        {
          Name: i18n.t('Insights_Intelligence'),
          SOCategoryID: 4,
          checked: true,
        },
        {
          Name: i18n.t('Human_Machine Talent'),
          checked: true,
          SOCategoryID: 2,
        },
        {
          Name: i18n.t('Work Orchestration'),
          checked: true,
          SOCategoryID: 3,
        },
      ],
      leversData: [
        {
          Name: i18n.t('Diverse Data'),
          checked: true,
          SOCategoryID: 5,
        },
        {
          Name: i18n.t('Insights_Intelligence'),
          SOCategoryID: 4,
          checked: true,
        },
        {
          Name: i18n.t('Human_Machine Talent'),
          checked: true,
          SOCategoryID: 2,
        },
        {
          Name: i18n.t('Work Orchestration'),
          checked: true,
          SOCategoryID: 3,
        },
      ],
      allLever: true,
      tempallLever: true,
      tempStatusData: this.state.originalStatusData,
      statusData: this.state.originalStatusData,
      allStatus: true,
      tempAllStatus: true,
      initiativeList: [...data.UNALTERED_initiativeList],
    });
  };

  handleInitiativeEdit = (data) => {
    this.editIoj(
      data.ProjectInitiativeName,
      data.IntelOpsInitiativesID,
      data.StageName_En,
      data.InitiativeStatus_En,
      data.IOJStageID,

    );
  };

  storeAllData = (data) => {
    const { isInitativeManagement } = this.props;
    const {
      allLever,
      leversData,
      tempallLever,
      templeverData,
      allStatus,
      statusData,
      tempAllStatus,
      tempStatusData,
      allStage,
      modifiedStageData,
      tempAllStage,
      staticStageData,
      UNALTERED_initiativeList,
      initiativeList,
      originalStatusData,
    } = this.state;
    const filterDetails = {
      leverFilter: {
        allLever,
        leversData: deepCopyData(leversData),
        tempallLever,
        templeverData: deepCopyData(templeverData),
      },
      statusFilter: {
        allStatus,
        statusData: deepCopyData(statusData),
        tempAllStatus,
        tempStatusData: deepCopyData(tempStatusData),
      },
      stageFilter: {
        allStage,
        staticStageData: deepCopyData(staticStageData),
        modifiedStageData: deepCopyData(modifiedStageData),
        tempAllStage,
      },
    };
    const Datas = {
      viewData: data,
      StageData: UNALTERED_initiativeList,
      initiativeMangementInitiativesModified: isInitativeManagement
        ? initiativeList
        : [],
      initiativeMangementInitiativesStatic: isInitativeManagement
        ? UNALTERED_initiativeList
        : [],
      isInitativeManagement,
      filterDetails: isInitativeManagement ? filterDetails : {}, // Tab: "IOJMaturityRoadmap"
      isDisableAdd: modifiedStageData.filter((a) => a.checked).length !== 1,
      originalStatusData: isInitativeManagement ? originalStatusData : [],
    };
    store.dispatch(InitializeInitiativedatas(Datas));
  };

  ShowInitativeDetails = (e, data) => {
    this.storeAllData(data);
    this.props.ShowInitativeDetails();
  };

  isLeverDropDown = (e) => {
    const data = this.state;
    data.tempallLever = data.allLever;
    const temp = [...data.templeverData].map((each) => {
      if (
        [...data.leversData].filter(
          (i) => String(i.SOCategoryID) === String(each.SOCategoryID),
        ).length > 0
      ) {
        each.checked = true;
      } else {
        each.checked = false;
      }
      return each;
    });
    const disableLever = false;

    this.setState({
      isLeversStatusDropdownStatus: true,
      disableLever,
      tempallLever: data.tempallLever,
      templeverData: temp,
    });
  };

  isLeversStatusDropdownOverlay = () => {
    this.setState({
      isLeversStatusDropdownStatus: false,
      isdisablemethodcalled: false,
    });
  };

  handleAllLever = (e) => {
    e.stopPropagation();

    const data = this.state;
    data.tempallLever = !data.tempallLever;
    let disableLever = false;
    if (data.tempallLever) {
      data.templeverData = [...data.templeverData].map((each) => ({
        ...each,
        checked: true,
      }));
    } else {
      data.templeverData = [...data.templeverData].map((each) => ({
        ...each,
        checked: false,
      }));
      disableLever = true;
    }

    this.setState(
      {
        tempallLever: data.tempallLever,
        templeverData: data.templeverData,
        disableLever,
      },
      () => {
        this.handleLeverApply();
      },
    );
  };

  handleLeverChange = (e, index) => {
    e.stopPropagation();

    const data = this.state;
    data.templeverData[index].checked = !data.templeverData[index].checked;
    const activeData = [...data.templeverData].filter(
      (each) => each.checked === true,
    );
    const inactiveData = [...data.templeverData].filter(
      (each) => each.checked === false,
    );
    let disableLever = false;
    if (activeData.length === data.templeverData.length) {
      data.tempallLever = true;
    } else {
      data.tempallLever = false;
    }
    if (
      inactiveData.length === data.templeverData.length
      && data.tempallLever === false
    ) {
      disableLever = true;
    }
    this.setState(
      {
        templeverData: data.templeverData,
        tempallLever: data.tempallLever,
        disableLever,
      },
      () => {
        this.handleLeverApply();
      },
    );
  };

  handleLeverApply = () => {
    const data = this.state;
    data.leversData = [
      ...data.templeverData.filter((each) => each.checked === true),
    ];
    data.allLever = data.tempallLever;

    // Reseting Filters
    if (data.tempallLever === true && data.allStatus === true) {
      this.handleResetInitiativeList();
    }

    this.setState(
      {
        leversData: data.leversData,
        allLever: data.allLever,
        disableLever: false,
        currentPage: 1,
      },
      () => {
        this.handleSetLeverFilter();
      },
    );
  };

  handleLeverClear = () => {
    const data = this.state;
    data.templeverData = [...data.templeverData].map((each) => ({
      ...each,
      checked: false,
    }));
    data.tempallLever = false;
    this.setState({
      tempallLever: data.tempallLever,
      templeverData: data.templeverData,
      disableLever: true,
    });
  };

  /* Stage Changes */
  handleStageDropDown = () => {
    const data = this.state;
    data.tempAllStage = data.allStage;
    const temp = [...data.modifiedStageData].map((each) => {
      if (
        [...data.staticStageData].filter((i) => i.value === each.value).length
        > 0
      ) {
        each.checked = true;
      } else {
        each.checked = false;
      }
      return each;
    });
    const disableStage = false;

    this.setState({
      isStageFilterDropdownStatus: true,
      disableStage,
      modifiedStageData: temp,
      tempAllStage: data.tempAllStage,
    });
  };

  handleStageChange = (e, index) => {
    const data = this.state;
    data.modifiedStageData[index].checked = !data.modifiedStageData[index]
      .checked;
    const activeData = data.modifiedStageData.filter((each) => each.checked);
    const inactiveData = data.modifiedStageData.filter((each) => !each.checked);
    let disableStage = false;
    if (activeData.length === data.modifiedStageData.length) {
      data.tempAllStage = true;
    } else {
      data.tempAllStage = false;
    }
    if (
      inactiveData.length === data.modifiedStageData.length
      && data.tempAllStage === false
    ) {
      disableStage = true;
    }
    // this.getAllInitiative()
    this.setState(
      {
        modifiedStageData: data.modifiedStageData,
        tempAllStage: data.tempAllStage,
        disableStage,
        isdisablemethodcalled: true,
      },
      () => {
        this.disableAddInitiativeButton(data.modifiedStageData);
        this.handleStageApply();
      },
    );
  };

  disableAddInitiativeButton = (stageData) => {
    const { disableAddInitiative } = this.props;
    if (stageData !== undefined && stageData.length > 0) {
      const isDisableAdd = stageData.filter((a) => a.checked).length !== 1;
      disableAddInitiative({
        isDisable: isDisableAdd,
        StageData: !isDisableAdd ? stageData : [],
      });
    }
  };

  handleStageAll = (e) => {
    const data = this.state;
    data.tempAllStage = !data.tempAllStage;
    let disableStage = false;
    if (data.tempAllStage) {
      data.modifiedStageData = [...data.modifiedStageData].map((each) => ({
        ...each,
        checked: true,
      }));
    } else {
      data.modifiedStageData = [...data.modifiedStageData].map((each) => ({
        ...each,
        checked: false,
      }));
      disableStage = true;
    }

    this.setState(
      {
        tempAllStage: data.tempAllStage,
        modifiedStageData: data.modifiedStageData,
        disableStage,
        isdisablemethodcalled: true,
      },
      () => {
        this.disableAddInitiativeButton(data.modifiedStageData);
        this.handleStageApply();
      },
    );
  };

  handleStageApply = () => {
    const data = this.state;
    data.staticStageData = [
      ...data.modifiedStageData.filter((each) => each.checked),
    ];
    data.allStage = data.tempAllStage;

    // Resetting Filters
    if (data.allStage === true && data.tempAllStage) {
      this.handleResetInitiativeList();
    }

    this.setState(
      {
        statusData: data.statusData,
        allStatus: data.allStatus,
        disableStatus: false,
        currentPage: 1,
      },
      () => {
        this.handleSetStageFilter();
        // this.handleStatusOverlay();
      },
    );
  };

  commonSetStageFilter = (initiativeData) => {
    const data = this.state;
    const selected = data.modifiedStageData;
    let filteredData = [];
    if (!data.allStage && selected.length !== 0) {
      initiativeData.map((each) => {
        if (
          selected
            .filter((a) => a.checked)
            .map((a) => a.ID)
            .includes(each.IOJStageID)
        ) {
          filteredData.push(each);
        }
        return each;
      });
    } else {
      filteredData = [...initiativeData];
    }
    return filteredData;
  };

  commonSetStatusFilter = (initiativeData) => {
    const data = this.state;
    const selected = data.statusData;
    let filteredData = [];
    if (!data.allStatus && selected.length !== 0) {
      initiativeData.map((each) => {
        if (
          selected.filter(
            (i) => i.value.toLowerCase()
              === each.InitiativeStatus_En.toString().toLowerCase(),
          ).length > 0
        ) {
          filteredData.push(each);
        }
      });
    } else {
      filteredData = [...initiativeData];
    }
    return filteredData;
  };

  handleSetStageFilter = () => {
    const data = this.state;
    const temp = [...data.UNALTERED_initiativeList];
    const selected = data.modifiedStageData;
    let findData = [];
    if (!data.allStage && selected.length !== 0) {
      temp.map((each) => {
        if (
          selected
            .filter((a) => a.checked)
            .map((a) => a.ID)
            .includes(each.IOJStageID)
        ) {
          findData.push(each);
        }
        return each;
      });
    } else if (!selected.length) {
      findData = [];
    } else {
      findData = [...temp];
    }
    findData = this.commonSetStatusFilter(findData);
    this.setState(
      {
        initiativeList: findData,
      },
      () => {
        if (!data.allLever && data.leversData.length !== 0) {
          this.handleResetSetLeverFilter();
        }
      },
    );
  };

  /* Stage Changes end */

  handleStatusDropDown = () => {
    const data = this.state;
    data.tempAllStatus = data.allStatus;
    const temp = [...data.tempStatusData].map((each) => {
      if (
        [...data.statusData].filter(
          (i) => String(i.value) === String(each.value),
        ).length > 0
      ) {
        each.checked = true;
      } else {
        each.checked = false;
      }
      return each;
    });
    const disableStatus = false;

    this.setState({
      isStatusFilterDropdownStatus: true,
      disableStatus,
      tempStatusData: temp,
      tempAllStatus: data.tempAllStatus,
    });
  };

  handleStatusChange = (e, index) => {
    const data = this.state;
    data.tempStatusData[index].checked = !data.tempStatusData[index].checked;
    const activeData = data.tempStatusData.filter(
      (each) => each.checked === true,
    );
    const inactiveData = data.tempStatusData.filter(
      (each) => each.checked === false,
    );
    let disableStatus = false;
    if (activeData.length === data.tempStatusData.length) {
      data.tempAllStatus = true;
    } else {
      data.tempAllStatus = false;
    }
    if (
      inactiveData.length === data.tempStatusData.length
      && data.tempAllStatus === false
    ) {
      disableStatus = true;
    }

    this.setState(
      {
        tempStatusData: data.tempStatusData,
        tempAllStatus: data.tempAllStatus,
        disableStatus,
      },
      () => {
        this.handleStatusApply();
      },
    );
  };

  handleStatusAll = (e) => {
    if (e.target.checked) {
      this.getAllInitiative();
    }
    const data = this.state;
    data.tempAllStatus = !data.tempAllStatus;
    let disableStatus = false;
    if (data.tempAllStatus) {
      data.tempStatusData = [...data.tempStatusData].map((each) => ({
        ...each,
        checked: true,
      }));
    } else {
      data.tempStatusData = [...data.tempStatusData].map((each) => ({
        ...each,
        checked: false,
      }));
      disableStatus = true;
    }

    this.setState(
      {
        tempAllStatus: data.tempAllStatus,
        tempStatusData: data.tempStatusData,
        disableStatus,
      },
      () => {
        this.handleStatusApply();
      },
    );
  };

  handleStatusApply = () => {
    const data = this.state;
    data.statusData = [
      ...data.tempStatusData.filter((each) => each.checked === true),
    ];
    data.allStatus = data.tempAllStatus;

    // Resetting Filters
    if (data.allLever === true && data.tempAllStatus === true) {
      this.handleResetInitiativeList();
    }

    this.setState(
      {
        statusData: data.statusData,
        allStatus: data.allStatus,
        disableStatus: false,
        currentPage: 1,
      },
      () => {
        this.handleSetStatusFilter();
        // this.handleStatusOverlay();
      },
    );
  };

  handleSetStatusFilter = () => {
    const data = this.state;
    const temp = [...data.UNALTERED_initiativeList];
    const selected = data.statusData;
    let findData = [];
    if (data.allStatus === false && selected.length !== 0) {
      temp.map((each) => {
        if (
          selected.filter(
            (i) => i.value.toLowerCase()
              == each.InitiativeStatus_En.toString().toLowerCase(),
          ).length > 0
        ) {
          findData.push(each);
        }
      });
    } else if (!selected.length) {
      findData = [];
    } else {
      findData = [...temp];
    }
    findData = this.commonSetStageFilter(findData);
    this.setState(
      {
        initiativeList: findData,
      },
      () => {
        if (data.allLever === false && data.leversData.length !== 0) {
          this.handleResetSetLeverFilter();
        }
      },
    );
  };

  handleStatusClear = () => {
    const data = this.state;
    data.tempStatusData = [...data.tempStatusData].map((each) => ({
      ...each,
      checked: false,
    }));
    data.tempAllStatus = false;

    this.setState({
      tempStatusData: data.tempStatusData,
      tempAllStatus: data.tempAllStatus,
      disableStatus: true,
    });
  };

  handleSetLeverFilter = () => {
    const data = this.state;
    const temp = [...data.UNALTERED_initiativeList];
    const selected = data.leversData;
    let findData = [];
    if (data.allLever === false && selected.length !== 0) {
      temp.map((each) => {
        if (
          selected.filter(
            (i) => String(i.SOCategoryID) === String(each.SOCategoryID),
          ).length > 0
        ) {
          findData.push(each);
        }
      });
    } else if (!selected.length) {
      findData = [];
    } else {
      findData = [...temp];
    }
    findData = this.commonSetStageFilter(findData);
    this.setState(
      {
        initiativeList: findData,
      },
      () => {
        if (data.allStatus === false && data.statusData.length !== 0) {
          this.handleResetSetStatusFilter();
        }
      },
    );
  };

  /// /////////////////////////////////
  handleResetSetLeverFilter = () => {
    const data = this.state;
    const temp = [...data.initiativeList];
    const selected = data.leversData;
    if (data.allLever === false && selected.length !== 0) {
      const findData = [];
      temp.map((each) => {
        if (
          selected.filter((i) => i.SOCategoryID === each.SOCategoryID).length
          > 0
        ) {
          findData.push(each);
        }
      });
      this.setState(
        {
          initiativeList: findData,
        },
        () => { },
      );
    }
  };

  handleResetSetStatusFilter = () => {
    const data = this.state;
    const temp = [...data.initiativeList];
    const selected = data.statusData;
    if (data.allStatus === false && selected.length !== 0) {
      const findData = [];
      temp.map((each) => {
        if (
          selected.filter(
            (i) => i.value.toLowerCase()
              == each.InitiativeStatus_En.toString().toLowerCase(),
          ).length > 0
        ) {
          findData.push(each);
        }
      });
      this.setState(
        {
          initiativeList: findData,
        },
        () => { },
      );
    } else if (!selected.length) {
      this.setState(
        {
          initiativeList: [],
        },
        () => { },
      );
    }
  };

  handleResetInitiativeList = () => new Promise((resolve, reject) => {
    const data = this.state;
    const temp = [...data.UNALTERED_initiativeList];
    this.setState(
      {
        initiativeList: temp,
      },
      () => {
        resolve();
      },
    );
  });

  handleStatusOverlay = () => {
    this.setState({
      isStatusFilterDropdownStatus: false,
      isdisablemethodcalled: false,
    });
  };

  handleStageOverlay = () => {
    this.setState({
      isStageFilterDropdownStatus: false,
      isdisablemethodcalled: false,
    });
  };

  handlehidden = (data) => {
    const ID = data.IntelOpsInitiativesID;
    this.setState({
      isApiLoading: true,
    });

    axiosInstance
      .post(
        `${LocalApiBaseUrl}IOJJourneyInitiative/ShowHideInitiatives?ID=${ID}`,
        {},
      )
      .then((response) => {
        this.setState(
          {
            isApiLoading: false,
          },
          () => { },
        );

        this.GetIOJQcscore();
        this.props.settingfilterChangedState();
        this.props.fetchJourneyInitiativeData();
        this.getJourneyInitiativeContainerData();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  changeSequenceOnDrop = (initiative, target) => {
    // UpdateInitiativeSequence?InitiativeID={id}&JourneyID={id}&IsConfigurator=true&TargetSequence=1
    axiosInstance
      .post(
        `${LocalApiBaseUrl}IOJJourneyInitiative/UpdateInitiativeSequence?InitiativeID=${initiative.IntelOpsInitiativesID}&JourneyID=${initiative.IntelOpsJourneyID}&IsConfigurator=${true}&TargetSequence=${target}`,

      )
      .then((response) => {

      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  deleteItemModalClose = () => {
    this.setState({ deleteInitiativeStatus: false });
  };

  successRemovalModalClose = () => {
    this.setState({ showsuccessModal: false });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const currentPage = (this.state.currentPage - 1) * this.state.postsPerPage;
    oldIndex += currentPage;
    newIndex += currentPage;
    const initiative = this.state.initiativeList[oldIndex];
    const newInitiativeList = arrayMove(this.state.initiativeList, oldIndex, newIndex);
    newInitiativeList.map((each, index) => each.Sequence = index + 1);
    this.setState({
      initiativeList: newInitiativeList,
    });

    this.changeSequenceOnDrop(initiative, newIndex + 1);
  };

  render() {
    const {
      isAccess,
      initiativeList,
      configuratorStatus,
      currentPage,
      postsPerPage,
      allLever,
      leversData,
      templeverData,
      tempallLever,
      tempStatusData,
      statusData,
      allStatus,
      allStage,
      tempAllStatus,
      tempAllStage,
      excelData,
      UNALTERED_initiativeList,
      isApiLoading,
      deleteInitiativeStatus,
      showsuccessModal,
      exportDisable,
      modifiedStageData,
      staticStageData,
    } = this.state;
    const { OMIDEnabled, isInitativeManagement } = this.props;
    const indexOfLastPosts = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPosts - postsPerPage;
    const currentPosts = initiativeList.slice(
      indexOfFirstPost,
      indexOfLastPosts,
    );

    const currentdate = new Date();
    const formatDate = moment(currentdate).format('YYYYMMDD');
    const formatTime = moment(currentdate).format('HHmmss');
    const formatSheetName = `InitiativeList_${formatDate}_${formatTime}`;

    const SortableContainer = sortableContainer(({ children }) => <div className="drag-drop-body">{children}</div>);

    const SortableItem = sortableElement(({ value }) => {
      let status_color = '';
      switch (value.InitiativeStatus_En) {
        case 'In concept':
          status_color = 'inconcept-color';
          break;
        case 'In flight':
          status_color = 'inflight-color';
          break;
        case 'Delivered':
          status_color = 'delivered-color';
          break;
        case 'On Hold':
          status_color = 'onhold-color';
          break;
        case 'Rejected':
          status_color = 'rejected-color';
          break;
        case 'Cancelled':
          status_color = 'cancelled-color';
          break;
        case 'In Draft':
          status_color = 'indraft-color';
          break;
      }

      return (

        <>
          <div className={value.IsHidden ? 'drag-drop-columns hidden-row' : 'drag-drop-columns'}>
            <DragHandle />
            <div className={'columns-data ' + `${value.RAGStatus.toLowerCase()}` + 'Color'}>
             { value.ReasonForRAG && <div className='ragTooltip'  data-tip data-for={`rag-tooltip${value.IntelOpsInitiativesID}`}>
              <ReactTooltip            
                  id={`rag-tooltip${value.IntelOpsInitiativesID}`}
                  effect="solid"
                  place="bottom"
                  multiline={true}
                  className="RAGtooltip"
                >
                <span> {value.ReasonForRAG}</span>
              </ReactTooltip>
                </div>
    }

              <span className="name-highlighted" data-tip data-for={`name-tooltip${value.IntelOpsInitiativesID}`} onClick={(e) => { this.ShowInitativeDetails(e, value); }}>
                {value.ProjectInitiativeName.length >24 ?value.ProjectInitiativeName.slice(0,24)+"...":value.ProjectInitiativeName }
              </span>
              { value.ProjectInitiativeName.length>24 &&
                <ReactTooltip            
                  id={`name-tooltip${value.IntelOpsInitiativesID}`}
                  effect="solid"
                  place="bottom"
                >
                <span> {value.ProjectInitiativeName}</span>
              </ReactTooltip>
              }
              </div>
            <div className="columns-data">
              <span>
                {i18n.t(
                  value.InitiativeImpacts != null
                    ? value.InitiativeImpacts.map(
                      ({ ImpactName }) => ImpactName,
                    ).join(', ')
                    : '',
                )}
              </span>
            </div>
            <div className="columns-data">
              <span>
                {value.InitiativeOutcomeOption != null
                  ? value.InitiativeOutcomeOption.length > 30
                    ? `${value.InitiativeOutcomeOption.slice(0, 26)}...`
                    : value.InitiativeOutcomeOption
                  : ''}
              </span>
            </div>
            <div className="columns-data">
              <span className={`draft-status ${status_color}`}>
                {value.InitiativeStatus}
              </span>
            </div>
            <div className="columns-data"><span>{value.SOCategoryName}</span></div>
            {isAccess && configuratorStatus
              ? (
                <div className="common-ellipsis-dropdown">
                  <div className="dropdown">
                    <span type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" />

                    <div className="dropdown-menu dropdown-menu-right">

                      {OMIDEnabled
                        ? (
                          <>
                            <a className="dropdown-item" href="javascript:void(0);" onClick={() => { this.handleInitiativeEdit(value); }}>
                              <span className="edit-icon" />
                              Edit
                            </a>
                            <a className="dropdown-item" href="javascript:void(0);" onClick={() => { this.deleteIoj(value.IntelOpsInitiativesID); }}>
                              <span className="delete-icon" />
                              Delete
                            </a>
                            <a className="dropdown-item" href="javascript:void(0);" onClick={() => { value.InitiativeStatus_En !== 'In Draft' && this.handlehidden(value); }}>
                              <span className={value.IsHidden ? 'eye-icon' : 'eye-icon-slash'} />
                              {value.IsHidden ? 'Show' : 'Hide'}
                            </a>
                          </>
                        )
                        : (
                          <>
                            <a className="dropdown-item disabled" href="javascript:void(0);">
                              <span className="edit-icon" />
                              Edit
                            </a>
                            <a className="dropdown-item disabled" href="javascript:void(0);">
                              <span className="delete-icon" />
                              Delete
                            </a>
                            <a className="dropdown-item disabled" href="javascript:void(0);">
                              <span className="eye-icon" />
                              Show
                            </a>
                          </>
                        )}

                    </div>

                  </div>
                </div>
              )
              : null}
          </div>
        </>
      );
    });
    const DragHandle = sortableHandle(() => (
      <span className="dragg-icon">
        {isAccess && configuratorStatus && !isInitativeManagement
          ? (
            <>
              <i className="fas fa-ellipsis-v" />
              <i className="fas fa-ellipsis-v" />
            </>
          )
          : null}
      </span>
    ));

    return (
      <>
        {(isApiLoading || this.props.iskpiLoading) && (
          <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
        )}
        {UNALTERED_initiativeList.length === 0
          && (isAccess && configuratorStatus && OMIDEnabled ? (
            <div className="emptycriteria-block">
              <img alt="" src={EmptyCriteria} width="78px" height="90px" />
              <h4>{i18n.t('Add_Initiative')}</h4>
            </div>
          ) : (
            <div className="emptycriteria-block">
              <p>{i18n.t('No_Initiatives_Added')}</p>
            </div>
          ))}
        {/* List View Condition for INitiative Start */}
        {UNALTERED_initiativeList.length
          !== 0 /* && !isShowInitiativeDeatils */ ? (
          <div className="listOfAddCriteria initiativecriteria-list">
            <div className="initiativecriteria-dropdowns">
              <div className="initiativecriteria-dropdownbtn">
                <span>
                  <i className="fal fa-cog" />
                </span>
                <span className="criteria-dropdownname">
                  {i18n.t('Levers')}
                </span>
                <span
                  className="levers-customdropdown"
                  onClick={(e) => {
                    this.isLeverDropDown(e);
                  }}
                >
                  <span className="levers-dropdownvalue">
                    {' '}
                    {allLever
                      ? i18n.t('All')
                      : leversData && leversData.length
                        ? leversData
                          .map((each) => each.Name)
                          .join(',')
                          .substring(0, 30).length >= 30
                          ? `${leversData
                            .map((each) => each.Name)
                            .join(',')
                            .substring(0, 30)}...`
                          : leversData
                            .map((each) => each.Name)
                            .join(',')
                            .substring(0, 30)
                        : i18n.t('Select')}
                  </span>
                </span>
                {this.state.isLeversStatusDropdownStatus ? (
                  <div className="initiativeheading-dropdownlist criteriastatus-list">
                    <CustomMultiSelect
                      selectAllChecked={tempallLever}
                      hadleSelectAllChange={(e) => {
                        this.handleAllLever(e);
                      }}
                      options={templeverData}
                      handleOptionChange={(e, index) => {
                        this.handleLeverChange(e, index);
                      }}
                      dispalySelectAll
                    />
                  </div>
                ) : (
                  ''
                )}

                {this.state.isLeversStatusDropdownStatus ? (
                  <div
                    className="initiative-dropdown-overlay"
                    onClick={this.isLeversStatusDropdownOverlay}
                  />
                ) : (
                  ''
                )}
              </div>

              <div className="initiativecriteria-dropdownbtn">
                <span>
                  <i className="fal fa-hourglass-end" />
                </span>
                <span className="criteria-dropdownname">
                  {i18n.t('Status')}
                </span>
                <span
                  className="levers-customdropdown"
                  onClick={(e) => {
                    this.handleStatusDropDown(e);
                  }}
                >
                  <span className="levers-dropdownvalue">
                    {' '}
                    {allStatus
                      ? i18n.t('All')
                      : statusData && statusData.length
                        ? statusData
                          .map((each) => each.Name)
                          .join(',')
                          .substring(0, 30).length >= 30
                          ? `${statusData
                            .map((each) => each.Name)
                            .join(',')
                            .substring(0, 30)}...`
                          : statusData
                            .map((each) => each.Name)
                            .join(',')
                            .substring(0, 30)
                        : i18n.t('Select')}
                  </span>
                </span>
                {this.state.isStatusFilterDropdownStatus ? (
                  <div className="initiativeheading-dropdownlist criteriastatus-list">
                    <CustomMultiSelect
                      selectAllChecked={tempAllStatus}
                      hadleSelectAllChange={(e) => {
                        this.handleStatusAll(e);
                      }}
                      options={tempStatusData}
                      handleOptionChange={(e, index) => {
                        this.handleStatusChange(e, index);
                      }}
                      dispalySelectAll
                    />
                  </div>
                ) : (
                  ''
                )}

                {this.state.isStatusFilterDropdownStatus ? (
                  <div
                    className="initiative-dropdown-overlay"
                    onClick={this.handleStatusOverlay}
                  />
                ) : (
                  ''
                )}
              </div>

              {isInitativeManagement && (
                <div className="initiativecriteria-dropdownbtn">
                  <span>
                    <i className="fal fa-hourglass-end" />
                  </span>
                  <span className="criteria-dropdownname">
                    {i18n.t('Journey_Stage')}
                  </span>
                  <span
                    className="levers-customdropdown"
                    onClick={(e) => {
                      this.handleStageDropDown(e);
                    }}
                  >
                    <span className="levers-dropdownvalue">
                      {' '}
                      {allStage
                        ? i18n.t('All')
                        : staticStageData && staticStageData.length
                          ? staticStageData
                            .map((each) => each.Name)
                            .join(',')
                            .substring(0, 30).length >= 30
                            ? `${staticStageData
                              .map((each) => each.Name)
                              .join(',')
                              .substring(0, 30)}...`
                            : staticStageData
                              .map((each) => each.Name)
                              .join(',')
                              .substring(0, 30)
                          : i18n.t('Select')}
                    </span>
                  </span>
                  {this.state.isStageFilterDropdownStatus ? (
                    <div className="initiativeheading-dropdownlist criteriastatus-list">
                      <CustomMultiSelect
                        selectAllChecked={tempAllStage}
                        hadleSelectAllChange={(e) => {
                          this.handleStageAll(e);
                        }}
                        options={modifiedStageData}
                        handleOptionChange={(e, index) => {
                          this.handleStageChange(e, index);
                        }}
                        dispalySelectAll
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  {this.state.isStageFilterDropdownStatus ? (
                    <div
                      className="initiative-dropdown-overlay"
                      onClick={this.handleStageOverlay}
                    />
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>

            <div className="iojexport-btn">
              <button
                className="btn btn-primary btn-sm py-1"
                disabled={exportDisable}
                onClick={this.GetExceldata}
              >
                <FontAwesomeIcon icon={faFileDownload} className="mr-1" />
                {' '}
                {i18n.t('Download')}
              </button>
              <Workbook
                filename={`${formatSheetName}.xlsx`}
                element={(
                  <button
                    style={{ display: 'none' }}
                    ref={this.inputRef}
                  />
                )}
              >
                {excelData.length > 0
                  && excelData.map((each) => (
                    <Workbook.Sheet data={each.Data} name={each.TabName}>
                      {Object.keys(each.Data[0]).map((column) => (
                        <Workbook.Column label={column} value={column} />
                      ))}
                    </Workbook.Sheet>
                  ))}
              </Workbook>
            </div>
            {initiativeList.length > 0
              && (
                <div className="drag-drop-table">
                  <div className="drag-drop-header">
                    <div className="drag-drop-columns">
                      Initiative Name
                    </div>
                    <div className="drag-drop-columns">
                      Impact Type
                    </div>
                    <div className="drag-drop-columns">
                      Outcome
                    </div>
                    <div className="drag-drop-columns">
                      Status
                    </div>
                    <div className="drag-drop-columns">
                      Levers
                    </div>
                  </div>
                  <SortableContainer onSortEnd={this.onSortEnd} helperClass="dragging-helper-class" useDragHandle>
                    {currentPosts.map((value, index) => (
                      <SortableItem key={`item-${value}`} index={index} value={value} sortIndex={index} distance={1} lockAxis="y" />
                    ))}
                  </SortableContainer>
                </div>
              )}
            {/* <table
              className={
                currentPosts.length > 0
                  ? "table table-responsive-sm table-responsive-md table-responsive-lg"
                  : "table table-responsive-sm table-responsive-md table-responsive-lg norows-selected"
              }
            >
              <thead className="thead-light">
                <tr>
                  <th className="">{i18n.t("Name")}</th>
                  <th className="pl-4">{i18n.t("Status")}</th>

                  <th>{i18n.t("Impact")}</th>
                  <th>{i18n.t("Outcome")}</th>

                  {isAccess && configuratorStatus ? (
                    <th className="text-center" colspan="3">
                      {i18n.t("Actions")}
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((each) => (
                  <tr>
                    <td
                      className={`criteriaName ${each.RAGStatus.toLowerCase()}Color tooltipellipsis-text`}
                      onClick={(e) => {
                        this.ShowInitativeDetails(e, each);
                      }}
                    >
                      <span>{each.ProjectInitiativeName}</span>
                    </td>
                    <td className="pl-0 draft-status">
                      <span
                        className={
                          each.InitiativeStatus_En.toLowerCase().replace(
                            /\s/g,
                            ""
                          ) + "-color"
                        }
                      >
                        {each.InitiativeStatus_En !== "In Draft"
                          ? each.InitiativeStatus
                          : i18n.t("In_Draft")}
                      </span>
                    </td>
                    <td>
                      <span className="initiative-impactName">
                        {i18n.t(
                          each.InitiativeImpacts != null
                            ? each.InitiativeImpacts.map(
                                ({ ImpactName }) => ImpactName
                              ).join(", ")
                            : ""
                        )}
                      </span>
                    </td>
                    <td className="tooltipellipsis-text">
                      <span>
                        {each.InitiativeOutcomeOption != null
                          ? each.InitiativeOutcomeOption.length > 30
                            ? each.InitiativeOutcomeOption.slice(0, 26) + "..."
                            : each.InitiativeOutcomeOption
                          : ""}
                      </span>
                      {each.InitiativeOutcomeOption != null &&
                        each.InitiativeOutcomeOption.length > 30 && (
                          <div className="initiativetooltip-text">
                            {each.InitiativeOutcomeOption}
                          </div>
                        )}
                    </td>
                    {isAccess && configuratorStatus ? (
                      <>
                        <td
                          className="viewAccess"
                          key={each.ProjectInitiativeName}
                          onClick={() => {
                            OMIDEnabled &&
                              each.InitiativeStatus_En !== "In Draft" &&
                              this.handlehidden(each);
                          }}
                        >
                          <span>
                            {!OMIDEnabled ||
                            each.InitiativeStatus_En === "In Draft" ? (
                              <i class="fal fa-eye disabled"></i>
                            ) : (
                              <i
                                data-tip
                                data-for={`initiative${each.ProjectInitiativeName}`}
                                className={
                                  each.IsHidden
                                    ? "far fa-eye-slash"
                                    : "fal fa-eye"
                                }
                              ></i>
                            )}
                            {!each.IsHidden ? (
                              <ReactTooltip
                                id={`initiative${each.ProjectInitiativeName}`}
                                place="bottom"
                                effect="solid"
                              >
                                {" "}
                                {i18n.t(
                                  `Click to hide Initiatives on Roadmap screen`
                                )}{" "}
                              </ReactTooltip>
                            ) : (
                              <ReactTooltip
                                id={`initiative${each.ProjectInitiativeName}`}
                                place="bottom"
                                effect="solid"
                              >
                                {" "}
                                {i18n.t(
                                  `Click to show Initiatives on Roadmap screen`
                                )}{" "}
                              </ReactTooltip>
                            )}
                          </span>
                        </td>
                        <td className="editAccess">
                          <span
                            onClick={() => {
                              OMIDEnabled && this.handleInitiativeEdit(each);
                            }}
                          >
                            {!OMIDEnabled ? (
                              <i class="fal fa-edit disabled"></i>
                            ) : (
                              <i class="fal fa-edit"></i>
                            )}
                          </span>
                        </td>
                        <td className="deleteAccess">
                          <span
                            onClick={() => {
                              OMIDEnabled &&
                                this.deleteIoj(each.IntelOpsInitiativesID);
                            }}
                          >
                            {!OMIDEnabled ? (
                              <i class="fal fa-trash-alt disabled"></i>
                            ) : (
                              <i class="fal fa-trash-alt delete-icon-fix"></i>
                            )}
                          </span>
                        </td>
                      </>
                    ) : null}
                  </tr>
                ))}
                {currentPosts.length < 10 &&
                  [...new Array(10 - currentPosts.length)].map((each) => (
                    <tr className="emptyRowsData">
                      <td>
                        <span></span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                      {isAccess && configuratorStatus ? (
                        <>
                          <td>
                            <span></span>
                          </td>
                          <td>
                            <span></span>
                          </td>
                          <td>
                            <span></span>
                          </td>
                        </>
                      ) : null}
                    </tr>
                  ))}
              </tbody>
            </table> */}
            {initiativeList.length > 10 && postsPerPage >= 10 && (
              <Pagination
                totalPosts={initiativeList.length}
                postsPerPage={postsPerPage}
                changePostsPerPage={this.handlePostsPerPage}
                handleCurrentPage={this.handleCurrentPage}
                currentPage={this.state.currentPage}
              />
            )}
          </div>
        ) : null}
        {deleteInitiativeStatus ? (
          <DeleteModal
            title="Delete_Initiative"
            Message="Are_you_Sure_you_Want_to_Delete"
            deleteAssessment={deleteInitiativeStatus}
            deleteItemModalClose={this.deleteItemModalClose}
            deleteItem={this.deleteIOJApiCall}
          />
        ) : (
          ''
        )}
        {/* Delete Notification Modal Popup Start */}
        <Modal
          show={showsuccessModal}
          onHide={this.successRemovalModalClose}
          className="successNotification-modal"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t('Notification')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="successnotification-body">
              <div className="successnotification-icon">
                <span className="mr-4">
                  <i className="fal fa-trash" />
                </span>
              </div>
              <div className="successnotification-data">
                <p>{i18n.t('Initiative_deleted_successfully')}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Delete Notification Modal Popup End */}
        {/* List View Condition for INitiative End */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  boData: state.BOReducers.BO,
  roleReducers: state.roleReducers.roleReducers,
  allInitiativeData: state.JourneyReducer.JourneyInitiatives,
  boiListData: state.JourneyReducer.Datas.BoiList,
  datas: state.JourneyReducer.Datas,
  IOJCategory: state.JourneyReducer.IOJCategory,
  languageData: state.CurrentFilter.languageData,
  currentFilter: state.CurrentFilter.SyncFilter,
  clientData: state.CurrentFilter.ClientData,
  OMIDEnabled: state.JourneyReducer.OMIDEnabled,
});

const mapDispatchToProps = (dispatch) => ({
  deleteInitiative: (payload) => dispatch(DeleteInitiative(payload)),
  storeActionStatus: (payload) => dispatch(actionStatus(payload)),
  storeStatusData: (payload) => dispatch(setStatusData(payload)),
});

const conntedFunc = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default compose(
  withRouter,
  conntedFunc,
)(Initiative);
