import React, { Component } from 'react';
import { Tabs, Tab, TabList } from 'react-web-tabs';
import 'chartjs-plugin-datalabels';
import { ViewInsights_Grid } from './IOJ_ViewInsights_grid';
import ViewinsightNavigation from './ViewInsightNavigation';
import i18n from "i18next";
import { Link, withRouter } from 'react-router-dom';
import axiosInstance from '../Shared/interceptor';
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


let charts = [];
class ViewInsights extends Component {
    constructor(props) {
        super(props)
        this.state = { MaturityPhase: "FOUNDATION",selectedCategory:"All",selectedTab:"All", UniqueCategories:[],IOJMainAll: [], IOJMain: [], IOJIntStatus: [], IOJSegr: [] ,data: []};
        
        this.selectedIndex = 0
    }
   
    componentWillReceiveProps(nextProps) {

       
    }
    componentWillMount() {

        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera 
     
            this.setState({ MaturityPhase: this.props.location.MaturityPhase, IOJSegr: this.props.location.IOJSegr ? this.props.location.IOJSegr: [],IOJMain:this.props.location.IOJMain ? this.props.location.IOJMain : [],selectedCategory: this.props.location.iojCategorySelected ? this.props.location.iojCategorySelected : "All"})
           

    }
    componentDidMount(){
        // 
        // isIojBlock
        this.getUniqueCategories();
    }
  

initiativesData = () =>{
let selectedCat = this.state.selectedCategory;
let phase =this.state.MaturityPhase;
let AllData = this.state.IOJMain;
let tab = this.state.selectedTab;
let initiativeData = [];
if(AllData.length){
if(selectedCat ==="All"){
if(tab==="All"){
    initiativeData =  AllData.filter((each)=>{
       if(each.MaturityPhase_En=== phase){
           return each;
       }
   })
   this.setState({IOJIntStatus:initiativeData})
}else{
     initiativeData =  AllData.filter((each)=>{
        if(each.MaturityPhase_En=== phase && each.SOCategoryName_En === tab){
            return each;
        }
    })
    this.setState({IOJIntStatus:initiativeData})
}
}else{
    if(tab==="All"){
         initiativeData =  AllData.filter((each)=>{
            if(each.MaturityPhase_En=== phase && each.IntelOpsCategoryName_En === selectedCat){
                return each;
            }
        })
        this.setState({IOJIntStatus:initiativeData})
     }else{
          initiativeData =  AllData.filter((each)=>{
             if(each.MaturityPhase_En=== phase && each.SOCategoryName_En === tab && each.IntelOpsCategoryName_En === selectedCat){
                 return each;
             }
         })
         this.setState({IOJIntStatus:initiativeData})
     }
}
}
}
getUniqueCategories = ()=>{

let currentData = this.state.IOJMain;
let currentPhase = this.state.MaturityPhase;
let data = [];
if(currentData.length && this.state.IOJSegr.length ===4){
    if(currentPhase.toUpperCase()==="FOUNDATION")
    {
            data=  this.state.IOJSegr[0];
    }
    else if(currentPhase.toUpperCase()==="AUTOMATED"){
        data=  this.state.IOJSegr[1];
    }
    else if(currentPhase.toUpperCase()==="INSIGHTS"){
        data=  this.state.IOJSegr[2];
    }
    else if(currentPhase.toUpperCase()==="INTELLIGENT"){
        data=  this.state.IOJSegr[3];
    }
}
let UniqueCategories = []
if(data.length){
    data.map((each)=>{
        let uniqeness =  UniqueCategories.filter(e =>{
              if(e.IntelOpsCategoryName_En === each.IntelOpsCategoryName_En){
                  return e
              }
          })
      if(uniqeness.length === 0){
          UniqueCategories.push({IntelOpsCategoryName:each.IntelOpsCategoryName,IntelOpsCategoryName_En:each.IntelOpsCategoryName_En}) 
      }
      })
}
         

      
      this.setState({UniqueCategories:UniqueCategories},()=>{
        this.initiativesData();
      })
   
}
onCategoryChange = (e) =>{
    this.setState({selectedCategory: e.target.options[e.target.selectedIndex].value},()=>{
      
        this.initiativesData();
    });
  
}
setMaturityPhase = (val) =>{
    this.setState({MaturityPhase:val,selectedCategory:"All"},()=>{
        this.getUniqueCategories()
    })
}
onTabChange = (val) =>{
    this.setState({selectedTab:val},()=>{
        this.initiativesData();
    })
}



    render() {


        
        return (
       
            <div className="main-component ioj-component">
                <div className="container-fluid">
                     <div className="row" >
                        <div className="col-lg-12">
                            <div className="big-tab-container">
                                <h3 className="mb-3"><Link className="round-back" to={"/Dashboard"} onClick={this.props.redirectLinkPass}></Link> {i18n.t("Intelligent_Automation_Journey")}!</h3>
                                <div className="big-tab">
                                    {this.state.IOJMain.length != 0 ? <ViewinsightNavigation  nav={this.state.IOJMain} IOjSeg={this.state.IOJSegr} phase = {this.state.MaturityPhase} selected={this.selectedIndex} setMaturityPhase={this.setMaturityPhase}  /> : null}
                                    <div className="row mb-4">
                               <div className="col-md-3">
                                    {/* <label ></label> */}
                                    {this.props.location.isIojBlock===true && this.state.UniqueCategories.length >= 1 && this.state.UniqueCategories[0].IntelOpsCategoryName!=null?  <select className="form-control" 
                                        onChange={this.onCategoryChange} value={this.state.selectedCategory} >
                                            <option value="All">{i18n.t("All")}</option>
                                 {this.state.UniqueCategories.map((name,i) => <option key={i} value={name.IntelOpsCategoryName_En}>{name.IntelOpsCategoryName}</option>) }
                                                             
                                          </select>:null}
                                    </div>
                                    </div>
                                    <div className="big-tab-content">
                                        <div className="tab-content-pane">
                                          
                                            <Tabs defaultTab="All" className="p-0 mt-2">
                                                <TabList>
                                                    <Tab tabFor="All" onClick={this.onTabChange.bind(this,"All")}><span>{i18n.t("All")}</span></Tab>
                                                    <Tab tabFor="Human + Machine Talent" onClick={this.onTabChange.bind(this,"Human + Machine Talent")}><span>{i18n.t("Human_plus_Machine_Talent")}</span></Tab>
                                                    <Tab tabFor="Work Orchestration" onClick={this.onTabChange.bind(this,"Work Orchestration")}><span>{i18n.t("Work_Orchestration")}</span></Tab>
                                                    <Tab tabFor="Insights & Intelligence" onClick={this.onTabChange.bind(this,"Insights & Intelligence")}><span>{i18n.t("Insights")} &amp; {i18n.t("Intelligence")}</span></Tab>
                                                    <Tab tabFor="Diverse Data" onClick={this.onTabChange.bind(this,"Diverse Data")}><span>{i18n.t("Diverse_Data")}</span></Tab>
                                                </TabList>
                                                {this.state.IOJIntStatus.length != 0 ? <ViewInsights_Grid Details={this.state.IOJIntStatus}  tab ={this.state.selectedTab} /> : null}
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        )
    }
}
export default withRouter(ViewInsights)