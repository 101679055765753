import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { regexForConfMasterFields, regexForTextAreas } from '../Shared/Constant';

export default class ClientRegionPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ClientRegion: {
        ApproverRemarks: '',
        ClientRegionName: '',
        ClientRegionName_En: '',
        ID: 0,
        IsActive: false,
        RequestorRemarks: '',
        Status: '',
      },
      errors: [],
    };

    this.onClientRegionChange = this.onClientRegionChange.bind(this);
    this.onRemarkChange = this.onRemarkChange.bind(this);
    this.onIsActiveChange = this.onIsActiveChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props;
    if (data) {
      this.setState({
        ClientRegion: data,
      });
    }
  }

  handleSave() {
    const { onSave, onClose } = this.props;
    const { ClientRegion } = this.state;
    if (this.validateForm()) {
      onSave(ClientRegion);
      onClose();
    }
  }

  onRemarkChange(event) {
    const { ClientRegion } = this.state;
    const errors = {};
    const tempClientRegion = ClientRegion;
    tempClientRegion.RequestorRemarks = event.target.value;
    this.setState({
      ClientRegion: tempClientRegion,
      errors,
    });
  }

  onIsActiveChange(event) {
    const { ClientRegion } = this.state;
    const tempClientRegion = ClientRegion;
    tempClientRegion.IsActive = event.target.checked;
    this.setState({
      ClientRegion: tempClientRegion,
    });
  }

  onClientRegionChange(event) {
    const { ClientRegion } = this.state;
    const errors = {};
    const tempClientRegion = ClientRegion;
    tempClientRegion.ClientRegionName = event.target.value;
    this.setState({
      ClientRegion: tempClientRegion,
      errors,
    });
  }

  validateForm() {
    const { ClientRegion } = this.state;
    const tempClientRegion = ClientRegion;
    const errors = {};
    let formIsValid = true;

    if (!tempClientRegion.ClientRegionName.trim()) {
      formIsValid = false;
      errors.ClientRegionName = 'ClientRegion Name is required';
    } else {
      // Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      // Validate TextBox value against the Regex.
      const isValid = regexForConfMasterFields.test(tempClientRegion.ClientRegionName);
      if (!isValid) {
        formIsValid = false;
        errors.ClientRegionName = 'ClientRegion Name contains Special characters';
      }
    }

    if (tempClientRegion.RequestorRemarks == null) {
      formIsValid = false;
      errors.RequestorRemarks = 'Requestor Remarks are required';
    } else if (!tempClientRegion.RequestorRemarks.trim()) {
      formIsValid = false;
      errors.RequestorRemarks = 'Requestor Remarks are required';
    } else {
      // Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      // var regex = /^[A-Za-z ]+$/
      // Validate TextBox value against the Regex.
      const isValid = regexForTextAreas.test(tempClientRegion.RequestorRemarks);
      if (!isValid) {
        formIsValid = false;
        errors.RequestorRemarks = 'Requestor Remarks contains Special characters';
      }
    }

    if (formIsValid) {
      this.setState({
        errors,
      });
    } else {
      this.setState({
        errors,
      });
    }
    return formIsValid;
  }

  render() {
    const { show, onClose, data } = this.props;
    const {
      ClientRegion, errors,
    } = this.state;
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={show}
          onHide={() => onClose()}
          backdrop="static"
          class="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            {!data ? (
              <h4 className="modal-title" id="dealprofile">
                Add Client Region
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile">
                Edit Client Region
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label htmlFor="">
                      Client Region Name
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      readOnly={data === null && false}
                      className="form-control"
                      id="name"
                      onChange={this.onClientRegionChange}
                      value={ClientRegion.ClientRegionName}
                    />
                    <span className="errorMsg">{errors.ClientRegionName}</span>
                  </div>

                  <div className="form-group col-lg-6">
                    <label htmlFor="">
                      Requestor Remarks
                      <span className="required">*</span>
                    </label>
                    <textarea
                      maxLength="200"
                      readOnly={!data && false}
                      className="form-control"
                      id="remarks"
                      onChange={this.onRemarkChange.bind(this)}
                      value={ClientRegion.RequestorRemarks}
                    />
                    <span className="errorMsg">{errors.RequestorRemarks}</span>
                  </div>

                  {data && (
                    data.Status === 'Approved' || data.Status === 'Rejected' ? (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                          Approver Remarks
                          <span className="required" />
                        </label>
                        <textarea
                          maxLength="200"
                          className="form-control"
                          id="ClientContact1"
                          disabled={data}
                          value={data.ApproverRemarks}
                        />
                      </div>
                    ) : null
                  )}

                  {data ? (
                    <div className="form-group col-lg-6 label-adjust">
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          readOnly={data.Status === 'Approved' && false}
                          type="checkbox"
                          onChange={this.onIsActiveChange}
                          defaultChecked={ClientRegion.IsActive}
                        />
                        <span className="checkmark" />
                        <span className="form-check-label">Is Active</span>
                      </label>
                    </div>
                  ) : (
                    <div className="form-group col-lg-6">
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={this.onIsActiveChange}
                          defaultChecked={ClientRegion.IsActive}
                        />
                        <span className="checkmark" />
                        <span className="form-check-label">Is Active</span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              {!data ? (
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value="Create"
                />
              ) : (
                data.Status === 'Approved' && (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Update"
                  />
                )
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

ClientRegionPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,

};
