import React, { Component } from "react";
import WO_Details from "./WorkOrchestrationDetails";
import AddMoreToolPopup from './AddMoreToolPopup';
import { store } from "../reducers/configureStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import { connect } from "react-redux";
import emptycomponent from "../Images/Empty State.svg"

const RemoveDuplicates = (arr) => {/*NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
    const map = new Map();
    arr.forEach(v => map.set(v.ApplicationName, v))
    return [...map.values()];
}

class StepSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMoreTool: false,
            ToolListData: [],
        }
        this.addMoreToolPopup = this.addMoreToolPopup.bind(this);
        this.setToolCatalog = this.setToolCatalog.bind(this);
    }


    componentWillReceiveProps(nextprops) {

        let { toolList, screen, metricRowDetails, woData } = nextprops;
        if (screen == "Dashboard") {
            let toolListData = [];
            let DatametricRowDetails = woData.filter(tile => tile.SOCategoryName != "Work Orchestration" && tile.ParentSOCategoryID !== 3);
            DatametricRowDetails.map(tile => {
                if (tile.WOStepTools != null) {
                    if (tile.WOStepTools.length > 0) {
                        tile.WOStepTools.map(tool => {
                            if (tool.Custom == "MTCTool") {
                                tool.IsMapped = true;
                                toolListData.push(tool);
                            }
                        })
                    }
                }
            })
            // Remove Duplicates
            if (toolListData.length > 0) {
                toolListData = RemoveDuplicates(toolListData);
            }

            let unmappedToolList = toolList.map(tool => {
                if (toolListData.filter(data => data.ApplicationName == tool.ApplicationName).length == 0) {
                    tool.IsMapped = false;
                    toolListData.push(tool);
                }
            })
            this.setState({
                ToolListData: toolListData,
            })
        }
        else {
            let tiles = store.getState().WOtileReducers.tile;
            let toolsCatalog = [];
            tiles = tiles.filter(tile => tile.SOCategoryName != "Work Orchestration");
            tiles.map(tile => {
                if (tile.WOStepTools != null) {
                    if (tile.WOStepTools.length > 0) {

                        tile.WOStepTools.map(tool => {
                            if(tool.PulseToolsConfigDetailsID === null || parseInt(tool.PulseToolsConfigDetailsID) === 0)
                            {
                                tool.IsMapped = true;
                                toolsCatalog.push(tool)
                            }
                           
                        })
                    }
                }
            }
            );
            //Remove Duplicates
            if (toolsCatalog.length > 0) {
                toolsCatalog = RemoveDuplicates(toolsCatalog);
            }

            let newtoolList = [];

            let mappedToolList = toolList.map(tool => {
                if (toolsCatalog.filter(data => data.ApplicationName == tool.ApplicationName).length > 0) {
                    tool.IsMapped = true;
                    newtoolList.push(tool);
                }
            })
            let unmappedToolList = toolList.map(tool => {
                if (toolsCatalog.filter(data => data.ApplicationName == tool.ApplicationName).length == 0) {
                    tool.IsMapped = false;
                    newtoolList.push(tool);
                }
            })
            this.setState({
                ToolListData: newtoolList,
            })


        }

    }

    //More Popup of Tool Catalog
    addMoreToolPopup() {
        this.state.showMoreTool ? this.setState({ showMoreTool: false }) :
            this.setState({ showMoreTool: true })
    }

    //Setting Tool Catalog
    setToolCatalog() {
        let { showAllTools, screen } = this.props;
        let toolList = this.state.ToolListData;
        let ATTools = toolList.filter((tool) => tool.ToolType === 'AT');
        let AITools = toolList.filter((tool) => tool.ToolType === 'AI');
        let WFTools = toolList.filter((tool) => tool.ToolType === 'WF');
        let AATools = toolList.filter((tool) => tool.ToolType === 'AA');
        let toolcatalog =
            <>
                <div className="catalog-list">
                    <h6>{i18n.t("Automation")}</h6>
                    <div className="radius-tools">
                        {ATTools.length > 0 ?
                            ATTools.slice(0, 3).map((tool) =>
                                screen === "Dashboard"
                                    ? showAllTools
                                        ? <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
                                        : tool.IsMapped && <span className="active">{tool.ApplicationName}</span>
                                    : <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
                            )
                            :
                            <p>No tool added. Explore tools</p>
                        }
                        {screen === "Dashboard" ?
                            showAllTools ?
                                ATTools.length > 3 && <a className="moretool-link" onClick={this.addMoreToolPopup}>More {ATTools.length - 3}...</a>
                                :
                                ATTools.filter((activetool) => activetool.IsMapped === true).length > 3 &&
                                <a className="moretool-link" onClick={this.addMoreToolPopup}>More {ATTools.filter((activetool) => activetool.IsMapped === true).length - 3}...</a>
                            :
                            ATTools.length > 3 && <a className="moretool-link" onClick={this.addMoreToolPopup}>More {ATTools.length - 3}...</a>
                        }
                    </div>
                </div>
                <div className="catalog-list" >
                    <h6>{i18n.t("Workflow")}</h6>
                    <div className="radius-tools">
                        {WFTools.length > 0 ? WFTools.slice(0, 3).map((tool) =>
                            screen === "Dashboard"
                                ? showAllTools
                                    ? <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
                                    : tool.IsMapped && <span className="active">{tool.ApplicationName}</span>
                                : <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
                        ) :
                            <p>No tool added. Explore tools</p>
                        }
                        {screen === "Dashboard" ?
                            showAllTools ?
                                WFTools.length > 3 && <a className="moretool-link" onClick={this.addMoreToolPopup}>More {WFTools.length - 3}...</a>
                                :
                                WFTools.filter((activetool) => activetool.IsMapped === true).length > 3 &&
                                <a className="moretool-link" onClick={this.addMoreToolPopup}>More {WFTools.filter((activetool) => activetool.IsMapped === true).length - 3}...</a>
                            :
                            WFTools.length > 3 && <a className="moretool-link" onClick={this.addMoreToolPopup}>More {WFTools.length - 3}...</a>
                        }
                    </div>
                </div>
                <div className="catalog-list">
                    <h6>{i18n.t("Advanced_Analytics")}</h6>
                    <div className="radius-tools">
                        {AATools.length > 0 ?
                            AATools.slice(0, 3).map((tool) =>
                                screen === "Dashboard"
                                    ? showAllTools
                                        ? <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
                                        : tool.IsMapped && <span className="active">{tool.ApplicationName}</span>
                                    : <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
                            )
                            :
                            <p>No tool added. Explore tools</p>
                        }
                        {screen === "Dashboard" ?
                            showAllTools ?
                                AATools.length > 3 && <a className="moretool-link" onClick={this.addMoreToolPopup}>More {AATools.length - 3}...</a>
                                :
                                AATools.filter((activetool) => activetool.IsMapped === true).length > 3 &&
                                <a className="moretool-link" onClick={this.addMoreToolPopup}>More {AATools.filter((activetool) => activetool.IsMapped === true).length - 3}...</a>
                            :
                            AATools.length > 3 && <a className="moretool-link" onClick={this.addMoreToolPopup}>More {AATools.length - 3}...</a>
                        }
                    </div>
                </div>
                <div className="catalog-list" >
                    <h6>{i18n.t("Artificial_Intelligence")}</h6>
                    <div className="radius-tools">
                        {
                            AITools.length > 0 ?
                                AITools.slice(0, 3).map((tool) =>
                                    screen === "Dashboard"
                                        ? showAllTools
                                            ? <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
                                            : tool.IsMapped && <span className="active">{tool.ApplicationName}</span>
                                        : <span className={tool.IsMapped === true ? "active" : "disabled"}>{tool.ApplicationName}</span>
                                ) :
                                <p>No tool added. Explore tools</p>
                        }
                        {screen === "Dashboard" ?
                            showAllTools ?
                                AITools.length > 3 && <a className="moretool-link" onClick={this.addMoreToolPopup}>More {AITools.length - 3}...</a>
                                :
                                AITools.filter((activetool) => activetool.IsMapped === true).length > 3 &&
                                <a className="moretool-link" onClick={this.addMoreToolPopup}>More {AITools.filter((activetool) => activetool.IsMapped === true).length - 3}...</a>
                            :
                            AITools.length > 3 && <a className="moretool-link" onClick={this.addMoreToolPopup}>More {AITools.length - 3}...</a>
                        }
                    </div>
                </div>
            </>
        return toolcatalog;
    }

    //Setting Steps
    generateDetails(metricRowDetails) {
        let {
            screen,
            card,
            toolCat,
            filter,
            roledata,
            deleteStep,
            selectedStep
        } = this.props;
        let Data = [...metricRowDetails];
        var dtlength = Data.length;
        var no_per_exact = Math.floor(dtlength / 4);
        var no_of_row = dtlength % 4 === 0 ? no_per_exact : no_per_exact + 1;
        var rowdone = false;
        let rowTheList = [];
        var mainIndex;
        let count = 0;

        return Data.map((data, index) => {
            data.key = index + 1;
            mainIndex = index;
            rowTheList = rowdone ? [] : rowTheList;
            switch (screen) {
                case "Configurator":
                    if ((index + 1) % 3 === 0 || index == dtlength - 1) {
                        rowdone = true;
                        count += 1;
                    } else {
                        rowdone = false;
                    }

                    rowTheList.push(data);
                    if (count % 2 == 0 && count != 0 && rowdone) {
                        rowTheList = rowTheList.reverse();
                    }

                    if (rowdone) {
                        return (
                            <div className="timeline-row">
                                {rowTheList.map((row, index) => {
                                    const each= {...row};
                                    return (
                                        <WO_Details
                                            screen={screen}
                                            selectedStep={selectedStep}
                                            generateData={each}
                                            no_per_row={3}
                                            card={card}
                                            index={index}
                                            main={mainIndex}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }
                    break;
                case "SuperAdmin":
                    if ((index + 1) % 4 === 0 || index == dtlength - 1) {
                        rowdone = true;
                        count += 1;
                    } else {
                        rowdone = false;
                    }

                    rowTheList.push(data);
                    if (count % 2 == 0 && count != 0 && rowdone) {
                        rowTheList = rowTheList.reverse();
                    }

                    if (rowdone) {
                        return (
                            <div className="timeline-row">
                                {rowTheList.map((row, index) => {
                                    return (
                                        <WO_Details
                                            getOfferingAPICallback={this.props.getOfferingAPICallback}
                                            screen={screen}
                                            deleteStep={deleteStep}
                                            selectedStep={selectedStep}
                                            filter={filter}
                                            generateData={row}
                                            no_per_row={4}
                                            card={card}
                                            index={index}
                                            main={mainIndex}
                                            roledata={roledata}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }
                    break;
                case "Dashboard":
                    if ((index + 1) % 3 === 0 || index == dtlength - 1) {
                        rowdone = index !== 0 ? true : false;
                        rowdone = dtlength === 1 ? true : rowdone;
                        if (rowdone == true) count += 1;
                    } else {
                        rowdone = false;
                    }
                    rowTheList.push(data);
                    if (count % 2 == 0 && count != 0 && rowdone) {
                        rowTheList = rowTheList.reverse();
                    }
                    if (rowdone) {
                        return (
                            <div className="timeline-row">
                                {rowTheList.map((row, index) => {
                                    const each= {...row};
                                    return (
                                        <WO_Details
                                            screen={screen}
                                            ToolsCategory={toolCat}
                                            selectedStep={selectedStep}
                                            generateData={each}
                                            no_per_row={3}
                                            card={card}
                                            index={index}
                                            main={mainIndex}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }
                    break;
                default:
                    return null;
            }
        });
    }

    render() {

        let {
            screen,
            selectFT,
            toolList,
            exploreMoreToolsToggler,
            showAllTools,
            deleteFT,
            isFoundationToolsLoaded,
            FoundationToolsApi,
            FTools_disable,
            foundationAttr,
            metricRowDetails,
            addStepModalShow,
            
        } = this.props;
        return (
            <>
               {metricRowDetails.length == 0 && screen === "SuperAdmin" ? (
          <div className="wo-tabcontent">
            <div className="tool-data">
              <div className="add-toolicon">
                {store.getState().CurrentFilter.languageData.id == 1 ? (
                  <>
                    <Button className="show-pop" onClick={addStepModalShow}>
                      <img src={emptycomponent} alt="" width="130" height="130" />
                    </Button>
                    <h6>There is no workflow step yet</h6>
                    <div className="add-step" onClick={addStepModalShow}>
                      Add Step
                    </div>
                  </>
                ) : (
                  <>
                    <Button className="show-pop">
                      <img src={emptycomponent} alt="" width="130" height="130" />
                    </Button>
                    <h6>There is no workflow step yet</h6>
                  </>
                )}
              </div>
            </div>
          </div>
                ) : (
                        <div className="wo-tabcontent">
                            {screen === "SuperAdmin"
                                ? this.props.roledata != undefined &&
                                store.getState().CurrentFilter.languageData.id == 1 &&
                                this.props.roledata.includes("Add") && (
                                    <div className="title-with-icon mb-3">
                                        <h2></h2>
                                        <div className="list-icon">
                                            <span className="help-icon" onClick={addStepModalShow}>
                                                <FontAwesomeIcon icon={faPlus} />
                                                    Add Step
                                            </span>
                                        </div>
                                    </div>
                                )
                                 : screen === "Configurator" ?
                                     (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] === "True" && !this.props.isManintencneWindowOpen) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0) ? "" :
                                         (<div className="title-with-icon mb-3">
                                             <h2></h2>
                                             <div className="list-icon">
                                                <span className="help-icon" onClick={addStepModalShow}>
                                                    <FontAwesomeIcon icon={faPlus} />
                                                        Add Step
                                                </span>
                                             </div>
                                         </div>)
                                    : null}
                            <div className="row">
                                <div
                                    className={
                                        screen === "SuperAdmin"
                                            ? "col-lg-12"
                                            : "col-lg-9 col-md-9 col-sm-12 col-xs-12"
                                    }
                                >
                                    {screen === "SuperAdmin" ? null : (
                                        <div className="orch-sidebar">
                                            <div className="rainbow-tools">
                                                <p>
                                                    <span>{i18n.t("AT")}</span> {i18n.t("Automation")}
                                                </p>
                                                <p>
                                                    <span>{i18n.t("WF")}</span> {i18n.t("Workflow")}
                                                </p>
                                                <p>
                                                    <span>{i18n.t("AA")}</span> {i18n.t("Advanced_Analytics")}
                                                </p>
                                                <p>
                                                    <span>{i18n.t("AI")}</span> {i18n.t("Artificial_Intelligence")}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className={
                                            screen === "SuperAdmin"
                                                ? "timeline-highlight"
                                                : "timeline-highlight configurator-timeline"
                                        }
                                    >
                                        {this.generateDetails(metricRowDetails)}
                                    </div>
                                </div>
                                {screen !== "SuperAdmin" &&
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 sidebar-cont">
                                        {screen === "Dashboard" ?
                                            showAllTools === false ?
                                                <span className="more-tools" style={{ cursor: "pointer" }} onClick={() => exploreMoreToolsToggler()}>
                                                    Explore More Tools
                      </span>
                                                :
                                                <span className="more-tools" style={{ cursor: "pointer" }} onClick={() => exploreMoreToolsToggler()}>
                                                    Show Added Tools
                    </span>
                                            : null}
                                        <div className="outcome-highlight">
                                            <div className="title-with-icon">
                                                <h2>{i18n.t("Foundation_Tools")}</h2>
                                            </div>
                                            {FoundationToolsApi.length > 0 ?
                                                <div className="radius-tools">
                                                    {foundationAttr.length !== 0 &&
                                                        foundationAttr.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((row) =>
                                                            screen == 'Configurator' ?
                                                                <span className="active">
                                                                    {row}
                                                                    {((this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] ===  "True" && !this.props.isManintencneWindowOpen ) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0)) ?
                                                                        <a>
                                                                            <i style={{ cursor: 'pointer' }} class="far fa-times-circle"></i>
                                                                        </a> : <a onClick={() => deleteFT(row)} >
                                                                            <i style={{ cursor: 'pointer' }} class="far fa-times-circle"></i>
                                                                        </a>}
                                                                </span>
                                                                :
                                                                <span className="active">
                                                                    {row}
                                                                </span>
                                                        )
                                                    }
                                                    {FTools_disable != null &&
                                                        FTools_disable.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((row, index) =>
                                                            screen === 'Configurator'
                                                                ? (((this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] ===  "True" && !this.props.isManintencneWindowOpen ) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0)) ?
                                                                    <span style={{ cursor: 'pointer' }} className="disable" key={index} >{row}</span> : <span style={{ cursor: 'pointer' }} className="disable" key={index} onClick={() => selectFT(row)}>{row}</span>)
                         :
                                                                showAllTools && <span className="disable" key={index}>{row}</span>
                                                        )}
                                                </div>
                                                : isFoundationToolsLoaded ?
                                                    <span className="radius-tools"><p>No Foundation_Tools recieved </p></span>
                                                    : <span className="radius-tools"><p>Loading ...</p></span>
                                            }
                                        </div>
                                        <div className="outcome-highlight">
                                            <div className="title-with-icon">
                                                <h2>Tools Catalog</h2>
                                            </div>
                                            {this.setToolCatalog()}
                                            {this.state.showMoreTool &&
                                                <AddMoreToolPopup
                                                    showAllTools={this.props.showAllTools}
                                                    toolList={this.state.ToolListData}
                                                    screen={this.props.screen}
                                                    show={this.state.showMoreTool}
                                                    modalClose={this.addMoreToolPopup} />}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    )}
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        roleReducers: state.roleReducers.roleReducers,
    };
};


export default connect(mapStateToProps, "")(StepSummary);
