//REACT IMPORTS
import {
    LocalApiBaseUrl,
    getPrevMonth,
} from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import React, { Component } from "react";

//CUSTOM IMPORTS
import DiverseKpiContiner from "../SuperAdmin/DiverseKpiContainer";
import DDWidgetContainer from "./WidgetConfigContainer/DDWidgetContainer";
import DiversePopUp from "../SuperAdmin/diverse_popup";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import ExportCsv from './ExportCsv';

//STYLING IMPORTS
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import { Tabs, Tab } from "react-bootstrap-tabs";

export default class DiverseMasterMetricDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metricDetails: [],
            viewmore: false,
            type: "",
            metricRowDetails: [],
            operationArray: [],
            attributeLabels: [],
        };
        this.onClose = this.onClose.bind(this);
        this.deleteDiverseCategory = this.deleteDiverseCategory.bind(this);
        this.setOperations = this.setOperations.bind(this);
    }

    setOperations() {
        const data = store.getState();
        let operation = this.state.operationArray;
        data.roleReducers["roleReducers"].map((i) => {
            if (i.FeatureName === "Master Template Configurator") {
                operation.push(...i.Operations);
                return operation;
            }
        });

    this.setState({
      operationArray: operation
    });
  }
  getDiverseData() {
    var data = store.getState();
    let labelsForDD = data.BOMasterReducers.attributeLabels.filter(z=>z.ApplicableForDD===true);

    this.setState({
      attributeLabels: labelsForDD[0]
    });
    
    this.state.diverseMasterData = data.diverseMasterReducers["diverse"];
    let metricRowDetails = this.state.diverseMasterData.map(rowDetails => {
      return (
        <DiverseKpiContiner
          key={rowDetails.kpiData.KPIMappingId}
          SODetails={rowDetails.kpiData}
          filterDetails={this.props.filterDetails}
          roledata={this.state.operationArray}
        />
      );
    });

        this.setState({ metricRowDetails: metricRowDetails });
    }

    getKpiMetrics(
        SODetails = { SOCategoryID: 0 },
        offeringIdparam = {
            Client: 0,
            process: 0,
            Locations: 0,
            Month: getPrevMonth(),
        }
    ) {
        this.setState({
            metricDetails:
                this.props.SODetails.kpiData != undefined
                    ? this.props.SODetails.kpiData
                    : null,
        });
    }

    componentDidMount() {
        this.setOperations();
        this.getKpiMetrics(this.props.SODetails, this.props.filterDetails);
        var data = store.getState();
        this.setState({ attributeLabels: data.BOMasterReducers.attributeLabels });
    }
    componentWillReceiveProps(nextProps) {
        this.getKpiMetrics(nextProps.SODetails, nextProps.filterDetails);
    }

    dealpopup(type, e) {
        this.setState({ viewmore: true, type: type });
        e.preventDefault();
    }

    componentWillReceiveProps(nextProps) {
        store.subscribe(() => {

            var data = store.getState();

            this.setState({ diverseData: data.diverseMasterReducers["diverse"] });
        });
        this.getDiverseData();
    }

    deleteDiverseCategory(data, filterDetails) {
            axiosInstance
                .post(
                    `${LocalApiBaseUrl}Master/DeleteSOCategoryMasterTemplate?soCategoryId=${data.SOCategoryID}&offeringId=${filterDetails.process}`
                )
                .then((response) => { })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
          

            store.dispatch({
                type: "DELETE_DIVERSE_CATEGORY_MASTER",
                payload: data.SOCategoryName,
            });
        //}
    }

    onClose() {
        this.setState({ viewmore: false });
    }

    render() {
        return (
            <div className="row">
             <ExportCsv csvProp = {this.props.filterDetails.process}/>
                <div className="col-lg-12">
                    {this.props.SODetails.SOCategoryID !== 5 ? (
                        <div className="outcome-highlight no-whiteShadow no-table-layout">
                            <div className="title-with-icon">
                                <Tabs
                                    className="tabBar dd-widget"
                                >
                                    <Tab label="Metrics">
                                        <div classNmae="metrics-tabheader-block">
                                        <h2 className="mb-3">
                                            {this.props.SODetails.SOCategoryID !== 5 &&
                                                this.props.SODetails.SOCategoryName}
                                        </h2>
                                        <div className="list-icon">
                                            {this.props.SODetails.SOCategoryID !== 5 &&
                                                this.props.roledata != undefined &&
                                                store.getState().CurrentFilter.languageData.id == 1 &&
                                                this.props.roledata.includes("Add") && (
                                                    <span
                                                        onClick={(e) => {
                                                            this.dealpopup(
                                                                this.props.SODetails.SOCategoryName,
                                                                e
                                                            );
                                                        }}
                                                    >
                                                        <i className="far fa-plus plus-icon-fix"></i>
                                                    </span>
                                                )}
                                            {this.props.SODetails.SOCategoryID !== 5 &&
                                                this.props.roledata != undefined &&
                                                store.getState().CurrentFilter.languageData.id === 1 &&
                                                this.props.roledata.includes("Delete") && (
                                                    <span
                                                        onClick={(e) => {
                                                            this.deleteDiverseCategory(
                                                                this.props.SODetails,
                                                                this.props.filterDetails
                                                            );
                                                        }}
                                                    >
                                                       <i className="fal fa-trash-alt delete-icon-fix"></i>
                                                    </span>
                                                )}
                                        </div>
                                        </div>
                                          <div className="custom-tilecard mtclayout-tilecard">
                                             <div className="tilecard-wrapper no-carousel">
                                                {this.props.SODetails.SOCategoryID !== 5 ? (
                                                    <DiverseKpiContiner
                                                        key={this.props.SODetails.SOCategoryID}
                                                        SODetails={this.props.SODetails.kpiData}
                                                        filterDetails={this.props.filterDetails}
                                                        roledata={this.props.roledata}
                                                        IsRelatorBlockForOffering={
                                                            this.props.IsRelatorBlockForOffering
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                        {this.state.viewmore ? (
                                            <DiversePopUp
                                                SOCategoryID={this.props.SODetails.SOCategoryID}
                                                SOCategoryName={this.props.SODetails.SOCategoryName}
                                                show={this.state.viewmore}
                                                onClose={this.onClose}
                                                opsgrp={this.props.opsgrp}
                                                filterDetails={this.props.filterDetails}
                                                type={this.state.type}
                                                roledata={this.props.roledata}
                                                IsRelatorBlockForOffering={
                                                    this.props.IsRelatorBlockForOffering
                                                }
                                                attributeLabels={this.state.attributeLabels}
                                            />
                                        ) : null}
                                    </Tab>
                                    {/* {WidgetConfigurator Starts here} */}
                                    <Tab label="Widget Configurator">
                                        <DDWidgetContainer
                                            filterDetails={this.props.filterDetails}
                                            roledata={this.state.operationArray}
                                        />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}
