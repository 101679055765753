import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Moment from "moment";
import i18n from "i18next";
import "react-datepicker/dist/react-datepicker.css";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class UserLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadUrl: null,
      formIsValid: false,

      viewmore: false,
      process: "",
      Client: "",
      MaturityPhase: this.props.MaturityPhase,
      processOptions: [],
      errors: [],
      changeStartDate: null,
      changeEndDate: null,
      Report: {
        StartDate: new Date(),
        EndDate: new Date(),
      },
    };

    this.validateForm = this.validateForm.bind(this);
    this.QueryString = this.QueryString.bind(this);
    this.generateExcelReport = this.generateExcelReport.bind(this);
  }

  componentDidMount() { }

  generateExcelReport() {
    this.validateForm();

    var startDate = Moment(this.state.Report.StartDate).format("YYYY-MM-DD");
    var endDate = Moment(this.state.Report.EndDate).format("YYYY-MM-DD");

    axiosInstance
      .get(`${LocalApiBaseUrl}Report/GetUserLoginFortnightly`, {
        responseType: "blob",
        params: { startDate: startDate, endDate: endDate },
      })
      .then((response) => {
        let fileNameFromHeaders = response.headers.filename;
        if (!window.navigator.msSaveOrOpenBlob) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", fileNameFromHeaders);

          document.body.appendChild(link);
          link.click();
        } else {
          // BLOB FOR EXPLORER 11
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  QueryString() {
    if (this.validateForm()) {
      var A = "Report/GetUserLoginFortnightly?";
      var B = LocalApiBaseUrl;
      var startDate = Moment(this.state.Report.StartDate).format("YYYY-MM-DD");
      var endDate = Moment(this.state.Report.EndDate).format("YYYY-MM-DD");
      let downloadUrl = this.state.downloadUrl;

      downloadUrl = B + A + "startdate=" + startDate + "&enddate=" + endDate;

      this.setState({
        downloadUrl: downloadUrl,
      });
    }
  }

  onStartDateChange = (event) => {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const Report = this.state.Report;
    Report.StartDate = event;

    this.setState({ StartDate: event });
    this.setState({
      Report: Report,
      changeStartDate: event,
      changeEndDate: "",
    });
    this.QueryString();
  };
  onEndDateChange = (event) => {
    let errors = {};
    this.setState({
      errors: errors,
      EndDate: event,
    });
    const Report = this.state.Report;
    Report.EndDate = event;
    this.setState({
      Report: Report,
      changeEndDate: event,
    });

    this.QueryString();
  };

  validateForm() {
    let Report = this.state.Report;
    let errors = {};
    let formIsValid = true;
    if (
      this.state.changeStartDate != null &&
      String(this.state.changeStartDate) !== "" &&
      this.state.changeEndDate != null &&
      String(this.state.changeEndDate) !== ""
    ) {
      if (!Report["StartDate"]) {
        errors["StartDate"] = i18n.t("Start Date is required");
        formIsValid = false;
      }

      if (!Report["EndDate"]) {
        errors["EndDate"] = i18n.t("End Date is required");
        formIsValid = false;
      }
    }
    this.setState({
      errors: errors,
    });

    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  render() {

    return (
      <div className="main-data">
        <div className="row">
          <div className="form-group col-lg-4 block-picker">
            <label className="datepicker-label" htmlFor="">
              Start Date<span className="required"></span>
            </label>
            <DatePicker
              className="form-control"
              selected={this.state.StartDate}
              autoComplete="off"
              id="StartDate"
              onChange={this.onStartDateChange.bind(this)}
              value={this.state.Report.StartDate}
            />
            <span className="errorMsg">{this.state.errors.StartDate}</span>
          </div>
          <div className="form-group col-lg-4 block-picker">
            <label className="datepicker-label" htmlFor="">
              End Date<span className="required"></span>
            </label>
            <DatePicker
              className="form-control"
              selected={this.state.EndDate}
              minDate={this.state.Report.StartDate}
              autoComplete="off"
              id="EndDate"
              onChange={this.onEndDateChange.bind(this)}
              value={this.state.Report.EndDate}
            />
            <span className="errorMsg">{this.state.errors.EndDate}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <button
              className="btn btn-primary btn-sm"
              id="btnsave"
              onClick={this.generateExcelReport}
              disabled={this.state.active}
            >
              Generate Report
            </button>
          </div>
        </div>
      </div>
    );
  }
}
