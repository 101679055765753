import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import _ from "lodash";

export default function MultiSelectDropDown({ 
  labelledBy, 
  options,
  value,
  onChangeFun,
  rowIndex
}) {
  //STATES
  const [dropdownStatus, setdropdownStatus] = useState(false);
  const [optionSource, setoptionSource] = useState([]);
  const [selectAllStatus, setselectAllStatus] = useState(false)
  const [filterOptions, setFilteroptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  //USE EFFECTS
  useEffect(() => {
      let data = _.cloneDeep(options).sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase())
      data = data.map(e=>{
        if(value.filter(a=>String(a.value) === String(e.value)).length>0)
        {
          e.checked=true
        }
        else{
          e.checked=false
        }
        return e;
      })
      let selected = [...data.filter(a=>a.checked === true)]
      if(selected.length>0 && selected.length === data.length)
      {
        setselectAllStatus(true)
      }
      else{
        setselectAllStatus(false)
      }
     
      setoptionSource([...data]);

      setFilteroptions([...data]);
      
      
  onChangeFun(selected,rowIndex);
  if(searchValue !== "" )
        setFilter({target: {value: searchValue}});
    
     
  }, [options]);

  useEffect(()=>{
    let data = _.cloneDeep(options).sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase())
      data = data.map(e=>{
        if(value.filter(a=>String(a.value) === String(e.value)).length>0)
        {
          e.checked=true
        }
        else{
          e.checked=false
        }
        return e;
      })
      let selected = [...data.filter(a=>a.checked === true)]
      if(selected.length>0 && selected.length === data.length)
      {
        setselectAllStatus(true)
      }
      else{
        setselectAllStatus(false)
      }
      setoptionSource([...data]);
      setFilteroptions([...data]);
      if(searchValue !== "" )
        setFilter({target: {value: searchValue}});
  },[value])

  //FUNCTIONS
  const enableDropdown = () => {
    setdropdownStatus(true);
  };

  const closeDropDown = () => {
    setdropdownStatus(false);
  };

  const selectedNameList = () => {
    let selected = _.orderBy(optionSource.filter((e) => e.checked === true), ['label']).map((e) => e.label);
    if(selected.length ===0)
    return labelledBy
    else if(selected.length === optionSource.length)
    return "All Selected"
    else
    {
      let count=1
      let nameSelected=""
      if (selected.length === 1)
      nameSelected = selected[0]
      else
      {
        for(let x of selected)
        {
           nameSelected += x+", "
        }
      }
      nameSelected = nameSelected.replace(/,\s*$/, "")
      if(selected.length ===1)
      {
        return nameSelected.length>17? nameSelected.slice(0,14) + "...":nameSelected
      }
      else
      {
        let morecount = (selected.length - count)
        let names = nameSelected.length>10 ? nameSelected.slice(0,7) + "...":nameSelected
         return `${names} +${morecount} more`
      }
    }
  };

  const setSelectedOptions = (option) => {
    let optionsdata = [...optionSource.map((each) => {
      if (each.value === option.value) {
        each.checked = !each.checked;
      }
      return each;
    })];
    let selected = [...optionsdata.filter((e) => e.checked === true)];
    selected = _.orderBy(selected, ['label']);
    if(selected.length === optionSource.length)
    {
      setselectAllStatus(true)
    }
    else
    {
      setselectAllStatus(false)
    }
    //setoptionSource(optionsdata);
    setFilteroptions(optionsdata)
    if(searchValue !== "" )
        setFilter({target: {value: searchValue}});
    onChangeFun([...selected],rowIndex);
  };
  const selectAll = () => {
      let status= !selectAllStatus
      setselectAllStatus(status)
    let options = optionSource.map((e) => ({ ...e, checked: status }));
    //setoptionSource(options);
    setFilteroptions(options);

    let selected = [...options.filter(e=>e.checked === true)]
    onChangeFun(selected,rowIndex);
  };

  const setFilter =(e) => {
     if(e.target.value !== ""){
     let filterData = optionSource.filter( (f) => {
      return(
        f.label.toUpperCase().includes(e.target.value.toUpperCase())
      )
    })
  
    setFilteroptions(filterData);
  }
  else{
    setFilteroptions([...optionSource])
  }
    setSearchValue(e.target.value);

  }

  return (
    <>
      <label htmlFor="ClientName" className="form-label">
        {labelledBy} <span className="required">*</span>
      </label>
      <div className="customform-dropdown">
        <Dropdown id="role">
          <Dropdown.Toggle className={selectedNameList()===labelledBy?"not-selected":""}>{selectedNameList()}</Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="offering-droplistwrapper">
            
              <>
                  <div class="searchFilter user-profile-search">
                     <input
                       id="search"
                       placeholder="Search ..."
                       type="search"
                       value = {searchValue}
                       autocomplete="no"
                       onChange={((e)=>{setFilter(e)})}
                     />
                     </div>
                  <Dropdown.ItemText key={`index${rowIndex}`}>              
                   
                     {filterOptions.length>0 &&  searchValue === "" &&
                    <label className="form-check">
                    <input onChange={()=>{ selectAll()}} className="form-check-input" type="checkbox" checked={selectAllStatus} />
                    <span className="checkmark"></span>
                    <span className="form-check-label">Select All</span>
                    </label>}
                    
                  </Dropdown.ItemText>

                  {filterOptions.length>0 && filterOptions.map((option,index) => {
                  return (
                  <Dropdown.ItemText key={`index${rowIndex}${index}`}>
                    <label className="form-check">
                    <input
                          onChange={(e) => setSelectedOptions(option)}
                          checked={option.checked}
                          className="form-check-input"
                          type="checkbox"
                          key={`index${rowIndex}${index}`}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">{option.label}</span>
                    </label>
                  </Dropdown.ItemText>
                   );
                })}
              </>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}
