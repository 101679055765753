import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import DayChart from '../Images/day-chart.PNG';
import WeekChart from '../Images/week-chart.PNG';
import MonthChart from '../Images/month-chart.PNG';

export default function ChartReport(props) {
    return <>
        <div className="container-fluid padding-40">

            <div className="row">
                <div className="col-md-3">
                    <ul className="nav nav-pills" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#1st-tab" role="tab" aria-controls="home" aria-selected="true">Deal Reports<span className="pull-right"><FontAwesomeIcon icon={faAngleRight} /></span></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#2nd-tab" role="tab" aria-controls="profile" aria-selected="false">Technical Reports<span className="pull-right"><FontAwesomeIcon icon={faAngleRight} /></span></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#3rd-tab" role="tab" aria-controls="contact" aria-selected="false">Trend Charts<span className="pull-right"><FontAwesomeIcon icon={faAngleRight} /></span></a>
                        </li>
                    </ul>
                </div>

                <div className="col-md-9 mb-3">
                    <div className="tab-content">
                        <div className="tab-pane active" id="1st-tab" role="tabpanel" aria-labelledby="tab-1">
                            <h2>Home</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque, eveniet earum. Sed accusantium eligendi molestiae quo hic velit nobis et, tempora placeat ratione rem blanditiis voluptates vel ipsam? Facilis, earum!</p>

                        </div>
                        <div className="tab-pane" id="2nd-tab" role="tabpanel" aria-labelledby="tab-2">
                            <h2>Profile</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque, eveniet earum. Sed accusantium eligendi molestiae quo hic velit nobis et, tempora placeat ratione rem blanditiis voluptates vel ipsam? Facilis, earum!</p>

                        </div>
                        <div className="tab-pane" id="3rd-tab" role="tabpanel" aria-labelledby="tab-3">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#tabs-1">Day</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tabs-2">Week</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Month</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                    <img src={DayChart} alt="Day chart" className="img-responsive" />
                                </div>
                                <div className="tab-pane" id="tabs-2" role="tabpanel">
                                    <img src={WeekChart} alt="Week chart" className="img-responsive" />
                                </div>
                                <div className="tab-pane" id="tabs-3" role="tabpanel">
                                    <img src={MonthChart} alt="Month chart" className="img-responsive" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

        </div>
    </>
}