import React, { Component } from "react";
import BOMetricDetails from "../SuperAdmin/BOMetricDetails";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import i18n from "i18next";
import BOCategoryPopUp from "../SuperAdmin/BOCategoryPopUp";
import ExportCsv from './ExportCsv';
import emptycomponent from "../Images/Empty State.svg";

axiosInstance.defaults.headers.common[
    "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class BOContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boData: [],
            attributeLabels: [],
            viewmore: false,
            type: null,
            isRelatorBlockForOffering: false
        };
        this.onClose = this.onClose.bind(this);
    }

    dealpopup(type, e) {
        e.preventDefault();
        this.setState({ viewmore: true, type: type });
    }

    onClose() {
        this.setState({ viewmore: false });
    }

    getBoDetails(offeringId) { }

    componentDidMount() {
        store.subscribe(() => {
            var data = store.getState();

            this.setState({
                boData: data.BOMasterReducers["BO"],
                isRelatorBlockForOffering: data.CurrentFilter.IsRelatorBlock
            });
        });

        if (this.props.filterDetails) {
            if (this.props.filterDetails.process !== 0)
                this.getAttributes(this.props.filterDetails.process);
        }


    }

    getAttributes(selectedOffering) {
        axiosInstance
            .get(`${LocalApiBaseUrl}Attribute/GetAttributeCategory`, {
                params: { OfferingID: selectedOffering }
            })

            .then(response => {
                let mappingData = response.data;
                //If IsActive==false, we should not show the attribute names in the UI so adding a filter on the api reponse
                let finalMappingData = mappingData.filter(
                    attr => attr.IsActive == true
                );

                this.setState({ attributeLabels: finalMappingData }, () => {
                    //add the labels into the store

                    store.dispatch({
                        type: "INITIALISE_ATTRIBUTE_LABELSMTC",
                        payload: finalMappingData

                    });

                    //All offerings will have attribute labels
                });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
      
    }

    render() {
        return (
            <div className="row">
             <ExportCsv csvProp = {this.props.filterDetails.process}/>
                <div className="col-lg-12">
                <div className="title-with-icon d-flex align-items-center mb-4">
                    {this.props.roledata != undefined && store.getState().CurrentFilter.languageData.id==1 &&
                        this.props.roledata.includes("Add") ? (
                                <div className="list-icon mtc-list-icon">
                                    <span
                                        onClick={e => {
                                            this.dealpopup("Add SO Category", e);
                                        }}
                                    >
                                        <i className="far fa-plus plus-icon-fix"></i>
                                    </span>
                                </div>
                        ) : null}
                    </div>
                    {(this.state.boData.length !== 0 &&
                        this.state.boData.length > 0) ?(
                        this.state.boData.map(SOData => (
                            <BOMetricDetails
                                SODetails={SOData}
                                filterDetails={this.props.filterDetails}
                                roledata={this.props.roledata}
                                isRelatorBlockForOffering={this.state.isRelatorBlockForOffering}
                                attributeLabels={this.state.attributeLabels}
                            />
                        ))):(!this.props.active && <div className="emptymetric-msg">
                        <img alt="" src={emptycomponent} width="80px" height="80px" />
                      <h6>{i18n.t("Please click on '+' to add category")}</h6>
                    </div>)}
                    {this.state.viewmore ? (
                        <BOCategoryPopUp
                            show={this.state.viewmore}
                            onClose={this.onClose}
                            filterDetails={this.props.filterDetails}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}
