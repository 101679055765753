import React from 'react'
import { Modal } from "react-bootstrap";
import Success from "../Images/Success.svg";
import i18n from "i18next";

function MORNotification({
  Singledata,
  isShowNotification,
  closeNotication,
  TotalSelectedCount,
  isMovedToArchieve,
  isMovedToFavourite,
  isReMovedFromArchieve,
  isRemovedFromFavourite,
  isFolderAdded,
  isFolderEdited,
  isItemAdded,
  isItemEdited,
  isDeleted,
  folderItemMessage,
  dataToDelete,
  deleteItemModalStatus,
  deleteItemModalClose,
  deleteData
}) {
    return (
        <div>
            <Modal
          show={isShowNotification}
          onHide={()=>{closeNotication()}}
          className="successNotification-modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t("Notification")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="successnotification-body">
              <div className="successnotification-icon">
              <span>
                  <img src={Success} width="40px" height="40px"alt="Sucess" />
                </span>
              </div>
              <div className="successnotification-data">
                <p>
           
                  <b>
                  {!(isFolderAdded || isItemAdded || isItemEdited || isFolderEdited) ?( dataToDelete.length === 1 ? dataToDelete[0].DocName 
                  :Singledata!=="" && Singledata!==null?Singledata:`${TotalSelectedCount} Items`):""} 
                  </b> 
                  &nbsp;
                {isMovedToArchieve && !isMovedToFavourite && i18n.t("Multi_Archived_SucessFully")}
                {!isMovedToArchieve && isMovedToFavourite && i18n.t("Multi_Marked_Favourites")}
                {isReMovedFromArchieve && !isRemovedFromFavourite && i18n.t("UnArchived_SucessFully")}
                {isRemovedFromFavourite && !isReMovedFromArchieve && i18n.t("UnMarked_Favourite")}
                {isDeleted && i18n.t("Data_Deleted_Success")}
                {(isFolderAdded || isFolderEdited  || isItemAdded || isItemEdited) && `${folderItemMessage}`}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
         {/* Delete MOR Item Modal Popup Start*/}
        {deleteItemModalStatus && <Modal
          show={deleteItemModalStatus}
          onHide={deleteItemModalClose}
          className="deletemoritem-modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title className="deletemoritem-title">
              {i18n.t("Delete")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="deletenotification-body">
              <div className="deletenotification-icon">
                <span>
                  <i class="fal fa-exclamation-triangle"></i>
                </span>
              </div>
              <div className="deletenotification-data">
                {dataToDelete.length >1 ?
                <p>
                {i18n.t("Are you sure you want to delete this data?")}
              </p>
              :
              <p>
                  {i18n.t("Are you sure you want to delete this")}&nbsp;
                  <b>
                      {dataToDelete[0].DocName}
                  </b>
                  ?
                </p>
              }
                
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn deletemoritem-btns">
              <button
                onClick={deleteItemModalClose}
                className="btn btn-default btn-sm"
              >
                {i18n.t("Cancel")}
              </button>
              <button
                type="Button"
                className="btn btn-danger btn-sm"
                onClick={() =>{ deleteData() }}
              >
                {i18n.t("Delete")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
}
        {/* Delete MOR Item Modal Popup End*/}
        </div>
    )
}

export default MORNotification
