import React, { Component } from 'react';
import { LocalApiBaseUrl } from '../Shared/Constant';
import ReactDataGrid from "react-data-grid";
import { Editors } from "react-data-grid-addons";
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;




const { DropDownEditor } = Editors;


const issueTypes2 = [
    { id: "bro", value: "" },
    { id: "bug", value: ">" },
    { id: "epic", value: "<" },
    { id: "story1", value: "=" },
    { id: "story2", value: "!=" },
    { id: "story3", value: "IN" }


];
const issueTypes3 = [
    { id: "bug", value: "" },
    { id: "bag", value: "AND" },
    { id: "epic", value: "OR" },
];


///if IsMaster=true
const issueTypes4 = [
    { id: "bug", value: "master1" },
    { id: "bag", value: "master2" },
    { id: "epic", value: "master3" },
];

const IssueTypeEditor2 = <DropDownEditor options={issueTypes2} />;
const IssueTypeEditor3 = <DropDownEditor options={issueTypes3} />;


///if IsMaster=true
const IssueTypeEditor4 = <DropDownEditor options={issueTypes4} />;


const GridOnecolumns = [
    {
        key: "FieldName", name: "Field Name", editable: true, editor: <DropDownEditor options={[
            { id: "bro", value: "" },
            { id: "bug", value: "Offering" },
            { id: "epic", value: "Tower" },
            { id: "story", value: "Client" },
            { id: "store", value: "Delivery Location" }

        ]} />
    },
    { key: "Operator", name: "Operator", editable: true, editor: IssueTypeEditor2 },
    { key: "Value", name: "Value", editable: true },
    { key: "Condition", name: "Condition", editable: true, editor: IssueTypeEditor3 },


];


export default class DealConnectorPopup extends Component {
    constructor(props) {

        super(props)
        this.state = {
            issueTypes1: [], error: [], childValue: [], show: false, RowsCount: null, MasterValues: [],

            GridOnecolumns: [
                { key: "FieldName", name: "Field Name", editable: true, editor: <DropDownEditor options={this.props.Fields} /> },
                { key: "Operator", name: "Operator", editable: true, editor: IssueTypeEditor2 },
                { key: "Value", name: "Value", editable: true },
                { key: "Condition", name: "Condition", editable: true, editor: IssueTypeEditor3 },
            ],
            GridOnecolumnsForMaster: [
                { key: "FieldName", name: "Field Name", editable: true, editor: <DropDownEditor options={this.props.Fields} /> },
                { key: "Operator", name: "Operator", editable: true, editor: IssueTypeEditor2 },
                { key: "Value", name: "Value", editable: true, editor: <DropDownEditor options={this.props.Fields} /> },
                { key: "Condition", name: "Condition", editable: true, editor: IssueTypeEditor3 },
            ],

            Model: {
                DealFilters: [
                    { FieldName: "", Operator: "", Value: "", Condition: "" },
                    { FieldName: "", Operator: "", Value: "", Condition: "" },
                    { FieldName: "", Operator: "", Value: "", Condition: "" },
                    { FieldName: "", Operator: "", Value: "", Condition: "" },
                    { FieldName: "", Operator: "", Value: "", Condition: "" },
                    { FieldName: "", Operator: "", Value: "", Condition: "" },
                ],
                DealID: null,
                ConnectorID: null,
                IsActive: false,
            },


        };
        this.onFirstGridRowsUpdated = this.onFirstGridRowsUpdated.bind(this);
        this.getMasterValues = this.getMasterValues.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);



    }
    onFirstGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        debugger;
        this.state.IsMaster = false
        this.state.MasterValues = []
        const DealFilters = this.state.Model.DealFilters.slice();
        for (let i = fromRow; i <= toRow; i++) {
            DealFilters[i] = { ...DealFilters[i], ...updated };

            let x = this.getMasterValues(this.props.connectorData.ConnectorID, DealFilters[i].FieldName);

        }
        this.setState({
            Model: { ...this.state.Model, DealFilters: DealFilters }
        });
        if (this.state.MasterValues.length != 0) {
            this.GridOnecolumnsForMaster()
        }
    };


    onStatusChange(event) {
        debugger
        const Model = this.state.Model;
        Model.IsActive = event.target.checked;
        this.setState({
            Model: Model
        })
    }


    GridOnecolumnsForMaster() {
        debugger

        let GridOnecolumnsForMaster = this.state.GridOnecolumnsForMaster
        if (this.state.MasterValues.length != 0) {

            this.state.GridOnecolumnsForMaster = [
                { key: "FieldName", name: "Field Name", editable: true, editor: <DropDownEditor options={this.props.Fields} /> },
                { key: "Operator", name: "Operator", editable: true, editor: IssueTypeEditor2 },
                { key: "Value", name: "Value", editable: true, editor: <DropDownEditor options={this.state.MasterValues} /> },
                { key: "Condition", name: "Condition", editable: true, editor: IssueTypeEditor3 },
            ]

        }


    };



    getMaster(val) {
        this.setState({

            IsMaster: val

        });
    }


    setChildValue() {

        if (this.state != undefined) {
            return this.state.childValue;
        }
        else {
            return null
        }
    }




    RowsCount() {
        let DealFilters = this.state.Model.DealFilters;
        for (var i = 0; i < 3; i++) {
            DealFilters = [...DealFilters, { FieldName: "", Operator: "", Value: "", Condition: "" }]
            this.setState({

                Model: { ...this.state.Model, DealFilters: DealFilters }

            });
        }
    }


    handleSave() {
        if (this.validateForm()) {
            this.props.onSave(this.state.Model);
        }
    }

    getMasterValues(ConnectorName, FieldName) {
        let MasterValues = this.state.MasterValues
        let IsMaster = this.state.IsMaster

        axiosInstance.get(`${LocalApiBaseUrl}QueryBuilder/GetMasterKeyData`, {

            params: {
                ConnectorID: ConnectorName, FieldName: FieldName,
            }
        }
        ).then((response) => {

            response.data[0].BATMasters.map((row) => {

                MasterValues.push(row.MasterValue)
                IsMaster = response.data[0].HasMaster
            })

            this.setState({
                MasterValues: MasterValues,
                IsMaster: IsMaster


            })

        })
            .catch((error) => {
                trycatchAlertPopup(error);
            });


    }



    componentDidMount() {
        this.setState({
            show: this.props.show
        })

        if (this.props.data) {
            this.setState({
                Model: this.props.fullData[0],
            })

        }
        else {
            const Model = this.state.Model;
            Model.ConnectorID = this.props.connectorData.ConnectorID;
            Model.DealID = this.props.connectorData.DealID;


            this.setState({
                Model: Model,
            })
        }
    }


    validateForm() {

        let Model = this.state.Model;
        let errors = {};
        let formIsValid = true;
        if (Model.DealFilters[0].FieldName == "" || Model.DealFilters[0].Operator == "" || Model.DealFilters[0].Value == "") {
            formIsValid = false;
            errors["DealFilters"] = "Enter Filter values";

        }
        this.setState({
            errors: errors
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;


    }






    render() {
        if (this.state) {
            if (this.state.MasterValues.length != 0) {
                this.GridOnecolumnsForMaster()
            }
        }
        return (
            <>

                {this.props.show ?
                    <>
                        <form>
                            <div className="title-with-icon">
                                <h2 className="ht-title">Deal Filters</h2>
                            </div>
                            <div className="form-section">
                                <div className="form-row">
                                    <div className="form-group col-lg-6">
                                        <label className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={this.onStatusChange.bind(this)} defaultChecked={!this.props.data ? this.state.Model.IsActive : this.props.data.IsActive} />
                                            <span className="checkmark"></span>
                                            <span className="form-check-label">Is Active</span>
                                        </label>
                                        {this.state.errors != undefined ? <span className="errorMsg">{this.state.errors.DealFilters}</span> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <ReactDataGrid
                                        columns={this.state.MasterValues.length != 0 ? this.state.GridOnecolumnsForMaster : this.state.GridOnecolumns}
                                        rowGetter={i => this.state.Model.DealFilters[i]}
                                        rowsCount={this.state.Model.DealFilters.length}
                                        onGridRowsUpdated={this.onFirstGridRowsUpdated}
                                        enableCellSelect={true}
                                        minHeight={260}
                                        minWidth={950}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="d-flex justify-content-center">
                                        {this.props.data ? <input type="Button" className="btn btn-primary btn-sm mx-3" onClick={() => this.handleSave()} value="Update" /> : <input type="Button" className="btn btn-primary btn-sm mx-3" onClick={() => this.handleSave()} value="Create" />}
                                        <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.RowsCount()} value="Add Row" />
                                    </div>



                                </div>
                            </div>
                        </form>
                    </>
                    : null}

            </>
        );
    }





}

