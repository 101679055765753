import React, { Component } from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { store } from '../reducers/configureStore';
import { regexForFields, LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import BeforeAfterLandingPageComments from './BeforeAfterLandingPageComments';
import axiosInstance from '../Shared/interceptor';

class BeforeAfterTimeline extends Component {
  constructor() {
    super();
    this.state = {

    };
  }

  truncateDecimals = function (number) {
    return Math[number < 0 ? 'ceil' : 'floor'](number);
  };

  render() {
    const { timeLineData, showComments, stepName } = this.props;
    const isMetricDetail = !!((stepName === 'BO' || stepName === "COMP" || stepName === 'QA'));
    const timeline = timeLineData.TimeseriesData.filter((a) => !a.isDeleted);
    const stringValueTypes = ['DSP', 'WC', "NPS"];
    let BaselineValue = String(timeLineData.ValueUnit !== 'String' ? parseFloat(timeLineData.BaselineValue) : timeLineData.BaselineValue);
    let TargetValue = String(timeLineData.ValueUnit !== 'String' ? parseFloat(timeLineData.TargetValue) : timeLineData.TargetValue);
    let TargetValue2 = String(timeLineData.ValueUnit !== 'String' ? parseFloat(timeLineData.TargetValue2) : timeLineData.TargetValue2);
    let CurrentValue = String(timeLineData.ValueUnit !== 'String' ? parseFloat(timeLineData.CurrentValue) : timeLineData.CurrentValue);
    let BaselineTooltipValue = '';
    let CurrentTooltipValue = '';
    let TargetTooltipValue = '';
    let TargetTooltipValue2 = '';
    if (timeLineData.ValueUnit !== 'String' && timeLineData.BaselineValue.split('-').length > 1) {
      const temp = [];
      const truncatedValue = [];
      if (timeLineData.BaselineValue.split('-')[0].split('.')[1] && timeLineData.BaselineValue.split('-')[0].split('.')[1].length > 2) {
        temp[0] = String(this.truncateDecimals(timeLineData.BaselineValue.split('-')[0] * 100) / 100);
      } else {
        temp[0] = String(timeLineData.BaselineValue.split('-')[0]);
      }
      if (timeLineData.BaselineValue.split('-')[1].split('.')[1] && timeLineData.BaselineValue.split('-')[1].split('.')[1].length > 2) {
        temp[1] = String(this.truncateDecimals(timeLineData.BaselineValue.split('-')[1] * 100) / 100);
      } else {
        temp[1] = String(timeLineData.BaselineValue.split('-')[1]);
      }
      BaselineValue = temp.join('-');
      if (BaselineValue === '') {
        BaselineValue = String(timeLineData.BaselineValue());
      }
      if (String(BaselineValue).length > 9) {
        if (temp[0].length > 4) {
          truncatedValue[0] = temp[0].substring(0, 3).concat('...');
        } else {
          truncatedValue[0] = temp[0];
        }
        if (temp[1].length > 4) {
          truncatedValue[1] = temp[1].substring(0, 3).concat('...');
        } else {
          truncatedValue[1] = temp[1];
        }
        BaselineTooltipValue = truncatedValue.join('-');
      }
    } else {
      if (timeLineData.ValueUnit !== 'String' && timeLineData.BaselineValue.split('.')[1] && timeLineData.BaselineValue.split('.')[1].length > 2) {
        BaselineValue = this.truncateDecimals(timeLineData.BaselineValue * 100) / 100;
      } else if (timeLineData.ValueUnit !== 'String' && timeLineData.BaselineValue.split('.')[1] && timeLineData.BaselineValue.split('.')[1].length <= 2) {
        BaselineValue = String(timeLineData.BaselineValue);
      }
      if (BaselineValue === '') {
        BaselineValue = String(timeLineData.BaselineValue);
      }
      if (String(BaselineValue).length > 9) {
        BaselineTooltipValue = String(BaselineValue).substring(0, 8).concat('...');
      }
    }

    if (timeLineData.ValueUnit !== 'String' && timeLineData.CurrentValue.split('-').length > 1) {
      const temp = [];
      const truncatedValue = [];
      if (timeLineData.CurrentValue.split('-')[0].split('.')[1] && timeLineData.CurrentValue.split('-')[0].split('.')[1].length > 2) {
        temp[0] = String(this.truncateDecimals(timeLineData.CurrentValue.split('-')[0] * 100) / 100);
      } else {
        temp[0] = String(timeLineData.CurrentValue.split('-')[0]);
      }
      if (timeLineData.CurrentValue.split('-')[1].split('.')[1] && timeLineData.CurrentValue.split('-')[1].split('.')[1].length > 2) {
        temp[1] = String(this.truncateDecimals(timeLineData.CurrentValue.split('-')[1] * 100) / 100);
      } else {
        temp[1] = String(timeLineData.CurrentValue.split('-')[1].toString());
      }
      CurrentValue = temp.join('-');
      if (CurrentValue === '') {
        CurrentValue = String(timeLineData.CurrentValue());
      }
      if (String(CurrentValue).length > 9) {
        if (temp[0].length > 4) {
          truncatedValue[0] = temp[0].substring(0, 3).concat('...');
        } else {
          truncatedValue[0] = temp[0];
        }
        if (temp[1].length > 4) {
          truncatedValue[1] = temp[1].substring(0, 3).concat('...');
        } else {
          truncatedValue[1] = temp[1];
        }
        CurrentTooltipValue = truncatedValue.join('-');
      }
    } else {
      if (timeLineData.ValueUnit !== 'String' && timeLineData.CurrentValue.split('.')[1] && timeLineData.CurrentValue.split('.')[1].length > 2) {
        CurrentValue = this.truncateDecimals(timeLineData.CurrentValue * 100) / 100;
      } else if (timeLineData.ValueUnit !== 'String' && timeLineData.CurrentValue.split('.')[1] && timeLineData.CurrentValue.split('.')[1].length <= 2) {
        CurrentValue = timeLineData.CurrentValue.toString();
      }
      if (CurrentValue === '') {
        CurrentValue = timeLineData.CurrentValue.toString();
      }
      if (String(CurrentValue).length > 9) {
        CurrentTooltipValue = String(CurrentValue).substring(0, 8).concat('...');
      }
    }

    if (timeLineData.ValueUnit !== 'String' && timeLineData.TargetValue.split('-').length > 1) {
      const temp = [];
      const truncatedValue = [];
      if (timeLineData.TargetValue.split('-')[0].split('.')[1] && timeLineData.TargetValue.split('-')[0].split('.')[1].length > 2) {
        temp[0] = String(this.truncateDecimals(timeLineData.TargetValue.split('-')[0] * 100) / 100);
      } else {
        temp[0] = String(timeLineData.TargetValue.split('-')[0]);
      }
      if (timeLineData.TargetValue.split('-')[1].split('.')[1] && timeLineData.TargetValue.split('-')[1].split('.')[1].length > 2) {
        temp[1] = String(this.truncateDecimals(timeLineData.TargetValue.split('-')[1] * 100) / 100);
      } else {
        temp[1] = String(timeLineData.TargetValue.split('-')[1]);
      }
      TargetValue = temp.join('-');
      if (TargetValue === '') {
        TargetValue = timeLineData.TargetValue;
      }
      if (String(TargetValue).length > 9) {
        if (temp[0].length > 4) {
          truncatedValue[0] = temp[0].substring(0, 3).concat('...');
        } else {
          truncatedValue[0] = temp[0];
        }
        if (temp[1].length > 4) {
          truncatedValue[1] = temp[1].substring(0, 3).concat('...');
        } else {
          truncatedValue[1] = temp[1];
        }
        TargetTooltipValue = truncatedValue.join('-');
      }
    } else {
      if (timeLineData.ValueUnit !== 'String' && timeLineData.TargetValue.split('.')[1] && timeLineData.TargetValue.split('.')[1].length > 2) {
        TargetValue = this.truncateDecimals(timeLineData.TargetValue * 100) / 100;
      } else if (timeLineData.ValueUnit !== 'String' && timeLineData.TargetValue.split('.')[1] && timeLineData.TargetValue.split('.')[1].length <= 2) {
        TargetValue = timeLineData.TargetValue;
      }
      if (TargetValue === '') {
        TargetValue = timeLineData.TargetValue;
      }
      if (String(TargetValue).length > 9) {
        TargetTooltipValue = String(TargetValue).substring(0, 8).concat('...');
      }
    }
    if (timeLineData.ValueUnit !== 'String' && timeLineData.TargetValue2 && timeLineData.TargetValue2.split('-').length > 1) {
      const temp = [];
      const truncatedValue = [];
      if (timeLineData.TargetValue2.split('-')[0].split('.')[1] && timeLineData.TargetValue2.split('-')[0].split('.')[1].length > 2) {
        temp[0] = String(this.truncateDecimals(timeLineData.TargetValue2.split('-')[0] * 100) / 100);
      } else {
        temp[0] = String(timeLineData.TargetValue2.split('-')[0]);
      }
      if (timeLineData.TargetValue2.split('-')[1].split('.')[1] && timeLineData.TargetValue2.split('-')[1].split('.')[1].length > 2) {
        temp[1] = String(this.truncateDecimals(timeLineData.TargetValue2.split('-')[1] * 100) / 100);
      } else {
        temp[1] = String(timeLineData.TargetValue2.split('-')[1]);
      }
      TargetValue2 = temp.join('-');
      if (TargetValue2 === '') {
        TargetValue2 = timeLineData.TargetValue;
      }
      if (String(TargetValue2).length > 9) {
        if (temp[0].length > 4) {
          truncatedValue[0] = temp[0].substring(0, 3).concat('...');
        } else {
          truncatedValue[0] = temp[0];
        }
        if (temp[1].length > 4) {
          truncatedValue[1] = temp[1].substring(0, 3).concat('...');
        } else {
          truncatedValue[1] = temp[1];
        }
        TargetTooltipValue2 = truncatedValue.join('-');
      }
    } else {
      if (timeLineData.ValueUnit !== 'String' && timeLineData.TargetValue2 && timeLineData.TargetValue2.split('.')[1] && timeLineData.TargetValue2.split('.')[1].length > 2) {
        TargetValue2 = this.truncateDecimals(timeLineData.TargetValue2 * 100) / 100;
      } else if (timeLineData.ValueUnit !== 'String' && timeLineData.TargetValue2 && timeLineData.TargetValue2.split('.')[1] && timeLineData.TargetValue2.split('.')[1].length <= 2) {
        TargetValue = timeLineData.TargetValue2;
      }
      if (TargetValue2 === '') {
        TargetValue2 = timeLineData.TargetValue2;
      }
      if (TargetValue2 && String(TargetValue2).length > 9) {
        TargetTooltipValue2 = String(TargetValue2).substring(0, 8).concat('...');
      }
    }

    if (timeline) {
      return (
        <div className="mapping-commentstimeline">
          <ul className="mapping-timeline">
            <li>
              <div className="time-series-top">Baseline</div>
              <div className="time-series-bottom">
                <p className="month-line">{moment(new Date(timeLineData.BaselineYear)).format("MMM'YY")}</p>
                {stepName === "IO" ? timeLineData.BaselineIOJStage.length > 10 && ((timeline && timeline.filter((a) => a.isDeleted === false).length >= 2) || timeLineData.ValueUnit === 'String') ? <p data-tip data-for={`Baseline${timeLineData.BaselineIOJStage}`}>{timeLineData.BaselineIOJStage.substring(0, 9).concat('...')}</p> : <p>{timeLineData.BaselineIOJStage}</p> : `${BaselineValue}`.length > 9 && ((timeline && timeline.filter((a) => a.isDeleted === false).length >= 1) || timeLineData.ValueUnit === 'String') && BaselineTooltipValue ? <p data-tip data-for={`Baseline${BaselineValue}`}>{BaselineTooltipValue}</p> : <p>{BaselineValue}</p> }
                {
                      BaselineValue && String(BaselineValue).length > 9 && BaselineTooltipValue && (timeline.filter((a) => a.isDeleted === false).length >= 1 || timeLineData.ValueUnit === 'String')
                      && (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`Baseline${BaselineValue}`} className="timelinedigit-tooltips">
                        {BaselineValue}
                      </ReactTooltip>
                      )
                    }
                {
                      timeLineData.BaselineIOJStage && timeLineData.BaselineIOJStage.length > 9 && timeline.filter((a) => a.isDeleted === false).length >= 2
                      && (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`Baseline${timeLineData.BaselineIOJStage}`} className="timelinedigit-tooltips">
                        {timeLineData.BaselineIOJStage}
                      </ReactTooltip>
                      )
                    }

              </div>
            </li>

            {timeline.length ? (
              <li className="timeline-series">
                <div className="time-series-top">Timeseries</div>
                <ul>
                  {timeline.map((each, index) => {
                    if (!each.isDeleted) {
                      let timeValue = timeLineData.ValueUnit !== 'String' ? String(parseFloat(each.Value)) : each.Value;
                      let tooltipValue = '';
                      if (timeLineData.ValueUnit !== 'String' && each.Value.split('-').length > 1) {
                        const temp = [];
                        const truncatedValue = [];
                        if (each.Value.split('-')[0].split('.')[1] && each.Value.split('-')[0].split('.')[1].length > 2) {
                          temp[0] = String(this.truncateDecimals(each.Value.split('-')[0] * 100) / 100);
                        } else {
                          temp[0] = String(each.Value.split('-')[0]);
                        }
                        if (each.Value.split('-')[1].split('.')[1] && each.Value.split('-')[1].split('.')[1].length > 2) {
                          temp[1] = String(this.truncateDecimals(each.Value.split('-')[1] * 100) / 100);
                        } else {
                          temp[1] = String(each.Value.split('-')[1]);
                        }
                        timeValue = temp.join('-');
                        if (timeValue === '') {
                          timeValue = each.Value;
                        }
                        if (String(timeValue).length > 7) {
                          if (temp[0].length > 3) {
                            truncatedValue[0] = temp[0].substring(0, 2).concat('...');
                          } else {
                            truncatedValue[0] = temp[0];
                          }
                          if (temp[1].length > 3) {
                            truncatedValue[1] = temp[1].substring(0, 2).concat('...');
                          } else {
                            truncatedValue[1] = temp[1];
                          }
                          tooltipValue = truncatedValue.join('-');
                        }
                      } else {
                        if (timeLineData.ValueUnit !== 'String' && each.Value.split('.')[1] && each.Value.split('.')[1].length > 2) {
                          timeValue = this.truncateDecimals(each.Value * 100) / 100;
                        }
                        if (timeValue === '') {
                          timeValue = each.Value;
                        }
                        if (String(timeValue).length > 7) {
                          tooltipValue = String(timeValue).substring(0, 6).concat('...');
                        }
                      }
                      return (
                        <li key={each.Year}>
                          <div className="time-series-bottom">
                            <p className="month-line">{moment(new Date(each.Year)).format("MMM'YY")}</p>

                            {stepName === "IO" ? each.IOJStage && each.IOJStage.length > 7 && ((timeline && timeline.filter((a) => a.isDeleted === false).length >= 2) || timeLineData.ValueUnit === 'String') ? <p data-tip data-for={`each${index}${each.IOJStage}`}>{each.IOJStage.substring(0, 6).concat('...')}</p> : <p>{each.IOJStage}</p> : `${timeValue}`.length > 7 && ((timeline && timeline.filter((a) => a.isDeleted === false).length >= 1) || timeLineData.ValueUnit === 'String') && tooltipValue ? <p data-tip data-for={`each${index}${timeValue}`}>{tooltipValue}</p> : <p>{timeValue}</p>}
                            {
                                  `${timeValue}`.length > 7 && ((timeline && timeline.filter((a) => a.isDeleted === false).length >= 1) || timeLineData.ValueUnit === 'String')
                                  && (
                                  <ReactTooltip place="bottom" type="dark" effect="solid" id={`each${index}${timeValue}`} className="timelinedigit-tooltips">
                                    {timeValue}
                                  </ReactTooltip>
                                  )
                                }
                            {
                                  ((timeline && timeline.filter((a) => a.isDeleted === false).length >= 2) || timeLineData.ValueUnit === 'String') && `${each.IOJStage}`.length > 7
                                  && (
                                  <ReactTooltip place="bottom" type="dark" effect="solid" id={`each${index}${each.IOJStage}`} className="timelinedigit-tooltips">
                                    {each.IOJStage}
                                  </ReactTooltip>
                                  )
                                }

                          </div>
                        </li>
                      );
                    }
                  })}
                </ul>
              </li>
            ) : null }
            <li>
              <div className="time-series-top">Current</div>
              <div className="time-series-bottom">
                <p className="month-line">{moment(new Date(timeLineData.CurrentYear)).format("MMM'YY")}</p>
                {stepName === "IO" ? timeLineData.CurrentIOJStage.length > 10 && ((timeline && timeline.filter((a) => a.isDeleted === false).length >= 2) || timeLineData.ValueUnit === 'String') ? <p data-tip data-for={`Current${timeLineData.CurrentIOJStage}`}>{timeLineData.CurrentIOJStage.substring(0, 9).concat('...')}</p> : (
                  <p>
                    {' '}
                    {timeLineData.CurrentIOJStage}
                    {' '}
                  </p>
                ) : `${CurrentValue}`.length > 9 && (timeline.length >= 1 || timeLineData.ValueUnit === 'String') && CurrentTooltipValue ? <p data-tip data-for={`Current${CurrentValue}`}>{CurrentTooltipValue}</p> : <p>{CurrentValue}</p> }

                {
                       CurrentValue && `${CurrentValue}`.length > 9 && CurrentTooltipValue && (timeline.filter((a) => a.isDeleted === false).length >= 1 || timeLineData.ValueUnit === 'String')
                       && (
                       <ReactTooltip place="bottom" type="dark" effect="solid" id={`Current${CurrentValue}`} className="timelinedigit-tooltips">
                         {CurrentValue}
                       </ReactTooltip>
                       )
                     }
                {
                       timeLineData.CurrentIOJStage && timeLineData.CurrentIOJStage.length > 10 && timeline.filter((a) => a.isDeleted === false).length >= 2
                      && (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`Current${timeLineData.CurrentIOJStage}`} className="timelinedigit-tooltips">
                        {timeLineData.CurrentIOJStage}
                      </ReactTooltip>
                      )
                    }

              </div>
            </li>
            <li>
              <div className="time-series-top">Target</div>
              <div className="time-series-bottom">
                <p className="month-line">{moment(new Date(timeLineData.TargetYear)).format("MMM'YY")}</p>
                {stepName === "IO" ? timeLineData.TargetIOJStage.length > 10 && timeline.filter((a) => a.isDeleted === false).length >= 2 ? <p data-tip data-for={`Target${timeLineData.TargetIOJStage}`}>{timeLineData.TargetIOJStage.substring(0, 8).concat('...')}</p> : <p>{timeLineData.TargetIOJStage}</p> : `${TargetValue}`.length > 9 && ((timeline && timeline.filter((a) => a.isDeleted === false).length >= 1) || timeLineData.ValueUnit === 'String') && TargetTooltipValue ? <p data-tip data-for={`Target${TargetValue}`}>{TargetTooltipValue}</p> : <p>{TargetValue}</p> }
                {
                      TargetValue && `${TargetValue}`.length > 9 && TargetTooltipValue && (timeline.filter((a) => a.isDeleted === false).length >= 1 || timeLineData.ValueUnit === 'String')
                      && (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`Target${TargetValue}`} className="timelinedigit-tooltips">
                        {TargetValue}
                      </ReactTooltip>
                      )
                     }
                {
                      timeLineData.TargetIOJStage && timeLineData.TargetIOJStage.length > 10 && timeline.filter((a) => a.isDeleted === false).length >= 2
                      && (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`Target${timeLineData.TargetIOJStage}`} className="timelinedigit-tooltips">
                        {timeLineData.TargetIOJStage}
                      </ReactTooltip>
                      )
                    }
              </div>
            </li>
            {timeLineData.TargetYear2 ? (
              <li>
                <div className="time-series-top">Target</div>
                <div className="time-series-bottom">
                  <p className="month-line">{moment(new Date(timeLineData.TargetYear2)).format("MMM'YY")}</p>
                  {stepName === "IO" ? timeLineData.TargetIOJStage2.length > 10 && timeline.filter((a) => a.isDeleted === false).length >= 2 ? <p data-tip data-for={`Target${timeLineData.TargetIOJStage2}`}>{timeLineData.TargetIOJStage2.substring(0, 8).concat('...')}</p> : <p>{timeLineData.TargetIOJStage2}</p> : `${TargetValue2}`.length > 9 && ((timeline && timeline.filter((a) => a.isDeleted === false).length >= 1) || timeLineData.ValueUnit === 'String') && TargetTooltipValue2 ? <p data-tip data-for={`Target${TargetValue2}`}>{TargetTooltipValue2}</p> : <p>{TargetValue2}</p> }
                  {
                      TargetValue2 && `${TargetValue2}`.length > 9 && TargetTooltipValue2 && (timeline.filter((a) => a.isDeleted === false).length >= 1 || timeLineData.ValueUnit === 'String')
                      && (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`Target${TargetValue2}`} className="timelinedigit-tooltips">
                        {TargetValue2}
                      </ReactTooltip>
                      )
                     }
                  {
                      timeLineData.TargetIOJStage2 && timeLineData.TargetIOJStage2.length > 10 && timeline.filter((a) => a.isDeleted === false).length >= 2
                      && (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`Target${timeLineData.TargetIOJStage2}`} className="timelinedigit-tooltips">
                        {timeLineData.TargetIOJStage2}
                      </ReactTooltip>
                      )
                    }
                </div>
              </li>
            ) : null}
          </ul>
          {isMetricDetail && timeLineData.MetricUnit && timeLineData.MetricUnit !== undefined && timeLineData.MetricUnit.toLowerCase() !== "string" && (
          <p className="mappinglabel-unit">
            Unit of Measurement:
            {' '}
            <span>{timeLineData.MetricUnit}</span>
          </p>
          )}
          {!isMetricDetail && timeLineData.ValueUnit && timeLineData.ValueUnit !== undefined && timeLineData.ValueUnit.toLowerCase() !== "string" && stepName !== "IO" && (
          <p className="mappinglabel-unit">
            Unit of Measurement:
            {' '}
            <span>{timeLineData.ValueUnit}</span>
          </p>
          )}
        </div>
      );
    }
    return '';
  }
}

export default BeforeAfterTimeline;
