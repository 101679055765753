import React from 'react';
import { Modal } from 'react-bootstrap';
import { LocalApiBaseUrl } from '../Shared/Constant';
import i18n from "i18next";
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { regexForFields } from '../Shared/Constant';
import { regexForTextAreas } from "../Shared/Constant";

export default class IIMetricGroupPopUp extends React.Component {
    constructor(props) {
        super(props)
        const { show, ParentSOCategoryID } = this.props;
        this.state = {
            show: show,
            apivalue: "",
            filter: { OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null },
            errors: {}, formIsValid: false,
            iiData: {
                BusinessReason: "",
                SOCategoryID: ParentSOCategoryID,
                MetricGroup: "",
                MetricDescription: '',
            }
        }
        this.onNameChange = this.onNameChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleRejectHide = this.handleRejectHide.bind(this);
        this.handleApproveHide = this.handleApproveHide.bind(this);
        this.handleSave = this.handleSave.bind(this);

    }
    onNameChange(event) {

        let errors = {};
        this.setState({
            errors: errors
        });
        const iiData = this.state.iiData;

        iiData.MetricGroup = event.target.value
        this.setState({
            iiData: iiData
        })
    }

    onDescriptionChange(event) {
        let errors = {};
        this.setState({
            errors: errors
        });
        const iiData = this.state.iiData;
        iiData.MetricDescription = event.target.value;
        this.setState({
            iiData: iiData
        })
    }

    onRemarksChange(event) {

        this.setState({ errors: {} });
        const iiData = this.state.iiData;
        iiData.BusinessReason = event.target.value;
        this.setState({
            iiData: iiData
        })
    }

    handleSave(e) {
        const { iiData } = this.state;
        const { filterDetails, SOCategoryID } = this.state;
        if (this.validateForm()) {
            let filter = {
                LocationID: filterDetails.Locations,
                OrgEntityID: filterDetails.Client,
                OfferingID: filterDetails.process,
                SOCategoryID: SOCategoryID
            };
            this.setState({
                iiData: {
                    MetricGroup: iiData.MetricGroup.trim()
                }
            });

            let data = {
                dealTemplateMetricGroup: iiData,
                FiltersIDsVM: filter
            }

            axiosInstance.post(`${LocalApiBaseUrl}DealTemplateMetricGroup/PostDealTemplateMetricGroup`, data).then((response) => {
                alert(i18n.t("Sent_for_Approval"))
            })
                .catch((error) => {
                    trycatchAlertPopup(error);
                });

            this.handleHide();
        }
    }

    handleHide(e) {
        const { onClose } = this.state;
        this.setState({ show: false });
        if (String(e) !== String(undefined)) {

            if ((String(e.target.textContent) !== "×")) {
                onClose(e);
            }
            else {
                onClose(e);
            }
        } else {
            onClose(e);
        }

    }
    handleRejectHide(e) {

        const { onRejectClose } = this.state;
        this.setState({ show: false });
        if (String(e) !== String(undefined)) {

            if ((String(e.target.textContent) !== "×")) {
                onRejectClose();
            }
        } else {
            onRejectClose();
        }

    }
    handleApproveHide(e) {
        const { onApproveClose } = this.state;
        this.setState({ show: false });
        if (String(e) !== String(undefined)) {

            if ((String(e.target.textContent) !== "×")) {
                onApproveClose();
            }
        } else {
            onApproveClose();
        }

    }

    validateForm() {

        const iiData = this.state.iiData;
        let errors = {};
        let formIsValid = true;

        if (!iiData["MetricGroup"].trim()) {
            formIsValid = false;
            errors["MetricGroup"] = i18n.t("addWoErrorM1");

        }
        else {

            let isValid = regexForFields.test(iiData["MetricGroup"]);
            if (!isValid) {

                formIsValid = false;
                errors["MetricGroup"] = i18n.t("metricGroupErrorM1");
            }
        }


        if (!iiData["MetricDescription"]) {
            formIsValid = false;
            errors["MetricDescription"] = i18n.t("ToolDescription_Required");
        }
        else {

            let isValid = regexForFields.test(iiData["MetricDescription"]);
            if (!isValid) {

                formIsValid = false;
                errors["MetricDescription"] = i18n.t("metricDescErrorM1");
            }
        }


        if (iiData["BusinessReason"] == null) {
            formIsValid = false;
            errors["BusinessReason"] = i18n.t("addWoErrorM8");

        }

        else if (!iiData["BusinessReason"].trim()) {
            formIsValid = false;
            errors["BusinessReason"] = i18n.t("addWoErrorM8");
        }
        else {

            let isValid = regexForTextAreas.test(iiData["BusinessReason"]);
            if (!isValid) {
                formIsValid = false;
                errors["BusinessReason"] = i18n.t("addWoErrorM9");
            }
        }

        this.setState({
            errors: errors
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true,
                errors: {}
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;

    }


    render() {
        const { show, errors } = this.state;
        return (
            <>
                {/* <Header /> */}
                <Modal show={show} onHide={this.handleHide} backdrop="static" className="modal fade" size="medium" id="dealpopup">
                    <Modal.Header closeButton>
                        <h4 className="modal-title" id="dealprofile">{i18n.t("Add_New_Metric_Group")}</h4>
                    </Modal.Header>
                    <Modal.Body>

                        <form>
                            <div className="form-section">
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Name")}<span className="required">*</span></label>
                                        <input type="text" maxlength="100" className="form-control" id="name" onChange={this.onNameChange} />
                                        <span className="errorMsg">{errors.MetricGroup}</span>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Description")} <span className="required">*</span></label>
                                        <input maxlength="50" type="text" className="form-control" id="" onChange={this.onDescriptionChange} />
                                        <span className="errorMsg">{errors.MetricDescription}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Requestor_Remarks")} <span className="required">*</span></label>
                                        <textarea maxLength="200" className="form-control" id="remarks" onChange={this.onRemarksChange.bind(this)} />
                                        <span className="errorMsg">{errors.BusinessReason}</span>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </Modal.Body>
                    {<Modal.Footer>
                        <div className="modal-btn">
                            <input type="button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={i18n.t("Create")} />
                        </div>
                    </Modal.Footer>}
                </Modal>
            </>
        )
    }
}