import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import i18n from "i18next";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { FormatDateWithoutUTC } from "../Shared/commonFunctions";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import Select from "react-select";

class CommentsHistory extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      SOCategories: [],
      SOCategoriy: "",
      metricDetails: [],
      KPIMappingID: "",
      comments: "All",
      apiCallStatus: false,
      filteredCommentsData: [],
      commentsData: [],
      SOCategoriyRow: null,
      selectedMetricRow: null,
      sortby: false,
    };
  }

  SOCategoriyChange = (event) => {
    let selectedValue = event.value;
    let metricDetails = [];
    this.props.data.forEach((each, index) => {
      if (each.SOCategoryID === parseInt(selectedValue)) {
        metricDetails = [...each.kpiData];
      }
    });
    this.setState({
      comments: "All",
      SOCategoriy: selectedValue,
      selectedMetricRow: null,
      SOCategoriyRow: event,
      metricDetails: metricDetails,
      KPIMappingID: "",
      filteredCommentsData: [],
      commentsData: [],
      sortby: false,
    });
  };
  onMetricChange = (event) => {
    let selectedValue = event.value;

    if (selectedValue) {
      this.setState({ apiCallStatus: true });
      axiosInstance
        .get(`${LocalApiBaseUrl}Commentary/GetCommentariesByMetric`, {
          params: {
            KPIMappingID: selectedValue,
            PeriodDT: this.props.SyncFilter.Month,
            LanguageID: parseInt(this.props.languageData.id),
          },
        })
        .then((response) => {
          if (response.data) {
            let commentsData = response.data;
            commentsData.sort(function(a, b) {
              return new Date(a.UpdatedTS) < new Date(b.UpdatedTS)
                ? 1
                : new Date(b.UpdatedTS) < new Date(a.UpdatedTS)
                ? -1
                : 0;
            });
            this.setState({ commentsData: commentsData, filteredCommentsData: commentsData, apiCallStatus: false });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({ apiCallStatus: false });
        });
    } else {
      this.setState({ commentsData: [], filteredCommentsData: [] });
    }

    this.setState({ KPIMappingID: selectedValue, selectedMetricRow: event, comments: "All", sortby: false });
  };
  onCommentsChange = (event) => {
    let selectedValue = event.target.options[event.target.selectedIndex].value;
    let filteredData = [...this.state.commentsData];
    let UpdatedData = [];
    if (selectedValue === "Edited") {
      filteredData.forEach((each) => {
        if (each.IsEdited === true) {
          UpdatedData.push(each);
        }
      });
    } else if (selectedValue === "Deleted") {
      filteredData.forEach((each) => {
        if (each.IsDeleted === true) {
          UpdatedData.push(each);
        }
      });
    } else {
      UpdatedData = filteredData;
    }
    this.setState({ comments: selectedValue, filteredCommentsData: UpdatedData });
  };
  sortBy = () => {
    let filteredCommentsData = [...this.state.filteredCommentsData];
    if (!this.state.sortby) {
      filteredCommentsData.sort(function(a, b) {
        return new Date(a.UpdatedTS) > new Date(b.UpdatedTS)
          ? 1
          : new Date(b.UpdatedTS) > new Date(a.UpdatedTS)
          ? -1
          : 0;
      });
    } else {
      filteredCommentsData.sort(function(a, b) {
        return new Date(a.UpdatedTS) < new Date(b.UpdatedTS)
          ? 1
          : new Date(b.UpdatedTS) < new Date(a.UpdatedTS)
          ? -1
          : 0;
      });
    }
    this.setState({ sortby: !this.state.sortby, filteredCommentsData: filteredCommentsData });
    this.setState({ sortby: !this.state.sortby });
  };
  componentDidMount() {
    let SOCategories = [];
    let SOCategoriy = this.props.selectedMetricDetails.SOCategoryID;
    let metricDetails = [];
    let selectedSoCategory = { label: "", value: "" };
    let selectedMetric = { label: "", value: "" };
    this.props.data.forEach((each, index) => {
      SOCategories.push({ SOCategoryName: each.SOCategoryName, SOCategoryID: each.SOCategoryID });
      if (each.SOCategoryID === this.props.selectedMetricDetails.SOCategoryID) {
        selectedSoCategory = { label: each.SOCategoryName, value: each.SOCategoryID };
        metricDetails = [...each.kpiData];
        each.kpiData.forEach((item) => {
          if (item.KPIMappingID === this.props.selectedMetricDetails.KPIMappingID) {
            selectedMetric = { label: item.MetricName, value: item.KPIMappingID };
          }
        });
      }
    });
    this.setState({ apiCallStatus: true });
    axiosInstance
      .get(`${LocalApiBaseUrl}Commentary/GetCommentariesByMetric`, {
        params: {
          KPIMappingID: this.props.selectedMetricDetails.KPIMappingID,
          PeriodDT: this.props.SyncFilter.Month,
          LanguageID: parseInt(this.props.languageData.id),
        },
      })
      .then((response) => {
        if (response.data) {
          let commentsData = response.data;
          commentsData.sort(function(a, b) {
            return new Date(a.UpdatedTS) < new Date(b.UpdatedTS)
              ? 1
              : new Date(b.UpdatedTS) < new Date(a.UpdatedTS)
              ? -1
              : 0;
          });
          this.setState({ commentsData: commentsData, filteredCommentsData: commentsData, apiCallStatus: false });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ apiCallStatus: false });
      });
    this.setState({
      data: this.props.data,
      selectedMetricRow: selectedMetric,
      SOCategoriyRow: selectedSoCategory,
      SOCategories: SOCategories,
      SOCategoriy: SOCategoriy,
      metricDetails: metricDetails,
      KPIMappingID: this.props.selectedMetricDetails.KPIMappingID,
    });
  }
  render() {
    const {
      SOCategories,
      metricDetails,
      apiCallStatus,
      SOCategoriyRow,
      selectedMetricRow,
      comments,
      sortby,
      filteredCommentsData,
    } = this.state;
    let SOCategoriesList = [];
    SOCategories.forEach((each) => {
      SOCategoriesList.push({ label: each.SOCategoryName, value: each.SOCategoryID });
    });
    let metricDetailsList = [];
    metricDetails.forEach((each) => {
      metricDetailsList.push({ label: each.MetricName, value: each.KPIMappingID });
    });

        const customStyles = {
            control: (provided, state) => ({
              ...provided,
              borderRadius: 24,
              cursor:"pointer",
              minHeight:'40px',
              marginRight:'14px'
            }),
            container: styles => ({
                ...styles,
                width:"270px"
            }),
            menu: styles => ({
                ...styles,
                marginTop:'4px',
                marginBottom:'0px',
                wordWrap:'break-word',
                overflowWrap: 'break-word',
            }),
            menuList: styles => ({
                ...styles,
                padding:'0px',
                wordBreak:'break-word',
                overflowWrap:'break-word',
                maxheight: "280px",
                minHeight: 'auto'
            }),
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : 'rgb(99, 115, 129)',
                fontSize: "14px",
                fontFamily:"Graphik-Medium",
                fontWeight: 500,
                letterSpacing: "-0.25px",
                lineHeight: "14px",
              }),
            indicatorSeparator: styles => ({display:'none'}),
            input: styles => ({ ...styles, color: "rgb(99, 115, 129)",
            fontSize: "14px",
            fontFamily:"Graphik-Medium",
            fontWeight: 500,
            letterSpacing: "-0.25px",
            lineHeight: "14px"}),
            placeholder: styles => ({ ...styles, color: "rgb(99, 115, 129)",
            fontSize: "14px",
            fontFamily:"Graphik-Medium",
            fontWeight: 500,
            letterSpacing: "-0.25px",
            lineHeight: "14px"}),
            singleValue: (styles) => ({ ...styles,     
            color: "rgb(99, 115, 129)",
            fontSize: "14px",
            fontFamily:"Graphik-Medium",
            fontWeight: 500,
            letterSpacing: "-0.25px",
            lineHeight: "14px"}),
          };
        return (
            <LoadingOverlay
                fadeSpeed={0}
                spinner={<SynopsLoader />}
                active={this.state.apiCallStatus}
                //spinner
                // text={i18n.t("Please_wait")}
            >
                <div className="commentry">
                    <div className="row">
                        <div className="col-sm-12 header-list">
                            <a onClick={() => { this.props.goBack(); }}>
                                <span className="backBtn">
                                    <i className="fas fa-chevron-left"></i>{i18n.t("Back")}
                                </span>
                            </a>
                            <h1 className="history-header inline p-l-20">{i18n.t("View_Comments_History")}</h1>

                        </div>
                    </div>
                    <div className="row p-t-20">
                        <div className="col-sm-10">
                            <ul className="selectdropdown-list">
                                <li>
                                    {/* <div className="reactCustom-Select-Container">
                                        <div className="reactCustom-select"> */}
                                            <Select value={this.state.SOCategoriyRow} onChange={this.SOCategoriyChange} options={SOCategoriesList} isMulti={false}  styles={customStyles} />
                                        {/* </div>

                                    </div> */}
                                    
                                </li>
                                <li>
                                {/* <div className="reactCustom-Select-Container">
                                        <div className="reactCustom-select"> */}
                                            <Select value={this.state.selectedMetricRow} placeholder = {i18n.t("Please_Select_Metric")} onChange={this.onMetricChange} options={metricDetailsList} isMulti={false} styles={customStyles} />
                                        {/* </div>

                                    </div> */}
                                    {/* <div className="select-custom">
                                        <select className="dropdown" name="dropDownOne" value={this.state.KPIMappingID} onChange={this.onMetricChange}>
                                            <option value={""}>{i18n.t("Please_Select_Metric")}</option>
                                            {this.state.metricDetails.map(each => {
                                                return <option value={each.KPIMappingID}>{each.MetricName}</option>
                                            })}
                                        </select>
                                    </div> */}
                                </li>
                                <li>
                                    <div className="select-custom">
                                        <select className="dropdown" name="dropDownOne" value={this.state.comments} onChange={this.onCommentsChange}>
                                            <option value={"All"}>{i18n.t("All_Comments")}</option>
                                            <option value={"Edited"}>{i18n.t("Edited")}</option>
                                            <option value={"Deleted"}>{i18n.t("Deleted")}</option>
                                        </select>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-2">
                            <p onClick={this.sortBy} className={`float-right sortby m-t-15 p-r-20 ${this.state.sortby ? "sortby-icon-up" : "sortby-icon-down"}`}>{i18n.t("Sort_By")}</p>
                        </div>
                    </div>

          <div className="row">
            <div className="col-sm-12">
              {filteredCommentsData.map((each) => {
                return (
                  <div className="user-specific-comment">
                    <div className="user-info">
                      <div className="user-profile">
                        <span>
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                      </div>
                      <div className="profile-info">
                        <h6>{each.CreatedUser}</h6>
                        <span className="time-info">
                          <ul>
                            {each.IsDeleted ? (
                              <li className={"red"}>{i18n.t("Deleted") + " "}</li>
                            ) : each.IsEdited ? (
                              <li className={"edited-label"}>{i18n.t("Edited") + " "}</li>
                            ) : (
                              ""
                            )}
                            <li>{FormatDateWithoutUTC(each.UpdatedTS)} </li>
                          </ul>
                        </span>
                      </div>
                    </div>
                    <div className={each.IsDeleted ? "user-comment deleted" : "user-comment"}>
                      <p>{each.Comment}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
    selectedMetricDetails: { ...state.BOReducers.selectedMetriCCommentHistory },
    SyncFilter: { ...state.CurrentFilter.SyncFilter },
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    goBack: () => {
      dispatch({
        type: "SELECTED_METRIC_COMMENT_HISTORY",
        payload: { SOCategoryID: "", KPIMappingID: "", showHistory: false },
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsHistory);
