import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownButton } from "react-bootstrap";
import SubOffering from "../Shared/SubOffering";
import { filterSelectedSubOffering } from '../Shared/commonFunctions';
import _ from 'lodash';

const OfferingDropdown = ({
  value,
  field,
  index,
  handleGridDropdown,
  filledRowsCount,
  offerings = [],
}) => {
  if (filledRowsCount >= index)
    return (
      <Dropdown
        className="access-form-input"
        onSelect={(e) => handleGridDropdown(e, index, field)}
      >
        <Dropdown.Toggle
          className="offering-dropdown"
          variant="light"
        >
          <div className="offering-dropdown-item">{value || `Select ${field}`}</div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {offerings.map((offering, index) => (
            <Dropdown.Item key={index} eventKey={offering.OfferingName} >
              {offering.OfferingName}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  else
    return (
      <input
        className="access-form-input"
        disabled
        type="text"
      />
    );
};

OfferingDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handleGridDropdown: PropTypes.func.isRequired,
  filledRowsCount: PropTypes.number.isRequired,
  offerings: PropTypes.array.isRequired,
};


const SubofferingDropdown = ({
  value,
  field,
  index,
  handleGridDropdown,
  filledRowsCount,
  offerings = [],
  offeringName
}) => {
  let subofferings = [];
  if (offerings.find((offering) => offering.OfferingName === offeringName))
    subofferings = offerings.find(
      (offering) => offering.OfferingName === offeringName
    ).ChildOfferings;
  else subofferings = [];

  const handleChange = (e) => {
    const processID = e.target.value;
    const filtersSubofferingData = filterSelectedSubOffering(_.cloneDeep(subofferings), processID);
    const selectedSuboffering = filtersSubofferingData.subofferingData[0];
    handleGridDropdown(selectedSuboffering.Process, index, field)
  }

  if (filledRowsCount >= index)
    return (
      <Dropdown className="access-form-input">
        <Dropdown.Toggle className="offering-dropdown" variant="light">
          <div className="offering-dropdown-item">{value || `Select ${field}`}</div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="suboffering-dropdown-menu">
          <SubOffering
            selectedSubOffering={value}
            subOfferings={subofferings}
            selectedSubOfferingId={value}
            onSubOfferingChange={handleChange}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  else
    return (
      <input
        className="access-form-input"
        disabled
        type="text"
      />
    );
};

SubofferingDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  offeringName: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handleGridDropdown: PropTypes.func.isRequired,
  handleDateInput: PropTypes.func,
  filledRowsCount: PropTypes.number.isRequired,
  offerings: PropTypes.array.isRequired,
};

const GridCellInput = ({
  value,
  field,
  index,
  handleGridInput,
  handleDateInput = () => {},
  filledRowsCount,
}) => {
    return (
      <input
        className="access-form-input"
        type="text"
        value={value}
        onChange={(e) => handleGridInput(e, index, field)}
        onFocus={(e) => handleDateInput(e, index)}
        disabled={filledRowsCount < index}
      />
    );
};

GridCellInput.propTypes = {
  value: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handleGridInput: PropTypes.func.isRequired,
  handleDateInput: PropTypes.func,
  filledRowsCount: PropTypes.number.isRequired,
};

const AccessRequestForm = ({
  formData,
  handleGridInput,
  handleDateInput,
  filledRowsCount,
  offerings,
  handleOfferingChange,
  handleSubofferingChange
}) => {
  return (
    <div className="access-form">
      <h4
        className="access-form-header"
      >
        Access Request Form
      </h4>
      <table className="access-table">
        <thead>
        <tr>
          <th>Request Date</th>
          <th>Offering</th>
          <th>Sub-offering</th>
          <th>Role (View, Edit and Publish)</th>
          <th>User Enterprise ID</th>
          <th>Action (Add/Revoke)</th>
        </tr>
        </thead>
        <tbody>
        {formData.map((data, index) => (
          <tr key={index}>
            <td>
              <GridCellInput
                value={data.requestDate}
                field={"requestDate"}
                index={index}
                handleGridInput={handleGridInput}
                handleDateInput={handleDateInput}
                filledRowsCount={filledRowsCount}
              />
            </td>
            <td>
              <OfferingDropdown
                value={data.offering}
                field={"offering"}
                index={index}
                handleGridDropdown={handleOfferingChange}
                filledRowsCount={filledRowsCount}
                offerings={offerings}
              />
            </td>
            <td>
              <SubofferingDropdown
                value={data.suboffering}
                offeringName={data.offering}
                field={"suboffering"}
                index={index}
                handleGridDropdown={handleSubofferingChange}
                filledRowsCount={filledRowsCount}
                offerings={offerings}
              />
            </td>
            <td>
              <GridCellInput
                value={data.role}
                field={"role"}
                index={index}
                handleGridInput={handleGridInput}
                filledRowsCount={filledRowsCount}
              />
            </td>
            <td>
              <GridCellInput
                value={data.userEnterpriseId}
                field={"userEnterpriseId"}
                index={index}
                handleGridInput={handleGridInput}
                filledRowsCount={filledRowsCount}
              />
            </td>
            <td>
              <GridCellInput
                value={data.action}
                field={"action"}
                index={index}
                handleGridInput={handleGridInput}
                filledRowsCount={filledRowsCount}
              />
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

AccessRequestForm.propTypes = {
  formData: PropTypes.array.isRequired,
  handleGridInput: PropTypes.func.isRequired,
  handleDateInput: PropTypes.func.isRequired,
  filledRowsCount: PropTypes.number.isRequired,
  offerings: PropTypes.array.isRequired,
  handleOfferingChange: PropTypes.func.isRequired,
  handleSubofferingChange: PropTypes.func.isRequired
};

export default AccessRequestForm;
