import { SAVE_CLIENT_DATA,SAVE_CONFIG_OFFERING_DATA } from "../actions/ClientAndFilterActions";

export let ClientAndFilterReducer = (
  state = {
    ClientData: [],
    configFilterData:{offeringData:[]}
  },
  action
) => {
  switch (action.type) {
    case SAVE_CLIENT_DATA: {
      state = { ...state };
      state.ClientData = action.payload;
      return state;
    }
    case SAVE_CONFIG_OFFERING_DATA: {
      state = { ...state };
      state.configFilterData.offeringData = action.payload;
      return state;
    }
    default : return state
  }
 
};
