import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import psl from 'psl';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl } from '../Shared/Constant';

export default class URLWhiteListPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urlData: {
        OrgEntityName:'All'
      },
      errors: [],
    };

    this.onChange = this.onChange.bind(this);
    // this.onIsActiveChange = this.onIsActiveChange.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      data.isActive = data.IsActive
      this.setState({
        urlData: data,
      });
    }
  }
  

  handleSave() {
    const { onSave, onClose } = this.props;
    const { urlData } = this.state;
    if (this.validateForm()) {
      onSave(urlData);
      onClose();
    }
  }

  onIsActiveChange = (event) => {
    const { urlData } = this.state;
    urlData.isActive = event.target.checked;
    this.setState({
      urlData,
    });
  }

  onChange(event) {
    const errors = {};
    const { urlData } = this.state;
    urlData.Domain = event.target.value;
    this.setState({
      errors,
      urlData,
    });
  }
  onSelect = (event) =>{
    const { urlData } = this.state;
    urlData.OrgEntityID = event.target.options[event.target.selectedIndex].value;
    urlData.OrgEntityName = event.target.options[event.target.selectedIndex].text;
    this.setState({
      urlData,
    })
  }

  // getClients() {
  //   axiosInstance
  //     .get(`${LocalApiBaseUrl}SO/GetOrgStructure`, {
  //       params: { LanguageId: 1 },
  //     })
  //     .then((response) => {        
  //      let clientData = response.data.map(each => ({'Name': each.OrgEntityName,'ID': each.OrgEntityID}) );
  //      this.setState({
  //        clientData,
  //      })
  //     })
  // }

  validateForm() {
    const { urlData } = this.state;

    const errors = {};
    let formIsValid = true;   

    if (!urlData.Domain || !urlData.Domain.trim()) {
      formIsValid = false;
      errors.Domain = ' Domain/SubDomain URL is required';
    } else {

      if (urlData.Domain.substring(0, 7) === 'http://'){
        urlData.Domain = urlData.Domain.slice(7,urlData.Domain.length);
      }else if (urlData.Domain.substring(0, 8) === 'https://'){
        urlData.Domain = urlData.Domain.slice(8,urlData.Domain.length);
      }
      let testURL = urlData.Domain;

      if(testURL[0] === '*'){
        let testURLArr = testURL.split('');
        testURLArr[0] = 'a';
        testURL = testURLArr.join('')
      }
      const  isValid = psl.isValid(testURL);
      
      if (!isValid) {
        formIsValid = false;
        errors.Domain = 'Please enter a valid Domain/SubDomain';
      }
    }

    this.setState({
      errors,
    });
    return formIsValid;
  }

  render() {
    const { show, onClose, data, clientData } = this.props;
    const {
      errors, urlData,
    } = this.state;
    return (
      <>
        <Modal
          show={show}
          onHide={() => onClose()}
          backdrop="static"
          class="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            {!data ? (
              <h4 className="modal-title" id="dealprofile">
                Add URL
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile">
                Edit URL
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-12">
                    <label htmlFor="">
                      Enter URL
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      onChange={this.onChange}
                      value={urlData.Domain}
                    />
                    <span className="errorMsg">{errors.Domain}</span>
                  </div>

                  <div className="form-group col-lg-6">
                    <label htmlFor="">
                      Client Name
                    </label>
                    <select
                      id="Client"
                      name="Client"
                      className="form-control"
                      value={urlData.OrgEntityID}
                      onChange={(e) => this.onSelect(e)}
                    >                   
                      <option value="" key="">All</option>
                      {clientData? clientData.map((row) => <option key={row.ID} value={row.ID} >{row.Name}</option>):null}
                    </select>
                  </div>
                  <div className="form-group col-lg-6 label-adjust">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={urlData.isActive}
                        onChange={(e)=>this.onIsActiveChange(e)}
                      />
                      
                      <span className="checkmark" />
                      <span className="form-check-label">Is Active</span>
                    </label>
                  </div>

                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              {!data ? (
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value="Create"
                />
              ) : (
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value="Update"
                />
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

URLWhiteListPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,

};
