import { RetainleftMenuOptions} from "../Shared/Constant"

export let roleReducers = (state = {
    roleReducers: [],
    userDeniedScreen:"",
}, action) => {
    switch (action.type) {
        case "INITIALISEROLE": {
            state = { ...state }
            let retainLeftMenu = [...RetainleftMenuOptions]
            let payload = action.payload
            if(!Array.isArray(action.payload))
            {
                payload = action.payload.Data
                if(!action.payload.IsMajorClick)
                {
                    retainLeftMenu.push({name:"PulsePage"},{"name":"Dashboard"},{"name":"Configurator"})
                }
               
                
            }

            // CALCULATING WHETHER IT HAS LEFT MENU ROLES
            if(state.roleReducers.length ===0 )
            {
                state["roleReducers"] = payload
            }
            else {
           
            let leftMenus = state.roleReducers.filter(a=>RetainleftMenuOptions.some(b=>b.name === a.FeatureName))
            if(leftMenus.length >0)
            {
                let payloadWithLeftMenu = state["roleReducers"].filter(a=>retainLeftMenu.some(b=>b.name === a.FeatureName))
                let payloadWithoutLeftMenu = payload.filter(a=>!retainLeftMenu.some(b=>b.name === a.FeatureName))
                let newPayload = [...payloadWithLeftMenu, ...payloadWithoutLeftMenu]
                state["roleReducers"] = newPayload
            }
            else
            {
                state["roleReducers"] = payload
            }
            }

            return state
        }
        case "SETUSERDENIEDSCREEN":{
            state = { ...state }
            state["userDeniedScreen"] = action.payload
            return state;
        }
        default:return state;

    }

}