/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import DiverseKpiContiner from './DiverseKpiContainer';
import MetricInsightDetails from '../Bat/MetricInsightDetails';
import MetricInfoModal from '../Bat/MetricInfoModal';
import axiosInstance from '../Shared/interceptor';
import SynopsLoader from '../Shared/SynopsLoader';
import { LocalApiBaseUrl, formatDate, trycatchAlertPopup } from '../Shared/Constant';

// IMPORT STYLES
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import '../Bat/CSS/batstyle.css';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;

function getFormattedStartAndEndMonthsFromGivenMonth(month) {
  const momentMonthSelectedInFilter = moment(month);
  const momentMonthBeforeOneYear = moment(momentMonthSelectedInFilter).subtract(11, 'months');

  return {
    formattedStartMonth: formatDate(momentMonthBeforeOneYear),
    formattedEndMonth: formatDate(momentMonthSelectedInFilter),
  };
}
class DDBatView extends Component {
  constructor(props) {
    super(props);
    const {
      syncFilter: { Month: monthSelectedInFilter },
    } = props;
    const { formattedStartMonth, formattedEndMonth } = getFormattedStartAndEndMonthsFromGivenMonth(
      monthSelectedInFilter,
    );

    this.state = {
      isAPILoading: false,
      dataByMetricCategories: [],
      isVisibleMetricInsightDetailsModal: false,
      currentlySelectedMetricData: {},
      currentlySelectedMetricDataForInfo: {},
      formattedStartDate: formattedStartMonth,
      formattedEndDate: formattedEndMonth,
    };
  }

  componentDidMount() {
    // EXECUTE API CALL TO GET `clientCount`
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetAppConfig`)
      .then((response) => {
        const responseData = response.data;
        const clientCountFromSettings = _.get(
          _.find(responseData, (eachSetting) => eachSetting.ConfigKey === 'BenchmarkAnalyticsMinClientCount'),
          'ConfigValue',
          '',
        );
        if (!_.isEmpty(String(clientCountFromSettings))) {
          this.setState({
            clientCount: clientCountFromSettings,
          });
        }

        this.fetchData();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidUpdate(prevProps) {
    const { syncFilter } = this.props;
    if (prevProps.syncFilter !== syncFilter) {
      const { formattedStartMonth, formattedEndMonth } = getFormattedStartAndEndMonthsFromGivenMonth(syncFilter.Month);
      this.setState({
        formattedStartDate: formattedStartMonth,
        formattedEndDate: formattedEndMonth,
      });
    }
  }

  fetchData = () => {
    const {
      BATClientName, BATOfferingName, BATSubOfferingName, LanguageId, Month,
    } = this.props;
    // const { formattedStartDate, formattedEndDate } = this.state;

    this.setState({ isAPILoading: true });

    const requestData = {
      clientName: BATClientName,
      offeringName: BATOfferingName,
      towerName: BATSubOfferingName,
      timeFrameStartDate: Month,
      benchmarkType: 'IndustryWise',
      isConsolidated: false,
    };

    // FETCH THE DATA FROM THE API
    axiosInstance
      .post(`${LocalApiBaseUrl}BAT/GetSynOpsDealBenchmarkDetails?LanguageId=${LanguageId}`, requestData)
      .then((response) => {
        let uniqueMetricCategories = [];
        let metricDetails = [];
        let dataByMetricCategories = [];

        if (!_.isEmpty(response.data)) {
          const responseData = response.data;
          metricDetails = responseData; // ALL DATA//

          // FOR THE METRICS, SEPARATE OUT METRIC VALUE AND ITS UNIT
          metricDetails = _.map(metricDetails, (eachMetric) => {
            let metricValue = parseFloat(eachMetric.aggregatedAverage);
            let median = parseFloat(eachMetric.median);
            let topQuartilePerformance = parseFloat(eachMetric.topQuartilePerformance);

            // FOR THE "Talent & HR" OFFERING THE METRIC VALUES ARE TO BE CORRECTED UP TO TWO DECIMAL PLACES FOR FLOATING POINT VALUE
            if (BATOfferingName === 'Talent & HR') {
              metricValue = _.isInteger(metricValue) ? metricValue : metricValue.toFixed(2);
            } else {
              metricValue = _.isInteger(metricValue) ? metricValue : metricValue.toFixed(1);
              median = _.isInteger(median) ? median : median.toFixed(1);
              topQuartilePerformance = _.isInteger(topQuartilePerformance) ? topQuartilePerformance : topQuartilePerformance.toFixed(1);
            }

            const metricUnit = _.toLower(eachMetric.metricUnit);
            let metricUnitFormatted = '';
            if (metricUnit === 'percentage') {
              metricUnitFormatted = '%';
            } else if (metricUnit === 'days') {
              metricUnitFormatted = 'Days';
            }

            // SPLIT THE METRIC CATEGORY NAME (BUSINESS OUTCOME) AND THE VALUE DRIVER NAME WHICH ARE SEPARATED BY COLON (:) AND ADD THE ATTRIBUTE `valueDriver` FOR EACH METRIC
            const arrayAfterSplit = _.split(eachMetric.metricCategory, ':', 2);
            const metricCategory = _.trim(arrayAfterSplit[0]);
            const valueDriver = !_.isEmpty(arrayAfterSplit[1]) ? _.trim(arrayAfterSplit[1]) : '';

            return {
              ...eachMetric,
              metricValue,
              median,
              topQuartilePerformance,
              metricUnitFormatted,
              metricCategory,
              valueDriver,
            };
          });

          // SORT THE METRICS BY `metricSequence` ATTRIBUTE
          metricDetails = _.sortBy(metricDetails, ['metricSequence']);

          // FILTER OUT UNIQUE METRIC CATEGORIES
          uniqueMetricCategories = _.uniq(_.map(metricDetails, 'metricCategory'));

          // const tveDataByMetricCategories =
          _.map(uniqueMetricCategories, (eachUniqueMetricCategory) => ({
            metricCategory: eachUniqueMetricCategory,
            metrics: _.filter(metricDetails, { metricCategory: eachUniqueMetricCategory }),
          }));
          this.setState({
            // tveDataByMetricCategories,
          });

          // GROUP THE METRICS BY THE UNIQUE CATEGORIES
          dataByMetricCategories = _.map(uniqueMetricCategories, (eachUniqueMetricCategory) => ({
            metricCategory: eachUniqueMetricCategory,
            metrics: _.filter(metricDetails, (eachMetric) => eachMetric.metricCategory === eachUniqueMetricCategory),
          }));
        }

        _.map(metricDetails, (eachMetric) => {
          if (!_.isEmpty(eachMetric)) {
            const leadingPractice = _.toLower(eachMetric.leadingPractice);
            const clientMetricValue = parseFloat(eachMetric.clientMetricValue);
            const aggregatedAverageFloatVal = parseFloat(eachMetric.aggregatedAverage);
            const topQuartilePerformanceFloatVal = parseFloat(eachMetric.topQuartilePerformance);

            let performanceIndicator = '';
            if (leadingPractice === 'minimum') {
              if (clientMetricValue <= topQuartilePerformanceFloatVal) {
                performanceIndicator = 'better';
              } else if (
                clientMetricValue > topQuartilePerformanceFloatVal
                && clientMetricValue <= aggregatedAverageFloatVal
              ) {
                performanceIndicator = 'worse';
              } else {
                performanceIndicator = 'worst';
              }
            } else if (clientMetricValue >= topQuartilePerformanceFloatVal) {
              performanceIndicator = 'better';
            } else if (
              clientMetricValue < topQuartilePerformanceFloatVal
                && clientMetricValue >= aggregatedAverageFloatVal
            ) {
              performanceIndicator = 'worse';
            } else {
              performanceIndicator = 'worst';
            }
            dataByMetricCategories = dataByMetricCategories.map((e) => ({ ...e, performanceIndicator }));
          }
        });
        this.setState({
          dataByMetricCategories,
          isAPILoading: false,
          // metricDetails, unused state
          // uniqueMetricCategories, unused state
        });
        if (_.isEmpty(response.data) && response.data !== undefined && response.data != null) {
          this.setState({ isAPILoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isAPILoading: false });
        trycatchAlertPopup(error);
      });
  };

  displayMetricBenchmarkChart = (metricData) => {
    this.setState({
      currentlySelectedMetricData: metricData,
      isVisibleMetricInsightDetailsModal: true,
    });
  };

  handleCloseMetricInsightDetailsModal = () => {
    this.setState({
      isVisibleMetricInsightDetailsModal: false,
    });
  };

  handleClickMetricInfoIcon = (metricData) => {
    const currentlySelectedMetricDataForInfo = metricData;
    this.setState({
      currentlySelectedMetricDataForInfo,
      isVisibleMetricInfoModal: true,
    });
  };

  handleMetricInfoModalHide = () => {
    this.setState({
      currentlySelectedMetricDataForInfo: {},
      isVisibleMetricInfoModal: false,
    });
  };

  render() {
    const {
      isAPILoading,
      dataByMetricCategories,
      isVisibleMetricInfoModal,
      currentlySelectedMetricDataForInfo,
      currentlySelectedMetricData,
      isVisibleMetricInsightDetailsModal,
      clientCount,
      formattedStartDate,
      formattedEndDate,
    } = this.state;

    const {
      location,
      selectedDataType,
      attributes,
      // ShowBannerMessage,
      clientData,
      BATClientName,
      BATOfferingName,
      BATOfferingType,
      BATSubOfferingName,
    } = this.props;

    const options = {
      loop: true,
      margin: 16,
      responsiveClass: '',
      dots: false,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        600: {
          items: 2,
          nav: false,
        },
        1000: {
          items: 3,
          nav: true,
          loop: false,
        },
        1440: {
          items: 4,
          nav: true,
          loop: false,
        },
        1600: {
          items: 4,
          nav: true,
          loop: false,
        },
        1800: {
          items: 5,
          nav: true,
          loop: false,
        },
      },
    };
    return (
      <>
        {/* IF API IS LOADING THEN SHOW THE LOADER */}
        {isAPILoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />}
        {/* {!_.isEmpty(dataByMetricCategories) && ( */}
        <p className="dd-info-message">
          <i className="fal fa-info-circle" />
          Please note All data on the page, including Industry and Market Unit details, are being fetched from Benchmark
          Analytics tool and shown.
        </p>
        {_.map(dataByMetricCategories, (metrics) => (
          <>
            <h2>
              <span>{metrics.metricCategory}</span>
            </h2>
            <div className="custom-carousel-padding">
              {!_.isEmpty(dataByMetricCategories) && (
                <OwlCarousel options={options} className="owl-carousel">
                  {metrics.metrics.map((eachMetric, index) => {
                    let { metricUnitFormatted } = eachMetric;
                    if (metricUnitFormatted !== '%') {
                      metricUnitFormatted = ` ${metricUnitFormatted}`;
                    }
                    return (
                      <div
                        className={classNames(
                          'item',
                          {
                            'green-color':
                              !_.isNaN(eachMetric.clientMetricValue) && metrics.performanceIndicator === 'better',
                          },
                          {
                            'amber-color':
                              !_.isNaN(eachMetric.clientMetricValue) && metrics.performanceIndicator === 'worse',
                          },
                          {
                            'red-color':
                              !_.isNaN(eachMetric.clientMetricValue) && metrics.performanceIndicator === 'worst',
                          },
                          { 'gray-color': !!_.isNaN(eachMetric.clientMetricValue) },
                        )}
                        data-place="bottom"
                        currentitem="false"
                        key={eachMetric.metricName}
                      >
                        <div className="item-left">
                          <p className="tile-metric-value">
                            {eachMetric.metricValue}
                            <span>
                              {metricUnitFormatted}
                            </span>
                          </p>
                          <p className="tile-metric-desc">{eachMetric.metricName}</p>
                        </div>
                        <div className="item-right">
                          <div className="metric-details-icon" data-tip data-for={`benchmarkTooltip${index}`}>
                            {eachMetric.isExternalBenchmarksAvailable && <i className="fal fa-bullseye-pointer" />}
                            <ReactTooltip place="left" effect="solid" id={`benchmarkTooltip${index}`}>
                              <span>External Benchmark Available</span>
                            </ReactTooltip>
                            <ReactTooltip place="bottom" effect="solid" className="tipcolor" />
                          </div>

                          <div className="tilequartile-performance">
                            <p>Top quartile performance </p>
                            <span>
                              {!_.isNaN(eachMetric.topQuartilePerformance) && eachMetric.topQuartilePerformance}
                              {metricUnitFormatted}
                            </span>
                          </div>
                          <div className="tilequartile-performance">
                            <p>Median</p>
                            <span>
                              {!_.isNaN(eachMetric.median) && eachMetric.median}
                              {metricUnitFormatted}
                            </span>
                          </div>
                        </div>

                        <div className="dropdown dropleft">
                          <span
                            type="button"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            data-tip
                            data-for="moreActionTooltip"
                          />
                          {location.pathname === '/admin/Configurator' ? (
                            <div className="dropdown-menu">
                              <a
                                href="javascript:void(0)"
                                className="dropdown-item"
                                onClick={() => this.handleClickMetricInfoIcon(eachMetric)}
                              >
                                <span className="info-icon" />
                                Info
                              </a>
                            </div>
                          ) : (
                            <div className="dropdown-menu">
                              <a
                                href="javascript:void(0)"
                                className="dropdown-item"
                                onClick={() => this.handleClickMetricInfoIcon(eachMetric)}
                              >
                                <span className="info-icon" />
                                Info
                              </a>
                              <a
                                href="javascript:void(0)"
                                className="dropdown-item"
                                onClick={() => this.displayMetricBenchmarkChart(eachMetric)}
                              >
                                <span className="insights-new-icon" />
                                Insights
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              )}
              <ReactTooltip place="bottom" effect="solid" id="moreActionTooltip">
                <span>More Actions</span>
              </ReactTooltip>
            </div>
          </>
        ))}

        {/* METRIC INSIGHT DETAILS MODAL WHICH DISPLAYS THE GRAPH */}
        {isVisibleMetricInsightDetailsModal && (
          <MetricInsightDetails
            offeringName={BATOfferingName}
            offeringType={BATOfferingType === 'Industrial' ? 'industry' : _.toLower(BATOfferingType)}
            subOfferingName={BATSubOfferingName}
            metricData={currentlySelectedMetricData}
            startDate={formattedStartDate}
            endDate={formattedEndDate}
            onModalClose={() => this.handleCloseMetricInsightDetailsModal()}
            clientCount={clientCount}
            parentScreen="diverseData"
            existingBATClientName={BATClientName}
            existingSynOpsClientName={clientData.name}
            isExistingClient
          />
        )}

        {/* MODAL TO SHOW METRIC INFO WHEN CLICKED ON INFO ICON */}
        {isVisibleMetricInfoModal && (
          <MetricInfoModal
            title={currentlySelectedMetricDataForInfo.metricName}
            description={currentlySelectedMetricDataForInfo.metricDescription}
            onClose={() => this.handleMetricInfoModalHide()}
          />
        )}

        {selectedDataType === 'frontDoorConfigData' && <DiverseKpiContiner attributes={attributes} />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  clientData: state.CurrentFilter.ClientData,
  BATClientName: state.BATUsersReducer.BATData.BATClientName,
  BATOfferingName: state.BATUsersReducer.BATData.BATOfferingName,
  BATOfferingType: state.BATUsersReducer.BATData.BATOfferingType,
  BATSubOfferingName: state.BATUsersReducer.BATData.BATSubOfferingName,
  FetchFromBAT: state.CurrentFilter.FetchFromBAT,
  ShowBannerMessage: state.CurrentFilter.ShowBannerMessage,
  syncFilter: state.CurrentFilter.SyncFilter,
  MonthToShowOnBannerMessage: state.CurrentFilter.MonthToShowOnBannerMessage,
  LanguageId: state.CurrentFilter.languageData.id,
  Month: state.CurrentFilter.SyncFilter.Month,
});

DDBatView.propTypes = {
  clientData: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  syncFilter: PropTypes.object.isRequired,
  selectedDataType: PropTypes.string.isRequired,
  BATClientName: PropTypes.string.isRequired,
  BATOfferingName: PropTypes.string.isRequired,
  BATSubOfferingName: PropTypes.string.isRequired,
  BATOfferingType: PropTypes.string.isRequired,
  attributes: PropTypes.array.isRequired,
  LanguageId: PropTypes.number.isRequired,
  Month: PropTypes.string.isRequired,
};

export default withRouter(connect(mapStateToProps)(DDBatView));
