import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Found from "../Images/intel-4.png";
import Auto from "../Images/intel-3.png";
import Smrt from "../Images/intel-2.png";
import intlgnt from "../Images/intel-1.png";
import { IOJData } from "../Shared/Constant";
import i18n from "i18next";

class DealDetailedReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: this.props.show,
            mS: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            From: null,
            To: null,
            displayOverView: true,
            displayPhaseDetails: false,
            displaycardDetails: false,
            cardDetails: null,
            phaseDetails: '',
            updateIojData: [],
            paginationCardDetails: [],
            pagination: {
                pageNo: 1
            }
        }
    }
    handleHide = () => {
        this.setState({ ...this.state, show: false })
        this.props.close();
    }
    componentDidMount() {
        let from = new Date(this.props.period.From);
        let formmatedFrom = this.state.mS[from.getMonth()] + ', ' + from.getFullYear();
        let to = new Date(this.props.period.To);
        let formmatedTo = this.state.mS[to.getMonth()] + ', ' + to.getFullYear();
        this.setState({ ...this.state, From: formmatedFrom, To: formmatedTo })
    }
    openCards = (val) => {
        let updatedIojData = IOJData.filter((each) => {
            if (each.IOJName !== val) {
                return each;
            }
        })
        this.setState({ ...this.state, phaseDetails: val, updateIojData: updatedIojData, displayOverView: false, displayPhaseDetails: true, displaycardDetails: false })
    }
    CardDetails = (val) => {
        let paginationCardDetails = [];
        if (val.intelOps.length > 4) {
            let intelopsData = [];
            val.intelOps.map((e, index) => {
                intelopsData.push(e);
                if (((index + 1) % 4) === 0) {
                    paginationCardDetails.push({ CategoryStatus: val.CategoryStatus, CategoryName: val.CategoryName, CategoryOutcome: val.CategoryOutcome, intelOps: intelopsData, MaturityPhase: val.MaturityPhase })
                    intelopsData = [];

                }
                if ((((index + 1) % 4) !== 0) && val.intelOps.length === index + 1) {
                    paginationCardDetails.push({ CategoryStatus: val.CategoryStatus, CategoryName: val.CategoryName, CategoryOutcome: val.CategoryOutcome, intelOps: intelopsData, MaturityPhase: val.MaturityPhase })
                }
            })
        }
        this.setState({ ...this.state, cardDetails: val, displayPhaseDetails: false, pagination: { pageNo: 1 }, paginationCardDetails: paginationCardDetails, displaycardDetails: true })
    }
    pagination = (val) => {
        if (val === "left") {

            this.setState({ ...this.state, pagination: { pageNo: this.state.pagination.pageNo - 1 } })

        } else {
            this.setState({ ...this.state, pagination: { pageNo: this.state.pagination.pageNo + 1 } })
        }
    }
    render() {
        return (
            <Modal show={this.state.show} onHide={this.handleHide} dialogClassName="dealReportModal" backdrop="static" bsSize="xl" className="modal fade v-center" id="dealpopup">
                <Modal.Header closeButton className="no-border">
                </Modal.Header >
                <Modal.Body>
                    <div className="detailed-report-container">
                        {this.state.displayOverView ?
                            <div >
                                <div className="row">
                                    <div className="col-md-6 detailed-report-header">
                                        <h1>{this.props.client}</h1>
                                        <h5>{i18n.t("Intelligent_Operations_Overview")}</h5>
                                        <p>{`${this.state.From} - ${this.state.To} `}</p>
                                    </div>
                                </div>
                                <div className="row deal-detailed-report">

                                    <div className="col-md-3 deal-detail-foundation" onClick={this.openCards.bind(this, "Foundation")}>
                                        <div className="detailed-report-box">
                                            <p><span className="imageAlignment"><img src={Found} alt="found-Image"></img></span><span className="p-l-5">{i18n.t("Foundation")}</span></p>
                                            {this.props.categories.map((e) => {
                                                if (e.MaturityPhase === "Foundation") {
                                                    return (
                                                        <div>
                                                            <p className={` p-l-20 `}>{e.CategoryName}</p>

                                                        </div>
                                                    )
                                                }

                                            })}
                                        </div>
                                    </div>
                                    <div className="col-md-3 deal-detail-automation" onClick={this.openCards.bind(this, "Automated")}>
                                        <div className="detailed-report-box">
                                            <p className="m-l-12p"><span className="imageAlignment"><img src={Auto} alt="found-Image"></img></span><span className="p-l-5">{i18n.t("Automated")}</span></p>
                                            {this.props.categories.map((e) => {
                                                if (e.MaturityPhase === "Automated") {
                                                    return (
                                                        <div>
                                                            <p className={` p-l-20 `}>{e.CategoryName}</p>

                                                        </div>
                                                    )
                                                }

                                            })}
                                            <div className="detailed-report-box-cross"><span></span></div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 deal-detail-insights" onClick={this.openCards.bind(this, "Insights")}>
                                        <div className="detailed-report-box">
                                            <p className="m-l-12p"><span className="imageAlignment"><img src={Smrt} alt="found-Image"></img></span><span className="p-l-5">{i18n.t("Insights")}</span></p>
                                            {this.props.categories.map((e) => {
                                                if (e.MaturityPhase === "Insights") {
                                                    return (
                                                        <div>
                                                            <p className={` p-l-20 `}>{e.CategoryName}</p>

                                                        </div>
                                                    )
                                                }

                                            })}
                                            <div className="detailed-report-box-cross"><span></span></div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 deal-detail-intelligent" onClick={this.openCards.bind(this, "Intelligent")}>
                                        <div className="detailed-report-box">
                                            <p className="m-l-12p"><span className="imageAlignment"><img src={intlgnt} alt="found-Image"></img></span><span className="p-l-5" >{i18n.t("Intelligent")}</span></p>
                                            {this.props.categories.map((e) => {
                                                if (e.MaturityPhase === "Intelligent") {
                                                    return (
                                                        <div>
                                                            <p className={` p-l-20 `}>{e.CategoryName}</p>

                                                        </div>
                                                    )
                                                }

                                            })}
                                            <div className="detailed-report-box-cross"><span></span></div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                        {this.state.phaseDetails ?
                            <div className="detailed-report-details">
                                <div className="row">
                                    <div className="col-md-12 detailed-report-details-header">
                                        <p>{this.props.client} <span className="p-l-20">.<span className="p-l-20">{i18n.t("Intelligent_Operations_Overview")}</span></span>
                                            <span className="p-l-20">.<span className="p-l-20">{`${this.state.From} - ${this.state.To} `}</span></span> </p>
                                        <h3><span className="imageAlignment"><img src={Found} alt="found-Image"></img></span><span className="p-l-5">{this.state.phaseDetails}</span>{IOJData.map(e => { if (e.IOJName === this.state.phaseDetails) { return <span className="subtitle">{e.IOJDesc}</span> } })}</h3>

                                    </div>
                                </div>
                                <div className="row p-t-20">
                                    <div className="col-md-9">
                                        {this.state.displayPhaseDetails ?
                                            <div className="row">
                                                {this.props.categories.map((e) => {
                                                    if (e.MaturityPhase === this.state.phaseDetails) {
                                                        return (
                                                            <div className="col-md-4" onClick={this.CardDetails.bind(this, e)}>
                                                                <div className={`${e.CategoryStatus === "1" ? "detailed-report-left-box green " : ''}${e.CategoryStatus === "2" ? "detailed-report-left-box orange-clr " : ''}${e.CategoryStatus === "3" ? "detailed-report-left-box red-clr " : ''}`}>
                                                                    <p>{e.CategoryName}</p>
                                                                    <p>{e.intelOps.length} {i18n.t("Projects_Initiatives")}</p>
                                                                    <p>{e.CategoryOutcome}</p>
                                                                </div>

                                                            </div>

                                                        )
                                                    }

                                                })}

                                            </div> : null}
                                        {this.state.displaycardDetails ? <div className="curve-hidden"><div className="row height-450">
                                            <div className="col-md-5">
                                                <div className={`card-detail-holder ${this.state.cardDetails.CategoryStatus === "1" ? "green-clr" : ''}${this.state.cardDetails.CategoryStatus === "2" ? "orange-clr" : ''}${this.state.cardDetails.CategoryStatus === "3" ? "red-clr" : ''}`}>
                                                    <div className="card-detail-holder-category">
                                                        <h4 className="ht-title">{this.state.cardDetails.CategoryName}</h4>
                                                        <p>{this.state.cardDetails.CategoryOutcome}</p>
                                                    </div>
                                                  
                                                </div>

                                            </div>
                                            {this.state.paginationCardDetails.length ? <div className="col-md-7 card-initiatives">
                                                <div className="card-initiatives-centre-align">
                                                {this.state.paginationCardDetails.map((e, i) => {
                                                    if ((i + 1) === this.state.pagination.pageNo) {
                                                        return (e.intelOps.map((each, index) => {
                                                            return (
                                                                <div>
                                                                    <div className={`row ${e.intelOps.length ===4 ? `card-initiative-alignment-${index}`:'' } ${e.intelOps.length === 1 || e.intelOps.length === 2 ? `card-initiative-alignment-2`:'' } ${e.intelOps.length === 3 ? `card-initiative-alignment-${index}-${index}`:'' }`}>
                                                                        <div className="col-md-4">
                                                                            <div className="m-b-0"><span className="value-font">{each.InitiativeStatus === "Delivered" ? each.DeliveredValue : each.TargetValue }</span> <span>{each.InitiativeStatus === "Delivered" ? each.ValueUnit: each.EstimatedUnit}</span></div>
                                                                           
                                                                            <p className="m-b-0">{each.InitiativeStatus}</p>
                                                                           
                                                                            <p className="m-b-0">{this.state.mS[new Date(each.UpdatedTS).getMonth()] + ", " + new Date(each.UpdatedTS).getFullYear()}</p>
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                            <p className="m-b-0 font-color-grey">{each.ProjectInitiativeName}</p>
                                                                            <p className="m-b-0">{each.BOImpacted}</p>
                                                                            <p className="m-b-0 over-flow-ellipsis">{each.ProjectDescription}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        )
                                                    }

                                                })}
                                                </div>

                                            </div> :
                                                <div className="col-md-7 card-initiatives">
                                                     <div className="card-initiatives-centre-align">
                                                    {this.state.cardDetails.intelOps.map((each, index) => {
                                                        return (
                                                            <div>
                                                                <div className={`row ${this.state.cardDetails.intelOps.length ===4 ? `card-initiative-alignment-${index}`:'' } ${this.state.cardDetails.intelOps.length === 1 || this.state.cardDetails.intelOps.length === 2 ? `card-initiative-alignment-2`:'' } ${this.state.cardDetails.intelOps.length === 3 ? `card-initiative-alignment-${index}-${index}`:'' }`}>
                                                                    <div className="col-md-4">
                                                                    <div className="m-b-0"><span className="value-font">{each.InitiativeStatus === "Delivered" ? each.DeliveredValue : each.TargetValue }</span> <span>{each.InitiativeStatus === "Delivered" ? each.ValueUnit: each.EstimatedUnit}</span></div>
                                                                    
                                                                        <p className="m-b-0">{each.InitiativeStatus}</p>
                                                                        
                                                                        <p className="m-b-0">{this.state.mS[new Date(each.UpdatedTS).getMonth()] + ", " + new Date(each.UpdatedTS).getFullYear()}</p>
                                                                    </div>
                                                                    <div className="col-md-8">
                                                                        <p className="m-b-0 font-color-grey">{each.ProjectInitiativeName}</p>
                                                                        <p className="m-b-0">{each.BOImpacted}</p>
                                                                        <p className="m-b-0 over-flow-ellipsis">{each.ProjectDescription}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}</div>
                                                </div>} 
                                        </div></div> : null}
                                        {this.state.displaycardDetails && this.state.paginationCardDetails.length ?
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="deal-report-pagination">
                                                        {this.state.pagination.pageNo === 1 ? <div className={`deal-report-page-left`} ></div> :
                                                            <div className={`active deal-report-page-left`} onClick={this.pagination.bind(this, "left")}></div>}
                                                        {this.state.pagination.pageNo === this.state.paginationCardDetails.length ? <div className={` deal-report-page-right`} ></div> :
                                                            <div className={`active deal-report-page-right`} onClick={this.pagination.bind(this, "right")}></div>}
                                                    </div>
                                                </div>
                                            </div> : null}
                                    </div>
                                    <div className="col-md-3">
                                        <div className="float-right">
                                        {
                                            this.state.updateIojData.map((each, index) => {
                                                if (each.IOJName !== this.state.phaseDetails) {
                                                    return (
                                                        <React.Fragment>
                                                            <div className={`detailed-report-box right-box-${index + 1}`} onClick={this.openCards.bind(this, each.IOJName)}>
                                                                <p><img src={each.image} alt="found-Image"></img></p>
                                                                {each.IOJName.split('').map(e => {
                                                                    return (
                                                                        <p>{e}</p>
                                                                    )
                                                                })}
                                                                <div className="detailed-report-box-cross"></div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                            })}
                                            </div>
                                       
                                    </div>

                                </div>
                            </div> : null}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
export default DealDetailedReport;
