import React from 'react';
import i18n from 'i18next';
import { Modal} from "react-bootstrap";
import './logoutConfirmationpopup.css';
import PropTypes from 'prop-types';

const logoutConfirmationpopup=({isIdle,logOut,clearTimeOut})=> {
    return (
        <>
        {isIdle &&(
            <Modal show={isIdle} centered className="log-out-poup" backdrop="static">
              <Modal.Body>
                <div className="session-message">
                <h6>{i18n.t("session_timedout")}</h6>
                <h4>{i18n.t("idle_message")}</h4>
                </div>
              </Modal.Body>
              <Modal.Footer className="modal-btn">
                {/* <button type="button" className="btn btn-outline-secondary mr-2" onClick={this.onClose}>
                  {i18n.t("Delete")}
                </button> */}
                <button type="button" className="log-out" onClick={logOut}>
                  {i18n.t("Logout")}
                </button>
                <button type="button" className="continue" onClick={clearTimeOut}>
                  {i18n.t("Continue")}
                </button>
              </Modal.Footer>
            </Modal>
        )
          }
          </>
    )
}
logoutConfirmationpopup.propTypes = {
  isIdle: PropTypes.bool.isRequired,
  logOut: PropTypes.func.isRequired,
  clearTimeOut: PropTypes.func.isRequired
}

export default React.memo(logoutConfirmationpopup);
