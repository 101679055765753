import React from "react";
import { Modal } from "react-bootstrap";
import { LocalApiBaseUrl } from "../Shared/Constant";
import i18n from "i18next";
import { connect } from "react-redux";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";

class KpiPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      apivalue: "",
      filter: { OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null },
      errors: {},
      formIsValid: false,
      kpiData: {
        MetricName: null,
        MetricUnit: "",
        KpiDescription: "",
      },
    };
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onUnitChange = this.onUnitChange.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleRejectHide = this.handleRejectHide.bind(this);
    this.handleApproveHide = this.handleApproveHide.bind(this);
    this.handleSave = this.handleSave.bind(this);
    let offeringValue = "";
    let value1 = [];
  }
  onNameChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const kpiData = this.state.kpiData;
    kpiData.MetricName = event.target.value;
    this.setState({
      kpiData: kpiData,
    });
  }

  onDescriptionChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const kpiData = this.state.kpiData;
    kpiData.KpiDescription = event.target.value;
    this.setState({
      kpiData: kpiData,
    });
  }

  onUnitChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const kpiData = this.state.kpiData;
    kpiData.MetricUnit = event.target.options[event.target.selectedIndex].value;
    this.setState({
      kpiData: kpiData,
    });
  }
componentDidMount(){
  let unitsData = this.props.unitsData;
  this.setState({unitsData:unitsData})
}
componentDidUpdate(prevProps){
  if(prevProps.unitsData !== this.props.unitsData){
    this.setState({unitsData:this.props.unitsData})
  }
}
  handleSave(e) {
    if (this.validateForm()) {
      var filter = {
        LocationID: this.props.filterDetails.Locations,
        OrgEntityID: this.props.filterDetails.Client,
        OfferingID: this.props.filterDetails.process,
        SOCategoryID: this.props.SOCategoryID,
      };

      var data = {
        DealTemplateApprovalModel: this.state.kpiData,
        FiltersIDsVM: filter,
      };

      axiosInstance
        .post(
          `${LocalApiBaseUrl}DealTemplate/PostMatricDetailRequestedForApproval`,
          data
        )
        .then((response) => {
          alert("Sent for Approval");
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });


      this.handleHide();
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }

  validateForm() {
    const kpiData = this.state.kpiData;
    let errors = {};
    let formIsValid = true;
    if (kpiData["MetricName"]) {
      kpiData["MetricName"] = kpiData["MetricName"].trim();
    }
    if (!kpiData["MetricName"]) {
      formIsValid = false;
      errors["MetricName"] = "Name is required";
    }
    if (kpiData["KpiDescription"]) {
      kpiData["KpiDescription"] = kpiData["KpiDescription"].trim();
    }
    if (!kpiData["KpiDescription"]) {
      formIsValid = false;
      errors["KpiDescription"] = "Description is required";
    }
    if (kpiData["MetricUnit"]) {
      kpiData["MetricUnit"] = kpiData["MetricUnit"];
    }
    if (!kpiData["MetricUnit"]) {
      formIsValid = false;
      errors["MetricUnit"] = "Unit is required";
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
        errors: {},
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  render() {
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={this.props.show}
          onHide={this.handleHide}
          className="modal fade"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title"> <span>{i18n.t("Add_New_Metric_Detail")}</span> </h4>                                    


          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">
                      {i18n.t("Name")}<span className="required">*</span>
                    </label>

                    <input
                      type="text"
                      maxLength="100"
                      className="form-control"
                      id="name"
                      onChange={this.onNameChange}
                    />
                    <span className="errorMsg">
                      {this.state.errors.MetricName}
                    </span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">
                      {i18n.t("Description")} <span className="required">*</span>
                    </label>
                    <input
                      maxLength="50"
                      type="text"
                      className="form-control"
                      id=""
                      onChange={this.onDescriptionChange}
                    />
                    <span className="errorMsg">
                      {this.state.errors.KpiDescription}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">
                      {i18n.t("Unit_of_Measure")} <span className="required">*</span>
                    </label>
                      <select className="form-control" value={this.state.kpiData.MetricUnit} onChange={this.onUnitChange} >
										<option value="">{i18n.t("Please_Select_Unit")}</option>
										{this.state.unitsData.map((each)=>{
											return <option value={each.MetricUnit_En}>{each.MetricUnit}</option>
										})}
									</select>
                    <span className="errorMsg">
                      {this.state.errors.MetricUnit}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value="Create"
                />
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return ({
  languageData: state.CurrentFilter.languageData,
  unitsData: state.BOMasterReducers.unitsData
  })
}
export default connect(mapStateToProps)(KpiPopUp);
