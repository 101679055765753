import React from 'react';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import jquery from 'jquery';
import { store } from "../reducers/configureStore";
import HumanPopUp from '../SuperAdmin/Humanconf_popup';
import { LocalApiBaseUrl } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';
import {trycatchAlertPopup} from "../Shared/Constant";
window.$ = window.jQuery = jquery;


export default class HumanMetricDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            viewmore: false,
            kpiData: []
        }
    }

    dealpopup = (data, e) => {
        e.preventDefault();
        this.setState({
            viewmore: true,
            kpiData: data
        })
    }

    onClose = (e) => {
        this.setState({ viewmore: false },()=>{this.props.onClose(e)});
        
    }

    deleteKpi(data, e) {
        if (window.confirm("Are you sure you want to delete metric detail?")) {
            axiosInstance.post(`${LocalApiBaseUrl}Master/DeleteKpiMetricMasterTemplate?kpiMappingId=${data.KPIMappingID}`)

                .then((response) => {
                    window.alert(response.data.StatusMessage)
                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
                  var human={
                    kpiData:data,
                    selectedOption:this.props.selectedOption
                  }
                  var machine={
                    kpiData:data,
                    machineWorkforceCat:this.props.machineWorkforceCat
                  }
                  if (this.props.Name == this.props.selectedOption) {
                    store.dispatch({
                      type: "REMOVE_HUMAN_MASTER",
                      payload: human,
                    });
                  }
              
                else if(this.props.Name == this.props.machineWorkforceCat ) {
                  store.dispatch({
                    type: "REMOVE_MACHINE_MASTER",
                    payload: machine,
                  });
                }
        }
    }

    render() {
        const options = {
            loop: true,
            margin: 24,
            responsiveClass: "",
            dots: false,
            responsive: {
              0: {
                items: 1,
                nav: true
              },
              600: {
                items: 2,
                nav: false
              },
              1000: {
                  items: 3,
                  nav: true,
                  loop: false
              },
              1440: {
                  items: 4,
                  nav: true,
                  loop: false
                },
                1600: {
                    items: 4,
                    nav: true,
                    loop: false
                },
                1800: {
                    items: 5,
                    nav: true,
                    loop: false
              }
            }
          };

        return (
            <>    
                {this.props.Details.map((eachDetails) => {
                    let AttrValue = "";
                    let metricName = "";
                    let unit = "";
                    let metricData = "";
                    let Description = "";
                    let viewmore = false;
                    let ValueBlock = true;
                    let ToolTip = "";
                    if (eachDetails != undefined) {
                        metricName = eachDetails.MetricName
                        unit = eachDetails.MetricUnit
                    }
                    let statusColor = "item gray-color";

                    metricData = eachDetails.MetricValue;
                    Description = eachDetails.KPIDesp ? eachDetails.KPIDesp : ""; 

                    unit = eachDetails.MetricUnit
                    if (eachDetails.RAGStatus != undefined && eachDetails.RAGStatus != null) {
                        switch (eachDetails.RAGStatus.toLowerCase()) {
                          case "grey":
                            statusColor = "item gray-color";
                            break;
                          case "red":
                            statusColor = "item red-color";
                            break;
                          case "orange":
                            statusColor = "item amber-color";
                            break;
                          case "green":
                            statusColor = "item green-color";
                            break;
                          case "amber":
                            statusColor = "item amber-color";
                            break;
                          default:
                          // code block
                        }
                      }
                    return (
                        <>
                        
                   {eachDetails.KPIMappingID !=0 && 
                    <div className={`${statusColor}`} data-place="bottom" data-tip={ToolTip}>
                        <div className="item-left">   
                            {ValueBlock ? (
                                <>
                                <p className="tile-metric-value">                            
                                    {
                                    (unit)                              
                                    }
                                </p>
                                </>
                             ) : null}        
                                  <p className="tile-metric-desc" data-tip data-for={'customTooltip' + eachDetails.KPIMappingID}>
                                        {metricName}
                                    </p>
                                    </div>
                        <div className="item-right">
                                        {Description ? (
                                            <p className="tile-metric-name" data-tip data-for={'customTooltip_desc' + eachDetails.KPIMappingID}>
                                            {Description}
                                    </p>
                          ) : (
                            <p className="tile-metric-name">&nbsp;</p>
                          )}                          
                          </div>                 
                          {this.props.roledata != undefined &&
                  (this.props.roledata.includes("Edit") ||
                    this.props.roledata.includes("Delete")) ?        
                        <div className="dropdown dropleft">
                        <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                            <div className="dropdown-menu">
                              {this.props.roledata.includes("Delete") && 
                            <a
                            href="javascript:void(0)"
                            className="dropdown-item"
                            onClick={this.deleteKpi.bind(this, eachDetails)}
                            >
                            <span className="delete-icon"></span>Remove
                            </a>
                            }
                            {this.props.roledata.includes("Edit") && 
                            <a
                            href="javascript:void(0)"
                            className="dropdown-item"
                            onClick={this.dealpopup.bind(this, eachDetails)}
                            >
                            <span className="edit-icon"></span>Edit
                            </a>
                            }
                            </div>
                        </div>
                        :
                        null}
                    </div>}
                    </>
                )})}

                {this.state.viewmore ? 
                    <HumanPopUp 
                        CatagoryName={this.props.CatagoryName}
                        SOCategoryID={this.props.SOCategoryID} 
                        type={this.props.Name} 
                        kpiData={this.state.kpiData} 
                        show={this.state.viewmore} 
                        onClose={this.onClose.bind(this)} 
                        opsgrp={this.props.opsgrp} 
                        filterDetails={this.props.filterDetails} 
                        machineWorkforceCat={this.props.machineWorkforceCat}
                        selectedOption={this.props.selectedOption}
                        showPopup={this.props.roledata != undefined && (this.props.roledata.includes("Edit") || this.props.roledata.includes("Add"))} 
                    /> : null}
            </>
        );
    }
}