import React from "react";
import IntOps from "synops-intops-ui/dist/index";
import { LocalApiBaseUrl, idTokenstring } from "../Shared/Constant";

const IOJScoreCard = () => {
  const selectedClient = JSON.parse(sessionStorage.getItem("clientSelection")).id;
  const idToken = sessionStorage.getItem(idTokenstring);
  return (
    <div>
      <IntOps
        selectedClient={selectedClient}
        localBaseUrl={LocalApiBaseUrl}
        idToken={idToken}
      />
    </div>
  );
};

export default IOJScoreCard;
