import React, { Component } from 'react';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl,idTokenstring } from '../Shared/Constant';
import './JourneyInitiative.css';
import { Modal } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';

import i18n from 'i18next';
import EmptyCriteria from '../Images/EmptyCriteria.svg';
import DeleteModal from './DeleteModal';
import { connect } from 'react-redux';
import Pagination from './Pagination';
import ReactTooltip from 'react-tooltip';
import SynopsLoader from '../Shared/SynopsLoader';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove,
} from 'react-sortable-hoc';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;


class AssessmentCriteria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAccess: this.props.isAccess,
      isaddCriteriaDropdownStatus: false,
      iscriteriaStatusDropdownStatus: false,
      initiativeStage: this.props.initiativeStage,
      addAssessmentFormStatus: false,
      deleteAssessment: false,
      deleteAssessmentData: {},
      successNotificationModalStatus: false,
      showsuccessModal: false,
      selectedInitiative: 'All',
      addNewOptionStatus: false,
      selectedAssessmentCriteriaValue: '',
      selectedStatusvalue: '',
      selectedAssessmentCriteriaID: undefined,
      isApiLoading: false,
      addAssessmentCriteriaListData: [],
      updatedAssessmentName: '',
      updatedAssessmentStatus: '',
      isEditAssessmentCriteria: false,
      EditingAssessmentId: 0,
      errorMessage: '',
      initiativeStageId: 1,
      currentFilterUpdated: '',
      currentPage: 1,
      postsPerPage: 10,
    };
  }

  onClickFetchaddCriteriaInitiative = (selectedData) => {
    if (selectedData.target.textContent === '<Add New>') {
      this.setState({
        addNewOptionStatus: true,
        selectedAssessmentCriteriaValue: '',
        selectedAssessmentCriteriaID: undefined,
        selectedStatusvalue: '',
      });
      this.props.SelectedAssesmentCriteria(undefined, '<Add New>');
      this.props.SelectedStatusCriteria('');
    } else {
      this.props.SelectedAssesmentCriteria(
        selectedData.target.id,
        selectedData.target.textContent,
      );
      this.props.SelectedStatusCriteria('');

      this.setState({
        selectedAssessmentCriteriaValue: selectedData.target.textContent,
        selectedAssessmentCriteriaID: selectedData.target.id,
        selectedStatusvalue: '',
      });
      // setTimeout(() => {
      //   this.isaddCriteriaDropdownOverlay();
      // }, 100);
      this.setState({ addNewOptionStatus: false });
    }
    setTimeout(() => {
      this.isaddCriteriaDropdownOverlay();
    }, 100);
  };

  successNotificationModalShow = () => {
    this.setState({
      successNotificationModalStatus: true,
    });
    setTimeout(() => {
      this.setState({ successNotificationModalStatus: false });
    }, 3000);
  };

  successNotificationModalClose = () => {
    this.setState({ successNotificationModalStatus: false });
  };

  deleteItemModalShow = (assessment) => {
    this.setState({ deleteAssessment: true, deleteAssessmentData: assessment });
  };

  deleteItemModalClose = () => {
    this.setState({ deleteAssessment: false });
  };

  hideAssessment = (assessment) => {
    this.setState({ isApiLoading: true });
    const { getAssessments } = this.props;
    axiosInstance
      .post(
        `${LocalApiBaseUrl}IOJJourneyInitiative/ShowHideAssessmentCriteria?ID=${assessment.ID}`,
      )
      .then((response) => {
        if (response.data) {
          this.setState({
            isApiLoading: false,
          });
          getAssessments();
        }
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });
  };

  handleEditAsessments = (data) => {
    const editData = JSON.parse(JSON.stringify(data));

    this.setState({
      selectedAssessmentCriteriaValue: editData.CriteriaName,
      selectedStatusvalue: editData.RAGStatus,
      EditingAssessmentId: editData.ID,
      addNewOptionStatus: editData.IsCustom,
    });
    this.props.editAssessmentCriteria(
      editData,
      this.state.isEditAssessmentCriteria,
      this.state.addNewOptionStatus,
    );
  };

  successRemovalModalShow = () => {
    const { deleteAssessmentData } = this.state;
    const { getAssessments } = this.props;
    this.setState({ isApiLoading: true });
    axiosInstance
      .delete(
        `${LocalApiBaseUrl}IOJJourneyInitiative/DeleteAssessmentCriteria?ID=${deleteAssessmentData.ID}`,
      )
      .then((response) => {
        if (response.data) {
          this.setState({
            showsuccessModal: true,
            deleteAssessment: false,
            isApiLoading: false,
          });
          this.props.settingfilterChangedState();
          getAssessments();
        }
      })
      .catch((error) => {
        this.setState({ isApiLoading: false, deleteAssessment: false });
        trycatchAlertPopup(error);
      });
  };

  successRemovalModalClose = () => {
    this.setState({ showsuccessModal: false });
  };

  isaddCriteriaDropdown = () => {
    this.setState({ isaddCriteriaDropdownStatus: true });
  };

  isaddCriteriaDropdownOverlay = () => {
    this.setState({ isaddCriteriaDropdownStatus: false });
  };

  iscriteriaStatusDropdown = () => {
    this.setState({ iscriteriaStatusDropdownStatus: true });
  };

  iscriteriaStatusDropdownOverlay = () => {
    this.setState({ iscriteriaStatusDropdownStatus: false });
  };

  onClickFetchcriteriaStatusInitiative = (selectedData) => {
    this.props.SelectedStatusCriteria(selectedData);
    this.setState({ selectedStatusvalue: selectedData });

    setTimeout(() => {
      this.iscriteriaStatusDropdownOverlay();
    }, 100);
  };

  getAssessmentList = () => {
    const {
      currentFilter,
      languageData,
      clientData,
      initiativeStageId,
    } = this.props;
    this.setState({
      isApiLoading: false,
    });
    if (String(currentFilter.LocationId) !== 'All') {
      axiosInstance
        .get(
          `${LocalApiBaseUrl}IOJJourneyInitiative/GetAssessmentCriteriaMaster?LanguageId=${languageData.id}&OfferingId=${currentFilter.ProcessId}&OrgEntityID=${clientData.id}&LocationID=${currentFilter.LocationId}&OMID=${currentFilter.LocationOMID}&IOJStageID=${initiativeStageId}`,
        )
        .then((response) => {
          this.setState({
            addAssessmentCriteriaListData: response.data,
            isApiLoading: false,
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { Datas } = nextProps;

    let currentPage = 1;
    if (nextProps.AssessmentData.length >= this.props.AssessmentData.length) {
      currentPage = this.state.currentPage;
    } else if (
      !Number.isInteger(
        nextProps.AssessmentData.length / this.state.postsPerPage,
      )
    ) {
      currentPage = this.state.currentPage;
    } else {
      currentPage = nextProps.AssessmentData.length / this.state.postsPerPage !== 0
        ? nextProps.AssessmentData.length / this.state.postsPerPage
        : 1;
    }
    if (nextProps.filterChanged) {
      currentPage = 1;
    }
    this.setState({
      isAccess: Datas.isAccess,
      currentPage,
    });
    if (
      nextProps.addAssessmentFormStatus
      !== this.props.addAssessmentFormStatus
      && !nextProps.isEditAssessment
    ) {
      this.setState({
        selectedAssessmentCriteriaValue: '',
        selectedAssessmentCriteriaID: undefined,
        selectedStatusvalue: '',
        EditingAssessmentId: 0,
        addNewOptionStatus: false,
      });
    }
  }

  componentDidMount() {
    this.getAssessmentList();
    const { addAssessmentFormStatus } = this.props;
    this.setState({
      addAssessmentFormStatus,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      getDataCounter,
      getAssessments,
      currentFilter,
      initiativeStageId,
    } = this.props;
    if (prevProps.getDataCounter !== getDataCounter) {
      getAssessments();
    }
    if (
      prevProps.currentFilter !== currentFilter
      || prevProps.initiativeStageId !== initiativeStageId
    ) {
      this.getAssessmentList();
    }
  }

  handleChangeCustomCriteria = ({ target }) => {
    this.setState({
      errorMessage: '',
    });
    this.props.SelectedAssesmentCriteria(undefined, target.value);

    this.setState({
      selectedAssessmentCriteriaValue: target.value,
      selectedAssessmentCriteriaID: undefined,
    });
  };

  handleCurrentPage = (data) => {
    this.setState({
      currentPage: data,
    });
  };

  handlePostsPerPage = (data) => {
    this.setState({
      postsPerPage: data,
    });
  };

  render() {
    const { addAssessmentCriteriaListData } = this.state;
    const { currentFilter } = this.props;

    let assessmentValueShown = 'Assessment Criteria *';
    let assessmentStatusShown = 'Assessment Criteria Status *';
    if (this.state.selectedAssessmentCriteriaValue) {
      assessmentValueShown = this.state.selectedAssessmentCriteriaValue;
    } else if (this.state.updatedAssessmentName) {
      assessmentValueShown = this.state.updatedAssessmentName;
    } else {
      assessmentValueShown = 'Assessment Criteria *';
    }

    if (this.state.selectedStatusvalue) {
      assessmentStatusShown = this.state.selectedStatusvalue;
    } else if (this.state.updatedAssessmentStatus) {
      assessmentStatusShown = this.state.updatedAssessmentStatus;
    } else {
      assessmentStatusShown = 'Assessment Criteria Status *';
    }

    // if (!this.props.addAssessmentFormStatus) {
    //   assessmentValueShown = "Assessment Criteria *";
    //   assessmentStatusShown = "Assessment Criteria Status *";
    // }

    const AssessmentCriteriaList = (
      <>
        <ul>
          {addAssessmentCriteriaListData.map((data) => (
            <li
              id={data.ID}
              onClick={(e) => this.onClickFetchaddCriteriaInitiative(e)}
            >
              {i18n.t(data.CriteriaName)}
            </li>
          ))}
        </ul>
      </>
    );
    const {
      AssessmentData,
      addAssessmentFormStatus,
      history,
      isEditAssessment,
      OMIDEnabled,
    } = this.props;
    const {
      addNewOptionStatus,
      iscriteriaStatusDropdownStatus,
      isaddCriteriaDropdownStatus,
      deleteAssessment,
      isApiLoading,
      isAccess,
      currentPage,
      postsPerPage,
    } = this.state;
    const indexOfLastPosts = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPosts - postsPerPage;
    const currentPosts = AssessmentData.sort((a, b) => (a.CriteriaName.toLowerCase() > b.CriteriaName.toLowerCase() ? 1 : -1)).slice(indexOfFirstPost, indexOfLastPosts);

    const SortableContainer = sortableContainer(({ children }) => <div className="drag-drop-body">{children}</div>);
    const SortableItem = sortableElement(({ value }) => (
      <>
        <div className={value.IsHidden ? "drag-drop-columns hidden-row" : "drag-drop-columns"}>
          <DragHandle />
          <div className={'columns-data ' + `${value.RAGStatus.toLowerCase()}` + 'Color'}><span className="name-highlighted">{value.CriteriaName}</span></div>
          {isAccess && history.location.pathname === '/admin/Configurator' &&
            currentFilter.isOMIDAvailable === false
            && (
              <div className="common-ellipsis-dropdown">
                <div className="dropdown">
                  <span type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" />

                  <div className="dropdown-menu dropdown-menu-right">

                    {OMIDEnabled
                      ? (
                        <>
                          <a className="dropdown-item" href="javascript:void(0);" onClick={() => this.handleEditAsessments(value)}>
                            <span className="edit-icon" />
                            Edit
                          </a>
                          <a className="dropdown-item" href="javascript:void(0);" onClick={() => this.deleteItemModalShow(value)}>
                            <span className="delete-icon" />
                            Delete
                          </a>
                          <a className="dropdown-item" href="javascript:void(0);" onClick={() => { this.hideAssessment(value); }}>
                            <span className={value.IsHidden ? 'eye-icon' : 'eye-icon-slash'} />
                            {value.IsHidden ? 'Show' : 'Hide'}
                          </a>
                        </>
                      )

                      : (
                        <>
                          <a className="dropdown-item disabled" href="javascript:void(0);">
                            <span className="edit-icon" />
                            Edit
                          </a>
                          <a className="dropdown-item disabled" href="javascript:void(0);">
                            <span className="delete-icon" />
                            Delete
                          </a>
                          <a className="dropdown-item disabled" href="javascript:void(0);">
                            <span className="eye-icon" />
                            Show
                          </a>
                        </>
                      )}
                  </div>

                </div>
              </div>
            )}
        </div>
      </>
    ));
    const DragHandle = sortableHandle(() => <span className="dragg-icon" />);

    return (
      <>
        {isApiLoading && (
          <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
        )}
        {!AssessmentData.length && !addAssessmentFormStatus ? (
          isAccess
            && history.location.pathname === '/admin/Configurator'
            && OMIDEnabled ? (
            <div className="emptycriteria-block">
              <img alt="" src={EmptyCriteria} width="78px" height="90px" />
              <h4>{i18n.t('Add_Assessment_Criteria')}</h4>
            </div>
          ) : (
            <div className="emptycriteria-block">
              <p>{i18n.t('No_Assessment_Added')}</p>
            </div>
          )
        ) : (
          ''
        )}
        {addAssessmentFormStatus ? (
          <div className="assessmentcriteria-form">
            {isEditAssessment ? (
              <h3>{i18n.t('Edit Assessment Criteria')}</h3>
            ) : (
              <h3>{i18n.t('Add_Assessment_Criteria')}</h3>
            )}
            <div className="assessmentcriteria-formfields">
              <div className="assessmentcriteria-dropdown">
                <span
                  className="assessmentcriteria-dropdownvalue"
                  onClick={this.isaddCriteriaDropdown}
                >
                  {addNewOptionStatus
                    ? '<Add New>'
                    : i18n.t(assessmentValueShown)}
                </span>
                {String(this.props.errMsg[0]) === '' ? (
                  ''
                ) : (
                  <span className="errorMsg">
                    {' '}
                    {this.props.errMsg[0]}
                    {' '}
                  </span>
                )}

                {isaddCriteriaDropdownStatus ? (
                  <div className="initiativeheading-dropdownlist">
                    {AssessmentCriteriaList}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {isaddCriteriaDropdownStatus ? (
                <div
                  className="initiative-dropdown-overlay"
                  onClick={this.isaddCriteriaDropdownOverlay}
                />
              ) : (
                ''
              )}
              {isaddCriteriaDropdownStatus ? (
                <div
                  className="initiative-dropdown-overlay"
                  onClick={this.isaddCriteriaDropdownOverlay}
                />
              ) : (
                ''
              )}
              <div className="assessmentcriteria-dropdown">
                <span
                  className="assessmentcriteria-dropdownvalue"
                  onClick={this.iscriteriaStatusDropdown}
                >
                  {i18n.t(assessmentStatusShown)}
                </span>
                {String(this.props.errMsg[2]) === '' ? (
                  ''
                ) : (
                  <span className="errorMsg">
                    {' '}
                    {this.props.errMsg[2]}
                    {' '}
                  </span>
                )}

                {iscriteriaStatusDropdownStatus ? (
                  <div className="initiativeheading-dropdownlist criteriastatus-list">
                    <ul>
                      <li
                        onClick={this.onClickFetchcriteriaStatusInitiative.bind(
                          this,
                          'Green',
                        )}
                      >
                        Green
                      </li>
                      <li
                        onClick={this.onClickFetchcriteriaStatusInitiative.bind(
                          this,
                          'Amber',
                        )}
                      >
                        Amber
                      </li>
                    </ul>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {iscriteriaStatusDropdownStatus ? (
                <div
                  className="initiative-dropdown-overlay"
                  onClick={this.iscriteriaStatusDropdownOverlay}
                />
              ) : (
                ''
              )}
              {addNewOptionStatus && (
                <div className="assessmentcriteria-dropdown newCustomCriteria">
                  <div className="form-group">
                    <label htmlFor="customcriteria">
                      {i18n.t('Custom_Assessment_Criteria')}
                      <sup>&#42;</sup>
                    </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder={i18n.t('Custom_Assessment_Criteria')}
                      id="customcriteria"
                      name="text"
                      maxLength="100"
                      value={this.state.selectedAssessmentCriteriaValue}
                      onChange={(e) => this.handleChangeCustomCriteria(e)}
                    />
                    {String(this.props.errMsg[1]) === '' ? (
                      ''
                    ) : (
                      <span className="errorMsg">
                        {' '}
                        {this.props.errMsg[1]}
                        {' '}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {AssessmentData.length > 0 && !addAssessmentFormStatus &&
          <>
            <div className="drag-drop-table">
              <div className="drag-drop-header">
                <div className="drag-drop-columns">
                  {i18n.t('Assessment_Criteria')}
                </div>
              </div>
              <SortableContainer onSortEnd={this.onSortEnd} helperClass="dragging-helper-class" useDragHandle>
                {currentPosts.map((value, index) => (
                  <SortableItem key={`item-${value}`} index={index} value={value} sortIndex={index} distance={1} lockAxis="y" />
                ))}
              </SortableContainer>
            </div>
          </>
        }
        {/* {AssessmentData.length && !addAssessmentFormStatus ? (
          <div className="listOfAddCriteria assessmentcriteria-listtable">
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th>{i18n.t("Assessment_Criteria")}</th>
                  {isAccess &&
                  history.location.pathname === "/admin/Configurator" ? (
                    <th colspan="3">{i18n.t("Actions")}</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((assessment) => {
                  return (
                    <tr>
                      <td
                        className={
                          assessment.RAGStatus !== null &&
                          assessment.RAGStatus.trim() === "Amber"
                            ? "criteriaName amberColor"
                            : "criteriaName greenColor"
                        }
                      >
                        {assessment.CriteriaName}
                      </td>
                      {isAccess &&
                      history.location.pathname === "/admin/Configurator" ? (
                        <>
                          <td
                            className="viewAccess"
                            key={assessment.IntelOpsAssessmentCriteriaID}
                          >
                            <span>
                              {!assessment.IsHidden ? (
                                <ReactTooltip
                                  id={`assessment${assessment.IntelOpsAssessmentCriteriaID}`}
                                  place="bottom"
                                  effect="solid"
                                >
                                  {" "}
                                  {i18n.t(
                                    "Click to hide Assessment Criteria on Roadmap screen"
                                  )}{" "}
                                </ReactTooltip>
                              ) : (
                                <ReactTooltip
                                  id={`assessment${assessment.IntelOpsAssessmentCriteriaID}`}
                                  place="bottom"
                                  effect="solid"
                                >
                                  {" "}
                                  {i18n.t(
                                    "Click to show Assessment Criteria on Roadmap screen"
                                  )}{" "}
                                </ReactTooltip>
                              )}

                              {assessment.IsCustom === false &&
                              currentFilter.isOMIDAvailable === true ? (
                                <i class="fal fa-eye disabled"></i>
                              ) : (
                                <i
                                  data-tip
                                  data-for={`assessment${assessment.IntelOpsAssessmentCriteriaID}`}
                                  class={
                                    !OMIDEnabled ? (
                                      <i class="fal fa-eye disabled"></i>
                                    ) : assessment.IsHidden ? (
                                      "far fa-eye-slash"
                                    ) : (
                                      "fal fa-eye"
                                    )
                                  }
                                  onClick={
                                    OMIDEnabled &&
                                    this.hideAssessment.bind(this, assessment)
                                  }
                                ></i>
                              )}
                            </span>
                          </td>
                          {assessment.IsCustom ? (
                            <td
                              className="editAccess"
                              onClick={() => {
                                !assessment.IsCustom &&
                                  this.handleEditAsessments(assessment);
                              }}
                            >
                              <span>
                                <i
                                  class={
                                    assessment.IsCustom === true
                                      ? "fal fa-edit disabled"
                                      : "fal fa-edit"
                                  }
                                ></i>
                              </span>
                            </td>
                          ) : (
                            <td
                              className="editAccess"
                              onClick={() => {
                                !assessment.IsCustom &&
                                  currentFilter.isOMIDAvailable === false &&
                                  OMIDEnabled &&
                                  this.handleEditAsessments(assessment);
                              }}
                            >
                              <span>
                                <i
                                  class={
                                    currentFilter.isOMIDAvailable === true
                                      ? "fal fa-edit disabled"
                                      : !OMIDEnabled
                                      ? "fal fa-edit disabled"
                                      : "fal fa-edit"
                                  }
                                ></i>
                              </span>
                            </td>
                          )}
                          <td
                            className="deleteAccess"
                            onClick={
                              (assessment.IsCustom ||
                                !currentFilter.isOMIDAvailable) &&
                              OMIDEnabled &&
                              this.deleteItemModalShow.bind(this, assessment)
                            }
                          >
                            <span>
                              <i
                               this.state.addAssessmentCriteriaStatus &&
                                  currentFilter.ProcessId != "All" &&
                                  currentFilter.OfferingId != "All" &&
                                  currentFilter.isOMIDAvailable == false
                                class={
                                  assessment.IsCustom === false &&
                                  currentFilter.isOMIDAvailable === true
                                    ? "fal fa-trash-alt disabled"
                                    : !OMIDEnabled
                                    ? "fal fa-trash-alt disabled"
                                    : "fal fa-trash-alt"
                                }
                              ></i>
                            </span>
                          </td>
                        </>
                      ) : null}
                    </tr>
                  );
                })}
                {currentPosts.length < 10 &&
                  [...new Array(10 - currentPosts.length)].map((each) => (
                    <tr className="emptyRowsData">
                      <td>
                        <span></span>
                      </td>
                      {isAccess &&
                      history.location.pathname === "/admin/Configurator" ? (
                        <>
                          <td>
                            <span></span>
                          </td>
                          <td>
                            <span></span>
                          </td>
                          <td>
                            <span></span>
                          </td>
                        </>
                      ) : null}
                    </tr>
                  ))}
              </tbody>
            </table>
            {AssessmentData.length > 10 && postsPerPage >= 10 && (
              <Pagination
                totalPosts={AssessmentData.length}
                postsPerPage={postsPerPage}
                currentPage={currentPage}
                changePostsPerPage={this.handlePostsPerPage}
                handleCurrentPage={this.handleCurrentPage}
              />
            )}
          </div>
        ) : null} */}

        {deleteAssessment ? (
          <DeleteModal
            title="Delete_Assessment"
            Message="Are_you_Sure_you_Want_to_Delete"
            deleteAssessment={deleteAssessment}
            deleteItemModalClose={this.deleteItemModalClose}
            deleteItem={this.successRemovalModalShow}
          />
        ) : (
          ''
        )}

        {/* Delete Notification Modal Popup Start */}
        <Modal
          show={this.state.showsuccessModal}
          onHide={this.successRemovalModalClose}
          className="successNotification-modal"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t('Notification')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="successnotification-body">
              <div className="successnotification-icon">
                <span className="mr-4">
                  <i className="fal fa-trash" />
                </span>
              </div>
              <div className="successnotification-data">
                <p>{i18n.t('Assessment_deleted_successfully')}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Delete Notification Modal Popup End */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getDataCounter: state.JourneyReducer.getDataCounter,
  languageData: state.CurrentFilter.languageData,
  currentFilter: state.CurrentFilter.SyncFilter,
  clientData: state.CurrentFilter.ClientData,
  Datas: state.JourneyReducer.Datas,
  Suboffering: state.CurrentFilter.Common,
  OMIDEnabled: state.JourneyReducer.OMIDEnabled,
});
export default connect(
  mapStateToProps,
  '',
)(AssessmentCriteria);
