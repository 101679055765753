import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { LocalApiBaseUrl, regexForToolURL, commonToolRegex } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import i18n from "i18next";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import { validateIsAllowedDomainFromURL, fetchAllowedDomains } from '../Shared/commonFunctions';

class LandingQuickLinkPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            ShowButton: false,
            ButtonErrors: {},
            MainData: [],
            ButtonDetails: {
                ID: 0,
                Name: "",
                Type: "",
                URL: "",
                Sequence: "",
                IsActive: false
            },
            isAPILoading: false,
            allowedDomainsArray: [],
        }
    }
    async componentDidMount() {
        this.setState({
            isAPILoading: true,
        });
        const allowedDomains = await fetchAllowedDomains();
        this.setState({
            allowedDomainsArray: allowedDomains,
            isAPILoading: false,
        });

        let ButtonDetails = this.state.ButtonDetails
        let MainData = this.props.rowData.filter(a => a.IsActive);

        if (this.props.data) {
            ButtonDetails = { ...this.props.data }
            MainData = MainData.filter(a => a.ID != this.props.data.ID)
        }
        this.setState({
            ButtonDetails: ButtonDetails,
            MainData: MainData
        })

    }
    onClose = () => {
        this.props.onClose();
    }
    bindButtonFieldChange = (e) => {
        const id = e.target.id;
        let data = this.state.ButtonDetails;
        switch (id) {
            case "ButtonName":
                data.Name = e.target.value;
                break;
            case "ButtonLink":
                data.URL = e.target.value;
                break;
            case "ButtonType":
                data.Type = e.target.value;
                break;
            case "ButtonSequence":
                data.Sequence = e.target.value;
                break;
            case "chkIsActive":
                data.IsActive =  !data.IsActive;
                break;
        }

        this.setState({
            ButtonDetails: data
        });
    }
    validateForm() {
        const { allowedDomainsArray } = this.state;

        let data = this.state.ButtonDetails
        const{rowData,TupleKeys,numberToWords}=this.props
        let MainData = this.state.MainData; 
        let ButtonErrors = this.state.ButtonErrors;
        let isValid = true;
        Object.keys(data).map((key) => {
            switch (key) {
                case "Name":
                    data[key].trim() ? (ButtonErrors[key] = "") : (ButtonErrors[key] = i18n.t("Link Name is Required"))
                    if (ButtonErrors[key])
                        isValid = false;
                    break;
             
                case "URL":
                    if (data[key]) {
                        if (!commonToolRegex.test(data[key].trim().toLowerCase())) {
                            if (regexForToolURL.test(data[key].trim().toLowerCase())) {
                                const isAllowed = validateIsAllowedDomainFromURL(data[key].trim(), allowedDomainsArray);
                                if (!isAllowed) {
                                    ButtonErrors[key] = i18n.t('subdomain_or_domain_not_whitelisted');
                                } else {
                                    ButtonErrors[key] = ""
                                }
                            } else {
                                ButtonErrors[key] = i18n.t("Invalid_URL")
                            }
                        } else {
                            ButtonErrors[key] = i18n.t("commonToolRegex_validatemessage")
                        }
                    } else {
                        ButtonErrors[key] = i18n.t("Url_Required")/* (ButtonErrors[key] = "VideoLink is Required") */
                    }
                    
                    if (ButtonErrors[key])
                        isValid = false;
                    break;
                case "Type":
                    data[key] ? (ButtonErrors[key] = "") : (ButtonErrors[key] = i18n.t("Link Type is Required"))
                    if (ButtonErrors[key])
                        isValid = false;
                    break;
                case "Sequence":
                    data[key] ?
                    data.IsActive&& MainData.some(a=>a.Sequence==data[key])?
                        (ButtonErrors[key] = i18n.t("Link Sequence Already Exist")): 
                        (ButtonErrors[key] = "") :
                        (ButtonErrors[key] = i18n.t("Link Sequence is Required"))
                    if(data[key] <= 0 || data[key] > 999999)
                        ButtonErrors[key] = i18n.t('Link')+' '+i18n.t('addWoErrorM6');
                    if (ButtonErrors[key])
                        isValid = false;
                    break;
            }
        });
        this.setState({
            ButtonErrors: ButtonErrors
        })

       if(isValid && data.IsActive  && rowData.filter(a=>a.IsActive).every(b=>b.ID!=data.ID) && TupleKeys.MaxQuickLinks==rowData.filter(a=>a.IsActive).length)
       {
        isValid=false;
        window.alert("At any point of time, only "+numberToWords(this.props.TupleKeys.MaxQuickLinks)+" links can be active.")
      
       }else if(isValid && data.IsActive && data.ID!==0 && rowData.filter(a=>a.IsActive).every(b=>b.ID!=data.ID) && TupleKeys.MaxQuickLinks==rowData.filter(a=>a.IsActive).length)
       {
        isValid=false;
        window.alert("At any point of time, only "+numberToWords(this.props.TupleKeys.MaxQuickLinks)+" links can be active.")
       
       }
       if(isValid && !data.IsActive && data.ID!==0 &&rowData.filter(a=>a.IsActive).some(b=>b.ID==data.ID) && TupleKeys.MinQuickLinks==rowData.filter(a=>a.IsActive).length)
       {
        isValid=false;
        window.alert(numberToWords(TupleKeys.MinQuickLinks)+" active is mandatory")
        
       }
     
        return isValid
    }
    handleSave() {
        const { MainData, ButtonDetails } = this.state;
        if (this.validateForm()) {
           
              this.setState({ ShowButton: true });
              if(this.props.data )
               {
                axiosInstance.post(`${LocalApiBaseUrl}SO/EditLandingPageQuickLinks`, ButtonDetails)
                .then((response) => {
                    window.alert(response.data.StatusMessage)
                    if(response.data.IsSuccess) {
                      this.props.onClose("add");
                  }
                  else{
                      this.setState({ ShowButton: false });
                  }
                 
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      


               } else{
                     axiosInstance.post(`${LocalApiBaseUrl}SO/AddLandingPageQuickLinks`, ButtonDetails)
                    .then((response) => {
                        window.alert(response.data.StatusMessage)
                        if(response.data.IsSuccess) {
                            this.props.onClose("add");
                        }
                        else{
                            this.setState({ ShowButton: false });
                        }
                    }).catch((error) => {
                        trycatchAlertPopup(error);
                        this.setState({ ShowButton: false });
                    });
                  }
            }
           
        /* } */
    }
    render() {
        const { isAPILoading } = this.state;
        const { Name,Type, Sequence, IsActive, URL } = this.state.ButtonDetails
        return (
            <>
                <>
                    <LoadingOverlay
                        className="custom-loader"
                        fadeSpeed={0}
                        spinner={<SynopsLoader />}
                        active={isAPILoading}
                    />
                    {/* <Header /> */}
                    <Modal show={this.state.show} className="modal fade" size="medium" id="dealpopup" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true" >

                        <Modal.Header> {/*closeButton*/}
                            <h4 className="modal-title">
                                {this.state.popupHeader}
                            </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </Modal.Header>

                        <Modal.Body>
                            <form>
                                {Name && Type && Sequence || IsActive && URL ? <h4>{i18n.t("Edit Landing Page Quick Link")}</h4>:<h4>{i18n.t("Add Landing Page Quick Link")}</h4>}
                                <div className="form-section">

                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label for="">
                                                {i18n.t("Link Name")} <span className="required">*</span>
                                            </label>
                                            <input id="ButtonName" type="text" maxlength="20" className="form-control" onChange={(e) => { this.bindButtonFieldChange(e) }} value={Name} />
                                            <span className="errorMsg">
                                                {this.state.ButtonErrors.Name}
                                            </span>
                                        </div>

                                        



                                        <div className="form-group col-lg-6">
                                            <label for="">
                                                {i18n.t("Link URL")} <span className="required">*</span>
                                            </label>
                                            <input id="ButtonLink" type="text" maxlength="5000" className="form-control" onChange={(e) => { this.bindButtonFieldChange(e) }} value={URL} />
                                            <span className="errorMsg">
                                                {this.state.ButtonErrors.URL}
                                            </span>
                                        </div>


                                        <div className="form-group col-lg-6">
                                            <label for="">
                                                {i18n.t("Link Type")} <span className="required">*</span>
                                            </label>
                                            <select
                                                id="ButtonType"
                                                name="toolType"
                                                className="form-control"
                                                onChange={(e) => { this.bindButtonFieldChange(e) }}
                                                value={Type}>
                                                 <option value="">Select</option>   
                                                <option value="Internal">Internal</option>
                                                <option value="External">External</option>
                                            </select>
                                         
                                            <span className="errorMsg">
                                                {this.state.ButtonErrors.Type}
                                            </span>
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <label for="">
                                                {i18n.t("Link Sequence")} <span className="required">*</span>
                                            </label>
                                            <input id="ButtonSequence" 
                                            min="1"
                                            type="number" maxlength="50" className="form-control" onChange={(e) => { this.bindButtonFieldChange(e) }} value={parseInt(Sequence)} />
                                            <span className="errorMsg">
                                                {this.state.ButtonErrors.Sequence}
                                            </span>
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <div className="toggle-group toggle-tick">
                                                <input
                                                    type="checkbox"
                                                    name="IsActive"
                                                   
                                                    checked={IsActive}
                                                   
                                                />
                                                
                                                <label id="chkIsActive" onClick={(e) =>
                                                        this.bindButtonFieldChange(e)
                                                    } htmlFor="isActive">Is Active</label>
                                                <div className="onoffswitch" aria-hidden="true">
                                                    <div className="onoffswitch-label">
                                                        <div className="onoffswitch-inner"></div>
                                                        <div className="onoffswitch-switch"></div>
                                                    </div>
                                                </div>
                                                <span className="switch-status">Active</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer className="modal-btn footer-btns">
                            <button type="button" className="btn btn-outline-secondary mr-2" onClick={this.onClose}>
                                {i18n.t("Cancel")}
                            </button>
                            <button type="button" className="btn btn-primary" disabled={this.state.ShowButton} onClick={() => this.handleSave()}>
                                {this.props.data ? i18n.t("Update") : i18n.t("Add")}
                            </button>
                        </Modal.Footer>
                    </Modal>
                </>
            </>
        )
    }
}

export default LandingQuickLinkPopup
