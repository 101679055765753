import React from 'react';
import PropTypes from 'prop-types';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { getMonthAndTime } from './utilities/utils';
import UserReplies from './UserReplies';

import TaggingUser from './TaggingUser';
import HighlightTag from './HighlightTag';

class OtherUserComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReplyCommentsCount: 2,
      showReplyForm: false,
      replyToUserName: '',
      editComments: '',
      editCommentData: {},
      taggedUsers: '' || [],
      showEditCommentForm: false,
      editCommentId: 0,
    };
  }

  componentDidUpdate(prevProps) {
    const { searchText, commentData } = this.props;
    const { showReplyCommentsCount } = this.state;
    if (prevProps.searchText !== searchText && showReplyCommentsCount <= 2 && commentData.Replies.length > 2) {
      if (commentData.Replies.filter((each, index) => {
        if (index > 1 && each.Comment.includes(searchText)) { return each; }
      }).length) {
        this.setState({ showReplyCommentsCount: commentData.Replies.length, showReplies: true });
      }
    }
  }

    closeReplyForm = () => {
      this.setState({
        showReplyForm: false,
        replyToUserName: '',
      });
    }

    showReplyForm = (commentData) => {
      // Track Event
      //  common.userTrackingModuleEvent(common.TRACKING_MODULES.BUSINESS_OUTCOME, common.TRACKING_MODULE_EVENTS.REPLY_BUTTON);
      this.setState({ replyToUserName: commentData.UserName, showReplyForm: true });
      // this.props.updateSelectedReply(commentID);
    }

    onEditCommentClick = (data) => {
      this.setState({ showEditCommentForm: true, editCommentId: data.CommentId });
    }

    handleReplies = (value) => {
      const { commentData } = this.props;
      this.setState({ showReplies: value });
      if (value === true) {
        this.setState({ showReplyCommentsCount: commentData.Replies.length });
      } else {
        this.setState({ showReplyCommentsCount: 2 });
      }
    }

    onCommentChange = (value) => {
      const { editComments } = this.state;
      if (editComments !== value && value.length <= 120) {
        this.setState({ editComments: value });
      }
    }

    onTaggedUserChange = (users) => {
      this.setState({ taggedUsers: users });
    }

    saveEditComment = () => {
      const { editCommentData, editComments, taggedUsers } = this.state;
      const { onAddOrUpdateComment } = this.props;
      editCommentData.Comment = editComments;
      editCommentData.TaggedUsers = taggedUsers;
      editCommentData.IsEdited = true;
      onAddOrUpdateComment(editCommentData);
      this.closeEditComment();
    }

    closeEditComment = () => {
      this.setState({
        showEditCommentForm: false,
        editCommentId: 0,
        editCommentData: {},
        editComments: '',
      });
    }

    showEditComment = (rep) => {
      // const { showEditCommentForm, editCommentId } = this.state;
      this.setState({
        showEditCommentForm: true,
        editCommentId: rep.CommentId,
        editComments: rep.comment,
        editCommentData: rep,
      });
    }

    render() {
      const {
        searchText, suggestions, commentData, userName, onAddOrUpdateComment, setScrollElementId, deleteComment, charLimit,
      } = this.props;
      const { showReplyForm, replyToUserName, showReplyCommentsCount } = this.state;
      const {
        showEditCommentForm, editCommentId, showReplies,
      } = this.state;
      return (
        <div className={`to-message ${commentData.IsNew ? 'new-message' : ''}`}>
          {commentData.Image
            ? <img src={commentData.Image} alt="profile" width="32" height="32" className="rounded-circle mr-1" />
            : <span className="default-profile rounded-circle mr-1">{commentData.ShortName}</span>}
          <div className="message-text">
            {' '}
            <p className="mb-0">
              <span className="name">{commentData.UserName}</span>
              <span>{getMonthAndTime(commentData.CreatedTS)}</span>
              {commentData.IsEdited && commentData.IsActive && <span className="edited-text pl-1">Edited</span>}
            </p>
            {commentData.IsActive ? (
              <>
                <HighlightTag
                  allreply=""
                  setScrollElementId={setScrollElementId}
                  comments={commentData}
                  searchText={searchText}
                  userName={userName}
                  handleReplies={this.handleReplies}
                />
                <p className="reply mb-0"><span role="presentation" onKeyDown={() => this.showReplyForm(commentData)} onClick={() => this.showReplyForm(commentData)}>Reply</span></p>

                {showReplyForm
                  ? (
                    <UserReplies
                      closeReplyForm={this.closeReplyForm}
                      charLimit={charLimit}
                      commentData={commentData}
                      suggestions={suggestions}
                      replyToUserName={replyToUserName}
                      userName={userName}
                      onAddOrUpdateComment={onAddOrUpdateComment}
                    />
                  )
                  : null}
              </>
            ) : <p>This message is deleted</p> }
            {
                        commentData.Replies
                          && (
                          <div className="reply-back">
                            {commentData.Replies.slice(0, showReplyCommentsCount).map((rep, i) => {
                              if (rep.UserName === userName) {
                                return (
                                  <>
                                    {' '}
                                    {showEditCommentForm && editCommentId === rep.CommentId
                                      ? (
                                        <TaggingUser
                                          placeholderText={`${'Replying to' + ' @'}${commentData.UserName}`}
                                          comment={rep.Comment}
                                          charLimit={charLimit}
                                          onCommentChange={this.onCommentChange}
                                          onTaggedUserChange={this.onTaggedUserChange}
                                          isEditMode
                                          saveEditComment={this.saveEditComment}
                                          closeEditComment={this.closeEditComment}
                                          taggedUserData={rep.TaggedUsers}
                                          suggestionsData={suggestions}
                                        />
                                      )
                                      : (
                                        <div className="message-text">
                                          {rep.IsActive
                                            ? (
                                              <div className="message-reply-content">
                                                <p className="mb-0">
                                                  <span>{getMonthAndTime(rep.CreatedTS)}</span>
                                                  {rep.IsEdited && rep.IsActive && <span className="edited-text pl-1">Edited</span>}
                                                  <DropdownButton title="">
                                                    <Dropdown.Item className="comments-edit" onClick={() => this.showEditComment(rep)}>Edit</Dropdown.Item>
                                                    <Dropdown.Item className="comments-delete" onClick={() => deleteComment(rep)}>Delete</Dropdown.Item>
                                                  </DropdownButton>
                                                </p>

                                                <HighlightTag
                                                  allreply=""
                                                  setScrollElementId={setScrollElementId}
                                                  comments={rep}
                                                  searchText={searchText}
                                                  userName={userName}
                                                  handleReplies={this.handleReplies}
                                                />

                                              </div>
                                            ) : <p>This message is deleted</p> }
                                        </div>
                                      )}
                                  </>
                                );
                              }

                              return (
                                <div className={`message-text to-reply-back ${rep.IsNew ? 'new-message' : ''}`}>
                                  {rep.Image
                                    ? <img src={rep.Image} alt="profile" width="32" height="32" className="rounded-circle mr-1" />
                                    : <span className="default-profile rounded-circle mr-1">{rep.ShortName}</span>}
                                  <div className="message-reply-content">
                                    <p className="mb-0">
                                      <span className="name">{rep.UserName}</span>
                                      <span>{getMonthAndTime(rep.CreatedTS)}</span>
                                      {rep.IsEdited && rep.IsActive && <span className="edited-text pl-1">Edited</span>}
                                    </p>
                                    { rep.IsActive ? (
                                      <HighlightTag
                                        allreply=""
                                        setScrollElementId={setScrollElementId}
                                        comments={rep}
                                        searchText={searchText}
                                        userName={userName}
                                        handleReplies={this.handleReplies}
                                      />
                                    ) : <p>This message is deleted</p> }
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          )
    }

            {commentData.Replies.length > 2
            && showReplies
              ? (
                <div>
                  <p className="view-hide-btn" onClick={() => this.handleReplies(false)} role="presentation" onKeyDown={() => this.handleReplies(false)}>Hide Replies</p>
                </div>
              ) : null }
            {commentData.Replies.length > 2
            && !showReplies
              ? (
                <div>
                  <p className="view-hide-btn" onClick={() => this.handleReplies(true)} role="presentation" onKeyDown={() => this.handleReplies(true)}>{`${commentData.Replies.length - 2 > 0 ? ' More Replies' : ' More Reply'}`}</p>
                </div>
              ) : null}
          </div>
        </div>
      );
    }
}
OtherUserComments.propTypes = {
  commentData: PropTypes.object.isRequired,
  suggestions: PropTypes.object.isRequired,
  searchText: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  setScrollElementId: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  onAddOrUpdateComment: PropTypes.func.isRequired,
  charLimit: PropTypes.number.isRequired,
};
export default OtherUserComments;
