//REACT IMPORTS
import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';

//CUSTOM IMPORTS
import {CommonToolTip} from "../../../Shared/Common";

export default class WidgetDisplay extends Component {
  constructor(props) {
    super(props);
  }
  //ON EDIT CLICK
  editHandler = (widgetData) => {
    this.props.editWidgetPopup(widgetData);
  };

  //ON DELETE CLICK
  deleteHandler = (offeringID) => {
    this.props.delete(offeringID);
  };

  //ON SLIDER CHANGE
  connectionChangeHandler = (offeringID) => {
    let isActive= this.props.widgetData.IsActive;
    if(isActive) {
      isActive = false;
      this.props.connectionChange(offeringID, isActive)
    } 
    else{
      isActive=true;
      this.props.connectionChange(offeringID, isActive)
    }
  };

  render() {
    const options = {
      loop: true,
      margin: 16,
      responsiveClass: "",
      dots: false,
      responsive: {
          0: {
              items: 1,
              nav: true
          },
          600: {
              items: 2,
              nav: false
          },
          1000: {
              items: 3,
              nav: true,
              loop: false
          },
          1200: {
              items: 4,
              nav: true,
              loop: false
          },
          1400: {
              items: 4,
              nav: true,
              loop: false
          },
          1600: {
              items: 5,
              nav: true,
              loop: false
          }
      }
  };
    let { widgetData } = this.props;
    return widgetData.IsDonutDisplay == 1 ? (
      <>
      <div className="doughnutwidget-layout">
      <div className="title-with-icon">
        <h2 className="ht-title"> {widgetData.TileHeader}</h2>
        <div className="list-icon mtclist-icons">
        
        {this.props.roledata.includes("Edit") &&  
            <div className="toggle-group toggle-tick">
              <input type="checkbox" name="datatypechange" id={`${this.props.index}`} 
                checked={this.props.widgetData.IsActive}
                onChange={() => this.connectionChangeHandler(widgetData.OfferingWidgetMappingTemplateID)}
              />
              <label htmlFor={`${this.props.index}`}>
                Connection Status
              </label>
              <div className="onoffswitch" aria-hidden="true">
                <div className="onoffswitch-label">
                  <div className="onoffswitch-inner"></div>
                  <div className="onoffswitch-switch"></div>
                </div>
              </div>
            </div>
          }
         {this.props.roledata.includes("Edit") &&         
          <span>
          <i className="far fa-edit" 
            onClick={()=>this.editHandler(this.props.widgetData)}/>
          </span>
         }
         {this.props.roledata.includes("Delete") &&
          <span>
            <i className="fal fa-trash-alt delete-icon-fix" 
               onClick={()=>this.deleteHandler(widgetData.OfferingWidgetMappingTemplateID)}
            />
          </span>        
          }
        </div>
      </div>
      <div className="rd-data">
            <div className="item doughnutwidget-insight">
              <div className="item-left">
              <div className="rd-progress p100">
                    <span>
                    {widgetData.Attributes[1].AttributeName.length > 3 ?
                   <CommonToolTip
                     id={'attribute_0'}
                     text={widgetData.Attributes[1].AttributeName}
                     length={3}
                     element={"b"}
                     place={"bottom"}                     
                   />: 
                      <b>  {widgetData.Attributes[1].AttributeName}</b>}

                  {widgetData.Attributes[2].AttributeName.length > 1 ?
                   <CommonToolTip
                     id={'attribute_0'}
                     text={widgetData.Attributes[2].AttributeName}
                     length={1}
                     element={"span"}
                     place={"bottom"}
                     class={"metric-digit-text"}
                   />: 
                        <span className="metric-digit-text">{widgetData.Attributes[2].AttributeName}</span>}
                    </span>
                    <div className="rd-slice"><div className="rd-bar"></div>
                        <div class="rd-fill"></div>
                    </div>
                </div>
              </div>
              <div className="item-right">
              {widgetData.Attributes[0].AttributeName.length > 46 ?
                   <CommonToolTip
                     id={'attribute_0'}
                     text={widgetData.Attributes[0].AttributeName}
                     length={46}
                     element={"span"}
                     place={"bottom"}
                     class={"doughnut-name"}
                   />: 
                <span className="doughnut-name">{widgetData.Attributes[0].AttributeName}</span>}
               </div>              
            </div>
      </div>
     </div>
      </>
    ) :widgetData.AttributeCount == 2 ? (      <>
        <div className="threewidget-layout">
          <div className="title-with-icon">
            <h2 className="ht-title"> {widgetData.TileHeader}</h2>
            <div className="list-icon mtclist-icons">
            {(this.props.roledata.includes("Edit") || this.props.roledata.includes("Delete")) &&
                <>
                
                {this.props.roledata.includes("Edit") &&  
                <div className="toggle-group toggle-tick">
                  <input type="checkbox" name="datatypechange" id={`${this.props.index}`} 
                    checked={this.props.widgetData.IsActive}
                    onChange={() => this.connectionChangeHandler(widgetData.OfferingWidgetMappingTemplateID)}
                  />
                  <label htmlFor={`${this.props.index}`}>
                    Connection Status
                  </label>
                  <div className="onoffswitch" aria-hidden="true">
                    <div className="onoffswitch-label">
                      <div className="onoffswitch-inner"></div>
                      <div className="onoffswitch-switch"></div>
                    </div>
                  </div>
                </div>
              }
              {this.props.roledata.includes("Edit") &&
              <span>
              <i className="far fa-edit" 
                onClick={()=>this.editHandler(this.props.widgetData)}/>
              </span>
              }
              {this.props.roledata.includes("Delete") &&
              <span>
                <i className="fal fa-trash-alt delete-icon-fix" 
                   onClick={()=>this.deleteHandler(widgetData.OfferingWidgetMappingTemplateID)}
                />
              </span>
              }
              </>
              }
            </div>
          </div>
          <OwlCarousel options={options} className={`owl-carousel`}>
          <div className="item gray-color" data-place="bottom" data-tip='Lower Threshold: NA, Upper Threshold: NA' >
             <div className="item-top">
             {widgetData.Attributes[0].AttributeName.length > 18 ?
                   <CommonToolTip
                     id={'attribute_1'}
                     text={widgetData.Attributes[0].AttributeName}
                     length={18}
                     element={"p"}
                     place={"bottom"}
                   /> :
                 <p>{widgetData.Attributes[0].AttributeName}</p>
               }
              </div>
              <div className="item-bottom">
                {/* <p>{widgetData.Attributes[1].AttributeName}</p> */}
                {widgetData.Attributes[1].AttributeName.length > 40 ?
                   <CommonToolTip
                     id={'attribute_1'}
                     text={widgetData.Attributes[1].AttributeName}
                     length={40}
                     element={"p"}
                     place={"top"}
                   /> : 
                   <p>{widgetData.Attributes[1].AttributeName}</p>
                }
             </div>
         </div> 
        </OwlCarousel>
          </div>
          </>
    ):widgetData.AttributeCount ==3?(
      <>
      <div className="twowidget-layout">
        <div className="title-with-icon">
            <h2 className="ht-title"> {widgetData.TileHeader}</h2>
            <div className="list-icon mtclist-icons">
            {(this.props.roledata.includes("Edit") || this.props.roledata.includes("Delete")) &&
                    <>
                    
                {this.props.roledata.includes("Edit") &&  
                <div className="toggle-group toggle-tick">
                  <input type="checkbox" name="datatypechange" id={`${this.props.index}`} 
                    checked={this.props.widgetData.IsActive}
                    onChange={() => this.connectionChangeHandler(widgetData.OfferingWidgetMappingTemplateID)}
                  />
                  <label htmlFor={`${this.props.index}`}>
                    Connection Status
                  </label>
                  <div className="onoffswitch" aria-hidden="true">
                    <div className="onoffswitch-label">
                      <div className="onoffswitch-inner"></div>
                      <div className="onoffswitch-switch"></div>
                    </div>
                  </div>
                </div>
                }
                {this.props.roledata.includes("Edit") &&
                      <span>
                        <i className="far fa-edit"
                          onClick={() => this.editHandler(this.props.widgetData)} />
                      </span>
  }
  {this.props.roledata.includes("Delete") &&
                      <span>
                        <i className="fal fa-trash-alt delete-icon-fix"
                          onClick={() => this.deleteHandler(widgetData.OfferingWidgetMappingTemplateID)}
                        />
                      </span>
  }
                    </>
                  }
            </div>
          </div>
       
        <OwlCarousel options={options} className={`owl-carousel`}>
          <div className="item gray-color" data-place="bottom" data-tip='Lower Threshold: NA, Upper Threshold: NA'>
            <div className="item-top">
                
                <p >                  
                  {widgetData.Attributes[1].AttributeName.length > 7 ?
                   <CommonToolTip
                     id={'attribute_2'}
                     text={widgetData.Attributes[1].AttributeName}
                     length={7}
                     element={"b"}
                     place={"bottom"}
                   />: 
                   <b>{widgetData.Attributes[1].AttributeName}</b>}
                  
                 {widgetData.Attributes[2].AttributeName.length > 6 ?
                   <CommonToolTip
                     id={'attribute_2'}
                     text={widgetData.Attributes[2].AttributeName}
                     length={6}
                     element={"span"}
                     place={"bottom"}
                   />: 
                  <span> 
                  {widgetData.Attributes[2].AttributeName}
                  </span>}

                </p>
              </div>
                <div className="item-bottom">
                {widgetData.Attributes[0].AttributeName.length > 40 ?
                   <CommonToolTip
                     id={'attribute_2'}
                     text={widgetData.Attributes[0].AttributeName}
                     length={40}
                     element={"p"}
                     place={"bottom"}
                   />:
                <p>{widgetData.Attributes[0].AttributeName}</p>
                }
            </div>
          </div> 
        </OwlCarousel>
        </div>
      
      </>
    
    ):widgetData.AttributeCount == 4?(
      <>
      <div className="fourwidget-layout">
      <div className="title-with-icon">
          <h2 className="ht-title"> {widgetData.TileHeader}</h2>
          <div className="list-icon mtclist-icons">
          {this.props.roledata.includes("Edit") &&
                      <>
                      
         {this.props.roledata.includes("Edit") &&  
              <div className="toggle-group toggle-tick">
                <input type="checkbox" name="datatypechange" id={`${this.props.index}`} 
                  checked={this.props.widgetData.IsActive}
                  onChange={() => this.connectionChangeHandler(widgetData.OfferingWidgetMappingTemplateID)}
                />
                <label htmlFor={`${this.props.index}`}>
                  Connection Status
                </label>
                <div className="onoffswitch" aria-hidden="true">
                  <div className="onoffswitch-label">
                    <div className="onoffswitch-inner"></div>
                    <div className="onoffswitch-switch"></div>
                  </div>
                </div>
                    </div>
  }
                    {this.props.roledata.includes("Edit") &&
                        <span>
                          <i className="far fa-edit"
                            onClick={() => this.editHandler(this.props.widgetData)} />
                        </span>
  }
                        {this.props.roledata.includes("Delete") &&
                        <span>
                          <i className="fal fa-trash-alt delete-icon-fix"
                            onClick={() => this.deleteHandler(widgetData.OfferingWidgetMappingTemplateID)}
                          />
                        </span>
  }
                      </>
                    }
                  </div>
                </div>
      <OwlCarousel options={options} className={`owl-carousel`}>
        <div className="item gray-color fourlayout-widget mtc-otherinsights" data-place="bottom" data-tip='Lower Threshold: NA, Upper Threshold: NA'>
          <div className="item-left">
          <p className="mtcinsight-value"> 
          {widgetData.Attributes[2].AttributeName.length > 4 ?
                   <CommonToolTip
                     id={'attribute_3'}
                     text={widgetData.Attributes[2].AttributeName}
                     length={4}
                     element={"b"}
                     place={"right"}
                   />:
              <b>{widgetData.Attributes[2].AttributeName}</b>}
              {widgetData.Attributes[3].AttributeName.length > 6 ?
                   <CommonToolTip
                     id={'attribute_3'}
                     text={widgetData.Attributes[3].AttributeName}
                     length={6}
                     element={"span"}
                     place={"right"}
                   />:
              <span> {widgetData.Attributes[3].AttributeName} </span>}
          </p>
              {widgetData.Attributes[0].AttributeName.length > 45 ?
                   <CommonToolTip
                     id={'attribute_3'}
                     text={widgetData.Attributes[0].AttributeName}
                     length={45}
                     element={"p"}
                     place={"right"}
                     class={"mtcinsights-desc"}
                   />:
              <p className="mtcinsights-desc" >{widgetData.Attributes[0].AttributeName}</p>}
              </div>
              <div className="item-right">
              {widgetData.Attributes[1].AttributeName.length > 40 ?
                   <CommonToolTip
                     id={'attribute_3'}
                     text={widgetData.Attributes[1].AttributeName}
                     length={40}
                     element={"p"}
                     place={"bottom"}
                     class={"mtcinsights-name"}
                   />:
              <p className="mtcinsights-name">{widgetData.Attributes[1].AttributeName}</p>}
          </div>
         
        </div> 
      </OwlCarousel>
      </div>
    </>
    ):<>
    <div className="title-with-icon">
        <h2 className="ht-title"> {widgetData.TileHeader}</h2>
        <div className="list-icon mtclist-icons">
        {(this.props.roledata.includes("Edit") || this.props.roledata.includes("Delete")) &&
                    <>
                    
         {this.props.roledata.includes("Edit") &&  
            <div className="toggle-group toggle-tick">
              <input type="checkbox" name="datatypechange" id={`${this.props.index}`} 
                checked={this.props.widgetData.IsActive}
                onChange={() => this.connectionChangeHandler(widgetData.OfferingWidgetMappingTemplateID)}
              />
              <label htmlFor={`${this.props.index}`}>
                Connection Status
              </label>
              <div className="onoffswitch" aria-hidden="true">
                <div className="onoffswitch-label">
                  <div className="onoffswitch-inner"></div>
                  <div className="onoffswitch-switch"></div>
                </div>
              </div>
                  </div>
                }
                  {this.props.roledata.includes("Edit") &&
                      <span>
                        <i className="far fa-edit"
                          onClick={() => this.editHandler(this.props.widgetData)} />
                      </span>
  }
                      {this.props.roledata.includes("Delete") &&
                      <span>
                        <i className="fal fa-trash-alt delete-icon-fix"
                          onClick={() => this.deleteHandler(widgetData.OfferingWidgetMappingTemplateID)}
                        />
                      </span>
  }
                    </>
                  }
        </div>
      </div>
    <OwlCarousel options={options} className={`owl-carousel`}>
      <div className="item gray-color" data-place="bottom" data-tip='Lower Threshold: NA, Upper Threshold: NA'>
        <div className="item-top">
            <p>{widgetData.Attributes[0].AttributeName}</p>
            </div>
            <div className="item-bottom">
            <p>{widgetData.Attributes[1].AttributeName}</p>
        </div>
      </div> 
    </OwlCarousel>
  </>;  

  }
}
