import React from "react";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import { connect } from "react-redux";
import _ from "lodash";

class DealPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: this.props.show, apivalue: "", selectValue: this.props.SelectValue, Display: false };

    this.handleHide = this.handleHide.bind(this);
    this.handleRejectHide = this.handleRejectHide.bind(this);
    this.handleApproveHide = this.handleApproveHide.bind(this);
  }
  componentWillMount() {
    if (!_.isNil(this.props.filterDetails))
      if (
        this.props.filterDetails.Client > 0 &&
        this.props.filterDetails.Locations > 0 &&
        parseInt(this.props.filterDetails.offering) > 0 &&
        this.props.filterDetails.process > 0
      ) {
        this.setState({ Display: true });
      }
    let apivalue = this.props.opsgrp;

    this.setState({ apivalue });
  }
  componentWillReceiveProps(nextProps) {
    if (!_.isNil(nextProps.filterDetails))
      if (
        nextProps.filterDetails.Client > 0 &&
        nextProps.filterDetails.Locations > 0 &&
        parseInt(nextProps.filterDetails.offering) > 0 &&
        nextProps.filterDetails.process > 0
      ) {
        this.setState({ Display: true });
      }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  render() {
    const { SelectedFilter } = this.props;
    const { show, Display, apivalue } = this.state;
    const showPopup =
      SelectedFilter &&
      SelectedFilter.LocationId !== "All" &&
      SelectedFilter.OfferingId !== "All" &&
      SelectedFilter.ProcessId !== "All" &&
      SelectedFilter.LocationId &&
      SelectedFilter.OfferingId &&
      SelectedFilter.ProcessId &&
      SelectedFilter.Month
        ? true
        : false;
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={show}
          onHide={this.handleHide}
          backdrop="static"
          className="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">
              {" "}
              <span>{i18n.t("Deal_Profile")}</span>{" "}
            </h4>
          </Modal.Header>
          <Modal.Body>
            {Display && showPopup ? (
              <>
                {apivalue != null ? (
                  <form>
                    <div className="form-section">
                      <h3>
                        {" "}
                        <span>{i18n.t("Org_Structure")}</span>{" "}
                      </h3>

                      <div className="row">
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Operating_Group")}</span>{" "}
                          </label>
                          {apivalue.length !== 0 ? <p>{apivalue[0].OperatingGroup || "-"}</p> : null}
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Industry")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].Industry || "-"}</p> : null}
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Client_Name")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].ClientName || "-"}</p> : null}
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Program")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].Program || "-"}</p> : null}
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("My_Deal_Profile")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].Project || "-"}</p> : null}
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Deal_Name")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].DealName || "-"}</p> : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-section">
                      <h3>
                        {" "}
                        <span>{i18n.t("Geo/Location")}</span>{" "}
                      </h3>

                      <div className="row">
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Client_Region")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].ClientRegion || "-"}</p> : null}
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Client_Country")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].ClientCountry || "-"}</p> : null}
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("City/Deliver Center")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].City || "-"}</p> : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-section">
                      <h3>
                        {" "}
                        <span>{i18n.t("Offering")}</span>{" "}
                      </h3>

                      <div className="row">
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Offering")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].Offering || "-"}</p> : null}
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Process")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].Process || "-"}</p> : null}
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Sub_Process")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].SubProcess || "-"}</p> : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-section">
                      <h3>
                        {" "}
                        <span>{i18n.t("Stakeholders")}</span>{" "}
                      </h3>

                      <div className="row">
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Accenture_Delivery_MD")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].AccentureMD || "-"}</p> : null}
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Delivery_SDL")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p>{apivalue[0].AccentureSDL || "-"}</p> : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("Client Experience Lead")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p className="overflow-scroll">{apivalue[0].Cxl || "-"}</p> : null}
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {" "}
                            <span>{i18n.t("OPS Account Lead")}</span>{" "}
                          </label>

                          {apivalue.length !== 0 ? <p className="overflow-scroll">{apivalue[0].Oal || "-"}</p> : null}
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  <p>{i18n.t("NO_DATA_/_Not_Selected_Client,Offering_and_Location")}</p>
                )}
              </>
            ) : (
              <p>{i18n.t("Please_Select_Client,Offering_and_Location")}</p>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    SelectedFilter: { ...state.CurrentFilter.SyncFilter },
  };
};
export default connect(mapStateToProps)(DealPopUp);
