import { setUserFeatures } from "../Shared/Common";

export let CurrentFilter = (
  state = {
    Filter: [],
    Common: [],
    Location: [],
    IsRelatorBlock: false,
    DealID: 0,
    ClientData: {},
    Tab: { title: "Business Outcomes", description: "", component: "", ioj: false },
    PulseData: { data: [] },
    pulsePageData: { SubOffering: "", Offering: "", offeringId: 0, pulseData: [] },
    languageData: { id: 1, langCode: "eng", messages: {}, languageDropDown: [] },
    SOCategories: [],
    iojData: [],
    sourceConnectionList: [],
    getRecordStatus: [],
    QcOverallScore: { QualityScoreDatas: { QualityScoreTabDatas: [] } },
    selectedDataType: "",

    QcIOJScore: {},
    FetchFromBAT: false,
    AllowBATDataFetchOption: false,
    IsToggleGrayedOut: false,
    apiLoadCompletedIndashboard:false,
    ConfiguratorID: 0,
    ID: 0,
    ShowBannerMessage:false,
    MonthToShowOnBannerMessage:false,    
    SyncFilter: {
      OfferingId: "",
      ProcessId: "",
      LocationId: "",
      Month: "",
      LocationOMID: [],
      IsDataPublishForLocation: false,
      isOMIDAvailable: false,
      OfferingName: "",
    },
    SelectedCommentMonth:"",
    CommentMonthChange:false,
    maxSequences: [], 
    adminFrom: "",
    snapshotProfile:[],
    multiLevelCategoriesLimit:30 // Default
  },
  action
) => {
  switch (action.type) {
    case "FILTER_OFFERING_CURRENTFILTER": {
      state = { ...state };
      state["Filter"] = action.payload;
      return state;
    }
    case "COMMON_SUBOFFERING_CURRENTFILTER": {
      state = { ...state };
      state["Common"] = action.payload;
      return state;
    }
    case "LOCATION_DETAILS_CURRENTFILTER": {
      state = { ...state };
      state["Location"] = action.payload;
      return state;
    }
    // This action is to maintain the SynOps Filter values (Offering, Sub-Offering, Location, Month)
    // Value for this update each time once user make any changes on these four fiters from Pulse, Configurator or Front-Door Screen
    case "SYNC_CURRENTFILTER": {
      state = { ...state };      

      const prevProcessID = state["SyncFilter"] ? (state["SyncFilter"].ProcessId ? state["SyncFilter"].ProcessId : 0) : 0;      
      const currentProcessID = action.payload ? (action.payload.ProcessId ? action.payload.ProcessId : 0) : 0;
      
      state["SyncFilter"] = action.payload;
      if(prevProcessID != currentProcessID) {
        setUserFeatures(state["ClientData"].id, action.payload.ProcessId,undefined,false);
      }
      
      return state;
    }
    case "SYNC_COMMENTFILTER":{
      state = { ...state };
      state["SelectedCommentMonth"] = action.payload;
      state["CommentMonthChange"]=true;
      return state;
    }
    case "LastPublishedDate": {
      state = { ...state };
      state["LastPublishedDate"] = action.payload;
      return state;
    }
    case "SAVE_DEALID":{
      state = {...state};
      state["DealID"] = action.payload;
      return state;
    }
    case "PULSE_DATA": {
      state = { ...state };
      state["PulseData"] = action.payload;
      state["pulsePageData"] = { SubOffering: "", Offering: "", offeringId: 0, pulseData: [] };
      return state;
    }
    case "SAVE_QUALITY_SCORE_AUTOMATION_SUMMARY_DATA": {
      state = { ...state };
      state["QcOverallScore"] = action.payload;
      return state;
    }
    case "SAVE_QUALITY_SCORE_IOJ_DATA": {
      state = { ...state };
      state["QcIOJScore"] = action.payload;
      return state;
    }
    case "FETCHFROMBAT": {
      state = { ...state };
      state["FetchFromBAT"] = action.payload;
      return state;
    }
    case "SHOWBANNERMESSAGE": {
      state = { ...state };
      state["ShowBannerMessage"] = action.payload;
      return state;
    }
    case "ALLOWBATDATAFETCHOPTION": {
      state = { ...state };
      state["AllowBATDataFetchOption"] = action.payload;
      return state;
    }
    case "ISTOGGLEGRAYEDOUT": {
      state = { ...state };
      state["IsToggleGrayedOut"] = action.payload;
      return state;
    }
 
    case "APILOADCOMPLETEDINDASHBOARDCONTAINER": {
      state = { ...state };
      state["apiLoadCompletedIndashboard"] = action.payload;
      return state;
    }
    case "CONFIGURATORID": {
      state = { ...state };
      state["ConfiguratorID"] = action.payload;
      return state;
    }
    case "ID": {
      state = { ...state };
      state["ID"] = action.payload;
      return state;
    }
    case "MONTHSHOWBANNERMESSAGE": {
      state = { ...state };
      state["MonthToShowOnBannerMessage"] = action.payload;
      return state;
    }    
    case "ADDNEW_ASSET": {
      state = { ...state };

      let updateTools = state.PulseData.data.map((each) => {
        if (each.OfferingId === action.payload.OfferingID) {
          each.tilemodel.map((eachAsset) => {
            if (eachAsset.TileID === action.payload.SOCategoryID) {
              eachAsset.pulseDashBoardApplicaionModels = eachAsset.pulseDashBoardApplicaionModels.filter(
                (tool) => tool.ApplicationMappingID !== action.payload.ApplicationMappingID
              );
              eachAsset.pulseDashBoardApplicaionModels.push(action.payload);
              //eachAsset.pulseDashBoardApplicaionModels.sort((a, b) => (a.ApplicationMappingID > b.ApplicationMappingID) ? 1 : -1)
              eachAsset.pulseDashBoardApplicaionModels.sort((a, b) =>
                a.ApplicationName.toLowerCase() > b.ApplicationName.toLowerCase() ? 1 : -1
              );
            }
          });
        }
        let updatedpagedata = [];
        state.PulseData.data.filter((each) => {
          if (each.OfferingId === action.payload.OfferingID) {
            updatedpagedata.push(each.tilemodel);
          }
        });
        state.pulsePageData.pulsePageData = updatedpagedata;
        return each;
      });

      state.PulseData.data = updateTools;

      return state;
    }
    case "DELETE_ASSET": {
      state = { ...state };
      let updateTools = state.PulseData.data.map((each) => {
        if (each.OfferingId === action.payload.OfferingID) {
          each.tilemodel.map((eachAsset) => {
            if (eachAsset.TileID === action.payload.SOCategoryID) {
              let newdata = [];
              eachAsset.pulseDashBoardApplicaionModels.filter((tool) => {
                if (tool.ApplicationMappingID !== action.payload.ApplicationMappingID) {
                  newdata.push(tool);
                }
              });
              eachAsset.pulseDashBoardApplicaionModels = newdata;
              eachAsset.SequenceNumber = eachAsset.pulseDashBoardApplicaionModels.map((a) => {
                return a.SequenceNumber;
              });
              eachAsset.SequenceNumber =
                eachAsset.SequenceNumber.length > 0 ? Math.max(...eachAsset.SequenceNumber) : 0;
              state.pulseMaxSequences.filter((f) => {
                return f.SOCategory == eachAsset.TileID;
              })[0].maxSequence = eachAsset.SequenceNumber;
            }
          });
        }
        return each;
      });

      state.PulseData.data = updateTools;
      let updatedpagedata = [];
      state.PulseData.data.filter((each) => {
        if (each.OfferingId === action.payload.OfferingID) {
          updatedpagedata.push(each.tilemodel);
        }
      });
      state.pulsePageData.pulsePageData = updatedpagedata;
      return state;
    }
    case "PULSE_PAGEDATA": {
      state = { ...state };

      state["pulsePageData"] = action.payload;
      return state;
    }
    case "LANGUAGE_DATA": {
      state = { ...state };
      state["languageData"] = action.payload;
      return state;
    }
    case "OFFERING_ISRELATORBLOCK": {
      state = { ...state };
      state["IsRelatorBlock"] = action.payload;
      return state;
    }
    case "OFFERING_ISBATDEALLEVEL": {
      state = { ...state };
      state["IsBatDealLevel"] = action.payload;
      return state;
    }

    case "ADD_SELECTED_TAB_DETAILS": {
      state = { ...state };
      state["Tab"] = action.payload;
      return state;
    }
    case "GET_SOCATEGORIES": {
      state = { ...state };
      state["SOCategories"] = action.payload;
      return state;
    }
    case "SAVE_IOJDATA": {
      state = { ...state };
      state["iojData"] = action.payload;
      return state;
    }
    case "SELECTED_CLIENT": {
      state = { ...state };
      state["ClientData"] = action.payload;
      return state;
    }

      case "selectedTabDiverseDta":{
      state = { ...state};
      state["selectedDataType"] = action.payload;
      return state;
    }
    
    case "SAVE_QC_SCORE_OF_EACH_TAB": {
      state = { ...state };
      if (state.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length) {
        state.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas = state.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.map(
          (each, i) => {
            if (each.LeverName_En === action.payload.LeverName_En) {
              each = action.payload;
            }
            return each;
          }
        );
        let score = 0;
        state.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.map((each) => {
          score = score + each.AchievedScore;
          return each;
        });
        score = score / state.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length;
        state.QcOverallScore.QualityScoreDatas.OverallScore = Math.round(score);
      } else {
        state.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.push(action.payload);
      }
      return state;
    }
    case "FETCH_SOURCE_CONNECTION_LIST": {
      state = { ...state };
      state["sourceConnectionList"] = action.payload;
      return state;
    }
    case "SAVE_GET_RECORD_STATUS": {
      state = { ...state };
      state["getRecordStatus"] = action.payload;
      return state;
    }
    case "PULSE_ADD_TOOL": {
      state = { ...state };
      action.payload.data.map((value, index) => {

        if (
          state["PulseData"].data
            .filter((x) => x.OfferingId == action.payload.offeringId)[0]
            .tilemodel.filter((f) => f.TileName_En == action.payload.lever)[0]
            .pulseDashBoardApplicaionModels.filter((p) => p.ApplicationMappingID == value.ApplicationMappingID)
            .length == 0
        ) {
          state["PulseData"].data
            .filter((x) => x.OfferingId == action.payload.offeringId)[0]
            .tilemodel.filter((f) => f.TileName_En == action.payload.lever)[0]
            .pulseDashBoardApplicaionModels.push(value);
        }
      });
      state["pulsePageData"].pulseData = state["PulseData"].data.filter(
        (x) => x.OfferingId == action.payload.offeringId
      )[0].tilemodel;
      state["PulseData"].data
        .filter((x) => x.OfferingId == action.payload.offeringId)[0]
        .tilemodel.filter((f) => f.TileName_En == action.payload.lever)[0]
        .pulseDashBoardApplicaionModels.sort((a, b) =>
          a.ApplicationName.toLowerCase() > b.ApplicationName.toLowerCase() ? 1 : -1
        );

      return state;
    }
    case "PULSE_MAX_SEQUENCE": {
      state = { ...state };
      state["pulseMaxSequences"] = action.payload;
      return state;
    }
    case "ADMIN_REDIRECT_FROM": {
      state = { ...state };
      state["adminFrom"] = action.payload;
      return state;
    }
    case "SNAPSHOT":{
      state = { ...state };
      state["snapshotProfile"] = action.payload;
      return state;
    }
    case "SET_MULTILEVEL_CATEGORIES_LIMIT":{
      state = {...state};
      state["multiLevelCategoriesLimit"] = action.payload;
      return state;
    }
  }
  return state;
};
