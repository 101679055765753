import React from "react";
import { dateFormatMethodTimeLineSeries } from "../Shared/commonFunctions";

const mapping = {
  Baseline: "BaselineValue",
  Current: "CurrentValue",
  Target: "TargetValue",
  Target2: "TargetValue2",
  Timeseries: "Value",
};
const mappingYear = {
  Baseline: "BaselineYear",
  Current: "CurrentYear",
  Target: "TargetYear",
  Target2: "TargetYear2",
  Timeseries: "Year",
};
const displayValuesTime = (section, val) => {
  const keyval = val.includes("Timeseries") ? "Timeseries" : val;
  const field = mappingYear[keyval];
  if (!val.includes("Timeseries")) {
    return dateFormatMethodTimeLineSeries(section[field]);
  } else {
    let index = parseInt(val.split("Timeseries")[1]);
    let timeseries = dateFormatMethodTimeLineSeries(section.TimeseriesData[index][field]);
    return timeseries;
  }
};

const fromToDisplay = (value) => {
  if (value.includes("Timeseries")) {
    let index = parseInt(value.split("Timeseries")[1]);
    return "Timeseries" + (index + 1);
  }
  return value;
};
const displayHumanMachineValues = (value, isHuman) => {
  const newData = value.split("-");
  return isHuman ? newData[0] : newData[1];
};

const displayValues = (section, val, isHuman) => {
  let mapped = val.includes("Timeseries") ? "Timeseries" : val;
  const field = mapping[mapped];
  if (!val.includes("Timeseries")) {
    return parseFloat(displayHumanMachineValues(section[field], isHuman));
  } else {
    let index = parseInt(val.split("Timeseries")[1]);
    let timeseries = section.TimeseriesData[index][field];
    return parseFloat(displayHumanMachineValues(timeseries, isHuman));
  }
};

export default function BeforeAfterHnMGraph({ from, to, sectionData }) {
  const fromHuman = parseFloat(displayValues(sectionData, from, true));
  const fromMachine = parseFloat(displayValues(sectionData, from, false));
  const fromNumerator = (parseFloat(fromHuman) * 100) / (fromHuman + fromMachine);
  const fromDenominator = (parseFloat(fromMachine) * 100) / (fromHuman + fromMachine);

  const toHuman = parseFloat(displayValues(sectionData, to, true));
  const toMachine = parseFloat(displayValues(sectionData, to, false));

  const toNumerator = (parseFloat(toHuman) * 100) / (toHuman + toMachine);
  const toDenominator = (parseFloat(toMachine) * 100) / (toHuman + toMachine);
  return (
    <div className="pyramids-block">
      <div className="pyramid-block1">
        <div class="human-machine-labels">
          <div class="human-label" style={{ height: `${fromNumerator}%` }}>
            <span>
              {fromHuman} <br />
              <span>Human</span>
            </span>
          </div>
          <div class="machine-label" style={{ height: `${fromDenominator}%` }}>
            <span>
              {fromMachine} <br />
              <span>Machine</span>
            </span>
          </div>
        </div>
        <div id="pyramid-hybrid" class="pyramid-chart">
          <div id="level1" style={{ height: `${fromNumerator}%` }}></div>
          <div id="level2" style={{ height: `${fromDenominator}%` }}></div>
        </div>
        <div className="pyramidblock-label">{fromToDisplay(from)}</div>
        <div className="pyramidblock-label">{from && displayValuesTime(sectionData, from)}</div>
      </div>
      <div className="pyramid-block2">
        <div class="human-machine-labels">
          <div class="human-label" style={{ height: `${toNumerator}%` }}>
            <span>
              {toHuman} <br />
              <span>Human</span>
            </span>
          </div>
          <div class="machine-label" style={{ height: `${toDenominator}%` }}>
            <span>
              {toMachine} <br />
              <span>Machine</span>
            </span>
          </div>
        </div>
        <div id="pyramid-hybrid" class="pyramid-chart">
          <div id="level1" style={{ height: `${toNumerator}%` }}></div>
          <div id="level2" style={{ height: `${toDenominator}%` }}></div>
        </div>
        <div className="pyramidblock-label">{fromToDisplay(to)}</div>
        <div className="pyramidblock-label">{to && displayValuesTime(sectionData, to)}</div>
      </div>
    </div>
  );
}
