import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

class HighlightTag extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { searchText, comments, setScrollElementId } = this.props;
    if (prevProps.searchText !== searchText) {
      if (searchText) {
        if ((comments.Comment).toLowerCase().includes(searchText.toLowerCase())) {
          setScrollElementId(comments.CommentId);
        }
      }
    }
  }

    highlight = ({ children }) => {
      const { userName, searchText } = this.props;
      if (children === `@${userName}`) {
        return <span className="logged-user-redtext">{children}</span>;
      }
      if (children.toLowerCase() === searchText.toLowerCase()) {
        return <span className="user-comments-text search-style">{children}</span>;
      }
      return <span className="other-user-bluetext">{children}</span>;
    }

    render() {
      const { comments, searchText } = this.props;
      let searchWordArray = [];
      if (comments.TaggedUsers && comments.TaggedUsers.length) {
        comments.TaggedUsers.forEach((each) => {
          if (each.Name) {
            searchWordArray.push(`@${each.Name}`);
          }
        });
        if (searchText) {
          searchWordArray.push(searchText);
        }
      } else if (searchText) {
        searchWordArray = [searchText];
      }
      return (

        <Highlighter
          id={`custom-comments-${comments.CommentId}`}
          ref={this.myRef}
          searchWords={searchWordArray}
          highlightTag={this.highlight}
          autoEscape
          textToHighlight={comments.Comment}
          unhighlightClassName="user-comments-text"
        />
      );
    }
}

HighlightTag.propTypes = {
  comments: PropTypes.object.isRequired,
  searchText: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  setScrollElementId: PropTypes.func.isRequired,
};
export default HighlightTag;
