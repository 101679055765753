import React, { Component } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { store } from "../reducers/configureStore";
import MasterContainer from "./MasterContainer";
import axiosInstance from "../Shared/interceptor";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class MasterList extends Component {
  constructor() {
    super();
    this.state = {
      userList: [],
      roleList: [],
      clientList: [],
      featureData: [],
      isDetailReportClicked: false,
      isFeatureReportClicked: false,
      isMonthlyViewClicked: false,
      isDealUserClicked: false,
      isUserLoginClicked: false,
      isDealSpecificReportClicked: false,
      isTopUsersClicked: false,
      isRegisteredUsersClicked: false,
      isTop10UserClicked: false,
      isExtractConfigurationClicked: false,
      isQualityScorecardClicked: false,
      isIntegrationStatusReportClicked: false,
      reportTabs: [],
      pageHeader: "",
    };

    this.onDealUserClick = this.onDealUserClick.bind(this);
    this.onUserLoginClick = this.onUserLoginClick.bind(this);
    this.onDealReportClick = this.onDealReportClick.bind(this);
    this.onMonthlyView = this.onMonthlyView.bind(this);
    this.onTopUserClick = this.onTopUserClick.bind(this);
    this.getRoleData = this.getRoleData.bind(this);
    this.onSpecificReportView = this.onSpecificReportView.bind(this); //// for the Specific report view
    this.onRegisteredUserClick = this.onRegisteredUserClick.bind(this);
    this.onQualityScorecardClick = this.onQualityScorecardClick.bind(this);
    this.onExtractConfiguratorClick = this.onExtractConfiguratorClick.bind(
      this
    );
    this.onFeatureReportClick = this.onFeatureReportClick.bind(this);
    this.onIntegrationStatusReportClick = this.onIntegrationStatusReportClick.bind(
      this
    );
    this.setDefaultTabOnLoad = this.setDefaultTabOnLoad.bind(this);
  }

  onDealReportClick(e) {
    this.setState({
      isDetailReportClicked: true,
      isMonthlyViewClicked: false,
      isDealUserClicked: false,
      isFeatureReportClicked: false,
      isUserLoginClicked: false,
      isDealSpecificReportClicked: false,
      isTopUsersClicked: false,
      isRegisteredUsersClicked: false,
      isExtractConfigurationClicked: false,
      isQualityScorecardClicked: false,
      isIntegrationStatusReportClicked: false,
    });
  }

  onMonthlyView(e) {
    this.setState({
      isDetailReportClicked: false,
      isMonthlyViewClicked: true,
      isDealUserClicked: false,
      isFeatureReportClicked: false,
      isUserLoginClicked: false,
      isDealSpecificReportClicked: false,
      isTopUsersClicked: false,
      isRegisteredUsersClicked: false,
      isExtractConfigurationClicked: false,
      isQualityScorecardClicked: false,
      isIntegrationStatusReportClicked: false,
    });
  }

  onSpecificReportView(e) {
    this.setState({
      isDetailReportClicked: false,
      isMonthlyViewClicked: false,
      isDealUserClicked: false,
      isFeatureReportClicked: false,
      isUserLoginClicked: false,
      isDealSpecificReportClicked: true,
      isTopUsersClicked: false,
      isRegisteredUsersClicked: false,
      isExtractConfigurationClicked: false,
      isIntegrationStatusReportClicked: false,
      isQualityScorecardClicked: false,
    });
  }

  onDealUserClick(e) {
    this.setState({
      isDetailReportClicked: false,
      isMonthlyViewClicked: false,
      isDealUserClicked: true,
      isFeatureReportClicked: false,
      isUserLoginClicked: false,
      isDealSpecificReportClicked: false,
      isTopUsersClicked: false,
      isRegisteredUsersClicked: false,
      isExtractConfigurationClicked: false,
      isQualityScorecardClicked: false,
      isIntegrationStatusReportClicked: false,
    });
  }

  onUserLoginClick(e) {
    this.setState({
      isDetailReportClicked: false,
      isMonthlyViewClicked: false,
      isDealUserClicked: false,
      isFeatureReportClicked: false,
      isUserLoginClicked: true,
      isTopUsersClicked: false,
      isDealSpecificReportClicked: false,
      isRegisteredUsersClicked: false,
      isExtractConfigurationClicked: false,
      isQualityScorecardClicked: false,
      isIntegrationStatusReportClicked: false,
    });
  }

  onTopUserClick(e) {
    this.setState({
      isDetailReportClicked: false,
      isMonthlyViewClicked: false,
      isDealUserClicked: false,
      isFeatureReportClicked: false,
      isUserLoginClicked: false,
      isTopUsersClicked: true,
      isDealSpecificReportClicked: false,
      isRegisteredUsersClicked: false,
      isExtractConfigurationClicked: false,
      isQualityScorecardClicked: false,
      isIntegrationStatusReportClicked: false,
    });
  }

  onRegisteredUserClick(e) {
    this.setState({
      isDetailReportClicked: false,
      isMonthlyViewClicked: false,
      isDealUserClicked: false,
      isUserLoginClicked: false,
      isFeatureReportClicked: false,
      isTopUsersClicked: false,
      isDealSpecificReportClicked: false,
      isRegisteredUsersClicked: true,
      isExtractConfigurationClicked: false,
      isQualityScorecardClicked: false,
      isIntegrationStatusReportClicked: false,
    });
  }

  onExtractConfiguratorClick(e) {
    this.setState({
      isDetailReportClicked: false,
      isMonthlyViewClicked: false,
      isDealUserClicked: false,
      isFeatureReportClicked: false,
      isUserLoginClicked: false,
      isTopUsersClicked: false,
      isDealSpecificReportClicked: false,
      isRegisteredUsersClicked: false,
      isExtractConfigurationClicked: true,
      isQualityScorecardClicked: false,
      isIntegrationStatusReportClicked: false,
    });
  }

  // onExtractConfiguratorClick(e) {
  //   this.setState({
  //     isDetailReportClicked: false,
  //     isMonthlyViewClicked: false,
  //     isDealUserClicked: false,
  //     isFeatureReportClicked: false,
  //     isUserLoginClicked: false,
  //     isTopUsersClicked: false,
  //     isDealSpecificReportClicked: false,
  //     isRegisteredUsersClicked: false,
  //     isExtractConfigurationClicked: true,
  //     isQualityScorecardClicked: false,
  //     isIntegrationStatusReportClicked: false,
  //   });
  // }

  onQualityScorecardClick(e) {
    this.setState({
      isDetailReportClicked: false,
      isMonthlyViewClicked: false,
      isDealUserClicked: false,
      isFeatureReportClicked: false,
      isUserLoginClicked: false,
      isTopUsersClicked: false,
      isDealSpecificReportClicked: false,
      isRegisteredUsersClicked: false,
      isExtractConfigurationClicked: false,
      isQualityScorecardClicked: true,
      isIntegrationStatusReportClicked: false,
    });
  }
  onFeatureReportClick(e) {
    this.setState({
      isDetailReportClicked: false,
      isMonthlyViewClicked: false,
      isDealUserClicked: false,
      isFeatureReportClicked: true,
      isUserLoginClicked: false,
      isTopUsersClicked: false,
      isDealSpecificReportClicked: false,
      isRegisteredUsersClicked: false,
      isExtractConfigurationClicked: false,
      isQualityScorecardClicked: false,
      isIntegrationStatusReportClicked: false,
    });
  }

  onIntegrationStatusReportClick(e) {
    this.setState({
      isDetailReportClicked: false,
      isMonthlyViewClicked: false,
      isDealUserClicked: false,
      isFeatureReportClicked: false,
      isUserLoginClicked: false,
      isTopUsersClicked: false,
      isDealSpecificReportClicked: false,
      isRegisteredUsersClicked: false,
      isExtractConfigurationClicked: false,
      isQualityScorecardClicked: false,
      isIntegrationStatusReportClicked: true,
    });
  }

  getRoleData() {
    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let featureDetails1 = data.roleReducers["roleReducers"];
      let featureData = featureDetails1.map(function (el) {
        return el.FeatureName;
      });
      this.setState({ featureData: featureData });
    });
  }

  setDefaultTabOnLoad() {
    let reportTabsArray = this.state.reportTabs;

    if (reportTabsArray.includes("Deal Report")) {
      this.setState({
        isDetailReportClicked: true,
      });
    } else if (reportTabsArray.includes("Deal Data Upload View")) {
      this.setState({
        isMonthlyViewClicked: true,
      });
    } else if (reportTabsArray.includes("Deal Summary")) {
      this.setState({
        isDealSpecificReportClicked: true,
      });
    } else if (reportTabsArray.includes("User Login Summary")) {
      this.setState({
        isDealUserClicked: true,
      });
    } else if (reportTabsArray.includes("Top Users")) {
      this.setState({
        isTopUsersClicked: true,
      });
    } else if (reportTabsArray.includes("Feature Report")) {
      this.setState({
        isFeatureReportClicked: true,
      });
    } else if (reportTabsArray.includes("User Report")) {
      this.setState({
        isRegisteredUsersClicked: true,
      });
    } else if (reportTabsArray.includes("Quality Scorecard")) {
      this.setState({
        isQualityScorecardClicked: true,
      });
    } else if (reportTabsArray.includes("Integration Status Report")) {
      this.setState({
        isIntegrationStatusReportClicked: true,
      });
    } else {
      // Deal Data View
      this.setState({
        isExtractConfigurationClicked: true,
      });
    }
  }

  setTabOnLoad(tabName) {
    let reportTabsArray = this.state.reportTabs;
    let tabData = [];

    switch (tabName) {
      case "Deal_Report":
        tabData = reportTabsArray.filter((f) => {
          return f === "Deal Report";
        });
        if (tabData.length > 0) {
          this.onDealReportClick();
        }
        break;
      case "Deal_Data_Upload_View":
        tabData = reportTabsArray.filter((f) => {
          return f === "Deal Data Upload View";
        });
        if (tabData.length > 0) {
          this.onMonthlyView();
        }
        break;
      case "Deal_Summary":
        tabData = reportTabsArray.filter((f) => {
          return f === "Deal Summary";
        });
        if (tabData.length > 0) {
          this.onSpecificReportView();
        }
        break;
      case "User_Login_Summary":
        tabData = reportTabsArray.filter((f) => {
          return f === "User Login Summary";
        });
        if (tabData.length > 0) {
          this.onDealUserClick();
        }
        break;
      case "Top_Users":
        tabData = reportTabsArray.filter((f) => {
          return f === "Top Users";
        });
        if (tabData.length > 0) {
          this.onTopUserClick();
        }
        break;
      case "Feature_Report":
        tabData = reportTabsArray.filter((f) => {
          return f === "Feature Report";
        });
        if (tabData.length > 0) {
          this.onFeatureReportClick();
        }
        break;
      case "User_Report":
        tabData = reportTabsArray.filter((f) => {
          return f === "User Report";
        });
        if (tabData.length > 0) {
          this.onRegisteredUserClick();
        }
        break;
      case "Metric_Data_View":
        tabData = reportTabsArray.filter((f) => {
          return f === "Metric Data View";
        });
        if (tabData.length > 0) {
          this.onExtractConfiguratorClick();
        }
        break;
      case "Quality_Scorecard":
        tabData = reportTabsArray.filter((f) => {
          return f === "Quality Scorecard";
        });
        if (tabData.length > 0) {
          this.onQualityScorecardClick();
        }
        break;
      case "Integration_Status_Report":
        tabData = reportTabsArray.filter((f) => {
          return f === "Integration Status Report";
        });
        if (tabData.length > 0) {
          this.onIntegrationStatusReportClick();
        }
        break;
      default:
        break;
    }

    this.setState({
      pageHeader: tabData.length > 0 ? tabData[0] : "",
    });
  }

  loadTabBasedOnURL = () => {
    const reqPath = this.props.location.pathname;
    const reqPathArr = reqPath.split("/");
    if (reqPath.startsWith("/admin/Report") && reqPathArr.length === 4) {
      this.setTabOnLoad(reqPathArr[3]);
    }
  };

  componentDidMount() {
    this.getRoleData();

    store.subscribe(() => {
      let data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] }, () => {
        let featureDetails1 = this.state.roleData;

        let masterMenu = [];

        var test = featureDetails1.forEach((ele) => {
          if (ele.Operations != null && ele.Operations.includes("View")) {
            masterMenu.push(ele.Submenu);
          }
        });

        let uniqueMenu = [];
        if (masterMenu != null) {
          uniqueMenu = masterMenu.filter(function (item, pos) {
            return masterMenu.indexOf(item) === pos;
          });
        }

        var filtered = uniqueMenu.filter(function (el) {
          return el != null;
        });

        let featureData = featureDetails1.map(function (el) {
          return el.Submenu;
        });
        this.setState({ reportTabs: filtered });
      });
      this.loadTabBasedOnURL();

      //Create State Varaibles and use to provide rights
    });
  }

  componentDidUpdate(nextProps) {
    if (
      String(this.props.location.pathname) !==
      String(nextProps.location.pathname)
    )
      this.loadTabBasedOnURL();
  }

  render() {
    return (
      <div className="main-component">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="grid-heading">
                {i18n.t("Reports") +
                  " > " +
                  (this.props.location.state
                    ? i18n.t(this.props.location.state.detail)
                    : i18n.t(this.state.pageHeader))}
              </h3>
              <div className="tab-list"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <MasterContainer
                filterDetails={this.props.filterDetails}
                dealReportClicked={this.state.isDetailReportClicked}
                monthlyViewClicked={this.state.isMonthlyViewClicked}
                DealUserClicked={this.state.isDealUserClicked}
                UserLoginClicked={this.state.isUserLoginClicked}
                ExtractConfiguratorClicked={
                  this.state.isExtractConfigurationClicked
                }
                dealSpecificReportClicked={
                  this.state.isDealSpecificReportClicked
                }
                TopUserClicked={this.state.isTopUsersClicked}
                FeatureReportClicked={this.state.isFeatureReportClicked}
                RegisteredUserClicked={this.state.isRegisteredUsersClicked}
                QualityScorecardClick={this.state.isQualityScorecardClicked}
                IntegrationStatusReportClicked={
                  this.state.isIntegrationStatusReportClicked
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
  };
};
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    ""
  )
)(MasterList);
