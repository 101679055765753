import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import ReactTooltip from 'react-tooltip';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import CommonRegionIcon from '../Images/common-region.svg';
import NorthAmericaIcon from '../Images/north-america.png';
import EuropeIcon from '../Images/europe.png';
import AAPACIcon from '../Images/aapac.png';
import LatinAmericaIcon from '../Images/latin-america.png';
import {  
  convertToRaw
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import parse from 'react-html-parser';


const OverviewAndKeyMileStones = ({ ...props }) => {
  const { mileStones, overView } = props;
  const options = {
    loop: true,
    margin: 16,
    responsiveClass: '',
    autoWidth: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 2,
        nav: true,
      },
      1000: {
        items: 2,
        nav: true,
        loop: false,
      },
      1440: {
        items: 3,
        nav: true,
        loop: false,
      },
      1600: {
        items: 4,
        nav: true,
        loop: false,
      },
      1800: {
        items: 5,
        nav: true,
        loop: false,
      },
    },
  };
  const mileStoneColors = [{ stepColors: ['step4-color'] },
    { stepColors: ['step3-color', 'step4-color'] },
    { stepColors: ['step3-color', 'step4-color', 'step5-color'] },
    { stepColors: ['step2-color', 'step3-color', 'step4-color', 'step5-color'] },
    { stepColors: ['step2-color', 'step3-color', 'step4-color', 'step5-color', 'step6-color'] },
    { stepColors: ['step1-color', 'step2-color', 'step3-color', 'step4-color', 'step5-color', 'step6-color'] },
    { stepColors: ['step1-color', 'step2-color', 'step3-color', 'step4-color', 'step5-color', 'step6-color', 'step7-color'] },
  ];
  const currentMileStoneColor = mileStones && mileStones.length ? mileStoneColors[mileStones.length - 1].stepColors : mileStoneColors[0].stepColors;

  return (
    <div className="story-carousel-wrapper">
      <div className="custom-tilecard">
        <div className="tilecard-wrapper">
          <div className="custom-carousel-padding">
            {overView.filter(((each) => each.StoryMapId !== 0)).length > 0 ? (
              <OwlCarousel options={options} className="owl-carousel">
                {overView.map((each, index) => {
                  let icon = '';
                  if (each.region === 'North America') {
                    icon = NorthAmericaIcon;
                  } else if (each.region === 'Europe') {
                    icon = EuropeIcon;
                  } else if (each.region === 'Latin America') {
                    icon = LatinAmericaIcon;
                  } else if (each.region === 'AAPAC') {
                    icon = AAPACIcon;
                  } else {
                    icon = CommonRegionIcon;
                  }
                  return (
                    <div className="item">
                      <img src={icon} className="float-left" alt="north america" width="40" height="40" />
                      <div className="regions-data-wrapper">
                        <div className="regions-data">
                          <span className="regions-label">Region Name: </span>
                          <span className="regions-value" data-tip data-for={`region-name-${index}`}>{each.region && each.region.length > 24 ? `${each.region.slice(0, 21)}...` : each.region }</span>

                        </div>
                        <div className="regions-data">
                          <span className="regions-label">Scope of Work: </span>
                          <span className="regions-value" data-tip data-for={`Scope-of-Work-${index}`}>{each.scope && each.scope.length > 24 ? `${each.scope.slice(0, 21)}...` : each.scope }</span>

                        </div>
                        <div className="regions-data">
                          <span className="regions-label">Processes: </span>
                          <span className="regions-value" data-tip data-for={`processes-${index}`}>{each.process && each.process.length > 24 ? `${each.process.slice(0, 21)}...` : each.process }</span>

                        </div>
                        <div className="regions-data">
                          <span className="regions-label">Other Comments: </span>
                          <span className="regions-value other-comments-value" data-tip data-for={`Other-Comments-${index}`}>{each.comments && each.comments.length > 48 ? `${each.comments.slice(0, 45)}...` : each.comments }</span>

                        </div>
                        <div className="regions-data">
                          <span className="regions-label">Head Count: </span>
                          <span className="regions-value" data-tip data-for={`Head-Count-${index}`}>{each.HeadCount && each.HeadCount.length > 24 ? `${each.HeadCount.slice(0, 21)}...` : each.HeadCount }</span>

                        </div>
                      </div>
                      {
                      (each.additionalInfoEditorState && each.additionalInfoEditorState.getCurrentContent().getPlainText() != "") &&
                      <span className="addition-info-tooltip">
                        <i className="fal fa-info-circle" data-tip data-for={`information-tooltip-${index}`} />                        
                      </span>
                      }
                    </div>
                  );
                })}
              </OwlCarousel>
            ) : null}
            {overView.filter(((each) => each.StoryMapId !== 0)).length > 0 ? (
              <>
                {overView.map((each, index) => (
                  <>
                    { each.region && each.region.length > 24 ? (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`region-name-${index}`} className="menulist-tooltips">
                        {each.region }
                      </ReactTooltip>
                    ) : null}
                    { each.scope && each.scope.length > 24 ? (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`Scope-of-Work-${index}`} className="menulist-tooltips">
                        {each.scope }
                      </ReactTooltip>
                    ) : null}
                    { each.process && each.process.length > 24 ? (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`processes-${index}`} className="menulist-tooltips">
                        {each.process}
                      </ReactTooltip>
                    ) : null}
                    { each.comments && each.comments.length > 48 ? (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`Other-Comments-${index}`} className="menulist-tooltips">
                        {each.comments }
                      </ReactTooltip>
                    ) : null}

                    { each.HeadCount && each.HeadCount.length > 24 ? (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`Head-Count-${index}`} className="menulist-tooltips">
                        {each.HeadCount }
                      </ReactTooltip>
                    ) : null}
                    { each.HeadCount && each.HeadCount.length > 24 ? (
                      <ReactTooltip place="bottom" type="dark" effect="solid" id={`Head-Count-${index}`} className="menulist-tooltips">
                        {each.HeadCount }
                      </ReactTooltip>
                    ) : null}
                    {each.additionalInfoEditorState.getCurrentContent() && 
                    draftToHtml(convertToRaw(each.additionalInfoEditorState.getCurrentContent())) ? <ReactTooltip
                      className="menulist-tooltips clientstory-card-tooltip"
                      id={`information-tooltip-${index}`}
                      effect="solid"
                      place="bottom"
                    >
                      <span>  
                        {parse(draftToHtml(convertToRaw(each.additionalInfoEditorState.getCurrentContent())))}                                              
                      </span>
                    </ReactTooltip> : null }
                  </>
                ))}
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className={`steps-wrapper  ${overView.filter(((each) => each.StoryMapId !== 0)).length === 0 ? 'nostory-data' : ''}`}>
        {mileStones && mileStones.length ? mileStones.map((each, index) => {
          if (each.active) {
            return (
              <div className={`step ${currentMileStoneColor[index]}`} key={each.year}>
                <div
                  // data-tip
                  // data-for={`menutooltip-${`${each.name.trim()}-${index}`}`}
                  className="step-year"
                >
                  {`${new Date(each.year).toLocaleString('en-US', { month: 'long' }).substring(0, 3)}'${new Date(each.year).getFullYear().toString().substring(2, 4)}`}
                </div>
                {/* <ReactTooltip
                  place={(index + 1) % 2 === 0 ? "bottom" : "top"}
                  type="dark"
                  effect="solid"
                  id={`menutooltip-${`${each.name.trim()}-${index}`}`}
                  className="menulist-tooltips"
                >
                  {new Date(each.year).toLocaleString("en-US", { month: "long" })}
                </ReactTooltip> */}
                <div className="step-details">
                  {/* <div className="step-name">{`${each.name}${index + 1}`}</div> */}
                  { each && each.desc && typeof each.desc === 'string' ? <div
                    className="step-description"
                  >{parse(each.desc)} </div> : null }
                </div>
              </div>
            );
          }
        }) : null}
      </div>
    </div>
  );
};
export default OverviewAndKeyMileStones;
