import React, { Component } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { IOJNavigation as getPrevMonth, LocalApiBaseUrl, trycatchAlertPopup,idTokenstring } from '../Shared/Constant';

import InitiativeDetails from '../JourneyInitiative/InitiativeDetails';
import { FormatDateNoTime, FormatDateWithMonthAndYear, createBreadCrumbs } from '../Shared/commonFunctions';

import CustomDatePicker from '../Configurator/CustomDatePicker';
import SynopsLoader from '../Shared/SynopsLoader';
import TabQCScore from '../QCScore/TabQCScore';
import IOJ_ValueRealization from './IOJ_ValueRealization';
import RejectPopUp from '../Configurator/RejectPopUp';
import RemarksPopUp from '../Configurator/RemarksPopUp';
import axiosInstance from '../Shared/interceptor';

import { store } from '../reducers/configureStore';
import { InitializeDatas, addAccess, ClearDatas_ViewDataStageData } from '../actions/JourneyActions';
import AddPMMService from './MaturityPlan/AddPMMService';
import JourneyInitiativeContainer from '../JourneyInitiative/JourneyInitiativeContainer';

import '../CSS/IojStyle.css';
import DealReport from '../IOJ/DealReport';
import References from '../Shared/References';
import ReactTooltip from 'react-tooltip';
import { NavLink } from "react-router-dom";
import IOJScoreCard from '../IOJ/IOJScoreCard';
axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;


class IOJConf extends Component {
  constructor() {
    super();
    this.state = {
      Tab: 'IOJMaturityRoadmap',
      isStage: null,
      isAccess: false,
      isShowInitiativeDeatils: false,
      EachInitiativeDetail: [],
      IOJStatus: true,
      showQcGraph: false,
      selectedTab: '',
      intelOpsDetails: [],
      filterDetails: [],
      isManintencneWindowOpen: false,
      params: {
        Status: null,
        MonthDt: null,
        DealID: null,
        activex: true,
      },
      active: false,
      dataStatus: '',
      showstage: true,
      PMMData: [],
      selectedYear: new Date().getFullYear(),
      isVisibleDatepickerDropdownPopover: false,
      IsAdmin: true,
      filtername: '',
      filter: {
        OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null,
      },
      postData: [],
      roleData: [],
      featureData: [],
      saveEnable: false,
      submitEnable: false,
      publishEnable: false,
      publishStatus: false,
      saveStatus: false,
      submitStatus: false,
      viewRemark: false,
      implementationTeamButtonsEnable: false,
      reviewImplementationStatus: false,
      rejectedByImplementationTeam: false,
      recordStatus: '',
      CountInsertData: [],
      CountData: [],
      // states for Metric Migration
      isPublishDisabled: false,
      newHelpFeaturesCount: 0,
      isHelpCountLoaded: false,
    };

    this.ShowInitativeDetails = this.ShowInitativeDetails.bind(this);
    this.HideInitativeDeatils = this.HideInitativeDeatils.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getRecordStatus = this.getRecordStatus.bind(this);
    this.handlePost = this.handlePost.bind(this);
    this.SaveClick = this.SaveClick.bind(this);
    this.SubmitClick = this.SubmitClick.bind(this);
    this.GetUserDetails = this.GetUserDetails.bind(this);
    this.Hideparent = this.Hideparent.bind(this);
    this.Showparent = this.Showparent.bind(this);
    this.datepickerDropdownPopoverTarget = React.createRef();
  }

  RejectClick(e) {
    e.preventDefault();

    this.setState({ viewmore: true });
  }

  viewMore(e) {
    e.preventDefault();

    this.setState({ viewRemark: true });
  }

  onClose() {
    this.setState({ viewmore: false, viewRemark: false });
    this.extradata = [];
  }

  SaveClick(e) {

    this.handlePost('Save');
  }

  Hideparent(e) {
    this.setState({ showstage: false });
  }

  Showparent(e) {
    this.setState({ showstage: true });
  }

  approveImplementationClick() {
    this.handlePost('Submit');
  }

  RejectImplementationClick() {
    this.setState({ rejectedByImplementationTeam: true });
    this.setState({ viewmore: true });
  }

  SubmitClick() {
    if (!this.props.isNewDeal || this.state.dataStatus === 'In Review') this.handlePost('Submit');
    else {
      this.handlePost('Review(Implementation)');
    }
  }

  componentWillMount() {
    const lastPublishedDate = store.getState().CurrentFilter.LastPublishedDate;
    this.setState({ LastPublishedDate: lastPublishedDate });
    // Metric Migration related code
  }

  PublishClick() {
    this.handlePost('Publish');
  }

  ResetClick() {
    this.handleReset();
  }

  LastMonthClick() {
    this.handleLastMonth();
  }

  handleLastMonth() {
    const { filter } = this.state;
    filter.LocationID = this.props.filterDetails.Locations;
    filter.OrgEntityID = this.props.filterDetails.Client;
    filter.OfferingID = this.props.filterDetails.process;
    filter.MonthDT = this.props.filterDetails.Month;
    this.setState({
      active: true,
    });

    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetLastMonthData`, {
        params: {
          OrgEntityID: filter.OrgEntityID,
          LocationID: filter.LocationID,
          MonthDT: filter.MonthDT,
          OfferingID: filter.OfferingID,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          store.dispatch({
            type: 'INITIALISE_BO',
            payload: response.data[0].TabData,
          });
          store.dispatch({
            type: 'INITIALISE',
            payload: response.data[1].TabData,
          });
          store.dispatch({
            type: 'INITIALISEINSIGHT',
            payload: response.data[2].TabData,
          });
          store.dispatch({
            type: 'INITIALISEDIVERSE',
            payload: response.data[3].TabData,
          });
          store.dispatch({
            type: 'INITIALISEIOJ',
            payload: response.data[5].IntelTabData == null ? [] : response.data[5].IntelTabData,
          });
          store.dispatch({
            type: 'INITIALISEWO',
            payload: response.data[4].TabData,
          });
        }
        if (response.status == '200') {
          this.setState({ active: false });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  handleReset = () => {
    const { filter } = this.state;
    filter.LocationID = this.props.filterDetails.Locations;
    filter.OrgEntityID = this.props.filterDetails.Client;
    filter.OfferingID = this.props.filterDetails.process;
    filter.MonthDT = this.props.filterDetails.Month;
    this.setState({
      active: true,
    });
    store.dispatch(InitializeDatas({ isGetData: false }));
    if (filter.OrgEntityID && filter.LocationID && filter.MonthDT && filter.OfferingID) {
      axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetConfiguratorData`, {
          params: {
            OrgEntityID: filter.OrgEntityID,
            LocationID: filter.LocationID,
            MonthDT: filter.MonthDT,
            OfferingID: filter.OfferingID,
          },
        })
        .then((response) => {
          store.dispatch({
            type: 'INITIALISE_BO',
            payload: response.data[0].TabData,
          });
          store.dispatch({
            type: 'INITIALISE',
            payload: response.data[1].TabData,
          });
          store.dispatch({
            type: 'INITIALISEINSIGHT',
            payload: response.data[2].TabData,
          });
          store.dispatch({
            type: 'INITIALISEDIVERSE',
            payload: response.data[3].TabData,
          });
          store.dispatch({
            type: 'INITIALISEIOJ',
            payload: response.data[5].IntelTabData == null ? [] : response.data[5].IntelTabData,
          });
          store.dispatch({
            type: 'INITIALISEWO',
            payload: response.data[4].TabData,
          });
          store.dispatch({
            type: 'LastPublishedDate',
            payload: response.data[0].LastPublishedDate,
          });
          store.dispatch({
            type: 'INITIALISEMaturity',
            payload: response.data[6].MaturityPlanTabData,
          });
          if (response.status == '200') {
            this.setState({ active: false });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  GetUserDetails() {
    const samp = {};
    const EnterpriseID = sessionStorage.getItem('EnterpriseID');
    if (EnterpriseID != undefined) {
      axiosInstance
        .get(`${LocalApiBaseUrl}User/GetUserDetails `, {
          params: {},
        })

        .then((response) => {
          if (response.data[0] === 'True') {
            this.setState({ IsAdmin: true });
          }
          if (response.data[0] === 'False') {
            this.setState({ IsAdmin: false });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  handlePost(status) {
    const { params } = this.state;
    params.Status = status;
    params.MonthDt = this.props.filterDetails.Month;
    params.DealID = this.props.filterDetails.Client;
    const { filter } = this.state;

    params.MonthDt = this.props.filterDetails.Month;
    filter.LocationID = this.props.filterDetails.Locations;
    filter.OrgEntityID = this.props.filterDetails.Client;
    filter.OfferingID = this.props.filterDetails.process;
    filter.MonthDT = this.props.filterDetails.Month;
    this.setState({
      params,
      active: true,
    });
    this.CreateSaveAndSubmitData();
    this.state.CountData = [];
    if (status != 'Save') {
      if (this.isBODataHasValue()) {
        if (this.state.postData[0].TabData.length <= 0) {
          this.handleAlertCategory('Business Outcome');
        } else {
          this.handleAlertData('Business Outcome');
        }
      } else if (this.isHMTHasValue()) {
        if (this.state.postData[1].TabData.length <= 0) {
          this.handleAlertCategory('Human & Machine Talent');
        } else {
          this.handleAlertData('Human & Machine Talent');
        }
      } else if (this.isWOHasData()) {
        if (this.state.postData[4].TabData.length <= 0) {
          this.handleAlertCategory('Work Orchestration');
        } else {
          this.handleAlertData('Work Orchestration');
        }
      } else if (this.state.params.activex == false) {

      } else if (this.isIIHasValue()) {
        if (this.state.postData[2].TabData.length <= 0) {
          this.handleAlertCategory('Insights & Intelligencen');
        } else {
          this.handleAlertData('Insights & Intelligence');
        }
      } else if (this.isDDHasData()) {
        if (this.state.postData[3].TabData.length <= 0) {
          this.handleAlertCategory('Diverse Data');
        } else {
          this.handleAlertData('Diverse Data');
        }
      } else if (this.validateDDConfWidgetData()) {
        if (this.state.postData[3].TabData.length <= 0) {
          this.handleAlertCategory('Diverse Data');
        } else {
          this.handleAlertData('Diverse Data');
        }
      } else if (this.isMaturityHasValue()) {
      } else {
        let tilename = '';
        if (this.state.postData.length == 7) {
          if (this.state.params.activex == true) {
            if (this.state.CountInsertData.length > 0) {
              for (let j = 0; j < this.state.CountInsertData.length; j++) {
                const tname = this.state.CountInsertData[j].name;
                const tcount = this.state.CountInsertData[j].count;
                tilename = `${tilename + tname}-${tcount}\n`;
              }
            }

            if (window.confirm(`Metrics Added:\n${tilename}\n Do you want to proceed?`)) {
              this.state.CountInsertData = [];
              this.handleData(filter, status);
            } else {
              this.setState({
                active: false,
              });
              this.state.CountInsertData = [];
            }
          } else {
            this.setState({
              active: false,
            });
          }
        }
      }
    } else {
      this.handleData(filter, status);
    }
  }

  /** Start- Validating if a widget has any record or not */
  validateDDConfWidgetData() {
    let count = 0;
    if (this.state.postData[3].TabData.length > 0) {
      if (this.state.postData[3].TabName === 'Diverse Data' && this.state.postData[3].TabData.length > 0) {
        if (this.state.postData[3].TabData[0].SOCategoryID === 5) {
          const wData = this.state.postData[3].TabData[0].Widget;
          if (wData && wData.length > 0) {
            for (let i = 0; i < wData.length; i++) {
              if (wData[i].WidgeFields.length > 0) {
                count = wData[i].WidgeFields.length + count;
              }
            }
            if (count > 0) {
              return false;
            }
          } else {
            return false;
          }
        }
      }
      return true;
    }
    return true;
  }

  /** End - Validating if a widget has any record or not */
  handleWOTitleAlertData(name) {
    this.setState({
      active: false,
    });
    this.state.params.activex = false;
    let tilename = '';
    for (let j = 0; j < name.length; j++) {
      const tname = name[j].tileName;
      tilename = `${tilename + tname}\n`;
      var fName = tilename; // tilename.replace(',','\n');
    }
    this.state.CountData = [];
    this.state.CountInsertData = [];
    return alert(i18n.t('dashboardCntContainerErrorM5') + fName);
  }

  handleData(filter, status) {
    const data = {
      ConfiguratorDataModels: this.state.postData,
      ConfiguratorRequestParamsModel: this.state.params,
      FiltersIDsVM: filter,
    };
    const { filterDetails } = this.props;

    axiosInstance
      .post(`${LocalApiBaseUrl}Configurator/PostConfiguratorData`, data)
      .then((response) => {
        // alert(response.data.StatusMessage)

        if (response.data.IsSuccess) {
          switch (status) {
            case 'Save':
              alert(i18n.t('dashboardCntContainerSucM1'));
              this.setState({
                saveStatus: true,
                submitStatus: false,
                publishStatus: false,
                reviewImplementationStatus: false,
              });
              this.handleReset();
              this.getRecordStatus(filterDetails);
              break;

            case 'Review(Implementation)':
              alert(i18n.t('dashboardCntContainerSucM2'));
              this.setState({
                saveStatus: false,
                publishStatus: false,
                submitStatus: false,
                reviewImplementationStatus: true,
              });
              this.state.CountInsertData = [];
              this.handleReset();
              this.getRecordStatus(filterDetails);
              break;

            case 'Submit':

              this.state.reviewImplementationStatus
                ? alert(i18n.t('dashboardCntContainerSucM3'))
                : alert(i18n.t('dashboardCntContainerSucM4'));

              this.setState({
                saveStatus: false,
                publishStatus: false,
                submitStatus: true,
                reviewImplementationStatus: false,
                CountInsertData: [],
              });
              this.handleReset();
              this.getRecordStatus(filterDetails);
              break;
            case 'Publish':
              alert(i18n.t('dashboardCntContainerSucM5'));
              this.setState({
                publishStatus: true,
                saveStatus: false,
                submitStatus: false,
                reviewImplementationStatus: false,
              });
              this.handleReset();
              this.getRecordStatus(filterDetails);
              break;
            default:
              this.handleReset();
              break;
          }
        } else {
          alert(i18n.t('dashboardCntContainerSucM4'));
        }
        this.setState({ active: false });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ active: false });
        alert(i18n.t('dashboardCntContainerSucM6'));
      });
  }

  handleAlertData(name) {
    this.setState({
      active: false,
    });
    this.state.CountData = [];
    this.state.CountInsertData = [];
    if (String(name) === 'Diverse Data') {
      return alert(i18n.t('dashboardCntContainerErrorM7'));
    }
    return alert(i18n.t('dashboardCntContainerErrorM2') + name);

    return alert(i18n.t('dashboardCntContainerErrorM2') + name);
  }

  handleAlertCategory(name) {
    this.setState({
      active: false,
    });
    // this.state.params.activex=false
    return alert(i18n.t('dashboardCntContainerErrorM3') + name);
  }

  handleIOJAlertData(name) {
    this.setState({
      active: false,
    });

    this.state.CountData = [];
    this.state.CountInsertData = [];
    return alert(i18n.t('dashboardCntContainerErrorM4'));
  }

  CreateSaveAndSubmitData() {
    this.state.CountData = [];
    this.state.CountInsertData = [];
    this.state.postData = [];
    const data = store.getState();
    var dataParam = {
      TabName: null,
      TabData: [],
    };

    const BOData = data.BOReducers.BO;
    dataParam.TabName = 'Business Outcome';
    dataParam.TabData = [...BOData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    const HMData = data.humanReducers.humanandmachine;
    dataParam.TabName = 'Human & Machine Talent';
    dataParam.TabData = [...HMData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    const IIData = data.intelligentInsightReducers.insightandintelligence;
    dataParam.TabName = 'Insights & Intelligence';
    dataParam.TabData = [...IIData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    const DiverseData = data.diverseReducers.diverse;
    dataParam.TabName = 'Diverse Data';
    dataParam.TabData = [...DiverseData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    const WOData = data.WOtileReducers.tile;
    dataParam.TabName = 'Work Orchestration';
    dataParam.TabData = [...WOData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    const IntelOpsData = data.intelligentReducers.IOJ;
    dataParam.TabName = 'IntelOps';
    dataParam.IntelTabData = [...IntelOpsData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    const MaturityData = data.MaturityReducers.Maturity;
    dataParam.TabName = 'MaturityPlanData';
    dataParam.MaturityPlanTabData = [...MaturityData];
    this.state.postData.push(dataParam);
  }

  // Function for WO Data
  isWOHasData() {
    this.state.params.activex = true;
    let bocount1 = 0;
    const bname = '';
    const bname1 = '';
    const insertParamCount = {
      name: '3. Work Orchestration',
      count: 0,
    };
    var dataParamCount = {
      tileName: null,
    };

    if (this.state.postData[4].TabData.length > 0) {
      if (this.state.postData[4].TabName === 'Work Orchestration' && this.state.postData[4].TabData.length > 0) {
        for (let i = 0; i < this.state.postData[4].TabData.length; i++) {
          if (
            this.state.postData[4].TabData[i].ParentSOCategoryID != 0
            && this.state.postData[4].TabData[i].ParentSOCategoryID != null
            && this.state.postData[4].TabData[i].ParentSOCategoryID != undefined
          ) {
            if (this.state.postData[4].TabData[i].IsActive && this.state.postData[4].TabData[i].kpiData.length == 0) {
              var dataParamCount = {
                tileName: null,
              };

              dataParamCount.tileName = this.state.postData[4].TabData[i].SOCategoryName;
              this.state.CountData.push(dataParamCount);
            } else if (
              this.state.postData[4].TabData[i].IsActive
              && this.state.postData[4].TabData[i].kpiData.length > 0
            ) {
              const bocount = this.state.postData[4].TabData[i].kpiData.length;
              bocount1 += bocount;
            }
          }
        }
        if (bocount1 > 0) {
          insertParamCount.count = bocount1;
          this.state.CountInsertData.push(insertParamCount);
          if (this.state.filtername != 'Finance & Accounting') {
            return false;
          }
        } else if (bocount1 == 0) {
          insertParamCount.count = bocount1;
          this.state.CountInsertData.push(insertParamCount);
        }
        if (this.state.CountData.length > 0) {
          if (this.state.filtername == 'Finance & Accounting') {
            this.handleWOTitleAlertData(this.state.CountData);
            this.state.CountData = [];
          }
          if (this.state.CountData.length > 0 && bocount1 <= 0) {
            return true;
          }
        }
        return false;
      }
      return true;
    }
    return true;
  }

  isMaturityHasValue() {
    let Macount = 0;
    const insertParamCount = {
      name: '6. Maturity Plan ',
      count: 0,
    };
    if (
      this.state.postData[6].TabName === 'MaturityPlanData'
      && this.state.postData[6].MaturityPlanTabData.length >= 0
    ) {
      Macount = this.state.postData[6].MaturityPlanTabData
        ? this.state.postData[6].MaturityPlanTabData.filter((a) => a.IsChecked === true).length
        : 0;

      insertParamCount.count = Macount;
      this.state.CountInsertData.push(insertParamCount);
      return false;
    }
    return true;
  }

  // Function for IOJ Data
  isIOJHasValue() {
    const insertParamCount = {
      name: '6. Intelligent Operation Journey ',
      count: 0,
    };

    const { IOJData } = this.props;
    if (IOJData.length > 0) {
      let hasInitiativesData = false;
      let InitiativesCount = 0;
      IOJData.forEach((each) => {
        if (each.IOJInitiativeModels.length) {
          hasInitiativesData = true;
          InitiativesCount += each.IOJInitiativeModels.length;
        }
      });

      if (hasInitiativesData) {
        insertParamCount.count = InitiativesCount;
        this.state.CountInsertData.push(insertParamCount);
        return false;
      }
    }
    return true;
  }

  // Function for DD Data
  isDDHasData() {
    let bocount1 = 0;
    const insertParamCount = {
      name: '5. Diverse Data ',
      count: 0,
    };

    if (this.state.postData[3].TabData.length > 0) {
      if (this.state.postData[3].TabName === 'Diverse Data' && this.state.postData[3].TabData.length > 0) {
        for (let i = 0; i < this.state.postData[3].TabData.length; i++) {
          if (this.state.postData[3].TabData[i].kpiData.length > 0) {
            const bocount = this.state.postData[3].TabData[i].kpiData.length;
            bocount1 += bocount;
          }
        }
        if (bocount1 > 0) {
          insertParamCount.count = bocount1;
          this.state.CountInsertData.push(insertParamCount);
          return false;
        }
      }
      return true;
    }
    return true;
  }

  isDDHasDataProcessed() {
    const soCatAtt = this.state.postData[3].TabData[0].SoCatDataAttr.filter((obj) => obj.AttributeValue == 'Yes');
    if (soCatAtt.length > 0) {
      return false;
    }
    this.setState({
      active: false,
    });
    alert(i18n.t('dashboardCntContainerErrorM1'));
    return true;
  }

  // Function for II Data
  isIIHasValue() {
    let bocount1 = 0;
    const insertParamCount = {
      name: '4. Insight and Intelligence ',
      count: 0,
    };

    if (this.state.postData[2].TabData.length > 0) {
      if (this.state.postData[2].TabName === 'Insights & Intelligence' && this.state.postData[2].TabData.length > 0) {
        for (let i = 0; i < this.state.postData[2].TabData.length; i++) {
          if (this.state.postData[2].TabData[i].kpiData.length > 0) {
            const bocount = this.state.postData[2].TabData[i].kpiData.length;
            bocount1 += bocount;
          }
        }
        if (bocount1 > 0) {
          insertParamCount.count = bocount1;
          this.state.CountInsertData.push(insertParamCount);
          return false;
        }
      }
      return true;
    }
    return true;
  }

  // Function for HMT Data

  isHMTHasValue() {
    const humanWorforceKpiData = [];
    const machineWorkforceKpiData = [];
    const keyInsightsKpiData = [];
    this.state.postData[1].TabData.map((Catagory) => {
      if (String(Catagory.SOCategoryName) === 'Human Workforce') {
        humanWorforceKpiData.push(Catagory.kpiData);
      } else if (String(Catagory.SOCategoryName) === 'Key Insights') {
        keyInsightsKpiData.push(Catagory.kpiData);
      }
      if (String(Catagory.SOCategoryName) === 'Machine Workforce') {
        machineWorkforceKpiData.push(Catagory.kpiData);
      }
    });

    if (this.state.postData[1].TabData.length > 0) {
      if (this.state.postData[1].TabName === 'Human & Machine Talent' && this.state.postData[1].TabData.length > 0) {
        if (
          humanWorforceKpiData[0].length == 0
          && keyInsightsKpiData[0].length == 0
          && machineWorkforceKpiData[0].length == 0
        ) {
          return true;
        }
        this.setState({
          humanWorkforceData: humanWorforceKpiData[0].length,
          keyInsightsData: keyInsightsKpiData[0].length,
          machineWorkforceData: machineWorkforceKpiData[0].length,
        });
        const insertParamCount = {
          name: '2. Human and Machine ',
          count: 0,
        };

        insertParamCount.count = (this.state.postData[1].TabData.length > 0 ? humanWorforceKpiData[0].length : 0)
            + (this.state.postData[1].TabData.length > 1 ? keyInsightsKpiData[0].length : 0)
            + (this.state.postData[1].TabData.length > 2 ? machineWorkforceKpiData[0].length : 0);
        this.state.CountInsertData.push(insertParamCount);

        return false;
      }
    } else {
      return true;
    }
  }

  GetHelpFeatureNotifications = () => {
    var data = {
      LeverName: this.props.compDetails.title
    }
    axiosInstance
      .post(`${LocalApiBaseUrl}UserGuide/GetUserHelpTopicCount`, data)
      .then((response) => {
          this.setState({
            newHelpFeaturesCount: response.data.NewHelpTopicCount,
            isHelpCountLoaded: true,
          })
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  // Function for BO Data

  isBODataHasValue() {
    this.state.CountData = [];
    this.state.CountInsertData = [];
    let bocount1 = 0;
    if (this.state.postData[0].TabData.length > 0) {
      if (this.state.postData[0].TabName === 'Business Outcome' && this.state.postData[0].TabData.length > 0) {
        for (let i = 0; i < this.state.postData[0].TabData.length; i++) {
          if (this.state.postData[0].TabData[i].kpiData.length > 0) {
            var insertParamCount = {
              name: '1. Business Outcome ',
              count: 0,
            };

            const bocount = this.state.postData[0].TabData[i].kpiData.length;

            bocount1 += bocount;
          }
        }
        if (bocount1 > 0) {
          insertParamCount.count = bocount1;
          this.state.CountInsertData.push(insertParamCount);
          return false;
        }
      }
      return true;
    }
    return true;
    // this.handleAlertCategory("Business Outcome");
  }

  componentDidUpdate(prevProps) {
    const { currentFilter } = this.props;

    if (currentFilter && prevProps.currentFilter !== currentFilter) {
      this.Accesscontrol();
    }
    const { lastPublishedDate } = this.props;
    if (prevProps.lastPublishedDate !== lastPublishedDate) {
      this.setState({ LastPublishedDate: lastPublishedDate });
    }
    if (this.props.boData !== prevProps.boData) {
      this.setState({ boData: this.props.boData });
    }
    let saveEnable = false;
    let submitEnable = false;
    let publishEnable = false;
    let implementationTeamButtonsEnable = false;

    let publishStatus = false;
    let saveStatus = false;
    let submitStatus = false;
    let reviewImplementationStatus = false;
    let remarkStatus = false;
    let dataStatus = '';
    // this.getRecordStatus();

    if (prevProps.isNewDeal !== this.props.isNewDeal) {
      const featureDataForConf = store.getState().roleReducers.roleReducers.map((el) => el);
      const confOperations = [];
      const confData = featureDataForConf.filter((el) => el.FeatureName === 'Configurator');
      const configuratorOperations = confData.map((ele) => {
        if (ele.Operations != null && ele.Operations.count != 0) {
          confOperations.push(ele.Operations);
        }
      });

      const operation = [];
      const test = confOperations.map((ele) => {
        saveEnable = ele.includes('Save');
        submitEnable = ele.includes('Submit');
        publishEnable = ele.includes('Publish');

        implementationTeamButtonsEnable = ele.includes('Approve - Review Stage 1') && this.props.isNewDeal;
      
      });
      this.setState({
        implementationTeamButtonsEnable,
      });
    }
    if (this.state.recordStatus !== this.props.getRecordStatus) {
      this.Accesscontrol();
      this.setState({ recordStatus: this.props.getRecordStatus });

      if (this.props.getRecordStatus[0] == 'Publish') {
        publishStatus = true;
        dataStatus = 'Published';
      }

      if (this.props.getRecordStatus[0] == 'Save') {
        saveStatus = true;
        dataStatus = 'Draft';
      }

      if (this.props.getRecordStatus[0] == 'Submit') {
        submitStatus = true;
        dataStatus = 'In Review';
      }

      if (this.props.getRecordStatus[0] == 'Review(Implementation)') {
        submitStatus = true;
        reviewImplementationStatus = true;
        dataStatus = 'In Review(Implementation)';
      }
      if (this.props.getRecordStatus[0] == 'Rejected') {
        submitStatus = true;
        dataStatus = 'Rejected';
      }
      if (this.props.getRecordStatus[3] != undefined) {
        remarkStatus = true;
      }

      if (this.props.getRecordStatus[2] == 'False') {
        if (this.props.getRecordStatus[1] == 'True') {
          publishStatus = true;
          saveStatus = false;
          submitStatus = false;
        }
      }
      this.setState({
        publishStatus,
        saveStatus,
        submitStatus,
        remarkStatus,
        dataStatus,
        reviewImplementationStatus,
      });
    }
  }

  componentDidMount() {
    let saveEnable = false;
    let submitEnable = false;
    let publishEnable = false;
    let implementationTeamButtonsEnable = false;
    let { dataStatus } = this.state;
    if (store.getState().CurrentFilter.getRecordStatus.length) {
      const recordData = store.getState().CurrentFilter.getRecordStatus;
      let publishStatus = false;
      let saveStatus = false;
      let submitStatus = false;
      let reviewImplementationStatus = false;
      this.GetUserDetails();

      let remarkStatus = false;

      this.setState({ recordStatus: recordData });

      if (recordData[0] == 'Publish') {
        publishStatus = true;
        dataStatus = 'Published';
      }

      if (recordData[0] == 'Save') {
        saveStatus = true;
        dataStatus = 'Draft';
      }

      if (recordData[0] == 'Review(Implementation)') {
        submitStatus = true;
        reviewImplementationStatus = true;
        dataStatus = 'In Review(Implementation)';
      }

      if (recordData[0] == 'Submit') {
        submitStatus = true;
        dataStatus = 'In Review';
      }
      if (recordData[0] == 'Rejected') {
        submitStatus = true;
        dataStatus = 'Rejected';
      }
      if (recordData[3] != undefined) {
        remarkStatus = true;
      }

      if (recordData[2] == 'False') {
        if (recordData[1] == 'True') {
          publishStatus = true;
          saveStatus = false;
          submitStatus = false;
        }
      }
      this.setState({
        publishStatus,
        saveStatus,
        submitStatus,
        remarkStatus,
        dataStatus,
        reviewImplementationStatus,
      });
    } else {
      this.getRecordStatus(this.props.filterDetails);
    }

    const data = store.getState();
    this.setState({
      boData: this.props.boData,
      roleData: data.roleReducers.roleReducers,
      filtername: store.getState().CurrentFilter.Filter.offeringName,
    });
    // Create State Varaibles and use to provide rights

    const featureData = data.roleReducers.roleReducers.map((el) => el.FeatureName);
    const featureDataForConf = data.roleReducers.roleReducers.map((el) => el);
    const confOperations = [];
    const confData = featureDataForConf.filter((el) => el.FeatureName === 'Configurator');
    const configuratorOperations = confData.map((ele) => {
      if (ele.Operations != null && ele.Operations.count != 0) {
        confOperations.push(ele.Operations);
      }
    });


    const operation = [];
    const test = confOperations.map((ele) => {
     
      saveEnable = ele.includes('Save');
      submitEnable = ele.includes('Submit');
      publishEnable = ele.includes('Publish');
      implementationTeamButtonsEnable = ele.includes('Approve - Review Stage 1') && this.props.isNewDeal;
    });

    this.setState({
      saveEnable,
      submitEnable,
      publishEnable,
      implementationTeamButtonsEnable,
    });
    this.getMaintenanceWindowStatus();
    this.Accesscontrol();
    this.GetHelpFeatureNotifications();
  }

  getMaintenanceWindowStatus = () => {
    axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetMaintenanceWindowStatus`).then((response) => {
      this.setState({ isManintencneWindowOpen: response.data }, () => {

      });
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  Accesscontrol = () => {
    const { roleReducers } = this.props;
    let isAccess = false;
    if (
      roleReducers.filter((each) => {
        if (each.FeatureName === 'Configurator' && each.Operations.filter((op) => op === 'Submit').length) {
          return each;
        }
      }).length !== 0
    ) {
      const { currentFilter } = this.props;
      if (
        currentFilter.OfferingId === 'All'
        || currentFilter.ProcessId === 'All'
        || currentFilter.LocationId === 'All'
        || (currentFilter.LocationOMID
          && currentFilter.LocationOMID.length
          && currentFilter.isOMIDAvailable
          && currentFilter.LocationOMID[0] === 'All')
      ) {
        isAccess = false;
      } else {
        isAccess = true;
      }
    }
    this.props.addAccesstoStore({ isAccess });
    this.setState({
      isAccess,
    });
  };

  getRecordStatus(offeringIdparam = {
    Client: 0, process: 0, Locations: 0, Month: getPrevMonth(),
  }) {
    let publishStatus = false;
    let saveStatus = false;
    let submitStatus = false;
    let reviewImplementationStatus = false;
    let remarkStatus = false;
    let dataStatus = '';
    const filterLocations = this.props.filterDetails == undefined ? 0 : this.props.filterDetails.Locations;
    const filterClient = this.props.filterDetails == undefined ? 0 : this.props.filterDetails.Client;
    const filterProcess = this.props.filterDetails == undefined ? 0 : this.props.filterDetails.process;
    const filterDate = this.props.filterDetails == undefined ? 0 : this.props.filterDetails.Month;

    if (filterClient && filterLocations && filterDate && filterProcess) {
      axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetRecordStatus`, {
          params: {
            OrgEntityID: filterClient,
            LocationID: filterLocations,
            MonthDT: filterDate,
            OfferingID: filterProcess,
          },
        })
        .then((response) => {
          this.setState({ recordStatus: response.data });

          store.dispatch({
            type: 'SAVE_GET_RECORD_STATUS',
            payload: response.data,
          });
          if (response.data[0] == 'Publish') {
            publishStatus = true;
            dataStatus = 'Published';
          }

          if (response.data[0] == 'Save') {
            saveStatus = true;
            dataStatus = 'Draft';
          }

          if (response.data[0] == 'Review(Implementation)') {
            submitStatus = true;
            reviewImplementationStatus = true;
            dataStatus = 'In Review(Implementation)';
          }

          if (response.data[0] == 'Submit') {
            submitStatus = true;
            dataStatus = 'In Review';
          }
          if (response.data[0] == 'Rejected') {
            submitStatus = true;
            dataStatus = 'Rejected';
          }
          if (response.data[3] != undefined) {
            remarkStatus = true;
          }

          if (response.data[2] == 'False') {
            if (response.data[1] == 'True') {
              publishStatus = true;
              saveStatus = false;
              submitStatus = false;
            }
          }
          this.setState({
            publishStatus,
            saveStatus,
            submitStatus,
            remarkStatus,
            dataStatus,
            reviewImplementationStatus,
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  onGetData = () => {
    this.setState({ isVisibleDatepickerDropdownPopover: !this.state.isVisibleDatepickerDropdownPopover });
  };

  // Migration View Code Ends
  showQcGraph = (val1, val2) => {
    this.setState({ showQcGraph: val1, selectedTab: val2 });
  };

  ShowInitativeDetails(e) {
    this.setState({
      isShowInitiativeDeatils: true,
    });
  }

  publishJourneyInitiatives = () => {
    if (this.isIOJHasValue()) {
      this.handleIOJAlertData('IntelOps');
    } else {
      const { JourneyData } = this.props;
      let InvalidData = false;
      for (const stage of JourneyData.IOJGlidePathAllModels) {
        if (!this.validateJourenyGlidePath(stage.GlidePathMonth, stage.IOJStageID)) {
          InvalidData = true;
          break;
        }
      }
      if (!InvalidData) {
        this.setState({ active: true });
        axiosInstance
          .post(
            `${LocalApiBaseUrl}IOJJourneyInitiative/PostIOJData?ConfigurationIntelOpsRoadmapID=${JourneyData.ConfigurationIntelOpsRoadmapID}`,
          )
          .then((response) => {
            response = response.data;
            this.setState({ active: false });
            alert(i18n.t('dashboardCntContainerSucM4'));
          })
          .catch((error) => {
            this.setState({ active: false });
            alert(i18n.t('dashboardCntContainerSucM6'));
          });
      }
    }
  };

  validateJourenyGlidePath = (data, IOJStageID) => {
    const { JourneyData } = this.props;
    let valideDate = false;
    const UpdatedJourneyData = { ...JourneyData };
    UpdatedJourneyData.IOJGlidePathAllModels = UpdatedJourneyData.IOJGlidePathAllModels.map((each, i) => {
      if (!each.GlidePathMonth) {
        if (i === 0) {
          each.GlidePathMonth = JourneyData.GlideToFoundation;
        } else if (i === 1) {
          each.GlidePathMonth = JourneyData.GlideToAutomated;
        } else if (i === 2) {
          each.GlidePathMonth = JourneyData.GlideToInsights;
        } else {
          each.GlidePathMonth = JourneyData.GlideToIntelligent;
        }
      }
      return each;
    });
    UpdatedJourneyData.IOJGlidePathAllModels.forEach((each) => {
      if (each.IOJStageID === IOJStageID) {
        let errorExist = false;
        let validPrevStageFlow = false;
        let validNextStageFlow = false;

        const currentStage = IOJStageID - 1;
        for (let i = 0; i < UpdatedJourneyData.IOJGlidePathAllModels.length; i++) {
          if (currentStage !== i && i < currentStage) {
            if (UpdatedJourneyData.IOJGlidePathAllModels[currentStage - (i + 1)].GlidePathMonth) {
              if (
                new Date(
                  FormatDateWithMonthAndYear(
                    UpdatedJourneyData.IOJGlidePathAllModels[currentStage - (i + 1)].GlidePathMonth,
                  ),
                ) < new Date(FormatDateWithMonthAndYear(data))
              ) {
                validNextStageFlow = true;
              } else if (data) {
                alert(
                  `${i18n.t(UpdatedJourneyData.IOJGlidePathAllModels[currentStage - i].IOJStageName)} ${i18n.t(
                    'Glide_Path_Date_should_be_greater_than',
                  )} ${i18n.t(
                    UpdatedJourneyData.IOJGlidePathAllModels[currentStage - (i + 1)].IOJStageName,
                  )} ${i18n.t('Glide_Path_Date')}`,
                );

                errorExist = true;
                break;
              } else {
                validNextStageFlow = true;
              }
            }
          } else if (currentStage !== i && i > currentStage) {
            if (UpdatedJourneyData.IOJGlidePathAllModels[i].GlidePathMonth) {
              if (
                new Date(FormatDateWithMonthAndYear(UpdatedJourneyData.IOJGlidePathAllModels[i].GlidePathMonth))
                > new Date(FormatDateWithMonthAndYear(data))
              ) {
                validPrevStageFlow = true;
              } else if (data) {
                alert(
                  `${i18n.t(UpdatedJourneyData.IOJGlidePathAllModels[currentStage].IOJStageName)} ${i18n.t(
                    'Glide_Path_Date_should_be_less_than',
                  )} ${i18n.t(UpdatedJourneyData.IOJGlidePathAllModels[i].IOJStageName)} ${i18n.t('Glide_Path_Date')}`,
                );
                errorExist = true;
                break;
              } else {
                validPrevStageFlow = true;
              }
            }
          }
        }
        if (!errorExist || (validPrevStageFlow && validNextStageFlow)) {
          valideDate = true;
        }
      }
      return each;
    });
    return valideDate;
  };

  setTabstatus=(isInitativeManagement) => {
    this.setState({
      Tab: isInitativeManagement ? 'InitiativeManagement' : 'IOJMaturityRoadmap',
    });
  }

  HideInitativeDeatils(status, isInitativeManagement) {
    let { isStage, isShowInitiativeDeatils } = this.state;

    if (status) {
      isStage = true;
    } else {
      isStage = false;
      store.dispatch(ClearDatas_ViewDataStageData(true));
    }
    isShowInitiativeDeatils = false;
    this.setState({
      isStage,
      isShowInitiativeDeatils,
      Tab: isInitativeManagement ? 'InitiativeManagement' : 'IOJMaturityRoadmap',
    });
  }

  render() {
    const { isAccess, Tab } = this.state;

    const soCategoryName = 'Intelligent Operations Journey';

    let tabQcScore = 0;
    let tabUnit = '';
    let hasQcSocreData = false;
    if (soCategoryName) {
      if (
        this.props.QcOverallScore
        && this.props.QcOverallScore.QualityScoreDatas
        && this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length
      ) {
        this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.filter((each) => {
          if (each.LeverName_En === soCategoryName) {
            tabQcScore = each.AchievedScore;
            tabUnit = each.Unit;
            hasQcSocreData = true;
          }
        });
      }
    }
    const {
      compDetails, ConfiguratorSubOfferingData, ConfiguratorOfferingData, currentFilter,
    } = this.props;
    return (
      <>
        <LoadingOverlay fadeSpeed={10} spinner={<SynopsLoader />} active={this.props.getConfiguratorapicall}>
          <LoadingOverlay
            classNamePrefix="_loading_overlay_overlay"
            active={this.state.active}
            // spinner
            // text={i18n.t("Loading_SynOps") + "..."}
          >
            <div className="main-component ioj-component">
              {createBreadCrumbs(ConfiguratorSubOfferingData, currentFilter.OfferingId, currentFilter.ProcessId, ConfiguratorOfferingData) }
              <div
                className={
                  this.props.compDetails.component === 'Journey Initiatives'
                    ? 'container-fluid ioj-journey-initiative'
                    : 'container-fluid'
                }
              >
                {/* {this.props.compDetails.component !== "Maturity Plan" ? */}
                {this.state.showstage ? (
                  <div className="row">
                    {this.props.compDetails.component !== 'Journey Initiatives' ? (
                      <div className="col-lg-12">
                        {this.state.dataStatus != undefined ? (
                          <div className="row">
                            <div className="col-sm-7">
                              <h4 className="stage-heading pull-left mb-0 stageheading-lineheight">
                                {`${compDetails.tabName}  (${i18n.t('Stage')} - ${this.state.dataStatus}`}
                                )
                                {this.props.compDetails.component !== 'Maturity Plan'
                                && this.props.compDetails.component !== 'Deal Report'
                                && this.props.compDetails.component !== 'Journey Initiatives' ? (
                                  <span className="p-l-20">
                                    {!this.state.publishStatus && !this.state.submitStatus ? (
                                      (this.props.getRecordStatus && this.props.getRecordStatus[0] === 'Publish')
                                      || (this.props.getRecordStatus
                                        && this.props.getRecordStatus[1] === 'True'
                                        && !this.state.isManintencneWindowOpen)
                                      || (this.props.getRecordStatus
                                        && this.props.getRecordStatus[0] === 'Publish'
                                        && !this.state.isManintencneWindowOpen)
                                      || this.props.roleReducers.filter((each) => {
                                        if (each.FeatureName === 'Configurator' && each.Operations.length) {
                                          return each;
                                        }
                                      }).length === 0
                                      || (this.props.getRecordStatus
                                        && this.props.getRecordStatus[0] === 'Save'
                                        && this.props.roleReducers.filter((each) => {
                                          if (
                                            each.FeatureName === 'Configurator'
                                            && each.Operations.filter((op) => op === 'Submit').length
                                          ) {
                                            return each;
                                          }
                                        }).length === 0)
                                      || (this.props.getRecordStatus
                                        && this.props.getRecordStatus[0] === 'Submit'
                                        && this.props.roleReducers.filter((each) => {
                                          if (
                                            each.FeatureName === 'Configurator'
                                            && each.Operations.filter((op) => op === 'Publish').length
                                          ) {
                                            return each;
                                          }
                                        }).length === 0)
                                      || (this.state.implementationTeamButtonsEnable
                                        && this.props.getRecordStatus[0] === 'Review(Implementation)') ? (
                                          ''
                                        ) : (
                                          <button
                                            className="btn btn-primary btn-sm mr-2 button-get-data"
                                            ref={this.datepickerDropdownPopoverTarget}
                                            onClick={this.onGetData.bind(this)}
                                          >
                                            {i18n.t('Get_Data')}
                                            {' '}
                                            <span className="p-l-10">
                                              <i className="fas fa-chevron-down" />
                                            </span>
                                          </button>
                                        )
                                    ) : null}
                                  </span>
                                  ) : (
                                    ''
                                  )}
                              </h4>

                              {this.state.isVisibleDatepickerDropdownPopover ? (
                                <CustomDatePicker
                                  datepickerDropdownPopoverTarget={this.datepickerDropdownPopoverTarget}
                                  isVisibleDatepickerDropdownPopover={this.state.isVisibleDatepickerDropdownPopover}
                                  onGetData={this.onGetData}
                                />
                              ) : (
                                ''
                              )}

                              <span className="publishedOn">
                                {' '}
                                {this.state.dataStatus != 'Published' ? (
                                  <span>
                                    {this.state.LastPublishedDate ? (
                                      <span>
                                        {i18n.t('Last_Published_on')}
                                        :
                                        {FormatDateNoTime(this.state.LastPublishedDate)}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </span>
                            </div>
                            {this.props.compDetails.component !== 'Deal Report' ? (
                              <div className="col-sm-5 ioj-right-btn-controls">
                                <div className="float-right d-flex align-items-center button-controls">
                                  {hasQcSocreData && this.props.compDetails.component === 'Journey Initiatives' ? (
                                    <span className="qcscore-status">
                                      <span
                                        onClick={this.showQcGraph.bind(this, true, soCategoryName)}
                                        className={String(dataStatus) !== 'Published' ? (
                                          tabQcScore < 50
                                            ? 'qcscore-percent red-status'
                                            : tabQcScore >= 50 && tabQcScore <= 75
                                              ? 'qcscore-percent orange-status'
                                              : 'qcscore-percent green-status'
                                        ):(tabQcScore < 50
                                          ? 'mr-1 qcscore-percent red-status'
                                          : tabQcScore >= 50 && tabQcScore <= 75
                                            ? 'mr-1 qcscore-percent orange-status'
                                            : 'mr-1 qcscore-percent green-status')}
                                      >
                                        QC Score
                                        {' '}
                                        {tabQcScore}
                                        {tabUnit}
                                      </span>
                                      
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                  {this.state.remarkStatus ? (
                                    <span className="p-r-20">
                                      <a onClick={this.viewMore.bind(this)} className="colour-red">
                                        <FontAwesomeIcon icon={faInfoCircle} title="Remarks" />
                                      </a>
                                    </span>
                                  ) : null}

                                  {(this.state.submitEnable
                                    && !this.state.submitStatus
                                    && !this.state.publishStatus
                                    && this.props.compDetails.component !== 'Journey Initiatives')
                                  || (this.state.submitEnable
                                    && !this.state.publishStatus
                                    && this.state.IsAdmin == true
                                    && this.props.compDetails.component !== 'Journey Initiatives') ? (
                                      <>
                                     {/* <span className="border-line-end publish-border" /> */}
                                      <button
                                        className="btn btn-primary btn-sm mr-2 button-get-data"
                                        id="btnsubmit"
                                      // Metric Migration Condn

                                        onClick={this.SubmitClick.bind(this)}
                                      >
                                        {i18n.t('Submit')}
                                      </button>
                                    </>) : null}

                                  {/* New approve and reject buttons for implementation team */}

                                  {this.state.implementationTeamButtonsEnable
                                  && this.state.reviewImplementationStatus
                                  && this.props.compDetails.component !== 'Journey Initiatives' ? (
                                    <button
                                      className="btn btn-primary btn-sm mr-2 button-get-data"
                                      onClick={(e) => {
                                        this.approveImplementationClick(e);
                                      }}
                                    >
                                      {i18n.t('Approve')}
                                    </button>
                                    ) : null}

                                  {this.state.implementationTeamButtonsEnable
                                  && this.state.reviewImplementationStatus
                                  && this.props.compDetails.component !== 'Journey Initiatives' ? (
                                    <button
                                      className="btn btn-primary btn-sm mr-2 button-get-data"
                                      onClick={(e) => {
                                        this.RejectImplementationClick(e);
                                      }}
                                    >
                                      {i18n.t('Reject')}
                                    </button>
                                    ) : null}

                                  {/* New approve and reject buttons for implementation team end */}
                                  {this.state.publishEnable
                                  && !this.state.publishStatus
                                  && !this.state.saveStatus
                                  && this.state.dataStatus == 'In Review'
                                  && this.props.compDetails.component !== 'Journey Initiatives' ? (
                                    <button
                                      className="btn btn-primary btn-sm mr-2 button-get-data"
                                      id="btnpublish"
                                      // Metric Migration Condn

                                      onClick={this.PublishClick.bind(this)}
                                    >
                                      {i18n.t('Publish')}
                                    </button>
                                    ) : null}
                                  {this.state.publishEnable
                                  && !this.state.saveStatus
                                  && !this.state.publishStatus
                                  && this.state.dataStatus == 'In Review'
                                  && this.props.compDetails.component !== 'Journey Initiatives' ? (
                                    <button
                                      className="btn btn-primary btn-sm mr-2 button-get-data"
                                      onClick={(e) => {
                                        this.RejectClick(e);
                                      }}
                                    >
                                      {i18n.t('Reject')}
                                    </button>
                                  ) : null}
                                  <div className="category-references dashboard-category-references">
                                  {this.props.compDetails.component === 'Value Realization'
                                    ? (
                                      <References
                                        {...this.props}
                                        IOJSectionHierarchyID={2}
                                        SOCatID={0}
                                        isConfig
                                        leverName="Value Realization"
                                        referenceScreen="ValueRealization"
                                      />
                                    )
                                    : (
                                      <References
                                        {...this.props}
                                        IOJSectionHierarchyID={3}
                                        SOCatID={0}
                                        isConfig
                                        leverName="Maturity Plan"
                                        referenceScreen="MaturityPlan"
                                      />
                                    )}
                                </div>
                                
                                    
                                <NavLink
                                    to= {{
                                      pathname: "/helpfeatures",
                                      state: {
                                        name: compDetails.tabName
                                      }
                                    }}
                                  >
                                    <span className="help-notification">
                                      {this.state.isHelpCountLoaded && 
                                        <a data-tip data-for="help-notification-tooltip"  href="javascript:void(0)">
                                          {this.state.newHelpFeaturesCount > 0 &&
                                            <span>
                                              {this.state.newHelpFeaturesCount > 9 ? i18n.t('9+') : this.state.newHelpFeaturesCount}
                                            </span>
                                          }
                                        </a>
                                      }
                                    </span>
                                    {this.state.isHelpCountLoaded &&
                                      <ReactTooltip id="help-notification-tooltip" effect="solid" place="left">
                                        <span>
                                        {this.state.newHelpFeaturesCount > 0 
                                          ? (this.state.newHelpFeaturesCount + " " +i18n.t('New') + " " +
                                            (this.state.newHelpFeaturesCount == 1 ? i18n.t('Feature') : i18n.t('Features')) + " " +
                                            i18n.t('updated.') + " " + i18n.t('Click here for details')) 
                                          : i18n.t('Click here for updates')
                                        } 
                                        </span>
                                      </ReactTooltip>
                                    } 
                                  </NavLink>
                                </div>          
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-sm-7">
                            <h4 className="stage-heading pull-left">{compDetails.tabName}</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
                {this.props.compDetails.component === 'Intelligent Operations Journey'
                || this.props.compDetails.component === 'Journey Initiatives' ? (

                    !this.state.isShowInitiativeDeatils ? (
                      <JourneyInitiativeContainer
                        Tab={Tab}
                        ShowInitativeDetails={this.ShowInitativeDetails}
                        setTabstatus={this.setTabstatus}
                        isStage={this.state.isStage}
                        isInitativeEditMode={this.state.isInitativeEditMode}
                        InitativeID={this.state.InitativeID}
                        publishJourneyInitiatives={this.publishJourneyInitiatives}
                        compDetails={this.props.compDetails}
                        isAccess={this.state.isAccess}
                        RecordStatus={this.props.getRecordStatus}
                        isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                        roleReducers={this.props.roleReducers}
                        filterDetails={this.props.filterDetails}
                        dataStatus={this.state.dataStatus}
                        newHelpFeaturesCount={this.state.newHelpFeaturesCount}
                        isHelpCountLoaded={this.state.isHelpCountLoaded}
                      />
                    ) : (
                      <InitiativeDetails
                        Tab={Tab}
                        EachInitativeData={this.state.EachInitiativeDetail}
                        HideInitativeDeatils={this.HideInitativeDeatils}
                        isStage={this.state.isStage}
                        isAccess={this.state.isAccess}
                        RecordStatus={this.props.getRecordStatus}
                        isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                        roleReducers={this.props.roleReducers}
                        filterDetails={this.props.filterDetails}
                      />
                    )
                  ) : null}
                  {this.props.compDetails.component === 'IOJ Scorecard' ? (
                  <IOJScoreCard />
                ) : null}


                {this.props.compDetails.component === 'Value Realization' ? (
                  <IOJ_ValueRealization filterDetails={this.props.filterDetails} />
                ) : null}

                {this.props.compDetails.component === 'Maturity Plan' ? (
                  <AddPMMService
                    Showparent={this.Showparent}
                    Hideparent={this.Hideparent}
                    RecordStatus={this.props.getRecordStatus}
                    isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                    roleReducers={this.props.roleReducers}
                    filterDetails={this.props.filterDetails}
                    isAccess={this.state.isAccess}
                  />
                ) : null}

                {this.props.compDetails.component === 'Deal Report' ? (
                  <DealReport filterDetails={this.props.filterDetails} roledata={this.state.roledata} />
                ) : null}
              </div>
            </div>
          </LoadingOverlay>
        </LoadingOverlay>
        {this.state.viewmore ? (
          <RejectPopUp
            show
            onClose={this.onClose}
            onStatus={this.getRecordStatus}
            filterDetails={this.props.filterDetails}
          />
        ) : null}
        {this.state.viewRemark ? (
          <RemarksPopUp
            show
            onClose={this.onClose}
            filterDetails={this.props.filterDetails}
            rejectedByImplementationTeam={this.state.rejectedByImplementationTeam}
          />
        ) : null}
        {this.state.showQcGraph ? (
          <TabQCScore showQcGraph={this.showQcGraph} selectedTab={this.state.selectedTab} />
        ) : (
          ''
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  boData: state.BOReducers.BO,
  getRecordStatus: state.CurrentFilter.getRecordStatus,
  QcOverallScore: { ...state.CurrentFilter.QcOverallScore },
  lastPublishedDate: state.CurrentFilter.LastPublishedDate,
  IOJData: state.JourneyReducer.IOJData,
  JourneyData: state.JourneyReducer.JourneyData,
  currentFilter: state.CurrentFilter.SyncFilter,
  ConfiguratorOfferingData: state.FilterReducer && state.FilterReducer.ConfiguratorOfferingData ? state.FilterReducer.ConfiguratorOfferingData : [],
  ConfiguratorSubOfferingData: state.FilterReducer && state.FilterReducer.ConfiguratorSubOfferingData ? state.FilterReducer.ConfiguratorSubOfferingData : [],
});
const mapDispatchToProps = (dispatch) => ({
  addAccesstoStore: (payload) => dispatch(addAccess(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IOJConf);
