import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { connect } from "react-redux";
import { store } from "../reducers/configureStore";
import DataPopUp from "./data_popup";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { CommonToolTip } from "../Shared/Common";

class DiverseInsights extends Component {
  constructor() {
    super();
    this.state = {
      metrics: [],
      showAddWidgetPopUp: false,
      widgetData: [],
      wMode: "",
      widgetPopUpObj: {},
      widgetTitle: "",
      widgetIdx: "",
      selectedMetricIdx: "",
      widgetPopUpData: [],
    };
    this.onClose = this.onClose.bind(this);
    this.updateLocalState = this.updateLocalState.bind(this);
  }

  componentDidMount() {
    let dataProcess = [...this.props.diverseData];
    this.updateLocalState(dataProcess);
  }

  componentWillReceiveProps(newProps) {
    this.updateLocalState(newProps.diverseData);
  }

  updateLocalState(dataProcess) {
    if (dataProcess != undefined && dataProcess[0] != undefined && dataProcess[0].Widget != undefined) {
      let result = dataProcess[0].Widget;
      result = result.sort(function(a, b) {
        return a.WidgetDisplayOrder - b.WidgetDisplayOrder;
      });
      //adding isError property to the response object
      result = result.map((item, index) => {
        item.AddNewWidget = item.AddNewWidget.sort(function(a, b) {
          return a.RecordSequenceNumber - b.RecordSequenceNumber;
        });
        for (let i = 0; i < item.AddNewWidget.length; i++) {
          item.AddNewWidget[i].isError = false;
        }
        for (let ii = 0; ii < item.WidgeFields.length; ii++) {
          let dataArr = item.WidgeFields[ii].Data;
          dataArr = dataArr.sort(function(a, b) {
            return a.RecordSequenceNumber - b.RecordSequenceNumber;
          });
          for (let j = 0; j < dataArr.length; j++) {
            dataArr[j].isError = false;
          }
        }
        return item;
      });
      this.setState({ widgetData: result });
    }
  }

  handleWidgetPopUp(e, param, obj, wTitle, index, idx) {
    e.preventDefault();
    let temp = [...this.state.widgetPopUpData];
    if (param === "ADD_WDATA") {
      temp = obj.AddNewWidget;
    } else {
      temp = obj.WidgeFields[idx].Data;
    }
    this.setState({
      showAddWidgetPopUp: true,
      wMode: param,
      widgetPopUpObj: obj,
      widgetTitle: wTitle,
      selectedMetricIdx: idx,
      widgetIdx: index,
      widgetPopUpData: temp,
    });
  }

  deleteData = (e, param, w_index, m_idx) => {
    if (window.confirm("Are you sure you want to delete item?")) {
      e.preventDefault();
      let temp = [...this.state.widgetData];
      if (m_idx > -1) {
        let ids = [];
        let value = temp[w_index].WidgeFields[m_idx].Data
          ? temp[w_index].WidgeFields[m_idx].Data.map((each) => {
              ids.push(each.ConfigurationWidgetAttributeID);

              return each;
            })
          : "";

        axiosInstance
          .delete(`${LocalApiBaseUrl}Configurator/DeleteConfiguratorDDWidgets?widgetIDs=${ids}`)
          .then((res) => {
            axiosInstance
              .get(`${LocalApiBaseUrl}Configurator/GetDiverseDataQualityScore`, {
                params: {
                  LocationID: this.props.filterDetails.Locations,
                  OrgEntityID: this.props.filterDetails.Client,
                  OfferingID: this.props.filterDetails.process,
                  MonthDT: this.props.filterDetails.Month,
                  LanguageID: store.getState().CurrentFilter.languageData.id,
                },
              })
              .then((response) => {
                if (response.data) {
                  store.dispatch({
                    type: "SAVE_QC_SCORE_OF_EACH_TAB",
                    payload: response.data,
                  });
                }
              })
              .catch((error) => {
                trycatchAlertPopup(error);
              });

            temp[w_index].WidgeFields.splice(m_idx, 1);
            this.setState({ widgetData: temp });
            this.props.onDeleteData(temp);
          })
          .catch((error) => {
            trycatchAlertPopup(error);

          });
      }
    }
    e.preventDefault();
  };

  onClose() {
    this.setState({ showAddWidgetPopUp: false, wMode: "" });
  }

  MetricValueUnitFormat = (Unitvalue, value, AttributeValueDigit) => {
    Unitvalue = Unitvalue.trim();
    let returnValue = "";
    if (Number.isInteger(Number(value)) && AttributeValueDigit != 0) {
      if (
        Unitvalue === "$" ||
        Unitvalue === "₹" ||
        Unitvalue === "R$" ||
        Unitvalue === "€" ||
        Unitvalue === "£" ||
        Unitvalue === "SGD"
      ) {
        returnValue = Unitvalue + " " + (AttributeValueDigit != null ? AttributeValueDigit : "");
      } else if (
        Unitvalue === "$ Mn" ||
        Unitvalue === "₹ Mn" ||
        Unitvalue === "R$ Mn" ||
        Unitvalue === "€$ Mn" ||
        Unitvalue === "SGD Mn"
      ) {
        returnValue = Unitvalue.split(" ")[0] + " " + (AttributeValueDigit != null ? AttributeValueDigit : "") + " Mn";
      } else {
        returnValue = AttributeValueDigit + " " + Unitvalue;
      }
    } else {
      returnValue =
        Unitvalue === "$"
          ? Unitvalue + " " + (AttributeValueDigit != null ? AttributeValueDigit : "")
          : AttributeValueDigit != null
          ? AttributeValueDigit
          : "";
    }
    return returnValue;
  };

  render() {
    const options = {
      loop: true,
      margin: 16,
      dots: false,
      autoplay: false,
      loop: false,
      nav: true,
      responsiveClass: true,
      autoWidth: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        600: {
          items: 2,
          nav: false,
        },
        1000: {
          items: 3,
          nav: true,
          loop: false,
        },
        1200: {
          items: 3,
          nav: true,
          loop: false,
        },
        1400: {
          items: 3,
          nav: true,
          loop: false,
        },
        1600: {
          items: 4,
          nav: true,
          loop: false,
        },
      },
    };
    return (
      <>
        {this.state.widgetData.length > 0 &&
          this.state.widgetData.map((wData, index) => {
            return (
              <>
                <div className="custom-tilecard dd-insights-card ddinsights-dashboard">
                  <div className="tilecard-wrapper">
                    <h2>
                      <span>{wData.TileHeader}</span>
                      <span
                        className="add-data adddata-icon"
                        onClick={(e) => {
                          this.handleWidgetPopUp(e, "ADD_WDATA", wData, wData.TileHeader, index);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp; Add Data
                      </span>
                    </h2>

                    <div className="custom-carousel-padding">
                      <OwlCarousel options={options} className="owl-carousel">
                        {wData.WidgeFields.length > 0 ? (
                          <>
                            {wData.WidgeFields.map((row, item) => {
                              let AttributeValueDigit = "";
                              AttributeValueDigit = row.Data[1].AttributeValue;
                              let AttributeValueDigitDonut = "";

                              AttributeValueDigitDonut =
                                Math.round(row.Data[1].AttributeValue) > 100 ? 100 : Math.round(AttributeValueDigit);
                              return (
                                <>
                                  {!wData.IsDonutDisplay ? (
                                    <>
                                      {row.Data && row.Data.length == 2 ? (
                                        <>
                                          <div
                                            className="item gray-color twoitem-widget"
                                            data-place="bottom"
                                            data-tip="Lower Threshold: NA, Upper Threshold: NA"
                                          >
                                            <div className="item-top">
                                              {row.Data[0].AttributeValue.length > 18 ? (
                                                <CommonToolTip
                                                  id={"attribute_1"}
                                                  text={row.Data[0].AttributeValue}
                                                  length={18}
                                                  element={"p"}
                                                  place={"bottom"}
                                                />
                                              ) : (
                                                <p>{row.Data[0].AttributeValue}</p>
                                              )}
                                            </div>
                                            <div className="item-bottom">
                                              <p>{AttributeValueDigit != null ? AttributeValueDigit : ""}</p>
                                            </div>

                                            {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                                            (this.props.RecordStatus &&
                                              this.props.RecordStatus[1] === "True" &&
                                              !this.props.isManintencneWindowOpen) ||
                                            (this.props.RecordStatus &&
                                              this.props.RecordStatus[0] === "Publish" &&
                                              !this.props.isManintencneWindowOpen) ||
                                            this.props.roleReducers.filter((each) => {
                                              if (each.FeatureName === "Configurator" && each.Operations.length) {
                                                return each;
                                              }
                                            }).length === 0 ||
                                            (this.props.RecordStatus &&
                                              this.props.RecordStatus[0] === "Save" &&
                                              this.props.roleReducers.filter((each) => {
                                                if (
                                                  each.FeatureName === "Configurator" &&
                                                  each.Operations.filter((op) => op === "Submit").length
                                                ) {
                                                  return each;
                                                }
                                              }).length === 0) ||
                                            (this.props.RecordStatus &&
                                              this.props.RecordStatus[0] === "Submit" &&
                                              this.props.roleReducers.filter((each) => {
                                                if (
                                                  each.FeatureName === "Configurator" &&
                                                  each.Operations.filter((op) => op === "Publish").length
                                                ) {
                                                  return each;
                                                }
                                              }).length === 0) ? (
                                              ""
                                            ) : (
                                              <div className="dropdown dropleft">
                                                <span
                                                  type="button"
                                                  className="dropdown-toggle"
                                                  data-toggle="dropdown"
                                                ></span>
                                                <div className="dropdown-menu">
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="dropdown-item"
                                                    onClick={(e) => this.deleteData(e, "REMOVE_WDATA", index, item)}
                                                  >
                                                    <span className="delete-icon"></span>Remove
                                                  </a>
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="dropdown-item"
                                                    onClick={(e) => {
                                                      this.handleWidgetPopUp(
                                                        e,
                                                        "EDIT_WDATA",
                                                        wData,
                                                        wData.TileHeader,
                                                        index,
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    <span className="edit-icon"></span>Edit
                                                  </a>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      ) : row.Data && row.Data.length == 3 ? (
                                        <>
                                          <div
                                            className="item gray-color threeitem-widget"
                                            data-place="bottom"
                                            data-tip="Lower Threshold: NA, Upper Threshold: NA"
                                          >
                                            <div className="item-top">
                                              <p className="custom-widget-tooltip">
                                                {row.Data[1].AttributeValue.length > 7 ? (
                                                  <CommonToolTip
                                                    id={"attribute_2"}
                                                    text={row.Data[1].AttributeValue}
                                                    length={7}
                                                    element={"b"}
                                                    place={"bottom"}
                                                  />
                                                ) : (
                                                  <b>{row.Data[1].AttributeValue}</b>
                                                )}

                                                {row.Data[2].AttributeValue.length > 28 ? (
                                                  <CommonToolTip
                                                    id={"attribute_2"}
                                                    text={" " + row.Data[2].AttributeValue}
                                                    length={28}
                                                    element={"span"}
                                                    place={"bottom"}
                                                  />
                                                ) : (
                                                  <span>{" " + row.Data[2].AttributeValue}</span>
                                                )}
                                              </p>
                                            </div>
                                            <div className="item-bottom">
                                              {row.Data[0].AttributeValue.length > 40 ? (
                                                <CommonToolTip
                                                  id={"attribute_2"}
                                                  text={row.Data[0].AttributeValue}
                                                  length={40}
                                                  element={"p"}
                                                  place={"bottom"}
                                                />
                                              ) : (
                                                <p>{row.Data[0].AttributeValue}</p>
                                              )}
                                            </div>
                                            {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                                            (this.props.RecordStatus &&
                                              this.props.RecordStatus[1] === "True" &&
                                              !this.props.isManintencneWindowOpen) ||
                                            (this.props.RecordStatus &&
                                              this.props.RecordStatus[0] === "Publish" &&
                                              !this.props.isManintencneWindowOpen) ||
                                            this.props.roleReducers.filter((each) => {
                                              if (each.FeatureName === "Configurator" && each.Operations.length) {
                                                return each;
                                              }
                                            }).length === 0 ||
                                            (this.props.RecordStatus &&
                                              this.props.RecordStatus[0] === "Save" &&
                                              this.props.roleReducers.filter((each) => {
                                                if (
                                                  each.FeatureName === "Configurator" &&
                                                  each.Operations.filter((op) => op === "Submit").length
                                                ) {
                                                  return each;
                                                }
                                              }).length === 0) ||
                                            (this.props.RecordStatus &&
                                              this.props.RecordStatus[0] === "Submit" &&
                                              this.props.roleReducers.filter((each) => {
                                                if (
                                                  each.FeatureName === "Configurator" &&
                                                  each.Operations.filter((op) => op === "Publish").length
                                                ) {
                                                  return each;
                                                }
                                              }).length === 0) ? (
                                              ""
                                            ) : (
                                              <div className="dropdown dropleft">
                                                <span
                                                  type="button"
                                                  className="dropdown-toggle"
                                                  data-toggle="dropdown"
                                                ></span>
                                                <div className="dropdown-menu">
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="dropdown-item"
                                                    onClick={(e) => this.deleteData(e, "REMOVE_WDATA", index, item)}
                                                  >
                                                    <span className="delete-icon"></span>Remove
                                                  </a>
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="dropdown-item"
                                                    onClick={(e) => {
                                                      this.handleWidgetPopUp(
                                                        e,
                                                        "EDIT_WDATA",
                                                        wData,
                                                        wData.TileHeader,
                                                        index,
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    <span className="edit-icon"></span>Edit
                                                  </a>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="item gray-color fourlayout-widget"
                                            data-place="bottom"
                                            data-tip="Lower Threshold: NA, Upper Threshold: NA"
                                          >
                                            <div className="item-left">
                                              <p className="mtcinsight-value custom-widget-tooltip">
                                                {row.Data[2].AttributeValue.length > 7 ? (
                                                  <CommonToolTip
                                                    id={"attribute_3"}
                                                    text={row.Data[2].AttributeValue}
                                                    length={7}
                                                    element={"b"}
                                                    place={"bottom"}
                                                  />
                                                ) : (
                                                  <b>{row.Data[2].AttributeValue}</b>
                                                )}
                                                {row.Data[3].AttributeValue.length > 15 ? (
                                                  <CommonToolTip
                                                    id={"attribute_3"}
                                                    text={" " + row.Data[3].AttributeValue}
                                                    length={15}
                                                    element={"span"}
                                                    place={"bottom"}
                                                  />
                                                ) : (
                                                  <span> {" " + row.Data[3].AttributeValue} </span>
                                                )}
                                              </p>
                                              {row.Data[0].AttributeValue.length > 45 ? (
                                                <CommonToolTip
                                                  id={"attribute_3"}
                                                  text={row.Data[0].AttributeValue}
                                                  length={45}
                                                  element={"p"}
                                                  place={"bottom"}
                                                  class={"mtcinsights-desc"}
                                                />
                                              ) : (
                                                <p className="mtcinsights-desc">{row.Data[0].AttributeValue}</p>
                                              )}
                                            </div>
                                            <div className="item-right">
                                              {row.Data[1].AttributeValue.length > 40 ? (
                                                <CommonToolTip
                                                  id={"attribute_3"}
                                                  text={row.Data[1].AttributeValue}
                                                  length={40}
                                                  element={"p"}
                                                  place={"bottom"}
                                                  class={"mtcinsights-name"}
                                                />
                                              ) : (
                                                <p className="mtcinsights-name">{row.Data[1].AttributeValue}</p>
                                              )}
                                            </div>
                                            {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                                            (this.props.RecordStatus &&
                                              this.props.RecordStatus[1] === "True" &&
                                              !this.props.isManintencneWindowOpen) ||
                                            (this.props.RecordStatus &&
                                              this.props.RecordStatus[0] === "Publish" &&
                                              !this.props.isManintencneWindowOpen) ||
                                            this.props.roleReducers.filter((each) => {
                                              if (each.FeatureName === "Configurator" && each.Operations.length) {
                                                return each;
                                              }
                                            }).length === 0 ||
                                            (this.props.RecordStatus &&
                                              this.props.RecordStatus[0] === "Save" &&
                                              this.props.roleReducers.filter((each) => {
                                                if (
                                                  each.FeatureName === "Configurator" &&
                                                  each.Operations.filter((op) => op === "Submit").length
                                                ) {
                                                  return each;
                                                }
                                              }).length === 0) ||
                                            (this.props.RecordStatus &&
                                              this.props.RecordStatus[0] === "Submit" &&
                                              this.props.roleReducers.filter((each) => {
                                                if (
                                                  each.FeatureName === "Configurator" &&
                                                  each.Operations.filter((op) => op === "Publish").length
                                                ) {
                                                  return each;
                                                }
                                              }).length === 0) ? (
                                              ""
                                            ) : (
                                              <div className="dropdown dropleft">
                                                <span
                                                  type="button"
                                                  className="dropdown-toggle"
                                                  data-toggle="dropdown"
                                                ></span>
                                                <div className="dropdown-menu">
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="dropdown-item"
                                                    onClick={(e) => this.deleteData(e, "REMOVE_WDATA", index, item)}
                                                  >
                                                    <span className="delete-icon"></span>Remove
                                                  </a>
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="dropdown-item"
                                                    onClick={(e) => {
                                                      this.handleWidgetPopUp(
                                                        e,
                                                        "EDIT_WDATA",
                                                        wData,
                                                        wData.TileHeader,
                                                        index,
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    <span className="edit-icon"></span>Edit
                                                  </a>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div className="item gray-color doughnutwidget-insight">
                                        <div className="item-left">
                                          <div className="rd-data">
                                            <div
                                              className={`rd-progress ${`${
                                                Number.isInteger(Number(AttributeValueDigitDonut))
                                                  ? "p" + AttributeValueDigitDonut
                                                  : "p" + Math.round(AttributeValueDigitDonut)
                                              }`}`}
                                            >
                                              <span>
                                                {row.Data[1].AttributeValue.length > 3 ? (
                                                  <CommonToolTip
                                                    id={"attribute_0"}
                                                    text={row.Data[1].AttributeValue}
                                                    length={3}
                                                    element={"b"}
                                                    place={"bottom"}
                                                  />
                                                ) : (
                                                  <b> {row.Data[1].AttributeValue}</b>
                                                )}

                                                {row.Data[2].AttributeValue.length > 2 ? (
                                                  <CommonToolTip
                                                    id={"attribute_0"}
                                                    text={row.Data[2].AttributeValue}
                                                    length={2}
                                                    element={"span"}
                                                    place={"bottom"}
                                                    class={"metric-digit-text"}
                                                  />
                                                ) : (
                                                  <span className="metric-digit-text">
                                                    {row.Data[2].AttributeValue}
                                                  </span>
                                                )}
                                              </span>
                                              <div className="rd-slice">
                                                <div className="rd-bar"></div>
                                                <div class="rd-fill"></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="item-right">
                                          {row.Data[0].AttributeValue.length > 46 ? (
                                            <CommonToolTip
                                              id={"attribute_0"}
                                              text={row.Data[0].AttributeValue}
                                              length={46}
                                              element={"span"}
                                              place={"bottom"}
                                              class={"doughnut-name"}
                                            />
                                          ) : (
                                            <span className="doughnut-name">{row.Data[0].AttributeValue}</span>
                                          )}
                                        </div>
                                        {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                                        (this.props.RecordStatus &&
                                          this.props.RecordStatus[1] === "True" &&
                                          !this.props.isManintencneWindowOpen) ||
                                        (this.props.RecordStatus &&
                                          this.props.RecordStatus[0] === "Publish" &&
                                          !this.props.isManintencneWindowOpen) ||
                                        this.props.roleReducers.filter((each) => {
                                          if (each.FeatureName === "Configurator" && each.Operations.length) {
                                            return each;
                                          }
                                        }).length === 0 ||
                                        (this.props.RecordStatus &&
                                          this.props.RecordStatus[0] === "Save" &&
                                          this.props.roleReducers.filter((each) => {
                                            if (
                                              each.FeatureName === "Configurator" &&
                                              each.Operations.filter((op) => op === "Submit").length
                                            ) {
                                              return each;
                                            }
                                          }).length === 0) ||
                                        (this.props.RecordStatus &&
                                          this.props.RecordStatus[0] === "Submit" &&
                                          this.props.roleReducers.filter((each) => {
                                            if (
                                              each.FeatureName === "Configurator" &&
                                              each.Operations.filter((op) => op === "Publish").length
                                            ) {
                                              return each;
                                            }
                                          }).length === 0) ? (
                                          ""
                                        ) : (
                                          <div className="dropdown dropleft">
                                            <span
                                              type="button"
                                              className="dropdown-toggle"
                                              data-toggle="dropdown"
                                            ></span>
                                            <div className="dropdown-menu">
                                              <a
                                                href="javascript:void(0)"
                                                className="dropdown-item"
                                                onClick={(e) => this.deleteData(e, "REMOVE_WDATA", index, item)}
                                              >
                                                <span className="delete-icon"></span>Remove
                                              </a>
                                              <a
                                                href="javascript:void(0)"
                                                className="dropdown-item"
                                                onClick={(e) => {
                                                  this.handleWidgetPopUp(
                                                    e,
                                                    "EDIT_WDATA",
                                                    wData,
                                                    wData.TileHeader,
                                                    index,
                                                    item
                                                  );
                                                }}
                                              >
                                                <span className="edit-icon"></span>Edit
                                              </a>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <div className="data-processed ddnew-empty">
                            {wData.IsDonutDisplay ? <div className="dataprocessed-icon"></div> : null}
                            <div className="empty-ddinsight">
                              <h6>Add {" " + wData.TileHeader}</h6>
                              <h6></h6>
                            </div>
                          </div>
                        )}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </>
            );
          })}

        {this.state.showAddWidgetPopUp ? (
          <DataPopUp
            show={this.state.showAddWidgetPopUp}
            onClose={this.onClose}
            type={this.state.type}
            wMode={this.state.wMode}
            widgetPopUpObj={this.state.widgetPopUpObj}
            widgetTitle={this.state.widgetTitle}
            widgetIdx={this.state.widgetIdx}
            selectedMetricIdx={this.state.selectedMetricIdx}
            widgetPopUpData={this.state.widgetPopUpData}
            widgetData={this.state.widgetData}
            filterDetails={this.props.filterDetails}
            callGetConfigurator={this.props.callGetConfigurator}
            isApiSuccess={this.props.isApiSuccess}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    diverseData: [...state.diverseReducers.diverse],
    roleReducers: state.roleReducers.roleReducers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteData: (data) => {
      dispatch({ type: "REMOVE_DATA", payload: data });
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiverseInsights);
