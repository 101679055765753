import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import { useMsal } from "@azure/msal-react";
import { withRouter } from "react-router-dom";
import LogoutConfirmationpopup from "./logoutConfirmationpopup";
import { idleTimedout, popUpidleTimedout } from "../Shared/Constant";
import { connect } from "react-redux";
import { compose } from "redux";

function IdleTimerContainer({userLoginData}) {
  const idleTimerRef = useRef(null);
  const [isIdle, setisIdle] = useState(false);
  const sessionTimeoutref = useRef(null);
  const { instance } = useMsal();

  const idleTimeout = idleTimedout; //30 minutes
  const popupIdleTimeout = popUpidleTimedout; //5 seconds

  const logOut = () => {
    setisIdle(false);
    instance.logoutRedirect({
      account: instance.getAccountByHomeId(userLoginData.account.homeAccountId),
    });
  };

  const clearTimeOut = () => {
    clearTimeout(sessionTimeoutref.current);
    setisIdle(false);
  };
  const handleIdle = () => {
    setisIdle(true);
    sessionTimeoutref.current = setTimeout(logOut, popupIdleTimeout); //User is still idle even after poup displayed.autologout will happen after 5sec
  };

  return (
    <div>
      <IdleTimer ref={idleTimerRef} timeout={idleTimeout} onIdle={handleIdle} />
      {isIdle && (
        <LogoutConfirmationpopup isIdle={isIdle} logOut={logOut} clearTimeOut={clearTimeOut} />
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  userLoginData:{...state.userLoginDataReducer}
});
let conntedFunc = connect(
  mapStateToProps,
  null
);
export default compose(
  withRouter,
  conntedFunc
)(IdleTimerContainer);
