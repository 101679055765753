/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import i18n from 'i18next';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import moment from 'moment';
import { updateSyncFilter } from '../Shared/FiltersHelper';

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import MindMap from '../Analytics/MindMap';
import SynopsLoader from '../Shared/SynopsLoader';
import axiosInstance from '../Shared/interceptor';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import usePrevious from '../customHooks/usePrevious';
import { MindmapClick } from '../actions/mindMapAction';
import { store } from '../reducers/configureStore';

function ClientStoryMindMap(props) {
  const {
    CurrentFilter,
    languageData,
    userHasEditAccess,
    isInEditMode,
    doSaveBOIMindMap,
    onCallbackLoadStatusOfMindMap,
    onCallbackAfterSaveMindMap,
    onCallbackEnableDisableSaveMindMapButton,
  } = props;

  const clientId = _.get(CurrentFilter, 'ClientData.id', '');
  const languageId = _.get(languageData, 'id', '');

  // GET THE FILTER VALUES FROM THE REDUX STORE AS PROPS TO PRE-SELECT THE FILTERS
  const [offeringIdForPreSelection, setOfferingIdForPreSelection] = useState(_.get(CurrentFilter, 'SyncFilter.OfferingId', ''));
  const [subOfferingIdForPreSelection, setSubOfferingIdForPreSelection] = useState(_.get(CurrentFilter, 'SyncFilter.ProcessId', ''));
  const [locationIdForPreSelection, setLocationIdForPreSelection] = useState(_.get(CurrentFilter, 'SyncFilter.LocationId', ''));
  const [monthForPreSelection, setMonthForPreSelection] = useState(_.get(CurrentFilter, 'SyncFilter.Month', ''));

  const [isApiLoading, setApiLoading] = useState(false);
  const [offerings, setOfferings] = useState([]);
  const [selectedOffering, setSelectedOffering] = useState({});
  // PREVIOUS VALUE IS NEEDED TO CASCADE THE RESETTING OF THE DEPENDENT DROPDOWN
  const prevSelectedOffering = usePrevious(selectedOffering);
  const [subOfferings, setSubOfferings] = useState([]);
  const [selectedSubOffering, setSelectedSubOffering] = useState({});
  const prevSelectedSubOffering = usePrevious(selectedSubOffering);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const prevSelectedLocation = usePrevious(selectedLocation);
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const prevSelectedMonth = usePrevious(selectedMonth);
  const [metrics, setMetrics] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState({});
  const prevSelectedMetric = usePrevious(selectedMetric);
  const [searchMetricText, setSearchMetricText] = useState('');
  const [expandedOfferingsInTreeUIArray, setExpandedOfferingsInTreeUIArray] = useState([]);
  const [isMindMapLoaded, setMindMapLoaded] = useState(false);


  // useEffect(() => {
  //     if (!_.isNil(locationIdForPreSelection) && locationIdForPreSelection!=="") {
  //     fetchMonths();
  //   }
  // },[])



  useEffect(() => {
    if(CurrentFilter.SyncFilter.OfferingId !== offeringIdForPreSelection){
      setOfferingIdForPreSelection(CurrentFilter.SyncFilter.OfferingId) 
      // setMonths([])
      // setSelectedMonth('');
    }
    if(CurrentFilter.SyncFilter.ProcessId !== subOfferingIdForPreSelection){
      setSubOfferingIdForPreSelection(CurrentFilter.SyncFilter.ProcessId)
      // setMonths([])
      // setSelectedMonth('');
    }
    if(CurrentFilter.SyncFilter.LocationId !== locationIdForPreSelection){    
      setLocationIdForPreSelection(CurrentFilter.SyncFilter.LocationId);
      if (CurrentFilter.SyncFilter.LocationId !== "" && CurrentFilter.SyncFilter.LocationId !== 0){ 
        fetchMonths();
      }
    }
    
    if(CurrentFilter.SyncFilter.LocationId === "" || CurrentFilter.SyncFilter.LocationId === 0){
      setMonths([])
      setSelectedMonth('');
    }
  }, [CurrentFilter, languageData]);

  // useEffect(() => {
  //   let subOfferingsArray = [];
  //   if (!_.isEmpty(selectedOffering)) {
  //     subOfferingsArray = selectedOffering.ChildOfferings;
  //   }
  //   if (
  //     _.isEmpty(selectedOffering)
  //     || prevSelectedOffering.OfferingName !== selectedOffering.OfferingName
  //   ) {
  //     setSelectedSubOffering({});
  //     setExpandedOfferingsInTreeUIArray([]);
  //   }
  //   setSubOfferings(subOfferingsArray);
  // }, [selectedOffering]);

  // useEffect(() => {
  //   if (!_.isEmpty(selectedSubOffering)) {
  //     fetchLocations();
  //   }
  //   if (
  //     _.isEmpty(selectedSubOffering)
  //     || prevSelectedSubOffering.Process !== selectedSubOffering.Process
  //   ) {
  //     setLocations([]);
  //     setSelectedLocation('');
  //   }
  // }, [selectedSubOffering]);

  // useEffect(() => {
  //   if (!_.isEmpty(selectedLocation)) {
  //     fetchMonths();
  //   }
  //   if (
  //     _.isEmpty(selectedLocation)
  //     || prevSelectedLocation.City !== selectedLocation.City
  //   ) {
  //     setMonths([]);
  //     setSelectedMonth('');
  //   }
  // }, [selectedLocation]);

  useEffect(() => {
    if (!_.isEmpty(selectedMonth)) {
      fetchBusinessOutcomes();
    }
    if (
      _.isEmpty(selectedMonth)
      || prevSelectedMonth !== selectedMonth
    ) {
      setMetrics([]);
      setSelectedMetric({});
    }
  }, [selectedMonth]);

  useEffect(() => {
    setSearchMetricText('');
    if (
      _.isEmpty(selectedMetric)
      || prevSelectedMetric.MetricName !== selectedMetric.MetricName
    ) {
      setMindMapLoaded(false);
    }
  }, [selectedMetric]);

  useEffect(() => {
    if (
      !_.isNil(offeringIdForPreSelection)
      && !_.isNil(subOfferingIdForPreSelection)
      && !_.isNil(locationIdForPreSelection)
      && !_.isEmpty(selectedMonth)
      && !_.isEmpty(selectedMetric)
      && isMindMapLoaded
    ) {
      onCallbackLoadStatusOfMindMap(true);
    } else {
      onCallbackLoadStatusOfMindMap(false);
    }
  }, [
    offeringIdForPreSelection,
    subOfferingIdForPreSelection,
    selectedLocation,
    selectedMonth,
    selectedMetric,
    isMindMapLoaded,
  ]);

  function recursivelySetSelectedSubOffering(subOfferingsObj, level, names) {
    const subOfferingName = subOfferingsObj.Process;
    const subOfferingId = subOfferingsObj.ProcessID;
    const newNames = `${names},${subOfferingName}`;

    if (subOfferingId === _.toNumber(subOfferingIdForPreSelection)) {

      // SET THE SUB-OFFERING IN THE STATE
      setSelectedSubOffering(subOfferingsObj);
      //setSubOfferingIdForPreSelection('');
      // SET ALL THE PARENT OFFERINGS IN THE STATE SO THAT ALL THE RELEVANT PARENT OFFERINGS ARE EXPANDED IN THE SUB-OFFERING HIERARCHICAL DROPDOWN
      setExpandedOfferingsInTreeUIArray(
        _.filter(
          _.split(newNames, ','), (eachItem) => !_.isEmpty(eachItem),
        ),
      );
    }

    if (!subOfferingsObj.IsSubOffering) {

      const subLevelSubOfferings = subOfferingsObj.SubLevel;

      _.forEach(
        subLevelSubOfferings,
        (eachSubLevelSubOffering) => recursivelySetSelectedSubOffering(eachSubLevelSubOffering, level + 1, newNames),
      );
    }
  }

  function fetchOfferings() {
    setApiLoading(true);
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        params: {
          OrgEntityID: clientId,
          LanguageID: languageId,
          featureName: 'Dashboard',
          IsCalledForFlatJSONStructure: false,
          IsNew: false,
        },
      })
      .then((response) => {
        setApiLoading(false);
        if (response.data) {
          const offeringsData = response.data;
          setOfferings(offeringsData);

          if (!_.isNil(offeringIdForPreSelection)
            && !_.isEmpty(String(offeringIdForPreSelection))
          ) {
            const foundOfferingObj = _.find(
              offeringsData,
              { OfferingID: _.toNumber(offeringIdForPreSelection) },
            );
            if (!_.isEmpty(foundOfferingObj)) {
              setSelectedOffering(foundOfferingObj);
            }
          

            const subOfferingsArray = foundOfferingObj.ChildOfferings;
            const foundSubOffering = [];

            _.forEach(subOfferingsArray, (eachSubOffering) => {
              foundSubOffering.push(recursivelySetSelectedSubOffering(eachSubOffering, 0, ''));
            });

          }
        }
      })
      .catch((error) => {
        setApiLoading(false);
        trycatchAlertPopup(error);
      });
  }

  function fetchLocations() {
    setApiLoading(true);
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetLocation`, {
        params: {
          OrgEntityID: clientId,
          LanguageID: languageId,
          OfferingID: subOfferingIdForPreSelection,
        },
      })
      .then((response) => {
        setApiLoading(false);
        if (!_.isEmpty(response.data)) {
          const locationsData = response.data;
          setLocations(
            // ONLY DISPLAY THE LOCATIONS WHERE `IsNew = false`
            _.filter(locationsData, (eachLocation) => !eachLocation.IsNew),
          );

          if (!_.isEmpty(String(locationIdForPreSelection)) && _.toNumber(locationIdForPreSelection) !== 0) {
            const foundLocation = _.find(
              locationsData,
              (eachLocation) => (
                _.toNumber(eachLocation.LocationID) === _.toNumber(locationIdForPreSelection)
                  && !eachLocation.IsNew
              ),
            );
            if (!_.isEmpty(foundLocation)) {
              setSelectedLocation(foundLocation);
            }
            //setLocationIdForPreSelection('');
          }
        }
      })
      .catch((error) => {
        setApiLoading(false);
        trycatchAlertPopup(error);
      });
  }

  function fetchMonths() {
    setApiLoading(true);
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetMonth`, {
        params: {
          OrgEntityID: clientId,
          OfferingID: CurrentFilter.SyncFilter.ProcessId,
          LocationId: CurrentFilter.SyncFilter.LocationId,
        },
      })
      .then((response) => {
        setApiLoading(false);
        if (!_.isEmpty(response.data)) {
          const monthsArray = _.map(
            response.data,
            (eachItem) => moment(eachItem).format('MMMM-YYYY'),
          );
          setMonths(monthsArray);

          // if (!_.isEmpty(monthForPreSelection)) {
          //   if (_.indexOf(monthsArray, monthForPreSelection) !== -1) {
          //     setSelectedMonth(monthForPreSelection);
          //   }
          //   setMonthForPreSelection('');
          // }
        }
      })
      .catch((error) => {
        setApiLoading(false);
        trycatchAlertPopup(error);
      });      
  }

  function fetchBusinessOutcomes() {
    setApiLoading(true);
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetBusinessOutcomes`, {
        params: {
          OrgEntityID: clientId,
          OfferingID: CurrentFilter.SyncFilter.ProcessId,
          LocationID: CurrentFilter.SyncFilter.LocationId,
          MonthDT: selectedMonth,
          LanguageID: languageId,
        },
      })
      .then((response) => {
        setApiLoading(false);
        if (response.data) {
          let metricsArr = [];
          _.forEach(response.data, (eachMetricCategory) => {
            metricsArr = _.concat(metricsArr, eachMetricCategory.kpiData);
          });
          setMetrics(_.sortBy(metricsArr, ['MetricName']));
        }
      })
      .catch((error) => {
        setApiLoading(false);
        trycatchAlertPopup(error);
      });
  }

  function handleChangeOffering(offeringObj) {
    let syncFilter = {
      OfferingId: offeringObj.OfferingID,
      OfferingName: offeringObj.OfferingName_En,
      ProcessId: 0,
      LocationId: 0,
      Month: null,
      LocationOMID: [],
      isOMIDAvailable: null,
    };
    updateSyncFilter(syncFilter);
    setSelectedOffering(offeringObj);
  }

  function handleChangeSubOffering(subOfferingObj) {
    let SyncFilter = CurrentFilter.SyncFilter;
    SyncFilter.ProcessId = subOfferingObj.ProcessID;
    updateSyncFilter(SyncFilter);
    setSelectedSubOffering(subOfferingObj);
  }

  function handleChangeLocation(locationObj) {
    let SyncFilter = CurrentFilter.SyncFilter;
    SyncFilter.LocationId = locationObj.LocationID;
    updateSyncFilter(SyncFilter);

    setSelectedLocation(locationObj);
  }

  function handleChangeMonth(selectedMonthOption) {
    let SyncFilter = CurrentFilter.SyncFilter;
    SyncFilter.Month = selectedMonthOption;
    updateSyncFilter(SyncFilter);
    setSelectedMonth(selectedMonthOption);
  }

  function handleChangeMetric(metricObj) {
    setSelectedMetric(metricObj);
    store.dispatch(MindmapClick(true));
  }

  function handleClickTreeElement(selectedSubOfferingName) {
    let clonedArray = _.cloneDeep(expandedOfferingsInTreeUIArray);
    if (_.indexOf(expandedOfferingsInTreeUIArray, selectedSubOfferingName) !== -1) {
      clonedArray = _.filter(clonedArray, (eachItem) => eachItem !== selectedSubOfferingName);
    } else {
      clonedArray.push(selectedSubOfferingName);
    }

    setExpandedOfferingsInTreeUIArray(clonedArray);
  }

  function renderSubOfferings(subOfferingsObj, level) {
    let ret;
    const subOfferingName = subOfferingsObj.Process;

    if (!subOfferingsObj.IsSubOffering) {
      const subLevelSubOfferings = subOfferingsObj.SubLevel;
      const isSelected = _.indexOf(expandedOfferingsInTreeUIArray, subOfferingName) !== -1;
      ret = (
        <li className="nested-li">
          <span
            className={classNames(
              'caret',
              { 'caret-down': isSelected },
            )}
            onClick={() => handleClickTreeElement(subOfferingName)}
            onKeyDown={() => handleClickTreeElement(subOfferingName)}
            style={{ paddingLeft: 24 * level }}
            role="button"
            tabIndex="0"
          >
            {subOfferingName}
          </span>

          <ul className={classNames('nested-ul', { active: isSelected })}>
            {_.map(
              subLevelSubOfferings,
              (eachSubLevelSubOffering) => renderSubOfferings(eachSubLevelSubOffering, level + 1),
            )}
          </ul>
        </li>
      );
    } else {
      ret = (
        <li>
          <Dropdown.Item
            onClick={() => handleChangeSubOffering(subOfferingsObj)}
            style={{ paddingLeft: 24 * level }}
            className={classNames({ active: subOfferingName === selectedSubOffering.Process })}
          >
            {subOfferingName}
          </Dropdown.Item>
        </li>
      );
    }
    return ret;
  }

  if (_.isEmpty(String(clientId)) || _.isEmpty(String(languageId))) {
    return (
      <div className="default-msg">{i18n.t('Please select a client to continue')}</div>
    );
  }

  let metricDropdownOptions = metrics;
  if (!_.isEmpty(searchMetricText)) {
    metricDropdownOptions = _.filter(
      metrics,
      (eachMetric) => _.toLower(eachMetric.MetricName).indexOf(_.toLower(searchMetricText)) !== -1,
    );
  }

  return (
    <>
      {isApiLoading && (
        <LoadingOverlay
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active
        />
      )}
      <div className="purpleborder-dropdown offeringlist-dropdownslist clientstory-boi-map">        
        <DropdownButton
          menuAlign="left"
          id="Monthsbutton"
          title={!_.isEmpty(selectedMonth) ? selectedMonth : i18n.t('Select_Month')}
          className={classNames('mr-3', { selected: !_.isEmpty(selectedMonth) })}
          disabled={_.isEmpty(months)}
        >
          <div className="offering-droplistwrapper">
            {months.map((eachMonth) => (
              <Dropdown.Item
                href="javascript:void(0)"
                className={
                classNames({
                  active: eachMonth === selectedMonth,
                })
              }
                onClick={() => handleChangeMonth(eachMonth)}
                onKeyDown={() => handleChangeMonth(eachMonth)}
              >

                <span
                  className="form-check-label"
                  role="button"
                  tabIndex={0}
                >
                  {eachMonth}
                </span>
              </Dropdown.Item>
            ))}
          </div>
        </DropdownButton>
        {/* END - MONTH DROPDOWN */}

        {/* START - METRIC DROPDOWN */}
        <Dropdown
          onToggle={(isOpen) => {
            if (!isOpen) {
              setSearchMetricText('');
            }
          }}
          className={classNames(
            'mr-3',
            'metrics-clientstory',
            'metrictoggle-btn',
            { selected: !_.isEmpty(selectedMetric) },
            { disabled: _.isEmpty(metrics) },
          )}
        >
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <span className={
              classNames(
                'rag-status-color',
                { amberColor: _.toLower(selectedMetric.RAGStatus) === 'amber' },
                { greenColor: _.toLower(selectedMetric.RAGStatus) === 'green' },
                { redColor: _.toLower(selectedMetric.RAGStatus) === 'red' },
                { greyColor: _.toLower(selectedMetric.RAGStatus) === 'grey' || _.toLower(selectedMetric.RAGStatus) === 'gray' },
              )
              }
            >
              {!_.isEmpty(selectedMetric) ? selectedMetric.MetricName : i18n.t('Metrics')}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <div className="searchFilter mb-2">
              <input
                id="search"
                placeholder="Search ..."
                type="search"
                value={searchMetricText}
                onChange={(e) => setSearchMetricText(e.target.value)}
                autoComplete={false}
              />
            </div>

            <div className="offering-droplistwrapper metric-dropdown">
              {_.isEmpty(metricDropdownOptions) && (
              <p className="search-no-items text-center">No Results Found</p>
              )}

              {!_.isEmpty(metricDropdownOptions)
              && metricDropdownOptions.map((eachMetric) => {
                const metricRAGStatus = _.toLower(eachMetric.RAGStatus);
                return (
                  <Dropdown.Item
                    href="javascript:void(0)"
                    className={
                      classNames(
                        { 'amber-color': metricRAGStatus === 'amber' },
                        { 'green-color': metricRAGStatus === 'green' },
                        { 'red-color': metricRAGStatus === 'red' },
                        { 'gray-color': metricRAGStatus === 'grey' || metricRAGStatus === 'gray' },
                        {
                          active:
                          eachMetric.KPIMappingID === selectedMetric.KPIMappingID,
                        },
                      )
                    }
                    onClick={() => handleChangeMetric(eachMetric)}
                    onKeyDown={() => handleChangeMetric(eachMetric)}
                  >
                    <span
                      className="form-check-label"
                      role="button"
                      tabIndex={0}
                    >
                      {eachMetric.MetricName}
                    </span>
                  </Dropdown.Item>
                );
              })}
            </div>
          </Dropdown.Menu>
        </Dropdown>
          </div>

      {
        !_.isNil(CurrentFilter.SyncFilter.OfferingId)
        && !_.isNil(CurrentFilter.SyncFilter.ProcessId)
        && !_.isNil(CurrentFilter.SyncFilter.LocationId)
        && !_.isEmpty(selectedMonth)
        && !_.isEmpty(selectedMetric) && (
          <MindMap
            propsFromParentScreen={{
              screenName: 'clientStory',
              orgEntityId: clientId,
              offeringId: CurrentFilter.SyncFilter.ProcessId,
              locationId: CurrentFilter.SyncFilter.LocationId,
              month: selectedMonth,
              kpiMappingId: selectedMetric.KPIMappingID,
              kpiName: selectedMetric.MetricName,
              userHasEditAccess,
              isInEditMode,
              doSave: doSaveBOIMindMap,
              callbackWhenLoadComplete: (status) => setMindMapLoaded(status),
              onCallbackAfterSaveMindMap,
              onCallbackEnableDisableSaveMindMapButton,
            }}
          />
        )
      }
    </>
  );
}

ClientStoryMindMap.propTypes = {
  CurrentFilter: PropTypes.object.isRequired,
  languageData: PropTypes.object.isRequired,
  userHasEditAccess: PropTypes.bool.isRequired,
  isInEditMode: PropTypes.bool.isRequired,
  doSaveBOIMindMap: PropTypes.bool.isRequired,
  onCallbackLoadStatusOfMindMap: PropTypes.func.isRequired,
  onCallbackAfterSaveMindMap: PropTypes.func.isRequired,
  onCallbackEnableDisableSaveMindMapButton: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  CurrentFilter: state.CurrentFilter,
  languageData: state.CurrentFilter.languageData,
});

export default connect(mapStateToProps)(ClientStoryMindMap);
