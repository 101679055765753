import Moment from 'moment';
import _ from "lodash";
// import match from "autosuggest-highlight/match";
// import parse from "autosuggest-highlight/parse";
// import React from 'react';


export const getInitials = (nameString) => {
    const fullName = nameString.split('');
    const initials = fullName.length > 1 ? (fullName.shift().charAt(0) + fullName.pop().charAt(0)) : fullName.pop().charAt(0);
    return initials.toUpperCase();
}

export const getMonthAndTime = (value) => {
    let conv = value.replace("T", " ");
    let tempData = Moment.utc(conv)
        .local()
        .format("DD MMM YYYY");
    let tempTime = Moment.utc(conv)
        .local()
        .format("hh:mm a");
    if (tempTime.charAt(0) === '0'){
        tempTime = tempTime.substring(1);
    }
    let result = tempData + ", " + tempTime.toUpperCase();
    return result;

}

// export const filterByNames = (data, inputValue) => {
//     const re = new RegExp(_.escapeRegExp(inputValue), "i");
//     const clonedData = _.cloneDeep(data);
//     const results = clonedData.filter((object) => {
//         return (
//             object.comment.split(" ").filter((word) => {
//                 if (re.test(word)) {
//                     const matches = match(object.comment, inputValue);
//                     object["parts"] = parse(object.comment, matches);
//                     object.replies.filter((item) => 
//                     {
//                         if (re.test(item.comment)) {
//                             const matches = match(item.comment, inputValue);
//                             item["parts"] = parse(item.comment, matches);
//                             return true;
//                         } else {
//                             return false;
//                         }
//                     }).length > 0;
//                     return true;
//                 } else {
//                     object.replies.filter((item) => {
//                         if (re.test(item.comment)) {
//                             const matches = match(item.comment, inputValue);
//                             item["parts"] = parse(item.comment, matches);
//                             return true;
//                         } else {
//                             return false;
//                         }
//                     }).length > 0;
//                     return true;
//                 }
//             }).length > 0
//         );
//     });
//     return results;
// };
