import React, { Component } from 'react';
import { LocalApiBaseUrl } from '../Shared/Constant';
import { AgGridReact } from 'ag-grid-react';
import IojPopUp from './IojPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class IojList extends Component {
    constructor(props) {
        super(props)
        this.state = {

            rowSelected: [], viewmore: false,
            IOJOptions: [],
            IOJ: {
                OfferingId: null
            },

            columnDefs: [

                {
                    headerName: "Edit", suppressMovable: true,
                    width: 50,
                    cellRenderer: (params) => {
                        var link = document.createElement('span');
                        link.className = 'tbledit';
                        link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.dealpopup(e, params.data);

                        });

                        return link;
                    },
                    suppressFilter: true,
                },
                {
                    headerName: "Delete", suppressMovable: true,
                    width: 80,
                    cellRenderer: (params) => {
                        var link = document.createElement('span');
                        link.className = 'tbldel';
                        link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.deleteIOJ(e, params.data);
                        });
                        return link;
                    },
                    suppressFilter: true
                },




                { headerName: "Category name", field: "IntelOpsCategoryName", suppressMovable: true, sort: "asc", resizable: true },
                { headerName: "Stage Name", field: "IntelOpsStageName", resizable: true, suppressMovable: true, suppressSizeToFit: true },
                { headerName: "Offering", field: "Offering", resizable: true, suppressMovable: true, suppressSizeToFit: true },
                {
                    headerName: "Is Active", field: "IsActive", resizable: false, suppressMovable: true, suppressFilter: true, suppressSizeToFit: true,
                    cellRenderer: params => {
                        return `<label class="form-check">
                        <input class="form-check-input" type="checkbox" disabled ${params.value ? 'checked' : ''}>
                        <span class="checkmark"></span>
                         </label>`;
                    }/* , cellStyle: function (params) {
                        return { 'text-align': "left" };
                    } */
                },

            ],
            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'multiple',
                defaultColDef: {
                    sortable: true
                },

            }

        }
        this.onClose = this.onClose.bind(this);
        this.onDEIDealChange = this.onDEIDealChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }


    deleteIOJ(e, data) {


        if (this.props.featureData.filter((data) => data.Submenu == "IOJ Category" && data.Operations.includes("Delete")).length > 0) {

            if (window.confirm('Are you sure you want to delete the item?')) {
                axiosInstance.delete(`${LocalApiBaseUrl}IntelOpsCategory/IntelOpsDeleteCategory`, {
                    params: {
                        IntelOpsCategoryId: data.IntelOpsCategoryId
                    }
                }).then((response) => {
                    window.alert(response.data.StatusMessage)
                    this.getIOJ();

                })
                    .catch((error) => {
                        trycatchAlertPopup(error);
                    });



            }
        }
        else window.alert("Access Denied !!")

    }

    dealpopup(e, data) {

        // e.currentTarget.className = "active"
        e.preventDefault();

        if (this.state.IOJ.OfferingId > 0) {

            if (data != undefined) {
                // edit condition
                if (this.props.featureData.filter((data) => data.Submenu == "IOJ Category" && data.Operations.includes("Edit")).length > 0) {
                    e.preventDefault();
                    this.setState({ viewmore: true, rowSelected: data })
                }
                else window.alert("Access Denied !!")

            }
            else {
                // add condition
                if (this.props.featureData.filter((data) => data.Submenu == "IOJ Category" && data.Operations.includes("Add")).length > 0) {

                    e.preventDefault();
                    this.setState({ viewmore: true, rowSelected: data })
                }
                else window.alert("Access Denied !!")


            }

        }

    }
    onClose(e) {

        this.setState({ viewmore: false });
    }


    onDEIDealChange(event) {

        this.setState({ errors: {} });
        const IOJ = this.state.IOJ;
        IOJ.OfferingId = event.target.value;
        this.setState({
            IOJ: IOJ
        })
        this.getIOJ()
    }

    GetDEIDeal() {

        let samp = {};
        var EnterpriseID = sessionStorage.getItem("EnterpriseID")
        if (EnterpriseID != undefined) {
            axiosInstance.get(`${LocalApiBaseUrl}Offering/GetOfferingsList`, {
                params: {
                    FeatureName: "IOJ Category",
                    IsCalledForFlatJSONStructure: true
                }
            })

                .then((response) => {
                    let IOJOptions = []
                    response.data.map((row) => {
                        row.ChildOfferings.map((SubRow) =>
                            IOJOptions.push(SubRow)
                        )
                    })
                    let IojCategoryData = IOJOptions.filter((iojcategory) => iojcategory.IsIntelOpsCategory == true);
                    this.setState({ IOJOptions: IojCategoryData });
                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                });



        }
    }

    getIOJ() {

        axiosInstance.get(`${LocalApiBaseUrl}IntelOpsCategory/GetIntelOpsCategory`, {

            params: { OfferingID: this.state.IOJ.OfferingId }
        })
            .then((response) => {
                this.setState({
                    rowData: response.data.sort((a, b) => (a.UserName > b.UserName) ? 1 : -1)
                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });



    }

    componentDidMount() {

        this.GetDEIDeal();
        this.getIOJ();
        let columnDefs = this.state.columnDefs;

        if (this.props.featureData.filter((data) => data.Submenu == "IOJ Category" && data.Operations.includes("Edit")).length == 0) {
            columnDefs = columnDefs.filter((data) => data.headerName != "Edit");
            this.setState({
                columnDefs: columnDefs
            })

        }


        if (this.props.featureData.filter((data) => data.Submenu == "IOJ Category" && data.Operations.includes("Delete")).length == 0) {
            columnDefs = columnDefs.filter((data) => data.headerName != "Delete");
            this.setState({
                columnDefs: columnDefs
            })

        }


    }

    componentWillMount() {

        this.getIOJ();
    }

    test() {

        let x = this.state

    }

    handleSave(data) {

        axiosInstance.post(`${LocalApiBaseUrl}IntelOpsCategory/AddEditIntelOpsCategory`, data)
            .then((response) => {
                window.alert(response.data.StatusMessage)
                this.getIOJ();
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });

        this.getIOJ();

    }

    render() {

        return (
            <div className="main-data">

                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <select id="OperatingGroup" className="form-control" disabled={this.props.data} ref="OperatingGroupDropDown" onChange={this.onDEIDealChange.bind(this)} value={this.state.IOJ.OfferingId}>
                                    <option value="0">Select Offering</option>
                                    {this.state.IOJOptions.map((row) => {
                                        return <option value={row.OfferingID}>{row.ParentOfferingName + "  -  " + row.Process}</option>;
                                    })}
                                </select>
                            </div>
                            {this.state.IOJ.OfferingId > 0 &&
                                <div className="form-group col-lg-3">
                                    <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                                </div>
                            }
                            {this.props.featureData.filter((data) => data.Submenu == "IOJ Category" && data.Operations.includes("Add")).length > 0 &&
                                <div className="form-group col-lg-3">
                                    <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.dealpopup(e) }}><span><FontAwesomeIcon icon={faPlusCircle} /></span> Add IOJ</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        {this.state.IOJ.OfferingId > 0 &&
                            <div className="ag-theme-balham ag-table">
                                <AgGridReact
                                    enableSorting={true}
                                    enableFilter={true}
                                    pagination={true}
                                    paginationPageSize={50}
                                    floatingFilter={true}

                                    gridOptions={this.state.gridOptions}
                                    columnDefs={this.state.columnDefs}
                                    rowData={this.state.rowData}
                                    onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                                </AgGridReact>
                            </div>}
                    </div>
                </div>
                {this.state.viewmore ? <IojPopUp data={this.state.rowSelected} OfferingId={this.state.IOJ.OfferingId} show={this.state.viewmore} onClose={this.onClose} filterDetails={this.props.filterDetails} onSave={this.handleSave} /> : null}

            </div>
        )
    }
}