import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { AgGridReact } from "ag-grid-react";
import OneTimeSetupApprovalPopup from "./OneTimeSetupApprovalPopup";
import axiosInstance from "../Shared/interceptor";
import _ from "lodash";
import { trycatchAlertPopup } from "../Shared/Constant";

import OneTimeSetupViewPopup from "./OneTimeSetupViewPopup";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class OneTimeSetupApprovalList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SelectAll: null,
      Approval: {
        ID: [],
        ApprovalType: null,
        ApprovalAction: false,
      },

      CanApprove: false,
      CanReject: false,
      action: "",
      rowSelected: [],
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      viewpopupStatus: false,
      viewData: {},
      prevLength: "",
      flag: false,
      columnDefs: [
        {
          headerName: "",
          resizable: true,
          suppressFilter: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          checkboxSelection: (params) => String(params.data.Status) === "Pending Approval" && !["Bat", "PMM", "SVM"].includes(params.data.ConnectorName),
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 100,
        },
        {
          headerName: "View",
          suppressMovable: true,
          width: 80,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tblview";
            link.innerHTML = '<i class="fas fa-eye"></i>';
            link.id = "viewOneTimeSetUp";
            link.addEventListener("click", (e) => {
              this.viewpopup(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Source System",
          field: "ConnectorName",
          colId: "SourceSystem",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Client",
          colId: "offering",
          field: "DestinationClientName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Offering",
          colId: "suboffering",
          field: "DestinationOfferingName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Sub-Offering",
          colId: "sub-offering",
          field: "DestinationSubOfferingName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Location",
          colId: "sub-offering",
          field: "DestinationLocationName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Client Region/Market Unit",
          colId: "clientRegion",
          field: "ClientRegion",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },

        {
          headerName: " Requested By",
          colId: "requestedby",
          field: "RequestedBy",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Status",
          colId: "status",
          field: "Status",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: " Approver Remarks",
          colId: "approverremarks",
          field: "Remarks",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
      ],
      autoGroupColumnDef: {
        headerName: "hello",
        field: "DealName",
        width: 200,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },

      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",

        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.decline = this.decline.bind(this);
    this.onBtnExport = this.onBtnExport.bind(this);
  }
  viewpopup = (e, data) => {
    if (!_.isNil(data)) {
      let viewData = data;

      this.setState({ viewpopupStatus: true, viewData });
    }
  };

  handleViewPopupClose = () => {
    this.setState({
      viewpopupStatus: false,
    });
  };

  onRowSelected(event) {
    let { rowSelected } = this.state;
    if (String(event.data.Status) === "Pending Approval") {
      if (event.node.selected) {
        rowSelected.push(event.data);
        this.setState({ rowSelected: rowSelected });
      } else {
        rowSelected = rowSelected.filter((row) => row.ID !== event.data.ID);
        this.setState({ rowSelected: rowSelected });
      }
    }
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  dealpopup(e) {
    const { featureData } = this.props;
    if (
      featureData.filter(
        (data) => String(data.Submenu) === "OneTimeSetup Approval" && data.Operations.includes("Approve")
      ).length > 0
    ) {
      e.preventDefault();
      this.setState({ viewmore: true, action: "Approve" });
    } else window.alert("Access Denied !!");
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  getApproval() {
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetOneTimeSetupForApproval`, {
        params: {},
      })
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    const { featureData } = this.props;
    if (
      featureData.filter(
        (data) => String(data.Submenu) === "OneTimeSetup Approval" && data.Operations.includes("Reject")
      ).length > 0
    ) {
      this.setState({ CanReject: true });
    }
    if (
      featureData.filter(
        (data) => String(data.Submenu) === "OneTimeSetup Approval" && data.Operations.includes("Approve")
      ).length > 0
    ) {
      this.setState({ CanApprove: true });
    }

    this.getApproval();
    this.setState({
      rowSelected: [],
    });
  }

  componentWillMount() {
    const { featureData } = this.props;
    this.setState({
      rowSelected: [],
    });
    if (
      featureData.filter(
        (data) =>
          String(data.Submenu) === "OneTimeSetup Approval" &&
          (data.Operations.includes("Reject") || data.Operations.includes("Approve"))
      ).length === 0
    ) {
      let data = this.state;
      data.columnDefs[0].checkboxSelection = false;
      this.setState({
        columnDefs: data.columnDefs,
      });
    }

    this.getApproval();
  }

  decline(e) {
    const { featureData } = this.props;
    let { rowSelected } = this.state;
    if (
      featureData.filter(
        (data) => String(data.Submenu) === "OneTimeSetup Approval" && data.Operations.includes("Reject")
      ).length > 0
    ) {
      if (rowSelected.length > 1) {
        window.alert("User is advised to select one row at a time");
      } else {
        e.preventDefault();
        this.setState({ viewmore: true, action: "Reject" });
      }
    } else window.alert("Access Denied !!");
  }
  handleSave(data) {

    axiosInstance
      .post(`${LocalApiBaseUrl}ConnectorMapping/PostOneTimeSetupApproval`, data)
      .then((response) => {
        window.alert(response.data.StatusMessage);
        this.getApproval();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
    this.setState({
      rowSelected: [],
    });
  }
  onBtnExport() {
    let params = {
      columnKeys: [
        "SOPageName",
        "ClientName",
        "Location",
        "offering",
        "suboffering",
        "categoryname",
        "metricname",
        "description",
        "unit",
        "requestedby",
        "requesteddate",
        "approvedate",
        "status",
        "requestorremarks",
        "approverremarks",
      ],
      columnGroups: true,
      allColumns: false,
      skipHeader: false,
      fileName: "Metric",
    };
    this.state.gridOptions.api.exportDataAsCsv(params);
  }
  onGridReady = (params) => {
    this.gridApi = params.api;

    this.gridColumnApi = params.columnApi;
  };
  render() {
    const { featureData, filterDetails } = this.props;
    const {
      autoGroupColumnDef,
      gridOptions,
      columnDefs,
      rowData,
      viewmore,
      rowSelected,
      CanApprove,
      CanReject,
      viewpopupStatus,
      viewData,
      action,
    } = this.state;
    return (
      <>
        <div className="main-data">
          <div className="tableFilter">
            <div className="form-section">
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    id="filter-text-box"
                    placeholder="Filter..."
                    onChange={(e) => {
                      this.onFilterTextBoxChanged(e);
                    }}
                  />
                </div>
                <div className="ml-auto mr-1">
                  {featureData.filter(
                    (data) => String(data.Submenu) === "OneTimeSetup Approval" && data.Operations.includes("Download")
                  ).length > 0 ? (
                    <button class="setup-Btn">
                      <i class="fa fa-download"></i>Download
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ag-theme-balham ag-table">
                <AgGridReact
                  enableSorting={true}
                  enableFilter={true}
                  pagination={true}
                  paginationPageSize={50}
                  floatingFilter={true}
                  autoGroupColumnDef={autoGroupColumnDef}
                  onRowSelected={this.onRowSelected.bind(this)}
                  gridOptions={gridOptions}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  onGridReady={this.onGridReady}
                  onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                ></AgGridReact>
              </div>
            </div>
          </div>
          {viewmore ? (
            <OneTimeSetupApprovalPopup
              action={action}
              data={rowSelected}
              show={viewmore}
              onClose={this.onClose}
              onSave={this.handleSave}
            />
          ) : null}

          <div className="text-center mt-3 mb-2">
            {rowSelected.length > 0 && CanApprove ? (
              <button
                className="btn btn-primary btn-sm mx-2"
                onClick={(e) => {
                  this.dealpopup(e);
                }}
              >
                Approve
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm mx-2"
                disabled={true}
                onClick={(e) => {
                  this.dealpopup(e);
                }}
              >
                Approve
              </button>
            )}
            {rowSelected.length > 0 && CanReject ? (
              <button
                className="btn btn-primary btn-sm mx-2"
                onClick={(e) => {
                  this.decline(e);
                }}
              >
                Decline
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm mx-2"
                disabled={true}
                onClick={(e) => {
                  this.decline(e);
                }}
              >
                Decline
              </button>
            )}
          </div>
        </div>
        {viewpopupStatus ? (
          <OneTimeSetupViewPopup
            data={viewData}
            OfferingId={5}
            show={viewpopupStatus}
            close={this.handleViewPopupClose}
            dataoperation={true}
            filterDetails={filterDetails}
          />
        ) : null}
      </>
    );
  }
}
