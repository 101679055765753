import React, { Component } from 'react';
import _ from 'lodash';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus, faEdit, faTrashAlt, faInfoCircle, faDoorClosed } from "@fortawesome/free-solid-svg-icons";
import { Modal } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import i18n from 'i18next';
import SynopsLoader from '../Shared/SynopsLoader';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';

class BulkShareAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPermission: '',
      shareAssessmentModalClose: false,
      isPermissionDropdownDisabled: false,
      AccessLevelID: [],
      permissionData: [],
      OwnerUserName: this.props.ownerUserName,
      isClientDropdown: false,
      isAPILoading: false,
      list: this.props.list,
      allChecked: false,
      searchTerm: '',
      isLimitExceed: false,
      showNotification: false,
      filteredUserList: [],
      show: true,
      selectedUserList: [],
      duplicateUserNames: [],
      errMsg: '',
      subOfferingList: [],
      isPermissionDropdownSearchable: false,
      isClientDropdownSearchable: false,
      checkedValue: [],
      isUserListAPILoading: false,
      isSubOfferingDisabled: true,
    };

    // DEBOUNCED FUNCTION FOR THE API CALL TO IMPROVE PERFORMANCE
    this.debouncedFetchUsers = _.debounce(this.fetchUsers, 1000);
  }

  componentDidMount() {
    this.getPermission();
    this.userAccessFromProps();
    this.setState({
      isPermissionDropdownDisabled: true,
    });
  }

  openClientDropdown = (e) => {
    const target = !this.state.isClientDropdown;
    this.setState({
      isClientDropdown: target,
    });
  };

  onOpenSearchableDropdown = (e) => {
    const element = e.target || e.srcElement;
    switch (element.id) {
      case 'clientDropdown':
        this.setState({
          isClientDropdownSearchable: !this.state.isClientDropdownSearchable,
          isPermissionDropdownSearchable: false,
        });
        break;
      case 'permissionDropdown':
        this.setState({
          isPermissionDropdownSearchable: !this.state.isPermissionDropdownSearchable,
          isClientDropdownSearchable: false,
          isClientDropdown: false,
        });
        break;
    }
  };

  closeCustomDropdown = () => {
    // this.state.isClientDropdownSearchable = true;
    this.setState({
      isClientDropdownSearchable: false,
      isClientDropdown: false,
      isPermissionDropdownSearchable: false,
    });
  };

  onDeleteClient(userlist, user) {
    const newlist = userlist.filter((item) => item !== user);
    this.setState({
      selectedUserList: newlist,
      errMsg: '',
      isLimitExceed: false,
    });
  }

  userAccessFromProps = () => {
    const { mode, actionToBePerformed } = this.props;
    const subOfferingListPropData = [];
    const selectedUserListPropData = [];
    const lisDataFromProps = _.get(this.props, 'list', []);
    const listData = _.map(lisDataFromProps, (Data) => {
      subOfferingListPropData.push({
        ...Data,
        isChecked: !!(mode !== 'share' && actionToBePerformed === 'approve'),
      });
    });
    const userAccessPairFromProps = _.get(this.props, 'sharedUsers', []);
    if (!_.isEmpty(userAccessPairFromProps)) {
      const userAccessPair = _.map(userAccessPairFromProps, (eachItem) => {
        selectedUserListPropData.push(eachItem.UserName);
      });
      this.setState({
        selectedUserList: selectedUserListPropData,
        selectedPermission: userAccessPairFromProps[0].AccessLevelName,
        subOfferingList: subOfferingListPropData,
        checkedValue: subOfferingListPropData,
        AccessLevelID: userAccessPairFromProps[0].AccessLevelID,
      });
    } else {
      this.setState({
        subOfferingList: subOfferingListPropData,
      });
    }
  };

  onMultipleClientChange = (ClientObj) => {
    if (this.state.selectedUserList.length < 10) {
      if (!this.state.selectedUserList.includes(ClientObj.target.textContent)) {
        this.setState({
          selectedUserList: [...this.state.selectedUserList, ClientObj.target.textContent],
          isClientDropdownSearchable: false,
          isClientDropdown: false,
          searchTerm: '',
          isPermissionDropdownSearchable: false,
          isLimitExceed: false,
          errMsg: '',
          filteredUserList: [],
          isPermissionDropdownDisabled: false,
        });
      } else {
        this.setState({
          errMsg: 'Duplicate User Name',
        });
      }
    } else {
      this.setState({
        isLimitExceed: true,
      });
    }
  };

  getPermission = () => {
    // this.setState({
    //   isAPILoading: true,
    // });
    axiosInstance
      .get(`${LocalApiBaseUrl}BATAssessment/GetAccessLevels`, {
        params: {},
      })
      .then((response) => {
        if (response.data) {
          this.setState({
            permissionData: response.data,
          });
          this.filteredAccessPermissionList(response.data);
        }
        // this.setState({
        //   isAPILoading: false,
        // });
      })
      .catch((error) => {
        // this.setState({
        //   isAPILoading: false,
        // });

        trycatchAlertPopup(error);
      });
  };

  filteredAccessPermissionList = (PermissionList) => {
    {
      const { tabName, actionToBePerformed, mode } = this.props;
      let isPermissionDropdownDisabled = false;
      let filteredPermissionList = [...PermissionList];

      if (actionToBePerformed === 'request' && mode === 'reshare') {
        filteredPermissionList = PermissionList.filter((data) => data.AccessLevelID === 1);
        isPermissionDropdownDisabled = true;
        this.setState({
          isPermissionDropdownDisabled,
          permissionData: filteredPermissionList,
          AccessLevelID: filteredPermissionList[0].AccessLevelID,
          selectedPermission: filteredPermissionList[0].AccessLevelName,
          isSubOfferingDisabled: false,
        });
      }
      if (mode === 'share') {
        filteredPermissionList = PermissionList.filter((data) => data.AccessLevelID === 1 || data.AccessLevelID === 2);
        this.setState({
          permissionData: filteredPermissionList,
        });
      }
    }
  };

  onPermissionChange(ClientObj) {
    const selectedId = this.state.permissionData.filter((e) => e.AccessLevelName === ClientObj.target.textContent)[0]
      .AccessLevelID;
    this.setState({
      selectedPermission: ClientObj.target.textContent,
      AccessLevelID: selectedId,
      isPermissionDropdownSearchable: false,
      isClientDropdownSearchable: false,
      errMsg: '',
      isSubOfferingDisabled: false,
    });
  }

  fetchUsers = (searchTerm) => {
    const { OwnerUserName } = this.state;

    if (searchTerm.length >= 3) {
      this.setState({ isUserListAPILoading: true });
      axiosInstance
        .get(`${LocalApiBaseUrl}BATAssessment/GetAllActiveUsers?searchStringEID=${searchTerm}`)
        .then((response) => {
          const responseData = response.data;

          const filteredUserList = [];
          if (!_.isEmpty(responseData)) {
            const filteredResponse = responseData.filter((res) => res.UserEnterpiseID !== OwnerUserName);
            const sortedObjList = filteredResponse.sort((a, b) => (a.UserEnterpiseID > b.UserEnterpiseID ? 1 : -1));

            sortedObjList.forEach((each) => {
              filteredUserList.push({ UserName: each.UserEnterpiseID });
            });
          }

          this.setState({
            isUserListAPILoading: false,
            filteredUserList,
          });
        })
        .catch((error) => {
          this.setState({
            isUserListAPILoading: false,
            filteredUserList: [],
          });

          trycatchAlertPopup(error);
        });
    } else if (searchTerm.length === 0) {
      this.setState({ filteredUserList: [] });
    }
  };

  // EVENT HANDLER FOR "SEARCH USER" INPUT FIELD
  handleChangeSearchUser = (event) => {
    const searchTerm = event.target.value;
    this.debouncedFetchUsers(searchTerm);
    this.setState({ searchTerm });
  };

  handleChange = (e) => {
    const itemName = e.target.name;
    const { checked } = e.target;
    this.setState((prevState) => {
      let { list, allChecked, subOfferingList } = prevState;
      if (itemName === 'checkAll') {
        allChecked = checked;
        subOfferingList = subOfferingList.map((item) => ({ ...item, isChecked: checked }));
      } else {
        subOfferingList = subOfferingList.map((item) => (item.name === itemName ? { ...item, isChecked: checked } : item));
        allChecked = subOfferingList.every((item) => item.isChecked);
      }
      return {
        list, allChecked, subOfferingList, errMsg: '',
      };
    });
  };

  bulkSharedNoticeModalShow = () => {
    this.setState({
      isAPILoading: true,
    });
    const {
      subOfferingList, selectedUserList, OwnerUserName, AccessLevelID,
    } = this.state;
    const { actionToBePerformed, sharing } = this.props;

    const bulkShareData = [];
    selectedUserList.forEach((userListData) => {
      subOfferingList.forEach((subOffering) => {
        if (subOffering.isChecked === true) {
          bulkShareData.push({
            BATAssessSubOfferingID: subOffering.id,
            UserName: userListData,
            AccessLevelID,
          });
        }
      });
    });
    if (sharing === 'share') {
      axiosInstance
        .post(`${LocalApiBaseUrl}BATAssessment/AddEditShareBATAssessments`, bulkShareData)
        .then((response) => {
          this.setState({
            bulkSharedNotice: true,
            isAPILoading: false,
            show: false,
          });
        })
        .catch((error) => {
          this.setState({
            isAPILoading: false,
          });
        });
    } else {
      const data = {};
      if (actionToBePerformed === 'request') {
        data.Status = 'RequestAccess';
      } else {
        data.Status = 'ApproveAccess';
      }
      data.RequestedUsers = bulkShareData;
      axiosInstance
        .post(`${LocalApiBaseUrl}BATAssessment/AddEditReShareBATAssessments`, data)
        .then((response) => {
          this.setState({
            bulkSharedNotice: true,
            show: false,
            isAPILoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            isAPILoading: false,
          });
          trycatchAlertPopup(error);
        });
    }
  };

  validateModel = () => {
    this.setState({
      isLimitExceed: false,
    });
    const subOfferingCheckedData = [];
    const subOffData = _.map(this.state.subOfferingList, (listData) => {
      if (listData.isChecked == true) subOfferingCheckedData.push(listData.name);
    });

    if (_.isEmpty(this.state.selectedUserList)) {
      this.setState({
        errMsg: 'User is Required',
      });
    } else if (this.state.selectedPermission == '') {
      this.setState({
        errMsg: 'Permission is Required',
      });
    } else if (subOfferingCheckedData.length < 1) {
      this.setState({
        errMsg: 'Suboffering is Required',
      });
    } else {
      this.setState({
        errMsg: '',
      });
      this.bulkSharedNoticeModalShow();
    }
  };

  renderList = () => {
    const { subOfferingList } = this.state;
    const { actionToBePerformed, mode } = this.props;
    if (subOfferingList.length > 0) {
      return subOfferingList.map((item) => (
        <li className={`${item.isChecked ? 'labelselected' : ' '} `}>
          <label className="form-check">
            <span className="form-check-label">{item.name}</span>
            {!this.state.isSubOfferingDisabled
            && (
            <input
              key={item.id}
              type="checkbox"
              disabled={mode === 'reshare' && actionToBePerformed === 'approve'}
              className="form-check-input"
              name={item.name}
              value={item.name}
              checked={item.isChecked}
              onChange={this.handleChange}
            />
            )}
            <span className="checkmark" />
          </label>
        </li>
      ));
    }
  };

  render() {
    const {
      filteredUserList,
      permissionData,
      isPermissionDropdownDisabled,
      isAPILoading,
      isLimitExceed,
      searchTerm,
      isClientDropdown,
      isPermissionDropdownSearchable,
      selectedUserList,
      // allChecked,
      errMsg,
      // list,
      OwnerUserName,
      bulkSharedNotice,
      selectedPermission,
      isClientDropdownSearchable,
      subOfferingList,
      isUserListAPILoading,
      isSubOfferingDisabled,
    } = this.state;

    const {
      onBulkShareModalSuccClose, onBulkShareModalCancelClose, sharing, actionToBePerformed, mode, clientDetails,
    } = this.props;

    const subOfferingData = [];
    const subOfferings = _.map(subOfferingList, (listData) => {
      if (listData.isChecked === true) {
        subOfferingData.push(listData.name);
      }
    });
    const userDataLength = selectedUserList.length;

    const permissionList = (
      <>
        <ul>
          {permissionData.map((data) => (
            <li id={data.AccessLevelID} onClick={(e) => this.onPermissionChange(e)}>
              {data.AccessLevelName}
            </li>
          ))}
        </ul>
      </>
    );

    const count = subOfferingData.length - 3;
    let displaySubOfferingName = [];
    if (count >= 1) {
      for (let i = 0; i < 3; i += 1) {
        displaySubOfferingName.push(subOfferingData[i]);
      }
    } else {
      displaySubOfferingName = subOfferingData;
    }

    return (
      <>
        {/* Single Share Assessment Modal */}
        {isAPILoading && <LoadingOverlay spinner={<SynopsLoader />} active />}

        <Modal
          show={this.props.show} // left
          onHide={onBulkShareModalCancelClose}
          className="modal fade shareassessment-modal"
        >
          <Modal.Body>
            <div className="modalheader-title">
              <h4>Share Assesment</h4>
              <span className="incentive-count">
                {subOfferingList.length}
                {' '}
                Initiatives
              </span>
              {isLimitExceed && <span className="requiredNotice">* Maximum 10 users can be added</span>}
            </div>
            {/* {isLimitExceed && <span className="errorMsg">Limit Exceed than 10 </span>} */}

            <div className="form-section">
              <div className="assessmentinfo-fields">
                <div className="people-assessmentinfo">
                  <div className="searchBox-Container">
                    <div className="search-select-list">
                      <div
                        className="searchFilter"
                        disabled={mode === 'reshare' && actionToBePerformed === 'approve'}
                        onClick={
                          mode === 'reshare' && actionToBePerformed === 'approve' ? () => {} : this.openClientDropdown
                        }
                      >
                        <input
                          type="search"
                          disabled={mode === 'reshare' && actionToBePerformed === 'approve'}
                          value={searchTerm}
                          onChange={
                            mode === 'reshare' && actionToBePerformed === 'approve'
                              ? () => {}
                              : this.handleChangeSearchUser
                          }
                          placeholder="Search user to add…"
                        />
                      </div>

                      {isClientDropdown && (isUserListAPILoading || !_.isEmpty(filteredUserList)) && (
                        <div className="search-list-container">
                          <ul>
                            {isUserListAPILoading && <li className="disabled">Loading User List...</li>}

                            {!isUserListAPILoading
                              && filteredUserList.map((data) => (
                                <li key={data.UserID} onClick={(e) => this.onMultipleClientChange(e)}>
                                  {data.UserName}
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="permission-assessmentinfo">
                  <div className="dropdown-box">
                    <div
                      className="custom-dropdown-search"
                      onClick={!isPermissionDropdownDisabled ? this.onOpenSearchableDropdown : null}
                    >
                      <div className="custom-dropdownname" id="permissionDropdown">
                        {selectedPermission ? <span className="selectedItem">{selectedPermission}</span> : 'Permission'}
                      </div>

                      {isPermissionDropdownSearchable && (
                        <div className="search-select-list" disabled>
                          {permissionList}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="seletedUser-container">
                {!_.isEmpty(selectedUserList) // <span className="selectedItem">{selectedUser}</span>
                  && selectedUserList.map((user) => (
                    <span className="multiple-selectedListItem disabled" id={user}>
                      <span className="item-ellipse">{user}</span>
                      {mode === 'reshare' && actionToBePerformed === 'approve' ? (
                        <i className="fa fa-times" aria-hidden="true" disabled />
                      ) : (
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          onClick={() => this.onDeleteClient(selectedUserList, user)}
                        />
                      )}
                    </span>
                  ))}
              </div>

              <div className="assessment-components">
                <div className="assessment-selectall">
                  <label className="form-check">
                    <span className="form-check-label">Select All</span>
                    {!isSubOfferingDisabled
                    && (
                    <input
                      type="checkbox"
                      name="checkAll"
                      disabled={mode === 'reshare' && actionToBePerformed === 'approve'}
                      className="form-check-input"
                      checked={subOfferingList.filter((each) => each.isChecked === false).length === 0}
                      onChange={this.handleChange}
                    />
                    )}
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="shareassessments-list">{this.renderList()}</div>
              </div>
            </div>
            <div className="errorMsg">{errMsg}</div>
          </Modal.Body>

          <Modal.Footer className="assessmentcount-footer">
            <div className="selected-assessmentcount">
              <span>
                {subOfferingList.filter((each) => each.isChecked === true).length}
                {' '}
                Assessments Selected
                {' '}
              </span>
            </div>
            <div className="modal-btn">
              <button onClick={onBulkShareModalCancelClose} className="bat-cancel btn-default mr-2">
                Cancel
              </button>
              <button className="batassessment-btn-purplebg" onClick={this.validateModel}>
                {sharing === 'reshare' ? 'RequestAccess' : 'Share'}
              </button>
            </div>
          </Modal.Footer>
          {isClientDropdown || isClientDropdownSearchable || isPermissionDropdownSearchable ? (
            <div className="custom-dropdown-search-overlay" onClick={this.closeCustomDropdown} />
          ) : null}
        </Modal>

        <Modal
          show={bulkSharedNotice}
          // onHide={() => onBulkShareModalSuccClose()}
          className="modal fade bulkSharedNotice-modal"
        >
          {sharing === 'reshare' ? (
            <Modal.Header onHide={() => onBulkShareModalSuccClose()} closeButton className="bulkSharedNotice-modalHeader">
              <div className="bulkSharedNotice-title">
                <i className="fa fa-check-circle" aria-hidden="true" />
                <h4>Request Sent</h4>
              </div>
              {count >= 1
                ? (
                  <div className="desc-line">
                    {!_.isEmpty(clientDetails.BATClientName) && `${clientDetails.BATClientName}:`}
                    {' '}
                    {`Access Request raised successfully for ${displaySubOfferingName} (+${count}) to ${OwnerUserName} for ${userDataLength} users with ${selectedPermission} Permission`}
                  </div>
                )
                : (
                  <div className="desc-line">
                    {!_.isEmpty(clientDetails.BATClientName) && `${clientDetails.BATClientName}:`}
                    {' '}
                    {`Access Request raised successfully for ${subOfferingData} to ${OwnerUserName} for ${userDataLength} users with ${selectedPermission} Permission`}
                  </div>
                )}

            </Modal.Header>
          ) : (
            <Modal.Header onHide={() => onBulkShareModalSuccClose()} closeButton className="bulkSharedNotice-modalHeader">
              <div className="bulkSharedNotice-title">
                <i className="fa fa-check-circle" aria-hidden="true" />
                <h4>Shared Successfully</h4>
              </div>
              <div className="desc-line">
                {!_.isEmpty(clientDetails.BATClientName) && `${clientDetails.BATClientName}:`}
                {' '}
                {`${subOfferingData} is successfully shared for ${userDataLength} users with ${selectedPermission} Permission`}
              </div>
            </Modal.Header>
          )}

          <Modal.Body>
            {/* <div className="modal-userlist">
              <div className="table-heading">
                <div className="list-username-title titleTag">Users</div>
                <div className="list-permission-title titleTag">Permission</div>
              </div> */}
            {/* <div className="table-body">
              {_.map(selectedUserList, (data, index) => {
                  return (
                    <div className="table-body-item">
                      <div className="list-username listItem">{data}</div>
                      <div className="list-permission listItem">{selectedPermission}</div>
                    </div>
                  );
                })}
              </div> */}
            {/* </div> */}
            {sharing === 'reshare' && (
              <div className="notePoint">
                Users will be able to access the assessment once the owner grant permission
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default BulkShareAssessment;
