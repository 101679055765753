import React from "react";
import { Modal } from "react-bootstrap";
import { regexForTextAreas } from "../Shared/Constant";
import _ from "lodash";
import axiosInstance from "../Shared/interceptor";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class DealPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LocationOptions: [],
      IndustryOptions: [],
      offeringValueID: "",
      processValueID: "",
      processOptions: [],
      OperatingGroupOptions: [],
      OfferingOptions: [],
      ClientOptions: [],
      show: false,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      errors: [],
      formIsValid: false,
      deal: {
        ApproverRemarks: "",
        DealID: 0,
        OrgEntityID: null,
        OfferingID: null,
        ClientName: null,
        Program: null,
        Project: null,
        DealName: null,
        Offering: null,
        Process: null,
        SubProcess: null,
        ClientRegion: "",
        ClientCountry: "",
        MaturityPhase: null,
        AccentureMD: "",
        ProcessID: null,
        AccentureSDL: "",
        ClientContact1: null,
        ClientContact2: null,
        CreatedUser: null,

        UpdatedUser: null,

        LocationID: null,
        IndustryID: null,
        Industry: null,
        City: null,
        OperatingGroup: null,
        IsDemo: false,
      },
    };
  }
  handleSave(e) {
    const { deal } = this.state;
    if (this.validateForm()) {
      this.props.onSubmit(deal);

      this.handleHide();
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }

  componentDidMount() {
    const { deal } = this.state;
    deal.ApproverRemarks = this.props.dealData[0].ApproverRemarks;
    this.setState({
      deal,
      errors: [],
    });
  }

  componentWillMount() {}

  componentWillReceiveProps(nextProps) {
    if (this.props.profile) {
      if (!_.isNil(nextProps.filterDetails))
        if (
          String(nextProps.filterDetails.Client) !== String(0) &&
          String(nextProps.filterDetails.Locations) !== String(0) &&
          String(nextProps.filterDetails.Offering) !== String(0) &&
          String(nextProps.filterDetails.Process) !== String(0)
        ) {
          this.setState({ Display: true });
        } else this.setState({ Display: false });
    }
  }

  onRemarksChange(event) {
    this.setState({ errors: {} });
    const deal = this.props.dealData[0];
    deal.ApproverRemarks = event.target.value;
    this.setState({
      deal: deal,
    });
  }
  validateForm() {
    const { deal } = this.state;
    let errors = {};
    var formIsValid = true;
    
    if (!deal["ApproverRemarks"]) {
      formIsValid = false;
      errors["ApproverRemarks"] = "Approver Remarks are required";
    } else {
      var isValid = regexForTextAreas.test(deal["ApproverRemarks"]);
      if (!isValid) {
        formIsValid = false;
        errors["ApproverRemarks"] = "Approver Remarks contains Special characters";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    const { errors } = this.state;
    return (
      <>
        <Modal
          backdrop="static"
          show={true}
          onHide={this.handleHide.bind(this)}
          className="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile" flag>
              Approval
            </h4>
          </Modal.Header>
          <Modal.Body>
            {true ? (
              <form>
                <div className="form-section">
                  <h3>Org Structure</h3>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">Industry</label>
                      <input
                        id="Industry"
                        className="form-control"
                        disabled={true}
                        ref="IndustryDropDown"
                        value={this.props.dealData[0].Industry}
                      />
                    </div>
                    {!this.props.dealData[0].ClientCountry ? (
                      <div className="form-group col-lg-6">
                        <label for="">Other Industry</label>
                        <input
                          id="OtherIndustry"
                          className="form-control"
                          disabled={true}
                          ref="IndustryDropDown"
                          value={this.props.dealData[0].OtherIndustry}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">Client Name</label>
                      <input
                        id="Client"
                        className="form-control"
                        disabled={true}
                        ref="ClientDropdown"
                        value={this.props.dealData[0].CoreBeatClientName}
                      />
                    </div>
                    {!this.props.dealData[0].ClientCountry ? (
                      <div className="form-group col-lg-6">
                        <label for="">Alias Client Name</label>
                        <input
                          id="AliasClient"
                          className="form-control"
                          disabled={true}
                          ref="ClientDropdown"
                          value={
                            this.props.dealData[0].CoreBeatClientName.toUpperCase() ===
                            this.props.dealData[0].ClientName.toUpperCase()
                              ? ""
                              : this.props.dealData[0].ClientName
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row">
                    {this.props.dealData[0].ClientCountry ? (
                      <div className="form-group col-lg-6">
                        <label for="">Program</label>
                        <input
                          maxLength="50"
                          type="text"
                          className="form-control"
                          id="program"
                          disabled={true}
                          value={this.props.dealData[0].Program}
                        />
                      </div>
                    ) : null}

                    <div className="form-group col-lg-6">
                      <label for="">Project</label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="project"
                        value={this.props.dealData[0].Project}
                        disabled={true}
                      />
                    </div>
                    {this.props.dealData[0].ClientCountry ? null : (
                      <div className="form-group col-lg-6">
                        <label className="form-check approvalPopup-check-top">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            disabled
                            defaultChecked={this.props.dealData[0].IsDemo}
                          />
                          <span className="checkmark"></span>
                          <span className="form-check-label">Demo</span>
                        </label>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    {this.props.dealData[0].ClientCountry ? (
                      <div className="form-group col-lg-6">
                        <label for="">Deal Name</label>
                        <input
                          maxLength="50"
                          type="text"
                          className="form-control"
                          id="dealname"
                          value={this.props.dealData[0].DealName}
                          disabled={true}
                        />
                      </div>
                    ) : null}
                    <div className="form-group col-lg-6">
                      <label for="">Maturity Phase</label>
                      <input
                        id="MaturityPhases"
                        className="form-control"
                        value={this.props.dealData[0].MaturityPhase}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-section">
                  <h3>Geo / Location</h3>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">Market Region</label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="ClientRegions"
                        value={this.props.dealData[0].ClientRegion}
                        disabled={true}
                      />
                    </div>

                    {!this.props.dealData[0].ClientCountry ? (
                      <div className="form-group col-lg-6">
                        <label for="">Other Market Region</label>
                        <input
                          maxLength="50"
                          type="text"
                          className="form-control"
                          id="ClientRegions"
                          value={this.props.dealData[0].OtherClientRegion}
                          disabled={true}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* oTHER CLIENT REGION */}

                    {this.props.dealData[0].ClientCountry ? (
                      <div className="form-group col-lg-6">
                        <label for="">Client Country</label>
                        <input
                          maxLength="50"
                          type="text"
                          className="form-control"
                          id="ClientCountries"
                          value={this.props.dealData[0].ClientCountry}
                          disabled={true}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">City / Deliver Center</label>
                      <input
                        id="Location"
                        className="form-control"
                        disabled={true}
                        ref="LocationDropdown"
                        value={this.props.dealData[0].CoreBeatLocation}
                      />
                    </div>
                    {!this.props.dealData[0].ClientCountry ? (
                      <div className="form-group col-lg-6">
                        <label for="">Deliver Center Alias</label>
                        <input
                          id="Location"
                          className="form-control"
                          disabled={true}
                          ref="LocationDropdown"
                          value={
                            this.props.dealData[0].CoreBeatLocation.toUpperCase() ===
                            this.props.dealData[0].City.toUpperCase()
                              ? ""
                              : this.props.dealData[0].City
                          }
                        />
                      </div>
                    ) : null}
                  </div>

                  {!this.props.dealData[0].ClientCountry ? (
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">Delivery Region</label>
                        <input
                          id="DeliveryRegion"
                          className="form-control"
                          disabled={true}
                          ref="LocationDropdown"
                          value={this.props.dealData[0].DeliveryRegion}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label for="">Other Delivery Region</label>
                        <input
                          id="DeliveryRegion"
                          className="form-control"
                          disabled={true}
                          ref="LocationDropdown"
                          value={this.props.dealData[0].OtherDeliveryRegion}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="form-section">
                  <h3>Offering</h3>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">Offering</label>
                      <input
                        id="Offering"
                        className="form-control"
                        ref="OfferingDropDown"
                        disabled={true}
                        value={this.props.dealData[0].Offering}
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label for="">Sub Offering</label>
                      <input
                        id="Process"
                        className="form-control"
                        ref="ProcessDropDown"
                        disabled={true}
                        value={this.props.dealData[0].Process}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-section">
                  <h3>Stakeholders</h3>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">Accenture Delivery MD</label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="AccentureMD"
                        value={this.props.dealData[0].AccentureMD}
                        disabled={true}
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label for="">Delivery SDL</label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="AccentureSDL"
                        value={this.props.dealData[0].AccentureSDL}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">Client Experience Lead</label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="cxl"
                        value={this.props.dealData[0].Cxl}
                        disabled={true}
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label for="">OPS Account Lead</label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="oal"
                        value={this.props.dealData[0].Oal}
                        disabled={true}
                      />
                    </div>
                  </div>
                  {this.props.dealData[0].ClientCountry ? (
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">Client Contact 1</label>
                        <input
                          maxLength="50"
                          type="text"
                          className="form-control"
                          id="ClientContact1"
                          disabled={true}
                          value={this.props.dealData[0].ClientContact1}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label for="">Client Contact 2</label>
                        <input
                          maxLength="50"
                          type="text"
                          className="form-control"
                          id="Client Contact 2"
                          disabled={true}
                          value={this.props.dealData[0].ClientContact2}
                        />
                      </div>
                    </div>
                  ) : null}
                  {!this.props.dealData[0].ClientCountry ? (
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">Users with Edit access</label>
                        <input
                          maxLength="50"
                          type="text"
                          className="form-control"
                          id="editaccessusers"
                          disabled={true}
                          value={this.props.dealData[0].EditAccessUsers}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label for="">Users with View access</label>
                        <input
                          maxLength="50"
                          type="text"
                          className="form-control"
                          id="viewaccessusers"
                          disabled={true}
                          value={this.props.dealData[0].ViewAccessUsers}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="form-section">
                  <h3>Remarks</h3>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">Requestor Remarks</label>

                      <textarea
                        maxLength="200"
                        className="form-control"
                        value={this.props.dealData[0].RequestorRemarks}
                        disabled={true}
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label for="">
                        Approver Remarks<span className="required">*</span>
                      </label>

                      <textarea
                        maxLength="50"
                        className="form-control"
                        value={this.props.dealData[0].ApproverRemarks}
                        onChange={this.onRemarksChange.bind(this)}
                      />

                      <span className="errorMsg">{errors.ApproverRemarks}</span>
                    </div>
                  </div>
                </div>
                <div className="form-section">
                  <div className="row">
                    <div className="form-group col-lg-12">
                      {!this.props.profile ? (
                        <>
                          {!this.props.data ? (
                            <input
                              type="Button"
                              className="btn btn-primary btn-sm"
                              onClick={() => this.handleSave()}
                              value="Create"
                            />
                          ) : (
                            <input
                              type="Button"
                              className="btn btn-primary btn-sm"
                              onClick={() => this.handleSave()}
                              value="Update"
                            />
                          )}
                        </>
                      ) : (
                        <input
                          type="Button"
                          className="btn btn-primary btn-sm"
                          onClick={() => this.handleSave()}
                          value="Update"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <p>Please Select Client,Offering and Location </p>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
