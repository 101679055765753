import React from 'react';
import { Modal } from 'react-bootstrap';
import { LocalApiBaseUrl } from '../Shared/Constant';
import i18n from "i18next";
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import ToolsPopUP from './ToolsPopUp'
import { regexForFields, regexForToolURL, commonToolRegex } from "../Shared/Constant";
import { store } from "../reducers/configureStore";

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class ClickableToolPopup extends React.Component {
    constructor(props) {

        super(props)
        const { show, SelectValue } = this.props;
        this.state = {
            rowSelected: [],
            viewmore: false,
            viewcatdes: false,
            viewdesc: false,
            isEditable: false,
            countOffering: [],
            leverName: "",
            Tooltype: "",
            toolLeversList: [],
            clientname: "",
            Action: "Create",
            popupHeader: i18n.t("Add Tool Mapping"),
            popupSaveButton: i18n.t("Create"),
            offeringName: "",
            ApplicationID: "",
            subofferingname: "",
            selectedClientId: "",
            ClickableToolID: "",
            ToolCategory: "",
            ToolDescription: "",
            temp: [],
            processEnable: "",
            LocationOptions: [],
            processData: [],
            MappingDetails: {
                OfferingID: "",
                OfferingName: "",
                proID: "",
                ClickableTooLMappingID: "",
                locID: "",
                LocationName: "",
                url: "",
                description: "",
                BusinessBenefit: "",
                DefaultUserID: ""
            },
            MappingErrors: {
                ClientName: "",
                Offering: "",
                SubOffering: "",
                Location: "",
                Levers: "",
                toolname: "",
                BusinessBenefit: "",
                url: ""
            },
            ClientOptions: [],
            CapabilitiesClientsOptions: [],
            showOffering: false,
            OfferingOptions: [],
            offeringData: [],
            RoleOptions: [],
            show: show, apivalue: "", selectValue: SelectValue, Display: false,
            errors: [],
            formIsValid: false,
            offering: '',
            process: '',
            lever: {
                leverId: 0
            },

            user: {
                UserID: 0,
                UserName: "",
                UserEnterpiseID: null,
                OrgEntityID: null,
                UserEmailAddress: null,
                IsActive: false,
                IsGuest: false,
                IsAdmin: false,
                IsCapability: false,
                SubOfferingOptions: [],
                RoleIds: [],
                ParentId: [],
                RoleNames: [
                ],
                ClientIds: [
                ],
                OfferingIds: [
                ],
                OfferingNames: [
                ],
                SubOfferingIds: [
                ],
                SubOfferingNames: [
                ],
                ClientNames: [
                ],
                CreatedUser: null,
                CreatedTS: null
            }
        }
        this.OfferingValues = [];
        this.getnewOfferings = this.getnewOfferings.bind(this);
        this.leverselect = this.leverselect.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.onChange = this.onChange.bind(this);
        this.roleSelect = this.roleSelect.bind(this);
        this.locationSelect = this.locationSelect.bind(this)
        this.bindProcess = this.bindProcess.bind(this);
        this.selectedFilter = this.selectedFilter.bind(this);
        this.onClose = this.onClose.bind(this);

    }


    validateForm() {
        const { isEditable, MappingDetails, user, lever, ApplicationMappingName } = this.state
        let isValid = false;
        let errorJSON = [];
        if (!isEditable) {
            if (String(user.ClientIds) === String(undefined) || parseInt(user.ClientIds) === 0) {
                errorJSON[0] = i18n.t("Client_Selection_Required");
            }
            else {
                errorJSON[0] = "";
            }

            if (String(MappingDetails.OfferingID) === String(undefined) || parseInt(MappingDetails.OfferingID) === 0) {
                errorJSON[1] = i18n.t("Offering_Selection_Required");
            }
            else {
                errorJSON[1] = "";
            }
            if (String(MappingDetails.proID) === String(undefined) || parseInt(MappingDetails.proID) === 0) {
                errorJSON[2] = i18n.t("Sub_Offering_Selection_Required");
            }
            else {
                errorJSON[2] = "";
            }

            if (String(MappingDetails.locID) === String(undefined) || parseInt(MappingDetails.locID) === 0) {
                errorJSON[3] = i18n.t("Location_Selection_Required");
            }
            else {
                errorJSON[3] = "";
            }

            if (String(lever.leverId) === String(undefined) || parseInt(lever.leverId) === 0) {
                errorJSON[4] = i18n.t("Lever_Selection_Required");
            }
            else {
                errorJSON[4] = "";
            }
            if (String(ApplicationMappingName) === String(undefined) || String(ApplicationMappingName) === "" || String(ApplicationMappingName) === "0") {
                errorJSON[5] = i18n.t("Tool_Selection_Required");
            }
            else {
                errorJSON[5] = "";
            }
            if (String(MappingDetails.BusinessBenefit) !== String(undefined) && MappingDetails.BusinessBenefit.toString().trim() !== "" && !regexForFields.test(MappingDetails.BusinessBenefit.toString().trim())) {
                errorJSON[6] = i18n.t("Business_benefit_Contains_Special_Character");
            }
            else {
                errorJSON[6] = "";
            }
            if (String(MappingDetails.url) && String(MappingDetails.url) !== String(undefined)) {

                if (!commonToolRegex.test(MappingDetails.url.trim().toLowerCase())) {
                    if (String(MappingDetails.url) !== String(undefined) && MappingDetails.url.toString().trim() !== "" && !regexForToolURL.test(MappingDetails.url.toString().trim().toLowerCase())) {
                        errorJSON[7] = i18n.t("Invalid_URL");
                    }
                    else {
                        errorJSON[7] = "";
                    }
                }
                else {
                    errorJSON[7] = i18n.t("commonToolRegex_validatemessage");
                }
            }
            else {
                errorJSON[7] = "";
            }
            this.setState({
                MappingErrors: { ClientName: errorJSON[0], Offering: errorJSON[1], SubOffering: errorJSON[2], Location: errorJSON[3], Levers: errorJSON[4], toolname: errorJSON[5], BusinessBenefit: errorJSON[6], url: errorJSON[7] }
            });
            if (String(errorJSON[0]) === "" && String(errorJSON[1]) === "" && String(errorJSON[2]) === "" && String(errorJSON[3]) === "" && String(errorJSON[4]) === "" && String(errorJSON[5]) === "" && String(errorJSON[6]) === "" && String(errorJSON[7]) === "")
                isValid = true;
        }
        else {
            if (String(MappingDetails.url) && String(MappingDetails.url) !== String(undefined)) {
                if (!commonToolRegex.test(MappingDetails.url.trim().toLowerCase())) {
                    if (String(MappingDetails.url) !== String(undefined) && MappingDetails.url.toString().trim() !== "" && !regexForToolURL.test(MappingDetails.url.toString().trim().toLowerCase())) {
                        errorJSON[7] = i18n.t("Invalid_URL");
                    }
                    else {
                        errorJSON[7] = "";
                    }
                }
                else {
                    errorJSON[7] = i18n.t("commonToolRegex_validatemessage");
                }
            }
            else {
                errorJSON[7] = "";
            }
            this.setState({ MappingErrors: { url: errorJSON[7] } })
            if (String(errorJSON[7]) === "") {
                isValid = true;
            }
            else {
                isValid = false;
            }
        }
        return isValid;
    }

    handleSave() {
        const { MappingDetails, lever, user, ApplicationMappingName, ToolCategory, ToolDescription, Action, ApplicationID } = this.state;
        let url = MappingDetails.url;
        let BusinessBenefit = MappingDetails.BusinessBenefit;
        let DefaultUserID = MappingDetails.DefaultUserID;
        if (this.validateForm()) {
            const data = {
                "OfferingID": parseInt(MappingDetails.proID),
                "SOCategoryID": parseInt(lever.leverId),
                "OrgEntityID": parseInt(user.ClientIds),
                "LocationID": parseInt(MappingDetails.locID),
                "ApplicationName": ApplicationMappingName ? ApplicationMappingName.toString() : "",
                "ApplicationMappingID": ApplicationID,//MAPPING APPLICATIONID
                "ClickableToolMappingID": parseInt(MappingDetails.ClickableTooLMappingID),
                "URL": url ? url.trim() : "",
                "ToolCategory": ToolCategory.trim(),
                "ApplicationDescription": ToolDescription.toString().trim(),
                "BusinessBenefit": BusinessBenefit ? BusinessBenefit.trim() : "",
                "DefaultuserID": DefaultUserID ? DefaultUserID.trim() : "",
                "Action": (Action && Action.trim())
            };
            this.props.onSave(data);
            this.handleHide();
        }
    }



    bindToolFieldChange = (e) => {
        const { MappingDetails } = this.state;
        const id = e.target.id;
        let data = MappingDetails;
        switch (id) {
            case "txtToolUrl":
                data.url = e.target.value;
                break;
            case "txtToolbusinessreason":
                data.BusinessBenefit = e.target.value;
                break;
            case "txtTooldefaultuserid":
                data.DefaultUserID = e.target.value;
                break;
            case "textdesc":
                this.setState({ ToolDescription: e.target.value })
                break;
            default:
                break;
        }

        this.setState({
            MappingDetails: data
        });
    }



    roleSelect(event) {
        const { RoleOptions, MappingDetails } = this.state;
        let toolcheck = event.target.value//toolcheck.id
        let statestore = RoleOptions.filter(row => String(row.ClickableToolID) === String(event.target.value))[0];
        let ApplicationID = String(toolcheck) !== "-1" && String(toolcheck) !== "0" ? RoleOptions.filter(row => String(row.ClickableToolID) === String(event.target.value))[0].ApplicationMappingID : []
        let ApplicationName = String(toolcheck) !== "-1" && String(toolcheck) !== "0" ? RoleOptions.filter(row => String(row.ClickableToolID) === String(event.target.value))[0].ApplicationName : []
        let Tooltype = String(toolcheck) !== "-1" && String(toolcheck) !== "0" ? RoleOptions.filter(row => String(row.ClickableToolID) === String(event.target.value))[0].ApplicationCategory : []
        let ToolDescription = String(toolcheck) !== "-1" && String(toolcheck) !== "0" ? RoleOptions.filter(row => String(row.ClickableToolID) === String(event.target.value))[0].ApplicationDescription : []
        let BusinessBenefit = String(toolcheck) !== "-1" && String(toolcheck) !== "0" ? RoleOptions.filter(row => String(row.ClickableToolID) === String(event.target.value))[0].BusinessBenefit : []
        let DefaultUserID = String(toolcheck) !== "-1" && String(toolcheck) !== "0" ? RoleOptions.filter(row => String(row.ClickableToolID) === String(event.target.value))[0].DefaultUserID : []
        let url = String(toolcheck) !== "-1" && String(toolcheck) !== "0" ? RoleOptions.filter(row => String(row.ClickableToolID) === String(event.target.value))[0].URL : []
        let ToolCategory = String(toolcheck) !== "-1" && String(toolcheck) !== "0" && statestore.ToolCategory ? statestore.ToolCategory : []

        this.setState({
            ...this.state,
            ApplicationID: ApplicationID,
            Tooltype: Tooltype,
            ToolDescription: ToolDescription,
            ApplicationMappingName: ApplicationName,
            ToolCategory: ToolCategory,
            MappingDetails: {
                ...MappingDetails,
                BusinessBenefit: BusinessBenefit,
                DefaultUserID: DefaultUserID,
                url: url
            }
        })

        if (String(event.target.value) === "-1") {
            this.setState({ viewmore: true, show: false });

        }

        if (String(event.target.value) !== "-1" && String(event.target.value) !== "0") {
            this.setState({ viewcatdes: true });

        }
        else {
            this.setState({ viewcatdes: false })
        }
        if (String(ToolCategory) === "Custom") {
            this.setState({ viewdesc: false })
        }
        else {
            this.setState({ viewdesc: true })
        }

        this.onChange(event);
        this.setState({ errors: {} });
        let ClickableToolID = this.state.ClickableToolID;

        let tool = document.getElementById("tool");
        let temptools = [];
        let len = tool.length;
        for (var i = 0; i < len; i++) {
            let opt = tool.options[i];
            if (opt.selected) {
                temptools.push(opt.value);
            }

        }

        ClickableToolID = temptools;

        this.setState({
            ClickableToolID: ClickableToolID
        });
    }


    SubofferingSelect() {

        let user = this.state.user;
        let SubOffering = document.getElementById("SubOffering");
        let tempSubOff = [];
        let len = SubOffering.length;
        for (var i = 0; i < len; i++) {
            let opt = SubOffering.options[i];
            if (opt.selected) {
                tempSubOff.push(opt.value);
            }
        }
        user.SubOfferingIds = tempSubOff;
        this.setState({
            user: user
        })
    }

    handleHide(e) {
        this.setState({ show: false });
        if (String(e) !== String(undefined)) {

            if ((String(e.target.textContent) !== "×")) {
                this.props.onClose(e);
            }
            else {
                this.props.onClose(e);
            }
        } else {
            this.props.onClose(e);
        }


    }
    handleRejectHide(e) {

        this.setState({ show: false });
        if (String(e) !== String(undefined)) {
            if ((String(e.target.textContent) !== "×")) {
                this.props.onRejectClose();
            }
        } else {
            this.props.onRejectClose();
        }

    }
    handleApproveHide(e) {

        this.setState({ show: false });
        if (String(e) !== String(undefined)) {
            if ((String(e.target.textContent) !== "×")) {
                this.props.onApproveClose();
            }
        } else {
            this.props.onApproveClose();
        }

    }
    passToConfirmation(data) {
        this.props.enteredData(data.PoNumber)
    }
    removeDuplicates(arr) {/*NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
        const map = new Map();
        arr.forEach(v => map.set(v.ApplicationName, v))
        return [...map.values()];
    }

    getClients() {
        axiosInstance.get(`${LocalApiBaseUrl}SO/GetOrgStructure`, { 
            params: { featureName: "ClickableTool" },
          })
            .then((response) => {
                this.setState({ ClientOptions: response.data });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });

    }

    onClientChange(event) {

        let user = this.state.user;
        let client = document.getElementById("Client");
        let tempClient = [];
        let len = client.length;
        for (var i = 0; i < len; i++) {
            let opt = client.options[i];
            if (opt.selected) {
                tempClient.push(opt.value);
            }
        }
        user.ClientIds = tempClient;
        this.setState({
            user: user
        })
        let ClientIdspass = this.state.user.ClientIds
        if (String(event.target.value) === String(0)) {
            this.setState({
                viewcatdes: false,
                offeringData: [],
                processData: [],
                LocationOptions: [],
                toolLeversList: [],
                RoleOptions: [],
                MappingDetails: {
                    OfferingID: 0,
                    locID: 0,
                    proID: 0,
                    ApplicationMappingID: 0
                },
                lever: {
                    leverId: 0
                },
                ApplicationMappingName: "",
                ToolCategory: "",
                ToolDescription: "",
            });
        }
        else {


            this.getnewOfferings(ClientIdspass);
        }
    }
    getnewOfferings(ClientIdspass) {
        const { user, ClientOptions } = this.state;
        let offeringData = [];
        let Client = user.ClientIds//PASSING STATES ID
        let clientname = Client !== "0" ? ClientOptions.filter(row => String(row.OrgEntityID) === String(ClientIdspass))[0].OrgEntityName : []
        this.setState({ clientname: clientname })
        this.setState({ selectedClientId: Client })
        axiosInstance
            .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
                params: { OrgEntityID: ClientIdspass, LanguageID: store.getState().CurrentFilter.languageData.id, featureName: "ClickableTool" }
            })
            .then(response => {
                /*For Token renewl end */
                offeringData = response.data;
                this.setState({
                    offeringData: offeringData,
                    viewcatdes: false,
                    processData: [],
                    LocationOptions: [],
                    toolLeversList: [],
                    RoleOptions: [],
                    MappingDetails: {
                        OfferingID: 0,
                        locID: 0,
                        proID: 0,
                        ApplicationMappingID: 0
                    },
                    lever: {
                        leverId: 0
                    },
                    ApplicationMappingName: "",
                    ToolCategory: "",
                    ToolDescription: "",
                });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });


    }

    OnnewOfferingChange(event) {
        const { MappingDetails, offeringData } = this.state;
        let offer = MappingDetails;
        let Offering = document.getElementById("Offering");
        let tempOffering = [];
        let len = Offering.length;
        for (var i = 0; i < len; i++) {
            let opt = Offering.options[i];
            if (opt.selected) {
                tempOffering.push(opt.value);
            }
        }
        offer.OfferingID = tempOffering;
        this.setState({
            offer: offer
        })

        this.setState({
            ...this.state,
            MappingDetails: {
                ...MappingDetails,
                locID: 0,
                proID: 0,
                ApplicationMappingID: 0
            },
            lever: {
                leverId: 0
            },
            LocationOptions: [],
            toolLeversList: [],
            RoleOptions: [],
            viewcatdes: false,
            ApplicationMappingName: "",
            ToolCategory: "",
            ToolDescription: "",
        });

        let selectedOffering = MappingDetails;
        let OfferingIDpass = MappingDetails.OfferingID

        let offeringName = String(selectedOffering.OfferingID) !== "0" ? offeringData.filter(
            eachValue => String(eachValue.OfferingID) === String(selectedOffering.OfferingID))[0].OfferingName : []
        this.setState({ offeringName: offeringName })
        this.getSuboffering(OfferingIDpass);
    }

    getSuboffering(OfferingIDpass) {
        const { offeringData } = this.state;
        let processData = (OfferingIDpass && String(OfferingIDpass[0]) !== "0") ? offeringData.filter(
            eachValue => String(eachValue.OfferingID) === String(OfferingIDpass[0]
            ))[0].ChildOfferings : [];///SINGLE ROW 

        this.setState({ processData: processData });
    }
    OnOfferingChange(event) {
        const { MappingDetails, selectedClientId, processData } = this.state;
        let sub = MappingDetails;
        let offering = document.getElementById("SubOffering");
        if (offering) {
            let tempOffering = [];
            let countOffering = [];
            let len = offering.length;
            for (var i = 0; i < len; i++) {
                let opt = offering.options[i];

                if (opt.selected) {
                    tempOffering.push(opt);
                    countOffering.push(opt.value);

                }
            }
            sub.proID = countOffering;
            this.setState({
                countOffering: countOffering
            })


            this.setState({
                processValue: sub
            })

        }

        this.setState({
            MappingDetails: {
                ...MappingDetails,
                locID: 0,
                ApplicationMappingID: 0
            },
            lever: {
                leverId: 0
            },
            LocationOptions: [],
            toolLeversList: [],
            RoleOptions: [],
            viewcatdes: false,
            ApplicationMappingName: "",
            ToolCategory: "",
            ToolDescription: "",
        });

        let subofferingID = MappingDetails.proID
        let subofferingname = subofferingID.toString() !== "0" ? processData.filter(row => String(row.OfferingID) === String(subofferingID))[0].Process : []
        this.setState({ subofferingname: subofferingname })
        if (selectedClientId && String(subofferingID) !== 0) {
            this.getLocations(selectedClientId, subofferingID);
        }

    }

    locationSelect(event) {
        const { MappingDetails, LocationOptions } = this.state;
        this.setState({
            ...this.state,
            RoleOptions: [],
            toolLeversList: [],
            ApplicationName: "",
            viewcatdes: false,
            lever: {
                leverId: 0
            },
        });


        this.onChange(event);
        this.setState({ errors: {} });

        let location = MappingDetails;

        let loc = document.getElementById("location");
        let temploc = [];
        let len = loc.length;
        for (var i = 0; i < len; i++) {
            let opt = loc.options[i];
            if (opt.selected) {
                temploc.push(opt.value);
            }

        }
        location.locID = temploc;
        this.setState({
            MappingDetails: location
        })
        let LocationIDs = MappingDetails.locID

        let LocationName = LocationIDs.toString() !== "0" ? LocationOptions.filter(row => String(row.LocationID) === String(LocationIDs))[0].City : []

        this.setState({ LocationName: LocationName })

        if (LocationIDs.toString() !== "0") {
            this.getToolMasterData();
        }
        else {
            this.setState({
                ...this.state,
                RoleOptions: [],
                toolLeversList: [],
                ApplicationName: "",
                viewcatdes: false,
                lever: {
                    leverId: 0
                },
            });
        }
    }




    getRoles() {
        const { user, MappingDetails, lever } = this.state;
        let EnterpriseID = sessionStorage.getItem("EnterpriseID")
        let OrgEntityID = user.ClientIds;
        
        if (String(EnterpriseID) !== String(undefined)) {
            axiosInstance.get(`${LocalApiBaseUrl}ClickableTools/GetMergedTool`, {
                params: {
                    OrgEntityID: user.ClientIds,
                    OfferingID: MappingDetails.proID,
                    LocationID: MappingDetails.locID,
                    SOCategoryID: lever.leverId
                }
            })

                .then((response) => {
                    this.setState({
                        RoleOptions: response.data,
                        ApplicationMappingName: "",
                        ToolCategory: "",
                        ToolDescription: ""
                    });
                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                });

        }
    }

    leverselect(event) {

        this.setState({
            ...this.state,
            viewcatdes: false,
            RoleOptions: [],
            ApplicationMappingName: "",
            ClickableToolID: ""

        });
        this.onChange(event);
        this.setState({ errors: {} });
        let lever = this.state.lever;
        let lev = document.getElementById("lever");
        let templev = [];
        let len = lev.length;
        for (var i = 0; i < len; i++) {
            let opt = lev.options[i];
            if (opt.selected) {
                templev.push(opt.value);
            }

        }

        lever.leverId = event.target.value;
        this.setState({
            lever: lever
        })
        const { toolLeversList } = this.state;
        let SOCategoryID = this.state.lever.leverId;

        let SOCategoryName = SOCategoryID !== "0" ? toolLeversList.filter(a => String(a.ID) === String(SOCategoryID))[0].Value : [];
        this.setState({ leverName: SOCategoryName })


        this.getRoles();
    }

    clickableclose(e) {
        this.props.onClose(e);
    }

    onClose(e) {
        this.setState({ viewmore: false });
        this.props.onClose(e);
    }


    getLocations(Client, subofferingID) {

        let LocationData = [];
        axiosInstance
            .get(`${LocalApiBaseUrl}SOFilter/GetLocation`, {

                params: { OrgEntityID: Client, OfferingID: subofferingID, LanguageID: store.getState().CurrentFilter.languageData.id }
            })
            .then(response => {
                LocationData = response.data;
                this.setState({ LocationOptions: LocationData, });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });


    }

    bindProcess(event) {
        if (!event.Auto) {
            let userOld = this.state.user;
            //clearing all fields
            userOld.ClientIds = []
            userOld.OfferingIds = []
            userOld.ClientNames = ""
            userOld.OfferingNames = ""
            //clearing all the suboffering from FilteredSuboffering
            this.setState({
                user: userOld, CapabilitiesClientsOptions: [], FilteredSuboffering: []
            })
        }
        let selectedOffering = event.target.value;
        let tempOffering = [];
        let countOffering = [];
        let offering = document.getElementById("offerings");
        if (offering) {

            let len = offering.length;
            for (var i = 0; i < len; i++) {
                let opt = offering.options[i];

                if (opt.selected) {
                    tempOffering.push(opt);
                    countOffering.push(opt.value);

                }
            }

        }
        let process = "";
        let processOptions = [];
        let FilteredSuboffering = [];
        if (String(selectedOffering) !== "0") {
            let processData = this.OfferingValues.filter(
                eachValue => String(eachValue.OfferingID) === String(event.target.value)
            )[0].ChildOfferings;

            this.setState({ processData: processData });
            offering = processData[0].OfferingID;
            this.setState({ processEnable: offering });
            processOptions = processData
            this.setState({ processOptions, selectedOffering });
            let user = this.state.user;

            this.setState({
                user: user
            })
            this.setState({
                process, FilteredSuboffering
            })


        }
        this.setState({
            process: process
        })
    }

    selectedFilter = (elem) => {
        let user = this.state.user;
        let offering = document.getElementById("process");
        if (offering) {
            let tempOffering = [];
            let countOffering = [];
            let len = offering.length;
            for (var i = 0; i < len; i++) {
                let opt = offering.options[i];

                if (opt.selected) {
                    tempOffering.push(opt);
                    countOffering.push(opt.value);


                }
            }
            user.OfferingIds = countOffering;
            this.setState({
                countOffering: countOffering
            })

            this.setState({
                user: user
            })

        }

        //calling clients

        axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetTowerLevelClient`, {
            params: { OfferingID: this.state.user.OfferingIds.toString() }
        })
            .then((response) => {
                this.setState({ CapabilitiesClientsOptions: response.data });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });




    }

    onCapClientChange(event) {

        let user = this.state.user;
        let client = document.getElementById("CapClient");
        let tempClient = [];
        let len = client.length;
        for (var i = 0; i < len; i++) {
            let opt = client.options[i];
            if (opt.selected) {
                
                tempClient.push(opt.value);
            }
        }

        user.ClientIds = tempClient;
        this.setState({
            user: user
        });
    }
    componentDidMount() {
        const { rowData, tooldatatype } = this.props;
        this.getClients();

        if (String(rowData) !== String(undefined) && String(tooldatatype) === "Edit") {
            let data = this.props.data;
            if (data.Tooltype === "Custom") {
                this.setState({ viewdesc: false })
            }
            else {
                this.setState({ viewdesc: true })
            }
            this.setState({
                popupHeader: i18n.t("Edit Tool Mapping"),
                popupSaveButton: i18n.t("Update"),
                viewcatdes: true,
                ApplicationID: data.ApplicationMappingID,
                clientname: data.OrgEntityName,
                offeringName: data.Offering,
                subofferingname: data.Process,
                LocationName: data.City,
                SOCategoryName: data.SOCategoryName,
                ToolName: data.ApplicationName,
                Tooltype: data.ApplicationCategory,
                ToolDescription: data.ApplicationDescription,
                isEditable: true,
                Action: "Edit",
                MappingDetails: {
                    url: data.URL,
                    ClickableTooLMappingID: data.ClickableToolMappingID,
                    DefaultUserID: data.DefaultUserID,
                    BusinessBenefit: data.BusinessBenefit
                }
            });
        }
        else {
            this.setState({
                popupHeader: i18n.t("Add Tool Mapping"),
                popupSaveButton: i18n.t("Create"),
                clientname: "",
                offeringName: "",
                subofferingname: "",
                Action: "Create",
                LocationName: "",
                SOCategoryName: "",
                ToolName: "",
                ToolCategory: "",
                ToolDescription: "",
                MappingDetails: {
                    url: ""
                }
            });
        }
    }
    componentDidUpdate() {

    }
    componentWillMount() {
        if (this.props.data) {
            let data = { ...this.props.data }
            this.setState({
                user: data
            })
        }
    }

    select() {
    }

    getToolMasterData = () => {
        axiosInstance.get(`${LocalApiBaseUrl}ApplicationMapping/GetMasterDataForApplicationMapping`, {
            params: { languageID: store.getState().CurrentFilter.languageData.id }
        })
            .then((response) => {
                const soCategoryList = response.data.SOCategoryList.filter(id => id.ID !== 1)
                this.setState({
                    toolLeversList: soCategoryList
                });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });

    }
    onChange(event) {
        const { user } = this.state;
        if (String(event.target.value) === String(7) & user.ClientIds != null) {
            this.setState({
                showOffering: true
            })
        }
        else {
            this.setState({
                showOffering: false
            })
        }
    }
    dealpopup(e) {

        this.setState({ viewmore: true });
    }
    render() {
        const { ClickableToolID, clientname, offeringName, parentofferingID, CapofferingData, ClientOptions, offeringData, MappingDetails, processData, subofferingname, LocationName, SOCategoryName, ToolName, show, popupHeader, isEditable, MappingErrors, LocationOptions, toolLeversList, RoleOptions, lever, viewcatdes, viewmore, leverName, user, ToolDescription, Tooltype, viewdesc, popupSaveButton } = this.state;
        const { data, filterDetails } = this.props;
        let childoffering = []
        let uniqueParent = [...new Set(parentofferingID)];
        if (data) {

            if (data.IsCapability === true) {


                if (CapofferingData) {
                    CapofferingData.filter((eachValue) => {

                        return uniqueParent.forEach(ele => {

                            if (String(eachValue.OfferingID) === String(ele))
                                childoffering.push(...eachValue.ChildOfferings)

                        })
                    })
                }
            }
        }

        const clientMultipleselectbar = (
            <React.Fragment>

                <select id="Client" className="form-control" ref="ClientDropDown" onChange={this.onClientChange.bind(this)}>
                    <option value="0" selected="">{i18n.t("Select Client")}</option>

                    {
                        ClientOptions.map((row) => {
                            return <option value={row.OrgEntityID} key={row.OrgEntityName}>{row.OrgEntityName}</option>
                        })
                    }
                </select>
            </React.Fragment>
        );



        const offeringMultipleselectbar = (
            <React.Fragment>
                <select id="Offering" className="form-control" ref="ClientDropDown" onChange={this.OnnewOfferingChange.bind(this)} value={MappingDetails.OfferingID}  >
                    <option value="0">{i18n.t("Select Offering")}</option>

                    {offeringData.map((OD) => {
                        return <option value={OD.OfferingID} >{OD.OfferingName}</option>
                    })
                    }
                </select>
            </React.Fragment>
        );
        const SubofferingMultipleselectbar = (
            <React.Fragment>
                <select id="SubOffering" className="form-control" ref="ClientDropDown" onChange={this.OnOfferingChange.bind(this)}
                    value={MappingDetails.proID} >
                    <option value="0">{i18n.t("Select SubOfferings")}</option>
                    {processData.map((eachProcess) => {
                        return <option value={eachProcess.OfferingID} selected>{eachProcess.Process}</option>

                    })
                    }
                </select>


            </React.Fragment>
        );

        const clientOfferingDisabled = (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6">
                        <label for="">Client Name</label>
                        <input type="text" maxlength="200" className="form-control" id="" value={clientname} disabled />
                    </div>

                    <div className="form-group col-lg-6">
                        <label for="">Offering</label>
                        <input type="text" maxlength="200" className="form-control" id="" value={offeringName} disabled />
                    </div>

                </div>

            </React.Fragment>
        );

        const SubOfferingLocDisabled = (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6">
                        <label for="">Sub Offering</label>
                        <input type="text" maxlength="200" className="form-control" id="" value={subofferingname} disabled />
                    </div>

                    <div className="form-group col-lg-6">
                        <label for="">Delivery Center</label>
                        <input type="text" maxlength="200" className="form-control" id="" value={LocationName} disabled />
                    </div>

                </div>

            </React.Fragment>
        );
        const LeverstoolDisabled = (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6">
                        <label for="">SynOps Lever</label>
                        <input type="text" maxlength="200" className="form-control" id="" value={SOCategoryName} disabled />
                    </div>

                    <div className="form-group col-lg-6">
                        <label for="">Tool</label>
                        <input type="text" maxlength="45" className="form-control" id="" value={ToolName} disabled />
                    </div>

                </div>

            </React.Fragment>
        );


        return (

            <>
                {/* <Header /> */}

                <Modal show={show} onHide={this.handleHide.bind(this)} className="modal fade" size="medium" backdrop="static" id="dealpopup">
                    <Modal.Header closeButton>
                        <h4 className="modal-title">
                            {popupHeader}
                        </h4>
                    </Modal.Header>
                    <Modal.Body>

                        <form>
                            <div className="form-section">

                                {!isEditable ? (<div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="">Client Name<span className="required">*</span></label>
                                        {clientMultipleselectbar}

                                        <span className="errorMsg">
                                            {MappingErrors.ClientName}
                                        </span>

                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label for="">Offering<span className="required">*</span></label>
                                        {offeringMultipleselectbar}

                                        <span className="errorMsg">
                                            {MappingErrors.Offering}
                                        </span>

                                    </div>
                                </div>) : clientOfferingDisabled
                                }

                                {!isEditable ? (<div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="">Sub Offering<span className="required">*</span></label>
                                        {SubofferingMultipleselectbar}
                                        <span className="errorMsg">
                                            {MappingErrors.SubOffering}
                                        </span>
                                    </div>
                                    <div className="form-group col-lg-6">

                                        <label for="">Delivery Center<span className="required">*</span></label>
                                        <select id="location" className="form-control" ref="locDropDown" onChange={this.locationSelect.bind(this)} value={MappingDetails.locID}>
                                            <option value="0">{i18n.t("Select Delivery Center")}</option>
                                            {LocationOptions.map((loc) => {
                                                return <option value={loc.LocationID}>{loc.City}</option>
                                                // }
                                            })
                                            }
                                        </select>
                                        <span className="errorMsg">
                                            {MappingErrors.Location}
                                        </span>
                                    </div>
                                </div>) : SubOfferingLocDisabled
                                }

                                {!isEditable ? (<div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("SynOps Lever")} <span className="required">*</span>
                                        </label>
                                        <select id="lever" className="form-control" ref="RoleDropDown" onChange={this.leverselect.bind(this)} value={lever.leverId}>
                                            <option value="0">{i18n.t("Select Lever")}</option>
                                            {toolLeversList.map((lever) => {
                                                return <option value={lever.ID}>{lever.Value}</option>
                                                // }
                                            })
                                            }
                                        </select>
                                        <span className="errorMsg">
                                            {MappingErrors.Levers}
                                        </span>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label for="">Tool<span className="required">*</span></label>
                                        <select id="tool" className="form-control" ref="RoleDropDown" onChange={this.roleSelect.bind(this)} value={ClickableToolID}>
                                            <option value="0">{i18n.t("Select Tool")}</option>
                                            {RoleOptions.map((role) => {//value={role}
                                                return <option value={role.ClickableToolID}>{role.ApplicationName}</option>
                                                // }
                                            })
                                            }
                                            {lever.leverId && lever.leverId !== "0" ? (<option value="-1" >&lt;{i18n.t("Add_New")}&gt;</option>) : null}
                                        </select>
                                        <span className="errorMsg">
                                            {MappingErrors.toolname}
                                        </span>

                                    </div>
                                </div>) : LeverstoolDisabled
                                }

                                {viewcatdes ?
                                    <>
                                        <div className="row">

                                            <div className="form-group col-lg-6">
                                                <label for="">Tool Category</label>
                                                <input type="text" maxlength="200" className="form-control" id="" value={Tooltype} readOnly={true} />
                                            </div>


                                            <div className="form-group col-lg-6">
                                                <label for="">Tool Description</label>
                                                <input id="textdesc" type="text" maxlength="70" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={ToolDescription} readOnly={viewdesc} />
                                            </div>


                                        </div>

                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label for="">Business Benefit</label>
                                                <input id="txtToolbusinessreason" type="text" maxlength="255" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={MappingDetails.BusinessBenefit} />
                                                <span className="errorMsg">
                                                    {MappingErrors.BusinessBenefit}
                                                </span>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-12">
                                                <label for="">{i18n.t("Default User ID")}<span className="required"></span></label>
                                                <div class="input-group">
                                                    <input id="txtTooldefaultuserid" type="text" maxlength="100" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={MappingDetails.DefaultUserID} />

                                                </div>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-12">
                                                <label for="">URL</label>
                                                <input id="txtToolUrl" type="url" maxlength="5000" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={MappingDetails.url} />
                                                <span className="errorMsg">
                                                    
                                                    {MappingErrors.url}
                                                </span>
                                            </div>

                                        </div>
                                    </> : null}
                            </div>

                        </form>



                    </Modal.Body>
                    {<Modal.Footer>
                        <div className="modal-btn">
                            <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={popupSaveButton} />
                        </div>
                    </Modal.Footer>}
                </Modal>
                {viewmore ? (
                    <ToolsPopUP
                        SOCategoryID={lever.leverId}
                        SOCategoryName={leverName}
                        show={true}
                        onClose={this.onClose.bind(this)}
                        clickableclose={this.clickableclose.bind(this)}
                        filterDetails={filterDetails}
                        clientname={clientname}
                        clientID={user.ClientIds}
                        offeringName={offeringName}
                        subofferingname={subofferingname}
                        offeringID={MappingDetails.proID}
                        LocationName={LocationName}
                        LocationID={MappingDetails.locID}
                    />
                ) : null}
            </>
        )
    }
}
