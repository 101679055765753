import React from "react";
import { Modal } from "react-bootstrap";
import { LocalApiBaseUrl } from "../Shared/Constant";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { store } from "../reducers/configureStore";
import { regexForFields } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import i18n from "i18next";
import { DisplayMessage,isValidText,InvalidDescription } from "../Shared/commonFunctions";
import SynopsLoader from "../Shared/SynopsLoader";

 class BOCategoryPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      specialCharmessage:InvalidDescription(),
      show: this.props.show,
      apivalue: "",
      message: "",
      selectValue: this.props.SelectValue,
      Display: false,
      viewmore: false,
      MetricNameOptions: [],
      errors: {},
      formIsValid: false,
      isAPILoading: false,
      data: {
        ParentSOCategoryID: 1,
        Remarks: "",
        SOCategoryDesp: "",
        SOCategoryID: 0,
        SOCategoryName: "",
        SOCategorySeq: null,
        SOCategoryTypeID: 1,
        SequenceID: null,
        SoCatDataAttr: [],
        SoCategoryImage: null,
        kpiData: [],
      },
    };
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleRejectHide = this.handleRejectHide.bind(this);
    this.handleApproveHide = this.handleApproveHide.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onClose = this.onClose.bind(this);
    let offeringValue = "";
    let value1 = [];
  }

  onClose(e) {
    this.setState({ viewmore: false });
    this.props.onClose(e);
  }

  onNameChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const data = this.state.data;
    var a = this.props;
    data.SOCategoryName = event.target.value.trim();
    this.setState({
      data: data,
    });
  }

  onDescriptionChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const data = this.state.data;
    data.SOCategoryDesp = event.target.value.trim();
    this.setState({
      data: data,
    });
  }

  handleSave(e) {
    if (this.validateForm()) {
      let data = this.state.data;
      this.setState({
        data: data,
      });

      let filterProcess = this.props.filterDetails.process;
      data.SOCategoryDescription = this.state.data.SOCategoryDesp;
      this.setState({ isAPILoading: true });
      axiosInstance
        .post(`${LocalApiBaseUrl}Master/PostSOCategoryMasterTemplate`, data, {
          params: { offeringId: filterProcess,
            LanguageID: store.getState().CurrentFilter.languageData.id  
          },
        })
        .then((response) => {          
          store.dispatch({
            type: "ADD_BO_CATEGORY_MASTER",
            payload: response.data.stateData,
          });
          this.setState({ isAPILoading: false }, () => {
            if(response.data.IsSuccess){
            window.alert(i18n.t("New_So_Cat"));
            }

          this.handleHide();
          });
        })
        .catch((error)=>{
trycatchAlertPopup(error);
          this.setState({ isAPILoading: false }, () => {
          
            DisplayMessage();
            this.handleHide(e);
          });
        });
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }

  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }

  validateForm() {
    const{specialCharmessage}=this.state;
    const data = this.state.data;
    let errors = {};
    let formIsValid = true;
    let result=false;
    data["SOCategoryName"] = data["SOCategoryName"].trim();
    if (!data["SOCategoryName"]) {
      formIsValid = false;
      errors["SOCategoryName"] = "Name is required";
    } else {
      var isValid = regexForFields.test(data["SOCategoryName"]);
      if (!isValid) {
        formIsValid = false;
        errors["SOCategoryName"] = "SOCategoryName contains Special characters";
      }
      else{
        if(this.props.BO.length>0){
          result=this.props.BO.some((a) => a.SOCategoryName.trim().toLowerCase() === data["SOCategoryName"].trim().toLowerCase());
         if(result){
           window.alert(i18n.t("So_CatExist"));
           formIsValid = false;
         }
         else{
          if(!isValidText(data["SOCategoryName"].trim())) {
            errors["SOCategoryName"] = specialCharmessage;
            formIsValid = false;
        }
         }
         }
      }
    }
    data["SOCategoryDesp"] = data["SOCategoryDesp"].trim();
    if (!data["SOCategoryDesp"]) {
      formIsValid = false;
      errors["SOCategoryDesp"] = "Description is required";
    } else {
      var isValid = regexForFields.test(data["SOCategoryDesp"]);
      if (!isValid) {
        formIsValid = false;
        errors["SOCategoryDesp"] = "SOCategoryDesp contains Special characters";
      }
      else{
        if(!isValidText(data["SOCategoryDesp"].trim())) {
          errors["SOCategoryDesp"] = specialCharmessage;
          formIsValid = false;
      }
       }
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
        errors: {},
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  render() {
    const { isAPILoading } = this.state;

    return (
      <>
        {/* <Header /> */}
        {isAPILoading && (
          <LoadingOverlay
            fadeSpeed={0}
            spinner={<SynopsLoader />}
            active
          />
        )}
        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          className="modal fade"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title"> <span>{i18n.t("Add_New_BO_Category")}</span>   </h4>                                    

          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">
                    {i18n.t("Name")}<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      maxlength="100"
                      className="form-control"
                      id="name"
                      onChange={this.onNameChange}
                    />
                    <span className="errorMsg">
                      {this.state.errors.SOCategoryName}
                    </span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">
                    {i18n.t("Description")} <span className="required">*</span>
                    </label>
                    <input
                      maxlength="50"
                      type="text"
                      className="form-control"
                      id=""
                      onChange={this.onDescriptionChange}
                    />
                    <span className="errorMsg">
                      {this.state.errors.SOCategoryDesp}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value="Create"
                />
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    BO: state.BOMasterReducers.BO
  };
};

export default connect(
  mapStateToProps,
  ""
)(BOCategoryPopUp);
