import React, { useState, useEffect } from "react";
import { dateFormatMethodTimeLineSeries, backendDateToJsDate } from "../Shared/commonFunctions";
import ReactTooltip from 'react-tooltip';

const displayValues = (section, field) => {
  return section[field];
};

function BeforeAfterDrilldownTimeLine({
  from,
  fromIndex,
  to,
  toIndex,
  handleChangeFromTo,
  sectionData,
  nodeselected,
}) {
  return (
    <div className="beforeafter-detailtimeline">
      <ul className="mapping-timeline">
        <li
          className={(from === "Baseline" || to === "Baseline") && "active-timeline"}
          onClick={() => {
            if (!(from === "Baseline" || to === "Baseline"))
              handleChangeFromTo("Baseline", backendDateToJsDate(sectionData.BaselineYear), 0);
          }}
        >
          <div className="time-series-top">Baseline</div>
          <div className="time-series-bottom">
            <p className="month-line">{dateFormatMethodTimeLineSeries(sectionData.BaselineYear)}</p>
            <p  data-tip data-for={`Baseline`}>
              {(sectionData.BaselineIOJStage
                ? sectionData.BaselineIOJStage
                : displayValues(sectionData, "BaselineValue")).length >12 ? (sectionData.BaselineIOJStage
                  ? sectionData.BaselineIOJStage
                  : displayValues(sectionData, "BaselineValue")).slice(0,12)+"...":(sectionData.BaselineIOJStage
                    ? sectionData.BaselineIOJStage
                    : displayValues(sectionData, "BaselineValue")) }
            </p>
            {
              (sectionData.BaselineIOJStage
                ? sectionData.BaselineIOJStage
                : displayValues(sectionData, "BaselineValue")).length > 12 && 
            <ReactTooltip
                place="bottom"
                type="dark"
                effect="solid"
                id={`Baseline`}
                className="kpi-name"
              >
                {(sectionData.BaselineIOJStage
                ? sectionData.BaselineIOJStage
                : displayValues(sectionData, "BaselineValue"))}
              </ReactTooltip>
            }
          </div>
          {fromIndex !== "" && toIndex !== "" && (fromIndex === 0 || toIndex === 0) && (
            <div className="timeline-activeborder first-element"></div>
          )}
        </li>
        {sectionData.TimeseriesData.filter((a) => !a.isDeleted).length > 0 && (
          <li className="timeline-series">
            <div className="time-series-top">Timeseries</div>
            <ul>
              {sectionData.TimeseriesData.filter((a) => !a.isDeleted).map((time, index) => (
                <li
                  className={
                    (from === `Timeseries${index}` || to === `Timeseries${index}`) &&
                    "active-timeline"
                  }
                  onClick={() => {
                    if (!(from === `Timeseries${index}` || to === `Timeseries${index}`))
                      handleChangeFromTo(
                        `Timeseries${index}`,
                        backendDateToJsDate(time.Year),
                        index + 1,
                        time
                      );
                  }}
                >
                  <div className="time-series-bottom">
                    <p className="month-line">{dateFormatMethodTimeLineSeries(time.Year)}</p>
                    <p  data-tip data-for={`Timeseries${index}`}>{(time.IOJStage ? time.IOJStage : displayValues(time, "Value")).length>8 ? 
                  (time.IOJStage ? time.IOJStage : displayValues(time, "Value")).slice(0,8) + "...":(time.IOJStage ? time.IOJStage : displayValues(time, "Value"))  
                  }
                    
                    
                    </p>
                    {
                      (time.IOJStage ? time.IOJStage : displayValues(time, "Value")).length >8 && 
                      <ReactTooltip
                      place="bottom"
                      type="dark"
                      effect="solid"
                      id={`Timeseries${index}`}
                      className="kpi-name"
                    >
                      {(time.IOJStage ? time.IOJStage : displayValues(time, "Value"))}
                    </ReactTooltip>
                    }
                   
                  </div>
                  {fromIndex !== "" &&
                    toIndex !== "" &&
                    (fromIndex === index + 1 ||
                      toIndex === index + 1 ||
                      (index + 1 > fromIndex && index + 1 < toIndex)) && (
                      <div
                        className={
                          fromIndex === index + 1
                            ? `timeline-activeborder first-element`
                            : `timeline-activeborder last-element`
                        }
                      ></div>
                    )}
                </li>
              ))}
            </ul>
          </li>
        )}
        <li
          className={(from === "Current" || to === "Current") && "active-timeline"}
          onClick={() => {
            if (!(from === "Current" || to === "Current"))
              handleChangeFromTo(
                "Current",
                backendDateToJsDate(sectionData.CurrentYear),
                sectionData.TimeseriesData.length + 1
              );
          }}
        >
          <div className="time-series-top">Current</div>
          <div className="time-series-bottom">
            <p className="month-line">{dateFormatMethodTimeLineSeries(sectionData.CurrentYear)}</p>
            <p data-tip data-for={`Current`}>
              {(sectionData.CurrentIOJStage
                ? sectionData.CurrentIOJStage
                : displayValues(sectionData, "CurrentValue")).length >12 ? (sectionData.CurrentIOJStage
                  ? sectionData.CurrentIOJStage
                  : displayValues(sectionData, "CurrentValue")).slice(0,12)+"...":(sectionData.CurrentIOJStage
                    ? sectionData.CurrentIOJStage
                    : displayValues(sectionData, "CurrentValue")) }
            </p>
            {
              (sectionData.CurrentIOJStage
                ? sectionData.CurrentIOJStage
                : displayValues(sectionData, "CurrentValue")).length > 12 && 
            <ReactTooltip
                place="bottom"
                type="dark"
                effect="solid"
                id={`Current`}
                className="kpi-name"
              >
                {sectionData.CurrentIOJStage
                ? sectionData.CurrentIOJStage
                : displayValues(sectionData, "CurrentValue")}
              </ReactTooltip>
            }
          </div>
          {fromIndex !== "" &&
            toIndex !== "" &&
            (fromIndex === sectionData.TimeseriesData.length + 1 ||
              toIndex === sectionData.TimeseriesData.length + 1 ||
              (sectionData.TimeseriesData.length + 1 > fromIndex &&
                sectionData.TimeseriesData.length + 1 < toIndex)) && (
              <div
                className={
                  fromIndex === sectionData.TimeseriesData.length + 1
                    ? `timeline-activeborder first-element`
                    : `timeline-activeborder last-element`
                }
              ></div>
            )}
        </li>
        <li
          className={(from === "Target" || to === "Target") && "active-timeline"}
          onClick={() => {
            if (!(from === "Target" || to === "Target"))
              handleChangeFromTo(
                "Target",
                backendDateToJsDate(sectionData.TargetYear),
                sectionData.TimeseriesData.length + 2
              );
          }}
        >
          <div className="time-series-top">Target</div>
          <div className="time-series-bottom">
            <p className="month-line">{dateFormatMethodTimeLineSeries(sectionData.TargetYear)}</p>
            <p data-tip data-for={`Target`}>
              {(sectionData.TargetIOJStage
                ? sectionData.TargetIOJStage
                : displayValues(sectionData, "TargetValue")).length >12 ? (sectionData.TargetIOJStage
                  ? sectionData.TargetIOJStage
                  : displayValues(sectionData, "TargetValue")).slice(0,12) +"...":(sectionData.TargetIOJStage
                    ? sectionData.TargetIOJStage
                    : displayValues(sectionData, "TargetValue")) }
            </p>
            {
              (sectionData.TargetIOJStage
                ? sectionData.TargetIOJStage
                : displayValues(sectionData, "TargetValue")).length > 12 && 
            <ReactTooltip
                place="bottom"
                type="dark"
                effect="solid"
                id={`Target`}
                className="kpi-name"
              >
                {(sectionData.TargetIOJStage
                ? sectionData.TargetIOJStage
                : displayValues(sectionData, "TargetValue"))}
              </ReactTooltip>
            }
          </div>
          {fromIndex !== "" &&
            toIndex !== "" &&
            !(sectionData.TargetYear2 || sectionData.TargetIOJStage2) &&
            (fromIndex === sectionData.TimeseriesData.length + 2 ||
              toIndex === sectionData.TimeseriesData.length + 2) && (
              <div className="timeline-activeborder last-element"></div>
            )}
            {fromIndex !== "" &&
            toIndex !== "" &&
            (sectionData.TargetYear2 || sectionData.TargetIOJStage2) &&
            (fromIndex === sectionData.TimeseriesData.length + 2 ||
              toIndex === sectionData.TimeseriesData.length + 2 ||
              (sectionData.TimeseriesData.length + 2 > fromIndex &&
                sectionData.TimeseriesData.length + 2 < toIndex)) &&
              (
              <div  className={
                fromIndex === sectionData.TimeseriesData.length + 2
                  ? `timeline-activeborder first-element`
                  : `timeline-activeborder last-element`
              }></div>
            )}
          {/* <div className="timeline-activeborder last-element"></div> */}
        </li>
        {
          (sectionData.TargetYear2 || sectionData.TargetIOJStage2)  &&  <li
          className={(from === "Target2" || to === "Target2" ) && "active-timeline"}
          onClick={() => {
            if (!(from === "Target2" || to === "Target2"))
              handleChangeFromTo(
                "Target2",
                backendDateToJsDate(sectionData.TargetYear2),
                sectionData.TimeseriesData.length + 3
              );
          }}
        >
          <div className="time-series-top">Target</div>
          <div className="time-series-bottom">
            <p className="month-line">{dateFormatMethodTimeLineSeries(sectionData.TargetYear2)}</p>
            <p>
              {sectionData.TargetIOJStage2
                ? sectionData.TargetIOJStage2
                : displayValues(sectionData, "TargetValue2")}
            </p>
          </div>
          {fromIndex !== "" &&
            toIndex !== "" &&
            (fromIndex === sectionData.TimeseriesData.length + 3 ||
              toIndex === sectionData.TimeseriesData.length + 3) && (
              <div className="timeline-activeborder last-element"></div>
            )}
          {/* <div className="timeline-activeborder last-element"></div> */}
        </li>
        }
       
      </ul>
    </div>
  );
}

export default BeforeAfterDrilldownTimeLine;
