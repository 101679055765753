import React from "react";
import { Modal } from "react-bootstrap";

import axiosInstance from "../Shared/interceptor";
import { regexForConfMasterFields } from "../Shared/Constant";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class IojPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      Ioj: {
        OfferingId: null,
        IntelOpsCategoryName: "",
        IntelOpsStageName: null,
        IsActive: false,
      },
      errors: [],
      formIsValid: false,
    };

    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  onCategoryChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const Ioj = this.state.Ioj;
    Ioj.IntelOpsCategoryName = event.target.value;
    this.setState({
      Ioj: Ioj,
    });
  }
  onStatusChange(event) {
    const Ioj = this.state.Ioj;
    Ioj.IsActive = event.target.checked;
    this.setState({
      Ioj: Ioj,
    });
  }
  onStageChange(event) {
    this.setState({ errors: {} });
    const Ioj = this.state.Ioj;
    Ioj.IntelOpsStageName = event.target.value;
    this.setState({
      Ioj: Ioj,
    });
  }

  handleSave(e) {
    if (this.validateForm()) {
      this.props.onSave(this.state.Ioj);

      this.handleHide();
    }
  }
  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
        // this.props.onClose("from x");
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  componentDidMount() {
    // const Ioj = this.state.Ioj;
    // Ioj.OfferingId = this.props.OfferingId;

    this.setState({
      // Ioj: Ioj,
      show: this.props.show,
    });
  }

  validateForm() {
    const Ioj = this.state.Ioj;
    let errors = {};
    let formIsValid = true;

    if (!Ioj["IntelOpsCategoryName"].trim()) {
      formIsValid = false;
      errors["IntelOpsCategoryName"] = "Category is required";
    } else {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
     
      //Validate TextBox value against the Regex.
      var isValid = regexForConfMasterFields.test(Ioj["IntelOpsCategoryName"]);
      if (!isValid) {
        formIsValid = false;
        errors["IntelOpsCategoryName"] = "IntelOpsCategoryName contains Special characters";
      }
    }

    if (!Ioj["OfferingId"]) {
      formIsValid = false;
      errors["OfferingId"] = "Id is required";
    }

    if (!Ioj["IntelOpsStageName"]) {
      formIsValid = false;
      errors["IntelOpsStageName"] = "Stage is required";
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  componentWillMount() {
    if (this.props.data) {
      this.setState({
        Ioj: this.props.data,
      });
    } else {
      const Ioj = this.state.Ioj;
      Ioj.OfferingId = this.props.OfferingId;

      this.setState({
        Ioj: Ioj,
        show: this.props.show,
      });
    }
  }

  render() {
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          className="modal fade"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            {!this.props.data ? (
              <h4 className="modal-title" id="dealprofile">
                Add IOJ
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile">
                Edit IOJ
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">
                      Category<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      onChange={this.onCategoryChange}
                      maxLength="60"
                      value={this.state.Ioj.IntelOpsCategoryName}
                    />
                    <span className="errorMsg">{this.state.errors.IntelOpsCategoryName}</span>
                  </div>


                  <div className="form-group col-lg-6">
                    <label for="">
                      Stage <span className="required">*</span>
                    </label>
                    <select
                      id="stageName"
                      className="form-control"
                      value={this.state.Ioj.IntelOpsStageName}
                      onChange={this.onStageChange.bind(this)}
                    >
                      <option value="">--Select--</option>
                      <option value="Foundation">Foundation</option>
                      <option value="Automated">Automated</option>
                      <option value="Insights">Insights</option>
                      <option value="Intelligent">Intelligent</option>
                    </select>
                    <span className="errorMsg">{this.state.errors.IntelOpsStageName}</span>
                  </div>
                </div>
              </div>

              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={this.onStatusChange}
                        defaultChecked={this.state.Ioj.IsActive}
                      />
                      <span className="checkmark"></span>
                      <span className="form-check-label">Is Active</span>
                    </label>

                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                {!this.props.data ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Create"
                  />
                ) : (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Update"
                  />
                )}
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
