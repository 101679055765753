import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { filterSelectedSubOffering, createBreadCrumbs } from '../Shared/commonFunctions';
import ReactTooltip from 'react-tooltip';
 import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
 import { store } from '../reducers/configureStore';
 import axiosInstance from '../Shared/interceptor';

// import { store } from '../reducers/configureStore';

class ContentHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      leverDescription: [],
      currentTabDescription: ''
    }
  }
  // constructor(props) {
  //   super(props);
  //   // this.state = { exploreLinkList: [], exploreDrop: 'none' };
  // }

  // viewMore() {
  //   const { filterDetails, SODetails } = this.props;
  //   const { exploreDrop } = this.state;
  //   const filterClient = filterDetails.Client;
  //   const filterProcess = filterDetails.process;
  //   const filterLocations = filterDetails.Locations;
  //   const { SOCategoryID } = SODetails[0];
  //   const filterDate = filterDetails.Month;

  //   const displayStatus = exploreDrop;
  //   if (String(displayStatus) === 'none') {
  //     axiosInstance
  //       .get(`${LocalApiBaseUrl}SO/GetSOCategoryLinks`, {
  //         params: {
  //           OrgEntityID: filterClient,
  //           OfferingID: filterProcess,
  //           LocationID: filterLocations,
  //           SOCategoryID,
  //           MonthDT: filterDate,
  //           LanguageID: store.getState().CurrentFilter.languageData.id,
  //         },
  //       })
  //       .then((response) => {
  //         const selectionBasedList = response.data.filter(
  //           (row) => row.SOCategoryID === SODetails[0].SOCategoryID,
  //         );

  //         const exploreLinkList = selectionBasedList.map((eachLinks) => (
  //           <a className="dropdown-item" href={eachLinks.LinkUrl} rel="noopener noreferrer" target="_blank">
  //             {eachLinks.LinkName}
  //           </a>
  //         ));

  //         this.setState({ exploreLinkList, exploreDrop: 'block' });
  //       })
  //       .catch((error) => {
  //         trycatchAlertPopup(error);
  //       });
  //   } else {
  //     this.setState({ exploreDrop: 'none' });
  //   }
  // }

  componentDidMount() {
    this.fetchLeverDescription();
  }

  componentDidUpdate(prevProps){
    const { TabName } = this.props;
    const {leverDescription} = this.state;
    if(prevProps.TabName !== TabName) {
      if(leverDescription.length === 0) {
        this.fetchLeverDescription();
      } else {
        let foundData = leverDescription.find((item) => item.tab === TabName)
        this.setState({
          currentTabDescription: foundData.description,
        })
      }
    }
  }

  fetchLeverDescription = () => {
    const { TabName } = this.props;
    axiosInstance
      .get(
        `${LocalApiBaseUrl}so/GetSOCategories`,
        { params: { LanguageID: store.getState().CurrentFilter.languageData.id } },
        // params: { OrgEntityID: filterClient, OfferingID: filterProcess, LocationID: filterLocations, MonthDT: todayDate }
      )
      .then((response) => {
        let tempData = [];
        let foundData = {};
        response.data.map((item) => {
          tempData.push({
            tab: item.SOCategoryName_En,
            description: item.SOCategoryDescription
          })
        })
        foundData = tempData.find((item) => item.tab === TabName)
        this.setState({
          leverDescription: tempData,
          currentTabDescription: foundData.description,
        })
      })
      .catch((err) => {
        //console.log(err);
      });
  }
  render() {
    const {
      titleDescription, filterDetails, DashboardSubOfferingData, DasboardOfferingData,TabName
    } = this.props;
    const {
      currentTabDescription
    } = this.state;

    return (
      <div className="col-lg-12">
        {createBreadCrumbs(DashboardSubOfferingData, filterDetails.offering, filterDetails.process, DasboardOfferingData) }

          <h2 className={TabName !== 'Business Outcomes' ? "ht-title dashboard-httitle lever-description-main" : "ht-title dashboard-httitle"}>
          {
            TabName !== 'Business Outcomes' &&
            <span className="lever-description" data-tip data-for="lever-description-tooltip"></span>
          }
          {titleDescription.tabName !== undefined ? titleDescription.tabName:this.props.TabName}</h2>

        <div className="lever-description-wrapper">
          <ReactTooltip place="right" effect="solid" id="lever-description-tooltip"> 
              <span>{currentTabDescription}</span> 
          </ReactTooltip>
        </div>
      </div>
    );
  }
}
ContentHeader.propTypes = {
  titleDescription: PropTypes.object.isRequired,
  filterDetails: PropTypes.object.isRequired,
  DashboardSubOfferingData: PropTypes.array.isRequired,
  DasboardOfferingData: PropTypes.array.isRequired,
  // SODetails: PropTypes.array.isRequired,
  currentFilter:PropTypes.object,
  TabName:PropTypes.string
};
const mapStateToProps = (state) => {
  return {
    currentFilter: state.CurrentFilter.SyncFilter,
    TabName:state.CurrentFilter.Tab.title,
    leverDescription: state.ClientAndFilterReducer.leverDescription,
  };
};

export default connect(
  mapStateToProps,
  ""
)(ContentHeader);

