import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import i18n from 'i18next';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl,idTokenstring } from '../Shared/Constant';
import { store } from '../reducers/configureStore';

import QueryBuilderPopUp from './QueryBuilderPopUp';
import SynopsLoader from '../Shared/SynopsLoader';
import UserAccessDeniedScreen from "../Shared/UserAccessDeniedScreen"
import './QueryBuilder.css';
import SubOfferingDropdowns from '../Shared/SubOfferingDropdowns';
import { filterSelectedSubOffering } from '../Shared/commonFunctions';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;


// Build for UAT Deployment
// QB Changes
class QueryBuilderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connection: false,
      offeringsOptionsList: [],
      subOfferingsOptions: [],
      locationOptions: [],
      enableConfigureMetic: true,
      userAccessDenied: false,
      metricType: '',
      dealfilterObj: {
        orgID: '',
        offerID: '',
        subOfferID: '',
        locID: '',
        langID: '',
        month: '',
      },
      dealQueryInfo: [],
      configuredMetricList: [],
      updateMetricObj: {},
      selectedConfMetricObj: {},
      searchString: '',
      offeringsListDataArr: [],
      showLoaderOnPage: false,
      SubofferingName: '',
    };

    this.handleOfferingsChange = this.handleOfferingsChange.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.handleSubOfferingsChange = this.handleSubOfferingsChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.closeConnection = this.closeConnection.bind(this);
    this.getConfiguredMetricList = this.getConfiguredMetricList.bind(this);
    this.updateConfiguredMetric = this.updateConfiguredMetric.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);
  }

  componentDidMount() {
    if (this.props.userAccessDeniedScreen === "Build Query") {
      this.setState({
        userAccessDenied: true
      })
    }
    else {
      const clientId = this.props.selectedClientId;
      if (clientId !== '' && clientId !== 0 && clientId !== undefined) {
        this.getOfferings(clientId);
      }
      this.getGetConnectorMasterQB();
    }


  }
  componentDidUpdate(prevProps) {
    if (prevProps.userAccessDeniedScreen !== this.props.userAccessDeniedScreen) {
      if (this.props.userAccessDeniedScreen === "Build Query") {
        this.setState({
          userAccessDenied: true
        })
      }
      else {
        this.setState({
          userAccessDenied: false
        })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const Client = nextProps.selectedClientId != undefined ? nextProps.selectedClientId : '';
    if (Client != undefined && Client != '') {
      this.getOfferings(Client);
    }
  }

  getGetConnectorMasterQB() {
    let source = [];
    axiosInstance.get(`${LocalApiBaseUrl}QueryBuilder/GetConnectorMasterQB`)
      .then((response) => {
        source = response.data;
        const sourceValues = source.map((x) => <option key={x.ConnectorID} value={x.ConnectorID}>{x.ConnectorName}</option>);
        store.dispatch({
          type: 'FETCH_SOURCE_CONNECTION_LIST',
          payload: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getOfferings(Client) {
    if (Client != undefined && Client > 0 && Client != '') {
      axiosInstance.get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        params: { OrgEntityID: Client, LanguageID: store.getState().CurrentFilter.languageData.id, featureName: 'Build Query' },
      }).then((response) => {
        const offeringsOptionsList = (response.data).map((dataOption, index) => (
          <option
            key={dataOption.OfferingID}
            id={dataOption.OfferingID}
            value={dataOption.OfferingID}
          >
            {dataOption.OfferingName}
          </option>
        ));
        this.setState({ offeringsOptionsList, offeringsListDataArr: response.data });
      })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  getConfiguredMetricList() {
    axiosInstance.get(`${LocalApiBaseUrl}QueryBuilder/GetMetricDetails`, {
      params: {
        clientId: this.props.selectedClientId, OfferingId: this.state.dealfilterObj.subOfferID, LocationId: this.state.dealfilterObj.locID, subofferingid: this.state.dealfilterObj.subOfferID,
      },

    }).then((response) => {
      this.setState({
        configuredMetricList: response.data,
      });
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  showConnection = (param) => {
    this.setState({
      connection: true,
      metricType: param,
    });
  }

  closeConnection() {
    this.setState({
      connection: false,
    });
  }

  updateConfiguredMetric(param, metObj) {
    this.setState({ showLoaderOnPage: true });
    axiosInstance.get(`${LocalApiBaseUrl}QueryBuilder/GetMetricConfiguration`, {
      params: { DealID: metObj.DealID, KPIMappingID: metObj.KPIMappingId },
    }).then((response) => {
      this.setState({
        updateMetricObj: response.data,
        connection: true,
        metricType: param,
        selectedConfMetricObj: metObj,
        showLoaderOnPage: false,
      });
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  handleOfferingsChange(event) {
    let subOfferingsOptions = []; let
      sub_offering;
    const selectedOffering = event.target.value;
    let availableSubOfferings = [];
    const dealFilterData = { ...this.state.dealfilterObj };
    if (selectedOffering != '0') {
      dealFilterData.offerID = selectedOffering;
      dealFilterData.orgID = this.props.selectedClientId;
      dealFilterData.langID = store.getState().CurrentFilter.languageData.id;
      this.setState({ locationOptions: [], configuredMetricList: [] });
      const offeringValues = this.state.offeringsListDataArr;
      if (offeringValues.length > 0 && offeringValues.filter((eachValue) => eachValue.OfferingID == event.target.value).length > 0) availableSubOfferings = offeringValues.filter((eachValue) => eachValue.OfferingID == event.target.value)[0].ChildOfferings;
      availableSubOfferings = offeringValues.filter((eachValue) => eachValue.OfferingID == event.target.value)[0].ChildOfferings;
      subOfferingsOptions = availableSubOfferings;
      const filtersSubofferingData = filterSelectedSubOffering(subOfferingsOptions, dealFilterData.subOfferID);
      const { firstSuboffering } = filtersSubofferingData;
      if (availableSubOfferings.length > 0) {
        sub_offering = firstSuboffering[0].OfferingID;
      } else {
        sub_offering = 0;
      }
      dealFilterData.subOfferID = sub_offering;
      if ((this.props.selectedClientId != 0) && (sub_offering != 0)) {
        this.getLocations(this.props.selectedClientId, sub_offering);
      }
      this.setState({
        subOfferingsOptions, dealfilterObj: dealFilterData, enableConfigureMetic: true, SubofferingName: firstSuboffering[0].Process,
      });
    } else {
      this.setState({
        subOfferingsOptions: [], locationOptions: [], enableConfigureMetic: true, configuredMetricList: [], SubofferingName: '',
      });
    }
  }

  getLocations(clientId, subOfferID) {
    let locationArr = [];
    const languageID = store.getState().CurrentFilter.languageData.id;
    axiosInstance.get(`${LocalApiBaseUrl}SOFilter/GetLocation`, {
      params: { OrgEntityID: clientId, OfferingID: subOfferID, LanguageID: languageID },
    }).then((response) => {
      locationArr = response.data;
      const locValues = locationArr.map((item) => <option key={item.LocationID} value={item.LocationID}>{item.City}</option>);
      this.setState({ locationOptions: locValues });
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  handleSubOfferingsChange(event) {
    if (event.target.value !== '' && event.target.value !== null && event.target.value !== undefined && event.target.value !== 0) {
      const filtersSubofferingData = filterSelectedSubOffering([...this.state.subOfferingsOptions], event.target.value);
      const selectedSuboffering = filtersSubofferingData.subofferingData[0];
      let SubofferingName = selectedSuboffering.Process;
      this.getLocations(this.props.selectedClientId, event.target.value);
      const dealFilterData = { ...this.state.dealfilterObj };
      dealFilterData.subOfferID = event.target.value;
      this.setState({
        dealfilterObj: dealFilterData, configuredMetricList: [], locationOptions: [], enableConfigureMetic: true, SubofferingName: SubofferingName
      });
    } else {
      const dealFilterData = { ...this.state.dealfilterObj };
      dealFilterData.subOfferID = event.target.value;
      this.setState({ dealfilterObj: dealFilterData, locationOptions: [], configuredMetricList: [], enableConfigureMetic: true, SubofferingName: '' });
    }
  }

  handleLocationChange(event) {
    const dealFilterData = { ...this.state.dealfilterObj };
    if (event.target.value !== '' && event.target.value !== null && event.target.value !== undefined && event.target.value !== '0') {
      dealFilterData.locID = event.target.value;
      const self = this;
      this.setState({ dealfilterObj: dealFilterData, enableConfigureMetic: false }, () => {
        self.getDealQuery(self.state.dealfilterObj);
        self.getConfiguredMetricList();
      });
    } else {
      this.setState({ enableConfigureMetic: true, configuredMetricList: [] });
    }
  }

  getDealQuery(dealfilterObj) {
    axiosInstance.get(`${LocalApiBaseUrl}SOFilter/GetDealInfo`, {
      params: {
        OrgEntityID: this.props.selectedClientId, OfferingID: dealfilterObj.subOfferID, LocationId: dealfilterObj.locID, LanguageID: dealfilterObj.langID,
      },
    }).then((response) => {
      this.setState({
        dealQueryInfo: response.data[0],
      });
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  deleteConfiguredMetric = (param) => {
    axiosInstance.post(`${LocalApiBaseUrl}QueryBuilder/DeleteMetricConfiguration?DealId=${param.DealID}&KPIMappingId=${param.KPIMappingId}`).then((response) => {
      if (response.data.IsSuccess) {
        this.getConfiguredMetricList();
      }
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  handleSearchInput(event) {
    this.setState({
      searchString: event.target.value,
    });
  }

  render() {
    const { featureData } = this.props;
    let _metricList = this.state.configuredMetricList;
    const search = this.state.searchString.trim().toLowerCase();
    if (search.length > 0) {
      _metricList = _metricList.filter((metric) => metric.MetricName.toLowerCase().match(search));
    }
    const editAccess = featureData.filter((data) => data.Submenu === "Build Query" && data.Operations.includes("Edit")).length > 0? true : false;
    const addAccess = featureData.filter((data) => data.Submenu === "Build Query" && data.Operations.includes("Add")).length > 0? true : false;
    return (
      <>
        <LoadingOverlay
          className="custom-loader"
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={this.state.showLoaderOnPage}
        />
        {
          !this.state.userAccessDenied &&
          <div className="business-filter query-builder-sec">
            <div className="d-flex justify-content-start">
              <div className="row d-flex align-items-center pl-4">
                <div className="col-lg-4">
                  <select id="offering" className="form-control" disabled={this.props.selectedClientId == '' || this.props.selectedClientId == '0'} onChange={(eve) => this.handleOfferingsChange(eve)}>
                    <option value="0">Select Offering</option>
                    {this.state.offeringsOptionsList}
                  </select>
                </div>
                <div className="col-lg-4">
                  <SubOfferingDropdowns
                    selectedSubOffering={this.state.SubofferingName}
                    subOfferings={this.state.subOfferingsOptions}
                    onSubOfferingChange={(e) => this.handleSubOfferingsChange(e)}
                    defaultToggleName={i18n.t('Select_Sub_Offerings')}
                    required={false}
                    labelClassName=""
                    labelfor=""
                    disabledValue={this.props.selectedClientId == '' || this.props.selectedClientId == '0'}
                    dropdownItem
                    labelpresent={false}
                    selectedSubOfferingId={this.state.dealfilterObj.subOfferID}
                    SubOfferingDropdownsClass={true}
                  />
                </div>
                <div className="col-lg-4">
                  <select id="location" className="form-control" disabled={this.props.selectedClientId == '' || this.props.selectedClientId == '0'} onChange={(eve) => this.handleLocationChange(eve)}>
                    <option value="0">Select Location</option>
                    {this.state.locationOptions}
                  </select>
                </div>
              </div>
            </div>
          </div>
        }
        {
          !this.state.userAccessDenied &&
          <div className="d-flex justify-content-between">
            <div className="searchBtn">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => this.handleSearchInput(e)}
                  value={this.state.searchString}
                  ref="search"
                />
              </div>
            </div>
            {addAccess &&
            <div className="metricBtn">
              <Button variant="outline-primary" className={this.state.enableConfigureMetic ? 'btn-sm disable' : 'btn-sm'} disabled={this.state.enableConfigureMetic} onClick={() => this.showConnection('CREATE')}>Configure Metric</Button>
            </div>
            }
          </div>
        }
        {
          !this.state.userAccessDenied ? this.state.configuredMetricList.length === 0
            ? (
              <div className="addSource mt-5 text-center">
                No Rows To Show
              </div>
            )
            : (
              <div className="ioj-full metricCardList">
                <div className="outcome-highlight no-whiteShadow no-table-layout">
                  {_metricList.map((confObj, ind) => (
                    <div className="outcome-divider metricGroup" key={confObj.KPIMappingId}>
                      <h1>
                        {`${confObj.MetricName} - `}
                        {' '}
                        {confObj.ISActive ? 'Active' : 'In-Active'}
                        {' '}
                      </h1>
                      <ul className="list-group list-group-horizontal">
                        <li className="list-group-item">{confObj.SOCategory}</li>
                        <li className="list-group-item">{confObj.SOSubCategory}</li>
                        <li className="list-group-item">{confObj.MetricDefinition}</li>
                      </ul>
                      <p>{confObj.Formulastring}</p>
                      {editAccess &&
                      <div className="overlay-item">
                        <div className="hover-center">
                          <span className="edit"><i className="fas fa-edit" onClick={() => this.updateConfiguredMetric('UPDATE', confObj)} /></span>
                        </div>
                      </div>
                      }
                    </div>
                  ))}
                </div>
              </div>
            ) : <UserAccessDeniedScreen />
        }
        {this.state.connection
          ? <QueryBuilderPopUp connection={this.state.connection} metricType={this.state.metricType} closeConnection={this.closeConnection} dealfilterObj={this.state.dealfilterObj} dealQueryInfo={this.state.dealQueryInfo} updateMetricObj={this.state.updateMetricObj} selectedConfMetricObj={this.state.selectedConfMetricObj} getConfiguredMetricList={this.getConfiguredMetricList} />
          : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAccessDeniedScreen: state.roleReducers.userDeniedScreen,
  };
};
export default connect(mapStateToProps)(QueryBuilderContainer);
