import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import {
  Tabs, Tab, TabPanel, TabList,
} from 'react-web-tabs';
import _ from 'lodash';
import classNames from 'classnames';

function OfferingsSubOfferingsSelectionModal(props) {
  const {
    onModalHide,
    offerings,
    functionalOfferings,
    industryOfferings,
    BATOfferingIcons,
    selectedOfferingId,
    selectedSubOfferingName,
  } = props;

  // BASED ON THE OFFERING ID FROM PROPS, FILTER OUT THE SELECTED OFFERING OBJ
  const foundSelectedOfferingObj = _.find(
    offerings,
    (eachOffering) => String(eachOffering.offeringId) === String(selectedOfferingId),
  );
  const [hoverOff, setHoverOff] = useState('');
  const [
    selectedOfferingObj,
    setSelectedOfferingObj,
  ] = useState(foundSelectedOfferingObj);
  const [
    selectedTab,
    setSelectedTab,
  ] = useState(foundSelectedOfferingObj.offeringType);

  function handleClickOffering(clickedOfferingId) {
    const foundOfferingObj = _.find(
      offerings,
      (eachOffering) => String(eachOffering.offeringId) === String(clickedOfferingId),
    );
    setSelectedOfferingObj(foundOfferingObj);
  }

  function handleHoverOffering(off) {
    setHoverOff(off);
  }

  function handleBlurOffering() {
    setHoverOff('');
  }

  function renderOfferingsList(offeringsArray) {
    return (
      _.map(offeringsArray, (eachOffering, index) => {
        const currentOfferingId = eachOffering.offeringId;
        const currentOfferingName = eachOffering.offering;
        const Image = BATOfferingIcons.filter(
          (obj) => obj.key.toLowerCase() === eachOffering.logo.toLowerCase(),
        )[0];

        return (
          <div key={`offering-${currentOfferingId}`}>
            <Tab
              tabFor={String(currentOfferingId)}
              key={`tab-${index}`}
              disabled={!eachOffering.isEnabled}
              onClick={() => handleClickOffering(currentOfferingId)}
            >
              <span
                className={`offering-label ${String(currentOfferingId) === String(selectedOfferingObj.id) ? 'active' : ''}`}
                onFocus={() => handleHoverOffering(currentOfferingName)}
                onMouseOver={() => handleHoverOffering(currentOfferingName)}
                onBlur={() => handleBlurOffering()}
                onMouseOut={() => handleBlurOffering()}
              >
                {Image ? (
                  <img
                    src={
                      hoverOff === currentOfferingName
                      || String(selectedOfferingObj.id) === String(currentOfferingId)
                        ? Image.iconWhite
                        : Image.iconBlack
                    }
                    alt={currentOfferingName}
                  />
                ) : (
                  <img alt="" />
                )}
                {currentOfferingName}
              </span>
            </Tab>
          </div>
        );
      })
    );
  }

  function renderSubOfferingsList(subOfferingsArray) {
    return (
      _.map(subOfferingsArray, (eachSubOffering) => {
        const currentSubOfferingName = eachSubOffering.key;
        const radioInputId = `subOffering-${currentSubOfferingName}`;

        let isSubOfferingSelected = false;
        if (String(selectedOfferingId) === String(selectedOfferingObj.offeringId)) {
          isSubOfferingSelected = currentSubOfferingName === selectedSubOfferingName;
        }

        return (
          <label htmlFor={radioInputId} className="form-check mr-3" key={radioInputId}>
            <input
              id={radioInputId}
              className="form-check-input"
              type="radio"
              value={currentSubOfferingName}
              onChange={() => onModalHide(selectedOfferingObj, eachSubOffering)}
              checked={isSubOfferingSelected}
              disabled={!eachSubOffering.isEnabled}
            />
            <span className="checkmark" />
            <span
              className={classNames('form-check-label', {
                'form-check-label-active': isSubOfferingSelected,
              })}
            >
              {currentSubOfferingName}
            </span>
          </label>
        );
      })
    );
  }

  function handleChangeOfferingTypeTab(tabId) {
    setSelectedTab(tabId);
  }

  const subOfferingsOfSelectedOffering = selectedOfferingObj.subOfferings;
  return (
    <Modal
      show
      centered
      onHide={() => onModalHide()}
      backdrop="static"
      dialogClassName="modal-90w"
      className="offeringCategoryModal"
    >
      <Tabs
        defaultTab={selectedTab}
        onChange={(tabId) => handleChangeOfferingTypeTab(tabId)}
      >
        <Modal.Header closeButton className="justify-content-center">
          <TabList>
            <Tab tabFor="Functional">Functional Offerings</Tab>
            <Tab tabFor="Industrial">Industry Offerings</Tab>
          </TabList>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <TabPanel tabId="Functional">
              <Tabs
                defaultTab={String(selectedOfferingObj.id)}
              >
                <div className="row col-lg-12">
                  <div className="col-lg-3 offeringName">
                    <TabList>
                      {renderOfferingsList(functionalOfferings)}
                    </TabList>
                  </div>

                  <div className="col-lg-9 subOfferingName">
                    <TabPanel tabId={String(selectedOfferingObj.id)}>
                      <div>
                        {selectedOfferingObj.offeringType === 'Functional'
                          && !_.isEmpty(subOfferingsOfSelectedOffering)
                          && renderSubOfferingsList(subOfferingsOfSelectedOffering)}
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </Tabs>
            </TabPanel>

            <TabPanel tabId="Industrial">
              <Tabs
                defaultTab={String(selectedOfferingObj.id)}
              >
                <div className="row col-lg-12">
                  <div className="col-lg-3 offeringName">
                    <TabList>
                      {renderOfferingsList(industryOfferings)}
                    </TabList>
                  </div>

                  <div className="col-lg-9 subOfferingName">
                    <TabPanel tabId={String(selectedOfferingObj.id)}>
                      <div>
                        {selectedOfferingObj.offeringType === 'Industrial'
                          && !_.isEmpty(subOfferingsOfSelectedOffering)
                          && renderSubOfferingsList(subOfferingsOfSelectedOffering)}
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </Tabs>
            </TabPanel>
          </div>
        </Modal.Body>
      </Tabs>
    </Modal>
  );
}

OfferingsSubOfferingsSelectionModal.propTypes = {
  onModalHide: PropTypes.func.isRequired,
  offerings: PropTypes.array.isRequired,
  functionalOfferings: PropTypes.array.isRequired,
  industryOfferings: PropTypes.array.isRequired,
  BATOfferingIcons: PropTypes.array.isRequired,
  selectedOfferingId: PropTypes.string.isRequired,
  selectedSubOfferingName: PropTypes.string.isRequired,
};

export default OfferingsSubOfferingsSelectionModal;
