import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import {
    LocalApiBaseUrl
} from "./Constant";
import { connect } from "react-redux";
import Moment from "moment";
import SynopsLogo_Transparent from "../Images/Synops-logo-transparent.svg"
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import parse from 'react-html-parser';

axiosInstance.defaults.headers.common[
    "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class StaticMessage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            messages: [],
            show: false,
            ShowNoNewMsg: false,
            pathLocation: this.props.pathLocation,
            currentDateTime: new Date(),
            doNotShow: false,
            currentPage: ""
        }
    }

    componentDidMount() {
        if (this.props.show) {
            this.getStaticMessage();
            this.setState({
                currentPage: this.props.pathLocation.indexOf("/Dashboard") == 0 ? "front door" : "configurator",
            })
        }
    }

    getStaticMessage() {
        let datedd = null;
        let date = new Date();
        datedd = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + Moment(date).format('hh:mm A')
        axiosInstance
            .get(`${LocalApiBaseUrl}MessageBoard/GetStaticMessages`, {
                params: {
                    loggedUserDateTime: datedd,
                    FuncAreaName: this.props.pathLocation.indexOf("/Dashboard") == 0 ? "front door" : "configurator",
                    LanguageID: store.getState().CurrentFilter.languageData.id,
                    IsRead: false
                },
            })
            .then((response) => {
                if (response.data != 0) {

                    let responseData = response.data;
                    if (this.state.currentPage == "front door") {
                        responseData = responseData.sort((a, b) => a.FrontSequence > b.FrontSequence ? 1 : -1);
                    }
                    else {
                        responseData = responseData.sort((a, b) => a.ConfSequence > b.ConfSequence ? 1 : -1);
                    }
                    this.setState({
                        show: true,
                        messages: responseData
                    })
                } else {
                    this.handleClose()
                }
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });

    }

    handleChange(name, value) {
        this.setState({ [name]: value })
    }

    handleClose = () => {
        if (this.state.doNotShow) {
            this.handleDoNotShowAgain();
        }
        if (this.state.pathLocation.indexOf("/Dashboard") == 0) {
            this.props.hideStaticMessage();
        } else if (this.state.pathLocation.indexOf("/admin/Configurator") == 0) {
            this.props.hideStaticMessage();
        }
        this.setState({
            messages: [],
            show: false,
            ShowNoNewMsg: false
        })
    }

    handleDoNotShowAgain() {
        let date = new Date();
        let fullDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + Moment(date).format('hh:mm A')
        let funcAreaName = this.state.pathLocation.indexOf("/Dashboard") == 0 ? "front door" : "configurator";
        axiosInstance
            .post(`${LocalApiBaseUrl}MessageBoard/AddUserCheckedChecdBoxMessages?FuncAreaName=${funcAreaName}&lastReaddatetime=${fullDate}`)
            .then(
                (response) => {

                },
                (error) => {
                    trycatchAlertPopup(error);
                }
            )
            .catch((error) => {
                trycatchAlertPopup(error);
            });

    }

    render() {
        return (
            <>
                {this.state.show ? (
                    <Modal
                        show={this.state.show}
                        onHide={this.handleClose}
                        className="modal fade welcomeMessageModal"
                        size="medium"
                        backdrop={false}
                        centered
                    >
                        <Modal.Body>
                            <div className="message-board-header">
                                <img
                                    src={SynopsLogo_Transparent}
                                    alt="Synops-logo"
                                    width="40"
                                    height="40"
                                />
                                <h3>What's New</h3>
                            </div>
                            <div className="message-board-messages">
                                <ul>
                                    {this.state.messages.map((row) => {
                                        let desc = row.Description.replaceAll("&nbsp;", "").replaceAll("<br>", " ");
                                        return (
                                            <li value={row.ID}>
                                                <h4>{row.Header}</h4>
                                                <div>
                                                    {desc && typeof desc === 'string' ? parse(desc) : null}
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="modal-btn message-checkbox">
                                <div>
                                    <label className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="doNotShow"
                                            defaultChecked={this.state.doNotShow}
                                            onChange={(e) => this.handleChange(e.target.name, e.target.checked)}
                                        />
                                        <span className="checkmark"></span>
                                        <span className="form-check-label">Do not show until new message arrives</span>
                                    </label>
                                </div>
                                <button
                                    className="btn btn-default btn-sm mr-2"
                                    onClick={this.handleClose}
                                >
                                    Close
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                ) : null}
            </>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        languageData: state.CurrentFilter.languageData,
        clientData: state.CurrentFilter.ClientData,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(StaticMessage);
