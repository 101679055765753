import {ADD_BODATA_FOR_IOJ} from "../actions/JourneyActions";


export let BOReducers = (state={
    BO:[],
    selectedMetriCCommentHistory:{SOCategoryID:"",KPIMappingID:"",showHistory:false}
},action)=>{
switch(action.type){ 
    case ADD_BODATA_FOR_IOJ :{          
        state = {...state}
        state["BO"] = action.payload            
        return state
    }

    case "INITIALISE_BO" :{
            
            state = {...state}
            state["BO"] = action.payload            
            
            state["BO"]= state["BO"].map(eachDetails => {
                eachDetails.kpiData.sort((a, b) => (a.MetricSeq !== b.MetricSeq ? ((a.MetricSeq > b.MetricSeq) ? 1 : -1) : (a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1)))
                return eachDetails
            })
            return state
        }
    case "ADD_BO":{
        state = {...state}            
           if(state["BO"].filter((x)=> x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID).length > 0){
            state["BO"].filter((x)=>x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData = state["BO"].filter((x)=>x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.filter((data)=> data.KPIMappingID!= action.payload.kpiData.KPIMappingID);   
            state["BO"].filter((x)=>x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.push(action.payload.kpiData);
        }else{
            state["BO"].filter((x)=>x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.push(action.payload.kpiData);
        }
        state["BO"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.sort((a, b) => (a.MetricSeq !== b.MetricSeq ? ((a.MetricSeq > b.MetricSeq) ? 1 : -1) : (a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1)))
         return state 
    }
    case "DELETE_BO" :{
        state = {...state}            
        state["BO"].filter((x)=>x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData = state["BO"].filter((x)=>x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.filter((data)=> data.KPIMappingID!= action.payload.kpiData.KPIMappingID) 
        return state
    }
     case "ADD_BO_DELIVER_ENT_VAL" :{
        
         state = {...state}            
           state["BO"].filter((x)=> x.SOCategoryName =='Deliver Enterprise Value')[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.KPIMappingID).length > 0 ?
           state["BO"].filter((x)=>x.SOCategoryName =='Deliver Enterprise Value')[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.KPIMappingID)[0] = action.payload :
           state["BO"].filter((x)=>x.SOCategoryName =='Deliver Enterprise Value')[0].kpiData.push(action.payload)
        state["BO"].filter((x) => x.SOCategoryName == 'Deliver Enterprise Value')[0].kpiData.sort((a, b) => (a.MetricSeq !== b.MetricSeq ? ((a.MetricSeq > b.MetricSeq) ? 1 : -1) : (a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1)))
         return state 
     }


    case "ADD_BO_END_USER_EXP" :{
        
        state = {...state}            
        state["BO"].filter((x)=> x.SOCategoryName =='End-user Experience / Touchless Processing / Process Efficiency')[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.KPIMappingID).length > 0 ?
           state["BO"].filter((x)=>x.SOCategoryName =='End-user Experience / Touchless Processing / Process Efficiency')[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.KPIMappingID)[0] = action.payload :
           state["BO"].filter((x)=>x.SOCategoryName =='End-user Experience / Touchless Processing / Process Efficiency')[0].kpiData.push(action.payload)
        state["BO"].filter((x) => x.SOCategoryName == 'End-user Experience / Touchless Processing / Process Efficiency')[0].kpiData.sort((a, b) => (a.MetricSeq !== b.MetricSeq ? ((a.MetricSeq > b.MetricSeq) ? 1 : -1) : (a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1)))
        return state
    }

    case "ADD_BO_MANAGING_RISK" :{
        
        state = {...state}            
        state["BO"].filter((x)=> x.SOCategoryName =='Managing Risk and Compliance')[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.KPIMappingID).length > 0 ?
           state["BO"].filter((x)=>x.SOCategoryName =='Managing Risk and Compliance')[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.KPIMappingID)[0] = action.payload :
           state["BO"].filter((x)=>x.SOCategoryName =='Managing Risk and Compliance')[0].kpiData.push(action.payload)
        state["BO"].filter((x) => x.SOCategoryName == 'Managing Risk and Compliance')[0].kpiData.sort((a, b) => (a.MetricSeq !== b.MetricSeq ? ((a.MetricSeq > b.MetricSeq) ? 1 : -1) : (a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1)))
        return state
    }

    case "DELETE_BO_END_USER_EXP" :{
        state = {...state}            
        state["BO"].filter((x)=>x.SOCategoryName =='End-user Experience / Touchless Processing / Process Efficiency')[0].kpiData = state["BO"].filter((x)=>x.SOCategoryName =='End-user Experience / Touchless Processing / Process Efficiency')[0].kpiData.filter((data)=> data.KPIMappingID!= action.payload.KPIMappingID)  
        return state
    }
    case "DELETE_BO_DELIVER_ENT_VAL" :{
        
        state = {...state}            
        state["BO"].filter((x)=>x.SOCategoryName =='Deliver Enterprise Value')[0].kpiData = state["BO"].filter((x)=>x.SOCategoryName =='Deliver Enterprise Value')[0].kpiData.filter((data)=> data.KPIMappingID!= action.payload.KPIMappingID) 
        
        return state
    }

    case "DELETE_BO_MANAGING_RISK" :{
        
        state = {...state}            
        state["BO"].filter((x)=>x.SOCategoryName =='Managing Risk and Compliance')[0].kpiData = state["BO"].filter((x)=>x.SOCategoryName =='Managing Risk and Compliance')[0].kpiData.filter((data)=> data.KPIMappingID!= action.payload.KPIMappingID) 
        
        return state
    }
    case "SELECTED_METRIC_COMMENT_HISTORY" :{
        state = {...state}  
        state.selectedMetriCCommentHistory = action.payload;       
        return state
    }
    
    default : return state
}
}
