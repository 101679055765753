import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { AgGridReact } from "ag-grid-react";
import MetricDetailPopup from "./ApprovalPopup";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import _ from "lodash";
import i18n from 'i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class MetricDetailList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SelectAll: null,
      filterTextState: {
        SOPageName: "",
        ClientName: "",
        Location: "",
        offering: "",
        suboffering: "",
        categoryname: "",
        metricname: "",
        description: "",
        unit: "",
        requestedby: "",
        status: "",
        requestorremarks: "",
        approverremarks: "",
      },
      columnNames: [
        "SOPageName",
        "ClientName",
        "Location",
        "offering",
        "suboffering",
        "categoryname",
        "metricname",
        "description",
        "unit",
        "requestedby",
        "status",
        "requestorremarks",
        "approverremarks",
      ],

      Approval: {
        DealApprovalID: [],
        ApprovalType: null,
        ApprovalAction: false,
      },
      CanApprove: false,
      CanReject: false,
      action: "",
      rowSelected: [],
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      prevLength: "",
      flag: false,
      columnDefs: [
        {
          headerName: "",
          resizable: true,
          suppressFilter: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          checkboxSelection: (params) => String(params.data.Status) === "Pending",
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 100,
        },

        {
          headerName: " SOPage Name",
          field: "SOPageCategoryName",
          colId: "SOPageName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: " Client Name",
          field: "ClientName",
          colId: "ClientName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: " Location",
          colId: "Location",
          field: "Location",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: " Offering",
          colId: "offering",
          field: "Offering",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: " SubOffering",
          colId: "suboffering",
          field: "SubOffering",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: " Category Name",
          colId: "categoryname",
          field: "SoCategoryName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: " Metric Name",
          colId: "metricname",
          field: "MetricName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Description",
          colId: "description",
          field: "KpiDescription",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Unit",
          colId: "unit",
          field: "MetricUnit",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: " Requested By",
          colId: "requestedby",
          field: "RequestUser",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Requested Date",
          colId: "requesteddate",
          field: "RequestedDate",
          resizable: true,
          suppressFilter: true,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Metrics Approved/Denied Date",
          colId: "approvedate",
          field: "ActionDate",
          resizable: true,
          suppressFilter: true,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Status",
          colId: "status",
          field: "Status",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: " Requestor Remarks",
          colId: "requestorremarks",
          field: "BusinessReason",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: " Approver Remarks",
          colId: "approverremarks",
          field: "ApproverRemarks",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
      ],
      autoGroupColumnDef: {
        headerName: "hello",
        field: "DealName",
        width: 200,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },

      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",

        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.decline = this.decline.bind(this);
    this.onBtnExport = this.onBtnExport.bind(this);
  }

  handleSetFilterText = (name) => {
    var filterComponent = this.gridApi.getFilterInstance(name);
    filterComponent.setModel({
      type: "contains",
      filter: this.state.filterTextState[name],
      filterTo: this.state.filterTextState[name],
    });
    this.gridApi.onFilterChanged();
  };

  onRowSelected(event) {
    let { rowSelected } = this.state;
    if (String(event.data.Status) === "Pending") {
      if (event.node.selected) {
        rowSelected.push(event.data);
        this.setState({ rowSelected: rowSelected });
      } else {
        rowSelected = rowSelected.filter((row) => row.DealApprovalID !== event.data.DealApprovalID);
        this.setState({ rowSelected: rowSelected });
      }
    }
  }

  onCheckboxClicked(e, data) {
    let { rowSelected } = this.state;
    if (e.target.checked) {
      rowSelected.push(data);
    } else {
      this.setState({
        rowSelected: rowSelected.filter((row) => row.DealApprovalID !== data.DealApprovalID),
      });
    }
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  dealpopup(e) {
    if (
      this.props.featureData.filter((data) => String(data.Submenu) === "Metric" && data.Operations.includes("Approve"))
        .length > 0
    ) {
      e.preventDefault();
      this.setState({ viewmore: true, action: "Approve" })
    } else window.alert("Access Denied !!");
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  getApproval() {
    axiosInstance
      .get(`${LocalApiBaseUrl}DealTemplate/GetMatricDetailRequestedForApproval`, {
        params: {},
      })
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
        if (!_.isNil(this.gridApi)) {
          this.state.columnNames.forEach((item) => {
            this.handleSetFilterText(item);
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    if (
      this.props.featureData.filter((data) => String(data.Submenu) === "Metric" && data.Operations.includes("Reject"))
        .length > 0
    ) {
      this.setState({ CanReject: true });
    }
    if (
      this.props.featureData.filter((data) => String(data.Submenu) === "Metric" && data.Operations.includes("Approve"))
        .length > 0
    ) {
      this.setState({ CanApprove: true });
    }
    this.getApproval();
    this.setState({
      rowSelected: [],
    });
  }

  // componentWillMount() {
  //   this.setState({
  //     rowSelected: [],
  //   });

  //   this.getApproval();
  // }

  decline(e) {
    let { rowSelected, columnNames } = this.state;
    if (
      this.props.featureData.filter((data) => String(data.Submenu) === "Metric" && data.Operations.includes("Reject"))
        .length > 0
    ) {
      if (rowSelected.length > 1) {
        window.alert("User is advised to select one row at a time");
      } else {
        e.preventDefault();
        this.setState({ viewmore: true, action: "Reject" });
        let s = this.state;
        columnNames.forEach((item) => {
          s.filterTextState[item] = this.gridApi.getFilterInstance(item).filterText;
        });
        this.setState({
          filterText: s.filterTextState,
        });
      }
    } else window.alert("Access Denied !!");
  }
  handleSave(data) {
    let { columnNames } = this.state;

    axiosInstance
      .post(`${LocalApiBaseUrl}DealTemplate/PostMatricDetailApproval`, data)
      .then((response) => {
        window.alert(response.data.StatusMessage);
        let s = this.state;
        columnNames.forEach((item) => {
          s.filterTextState[item] = this.gridApi.getFilterInstance(item).filterText;
        });
        this.setState({
          filterText: s.filterTextState,
        });

        this.getApproval();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
    this.setState({
      rowSelected: [],
    });
  }
  onBtnExport() {
    let params = {
      columnKeys: [
        "SOPageName",
        "ClientName",
        "Location",
        "offering",
        "suboffering",
        "categoryname",
        "metricname",
        "description",
        "unit",
        "requestedby",
        "requesteddate",
        "approvedate",
        "status",
        "requestorremarks",
        "approverremarks",
      ],
      columnGroups: true,
      allColumns: false,
      skipHeader: false,
      fileName: "Metric",
    };
    this.state.gridOptions.api.exportDataAsCsv(params);
  }
  onGridReady = (params) => {
    this.gridApi = params.api;

    this.gridColumnApi = params.columnApi;
  };
  render() {
    const { featureData } = this.props;
    const {
      autoGroupColumnDef,
      gridOptions,
      columnDefs,
      rowData,
      viewmore,
      rowSelected,
      CanApprove,
      CanReject,
      action,
    } = this.state;

    return (
      <>
        <div className="main-data">
          <div className="tableFilter">
            <div className="form-section">
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    id="filter-text-box"
                    placeholder="Filter..."
                    onChange={(e) => {
                      this.onFilterTextBoxChanged(e);
                    }}
                  />
                </div>
                <div className="ml-auto mr-1">
                  {featureData.filter(
                    (data) => String(data.Submenu) === "Metric" && data.Operations.includes("Download")
                  ).length > 0 ? (
                    <span className="btn btn-primary btn-sm py-1" onClick={this.onBtnExport}>
                      <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Download
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ag-theme-balham ag-table">
                <AgGridReact
                  enableSorting={true}
                  enableFilter={true}
                  pagination={true}
                  paginationPageSize={50}
                  floatingFilter={true}
                  autoGroupColumnDef={autoGroupColumnDef}
                  onRowSelected={this.onRowSelected.bind(this)}
                  gridOptions={gridOptions}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  onGridReady={this.onGridReady}
                  onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                ></AgGridReact>
              </div>
            </div>
          </div>
          {viewmore ? (
            <MetricDetailPopup
              action={action}
              data={rowSelected}
              show={viewmore}
              onClose={this.onClose}
              onSave={this.handleSave}
            />
          ) : null}

          <div className="text-center mt-3 mb-2">
            {rowSelected.length > 0 && CanApprove ? (
              <button
                className="btn btn-primary btn-sm mx-2"
                onClick={(e) => {
                  this.dealpopup(e);
                }}
              >
                Approve
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm mx-2"
                disabled={true}
                onClick={(e) => {
                  this.dealpopup(e);
                }}
              >
                Approve
              </button>
            )}
            {rowSelected.length > 0 && CanReject ? (
              <button
                className="btn btn-primary btn-sm mx-2"
                onClick={(e) => {
                  this.decline(e);
                }}
              >
                Decline
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm mx-2"
                disabled={true}
                onClick={(e) => {
                  this.decline(e);
                }}
              >
                Decline
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}
