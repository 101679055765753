import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import _ from "lodash";
import { MetricValueThousandFormat, thousandSeparatorFormat } from "../Shared/Common";

export default class MetricComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      info: this.props.info, // assign props data to info object
    };
  }
  handleHide = () => {
    this.setState({ show: false });
    this.props.onClose();
  };

  render() {
    const { info, isDD, isIOJ, isRelatorBlock } = this.props;
    const { show } = this.state;
    let BenchmarkName = "";
    let BenchmarkValue = "";
    let BenchmarkDD = isDD !== undefined ? isDD : false;
    let IOJ = isIOJ !== undefined ? isIOJ : false;
    // assign props data to info object
    if (info.metDataAttr != null) {
      BenchmarkName = info.metDataAttr.map((e) => {
        return e.AttributeName;
      });
      BenchmarkValue = info.metDataAttr.map((e) => {
        return e.AttributeValue;
      });
    }
    let RelatorBlock = _.isNil(isRelatorBlock) ? false : isRelatorBlock;
    let metricName = info.MetricName;
    let currentValue =
      info.currentValue != null && String(info.currentValue) !== "" && String(info.currentValue) !== "NA"
        ? info.currentValue
        : 0;
    let baseLineValue = info.baseLineValue;
    let targetValue = info.targetValue;

    let Description =
      /* info.MetricDataDesp === "" || info.MetricDataDesp === null || info.MetricDataDesp === undefined
				? info.KPIDesp
				: info.MetricDataDesp || "NA"; */
      info.KPIDesp ? info.KPIDesp : "Metric Description Not Available";

    let comments = info.Comments ? info.Comments : i18n.t("Comments_Not_Available");
    let fte = info.FTEReleased ? info.FTEReleased : "FTE Released not available"
    let impact = info.Impact ? info.Impact : "Impact not available"
    let toolName =
      !_.isNil(info.ApplicationMappingName) && info.ApplicationMappingName.length > 0
        ? info.ApplicationMappingName
        : "";

    let projectName = !_.isNil(info.ProjectName) && info.ProjectName.length > 0 ? info.ProjectName : "";

    let isMultiLevel = info.IsMultiLevel;
    let metricData = info.MetricValue;
    let unit = info.MetricUnit;
    let iojUnit = info.ImpactedUnit != null && String(info.ImpactedUnit) !== "NA" ? info.ImpactedUnit : "";
    
    return (
      <Modal
        show={show}
        onHide={this.handleHide.bind(this)}
        backdrop="static"
        size="medium"
        className="modal fade v-center bo-dashboard-modal"
        id="dealpopup"
      >
        <Modal.Header className="no-border" closeButton>
          <h5 class="modal-title" id="dealprofile">
            {i18n.t(metricName)}
          </h5>
        </Modal.Header>
        <Modal.Body>
          

          <div className="form-group">
            
            {!IOJ && (
              <>
                <label className="infoModalLabel">{i18n.t("Metric_Value")}</label>
                <p className="infoModalText">{MetricValueThousandFormat(unit, metricData)}</p>
                <label className="infoModalLabel">{i18n.t("Metric_Description")}</label>
                <p className="infoModalText">{i18n.t(Description)}</p>
                {this.props.isMachine && 
                <>
                <label className="infoModalLabel">{i18n.t("Impact")}</label>
                <p className="infoModalText">{impact}</p>
                <label className="infoModalLabel">{i18n.t("FTE Released")}</label>
                <p className="infoModalText">{fte}</p>
                </> }
                <label className="infoModalLabel">{i18n.t("Comment")}</label>
                <p className="infoModalText">{comments}</p>
              </>
            )}

            {RelatorBlock && (
              <>
                {BenchmarkValue.length > 0 &&
                  (BenchmarkValue[0] !== "NA" ||
                    BenchmarkValue[1] !== "NA" ||
                    (BenchmarkValue[0] !== "NA" && BenchmarkValue[1] !== "NA")) && (
                    <>
                      <label className="infoModalLabel">{i18n.t("Benchmark Values")}</label>
                    </>
                  )}
                <div className="categoryType">
                  <ul className="info-metric-span">
                    {BenchmarkValue.length > 0 && BenchmarkValue[0] !== "NA" && (
                      <>
                        <li>
                          <span className="info-categoryname">{BenchmarkName[0]}</span>
                          <span className="info-categoryvalue">{thousandSeparatorFormat(BenchmarkValue[0])}</span>
                        </li>
                      </>
                    )}

                    {BenchmarkValue.length > 0 && BenchmarkValue[1] !== "NA" && (
                      <>
                        <li>
                          <span className="info-categoryname">{BenchmarkName[1]}</span>
                          <span className="info-categoryvalue">{thousandSeparatorFormat(BenchmarkValue[1])}</span>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </>
            )}
            {BenchmarkDD && (
              <>
                <label className="infoModalLabel">{i18n.t("Benchmark Values")}</label>
                <div className="categoryType">
                  <ul className="info-metric-span">
                    <li>
                      <span className="info-categoryname">{BenchmarkName[0]}</span>
                      <span className="info-categoryvalue">{thousandSeparatorFormat(BenchmarkValue[0])}</span>
                    </li>
                    <li>
                      <span className="info-categoryname">{BenchmarkName[1]}</span>
                      <span className="info-categoryvalue">{thousandSeparatorFormat(BenchmarkValue[1])}</span>
                    </li>
                  </ul>
                </div>
              </>
            )}
            {IOJ && (
              <>
                <div className="categoryType">
                  <label className="infoModalLabel">{i18n.t("Metric Current Value")}</label>
                  <p className="infoModalText">
                    {String(iojUnit) !== ""
                      ? MetricValueThousandFormat(iojUnit, currentValue)
                      : thousandSeparatorFormat(currentValue)}
                  </p>
                  <label className="infoModalLabel">{i18n.t("Metric Baseline Value")}</label>
                  <p className="infoModalText">
                    {baseLineValue != null && String(baseLineValue) !== ""
                      ? thousandSeparatorFormat(baseLineValue)
                      : "NA"}
                  </p>
                  <label className="infoModalLabel">{i18n.t("Metric Target Value")}</label>
                  <p className="infoModalText">
                    {targetValue != null && String(targetValue) !== "" ? thousandSeparatorFormat(targetValue) : "NA"}
                  </p>
                </div>
              </>
            )}

            {toolName.length > 0 && (
              <>
                <label className="infoModalLabel">{i18n.t("Tool")}</label>
                <p className="infoModalText">{toolName}</p>
              </>
            )}
            {projectName.length > 0 && (
              <>
                <label className="infoModalLabel">{i18n.t("Project")}</label>
                <p className="infoModalText">{projectName}</p>
              </>
            )}
            {isMultiLevel && (
              <div className="categoryType">
                {info.CategoryDetails.length > 0 && (
                  <label className="infoModalLabel">
                    {info.CategoryDetails[0].CategoryType} {i18n.t("Category")}
                  </label>
                )}

                <ul className="info-metric-span">
                  {info.MetricUnit !== "%" &&
                    info.CategoryDetails.map(function(item, i) {
                      return (
                        <li key={i}>
                          <span className="info-categoryname">{item.CategoryName}</span>

                          <span className="info-categoryvalue">
                            {thousandSeparatorFormat(item.NumeratorValue) % 1 !== 0
                              ? MetricValueThousandFormat(unit, item.NumeratorValue, 2)
                              : MetricValueThousandFormat(unit, item.NumeratorValue, 2)}
                            {item.Denominator && <span> | </span>}

                            {item.Denominator
                              ? thousandSeparatorFormat(item.Denominator) % 1 !== 0
                                ? MetricValueThousandFormat(unit, item.Denominator, 2)
                                : MetricValueThousandFormat(unit, item.Denominator, 2)
                              : ""}
                          </span>
                        </li>
                      );
                    })}

                  {info.MetricUnit === "%" &&
                    info.CategoryDetails.map(function(item, i) {
                      return (
                        <li key={i}>
                          <span className="info-categoryname">{item.CategoryName}</span>

                          <span className="info-categoryvalue">
                            {thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator) % 1 !== 0
                              ? thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator, 2)
                              : thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator, 2)}{" "}
                            {info.MetricUnit}
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
