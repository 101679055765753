import React from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import classNames from "classnames";
import i18n from "i18next";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { regexForFields } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import { regexForTextAreas } from "../Shared/Constant";
import { isValidText, InvalidDescription } from "../Shared/commonFunctions";

class KpiPopUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: this.props.show,
      apivalue: "",
      unitsData: [],
      filter: { OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null },
      errors: {}, formIsValid: false,
      isVisibleDenominatorDefinition: false,
      isEditableMetricDefinition: true,
      isNumeratorClicked: true,
      defaultIsRequiredLeadingPractice: true,
      isNumeratorReqd: true,
      isDisabledNumeratorDefinitionField: false,

      isDenomReqd: true,
      metDefAvl: true,
      kpiData: {
        BusinessReason: "",
        MetricName: "",
        MetricUnit: "",
        KpiDescription: "",
        LeadingPractice: "",
        NumeratorDescription: null,
        DenominatorDescription: null,
      },
      InvalidDescriptionText: InvalidDescription(),
    };
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onUnitChange = this.onUnitChange.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleRejectHide = this.handleRejectHide.bind(this);
    this.handleApproveHide = this.handleApproveHide.bind(this);
    this.handleSave = this.handleSave.bind(this);
    let offeringValue = "";
    let value1 = [];
  }

  componentDidMount() {

    if (this.props.tabName === "BO" || this.props.tabName === "II" || this.props.tabName === "DD")
      this.setState({
        defaultIsRequiredLeadingPractice: true,
        isNumeratorReqd: true,
        isDenomReqd: true
      });

    else
      this.setState({
        defaultIsRequiredLeadingPractice: false,
        isNumeratorReqd: false,
        isDenomReqd: false
      });
    let unitsData = this.props.unitsData;

    this.setState({ unitsData: unitsData });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.unitsData !== this.props.unitsData) {
      this.setState({ unitsData: this.props.unitsData })
    }
  }

  onNameChange(event) {
    let errors = {};
    this.setState({
      errors: errors
    });
    const kpiData = this.state.kpiData;
    kpiData.MetricName = event.target.value
    this.setState({
      kpiData: kpiData
    })
  }

  onDescriptionChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const kpiData = this.state.kpiData;
    kpiData.KpiDescription = event.target.value;
    this.setState({
      kpiData: kpiData,
    });
  }

  onUnitChange(event) {
    let errors = {};
    this.setState({
      errors: errors
    });
    const kpiData = this.state.kpiData;
    kpiData.MetricUnit = event.target.options[event.target.selectedIndex].value;
    this.setState({
      kpiData: kpiData
    },
      () => {
        this.toggleRequiredMetricDefinitionFields();
      }

    );


  }

  toggleRequiredMetricDefinitionFields = () => {
    const kpiData = this.state.kpiData;

    if (
      this.props.tabName == "BO" ||
      this.props.tabName == "DD" ||
      this.props.tabName == "II"
    ) {
      if (kpiData.MetricUnit.trim() === "%") {
        this.setState({
          isDisabledNumeratorDefinitionField: true,
          isNumeratorClicked: false,
          isVisibleDenominatorDefinition: true,
        });
      } else {
        this.setState({
          isDisabledNumeratorDefinitionField: false,
          isNumeratorClicked: true,
          isVisibleDenominatorDefinition: false,
        });
      }
    }
  };

  onRemarksChange(event) {
    this.setState({ errors: {} });
    const kpiData = this.state.kpiData;
    kpiData.BusinessReason = event.target.value;
    this.setState({
      kpiData: kpiData,
    });
  }
  handleSave(e) {
    if (this.validateForm()) {
      var filter = {
        LocationID: this.props.filterDetails.Locations,
        OrgEntityID: this.props.filterDetails.Client,
        OfferingID: this.props.filterDetails.process,
        SOCategoryID: this.props.SOCategoryID,
      };
      this.state.kpiData.MetricName = this.state.kpiData.MetricName.trim();
      this.state.kpiData.MetricUnit = this.state.kpiData.MetricUnit.trim();

      //If metric def avl no chosen insert Definition is not available to the back end
      if (!this.state.metDefAvl) {
        const kpiData = this.state.kpiData;
        //if def avl no chosen, for BO II DD if metric unit % then insert def as 'def not avl' in both numerator and denom def

        //else previous implementation- 'def not avl' text only in numerator
        if (kpiData.MetricUnit === "%") {
          if (
            this.props.tabName == "BO" ||
            this.props.tabName == "II" ||
            this.props.tabName == "DD"
          ) {
            kpiData.NumeratorDescription = "Definition is not available";
            kpiData.DenominatorDescription = "Definition is not available";
          } else {
            kpiData.NumeratorDescription = "Definition is not available";
            kpiData.DenominatorDescription = "";
          }
        } else {
          kpiData.NumeratorDescription = "Definition is not available";
          kpiData.DenominatorDescription = "";
        }
        this.setState({
          kpiData: kpiData,
        });
        
      } else {
        //If met def avl yes chosen and no data entered in HM and WO insert Definition is not available to the back end

        if (
          (this.props.tabName == "HM" || this.props.tabName == "WO") &&
          (!this.state.kpiData.DenominatorDescription &&
            !this.state.kpiData.NumeratorDescription)
        ) {
          const kpiData = this.state.kpiData;
          kpiData.NumeratorDescription = "Definition is not available";
          kpiData.DenominatorDescription = "Definition is not available";

          this.setState({
            kpiData: kpiData,
          });
        }
      }

      //clear out denominator if numerator def radio button selected
      if (this.state.isNumeratorClicked) {
        const kpiData = this.state.kpiData;
        kpiData.DenominatorDescription = "";
        this.setState({
          kpiData: kpiData,
        });
      }
 
      this.PostMetricCall(filter);
     
      
  }


  }

  PostMetricCall = (filter) =>{
    var data = {
      DealTemplateApprovalModel: this.state.kpiData,
      FiltersIDsVM: filter,
    };
    this.setState({ showLoaderonPage: true });
    axiosInstance
      .post(`${LocalApiBaseUrl}DealTemplate/PostMatricDetailRequestedForApproval`, data)
      .then((response) => {
        this.setState({ showLoaderonPage: false });
        alert(i18n.t("Sent_for_Approval"));
        this.handleHide();
      })
      .catch((error) => {
        this.setState({ showLoaderonPage: false });
        this.handleHide();
        trycatchAlertPopup(error);

      })

  }

  OnNumeratorChange = (event) => {
    const kpiData = this.state.kpiData;
    kpiData.NumeratorDescription = event.target.value;
    this.setState({
      kpiData: kpiData,
    });
  };

  OnDenominatorChange = (event) => {
    const kpiData = this.state.kpiData;
    kpiData.DenominatorDescription = event.target.value;
    this.setState({
      kpiData: kpiData,
    });
  };

  handleMetricDefAvlRadioButtonChange = (event) => {
    
    if (event.target.value === "Yes")
      this.setState({
        isEditableMetricDefinition: true,
        metDefAvl: true,
      });
    if (event.target.value === "No")
      this.setState({
        isEditableMetricDefinition: false,
        metDefAvl: false,
      });
  };

  handleRadioButtonChange = (event) => {
    
    if (event.target.value === "numeratorDefinition")
      this.setState({
        isVisibleDenominatorDefinition: false,
        isNumeratorClicked: true,
      });
    if (event.target.value === "numeratorAndDenominatorDefinition")
      this.setState({
        isVisibleDenominatorDefinition: true,
        isNumeratorClicked: false,
      });
  };

  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }

  handleChangeLeadingPractice = (e) => {
    const kpiDat = this.state.kpiData;
    kpiDat.LeadingPractice = e.target.value;
    this.setState({
      kpiData: kpiDat,
    });
  };

  validateForm() {
    const { InvalidDescriptionText, kpiData } = this.state;
    let errors = {};
    let formIsValid = true;

    if (!kpiData["MetricName"].trim()) {
      formIsValid = false;
      errors["MetricName"] = i18n.t("addWoErrorM1");
    } else {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      //  var regex = /^[A-Za-z0-9  @ $ % & () - _ , . ? ’ ”]+$/
      //Validate TextBox value against the Regex.
      let isValid = regexForFields.test(kpiData["MetricName"]);
      if (!isValid) {
        formIsValid = false;
        errors["MetricName"] = i18n.t("datapopupErrorM2");
      }
      else if(!isValidText(kpiData["MetricName"])){
        errors["MetricName"] = InvalidDescriptionText;
        formIsValid = false;
      }
    }

    if (!kpiData["KpiDescription"]) {
      formIsValid = false;
      errors["KpiDescription"] = i18n.t("ToolDescription_Required");
    } else {
      var isValid = regexForFields.test(kpiData["KpiDescription"]);
      if (!isValid) {
        formIsValid = false;
        errors["KpiDescription"] = i18n.t("kpiDescErrorM1");
      }
      else if(!isValidText(kpiData["KpiDescription"])){
        errors["KpiDescription"] = InvalidDescriptionText;
        formIsValid = false;
      }
    }
    if (!kpiData["MetricUnit"]) {
      formIsValid = false;
      errors["MetricUnit"] = i18n.t("Unit_is_required");
    }

    if (kpiData["BusinessReason"] == null) {
      formIsValid = false;
      errors["BusinessReason"] = i18n.t("addWoErrorM8");
    } else if (!kpiData["BusinessReason"].trim()) {
      formIsValid = false;
      errors["BusinessReason"] = i18n.t("addWoErrorM8");
    } else {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      // var regex = /^[A-Za-z0-9 _ & - ]+$/
      //Validate TextBox value against the Regex.
      var isValid = regexForTextAreas.test(kpiData["BusinessReason"]);
      if (!isValid) {
        formIsValid = false;
        errors["BusinessReason"] = i18n.t("addWoErrorM9");
      }
      else if(!isValidText(kpiData["BusinessReason"])){
        errors["BusinessReason"] = InvalidDescriptionText;
        formIsValid = false;
      }
    }

    //validation for leading practice

    if (
      this.state.defaultIsRequiredLeadingPractice &&
      !kpiData["LeadingPractice"]
    ) {
      formIsValid = false;
      errors["LeadingPractice"] = i18n.t("selectLeadingPractice");
    }

    //Validation for metric definition

    //trimmimg metric def and metric unit before the business rules

    if (kpiData["NumeratorDescription"]) {
      kpiData["NumeratorDescription"] = kpiData["NumeratorDescription"].trim();
    }
    if (kpiData["DenominatorDescription"]) {
      kpiData["DenominatorDescription"] = kpiData[
        "DenominatorDescription"
      ].trim();
    }

    if (kpiData["MetricUnit"]) {
      kpiData["MetricUnit"] = kpiData["MetricUnit"].trim();
    }

    if (
      (this.props.tabName == "BO" ||
        this.props.tabName == "II" ||
        this.props.tabName == "DD") &&
      this.state.metDefAvl
    ) {
      if (
        (!kpiData["NumeratorDescription"] ||
          !kpiData["DenominatorDescription"]) &&
        kpiData["MetricUnit"] === "%"
      ) {
        formIsValid = false;
        errors["NumeratorDenominator"] = i18n.t(
          "selectNumeratorAndDenominator"
        );
      }

      if (
        (!kpiData["NumeratorDescription"] ||
          !kpiData["DenominatorDescription"]) &&
        kpiData["MetricUnit"] !== "%"
      ) {
        if (!this.state.isNumeratorClicked) {
          formIsValid = false;
          errors["NumeratorDenominator"] = i18n.t(
            "selectNumeratorAndDenominator"
          );
        }
      }
      if (
        !kpiData["NumeratorDescription"] &&
        !kpiData["DenominatorDescription"] &&
        kpiData["MetricUnit"] !== "%"
      ) {
        if (!this.state.isNumeratorClicked) {
          formIsValid = false;
          errors["NumeratorDenominator"] = i18n.t(
            "selectNumeratorAndDenominator"
          );
        } else {
          formIsValid = false;
          errors["NumeratorDenominator"] = i18n.t("NumeratorDefReqd");
        }
      }
    } else {
      //HM and WO
      //both are optional for all units
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
        errors: {},
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }


  render() {

    return (
      <>
         <LoadingOverlay 
         className="custom-loader" 
         fadeSpeed={0} 
         spinner={<SynopsLoader />}
         active={this.state.showLoaderonPage} 
         ></LoadingOverlay>

        {/* <Header /> */}
        <Modal show={this.state.show} onHide={this.handleHide} backdrop = "static" className="modal fade" bsSize="medium" id="dealpopup">
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">{i18n.t("Add_New_Metric_Detail")}</h4>
          </Modal.Header>
          <Modal.Body>

                        <form>
                            <div className="form-section">
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Name")}<span className="required">*</span></label>
                                        <input type="text" maxLength="100" className="form-control" id="name" onChange={this.onNameChange} />
                                        <span className="errorMsg">{this.state.errors.MetricName}</span>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Description")} <span className="required">*</span></label>
                                        <input maxLength="255" type="text" className="form-control" id="" onChange={this.onDescriptionChange} />
                                        <span className="errorMsg">{this.state.errors.KpiDescription}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Unit_of_Measure")} <span className="required">*</span></label>
                                        <select className="form-control" value={this.state.kpiData.MetricUnit} onChange={this.onUnitChange}>
										<option value="">{i18n.t("Please_Select_Unit")}</option>
										{this.state.unitsData.map((each)=>{
											return <option value={each.MetricUnit_En}>{each.MetricUnit}</option>
										})}
									</select>
                                       
                                        <span className="errorMsg">{this.state.errors.MetricUnit}</span>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Requestor_Remarks")}<span className="required">*</span></label>
                                        <textarea maxLength="200" className="form-control" id="remarks" onChange={this.onRemarksChange.bind(this)} />
                                        <span className="errorMsg">{this.state.errors.BusinessReason}</span>
                                    </div>

                </div>

                {/* Row for leading practice */}
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label>
                      {i18n.t("Leading_Practice")}{" "}
                      {this.state.defaultIsRequiredLeadingPractice && (
                        <span className="required"> *</span>
                      )}
                      &nbsp;
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {i18n.t("addBOMess2")}
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </OverlayTrigger>
                    </label>
                    <select
                      value={this.state.kpiData.LeadingPractice}
                      onChange={(e) => this.handleChangeLeadingPractice(e)}
                      className="form-control"
                    >
                      <option value="">
                        {i18n.t("Select_Leading_Practice")}
                      </option>

                      <option value="Maximum">{i18n.t("Maximum")}</option>
                      <option value="Minimum">{i18n.t("Minimum")}</option>
                    </select>
                    <div className="errorMsg">
                      {this.state.errors.LeadingPractice}
                    </div>
                  </div>
                </div>

                {/* Row for single metric definition */}

                <h3 className="my-3">
                  {i18n.t("Metric_Definition")}{" "}
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">{i18n.t("addBOMess3")}</Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </OverlayTrigger>
                </h3>
                <div className="row">
                  {/* Row for metric definition available? Yes No radio buttons */}
                  <div className="form-group col-lg-12">
                    <label>{i18n.t("Metric_Definition_Available")}</label>
                    <div className="d-inline-block ml-4">
                      <label className="form-check mr-4">
                        <input
                          type="radio"
                          value="Yes"
                          onChange={this.handleMetricDefAvlRadioButtonChange}
                          checked={this.state.metDefAvl}
                          name="metricDefAvlRadioButtons"
                          className="form-check-input"
                        />
                        <span className="checkmark" />
                        <span className="form-check-label">
                          {i18n.t("Yes")}
                        </span>
                      </label>
                      <label className="form-check">
                        <input
                          type="radio"
                          onChange={this.handleMetricDefAvlRadioButtonChange}
                          value="No"
                          checked={!this.state.metDefAvl}
                          name="metricDefAvlRadioButtons"
                          className="form-check-input"
                        />
                        <span className="checkmark" />
                        <span className="form-check-label">{i18n.t("No")}</span>
                      </label>
                    </div>
                  </div>
                </div>
                {/* end of metric definitions available? radio buttons row */}
                {this.state.isEditableMetricDefinition && (
                  <div>
                    <div className="mb-3 mt-1">
                      <div className="row">
                        <div className="form-group col-lg-12 mb-2">
                          <label className="form-check mr-4">
                            <input
                              type="radio"
                              value="numeratorDefinition"
                              onChange={this.handleRadioButtonChange}
                              name="numeratorDenominatorRadioButtons"
                              checked={this.state.isNumeratorClicked}
                              disabled={
                                this.state.isDisabledNumeratorDefinitionField
                              }
                              className="form-check-input"
                            />
                            <span className="checkmark" />
                            <span className="form-check-label">
                              {i18n.t("Numerator_Definition")}
                            </span>
                          </label>
                          <label className="form-check">
                            <input
                              type="radio"
                              onChange={this.handleRadioButtonChange}
                              checked={!this.state.isNumeratorClicked}
                              value="numeratorAndDenominatorDefinition"
                              name="numeratorDenominatorRadioButtons"
                              className="form-check-input"
                            />
                            <span className="checkmark" />
                            <span className="form-check-label">
                              {i18n.t("Numerator_And_Denominator_Definition")}
                            </span>
                          </label>
                        </div>
                      </div>
                      {/* End of radio buttons */}
                      <div className="row">
                        <div
                          className={classNames(
                            "form-group",
                            {
                              "col-lg-6": this.state
                                .isVisibleDenominatorDefinition,
                            },
                            { col: !this.state.isVisibleDenominatorDefinition }
                          )}
                        >
                          <label>
                            {i18n.t("Numerator_Definition")}
                            {this.state.isNumeratorReqd && (
                              <span className="required"> *</span>
                            )}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.kpiData.NumeratorDescription}
                            onChange={this.OnNumeratorChange}
                            maxLength="500"
                          />
                        </div>

                        {this.state.isVisibleDenominatorDefinition && <div className="form-group col-lg-6">
                          <label>
                            {i18n.t("Denominator_Definition")}
                            {this.state.isDenomReqd && (
                              <span className="required">{' '}*</span>
                            )}
                          </label>
                          <input type="text"
                            className="form-control"
                            value={this.state.kpiData.DenominatorDescription}
                            onChange={this.OnDenominatorChange}
                            maxLength="500"
                          />
                        </div>}

                      </div>
                      <div>
                        <span className="errorMsg">{this.state.errors.NumeratorDenominator}</span></div>

                    </div>
                  </div>)}


              </div>

            </form>

          </Modal.Body>
          {<Modal.Footer>
            <div className="modal-btn">
              <input type="button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={i18n.t("Create")} />
            </div>
          </Modal.Footer>}
        </Modal>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return ({
    languageData: state.CurrentFilter.languageData,
    unitsData: state.BOMasterReducers.unitsData
  })
}
export default connect(mapStateToProps)(KpiPopUp);