export let humanMasterReducers = (state={
    humanandmachine:[]
},action)=>{
switch(action.type){   
    case "INITIALISE_MASTER" :{
            state = {...state}
            state["humanandmachine"] = action.payload
            return state;
        }
    case "ADD_EDIT_HUMAN_MASTER" :{
        state = {...state} 
                
        if(state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.selectedOption)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID).length > 0 && action.payload.kpiData.KPIMappingID > 0)
            state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.selectedOption)[0].kpiData = state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.selectedOption)[0].kpiData.filter((data)=> data.KPIMappingID != action.payload.kpiData.KPIMappingID);
        
        state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.selectedOption)[0].kpiData.push(action.payload.kpiData)        
        state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.selectedOption)[0].kpiData.sort((a, b) => (a.MetricSeq > b.MetricSeq) ? 1 : -1)

        return state
    }
    case "ADD_EDIT_MACHINE_MASTER" :{
        
       
        state = {...state}            
        
        if(state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID).length > 0 && action.payload.kpiData.KPIMappingID > 0){
            state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData = state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData.filter((data)=> data.KPIMappingID != action.payload.kpiData.KPIMappingID);
        }
        
        state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData.push(action.payload.kpiData)
        state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData.sort((a, b) => (a.MetricSeq > b.MetricSeq) ? 1 : -1)
        return state
    }
    
    case "REMOVE_HUMAN_MASTER" :{
        state = {...state}            
        state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.selectedOption)[0].kpiData = state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.selectedOption)[0].kpiData.filter((data)=> data.KPIMappingID != action.payload.kpiData.KPIMappingID)
        
        return state
    }
    case "REMOVE_MACHINE_MASTER" :{
        state = {...state}            
        state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData = state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData.filter((data)=> data.KPIMappingID != action.payload.kpiData.KPIMappingID)
        
        return state
    }
    
    default : return state
}
}
