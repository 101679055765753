import React from 'react';
import { Modal } from 'react-bootstrap';
import i18n from 'i18next';
import { LocalApiBaseUrl, regexForConnectorField, trycatchAlertPopup } from '../Shared/Constant';

import { store } from '../reducers/configureStore';
import axiosInstance from '../Shared/interceptor';

import SubOfferingDropdowns from '../Shared/SubOfferingDropdowns';
import { filterSelectedSubOffering,isValidText,InvalidDescription } from '../Shared/commonFunctions';

export default class ConnectorForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      specialCharmessage:InvalidDescription(),
      apivalue: '',
      selectValue: this.props.SelectValue,
      Display: false,
      viewmore: false,
      MetricNameOptions: [],
      LocationOptions: [],
      OneTimeSetupError: '',
      SourceDetails: [],
      OfferingOptions: [],
      criteriaOptions: [],
      processOptions: [],
      CategoryOptions: [],
      SubprocessOptions: [],
      ParamList: [],
      MaterValueList: [],
      valueConnector: false,
      con: false,
      valueEdit: false,
      valueConnectorEdit: false,
      MetricParam1Options: [],
      MetricParam2Options: [],
      MetricParam3Options: [],
      MetricParam4Options: [],
      MetricParam5Options: [],
      MetricParam6Options: [],
      errors: {},
      formIsValid: false,
      MonthDT: '2018-12-21',
      temp: [],
      filter: { DealID: 0, ConnectorID: 0, SOCategoryID: 0 },
      data: {
        SOCategoryID: 0,
        DealID: 0,
        process: '',
        DestinationOfferingValueID: '',
        processValueID: '',
        Client: '',
        SubSOCategoryID: 0,
        OrgEntityID: 0,
        OfferingID: 0,
        LocationID: 0,
        MaturityPhase: this.props.MaturityPhase,

        kpiData: {
          KPIMappingID: 0,
          SOCategoryID: 0,
          MetricID: null,
          MetricName: '',
          SynOpsMetricName: '',
          MetricParam1: '',
          MetricParam2: '',
          MetricParam3: '',
          MetricParam4: '',
          MetricParam5: '',
          MetricParam6: '',
          ConnectorID: '',
          ConnectorName: '',
          CriteriaID: '',
          SourceFieldName: '',
          SourceName: '',
          Client: '',
          DestinationClient: '',
          Offering: '',
          SourceOffering: '',
          SubOffering: '',
          Location: '',
          SourceLocation: '',
          LocationID: 0,
          OrgEntityID: 0,
          OfferingID: 0,
          DealID: 0,
          SubSOCategoryID: 0,
          SubSOCategory: '',
          ParamName1: '',
          ParamName2: '',
          ParamName3: '',
          ParamName4: '',
          ParamName5: '',
          ParamName6: '',
          HasMasterParam1: '',
          HasMasterParam2: '',
          HasMasterParam3: '',
          HasMasterParam4: '',
          HasMasterParam5: '',
          HasMasterParam6: '',
          City: null,
          process: '',
          ProcessID: null,
          DestinationLocation: '',
          DestinationProcess: '',
          DestinationOffering: '',
          ToolDealMappingID: null,

        },
      },
    };
    this.bindProcess = this.bindProcess.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onMetricNameChange = this.onMetricNameChange.bind(this);
    this.onOfferingChange = this.onOfferingChange.bind(this);
    this.onSourceOfferingChange = this.onSourceOfferingChange.bind(this);
    this.onSubOfferingChange = this.onSubOfferingChange.bind(this);
    this.onMetricParam1Change = this.onMetricParam1Change.bind(this);
    this.onMetricParam2Change = this.onMetricParam2Change.bind(this);
    this.onMetricParam3Change = this.onMetricParam3Change.bind(this);
    this.onMetricParam4Change = this.onMetricParam4Change.bind(this);
    this.onMetricParam5Change = this.onMetricParam5Change.bind(this);
    this.onMetricParam6Change = this.onMetricParam6Change.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
    // this.onSourceSystemChange = this.onSourceSystemChange.bind(this);
    this.onSoCategoryChange = this.onSoCategoryChange.bind(this);
    this.onOtherSystemChange = this.onOtherSystemChange.bind(this);
    this.onClientChange = this.onClientChange.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.onDestinationLocationChange = this.onDestinationLocationChange.bind(this);
    this.OfferingValues = [];
    this.handleSave = this.handleSave.bind(this);
    this.onToolOwnerChange = this.onToolOwnerChange.bind(this);
    this.getGetConnectorMaster = this.getGetConnectorMaster.bind(this);
    this.onDestinationLocationChange = this.onDestinationLocationChange.bind(this);
    this.onDestinationOfferingsChange = this.onDestinationOfferingsChange.bind(this);
    this.onDestinationOfferingsProcessChange = this.onDestinationOfferingsProcessChange.bind(this);
    this.getLocation = this.getLocation.bind(this);
  }

  /// /////-----On change Method

  componentWillMount() {
    if (this.props.data) {
      const kpiData = _.cloneDeep(this.props.data);

      this.setState({
        data: {
          kpiData,
        },
        errors: {},
        formIsValid: true,
      });
    } else {
      const { kpiData } = this.state;
      this.setState({
        kpiData,
        show: this.props.show,
      });
    }
  }

  bindProcess(event) {
    const selectedSOCategory = this.props.data != undefined ? this.props.data.SOCategoryID : parseInt(event.target.value);
    const valueConnectorEdit = false;
    const SubprocessOptions = [];
    const { kpiData } = this.state.data;
    if (selectedSOCategory != 0) {
      if (this.props.data != undefined) {
        kpiData.SOCategoryID = this.props.data.SOCategoryID;
        kpiData.SOCategoryName = this.props.data.SOCategoryName;
        kpiData.SubSOCategoryID = this.props.data.SubSOCategoryID;
        axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetSubData`, {
          params: {
            OrgEntityID: this.props.data.OrgEntityID, OfferingID: this.props.data.OfferingID, LocationID: this.props.data.LocationID, SOCategoryID: selectedSOCategory,
          },
        })
          .then((response) => {
            const mappingData = response.data;
            const temp = [];
            this.setState({ temp: response.data });
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      } else {
        kpiData.SOCategoryID = parseInt(event.target.value);
        axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetSubData`, {
          params: {
            OrgEntityID: this.state.data.kpiData.OrgEntityID, OfferingID: this.state.data.kpiData.OfferingID, LocationID: this.state.data.kpiData.LocationID, SOCategoryID: selectedSOCategory,
          },
        })
          .then((response) => {
            const mappingData = response.data;
            kpiData.SubSOCategoryID = 0;
            kpiData.MetricID = 0;
            this.setState({ temp: response.data });
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      }
      this.state.SubprocessOptions = SubprocessOptions;
      if (selectedSOCategory != '0') {
      }

      if (String(selectedSOCategory) === '0') {
        const filterObj = {};
        filterObj.offering = '0';
        filterObj.process = '0';

        this.props.applyOfferingFilters(filterObj);
      }
    }else{      
      kpiData.SOCategoryID = parseInt(event.target.value);
      kpiData.SOCategoryName = '';
      kpiData.SubSOCategoryID = 0;
      kpiData.MetricID = 0;
    }
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  componentDidMount() {
    const Client = store.getState().CurrentFilter.ClientData;
    this.state.data.kpiData.DestinationClient = Client.name;
    this.state.data.kpiData.OrgEntityID = Client.id;
    this.getGetConnectorMaster();
    // this.getKpiMAppingDetails();
    this.getOffering();
    this.getOfferings();

    if (this.props.data) {
        let kpiData = _.cloneDeep(this.props.data);
        kpiData.SourceLocation = this.props.data.Location
        this.getMaterKeyDetails(this.props.data.ConnectorID, this.props.data.OrgEntityID, this.props.data.OfferingID, this.props.data.LocationID)

        this.setState({
            data: {

                kpiData: kpiData,
            },
            errors: {},
            formIsValid: true
        })
    }
    // this.setState({
    //     show: this.props.show
    // })
  }

  getKpiMAppingDetails() {
    const samp = {};
    let valueConnectorEdit = false;
    if (this.props.data) {
      axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
        params: {
          OrgEntityID: this.props.data.OrgEntityID, LocationID: this.props.data.LocationID, OfferingID: this.props.data.OfferingID, SOCategoryID: this.props.data.SubSOCategoryID,
        },
      })
        .then((response) => {
          const mappingData = response.data;
          if (this.props.kpiData) {
            const mappedConnector = mappingData.filter((x) => x.MetricName == this.props.kpiData.MetricName);
            const mappedConnectorName = mappedConnector[0].ConnectorName;
            if (mappedConnectorName != null) {
              valueConnectorEdit = true;
            }
          }
          this.setState({ MetricNameOptions: response.data, valueConnectorEdit });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    } else {
      axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
        params: {
          OrgEntityID: this.state.data.kpiData.OrgEntityID, LocationID: this.state.data.kpiData.LocationID, OfferingID: this.state.data.kpiData.OfferingID, SOCategoryID: this.state.data.kpiData.SubSOCategoryID,
        },
      })
        .then((response) => {
          const mappingData = response.data;
          this.setState({ MetricNameOptions: response.data, valueConnectorEdit });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  getMaterKeyDetails(ConnectorID, OrgEntityID, OfferingID, LocationID) {    
    const samp = {};
    axiosInstance.get(`${LocalApiBaseUrl}QueryBuilder/GetMasterKeyData1`, {
      params: {
        ConnectorID, OrgEntityID, OfferingID, LocationID,
      },

    })
      .then((response) => {
        const storestate = this.state.data;
        const mappingData = response.data;
        const opt1 = [];
        const opt2 = [];
        const opt3 = [];
        const opt4 = [];
        const opt5 = [];
        const opt6 = [];
        if (response.data.length) {
          response.data.map((each, index) => {
            if (index === 0) {
              if (each.ConnectorMasterKey.length) {
                each.ConnectorMasterKey.map((e) => {
                  opt1.push(e.MasterValue.toUpperCase());
                  storestate.kpiData.HasMasterParam1 = mappingData[0].HasMaster;
                });
              } else
              if (this.props.data) {
                if (storestate.kpiData.MetricParam1 != '' && storestate.kpiData.MetricParam1 != undefined) {
                  storestate.kpiData.HasMasterParam1 = mappingData[0].HasMaster;
                }
              } else {
                storestate.kpiData.HasMasterParam1 = mappingData[0].HasMaster;
                storestate.kpiData.MetricParam1 = '';
              }
            }

            if (index === 1) {
              if (each.ConnectorMasterKey.length) {
                each.ConnectorMasterKey.map((e) => {
                  opt2.push(e.MasterValue);
                  storestate.kpiData.HasMasterParam2 = mappingData[1].HasMaster;
                });
              } else if (this.props.data) {
                if (storestate.kpiData.MetricParam2 != '' && storestate.kpiData.MetricParam2 != undefined) {
                  storestate.kpiData.HasMasterParam2 = mappingData[1].HasMaster;
                }
              } else {
                storestate.kpiData.HasMasterParam2 = mappingData[1].HasMaster;
                storestate.kpiData.MetricParam2 = '';
              }
            }
            if (index === 2) {
              if (each.ConnectorMasterKey.length) {
                each.ConnectorMasterKey.map((e) => {
                  opt3.push(e.MasterValue);
                  storestate.kpiData.HasMasterParam3 = mappingData[2].HasMaster;
                });
              } else if (this.props.data) {
                if (storestate.kpiData.MetricParam3 != '' && storestate.kpiData.MetricParam3 != undefined) {
                  storestate.kpiData.HasMasterParam3 = mappingData[2].HasMaster;
                }
              } else {
                storestate.kpiData.HasMasterParam3 = mappingData[2].HasMaster;
                storestate.kpiData.MetricParam3 = '';
              }
            }
            if (index === 3) {
              if (each.ConnectorMasterKey.length) {
                each.ConnectorMasterKey.map((e) => {
                  opt4.push(e.MasterValue);
                  storestate.kpiData.HasMasterParam4 = mappingData[3].HasMaster;
                });
              } else if (this.props.data) {
                if (storestate.kpiData.MetricParam4 != '' && storestate.kpiData.MetricParam4 != undefined) {
                  storestate.kpiData.HasMasterParam4 = mappingData[3].HasMaster;
                }
              } else {
                storestate.kpiData.HasMasterParam4 = mappingData[3].HasMaster;
                storestate.kpiData.MetricParam4 = '';
              }
            }
            if (index === 4) {
              if (each.ConnectorMasterKey.length) {
                each.ConnectorMasterKey.map((e) => {
                  opt5.push(e.MasterValue);
                  storestate.kpiData.HasMasterParam5 = mappingData[4].HasMaster;
                });
              } else if (this.props.data) {
                if (storestate.kpiData.MetricParam5 != '' && storestate.kpiData.MetricParam5 != undefined) {
                  storestate.kpiData.HasMasterParam5 = mappingData[4].HasMaster;
                }
              } else {
                storestate.kpiData.HasMasterParam5 = mappingData[4].HasMaster;
                storestate.kpiData.MetricParam5 = '';
              }
            }
            if (index === 5) {
              if (each.ConnectorMasterKey.length) {
                each.ConnectorMasterKey.map((e) => {
                  opt6.push(e.MasterValue);
                  storestate.kpiData.HasMasterParam6 = mappingData[5].HasMaster;
                });
              } else if (this.props.data) {
                if (storestate.kpiData.MetricParam6 != '' && storestate.kpiData.MetricParam6 != undefined) {
                  storestate.kpiData.HasMasterParam6 = mappingData[5].HasMaster;
                }
              } else {
                storestate.kpiData.HasMasterParam6 = mappingData[5].HasMaster;
                storestate.kpiData.MetricParam6 = '';
              }
            }
          });
        }
       
        
        this.setState({
          data: storestate, MetricParam1Options: opt1, MetricParam2Options: opt2, MetricParam3Options: opt3, MetricParam4Options: opt4, MetricParam5Options: opt5, MetricParam6Options: opt6,
        }, () => {  });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getGetConnectorMaster() {
    let source = [];
    axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
      .then((response) => {
        source = response.data.filter(src => !["Bat", "PMM", "SVM"].includes(src.ConnectorName));
        const sourceValues = source.map((x) => <option key={x.ConnectorID} value={x.ConnectorID}>{x.ConnectorName}</option>);

        this.setState({ sourceOptions: sourceValues, ParamList: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getLocation(processValueID) {
    const samp = {};
    axiosInstance.get(`${LocalApiBaseUrl}SOFilter/GetLocation`,
      {
        params: { OrgEntityID: this.state.data.kpiData.OrgEntityID, OfferingID: processValueID },

      })
      .then((response) => {
        if (this.props.data != null) {
          const { LocationID } = this.props.data;
          this.setState({

            LocationID,
            LocationOptions: response.data,

          });
        } else {
          this.setState({ LocationOptions: response.data });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getOffering() {
    const samp = {};
    axiosInstance.get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
      params: { OrgEntityID: this.state.data.kpiData.OrgEntityID, featureName: 'Configure Source Parameter' },
    })
      .then((response) => {
        this.setState({ OfferingOptions: response.data });
        if (this.props.data != null) {
          response.data.map( each => {
            var data = filterSelectedSubOffering(each.ChildOfferings)
            each.ChildOfferings = data.allLeafNodes;
          })
          const processOptions = response.data.filter((data) => data.OfferingName === this.state.data.kpiData.DestinationOffering)[0].ChildOfferings;
          const DestinationOfferingValueID = response.data.filter((data) => data.OfferingName === this.state.data.kpiData.DestinationOffering)[0].OfferingID;
          const processValueID = processOptions.filter((data) => data.Process === this.state.data.kpiData.DestinationProcess)[0].OfferingID;
          this.state.data.kpiData.ProcessID = processValueID;
          this.setState({
            processOptions,
            DestinationOfferingValueID,
            processValueID,

          });
          this.getLocation(processValueID);
        }
        if (this.state.data.OfferingID) {
          const processOptions = response.data.filter((data) => data.OfferingName === 'Finance & Accounting')[0].ChildOfferings;
          const DestinationOfferingValueID = response.data.filter((data) => data.OfferingName === this.state.data.kpiData.Offering)[0].OfferingID;
          const processValueID = processOptions.filter((data) => data.Process === this.state.data.kpiData.Process)[0].OfferingID;
          this.setState({
            processOptions,
            DestinationOfferingValueID,
            processValueID,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getSourceDetails() {
    const samp = {};
    let OneTimeSetupError = '';
    const { kpiData } = this.state.data;
    axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetSourceDetails`,
      {
        params: {
          OrgEntityID: this.state.data.kpiData.OrgEntityID, LocationID: this.state.data.kpiData.LocationID, OfferingID: this.state.data.kpiData.OfferingID, SOCategoryID: this.state.data.kpiData.SubSOCategoryID, ConnectorID: this.state.data.kpiData.ConnectorID,
        },

      })
      .then((response) => {
        const sourcedata = response.data;
        if (sourcedata.length > 0) {
          this.setState({ SourceDetails: response.data[0] });
          if (this.state.SourceDetails != null || this.state.SourceDetails != undefined) {
            kpiData.Client = this.state.SourceDetails.Client;
            kpiData.SourceLocation = this.state.SourceDetails.Location;
            kpiData.SourceOffering = this.state.SourceDetails.Offering;
            kpiData.SubOffering = this.state.SourceDetails.SubOffering;
            kpiData.DealID = this.state.SourceDetails.DealID;
            kpiData.ToolDealMappingID = this.state.SourceDetails.ToolDealMappingID;
          }
        } else if (kpiData.OrgEntityID != 0 && kpiData.LocationID != 0 && kpiData.OfferingID != 0 && kpiData.ConnectorID != 0) {
          if (sourcedata.length == 0) {
            kpiData.Client = '';
            kpiData.SourceLocation = '';
            kpiData.SourceOffering = '';
            kpiData.SubOffering = '';
            kpiData.DealID = '';

            OneTimeSetupError = 'Connect with Synops Team to setup the application for Integration';
          }
        } else {
          kpiData.Client = '';
          kpiData.SourceLocation = '';
          kpiData.SourceOffering = '';
          kpiData.SubOffering = '';
          kpiData.DealID = '';
        }

        this.setState({ SourceDetails: response.data[0], kpiData, OneTimeSetupError });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getMasterValues() {
    const samp = {};
    const { kpiData } = this.state.data;
    axiosInstance.get(`${LocalApiBaseUrl}QueryBuilder/GetMasterKeyData`,
      {
        params: { ConnectorID: this.state.data.kpiData.ConnectorID, ParamList: this.state.paramList },

      })
      .then((response) => {
        this.setState({ MaterValueList: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  onNameChange(event) {
    const errors = {};
    this.setState({
      errors,
    });
    const { kpiData } = this.state.data;
    kpiData.KPIMappingID = event.target.value;
    kpiData.SourceName = '';
    kpiData.SourceFieldName = '';
    if (kpiData.KPIMappingID !== '-1') {
      kpiData.KPIDesp = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].MetricDescription;
      kpiData.MetricUnit = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].MetricUnit;
      kpiData.MetricName = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].MetricName;
      kpiData.MetricSeq = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].MetricSeq;
      kpiData.SOCategoryID = this.props.SOCategoryID;
    } else {
      this.setState({
        viewmore: true,
        show: false,
      });
    }
    let con = '';
    let valueConnector = '';

    const metriName = this.state.MetricNameOptions.filter((eachValue) => eachValue.KpiMappingID == event.target.value);
    if (metriName.length > 0) {
      const connectorName = metriName[0].ConnectorName;
      const connectorId = metriName[0].ConnectorID;
      const criteria = metriName[0].Criteria;
      if (connectorName == null) {
        con = false;
      } else {
        con = true;
        kpiData.ConnectorNames = connectorName;
        kpiData.ConnectorID = connectorId;
        kpiData.SourceFieldName = criteria;

        if (metriName[0].IsConnector == true && metriName[0].IsTransaction == false) {
          valueConnector = true;
        }
      }
    }

    const connectorOptions = metriName.map((eachProcess) => <option key={eachProcess.ConnectorID} value={eachProcess.ConnectorID}>{eachProcess.ConnectorName}</option>);
    const criteriaOptions = metriName.map((eachProcess, index) => <option key={index} value={eachProcess.Criteria}>{eachProcess.Criteria}</option>);

    this.setState({
      data: {
        kpiData,
      },
      con,
      connectorOptions,
      criteriaOptions,
      valueConnector,
    });
  }

  onMetricNameChange(event) {
    const errors = {};
    this.setState({
      errors,
    });
    const { kpiData } = this.state.data;
    const index = event.nativeEvent.target.selectedIndex;
    kpiData.MetricID = parseInt(event.target.value);
    kpiData.SynOpsMetricName = event.nativeEvent.target[index].text;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onOfferingChange(event) {
    const { kpiData } = this.state.data;
    kpiData.Offering = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onSourceOfferingChange(event) {
    const { kpiData } = this.state.data;
    kpiData.SourceOffering = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onDestinationOfferingsChange(event) {
    this.setState({ errors: {} });
    const { kpiData } = this.state.data;
    kpiData.OfferingID = parseInt(event.target.value);
    const DestinationOfferingValueID = parseInt(event.target.value);
    const processOptions = parseInt(event.target.value) ? this.state.OfferingOptions.filter((data) => data.OfferingID == parseInt(event.target.value))[0].ChildOfferings : [];
    if (processOptions.length && parseInt(event.target.value)) {
      kpiData.Offering =this.state.OfferingOptions.filter((data) => data.OfferingID == parseInt(event.target.value))[0].OfferingName;
    }
    else{
      kpiData.Offering = '';
    }
    kpiData.Process = '';
    kpiData.ProcessID = 0;
    kpiData.LocationID = 0;
    const processValueID = 0;
    kpiData.Client = '';
    kpiData.SourceLocation = '';
    kpiData.SourceOffering = '';
    kpiData.SubOffering = '';
    kpiData.DealID = '';
    kpiData.SOCategoryID = 0;
    kpiData.SubSOCategoryID = 0;
    kpiData.MetricID = 0;
    kpiData.MetricParam1 = '';
    kpiData.MetricParam2 = '';
    kpiData.MetricParam3 = '';
    kpiData.MetricParam4 = '';
    kpiData.MetricParam5 = '';
    kpiData.MetricParam6 = '';
    kpiData.HasMasterParam1 = '';
    kpiData.HasMasterParam2 = '';
    kpiData.HasMasterParam3 = '';
    kpiData.HasMasterParam4 = '';
    kpiData.HasMasterParam5 = '';
    kpiData.HasMasterParam6 = '';
    this.setState({
      LocationOptions: [],
      processOptions,
      DestinationOfferingValueID,
      data: {
        kpiData,
      },
      processValueID,
    });
  }

  onDestinationOfferingsProcessChange(e) {
    this.setState({ errors: {} });
    const { kpiData } = this.state.data;
    let selectedSuboffering=[];
    if(e.target.value != 0){
      const filtersSubofferingData = filterSelectedSubOffering([...this.state.processOptions], e.target.value);
      selectedSuboffering = filtersSubofferingData.subofferingData[0];
    }
    kpiData.ProcessID = e.target.value;
    kpiData.OfferingID= e.target.value;
    kpiData.LocationID = 0;
    kpiData.Process = selectedSuboffering.Process ? selectedSuboffering.Process : '';
    const processValueID = e.target.value;
    kpiData.Client = '';
    kpiData.SourceLocation = '';
    kpiData.SourceOffering = '';
    kpiData.SubOffering = '';
    kpiData.DealID = '';
    kpiData.SOCategoryID = 0;
    kpiData.SubSOCategoryID = 0;
    kpiData.MetricID = 0;
    kpiData.MetricParam1 = '';
    kpiData.MetricParam2 = '';
    kpiData.MetricParam3 = '';
    kpiData.MetricParam4 = '';
    kpiData.MetricParam5 = '';
    kpiData.MetricParam6 = '';
    kpiData.HasMasterParam1 = '';
    kpiData.HasMasterParam2 = '';
    kpiData.HasMasterParam3 = '';
    kpiData.HasMasterParam4 = '';
    kpiData.HasMasterParam5 = '';
    kpiData.HasMasterParam6 = '';
    this.getLocation(processValueID);
    // this.getSourceDetails(this.state.data.kpiData.OrgEntityID, this.state.data.kpiData.OfferingID, this.state.data.kpiData.LocationID, this.state.data.kpiData.ConnectorID)
    // this.getMaterKeyDetails(this.state.data.kpiData.ConnectorID, this.state.data.kpiData.OrgEntityID, this.state.data.kpiData.OfferingID, this.state.data.kpiData.LocationID)
    // this.getKpiMAppingDetails(this.state.data.kpiData.OrgEntityID, this.state.data.kpiData.LocationID, this.state.data.kpiData.OfferingID, this.state.data.kpiData.SubSOCategoryID)
    this.setState({
      data: {
        kpiData,
      },
      processValueID,
    });
  }

  onDestinationLocationChange(event) {
    this.setState({ errors: {} });
    const { kpiData } = this.state.data;
    event = document.getElementById('Location');
    kpiData.LocationID = parseInt(event.options[event.selectedIndex].value);
    kpiData.Location = event.options[event.selectedIndex].label;
    kpiData.Client = '';
    kpiData.SourceLocation = '';
    kpiData.SourceOffering = '';
    kpiData.SubOffering = '';
    kpiData.DealID = '';
    kpiData.SOCategoryID = 0;
    kpiData.SubSOCategoryID = 0;
    kpiData.MetricID = 0;
    kpiData.MetricParam1 = '';
    kpiData.MetricParam2 = '';
    kpiData.MetricParam3 = '';
    kpiData.MetricParam4 = '';
    kpiData.MetricParam5 = '';
    kpiData.MetricParam6 = '';
    if (kpiData.ConnectorName && kpiData.LocationID !== 0) {
      this.getSourceDetails(this.state.data.kpiData.OrgEntityID, this.state.data.kpiData.OfferingID, this.state.data.kpiData.LocationID, this.state.data.kpiData.ConnectorID);
      this.getMaterKeyDetails(this.state.data.kpiData.ConnectorID, this.state.data.kpiData.OrgEntityID, this.state.data.kpiData.OfferingID, this.state.data.kpiData.LocationID);
    }// this.getKpiMAppingDetails(this.state.data.kpiData.OrgEntityID, this.state.data.kpiData.LocationID, this.state.data.kpiData.OfferingID, this.state.data.kpiData.SubSOCategoryID)
    else{
      kpiData.HasMasterParam1 = '';
      kpiData.HasMasterParam2 = '';
      kpiData.HasMasterParam3 = '';
      kpiData.HasMasterParam4 = '';
      kpiData.HasMasterParam5 = '';
      kpiData.HasMasterParam6 = '';
    }
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onSubOfferingChange(event) {
    const { kpiData } = this.state.data;
    kpiData.SubOffering = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onMetricParam1Change(event) {
    const { kpiData } = this.state.data;
    kpiData.MetricParam1 = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onMetricParam2Change(event) {
    const { kpiData } = this.state.data;
    kpiData.MetricParam2 = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onMetricParam3Change(event) {
    const { kpiData } = this.state.data;
    kpiData.MetricParam3 = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onMetricParam4Change(event) {
    const { kpiData } = this.state.data;
    kpiData.MetricParam4 = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onMetricParam5Change(event) {
    const { kpiData } = this.state.data;
    kpiData.MetricParam5 = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onMetricParam6Change(event) {
    const { kpiData } = this.state.data;
    kpiData.MetricParam6 = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onClientChange(event) {
    const { kpiData } = this.state.data;
    kpiData.Client = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onLocationChange(event) {
    const { kpiData } = this.state.data;
    kpiData.SourceLocation = event.target.value;
    kpiData.Location = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onSourceChange(event) {
    const { kpiData } = this.state.data;
    kpiData.ConnectorID = parseInt(event.target.value);
    const index = event.nativeEvent.target.selectedIndex;
    kpiData.ConnectorName = event.nativeEvent.target[index].text;
    kpiData.SourceName = '';
    kpiData.SourceFieldName = '';
    const paramList = this.state.ParamList.filter((x) => x.ConnectorID == kpiData.ConnectorID);
    const errors = {};
    const formIsValid = true;
    if (!kpiData.OfferingID || !kpiData.ProcessID || !kpiData.LocationID) {
      this.handleSaveTwo();
    } else {
      this.getSourceDetails(this.state.data.kpiData.OrgEntityID, this.state.data.kpiData.OfferingID, this.state.data.kpiData.LocationID, this.state.data.kpiData.ConnectorID);
      this.getMaterKeyDetails(this.state.data.kpiData.ConnectorID, this.state.data.kpiData.OrgEntityID, this.state.data.kpiData.OfferingID, this.state.data.kpiData.LocationID);
    }    
    // this.getMasterValues(this.state.data.kpiData.ConnectorID, paramList)
    if ((this.validateFormTwo()) && (this.state.SourceDetails != null || this.state.SourceDetails != undefined)) {
      kpiData.Client = this.state.SourceDetails.Client;
      kpiData.SourceLocation = this.state.SourceDetails.Location;
      kpiData.SourceOffering = this.state.SourceDetails.Offering;
      kpiData.SubOffering = this.state.SourceDetails.SubOffering;
      kpiData.DealID = this.state.SourceDetails.DealID;
    }
    if (index > 0) {
      this.state.data.kpiData.ParamName1 = paramList[0].ParamName1;
      this.state.data.kpiData.ParamName2 = paramList[0].ParamName2;
      this.state.data.kpiData.ParamName3 = paramList[0].ParamName3;
      this.state.data.kpiData.ParamName4 = paramList[0].ParamName4;
      this.state.data.kpiData.ParamName5 = paramList[0].ParamName5;
      this.state.data.kpiData.ParamName6 = paramList[0].ParamName6;
      kpiData.MetricParam1 = '';
      kpiData.MetricParam2 = '';
      kpiData.MetricParam3 = '';
      kpiData.MetricParam4 = '';
      kpiData.MetricParam5 = '';
      kpiData.MetricParam6 = '';
    }
    else{
      kpiData.MetricParam1 = '';
      kpiData.MetricParam2 = '';
      kpiData.MetricParam3 = '';
      kpiData.MetricParam4 = '';
      kpiData.MetricParam5 = '';
      kpiData.MetricParam6 = '';
      kpiData.ParamName1 = '';
      kpiData.ParamName2 = '';
      kpiData.ParamName3 = '';
      kpiData.ParamName4 = '';
      kpiData.ParamName5 = '';
      kpiData.ParamName6 = '';
      kpiData.HasMasterParam1 = '';
      kpiData.HasMasterParam2 = '';
      kpiData.HasMasterParam3 = '';
      kpiData.HasMasterParam4 = '';
      kpiData.HasMasterParam5 = '';
      kpiData.HasMasterParam6 = '';
    }
    kpiData.SOCategoryID = 0;
    kpiData.SubSOCategoryID = 0;
    kpiData.MetricID = 0;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  onSoCategoryChange(event) {
    const soCatAtt = null;

    if (this.props.data != null) {
      if (this.props.data.SubSOCategoryID != null) {
        const { kpiData } = this.state.data;
        kpiData.SubSOCategoryID = this.props.data.SubSOCategoryID;
        var index = event.nativeEvent.target.selectedIndex;
        kpiData.SubSOCategory = this.props.data.SubSOCategory;
        kpiData.SourceName = '';
        kpiData.SourceFieldName = '';
        this.setState({
          data: {
            kpiData,
          },
        });
      }
    } else {
      const kpiData1 = this.state.data.kpiData;
      kpiData1.SubSOCategoryID = parseInt(event.target.value);
      var index = event.nativeEvent.target.selectedIndex;
      kpiData1.SOCategoryName = event.nativeEvent.target[index].text;
      kpiData1.SourceName = '';
      kpiData1.SourceFieldName = '';
      kpiData1.SynOpsMetricName = '';
      kpiData1.MetricID = 0;

      this.setState({
        data: {
          kpiData: kpiData1,
        },
      });
    }
    if(parseInt(event.target.value)){
      this.getKpiMAppingDetails(this.state.data.kpiData.OrgEntityID, this.state.data.kpiData.OfferingID, this.state.data.kpiData.LocationID, this.state.data.kpiData.SubSOCategoryID);
    }
  }

  onToolOwnerChange(event) {
    const { kpiData } = this.state.data;
    kpiData.IsAccentureOwned = event.target.checked;
    this.setState({
      data: {
        kpiData,
      },
    });
  }
  // onSourceSystemChange(event) {
  //     const kpiData = this.state.data.kpiData;
  //     kpiData.SourceFieldName = event.target.value;
  //     this.getSourceDetails(this.state.data.kpiData.OrgEntityID, this.state.data.kpiData.OfferingID, this.state.data.kpiData.LocationID, this.state.data.kpiData.ConnectorID)
  //     this.getMaterKeyDetails(this.state.data.kpiData.ConnectorID, this.state.data.kpiData.OrgEntityID, this.state.data.kpiData.OfferingID, this.state.data.kpiData.LocationID)
  //     this.setState({
  //         data: {
  //             kpiData: kpiData,
  //         }
  //     })

  // }

  onOtherSystemChange(event) {
    const { kpiData } = this.state.data;
    kpiData.ConnectorNames = event.target.value;
    this.setState({
      data: {
        kpiData,
      },
    });
  }

  handleReset() {
    this.state.data.kpiData.KpiMappingID = 0;
    this.state.data.kpiData.MetricParam1 = '';
    this.state.data.kpiData.MetricParam2 = '';
    this.state.data.kpiData.MetricParam3 = '';
    this.state.data.kpiData.MetricParam4 = '';
    this.state.data.kpiData.MetricParam5 = '';
    this.state.data.kpiData.MetricParam6 = '';
    this.state.data.kpiData.Client = '';
    this.state.data.kpiData.Location = '';
    this.state.data.kpiData.Offering = '';
    this.state.data.kpiData.SubOffering = '';
    this.state.data.kpiData.ConnectorID.selectedIndex = 0;
    this.state.data.kpiData.DealID.selectedIndex = 0;
    this.state.data.kpiData.SOCategoryID = 0;
    this.state.data.kpiData.SOCategoryID = 0;
  }

  handleSaveTwo(e) {
    if (this.validateFormTwo()) {
      if (this.state.data.kpiData.ToolDealMappingID) {
        this.props.onSave(this.state.data.kpiData);
        this.handleHide();
      }
    }
  }

  handleSave(e) {
    if (this.validateForm()) {
      if (this.state.data.kpiData.ToolDealMappingID) {
        this.props.onSave(this.state.data.kpiData);
        this.handleHide();
      }
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if ((e.target.textContent != '×')) {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }

  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != '×') {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }

  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != '×') {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }

  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  getOfferings(Client) {
    let CategoryOptions = '';
    let offeringData = [];
    let ID = 0;
    const OffData = '';

    if (this.props.data != undefined) {
      const mappingData = this.props.data;

      axiosInstance.get(`${LocalApiBaseUrl}so/GetSOCategories`, 
        { params: { LanguageID: store.getState().CurrentFilter.languageData.id } }).then((response) => {
        offeringData = response.data;
        this.OfferingValues = offeringData;
        ID = this.props.data.SOCategoryID;
        const mappedConnector = offeringData.filter((x) => x.SOCategoryID == ID);

        CategoryOptions = offeringData.map((dropValue, index) => <option key={dropValue.SOCategoryID} selected={index == 0 ? '' : '0'} value={dropValue.SOCategoryID}>{dropValue.SOCategoryName}</option>);

        this.bindProcess({ target: { value: 0 } });
        this.setState({ CategoryOptions });
      })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    } else {
      axiosInstance.get(`${LocalApiBaseUrl}so/GetSOCategories`,
        { params: { LanguageID: store.getState().CurrentFilter.languageData.id } }).then((response) => {
        offeringData = response.data;
        this.OfferingValues = offeringData;

        CategoryOptions = offeringData.map((dropValue, index) => <option key={dropValue.SOCategoryID} selected={index == 0 ? '' : ''} value={dropValue.SOCategoryID}>{dropValue.SOCategoryName}</option>);

        this.bindProcess({ target: { value: 0 } });

        this.setState({ CategoryOptions });
      })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  validateFormTwo() {
    const { kpiData } = this.state.data;
    const errors = {};
    let formIsValid = true;

    if (!kpiData.Offering) {
      formIsValid = false;
      errors.OfferingID = 'Offering  is required';
    }
    if (!kpiData.ProcessID) {
      formIsValid = false;
      errors.ProcessID = 'Sub Offering is required';
    }
    if (!kpiData.LocationID) {
      formIsValid = false;
      errors.LocationID = 'Location is required';
    }

    this.setState({
      errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  validateForm() {
    const { specialCharmessage } = this.state;
    const { kpiData } = this.state.data;
    const errors = {};
    let formIsValid = true;

    if (!kpiData.Offering && !kpiData.DestinationOffering) {
      formIsValid = false;
      errors.OfferingID = 'Offering  is required';
    }
    if (!kpiData.ProcessID) {
      formIsValid = false;
      errors.ProcessID = 'Sub Offering is required';
    }
    if (!kpiData.LocationID) {
      formIsValid = false;
      errors.LocationID = 'Location is required';
    }

    if (!kpiData.MetricParam1) {
      formIsValid = false;
      errors.MetricParam1 = 'Metric Param 1 is required';
    } else {
      const isValid = regexForConnectorField.test(kpiData.MetricParam1);
      if (!isValid) {
        formIsValid = false;
        errors.MetricParam1 = 'Value contains Special characters';
      }
      else{
        if(!isValidText(kpiData.MetricParam1.trim())) {
          errors.MetricParam1 = specialCharmessage;
          formIsValid = false;
      }
      }
    }

    if (!kpiData.MetricParam2) {
      formIsValid = false;
      errors.MetricParam2 = 'Metric Param 2 is required';
    } else {
      const isValid = regexForConnectorField.test(kpiData.MetricParam2);
      if (!isValid) {
        formIsValid = false;
        errors.MetricParam2 = 'Value contains Special characters';
      }
      else{
        if(!isValidText(kpiData.MetricParam2.trim())) {
          errors.MetricParam2 = specialCharmessage;
          formIsValid = false;
      }
      }
    }
    if (kpiData.MetricParam3) {
        if(!isValidText(kpiData.MetricParam3.trim())) {
          errors.MetricParam3 = specialCharmessage;
          formIsValid = false;
      }
    }
    if (kpiData.MetricParam4) {
      if(!isValidText(kpiData.MetricParam4.trim())) {
        errors.MetricParam4 = specialCharmessage;
        formIsValid = false;
    }
  }
  if (kpiData.MetricParam5) {
    if(!isValidText(kpiData.MetricParam5.trim())) {
      errors.MetricParam5 = specialCharmessage;
      formIsValid = false;
  }
}
if (kpiData.MetricParam6) {
  if(!isValidText(kpiData.MetricParam6.trim())) {
    errors.MetricParam6 = specialCharmessage;
    formIsValid = false;
}
}

    if (!kpiData.ConnectorID) {
      formIsValid = false;
      errors.ConnectorID = 'Source is required';
    }
    if (!kpiData.SOCategoryID) {
      formIsValid = false;
      errors.SOCategoryID = 'Lever is required';
    }
    if (!kpiData.SubSOCategoryID) {
      formIsValid = false;
      errors.SubSOCategoryID = 'Sub-Lever is required';
    }
    if (!kpiData.MetricID) {
      formIsValid = false;
      errors.SynOpsMetricName = 'Metric is required';
    }

    if (kpiData.MetricParam3 != null) {
      if (kpiData.MetricParam3) {
        const isValid = regexForConnectorField.test(kpiData.MetricParam3);
        if (!isValid) {
          formIsValid = false;
          errors.MetricParam3 = 'Metric Param 4 contains Special characters';
        }
      }
    }

    if (kpiData.MetricParam4 != null) {
      if (kpiData.MetricParam4) {
        const isValid = regexForConnectorField.test(kpiData.MetricParam4);
        if (!isValid) {
          formIsValid = false;
          errors.MetricParam4 = 'Metric Param 4 contains Special characters';
        }
      }
    }
    if (kpiData.MetricParam5 != null) {
      if (kpiData.MetricParam5) {
        const isValid = regexForConnectorField.test(kpiData.MetricParam5);
        if (!isValid) {
          formIsValid = false;
          errors.MetricParam5 = 'Metric Param 5 contains Special characters';
        }
      }
    }
    if (kpiData.MetricParam6 != null) {
      if (kpiData.MetricParam6) {
        const isValid = regexForConnectorField.test(kpiData.MetricParam6);
        if (!isValid) {
          formIsValid = false;
          errors.MetricParam6 = 'Metric Param 6 contains Special characters';
        }
      }
    }

    this.setState({
      errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  render() {
    return (

      <>

        <Modal show={this.state.show} onHide={this.handleHide.bind(this)} backdrop="static" className="modal fade" size="medium" id="dealpopup">
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">Connector Mapping Master</h4>
          </Modal.Header>
          <Modal.Body>

            <form>
              <div className="form-section">
                <p className="fs-medium">Mapping source connector fields with its corresponding fields in SynOps for fetching metric data</p>
                                    <div className="errorMsg">{this.state.OneTimeSetupError}</div>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="row">
                                          <div className="col-lg-12">
                                            <h3 className="grid-heading border-0 mt-2 mb-3">SynOps Details</h3>
                                          </div>
                                          <div className="form-group col-lg-12">
                                            <label htmlFor="">Destination System</label>
                                            <input type="text" maxLength="200" className="form-control" id="" value="SynOps" disabled />
                                          </div>
                                          <div className="form-group col-lg-12">
                                            <label htmlFor="">Client Name</label>
                                            <input type="text" maxLength="200" className="form-control" id="" value={this.state.data.kpiData.DestinationClient ? this.state.data.kpiData.DestinationClient : this.state.data.kpiData.DealName} disabled />
                                          </div>
                                          <div className="form-group col-lg-12">
                                            <label htmlFor="">
                                                  Offering
                                                <span className="required">*</span>
                                                </label>
                                            {this.props.data != null
                                                  ? (
                                                      <select id="Offering" className="form-control" ref="OfferingDropDown" disabled={this.props.data || this.state.apivalue} onChange={this.onDestinationOfferingsChange.bind(this)} value={this.state.DestinationOfferingValueID}>
                                                          <option value="0">Select Offering</option>
                                                          {this.state.OfferingOptions.map((row) => <option value={row.OfferingID}>{row.OfferingName}</option>)}
                                                        </select>
                                                  )
                                                  : (
                                                      <select id="Offering" className="form-control" ref="OfferingDropDown" disabled={this.props.data || this.state.apivalue} onChange={this.onDestinationOfferingsChange.bind(this)} value={this.state.DestinationOfferingValueID}>
                                                          <option value="0">Select Offering</option>
                                                          {this.state.OfferingOptions.map((row) => <option value={row.OfferingID}>{row.OfferingName}</option>)}
                                                        </select>
                                                  )}
                                            {<div className="errorMsg">{this.state.errors.OfferingID}</div>}

                                          </div>

                      <div className="form-group col-lg-12">
                        <SubOfferingDropdowns
                            selectedSubOffering={this.props.data || this.state.apivalue ?
                                                  this.state.data.kpiData.DestinationProcess :
                                                  this.state.data.kpiData.Process}
                            subOfferings={this.state.processOptions}
                            onSubOfferingChange={(e) => this.onDestinationOfferingsProcessChange(e)}
                            defaultToggleName={i18n.t('Select_Sub_Offerings')}
                            selectedSubOfferingId={this.state.processValueID}
                            required
                            labelClassName=""
                            labelfor=""
                            disabledValue={this.props.data || this.state.apivalue}
                            dropdownItem={true}
                            labelpresent={true}
                            SubOfferingDropdownsClass={true}
                        />
                        <span className="errorMsg">{this.state.errors.ProcessID}</span>
                      </div>
                      <div className="form-group col-lg-12">
                        <label htmlFor="">
                              Location
{' '}
                              <span className="required">*</span>
                            </label>

                        <select id="Location" className="form-control" disabled={this.props.data || this.state.apivalue} ref="LocationDropdown" onChange={this.onDestinationLocationChange.bind(this)} value={this.state.data.kpiData.LocationID}>
                              <option value="0">Select Location</option>
                              {this.state.LocationOptions.map((row) => {
                                  if (row.IsActive) {
                                    return <option value={row.LocationID}>{row.City}</option>;
                                  }
                                })}
                            </select>

                        <span className="errorMsg">{this.state.errors.LocationID}</span>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3 className="grid-heading border-0 mt-2 mb-3">Source Details</h3>
                      </div>
                      <div className="form-group col-lg-12">
                        {' '}
                        {/* copy Paste field: vignesh */}
                        <label htmlFor="" className="source-system-padding">
                              Source System
<span className="required ">*</span>
                            </label>
                        {this.state.con ? (
                              <select className="form-control" readOnly onChange={this.onSourceChange} value={this.state.data.kpiData.ConnectorID}>

                                  {this.state.connectorOptions}
                                </select>
                            ) : this.state.valueConnectorEdit ? (
                                <input type="text" className="form-control" readOnly onChange={this.onSourceChange} value={this.props.kpiData.ConnectorNames} />
                            ) : (
                                <select
                                    className="form-control" disabled={this.props.data} onChange={this.onSourceChange}
                                    value={this.state.data.kpiData.ConnectorID}
                                  >
                                    <option value="0">Select Source</option>
                                    {this.state.sourceOptions}

                                  </select>
                            )}
                        <div className="errorMsg">{this.state.errors.ConnectorID}</div>
                      </div>
                      <div className="form-group col-lg-12">
                        <label htmlFor="">
                              Client Name
<span className="required" />
                            </label>
                        <input onChange={this.onClientChange} disabled type="text" maxLength="200" className="form-control" id="" value={this.state.data.kpiData.Client} />

                      </div>
                      <div className="form-group col-lg-12">
                        <label htmlFor="">
                              Offering
                            <span className="required" />
                            </label>
                        {this.props.data != null
                          ? <input onChange={this.onSourceOfferingChange} disabled type="text" maxLength="200" className="form-control" id="" value={this.props.data.Offering} />
                          : <input onChange={this.onSourceOfferingChange} disabled type="text" maxLength="200" className="form-control" id="" value={this.state.data.kpiData.SourceOffering} />}
                      </div>
                      <div className="form-group col-lg-12">
                        <label htmlFor="">
                              Sub-Offering
<span className="required" />
                            </label>
                        <input onChange={this.onSubOfferingChange} disabled type="text" maxLength="200" className="form-control" id="" value={this.state.data.kpiData.SubOffering} />
                      </div>
                      <div className="form-group col-lg-12">
                        <label htmlFor="">
                              Location
<span className="required" />
                            </label>
                        {this.props.data != null
                              ? <input onChange={this.onLocationChange} disabled type="text" maxLength="200" className="form-control" id="" value={this.props.data.Location} />
                              : <input onChange={this.onLocationChange} disabled type="text" maxLength="200" className="form-control" id="" value={this.state.data.kpiData.SourceLocation} />}
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="grid-heading border-0 mt-4 mb-3">Metric Detail</h3>
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label htmlFor="">
                      SynOps Lever
                      {' '}
                      <span className="required">*</span>
                    </label>
                    <select className="form-control" name="" id="offerings" disabled={this.props.data} value={this.props.data ? this.state.data.kpiData.SOCategoryID : this.state.data.kpiData.SOCategoryID} onChange={this.bindProcess.bind(this)}>
                      <option value="0">Select Lever</option>
                      {this.state.CategoryOptions}
                    </select>
                    <div className="errorMsg">{this.state.errors.SOCategoryID}</div>
                  </div>
                  <div className="form-group col-lg-6">
                    <label htmlFor="">
                      SynOps Sub Lever
                      {' '}
                      <span className="required">*</span>
                    </label>
                    {this.props.data != undefined
                      ? (
                        <select
                              className="form-control"
                              disabled={this.props.data}
                              onChange={this.onSoCategoryChange.bind(this)}
                              value={this.props.data.SubSOCategoryID}
                            >
                              <option value="0">Select Sub Lever</option>
                              {this.state.temp.map((row, index) => <option selected={index == 0 ? '' : '0'} value={row.SubSOCategoryID}>{row.SubSOCategory}</option>)}

                            </select>
                      )

                      : (
                        <select
                              className="form-control"
                              onChange={this.onSoCategoryChange}
                              value={this.state.data.kpiData.SubSOCategoryID}
                            >
                              <option value="0">Select Sub Lever</option>
                              {this.state.temp.map((row) => <option value={row.SubSOCategoryID}>{row.SubSOCategory}</option>)}

                            </select>
                      )}
                    {this.state.data.SubSOCategoryID != 0 ? <div className="errorMsg">{this.state.errors.SubSOCategoryID}</div> : <div className="errorMsg">{this.state.errors.SubSOCategoryID}</div>}
                  </div>
                  <div className="form-group col-lg-6">
                    <label htmlFor="">
                      Metric
                      <span className="required">*</span>
                    </label>
                    {this.props.data != undefined
                      ? (
                        <select id="name" className="form-control" disabled={this.props.data} ref="MetricNameDropdown" onChange={this.onMetricNameChange.bind(this)} value={this.props.data.MetricID}>
                              <option value="">Select Metric</option>
                              <option value={this.props.data.MetricID}>{this.props.data.SynOpsMetricName}</option>

                            </select>
                      )
                      : (
                        <select id="name" className="form-control" disabled={this.props.data} ref="MetricNameDropdown" onChange={this.onMetricNameChange.bind(this)} value={this.state.data.kpiData.MetricID}>
                              <option value="0">Select Metric</option>
                              {this.state.MetricNameOptions.map((row) => <option value={row.MetricID}>{row.MetricName}</option>)}

                            </select>
                      )}
                    <span className="errorMsg">{this.state.errors.SynOpsMetricName}</span>
                  </div>

                </div>

                <h3 className="grid-heading border-0 mt-4 mb-3">Mapping SynOps Metric to respective source data fields</h3>
                <div className="row">
                  {this.state.data.kpiData != undefined
                    ? (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                              {' '}
                              {this.state.data.kpiData.ParamName1 != null ? this.state.data.kpiData.ParamName1 : null}
                              {' '}
                              (Metric Param 1)
    {' '}
                              <span className="required">*</span>
                            </label>

                        {this.state.data.kpiData.HasMasterParam1 == true
                              ? (
                                  <select className="form-control" id="" onChange={this.onMetricParam1Change.bind(this)} value={this.state.data.kpiData.MetricParam1.toUpperCase()}>
                                      <option value="">--Select--</option>
                                      {this.state.MetricParam1Options.map((row) => row !== null ? row.toUpperCase() !== 'NULL' && <option value={row}>{row}</option>:<></>)}
                                    </select>
                              )
                              : <input type="text" xpattern="\d*" maxLength="200" className="form-control" id="" onChange={this.onMetricParam1Change} value={this.state.data.kpiData.MetricParam1} />}
                        <div className="errorMsg">{this.state.errors.MetricParam1}</div>
                      </div>
                    )
                    : (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                              {' '}
                              {this.state.data.kpiData.ParamName1 != null ? this.state.data.kpiData.ParamName1 : null}
                              {' '}
                              (Metric Param 1)
    {' '}
                              <span className="required">*</span>
                            </label>
                        <input type="text" xpattern="\d*" maxLength="200" className="form-control" id="" onChange={this.onMetricParam1Change} value={this.state.data.kpiData.MetricParam1} />
                        <div className="errorMsg">{this.state.errors.MetricParam1}</div>
                      </div>
                    )}

                  {this.state.data.kpiData != undefined

                    ? (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                              {' '}
                              {this.state.data.kpiData.ParamName2 != null ? this.state.data.kpiData.ParamName2 : null}
                              {' '}
                              (Metric Param 2)
    {' '}
                              <span className="required">*</span>
                            </label>
                        {this.state.data.kpiData.HasMasterParam2 == true
                              ? (
                                  <select className="form-control" id="" onChange={this.onMetricParam2Change.bind(this)} value={this.state.data.kpiData.MetricParam2}>
                                      <option value="">--Select--</option>
                                      {this.state.MetricParam2Options.map((row) => row !== null ? row.toUpperCase() !== 'NULL' && <option value={row}>{row}</option>:<></>)}
                                    </select>
                              )
                              : <input type="text" xpattern="\d*" maxLength="200" className="form-control" id="" onChange={this.onMetricParam2Change} value={this.state.data.kpiData.MetricParam2} />}
                        <div className="errorMsg">{this.state.errors.MetricParam2}</div>

                      </div>
                    )
                    : (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                              {' '}
                              {this.state.data.kpiData.ParamName2 != null ? this.state.data.kpiData.ParamName2 : null}
                              {' '}
                              (Metric Param 2)
    {' '}
                              <span className="required">*</span>
                            </label>
                        <input type="text" xpattern="\d*" maxLength="200" className="form-control" id="" onChange={this.onMetricParam2Change} value={this.state.data.kpiData.MetricParam2} />
                        <div className="errorMsg">{this.state.errors.MetricParam2}</div>

                      </div>
                    )}

                  {this.state.data.kpiData != undefined
                    ? (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                              {this.state.data.kpiData.ParamName3 != null ? this.state.data.kpiData.ParamName3 : null}
                              {' '}
                              (Metric Param 3 )
    {' '}
                            </label>
                        {this.state.data.kpiData.HasMasterParam3 == true
                              ? (
                                  <select className="form-control" id="" onChange={this.onMetricParam3Change.bind(this)} value={this.state.data.kpiData.MetricParam3}>
                                      <option>--Select--</option>
                                      {this.state.MetricParam3Options.map((row) => row !== null ? row.toUpperCase() !== 'NULL' && <option value={row}>{row}</option>:<></>)}
                                    </select>
                              )
                              : <input type="text" className="form-control" maxLength="200" id="" onChange={this.onMetricParam3Change} value={this.state.data.kpiData.MetricParam3} />}
                        <div className="errorMsg">{this.state.errors.MetricParam3}</div>
                      </div>
                    )
                    : (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                              {this.state.data.kpiData.ParamName3 != null ? this.state.data.kpiData.ParamName3 : null}
                              {' '}
                              (Metric Param 3 )
    {' '}
                            </label>
                        <input type="text" className="form-control" maxLength="200" id="" onChange={this.onMetricParam3Change} value={this.state.data.kpiData.MetricParam3} />

                        <div className="errorMsg">{this.state.errors.MetricParam3}</div>
                      </div>
                    )}

                  {this.state.data.kpiData != undefined
                    ? (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                              {' '}
                              {this.state.data.kpiData.ParamName4 != null ? this.state.data.kpiData.ParamName4 : null}
                              {' '}
                              (Metric Param 4)
    {' '}
                            </label>
                        {this.state.data.kpiData.HasMasterParam4 == true
                              ? (
                                  <select className="form-control" id="" onChange={this.onMetricParam4Change.bind(this)} value={this.state.data.kpiData.MetricParam4}>
                                      <option>--Select--</option>
                                      {this.state.MetricParam4Options.map((row) => row !== null ? row.toUpperCase() !== 'NULL' && <option value={row}>{row}</option>:<></>)}
                                    </select>
                              )
                              : <input type="text" maxLength="200" className="form-control" id="" onChange={this.onMetricParam4Change} value={this.state.data.kpiData.MetricParam4} />}
                        <div className="errorMsg">{this.state.errors.MetricParam4}</div>
                      </div>
                    )
                    : (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                              {' '}
                              {this.state.data.kpiData.ParamName4 != null ? +this.state.data.kpiData.ParamName4 : null}
                              {' '}
                              (Metric Param 4)
    {' '}
                            </label>
                        <input type="text" maxLength="200" className="form-control" id="" onChange={this.onMetricParam4Change} value={this.state.data.kpiData.MetricParam4} />
                        <div className="errorMsg">{this.state.errors.MetricParam4}</div>
                      </div>
                    )}

                  {this.state.data.kpiData != undefined
                    ? (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                              {' '}
                              {this.state.data.kpiData.ParamName5 != null ? this.state.data.kpiData.ParamName5 : null}
                              {' '}
                              (Metric Param 5)
    {' '}
                            </label>
                        {this.state.data.kpiData.HasMasterParam5 == true
                              ? (
                                  <select className="form-control" id="" onChange={this.onMetricParam5Change.bind(this)} value={this.state.data.kpiData.MetricParam5}>
                                      <option>--Select--</option>
                                      {this.state.MetricParam5Options.map((row) => row !== null ? row.toUpperCase() !== 'NULL' && <option value={row}>{row}</option>:<></>)}
                                    </select>
                              )
                              : <input type="text" maxLength="200" className="form-control" id="" onChange={this.onMetricParam5Change} value={this.state.data.kpiData.MetricParam5} />}
                        <div className="errorMsg">{this.state.errors.MetricParam5}</div>
                      </div>
                    )
                    : (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                              {' '}
                              {this.state.data.kpiData.ParamName5 != null ? this.state.data.kpiData.ParamName5 : null}
                              {' '}
                              (Metric Param 5)
    {' '}
                            </label>
                        <input type="text" maxLength="200" className="form-control" id="" onChange={this.onMetricParam5Change} value={this.state.data.kpiData.MetricParam5} />
                        <div className="errorMsg">{this.state.errors.MetricParam5}</div>
                      </div>
                    )}

                  {this.state.data.kpiData != undefined
                    ? (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                              {' '}
                              {this.state.data.kpiData.ParamName6 != null ? this.state.data.kpiData.ParamName6 : null}
                              {' '}
                              (Metric Param 6)
    {' '}
                            </label>
                        {this.state.data.kpiData.HasMasterParam6 == true
                              ? (
                                  <select className="form-control" id="" onChange={this.onMetricParam6Change.bind(this)} value={this.state.data.kpiData.MetricParam6}>
                                      <option>--Select--</option>
                                      {this.state.MetricParam6Options.map((row) => row !== null ? row.toUpperCase() !== 'NULL' && <option value={row}>{row}</option>:<></>)}
                                    </select>
                              )
                              : <input type="text" maxLength="200" className="form-control" id="" onChange={this.onMetricParam6Change} value={this.state.data.kpiData.MetricParam6} />}
                        <div className="errorMsg">{this.state.errors.MetricParam6}</div>
                      </div>
                    )

                    : (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                              {' '}
                              {this.state.data.kpiData.ParamName6 != null ? this.state.data.kpiData.ParamName6 : null}
                              {' '}
                              (Metric Param 6)
    {' '}
                            </label>
                        <input type="text" maxLength="200" className="form-control" id="" onChange={this.onMetricParam6Change} value={this.state.data.kpiData.MetricParam6} />
                        <div className="errorMsg">{this.state.errors.MetricParam6}</div>
                      </div>
                    )}
                </div>
              </div>

            </form>

          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              {!this.props.data
                ? <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Create" />
                : <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Update" />}
            </div>
          </Modal.Footer>
        </Modal>

      </>
    );
  }
}
