import Workbook from "react-excel-workbook";
import React, { Component } from "react";
import moment from "moment";

export default class ToolsExportCsv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excelData: [],
      active: false,
      keysToExclude: [
        "ApplicationMappingID",
        "ClickableToolMappingID",
        "ApprovelID",
        "OfferingID",
        "LocationID",
        "ApplicationName_En",
        "ApplicationCategoryID",
        "ApplicationDescription_En",
        "SOCategoryID",
        "OrgEntityName",
        "Offering",
        "Process",
        "City",
        "DefaultUserID",
        "ApprovalLevel",
        "IsActive",
        "IsClickableTool",
        "Action",
        "IconBase64",
        "Icon",
        "ClientID",
        "IsMasterTool",
        "BusinessBenefit",
        "BusinessBenefit_En",
        "OrgEntityID",
        "CreatedUser",
        "CreatedTS",
        "UpdatedUser",
        "SummeryView",
        "UpdatedTS",
        "ClickableToolID",
        "ToolCategory",
        "SequenceNumber",
      ],
    };
    this.inputRef = React.createRef();
    this.getExcelData = this.getExcelData.bind(this);
  }

  getExcelData = () => {
    let excelData = this.props.csvProp;
    this.setState(
      {
        excelData: excelData,
      },
      () => {
        this.inputRef.current.click();
      }
    );
  };

  render() {
    let { excelData, keysToExclude } = this.state;
    const columnWidths = [
      { wch: 500 },
      { wch: 500 },
      { wch: 500 },
      { wch: 500 },
    ];
    let currentdate = new Date();
    let formatDate = moment(currentdate).format("YYYYMMDD");
    let formatTime = moment(currentdate).format("HHmmss");
    let formatSheetName = `MTCToolReport_${formatDate}_${formatTime}`;
    let Data, columnKeys;
    if (excelData != null && excelData.length > 0) {
      Data = [...excelData];
      columnKeys = Object.keys(Data[0]);
      columnKeys = columnKeys.filter((a) => !keysToExclude.includes(a));
    } else {
      Data = [{ Message: "Data Not available" }];
    }
    let TabName = "MTCTools";
    return (
      <div className="mtcexport-download">
        <button className="btn" onClick={this.getExcelData}>
          <i class="far fa-download download-icon-fix"></i>
        </button>
        {excelData.length > 0 && (
          <Workbook
            filename={`${formatSheetName}.xlsx`}
            element={
              <button style={{ display: "none" }} ref={this.inputRef}></button>
            }
          >
            <Workbook.Sheet
              data={Data}
              name={TabName}
              columsWidths={columnWidths}
            >
              {columnKeys.map((column) => (
                <Workbook.Column label={column} value={column} />
              ))}
            </Workbook.Sheet>
          </Workbook>
        )}
      </div>
    );
  }
}
