import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import _ from "lodash";
import OwlCarousel from "react-owl-carousel2";
import LoadingOverlay from "react-loading-overlay";
import classNames from "classnames";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Tab, Nav } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import BatCommonHeader from "./BatCommonHeader";
import MetricInfoModal from "./MetricInfoModal";
import MetricInsightDetails from "./MetricInsightDetails";
import TotalValueEnabledModal from "./TotalValueEnabledModal";
import MetricParametersModal from "./MetricParametersModal";
import SynopsLoader from "../Shared/SynopsLoader";
import {
  LocalApiBaseUrl,
  ROUTE_CONSTANTS,
  BATFormattedDefaultStartDate,
  BATFormattedDefaultEndDate,
  getFormattedUnitNameBAT as getFormattedUnitName,
  numberFormatter,
} from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

// IMPORT STYLES
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";

//IMPORT IMAGES
import UpwardCarat from "../Images/tve_Icons/UpwardCarat.svg";
import UpwardCarat_Selected from "../Images/tve_Icons/UpwardCarat _Selected.svg";
import DownwardCarat from "../Images/tve_Icons/DownwardCarat.svg";
import DownwardCarat_Selected from "../Images/tve_Icons/DownwardCarat_Selected.svg";
import { each } from "jquery";
// import { forEach } from "core-js/library/js/array";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem(
  "msal.idtoken"
)}`;

function calculateMetricImprovementOpportunity({
  metricLeadingPractice,
  clientValue,
  benchmarkValue,
}) {
  let opportunityValue = "";
  let performanceIndicator = "";

  // TO CALCULATE IMPROVEMENT OPPORTUNITY VALUE BOTH `clientValue` AND `benchmarkValue` MUST BE PRESENT. IF NOT SO THE VALUE WOULD NOT BE CALCULATED AND THE `performanceIndicator` WOULD BE SET TO "worst"
  if (!_.isEmpty(String(clientValue)) && !_.isEmpty(String(benchmarkValue))) {
    const clientValueParsed = _.toNumber(clientValue);
    const benchmarkValueParsed = _.toNumber(benchmarkValue);

    if (metricLeadingPractice === "minimum") {
      opportunityValue =
        ((clientValueParsed - benchmarkValueParsed) / Math.abs(clientValueParsed)) * 100;
    } else {
      opportunityValue =
        ((benchmarkValueParsed - clientValueParsed) / Math.abs(clientValueParsed)) * 100;
    }
    opportunityValue = parseFloat(opportunityValue.toFixed(2));
  }

  if (!_.isEmpty(String(opportunityValue))) {
    if (opportunityValue < 0) {
      performanceIndicator = "better"; // green
    } else if (opportunityValue >= 0 && opportunityValue <= 40) {
      performanceIndicator = "worse"; // amber (yellow)
    } else if (opportunityValue > 40) {
      performanceIndicator = "worst"; // red
    }

    // IF OPPORTUNITY VALUE IS LESS THAN ZERO, THEN THE OPPORTUNITY VALUE IS NOT TO BE DISPLAYED
    if (performanceIndicator === "better") {
      opportunityValue = "";
    }
  }

  return {
    opportunityValue,
    performanceIndicator,
  };
}

function calculateMetricTVE({
  metricID,
  metricUnit,
  formulasForTVECalculation,
  volumetricDetails,
  clientValue,
  benchmarkValue,
}) {
  const isMetricExcepted = _.indexOf(exceptedTVEMetrics, metricID) !== -1;
  let totalValueEnabled = "";
  let totalValueEnabledSuffixText = "";
  let totalValueEnabledUOM = "";

  // LOOP OVER METRIC TVE CALCULATION FORMULAS
  let modifiedFormulasForTVECalculation = _.map(formulasForTVECalculation, (eachFormula) => {
    const tveFormula = eachFormula.tveFormula;
    let tveFormulaParsed = "";
    let calculatedValueForFormula = "";
    let areAllVolumetricsFilled = true;
    // IN THE FORMULA STRING "{VS}" STRING INDICATES THE START OF THE FORMULA SYMBOL, SO SUCH STRING EXISTS THEN IT INDICATES THAT IT IS THE FORMULA CONTAINING THE VOLUMETRICS
    const isFormulaContainingVolumetrics = tveFormula.indexOf("{VS}") !== -1;

    if (!_.isEmpty(String(clientValue)) && !_.isEmpty(String(benchmarkValue))) {
      let clientValueParsed = _.toNumber(clientValue);
      let benchmarkValueParsed = _.toNumber(benchmarkValue);

      // IF THE METRIC UNIT IS "PERCENTAGE", THEN CONVERT THE VALUES TO PERCENTAGE FOR TVE CALCULATION
      if (metricUnit === "percentage" && metricID != 410 && metricID != 423) {
        clientValueParsed = clientValueParsed / 100;
        benchmarkValueParsed = benchmarkValueParsed / 100;
      }
      tveFormulaParsed = tveFormula;
      // REPLACE THE VALUE AS STRING ENCLOSED WITH ROUND BRACKETS SO THAT THE CALCULATION OF eval DOES NOT BREAK WHEN THE VALUES ARE NEGATIVE
      tveFormulaParsed = _.replace(
        tveFormulaParsed,
        new RegExp("CV", "g"),
        String(`(${clientValueParsed})`)
      );
      tveFormulaParsed = _.replace(
        tveFormulaParsed,
        new RegExp("BV", "g"),
        String(`(${benchmarkValueParsed})`)
      );

      // LOOP OVER VOLUMETRICS TO CHECK IF ALL HAVE VALUE FILLED UP THEN REPLACE THE VOLUMETRIC SYMBOL FROM THE CALCULATION FORMULA SO THAT THE TVE VALUE CAN BE CALCULATED
      _.forEach(volumetricDetails, (eachVolumetric) => {
        if (!_.isEmpty(String(eachVolumetric.enteredValue))) {
          const volumetricUnit = eachVolumetric.volumetricUnit;
          let volumetricValue = _.toNumber(eachVolumetric.enteredValue);

          // IF THE VOLUMETRIC UNIT IS "PERCENTAGE", THEN CONVERT THE VALUES TO PERCENTAGE FOR TVE CALCULATION
          if (volumetricUnit === "percentage") {
            volumetricValue = volumetricValue / 100;
          }
          const volumetricSymbol = eachVolumetric.volumetricSymbol;

          // REPLACE THE VALUE AS STRING ENCLOSED WITH ROUND BRACKETS SO THAT THE CALCULATION OF eval DOES NOT BREAK WHEN THE VALUES ARE NEGATIVE
          tveFormulaParsed = _.replace(
            tveFormulaParsed,
            new RegExp(`{VS}${volumetricSymbol}{VE}`, "g"),
            String(`(${volumetricValue})`)
          );
        } else {
          // UPDATE THE FLAG TO `false` ONLY IF IT WAS `true` BEFORE
          if (areAllVolumetricsFilled) {
            areAllVolumetricsFilled = false;
          }
        }
      });

      // IF THE METRIC IS EXCEPTED THEN CALCULATE THE "TVE" VALUE FROM THE OTHER FORMULA WHICH JUST USES `clientValue` AND `benchmarkValue`, AND IF ALL THE VOLUMETRICS ARE FILLED THEN DERIVE THE "TVE" VALUE FROM THE FORMULA WHICH USES VOLUMETRIC VALUES ALSO ALONG WITH `clientValue` AND `benchmarkValue`
      if (isMetricExcepted && !isFormulaContainingVolumetrics) {
        calculatedValueForFormula = _.toNumber(eval(tveFormulaParsed));
      }

      if (!_.isEmpty(volumetricDetails) && areAllVolumetricsFilled) {
        calculatedValueForFormula = _.toNumber(eval(tveFormulaParsed));
      }

      // IF THE METRIC IS NOT EXCEPTED AND HAS GOT NO VOLUMETRICS, THEN TVE VALUE WOULD BE DERIVED FROM THE FORMULA THAT DOES NOT CONTAIN VOLUMETRICS
      if (!isMetricExcepted && !isFormulaContainingVolumetrics && _.isEmpty(volumetricDetails)) {
        calculatedValueForFormula = _.toNumber(eval(tveFormulaParsed));
      }

      totalValueEnabled = calculatedValueForFormula;
      totalValueEnabledSuffixText = eachFormula.resultText;
    }

    return {
      ...eachFormula,
      tveFormulaParsed,
      calculatedValueForFormula: !_.isEmpty(String(calculatedValueForFormula))
        ? parseFloat(calculatedValueForFormula).toFixed(2)
        : "",
    };
  });

  // IF THE FORMULAS ARE NOT EMPTY, THEN SORT THEM IN ASCENDING ORDER OF THE PREFERENCE SO THAT IF THE CALCULATED VALUE OF THE FIRST PREFERRED FORMULA IS NOT EMPTY THEN "TVE" VALUE WOULD BE THAT
  if (!_.isEmpty(formulasForTVECalculation)) {
    modifiedFormulasForTVECalculation = _.sortBy(modifiedFormulasForTVECalculation, [
      "preferenceOrder",
    ]);
    const firstPreferredFormula = _.first(modifiedFormulasForTVECalculation);

    if (!_.isEmpty(String(firstPreferredFormula.calculatedValueForFormula))) {
      totalValueEnabled = firstPreferredFormula.calculatedValueForFormula;
      totalValueEnabledSuffixText = firstPreferredFormula.resultText;
      totalValueEnabledUOM = firstPreferredFormula.formulaUOM;
    }
  }

  // IF THE CALCULATED VALUE IS FLOAT THEN CORRECT THE VALUE UPTO 2 DECIMAL PLACES
  if (!_.isEmpty(String(totalValueEnabled)) && !_.isInteger(totalValueEnabled)) {
    totalValueEnabled = parseFloat(totalValueEnabled).toFixed(2);
  }

  return {
    modifiedFormulasForTVECalculation,
    totalValueEnabled,
    totalValueEnabledFormatted: !_.isEmpty(String(totalValueEnabled))
      ? numberFormatter(totalValueEnabled, 2)
      : "",
    totalValueEnabledSuffixText,
    totalValueEnabledUOM,
  };
}

// these 2 metrics have 2 formulas for TVE (one with volumetric and one without volumetric). In case of these 2 metrics, if volumetrics are not shared then this Volumetric pop-up will not come up. Rather we will show, TVE pop-up with TVE value computed using formula without Volumetric details
const exceptedTVEMetrics = [
  400, // MetricName: First Contact Resolution - Voice, Offering: IS&CO - Customer Operations, Sub-offering: Customer Operations, BO: Optimize Operation, ValueDriver: Maximize efficiency/quality
  405, // MetricName: Average Handle Time - voice, Offering: IS&CO - Customer Operations, Sub-offering: Customer Operations, BO: Optimize Operation, ValueDriver: Maximize efficiency/quality
];
const volumetricModalSubHeaderText = "Volumetric details needed to compute 'Total Value Enabled'";
const errMsgInvalidValue = "Invalid Numeric Value";

class ClientPerformanceView extends Component {
  constructor(props) {
    super(props);
    const clientPerformanceViewScreenData = _.get(
      props,
      "location.state.clientPerformanceViewScreenData",
      {}
    );
    const startDateFromProps = _.get(clientPerformanceViewScreenData, "startDate", "");
    const endDateFromProps = _.get(clientPerformanceViewScreenData, "endDate", "");

    const offeringName = _.get(clientPerformanceViewScreenData, "offeringName", "");
    const offeringType = _.get(clientPerformanceViewScreenData, "offeringType", "");
    const subOfferingId = _.get(clientPerformanceViewScreenData, "subOfferingId", "");
    const subOfferingName = _.get(clientPerformanceViewScreenData, "subOfferingName", "");
    const isSubOfferingTVEEnabled = _.get(
      clientPerformanceViewScreenData,
      "isSubOfferingTVEEnabled",
      false
    );
    const startDate = !_.isEmpty(startDateFromProps)
      ? startDateFromProps
      : BATFormattedDefaultStartDate;
    const endDate = !_.isEmpty(endDateFromProps) ? endDateFromProps : BATFormattedDefaultEndDate;
    const clientCount = _.get(clientPerformanceViewScreenData, "clientCount", "");
    const clientName = _.get(clientPerformanceViewScreenData, "clientName", "");
    const clientIndustry = _.get(clientPerformanceViewScreenData, "clientIndustry", "");
    const clientMarketUnit = _.get(clientPerformanceViewScreenData, "clientMarketUnit", "");
    const BATAssessSubOfferingID = _.get(
      clientPerformanceViewScreenData,
      "BATAssessSubOfferingID",
      ""
    );
    const ClientLogo = _.get(clientPerformanceViewScreenData, "ClientLogo", "");

    this.state = {
      ClientLogo,
      BATAssessClientID: _.get(
        props,
        "location.state.clientPerformanceViewScreenData.BATAssessClientID",
        ""
      ),
      isAPILoading: false,
      offeringBenchmarkDataFromBAT: {}, // HOLDS THE DATA RETURNED FROM THE API
      assessmentDataFromSynOps: {}, // HOLDS THE DATA RETURNED FROM THE API
      tveOfferingBenchmarkDataFromBAT: {}, // HOLDS THE DATA RETURNED FROM THE API
      tveSubOfferingBenchmarkValuesFromBAT: {}, // HOLDS THE DATA RETURNED FROM THE API
      isVisibleMetricInfoModal: false,
      isVisibleMetricInsightDetailsModal: false,
      isVisibleMetricTVEModal: false,
      currentlySelectedMetricData: {},
      offeringName,
      offeringType,
      subOfferingId,
      subOfferingName,
      isSubOfferingTVEEnabled,
      startDate,
      endDate,
      clientCount,
      clientName,
      clientIndustry,
      clientMarketUnit,
      BATAssessSubOfferingID,
      dataByMetricCategories: [],
      expandedAccordionsKeysArray: ["all"], // KEEP ALL THE ACCORDIONS OPEN ON SCREEN LOAD
      selectedIndustryId: "",
      selectedRegionId: "",
      selectedBenchmark: "Top Quartile", // SET DEFAULT TO "Top Quartile"
      isLoadedDataForBenchmarkAnalysisView: false,
      isLoadedDataForValueCaseView: false,
      tveDataByMetricCategories: [],
      contractPeriod: "",
      allVolumetricFromSynOps: [],
      renderMetricParametersModalFor: "metricVolumetrics",
      areFormFieldsMandatoryForMetricParametersModal: true,
      subHeaderTextForMetricParametersModal: volumetricModalSubHeaderText,
      currentView: isSubOfferingTVEEnabled ? "ValueCaseView" : "BenchmarkAnalysisView",
      selectedTVEMetricsFilter: "",
    };
  }

  componentDidMount() {
    // FETCH THE DATA FROM THE APIs ON SCREEN LOAD
    this.fetchScreenData();
  }

  componentDidUpdate(prevProps, prevState) {
    // IF THE SELECTED CLIENT, OFFERING OR SUB-OFFERING IS CHANGED THEN EXPAND ALL THE ACCORDIONS
    const { offeringName, subOfferingName, clientName, currentView } = this.state;
    if (
      prevState.offeringName !== offeringName ||
      prevState.subOfferingName !== subOfferingName ||
      prevState.clientName !== clientName ||
      prevState.currentView !== currentView
    ) {
      this.setState({
        expandedAccordionsKeysArray: ["all"],
        selectedTVEMetricsFilter: "",
      });
    }
  }

  fetchScreenData = () => {
    this.setState(
      {
        isLoadedDataForValueCaseView: false,
        isLoadedDataForBenchmarkAnalysisView: false,
        offeringBenchmarkDataFromBAT: {},
        assessmentDataFromSynOps: {},
        tveOfferingBenchmarkDataFromBAT: {},
        tveSubOfferingBenchmarkValuesFromBAT: {},
        dataByMetricCategories: [],
        tveDataByMetricCategories: [],
      },
      () => {
        this.fetchAssessmentDataFromSynOps();
      }
    );
  };

  // FOR "NON-TVE ENABLED" SUB-OFFERING - API FOR GETTING THE METRIC DATA
  fetchOfferingBenchmarkDetailsFromBAT = () => {
    const { offeringName, subOfferingName, startDate, endDate, clientCount } = this.state;

    if (!_.isEmpty(offeringName)) {
      const requestData = {
        offeringName,
        towerName: subOfferingName,
        timeFrameStartDate: startDate,
        timeFrameEndDate: endDate,
        clientCount: _.toNumber(clientCount),
      };

      this.setState({ isAPILoading: true });

      // FETCH THE DATA FROM THE API
      return axiosInstance
        .post(`${LocalApiBaseUrl}BAT/GetOfferingBenchmarkDetails`, requestData)
        .then((response) => {
          let offeringBenchmarkDataFromBAT = {};
          if (!_.isEmpty(response.data) && !_.isEmpty(response.data.metricDetails)) {
            offeringBenchmarkDataFromBAT = response.data;
          }
          this.setState(
            {
              offeringBenchmarkDataFromBAT,
              isAPILoading: false,
            },
            () => {
              if (!_.isEmpty(offeringBenchmarkDataFromBAT)) {
                this.prepareDataForBenchmarkAnalysis();
              }
            }
          );
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({ isAPILoading: false });
        });
    }
  };

  // EXECUTES THE API REQUEST TO FETCH THE SAVED DATA FOR THE SUB-OFFERING AND METRICS FROM SynOps
  fetchAssessmentDataFromSynOps = () => {
    const { BATAssessSubOfferingID, offeringName, currentView } = this.state;
    let { contractPeriod, selectedIndustryId, selectedRegionId, selectedBenchmark } = this.state;

    if (!_.isEmpty(offeringName)) {
      this.setState({ isAPILoading: true });
      // FETCH THE DATA FROM THE API
      return axiosInstance
        .get(
          `${LocalApiBaseUrl}BATAssessment/GetAssessedMetricsClientDetailsBasedOnSubOffering?BATAssessSubOfferingID=${BATAssessSubOfferingID}`
        )
        .then((response) => {
          const responseData = response.data;
          let assessmentDataFromSynOps = {};
          if (!_.isEmpty(responseData)) {
            assessmentDataFromSynOps = responseData[0];
            contractPeriod = assessmentDataFromSynOps.ContractPeriod;
            // IF THE SELECTED INDUSTRY ID AND REGION ID FOR BENCHMARK SELECTION ARE EMPTY THEN TAKE THE INDUSTRY AND GEOGRAPHY OF THE CLIENT THAT ARE CAPTURED WHILE CREATING THE ASSESSMENT
            selectedIndustryId = !_.isEmpty(String(assessmentDataFromSynOps.SelectedBATIndustryID))
              ? assessmentDataFromSynOps.SelectedBATIndustryID
              : assessmentDataFromSynOps.BATIndustryID;

            selectedRegionId = !_.isEmpty(String(assessmentDataFromSynOps.SelectedBATRegionID))
              ? assessmentDataFromSynOps.SelectedBATRegionID
              : assessmentDataFromSynOps.BATGeographyID;

            if (!_.isEmpty(assessmentDataFromSynOps.SelectedBATMetricBenchmark)) {
              selectedBenchmark = assessmentDataFromSynOps.SelectedBATMetricBenchmark;
            }
          }

          this.setState(
            {
              assessmentDataFromSynOps,
              contractPeriod,
              selectedIndustryId,
              selectedRegionId,
              selectedBenchmark,
              isAPILoading: false,
            },
            () => {
              if (!_.isEmpty(assessmentDataFromSynOps)) {
                if (currentView === "ValueCaseView") {
                  this.fetchSubOfferingTVEBenchmarkDetailsFromBAT();
                } else if (currentView === "BenchmarkAnalysisView") {
                  this.fetchOfferingBenchmarkDetailsFromBAT();
                }
              }
            }
          );
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
        });
    }
  };

  // FOR "NON-TVE ENABLED" SUB-OFFERING TO FETCH DATA FROM OTHER ENDPOINT WHICH HAS `clientCount` PARAMETER SET
  prepareDataForBenchmarkAnalysis = () => {
    const { location } = this.props;
    const { offeringBenchmarkDataFromBAT, assessmentDataFromSynOps } = this.state;
    const clientPerformanceViewScreenData = _.get(
      location,
      "state.clientPerformanceViewScreenData",
      {}
    );

    // IF THE SCREEN IS LOADED FOR THE CLIENT CREATED BY ASSESSMENT, THEN LOOP OVER METRICS THAT WERE FILLED DURING THE ASSESSMENT
    const metricDetailsForLooping = assessmentDataFromSynOps.MetricDetails;

    let metricDetails = [];
    _.forEach(metricDetailsForLooping, (eachMetric) => {
      let metricID = eachMetric.BATMetricID;
      let foundBATMetric = _.find(
        offeringBenchmarkDataFromBAT.metricDetails,
        ["metricID", metricID],
        {}
      );

      if (!_.isEmpty(foundBATMetric)) {
        const leadingPractice = _.toLower(foundBATMetric.leadingPractice);
        let clientMetricValue = parseFloat(eachMetric.UserEnteredValue);
        let aggregatedAverageFloatVal = parseFloat(foundBATMetric.aggregatedAverage);
        let topQuartilePerformanceFloatVal = parseFloat(foundBATMetric.topQuartilePerformance);
        let bottomQuartilePerformance = parseFloat(foundBATMetric.bottomQuartilePerformance);
        const metricUnit = _.toLower(foundBATMetric.metricUnit);
        let metricUnitFormatted = getFormattedUnitName(metricUnit);

        let performanceIndicator = "";

        // If client metric value is better than upper quartile then GREEN​
        // If client metric value is between upper quartile and lower quartile then AMBER​
        // If lower quartile is better than client metric value then RED

        if (leadingPractice === "minimum") {
          if (
            clientMetricValue >= topQuartilePerformanceFloatVal &&
            clientMetricValue <= bottomQuartilePerformance
          ) {
            performanceIndicator = "worse";
          } else if (clientMetricValue < topQuartilePerformanceFloatVal) {
            performanceIndicator = "better";
          } else {
            performanceIndicator = "worst";
          }
        } else {
          if (
            clientMetricValue <= topQuartilePerformanceFloatVal &&
            clientMetricValue >= bottomQuartilePerformance
          ) {
            performanceIndicator = "worse";
          } else if (clientMetricValue > topQuartilePerformanceFloatVal) {
            performanceIndicator = "better";
          } else {
            performanceIndicator = "worst";
          }
        }

        // FOR THE "Talent & HR" OFFERING THE METRIC VALUES ARE CORRECT UP TO TWO DECIMAL PLACES, SO PLACE SOME EXTRA LOGIC TO SEPARATE METRIC UNIT AND VALUE
        if (clientPerformanceViewScreenData.offeringName === "Talent & HR") {
          clientMetricValue = _.isInteger(clientMetricValue)
            ? clientMetricValue
            : clientMetricValue.toFixed(2);
        }

        // SPLIT THE METRIC CATEGORY NAME (BUSINESS OUTCOME) AND THE VALUE DRIVER NAME WHICH ARE SEPARATED BY COLON (:) AND ADD THE ATTRIBUTE `valueDriver` FOR EACH METRIC
        const arrayAfterSplit = _.split(foundBATMetric.metricCategory, ":", 2);
        const metricCategory = _.trim(arrayAfterSplit[0]);
        const valueDriver = !_.isEmpty(arrayAfterSplit[1]) ? _.trim(arrayAfterSplit[1]) : "";

        const metric = {
          ...foundBATMetric,
          metricId: metricID,
          metricCategory,
          valueDriver,
          metricUnit,
          metricUnitFormatted,
          leadingPractice,
          performanceIndicator,
          clientMetricValue,
        };

        metricDetails.push(metric);
      }
    });

    // SORT THE METRICS BY `metricSequence` ATTRIBUTE
    metricDetails = _.sortBy(metricDetails, ["metricSequence"]);

    // FILTER OUT UNIQUE METRIC CATEGORIES
    const uniqueMetricCategories = _.uniq(_.map(metricDetails, "metricCategory"));

    // GROUP THE METRICS BY THE UNIQUE CATEGORIES
    const dataByMetricCategories = _.map(uniqueMetricCategories, (eachUniqueMetricCategory) => {
      return {
        metricCategory: eachUniqueMetricCategory,
        metrics: _.filter(metricDetails, { metricCategory: eachUniqueMetricCategory }),
      };
    });

    this.setState({
      dataByMetricCategories,
      currentView: "BenchmarkAnalysisView",
      isLoadedDataForBenchmarkAnalysisView: true,
    });
  };

  // FOR "NON-TVE ENABLED" SUB-OFFERING - API FOR GETTING THE METRIC DATA
  fetchSubOfferingTVEBenchmarkDetailsFromBAT = () => {
    const { offeringName, subOfferingName, startDate, endDate } = this.state;

    if (!_.isEmpty(offeringName)) {
      const requestData = {
        offeringName,
        towerName: subOfferingName,
        timeFrameStartDate: startDate,
        timeFrameEndDate: endDate,
      };

      this.setState({ isAPILoading: true });

      // FETCH THE DATA FROM THE API
      return axiosInstance
        .post(`${LocalApiBaseUrl}BAT/GetSubOfferingTVEBenchmarkDetails`, requestData)
        .then((response) => {
          let tveOfferingBenchmarkDataFromBAT = {};
          if (!_.isEmpty(response.data) && !_.isEmpty(response.data.metricDetails)) {
            tveOfferingBenchmarkDataFromBAT = response.data;
          }

          this.setState(
            {
              tveOfferingBenchmarkDataFromBAT,
              isAPILoading: false,
            },
            () => {
              if (!_.isEmpty(tveOfferingBenchmarkDataFromBAT)) {
                this.fetchTVESubOfferingMetricBenchmarkValuesFromBAT();
              }
            }
          );
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({ isAPILoading: false });
        });
    }
  };

  // FOR "TVE ENABLED" SUB-OFFERING TO FETCH DATA FROM OTHER ENDPOINT WHICH HAS DOES NOT HAS `clientCount` PARAMETER SET AND WILL RETURN "TVE ENABLED" METRICS FOM BAT
  fetchTVESubOfferingMetricBenchmarkValuesFromBAT = () => {
    const {
      offeringName,
      subOfferingName,
      startDate,
      endDate,
      tveOfferingBenchmarkDataFromBAT,
      assessmentDataFromSynOps,
      selectedIndustryId,
      selectedRegionId,
      selectedBenchmark,
    } = this.state;

    if (!_.isEmpty(tveOfferingBenchmarkDataFromBAT) && !_.isEmpty(assessmentDataFromSynOps)) {
      let metricDetailsForRequest = [];
      _.forEach(assessmentDataFromSynOps.MetricDetails, (eachAssessedMetric) => {
        const foundBATMetric = _.find(tveOfferingBenchmarkDataFromBAT.metricDetails, [
          "metricID",
          eachAssessedMetric.BATMetricID,
        ]);

        if (!_.isEmpty(foundBATMetric)) {
          let metricParametersForRequest = [];
          // IF METRIC VALUE WAS CALCULATED, THEN SEND THE PARAMETERS WITH VALUES
          metricParametersForRequest = _.map(
            foundBATMetric.requiredParameterDetails,
            (eachParameterForBATMetric) => {
              const BATParameterID = eachParameterForBATMetric.batParameterID;
              let parameterValue = "";
              if (!_.isEmpty(eachAssessedMetric.BATParameterDetails)) {
                const foundAssessedMetricParameter = _.find(
                  eachAssessedMetric.BATParameterDetails,
                  ["BATParameterID", BATParameterID]
                );
                parameterValue = _.get(foundAssessedMetricParameter, "BATParameterValue", "");
              }
              return {
                BATParameterID,
                parameterName: eachParameterForBATMetric.parameterName,
                parameterValue,
                parameterUOM: eachParameterForBATMetric.unitOfMeasurement,
              };
            }
          );
          const metric = {
            metricId: foundBATMetric.metricID,
            metricName: foundBATMetric.metricName,
            inputParameters: metricParametersForRequest,
          };
          metricDetailsForRequest.push(metric);
        }
      });

      const requestData = {
        offeringName,
        towerName: subOfferingName,
        timeFrameStartDate: startDate,
        timeFrameEndDate: endDate,
        industryId: selectedIndustryId,
        regionId: selectedRegionId,
        benchmarkName: selectedBenchmark,
        metricDetails: metricDetailsForRequest,
      };

      this.setState({ isAPILoading: true });
      // FETCH THE DATA FROM THE API
      return axiosInstance
        .post(`${LocalApiBaseUrl}BAT/GetSubOfferingMetricBenchmarkValues`, requestData)
        .then((response) => {
          let tveSubOfferingBenchmarkValuesFromBAT = {};
          if (!_.isEmpty(response.data) && !_.isEmpty(response.data.metricDetails)) {
            tveSubOfferingBenchmarkValuesFromBAT = response.data;
          }
          this.setState(
            {
              tveSubOfferingBenchmarkValuesFromBAT,
              isAPILoading: false,
            },
            () => {
              if (!_.isEmpty(tveSubOfferingBenchmarkValuesFromBAT)) {
                this.prepareDataForValueCase();
              }
            }
          );
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({ isAPILoading: false });
        });
    }
  };

  // for TVE flow
  prepareDataForValueCase = () => {
    const { location } = this.props;
    const {
      tveOfferingBenchmarkDataFromBAT,
      assessmentDataFromSynOps,
      tveSubOfferingBenchmarkValuesFromBAT,
    } = this.state;

    const clientPerformanceViewScreenData = _.get(
      location,
      "state.clientPerformanceViewScreenData",
      {}
    );

    let allVolumetricFromSynOps = [];

    // LOOP OVER METRICS FROM ASSESSMENT
    let metricDetails = [];
    _.forEach(assessmentDataFromSynOps.MetricDetails, (eachAssessedMetric) => {
      const BATMetricID = eachAssessedMetric.BATMetricID;
      const foundBATMetric = _.find(tveOfferingBenchmarkDataFromBAT.metricDetails, [
        "metricID",
        BATMetricID,
      ]);
      const foundBATMetricForBenchmarkValues = _.find(
        tveSubOfferingBenchmarkValuesFromBAT.metricDetails,
        ["metricID", BATMetricID]
      );

      if (!_.isEmpty(foundBATMetric) && !_.isEmpty(foundBATMetricForBenchmarkValues)) {
        const metricID = foundBATMetric.metricID;
        const leadingPractice = _.toLower(foundBATMetric.leadingPractice);
        let clientMetricValue = parseFloat(eachAssessedMetric.UserEnteredValue);
        const summary = eachAssessedMetric.Summary;

        const metricUnit = _.toLower(foundBATMetric.metricUnit);
        let metricUnitFormatted = getFormattedUnitName(metricUnit);

        let metricBenchmarkValue = !_.isEmpty(
          String(foundBATMetricForBenchmarkValues.metricBenchmarkValue)
        )
          ? parseFloat(foundBATMetricForBenchmarkValues.metricBenchmarkValue)
          : "";
        let userEnteredBenchmarkValue = !_.isEmpty(
          String(eachAssessedMetric.UserEnteredBenchmarkValue)
        )
          ? parseFloat(eachAssessedMetric.UserEnteredBenchmarkValue)
          : "";

        let benchmarkValueForCalculation = "";
        if (!_.isEmpty(String(userEnteredBenchmarkValue))) {
          benchmarkValueForCalculation = _.toNumber(userEnteredBenchmarkValue);
        } else if (!_.isEmpty(String(metricBenchmarkValue))) {
          benchmarkValueForCalculation = _.toNumber(metricBenchmarkValue);
        }

        const formulasForTVECalculation = _.map(eachAssessedMetric.FormulaArr, (eachItem) => ({
          tveFormula: eachItem.TVEFormula,
          resultText: eachItem.ResultText,
          formulaUOM: eachItem.TVEUOM,
          preferenceOrder: eachItem.PreferenceOrder,
        }));

        const volumetricDetails = _.map(eachAssessedMetric.Volumetrics, (eachVolumetric) => {
          const volumetricUnit = _.toLower(eachVolumetric.UOM);

          // STRUCTURE FOR EACH (SynOps) VOLUMETRIC
          const modifiedFoundSynOpsVolumetric = {
            volumetricId: eachVolumetric.VolumetricID,
            volumetricName: eachVolumetric.VolumetricName,
            volumetricSymbol: eachVolumetric.Symbol,
            volumetricUnit,
            volumetricUnitFormatted: getFormattedUnitName(volumetricUnit),
            enteredValue: String(eachVolumetric.Value),
            isValid: true,
          };
          allVolumetricFromSynOps.push(modifiedFoundSynOpsVolumetric);

          return modifiedFoundSynOpsVolumetric;
        });

        // DERIVE TOTAL VALUE ENABLED AND
        let {
          modifiedFormulasForTVECalculation,
          totalValueEnabled,
          totalValueEnabledFormatted,
          totalValueEnabledSuffixText,
          totalValueEnabledUOM,
        } = calculateMetricTVE({
          metricID,
          metricUnit,
          formulasForTVECalculation,
          volumetricDetails,
          clientValue: clientMetricValue,
          benchmarkValue: benchmarkValueForCalculation,
        });

        // DERIVE IMPROVEMENT OPPORTUNITY VALUE
        let { opportunityValue: improvementOpportunityValue, performanceIndicator } =
          calculateMetricImprovementOpportunity({
            metricLeadingPractice: leadingPractice,
            clientValue: clientMetricValue,
            benchmarkValue: benchmarkValueForCalculation,
          });

        // FOR THE "Talent & HR" OFFERING THE METRIC VALUES ARE CORRECT UP TO TWO DECIMAL PLACES, SO PLACE SOME EXTRA LOGIC TO SEPARATE METRIC UNIT AND VALUE
        if (clientPerformanceViewScreenData.offeringName === "Talent & HR") {
          clientMetricValue = _.isInteger(clientMetricValue)
            ? clientMetricValue
            : clientMetricValue.toFixed(2);
          if (!_.isEmpty(String(metricBenchmarkValue))) {
            metricBenchmarkValue = _.isInteger(metricBenchmarkValue)
              ? metricBenchmarkValue
              : metricBenchmarkValue.toFixed(2);
          }
          if (!_.isEmpty(String(userEnteredBenchmarkValue))) {
            userEnteredBenchmarkValue = _.isInteger(userEnteredBenchmarkValue)
              ? userEnteredBenchmarkValue
              : userEnteredBenchmarkValue.toFixed(2);
          }
          if (!_.isEmpty(String(improvementOpportunityValue))) {
            improvementOpportunityValue = _.isInteger(improvementOpportunityValue)
              ? improvementOpportunityValue
              : improvementOpportunityValue.toFixed(2);
          }
        }

        // SPLIT THE METRIC CATEGORY NAME (BUSINESS OUTCOME) AND THE VALUE DRIVER NAME WHICH ARE SEPARATED BY COLON (:) AND ADD THE ATTRIBUTE `valueDriver` FOR EACH METRIC
        const arrayAfterSplit = _.split(foundBATMetric.metricCategory, ":", 2);
        const metricCategory = _.trim(arrayAfterSplit[0]);
        const valueDriver = !_.isEmpty(arrayAfterSplit[1]) ? _.trim(arrayAfterSplit[1]) : "";

        // STRUCTURE FOR EACH METRIC
        const metric = {
          ...foundBATMetric,
          metricId: metricID,
          metricBenchmarkValue,
          userEnteredBenchmarkValue,
          improvementOpportunityValue,
          metricCategory,
          valueDriver,
          metricUnit,
          metricUnitFormatted,
          leadingPractice,
          performanceIndicator,
          clientMetricValue,
          volumetricDetails,
          formulasForTVECalculation: modifiedFormulasForTVECalculation,
          totalValueEnabled,
          totalValueEnabledFormatted,
          totalValueEnabledSuffixText,
          totalValueEnabledUOM,
          isValueCalculated: eachAssessedMetric.IsValueCalculated,
          summary,
        };
        metricDetails.push(metric);
      }
    });

    // SORT THE METRICS BY `metricSequence` ATTRIBUTE
    metricDetails = _.sortBy(metricDetails, ["metricSequence"]);

    // FILTER OUT UNIQUE METRIC CATEGORIES
    const uniqueMetricCategories = _.uniq(_.map(metricDetails, "metricCategory"));

    // GROUP THE METRICS BY THE UNIQUE CATEGORIES
    const tveDataByMetricCategories = _.map(uniqueMetricCategories, (eachUniqueMetricCategory) => {
      return {
        metricCategory: eachUniqueMetricCategory,
        metrics: _.filter(metricDetails, { metricCategory: eachUniqueMetricCategory }),
      };
    });

    if (!_.isEmpty(allVolumetricFromSynOps)) {
      allVolumetricFromSynOps = _.uniqBy(allVolumetricFromSynOps, "volumetricId");
    }

    this.setState({
      tveDataByMetricCategories,
      allVolumetricFromSynOps,
      currentView: "ValueCaseView",
      isLoadedDataForValueCaseView: true,
    });
  };

  // WOULD BE EXECUTED WHEN THE METRIC TILE WOULD BE CLICKED, WOULD OPEN UP THE "METRIC INSIGHT DETAILS" MODAL
  displayMetricBenchmarkChart = (metricData) => {
    this.setState({
      currentlySelectedMetricData: metricData,
      isVisibleMetricInsightDetailsModal: true,
    });
  };

  handleCloseMetricInsightDetailsModal = () => {
    this.setState({
      currentlySelectedMetricData: {},
      isVisibleMetricInsightDetailsModal: false,
    });
  };

  // EXECUTES THE API REQUEST TO SAVE THE ENTERED VALUES OF THE VOLUMETRICS TO DATABASE
  saveVolumetricValuesToDB = () => {
    const { allVolumetricFromSynOps, BATAssessSubOfferingID } = this.state;

    const volumetricsForRequest = _.map(allVolumetricFromSynOps, (eachVolumetric) => ({
      BATVolumetricID: eachVolumetric.volumetricId,
      Value: !_.isEmpty(String(eachVolumetric.enteredValue))
        ? parseFloat(eachVolumetric.enteredValue)
        : "",
    }));
    const requestData = {
      BATAssessSubOfferingID,
      Volumetrics: volumetricsForRequest,
    };

    if (!_.isEmpty(volumetricsForRequest)) {
      this.setState({ isAPILoading: true });
      axiosInstance
        .post(
          `${LocalApiBaseUrl}BATAssessment/AddEdit_BATAssessmentClientVolumetricValue`,
          requestData
        )
        .then((response) => {
          this.setState(
            {
              isAPILoading: false,
            },
            () => {
              this.fetchScreenData();
              this.displayTotalValueEnabledModal();
            }
          );
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
        });
    }
  };

  // FROM THE VALUES SAVED IN `allVolumetricFromSynOps` FOR ALL VOLUMETRICS, LOOP OVER AND SET THE VALUES IN THE CORRESPONDING METRICS VOLUMETRIC
  // AND THEN EXECUTE THE API TO SAVE THE VOLUMETRICS VALUES TO DB
  setVolumetricValuesInMetrics = () => {
    const { allVolumetricFromSynOps, tveDataByMetricCategories, currentlySelectedMetricData } =
      this.state;

    let modifiedCurrentlySelectedMetricData = {};
    let clonedDataByMetricCategories = _.cloneDeep(tveDataByMetricCategories);

    // SAVE ENTERED VALUE FOR THE SynOps PARAMETERS IN THE CORRESPONDING PARAMETERS FOR EACH METRIC
    _.forEach(clonedDataByMetricCategories, (eachDataByMetricCategory) => {
      const metrics = eachDataByMetricCategory.metrics;
      // LOOP OVER EACH METRIC
      _.forEach(metrics, (eachMetric) => {
        const metricID = eachMetric.metricID;
        const volumetricDetails = eachMetric.volumetricDetails;

        // LOOP OVER EACH METRIC VOLUMETRIC
        _.forEach(volumetricDetails, (eachVolumetric) => {
          const volumetricId = eachVolumetric.volumetricId;
          // FOR THE CURRENT METRIC VOLUMETRIC, FIND THE CORRESPONDING SynOps VOLUMETRIC SO THAT THE ENTERED VALUE FOR THE METRIC VOLUMETRIC CAN BE SET FROM THE VALUE ENTERED FOR THE SynOps VOLUMETRIC
          const foundVolumetric = _.find(allVolumetricFromSynOps, ["volumetricId", volumetricId]);
          if (!_.isEmpty(foundVolumetric)) {
            eachVolumetric.enteredValue = foundVolumetric.enteredValue;
          }
        });

        // IF THE CURRENTLY SELECTED METRIC IS SAME AS THE METRIC IN LOOP, THEN RE-SET THE DATA SO THAT THE UPDATED VALUES FOR VOLUMETRICS ARE SAVED IN `currentlySelectedMetricData`
        if (currentlySelectedMetricData.metricID === metricID) {
          modifiedCurrentlySelectedMetricData = eachMetric;
        }
      });
    });

    this.setState(
      {
        currentlySelectedMetricData: modifiedCurrentlySelectedMetricData,
        tveDataByMetricCategories: clonedDataByMetricCategories,
      },
      () => {
        this.saveVolumetricValuesToDB();
      }
    );
  };

  showMetricParametersModal = () => {
    this.setState({
      isVisibleMetricParametersModal: true,
    });
  };

  hideMetricParametersModal = () => {
    this.setState({
      isVisibleMetricParametersModal: false,
    });
  };

  // EVENT HANDLER FOR THE "CALCULATE" BUTTON IN THE METRIC PARAMETERS MODAL
  handleClickSubmitBtnMetricParametersModal = (metricData) => {
    const { allVolumetricFromSynOps } = this.state;
    this.hideMetricParametersModal();

    const volumetricDetails = metricData.volumetricDetails;
    const clonedAllVolumetricFromSynOps = _.cloneDeep(allVolumetricFromSynOps);

    // SET THE VALUES ENTERED FOR THE VOLUMETRICS IN THE MODAL INTO THE CORRESPONDING `allVolumetricFromSynOps` AND THEN EXECUTE THE API TO SAVE THE PARAMETER VALUES TO DB AND FETCH THE CALCULATED VALUES FOR THE METRICS
    _.forEach(volumetricDetails, (eachVolumetric) => {
      const volumetricId = eachVolumetric.volumetricId;
      const enteredValue = eachVolumetric.enteredValue;
      const foundVolumetric = _.find(clonedAllVolumetricFromSynOps, ["volumetricId", volumetricId]);
      foundVolumetric.enteredValue = enteredValue;
    });

    this.setState(
      {
        allVolumetricFromSynOps: clonedAllVolumetricFromSynOps,
      },
      () => {
        this.setVolumetricValuesInMetrics();
      }
    );
  };

  handleClickMetricTVEIcon = (tveMetricData) => {
    const volumetricDetails = tveMetricData.volumetricDetails;

    let areAllVolumetricsFilled = true;
    // LOOP OVER EACH METRIC VOLUMETRIC
    _.forEach(volumetricDetails, (eachVolumetric) => {
      if (_.isEmpty(String(eachVolumetric.enteredValue))) {
        areAllVolumetricsFilled = false;
      }
    });

    // IF THE CURRENTLY SELECTED METRIC EXISTS IN THE ARRAY OF EXCEPTED TVE METRICS
    if (_.indexOf(exceptedTVEMetrics, tveMetricData.metricID) !== -1) {
      areAllVolumetricsFilled = true;
    }

    this.setState(
      {
        currentlySelectedMetricData: tveMetricData,
      },
      () => {
        // IF ALL THE VOLUMETRIC VALUES ARE NOT EMPTY THEN DISPLAY THE "TOTAL VALUE ENABLED" MODAL
        if (areAllVolumetricsFilled) {
          this.displayTotalValueEnabledModal();
        } else {
          this.showMetricParametersModal();
        }
      }
    );
  };

  // EXECUTES THE API REQUEST TO SAVE THE ENTERED VALUES OF THE METRICS TO DATABASE
  saveMetricValuesToDB = (paramObj) => {
    const {
      subOfferingName,
      tveDataByMetricCategories,
      BATAssessSubOfferingID,
      startDate,
      endDate,
      isSubOfferingTVEEnabled,
      assessmentDataFromSynOps,
    } = this.state;

    const { metricID, metricValue, userEnteredBenchmarkValue, contractPeriod } = paramObj;

    const overallDataCollectionPercentage = assessmentDataFromSynOps.DataCollection;
    let metricDataForRequest = [];

    // ONLY SAVE THOSE METRICS WHOSE EITHER `calculatedValue` OR `manuallyEnteredValue` IS NOT EMPTY AND IS VALID
    _.forEach(tveDataByMetricCategories, (eachItem) => {
      const metrics = eachItem.metrics;
      const mappedMetrics = _.map(metrics, (eachMappedMetric) => {
        let metricUserEnteredBenchmarkValue = eachMappedMetric.userEnteredBenchmarkValue;
        let userEnteredValue = parseFloat(eachMappedMetric.clientMetricValue);
        let isValueCalculated = eachMappedMetric.isValueCalculated;

        const isCurrentlySelectedMetric = eachMappedMetric.metricId === metricID;
        if (isCurrentlySelectedMetric) {
          metricUserEnteredBenchmarkValue = userEnteredBenchmarkValue;
          userEnteredValue = metricValue;
          isValueCalculated = false;
        }

        return {
          BATMetricGroup: eachMappedMetric.metricCategory,
          BATMetricID: eachMappedMetric.metricID,
          BATMetricName: eachMappedMetric.metricName,
          BATMetricValueUnit: eachMappedMetric.metricUnit,
          UserEnteredValue: userEnteredValue,
          IsValueCalculated: isValueCalculated,
          UserEnteredBenchmarkValue: metricUserEnteredBenchmarkValue,
          BatParameterDetails: _.map(eachMappedMetric.parameterDetails, (eachMappedParameter) => ({
            BATParameterID: eachMappedParameter.BATParameterID,
            BATParameterName: eachMappedParameter.BATParameterName,
            UOM: eachMappedParameter.parameterUnit,
          })),
        };
      });
      metricDataForRequest = _.concat(mappedMetrics, metricDataForRequest);
    });

    if (!_.isEmpty(metricDataForRequest)) {
      const requestData = {
        BATAssessmentSubOfferingMappingID: BATAssessSubOfferingID,
        BATSubOfferingName: subOfferingName,
        StartDate: startDate,
        EndDate: endDate,
        DataCollection: overallDataCollectionPercentage,
        MetricDetails: metricDataForRequest,
        ContractPeriod: isSubOfferingTVEEnabled ? _.toNumber(contractPeriod) : "",
      };

      this.setState({ isAPILoading: true });
      // FETCH THE DATA FROM THE API
      return axiosInstance
        .post(`${LocalApiBaseUrl}BATAssessment/AddEditMetricsClientAssessmentDetails`, requestData)
        .then((response) => {
          this.setState(
            {
              isAPILoading: false,
            },
            () => {
              this.fetchScreenData();
            }
          );
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
        });
    }
  };

  displayTotalValueEnabledModal = () => {
    this.setState({
      isVisibleMetricTVEModal: true,
    });
  };

  handleCloseTotalValueEnabledModal = (returnedDataObj) => {
    const { haveValuesChanged, metricID, metricValue, userEnteredBenchmarkValue, contractPeriod } =
      returnedDataObj;

    this.setState(
      {
        isVisibleMetricTVEModal: false,
      },
      () => {
        if (haveValuesChanged) {
          this.saveMetricValuesToDB({
            metricID,
            metricValue,
            userEnteredBenchmarkValue,
            contractPeriod,
          });
        }
      }
    );
  };

  handleClickMetricInfoIcon = (metricData) => {
    const currentlySelectedMetricData = metricData;
    this.setState({
      currentlySelectedMetricData,
      isVisibleMetricInfoModal: true,
    });
  };

  handleCloseMetricInfoModal = () => {
    this.setState({
      currentlySelectedMetricData: {},
      isVisibleMetricInfoModal: false,
    });
  };

  redirectToProvideMetricDetailsForAssessmentScreen = () => {
    const { history } = this.props;
    const {
      offeringName,
      offeringType,
      subOfferingId,
      subOfferingName,
      startDate,
      endDate,
      clientCount,
      clientName,
      clientIndustry,
      clientMarketUnit,
      BATAssessSubOfferingID,
      BATAssessClientID,
      isSubOfferingTVEEnabled,
    } = this.state;
    const assessmentMetricDetailsScreenData = {
      offeringName,
      offeringType,
      subOfferingId,
      subOfferingName,
      startDate,
      endDate,
      clientCount,
      clientName,
      clientIndustry,
      clientMarketUnit,
      BATAssessSubOfferingID,
      BATAssessClientID,
      isSubOfferingTVEEnabled,
      createdBy: history.location.state.clientPerformanceViewScreenData.createdBy,
    };
    this.setState({ isAPILoading: true });
    axiosInstance
      .get(
        `${LocalApiBaseUrl}BATAssessment/AddEditLockOnBATAssessment?BATAssessSubOfferingID=${BATAssessSubOfferingID}&Status=get`
      )
      .then((response) => {
        this.setState({ isAPILoading: false });
        if (
          response.data &&
          response.data.assessmentLockDetails &&
          response.data.assessmentLockDetails.length
        ) {
          if (
            response.data.assessmentLockDetails[0].IsActive === true &&
            response.data.assessmentLockDetails[0].UserName !==
              sessionStorage.getItem("EnterpriseID")
          ) {
            alert(response.data.StatusMessage);
          } else {
            const location = {
              pathname: `/${ROUTE_CONSTANTS.BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT}`,
              state: { assessmentMetricDetailsScreenData },
            };

            history.push(location);
          }
        } else {
          const location = {
            pathname: `/${ROUTE_CONSTANTS.BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT}`,
            state: { assessmentMetricDetailsScreenData },
          };

          history.push(location);
        }
      })
      .catch((error) => {
        this.setState({ isAPILoading: false });
        trycatchAlertPopup(error);
      });
  };

  // EVENT HANDLER FOR THE STATE CHANGES IN THE COMMON HEADER COMPONENT. RELOADS THE SCREEN BASED ON THE DATA PASSED FROM HEADER
  handleCallbackHeaderStateChange = (headerStateObj) => {
    if (Object.keys(headerStateObj).length < 3) {
      this.setState(
        {
          startDate: headerStateObj.formattedStartDate,
          endDate: headerStateObj.formattedEndDate,
        },
        () => {
          this.fetchScreenData();
        }
      );
    } else {
      const {
        offeringName,
        offeringType,
        subOfferingId,
        subOfferingName,
        startDate,
        endDate,
        clientCount,
        ClientLogo,
        clientName,
        clientIndustry,
        clientMarketUnit,
        aliasName,
        BATAssessClientID,
        BATAssessSubOfferingID,
        isSubOfferingTVEEnabled,
      } = headerStateObj;

      this.setState(
        {
          offeringName,
          offeringType,
          subOfferingId,
          subOfferingName,
          startDate,
          endDate,
          clientCount,
          ClientLogo,
          clientName,
          clientIndustry,
          clientMarketUnit,
          clientAliasName: aliasName,
          BATAssessClientID,
          BATAssessSubOfferingID,
          isSubOfferingTVEEnabled,
          currentView: isSubOfferingTVEEnabled ? "ValueCaseView" : "BenchmarkAnalysisView",
          selectedTVEMetricsFilter: "",
        },
        () => {
          // THIS WILL CALL THE API WITH THE UPDATED STATE DATA AND THE SCREEN WOULD RELOAD WITH NEW DATA
          this.fetchScreenData();
        }
      );
    }
  };

  saveBenchmarkSelectionToDB = () => {
    const { BATAssessSubOfferingID, selectedIndustryId, selectedRegionId, selectedBenchmark } =
      this.state;

    const requestData = {
      BATAssessSubOfferingID,
      SelectedBATIndustryID: selectedIndustryId,
      SelectedBATRegionID: selectedRegionId,
      SelectedBATMetricBenchmark: selectedBenchmark,
    };

    this.setState({ isAPILoading: true });

    // FETCH THE DATA FROM THE API
    return axiosInstance
      .post(
        `${LocalApiBaseUrl}BATAssessment/AddEdit_BATAssessmentClientValueViewFilter`,
        requestData
      )
      .then((response) => {
        this.setState({ isAPILoading: false }, () => {
          this.fetchTVESubOfferingMetricBenchmarkValuesFromBAT();
        });
      })
      .catch((error) => {
        this.setState({ isAPILoading: false });
        trycatchAlertPopup(error);
      });
  };

  // FOR TVE FUNCTIONALITY - EVENT HANDLER WHEN THE BENCHMARK SELECTION WOULD CHANGE IN THE THE HEADER
  handleHeaderBenchmarkSelectionChange = (dataObj) => {
    const { selectedIndustryId, selectedRegionId, selectedBenchmark } = dataObj;

    this.setState(
      {
        selectedIndustryId,
        selectedRegionId,
        selectedBenchmark,
      },
      () => {
        this.saveBenchmarkSelectionToDB();
      }
    );
  };

  // WOULD EXPAND/COLLAPSE ALL THE ACCORDIONS
  expandCollapseAllAccordions = (action) => {
    // IF THE ACTION IS "collapse" THEN COLLAPSE ALL THE ACCORDIONS AND IF THE ACTION IS "expand" THEN EXPAND ALL THE ACCORDIONS
    this.setState({
      expandedAccordionsKeysArray: action === "collapse" ? [] : ["all"],
    });
  };

  // WOULD BE EXECUTED WHEN THE ACCORDION HEADER WOULD BE CLICKED. THIS WOULD TOGGLE THE OPEN/CLOSE OF THE ACCORDION BODY SECTION
  toggleAccordionSection = (currentEventKey, arrayOfAllAccordionKeys) => {
    const { expandedAccordionsKeysArray } = this.state;
    let modifiedExpandedAccordionsKeysArray = _.cloneDeep(expandedAccordionsKeysArray);

    // WHEN CLICKED ON CURRENT ACCORDION HEADER AND ALL WERE EXPANDED, THEN JUST COLLAPSE THE CURRENT ONE AND KEEP THE REST EXPANDED
    if (_.indexOf(modifiedExpandedAccordionsKeysArray, "all") !== -1) {
      modifiedExpandedAccordionsKeysArray = arrayOfAllAccordionKeys;
      modifiedExpandedAccordionsKeysArray = _.without(
        modifiedExpandedAccordionsKeysArray,
        "all",
        currentEventKey
      );
    } else {
      // IF THE CURRENT ACCORDION EVENT KEY IS NOT PRESENT IN THE ARRAY THEN ADD IT SO THAT THE CURRENT ACCORDION WOULD BE EXPANDED, ELSE REMOVE IT FROM THE ARRAY SO THAT THE CURRENT ACCORDION WOULD BE COLLAPSED
      if (_.indexOf(modifiedExpandedAccordionsKeysArray, currentEventKey) === -1) {
        modifiedExpandedAccordionsKeysArray.push(currentEventKey);
      } else {
        // REMOVE THE ONES WHICH HAS A MATCH OF STRING (THIS LOGIC WOULD ALSO WORK TO COLLAPSE ALL THE CHILDREN UNDER THE CURRENT PARENT)
        modifiedExpandedAccordionsKeysArray = _.filter(
          modifiedExpandedAccordionsKeysArray,
          (eachItem) => {
            const itemFound = eachItem.indexOf(currentEventKey) !== -1;
            return !itemFound;
          }
        );
      }
    }

    this.setState({
      expandedAccordionsKeysArray: modifiedExpandedAccordionsKeysArray,
    });
  };

  handleChangeTab = (selectedTab) => {
    let { isLoadedDataForValueCaseView, isLoadedDataForBenchmarkAnalysisView } = this.state;

    const prevValIsLoadedDataForBenchmarkAnalysisView = isLoadedDataForBenchmarkAnalysisView;
    const prevValIsLoadedDataForValueCaseView = isLoadedDataForValueCaseView;

    if (selectedTab === "BenchmarkAnalysisView" && !isLoadedDataForBenchmarkAnalysisView) {
      isLoadedDataForBenchmarkAnalysisView = true;
    } else if (selectedTab === "ValueCaseView" && !isLoadedDataForValueCaseView) {
      isLoadedDataForValueCaseView = true;
      this.fetchSubOfferingTVEBenchmarkDetailsFromBAT();
    }

    this.setState(
      {
        isLoadedDataForValueCaseView,
        isLoadedDataForBenchmarkAnalysisView,
        currentView: selectedTab,
      },
      () => {
        if (
          selectedTab === "BenchmarkAnalysisView" &&
          !prevValIsLoadedDataForBenchmarkAnalysisView
        ) {
          this.fetchOfferingBenchmarkDetailsFromBAT();
        } else if (selectedTab === "ValueCaseView" && !prevValIsLoadedDataForValueCaseView) {
          this.fetchSubOfferingTVEBenchmarkDetailsFromBAT();
        }
      }
    );
  };

  handleClickTVEMetricsFilter = (currentlySelectedFilter) => {
    const { selectedTVEMetricsFilter } = this.state;

    let filterToSetForSelection = currentlySelectedFilter;

    // IF THE SAME FILTER THAT IS CURRENTLY SELECTED IS CLICKED AGAIN, THEN THE CURRENT FILTER OPTION WOULD BE UNSELECTED
    if (currentlySelectedFilter === selectedTVEMetricsFilter) {
      filterToSetForSelection = "";
    }

    this.setState({
      selectedTVEMetricsFilter: filterToSetForSelection,
      expandedAccordionsKeysArray: ["all"],
    });
  };

  render() {
    const { location } = this.props;
    const {
      isAPILoading,
      dataByMetricCategories,
      isVisibleMetricInfoModal,
      currentlySelectedMetricData,
      isVisibleMetricInsightDetailsModal,
      isVisibleMetricTVEModal,
      offeringName,
      offeringType,
      subOfferingId,
      subOfferingName,
      startDate,
      endDate,
      clientCount,
      clientName,
      clientIndustry,
      clientMarketUnit,
      BATAssessSubOfferingID,
      BATAssessClientID,
      ClientLogo,
      expandedAccordionsKeysArray,
      selectedIndustryId,
      selectedRegionId,
      selectedBenchmark,
      isSubOfferingTVEEnabled,
      tveDataByMetricCategories,
      contractPeriod,
      isVisibleMetricParametersModal,
      areFormFieldsMandatoryForMetricParametersModal,
      subHeaderTextForMetricParametersModal,
      renderMetricParametersModalFor,
      currentView,
      assessmentDataFromSynOps,
    } = this.state;
    const isMarketingOps = offeringName === "Marketing Ops Value Solutioning";
    let { selectedTVEMetricsFilter } = this.state;

    const clientPerformanceViewScreenData = _.get(
      location,
      "state.clientPerformanceViewScreenData",
      {}
    );

    // IF THE `offerings` ARE EMPTY (WHEN THIS SCREEN IS LOADED DIRECTLY BY THE URL) NOT PASSED IN `location.state`, THEN REDIRECT TO THE LANDING SCREEN
    if (_.isEmpty(clientPerformanceViewScreenData)) {
      return <Redirect to={`/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`} />;
    }

    const options = {
      loop: false,
      margin: 22,
      responsiveClass: "",
      autoWidth: true,
      dots: false,
      nav: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 4,
        },
      },
    };
    const owlCarouselOptions = {
      loop: false,
      margin: 16,
      responsiveClass: "",
      dots: false,
      nav: true,
      responsive: {
        0: {
          items: 1,
        },
        760: {
          items: 2,
        },
        1080: {
          items: 3,
        },
      },
    };

    // FOR THE METRICS GROUPED BY "METRIC CATEGORIES" (BUSINESS OUTCOMES), CHECK IF THE METRICS HAVE ASSOCIATED "VALUE DRIVERS" AND IF IT IS SO THEN CHANGE THE DATA HIERARCHY SO THAT THE METRICS ARE GROUPED UNDER "VALUE DRIVERS" AND THEY IN TURN ARE GROUPED UNDER "METRIC CATEGORIES" (BUSINESS OUTCOMES)
    const modifiedDataByMetricCategories = _.map(dataByMetricCategories, (eachItem) => {
      let metrics = eachItem.metrics;
      const uniqueValueDrivers = _.uniq(_.map(metrics, "valueDriver"));
      let dataByValueDrivers = [];
      if (!_.isEmpty(_.first(uniqueValueDrivers))) {
        dataByValueDrivers = _.map(uniqueValueDrivers, (eachValueDriver) => {
          const metricsOfValueDriver = _.filter(metrics, { valueDriver: eachValueDriver });

          return {
            valueDriver: eachValueDriver,
            metrics: metricsOfValueDriver,
          };
        });
        metrics = [];
      }

      return {
        metricCategory: eachItem.metricCategory,
        metrics,
        dataByValueDrivers,
      };
    });

    // RETURNS THE LAYOUT FOR METRICS
    const metricsLayout = (metrics) => {
      return (
        <div className="custom-carousel-padding">
          <OwlCarousel options={options} className="owl-carousel">
            {_.map(metrics, (eachMetric) => {
              const isValidMetricValue = !_.isNaN(eachMetric.clientMetricValue);
              return (
                <div
                  className={classNames(
                    "item",
                    {
                      "green-color":
                        isValidMetricValue && eachMetric.performanceIndicator === "better",
                    },
                    {
                      "amber-color":
                        isValidMetricValue && eachMetric.performanceIndicator === "worse",
                    },
                    {
                      "red-color":
                        isValidMetricValue && eachMetric.performanceIndicator === "worst",
                    },
                    { "gray-color": !isValidMetricValue }
                  )}
                  data-place="bottom"
                  currentitem="false"
                  key={eachMetric.metricName}
                >
                  <div className="item-left">
                    <p className="tile-metric-value">
                      {isValidMetricValue ? eachMetric.clientMetricValue : ""}
                      <span>&nbsp;{isValidMetricValue && eachMetric.metricUnitFormatted}</span>
                    </p>
                    <p className="tile-metric-desc">{eachMetric.metricName}</p>
                  </div>

                  <div className="item-right">
                    <div className="metric-details-icon" data-tip data-for="benchmarkTooltip">
                      {eachMetric.isExternalBenchmarksAvailable && (
                        <i className="fal fa-bullseye-pointer"></i>
                      )}
                      <ReactTooltip place="left" effect="solid" id="benchmarkTooltip">
                        <span>External Benchmark Available</span>
                      </ReactTooltip>
                    </div>

                    <div className="tilequartile-performance">
                      <p>Median</p>
                      <span>{eachMetric.aggregatedAverage}</span>
                    </div>

                    <div className="tilequartile-performance">
                      <p>Top quartile performance</p>
                      <span>{eachMetric.topQuartilePerformance}</span>
                    </div>
                  </div>

                  <div className="dropdown dropleft">
                    <span
                      type="button"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      data-tip
                      data-for="moreActionTooltip"
                    >
                      <ReactTooltip place="bottom" effect="solid" id="moreActionTooltip">
                        <span>More Actions</span>
                      </ReactTooltip>
                    </span>

                    <div className="dropdown-menu">
                      <a
                        href="javascript:void(0)"
                        className="dropdown-item"
                        onClick={() => this.handleClickMetricInfoIcon(eachMetric)}
                      >
                        <span className="info-icon"></span>Info
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="dropdown-item"
                        onClick={() => this.displayMetricBenchmarkChart(eachMetric)}
                      >
                        <span className="insights-new-icon"></span>Insights
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      );
    };

    let increaseInClientRevenueValue = "";
    let reductionInClientCostValue = "";
    _.forEach(tveDataByMetricCategories, (eachDataByMetricCategory) => {
      const categoryMetrics = eachDataByMetricCategory.metrics;
      _.forEach(categoryMetrics, (eachMetric) => {
        const metricPerformanceIndicator = eachMetric.performanceIndicator;
        const metricTotalValueEnabled = eachMetric.totalValueEnabled;
        const metricTVEUnit = eachMetric.totalValueEnabledUOM;

        // CALCULATE "Increase in Client's Revenue" FROM THE METRICS UNDER "Increase Revenue" BUSINESS OUTCOME
        // DON'T CONSIDER THE TVE FOR THE METRICS THAT ARE "GREEN (BETTER)"
        if (
          (metricPerformanceIndicator === "worse" || metricPerformanceIndicator === "worst") &&
          !_.isEmpty(metricTotalValueEnabled) &&
          metricTVEUnit === "$"
        ) {
          if (
            _.toLower(eachDataByMetricCategory.metricCategory) === "increase revenue" ||
            _.toLower(eachMetric.summary) === "revenue"
          ) {
            increaseInClientRevenueValue =
              _.toNumber(increaseInClientRevenueValue) + _.toNumber(metricTotalValueEnabled);
          }

          // CALCULATE "Reduction in Client's Cost" FROM THE METRICS UNDER "Optimize Operations" OR "Optimize Operation" BUSINESS OUTCOME
          if (
            _.toLower(eachDataByMetricCategory.metricCategory) === "optimize operations" ||
            _.toLower(eachDataByMetricCategory.metricCategory) === "optimize operation" ||
            _.toLower(eachMetric.summary) === "cost savings"
          ) {
            reductionInClientCostValue =
              _.toNumber(reductionInClientCostValue) + _.toNumber(metricTotalValueEnabled);
          }
        }
      });
    });

    // IF THE CALCULATED VALUE AGAINST THE CURRENTLY ENGAGED FILTER IS EMPTY, THEN EMPTY THE CURRENTLY SELECTED FILTER
    if (
      (selectedTVEMetricsFilter === "increaseInClientRevenue" &&
        _.isEmpty(String(increaseInClientRevenueValue))) ||
      (selectedTVEMetricsFilter === "reductionInClientCost" &&
        _.isEmpty(String(reductionInClientCostValue)))
    ) {
      selectedTVEMetricsFilter = "";
    }

    let filteredTVEDataByMetricCategories = tveDataByMetricCategories;
    // ONLY DISPLAY THE METRICS UNDER "Increase Revenue" BUSINESS OUTCOME OR summary=Revenue and TVE has some positive value
    if (selectedTVEMetricsFilter === "increaseInClientRevenue") {
      // filteredTVEDataByMetricCategories = _.filter(
      //   filteredTVEDataByMetricCategories,
      //   (eachItem) => _.toLower(eachItem.metricCategory) === "increase revenue"
      // );

      filteredTVEDataByMetricCategories = filteredTVEDataByMetricCategories.map((o) => ({
        ...o,

        metrics: o.metrics.filter(
          (o2) =>
            String(o2.metricCategory).toLowerCase() === "increase revenue" ||
            (String(o2.summary).toLowerCase() === "revenue" && Number(o2.totalValueEnabled) > 0)
        ),
      }));
    } else if (selectedTVEMetricsFilter === "reductionInClientCost") {
      // ONLY DISPLAY THE METRICS UNDER "Optimize Operations" OR "Optimize Operation" BUSINESS OUTCOME
      // filteredTVEDataByMetricCategories = _.filter(
      //   filteredTVEDataByMetricCategories,
      //   (eachItem) =>
      //     _.toLower(eachItem.metricCategory) === "optimize operations" ||
      //     _.toLower(eachItem.metricCategory) === "optimize operation"
      // );

      filteredTVEDataByMetricCategories = filteredTVEDataByMetricCategories.map((o) => ({
        ...o,

        metrics: o.metrics.filter(
          (o2) =>
            String(o2.metricCategory).toLowerCase() === "optimize operations" ||
            String(o2.metricCategory).toLowerCase() === "optimize operation" ||
            (String(o2.summary).toLowerCase() === "cost savings" &&
              Number(o2.totalValueEnabled) > 0)
        ),
      }));
    }

    // FOR THE METRICS GROUPED BY "METRIC CATEGORIES" (BUSINESS OUTCOMES), CHECK IF THE METRICS HAVE ASSOCIATED "VALUE DRIVERS" AND IF IT IS SO THEN CHANGE THE DATA HIERARCHY SO THAT THE METRICS ARE GROUPED UNDER "VALUE DRIVERS" AND THEY IN TURN ARE GROUPED UNDER "METRIC CATEGORIES" (BUSINESS OUTCOMES)
    const modifiedTVEDataByMetricCategories = _.map(
      filteredTVEDataByMetricCategories,
      (eachItem) => {
        let metrics = eachItem.metrics;
        const uniqueValueDrivers = _.uniq(_.map(metrics, "valueDriver"));
        let dataByValueDrivers = [];
        if (!_.isEmpty(_.first(uniqueValueDrivers))) {
          dataByValueDrivers = _.map(uniqueValueDrivers, (eachValueDriver) => {
            const metricsOfValueDriver = _.filter(metrics, { valueDriver: eachValueDriver });

            return {
              valueDriver: eachValueDriver,
              metrics: metricsOfValueDriver,
            };
          });
          metrics = [];
        }

        return {
          metricCategory: eachItem.metricCategory,
          metrics,
          dataByValueDrivers,
        };
      }
    );

    const tveMetricsLayout = (metrics) => {
      return (
        <div className="value-case-view">
          <OwlCarousel options={owlCarouselOptions} className="owl-carousel">
            {_.map(metrics, (eachMetric) => {
              let metricBenchmarkValueForDisplay = "";
              const metricBenchmarkValue = eachMetric.metricBenchmarkValue;
              const userEnteredBenchmarkValue = eachMetric.userEnteredBenchmarkValue;
              const performanceIndicator = eachMetric.performanceIndicator;

              if (!_.isEmpty(String(userEnteredBenchmarkValue))) {
                metricBenchmarkValueForDisplay = userEnteredBenchmarkValue;
              } else if (!_.isEmpty(String(metricBenchmarkValue))) {
                metricBenchmarkValueForDisplay = metricBenchmarkValue;
              }

              if (
                eachMetric.metricCategory === "Improve Customer Experience" &&
                eachMetric.valueDriver === "Increase Customer Experience & Sentiment" &&
                (eachMetric.metricName === "Net Promoter Score - Chat" ||
                  eachMetric.metricName === "Net Promoter Score - Email")
              ) {
                eachMetric.metricUnitFormatted = "";
              }

              if (
                eachMetric.metricCategory === "Optimize Operations" &&
                eachMetric.valueDriver === "Maximize efficiency/quality" &&
                eachMetric.metricName === "Order Processing Time (Hours)"
              ) {
                eachMetric.metricUnitFormatted = "Hours";
              }

              return (
                <div className="tve-card-container" key={eachMetric.metricName}>
                  <div
                    className={classNames(
                      "tve-card-wrap",
                      { "tve-card-wrap-green": performanceIndicator === "better" },
                      { "tve-card-wrap-amber": performanceIndicator === "worse" },
                      { "tve-card-wrap-red": performanceIndicator === "worst" }
                    )}
                  >
                    <div className="tve-card">
                      <div className="tve-card-title">{eachMetric.metricName}</div>
                      <div className="tve-card-partition">
                        <div className="tve-card-partitionleft">
                          <div className="value-section bold-label">
                            <div className="text-label">Opportunity</div>
                            <div
                              className={classNames(
                                "text-value",
                                { "grey-arrow": performanceIndicator === "" },
                                { "green-arrow": performanceIndicator === "better" },
                                { "amber-arrow": performanceIndicator === "worse" },
                                { "red-arrow": performanceIndicator === "worst" }
                              )}
                            >
                              {!_.isEmpty(String(eachMetric.improvementOpportunityValue)) && (
                                <>
                                  {eachMetric.improvementOpportunityValue}
                                  <span>%</span>
                                </>
                              )}
                              {_.isEmpty(String(eachMetric.improvementOpportunityValue)) &&
                                performanceIndicator === "better" &&
                                "- %"}
                              {_.isEmpty(String(eachMetric.improvementOpportunityValue)) &&
                                performanceIndicator === "" &&
                                "- -"}
                            </div>
                          </div>

                          <div className="value-section">
                            <div className="text-label">
                              {_.toLower(selectedBenchmark) === "lower quartile"
                                ? "Bottom Quartile"
                                : selectedBenchmark}{" "}
                              Benchmark
                            </div>
                            <div className="text-value-B">
                              {!_.isEmpty(String(metricBenchmarkValueForDisplay)) &&
                                eachMetric.metricUnitFormatted === "%" &&
                                `${metricBenchmarkValueForDisplay}%`}

                              {!_.isEmpty(String(metricBenchmarkValueForDisplay)) &&
                                eachMetric.metricUnitFormatted !== "%" &&
                                `${metricBenchmarkValueForDisplay} ${eachMetric.metricUnitFormatted}`}

                              {_.isEmpty(String(metricBenchmarkValueForDisplay)) &&
                                "No Benchmark Available"}
                              {!_.isEmpty(String(metricBenchmarkValueForDisplay)) &&
                              selectedRegionId == 5 ? (
                                <>
                                  <span className="g-custom-icon" data-tip data-for="global">
                                    G
                                  </span>
                                  <ReactTooltip
                                    place="top"
                                    effect="solid"
                                    id="global"
                                    className="global-tip"
                                  >
                                    Global
                                  </ReactTooltip>
                                </>
                              ) : null}
                            </div>
                          </div>

                          <div className="value-section">
                            <div className="text-label">Client Value</div>
                            <div className="text-value-B">
                              {!_.isEmpty(String(eachMetric.clientMetricValue)) &&
                                eachMetric.metricUnitFormatted === "%" &&
                                `${eachMetric.clientMetricValue}%`}

                              {!_.isEmpty(String(eachMetric.clientMetricValue)) &&
                                eachMetric.metricUnitFormatted !== "%" &&
                                `${eachMetric.clientMetricValue} ${eachMetric.metricUnitFormatted}`}

                              {_.isEmpty(String(eachMetric.clientMetricValue)) &&
                                `-  ${eachMetric.metricUnitFormatted}`}
                            </div>
                          </div>
                        </div>

                        <div className="tve-card-partitionright">
                          <div className="value-section bold-label">
                            <div className="text-label">Total Value Enabled</div>
                            <div className="text-value-B">
                              <div
                                className={classNames("text-value", {
                                  applyPurple: !_.isEmpty(selectedTVEMetricsFilter),
                                })}
                              >
                                {!_.isEmpty(String(eachMetric.improvementOpportunityValue)) &&
                                !_.isEmpty(String(eachMetric.totalValueEnabled)) ? (
                                  <>
                                    {eachMetric.totalValueEnabledUOM === "$" && "$"}
                                    {eachMetric.totalValueEnabledFormatted}
                                    {eachMetric.totalValueEnabledUOM === "%" && "%"}
                                  </>
                                ) : (
                                  "- -"
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="value-section">
                            <div className="text-label">
                              {!_.isEmpty(String(eachMetric.improvementOpportunityValue)) &&
                              !_.isEmpty(String(eachMetric.totalValueEnabled))
                                ? eachMetric.totalValueEnabledSuffixText
                                : ""}
                            </div>
                          </div>

                          <div className="value-section">
                            <div className="text-label">Benchmark Source</div>
                            <div className="text-value-B">
                              {!_.isEmpty(eachMetric.source) ? eachMetric.source : "BAT"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown dropleft">
                        <span
                          type="button"
                          onClick={() => this.handleClickMetricTVEIcon(eachMetric)}
                          className="insights-new-icon dropdown-toggle "
                          data-tip
                          data-for="tve-tooltip"
                        >
                          <ReactTooltip
                            place="bottom"
                            effect="solid"
                            id="tve-tooltip"
                            className="tve-tooltip"
                          >
                            <span>Total Value Enabled</span>
                          </ReactTooltip>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      );
    };

    const expandCollapseAccordionsSection = () => (
      <div className="collapse-btn-wrap">
        <button
          type="button"
          className="collapse-btn"
          onClick={() => {
            let action = "collapse";
            // IF NO ACCORDIONS ARE EXPANDED, THEN SET THE ACTION TO "expand" TO EXPAND ALL THE ACCORDIONS
            if (_.isEmpty(expandedAccordionsKeysArray)) {
              action = "expand";
            }
            this.expandCollapseAllAccordions(action);
          }}
        >
          {_.isEmpty(expandedAccordionsKeysArray) ? "Expand All" : "Collapse All"}
        </button>
      </div>
    );

    const arrayOfAllAccordionKeys = [];
    return (
      <>
        {/* IF API IS LOADING THEN SHOW THE LOADER */}
        {isAPILoading && <LoadingOverlay spinner={<SynopsLoader />} active />}
        <div className="clientPerformanceView">
          <BatCommonHeader
            sharedUserDetails={assessmentDataFromSynOps.shareUsersList || []}
            clientName={clientName}
            ClientLogo={ClientLogo}
            clientCount={clientCount}
            clientIndustry={clientIndustry}
            clientMarketUnit={clientMarketUnit}
            BATAssessSubOfferingID={BATAssessSubOfferingID}
            BATAssessClientID={BATAssessClientID}
            offeringName={offeringName}
            subOfferingId={subOfferingId}
            subOfferingName={subOfferingName}
            renderedFromScreen="ClientPerformanceView"
            isSubOfferingTVEEnabled={isSubOfferingTVEEnabled}
            startDate={startDate}
            endDate={endDate}
            callbackHeaderStateChange={(headerStateObj) =>
              this.handleCallbackHeaderStateChange(headerStateObj)
            }
            selectedIndustryId={selectedIndustryId}
            selectedRegionId={selectedRegionId}
            selectedBenchmark={selectedBenchmark}
            onBenchmarkSelectionChange={(dataObj) =>
              this.handleHeaderBenchmarkSelectionChange(dataObj)
            }
            createdBy={this.props.history.location.state.clientPerformanceViewScreenData.createdBy}
          />

          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="top-40 batView">
                  {!isSubOfferingTVEEnabled && _.isEmpty(modifiedDataByMetricCategories) ? (
                    <div className="outcome-highlight no-whiteShadow no-table-layout">
                      <h2>No data is available for the selected time frame</h2>
                    </div>
                  ) : (
                    <>
                      <Tab.Container
                        activeKey={currentView}
                        onSelect={(tabId) => {
                          this.handleChangeTab(tabId);
                        }}
                      >
                        <div className="batOffering-category-title">
                          <div className="header">
                            {offeringName} - {subOfferingName}
                          </div>
                          {isSubOfferingTVEEnabled ? (
                            <div className="batOffering-contractSpan">
                              {contractPeriod} Year Contract
                            </div>
                          ) : (
                            <div className="performance-edit-btn">
                              <span>
                                <button
                                  disabled={
                                    !_.isEmpty(assessmentDataFromSynOps) &&
                                    assessmentDataFromSynOps.shareUsersList.filter(
                                      (user) =>
                                        user.UserName === sessionStorage.getItem("EnterpriseID") &&
                                        user.AccessLevelID === 1
                                    ).length !== 0
                                      ? true
                                      : false
                                  }
                                  type="button"
                                  className={
                                    !_.isEmpty(assessmentDataFromSynOps) &&
                                    assessmentDataFromSynOps.shareUsersList.filter(
                                      (user) =>
                                        user.UserName === sessionStorage.getItem("EnterpriseID") &&
                                        user.AccessLevelID === 1
                                    ).length !== 0
                                      ? "batassessment-btn-purple disabled"
                                      : "batassessment-btn-purple"
                                  }
                                  onClick={() =>
                                    this.redirectToProvideMetricDetailsForAssessmentScreen()
                                  }
                                >
                                  Edit
                                </button>
                              </span>
                            </div>
                          )}
                        </div>

                        {isSubOfferingTVEEnabled && (
                          <div className="batOffering-tab-holder">
                            <div>
                              <Nav>
                                <Nav.Item>
                                  <Nav.Link
                                    disabled={isMarketingOps}
                                    eventKey="BenchmarkAnalysisView"
                                  >
                                    Benchmark Analysis
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="ValueCaseView">Value Case View</Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </div>

                            <div className="performance-edit-btn">
                              <span>
                                <button
                                  type="button"
                                  disabled={
                                    !_.isEmpty(assessmentDataFromSynOps) &&
                                    assessmentDataFromSynOps.shareUsersList.filter(
                                      (user) =>
                                        user.UserName === sessionStorage.getItem("EnterpriseID") &&
                                        user.AccessLevelID === 1
                                    ).length !== 0
                                      ? true
                                      : false
                                  }
                                  className={
                                    !_.isEmpty(assessmentDataFromSynOps) &&
                                    assessmentDataFromSynOps.shareUsersList.filter(
                                      (user) =>
                                        user.UserName === sessionStorage.getItem("EnterpriseID") &&
                                        user.AccessLevelID === 1
                                    ).length !== 0
                                      ? "batassessment-btn-purple disabled"
                                      : "batassessment-btn-purple"
                                  }
                                  onClick={() =>
                                    this.redirectToProvideMetricDetailsForAssessmentScreen()
                                  }
                                >
                                  Edit
                                </button>
                              </span>
                            </div>
                          </div>
                        )}

                        <Tab.Content className="bat-tab-content">
                          {/* START - TAB CONTENT FOR "BENCHMARK ANALYSIS VIEW" */}
                          <Tab.Pane eventKey="BenchmarkAnalysisView">
                            {_.isEmpty(modifiedDataByMetricCategories) && (
                              <div className="outcome-highlight no-whiteShadow no-table-layout tilecard-border bat-accordion-view">
                                <div className="bat-category-accordion">
                                  <h2>No data is available for the selected time frame</h2>
                                </div>
                              </div>
                            )}

                            {!_.isEmpty(modifiedDataByMetricCategories) &&
                              expandCollapseAccordionsSection()}

                            {!_.isEmpty(modifiedDataByMetricCategories) &&
                              _.map(modifiedDataByMetricCategories, (eachItem, index) => {
                                const metricCategory = eachItem.metricCategory;
                                const parentAccordionEventKey = String(
                                  `parent-${index}-${metricCategory}`
                                );
                                arrayOfAllAccordionKeys.push(parentAccordionEventKey);
                                const isAccordionExpanded =
                                  _.indexOf(
                                    expandedAccordionsKeysArray,
                                    parentAccordionEventKey
                                  ) !== -1 || _.indexOf(expandedAccordionsKeysArray, "all") !== -1;
                                const accordionActiveKey = isAccordionExpanded
                                  ? parentAccordionEventKey
                                  : "";
                                return (
                                  <div
                                    className="outcome-highlight no-whiteShadow no-table-layout tilecard-border bat-accordion-view"
                                    key={parentAccordionEventKey}
                                  >
                                    <div className="bat-category-accordion">
                                      <div className="custom-tilecard">
                                        <div className="tilecard-wrapper">
                                          <Accordion
                                            activeKey={accordionActiveKey}
                                            key={parentAccordionEventKey}
                                            className={classNames("bat-main-accordion", {
                                              "bat-accordion-only-level-one": _.isEmpty(
                                                eachItem.dataByValueDrivers
                                              ),
                                            })}
                                          >
                                            <Card>
                                              <Accordion.Toggle
                                                as={Card.Header}
                                                eventKey={parentAccordionEventKey}
                                                onClick={() =>
                                                  this.toggleAccordionSection(
                                                    parentAccordionEventKey,
                                                    arrayOfAllAccordionKeys
                                                  )
                                                }
                                                className={classNames("card-link", {
                                                  collapsed: !isAccordionExpanded,
                                                })}
                                              >
                                                {eachItem.metricCategory}
                                              </Accordion.Toggle>

                                              <Accordion.Collapse
                                                eventKey={parentAccordionEventKey}
                                              >
                                                <Card.Body>
                                                  <>
                                                    {/* IF THE METRICS ARE DIRECTLY UNDER THE METRIC CATEGORY THEN GENERATE THE LAYOUT FOR METRICS */}
                                                    {!_.isEmpty(eachItem.metrics) &&
                                                      isAccordionExpanded &&
                                                      metricsLayout(eachItem.metrics)}

                                                    {/* IF THE METRIC CATEGORY HAS "VALUE DRIVERS" UNDER IT THEN GENERATE HIERARCHICAL LAYOUT OF METRICS UNDER EACH "VALUE DRIVER" */}
                                                    {isAccordionExpanded &&
                                                      !_.isEmpty(eachItem.dataByValueDrivers) &&
                                                      _.map(
                                                        eachItem.dataByValueDrivers,
                                                        (eachData, valDriverIndex) => {
                                                          const valueDriverName =
                                                            eachData.valueDriver;
                                                          const accordionEventKey = String(
                                                            `child-${valDriverIndex}-${valueDriverName}-${parentAccordionEventKey}`
                                                          );
                                                          arrayOfAllAccordionKeys.push(
                                                            accordionEventKey
                                                          );
                                                          const isAccordionExpanded =
                                                            _.indexOf(
                                                              expandedAccordionsKeysArray,
                                                              accordionEventKey
                                                            ) !== -1 ||
                                                            _.indexOf(
                                                              expandedAccordionsKeysArray,
                                                              "all"
                                                            ) !== -1;
                                                          const accordionActiveKey =
                                                            isAccordionExpanded
                                                              ? accordionEventKey
                                                              : "";
                                                          return (
                                                            <Accordion
                                                              activeKey={accordionActiveKey}
                                                              key={accordionEventKey}
                                                              className="bat-sub-accordion"
                                                            >
                                                              <Card>
                                                                <Accordion.Toggle
                                                                  as={Card.Header}
                                                                  eventKey={accordionEventKey}
                                                                  onClick={() =>
                                                                    this.toggleAccordionSection(
                                                                      accordionEventKey,
                                                                      arrayOfAllAccordionKeys
                                                                    )
                                                                  }
                                                                  className={classNames(
                                                                    "card-link",
                                                                    {
                                                                      collapsed:
                                                                        !isAccordionExpanded,
                                                                    }
                                                                  )}
                                                                >
                                                                  {valueDriverName}
                                                                </Accordion.Toggle>

                                                                <Accordion.Collapse
                                                                  eventKey={accordionEventKey}
                                                                >
                                                                  <Card.Body>
                                                                    {isAccordionExpanded &&
                                                                      metricsLayout(
                                                                        eachData.metrics
                                                                      )}
                                                                  </Card.Body>
                                                                </Accordion.Collapse>
                                                              </Card>
                                                            </Accordion>
                                                          );
                                                        }
                                                      )}
                                                  </>
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                          </Accordion>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </Tab.Pane>
                          {/* END - TAB CONTENT FOR "BENCHMARK ANALYSIS VIEW" */}

                          {/* START - TAB CONTENT FOR "VALUE CASE VIEW" - "TVE" METRICS */}
                          {isSubOfferingTVEEnabled && (
                            <Tab.Pane eventKey="ValueCaseView">
                              {/* START - TVE FILTERS */}
                              <div className="value-case-view-filter">
                                <div
                                  onClick={() => {
                                    if (!_.isEmpty(String(increaseInClientRevenueValue))) {
                                      this.handleClickTVEMetricsFilter("increaseInClientRevenue");
                                    }
                                  }}
                                  className={classNames("filter-wrap", {
                                    active: selectedTVEMetricsFilter === "increaseInClientRevenue",
                                    disabled: _.isEmpty(String(increaseInClientRevenueValue)),
                                  })}
                                >
                                  <div className="value-case-view-filter-tab">
                                    <div>
                                      <h6>Increase in Client's Revenue</h6>
                                      <div className="filter-range-value">
                                        {!_.isEmpty(String(increaseInClientRevenueValue))
                                          ? `$${numberFormatter(increaseInClientRevenueValue, 2)}`
                                          : "-"}
                                      </div>
                                    </div>
                                    <div>
                                      <img
                                        src={
                                          selectedTVEMetricsFilter === "increaseInClientRevenue"
                                            ? UpwardCarat_Selected
                                            : UpwardCarat
                                        }
                                        alt="UpwardCarat_Selected"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div
                                  onClick={() => {
                                    if (!_.isEmpty(String(reductionInClientCostValue))) {
                                      this.handleClickTVEMetricsFilter("reductionInClientCost");
                                    }
                                  }}
                                  className={classNames("filter-wrap", {
                                    active: selectedTVEMetricsFilter === "reductionInClientCost",
                                    disabled: _.isEmpty(String(reductionInClientCostValue)),
                                  })}
                                >
                                  <div className="value-case-view-filter-tab">
                                    <div>
                                      <h6>Reduction in Client's Cost</h6>
                                      <div className="filter-range-value">
                                        {!_.isEmpty(String(reductionInClientCostValue))
                                          ? `$${numberFormatter(reductionInClientCostValue, 2)}`
                                          : "-"}
                                      </div>
                                    </div>
                                    <div>
                                      <img
                                        src={
                                          selectedTVEMetricsFilter === "reductionInClientCost"
                                            ? DownwardCarat_Selected
                                            : DownwardCarat
                                        }
                                        alt="DownwardCarat"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* END - TVE FILTERS */}

                              {_.isEmpty(modifiedTVEDataByMetricCategories) && (
                                <div className="outcome-highlight no-whiteShadow no-table-layout tilecard-border bat-accordion-view">
                                  <div className="bat-category-accordion">
                                    <h2>No data is available for the selected time frame</h2>
                                  </div>
                                </div>
                              )}

                              {!_.isEmpty(modifiedTVEDataByMetricCategories) &&
                                expandCollapseAccordionsSection()}

                              {/* START - VALUE CASE VIEW - METRICS DISPLAY */}
                              {!_.isEmpty(modifiedTVEDataByMetricCategories) &&
                                _.map(modifiedTVEDataByMetricCategories, (eachItem, index) => {
                                  const metricCategory = eachItem.metricCategory;
                                  const parentAccordionEventKey = String(
                                    `parent-${index}-${metricCategory}`
                                  );
                                  arrayOfAllAccordionKeys.push(parentAccordionEventKey);
                                  const isAccordionExpanded =
                                    _.indexOf(
                                      expandedAccordionsKeysArray,
                                      parentAccordionEventKey
                                    ) !== -1 ||
                                    _.indexOf(expandedAccordionsKeysArray, "all") !== -1;
                                  const accordionActiveKey = isAccordionExpanded
                                    ? parentAccordionEventKey
                                    : "";
                                  return (
                                    <div
                                      className="outcome-highlight no-whiteShadow no-table-layout tilecard-border bat-accordion-view"
                                      key={parentAccordionEventKey}
                                    >
                                      <div className="bat-category-accordion">
                                        <Accordion
                                          activeKey={accordionActiveKey}
                                          key={parentAccordionEventKey}
                                          className={classNames("bat-main-accordion", {
                                            "bat-accordion-only-level-one": _.isEmpty(
                                              eachItem.dataByValueDrivers
                                            ),
                                          })}
                                        >
                                          <Card>
                                            <Accordion.Toggle
                                              as={Card.Header}
                                              eventKey={parentAccordionEventKey}
                                              onClick={() =>
                                                this.toggleAccordionSection(
                                                  parentAccordionEventKey,
                                                  arrayOfAllAccordionKeys
                                                )
                                              }
                                              className={classNames("card-link", {
                                                collapsed: !isAccordionExpanded,
                                              })}
                                            >
                                              {eachItem.metricCategory}
                                            </Accordion.Toggle>

                                            <Accordion.Collapse eventKey={parentAccordionEventKey}>
                                              <Card.Body>
                                                <>
                                                  {/* IF THE METRICS ARE DIRECTLY UNDER THE METRIC CATEGORY THEN GENERATE THE LAYOUT FOR METRICS */}
                                                  {!_.isEmpty(eachItem.metrics) &&
                                                    isAccordionExpanded &&
                                                    tveMetricsLayout(eachItem.metrics)}

                                                  {/* IF THE METRIC CATEGORY HAS "VALUE DRIVERS" UNDER IT THEN GENERATE HIERARCHICAL LAYOUT OF METRICS UNDER EACH "VALUE DRIVER" */}
                                                  {isAccordionExpanded &&
                                                    !_.isEmpty(eachItem.dataByValueDrivers) &&
                                                    _.map(
                                                      eachItem.dataByValueDrivers,
                                                      (eachData, valDriverIndex) => {
                                                        const valueDriverName =
                                                          eachData.valueDriver;
                                                        const accordionEventKey = String(
                                                          `child-${valDriverIndex}-${valueDriverName}-${parentAccordionEventKey}`
                                                        );
                                                        arrayOfAllAccordionKeys.push(
                                                          accordionEventKey
                                                        );
                                                        const isAccordionExpanded =
                                                          _.indexOf(
                                                            expandedAccordionsKeysArray,
                                                            accordionEventKey
                                                          ) !== -1 ||
                                                          _.indexOf(
                                                            expandedAccordionsKeysArray,
                                                            "all"
                                                          ) !== -1;
                                                        const accordionActiveKey =
                                                          isAccordionExpanded
                                                            ? accordionEventKey
                                                            : "";
                                                        return (
                                                          <Accordion
                                                            activeKey={accordionActiveKey}
                                                            key={accordionEventKey}
                                                            className="bat-sub-accordion"
                                                          >
                                                            <Card>
                                                              <Accordion.Toggle
                                                                as={Card.Header}
                                                                eventKey={accordionEventKey}
                                                                onClick={() =>
                                                                  this.toggleAccordionSection(
                                                                    accordionEventKey,
                                                                    arrayOfAllAccordionKeys
                                                                  )
                                                                }
                                                                className={classNames("card-link", {
                                                                  collapsed: !isAccordionExpanded,
                                                                })}
                                                              >
                                                                {valueDriverName}
                                                              </Accordion.Toggle>

                                                              <Accordion.Collapse
                                                                eventKey={accordionEventKey}
                                                              >
                                                                <Card.Body>
                                                                  {isAccordionExpanded &&
                                                                    tveMetricsLayout(
                                                                      eachData.metrics
                                                                    )}
                                                                </Card.Body>
                                                              </Accordion.Collapse>
                                                            </Card>
                                                          </Accordion>
                                                        );
                                                      }
                                                    )}
                                                </>
                                              </Card.Body>
                                            </Accordion.Collapse>
                                          </Card>
                                        </Accordion>
                                      </div>
                                    </div>
                                  );
                                })}
                              {/* END - VALUE CASE VIEW - METRICS DISPLAY */}
                            </Tab.Pane>
                          )}
                          {/* END - TAB CONTENT FOR "VALUE CASE VIEW" - "TVE" METRICS */}
                        </Tab.Content>
                      </Tab.Container>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* METRIC INSIGHT DETAILS MODAL WHICH DISPLAYS THE GRAPH */}
        {isVisibleMetricInsightDetailsModal && (
          <MetricInsightDetails
            offeringName={offeringName}
            offeringType={offeringType === "Industrial" ? "industry" : _.toLower(offeringType)}
            subOfferingName={subOfferingName}
            metricData={currentlySelectedMetricData}
            startDate={startDate}
            endDate={endDate}
            onModalClose={() => this.handleCloseMetricInsightDetailsModal()}
            clientCount={clientCount}
            clientSimulationData={{
              clientName: clientName,
              industry: clientIndustry,
              marketUnit: clientMarketUnit,
              metricValue: currentlySelectedMetricData.clientMetricValue,
              // isExistingClient: true,
            }}
          />
        )}

        {/* MODAL TO SHOW METRIC INFO WHEN CLICKED ON INFO ICON */}
        {isVisibleMetricInfoModal && (
          <MetricInfoModal
            title={currentlySelectedMetricData.metricName}
            description={currentlySelectedMetricData.metricDescription}
            onClose={() => this.handleCloseMetricInfoModal()}
          />
        )}

        {/* START - TOTAL VALUE ENABLED(TVE) MODAL */}
        {isVisibleMetricTVEModal && (
          <TotalValueEnabledModal
            sharedAccessDetail={assessmentDataFromSynOps.shareUsersList || []}
            BATAssessSubOfferingID={BATAssessSubOfferingID}
            metricData={currentlySelectedMetricData}
            onClose={(retDataObj) => this.handleCloseTotalValueEnabledModal(retDataObj)}
            contractPeriod={contractPeriod}
            benchmarkName={selectedBenchmark}
            calculateMetricImprovementOpportunity={calculateMetricImprovementOpportunity}
            calculateMetricTVE={calculateMetricTVE}
          />
        )}
        {/* END - TOTAL VALUE ENABLED(TVE) MODAL */}

        {/* START - METRIC VOLUMETRIC MODAL */}
        {isVisibleMetricParametersModal && (
          <MetricParametersModal
            sharedAccessDetail={assessmentDataFromSynOps.shareUsersList || []}
            metricData={currentlySelectedMetricData}
            onCancel={() => this.hideMetricParametersModal()}
            onSubmit={(metricData, renderFor) =>
              this.handleClickSubmitBtnMetricParametersModal(metricData, renderFor)
            }
            errMsgInvalidValue={errMsgInvalidValue}
            areFormFieldsMandatory={areFormFieldsMandatoryForMetricParametersModal}
            modalSubHeaderText={subHeaderTextForMetricParametersModal}
            renderFor={renderMetricParametersModalFor}
          />
        )}
        {/* END - METRIC VOLUMETRIC MODAL */}
      </>
    );
  }
}

export default withRouter(ClientPerformanceView);
