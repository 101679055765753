import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import MultiSelect from "react-multi-select-component";
import i18n from "i18next";

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;

const overrideStrings = {
  selectSomeItems: "Select",
  allItemsAreSelected: "All items selected.",
  selectAll: "Select All ",
  search: "Search",
};

export default class IntegrationStatusReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDisplay: { cl: false, off: false, sub: false, loc: false },
      multiselect: { cl: false, off: false, sub: false, loc: false },
      isApiCalled: { cl: false, off: false, sub: false, loc: false },
      SourceClientOptions: [],
      selectedClientOption: [],
      SourceSubOfferingOptions: [],
      selectedSubOfferingOption: [],
      OneTimeSetupModel: {
        isActive: true,
        destinationClientId: 0,
        destinationClientName: "",
        destinationSubOfferingId: 0,
        destinationSubOfferingName: "",
        sourceSubOfferingId: 0,
        sourceSubOfferingName: [],
        sourceClientId: 0,
        sourceClientName: [],
        sourceOfferingName: [],
      },
      active: false,
      OfferingSelected: false,
      errors: [],
      formIsValid: false,
      temp: [],
      downloadUrl: null,
      viewmore: false,
      StartDate: null,
      EndDate: null,
      focusedInput: null,
      process: "",
      Client: "",
      MaturityPhase: this.props.MaturityPhase,
      processOptions: [],
      ClientOptions: [],
      OfferingOptions: [],
      selectedStatus: [],
      statusOptions: [
        {
          value: 2,
          label: "Draft",
        },
        {
          value: 5,
          label: "In Review (Implementation)",
        },
        {
          value: 3,
          label: "Pending MD Approval",
        },
        {
          value: 4,
          label: "Data Published",
        },
      ],
      Report: {
        ClientIds: [],
        OfferingIds: [],
        StartDate: new Date(),
        EndDate: new Date(),
        Status: null,
        StatusIds: [],
        includeDemoDeal: false,
      },
    };

    this.validateForm = this.validateForm.bind(this);
    this.OnOfferingChange = this.OnOfferingChange.bind(this);
    this.getOfferings = this.getOfferings.bind(this);
    this.getClients = this.getClients.bind(this);
    this.QueryString = this.QueryString.bind(this);
    this.generateExcelReport = this.generateExcelReport.bind(this);
  }

  generateExcelReport() {
    var that = this;
    this.validateForm();
    var clientId = this.state.Report.ClientIds.toString();
    var Offering = this.state.Report.OfferingIds.toString();
    var status = this.state.Report.Status;
    const includeDemoDeal = this.state.Report.includeDemoDeal;
    var data = {
      offering: Offering,
      clientname: clientId,
      status: status,
      includeDemoDeal: includeDemoDeal,
    };
    let constructedURL = LocalApiBaseUrl + "Report/GetIntegrationStatusReport";
    if (data.offering !== "" && data.clientname !== "" && data.status != null) {
      this.setState({
        active: true,
      });
      axiosInstance
        .post(constructedURL, data, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status !== 200) {
            alert("An error has occurred!");
          }
          this.setState({ active: false });
          let fileNameFromHeaders = response.headers.filename;
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileNameFromHeaders);
            document.body.appendChild(link);
            link.click();
          } else {
            // BLOB FOR EXPLORER 11
          }
        })
        .catch(function (error) {
          that.setState({ active: false });
          trycatchAlertPopup(error);
        });
    }
  }

  QueryString() {
    if (this.validateForm()) {
      var A = "Report/GetIntegrationStatusReport?";
      var B = LocalApiBaseUrl;
      var clientId = this.state.Report.ClientIds.toString();
      var Offering = this.state.Report.OfferingIds.toString();
      var status = this.state.Report.Status;
      let downloadUrl = this.state.downloadUrl;
      const includeDemoDeal = this.state.Report.includeDemoDeal;
      downloadUrl =
        B +
        A +
        "&ClientName=" +
        clientId +
        "&Offering=" +
        Offering +
        "&status=" +
        status +
        "&includeDemoDeal=" +
        includeDemoDeal;
      this.setState({
        downloadUrl: downloadUrl,
      });
    }
  }

  onStartDateChange = (event) => {
    let errors = {};
    const { Report } = this.state;
    Report.EndDate = new Date();
    this.setState({
      errors: errors,
      Report,
      EndDate: null,
    });
    Report.StartDate = event;
    this.setState({ StartDate: event });
    this.setState({
      Report: Report,
    });
  };
  onEndDateChange = (event) => {
    let errors = {};
    this.setState({
      errors: errors,
      EndDate: event,
    });
    const Report = this.state.Report;
    Report.EndDate = event;
    this.setState({
      Report: Report,
    });
  };

  getOfferings(offeringIdparam = { Client: 0 }) {
    let SourceOfferingOptions = [];
    if (this.state.Report.ClientIds.length > 0) {
      let request = { OrgEntityID: this.state.Report.ClientIds.toString(), FeatureName: "Integration Status Report" }
      axiosInstance.post(`${LocalApiBaseUrl}Configurator/GetTowerLevelOffering`, request)
        .then((response) => {
          this.setState({ OfferingOptions: response.data });
          var temp = [];
          response.data.forEach((row) => {
            temp.push(row);
          });
          this.setState({ temp: temp });
          temp.forEach((row, i) => {
            let dummy = [];
            dummy.label = row.OfferingName;
            dummy.value = row.OfferingID;
            dummy.ChildOfferings = row.ChildOfferings;
            SourceOfferingOptions.push(dummy);
          });
          this.setState({
            OfferingOptions: SourceOfferingOptions,
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }
  getClients() {
    axiosInstance
      .get(`${LocalApiBaseUrl}SO/GetOrgStructure`, {
        params: { featureName: "Integration Status Report" },
      })
      .then((response) => {
        let SourceClientOptions = [];
        let selectedClientOption = [];
        response.data.forEach((row, i) => {
          let dummy = [];
          dummy.label = row.OrgEntityName;
          dummy.value = row.OrgEntityID;
          SourceClientOptions.push(dummy);
          if (this.props.data && this.state.isPropsLoaded === false) {
            if (
              this.state.OneTimeSetupModel.sourceClientName.includes(row.name)
            ) {
              selectedClientOption.push(dummy);
            }
          }
        });
        this.setState({
          SourceClientOptions: SourceClientOptions,
          selectedClientOption: selectedClientOption,
        });
        if (this.props.data && this.state.isPropsLoaded === false) {
          this.filterSelectionControl();
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  componentDidMount() {
    this.getClients();
    this.getOfferings();
  }

  OnOfferingChange(offeringlist) {
    let SourceSubOfferingOptions = [];
    let selectedSubOfferingOption = [];
    let ChildOfferings = [];
    offeringlist.forEach((row) => {
      row.ChildOfferings.map((SubRow) => ChildOfferings.push(SubRow));
    });
    ChildOfferings.forEach((row) => {
      let dummy = [];
      dummy.label = row.Process;
      dummy.value = row.OfferingID;
      SourceSubOfferingOptions.push(dummy);
      if (
        this.state.OneTimeSetupModel.sourceSubOfferingName.includes(row.name)
      ) {
        selectedSubOfferingOption.push(dummy);
      }
    });
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    let isApiCalled = this.state.isApiCalled;
    isApiCalled.loc = false;
    OneTimeSetupModel.sourceLocationName = [];
    let Report = this.state.Report;
    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
      isPropsLoaded: true,
      isApiCalled: isApiCalled,
      SourceLocationOptions: [],
      selectedLocationsOption: [],
    });
    let selectedOfferingOption = this.state.selectedOfferingOption;
    let tempOffering = [];
    let tempOfferingValue = [];
    var len = offeringlist.length;
    for (var i = 0; i < len; i++) {
      tempOffering.push(offeringlist[i]);
      tempOfferingValue.push(offeringlist[i].value);
    }
    Report.OfferingIds = tempOfferingValue;
    selectedOfferingOption = tempOffering;
    OneTimeSetupModel.sourceOfferingName = tempOfferingValue;
    this.setState({
      selectedOfferingOption: selectedOfferingOption,
      SourceSubOfferingOptions: SourceSubOfferingOptions,
      selectedSubOfferingOption: selectedSubOfferingOption,
      OneTimeSetupModel: OneTimeSetupModel,
      Report: Report,
    });
    this.filterSelectionControl();
  }
  onClientChange(selectedList) {
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    let isApiCalled = this.state.isApiCalled;
    isApiCalled.loc = false;
    isApiCalled.sub = false;
    isApiCalled.off = false;
    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
      OfferingOptions: [],
      SourceSubOfferingOptions: [],
      selectedSubOfferingOption: [],
      SourceOfferingOptions: [],
      selectedOfferingOption: [],
      isPropsLoaded: true,
    });
    let selectedClientOption = this.state.selectedClientOption;
    let tempClient = [];
    let tempClientValue = [];
    var len = selectedList.length;
    let Report = this.state.Report;
    for (var i = 0; i < len; i++) {
      tempClient.push(selectedList[i]);
      tempClientValue.push(selectedList[i].value);
    }
    Report.ClientIds = tempClientValue;
    selectedClientOption = tempClient;
    OneTimeSetupModel.sourceClientName = tempClientValue;
    this.setState({
      selectedClientOption: selectedClientOption,
      OneTimeSetupModel: OneTimeSetupModel,
      Report: Report,
    });
    this.getOfferings();
    this.filterSelectionControl();
  }

  onSourceProcessSelect(selectedList) {
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    let isApiCalled = this.state.isApiCalled;
    isApiCalled.loc = false;
    OneTimeSetupModel.sourceLocationName = [];
    let Report = this.state.Report;
    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
      isPropsLoaded: true,
      isApiCalled: isApiCalled,
      SourceLocationOptions: [],
      selectedLocationsOption: [],
    });
    let selectedSubOfferingOption = this.state.selectedSubOfferingOption;
    let tempProcess = [];
    let tempProcessValue = [];
    var len = selectedList.length;
    for (var i = 0; i < len; i++) {
      tempProcess.push(selectedList[i]);
      tempProcessValue.push(selectedList[i].value);
    }
    Report.OfferingIds = tempProcessValue;
    selectedSubOfferingOption = tempProcess;
    OneTimeSetupModel.sourceSubOfferingName = tempProcessValue;
    this.setState({
      selectedSubOfferingOption: selectedSubOfferingOption,
      OneTimeSetupModel: OneTimeSetupModel,
      Report: Report,
    });
    this.filterSelectionControl();
  }

  filterSelectionControl() {
    let isApiCalled = this.state.isApiCalled;
    let isVisible = this.state.filterDisplay;
    if (isVisible.cl && !isApiCalled.cl) {
      this.getClients();
      isApiCalled.cl = true;
      this.setState({
        isApiCalled: isApiCalled,
      });
    } else if (isVisible.sub && !isApiCalled.sub) {
      this.getSourceSubOfferings();
      isApiCalled.sub = true;
      this.setState({
        isApiCalled: isApiCalled,
      });
    }
  }

  onStatusChange(selectedList) {
    let Report = this.state.Report;
    let tempStatus = [];
    let tempStatusName = [];
    let tempStatusLabel = [];
    var len = selectedList.length;
    for (var i = 0; i < len; i++) {
      tempStatus.push(selectedList[i]);
      tempStatusName.push(selectedList[i].value);
      tempStatusLabel.push(selectedList[i].label);
    }
    Report.Status = tempStatusLabel;
    Report.StatusIds = tempStatusName;
    this.setState({
      Report: Report,
      selectedStatus: tempStatus,
    });
  }
  validateForm() {
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    let errors = {};
    let Report = this.state.Report;
    let formIsValid = true;

    if (!Report["ClientIds"].length) {
      errors["ClientIds"] = i18n.t("Please Select Client");
      formIsValid = false;
    }
    if (
      !Report["OfferingIds"].length &&
      OneTimeSetupModel["sourceOfferingName"].length === 0
    ) {
      errors["OfferingIds"] = i18n.t("Please Select  Offering");
      formIsValid = false;
    }
    if (
      OneTimeSetupModel["sourceSubOfferingName"].length === 0 ||
      (!Report["OfferingIds"].length &&
        OneTimeSetupModel["sourceOfferingName"].length)
    ) {
      formIsValid = false;
      errors["destinationSubOfferingId"] = i18n.t("Please Select Sub-offering");
    }
    if (!Report["StatusIds"].length) {
      errors["Status"] = i18n.t("Please Select Status");
      formIsValid = false;
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  onIsDemoCheck = (e) => {
    const report = this.state.Report;
    report.includeDemoDeal = e.target.checked;

    this.setState({
      Report: report,
    });
  };

  render() {
    const clientMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={this.state.SourceClientOptions}
          value={this.state.selectedClientOption}
          onChange={this.onClientChange.bind(this)}
          labelledBy="OrgEntityName"
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={this.state.multiselect}
        />
      </React.Fragment>
    );
    const offeringMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={this.state.OfferingOptions}
          value={this.state.selectedOfferingOption}
          onChange={this.OnOfferingChange.bind(this)}
          labelledBy="OfferingName"
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={this.state.multiselect}
        />
      </React.Fragment>
    );
    const SubofferingMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={this.state.SourceSubOfferingOptions}
          value={this.state.selectedSubOfferingOption}
          onChange={this.onSourceProcessSelect.bind(this)}
          labelledBy="OrgEntityName"
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={this.state.multiselect}
        />
      </React.Fragment>
    );

    const statusMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={this.state.statusOptions}
          value={this.state.selectedStatus}
          onChange={this.onStatusChange.bind(this)}
          labelledBy="OrgEntityName"
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={this.state.multiselect}
        />
      </React.Fragment>
    );

    return (
      <>
        <div className="main-data">
          <div className="row">
            <div className="form-group col-lg-4">
              <label htmlFor="Client">
                Client<span className="required"></span>
              </label>
              <div className="custom-multi-select">
                {clientMultipleselectbar}
              </div>
              <span className="errorMsg">{this.state.errors.ClientIds}</span>
            </div>
            <div className="form-group col-lg-4">
              <label htmlFor="Offering">
                Offering<span className="required"></span>
              </label>
              <div className="custom-multi-select">
                {offeringMultipleselectbar}
              </div>
              <span className="errorMsg">{this.state.errors.OfferingIds}</span>
            </div>
            <div className="form-group col-lg-4">
              <label htmlFor="SubOffering">
                Sub Offering<span className="required"></span>
              </label>
              <div className="custom-multi-select">
                {SubofferingMultipleselectbar}
              </div>
              <span className="errorMsg">
                {this.state.errors.destinationSubOfferingId}
              </span>
            </div>
            <div className="form-group col-lg-4">
              <label htmlFor="status">
                Status<span className="required"></span>
              </label>
              <div className="custom-multi-select">
                {statusMultipleselectbar}
              </div>
              <span className="errorMsg">{this.state.errors.Status}</span>
            </div>
            <div className="form-group col-lg-4">
              <label className="form-check dealData-check-top">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={(e) => this.onIsDemoCheck(e)}
                  defaultChecked={this.state.Report.includeDemoDeal}
                />
                <span className="checkmark"></span>
                <span className="form-check-label">Include Demo Deal</span>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <button
                className="btn btn-primary btn-sm"
                id="btnsave"
                onClick={this.generateExcelReport}
                disabled={this.state.active}
              >
                Generate Report
              </button>
              <LoadingOverlay
                active={this.state.active}
                spinner={<SynopsLoader />}
              ></LoadingOverlay>
            </div>
          </div>
        </div>
      </>
    );
  }
}
