import React from 'react';
import { TabPanel } from 'react-web-tabs';
import i18n from "i18next";
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import { store } from "../reducers/configureStore";


const options = {
    //loop: true,
    margin: 24,
    responsiveClass: "",
    dots: true,
    responsive: {
        0: {
            items: 1,
            nav: true
        },
        600: {
            items: 1,
            nav: false
        },
        1000: {
            items: 1,
            nav: true,
            loop: false
        }
    }
};

const Imageicon = {
    "Diverse Data": "icon-crossing-arrows",
    "Human + Machine Talent": "icon-small-user",
    "Work Orchestration": "icon-connect",
    "Insights & Intelligence": "icon-chart"

};

function griddata(Data) {

    var RAGClass = "outcome-divider row-divider";
    return Data.map((item, index) => {
        if (index < 4) {
            if (item.RAGStatus == "Green") {
                RAGClass = "green-clr"
            }
            else if (item.RAGStatus == "Blue") {
                RAGClass = "blue-clrr"
            }
            else if (item.RAGStatus == "Red") {
                RAGClass = "red-clr"
            }
            else if (item.RAGStatus == "Orange") {
                RAGClass = "orange-clr"
            }

            return <p className={RAGClass}>{item.RAGStatus}</p>
        }
        else {
            this.extradata.push(item)

        }

    })
}
export function ViewInsights_Grid(props) {

    let value;

    let valueDelivered;

    function tablegrid() {

        let LanguageID = store.getState().CurrentFilter.languageData.id

        return <> {props.Details.sort((a,b)=>a.ProjectInitiativeName.toLowerCase() > b.ProjectInitiativeName.toLowerCase() ?1:-1).map((row, index) => {



            if (row.EstimatedUnit === "$") {
                value = (row.EstimatedUnit + " ").concat(row.TargetValue);

            }
            else if (!row.EstimatedUnit) {

                if(!row.TargetValue)
                {
                    value="N.A";
                }
                else
                {
                    value = row.TargetValue;
                }

                

            }
            else if (row.EstimatedUnit !== null) {

                value = row.TargetValue.concat(" " + row.EstimatedUnit);
            }
            // Value Delivered start
            if(row.ValueUnit === "$")
            {
                valueDelivered = (row.ValueUnit + " ").concat(row.DeliveredValue);
            }
            else if (!row.ValueUnit) {

                if(!row.DeliveredValue)
                {
                    valueDelivered="N.A";  
                }
                else{
                    valueDelivered =row.DeliveredValue;
                }

            }
            else if (row.ValueUnit !== null) {

                valueDelivered = row.DeliveredValue.concat(" " + row.ValueUnit);
            }
            // value Delivered end

            let color = row.RAGStatus.toLowerCase();


            return <>
                <TabPanel tabId="All">
                    <div className="ioj-full">
                        <div className="outcome-highlight no-whiteShadow no-table-layout">
                            <div className="outcome-divider">
                                <div className="d-flex align-items-start">
                                    <div className={`outcome-indicate status-color-${color}`}>
                                        <p>{row.ProjectInitiativeName || ""}</p>
                                        <div className="tag-divider">
                                            <i className={Imageicon[row.SOCategoryName]}></i>
                                            <b>{row.SOCategoryName}</b>
                                            <b>{i18n.t("Client_Process")}: {`${row.OrgEntityName || ""} - ${row.OfferingName || ""}`}</b>
                                            <b>{LanguageID === 1 ? (row.InitiativeStatus === "Concept" ? i18n.t("In-Concept") : "" || row.InitiativeStatus === "InFlight" ? i18n.t("In-Flight") : "" || row.InitiativeStatus === "Delivered" ? i18n.t("Delivered") : "" ||  row.InitiativeStatus === "OnHold" ? i18n.t("On-Hold") : "") :
                                                (row.InitiativeStatus)}</b>
                                          
                                        </div>
                                       
                                        {row.InitiativeOutcomeOption ?
                                            <div className="tag-divider">
                                                <b className="p-0">{i18n.t("Initiative_Outcome")}:</b><span>{row.InitiativeOutcomeOption.split(',').join(', ')}</span>
                                            </div> : null}
                                        <div className="outcome-bottom">
                                            <p>{row.ProjectDescription || ""}</p>
                                        </div>
                                       
                                    </div>
                                    <div className="col-lg-4 ml-auto">
                                    
                                            <div className="item outcome-divider">
                                                <div className={`outcome-indicate status-color-${color}`}>
                                                    <p>{row.BOImpacted}</p>
                                                    <div className="outcome-bottom">
                                                      
                                                        {row.InitiativeStatus === "Delivered"?<span>{i18n.t("Value_Delivered")}: <b>{`${valueDelivered || ""}`}</b>  </span>:(<span>{i18n.t("Estimated_Value")}: <b>{`${value || ""}`}</b></span>)  }
                                                    </div>
                                                   
                                            </div>
                                        </div>
                                        {/* </OwlCarousel> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel tabId={row.SOCategoryName}>
                    <div className="ioj-full">
                        <div className="outcome-highlight no-whiteShadow no-table-layout">
                            <div className="outcome-divider">
                                <div className="d-flex align-items-start">
                                    <div className={`outcome-indicate status-color-${color}`}>
                                        <p>{row.ProjectInitiativeName || ""}</p>
                                        <div className="tag-divider">
                                            <i className={Imageicon[row.SOCategoryName]}></i>
                                            <b>{row.SOCategoryName}</b>
                                            <b>{i18n.t("Client_Process")}: {`${row.OrgEntityName || ""} - ${row.OfferingName || ""}`}</b>
                                        
                                            <b>{row.InitiativeStatus && row.InitiativeStatus === "Concept" ? i18n.t("In-Concept") : "" || row.InitiativeStatus === "InFlight" ? i18n.t("In-Flight") : "" ||  row.InitiativeStatus === "Delivered" ? i18n.t("Delivered") : "" ||  row.InitiativeStatus === "OnHold" ? i18n.t("On-Hold") : ""}</b>
                                           
                                        </div>
                                        {row.InitiativeOutcomeOption ?
                                            <div className="tag-divider">
                                                <b>{i18n.t("Initiative_Outcome")}:</b><span>{row.InitiativeOutcomeOption}</span>
                                            </div> : null}
                                        <div className="outcome-bottom">
                                            <p>{row.ProjectDescription || ""}</p>
                                        </div>
                                      
                                    </div>
                                    <div className="col-lg-4 ml-auto">
                                     
                                            <div className="item outcome-divider">
                                                <div className={`outcome-indicate status-color-${color}`}>
                                                    <p>{row.BOImpacted}</p>
                                                    <div className="outcome-bottom">
                                                     
                                                        {row.InitiativeStatus === "Delivered"?<span>{i18n.t("Value_Delivered")}: <b>{`${valueDelivered || ""}`}</b>  </span>:(<span>{i18n.t("Estimated_Value")}: <b>{`${value || ""}`}</b></span>)  }
                                                        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </>
        })}</>

    }

    return <>

        {tablegrid()}
    </>


}
