import React, { Component } from 'react';
import { LocalApiBaseUrl } from '../Shared/Constant';
import { AgGridReact } from 'ag-grid-react';
import LandingPopup from './LandingPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class LandingList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewmore: false,
            isEdit: false,
            rowData: [],
            TupleKeys: {
                Seconds: 0,
                MaxButtons: 0,
                MinButtons: 0,
            },
            rowSelected: [],
            columnDefs: [
                {
                    headerName: "Edit",
                    suppressMovable: true,
                    width: 60,
                    cellRenderer: (params) => {
                        var link = document.createElement('span');
                        link.className = 'tbledit';
                        link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.dealpopup(e, params.data);
                        });
                        return link;
                    },
                    suppressFilter: true, resizable: true, suppressSizeToFit: true
                },
                {
                    headerName: "Delete", suppressMovable: true,
                    width: 80,
                    cellRenderer: (params) => {
                        var link = document.createElement('span');
                        link.className = 'tbldel';
                        link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.deleteButtonData(e, params.data);
                        });
                        return link;

                    },
                    suppressFilter: true, resizable: true, suppressSizeToFit: true
                },
                {
                    headerName: "Button name",
                    field: "Name",
                    suppressMovable: true, resizable: true, suppressSizeToFit: true
                },
                {
                    headerName: "Button Description",
                    field: "Description",
                    suppressMovable: true, resizable: true, suppressSizeToFit: true
                },
                {
                    headerName: "Button link",
                    field: "URL",
                    suppressMovable: true, resizable: true, suppressSizeToFit: true
                },
                {
                    headerName: "Button Sequence",
                    field: "Sequence",
                    width: 120,
                    suppressMovable: true, resizable: true, suppressSizeToFit: true
                },
                {
                    headerName: "Button Type",
                    field: "Type",
                    width: 120,
                    suppressMovable: true, resizable: true, suppressSizeToFit: true
                },
                {
                    headerName: "Is Active", field: "IsActive", width: 80, suppressMovable: true, resizable: true, suppressSizeToFit: true,

                    cellRenderer: params => {
                        return `<label class="form-check">
                    <input class="form-check-input" disabled type="checkbox" ${params.value ? 'checked' : ''}>
                    <span class="checkmark"></span>
                     </label>`;
                    }
                },

            ],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'single',
                defaultColDef: {
                    sortable: true
                },
            }
        }
        this.onClose = this.onClose.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.deleteButtonData = this.deleteButtonData.bind(this);
        this.onBtnExport = this.onBtnExport.bind(this);

    }

    deleteButtonData(e, data) {
        const { TupleKeys, rowData } = this.state;
        if (data.IsActive) {
            if (this.props.featureData.filter((data) => data.Submenu == "Landing Page"
                && data.Operations.includes("Delete")).length > 0) {
                if (rowData.filter(a => a.IsActive).length === TupleKeys.MinButtons) {
                    window.alert(this.numberToWords(TupleKeys.MinButtons) + " active is mandatory")
                }
                else {
                    if (window.confirm('Are you sure you want to delete the item?')) {
                        axiosInstance.delete(`${LocalApiBaseUrl}SO/DisableButtonData`, {
                            params: {
                                ID: data.ID
                            }
                        }).then((response) => {
                            window.alert(response.data.StatusMessage)
                            this.getbuttonData();

                        })
                            .catch((error) => {
                                trycatchAlertPopup(error);
                            });


                    }

                }
            }
            else window.alert("Access Denied !!")
        }
    }
    numberToWords(num) {
        let n = false;
        var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
        var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
        if ((num = num.toString()).length > 9) return 'overflow';
        n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
        str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
        str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
        str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
        str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + ' ' : '';
        return str.trim();
    }
    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }

    dealpopup(e, data) {
        const { TupleKeys, rowData } = this.state;
        if (data != undefined) {
            // edit condition
            if (this.props.featureData.filter((data) => data.Submenu == "Landing Page" && data.Operations.includes("Edit")).length > 0) {
                this.setState({ viewmore: true, rowSelected: data })
            }
            else window.alert("Access Denied !!")

        }
        else {

            if (this.props.featureData.filter((data) => data.Submenu == "Landing Page" && data.Operations.includes("Add")).length > 0) {
                this.setState({ viewmore: true, rowSelected: data })
            }
            else window.alert("Access Denied !!")

        }
    }

    onClose(operation) {
        this.setState({ viewmore: false });
        operation === "add" && this.getbuttonData();
    }

    getbuttonData() {
        //API call
        axiosInstance.get(`${LocalApiBaseUrl}SO/GetAllButtons`)
            .then((response) => {
                this.setState({
                    rowData: response.data.LandingPageButtonConfigModel,
                    TupleKeys: response.data.TupleKeys
                })

            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });


    }

    componentWillMount() {

        this.getbuttonData();
        let columnDefs = this.state.columnDefs;


    }


    handleSave(data) {
        //Post API Call${LocalApiBaseUrl}DeliveryRegionApproval/AddEditDeliveryRegion
        axiosInstance.post(`${LocalApiBaseUrl}SO/AddEditButtons`, data)
            .then((response) => {
                window.alert(response.data.StatusMessage)
                this.getbuttonData();
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });

        this.getbuttonData();

    }
    onBtnExport() {

        let params = {
            columnKeys: ['DeliveryRegionName', 'Status', 'IsActive'],
            columnGroups: true,
            allColumns: false,
            skipHeader: false,
            fileName: 'DeliveryRegionList',

        };
        this.state.gridOptions.api.exportDataAsCsv(params);
    }
    render() {


        return (
            <div className="main-data">

                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => this.onFilterTextBoxChanged(e)} />
                            </div>
                            {this.props.featureData.filter((data) => data.Submenu == "Landing Page" && data.Operations.includes("Add")).length > 0 &&
                                <div className="form-group col-lg-3">
                                    <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.dealpopup(e) }}><span><FontAwesomeIcon icon={faPlusCircle} /></span> Add more Buttons</button>
                                </div>
                            }
                            <div className="ml-auto mr-1">
                                {this.props.featureData.filter((data) => data.Submenu == "Landing Page" && data.Operations.includes("Download")).length > 0 ?
                                    <span className="btn btn-primary btn-sm py-1" onClick={this.onBtnExport}>
                                        <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Download
                                    </span> : null}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ag-theme-balham ag-table">
                            <AgGridReact
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                paginationPageSize={50}
                                floatingFilter={true}
                                gridOptions={this.state.gridOptions}
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                            </AgGridReact>
                        </div>
                    </div>
                </div>
                {this.state.viewmore ? <LandingPopup rowData={this.state.rowData} numberToWords={this.numberToWords} data={this.state.rowSelected} TupleKeys={this.state.TupleKeys} show={this.state.viewmore} isEdit={this.state.isEdit} onClose={this.onClose} handleSave={this.handleSave} /> : null}

            </div>
        )
    }
}