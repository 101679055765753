export const SAVE_CLIENT_DATA = 'SAVE_CLIENT_DATA';
export const SAVE_CONFIG_OFFERING_DATA = 'SAVE_CONFIG_OFFERING_DATA';

export function saveClientData(data) {
  return {
    type: SAVE_CLIENT_DATA,
    payload: data,
  };
}

export function saveconfigofferingData(data) {
  return {
    type: SAVE_CONFIG_OFFERING_DATA,
    payload: data,
  };
}
