import {
  BAT_SET_CLIENT_SIMULATION_FLOW_INITIATED_FROM_SCREEN,
  BAT_UNSET_CLIENT_SIMULATION_FLOW_INITIATED_FROM_SCREEN,
  BAT_SET_OFFERINGS_DATA,
  BAT_UNSET_OFFERINGS_DATA,
  BAT_USER_DATA,
  BATDATA_FILTER,
} from "../actions/BATActions";

const initialState = {
  clientSimulationFlowInitiatedFromScreen: {
    screenName: "",
    screenData: {},
  },
  offeringsData: [],
};

const initialUserState = {
  userData: [],
  BATData: {
    BATClientName: "",
    BATOfferingName: "",
    BATSubOfferingName: "",
    BATOfferingType: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BAT_SET_CLIENT_SIMULATION_FLOW_INITIATED_FROM_SCREEN:
      return {
        ...state,
        clientSimulationFlowInitiatedFromScreen: {
          screenName: action.payload.screenName,
          screenData: action.payload.screenData,
        },
      };
    case BAT_UNSET_CLIENT_SIMULATION_FLOW_INITIATED_FROM_SCREEN:
      return {
        ...state,
        clientSimulationFlowInitiatedFromScreen: initialState.clientSimulationFlowInitiatedFromScreen,
      };

    case BAT_SET_OFFERINGS_DATA:
      return {
        ...state,
        offeringsData: action.payload.offeringsData,
      };
    case BAT_UNSET_OFFERINGS_DATA:
      return {
        ...state,
        offeringsData: initialState.offeringsData,
      };

    default:
      return state;
  }
};

//REDUCER FOR OFFERING,SUBOFFERING AND OFFERING TYPE FOR THE BAT//
export const BATUsersReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case BAT_USER_DATA:
      return {
        ...state,
        userData: action.payload.userData,
      };
    case BATDATA_FILTER:
      return {
        ...state,
        BATData: {
          BATClientName: action.payload.BATClientName,
          BATOfferingName: action.payload.BATOfferingName,
          BATSubOfferingName: action.payload.BATSubOfferingName,
          BATOfferingType: action.payload.BATOfferingType,
        },
      };

    default:
      return state;
  }
};

export default reducer;
