/* eslint-disable import/no-cycle */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl, regexForNegativeNumbers } from '../Shared/Constant';

import { store } from '../reducers/configureStore';
import SynopsLoader from '../Shared/SynopsLoader';

import { isValidText, InvalidDescription } from '../Shared/commonFunctions';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;

class DataPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // formIsValid: false,
      specialChar: InvalidDescription(),
      specialCharUnitError: false,
      errors: {},
      localWidgetData: [],
      newAddedMetric: {},
      showAddNewUnitPopUp: false,
      ListItem: '',
      emptyUnitError: false,
      showErrorMsg: false,
      showLoaderOnPage: false,
      widgetPopUpData: [],
      selectedFieldIdx: 0,
      unitFieldType: '',
    };

    this.handleHide = this.handleHide.bind(this);
    this.handleUnitHide = this.handleUnitHide.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleInputFieldChange = this.handleInputFieldChange.bind(this);
    this.handleNewUnit = this.handleNewUnit.bind(this);
    this.handleSaveNewUnit = this.handleSaveNewUnit.bind(this);
    this.uniKeyCode = this.uniKeyCode.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      widgetData, wMode, widgetPopUpData, widgetIdx,
    } = this.props;
    const { newAddedMetric } = this.state;
    const result = widgetData;
    let tempObj;
    if (wMode === 'ADD_WDATA') {
      tempObj = { ...newAddedMetric };
      tempObj.Data = [...widgetPopUpData];
      for (let i = 0; i < tempObj.Data.length; i += 1) {
        if (result[widgetIdx].WidgeFields.length === 0) {
          tempObj.Data[i].ConfigurationWidgetMappingID = 0;
        }
        tempObj.Data[i].ConfigurationWidgetAttributeID = 0;
      }
      this.setState({ newAddedMetric: tempObj });
    }
    this.setState({ localWidgetData: [...widgetData], widgetPopUpData: [...widgetPopUpData] });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { wMode } = this.props;
    let temp;
    if (wMode === 'ADD_WDATA') {
      temp = newProps.widgetData[newProps.widgetIdx].AddNewWidget;
    } else {
      temp = newProps.widgetData[newProps.widgetIdx].WidgeFields[newProps.selectedMetricIdx].Data;
    }
    this.setState({ localWidgetData: newProps.widgetData, widgetPopUpData: temp, showLoaderOnPage: false });
  }

  handleHide() {
    const { onClose } = this.props;
    onClose();
  }

  handleUnitHide() {
    this.setState({ showAddNewUnitPopUp: false, ListItem: '' });
  }

  handleSave(type) {
    /* eslint-disable no-shadow */
    const { localWidgetData, widgetPopUpData } = this.state;
    const {
      filterDetails, onSaveData, widgetIdx, selectedMetricIdx,
    } = this.props;
    const selectedWidIndex = widgetIdx;
    const selectedMetIndex = selectedMetricIdx;
    const temp = [...localWidgetData];
    const tempObj = {};
    const arrNew = [...widgetPopUpData];
    const isValid = this.handleSaveValdation();
    if (isValid) {
      this.setState({ showErrorMsg: true });
    } else {
      if (type === 'ADD') {
        const newIdGenerated = temp[selectedWidIndex].WidgeFields.length - 1;
        tempObj.DisplayGrouping = temp[selectedWidIndex].WidgeFields.length > 0
          ? temp[selectedWidIndex].WidgeFields[newIdGenerated].DisplayGrouping + 1
          : 1;
        let error = false;
        const arr = temp[selectedWidIndex].WidgeFields;
        const newlyAddedData = { ...temp[selectedWidIndex] };
        for (let i = 0; i < arr.length; i += 1) {
          let tempStr1 = '';
          let tempStr2 = '';
          for (let j = 0; j < arr[i].Data.length; j += 1) {
            tempStr1 += arr[i].Data[j].AttributeValue;
            tempStr2 += arrNew[j].AttributeValue;
          }
          if (tempStr1 === tempStr2) {
            error = true;
            break;
          } else {
            error = false;
          }
        }
        if (!error) {
          tempObj.Data = arrNew;

          newlyAddedData.WidgeFields = [tempObj];
        } else {
          alert('Duplicate Entry');
        }
        this.setState({ localWidgetData: temp, showErrorMsg: false });

        if (!error) {
          const data = {
            FilterParams: {
              OrgEntityID: filterDetails.Client,
              OfferingID: filterDetails.process,
              LocationID: filterDetails.Locations,
              MonthDT: filterDetails.Month,
            },
            WidgetDetails: newlyAddedData,
          };
          axiosInstance
            .post(`${LocalApiBaseUrl}Configurator/AutoSaveConfiguratorDDWidget`, data)
            .then((resp) => {
              if (resp.data.WidgetList) {
                axiosInstance
                  .get(`${LocalApiBaseUrl}Configurator/GetDiverseDataQualityScore`, {
                    params: {
                      LocationID: filterDetails.Locations,
                      OrgEntityID: filterDetails.Client,
                      OfferingID: filterDetails.process,
                      MonthDT: filterDetails.Month,
                      LanguageID: store.getState().CurrentFilter.languageData.id,
                    },
                  })
                  .then((response) => {
                    if (response.data) {
                      store.dispatch({
                        type: 'SAVE_QC_SCORE_OF_EACH_TAB',
                        payload: response.data,
                      });
                    }
                  })
                  .catch((error) => {
                    trycatchAlertPopup(error);
                  });
                if (resp.data.WidgetList.length && resp.data.WidgetList[0].WidgeFields.length) {
                  temp[selectedWidIndex].WidgeFields.push(resp.data.WidgetList[0].WidgeFields[0]);
                  onSaveData(temp);
                }
              }
            })
            .catch((error) => {
              trycatchAlertPopup(error);
            });
        }
      } else {
        const temp = _.cloneDeep(localWidgetData);
        let error1 = false;
        const arr = temp[selectedWidIndex].WidgeFields;
        const test = [...widgetPopUpData];
        const newlyUpdatedData = { ...temp[selectedWidIndex] };
        for (let i = 0; i < arr.length; i += 1) {
          let tempStr1 = '';
          let tempStr2 = '';
          for (let j = 0; j < arr[i].Data.length; j += 1) {
            tempStr1 += arr[i].Data[j].AttributeValue;
            tempStr2 += test[j].AttributeValue;
          }
          if (tempStr1 === tempStr2) {
            error1 = true;
            break;
          } else {
            error1 = false;
          }
        }
        if (!error1) {
          temp[selectedWidIndex].WidgeFields[selectedMetIndex].Data = test;
          newlyUpdatedData.WidgeFields = [{ ...temp[selectedWidIndex].WidgeFields[selectedMetIndex] }];
          this.setState({ localWidgetData: temp });
          const data = {
            FilterParams: {
              OrgEntityID: filterDetails.Client,
              OfferingID: filterDetails.process,
              LocationID: filterDetails.Locations,
              MonthDT: filterDetails.Month,
            },
            WidgetDetails: newlyUpdatedData,
          };
          axiosInstance
            .post(`${LocalApiBaseUrl}Configurator/AutoSaveConfiguratorDDWidget`, data)
            .then((response) => {
              if (response.data.WidgetList) {
                axiosInstance
                  .get(`${LocalApiBaseUrl}Configurator/GetDiverseDataQualityScore`, {
                    params: {
                      LocationID: filterDetails.Locations,
                      OrgEntityID: filterDetails.Client,
                      OfferingID: filterDetails.process,
                      MonthDT: filterDetails.Month,
                      LanguageID: store.getState().CurrentFilter.languageData.id,
                    },
                  })
                  .then((response) => {
                    if (response.data) {
                      store.dispatch({
                        type: 'SAVE_QC_SCORE_OF_EACH_TAB',
                        payload: response.data,
                      });
                    }
                  })
                  .catch((error) => {
                    trycatchAlertPopup(error);
                  });

                onSaveData(temp);
              }
            })
            .catch((error) => {
              trycatchAlertPopup(error);
            });
        } else {
          this.setState({ localWidgetData: temp });
        }
      }
      this.handleHide();
    }
  }

  handleSaveValdation() {
    const { errors, widgetPopUpData } = this.state;
    const errorobj = { ...errors };

    let hasError = false;
    const tempObj = _.cloneDeep(widgetPopUpData);
    for (let i = 0; i < tempObj.length; i += 1) {
      /** adding validation for number field range */
      if (
        String(tempObj[i].DataTypeName) === 'Numeric'
        && tempObj[i].FieldCategory !== 'List'
        && tempObj[i].FieldTypeName !== 'List'
        && tempObj[i].AttributeValue !== ''
      ) {
        if (tempObj[i].AttributeValue < tempObj[i].MinValue || tempObj[i].AttributeValue > tempObj[i].MaxValue) {
          const errormsg = 'Value must be in between';
          errorobj.RangeError = `${errormsg} ${tempObj[i].MinValue} to ${tempObj[i].MaxValue}`;
          tempObj[i].isError = true;
          hasError = true;
          this.setState({
            errors: errorobj,
          });
        }
      }
      if (tempObj[i].AttributeValue === '' || tempObj[i].AttributeValue.trim() === '') {
        tempObj[i].isError = true;
        hasError = true;
      } else if (!isValidText(tempObj[i].AttributeValue.trim())) {
        tempObj[i].isError = true;
        tempObj[i].isSpecialChar = true;
        hasError = true;
      }
    }
    this.setState({ widgetPopUpData: tempObj });
    return hasError;
  }

  handleInputFieldChange(e, int, fObj) {
    const { widgetPopUpData } = this.state;
    this.setState({
      errors: {},
      specialCharUnitError: false,
    });

    if (String(e.target.value) === 'list') {
      this.setState({
        fieldType: e.target.value,
        showAddNewUnitPopUp: true,
        selectedFieldIdx: int,
        unitFieldType: fObj.DataTypeName,
        emptyUnitError: false,
      });
    } else {
      this.setState({ fieldType: '' });
    }
    if (e.target.value !== 'list' && e.target.value !== 'Select') {
      const tempObj = _.cloneDeep(widgetPopUpData);
      if (String(fObj.DataTypeName) === 'Numeric') {
        if (regexForNegativeNumbers.test(e.target.value.trim())) {
          if (e.target.value === '-.') {
            tempObj[int].AttributeValue = '-0.';
          } else {
            tempObj[int].AttributeValue = e.target.value;
          }
        }
      } else {
        tempObj[int].AttributeValue = e.target.value;
      }
      if (e.target.value === '' || e.target.value.trim() === '') {
        tempObj[int].isError = true;
      } else {
        tempObj[int].isError = false;
      }
      this.setState({ widgetPopUpData: tempObj });
      this.setState({ showErrorMsg: false });
    }
  }

  handleNewUnit(e) {
    const { unitFieldType } = this.state;
    this.setState({
      errors: {},
      emptyUnitError: false,
    });
    if (String(unitFieldType) === 'Numeric') {
      if (regexForNegativeNumbers.test(e.target.value) || String(e.target.value) === '') {
        let temp;
        if (e.target.value !== '') {
          temp = parseInt(e.target.value, 10);
        } else {
          temp = '';
        }
        this.setState({ ListItem: temp });
      }
    } else {
      this.setState({ ListItem: e.target.value.trim() });
    }
    if (e.target.value !== '') this.setState({ emptyUnitError: false });
  }

  handleValidation = (value) => {
    const { errors, localWidgetData, selectedFieldIdx } = this.state;
    const { widgetIdx } = this.props;
    let valid = true;
    const errorobj = { ...errors };
    if (typeof value === 'string') {
      if (String(value) === '' || String(value.trim()) === '') {
        valid = false;
        this.setState({
          emptyUnitError: true,
        });
      } else if (!isValidText(value.trim())) {
        valid = false;
        this.setState({
          specialCharUnitError: true,
        });
      }
    } else if (String(value) === '') {
      valid = false;
      this.setState({
        emptyUnitError: true,
      });
    } else {
      const selectedWidIndex = widgetIdx;
      const numMax = localWidgetData[selectedWidIndex].AddNewWidget[selectedFieldIdx].MaxValue;
      const numMin = localWidgetData[selectedWidIndex].AddNewWidget[selectedFieldIdx].MinValue;
      if (value < numMin || value > numMax) {
        const errormsg = 'Value must be in between';
        errorobj.RangeError = `${errormsg} ${numMin} to ${numMax}`;
        this.setState({
          errors: errorobj,
        });
        valid = false;
      }
    }
    return valid;
  };

  handleSaveNewUnit(idx) {
    const { widgetPopUpData, ListItem, localWidgetData } = this.state;
    const { filterDetails, widgetIdx, onSaveData } = this.props;
    const param = widgetPopUpData[idx];
    const valid = this.handleValidation(ListItem);
    if (valid) {
      const payload = {
        OfferingWidgetAttributeTemplateID: param.AttributeID,
        AttributeName: param.AttributeName,
        AttributeDataType: param.AttributeDataType,
        AttributeFieldType: param.AttributeFieldType,
        ListSource: 'Manual',
        ListItems: ListItem,
      };
      const result = _.cloneDeep(localWidgetData);
      const selectedWidIndex = widgetIdx;

      const arr = result[selectedWidIndex];
      let isDuplicate = false;
      if (String(arr.AddNewWidget[idx].FieldTypeName) === 'List' && String(arr.AddNewWidget[idx].FieldCategory) === 'List') {
        const check_duplicate = String(arr.AddNewWidget[idx].DataTypeName) === 'Numeric'
          ? arr.AddNewWidget[idx].ListData.filter((item) => String(item.Value) === String(ListItem))
          : arr.AddNewWidget[idx].ListData.filter(
            (item) => String(item.Value.toLowerCase()) === String(ListItem.toLowerCase()),
          );
        if (check_duplicate.length > 0) {
          isDuplicate = true;
        }
      }
      if (!isDuplicate) {
        this.setState({ showLoaderOnPage: true });
        axiosInstance
          .post(`${LocalApiBaseUrl}Configurator/ADDNewAttribute`, payload, {
            params: {
              OrgEntityID: filterDetails.Client,
              LocationID: filterDetails.Locations,
              MonthDT: filterDetails.Month,
              OfferingID: filterDetails.process,
            },
          })
          .then((response) => {
            this.handleUnitHide();
            if (String(arr.AddNewWidget[idx].FieldTypeName) === 'List' && String(arr.AddNewWidget[idx].FieldCategory) === 'List') {
              arr.AddNewWidget[idx].ListData = response.data;
            }
            if (arr.WidgeFields.length > 0) {
              for (let ii = 0; ii < arr.WidgeFields.length; ii += 1) {
                const dataArr = arr.WidgeFields[ii].Data;
                for (let k = 0; k < dataArr.length; k += 1) {
                  if (String(dataArr[idx].FieldTypeName) === 'List' && String(dataArr[idx].FieldCategory) === 'List') {
                    dataArr[idx].ListData = response.data;
                  }
                }
              }
            }
            result[selectedWidIndex] = arr;
            this.setState({ localWidgetData: result });
            onSaveData(result);
            this.setState({ showLoaderOnPage: false });
          })
          .catch((error) => {
            this.setState({ showLoaderOnPage: false });
            trycatchAlertPopup(error);
          });
      } else {
        alert('Data already exists');
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  uniKeyCode(e) {
    if (parseInt(e.keyCode, 10) === 188) {
      e.preventDefault();
    }
  }

  render() {
    const {
      localWidgetData, selectedFieldIdx, showAddNewUnitPopUp, showLoaderOnPage, showErrorMsg, fieldType, errors, widgetPopUpData, unitFieldType, ListItem, emptyUnitError, specialChar, specialCharUnitError,
    } = this.state;
    const {
      widgetIdx, widgetTitle, show, wMode,
    } = this.props;
    const selectedWidIndex = widgetIdx;

    const newPopLabel = localWidgetData[selectedWidIndex].AddNewWidget[selectedFieldIdx]
      .AttributeName;
    const numMax = localWidgetData[selectedWidIndex].AddNewWidget[selectedFieldIdx].MaxValue;
    const numMin = localWidgetData[selectedWidIndex].AddNewWidget[selectedFieldIdx].MinValue;
    const numMaxLength = localWidgetData[selectedWidIndex].AddNewWidget[selectedFieldIdx].MaxLength;
    /* eslint-disable no-nested-ternary */
    return (
      <>
        <LoadingOverlay
          className="custom-loader"
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={showLoaderOnPage}
        />
        <Modal
          show={show}
          onHide={this.handleHide}
          className="modal fade dataprocessed-modal"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">
              {widgetTitle}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section form-widget">
                {widgetPopUpData.map((fieldObj, idx) => (
                  <div key={`w${idx.id}`}>
                    {fieldObj.FieldCategory === 'List' ? (
                      <>
                        <div className="row">
                          <div className="form-group col-lg-12">
                            <label htmlFor="electronic-unit">
                              {fieldObj.AttributeName}
                              {' '}
                              <span className="required">*</span>
                            </label>
                            <div className="select-custom label-animation">
                              <select
                                onChange={(e) => this.handleInputFieldChange(e, idx, fieldObj)}
                                className="form-control"
                                id="FieldType2"
                                value={fieldObj.AttributeValue}
                              >
                                <option value="Select" className="select-field">
                                  {`Select ${fieldObj.AttributeName}`}
                                </option>
                                {fieldObj.ListData.length
                                  ? fieldObj.ListData.map((each) => <option value={each.Value}>{each.Value}</option>)
                                  : null}
                                <option value="list">Add New</option>
                              </select>
                            </div>
                            {showErrorMsg && fieldObj.isError ? (
                              <div className="errorMsg">
                                {
                                  fieldObj.isSpecialChar ? specialChar : `${fieldObj.AttributeName} ${i18n.t('is_Required')}`

                                  }
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {String(fieldType) === 'list' ? (
                          <Modal
                            show={showAddNewUnitPopUp}
                            onHide={this.handleUnitHide}
                            className="modal fade dataprocessed-modal"
                            size="medium"
                            id="dealpopup"
                            backdrop="static"
                          >
                            <Modal.Header closeButton>
                              <h4 className="modal-title" id="dealprofile">
                                {`Add New ${newPopLabel}`}
                              </h4>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="listSourceSec">
                                <div className="row">
                                  <div className="form-group col-sm-11">
                                    {String(unitFieldType) === 'Numeric' ? (
                                      <input
                                        className="form-control animated-input"
                                        id="field4"
                                        placeholder={`New ${newPopLabel}`}
                                        type="number"
                                        max={numMax}
                                        min={numMin}
                                        onChange={(e) => this.handleNewUnit(e)}
                                        tabIndex="0"
                                        value={ListItem}
                                        onKeyDown={(e) => this.uniKeyCode(e)}
                                      />
                                    ) : (
                                      <input
                                        className="form-control animated-input"
                                        id="field4"
                                        placeholder={`New ${newPopLabel}`}
                                        type="text"
                                        maxLength={numMaxLength}
                                        onChange={(e) => this.handleNewUnit(e)}
                                        tabIndex="0"
                                        onKeyDown={(e) => this.uniKeyCode(e)}
                                      />
                                    )}

                                    {specialCharUnitError ? (
                                      <div className="errorMsg">{specialChar}</div>
                                    ) : null}
                                    {emptyUnitError ? (
                                      <div className="errorMsg">{`${newPopLabel} ${i18n.t('is_Required')}`}</div>
                                    ) : null}
                                    {errors.RangeError ? (
                                      <div className="errorMsg">{errors.RangeError}</div>
                                    ) : null}
                                  </div>
                                  <div className="form-group col-sm-1 delete-type" />
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <div className="modal-btn">
                                <Button variant="default" className="mr-2 footer-btn" onClick={this.handleUnitHide}>
                                  Cancel
                                </Button>
                                <Button
                                  variant="primary"
                                  className="footer-btn"
                                  onClick={() => this.handleSaveNewUnit(selectedFieldIdx)}
                                >
                                  {`Add ${newPopLabel}`}
                                </Button>
                              </div>
                            </Modal.Footer>
                          </Modal>
                        ) : null}
                      </>
                    ) : (
                      <div className="row">
                        <div className="form-group col-lg-12">
                          <label htmlFor="electronic-unit">
                            {fieldObj.AttributeName}
                            {' '}
                            <span className="required">*</span>
                          </label>
                          {fieldObj.DataTypeName === 'Numeric' ? (
                            <input
                              type="number"
                              className="form-control"
                              value={fieldObj.AttributeValue}
                              max={fieldObj.MaxValue}
                              min={fieldObj.MinValue}
                              onChange={(e) => this.handleInputFieldChange(e, idx, fieldObj)}
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              value={fieldObj.AttributeValue}
                              maxLength={fieldObj.MaxLength}
                              onChange={(e) => this.handleInputFieldChange(e, idx, fieldObj)}
                            />
                          )}
                          {showErrorMsg && fieldObj.isError ? (
                            errors.RangeError
                                && fieldObj.isError
                                && fieldObj.DataTypeName === 'Numeric' ? (
                                  <div className="errorMsg">{errors.RangeError}</div>
                              ) : (
                                <div className="errorMsg">
                                  {
                                  fieldObj.isSpecialChar ? specialChar
                                    : `${fieldObj.AttributeName} ${i18n.t('is_Required')}`
                                  }
                                </div>
                              )
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              <Button variant="default" className="mr-2 footer-btn" onClick={this.handleHide}>
                Cancel
              </Button>
              {wMode === 'ADD_WDATA' ? (
                <Button variant="primary" className="footer-btn" onClick={() => this.handleSave('ADD')}>
                  Add
                </Button>
              ) : (
                <Button variant="primary" className="footer-btn" onClick={() => this.handleSave('UPDATE')}>
                  Update
                </Button>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  diverseData: [...state.diverseReducers.diverse],
});
const mapDispatchToProps = (dispatch) => ({
  onSaveData: (data) => {
    dispatch({ type: 'ADD_DATAPROCESSED', payload: data });
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataPopUp);

DataPopUp.propTypes = {
  filterDetails: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  widgetTitle: PropTypes.string.isRequired,
  widgetIdx: PropTypes.number.isRequired,
  wMode: PropTypes.string.isRequired,
  widgetPopUpData: PropTypes.array.isRequired,
  widgetData: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveData: PropTypes.func.isRequired,

};
