import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import _ from "lodash";
import moment from "moment";
import i18n from "i18next";
import { LocalApiBaseUrl } from "../Shared/Constant";
import "../CSS/helpsection.css";
import AddFunctionality from "./AddFunctionality";

class HelpTopics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      query: "",
      KeyIDs: [
        "ID",
        "IsActive",
        "VideoLink",
        "DocLink",
        "CreatedBy",
        "CreatedTS",
        "UpdatedBy",
        "UpdatedTS",
      ],
      addFunctionalityStatus: false,
      editFunctionalityStatus: false,
      ConfiguratorHelpTopics: [],
      ClonedConfiguratorHelpTopics: [],
      EditData: null,
      featureData: [],
    };
    this.addFunctionalityModalShow = this.addFunctionalityModalShow.bind(this);
    this.editFunctionalityModalShow = this.editFunctionalityModalShow.bind(
      this
    );
    this.deleteFunctionality = this.deleteFunctionality.bind(this);
    this.childToParent = this.childToParent.bind(this);
    this.getRoleData = this.getRoleData.bind(this);
  }
  addFunctionalityModalShow() {
    this.setState({
      addFunctionalityStatus: true,
      EditData: null,
      isEdit: false,
    });
  }

  editFunctionalityModalShow = (details, event) => {
    this.setState({
      addFunctionalityStatus: true,
      EditData: details,
      isEdit: true,
    });
  };
  deleteFunctionality = (details, event) => {
    if (window.confirm("Are you sure you want to delete the item?")) {
      axiosInstance
        .delete(`${LocalApiBaseUrl}UserGuide/DisableUserGuideData`, {
          params: { ID: details.ID },
        })
        .then((response) => {
          window.alert(response.data.StatusMessage);
          this.fetchTopicData();
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };
  childToParent(selectedTab) {
    if (selectedTab === "Add") this.setState({ addFunctionalityStatus: false });
    this.fetchTopicData();
  }
  fetchTopicData() {
    axiosInstance
      .get(`${LocalApiBaseUrl}UserGuide/GetFunctionalityDetails`, {
        params: {
          LanguageId: store.getState().CurrentFilter.languageData.id,
        },
      })
      .then((response) => {
        let SortData = response.data.filter((f) => f.IsActive === true);
        SortData = _.orderBy(
          SortData,
          function(o) {
            return new moment(o.ID);
          },
          ["desc"]
        );
        this.setState(
          {
            ConfiguratorHelpTopics: SortData,
            ClonedConfiguratorHelpTopics: SortData,
          },
          () => {
            this.commonsearchFilter(this.state.query);
          }
        );
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  getRoleData() {
    var data = store.getState();
    let roleData1 = data.roleReducers["roleReducers"];
    this.setState({ featureData: roleData1 }, () => {});

    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 }, () => {});
    });
  }
  componentDidMount() {
    this.getRoleData();
    this.fetchTopicData();
  }

  commonsearchFilter(query) {
    let updatedTopicsData = [];
    let ConfiguratorHelpTopics = this.state.ConfiguratorHelpTopics;
    ConfiguratorHelpTopics.filter((obj) => {
      Object.keys(obj).forEach((key) => {
        if (
          this.state.KeyIDs.includes(key) &&
          obj[key] &&
          obj[key]
            .toString()
            .toUpperCase()
            .includes(query.trim().toUpperCase())
        ) {
          return;
        } else {
          if (
            obj[key] !== null &&
            obj[key]
              .toString()
              .toUpperCase()
              .includes(query.trim().toUpperCase())
          ) {
            if (!updatedTopicsData.includes(obj)) {
              updatedTopicsData.push(obj);
            }
          }
        }
      });
    });
    this.setState({
      ClonedConfiguratorHelpTopics: updatedTopicsData,
    });
  }
  handleTopicChange = (e) => {
    this.commonsearchFilter(e.target.value);
    this.setState({
      query: e.target.value,
    });
  };
  render() {
    return (
      <>
        {this.state.featureData.filter(
          (data) =>
            String(data.FeatureName) === "Help" &&
            data.Operations.includes("View")
        ).length !== 0 ? (
          <div className="helptopics-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="helptopic-heading">
                    <h5> {i18n.t("Help_Topics")}</h5>
                    <div className="helptopic-close">
                      <NavLink to="/admin/Configurator" className="close-icon">
                        <i className="fas fa-times"></i>
                      </NavLink>
                    </div>
                  </div>
                  <div className="search-functionality">
                    <div className="searchFilter ml-auto">
                      <input
                        type="search"
                        value={this.state.query}
                        onChange={this.handleTopicChange}
                        placeholder="Search ..."
                      />
                    </div>
                    {this.state.featureData.filter(
                      (data) =>
                        String(data.FeatureName) === "Help" &&
                        data.Operations.includes("Add")
                    ).length !== 0 ? (
                      <div className="addfunc-btn">
                        <button
                          type="button"
                          className="btn btn-doublegradient"
                          onClick={this.addFunctionalityModalShow}
                        >
                          {i18n.t("Add_Functionality")}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="helptopic-list">
                    {this.state.ClonedConfiguratorHelpTopics.length !== 0 ? (
                      _.map(
                        this.state.ClonedConfiguratorHelpTopics,
                        (each, index) => {
                          return (
                            <div className="helptopic-listitem">
                              <div className="listitem-heading">
                                <h6>{each.TopicName} </h6>
                                {this.state.featureData.filter(
                                  (data) =>
                                    String(data.FeatureName) === "Help" &&
                                    data.Operations.includes("Edit")
                                ).length !== 0 ||
                                this.state.featureData.filter(
                                  (data) =>
                                    String(data.FeatureName) === "Help" &&
                                    data.Operations.includes("Delete")
                                ).length !== 0 ? (
                                  <div className="dropdown infomore-dropdown">
                                    <a
                                      className="infomore-icon"
                                      href="#"
                                      role="button"
                                      id="dropdownMenuLink"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <span>
                                        <i className="fas fa-ellipsis-h"></i>
                                      </span>
                                    </a>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuLink"
                                    >
                                      {this.state.featureData.filter(
                                        (data) =>
                                          String(data.FeatureName) === "Help" &&
                                          data.Operations.includes("Edit")
                                      ).length !== 0 ? (
                                        <a
                                          className="dropdown-item"
                                          onClick={this.editFunctionalityModalShow.bind(
                                            this,
                                            each
                                          )}
                                        >
                                          <i className="far fa-edit"></i> Edit
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.featureData.filter(
                                        (data) =>
                                          String(data.FeatureName) === "Help" &&
                                          data.Operations.includes("Delete")
                                      ).length !== 0 ? (
                                        <a
                                          className="dropdown-item"
                                          onClick={this.deleteFunctionality.bind(
                                            this,
                                            each
                                          )}
                                        >
                                          <i className="fal fa-trash"> </i>{" "}
                                          Delete
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="listitem-content">
                                <p>{each.TopicDescription}</p>
                              </div>
                              <div className="listitem-footer">
                                <a
                                  href={
                                    each.VideoLink && each.VideoLink !== "NA"
                                      ? each.VideoLink.startsWith("www")
                                        ? "https://" + each.VideoLink
                                        : each.VideoLink
                                      : each.VideoLink.substr
                                  }
                                  target={"_blank"}
                                >
                                  <span
                                    className={`${
                                      each.VideoLink && each.VideoLink !== "NA"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <i className="fal fa-video"></i>{" "}
                                    {i18n.t("See_Video")}
                                  </span>
                                </a>
                                <a
                                  href={
                                    each.DocLink && each.DocLink !== "NA"
                                      ? each.DocLink.startsWith("www")
                                        ? "https://" + each.DocLink
                                        : each.DocLink
                                      : each.DocLink.substr
                                  }
                                  target={"_blank"}
                                >
                                  <span
                                    className={`${
                                      each.DocLink && each.DocLink !== "NA"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <i className="fal fa-file"></i>{" "}
                                    {i18n.t("Know_More")}
                                  </span>
                                </a>
                              </div>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <div className="title-card">
                        <small className="form-text text-muted">
                          <b>{i18n.t("No_Functionalities")}</b>
                        </small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.addFunctionalityStatus && (
          <AddFunctionality
            childToParent={this.childToParent}
            addFunctionalityStatus={this.state.addFunctionalityStatus}
            EditData={this.state.EditData}
            isEdit={this.state.isEdit}
          />
        )}
      </>
    );
  }
}
export default HelpTopics;
