import React from 'react';
import { Modal } from 'react-bootstrap';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import axiosInstance from '../Shared/interceptor';
import { regexForConfMasterFields, regexForTextAreas,idTokenstring } from '../Shared/Constant';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;

export default class ClientPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      apivalue: '',
      Client: {
        OrgEntityID: '0',
        OrgEntityName: '',
        OrgEntityImage: null,
        ApproverRemarks: '',
        RequestorRemarks: '',
        Status: '',
        IsActive: false,
        CreatedUser: 'admin',
        CreatedTS: new Date(),
        UpdatedUser: 'admin',
        UpdatedTS: new Date(),
      },
      errors: [],
    };

    this.onClientChange = this.onClientChange.bind(this);
    this.onRemarksChange = this.onRemarksChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props;
    if (data) {
      this.setState({
        Client: data,
      });
    }
  }

  componentDidMount() {
    const { show } = this.props;
    this.setState({
      show,
    });
  }

  handleApproveHide(e) {
    const { onApproveClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {
      if (String(e.target.textContent) !== '×') {
        onApproveClose();
      }
    } else {
      onApproveClose();
    }
  }

  handleRejectHide(e) {
    const { onRejectClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {
      if (String(e.target.textContent) !== '×') {
        onRejectClose();
      }
    } else {
      onRejectClose();
    }
  }

  handleHide(e) {
    const { onClose } = this.props;
    const { onSave } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {
      if (String(e.target.textContent) !== '×') {
        onClose(e);
      } else {
        onClose(e);
      }
    } else {
      onClose(e);
    }
    onSave();
  }

  handleSave() {
    const { onSave } = this.props;
    const { Client } = this.state;
    if (this.validateForm()) {
      onSave(Client);

      this.handleHide();
    }
  }

  onRemarksChange(event) {
    this.setState({ errors: {} });
    const { Client } = this.state;
    Client.RequestorRemarks = event.target.value;
    this.setState({
      Client,
    });
  }

  onStatusChange(event) {
    const { Client } = this.state;
    Client.IsActive = event.target.checked;
    this.setState({
      Client,
    });
  }

  onClientChange(event) {
    const errors = {};
    this.setState({
      errors,
    });
    const { Client } = this.state;
    Client.OrgEntityName = event.target.value;
    this.setState({
      Client,
    });
  }

  passToConfirmation(data) {
    const { enteredData } = this.props;
    enteredData(data.PoNumber);
  }

  validateForm() {
    const { rowData } = this.props;
    const { Client } = this.state;
    const errors = {};
    let formIsValid = true;
    const data = rowData;

    if (data.length > 0) {
      const oldData = data.map((el) => el.OrgEntityName);
      if (Client.OrgEntityName) {
        const obj = data.filter((el) => el.OrgEntityName === Client.OrgEntityName);

        const doesOtherRecordWithSameNameExist = obj.length > 1
          && obj.filter((elt) => elt.OrgEntityID !== Client.OrgEntityID).length > 0;

        if (
          oldData.includes(Client.OrgEntityName.trim())
          && doesOtherRecordWithSameNameExist
        ) {
          formIsValid = false;
          errors.ClientName = i18n.t('Client_Name_already_exists');
        }
      }
    }

    if (Client.OrgEntityName !== undefined) {
      if (!Client.OrgEntityName.trim()) {
        formIsValid = false;
        errors.ClientName = i18n.t('Client_Name_is_required');
      } else {
        const isValid = regexForConfMasterFields.test(Client.OrgEntityName);
        if (!isValid) {
          formIsValid = false;
          errors.ClientName = i18n.t('Client_Name_contains_Special_characters');
        }
        if (Client.OrgEntityName.length > 50) {
          formIsValid = false;
          errors.ClientName = i18n.t('Client_Name_charactersLimit');
        }
      }
    }
    if (Client.RequestorRemarks == null) {
      formIsValid = false;
      errors.RequestorRemarks = i18n.t('addWoErrorM8');
    } else if (!Client.RequestorRemarks.trim()) {
      formIsValid = false;
      errors.RequestorRemarks = i18n.t('addWoErrorM8');
    } else {
      const isValid = regexForTextAreas.test(Client.RequestorRemarks);
      if (!isValid) {
        formIsValid = false;
        errors.RequestorRemarks = i18n.t('addWoErrorM9');
      }
    }

    this.setState({
      errors,
    });
    if (formIsValid) {
      this.setState({
      });
    } else {
      this.setState({
      });
    }
    return formIsValid;
  }

  render() {
    const { data } = this.props;
    const
      {
        Client, errors, apivalue, show,
      } = this.state;
    let divTag;
    if (data) {
      divTag = (
        <div className="form-group col-lg-6">
          <label htmlFor="">
            {i18n.t('Client_Name')}
            <span className="required">*</span>

          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            readOnly
            onChange={this.onClientChange}
            value={Client.OrgEntityName}
          />
          <span className="errorMsg">
            {errors.ClientName}
          </span>
        </div>
      );
    } else {
      divTag = (
        <div className="form-group col-lg-6">
          <label htmlFor="">
            {i18n.t('Client_Name')}
            <span className="required">*</span>

          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            onChange={this.onClientChange}
            value={Client.OrgEntityName}
          />
          <span className="errorMsg">
            {errors.ClientName}
          </span>
        </div>
      );
    }
    const { eachdata } = this.props;
    if (eachdata) {
      divTag = (
        <div className="form-group col-lg-6">
          <label htmlFor="">
            {i18n.t('Requestor_Remarks')}
            <span className="required">*</span>
          </label>
          <textarea maxLength="200" readOnly className="form-control" id="remarks" onChange={this.onRemarksChange.bind(this)} value={Client.RequestorRemarks} />
          <span className="errorMsg">{errors.RequestorRemarks}</span>
        </div>
      );
    } else {
      divTag = (
        <div className="form-group col-lg-6">
          <label htmlFor="">
            {i18n.t('Requestor_Remarks')}
            <span className="required">*</span>
          </label>
          <textarea maxLength="200" className="form-control" id="remarks" onChange={this.onRemarksChange.bind(this)} value={Client.RequestorRemarks} />
          <span className="errorMsg">{errors.RequestorRemarks}</span>
        </div>
      );
    }
    if (data) {
      if (data.Status === 'Approved' || data.Status === 'Rejected') {
        divTag = (
          <div className="form-group col-lg-6">
            <label htmlFor="">
              {i18n.t('Approver_Remarks')}
              <span className="required" />
            </label>
            <textarea maxLength="200" className="form-control" id="ClientContact1" disabled={data || apivalue} value={data.ApproverRemarks} />

          </div>
        );
      }
    }
    if (data) {
      if (data.Status === 'Approved') {
        divTag = (
          <div className="form-group col-lg-6">
            <label className="form-check">
              <input className="form-check-input" type="checkbox" onChange={this.onStatusChange} defaultChecked={Client.IsActive} />
              <span className="checkmark" />
              <span className="form-check-label">{i18n.t('Is_Active')}</span>
            </label>
          </div>
        );
      } else {
        divTag = (
          <div className="form-group col-lg-6">

            <label className="form-check">
              <input className="form-check-input" readOnly type="checkbox" onChange={this.onStatusChange} defaultChecked={Client.IsActive} />
              <span className="checkmark" />
              <span className="form-check-label">{i18n.t('Is_Active')}</span>
            </label>
          </div>
        );
      }
    } else {
      divTag = (
        <div className="form-group col-lg-6">

          <label className="form-check">
            <input className="form-check-input" type="checkbox" onChange={this.onStatusChange} defaultChecked={Client.IsActive} />
            <span className="checkmark" />
            <span className="form-check-label">{i18n.t('Is_Active')}</span>
          </label>
        </div>
      );
    }
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={show}
          onHide={this.handleHide.bind}
          className="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>

            {!data ? (
              <h4 className="modal-title" id="dealprofile">
                {/* Add Client */}
                {i18n.t('Add_Client')}
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile">
                {/* Edit Client */}
                {`${i18n.t('Edit')} ${i18n.t('Client')}`}
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  {divTag}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              {!data && (

              <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={i18n.t('Create')} />

              )}

              {data.Status === 'Approved' && (

              <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={i18n.t('Update')} />

              )}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
ClientPopup.defaultProps = {
  data: [],
  rowData: [],
  eachdata: [],
  show: false,
  onApproveClose: () => {},
  onClose: () => {},
  onSave: () => {},
  onRejectClose: () => {},
};
ClientPopup.propTypes = {
  data: PropTypes.array,
  show: PropTypes.bool,
  rowData: PropTypes.array,
  eachdata: PropTypes.array,
  onApproveClose: PropTypes.func,
  onRejectClose: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  enteredData: PropTypes.number.isRequired,
};
