import React from "react";
import i18n from "i18next";

function SummaryViewDropdown({
  title,
  dropdownStatus,
  dropDownClick,
  AppliedList,
  allList,
  onCheckBoxClick,
  handleApply,
  listdata,
}) {
  return (
    <div className="location-filter singlefilter">
      <h6>{title}</h6>

      <div className="location-dropdown">
        <span className="selected-location" onClick={dropDownClick}>
          {AppliedList.filter((loc) => loc.checked === true).length > 0 &&
          allList.filterApply === false ? (
            AppliedList.filter((loc) => loc.checked === true).length === 1 ? (
              <span>{AppliedList.filter((loc) => loc.checked === true)[0].Location}</span>
            ) : (
              <span>{AppliedList.filter((loc) => loc.checked === true)[0].Location} ...</span>
            )
          ) : (
            allList.Location
          )}
        </span>
        {dropdownStatus ? (
          <div className="location-check-list">
            <ul>
              <li>
                <label className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={allList.checked}
                    onClick={() => {
                      onCheckBoxClick("All");
                    }}
                  />
                  <span className="checkmark"></span>
                  <span className="form-check-label">{i18n.t("All")}</span>
                </label>
              </li>
              {listdata.map((data) => (
                <li>
                  <label className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={data.checked}
                      onClick={() => {
                        onCheckBoxClick(data.Location);
                      }}
                    />
                    <span className="checkmark"></span>
                    <span className="form-check-label">{data.label}</span>
                  </label>
                </li>
              ))}
            </ul>
            {/*  <div className="location-footer">
        <button
          type="button"
          class="btn btn-outline-secondary"
          onClick={this.handleClear}
        >
          {i18n.t("Clear")}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          onClick={this.handleApply}
          disabled={applyButtonDisable}
        >
          {i18n.t("Apply")}
        </button>
      </div> */}
          </div>
        ) : (
          ""
        )}
      </div>
      {dropdownStatus ? (
        <div className="location-dropdown-overlay" onClick={handleApply}></div>
      ) : (
        ""
      )}
      <div className="selected-location-list">
        <ul>
          {allList.filterApply === false &&
            AppliedList.filter((loc) => loc.checked === true).map((data) => (
              <li>{data.Location}</li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default SummaryViewDropdown;
