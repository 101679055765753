export let BOMasterReducers = (
  state = {
    BO: [],
    attributeLabels:[],
    unitsData:[]
  },
  action
) => {
  switch (action.type) {
    case "INITIALISE_BO_MASTER": {
      
      state = { ...state };
      state["BO"] = action.payload;

      state["BO"].sort((a, b) => (a.SOCategoryName_En > b.SOCategoryName_En) ? 1 : -1)



      return state;
    }
    case "ADD_BO_CATEGORY_MASTER": {
      
      state = { ...state };

      state["BO"].filter(x => x.SOCategoryName.toLowerCase() == action.payload.SOCategoryName.toLowerCase())
        .length > 0
        ? (state["BO"].filter(
            data => data.SOCategoryName.toLowerCase() == action.payload.SOCategoryName.toLowerCase()
          )[0] = action.payload)
        : state["BO"].push(action.payload);
      return state;
    }

    case "ADD_BO_MASTER": {
      
      state = { ...state };



      if (state["BO"].length > 0) {
        if(state["BO"].filter((x)=> x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.filter((data) => data.KPIMappingID == action.payload.KPIMappingID).length > 0){
            state["BO"].filter((x)=>x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData = state["BO"].filter((x)=>x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.filter((data)=> data.KPIMappingID != action.payload.KPIMappingID);   
            
        }
        
        state["BO"].filter((x)=>x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.push(action.payload);
        state["BO"]
          .filter(x => x.SOCategoryID == action.payload.SOCategoryID)[0]
          .kpiData.sort((a, b) => (a.MetricSeq > b.MetricSeq ? 1 : -1));
        return state;
      }
    }
    case "DELETE_BO_MASTER": {
      state = { ...state };
      state["BO"].filter(
        x => x.SOCategoryID == action.payload.SOCategoryID
      )[0].kpiData = state["BO"]
        .filter(x => x.SOCategoryID == action.payload.SOCategoryID)[0]
        .kpiData.filter(
          data => data.KPIMappingID != action.payload.kpiData.KPIMappingID
        );
      return state;
    }
    case "DELETE_BO_CATEGORY_MASTER": { 
      state = { ...state };
      var a = state["BO"].filter(x => x.SOCategoryName !== action.payload);
      state["BO"] = state["BO"].filter(
        x => x.SOCategoryName !== action.payload
      );
      return state;
    }


    case "INITIALISE_ATTRIBUTE_LABELSMTC":{
        state = { ...state };
        state["attributeLabels"] = action.payload;
        return state;
    }
    case "SAVE_STANDARD_UNITS":{
      state = { ...state };
      state["unitsData"] = action.payload;
      return state;
  }

    default:
      return state;
  }
};
