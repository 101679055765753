import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import i18n from "i18next";
import _ from "lodash";
import classNames from "classnames";
import ReactTooltip from 'react-tooltip'

const InitiativeDetails = ({
  initiativesSequenceData,
  formMode,
  values,
  handleChange,
  handleBlur,
  errors,
  debouncedValidateField,
  setTouched,
  handleSelectDropdownOption,
  handleBlurFormField,
  getOptionObjFromDropdownValue,
  initiativeCategoryDropdownData,
  journeyStageDropdownData,
  initiativeImpactDropdownData,
  initiativeSourceDropdownData,
  initiativeStatusDropdownData,
  assessmentCriteriaDropdownData,
  synopsLeverDropdownData,
  rejectOnHoldCancelledReasonProps,
  isInitativeManagement,
  customBlur
}) => {

  //////////////////////////////    STATES   //////////////////////////////////////////////

  // MAINTAINS THE LIST OF "MULTI-SELECTS" IN THE FORM WITH THE CORRESPONDING "FORMIK" FIELD NAMES
  const [formMultiSelectsProps, setFormMultiSelectsProps] = useState({
    initiativeImpacts: {
      isVisible: false,
      tempSelectedValues: values.initiativeImpacts,
      selectedValues: values.initiativeImpacts,
    },
    initiativeAssessments: {
      isVisible: false,
      tempSelectedValues: values.initiativeAssessments,
      selectedValues: values.initiativeAssessments,
    },
  });
  const[allInitiativeSequences,setallInitiativeSequences]=useState([]);

  const isAdd=(formMode==="add");
  //////////////////////////////    EFFECTS   //////////////////////////////////////////////

  // WHEN THE FORMIK FIELD VALUES OF THE MULTI-SELECT FIELDS WOULD CHANGE IN THE PARENT COMPONENT, THEN UPDATE THE CORRESPONDING VALUES IN `formMultiSelectsProps`
  
  useEffect(() => {
    setallInitiativeSequences(initiativesSequenceData);
  },[initiativesSequenceData]);
  
  useEffect(() => {
    setFormMultiSelectsProps((prev) => {
      const modified = _.cloneDeep(prev);
      _.set(modified, "initiativeImpacts.selectedValues", values.initiativeImpacts);
      _.set(modified, "initiativeImpacts.tempSelectedValues", values.initiativeImpacts);

      _.set(modified, "initiativeAssessments.selectedValues", values.initiativeAssessments);
      _.set(modified, "initiativeAssessments.tempSelectedValues", values.initiativeAssessments);
      return modified;
    });
  }, [values.initiativeImpacts, values.initiativeAssessments]);

  //////////////////////////////    EVENT HANDLERS   //////////////////////////////////////////////

  // EVENT HANDLER FOR THE SHOW/HIDE OF MULTI-SELECT POPOVER
  function handleClickMultiSelect(fieldName) {
    if(!_.isEmpty(values.journeyStageId) || fieldName === "initiativeImpacts"){
    setFormMultiSelectsProps((prev) => {
      const fieldPrevProps = prev[fieldName];
      const modified = _.cloneDeep(prev);
      _.set(modified, `${fieldName}.isVisible`, !fieldPrevProps.isVisible);
      if (!fieldPrevProps.isVisible) {
        _.set(modified, `${fieldName}.tempSelectedValues`, fieldPrevProps.selectedValues);
      }
      return modified;
    });
  }
  }
  // EXECUTES WHEN THE MULTI-SELECT OPTION IS CLICKED
  function handleClickMultiSelectOption(e, fieldName, optionValue) {
    const clickedElementId = _.get(e, "target.id", "");

    // PUTTING THIS CHECK FOR VALIDATING THE EMPTY ID OF THE ELEMENT WOULD PREVENT THE STATE BEING UPDATED FROM THE CLICK EVENT THAT IS TRIGGERED FROM OTHER ELEMENTS EXCEPT THE `<li>` AND `checkbox`
    if (!_.isEmpty(clickedElementId)) {
      let selectedValues = [];
      setFormMultiSelectsProps((prev) => {
        const fieldPrevProps = prev[fieldName];
        const clonedTempSelectedValues = fieldPrevProps.tempSelectedValuesm ? _.cloneDeep(fieldPrevProps.tempSelectedValues): [];
        if (_.indexOf(clonedTempSelectedValues, optionValue) !== -1) {
          _.pull(clonedTempSelectedValues, optionValue);
        } else {
          clonedTempSelectedValues.push(optionValue);
        }

        const modified = _.cloneDeep(prev);
        _.set(modified, `${fieldName}.tempSelectedValues`, clonedTempSelectedValues);
        selectedValues = clonedTempSelectedValues;
        handleSelectDropdownOption(fieldName, selectedValues);
        // _.set(modified, `${fieldName}.isVisible`, false);
        return modified;
      });
    }
  }

  // EXECUTES WHEN THE "SELECT ALL" OPTION IN THE MULTI-SELECT IS CLICKED
  function handleClickSelectAllMultiSelectOptions(e, fieldName, dropdownData) {
    const clickedElementId = _.get(e, "target.id", "");
    let selectedValues = [];

    // PUTTING THIS CHECK FOR VALIDATING THE EMPTY ID OF THE ELEMENT WOULD PREVENT THE STATE BEING UPDATED FROM THE CLICK EVENT THAT IS TRIGGERED FROM OTHER ELEMENTS EXCEPT THE `<li>` AND `checkbox`
    if (!_.isEmpty(clickedElementId)) {
      const allDropdownValues = _.map(dropdownData, (eachItem) => eachItem.value);

      setFormMultiSelectsProps((prev) => {
        const fieldPrevProps = prev[fieldName];
        
        
        let newTempSelectedValues = [];
        if (_.size(fieldPrevProps.tempSelectedValues) !== _.size(allDropdownValues)) {
          newTempSelectedValues = allDropdownValues;
        }

        const modified = _.cloneDeep(prev);
        _.set(modified, `${fieldName}.tempSelectedValues`, newTempSelectedValues);
        selectedValues = newTempSelectedValues;
        handleSelectDropdownOption(fieldName, selectedValues);       
        return modified;
      });
    }
  }

  

 

  // BELOW BLOCK OF CODE IS FOR "Initiative Impact" MULTI SELECT
  const isVisibleInitiativeImpactsMultiSelect =
    !_.isEmpty(initiativeImpactDropdownData) && _.get(formMultiSelectsProps, "initiativeImpacts.isVisible");
  const tempSelectedValuesInitiativeImpactsMultiSelect = _.get(
    formMultiSelectsProps,
    "initiativeImpacts.tempSelectedValues"
  );
  const selectedValuesInitiativeImpactsMultiSelect = _.get(formMultiSelectsProps, "initiativeImpacts.selectedValues");

  const areAllSelectedInitiativeImpactsMultiSelect =
    !_.isEmpty(initiativeImpactDropdownData) &&
    _.size(selectedValuesInitiativeImpactsMultiSelect) === _.size(initiativeImpactDropdownData);

  let displayLabelFromSelectedValuesInitiativeImpactsMultiSelect = !_.isEmpty(initiativeImpactDropdownData)
    ? i18n.t("Initiative_Impact")
    : i18n.t("No_Data_Is_Available");
  // WHEN ALL OPTIONS ARE SELECTED
  if (areAllSelectedInitiativeImpactsMultiSelect) {
    displayLabelFromSelectedValuesInitiativeImpactsMultiSelect = i18n.t("All_Selected");
  } else if (!_.isEmpty(selectedValuesInitiativeImpactsMultiSelect)) {
    // WHEN SOME OPTIONS ARE SELECTED
    let selectedLabelsArray = [];
    _.forEach(initiativeImpactDropdownData, (eachItem) => {
      if (_.indexOf(selectedValuesInitiativeImpactsMultiSelect, eachItem.value) !== -1) {
        selectedLabelsArray.push(eachItem.label);
      }
    });

    displayLabelFromSelectedValuesInitiativeImpactsMultiSelect = _.join(selectedLabelsArray, ", ");
  }
  ///////////////////////////////////////////////////////////////////////////////////////////

  // BELOW BLOCK OF CODE IS FOR "Assessment Criteria" MULTI SELECT
  const isVisibleInitiativeAssessmentsMultiSelect =
    !_.isEmpty(assessmentCriteriaDropdownData) && _.get(formMultiSelectsProps, "initiativeAssessments.isVisible");
  const tempSelectedValuesInitiativeAssessmentsMultiSelect = _.get(
    formMultiSelectsProps,
    "initiativeAssessments.tempSelectedValues"
  );
  const selectedValuesInitiativeAssessmentsMultiSelect = _.get(
    formMultiSelectsProps,
    "initiativeAssessments.selectedValues"
  );

  const areAllSelectedInitiativeAssessmentsMultiSelect =
    !_.isEmpty(assessmentCriteriaDropdownData) &&
    _.size(selectedValuesInitiativeAssessmentsMultiSelect) === _.size(assessmentCriteriaDropdownData);

  let displayLabelFromSelectedValuesInitiativeAssessmentsMultiSelect = (!_.isEmpty(assessmentCriteriaDropdownData)
  ||(isInitativeManagement && _.isEmpty(assessmentCriteriaDropdownData))) ? i18n.t("Assessment_Criteria")
    : i18n.t("No_Data_Is_Available");
  // WHEN ALL OPTIONS ARE SELECTED
  if (areAllSelectedInitiativeAssessmentsMultiSelect) {
    displayLabelFromSelectedValuesInitiativeAssessmentsMultiSelect = i18n.t("All_Selected");
  } else if (!_.isEmpty(selectedValuesInitiativeAssessmentsMultiSelect)) {
    // WHEN SOME OPTIONS ARE SELECTED
    let selectedLabelsArray = [];
    _.forEach(assessmentCriteriaDropdownData, (eachItem) => {
      if (_.indexOf(selectedValuesInitiativeAssessmentsMultiSelect, eachItem.value) !== -1) {
        selectedLabelsArray.push(eachItem.label);
      }
    });

    const limitNoOfLabelsForDisplay = 2;
    const selectedLabelsArrayLength = _.size(selectedLabelsArray);
    if (selectedLabelsArrayLength > limitNoOfLabelsForDisplay) {
      const firstChunkOfSelectedLabels = _.chunk(selectedLabelsArray, limitNoOfLabelsForDisplay)[0];
      displayLabelFromSelectedValuesInitiativeAssessmentsMultiSelect = `${_.join(
        firstChunkOfSelectedLabels,
        ", "
      )} +${selectedLabelsArrayLength - limitNoOfLabelsForDisplay}`;
    } else {
      displayLabelFromSelectedValuesInitiativeAssessmentsMultiSelect = _.join(selectedLabelsArray, ", ");
    }
  }
  ///////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="initiative-formtabcontent">
      <div className="initiative-formfields">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="initiativeCategoryId" className="form-label">
                  {i18n.t("Initiative_Category")}
                  {/* <span className="required">&nbsp;*</span> */}
                </label>
                <div className="initiative-dropdown">
                  <Dropdown
                    id="initiativeCategoryId"
                    name="initiativeCategoryId"
                    onSelect={(selectedOptionEventKey) =>
                      handleSelectDropdownOption("initiativeCategoryId", selectedOptionEventKey)
                    }
                    onBlur={handleBlur}
                  >
                    <Dropdown.Toggle id="synopsLeverIdToggle">
                      {_.isEmpty(values.initiativeCategoryId)
                        ? i18n.t("Initiative_Category")
                        : _.get(
                            getOptionObjFromDropdownValue(initiativeCategoryDropdownData, values.initiativeCategoryId),
                            "label",
                            ""
                          )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {_.map(initiativeCategoryDropdownData, (eachItem) => (
                        <Dropdown.Item
                          eventKey={eachItem.value}
                          active={values.initiativeCategoryId === eachItem.value}
                          // key={eachItem.value} // COMMENTED OUT THE `key` ATTRIBUTE AS IT IS CAUSING SOME CACHING ISSUES IN THE DROPDOWN COMPONENT THAT LEADS TO BUGS
                        >
                          {eachItem.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            {errors.initiativeCategoryId && <div className="errorMsg">{errors.initiativeCategoryId}</div>}
          </div>

          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="rag" className="form-label">
                  {i18n.t("RAG")}
                  <span className="required">&nbsp;*&nbsp;</span>    
                </label>
                <span><i data-tip data-for="info-icon" data-multiline="true" class="fas fa-info-circle fa-sm"></i></span>
                <div className="initiative-dropdown">
                  <Dropdown
                    id="rag"
                    name="rag"
                    onSelect={(selectedOptionEventKey) => handleSelectDropdownOption("rag", selectedOptionEventKey)}
                    onBlur={handleBlur}
                  >
                    <Dropdown.Toggle id="ragToggle">
                      {_.isEmpty(values.rag) ? i18n.t("RAG") : values.rag}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="Amber" active={values.rag === "Amber"}>
                        {i18n.t("Amber")}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Grey" active={values.rag === "Grey"}>
                        {i18n.t("Grey")}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Green" active={values.rag === "Green"}>
                        {i18n.t("Green")}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Red" active={values.rag === "Red"}>
                        {i18n.t("Red")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <ReactTooltip id="info-icon" type="dark" effect="solid" place="top">
                  <span>{i18n.t("Red")}: {i18n.t("Delayed")}<br />{i18n.t("Amber")}: {i18n.t("At_Risk")}<br />{i18n.t("Green")}: {i18n.t("On_Track")}<br />{i18n.t("Grey")}: {i18n.t("Not_Time_bound")}</span>
                   </ReactTooltip> 
              </div>
              {errors.rag && <div className="errorMsg">{errors.rag}</div>}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            {/* ini name */}
              <div className="form-section">
              <div className={classNames("form-group", "border-group", { disabled: formMode === "edit" })}>
                <label htmlFor="initiativeName" className="form-label">
                  {i18n.t("Initiative_Name")}
                  <span className="required">&nbsp;*</span>
                </label>
                <input
                  type="text"
                  id="initiativeName"
                  name="initiativeName"
                  value={values.initiativeName}
                  onChange={async (e) => {
                    await handleChange(e);
                    await setTouched({ initiativeName: true }, false);
                    debouncedValidateField("initiativeName");
                  }}
                  //onBlur={customBlur}
                  onBlur={handleBlur}
                  className="form-control"
                  placeholder={i18n.t("Initiative_Name")}
                  maxLength="50"
                  readOnly={formMode === "edit"}
                  autoComplete="off"
                />
              </div>
            </div>
            {errors.initiativeName && <div className="errorMsg">{errors.initiativeName}</div>}
            
           {/* ini desc */}            
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="initiativeDescription" className="form-label">
                  {i18n.t("Initiative_Description")}
                  <span className="required">&nbsp;*</span>
                </label>
                <textarea
                  id="initiativeDescription"
                  name="initiativeDescription"
                  onChange={handleChange}
                  onBlur={handleBlurFormField}
                  rows="4"
                  className="form-control"
                  placeholder={i18n.t("Initiative_Description")}
                  maxLength="500"
                  value={values.initiativeDescription}
                />
              </div>
            </div>
            {errors.initiativeDescription && <div className="errorMsg">{errors.initiativeDescription}</div>}                   
          </div>
          
          <div className="col-sm-6">
            {/* rag reason */}
            
              <div className="form-section">
                <div className="form-group border-group">
                  <label htmlFor="ragReason" className="form-label">
                    {i18n.t("Reason For RAG")}
                    {/* required if rag red or amber */}
                  {(values.rag === "Red" || values.rag==="Amber" ) && <span className="required">&nbsp;*</span> } 
                  </label>
                  <textarea
                    id="ragReason"
                    name="ragReason"
                    onChange={handleChange}
                    onBlur={handleBlurFormField}
                    rows="4"
                    className="form-control"
                    placeholder={i18n.t("Reason For RAG")}
                    maxLength="200"
                    value={values.ragReason}
                  />
                </div>
              </div>
              {errors.ragReason && <div className="errorMsg">{errors.ragReason}</div>}
            

            {/* ini status */}
              <div className="form-section">
                <div className="form-group border-group">
                  <label htmlFor="initiativeStatusID" className="form-label">
                    {i18n.t("Initiative_Status")}
                    <span className="required">&nbsp;*</span>
                  </label>
                  <div className="initiative-dropdown">
                    <Dropdown
                      id="initiativeStatusID"
                      name="initiativeStatusID"
                      onSelect={(selectedOptionEventKey) =>
                        handleSelectDropdownOption("initiativeStatusID", selectedOptionEventKey)
                      }
                      onBlur={handleBlur}
                    >
                      <Dropdown.Toggle id="initiativeStatusIDToggle">
                        {_.isEmpty(values.initiativeStatusID)
                          ? i18n.t("Initiative_Status")
                          : _.get(
                              getOptionObjFromDropdownValue(initiativeStatusDropdownData, values.initiativeStatusID),
                              "label",
                              ""
                            )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {_.map(initiativeStatusDropdownData, (eachItem) => (
                          <Dropdown.Item
                            eventKey={eachItem.value}
                            active={values.initiativeStatusID === eachItem.value}
                            // key={eachItem.value} // COMMENTED OUT THE `key` ATTRIBUTE AS IT IS CAUSING SOME CACHING ISSUES IN THE DROPDOWN COMPONENT THAT LEADS TO BUGS
                          >
                            {eachItem.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {errors.initiativeStatusID && <div className="errorMsg">{errors.initiativeStatusID}</div>}
              </div>
            
          </div>

        
          
        </div>

        <div className="row">
          {/* impact */}
          <div className="col-sm-6">
              <div className="form-section">
                <div className="form-group border-group">
                  <label htmlFor="initiativeImpacts" className="form-label">
                    {i18n.t("Initiative_Impact")}
                    <span className="required">&nbsp;*</span>
                  </label>
                  <div className="initiative-dropdown" onClick={() => handleClickMultiSelect("initiativeImpacts")}>
                    <span className="initiative-dropdownvalue">
                      {displayLabelFromSelectedValuesInitiativeImpactsMultiSelect}
                    </span>

                    {isVisibleInitiativeImpactsMultiSelect && (
                      <div className="initiativeheading-dropdownlist criteriastatus-list">
                        <ul>
                          <li
                            id="initiativeImpacts_selectAll_li"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickSelectAllMultiSelectOptions(
                                e,
                                "initiativeImpacts",
                                initiativeImpactDropdownData
                              );
                            }}
                            className={classNames({
                              active:
                                _.size(tempSelectedValuesInitiativeImpactsMultiSelect) ===
                                _.size(initiativeImpactDropdownData),
                            })}
                          >
                            <label className="form-check">
                              <input
                                id="initiativeImpacts_selectAll_checkbox"
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  _.size(tempSelectedValuesInitiativeImpactsMultiSelect) ===
                                  _.size(initiativeImpactDropdownData)
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleClickSelectAllMultiSelectOptions(
                                    e,
                                    "initiativeImpacts",
                                    initiativeImpactDropdownData
                                  );
                                }}
                              />
                              <span className="checkmark"></span>
                              <span className="form-check-label">{i18n.t("Select_All")}</span>
                            </label>
                          </li>

                          {_.map(initiativeImpactDropdownData, (eachItem) => {
                            const optionValue = eachItem.value;
                            const isSelected =
                              _.indexOf(tempSelectedValuesInitiativeImpactsMultiSelect, optionValue) !== -1;
                            const idPrefixText = `initiativeImpacts_${optionValue}`;

                            return (
                              <li
                                id={`${idPrefixText}_li`}

                                key={optionValue}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleClickMultiSelectOption(e, "initiativeImpacts", optionValue);
                                }}
                                className={classNames({
                                  active: isSelected,
                                })}
                              >

                                <label className="form-check">
                                  <input
                                    id={`${idPrefixText}_checkbox`}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={isSelected}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickMultiSelectOption(e, "initiativeImpacts", optionValue);
                                    }}
                                  />
                                  <span className="checkmark"></span>
                                  <span className="form-check-label">{eachItem.label}</span>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                  {isVisibleInitiativeImpactsMultiSelect && (
                    <div
                      className="initiative-dropdown-overlay"
                      onClick={() => handleClickMultiSelect("initiativeImpacts")}
                    />
                  )}
                </div>
              </div>
              {errors.initiativeImpacts && <div className="errorMsg">{errors.initiativeImpacts}</div>}
            </div>

          {/* source */}
          <div className="col-sm-6">
            <div className="form-section">
              {/* Would be visible and required when initiativeStatus =  "On Hold" OR "Rejected" OR "Cancelled" */}
              {rejectOnHoldCancelledReasonProps.isRequired && (
                <>
                  <div className="form-group border-group">
                    <label htmlFor="rejectOnHoldCancelledReason" className="form-label">
                      {i18n.t("Reject_On_Hold_Cancelled_Reason")}
                      {rejectOnHoldCancelledReasonProps.isRequired && <span className="required">&nbsp;*</span>}
                    </label>
                    <input
                      type="text"
                      id="rejectOnHoldCancelledReason"
                      name="rejectOnHoldCancelledReason"
                      value={values.rejectOnHoldCancelledReason}
                      onChange={handleChange}
                      onBlur={handleBlurFormField}
                      className="form-control"
                      placeholder={i18n.t("Reject_On_Hold_Cancelled_Reason")}
                      maxLength="150"
                    />
                  </div>
                  {errors.rejectOnHoldCancelledReason && (
                    <div className="errorMsg">{errors.rejectOnHoldCancelledReason}</div>
                  )}
                </>
              )}

              <div className="form-group border-group">
                <label htmlFor="initiativeSourceId" className="form-label">
                  {i18n.t("Initiative_Source")}
                </label>
                <div className="initiative-dropdown">
                  <Dropdown
                    id="initiativeSourceId"
                    name="initiativeSourceId"
                    onSelect={(selectedOptionEventKey) =>
                      handleSelectDropdownOption("initiativeSourceId", selectedOptionEventKey)
                    }
                    onBlur={handleBlur}
                  >
                    <Dropdown.Toggle id="initiativeSourceIdToggle">
                      {_.isEmpty(values.initiativeSourceId)
                        ? i18n.t("Initiative_Source")
                        : _.get(
                            getOptionObjFromDropdownValue(initiativeSourceDropdownData, values.initiativeSourceId),
                            "label",
                            ""
                          )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {_.map(initiativeSourceDropdownData, (eachItem) => (
                        <Dropdown.Item
                          eventKey={eachItem.value}
                          active={values.initiativeSourceId === eachItem.value}
                          // key={eachItem.value} // COMMENTED OUT THE `key` ATTRIBUTE AS IT IS CAUSING SOME CACHING ISSUES IN THE DROPDOWN COMPONENT THAT LEADS TO BUGS
                        >
                          {eachItem.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {errors.initiativeSourceId && <div className="errorMsg">{errors.initiativeSourceId}</div>}
            </div>
          </div>
        </div>

        <div className="row">
          {/* Sequence */}
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="sequence" className="form-label">
                  {i18n.t("Display_Sequence")}
                  <span className="required">&nbsp;*</span>
                </label>
                <div className="initiative-dropdown">
                <Dropdown
                    id="sequenceStatus" 
                    name="sequenceStatus"
                    onSelect={(selectedOptionEventKey) =>
                      handleSelectDropdownOption("sequence", selectedOptionEventKey)
                    }
                    onBlur={handleBlur}
                    //show={false} // dropdown to be in readOnly mode
                  >
                    <Dropdown.Toggle id="sequenceIdToggle">
                      {!_.isEmpty(values.sequence) && !_.isEmpty(allInitiativeSequences)
                        ? _.get(
                            getOptionObjFromDropdownValue(allInitiativeSequences,values.sequence),
                            "label",
                            ""
                          )
                        : i18n.t("Display_Sequence")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {_.map(allInitiativeSequences, (eachItem) => (
                        <Dropdown.Item
                          eventKey={eachItem.value}
                          active={values.sequence === eachItem.value}                         
                        >
                          {eachItem.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* <div className="initiative-dropdown">
                  <input
                    type="text"
                    id="sequence"
                    name="sequence"
                    value={values.sequence}
                    onChange={handleChange}
                    onBlur={handleBlurFormField}
                    className="form-control"
                    placeholder={i18n.t("Display_Sequence")}
                    maxLength="6"
                  />
                </div> */}
              </div>
            </div>
            {errors.sequence && <div className="errorMsg">{errors.sequence}</div>}
          </div>
          {/* Creteria */}
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="initiativeAssessments" className="form-label">
                  {i18n.t("Assessment_Criteria")}
                </label>
                <div className="initiative-dropdown" onClick={() => handleClickMultiSelect("initiativeAssessments")}>
                  <span className="initiative-dropdownvalue">
                    {displayLabelFromSelectedValuesInitiativeAssessmentsMultiSelect}
                  </span>

                  {isVisibleInitiativeAssessmentsMultiSelect && (
                    <div className="initiativeheading-dropdownlist criteriastatus-list">
                      <ul>
                        <li
                          id="initiativeAssessments_selectAll_li"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClickSelectAllMultiSelectOptions(
                              e,
                              "initiativeAssessments",
                              assessmentCriteriaDropdownData
                            );
                          }}
                          className={classNames({
                            active:
                              _.size(tempSelectedValuesInitiativeAssessmentsMultiSelect) ===
                              _.size(assessmentCriteriaDropdownData),
                          })}
                        >
                          <label className="form-check">
                            <input
                              id="initiativeAssessments_selectAll_checkbox"
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                _.size(tempSelectedValuesInitiativeAssessmentsMultiSelect) ===
                                _.size(assessmentCriteriaDropdownData)
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                handleClickSelectAllMultiSelectOptions(
                                  e,
                                  "initiativeAssessments",
                                  assessmentCriteriaDropdownData
                                );
                              }}
                            />
                            <span className="checkmark"></span>
                            <span className="form-check-label">{i18n.t("Select_All")}</span>
                          </label>
                        </li>

                        {_.map(assessmentCriteriaDropdownData, (eachItem) => {
                          const optionValue = eachItem.value;
                          const isSelected =
                            _.indexOf(tempSelectedValuesInitiativeAssessmentsMultiSelect, optionValue) !== -1;
                          const idPrefixText = `initiativeAssessments_${optionValue}`;

                          return (
                            <li
                              id={`${idPrefixText}_li`}
                              key={optionValue}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleClickMultiSelectOption(e, "initiativeAssessments", optionValue);
                              }}
                              className={classNames({
                                active: isSelected,
                              })}
                            >
                              <label className="form-check">
                                <input
                                  id={`${idPrefixText}_checkbox`}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={isSelected}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickMultiSelectOption(e, "initiativeAssessments", optionValue);
                                  }}
                                />
                                <span className="checkmark"></span>
                                <span className="form-check-label">{eachItem.label}</span>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
                {isVisibleInitiativeAssessmentsMultiSelect && (
                  <div
                    className="initiative-dropdown-overlay"
                    onClick={() => handleClickMultiSelect("initiativeAssessments")}
                  />
                )}
              </div>
            </div>
            {errors.initiativeAssessments && <div className="errorMsg">{errors.initiativeAssessments}</div>}
          </div>
        </div>

        <div className="row">
         {/* Journey Stage */}
         <div className="col-sm-6">
            <div className="form-section">
              <div className={classNames("form-group", "border-group", isInitativeManagement && isAdd?"":"disabled")}>
                {console.log('js',isAdd,isInitativeManagement)}
                <label htmlFor="journeyStageId" className="form-label">
                  {i18n.t("Journey_Stage")}
                  <span className="required">&nbsp;*</span>
                </label>
                <div className="initiative-dropdown">
                  <Dropdown
                    id="journeyStageId"
                    name="journeyStageId"
                    onSelect={(selectedOptionEventKey) =>
                      handleSelectDropdownOption("journeyStageId", selectedOptionEventKey)
                    }
                    onBlur={handleBlur}
                    //show={false} // dropdown to be in readOnly mode
                  >
                    <Dropdown.Toggle id="journeyStageIdToggle">
                      {!_.isEmpty(values.journeyStageId) && !_.isEmpty(journeyStageDropdownData)
                        ? _.get(
                            getOptionObjFromDropdownValue(journeyStageDropdownData, values.journeyStageId),
                            "label",
                            ""
                          )
                        : 
                        i18n.t("Foundation")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {_.map(journeyStageDropdownData, (eachItem) => (
                        <Dropdown.Item
                          eventKey={eachItem.value}
                          active={values.journeyStageId === eachItem.value}                         
                        >
                          {eachItem.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            {errors.journeyStageId && <div className="errorMsg">{errors.journeyStageId}</div>}
          </div>
           {/* Owner */}
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="initiativeOwner" className="form-label">
                  {i18n.t("Initiative_Owner")}
                  <span className="required">&nbsp;*</span>
                </label>
                <input
                  type="text"
                  id="initiativeOwner"
                  name="initiativeOwner"
                  value={values.initiativeOwner}
                  onChange={handleChange}
                  onBlur={handleBlurFormField}
                  className="form-control"
                  placeholder={i18n.t("Initiative_Owner")}
                  maxLength="100"
                />
                <span className="suffix-text">@accenture.com</span>
              </div>
            </div>
            {errors.initiativeOwner && <div className="errorMsg">{errors.initiativeOwner}</div>}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="synopsLeverId" className="form-label">
                  {i18n.t("SynOps_Lever")}
                  <span className="required">&nbsp;*</span>
                </label>
                <div className="initiative-dropdown">
                  <Dropdown
                    id="synopsLeverId"
                    name="synopsLeverId"
                    onSelect={(selectedOptionEventKey) =>
                      handleSelectDropdownOption("synopsLeverId", selectedOptionEventKey)
                    }
                    onBlur={handleBlur}
                  >
                    <Dropdown.Toggle id="synopsLeverIdToggle">
                      {_.isEmpty(values.synopsLeverId)
                        ? i18n.t("SynOps_Lever")
                        : _.get(
                            getOptionObjFromDropdownValue(synopsLeverDropdownData, values.synopsLeverId),
                            "label",
                            ""
                          )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {_.map(synopsLeverDropdownData, (eachItem) => (
                        <Dropdown.Item
                          eventKey={eachItem.value}
                          active={values.synopsLeverId === eachItem.value}           
                        >
                          {eachItem.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            {errors.synopsLeverId && <div className="errorMsg">{errors.synopsLeverId}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitiativeDetails;
