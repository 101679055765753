import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useMsal } from "@azure/msal-react";
import { TRACKING_MODULES, userTracker } from '../Shared/Common';
import DatabaseLock from '../Images/database-lock.svg';
import { store } from "../reducers/configureStore";
import "../../src/CSS/privacynotice.css";
import axiosInstance from "../Shared/interceptor";
import { LocalApiBaseUrl } from "../Shared/Constant";

function PrivacyModal({userLoginData}) {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const {instance}=useMsal();

  const logOut=()=>{
    console.log("USER DATA PROPS:",userLoginData);
     console.log("Instances:",instance);
     instance.logoutRedirect({
       account: instance.getAccountByHomeId(userLoginData.account.homeAccountId)
   });
   userTracker(TRACKING_MODULES.EXPIRED_SESSION, false, null, null);
  }

  useEffect(() => {
    let userId = 0;
    if (
      store
        .getState()
        .ClientAndFilterReducer.ClientData.find(
          (data) => data.UserName === sessionStorage.getItem("EnterpriseID")
        )
    ) {
      userId = store
        .getState()
        .ClientAndFilterReducer.ClientData.find(
          (data) => data.UserName === sessionStorage.getItem("EnterpriseID")
        ).UserID;
    }

    if (userId !== 0) {
      axiosInstance
        .get(`${LocalApiBaseUrl}User/GetUserPrivacyConsent?userID=${userId}`)
        .then((response) => {
          if (response.data === false) {
            setShowPrivacyModal(true);
          } else {
            setShowPrivacyModal(false);
          }
        });
    }
  }, [store.getState().ClientAndFilterReducer.ClientData]);

  const handleConsent = () => {
    let userId = 0;
    if (
      store
        .getState()
        .ClientAndFilterReducer.ClientData.find(
          (data) => data.UserName === sessionStorage.getItem("EnterpriseID")
        )
    ) {
      userId = store
        .getState()
        .ClientAndFilterReducer.ClientData.find(
          (data) => data.UserName === sessionStorage.getItem("EnterpriseID")
        ).UserID;
    }

    const payload = {
      userID: userId,
      userName: sessionStorage.getItem("EnterpriseID"),
      userConsent: true,
    };

    if (userId !== 0) {
      axiosInstance
        .post(`${LocalApiBaseUrl}User/AddUserPrivacyConsent`, payload)
        .then(() => {
          setShowPrivacyModal(false);
        });
    }
  }

  return (
    <Modal
    show={showPrivacyModal}
    className="privacy-notice-popup"
    backdropClassName="privacy-notice-popup-backdrop"
    backdrop="static"
    centered
  >
    <Modal.Header>
      <img src={DatabaseLock} alt="database-lock" />
      <div />
      <h6>Privacy Note</h6>
    </Modal.Header>
    <Modal.Body>
      <div className="privacy-notice-popup-content">
        Please be aware that any personal data that you and others provide
        through this tool may be processed by Accenture. <br />
        <br /> The protection of your personal data is very important to
        Accenture. Accenture is committed to keeping your personal data
        secure, and processing it in accordance with, applicable data
        protection laws and our internal policies, including{" "}
        <a
          href="https://policies.accenture.com/policy/0090?rd=1&Country=United%20Kingdom"
          target="_blank"
          rel="noreferrer"
        >
          Accenture&apos;s Global Data Privacy Policy 0090
        </a>
        . <br />
        <br /> Your decision to provide your personal data to Accenture is
        voluntary. However, given that this tool requires personal data to
        function, you will not be able to use this tool if you do not provide
        your personal data. <br /> Before providing any personal data through
        this tool, Accenture invites you to carefully read its{" "}
        <a
          href="https://in.accenture.com/protectingaccenture/data-security/5422-2/"
          target="_blank"
          rel="noreferrer"
        >
          privacy statement
        </a>
        , which includes important information on why and how Accenture is
        processing your personal data. <br />
        <br /> This system is the property of Accenture, and is to be used in
        accordance with applicable Accenture Policies. Unauthorized access or
        activity is a violation of Accenture Policies and may be a violation
        of law. Use of this system constitutes consent to monitoring for
        unauthorized use, in accordance with Accenture Policies, local laws,
        and regulations. Unauthorized use may result in penalties including,
        but not limited to, reprimand, dismissal, financial penalties, and
        legal action. <br />
        <br /> Do you wish to continue?
      </div>
      <div className="privacy-notice-popup-buttons">
        <button
          type="button"
          onClick={() => {
            handleConsent();
          }}
        >
          I Consent
        </button>
        <button
          type="button"
          onClick={() => {
            logOut();
          }}
        >
          Cancel
        </button>
      </div>
    </Modal.Body>
  </Modal>
  );
}

const mapStateToProps = (state) => ({
  userLoginData:{...state.userLoginDataReducer}
});

export default 
  connect(
    mapStateToProps,
)(PrivacyModal);