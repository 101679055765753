import React, { Component } from "react";
import _ from "lodash";
import DatePicker from "react-datepicker";
import Chart from "chart.js";
import "chartjs-plugin-datalabels";
import "chartjs-plugin-annotation";
import ReactTooltip from "react-tooltip";
import { Button, Overlay, Popover } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import Moment from "moment";
import i18n from "i18next";
import $ from "jquery";
import SynopsLoader from "../Shared/SynopsLoader";
import { LocalApiBaseUrl } from "../Shared/Constant";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


const Loader = () => <>Loading...</>;
var randomItem = ["BlueViolet", "#FFD700", "DarkTurquoise", "DodgerBlue", "DarkOrange"];

function formatDate(momentObj) {
  const dateFormat = "MMM YYYY";
  return momentObj.format(dateFormat);
}
var minDate = new Date();
if (new Date().getMonth() === 11) {
  //special logic only if month is december. start date needs to be jan 2020

  let yearPart = new Date().getFullYear();
  let monthPart = 12 - new Date().getMonth() - 1; //12 -11 - 1 to show jan
  let dayPart = 1;

  minDate = new Date(yearPart.toString(), monthPart.toString(), dayPart.toString(), "00", "00", "00");
} else {
  minDate =
    new Date().getFullYear() -
    1 +
    "-" +
    (new Date().getMonth() + 2 < 10 ? "0" + (new Date().getMonth() + 2) : new Date().getMonth() + 2);
}
const allowedMinDate = new Date(minDate);

class JourneyTrendGraphsInsight extends Component {
  constructor(props) {
    super(props);
    const dateBeforeOneYear = Moment().subtract(12, "months");
    const defaultStartDateMoment1 = dateBeforeOneYear.isAfter(allowedMinDate) ? dateBeforeOneYear : allowedMinDate;
    const defaultEndDateMoment1 = Moment();

    const defaultStartDateMoment = Moment(defaultStartDateMoment1);
    const defaultEndDateMoment = Moment(defaultEndDateMoment1);

    var todaysMonth = new Date();
    var min = todaysMonth.getFullYear() - 1 + "-" + todaysMonth.getMonth();
    this.state = {
     
      isApiLoading: false,
      temp: [],
      dsKpiData: [],
      dsKpiData2: [],
      labels: [],
     
      similarAvg: 0,
      maxMetricValue: 0,
      minMetrixValue: 0,
      OfferingName: "",
      EntityName: [],
      DealValue: [],
      value: 0,
      ProcessName: "",
      dealOnelabel: "",
      dealOnevalue: "",
      dealTwolabel: "",
      dealTwovalue: "",
      stoldDealOneValue: 0,
      stoldDealTwoValue: 0,
      percentageChangeDealOne: 0,
      percentageChangeDealTwo: 0,
      diffdeal1: 0,
      diffdeal2: 0,
      autoCompleteDdlClientsList: [],
      selValueDdl: "",
      dealTwoDataPoints: [],
      dealOneDataPoints: [],
      numMonths: 0,
      dealComparisonGraphData: {
        labels: [],
        datasets: [],
      },
      operatingGroup: null,
      IndustryGroup: null,
      clientRegion: null,
      deliveryLocation: null,
      maturityPhase: null,
      filter: { og: "All", cr: "All", dl: "All", mp: "All", io: "All" },
      startDate: "",
      endDate: "",
      period: { from: "", to: "" },
      wholedata: null,
      filtereddata: null,
      loading: false,
      ShowDeals: false,
      defaultDate: true,
      startDate: defaultStartDateMoment.toDate(),
      endDate: defaultEndDateMoment.toDate(),
      formattedStartDate: formatDate(defaultStartDateMoment),
      formattedEndDate: formatDate(defaultEndDateMoment),
      tempFormattedStartDate: "",
      tempFormattedEndDate: "",
      tempPreviousFormattedStartDate: formatDate(defaultStartDateMoment),
      tempPreviousFormattedEndDate: formatDate(defaultEndDateMoment),
      isStartEndDateSelected: true,
      tempStartDate: defaultStartDateMoment.toDate(),
      tempEndDate: defaultEndDateMoment.toDate(),
      formattedTempStartDate: formatDate(defaultStartDateMoment),
      formattedTempEndDate: formatDate(defaultEndDateMoment),
      isVisibleDatepickerDropdownPopover: false,
      showCanvas: false,
      showErrorMsg: false,
    };

    this.datepickerDropdownPopoverTarget = React.createRef();
    this.insightMetricChart = React.createRef();
    this.startDatepickerRef = React.createRef();
    this.endDatepickerRef = React.createRef();
    this.getKpiData = this.getKpiData.bind(this);

  }

  // EVENT HANDLER FOR CALENDAR ICON IN DATEPICKER FIELDS, OPENS UP THE PICKER WHEN CLICKED
  handleUpdateDateClickField = (tempStart, tempEnd) => {
    let d1 = tempStart.getMonth();
    let d2 = tempEnd.getMonth();
    let d3 = tempStart.getFullYear() == tempEnd.getFullYear() ? 12 - d1 - (12 - d2) + 1 : 12 - d1 + d2 + 1;
    if (d3 > 2) {
      const formattedStartDate = !_.isNil(tempStart) ? formatDate(Moment(tempStart)) : "";
      const formattedEndDate = !_.isNil(tempEnd) ? formatDate(Moment(tempEnd)) : "";
      this.setState({
        startDate: tempStart,
        formattedStartDate: formattedStartDate,
        endDate: tempEnd,
        formattedEndDate: formattedEndDate,
        isVisibleDatepickerDropdownPopover: false,
      });
      const { currentView, tempPreviousFormattedStartDate, tempPreviousFormattedEndDate } = this.state;

      //IF EITHER THE START DATE OR END DATE HAS CHANGED FROM THE PREVIOUS VALUES
      // OR THEIR VALUES ARE EMPTY THEN VALIDATE THE VALUES
      // AND IF VALUES ARE FILLED THEN ONLY EXECUTE THE API CALL
      if (
        formattedStartDate !== null ||
        formattedEndDate !== null ||
        _.isEmpty(formattedStartDate) ||
        _.isEmpty(formattedEndDate)
      ) {
       
        this.setState(
          {
            tempPreviousFormattedStartDate: formattedStartDate,
            tempPreviousFormattedEndDate: formattedEndDate,
          },
          () => {
            setTimeout(() => {
              let errMsg = "";
              if (_.isEmpty(formattedStartDate) && _.isEmpty(formattedEndDate)) {
                errMsg =
                  i18n.t("Both") +
                  ' "' +
                  i18n.t("Start_Month") +
                  '" ' +
                  i18n.t("and") +
                  ' "' +
                  i18n.t("End_Month") +
                  '" ' +
                  i18n.t("should_be_filled_to_retrieve_the_results");
              } else if (_.isEmpty(formattedStartDate) && !_.isEmpty(formattedEndDate)) {
                errMsg = '"' + i18n.t("Start_Month") + '" ' + i18n.t("should_be_filled_to_retrieve_the_results");
              } else if (!_.isEmpty(formattedStartDate) && _.isEmpty(formattedEndDate)) {
                errMsg = '"' + i18n.t("End_Month") + '" ' + i18n.t("should_be_filed_to_retrieve_the_results");
              }
              if (!_.isEmpty(errMsg)) {
                window.alert(errMsg);
              } else {
                this.state.defaultDate = false;
                this.getKpiData();
              }
            }, 50);
          }
        );
      }
    } else {
      alert(i18n.t("Please-_select_minimum_three_months_to_view_the_graph"));
    }
  };

  handleChangeDateField = (date, isStartEndDateSelected) => {
    this.setState({
      [isStartEndDateSelected ? "tempStartDate" : "tempEndDate"]: date,
      [isStartEndDateSelected ? "formattedTempStartDate" : "formattedTempEndDate"]: formatDate(Moment(date)),
    });
  };

  handleClearDate = () => {
    this.setState({
      tempStartDate: this.state.startDate,
      tempEndDate: this.state.endDate,
      formattedTempStartDate: this.state.formattedStartDate,
      formattedTempEndDate: this.state.formattedEndDate,
    });
  };

  // HIDES THE "DATE SELECTION DROPDOWN" WHEN CLICKED OUTSIDE THE POPOVER
  hideDatepickerDropdownPopover = () => {
    this.setState({
      isVisibleDatepickerDropdownPopover: false,
    });
  };

  toggleDatepickerDropdownPopover = () => {
    const { isVisibleDatepickerDropdownPopover } = this.state;
    this.setState({
      isVisibleDatepickerDropdownPopover: !isVisibleDatepickerDropdownPopover,
      isStartEndDateSelected: true,
    });
    if (!isVisibleDatepickerDropdownPopover) {
      this.setState({
        tempStartDate: this.state.startDate,
        tempEndDate: this.state.endDate,
        formattedTempStartDate: this.state.formattedStartDate,
        formattedTempEndDate: this.state.formattedEndDate,
      });
    }
  };

  // SELECTS "START DATE" OR "END DATE"
  selectStartDate = () => {
    const { isStartEndDateSelected } = this.state;
    this.setState({
      isStartEndDateSelected: true,
    });
  };
  selectEndDate = () => {
    const { isStartEndDateSelected } = this.state;
    this.setState({
      isStartEndDateSelected: false,
    });
  };

  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    this.getKpiData();
  }

  getKpiData() {
    let kpiMappingId = this.props.kpiMappingId;
    let updatedDate2 =
      new Date().getFullYear() -
      1 +
      "-" +
      (new Date().getMonth() < 10 ? "0" + (new Date().getMonth() + 2) : new Date().getMonth() + 2);
    let enddate = new Date(this.state.endDate);
    let startdate = new Date(this.state.startDate);
    let d1 = enddate.getFullYear() + "-" + (enddate.getMonth() + 1);
    let s1 = startdate.getFullYear() + "-" + (startdate.getMonth() + 1);
    let start1 =
      startdate.getFullYear() == enddate.getFullYear() && startdate.getMonth() == enddate.getMonth()
        ? updatedDate2
        : s1;
    this.setState({
      isApiLoading: true,
    });
  
    axiosInstance
      .get(`${LocalApiBaseUrl}Analytics/GetKpiDataForIITrendGraph`, {
        params: { kpiMappingID: kpiMappingId, StartDate: start1, endDate: d1 },
      })
      .then((res) => {
        if (res.data.length < 3 && this.state.defaultDate) {
          this.setState(
            {
              isApiLoading: false,
              showErrorMsg: true, 
              temp: res.data,
            },
            () => {
              
            }
          );
        } else if (res.data.length < 3) {
          alert(i18n.t("Enough_data_is_not_available,_please_try_with_some_other_date_range"));
          this.setState(
            {
              showCanvas: true,
              isApiLoading: false,
              temp: res.data,
              dsKpiData: {
                labels: [],
                datasets: [],
              },
            },
            () => {
              this.showInsightChart();
            }
          );
        } else {
          let MontnDt = [""];
          let ValueDt = [];
          res.data.forEach((element) => {
            MontnDt.push(element.PeriodDt);
            ValueDt.push(parseFloat(element.MetricValue));
            
          });
          let sum = ValueDt.reduce((previous, current) => (current += previous));
          let avg = sum / ValueDt.length;
          let kpiMUnit = this.props.kpiUnit;
          this.setState(
            {
              showCanvas: true,
              isApiLoading: false,
              temp: res.data,
             
              dsKpiData: {
                labels: MontnDt,
                datasets: [
                  {
                    label: kpiMUnit,
                    data: [null, ...ValueDt],
                    fill: false,
                    lineTension: 0,
                    backgroundColor: "rgba(75,192,192,0.4)",
                    borderColor: "#66e0ff", // "rgba(196, 205, 213, 0.9)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: "miter",
                    floating: true,
                    pointDotRadius: 8, //6
                    datalabels: {
                      align: "top",
                      enabled: true,
                    },
                    pointBorderColor: "#66e0ff",
                    pointBackgroundColor: "white",
                    pointBorderWidth: 2, //1
                    pointHoverRadius: 8, //5
                    pointHoverBackgroundColor: "white",
                    pointHoverBorderColor: "#66e0ff",
                    pointHoverBorderWidth: 2,
                    pointRadius: 6,
                    pointHitRadius: 20,
                  },
                ],
              },
            },
            () => {
              this.showInsightChart();
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          isApiLoading: false,
        });
        trycatchAlertPopup(error);
      });
  }
  showInsightChart = () => {
  
    this.ctx2 = this.insightMetricChart.current.getContext("2d");
    if (!_.isNil(this.chart2)) {
      this.chart2.destroy();
    }
    let kpiMUnit = this.props.kpiUnit;

    var maxFromDataValues =
      this.state.dsKpiData.datasets.length > 0 ? _.max(this.state.dsKpiData.datasets[0].data) : [];
    var yAxisMaxVal = parseInt(maxFromDataValues) + 7;
    this.chart2 = new Chart(this.ctx2, {
      type: "line",
      data: {
        labels: this.state.dsKpiData.labels,
        datasets: this.state.dsKpiData.datasets,
      },
      options: {
        maintainAspectRatio: true,
        responsive: true,
        
        // testing for toltip start
        tooltips: {
          mode: "nearest",
          intersect: true,
          enabled: true,
          borderWidth: 3,
          filter: function(tooltipItem, data) {
            const tooltipDatasetIndex = tooltipItem.datasetIndex;
            const datasets = _.get(datasets, `${tooltipDatasetIndex}`);
            return _.get(datasets, "type", "") !== "line";
          },
          callbacks: {
            label: function(tooltipItem, data) {
              const tooltipDatasetIndex = tooltipItem.datasetIndex;
              let valWithUnit;
              const yLabel = tooltipItem.yLabel;
              valWithUnit = yLabel;
              
              if (!_.isEmpty(kpiMUnit)) {
                valWithUnit = kpiMUnit === "$" ? `$${yLabel}` : `${yLabel} ${kpiMUnit}`;
              }
              if (tooltipDatasetIndex !== 0) {
                const datasets = _.get(datasets, `${tooltipDatasetIndex}`);
                valWithUnit = `${datasets.label}: ${valWithUnit}`;
              }
              return valWithUnit;
            },
          },
        },
        //testing for tooltip end
        hover: {
          mode: "index",
          intersect: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                precision: 1,
                max: yAxisMaxVal,
                beginAtZero: false,
                callback: function(value, index, values) {
                  let valWithUnit = value;
                  if (!_.isEmpty(kpiMUnit)) {
                    valWithUnit = kpiMUnit === "$" ? `$${value}` : `${value} ${kpiMUnit}`;
                  }

                  return valWithUnit;
                },
              },
              gridLines: {
                drawOnChartArea: true, //false
              },
              scaleLabel: {
                display: true,
                labelString: i18n.t("Metric_Unit") + "(" + kpiMUnit + ")",
                color: "#637381", //"#666666",
                fontSize: 18,
                fontStyle: "bold",
                
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: true, //false
              },
              scaleLabel: {
                display: true,
                labelString: i18n.t("Timeframe"),
                color: "#637381", //"#666666",
                fontSize: 18,
                fontStyle: "bold",
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
  };
  render() {
    const { isApiLoading } = this.state;
    const {
      isVisibleDatepickerDropdownPopover,
      startDate,
      endDate,
      isStartEndDateSelected,
      tempStartDate,
      tempEndDate,
    } = this.state;
    let selValueDdl = this.state.selValueDdl;
    const dealListarr = [];
    this.state.autoCompleteDdlClientsList.forEach(function(arrayItem) {
      dealListarr.push({
        name: arrayItem.OrgEntityName,
        id: arrayItem.OrgEntityID,
        abbr: arrayItem.OrgEntityName.substr(0, 2),
      });
    });
    Chart.defaults.global.defaultFontColor = "black";
    let ToolTip = "";
    ToolTip = this.state.OfferingName + " - " + this.state.ProcessName;
    let kpiMUnit = this.props.kpiUnit;
    return (
      <>
        {isApiLoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />}
        {this.state.showCanvas ? (
          <div className="analyticsFilter no-whiteShadow px-0 py-2">
            <div className="chooseOption mb-10">
              <Overlay
                target={this.datepickerDropdownPopoverTarget.current}
                show={isVisibleDatepickerDropdownPopover}
                placement="bottom"
                rootClose
                rootCloseEvent="click"
                onHide={this.hideDatepickerDropdownPopover}
              >
                <Popover id="popover-basic4" className="calenderPopover">
                  <Popover.Content>
                    <div className="popContent">
                      <div className="popContentDisplay">
                        <div
                          className={isStartEndDateSelected ? "calenderPopoverSpanActive" : "calenderPopoverSpan"}
                          target={this.startDatepickerRef}
                          onClick={this.selectStartDate}
                        >
                          <div className="CalenderDatepickerLabel">{i18n.t("Start_Date")}</div>
                          <div className="CalenderDatepickerDate">{this.state.formattedTempStartDate}</div>
                        </div>
                        <div
                          className={isStartEndDateSelected ? "calenderPopoverSpan" : "calenderPopoverSpanActive"}
                          target={this.endDatepickerRef}
                          onClick={this.selectEndDate}
                        >
                          <div className="CalenderDatepickerLabel">{i18n.t("End_Date")}</div>
                          <div className="CalenderDatepickerDate">{this.state.formattedTempEndDate}</div>
                        </div>
                      </div>
                      {isStartEndDateSelected ? (
                        <div>
                          <DatePicker
                            selected={tempStartDate ? tempStartDate : startDate}
                            onChange={(date) => this.handleChangeDateField(date, isStartEndDateSelected)}
                            dateFormat="MMM yyyy"
                            showMonthYearPicker
                            strictParsing
                            inline
                            className="calenderDatepicker"
                            minDate={allowedMinDate}
                            maxDate={
                              !_.isNil(tempEndDate ? tempEndDate : endDate)
                                ? Moment(tempEndDate ? tempEndDate : endDate).toDate()
                                : Moment(new Date()).toDate()
                            }
                            placeholderText="Start Month"
                            // disabled={selectedBenchmark === "external"}
                            ref={this.startDatepickerRef}
                          />
                        </div>
                      ) : (
                        <DatePicker
                          selected={tempEndDate ? tempEndDate : endDate}
                          onChange={(date) => this.handleChangeDateField(date, isStartEndDateSelected)}
                          dateFormat="MMM yyyy"
                          showMonthYearPicker
                          strictParsing
                          inline
                          className="calenderDatepicker"
                          minDate={
                            !_.isNil(tempStartDate ? tempStartDate : startDate)
                              ? Moment(tempStartDate ? tempStartDate : startDate).toDate()
                              : null
                          }
                          maxDate={new Date()}
                          placeholderText={i18n.t("End_Month")}
                        
                          ref={this.endDatepickerRef}
                        />
                      )}
                    </div>
                  </Popover.Content>
                  <div className="popover-footer">
                    <div className="modal-btn temp">
                      <Button
                        variant="default"
                        className="btn-sm mr-auto"
                        onClick={() => {
                          this.handleClearDate();
                        }}
                      >
                        {i18n.t("Clear")}
                      </Button>
                      <Button
                        variant="primary"
                        className="btn-sm"
                        onClick={() => this.handleUpdateDateClickField(tempStartDate, tempEndDate)}
                      >
                        {i18n.t("Update")}
                      </Button>
                    </div>
                  </div>
                </Popover>
              </Overlay>
              <span
                className={isVisibleDatepickerDropdownPopover ? "active" : ""}
                ref={this.datepickerDropdownPopoverTarget}
                onClick={this.toggleDatepickerDropdownPopover}
              >
                {this.state.formattedStartDate === this.state.formattedEndDate
                  ? this.state.formattedStartDate
                  : this.state.formattedStartDate + " - " + this.state.formattedEndDate}
               
                <i class="fal fa-calendar-alt" style={{ marginLeft: "10px" }}></i>
              </span>
            </div>
          </div>
        ) : null}
        {this.state.showCanvas ? (
          <canvas id="myChart2" width="400" height="200" className="mt-4 mb-5" ref={this.insightMetricChart} />
        ) : null}
        {this.state.showErrorMsg ? (
          <div align="center" className="emptyView">
            {i18n.t("Enough_data_is_not_available_for_generating_the_graph")}
          </div>
        ) : null}
        <ReactTooltip place="top" className="tipcolor" />
      </>
    );
  }
}
export default JourneyTrendGraphsInsight;
