import React from 'react';
import i18n from "i18next";
import Iframe from 'react-iframe';
// IMPORT CUSTOM COMPONENTS
import FAQ from '../FAQ';
import { Modal, Button } from 'react-bootstrap';


// IMPORT IMAGES
import accLogo from '../Images/acc-logo.png';
import chatBotLogo from '../Images/chatbotLogo.svg';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisibleFAQModal: false,
            displayChatBox: false,
            termShow: false
        };
    }

    // MAKES THE FAQ MODAL VISIBLE
    handleClickFAQLink = () => {
        this.setState({ isVisibleFAQModal: true });
    }

    // HIDES THE FAQ MODAL
    handleHideFAQModal = () => {
        this.setState({ isVisibleFAQModal: false });
    }
    chatBox = (val, e) => {
        e.preventDefault();
        if (val === "open") {
            this.setState({ displayChatBox: true })
        } else {
            this.setState({ displayChatBox: false })
        }
    }

    handleTermsShow() {
        this.setState({ termShow: true });
    }
    handleTermsClose() {
        this.setState({ termShow: false });
    }
    render() {
        const { isVisibleFAQModal } = this.state;
        return (
            <>
                <div className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="footer-logo">
                                    <img src={accLogo} alt="" height="32" />
                                    <span className="copyright">&copy; {new Date().getFullYear() + " " + i18n.t("Accenture All Rights Reserved")}.</span>
                                    <span className="copyright-add"> {i18n.t("Accenture Confidential.  For Internal Use Only")}</span></div>
                                     <p className="copyright">
                                    {/* <a href="" onClick={this.chatBox.bind(this, "open")}>{i18n.t("Chat bot")}</a> */}
                                    {/* Below Chatbot commented for R2.6 release */}
                                    {/* <a href="" onClick={this.chatBox.bind(this, "open")} className="chatbotLogo"><img src={chatBotLogo} alt="chat bot logo" width="34" height="34"></img></a> */}
                                    {/*  <a href="https://in.accenture.com/accentureoperations/sell-business-process-services/" target="_blank">{i18n.t("About_SynOps")}</a> */}
                                    <a href="javascript:void(0)" onClick={this.handleTermsShow.bind(this)}>{i18n.t("Terms_of_Use")}</a>
                                    <a href="https://in.accenture.com/protectingaccenture/5422-2/" target="_blank">{i18n.t("Privacy_Statement")}</a>
                                    {/* <a href="mailto:synops.support@accenture.com?subject=Contact SynOps <Feedback/Support>&body=<Please mention how we can help you or provide your feedback on SynOps experience. Accordingly, please modify the subject line>">{i18n.t("Contact_Us")}</a> */}
                                </p>
                            </div>
                        </div>
                    </div>
                    <Modal className="termsContainer" show={this.state.termShow} backdrop={true} onHide={this.handleTermsClose.bind(this)} animation={true}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h2 className="termsUse">{i18n.t("Terms_of_use_Footer")} </h2>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="termsList">
                                {/* <p>"This system is the property of Accenture, and is to be used in accordance with applicable Accenture Policies.  Unauthorized access or activity is a violation of Accenture Policies and may be a violation of law.  Use of this system constitutes consent to monitoring for unauthorized use, in accordance with Accenture Policies, local laws, and regulations.  Unauthorized use may result in penalties including, but not limited to, reprimand, dismissal, financial penalties, and legal action. "</p>
                                    <p>Applications must provide the above information via a Terms of Use or Legal Notice page or screen.  These can be provided as links from a home web page, or via the Help function in the application.  It is not necessary for every application to provide a pop-up style notice as is required for laptops or workstations. </p>
                                    <p>It is prohibited to manually or systematically disable the Legal Notice.  Where possible, the system shall prevent users from disabling the legal notice on their machines.</p> */}
                                <p> {i18n.t("Terms_of_use_p1")} </p>
                                <p>  {i18n.t("Terms_of_use_p2")}</p>
                                <p> {i18n.t("Terms_of_use_p3")} </p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="d-none">
                        </Modal.Footer>
                    </Modal>
                </div>

                {/* FAQ MODAL */}
                {isVisibleFAQModal && (
                    <FAQ
                        onClose={() => this.handleHideFAQModal()}
                    />
                )}
                {/* {this.state.displayChatBox ? <>
                    <div className="chatbot-overlay" onClick={this.chatBox.bind(this, "close")}>
                        <div className="chatbot-iframe"><Iframe url="https://divachatstaging.accenture.com/DIVA_ConversationalUI/embed/SynOpsChatbot_UAT?s=b0X5aESTDQB8yrIm%2bLyar16EjDG7SfnNclFMNfE6eMJ7W3OTdfj5K6O65YOkN%2bJZUbshdkhhe%2fDisxyl%2fTAZjQHoUUrLSJ39AqLGwIvD8x520a%2fAwZEeK%2bUE7Q8KGOKhMibnz0Bc%2bwTrff63OyjoRw%3d%3d"
                            width="350px"
                            height="440px"
                            id="myId"
                            left="10px"
                            top="100px"
                            className="chat-box-alignment"
                            display="initial"
                            position="fixed" />
                            <p className="chat-box-close-alignment" onClick={this.chatBox.bind(this, "close")}>Close</p>
                        </div></div></> : ""} */}
            </>

        );
    }
}

export default Footer;