import React, { Component } from 'react';
import i18n from "i18next";
import { Tabs, Tab } from "react-bootstrap";
import HumanMetricDetails from './HumanMetricDetails';
import HumanPopUp from '../SuperAdmin/Humanconf_popup';
import { store } from "../reducers/configureStore"
import ExportCsv from './ExportCsv';
import emptycomponent from "../Images/Empty State.svg";

export default class HumanMachineTalent extends Component {
    constructor() {

        super()

      this.state = {
        type: "", humanandmachineData: [], metricDetails: "", machineMetricDetails: "", operationArray: [], selectedOption: 0, humanandMachinedata: [],
        machinedata: [],
        machineWorkforceCat: "",
        humanCatagoryName: "",
        machineWorkforceName: "",
        filterdata: []
      }
        this.onClose = this.onClose.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.setOperations = this.setOperations.bind(this);

    }
    dealpopup(type, e) {
        e.preventDefault();
        this.setState({ viewmore: true, type: type })
    }
    setOperations() {
      
        const data = store.getState();  
        let operation = this.state.operationArray
        data.roleReducers["roleReducers"].map(i => {
            if (i.FeatureName === "Master Template Configurator") {
                operation.push(...i.Operations)
                return operation
            }

        }
        )
  
        this.setState({
            operationArray: operation
        }
        );

    }

    onClose(e) {

        this.setState({ viewmore: false });
    }

    handleChange = event => {
      this.setState({
        selectedOption: event.target.value
      },()=>{
        this.filterData();
      });
    };
    getHumanAndMachineDetails=()=> {
        let filterClient = this.props.filterDetails.Client;
        let filterProcess = this.props.filterDetails.process;
        let filterLocations = this.props.filterDetails.Locations;
        let filterDate = this.props.filterDetails.Month;
    }

  displayHumanAndMachineDetails = () => {

    this.getHumanAndMachineDetails();

    let human = [];
    let Human = this.state.humanandmachineData.filter((x) => x.SOCategoryName_En === "Human");
    if(Human[0]){
      human = (this.state.humanandmachineData.filter((x) => x.ParentSOCategoryID === Human[0].SOCategoryID))
      if (human.length != 0) {
        const selectedTab=this.state.selectedOption == 0 ? human[0].SOCategoryID : this.state.selectedOption;
          this.setState({
            humanandMachinedata:human, 
            filterdata:this.state.humanandmachineData,
            selectedOption:selectedTab},()=>{
          this.filterData();
          });
      }
    }
   


    let machineData = [];
    let Machine = this.state.humanandmachineData.filter((x) => x.SOCategoryName_En === "Machine")
    let machineCatagory=0;
    Machine.map(item=>{
      if(item.SOCategoryID!=undefined){
      machineCatagory=item.SOCategoryID;
      }
    });
    this.state.humanandmachineData.map((data) => {
    if(machineCatagory== data.ParentSOCategoryID){
     machineData.push(data);
    }
    });


     machineData.map(cat => { this.state.machineWorkforceName = cat.SOCategoryName });
     machineData.map(cat => { this.state.machineWorkforceCat = cat.SOCategoryID });
    // this.state.machinedata = machineData;
    this.setState({machinedata:machineData})

    let machinelist = [];
    let machinerowTheList = [];
  
    let machinelistSample = machineData[0] != undefined ?
      machinelist.push(
        <div className="custom-tilecard mtclayout-tilecard">
          <div className="tilecard-wrapper no-carousel">
            <HumanMetricDetails
            onClose={this.onClose}
              CatagoryName={machineData[0].SOCategoryName}
              SOCategoryID={machineData[0].SOCategoryID}
              Details={machineData[0].kpiData}
              Name={machineData[0].SOCategoryID}
              filterDetails={this.props.filterDetails}
              roledata={this.state.operationArray}
              machineWorkforceCat={this.state.machineWorkforceCat}
              selectedOption={this.state.selectedOption}
            />
          </div>
        </div>

      ) : null  
      let kpiMappingID=false;
      if (machineData[0]) {
        machineData[0].kpiData.map(item=>{
          if(item.KPIMappingID === 0){
            kpiMappingID=true;
          }
          else
          kpiMappingID=false;
        });
      }
    let machinelistEmpty = (machineData[0] == undefined || machineData[0].kpiData.length == 0 || kpiMappingID) ?
      machinelist.push(<div className="emptymetric-msg">
        <img alt="" src={emptycomponent} width="80px" height="80px" />
        <h6>{i18n.t("Please click on '+' to add metrics")}</h6>
      </div>) : null

    this.state.machinelist = machinelist;


  }
    filterData =() =>{
      
      let Human = this.state.filterdata.filter((x) => x.SOCategoryName_En === "Human");
      let human = (this.state.filterdata.filter((x) => x.ParentSOCategoryID === Human[0].SOCategoryID))
      let humanData = human.filter((x) => this.state.selectedOption == x.SOCategoryID);
      let list = [];
      let rowTheList = [];
     if(humanData.length != 0){
      this.state.humanCatagoryName=humanData[0].SOCategoryName;
     }
      let listSample = humanData[0] != undefined ?
      
      list.push(                  
          <div className="custom-tilecard mtclayout-tilecard">
              <div className="tilecard-wrapper no-carousel">
                  <HumanMetricDetails 
                      onClose={this.onClose}
                      CatagoryName={this.state.humanCatagoryName}
                      SOCategoryID={humanData[0].SOCategoryID} 
                      Details={humanData[0].kpiData} 
                      Name={humanData[0].SOCategoryID} 
                      filterDetails={this.props.filterDetails} 
                      roledata={this.state.operationArray} 
                      machineWorkforceCat={this.state.machineWorkforceCat}
                      selectedOption={this.state.selectedOption}
                  /> 
              </div> 
          </div>
       
          )  :null
        
          let kpiMappingID=false;
      if (humanData[0] && humanData[0].kpiData.length>0) {
        humanData[0].kpiData.map(item=>{
          if(item.KPIMappingID === 0){
            kpiMappingID=true;
          }
          else
          kpiMappingID=false;
        });
      }
      let listEmpty = (humanData[0] == undefined || humanData[0].kpiData.length == 0 || kpiMappingID) ?
        list.push(<div className="emptymetric-msg">
          <img alt="" src={emptycomponent} width="80px" height="80px" />
          <h6>{i18n.t("Please click on '+' to add metrics")}</h6>
        </div>) : null
      this.setState({ list });
    }
    
    componentDidMount() {
        this.setOperations();
        store.subscribe(() => {
            var data = store.getState();
            this.setState({ humanandmachineData: data.humanMasterReducers["humanandmachine"] })
            this.displayHumanAndMachineDetails();
        })
    }

    componentDidUpdate(prevState){      
      var data = store.getState();
     if(prevState.humanandmachineData != null && prevState.humanandmachineData != data.humanMasterReducers["humanandmachine"] ){
        this.setState({ humanandmachineData: data.humanMasterReducers["humanandmachine"] })
        this.displayHumanAndMachineDetails();
     }
    }

    componentWillReceiveProps() {
      this.displayHumanAndMachineDetails();
    }

    render() {

        return (
            <>
                 <div className="row">
                 <ExportCsv csvProp = {this.props.filterDetails.process}/>
                    <div className="col-lg-12">
                        <div className="outcome-highlight no-whiteShadow no-table-layout">
                    <div className="hmtab-layout">
             
              <Tabs defaultActiveKey="Human">
              <Tab eventKey="Human" title="Human">
                    <div className="title-with-icon">
                      <div className="hmradio-btns">
                        {this.state.humanandMachinedata.map((Catagory) => {
                          return <>
                              {Catagory.SOCategoryID == this.state.selectedOption ?
                              <label className="form-check mr-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id={Catagory.SOCategoryID}
                                  name={Catagory.SOCategoryName}
                                  value={Catagory.SOCategoryID}
                                  checked={this.state.selectedOption == Catagory.SOCategoryID}
                                  onChange={this.handleChange}
                                />
                                <span className="checkmark"></span>
                                <span className="form-check-label active">{Catagory.SOCategoryName}</span>
                              </label> :
                              <label className="form-check mr-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id={Catagory.SOCategoryID}
                                  name={Catagory.SOCategoryName}
                                  value={Catagory.SOCategoryID}
                                  checked={this.state.selectedOption == Catagory.SOCategoryID}
                                  onChange={this.handleChange}
                                />
                                <span className="checkmark"></span>
                                <span className="form-check-label active">{Catagory.SOCategoryName}</span>
                              </label>}
                          </>

                        })}
                      </div>
                     
                      <div className="list-icon">
                        {this.state.humanandMachinedata.map((Catagory) => (
                          <>
                            {this.state.selectedOption == Catagory.SOCategoryID &&
                              <div>
                                {this.props.roledata != undefined && store.getState().CurrentFilter.languageData.id==1 && this.props.roledata.includes("Add") ? 

                                  <span onClick={(e) => { this.dealpopup(Catagory.SOCategoryID, e) }}>
                                    <i className="far fa-plus plus-icon-fix"></i>

                                  </span>: null}
                              </div>}
                          </>))}
                      </div>

                    </div>
                    {this.state.list}

                </Tab>
                <Tab eventKey="Machine" title="Machine">
                  <div className="title-with-icon">
                 
                      <div className="hmradio-btns">
                      <h5>{this.state.machineWorkforceName}</h5>
                      </div> 
                
                   {this.state.machinedata.map(machine => (
                  <div className="list-icon">
                 {this.props.roledata != undefined && store.getState().CurrentFilter.languageData.id==1 && this.props.roledata.includes("Add") ? 
                                
                                    <span onClick={(e) => { this.dealpopup(machine.SOCategoryID, e) }}>
                                         <i className="far fa-plus plus-icon-fix"></i>
                                  </span> : null}
                                </div>))}
                             </div> 
                {this.state.machinelist}
                 </Tab>   
              </Tabs>
            </div>
          </div>
                    
    
                  {this.state.viewmore && this.state.type == this.state.selectedOption ?
                    <HumanPopUp
                      CatagoryName={this.state.humanCatagoryName}
                      selectedOption={this.state.selectedOption}
                      SOCategoryID={this.state.selectedOption}
                      type={this.state.selectedOption} show={this.state.viewmore}
                      onClose={this.onClose}
                      opsgrp={this.props.opsgrp}
                      filterDetails={this.props.filterDetails}
                      showPopup={this.props.roledata != undefined && (this.props.roledata.includes("Edit") || this.props.roledata.includes("Add"))}
                    /> : null}                  
                  {this.state.viewmore && this.state.type == this.state.machineWorkforceCat ?
                    <HumanPopUp
                    CatagoryName={this.state.machineWorkforceName}
                    machineWorkforceCat={this.state.machineWorkforceCat}
                    SOCategoryID={this.state.machineWorkforceCat}
                    type={this.state.machineWorkforceCat} show={this.state.viewmore}
                      onClose={this.onClose} opsgrp={this.props.opsgrp}
                      filterDetails={this.props.filterDetails}
                      showPopup={this.props.roledata != undefined && (this.props.roledata.includes("Edit") || this.props.roledata.includes("Add"))}
                    /> : null}

               
                </div>
          </div>
                   

            </>


        )
    }
}