import React from "react";
import { ToChunk } from "../Shared/commonFunctions";
import ReactTooltip from "react-tooltip";
import i18n from "i18next";
function alternateMerge(arr1, arr2, n1, n2) {
  let arr3 = new Array(5);
  let i = 0,
    j = 0,
    k = 0;

  // Traverse both array
  while (i < n1 && j < n2) {
    arr3[k++] = arr1[i++];
    arr3[k++] = arr2[j++];
  }

  // Store remaining elements of first array
  while (i < n1) arr3[k++] = arr1[i++];

  // Store remaining elements of second array
  while (j < n2) arr3[k++] = arr2[j++];

  return arr3;
}

const mergeArray = (chunckedData) => {
  const newChunckedData = chunckedData.map((item) => {
    let temp = [];
    if (item.length === 2) {
      temp = alternateMerge(item[0], item[1], 5, 5);
      return temp;
    } else {
      let empty = new Array(5);
      temp = alternateMerge(item[0], empty, 5, 5);
      return temp;
    }
  });
  return newChunckedData;
};

const returnChunkData = (metricsData, isConfiguratorMode) => {
  const checkedData = metricsData
    .filter((a) => a.isChecked)
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  let uncheckedData = [];
  if (isConfiguratorMode) {
    uncheckedData = metricsData
      .filter((a) => !a.isChecked)
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }

  const datatoRender = [...checkedData, ...uncheckedData];
  let newChunckedData = [];
  if (datatoRender.length > 5) {
    const chunckedData = ToChunk(datatoRender, 5);
    newChunckedData = ToChunk(chunckedData, 2);
    const chunk = mergeArray(newChunckedData);
    const newData = chunk.flat(1);
    return newData;
  } else {
    const empty = new Array(5);
    let newArray = [...datatoRender];
    for (let i = 0; i < 5 - datatoRender.length; i++) {
      newArray.push(undefined);
    }
    newChunckedData = alternateMerge(newArray, empty, 5, 5);
    return newChunckedData;
  }
};

const checkedBoxCss = (obj, isConfiguratorMode) => {
  if (isConfiguratorMode) {
    if (obj.isChecked) {
      return obj.isRemoved ? "form-check-label active label-removed" : "form-check-label active";
    }
    return "form-check-label";
  } else {
    if (obj.isChecked) {
      return "form-check-label label-greyout";
    }
    return "form-check-label";
  }
};
const checkedBoxCssInput = (obj, isConfiguratorMode) => {
  if (!isConfiguratorMode) {
    if (obj.isChecked) {
      return "form-check-input greyout";
    }
    return "form-check-input";
  }
  return "form-check-input";
};

const toolTipName = {
  Initiatives: "Initiative",
  Metrics: "Metric",
  WorkOrchestration: "Step",
  HumanMachine: "Metric",
};
function BeforeAfterReasonGrowth({
  reasonForGrowth,
  handleChange,
  metricsData,
  handleCheckboxClick,
  selectAllStatus,
  handleSelectAll,
  from,
  to,
  isConfiguratorMode,
  searchGrowth,
  handleSearchGrowth,
  originalSearchReasons,
}) {
  const metrics = metricsData[reasonForGrowth] !== undefined ? metricsData[reasonForGrowth] : [];
  const newChunckedData = returnChunkData(metrics, isConfiguratorMode);
  const searchvalueexist =
    originalSearchReasons[reasonForGrowth] !== undefined
      ? isConfiguratorMode
        ? originalSearchReasons[reasonForGrowth]
        : originalSearchReasons[reasonForGrowth].filter((a) => a.isChecked)
      : [];
  return (
    <>
      <div className="growthreasons-sectionheader">
        <h5>Reasons for growth.. </h5>
        {searchvalueexist.length > 0 && (
          <div className="searchFilter">
            <input
              type="search"
              placeholder="Search"
              value={searchGrowth}
              onChange={(e) => {
                handleSearchGrowth(e);
              }}
            />
          </div>
        )}
      </div>
      <div className="growthreasons-content">
        <div className="growthreasons-contentleft">
          <ul className="growthreasons-list">
            <li>
              <label className="form-check mr-3">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={reasonForGrowth === "Initiatives"}
                  onClick={() => {
                    handleChange("Initiatives");
                  }}
                />
                <span className="checkmark"></span>
                <span
                  className={
                    reasonForGrowth === "Initiatives"
                      ? "form-check-label active"
                      : "form-check-label"
                  }
                >
                  Initiatives
                </span>
              </label>
            </li>
            <li>
              <label className="form-check mr-3">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={reasonForGrowth === "Metrics"}
                  onClick={() => {
                    handleChange("Metrics");
                  }}
                />
                <span className="checkmark"></span>
                <span
                  className={
                    reasonForGrowth === "Metrics" ? "form-check-label active" : "form-check-label"
                  }
                >
                  Metrics
                </span>
              </label>
            </li>
            <li>
              <label className="form-check mr-3">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={reasonForGrowth === "WorkOrchestration"}
                  onClick={() => {
                    handleChange("WorkOrchestration");
                  }}
                />
                <span className="checkmark"></span>
                <span
                  className={
                    reasonForGrowth === "WorkOrchestration"
                      ? "form-check-label active"
                      : "form-check-label"
                  }
                >
                  Work Orchestration
                </span>
              </label>
            </li>
            <li>
              <label className="form-check mr-3">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={reasonForGrowth === "HumanMachine"}
                  onClick={() => {
                    handleChange("HumanMachine");
                  }}
                />
                <span className="checkmark"></span>
                <span
                  className={
                    reasonForGrowth === "HumanMachine"
                      ? "form-check-label active"
                      : "form-check-label"
                  }
                >
                  Human + Machine
                </span>
              </label>
            </li>
          </ul>
        </div>
        {from && to && (
          <div className="growthreasons-contentright">
            {isConfiguratorMode &&
              newChunckedData.length > 0 &&
              newChunckedData.filter((a) => a === undefined).length !== newChunckedData.length && (
                <div className="mb-1">
                  <label className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={selectAllStatus}
                      onChange={() => {
                        handleSelectAll(reasonForGrowth);
                      }}
                    />
                    <span className="checkmark"></span>
                    <span className="form-check-label">Select All</span>
                  </label>
                </div>
              )}

            <ul>
              {newChunckedData.length > 0 &&
              newChunckedData.filter((a) => a === undefined).length !== newChunckedData.length ? (
                newChunckedData.map((a) => {
                  if (a !== undefined) {
                    let temp = (
                      <li>
                        <label className="form-check">
                          <input
                            className={checkedBoxCssInput(a, isConfiguratorMode)}
                            type="checkbox"
                            checked={a.isChecked}
                            onChange={() => {
                              isConfiguratorMode && handleCheckboxClick(reasonForGrowth, a);
                            }}
                          />
                          <span className="checkmark"></span>
                          <span
                            className={checkedBoxCss(a, isConfiguratorMode)}
                            data-tip
                            data-for={a.label}
                          >
                            {a.label}
                          </span>
                          {checkedBoxCss(a, isConfiguratorMode) ===
                            "form-check-label active label-removed" && (
                            <ReactTooltip id={a.label} place="right" effect="solid">
                              This {toolTipName[reasonForGrowth]} has been deleted from Frontdoor.
                              Please remove this metric and click on save to update.
                            </ReactTooltip>
                          )}
                        </label>
                      </li>
                    );
                    return temp;
                  } else {
                    let empty = <li></li>;
                    return empty;
                  }
                })
              ) : isConfiguratorMode && searchGrowth ===""  ? (
                <h3>{i18n.t("Please_config")}</h3>
              ) : (
                searchGrowth !== "" ?
                <h3>No results found</h3> : 
                <h3>{i18n.t("performance_empty")}</h3>
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default BeforeAfterReasonGrowth;
