import React from 'react';
import { LocalApiBaseUrl,LocalApiBaseUrlImage } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;




export default class QuickLinks extends React.Component {
    constructor() {
        super()
        this.GetQuickLinks = this.GetQuickLinks.bind(this);
        this.state = { link: [] ,humanlinks:[] ,worklinks:[], insightlinks:[],diverselinks:[],allLink:[]}
    }

    componentDidMount() {
        this.GetQuickLinks();
    }
    GetQuickLinks() {


        axiosInstance.get(`${LocalApiBaseUrl}SO/GetQuickLinksInfo`)
            .then((quickLinks) => {
                let allLink = [];
                let quicklink = quickLinks.data;
                
                
                let linkGroup = [... new Set(quicklink.map((eachValue) => eachValue.LinkGroup))];
                
                for(let i=0;i<linkGroup.length;i++)
                {
                    
                    let linkValue = quicklink.filter(x =>x.LinkGroup == linkGroup[i])
                
                    allLink.push(<div className="outcome-highlight quicklinks_grid"><h2><img src={`${LocalApiBaseUrlImage}${linkValue[0].LinkImage}`} alt="" />{linkGroup[i]} </h2>
                    <div className="radius-tools">                                  
                    {linkValue.map((x) =>{ return <a target="_blank" href={x.LinkUrl} ><span>{x.LinkName}</span></a>})}
                    </div>
                    </div>)                 
               


                }
                this.setState({allLink})
                
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
      


    }


    render() {
 
        return (           
            <div className="main-component">
                <div className="container-fluid">
                    <div className="row">
                    
                        <div className="col-lg-12">
                     
                        {this.state.allLink}
							
                        </div>
                    </div>
                </div>
            </div>
            

        )
    }
}