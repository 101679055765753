import React from 'react';
import { Modal } from 'react-bootstrap';
import { LocalApiBaseUrl } from '../Shared/Constant';
import { store } from "../reducers/configureStore"
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import MultiSelect from "react-multi-select-component";
import i18n from "i18next";
import SubOfferingDropdowns from "../Shared/SubOfferingDropdowns";
import { filterSelectedSubOffering } from "../Shared/commonFunctions";


const overrideStrings = {
    "selectSomeItems": "Select",
    "allItemsAreSelected": "All items selected.",
    "selectAll": "Select All ",
    "search": "Search"
}
export default class ToolConfiguratorPopup extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            show: this.props.show, apivalue: "", Display: false, viewmore: false, errors: {},OneTimeSetupError:"", formIsValid: false,
            SourceDetails: [], isPropsLoaded: false, isOTSdataReceived:false,


            SourceClientOptions: [], selectedClientOption: [],
            SourceOfferingOptions: [], selectedOfferingOption: [],
            SourceSubOfferingOptions: [], selectedSubOfferingOption: [],
            SourceLocationOptions: [], selectedLocationsOption: [],


            filterDisplay: { cl: false, off: false, sub: false, loc: false },
            friendlyNames: { cl: "", off: "", sub: "", loc: "" },
            multiselect: { cl: false, off: false, sub: false, loc: false },
            isApiCalled: { cl: false, off: false, sub: false, loc: false },


            OfferingOptions: [], LocationOptions: [], processOptions: [],
            DestinationOfferingValueID: 0,

            OneTimeSetupModel: {
                isActive: true,
                destinationClientId: 0, destinationClientName: "",

                destinationOfferingId: 0, destinationOfferingName: "",

                destinationSubOfferingId: 0, destinationSubOfferingName: "",

                destinationLocationId: 0, destinationLocationName: "",

                sourceConnectorID: 0, sourceConnectorName: "",

                sourceDealID: 0, sourceDealName: "",

                sourceClientId: 0, sourceClientName: [],

                sourceOfferingId: 0, sourceOfferingName: [],

                sourceSubOfferingId: 0, sourceSubOfferingName: [],

                sourceLocationId: 0, sourceLocationName: [],
            },
        }
        this.validateForm = this.validateForm.bind(this);
        // this.onOfferingChange = this.onOfferingChange.bind(this);
        // this.onLocationChange = this.onLocationChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    ////////---- Stand alone Methods


    componentDidMount() {
        debugger
        if (this.props.data) {
            let OneTimeSetupModel = this.props.data;
            this.setState({
                show: this.props.show,
                OneTimeSetupModel: OneTimeSetupModel,
                errors: {},
                formIsValid: true,
                isOTSdataReceived:true
            })
            this.getFilterDetails(this.props.data.sourceConnectorID);
        }

        else {
            var Client = store.getState().CurrentFilter.ClientData;
            let OneTimeSetupModel = this.state.OneTimeSetupModel;
            OneTimeSetupModel.destinationClientName = Client.name;
            OneTimeSetupModel.destinationClientId = Client.id;

            OneTimeSetupModel.sourceConnectorName = this.props.ConnectorData.sourceConnectorName;
            OneTimeSetupModel.sourceConnectorID = this.props.ConnectorData.sourceConnectorID;
            this.getOffering(Client.id);
            this.setState({
                show: this.props.show,
                OneTimeSetupModel: OneTimeSetupModel,
                errors: {},
                formIsValid: true
            })

            this.getFilterDetails(this.props.ConnectorData.sourceConnectorID);

        }
    }
    getOneTimeData() {
        if (this.state.OneTimeSetupModel.destinationSubOfferingId > 0 && this.state.OneTimeSetupModel.destinationLocationId > 0) {
            axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetHMClientOffering`, {
                params: {
                    destinationClientId: this.state.OneTimeSetupModel.destinationClientId,
                    destinationSubOfferingId: this.state.OneTimeSetupModel.destinationSubOfferingId,
                    destinationLocationId: this.state.OneTimeSetupModel.destinationLocationId,
                    sourceId: this.state.OneTimeSetupModel.sourceConnectorID
                }
            })

                .then((response) => {
                    let OneTimeSetupModel = this.state.OneTimeSetupModel
                    let OneTimeSetupError = ""
                    if (response.data.client === "") {
                        OneTimeSetupModel.sourceClientName = [];
                        OneTimeSetupError = "Connect with Synops Team to setup the application for Integration";
                    }
                    else {
                        OneTimeSetupModel.sourceClientName = response.data.client.split("|")
                    }
                    if (response.data.offering === "") {
                        OneTimeSetupModel.sourceOfferingName = [];
                    }
                    else {
                        OneTimeSetupModel.sourceOfferingName = response.data.offering.split("|")
                    }
                    if (response.data.subOffering === "") {
                        OneTimeSetupModel.sourceSubOfferingName = [];
                    }
                    else {
                        OneTimeSetupModel.sourceSubOfferingName = response.data.subOffering.split("|")
                    }
                    if (response.data.location === "") {
                        OneTimeSetupModel.sourceLocationName = [];
                    }
                    else {
                        OneTimeSetupModel.sourceLocationName = response.data.location.split("|")
                    }

                    this.setState({
                        OneTimeSetupModel: OneTimeSetupModel,
                        OneTimeSetupError:OneTimeSetupError
                    })
                    this.filterSelectionControl();
                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
        }
    }
    handleReset() {
    }
    handleSave(e) {
        if (this.validateForm()) {
            let OneTimeSetupModel = this.state.OneTimeSetupModel;
            OneTimeSetupModel.sourceClientName = this.state.OneTimeSetupModel.sourceClientName.toString();
            OneTimeSetupModel.sourceOfferingName = this.state.OneTimeSetupModel.sourceOfferingName.join("|");
            OneTimeSetupModel.sourceSubOfferingName = this.state.OneTimeSetupModel.sourceSubOfferingName.join("|");
            OneTimeSetupModel.sourceLocationName = this.state.OneTimeSetupModel.sourceLocationName.join("|");

            this.props.onSave(OneTimeSetupModel)
            this.handleHide();
        }
    }
    handleHide(e) {
        this.setState({ show: false });
        if (e != undefined) {

            if ((e.target.textContent != "×")) {
                this.props.onClose(e);
            }
            else {
                this.props.onClose(e);
            }
        } else {
            this.props.onClose(e);
        }

    }
    handleRejectHide(e) {
        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onRejectClose();
            }
        } else {
            this.props.onRejectClose();
        }

    }
    handleApproveHide(e) {

        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onApproveClose();
            }
        } else {
            this.props.onApproveClose();
        }

    }
    passToConfirmation(data) {
        this.props.enteredData(data.PoNumber)
    }
    validateForm() {
        debugger
        let OneTimeSetupModel = this.state.OneTimeSetupModel;
        let errors = {};
        let formIsValid = true;


        if (this.props.data) {
            if (!OneTimeSetupModel["destinationOfferingName"]) {
                formIsValid = false;
                errors["destinationOfferingId"] = "Offering  is required";
            }
        }
        else if (this.state.DestinationOfferingValueID == 0) {
            formIsValid = false;
            errors["destinationOfferingId"] = "Offering  is required";
        }

        if (!OneTimeSetupModel["destinationSubOfferingId"]) {

            formIsValid = false;
            errors["destinationSubOfferingId"] = "Sub Offering is required";
        }
        if (!OneTimeSetupModel["destinationLocationId"]) {

            formIsValid = false;
            errors["destinationLocationId"] = "Location is required";
        }

        if (!OneTimeSetupModel["sourceConnectorID"]) {
            formIsValid = false;
            errors["sourceConnectorID"] = "Source is required";

        }
        if (OneTimeSetupModel["sourceClientName"].length == 0 && this.state.filterDisplay.cl) {
            formIsValid = false;
            errors["sourceClientName"] = "Client is required";
        }

        if (OneTimeSetupModel["sourceLocationName"].length == 0 && this.state.filterDisplay.loc) {
            formIsValid = false;
            errors["sourceLocationName"] = this.state.friendlyNames.loc + " is required";
        }

        if (OneTimeSetupModel["sourceOfferingName"].length == 0 && this.state.filterDisplay.off) {
            formIsValid = false;
            errors["sourceOfferingName"] = "Offering is required";
        }

        if (OneTimeSetupModel["sourceSubOfferingName"].length == 0 && this.state.filterDisplay.sub) {
            formIsValid = false;
            errors["sourceSubOfferingName"] = this.state.friendlyNames.sub + " " + "is required";
        }

        this.setState({
            errors: errors
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;
    }

    ///------------ON Deal side Get Methods
    getLocation(processValueID) {
        let samp = {};
        axiosInstance.get(`${LocalApiBaseUrl}SOFilter/GetLocation`,
            {
                params: { OrgEntityID: this.state.OneTimeSetupModel.destinationClientId, OfferingID: processValueID }
            })
            .then((response) => {
                if (this.props.data != null) {

                }
                else {
                    this.setState({
                        LocationOptions: response.data,
                    });
                }
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
    }
    getOffering(OrgEntityID) {
        let samp = {};
        axiosInstance.get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
            params: { OrgEntityID: OrgEntityID, featureName: "Tool Configurator" }
        })
            .then((response) => {
                this.setState({
                    OfferingOptions: response.data,

                });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
    }

    ///------------ON Deal side Change Methods
    onDestinationOfferingsChange(event) {      
      
        let OneTimeSetupModel = this.state.OneTimeSetupModel;
        let isApiCalled = this.state.isApiCalled;

        isApiCalled.loc = false; isApiCalled.sub = false;
        OneTimeSetupModel.sourceLocationName = []; OneTimeSetupModel.sourceSubOfferingName = []; OneTimeSetupModel.sourceOfferingName = [];
        OneTimeSetupModel.sourceClientName = []; OneTimeSetupModel.destinationLocationId = 0; OneTimeSetupModel.destinationSubOfferingId = 0 ;
        OneTimeSetupModel.destinationSubOfferingName='';

        this.setState({
            errors: {},OneTimeSetupError:"",
            OneTimeSetupModel: OneTimeSetupModel, isPropsLoaded: true,isOTSdataReceived:true,
            isApiCalled: isApiCalled,
            LocationOptions: [], processOptions: [],
            SourceLocationOptions: [], selectedLocationsOption: [], SourceSubOfferingOptions: [], selectedSubOfferingOption: [],
            SourceOfferingOptions: [], selectedOfferingOption: [], SourceClientOptions: [], selectedClientOption: [],
        })
      
      
      
        let DestinationOfferingValueID = parseInt(event.target.value, 10);

        let processOptions = DestinationOfferingValueID ? this.state.OfferingOptions.filter((data) => data.OfferingID == event.target.value)[0].ChildOfferings : []
        this.setState({
            DestinationOfferingValueID: DestinationOfferingValueID,
            processOptions: processOptions,
        })
    }
    onDestinationProcessChange(e) {

        let OneTimeSetupModel = this.state.OneTimeSetupModel;
        let isApiCalled = this.state.isApiCalled;

        isApiCalled.loc = false; isApiCalled.sub = false;
        OneTimeSetupModel.sourceLocationName = []; OneTimeSetupModel.sourceSubOfferingName = []; OneTimeSetupModel.sourceOfferingName = [];
        OneTimeSetupModel.sourceClientName = []; OneTimeSetupModel.destinationLocationId = 0;

        this.setState({
            errors: {},OneTimeSetupError:"",
            OneTimeSetupModel: OneTimeSetupModel, isPropsLoaded: true,isOTSdataReceived:false,
            isApiCalled: isApiCalled,
            LocationOptions: [],
            SourceLocationOptions: [], selectedLocationsOption: [], SourceSubOfferingOptions: [], selectedSubOfferingOption: [],
            SourceOfferingOptions: [], selectedOfferingOption: [], SourceClientOptions: [], selectedClientOption: [],
        })
        let selectedSuboffering=[];
        if(e.target.value != 0){
            const filtersSubofferingData = filterSelectedSubOffering([...this.state.processOptions],e.target.value);
            selectedSuboffering = filtersSubofferingData.subofferingData[0];
        }

        OneTimeSetupModel.destinationSubOfferingId = parseInt(e.target.value, 10);

        OneTimeSetupModel.destinationSubOfferingName = selectedSuboffering.Process ? selectedSuboffering.Process : '';
        this.getLocation(OneTimeSetupModel.destinationSubOfferingId);
        this.setState({
            OneTimeSetupModel: OneTimeSetupModel,
        })
        this.getOneTimeData();

    }
    onDestinationLocationChange(event) {
        let OneTimeSetupModel = this.state.OneTimeSetupModel;
        let isApiCalled = this.state.isApiCalled;

        isApiCalled.loc = false; isApiCalled.sub = false;
        OneTimeSetupModel.sourceLocationName = []; OneTimeSetupModel.sourceSubOfferingName = []; OneTimeSetupModel.sourceOfferingName = [];
        OneTimeSetupModel.sourceClientName = [];

        this.setState({
            errors: {},OneTimeSetupError:"",
            OneTimeSetupModel: OneTimeSetupModel, isPropsLoaded: true,isOTSdataReceived:false,
            isApiCalled: isApiCalled,
            SourceLocationOptions: [], selectedLocationsOption: [], SourceSubOfferingOptions: [], selectedSubOfferingOption: [],
            SourceOfferingOptions: [], selectedOfferingOption: [], SourceClientOptions: [], selectedClientOption: [],
        })


        var event = document.getElementById('Location');
        OneTimeSetupModel.destinationLocationId = parseInt(event.options[event.selectedIndex].value, 10);

        OneTimeSetupModel.destinationLocationName = event.options[event.selectedIndex].label;
        this.setState({
            OneTimeSetupModel: OneTimeSetupModel,
        })
        this.getOneTimeData();
    }

    ///------------ON Source side Get Methods
    
    getFilterDetails(ConnectorId) {
        debugger
        let filterDisplay = this.state.filterDisplay;
        let friendlyNames = this.state.friendlyNames;
        let multiselect = this.state.multiselect;
        axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/SourceSystemFilter`,
            {
                params: { sourceId: ConnectorId }
            })
            .then((row) => {
                let cell = [];
                if (row.data.response[0].FieldName === "Client") {
                    cell = row.data.response[0];
                    filterDisplay.cl = cell.isVisible; friendlyNames.cl = cell.friendlyName; multiselect.cl = cell.multiSelection
                }
                if (row.data.response[1].FieldName === "Offering") {
                    cell = row.data.response[1];
                    filterDisplay.off = cell.isVisible; friendlyNames.off = cell.friendlyName; multiselect.off = cell.multiSelection
                }
                if (row.data.response[2].FieldName === "SubOffering") {
                    cell = row.data.response[2];
                    filterDisplay.sub = cell.isVisible; friendlyNames.sub = cell.friendlyName; multiselect.sub = cell.multiSelection
                }
                if (row.data.response[3].FieldName === "Location") {
                    cell = row.data.response[3];
                    filterDisplay.loc = cell.isVisible; friendlyNames.loc = cell.friendlyName; multiselect.loc = cell.multiSelection
                }

                this.setState({
                    filterDisplay: filterDisplay,
                    friendlyNames: friendlyNames,
                    multiselect: multiselect,
                });
                if (this.props.data) {
                this.filterSelectionControl();
                }
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      

    }
    filterSelectionControl() {
        let isApiCalled = this.state.isApiCalled;
        let isVisible = this.state.filterDisplay;
        let OneTimeSetupModel = this.state.OneTimeSetupModel;

        if (isVisible.sub && !isApiCalled.sub && OneTimeSetupModel["sourceClientName"].length != 0) {
            this.getSourceSubOfferings();
            isApiCalled.sub = true;
            this.setState({
                isApiCalled: isApiCalled
            });
        }

        else if (isVisible.loc && !isApiCalled.loc && OneTimeSetupModel["sourceClientName"].length != 0) {
            this.getSourceLocations();
            isApiCalled.loc = true;
            this.setState({
                isApiCalled: isApiCalled
            });
        }
    }
    getSourceSubOfferings() {

        let sourceId = this.state.OneTimeSetupModel.sourceConnectorID
        let clientName = this.state.OneTimeSetupModel.sourceClientName.toString();
        clientName = encodeURIComponent(clientName)
        let offeringName = this.state.OneTimeSetupModel.sourceOfferingName.join("|");
        offeringName = encodeURIComponent(offeringName)

        axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetSourceSubOffering?sourceId=${sourceId}&clientName=${clientName}&offeringName=${offeringName}`)

            .then((response) => {
                let SourceSubOfferingOptions = []
                let selectedSubOfferingOption = []
                let sortedSubOffering= response.data.subOfferingList.sort((a, b) => (a.name  > b.name ) ? 1 : -1)

                sortedSubOffering.map((row, i) => {
                    if (row.name != "") {

                    let dummy = [];
                    dummy.label = row.name;
                    dummy.value = row.name;
                    SourceSubOfferingOptions.push(dummy)

                    if (this.props.data && this.state.isPropsLoaded == false || !this.state.isOTSdataReceived) {
                        if (this.state.OneTimeSetupModel.sourceSubOfferingName.includes(row.name)) {
                            debugger
                            selectedSubOfferingOption.push(dummy)
                        }
                    }
                }
                })
                this.setState({ SourceSubOfferingOptions: SourceSubOfferingOptions, selectedSubOfferingOption: selectedSubOfferingOption });
                if (this.props.data && this.state.isPropsLoaded == false || !this.state.isOTSdataReceived) {
                    this.filterSelectionControl();
                }
               
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
    }
    getSourceLocations() {
        let sourceId = this.state.OneTimeSetupModel.sourceConnectorID
        let clientName = this.state.OneTimeSetupModel.sourceClientName.toString();
        let offeringName = this.state.OneTimeSetupModel.sourceOfferingName.join("|");
        let subOfferingname = this.state.OneTimeSetupModel.sourceSubOfferingName.join("|");

        // new method 
        let data = {}
        data.sourceId = sourceId
        data.clientName = clientName
        data.offeringName = offeringName
        data.subOfferingname = subOfferingname
        axiosInstance.post(`${LocalApiBaseUrl}ConnectorMapping/GetSourceLocation`, data)
            .then((response) => {
                
                let SourceLocationOptions = []
                let selectedLocationsOption = []
                let sortedlocation = response.data.sort((a, b) => (a.name > b.name) ? 1 : -1)

                sortedlocation.map((row, i) => {
                    if (row.name != "") {
                        let dummy = [];
                        dummy.label = row.name;
                        dummy.value = row.name;
                        SourceLocationOptions.push(dummy)
                        if (this.props.data && this.state.isPropsLoaded == false || !this.state.isOTSdataReceived) {
                            if (this.state.OneTimeSetupModel.sourceLocationName.includes(row.name)) {
                                debugger
                                selectedLocationsOption.push(dummy)
                            }
                        }
                    }
                })
                this.setState({ SourceLocationOptions: SourceLocationOptions, selectedLocationsOption: selectedLocationsOption });
                if (this.props.data && this.state.isPropsLoaded == false || !this.state.isOTSdataReceived) {
                    this.filterSelectionControl();
                }
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      

    }


    ///------------ ON Source side Change Methods
        
    onSourceProcessSelect(selectedList) {
        let OneTimeSetupModel = this.state.OneTimeSetupModel;
        let isApiCalled = this.state.isApiCalled;
        isApiCalled.loc = false;
        OneTimeSetupModel.sourceLocationName = [];


        this.setState({
            errors: {},
            OneTimeSetupModel: OneTimeSetupModel, isPropsLoaded: true,isOTSdataReceived:true,
            isApiCalled: isApiCalled,
            SourceLocationOptions: [], selectedLocationsOption: [],
        })

        let selectedSubOfferingOption = this.state.selectedSubOfferingOption;

        if (this.state.multiselect.sub) {
            let tempProcess = [];
            let tempProcessValue = [];
            var len = selectedList.length;
            for (var i = 0; i < len; i++) {
                tempProcess.push(selectedList[i]);
                tempProcessValue.push(selectedList[i].value);

            }
            selectedSubOfferingOption = tempProcess;
            OneTimeSetupModel.sourceSubOfferingName = tempProcessValue;

        }

        else {
            let tempProcess = [];
            let tempProcessValue = [];

            var len = selectedList.length;
            tempProcess.push(selectedList[len - 1]);
            tempProcessValue.push(selectedList[len - 1].value);

            selectedSubOfferingOption = tempProcess
            OneTimeSetupModel.sourceSubOfferingName = tempProcessValue;

        }
        this.setState({
            selectedSubOfferingOption: selectedSubOfferingOption,
            OneTimeSetupModel: OneTimeSetupModel,

        })
        this.filterSelectionControl();
    }
    onSourceLocationSelect(selectedList) {
        debugger
        let OneTimeSetupModel = this.state.OneTimeSetupModel;
        let selectedLocationsOption = this.state.selectedLocationsOption;
        if (this.state.multiselect.loc) {
            let tempLocation = [];
            let tempLocationValue = [];
            var len = selectedList.length;
            for (var i = 0; i < len; i++) {
                tempLocation.push(selectedList[i]);
                tempLocationValue.push(selectedList[i].value);

            }
            selectedLocationsOption = tempLocation;
            OneTimeSetupModel.sourceLocationName = tempLocationValue;

        }

        else {
            let tempLocation = [];
            let tempLocationValue = [];
            var len = selectedList.length;
            tempLocation.push(selectedList[len - 1]);
            tempLocationValue.push(selectedList[len - 1].value);
            selectedLocationsOption = tempLocation
            OneTimeSetupModel.sourceLocationName = tempLocationValue;
        }
        this.setState({
            errors: {},
            selectedLocationsOption: selectedLocationsOption, isPropsLoaded: true,isOTSdataReceived:true,
            OneTimeSetupModel: OneTimeSetupModel

        })
        this.filterSelectionControl();
    }

    render() {

        const subOfferingMultipleselectbar = (
            <React.Fragment>
                <MultiSelect
                    options={this.state.SourceSubOfferingOptions}
                    value={this.state.selectedSubOfferingOption}
                    onChange={this.onSourceProcessSelect.bind(this)}
                    labelledBy="OrgEntityName"
                    shouldToggleOnHover={false}
                    overrideStrings={overrideStrings}
                    hasSelectAll={this.state.multiselect.sub}
                />
            </React.Fragment>
        ); const locationMultipleselectbar = (
            <React.Fragment>
                <MultiSelect
                    options={this.state.SourceLocationOptions}
                    value={this.state.selectedLocationsOption}
                    onChange={this.onSourceLocationSelect.bind(this)}
                    labelledBy="OrgEntityName"
                    shouldToggleOnHover={false}
                    overrideStrings={overrideStrings}
                    hasSelectAll={this.state.multiselect.loc}
                />
            </React.Fragment>
        );
        return (
            <>

                {/* IF API IS LOADING THEN SHOW THE LOADER */}
              
                <Modal show={this.state.show} onHide={this.handleHide.bind(this)} scrollable={true} backdrop="static" className="modal fade" size="medium" id="dealpopup">
                    <Modal.Header closeButton>
                        <h4 className="modal-title" id="dealprofile">Parameter Mapping</h4>
                    </Modal.Header>
                    <Modal.Body>

                        <form>
                            <div className="form-section">
                                <p className='fs-medium'>Mapping source connector fields with its corresponding fields in SynOps for fetching metric data</p>
                                {<div className="errorMsg">{this.state.OneTimeSetupError}</div>}

                                <div className="row">
                                    <div className='col-lg-6'>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h3 className='grid-heading border-0 mt-2 mb-3'>SynOps Details</h3>
                                            </div>
                                            <div className="form-group col-lg-12">
                                                <label for="">Destination System</label>
                                                <input type="text" maxlength="200" className="form-control" id="" value='SynOps' disabled />
                                            </div>
                                            <div className="form-group col-lg-12">
                                                <label for="">Client Name</label>
                                                <input type="text" maxlength="200" className="form-control" id="" value={this.state.OneTimeSetupModel.destinationClientName} disabled />
                                            </div>
                                            {!this.props.data ?
                                                <div className="form-group col-lg-12">
                                                    <label for="">Offering<span className="required">*</span></label>
                                                    <select id="Offering" className="form-control" ref="OfferingDropDown" disabled={this.props.data || this.state.apivalue} onChange={this.onDestinationOfferingsChange.bind(this)} value={this.state.DestinationOfferingValueID}>
                                                        <option value="0">Select Offering</option>
                                                        {this.state.OfferingOptions.map((row) => {
                                                            return <option value={row.OfferingID}>{row.OfferingName}</option>;
                                                        })
                                                        }
                                                    </select>
                                                    {<div className="errorMsg">{this.state.errors.destinationOfferingId}</div>}
                                                </div>
                                                :
                                                <div className="form-group col-lg-12">
                                                    <label for="">Offering<span className="required">*</span></label>
                                                    <input id="Offering" className="form-control" ref="OfferingDropDown" disabled={true} value={this.state.OneTimeSetupModel.destinationOfferingName}>
                                                    </input>
                                                </div>
                                            }
                                            {!this.props.data ?
                                                <div className="form-group col-lg-12">
                                                    <SubOfferingDropdowns 
                                                        selectedSubOffering={this.state.OneTimeSetupModel.destinationSubOfferingName}
                                                        subOfferings={this.state.processOptions}
                                                        onSubOfferingChange={(e)=>this.onDestinationProcessChange(e)}
                                                        defaultToggleName={i18n.t("Select_Sub_Offerings")}
                                                        selectedSubOfferingId={this.state.OneTimeSetupModel.destinationSubOfferingId}
                                                        required={true}
                                                        labelClassName=""
                                                        labelfor=""
                                                        disabledValue={this.props.data || this.state.apivalue}
                                                        dropdownItem={true}
                                                        labelpresent={true}
                                                        SubOfferingDropdownsClass={true}
                                                    />
                                                    <span className="errorMsg">{this.state.errors.destinationSubOfferingId}</span>
                                                </div>
                                                :
                                                <div className="form-group col-lg-12">
                                                    <label for="">Sub Offering<span className="required">*</span></label>
                                                    <input id="Process" className="form-control" ref="ProcessDropDown" disabled={true} value={this.state.OneTimeSetupModel.destinationSubOfferingName}>
                                                    </input>
                                                </div>
                                            }
                                            {!this.props.data ?
                                                <div className="form-group col-lg-12">
                                                    <label for="">Location <span className="required">*</span></label>
                                                    <select id="Location" className="form-control" disabled={this.props.data || this.state.apivalue} ref="LocationDropdown" onChange={this.onDestinationLocationChange.bind(this)} value={this.state.OneTimeSetupModel.destinationLocationId}>
                                                        <option value="0">Select Location</option>
                                                        {this.state.LocationOptions.map((row) => {
                                                            if (row.IsActive) {
                                                                return <option value={row.LocationID}>{row.City}</option>
                                                            }
                                                        })}
                                                    </select>
                                                    <span className="errorMsg">{this.state.errors.destinationLocationId}</span>
                                                </div>
                                                :
                                                <div className="form-group col-lg-12">
                                                    <label for="">Location <span className="required">*</span></label>
                                                    <input id="Location" className="form-control" ref="ProcessDropDown" disabled={true} value={this.state.OneTimeSetupModel.destinationLocationName}>
                                                    </input>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h3 className='grid-heading border-0 mt-2 mb-3'>Source Details</h3>
                                            </div>
                                            <div className="form-group col-lg-12"> {/* copy Paste field: vignesh */}
                                                <label for="" className="source-system-padding">Source System<span className="required ">*</span></label>
                                               <input id="Process" className="form-control" ref="ProcessDropDown" disabled={true} value={this.state.OneTimeSetupModel.sourceConnectorName}>
                                               </input>

                                                <div className="errorMsg">{this.state.errors.sourceConnectorID}</div>
                                            </div>
                                            {this.state.filterDisplay.cl ?
                                                <div className="form-group col-lg-12">
                                                    <label for="" >{this.state.friendlyNames.cl}<span className="required">*</span></label>
                                                    <input id="Offering" className="form-control" ref="OfferingDropDown" disabled={true} value={this.state.OneTimeSetupModel.sourceClientName}>
                                                    </input>

                                                </div>

                                                 : null
                                            }

                                            {this.state.filterDisplay.off ?
                                                <div className="form-group col-lg-12">
                                                    <label for="">{this.state.friendlyNames.off}<span className="required">*</span></label>
                                                    <input id="Offering" className="form-control" ref="OfferingDropDown" disabled={true} value={this.state.OneTimeSetupModel.sourceOfferingName}>
                                                    </input>
                                                </div>
                                                 : null
                                            } 

                                            {this.state.filterDisplay.sub ?
                                                <div className="form-group col-lg-12">
                                                    <label for="">{this.state.friendlyNames.sub}<span className="required">*</span></label>
                                                    <div className="custom-multi-select">

                                                        {subOfferingMultipleselectbar}
                                                        <span className="errorMsg">{this.state.errors.sourceSubOfferingName}</span>
                                                    </div>

                                                </div>
                                                 : null
                                            } 

                                            {this.state.filterDisplay.loc ?
                                                <div className="form-group col-lg-12">
                                                    <label for="">{this.state.friendlyNames.loc}<span className="required">*</span></label>
                                                    <div className="custom-multi-select">

                                                        {locationMultipleselectbar}
                                                    </div>

                                                    <span className="errorMsg">{this.state.errors.sourceLocationName}</span>

                                                </div>
                                                : null
                                            } 

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    {<Modal.Footer>
                        <div className="modal-btn">
                            {!this.props.data ?
                                <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Create" />
                                : <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Update" />
                            }
                        </div>
                    </Modal.Footer>}
                </Modal>
            </>
        )
    }
}

