import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  LocalApiBaseUrl
} from "../Shared/Constant";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-blue.css";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";


axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class DealReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      formIsValid: false,
      downloadUrl: null,
      active: false,
      viewmore: false,
      process: "",
      Client: "",
      MaturityPhase: this.props.MaturityPhase,
      processOptions: [],

      Report: {
        MonthTextBox: null
      }
    };

    this.MonthChange = this.MonthChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.generateExcelReport = this.generateExcelReport.bind(this);
  }

  componentDidMount() { }

  MonthChange(event) {
    this.setState({ errors: {} });
    let Report = this.state.Report;
    Report.MonthTextBox = event.target.value < 99 ? event.target.value : Report.MonthTextBox;
    this.setState({
      Report: Report
    });

    var A = "Data/GetDealsReport?";
    var B = LocalApiBaseUrl;

    var Month = this.state.Report.MonthTextBox;

    let downloadUrl = this.state.downloadUrl;

    downloadUrl = B + A + "Months=" + Month;

    this.setState({
      downloadUrl: downloadUrl
    });
    //}
  }

  generateExcelReport() {
    if (this.validateForm()) {
      var that = this;
      var data = {
        month: Number(this.state.Report.MonthTextBox)
      };
      let constructedURL = LocalApiBaseUrl + "Data/GetDealsReport";

      if (data.month !== 0) {
        this.setState({
          active: true
        });
        axiosInstance
          .post(constructedURL, data, {
            responseType: "blob"
          })

          .then(response => {
            this.setState({ active: false });
            if (response.status !== 200) {
              alert("An error has occurred!");
            }

            let fileNameFromHeaders = response.headers.filename;
            if (!window.navigator.msSaveOrOpenBlob) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;

              link.setAttribute("download", fileNameFromHeaders);

              document.body.appendChild(link);
              link.click();
            }
            else {
              // BLOB FOR EXPLORER 11
            }
          })
          .catch(function (error) {
            that.setState({ active: false });
            trycatchAlertPopup(error);
          });
      }
    }
  }

  validateForm() {
    let Report = this.state.Report;
    let errors = {};
    let formIsValid = true;

    if (!Report["MonthTextBox"]) {
      formIsValid = false;
      errors["MonthTextBox"] = "Please Select Month";
    }
    if (Report["MonthTextBox"] === "0") {
      formIsValid = false;
      errors["MonthTextBox"] = "Month Cannot be Zero";
    }
    if (Report["MonthTextBox"] < "0") {
      formIsValid = false;
      errors["MonthTextBox"] = "Month cannot be negative"
    }
    this.setState({
      errors: errors
    });

    if (formIsValid) {
      this.setState({
        formIsValid: true
      });
    } else {
      this.setState({
        formIsValid: false
      });
    }
    return formIsValid;
  }

  render() {
    return (<>
      <div className="main-data">
        <div className="row">
          <div className="form-group col-lg-4">
            <label htmlFor="">Month<span className="required"></span></label>
            <input type="number" className="form-control" id="" onChange={this.MonthChange} value={this.state.Report.MonthTextBox} />
            <span className="errorMsg">{this.state.errors.MonthTextBox}</span>
          </div>

        </div>
        <div className="row">
          <div className="form-group col">
            <button className="btn btn-primary btn-sm" id="btnsave" onClick={this.generateExcelReport} disabled={this.state.active}>Generate Report</button>
            <LoadingOverlay
              active={this.state.active}
              spinner={<SynopsLoader />}
            ></LoadingOverlay>
          </div>
        </div>
      </div>
    </>
    );
  }
}
