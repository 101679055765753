import React, { Component } from 'react';
import { LocalApiBaseUrl, getPrevMonth } from '../Shared/Constant';
import InsightIntelligenceDetails from './InsightIntelligenceDetails';
import { connect } from "react-redux";
import { store } from "../reducers/configureStore";
import OwlCarousel from 'react-owl-carousel2';
import ReactTooltip from "react-tooltip";
import 'react-owl-carousel2/src/owl.carousel.css';
import i18n from "i18next";
import 'react-owl-carousel2/src/owl.theme.default.css';
import InsightIntelligencePopUp from '../Configurator/InsightIntelligencePopUp'
import InsightsDataPopUp from '../Configurator/InsightsDataPopUp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import _ from 'lodash';
import $ from "jquery";
import emptycomponent from "../Images/Empty State.svg";
import References from "../Shared/References"

const options = {
    loop: true,
    margin: 16,
    responsiveClass: "",
    dots: false,
    responsive: {
        0: {
            items: 1,
            nav: true
        },
        600: {
            items: 2,
            nav: false
        },
        1000: {
            items: 3,
            nav: true,
            loop: false
        },
        1440: {
            items: 4,
            nav: true,
            loop: false
        }
    }
};
let statusColor = "gray-clr";
class InsightsIntelligence extends Component {
    constructor(props) {
        super(props);
        this.OtherProcess = []
        this.Analytics = []
        this.OtherProcessWithCategory = []
        this.AnalyticsWithCategory = []
        this.state = { exploreLinkList: [],selectedSocategoryId:0,
            exploreDrop: "none",viewmore: false, type: "", AnalyticsAppsAIAdvisorsData: [], AttributeIsrelator: [], OtherProcessInsightsData: [], AnalyticsmetricDetails: [], AnalyticsmetricDetailsCategory: "", otherMetricDetailsCategory: "", metricDetails: "", machineMetricDetails: "", new: "", 
            insightintelligenceData: [], setCategory: "", pageHeader: "" ,
            exploreToolsList:[], resMetricData: [],responseMetricData: [], viewmore1: false
        }
        this.onClose = this.onClose.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
    }

    dealpopup(type, e, data, category,id, resultSet) {
        // e.currentTarget.className = "active"
        e.preventDefault();
        this.setState({ viewmore: true, type: type, data, setCategory:category,selectedSocategoryId:id, resMetricData:resultSet })
    }
    onClose=(e)=> {

        this.setState({ viewmore: false, viewmore1: false });
    }
    triggerViewMore=()=> {
        this.setState({ viewmore1: true });
    }

    getKpiMetrics = (status, SODetails = { SOCategoryID: 0 }, offeringIdparam = { Client: 0, process: 0, Locations: 0, Month: getPrevMonth() }) => {        
        let list = [];
        let rowTheList = [];
        if (SODetails != undefined && SODetails.SOCategoryID > 0) {
            if (String(status) === "Category"){
                list.push(<div className="title-with-icon">
                <h2 className="mb-3">{SODetails.SOCategoryName}</h2>
                    {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] ===  "True" && !this.props.isManintencneWindowOpen ) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0) ? "" :
                <div className="list-icon">
                    <span onClick={(e) => { this.dealpopup("Left_Panel", e, '', SODetails.SOCategoryName,SODetails.SOCategoryID) }}>
                        <FontAwesomeIcon icon={faPlus} />
                    </span>
                </div>}
            </div>);
            }
            if (SODetails.kpiData != undefined && SODetails.kpiData.length > 0) {
                SODetails.kpiData.map((data, index) => {        
                rowTheList.push(data);
                if (rowTheList.length == SODetails.kpiData.length) {  
                    return list.push(<div className="custom-tilecard ii-tilecard">
                        <div className="tilecard-wrapper">
                        <div className="custom-carousel-padding">
                            <OwlCarousel options={options}
                         className={`owl-carousel`}><InsightIntelligenceDetails  RecordStatus = {this.props.RecordStatus} isManintencneWindowOpen ={this.props.isManintencneWindowOpen} tool={this.props.tool} Details={rowTheList} SODetails={SODetails} isRelatorBlock={this.props.isRelatorBlock} filterDetails={this.props.filterDetails} AllCategoryData={this.state.insightintelligenceData}
                attributes = {this.props.attributes} setCategory={SODetails.SOCategoryName}  onClose={this.onClose} triggerViewMore={this.triggerViewMore}/></OwlCarousel>
                <ReactTooltip place="bottom" effect="solid" className="tipcolor" /></div></div></div>); 
                }
                })
            }
           
        }
        

        return list;
       
    }
    

    getInsightsIntelligenceDetails() {
        let parsedResponse = this.props.SODetails;
        let filterClient = this.props.filterDetails.Client;
        let filterProcess = this.props.filterDetails.process;
        let filterLocations = this.props.filterDetails.Locations;
        let filterDate = this.props.filterDetails.Month;
    }

    displayInIByCategory = () => {
        let leftCategoryGroup = [];
        var data = store.getState();
        this.state.insightintelligenceData = data.intelligentInsightReducers["insightandintelligence"]; 

        const leftCategoryID = 4;
        const leftCategoryChilds = this.state.insightintelligenceData.filter((IIDetails) => (IIDetails.ParentSOCategoryID === leftCategoryID));        
    
        leftCategoryChilds.map((row, index) => {       
            leftCategoryGroup.push(this.getKpiMetrics("Category", row, this.props.filterDetails));
            this.setState({ AnalyticsmetricDetailsCategory: leftCategoryGroup })
        });
    }

    componentWillMount() {
        this.getInsightsIntelligenceDetails();
    }

    componentDidMount() {
        this.getInsightsIntelligenceDetails(); 
        this.displayInIByCategory(); 
    }

    getKpiMAppingDetails = (row) => {
        return axiosInstance
            .get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
                params: {
                    OrgEntityID: this.props.filterDetails.Client,
                    LocationID: this.props.filterDetails.Locations,
                    OfferingID: this.props.filterDetails.process,
                    SOCategoryID: row.SOCategoryID,
                    MonthDT: this.props.filterDetails.Month
                }
            })
            .then(response => {              
            return this.getKpiMetrics("Category", row, this.props.filterDetails, response.data);
        });                
    }

    viewMore(e){
        let filterClient = this.props.filterDetails.Client;
        let filterProcess = this.props.filterDetails.process;
        let filterLocations = this.props.filterDetails.Locations;
        let SOCategoryID = 4


    let displayStatus = this.state.exploreDrop;
    if (String(displayStatus) === "none") {
        axiosInstance
        .get(`${LocalApiBaseUrl}ClickableTools/GetExploreTools`, {
          params: {
            OrgEntityID:filterClient,
            OfferingID:filterProcess,
            LocationID:filterLocations,
            SOCategoryID: SOCategoryID,
            IsAllTool:true
          }
        })
        .then(res => {
         
        this.setState({
            exploreToolsList : res.data,
            exploreDrop1: res.data.length==0?i18n.t("Please add tools on Pulse Page"):"",
        })
               
         
          this.onClose();

        })
        .catch((error)=>{
            trycatchAlertPopup(error)});
    } 
    else {
        this.setState({ exploreDrop: "none" });
      }
    }

    componentDidUpdate(prevProps, prevState, nextProps){
        if(this.state.viewmore != prevState.viewmore || this.state.viewmore1 != prevState.viewmore1){
            this.displayInIByCategory();             
        }
        if(!_.isEmpty(this.props.headerLabel) && !_.isNil(prevProps.headerLabel) && this.props.headerLabel != prevProps.headerLabel) {
            let pageHeader = this.props.headerLabel;
            this.setState({ pageHeader });
        }
        $(document).on('click', '.explore_tools_root', function (e) {
            e.stopPropagation();
          });
    }


    deleteEntry = (data) => {
        store.dispatch({
            type: 'Remove_APPADVISORS',
            payload: data
        })
    }
    handleToggle = (index)=>{
        let filterClient = this.props.filterDetails.Client;
        let filterProcess = this.props.filterDetails.process;
        let filterLocations = this.props.filterDetails.Locations;
        let SOCategoryID = 4
        let appID=this.state.exploreToolsList[index].ApplicationMappingID;
        axiosInstance.get(`${LocalApiBaseUrl}ClickableTools/IncludeExcludeTool`,{
            params:{
                OrgEntityID:filterClient,
                OfferingID:filterProcess,
                LocationID:filterLocations,
                AppId:appID,
                SOCatId:SOCategoryID
            }
        }).then(response=>{
            if(response.data.IsSuccess === true)
            {
                let data= this.state.exploreToolsList;
        data[index].IsEnable = !data[index].IsEnable;
        this.setState({
            exploreToolsList:data,
        })
            }
        })
        .catch((error) => {
            trycatchAlertPopup(error);
          });
  
  
        
    }

    

    render() {
        const { exploreToolsList } =this.state;
        let IIData = [...this.state.insightintelligenceData]
        var count=0;
        let TabDatalength=IIData.length;
        var i;
        for(i=0;i<TabDatalength;i++)
        {
                if(IIData[i].kpiData.length==0 || IIData[i].kpiData == undefined)
                {
                    count++;
                }
        }

        return (
            <>

<div className="row">
            <div className="col-lg-12">
            <div className="breadcrumb-title clearfix configurator-references-dropdown references-dropdown dashboard-references-dropdown">

    <div className="dropdown">
                                <a  className="dropdown-toggle"
                                    data-toggle="dropdown"
                                    onClick={() => { this.viewMore() }}>
                                    <span className="exploreTools">
                                    {i18n.t("Explore_Tools_Advisor")}
                                    <span>{i18n.t("Click on Explore Tools/Advisor to view Tools")}</span>
                                    </span>
                                <span className="caret"></span>
                                </a>
 
           <ul className="dropdown-menu explore_tools_root explore-tools-menu dropdown-menu-right custom-dropdown-menu">

              {exploreToolsList.length > 0 ? exploreToolsList.map((eachLinks,index) => {
            return (
            <div className="explore_tools">
              <a className={eachLinks.IsEnable ?(eachLinks.URL ?"dropdown-item":"dropdown-item explore_tools_noCursor" ): "dropdown-item disabled"}  href={eachLinks.URL? (eachLinks.URL !== "NA"&&eachLinks.URL !== "https://"?eachLinks.URL:eachLinks.URL.substr):"javascript:void(0)"} target={eachLinks.URL && "_blank"}>
                {eachLinks.ApplicationName} 
                
              </a>
              <div className="toggle-group toggle-tick">
              <input
                  type="checkbox"
                  name={"datatypechange"+index}
                  id={"datatypechange"+index}
                  defaultChecked={true} 
                  onChange={(e)=>{this.handleToggle(index) } }
                    checked={eachLinks.IsEnable}                                          
              />
              <label htmlFor={"datatypechange"+index}>Connection Status</label>
              <div className="onoffswitch" aria-hidden="true">
                  <div className="onoffswitch-label">
                      <div className="onoffswitch-inner"></div>
                      <div className="onoffswitch-switch"></div>
                  </div>
              </div>
          </div>

              </div>
            );
    
          } ):(<div><p>
          {this.state.exploreDrop1}
          </p></div>)
        }
      
              </ul>

                 </div>
                 <div className="category-references dashboard-category-references">
                {<References 
                {...this.props} 
                SOCatID={4}
                leverName="Insights & Intelligence"
                isConfig={true}
                referenceScreen="II"
                />
                }
            </div>
      </div>
              </div>
</div>
                <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="outcome-highlight insightsNew tilecard-new iiwidth no-whiteShadow no-table-layout"> 

                    <div className="title-with-icon">
                  <h1>{this.props.headerLabel}</h1>
                
                 </div>
            <h2><span>{this.state.OtherProcessInsightsData.SOCategoryName}</span></h2>
     
                            <>
                            
                                    {this.state.AnalyticsmetricDetailsCategory.length != 0 ? (
                                        this.state.AnalyticsmetricDetailsCategory.map((data) => {
                                            return <div className="outcome-highlight insightsNew tilecard-new iiwidth no-whiteShadow no-table-layout">{data}</div>;
                                        })
                                    ) : <div className="emptymetric-msg">
                                    <img alt="" src={emptycomponent} width="80px" height="80px" />
                                  <h6>{i18n.t("No category available")}</h6>
                                </div>
                                    }
                                    {IIData.length>0 && TabDatalength==count?
                        <div className="emptymetric-msg">
                        <img alt="" src={emptycomponent} width="80px" height="80px" />
                        <h6>{i18n.t("Please click on '+' to add metrics")}</h6>
                        </div>:null}
                            </>
                        </div>
                        </div>
                    {this.state.viewmore && this.state.type === "Left_Panel" ? <InsightIntelligencePopUp tool={this.props.tool} SOCategoryID = {this.state.selectedSocategoryId} isRelatorBlock={this.props.isRelatorBlock} show={true} onClose={this.onClose} Details={this.state.data} AllCategoryData={this.state.insightintelligenceData} MetricGroupsID={this.state.AnalyticsAppsAIAdvisorsData.SOCategoryID}  MetricGroupsName={this.state.AnalyticsAppsAIAdvisorsData.SOCategoryName}  filterDetails={this.props.filterDetails} filterDetails={this.props.filterDetails} type={this.state.type} setCategory={this.state.setCategory}
                      attributes = {this.props.attributes} responseMetricData={this.state.resMetricData}
                    /> : null}
                    {this.state.viewmore && this.state.type === "Right_Panel" ? <InsightIntelligencePopUp tool={this.props.tool} SOCategoryID = {this.state.selectedSocategoryId} isRelatorBlock={this.props.isRelatorBlock} show={true} onClose={this.onClose} Details={this.state.data} MetricGroupsID={this.state.OtherProcessInsightsData.SOCategoryID} MetricGroupsName={this.state.OtherProcessInsightsData.SOCategoryName}   AllCategoryData={this.state.insightintelligenceData} filterDetails={this.props.filterDetails} type={this.state.type} 
                        attributes = {this.props.attributes} responseMetricData={this.state.resMetricData}
                    /> : null}
                    {this.state.viewmore && this.state.type === "add kpi" ? <InsightsDataPopUp tool={this.props.tool} SOCategoryID = {this.state.selectedSocategoryId} isRelatorBlock={this.props.isRelatorBlock} show={true} onClose={this.onClose} Details={this.state.data} AllCategoryData={this.state.insightintelligenceData} filterDetails={this.props.filterDetails} isKpi={this.state.type}
                       attributes = {this.props.attributes} responseMetricData={this.state.resMetricData}
                    /> : null}
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      roleReducers: state.roleReducers.roleReducers,
      insightintelligenceData1: state.intelligentInsightReducers.insightandintelligence
    };
  };
  
  
  export default connect(mapStateToProps,"")(InsightsIntelligence);
