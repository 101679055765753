import React, { Component } from "react";
import i18n from "i18next";
import _ from "lodash";
import moment from "moment";
import { store } from "../../reducers/configureStore";
import { LocalApiBaseUrl } from "../../Shared/Constant";
import axiosInstance from "../../Shared/interceptor";
import {trycatchAlertPopup} from "../../Shared/Constant";
import { Modal } from "react-bootstrap";
import SynopsLoader from "../../Shared/SynopsLoader";
import ViewPMM from "./ViewPMM";
import LoadingOverlay from "react-loading-overlay";
import servicecomponentIcon from "../../Images/ServiceComponentIcon.svg";
import emptycomponent from "../../Images/Empty State.svg";
import Moment from "moment";

const singleServiceComponent = {
  ServiceComponentID: 0,
  ServiceComponent: "",
  AssessmentDate: "",
  IsConfigured: false,
  IsChecked: false,
  PMMGraphData: [],
};

export default class AddPMMService extends Component {
  constructor() {
    super();
    this.removeServiceModalShow = this.removeServiceModalShow.bind(this);
    this.removeServiceModalClose = this.removeServiceModalClose.bind(this);
    this.addServiceModalShow = this.addServiceModalShow.bind(this);
    this.addServiceModalClose = this.addServiceModalClose.bind(this);
    this.addServiceModalSave = this.addServiceModalSave.bind(this);
    this.successServiceModalClose = this.successServiceModalClose.bind(this);
    this.handlesuggestionChange = this.handlesuggestionChange.bind(this);
    this.state = {
      isApiLoading: false,
      dataStatus: "",
      removeServiceStatus: false,
      addServiceStatus: false,
      successServiceStatus: false,
      suggetionServiceStatus: false,
      viewPMMStatus: false,
      allChecked: false,
      selectedServiceCompForRemoval: [],
      selectedServiceComp: [],
      selectedModelServiceComp: [],
      serviceComponentDetails: [],
      modifiedServiceComponentDetails: [],
      suggestionList: [],
      graphData: [],
      checkedItems: new Map(),
    };
  }

  /* STAND ALONE METHODS */
  componentDidMount() {
    this.MapStoreDetailsToState();
  }

  async dispatchToStore(payload) {
    this.setState({
      isApiLoading: true,
    });
    return new Promise((resolve, reject) => {
      let filter = {};
      filter.LocationID = this.props.filterDetails.Locations;
      filter.OrgEntityID = this.props.filterDetails.Client;
      filter.OfferingID = this.props.filterDetails.process;
      filter.MonthDT = this.props.filterDetails.Month;
      let data = {
        MaturityPlanTabData: [...payload],
        FiltersIDsVM: filter,
      };
      axiosInstance
        .post(`${LocalApiBaseUrl}Configurator/SaveMaturityPlanData`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  }

  componentWillReceiveProps(nextprops) {
    this.MapStoreDetailsToState();
  }

  MapStoreDetailsToState() {
    var data = store.getState();
    var PMMData = data.MaturityReducers["Maturity"];
    let modifiedServiceComponentDetails = _.map(PMMData, (eachItem) => {
      const serviceComponent = {
        ..._.cloneDeep(singleServiceComponent),
        ServiceComponentID: eachItem.ServiceComponentID,
        ServiceComponent: eachItem.ServiceComponent,
        AssessmentDate: moment
          .utc(eachItem.AssessmentDate)
          .local()
          .format("YYYY-MM-DD"),
        IsConfigured: eachItem.IsChecked,
        IsChecked: eachItem.IsChecked,
        PMMGraphData: eachItem.PMMGraphData,
      };
      return serviceComponent;
    });

    let suggestionList = _.orderBy(
      modifiedServiceComponentDetails,
      function(o) {
        return new moment(o.AssessmentDate);
      },
      ["desc"]
    );

    suggestionList = _.map(suggestionList, (each) => {
      return {
        ...each,
        IsChecked: false,
      };
    });

    this.setState({
      serviceComponentDetails: PMMData,
      suggestionList: suggestionList,
      modifiedServiceComponentDetails,
      selectedServiceComp: modifiedServiceComponentDetails.filter((item) => item.IsChecked === true),
      selectedModelServiceComp: modifiedServiceComponentDetails.filter((item) => item.IsChecked === true),
      allChecked: modifiedServiceComponentDetails.every((item) => item.IsChecked),
    });
  }

  renderList = () => {
    //ADD SERVICE COMPONENT LIST

    return this.state.modifiedServiceComponentDetails.map((item) => (
      <li>
        <label className={`form-check ${item.IsChecked ? "labelselected" : " "} `}>
          <div className="service-name">
            <div className="service-icon">
              <img src={servicecomponentIcon} alt="" width="36" height="36" />
            </div>
            <div className="service-label">
              <span className="form-check-label">{item.ServiceComponent}</span>
              <span className="assessment-date">Assessment Date: {Moment(item.AssessmentDate).format('DD-MM-YY')}</span>
            </div>
          </div>
          <input
            key={item.ServiceComponentID}
            type="checkbox"
            name={item.ServiceComponent}
            value={item.ServiceComponent}
            className="form-check-input"
            checked={item.IsChecked}
            onChange={this.onAddServiceCompChange}
          />
          <span className="checkmark"></span>
        </label>
      </li>
    ));
  };

  /* REMOVE SERVICE COMPONENTS METHODS */
  async removeServiceModalShow(type, input) {
    let {
      modifiedServiceComponentDetails,
      allChecked,
      selectedServiceComp,
      selectedModelServiceComp,
      selectedServiceCompForRemoval,
    } = this.state;

    if (type === "alert") {
      this.setState({ removeServiceStatus: true, selectedServiceCompForRemoval: input });
    } else {
      modifiedServiceComponentDetails = modifiedServiceComponentDetails.map((item) =>
        item.ServiceComponent === selectedServiceCompForRemoval.ServiceComponent ? { ...item, IsChecked: false } : item
      );
      allChecked = modifiedServiceComponentDetails.every((item) => item.IsChecked);
      selectedServiceComp = selectedModelServiceComp = modifiedServiceComponentDetails.filter(
        (item) => item.IsChecked === true
      );
      this.setState({
        removeServiceStatus: false,
      });

      const Response = await this.dispatchToStore(modifiedServiceComponentDetails);
      if (Response.data.IsSuccess) {
        store.dispatch({
          type: "INITIALISEMaturity",
          payload: modifiedServiceComponentDetails,
        });
        this.setState({
          modifiedServiceComponentDetails,
          allChecked,
          selectedModelServiceComp,
          selectedServiceComp,
          removeServiceStatus: false,
          selectedServiceCompForRemoval: [],
        });
      }
      this.setState({
        isApiLoading: false,
      });
    }
  }
  removeServiceModalClose() {
    this.setState({ removeServiceStatus: false, selectedServiceCompForRemoval: [] });
  }

  /* ADD SERVICE MODEL METHODS */
  addServiceModalShow() {
    this.setState({ addServiceStatus: true });
  }
  onAddServiceCompChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    let { modifiedServiceComponentDetails, allChecked, selectedModelServiceComp } = this.state;

    if (itemName === "checkAll") {
      allChecked = checked;
      modifiedServiceComponentDetails = modifiedServiceComponentDetails.map((item) => ({
        ...item,
        IsChecked: checked,
      }));
    } else {
      modifiedServiceComponentDetails = modifiedServiceComponentDetails.map((item) =>
        item.ServiceComponent === itemName ? { ...item, IsChecked: checked } : item
      );
      allChecked = modifiedServiceComponentDetails.every((item) => item.IsChecked);
    }
    selectedModelServiceComp = modifiedServiceComponentDetails.filter((item) => item.IsChecked === true);

    this.setState({
      modifiedServiceComponentDetails,
      allChecked,
      selectedModelServiceComp,
    });
  };
  async addServiceModalSave() {
    let { modifiedServiceComponentDetails, allChecked, selectedServiceComp } = this.state;
    selectedServiceComp = modifiedServiceComponentDetails.filter((item) => item.IsChecked === true);

    //***** */ To dispatch the data to the reducers *****//
    const Response = await this.dispatchToStore(modifiedServiceComponentDetails);

    if (Response.data.IsSuccess) {
      store.dispatch({
        type: "INITIALISEMaturity",
        payload: modifiedServiceComponentDetails,
      });
      this.setState({ successServiceStatus: true, addServiceStatus: false, selectedServiceComp });
    }
    this.setState({
      isApiLoading: false,
    });
  }
  addServiceModalClose() {
    let { modifiedServiceComponentDetails, allChecked, selectedServiceComp } = this.state;

    modifiedServiceComponentDetails = _.map(modifiedServiceComponentDetails, (modifiedComp, index) => {
      let selectedComp = _.find(selectedServiceComp, function(each) {
        return each.ServiceComponentID === modifiedComp.ServiceComponentID;
      });
      if (selectedComp != undefined) {
        return {
          ...selectedComp,
        };
      } else {
        return {
          ...modifiedComp,
          IsChecked: false,
        };
      }
    });

    allChecked = modifiedServiceComponentDetails.every((item) => item.IsChecked);

    this.setState({
      addServiceStatus: false,
      selectedModelServiceComp: selectedServiceComp,
      modifiedServiceComponentDetails,
      allChecked,
    });
  }

  /* COMPONENT ADDITION SUCCESS MESSAGE */
  successServiceModalClose() {
    this.setState({
      successServiceStatus: false,
    });
  }

  /* SUGGESTION SELECTION METHOD */
  async handlesuggestionChange(e) {
    let itemName = e.target.name;
    let checked = e.target.checked;
    let { modifiedServiceComponentDetails, allChecked, selectedServiceComp, selectedModelServiceComp } = this.state;

    modifiedServiceComponentDetails = modifiedServiceComponentDetails.map((item) =>
      item.ServiceComponent === itemName ? { ...item, IsChecked: checked } : item
    );

    selectedModelServiceComp = selectedServiceComp = modifiedServiceComponentDetails.filter(
      (item) => item.IsChecked === true
    );
    allChecked = modifiedServiceComponentDetails.every((item) => item.IsChecked);

    const Response = await this.dispatchToStore(modifiedServiceComponentDetails);
    if (Response.data.IsSuccess) {
      store.dispatch({
        type: "INITIALISEMaturity",
        payload: modifiedServiceComponentDetails,
      });
      this.setState({
        allChecked,
        modifiedServiceComponentDetails,
        successServiceStatus: true,
        selectedServiceComp,
        selectedModelServiceComp,
      });
    }
    this.setState({
      isApiLoading: false,
    });
  }

  /* SHOW GRAPH COMPONENT METHOD */
  viewComponentGraphs(serviceComponent, event) {
    this.setState({ viewPMMStatus: true, graphData: serviceComponent }, () => {
      this.Hidestagestatus();
    });
  }

  Hidestagestatus = (e) => {
    this.props.Hideparent(e);
  };

  closePMMassessment(e) {
    this.setState(
      {
        viewPMMStatus: false,
      },
      () => {
        this.Showstagestatus();
      }
    );
  }

  Showstagestatus = (e) => {
    this.props.Showparent(e);
  };

  render() {
    return (
      <>
        <LoadingOverlay active={this.state.isApiLoading} spinner={<SynopsLoader />}>
          {this.state.viewPMMStatus == false ? (
            <div className="addPMM-service">
              <div className="maturity-plan">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="addservice-component">
                      <h4>Service Component</h4>
                      <div>
                      {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                                        (this.props.RecordStatus &&
                                          this.props.RecordStatus[1] === "True" &&
                                          !this.props.isManintencneWindowOpen) ||
                                        (this.props.RecordStatus &&
                                          this.props.RecordStatus[0] === "Publish" &&
                                          !this.props.isManintencneWindowOpen) ||
                                        this.props.roleReducers.filter((each) => {
                                          if (each.FeatureName === "Configurator" && each.Operations.length) {
                                            return each;
                                          }
                                        }).length === 0 ||
                                        (this.props.RecordStatus &&
                                          this.props.RecordStatus[0] === "Save" &&
                                          this.props.roleReducers.filter((each) => {
                                            if (
                                              each.FeatureName === "Configurator" &&
                                              each.Operations.filter((op) => op === "Submit").length
                                            ) {
                                              return each;
                                            }
                                          }).length === 0) ||
                                        (this.props.RecordStatus &&
                                          this.props.RecordStatus[0] === "Submit" &&
                                          this.props.roleReducers.filter((each) => {
                                            if (
                                              each.FeatureName === "Configurator" &&
                                              each.Operations.filter((op) => op === "Publish").length
                                            ) {
                                              return each;
                                            }
                                          }).length === 0) ? (
                                          ""
                                        ) : 
                        <button className={"btn btn-primary btn-sm"} onClick={this.addServiceModalShow}>
                          Add Service Component
                        </button>}
                      </div>
                    </div>
                    {this.state.selectedServiceComp.length === 0 ? (
                      <div className="noserviceContainer">
                        <div className="noservice-component">
                          <img src={emptycomponent} alt="" width="130" height="130" />
                          <h6>Add Service Component to begin</h6>
                          <p>You can add service component using the add button or view suggestions below to add</p>
                        </div>
                        <div className="suggested-services">
                          <h5>Suggestions</h5>
                          <p>These are the latest Service Components added to this Deal</p>
                          <ul className="suggestion-list">
                            {this.state.suggestionList.map((item, key) => {
                              if (key < 3) {
                                return (
                                  <li>
                                    <label className={`form-check ${item.IsChecked ? "labelselected" : " "} `}>
                                      <div className="service-name">
                                        <div className="service-icon">
                                          <img src={servicecomponentIcon} alt="" width="40" height="40" />
                                        </div>
                                        <div className="service-label">
                                          <span className="form-check-label">{item.ServiceComponent}</span>
                                          <span className="assessment-date">
                                            Assessment Date: {Moment(item.AssessmentDate).format('DD-MM-YY')}
                                          </span>
                                        </div>
                                      </div>
                                      <input
                                        type="checkbox"
                                        name={item.ServiceComponent}
                                        value={item.ServiceComponent}
                                        className="form-check-input"
                                        checked={item.IsChecked}
                                        onChange={this.handlesuggestionChange}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div className="service-component-list">
                          {this.state.selectedServiceComp.map((item, key) => {
                            if (item.IsChecked) {
                              return (
                                  <div className="service-component">
                                    <div className="service-icon">
                                     
                                    </div>
                                    <div className="service-data">
                                      <h4>{item.ServiceComponent}</h4>
                                      <p>
                                      {i18n.t("Assessment_Date")}: <span>{Moment(item.AssessmentDate).format('DD-MM-YY')}</span>
                                      </p>
                                      <p> 
                                        {i18n.t("Score")}: <span>{item.PMMGraphData[0].OverallScore}</span>
                                      </p>
                                    </div>
                                    
                                    <div className="dropdown dropleft">
                                        <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                                        <div className="dropdown-menu">
                                            <a href="javascript:void(0)" className="dropdown-item" onClick={this.viewComponentGraphs.bind(this, item)}>                                              
                                                  <span className="eye-icon"></span>{i18n.t("View")}                                              
                                            </a>
                                            {this.props.isAccess && (
                                              <a href="javascript:void(0)" className="dropdown-item" onClick={(e) => this.removeServiceModalShow("alert", item)}>
                                                 <span className="delete-icon"></span>{i18n.t("Remove")}
                                              </a>
                                            )}
                                        </div>
                                    </div>
                                  </div>
                              );
                            }
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Modal
                show={this.state.removeServiceStatus}
                onHide={this.removeServiceModalClose}
                className="modal fade remove-modal"
                size="medium"
                backdrop = "static"
              >
                <Modal.Header>
                  <h4 className="modal-title">Remove Service Component</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="notification-message">
                    <div className="remove-icon">
                      <span>
                        <i class="fal fa-exclamation-triangle"></i>
                      </span>
                    </div>
                    <div className="remove-message">
                      <p>
                        Are you sure you want to remove the service component{" "}
                        {`${this.state.selectedServiceCompForRemoval.ServiceComponent}`}?
                      </p>
                    </div>
                  </div>
                </Modal.Body>
                {
                  <Modal.Footer>
                    <div className="modal-btn">
                      <button onClick={this.removeServiceModalClose} className="btn btn-default btn-sm mr-2">
                        Cancel
                      </button>
                      <button onClick={(e) => this.removeServiceModalShow("remove")} className="btn btn-danger btn-sm">
                        Remove
                      </button>
                    </div>
                  </Modal.Footer>
                }
              </Modal>
              <Modal
                show={this.state.addServiceStatus}
                onHide={this.addServiceModalClose}
                className="modal fade remove-modal"
                size="medium"
                backdrop = "static"
              >
                <Modal.Header closeButton>
                  <h4 className="modal-title">Add Service Components</h4>
                </Modal.Header>
                <Modal.Body className="select-service">
                  <div className="components-added">
                    <div className="component-count">
                      <span>{this.state.serviceComponentDetails.length} Service Components</span>
                    </div>
                    <div className="component-allselect">
                      <label className="form-check">
                        <span className="form-check-label">Select All</span>
                        <input
                          type="checkbox"
                          name="checkAll"
                          className="form-check-input"
                          checked={this.state.allChecked}
                          onChange={this.onAddServiceCompChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="component-list">
                    <ul>{this.renderList()}</ul>
                  </div>
                </Modal.Body>
                {
                  <Modal.Footer className="component-footer">
                    <div className="selected-service">
                      <span>{this.state.selectedModelServiceComp.length} Service Component Selected</span>
                    </div>
                    <div className="modal-btn">
                      <button onClick={this.addServiceModalClose} className="btn btn-default btn-sm mr-2">
                        Cancel
                      </button>
                      <button className="btn btn-primary btn-sm" onClick={this.addServiceModalSave}>
                        Add
                      </button>
                    </div>
                  </Modal.Footer>
                }
              </Modal>
              <Modal
                show={this.state.successServiceStatus}
                onHide={this.successServiceModalClose}
                className="modal fade remove-modal success-notification"
                size="medium"
                backdrop = "static"
              >
                <Modal.Body>
                  <button type="button" class="close" onClick={this.successServiceModalClose}>
                    <span aria-hidden="true">×</span>
                    <span class="sr-only">Close</span>
                  </button>
                  <div className="notification-message">
                    <div className="success-icon">
                      <span>
                        <i class="fal fa-check"></i>
                      </span>
                    </div>
                    <div className="success-message">
                      <h5>Service Components added successfully!</h5>
                      <p>{this.state.selectedServiceComp.length} Service Components are successfully added.</p>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          ) : (
            <div className="viewPMM-service">
              <ViewPMM graphData={this.state.graphData} closePMMassessment={this.closePMMassessment.bind(this)} />
            </div>
          )}
        </LoadingOverlay>
      </>
    );
  }
}
