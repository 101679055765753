import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { AgGridReact } from "ag-grid-react";
import { store } from "../reducers/configureStore";
import DealPopUp from "./dealPopup";
import _ from "lodash";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class DealList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelected: [],
      OperatingGroupOptions: [],
      IndustryOptions: [],
      viewmore: false,
      isDealPop: false,
      ClientRegions: [],
      MaturityPhase: [],
      columnDefs: [
        {
          headerName: "Edit",
          suppressMovable: true,
          width: 80,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tbledit";
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.dealpopup(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
          suppressSizeToFit: true,
        },
        // { headerName: "Operating Group", field: "OperatingGroup", suppressSizeToFit: true, suppressMovable: true,suppressMovable: true,resizable: true },
        { headerName: "Industry", field: "Industry", suppressSizeToFit: true, suppressMovable: true, resizable: true },
        {
          headerName: "Client Name",
          field: "ClientName",
          sort: "asc",
          suppressSizeToFit: true,
          suppressMovable: true,
          resizable: true,
        },
        { headerName: "Deal Name", field: "DealName", suppressSizeToFit: true, suppressMovable: true, resizable: true },
        {
          headerName: "Delivery Location/ Delivery Center",
          field: "City",
          suppressSizeToFit: true,
          suppressMovable: true,
          resizable: true,
        },

        { headerName: "Offering", field: "Offering", suppressSizeToFit: true, suppressMovable: true, resizable: true },
        {
          headerName: "Sub Offering",
          field: "Process",
          suppressSizeToFit: true,
          suppressMovable: true,
          resizable: true,
        },
        {
          headerName: "Maturity Phase",
          field: "MaturityPhase",
          suppressSizeToFit: true,
          suppressMovable: true,
          resizable: true,
        },
        {
          headerName: "Accenture MD",
          field: "AccentureMD",
          suppressSizeToFit: true,
          suppressMovable: true,
          resizable: true,
        },
        { headerName: "Status", field: "Status", suppressSizeToFit: true, suppressMovable: true, resizable: true },
        {
          headerName: "Is Active",
          field: "IsActive",
          resizable: false,
          suppressFilter: true,
          suppressSizeToFit: true,
          suppressMovable: true,
          cellRenderer: (params) => {
            return `<label className="form-check">
                        <input className="form-check-input" type="checkbox" disabled ${params.value ? "checked" : ""}>
                        <span className="checkmark"></span>
                         </label>`;
          } /* , cellStyle: function (params) {
                        return { 'text-align': "left" };
                    } */,
        },
      ],
      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",
        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  dealpopup(e, data) {
    // e.currentTarget.className = "active"

    if (!_.isNil(data)) {
      // edit condition
      if (
        this.props.featureData.filter((data) => String(data.Submenu) === "Deals" && data.Operations.includes("Edit"))
          .length > 0
      ) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data, isDealPop: true });
      } else window.alert("Access Denied !!");
    } else {
      // add condition
      if (
        this.props.featureData.filter((data) => String(data.Submenu) === "Deals" && data.Operations.includes("Add"))
          .length > 0
      ) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data, isDealPop: true });
      } else window.alert("Access Denied !!");
    }
  }
  onClose(e) {
    this.setState({ viewmore: false, isDealPop: false });
  }

  getDeals() {
    axiosInstance
      .get(`${LocalApiBaseUrl}Deal/GetMasterDeals`)
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  GetAllMaturityPhase() {
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetMaturityPhase`, null, {
        params: {},
      })
      .then((response) => {
        this.setState({ MaturityPhase: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  GetAllClientRegion() {
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetAllClientRegion`, null, {
        params: { LanguageId: store.getState().CurrentFilter.languageData.id },
      })
      .then((response) => {
        this.setState({ ClientRegions: response.data });
       
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  componentDidMount() {
    this.GetAllClientRegion();
    this.GetAllMaturityPhase();
    this.getDeals();
    if (
      this.props.featureData.filter((data) => String(data.Submenu) === "Deals" && data.Operations.includes("Edit"))
        .length === 0
    ) {
      var columnDefs = this.state.columnDefs.filter((data) => String(data.headerName) !== "Edit");
      this.setState({
        columnDefs: columnDefs,
      });
    }

    axiosInstance
      .get(`${LocalApiBaseUrl}Industry/GetIndustryList`)
      .then((response) => {
        this.setState({ OperatingGroupOptions: response.data });
        
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  // shouldComponentUpdate() {
  //     this.getDeals();
  //     return true;
  // }

  handleSave(data) {
    // axiosInstance.post(`${LocalApiBaseUrl}Deal/AddEditDealInfo`, data)
    // .then((response) => {
    //     window.alert(response.data.StatusMessage)
    //     this.getDeals();

    // });
    let newDeal = [];
    // if(data.OrgEntityID==-1)
    // {
    data.Status = "New";

    // }else
    // {
    //     data.Status = "Approved"

    // }

    newDeal.push(data);
    axiosInstance
      .post(`${LocalApiBaseUrl}Deal/AddEditDealInfo`, newDeal, {
        params: { Action: "Create" },
      })
      .then((response) => {
        window.alert(response.data.StatusMessage);
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    this.getDeals();
  }
  handleHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (String(e.target.textContent) !== "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
        // this.props.onClose("from x");
      }
    } else {
      this.props.onClose(e);
    }
  }

  render() {
    const { featureData } = this.props;
    const {
      gridOptions,
      columnDefs,
      rowData,
      viewmore,
      isDealPop,
      MaturityPhase,
      rowSelected,
      ClientRegions,
      OperatingGroupOptions,
      IndustryOptions,
    } = this.state;
    return (
      <div className="main-data">
        {/* <h3 className="grid-heading">Deal List</h3> */}
        <div className="tableFilter">
          <div className="form-section">
            <div className="form-row">
              <div className="form-group col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  id="filter-text-box"
                  placeholder="Filter..."
                  onChange={(e) => {
                    this.onFilterTextBoxChanged(e);
                  }}
                />
              </div>
              {featureData.filter((data) => String(data.Submenu) === "Deals" && data.Operations.includes("Add"))
                .length > 0 && (
                  <div className="form-group col-lg-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={(e) => {
                        this.dealpopup(e);
                      }}
                    >
                      <span>
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </span>{" "}
                      Add Deal
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ag-theme-balham ag-table">
              <AgGridReact
                enableSorting={true}
                enableFilter={true}
                pagination={true}
                paginationPageSize={50}
                floatingFilter={true}
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                rowData={rowData}
                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
              ></AgGridReact>
            </div>
          </div>
        </div>
        {viewmore ? (
          <DealPopUp
            data={rowSelected}
            IndustryOptions={IndustryOptions}
            OperatingGroupOptions={OperatingGroupOptions}
            ClientRegions={ClientRegions}
            MaturityPhase={MaturityPhase}
            isDealPop={isDealPop}
            show={viewmore}
            onClose={this.onClose}
            onSave={this.handleSave}
            featureData={featureData}
          />
        ) : null}
      </div>
    );
  }
}
