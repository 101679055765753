import React, { useState } from "react";
import i18n from "i18next";
import PropTypes from "prop-types";

function FillDetails({
  handleSubmit,
  isSubmitDisabled,
  cxlId,
  oalId,
  remarks,
  setRemarks,
  showSuccessMsg,
}) {
  const handleFocus = (e) => {
    const target = e.target;
    target.parentNode.classList.add("active");
    target.setAttribute("placeholder", target.getAttribute("data-placeholder"));
  };

  // remove active class and placeholder
  const handleBlur = (e) => {
    const target = e.target;
    if (!target.value) {
      target.parentNode.classList.remove("active");
    }
    target.removeAttribute("placeholder");
  };

  return (
    <div className="fill-details">
      <h4 className="access-form-header">Fill Details</h4>
      <div className="fill-details-main">
        <div className="fill-details-col-1">
          <div
            id="floatContainer1"
            className={`float-container-cxloal float-container ${cxlId ? "active" : ""}`}
          >
            <label htmlFor="floatField1">{i18n.t("CXL ID*")}</label>
            <input
              value={cxlId}
              type="text"
              className="active"
              id="floatField1"
              data-placeholder="Enter here..."
              disabled
            />
          </div>

          <div
            id="floatContainer2"
            className={`float-container-cxloal float-container ${oalId ? "active" : ""}`}
          >
            <label htmlFor="floatField2">{i18n.t("OAL ID*")}</label>
            <input
              value={oalId}
              type="text"
              className="active"
              id="floatField2"
              data-placeholder="Enter here..."
              disabled
            />
          </div>
        </div>

        <div className="fill-details-col-2">
          <div id="floatContainer3" className="float-container-remarks float-container">
            <label htmlFor="floatField3">{i18n.t("Register Remarks*")}</label>
            <textarea
              value={remarks}
              type="text"
              className="active"
              id="floatField3"
              data-placeholder="Enter here..."
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e) => setRemarks(e.target.value)}
              maxLength={250}
            />
            <div className="char-count">{i18n.t("250 Characters")}</div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-end">
        <div className="fill-details-success-msg">
          {showSuccessMsg}
        </div>
        <button
          className="btn btn-primary float-right"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          {i18n.t("Submit")}
        </button>
      </div>
    </div>
  );
}

FillDetails.propTypes = {
  isClientAccess: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isSubmitDisabled: PropTypes.bool,
  cxlId: PropTypes.string,
  oalId: PropTypes.string,
  remarks: PropTypes.string,
  setRemarks: PropTypes.func,
};

export default FillDetails;
