import React, { useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import _ from "lodash";

 function MultiSelectDropDownComp({ 
  labelledBy, 
  options,
  value,
  onChangeFun,
  selectall,
  rowIndex,
  callBackOutsideClick,
  selectAllStatus,
  checkboxDisabled,
  callBackClickInside,
}) {
  //STATES

  const [dropdownStatus, setdropdownStatus] = useState(false);
  const [optionSource, setoptionSource] = useState([...options]);
  const [searchValue, setSearchValue] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  //USE EFFECTS

 





  const selectedNameList = () => {
    let selected = _.orderBy(options.filter((e) => e.checked === true), ['label']).map((e) => e.label);
    if(selected.length ===0)
    return labelledBy
    else if(selectAllStatus)
    return "All"
    else
    {
      let count=2
      let nameSelected=""
      if (selected.length === 1)
      nameSelected = selected[0]
      else
      {
        for(let x of selected)
        {
           nameSelected += x+", "
        }
      }
      nameSelected = nameSelected.replace(/,\s*$/, "")
      if(selected.length ===1)
      {
        return nameSelected.length>20? nameSelected.slice(0,17) + "...":nameSelected
      }
      else
      {
        let morecount = (selected.length - count)
        let names = nameSelected.length>20 ? nameSelected.slice(0,17) + "...":nameSelected
        let labelDisplay = morecount ===0 ? `${names}`:`${names} +${morecount} more`
         return labelDisplay
      }
    }
  };
  return (
    <>
      <div className="formsection-fields form-section">
    <div className="form-group border-group" >
      <label htmlFor="ClientName" className="form-label">
        {labelledBy}
      </label>
      <div className="customform-dropdown">
        <Dropdown id="role"  onToggle={(isOpen) => {
            if (!isOpen) {
              callBackOutsideClick()
            }
            else
            {
              callBackClickInside(rowIndex)
            }
          }}>
        <Dropdown.Toggle  id="dropdownButton" className={selectedNameList()===labelledBy?"not-selected":""}  >{selectedNameList()}</Dropdown.Toggle>
          <Dropdown.Menu >
            <div className="offering-droplistwrapper" >
            
              <>
              {/* {rowIndex === 1 &&   <div class="searchFilter user-profile-search">
                     <input
                       id="search"
                       placeholder="Search ..."
                       type="search"
                       value = {searchValue}
                       autocomplete="no"
                       onChange={((e)=>{setFilter(e)})}
                     />
                     </div>
                    } */}
                  <Dropdown.ItemText key={`index${rowIndex}`}>              
                   
                     {options.length>0 &&  searchValue === "" &&
                    <label className="form-check">
                    <input onChange={(e)=>selectall(e,rowIndex)} className="form-check-input" type="checkbox" checked={selectAllStatus} disabled={checkboxDisabled} />
                    <span className="checkmark"></span>
                    <span className="form-check-label">All</span>
                    </label>}
                    
                  </Dropdown.ItemText>

                  {options.length>0 && options.map((option,index) => {
                  return (
                  <Dropdown.ItemText key={`index${rowIndex}${index}`}>
                    <label className="form-check">
                    <input
                          onChange={(e) => onChangeFun(options,option,index)} 
                          checked={option.checked}
                          className="form-check-input"
                          type="checkbox"
                          key={`index${rowIndex}${index}`}
                          disabled={checkboxDisabled}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">{option.label}</span>
                    </label>
                  </Dropdown.ItemText>
                   );
                })}
              </>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      </div>
    </div>
    </>
  );
}

export default React.memo(MultiSelectDropDownComp);