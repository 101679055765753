import React from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import axiosInstance from "../Shared/interceptor";
import { regexForTextAreas } from "../Shared/Constant";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class OfferingPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      Offering: {
        OfferingId: "0",
        Offering: "",
        RequestorRemarks: "",
        ApproverRemarks: "",
        Process: "",
        ApprovalAction: true,
        ApproveType: "",
        IsActive: false,
        CreatedUser: null,
        CreatedTS: null,
        UpdatedUser: null,
        UpdatedTS: null,
        DealInfoes: null,
      },
      errors: [],
      formIsValid: false,
    };

    this.onOfferingChange = this.onOfferingChange.bind(this);
    this.onApproverRemarkChange = this.onApproverRemarkChange.bind(this);
    this.onRequestorRemarksChange = this.onRequestorRemarksChange.bind(this);
    this.onProcessChange = this.onProcessChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  onOfferingChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    var Offering = this.state.Offering;
    Offering.Offering = event.target.value;
    this.setState({
      Offering: Offering,
    });
  }

  onApproverRemarkChange(event) {
    if (String(this.props.type) === "Approve") {
      let errors = {};
      this.setState({
        errors: errors,
      });
      var Offering = this.state.Offering;
      Offering.ApproverRemarks = event.target.value;
      Offering.ApprovalAction = true;
      this.setState({
        Offering: Offering,
      });
    } else {
      let errors = {};
      this.setState({
        errors: errors,
      });
      Offering = this.state.Offering;
      Offering.ApproverRemarks = event.target.value;
      Offering.ApprovalAction = false;
      this.setState({
        Offering: Offering,
      });
    }
  }

  onRequestorRemarksChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    var Offering = this.state.Offering;
    Offering.RequestorRemarks = event.target.value;
    this.setState({
      Offering: Offering,
    });
  }

  onProcessChange(event) {
    const { Offering } = this.state;
    let errors = {};
    this.setState({
      errors: errors,
    });
    Offering.Process = event.target.value;
    this.setState({
      Offering: Offering,
    });
  }

  onStatusChange(event) {
    const { Offering } = this.state;
    Offering.IsActive = event.target.checked;
    this.setState({
      Offering: Offering,
    });
  }
  handleSave(e) {
    const { Offering } = this.state;
    if (this.validateForm()) {
      this.props.onSave(Offering);

      this.handleHide();
    }
  }
  handleHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
    this.props.onSave();
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  componentDidMount() {
    this.setState({
      show: this.props.show,
    });
  }

  validateForm() {
    const { Offering } = this.state;
    let errors = {};
    let formIsValid = true;
    if (Offering["ApproverRemarks"] == null) {
      formIsValid = false;
      errors["ApproverRemarks"] = "Approver Remarks are required";
    } else if (!Offering["ApproverRemarks"].trim()) {
      formIsValid = false;
      errors["ApproverRemarks"] = "Approver Remarks are required";
    } else {
      var isValid = regexForTextAreas.test(Offering["ApproverRemarks"]);
      if (!isValid) {
        formIsValid = false;
        errors["ApproverRemarks"] = "Approver Remarks contains Special characters";
      }
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  componentWillMount() {
    if (this.props.data) {
      this.setState({
        Offering: this.props.data[0],
      });
    }
  }

  render() {
    const { data } = this.props;
    const { show, Offering, errors } = this.state;
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={show}
          onHide={this.handleHide.bind(this)}
          backdrop="static"
          classOffering="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile" flag>
              Approval
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">
                      Offering Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      readOnly
                      onChange={this.onOfferingChange}
                      value={Offering.Offering}
                    />
                    <span className="errorMsg">{errors.Offering}</span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">
                      Process<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      readOnly
                      onChange={this.onProcessChange}
                      value={Offering.Process}
                    />
                    <span className="errorMsg">{errors.Process}</span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">Requestor Remarks</label>
                    <textarea
                      maxLength="200"
                      readOnly
                      className="form-control"
                      id="remark"
                      onChange={this.onRequestorRemarksChange}
                      value={Offering.RequestorRemarks}
                    />
                    <span className="errorMsg">{errors.RequestorRemarks}</span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">
                      Approver Remarks<span className="required">*</span>
                    </label>
                    <textarea
                      maxLength="200"
                      className="form-control"
                      id="remark"
                      onChange={this.onApproverRemarkChange}
                      value={Offering.ApproverRemarks}
                    />
                    <span className="errorMsg">{errors.ApproverRemarks}</span>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                {!data ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Create"
                  />
                ) : (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Update"
                  />
                )}
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
