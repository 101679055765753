import React from 'react';
import { Modal } from 'react-bootstrap';
import { LocalApiBaseUrl, getPrevMonth } from '../Shared/Constant';
import i18n from "i18next";
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";


export default class RemarksPopUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: this.props.show, params: {
                Status: null,
                MonthDt: null,
                DealID: null,
                Remarks: null
            },
            remarks: "",
            filter: { OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null }, postData: []
        }

        this.handleHide = this.handleHide.bind(this);
        this.handleRejectHide = this.handleRejectHide.bind(this);
        this.handleApproveHide = this.handleApproveHide.bind(this);
    
    
    }
    getRecordStatus(offeringIdparam = { Client: 0, process: 0, Locations: 0, Month: getPrevMonth() }) {

        let filterProcess = offeringIdparam.process == undefined ? 0 : offeringIdparam.process;
        let filterLocations = offeringIdparam.Locations == undefined ? 0 : offeringIdparam.Locations;
        let filterClient = offeringIdparam.Client == undefined ? 0 : offeringIdparam.Client;
        let filterDate = this.props.filterDetails == undefined ? 0 : this.props.filterDetails.Month;
        let dateArray = offeringIdparam.Month == 0 ? [] : offeringIdparam.Month.split("-")
        let todayDate = ""
        if (dateArray.length == 2) {
            todayDate = "1-" + dateArray[0] + "-" + dateArray[1]
        }
        else {
            todayDate = offeringIdparam.Month;
        }
        let publishStatus = "";
        let saveStatus = "";
        let submitStatus = "";
        if (filterProcess != 0 && filterLocations != 0 && filterClient != 0 && todayDate != 0) {
            let filterLocations = this.props.filterDetails.Locations;
            let filterClient = this.props.filterDetails.Client;
            let filterProcess = this.props.filterDetails.process;
            let filterDate = this.props.filterDetails.Month;
            if (filterClient && filterLocations && filterDate && filterProcess) {
                axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetRecordStatus`, {
                    params: { OrgEntityID: filterClient, LocationID: filterLocations, MonthDT: filterDate, OfferingID: filterProcess }
                })
                    .then((response) => {

                        if (response.data[3] != undefined) {
                            let remarks = response.data[3]
                            this.setState({ remarks: remarks })
                        }



                    })
                    .catch((error) => {
                        trycatchAlertPopup(error);
                      });
              
              
            }
        }

    }

    componentDidMount() {
        this.getRecordStatus(this.props.filterDetails);
    }

    handleHide(e) {


        this.setState({ show: false });
        if (e != undefined) {

            if ((e.target.textContent != "×")) {
                this.props.onClose(e);
            }
            else {
                this.props.onClose(e);
            }
        } else {
            this.props.onClose(e);
        }

    }
    handleRejectHide(e) {


        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onRejectClose();
            }
        } else {
            this.props.onRejectClose();
        }

    }
    handleApproveHide(e) {

        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onApproveClose();
            }
        } else {
            this.props.onApproveClose();
        }

    }
    handleClose(e) {
        this.handleHide()
    }


    render() {

        return (
                <Modal show={this.state.show} onHide={this.handleHide} className="modal fade" backdrop = "static" size="medium" id="dealpopup">
                    <Modal.Header closeButton>
        <h4 className="modal-title" id="dealprofile">{i18n.t("Rejection_Remarks")}</h4>
                    </Modal.Header>
                    <Modal.Body>
                        
                            <form>
                                <div className="form-section">
                                    <div className="form-group col-lg-12">
                                        <div className="border-box p-4">
                                            {this.state.remarks}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal-btn">
                            <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleClose()} value={i18n.t("OK")} />
                        </div>
                    </Modal.Footer>
                </Modal>
        )
    }
}