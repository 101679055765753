import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { Tabs } from "react-web-tabs";
import LoadingOverlay from "react-loading-overlay";

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import SynopsLoader from "../Shared/SynopsLoader";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { LocalApiBaseUrl } from "../Shared/Constant";

class BenchmarkSelectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndustryId: props.industryId,
      selectedRegionId: props.regionId,
      selectedBenchmark: props.benchmark,
      benchmarkFilterData: [],
    };
  }

  componentDidMount() {
    this.fetchBenchmarkFilterData();
  }

  fetchBenchmarkFilterData = () => {
    const { offeringDetails, subOfferingDetails, startDate, endDate } = this.props;
    const requestData = {
      offeringName: offeringDetails.name,
      towerName: subOfferingDetails.name,
      timeFrameStartDate: startDate,
      timeFrameEndDate: endDate,
    };

    this.setState({ isAPILoading: true });

    // FETCH THE DATA FROM THE API
    return axiosInstance
      .post(`${LocalApiBaseUrl}BAT/GetBenchmarkFilterData`, requestData)
      .then((response) => {
        let benchmarkFilterData = [];
        if (!_.isEmpty(response.data)) {
          benchmarkFilterData = response.data;
        } else {
          const errMsg = "BENCHMARK FILTER DATA IS EMPTY";
         
          window.alert(errMsg);
        }
        this.setState(
          {
            benchmarkFilterData,
            isAPILoading: false,
          },
          () => {
            if (_.isEmpty(benchmarkFilterData)) {
              this.handelHideModal();
            }
          }
        );
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ isAPILoading: false });
      });
  };

  handelHideModal = (dataForParentComponent = {}) => {
    const { onClose } = this.props;
    onClose(dataForParentComponent);
  };

  handleChangeSelection = (fieldName, selectedValue) => {
    let { selectedIndustryId, selectedRegionId, selectedBenchmark } = this.state;
    if (fieldName === "industry") {
      selectedIndustryId = selectedValue;
      selectedRegionId = "";
      selectedBenchmark = "";
    } else if (fieldName === "region") {
      selectedRegionId = selectedValue;
      selectedBenchmark = "";
    } else if (fieldName === "benchmark") {
      selectedBenchmark = selectedValue;
    }

    this.setState(
      {
        selectedIndustryId,
        selectedRegionId,
        selectedBenchmark,
      },
      () => {
        if (fieldName === "benchmark") {
          this.handelHideModal({
            selectedIndustryId,
            selectedRegionId,
            selectedBenchmark,
          });
        }
      }
    );
  };

  render() {
    const { isAPILoading, benchmarkFilterData, selectedIndustryId, selectedRegionId, selectedBenchmark } = this.state;

    const currentlySelectedIndustryObj = _.find(benchmarkFilterData, ["industryId", _.toNumber(selectedIndustryId)]);
    let currentlySelectedRegionObj = {};
    if (!_.isEmpty(currentlySelectedIndustryObj)) {
      currentlySelectedRegionObj = _.find(currentlySelectedIndustryObj.regions, [
        "regionId",
        _.toNumber(selectedRegionId),
      ]);
    }

    return (
      <>
        {/* IF API IS LOADING THEN SHOW THE LOADER */}
        {isAPILoading && <LoadingOverlay spinner={<SynopsLoader />} active />}

        {!_.isEmpty(benchmarkFilterData) && (
          <Modal
            show
            centered
            onHide={() => this.handelHideModal()}
            backdrop="static"
            dialogClassName="modal-90w"
            className="benchmarkModal"
          >
            <Modal.Header closeButton className="justify-content-center">
              <span className="header-modal">Select a Benchmark to compare…</span>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Tabs>
                  <div className="row">
                    <div className="col-lg-3 benchmark-col">
                      <div className="col-heading">Industry</div>
                      <ul>
                        {_.map(benchmarkFilterData, (eachItem) => {
                          const industryId = eachItem.industryId;
                          const industryName = eachItem.industryName;
                          const isSelected = _.toNumber(selectedIndustryId) === industryId;
                          return (
                            <li key={industryName} className={isSelected ? "selected" : ""}>
                              <label className="form-check">
                                {" "}
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="industry"
                                  id={`IND-${industryName}`}
                                  value={industryName}
                                  checked={isSelected}
                                  onChange={() => this.handleChangeSelection("industry", industryId)}
                                />
                                <span className="checkmark"></span>
                                <span className="form-check-label">{industryName}</span>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                    {!_.isEmpty(currentlySelectedIndustryObj) && (
                      <div className="col-lg-3 benchmark-col">
                        <div className="col-heading">Region</div>
                        <ul>
                          {_.map(currentlySelectedIndustryObj.regions, (eachRegion) => {
                            const regionId = eachRegion.regionId;
                            const regionName = eachRegion.regionName;
                            const isSelected = _.toNumber(selectedRegionId) === regionId;
                            return (
                              <li key={regionName} className={isSelected ? "selected" : ""}>
                                <label className="form-check">
                                  {" "}
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="region"
                                    id={`REG-${regionName}`}
                                    value={regionName}
                                    checked={isSelected}
                                    onChange={() => this.handleChangeSelection("region", regionId)}
                                  />
                                  <span className="checkmark"></span>
                                  <span className="form-check-label">{regionName}</span>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}

                    {!_.isEmpty(currentlySelectedRegionObj) && (
                      <div className="col-lg-6 benchmark-col">
                        <div className="col-heading">Metric Benchmark</div>
                        <ul>
                          {_.map(currentlySelectedRegionObj.benchmarks, (eachBenchmark) => {
                            const benchmarkName = eachBenchmark;
                            return (
                              <li
                                key={benchmarkName}
                                className={selectedBenchmark === benchmarkName ? "selected" : ""}
                                onClick={() => this.handleChangeSelection("benchmark", benchmarkName)}
                              >
                                <label>
                                  {_.toLower(benchmarkName) === "lower quartile" ? "Bottom Quartile" : benchmarkName}
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </Tabs>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
}

export default BenchmarkSelectionModal;
