import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import i18n from 'i18next';
import ReactTooltip from 'react-tooltip';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import OfferingSubOffering from './OfferingSubOffering';
import { LocalApiBaseUrl, IOJData, trycatchAlertPopup } from './Constant';
import { HasValue, filterSelectedSubOffering } from './commonFunctions';
import { store } from '../reducers/configureStore';
import {TRACKING_MODULES, PARAM_NAMES, userTracker, createParamObject} from "./Common";

import { updateSyncFilter } from './FiltersHelper';
import axiosInstance from './interceptor';

import DatabaseWhite from '../Images/LeftPanelIcons/database-white.svg';
import { SetOMIDStatus, InitializeOMIDListData } from '../actions/JourneyActions';
import { saveconfigofferingData } from '../actions/ClientAndFilterActions';
import SynopsLoader from './SynopsLoader';
import SnapshotProfile from "./snapshotProfile";


class ConfiguratorFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Client: '',
      MaturityPhase: this.props.MaturityPhase,
      AttributeIsrelator: [],
      IsRelatorBlockForOffering: false,
      SubOfferingValues: [],
      ssSelectClient : i18n.t("Select_Client"),
      processData: [],
      LocValues: [],
      locationValueNew: [],
      LocationData: [],
      selectedOMIDs: [],
      OMIDProcess: '',
      OMIDJSON: {},
      filteredOMIDJSON: {},
      searchedOMID: '',
      locationOmidEnable: false,
      isOverlayLocationEnabled: false,
      offeringData: [],
      filterDetails: {
        selectedOffering: '',
        selectedSubOffering: '',
        selectedLocation: '',
        selectedMonth: '',
        selectedOfferingName: '',
        selectedSubOfferingName: '',
        selectedLocationName: '',
        selectedMonthName: '',
        selecetedOMID: '',
        selectedOMIDProcess: '',
      },
      subOfferingsList: [],
      monthData: [],
      omidsLengthGTOne: false,
      isApiLoading: false,
      profileList:[],
      defaultSelectedProfileName:[]

    };
    this.tooltipRef = React.createRef();
    this.getOfferings = this.getOfferings.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.getMonths = this.getMonths.bind(this);
    this.OfferingValues = [];
    this.LocationValues = [];
    this.monthValues = [];
    this.MaturityPhase = '';
    this.afterAPICall = this.afterAPICall.bind(this);
  }

  handleDefaultSnapshotScreen = ()=>{
    const { location,onTabChange,compDetails } = this.props
    if(location.state !== undefined && location.state.Tab !== undefined && location.state.Tab !=="")
    {
      this.setState({
        snapshotClicked:true
      },()=>{
        if(this.props.compDetails !== location.state.Tab)
        onTabChange(location.state.Tab)
      })
      
    }
    else
    {
      this.setState({
        snapshotClicked:false
      })
    }
   
  }
  componentDidUpdate(prevProps, prevState) {

    ReactTooltip.rebuild()
    const { MaturityPhase, SelectedFilter, selectedClientId, languageData, filterClient, compDetails,location} = this.props;
    const { filterDetails, offeringData, LocationData, subOfferingsList,snapshotClicked,OMIDJSON } = this.state;
    
    if( !snapshotClicked &&location.state !== undefined && (
      (
        prevProps.location.state === undefined 
      ) ||
      (location.state.Tab !== undefined && 
      location.state.ClientID !== undefined &&
      prevProps.location.state.Tab !== undefined &&
      prevProps.location.state.ClientID !== undefined &&
      prevProps.location.state.Tab !== "" &&
      location.state.Tab !=="" &&
      location.state.ClientID !== prevProps.location.state.ClientID
      )
     )                                                 //WHEN CLIENT SELECT HAPPENS AT THE HEADER
    )
    {
      this.handleDefaultSnapshotScreen()
    }
    
    if (prevProps.languageData.id !== languageData.id || (selectedClientId !==0 && String(prevProps.selectedClientId) !== String(selectedClientId))) { //IF CLIENT OR LANGUAGE ID CHANGES
      this.setState({
       // selectedDataType: this.props.FetchFromBAT ? "BenchmarkAnalytics" : "frontDoorConfigData",

        offeringData:[],
        profileList:[]
      },()=>{
        this.loadProfileOnBasisOfFilter();
        this.getOfferings();

       
      })
      

    }

    if (
      offeringData.length>0 &&
      prevState.offeringData !== offeringData &&
      SelectedFilter.OfferingId &&
      SelectedFilter.OfferingId !== "All"                         //CALLING OFFERING CHANGE INITIALLY
    ) {
      this.onOfferingChange({ target: { value: SelectedFilter.OfferingId } },SelectedFilter.OfferingName);
    }
    if (
      SelectedFilter &&
      prevProps.SelectedFilter &&
      SelectedFilter.OfferingId !== "All" &&
      (offeringData.length>0 &&                                  // WHEN THERE IS CHANGE IN THE OFFERING ID
        SelectedFilter.OfferingId &&
        String(SelectedFilter.OfferingId) !== String(prevProps.SelectedFilter.OfferingId)) 
    ) {
      this.onOfferingChange({ target: { value: SelectedFilter.OfferingId } },SelectedFilter.OfferingName);
    }
    else if (
      prevProps.SelectedFilter &&
      SelectedFilter &&
      SelectedFilter.ProcessId !== "All" &&
      filterDetails.selectedSubOffering !== "All" &&
      (subOfferingsList.length &&
        SelectedFilter.ProcessId &&
        SelectedFilter.ProcessId !== prevProps.SelectedFilter.ProcessId &&
        SelectedFilter.ProcessId !== filterDetails.selectedSubOffering)     //WHEN THERE IS CHANGE IN THE SUBOFFERING 
    ) {
      this.onSubofferingChange({ target: { value: SelectedFilter.ProcessId } });
    }
    else if(
      prevProps.SelectedFilter &&
      SelectedFilter &&
      (LocationData.length &&
        SelectedFilter.LocationId &&
        SelectedFilter.LocationId !== prevProps.SelectedFilter.LocationId &&
        SelectedFilter.LocationId !== filterDetails.selectedLocation) 
        && snapshotClicked
    ) {
      if(SelectedFilter.LocationOMID.length >0 &&  SelectedFilter.LocationOMID.filter(a=>a==="All").length>0)  //WHEN SNAPSHOT IS CLICKED IN IOJ SCREEN
      {
        this.fetchOMIDRecords(selectedClientId, SelectedFilter);      
      }
      else if (!SelectedFilter.LocationOMID.filter(a=>a==="All").length>0)  // WHEN SNAPSHOT IS CLICK NOT IN IOJ SCREEN
      {
        this.onLocationChange({ target: { value: SelectedFilter.LocationId } });  
      }
      else{  // SELECTING OMIDS ON SNAPSHOT CHANGE
        this.onOMIDChange("All", SelectedFilter.LocationId)    
      }
    }

    if (
      prevProps.SelectedFilter &&
      SelectedFilter &&
      SelectedFilter !== prevProps.SelectedFilter &&
      (SelectedFilter.OfferingId !== prevProps.SelectedFilter.OfferingId ||
        SelectedFilter.ProcessId !== prevProps.SelectedFilter.ProcessId) &&
        SelectedFilter.ProcessId &&
      SelectedFilter.OfferingId !== "All" &&
      SelectedFilter.ProcessId !== "All" && 
      SelectedFilter.LocationOMID.filter(a=>a==="All").length ===0 &&      // GETTING LOCATIONS IN NON IOJ SCREEN
      !snapshotClicked
    ) {
      this.getLocations(this.state.Client, SelectedFilter.ProcessId);  
    }

    if (
      LocationData.length &&
      prevState.LocationData !== LocationData &&           //DIFFERENT LOCATION IS SELECTED IN NON IOJ SCREEN
      SelectedFilter.LocationId &&
      !filterDetails.selectedLocation && !snapshotClicked && filterDetails.selectedSubOfferingName !=="" && filterDetails.selectedOfferingName !==""
    ) {
      this.onLocationChange({ target: { value: SelectedFilter.LocationId } });      
    }

    if (this.state.Client !== selectedClientId) {      //DIFFERENT CLIENT SELECTED
      this.setState({ Client: selectedClientId });
      this.setState({
        selectedOMIDs: [],
        OMIDJSON: {},
        filteredOMIDJSON: {},
        offeringData: [],
        filterDetails: {
          selectedOffering: '',
          selectedSubOffering: '',
          selectedLocation: '',
          selectedMonth: '',
          selectedOfferingName: '',
          selectedSubOfferingName: '',
          selectedLocationName: '',
          selectedMonthName: '',
          selecetedOMID: '',
        },
        LocationData: [],
        subOfferingsList: [],
        monthData: [],
        MaturityPhase: '',
      });
    }


     if (prevProps.compDetails !== compDetails && compDetails === "Journey Initiatives") {   // WHEN NAVIGATING SCREEN FRON NON IOJ SCREEN TO IOJ SCREEN
      if(offeringData.length>0 && SelectedFilter.OfferingId !== "All" && SelectedFilter.ProcessId !== "All" && SelectedFilter.OfferingId !=="" && String(SelectedFilter.OfferingId) !== "0")
      this.fetchOMIDRecords(selectedClientId, SelectedFilter);
    }

    if (
      offeringData.length>0 &&
      prevProps.compDetails !== compDetails
      && compDetails !== 'Journey Initiatives'
      && prevProps.compDetails === 'Journey Initiatives'       // NAVIGATING FROM IOJ SCREEN TO NON IOJ SCREEN
    ) {
      const { filterDetails } = this.state;
      if (filterDetails.selectedOffering === 'All') {
        if (offeringData.length === 1) {
          filterDetails.selectedOffering = '';
          filterDetails.selectedOfferingName = i18n.t('Select_Offering');
          filterDetails.selectedSubOffering = '';
          filterDetails.selectedSubOfferingName = i18n.t('Select_Sub_Offerings');
          filterDetails.selectedLocation = '';
          filterDetails.selectedLocationName = i18n.t('Select_Location');
          this.onOfferingChange({ target: { value: offeringData[0].OfferingID } });
        }
        else{
          filterDetails.selectedOffering = '';
          filterDetails.selectedOfferingName = i18n.t('Select_Offering');
          filterDetails.selectedSubOffering = '';
          filterDetails.selectedSubOfferingName = i18n.t('Select_Sub_Offerings');
          filterDetails.selectedLocation = '';
          filterDetails.selectedLocationName = i18n.t('Select_Location');
        }

      } else if (filterDetails.selectedSubOffering === 'All') {
        filterDetails.selectedSubOffering = '';
        filterDetails.selectedSubOfferingName = i18n.t('Select_Sub_Offerings');
        filterDetails.selectedLocation = '';
        filterDetails.selectedLocationName = i18n.t('Select_Location');
      } else if (filterDetails.selectedLocation === 'All') {
        filterDetails.selectedLocation = '';
        filterDetails.selectedLocationName = i18n.t('Select_Location');
      }
      this.setState({ filterDetails });
      if (!SelectedFilter.Month && SelectedFilter.LocationId) {
        this.getMonths(this.state.Client, SelectedFilter.ProcessId, SelectedFilter.LocationId);
        if(LocationData.length === 0)
        {
          this.getLocations(this.state.Client, SelectedFilter.ProcessId);  
        }
      }
    }

    if (prevProps.MaturityPhase !== MaturityPhase) {
      this.setState({ MaturityPhase });
    }
    ReactTooltip.rebuild();
    if(snapshotClicked)
    {
      
      if(compDetails === "Journey Initiatives" && 
      SelectedFilter.ProcessId !== "" && String(SelectedFilter.ProcessId) !== "0" &&
        SelectedFilter.ProcessId === prevProps.SelectedFilter.ProcessId && 
        prevProps.SelectedFilter.LocationId !== "" &&
        SelectedFilter.LocationId !== "" && 
        String(SelectedFilter.LocationId) !== "0" &&
        SelectedFilter.LocationId !== prevProps.SelectedFilter.LocationId && 
        OMIDJSON.IOJOMIDs !== undefined  &&
        OMIDJSON.IOJOMIDs.length>0
        )                                                         //SAME OFFERING AND SUBOFFERING BUT LOCATION CHANGED
        {
          this.onOMIDChange("All", SelectedFilter.LocationId)
        }
    }
  }

  validateForm() {
    const user = this.state;
    const errors = {};
    let formIsValid = true;

    if (!user.Client) {
      formIsValid = false;
    }

    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }

    return formIsValid;
  }

  toggleClick = () => {
    const details = { ...this.props.SelectedFilter };
    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .post(`${LocalApiBaseUrl}IOJJourneyInitiative/CheckAndUpdateToggleButton`, {
        orgEntityID: this.state.Client,
        offeringID: details.ProcessId,
        offeringName: details.OfferingName,
        locationID: details.LocationId,
        omid: details.LocationOMID[0],
      })
      .then((response) => {
        this.setState({ isApiLoading: false });
        store.dispatch(SetOMIDStatus(response.data));
        if (this.props.OMIDEnabled == true) {
          window.alert(i18n.t('Toggle_On_Alert'));
        }
      })
      .catch((error) => {
        this.setState({
          isApiLoading: false,
        });
        trycatchAlertPopup(error);
      });
  };

  async fetchOMIDRecords(Client, syncFilter) {
    const { filterDetails,snapshotClicked } = this.state;
    if (
      this.props.compDetails === 'Journey Initiatives'
      && syncFilter.ProcessId
      && syncFilter.ProcessId != '0'
      && syncFilter.ProcessId != 'All'
    ) {
      let filteredOMIDs = [];
      let selectedOMIDs = [];
      let filteredOMIDJSON = {};
      syncFilter.LocationOMID = snapshotClicked? ["All"]: selectedOMIDs;
      const OMIDJSON = (filteredOMIDJSON = await this.getOMIDLocations(Client, syncFilter.ProcessId));
      if (syncFilter.LocationId && HasValue(syncFilter.LocationId) && syncFilter.LocationId !== 'All') {
        selectedOMIDs.push('All');
        syncFilter.LocationOMID = selectedOMIDs;
        filteredOMIDs = OMIDJSON.IOJOMIDs.filter((e) => e.locID == syncFilter.LocationId)[0];
        if (filteredOMIDs !== undefined && filteredOMIDs.IOJOMIDDatas !== undefined && filteredOMIDs.IOJOMIDDatas !== null && filteredOMIDs.IOJOMIDDatas.length === 0) {
          syncFilter.isOMIDAvailable = false;
        } else {
          syncFilter.isOMIDAvailable = true;
        }
      }
      const filterData = {
        offering: syncFilter.OfferingId,
        process: syncFilter.ProcessId,
        Locations: syncFilter.LocationId,
        Month: syncFilter.Month,
        LocationOMID: syncFilter.LocationOMID,
      };
      updateSyncFilter(syncFilter);
      this.props.applyFilters(filterData);
      filterDetails.selecetedOMID = 'All';
      this.setState({
        selectedOMIDs, OMIDJSON, filteredOMIDJSON, filterObj: filterData, searchedOMID: '',
      },()=>{
        if(!this.state.snapshotClicked && !syncFilter.LocationId){
          if(this.state.OMIDJSON.IOJOMIDs.length === 1){
             this.onOMIDChange('All', this.state.OMIDJSON.IOJOMIDs[0].locID)
          }        
      }
        if(this.state.snapshotClicked)
        {
          this.onOMIDChange("All", syncFilter.LocationId)
        }
      });
    }
  }
  onOMIDChange = (OMID_ID, loc_ID, Process) => {
    const selectedOMIDs = [];
    let filteredOMIDs = [];
    let OMIDProcess;
    let totalOmids;
    const { filterDetails } = this.state;
    const syncFilter = { ...this.props.SelectedFilter };
    const locationChanged = syncFilter.LocationId !== loc_ID;
    syncFilter.Month = locationChanged ? '' : syncFilter.Month;
    syncFilter.LocationId = loc_ID;

    selectedOMIDs.push(OMID_ID);
    OMIDProcess = Process;

    if (loc_ID === 'All') {
      syncFilter.isOMIDAvailable = null;
      syncFilter.LocationOMID = ['All'];
      filterDetails.selecetedOMID = 'All';
      filterDetails.selectedLocationName = i18n.t('All_Locations');
      filterDetails.selectedLocation = 'All';
      if (locationChanged) {
        filterDetails.selectedMonth = '';
        filterDetails.selectedMonthName = i18n.t('Select_Month');
      }
    } else {
      syncFilter.LocationOMID = selectedOMIDs;
      filterDetails.selecetedOMID = OMID_ID;
      filterDetails.selectedOMIDProcess = Process;
      filterDetails.selectedLocation = loc_ID;
      if (locationChanged) {
        filterDetails.selectedMonth = '';
        filterDetails.selectedMonthName = i18n.t('Select_Month');
      }
      filteredOMIDs = this.state.OMIDJSON.IOJOMIDs.filter((e) => {
        if (e.locID == loc_ID) {
          filterDetails.selectedLocationName = e.locname;
          return e;
        }
      })[0];
      if (filteredOMIDs !== undefined && filteredOMIDs.IOJOMIDDatas !== undefined && filteredOMIDs.IOJOMIDDatas != null && filteredOMIDs.IOJOMIDDatas.length > 1) {
        totalOmids = true;
      }
      if (filteredOMIDs !== undefined && filteredOMIDs.IOJOMIDDatas !== undefined && filteredOMIDs.IOJOMIDDatas !== null && filteredOMIDs.IOJOMIDDatas.length === 0) {
        syncFilter.isOMIDAvailable = false;
      } else {
        syncFilter.isOMIDAvailable = true;
      }
    }
    let filterData = {
      offering: syncFilter.OfferingId,
      process: syncFilter.ProcessId,
      Locations: syncFilter.LocationId,
      Month: syncFilter.Month,
      LocationOMID: syncFilter.LocationOMID,
    };
    this.setState({ selectedOMIDs, filterObj: filterData, omidsLengthGTOne: totalOmids, OMIDProcess },()=>{
      // Set the Filter values n REDUX to sync the other pages filters
      
      updateSyncFilter(syncFilter);
      this.props.applyFilters(filterData);
      this.loadProfileOnBasisOfFilter();
      if(this.state.snapshotClicked)
      {
        this.setState({
          snapshotClicked:false
        })
      }
          });


            };

  async getOMIDLocations(Client, offering) {
    // API CALL TO GET OMID LOCATION DATA
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetJourneyOMIDs`, {
          OrgEntityID: Client,
          OfferingID: offering,
          LanguageID: this.props.languageData.id,
          IsConfigurator: true,
        })
        .then((response) => {
          store.dispatch(InitializeOMIDListData(response.data.IOJOMIDs));
          resolve(response.data);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    });
  }

  searchOMID = (event) => {
    const filteredOMIDJSON = {};
    filteredOMIDJSON.IOJOMIDs = this.state.OMIDJSON.IOJOMIDs.filter((client) => {
      if (client.locname.toLowerCase().indexOf(event.target.value.toLowerCase().trim()) !== -1) {
        return client;
      }
      return null;
    });
    this.setState({ filteredOMIDJSON, searchedOMID: event.target.value });
  };

  clearSearchOMID = () => {
    this.setState({ searchedOMID: '', filteredOMIDJSON: this.state.OMIDJSON });
  };

  getOfferings(Client) {
    const {
      SelectedFilter, compDetails, saveconfigofferings, clientID,
    } = this.props;
    let offeringData = [];
    return new Promise((resolve,reject)=>{
    if (clientID) {
      axiosInstance
        .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
          params: { OrgEntityID: clientID, LanguageID: store.getState().CurrentFilter.languageData.id, featureName: 'Configurator' },
        })
        .then((response) => {
          /* For Token renewl end */
          offeringData = response.data;
          saveconfigofferings(response.data);
          store.dispatch({
            type: 'SAVE_CONFIGURATOR_OFFERING_DETAILS',
            payload: response.data,
          });

          this.setState({ offeringData: response.data, profileList:[] }, async () => {
              this.loadProfileOnBasisOfFilter()
              if (SelectedFilter && SelectedFilter.OfferingId && SelectedFilter.OfferingId !== "All") {
                //this.onOfferingChange({ target: { value: SelectedFilter.OfferingId } },SelectedFilter.OfferingName)
              }
              await this.getAllProfileAgainstUser();
              resolve();
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          store.dispatch({
            type: 'SAVE_CONFIGURATOR_OFFERING_DETAILS',
            payload: [],
          });
          reject();
        });
    }

  })

}

  /**
   * Method Set the snapshots (Filter snap shots that can be set from the Front door screen)
   * If Default snap shot exist set the pulse filters based on that
   */

  changeHeaderText = (selectedOption) => {
    this.props.changeHeaderText(selectedOption);
  };

  getLocations(Client, offering) {
    const cityArray = [];
    let LocationData = [];
    const { filterDetails } = this.state;
    const { SelectedFilter, compDetails } = this.props;
    return new Promise((resolve,reject)=>{
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetLocation`, {
        params: {
          OrgEntityID: Client,
          OfferingID: offering,
          LanguageID: store.getState().CurrentFilter.languageData.id,
        },
      })
      .then((response) => {
        LocationData = response.data;
        let locationName;
        const locationId = store.getState().CurrentFilter.SyncFilter.LocationId;

        LocationData.filter((eachValue) => {
          if (eachValue.LocationID == locationId) {
            locationName = eachValue.City_En;
          }
        });
        store.dispatch({
          type: 'LOCATION_DETAILS_CURRENTFILTER',
          payload: { LocationId: locationId, locationName },
        });

        this.setState({ LocationData }, async () => {
          if(!this.state.snapshotClicked && !SelectedFilter.LocationId && compDetails !== "Journey Initiatives"){
            if(this.state.LocationData.length === 1){
              await this.onLocationChange({ target: { value: this.state.LocationData[0].LocationID } });
            }   
        }
          if (
            SelectedFilter &&
            SelectedFilter.LocationId &&
            SelectedFilter.LocationId !== 'All' &&
            String(SelectedFilter.OfferingId) === String(filterDetails.selectedOffering) &&
            String(SelectedFilter.ProcessId) === String(filterDetails.selectedSubOffering) &&
            compDetails !== "Journey Initiatives"
          ) {
            this.onLocationChange({ target: { value: SelectedFilter.LocationId } });
            resolve()
          } else {
            if (SelectedFilter && SelectedFilter.LocationId === "All" && compDetails === "Journey Initiatives") {
              this.onLocationChange({ target: { value: "All" } });
            }
            resolve()
          }

        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        reject()
      });
    })

  }
  getCategoriesLimit = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetAppConfig`)
      .then((response) => {
        const { data } = response;
        const categoriesLimit = parseInt(
          data.filter((a) => a.ConfigKey === 'MultiLevelMetricsCount')[0]
            .ConfigValue,
        );
        this.props.setMultiLevelCategoriesLimit(categoriesLimit);
      }).catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    const Client = this.props.selectedClientId;
    if (Client != undefined && Client != '') {
      this.getOfferings(Client);
      this.loadProfileOnBasisOfFilter()

    }
    this.setState({ Client: this.props.selectedClientId });

  }

  getAttributes(selectedOffering) {
    const samp = {};
    const valueConnectorEdit = false;

    axiosInstance
      .get(`${LocalApiBaseUrl}Attribute/GetAttributeCategory`, {
        params: { OfferingID: selectedOffering },
      })

      .then((response) => {
        const mappingData = response.data;
        // If IsActive==false, we should not show the attribute names in the UI so adding a filter on the api reponse
        const finalMappingData = mappingData.filter((attr) => attr.IsActive == true);

        this.setState({ AttributeIsrelator: finalMappingData }, () => {
          this.afterAPICall();

          this.props.handlerFromParant(this.state.AttributeIsrelator);
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  afterAPICall() {
    if (this.state.IsRelatorBlockForOffering && !this.state.AttributeIsrelator.length > 0) {
      this.setState(
        {
          LocationOptions: [],
          LocationData: [],

          locationEnable: 0,
          monthEnable: 0,
          selectedOffering: '',

          monthValues: [],
        },
        () => {
          alert(i18n.t('configFilterMessage'));
        },
      );
    } else {
      // delaying the filling of the location dropdown until attributes are fetched. If relator block is true AND attributes have been configured
      if (this.props.selectedClientId && this.state.processEnable != 0) {
        this.getLocations(this.props.selectedClientId, this.state.processEnable);
      }
    }
  }
  onOfferingChange = (event,OfferingName="") => {
    const { offeringData, filterDetails } = this.state;
    const { SelectedFilter, compDetails } = this.props;
    let selectedOfferingName;
    let subOfferingsList = [];
    let isRelatorBlock;
    let isBatDeallevel;
    let isIojBlock;
    const selectedOffering = event.target.value;
    let tempSelectedOffering = "" 
    tempSelectedOffering=
    this.state.offeringData.filter(a=>OfferingName === a.OfferingName_En).length >0 ? 
    this.state.offeringData.filter(a=>OfferingName === a.OfferingName_En)[0]:{}
    let syncFilter;
    let compareFirst = OfferingName !==""?OfferingName:SelectedFilter.OfferingId
    let compareSecond = OfferingName !==""?tempSelectedOffering.OfferingName_En:selectedOffering
    let currentfilter = { offeringId: OfferingName !==""? tempSelectedOffering.OfferingID :selectedOffering, offeringName: null };
    if (String(compareFirst) === String(compareSecond)) {
      syncFilter = SelectedFilter;
      if(OfferingName !=="")
      syncFilter.OfferingId = tempSelectedOffering.OfferingID
    } else {
      syncFilter = {
        OfferingId: 0,
        OfferingName: '',
        ProcessId: 0,
        LocationId: 0,
        Month: null,
        LocationOMID: [],
        isOMIDAvailable: null,
      };
    }

    const filterObj = {
      Locations: '0',
      Month: '0',
      LocationOMID: [],
      offering: '',
      process: '',
    };
    let comp = OfferingName !== ""?String(tempSelectedOffering.OfferingID) : event.target.value
    let comparevalueName = OfferingName ?OfferingName:""
    let compareValueId = event.target.value

    if (comp !== "0" && selectedOffering !== "All") {
      offeringData.forEach((eachValue) => {
          // FROM SELECTED OFFERING NAME / OFFERING ID
         //FROM OFFERING DATA
         if (String(eachValue.OfferingID) === String(comp)) {
          selectedOfferingName = eachValue.OfferingName_En;
          filterObj.offering = comp;
          subOfferingsList = _.cloneDeep(eachValue.ChildOfferings);
          syncFilter.OfferingName = selectedOfferingName;
          syncFilter.OfferingId = comp;
          currentfilter.offeringName = selectedOfferingName;
          filterDetails.selectedOfferingName = eachValue.OfferingName;
          filterDetails.selectedOffering = comp;
          filterDetails.selectedSubOfferingName = i18n.t('Select_Sub_Offerings');
          filterDetails.selectedLocationName = i18n.t('Select_Location');
          filterDetails.selecetedOMID = '';
          filterDetails.selectedMonthName = i18n.t('Select_Month');
          filterDetails.selectedLocation = '';
          filterDetails.selectedMonth = '';
          store.dispatch({
            type: 'SAVE_CONFIGURATOR_SUB_OFFERING_DETAILS',
            payload: _.cloneDeep(eachValue.ChildOfferings),
          });
          if (eachValue.ChildOfferings.length) {
            const filtersSubofferingData = filterSelectedSubOffering(_.cloneDeep(eachValue.ChildOfferings), SelectedFilter.ProcessId);
            const { firstSuboffering } = filtersSubofferingData;
            const selectedSubofferingFromRedux = filtersSubofferingData.subofferingData[0];
            if (SelectedFilter && SelectedFilter.ProcessId && SelectedFilter.ProcessId !== 'All') {
              syncFilter.ProcessId = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.OfferingID
                : firstSuboffering[0].OfferingID;
              filterObj.process = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.OfferingID
                : firstSuboffering[0].OfferingID;
              filterDetails.selectedSubOfferingName = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.Process
                : firstSuboffering[0].Process;
              filterDetails.selectedSubOffering = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.OfferingID
                : firstSuboffering[0].OfferingID;
              isRelatorBlock = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.IsRelatorBlock
                : firstSuboffering[0].IsRelatorBlock;
              isBatDeallevel = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.IsBatDealLevel
                : firstSuboffering[0].IsBatDealLevel;
              isIojBlock = selectedSubofferingFromRedux
                ? selectedSubofferingFromRedux.IsIntelOpsCategory
                : firstSuboffering[0].IsIntelOpsCategory;
            } else if (SelectedFilter && SelectedFilter.OfferingId === 'All' && compDetails === 'Journey Initiatives') {
              syncFilter.ProcessId = 'All';
              filterObj.process = 'All';
              filterDetails.selectedSubOfferingName = 'All';
              filterDetails.selectedSubOffering = 'All';
              isRelatorBlock = '';
              isBatDeallevel = '';
              isIojBlock = '';
            }
            if (
              !SelectedFilter
              || !SelectedFilter.OfferingId
              || (comp !== 'All' && SelectedFilter.ProcessId === 'All')
            ) {
              syncFilter.ProcessId = firstSuboffering[0].OfferingID;
              filterObj.process = firstSuboffering[0].OfferingID;
              filterDetails.selectedSubOfferingName = firstSuboffering[0].Process;
              filterDetails.selectedSubOffering = firstSuboffering[0].OfferingID;
              isRelatorBlock = firstSuboffering[0].IsRelatorBlock;
              isBatDeallevel = firstSuboffering[0].IsBatDealLevel;
              isIojBlock = firstSuboffering[0].IsIntelOpsCategory;
            }
            store.dispatch({
              type: 'OFFERING_ISBATDEALLEVEL',
              payload: isBatDeallevel,
            });

            this.setState({
              IsRelatorBlockForOffering: isRelatorBlock,
            });

            store.dispatch({
              type: 'COMMON_SUBOFFERING_CURRENTFILTER',
              payload: { ProcessId: firstSuboffering[0].OfferingID, SubOfferingName: firstSuboffering[0].Process_En },
            });
            store.dispatch({
              type: 'ALLOWBATDATAFETCHOPTION',
              payload: { AllowBATDataFetchOption: firstSuboffering[0].AllowBATDataFetchOption },
            });

            this.getAttributes(firstSuboffering[0].OfferingID);
            if(compDetails !== "Journey Initiatives")
            this.getLocations(this.state.Client, syncFilter.ProcessId);
          } else {
            filterDetails.selectedSubOffering = '';
          }
        }
      });
      store.dispatch({
        type: 'FILTER_OFFERING_CURRENTFILTER',
        payload: currentfilter,
      });
    } else {
      filterObj.process = '';
      filterObj.offering = '';
      syncFilter.OfferingName = '';
      syncFilter.OfferingId = '';
      syncFilter.ProcessId = this.props.compDetails === 'Journey Initiatives' && comp === 'All' ? 'All' : 0;

      syncFilter.LocationId = this.props.compDetails === 'Journey Initiatives' && comp === 'All' ? 'All' : 0;
      syncFilter.Month = '';
      syncFilter.LocationOMID = this.props.compDetails === 'Journey Initiatives' && comp === 'All' ? ['All'] : [];
      if (comp === 'All') {
        filterDetails.selectedOfferingName = i18n.t('All_offering');
        filterDetails.selectedSubOfferingName = i18n.t('All_sub_offering');
        filterDetails.selectedLocationName = i18n.t('All_Locations');
        filterDetails.selectedMonthName = i18n.t('Select_Month');
        filterDetails.selectedMonth = '';
        filterDetails.selectedOffering = 'All';
        filterDetails.selectedSubOffering = 'All';
        filterDetails.selectedLocation = 'All';
        filterDetails.selecetedOMID = 'All';
        syncFilter.OfferingId = 'All';
      } else {
        filterDetails.selectedOfferingName = i18n.t('Select_Offering');
        filterDetails.selectedSubOfferingName = i18n.t('Select_Sub_Offerings');
        filterDetails.selectedLocationName = i18n.t('Select_Location');
        filterDetails.selecetedOMID = '';
        filterDetails.selectedMonthName = i18n.t('Select_Month');
        filterDetails.selectedOffering = '';
        filterDetails.selectedSubOffering = '';
        filterDetails.selectedLocation = '';
        filterDetails.selectedMonth = '';
      }
    }
    if (!comp || comp === 'All') {
      this.props.changeHeaderText('');
    } else {
      this.props.changeHeaderText(selectedOfferingName);
    }

    this.props.getSelectedRelatorBlock(isRelatorBlock);
    this.props.getSelectedIoj(isIojBlock);
    this.setState({
      filterDetails,
      subOfferingsList: subOfferingsList || [],
      LocationOptions: [],
      LocationData: [],
      monthData: [],
      locationEnable: '',
      monthEnable: '',
      monthValues: [],
      OMIDJSON: {},
      filteredOMIDJSON: {},
      selectedOMIDs: [],
      offering: '',
      process: '',
      processEnable: this.props.compDetails === 'Journey Initiatives' && comp == 'All' ? 'All' : '',
      processOptions: [],
      selectedOffering: this.props.compDetails === 'Journey Initiatives' && comp == 'All' ? 'All' : '',
    });
    updateSyncFilter(syncFilter);

    this.loadProfileOnBasisOfFilter();
    this.clearConfiguratorStoreData();
    if(compDetails === "Journey Initiatives")
    this.fetchOMIDRecords(this.props.filterClient, syncFilter);
  };

  onLocationChange = async  (event) => {
    const { LocationData, filterDetails } = this.state;
    const { SelectedFilter,compDetails } = this.props;
    const LocationDetails = { LocationId: '', locationName: null };
    return new Promise ( async (resolve,reject)=>{
    if (event.target.value && event.target.value !== 'All') {
      this.setState({
        monthData: [],
      });
      await  Promise.all(LocationData.map(async (each) => {
        if (parseInt(each.LocationID) === parseInt(event.target.value)) {
          if (parseInt(SelectedFilter.LocationId) !== parseInt(event.target.value)) {
            SelectedFilter.Month = '';
          }
          LocationDetails.LocationId = each.LocationID;
          LocationDetails.locationName = each.City;
          filterDetails.selectedLocationName = each.City;
          filterDetails.selecetedOMID = '';
          filterDetails.selectedMonthName = i18n.t('Select_Month');
          filterDetails.selectedLocation = each.LocationID;
          filterDetails.selectedMonth = '';
          SelectedFilter.LocationId = each.LocationID;
          SelectedFilter.LocationOMID = [];
          SelectedFilter.isOMIDAvailable = null;
          if(!this.state.snapshotClicked)
          {
            updateSyncFilter(SelectedFilter);
          }
            
          if(compDetails !== "Journey Initiatives")
          {
             await this.getMonths(this.state.Client, filterDetails.selectedSubOffering, event.target.value);
          }
            }
      }));
    } else {
      if(LocationData.length)
      {
      LocationDetails.LocationId = '';
      LocationDetails.locationName = '';
      filterDetails.selectedLocationName = i18n.t('Select_Location');
      filterDetails.selecetedOMID = '';
      filterDetails.selectedMonthName = i18n.t('Select_Month');
      filterDetails.selectedLocation = '';
      filterDetails.selectedMonth = '';
      SelectedFilter.LocationId = '';
      SelectedFilter.Month = '';
      SelectedFilter.LocationOMID = [];
      SelectedFilter.isOMIDAvailable = null;
      if(!this.state.snapshotClicked)
        {
          updateSyncFilter(SelectedFilter);
        }
    }
    else
      {
        
        if(compDetails !== "Journey Initiatives")
        {
        this.getLocations(this.state.Client, SelectedFilter.ProcessId);
        }
      }
     
    }
    this.setState({ filterDetails });
    store.dispatch({
      type: 'LOCATION_DETAILS_CURRENTFILTER',
      payload: LocationDetails,
    });
    this.loadProfileOnBasisOfFilter();
    this.clearConfiguratorStoreData();

    resolve()
  })
  };
onSubofferingChange = async (event) => {
  const { SelectedFilter,compDetails } = this.props;
    const { subOfferingsList, filterDetails } = this.state;
    const filtersSubofferingData = filterSelectedSubOffering([...subOfferingsList], event.target.value);
    const { firstSuboffering } = filtersSubofferingData;
    const selectedSuboffering = filtersSubofferingData.subofferingData[0];
    if (event.target.value && event.target.value !== 'All') {
      // subOfferingsList.forEach((each) => {
      //   if (parseInt(each.OfferingID) === parseInt(event.target.value)) {
      if (selectedSuboffering) {
        filterDetails.selectedSubOfferingName = selectedSuboffering.Process;
        filterDetails.selectedSubOffering = selectedSuboffering.OfferingID;
        filterDetails.selectedLocationName = i18n.t('Select_Location');
        filterDetails.selecetedOMID = '';
        filterDetails.selectedMonthName = i18n.t('Select_Month');
        filterDetails.selectedLocation = '';
        filterDetails.selectedMonth = '';
        SelectedFilter.ProcessId = selectedSuboffering.OfferingID;
        if(!this.state.snapshotClicked)
          {
            SelectedFilter.LocationId = "";
          }
        SelectedFilter.Month = '';
        SelectedFilter.LocationOMID = [];
        SelectedFilter.isOMIDAvailable = null;

        store.dispatch({
          type: 'OFFERING_ISBATDEALLEVEL',
          payload: firstSuboffering[0].IsBatDealLevel,
        });

        this.setState({
          IsRelatorBlockForOffering: firstSuboffering[0].IsRelatorBlock,
          LocationData: [],
          monthData: [],
        });

        store.dispatch({
          type: 'COMMON_SUBOFFERING_CURRENTFILTER',
          payload: { ProcessId: firstSuboffering[0].OfferingID, SubOfferingName: firstSuboffering[0].Process_En },
        });
        /*  if(this.state.snapshotClicked)
          {
            updateSyncFilter(SelectedFilter);
          } */
          updateSyncFilter(SelectedFilter);
         await this.getLocations(this.state.Client, selectedSuboffering.OfferingID);
         if(compDetails === "Journey Initiatives")
         {
          await this.fetchOMIDRecords(this.props.filterClient, SelectedFilter);
         }
          this.props.getSelectedRelatorBlock(subOfferingsList[0].IsRelatorBlock);
          this.props.getSelectedIoj(subOfferingsList[0].IsIntelOpsCategory);
     
        }
      } 
    else if (event.target.value === 'All') {
      filterDetails.selectedSubOfferingName = i18n.t('All_sub_offering');
      filterDetails.selectedSubOffering = 'All';
      filterDetails.selectedLocationName = i18n.t('All_Locations');
      filterDetails.selecetedOMID = '';
      filterDetails.selectedMonthName = i18n.t('Select_Month');
      filterDetails.selectedLocation = 'All';
      filterDetails.selectedMonth = '';
      SelectedFilter.ProcessId = 'All';
      SelectedFilter.LocationId = 'All';
      SelectedFilter.Month = '';
      SelectedFilter.LocationOMID = ['All'];
      SelectedFilter.isOMIDAvailable = null;
      if(!this.state.snapshotClicked)
      {
        updateSyncFilter(SelectedFilter);
      }
    } else {
      filterDetails.selectedSubOfferingName = i18n.t('Select_Sub_Offerings');
      filterDetails.selectedSubOffering = '';
      filterDetails.selectedLocationName = i18n.t('Select_Location');
      filterDetails.selecetedOMID = '';
      filterDetails.selectedMonthName = i18n.t('Select_Month');
      filterDetails.selectedLocation = '';
      filterDetails.selectedMonth = '';
      SelectedFilter.ProcessId = '';
      SelectedFilter.LocationId = '';
      SelectedFilter.Month = '';
      SelectedFilter.LocationOMID = [];
      SelectedFilter.isOMIDAvailable = null;
      if(!this.state.snapshotClicked)
      {
        updateSyncFilter(SelectedFilter);
      }
    }
   
    this.setState({ filterDetails });
    this.loadProfileOnBasisOfFilter();
    this.clearConfiguratorStoreData();

  };

  clearConfiguratorStoreData = () => {
    store.dispatch({
      type: 'INITIALISE_BO',
      payload: [],
    });
    store.dispatch({
      type: 'INITIALISE',
      payload: [],
    });
    store.dispatch({
      type: 'INITIALISEINSIGHT',
      payload: [],
    });
    store.dispatch({
      type: 'INITIALISEDIVERSE',
      payload: [],
    });
    store.dispatch({
      type: 'INITIALISEIOJ',
      payload: [],
    });
    store.dispatch({
      type: 'INITIALISEWO',
      payload: [],
    });
  };

  MonthChange = (event) => {
    const { monthData, filterDetails } = this.state;
    const { SelectedFilter, applyFilters } = this.props;
    this.clearConfiguratorStoreData();

    if (event.target.value && event.target.value !== 'All') {
      monthData.forEach((each) => {
        if (each === event.target.value && (event.target.value != filterDetails.selectedMonth)) {
          filterDetails.selecetedOMID = '';
          filterDetails.selectedMonthName = event.target.value;
          filterDetails.selectedMonth = event.target.value;
          SelectedFilter.Month = event.target.value;          
          applyFilters({
            Locations: SelectedFilter.LocationId,
            Month: event.target.value,
            LocationOMID: [],
            offering: SelectedFilter.OfferingId,
            process: SelectedFilter.ProcessId,
          });
        }
      });
    } else {
      filterDetails.selecetedOMID = '';
      filterDetails.selectedMonthName = i18n.t('Select_Month');
      filterDetails.selectedMonth = '';
      SelectedFilter.Month = '';
    }
    updateSyncFilter(SelectedFilter);
    this.setState({ filterDetails: filterDetails,snapshotClicked:false });
    if(this.state.snapshotClicked === true)
    {
      setTimeout(() => {
        this.setState({snapshotClicked:false})
      }, 1000);
    }
    this.loadProfileOnBasisOfFilter()
    this.getCategoriesLimit()
     
    let pageName = "";

    switch (this.props.compDetails) {
      case "Business Outcomes":
        pageName = TRACKING_MODULES.CONFIGARATOR_BUSINESS_OUTCOMES;
        break;
      case "Human + Machine Talent":
        pageName=TRACKING_MODULES.CONFIGARATOR_HUMAN_MACHINE_TALENT;
        break;
      case "Work Orchestration":
        pageName=TRACKING_MODULES.CONFIGARATOR_WORK_ORCHESTRATION;
        break;
      case "Insights & Intelligence":
        pageName=TRACKING_MODULES.CONFIGARATOR_INSIGHT_INTELLIGENCE;
        break;
      case "Diverse Data":
        pageName=TRACKING_MODULES.CONFIGARATOR_DIVERSE_DATA;
        break;
      case "Journey Initiatives":
        pageName=TRACKING_MODULES.CONFIGARATOR_JOURNEY_INITIATIVES;
        break;
      case "Maturity Plan":
        pageName=TRACKING_MODULES.MATURITY_PLAN;
    }
    userTracker(
      pageName,
      false,
      null,
      null,
      createParamObject(
        PARAM_NAMES.OFFERING,
        this.state.filterDetails.selectedOfferingName
      ),
      createParamObject(
        PARAM_NAMES.SUB_OFFERING,
        this.state.filterDetails.selectedSubOfferingName
      ),
      createParamObject(
        PARAM_NAMES.LOCATION,
        this.state.filterDetails.selectedLocationName
      ),
      createParamObject(
        PARAM_NAMES.MONTH,
        event.target.value
      ),
    );

  };
  getProfileListAPI = (deletedID=0)=>{
      const client = this.state.Client;
      let defaultSelectedProfileName = this.state.defaultSelectedProfileName
      return new Promise((resolve,reject)=>{
        if (client != undefined && client > 0 && client != "") {
          axiosInstance
            .get(`${LocalApiBaseUrl}Snapshot/GetSnapshotsByUserID`, {
              params: { OrgEntityID: client, LanguageID: this.props.languageData.id },
            })
            .then((response) => {
              store.dispatch({
                type: "SNAPSHOT",
                payload: response.data,
              });
  
                this.setState({
                  profileList: response.data,
                  defaultSelectedProfileName: response.data.length == 0 ? "" : this.state.defaultSelectedProfileName,
                },()=>{
                  if(deletedID !==0)
                  {
                    const ss = response.data.filter((f) => {
                      return f.SnapShotName === defaultSelectedProfileName;
                    });
                    if(ss.length ===0)
                        this.setState({
                          defaultSelectedProfileName:""
                        })
                  }
                  else
                  {
                    this.loadProfileOnBasisOfFilter()
                  }
                  resolve()
                  
                  
                });
               
            })
            .catch((error) => {
              trycatchAlertPopup(error);
              reject()
            });
        } else {
          reject()
        }
  
      })
    
    }
  
    getAllProfileAgainstUser = () => {
      const { SelectedFilter } = this.props;
      const client = this.state.Client;
  
      let loadDefaultSnapshot = false;
      return new Promise((resolve,reject)=>{
      if (client != undefined && client > 0 && client != "") {
        axiosInstance
          .get(`${LocalApiBaseUrl}Snapshot/GetSnapshotsByUserID`, {
            params: { OrgEntityID: client, LanguageID: this.props.languageData.id },
          })
          .then((response) => {
            store.dispatch({
              type: "SNAPSHOT",
              payload: response.data,
            });
  
            this.setState({
              profileList: response.data,
              defaultSelectedProfileName: response.data.length == 0 ? "" : this.state.defaultSelectedProfileName,
            });
  
            let defaultSelectedProfileName = this.state.defaultSelectedProfileName;
            if (response.data != null && response.data.length > 0) {
              const defaultSS = response.data.filter((f) => {
                return f.IsDefault === true;
              });
              if (defaultSS.length > 0) {
                defaultSelectedProfileName = defaultSS[0].SnapShotName;
  
                loadDefaultSnapshot =
                  SelectedFilter &&
                  (!SelectedFilter.OfferingId ||
                    !SelectedFilter.ProcessId ||
                    (!SelectedFilter.LocationId &&
                      (SelectedFilter.OfferingId ===
                        this.state.offeringData.filter((o) => {
                          return o.OfferingName_En === defaultSS[0].Offering_En;
                        })[0].OfferingID &&
                        SelectedFilter.ProcessId === defaultSS[0].OfferingID)))
                    ? true
                    : false;
                 let defaultObj = {
                  OfferingId: this.state.offeringData.filter((o) => {
                    return o.OfferingName_En === defaultSS[0].Offering_En;
                  })[0].OfferingID,
                  ProcessId: defaultSS[0].OfferingID,
                  LocationId: defaultSS[0].LocationID,
                  Month: "",
                  LocationOMID: defaultSS[0].PageName !== "Journey Initiatives"? []:["All"],
                  IsDataPublishForLocation: false,
                  isOMIDAvailable: false,
                  OfferingName: defaultSS[0].Offering_En,
                 }
                 if(defaultSS[0].PageName!=="Pulse")
                 this.props.onTabChange(defaultSS[0].PageName);   
                if (loadDefaultSnapshot && (String(SelectedFilter.ProcessId) !== String(defaultObj.ProcessId) || 
                String(SelectedFilter.LocationId) !== String(defaultObj.LocationId) ||
                 SelectedFilter.LocationOMID !== defaultObj.LocationOMID) 
                 ) {
                  store.dispatch({
                    type: "SYNC_CURRENTFILTER",
                    payload: {
                      OfferingId: defaultObj.OfferingId,
                      ProcessId: defaultObj.ProcessId,
                      LocationId: defaultObj.LocationId,
                      Month: "",
                      LocationOMID: defaultObj.LocationOMID,
                      IsDataPublishForLocation: false,
                      isOMIDAvailable: false,
                      OfferingName: defaultSS[0].Offering_En,
                    },
                  });
                  this.setState({ defaultSelectedProfileName: loadDefaultSnapshot ? defaultSelectedProfileName : "" ,snapshotClicked:true});
                }
              }
            }
            resolve()
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            reject()
          });
      }
      else
      {
        reject()
      }
    })

    };


  getMonths(Client, Process, Locations) {
    this.setState({ showLoaderOnPage: true });
    let monthValues = '';
    const month = [];
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let monthData = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetMonth`, {
        params: {
          OrgEntityID: Client,
          LocationId: Locations,
          OfferingId: Process,
          LanguageID: store.getState().CurrentFilter.languageData.id,
        },
      })
      .then((response) => {
        if(response.data){
          store.dispatch({
            type:'SAVE_MONTH_DATA',
            payload: response.data
          })
        }
        monthData = response.data;
        monthValues = monthData.map((dropValue, index) => {
          const isIE = false || !!document.documentMode;
          let objDate;
          if (isIE) {
            dropValue = dropValue.split('-');
            objDate = new Date(`${dropValue[0]} 01 ${dropValue[1]}`);
          } else {
            objDate = new Date(dropValue);
          }
          month[index] = `${monthNames[objDate.getMonth()]}-${objDate.getFullYear()}`;
          return (
            <option key={month[index]} selected={index == 0 ? '' : ''} value={month[index]}>
              {month[index]}
            </option>
          );
        });
      
        this.setState({ monthValues, monthData }, () => {
          const { monthData } = this.state;
          const { SelectedFilter } = this.props;
          if (
            SelectedFilter
            && SelectedFilter.Month
            && monthData.filter((each) => each === SelectedFilter.Month).length
          ) {            
            this.MonthChange({ target: { value: SelectedFilter.Month } });
          } else if (SelectedFilter.LocationId && SelectedFilter.LocationId !== 'All') {            
            this.MonthChange({ target: { value: monthData[0] } });
          }
        });
        this.setState({ showLoaderOnPage: false });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ showLoaderOnPage: false });
      });
  }

  viewOverallQCScore = () => {
    const { history } = this.props;
    const location = {
      pathname: '/OverallQCScore',
    };
    history.push(location);
    this.setState({
      viewOverallQCScore: true,
    });
  };

  subHeaderOpen = (event) => {
    this.props.subHeaderOpenCallback(true);
    event.preventDefault();
  };
  subHeaderClose = (event) => {
    this.props.subHeaderCloseCallback(false);
    event.preventDefault();
  };
  checkIfPulsePageAccessAllowed = () => {
    let { roleData } = this.props
   
   const foundPermissionObj = _.find(roleData, { FeatureName: "PulsePage" });

   let hasAccessPermission = false;
   if (!_.isEmpty(foundPermissionObj)) {
     const allowedOperations = foundPermissionObj.Operations;

     // ACCESS WOULD ONLY BE ALLOWED IF THE USER HAS EITHER "VIEW" OR "EDIT" PERMISSION
     if (allowedOperations.indexOf("View") || allowedOperations.indexOf("Edit")) {
       hasAccessPermission = true;
     }
   }

   return hasAccessPermission;
 };
  setDefaultSelectedProfileName = (data, snapshotClick,filterdata,screen="") =>{
    const { SelectedFilter } = this.props
  
    this.setState({
      defaultSelectedProfileName:data
    },()=>{
      if(snapshotClick === true)
      {
        this.setState({snapshotClicked:true},()=>{
          if(screen !== "Pulse" && !(filterdata.OfferingName === SelectedFilter.OfferingName &&
            String(filterdata.ProcessId) === String(SelectedFilter.ProcessId) &&
            String(filterdata.LocationId) === String(SelectedFilter.LocationId)           //No CHANGE ON TAB CHANGE
          ))
          {
            store.dispatch({
              type: "SYNC_CURRENTFILTER",
              payload: filterdata,
            });
          }

          if (screen === "Pulse") {
            if(this.checkIfPulsePageAccessAllowed())
            {
              store.dispatch({
                type: "SYNC_CURRENTFILTER",
                payload: filterdata,
              });
              this.navigatetoPulseScreen();
            }
          } else {
            this.props.onTabChange(screen);
          }
          this.loadProfileOnBasisOfFilter()
          
        })
      }
    })
  }
  navigatetoPulseScreen = () =>{
    let { history } = this.props
    history.push({
      pathname: "/landing",
      state: { pulseMenu: true },
    });
  }
  loadProfileOnBasisOfFilter = () => {
    const { SelectedFilter,CurrentFilter } = this.props;
    const profileData = this.state.profileList
      ? this.state.profileList
      : CurrentFilter.snapshotProfile;
      //let currentScreen = CurrentFilter.Tab.title;
      if(profileData && profileData.length > 0){
        const dataSS = profileData.filter((f) => {
          return String(f.OfferingID) === String(SelectedFilter.ProcessId) && String(f.LocationID) === String(SelectedFilter.LocationId); //&& currentScreen === f.PageName
        });
        let ssName = "";
        if (dataSS !== null && dataSS.length > 0) {
          ssName = dataSS[0].SnapShotName;
          this.setState({ defaultSelectedProfileName: ssName });
      }
      else
      {
        this.setState({ defaultSelectedProfileName: "" });
      }
    }
  };



 

  render() {
    const {
      subOfferingsList, offeringData, filterDetails, LocationData, monthData, MaturityPhase, isApiLoading, selectedOMIDs, OMIDJSON, filteredOMIDJSON, omidsLengthGTOne, OMIDProcess, searchedOMID,
    } = this.state;
    const {
      QcOverallScore, compDetails, OMIDEnabled, ActiveToggleCount, isAccess, selectedClientId, history,
    } = this.props;
    let sum  = 0;
    let avg =0;
    let DataDD= [];
   let newOverAllScore = 0;
    this.props.QcOverallScore.QualityScoreDatas && this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.map((each)=>{
  
    if(this.props.FetchFromBAT){
      each.LeverName == 'Diverse Data' ? DataDD.push(each.AchievedScore_BAT): DataDD.push(each.AchievedScore);
      for(var i in DataDD){
  sum += DataDD[i];
  
      }
      avg = Math.round(sum/DataDD.length);
      newOverAllScore = avg;

      sum = 0;
      
  
      }
      else{
          DataDD.push(each.AchievedScore );
          for(var i in DataDD){
          sum += DataDD[i];
         
      }
      avg = sum/DataDD.length;
      newOverAllScore = avg;

      sum = 0;
  
  }


  
  })

  


 


    // const offeringClass = classNames({
    //   'custom-headerdropdown mr-3': true,
    // });
    // const subOfferingClass = classNames({
    //   'custom-headerdropdown mr-3': true,
    // });
    // const renderSelectedLocation = () => {
    //   if (selectedOMIDs.toString()) {
    //     const filteredLoc = this.state.OMIDJSON.IOJOMIDs.filter(
    //       (e) => e.locID == this.props.SelectedFilter.LocationId,
    //     )[0];

    //     if (filteredLoc != undefined) {
    //       return filteredLoc.locname;
    //     }
    //     return selectedOMIDs.toString();
    //   }
    //   if (this.props.SelectedFilter === undefined) return 'Select Location';
    //   if (this.props.SelectedFilter.LocationId === 'All') {
    //     return 'All locations';
    //   } return 'Select Location';
    // };
    return (
      <div
        id="subnav-header-id"
        className="subnav-header fd-config-wrapper"
        onMouseEnter={this.subHeaderOpen}
        onMouseLeave={this.subHeaderClose}
      >
        {isApiLoading ? <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active /> : ''}
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex align-items-center">
                <div className="offeringfilter-dropdowns d-flex align-items-center">
                  <span className="mr-4">Filter By</span>

                  <OfferingSubOffering
                    selectedOffering={filterDetails.selectedOfferingName}
                    offerings={offeringData}
                    offeringsData={offeringData}
                    selectedSubOffering={filterDetails.selectedSubOfferingName}
                    selectedSubOfferingId={filterDetails.selectedSubOffering}
                    subOfferings={subOfferingsList}
                    onOfferingChange={this.onOfferingChange}
                    onSubOfferingChange={this.onSubofferingChange}
                    subHeaderOpen={this.subHeaderOpen}
                    subHeaderClose={this.subHeaderClose}
                    compDetails={compDetails}
                  />

                  {compDetails !== 'Journey Initiatives' && (
                    <DropdownButton
                      menuAlign="left"
                      id="Locationbutton"
                      title={
                        filterDetails.selectedLocationName
                          ? filterDetails.selectedLocationName
                          : i18n.t('Select_Location')
                      }
                      className={
                        filterDetails.selectedLocationName !== ''
                          && filterDetails.selectedLocationName !== i18n.t('Select_Location')
                          ? 'custom-headerdropdown selected mr-3'
                          : 'custom-headerdropdown mr-3'
                      }
                      disabled={!LocationData.length}
                    >
                      <div className="offering-droplistwrapper">
                        {LocationData.map((each) => (
                          <Dropdown.Item 
                            href="javascript:void(0);" 
                            className={
                              parseInt(each.LocationID, 10) === parseInt(filterDetails.selectedLocation, 10)
                                ? 'active'
                                : ''
                            }
                            role="button"
                              tabIndex={0}
                              onClick={() => parseInt(each.LocationID, 10) === parseInt(filterDetails.selectedLocation, 10) ? '' : this.onLocationChange({ target: { value: each.LocationID } })}
                              onKeyDown={() => parseInt(each.LocationID, 10) === parseInt(filterDetails.selectedLocation, 10) ? '' : this.onLocationChange({ target: { value: each.LocationID } })}
                            
                          >
                            <span
                              className='form-check-label'
                              >
                              {each.City}
                            </span>
                          </Dropdown.Item>
                        ))}
                      </div>
                    </DropdownButton>
                  )}
                  {compDetails === 'Journey Initiatives' && (
                    <DropdownButton
                      menuAlign="left"
                      id="OmidLocationbutton"
                      title={
                        filterDetails.selectedLocationName
                          ? filterDetails.selectedLocationName
                          : i18n.t('Select_Location')
                      }
                      className={
                        filterDetails.selectedLocationName !== ''
                          && filterDetails.selectedLocationName !== i18n.t('Select_Location')
                          ? 'custom-headerdropdown selected mr-3'
                          : 'custom-headerdropdown mr-3'
                      }
                      disabled={filterDetails.selectedSubOffering === 'All'}
                    >
                      {' '}
                      <div className="offering-droplistwrapper ioj-droplistwrapper">
                        <Dropdown.ItemText>
                          <div className="searchFilter">
                            <input
                              placeholder="Search ..."
                              type="search"
                              value={searchedOMID}
                              onChange={this.searchOMID}
                            />
                            <i
                              className="fal fa-times clear-search"
                              role="button"
                              tabIndex={0}
                              onClick={this.clearSearchOMID}
                              onKeyDown={this.clearSearchOMID}
                            />
                          </div>
                        </Dropdown.ItemText>
                        <Dropdown.Item 
                          href="javascript:void(0);" 
                          className={filterDetails.selectedLocation === 'All' ? "active all-locations" : "all-locations"}
                          role="button"
                          tabIndex={0}
                          onClick={() => filterDetails.selectedLocation === 'All' ? '' : this.onOMIDChange('All Locations', 'All')}
                          onKeyDown={() => filterDetails.selectedLocation === 'All' ? '' : this.onOMIDChange('All Locations', 'All')}
                       >
                          <span
                            className='form-check-label'
                          >
                            {i18n.t('All_Locations')}
                          </span>
                        </Dropdown.Item>
                        {filteredOMIDJSON !== {}
                          && filteredOMIDJSON.IOJOMIDs
                          && filteredOMIDJSON.IOJOMIDs.map((eachLocation) => (
                            <>
                              <Dropdown.ItemText className="omid-loc-label">{eachLocation.locname}</Dropdown.ItemText>

                              <Dropdown.Item 
                                href="javascript:void(0);" 
                                className={parseInt(eachLocation.locID, 10) === parseInt(filterDetails.selectedLocation, 10)
                                          && filterDetails.selecetedOMID === 'All' ? "active" : "" }
                                          role="button"
                                          tabIndex={0}
                                          onClick={() => parseInt(eachLocation.locID, 10) === parseInt(filterDetails.selectedLocation, 10)
                                            && filterDetails.selecetedOMID === 'All' ? '' : this.onOMIDChange('All', eachLocation.locID)}
                                          onKeyDown={() => parseInt(eachLocation.locID, 10) === parseInt(filterDetails.selectedLocation, 10)
                                            && filterDetails.selecetedOMID === 'All' ? '' : this.onOMIDChange('All', eachLocation.locID)}
                              >
                                <span
                                  className='form-check-label' 
                                >
                                  {i18n.t('All')}
                                </span>
                              </Dropdown.Item>
                              {eachLocation.IOJOMIDDatas.map((eachOMID, index) => (
                                <Dropdown.Item 
                                  href="javascript:void(0);" 
                                  className={parseInt(eachLocation.locID, 10) === parseInt(filterDetails.selectedLocation, 10)
                                  && parseInt(filterDetails.selecetedOMID, 10) === parseInt(eachOMID.OMID, 10) ? "active" : ""}
                                  role="button"
                                    tabIndex={0}
                                    onClick={() => parseInt(eachLocation.locID, 10) === parseInt(filterDetails.selectedLocation, 10)
                                      && parseInt(filterDetails.selecetedOMID, 10) === parseInt(eachOMID.OMID, 10) ? '' : this.onOMIDChange(eachOMID.OMID, eachLocation.locID, eachOMID.Process)}
                                    onKeyDown={() => parseInt(eachLocation.locID, 10) === parseInt(filterDetails.selectedLocation, 10)
                                      && parseInt(filterDetails.selecetedOMID, 10) === parseInt(eachOMID.OMID, 10) ? '' : this.onOMIDChange(eachOMID.OMID, eachLocation.locID, eachOMID.Process)}
                                  
                                >

                                  <span
                                    data-tip
                                    data-for={`omid-tooltip-${eachOMID.OMID}`}
                                    className='form-check-label'
                                    >
                                    {eachOMID.OMID}
                                  </span>
                                  {eachOMID.OMLite && <span className="omid-flagtext">{i18n.t('Lite')}</span>}
                                  <ReactTooltip
                                    place="bottom"
                                    type="dark"
                                    effect="solid"
                                    id={`omid-tooltip-${eachOMID.OMID}`}
                                  >
                                    <span>{eachOMID.Process}</span>
                                  </ReactTooltip>
                                </Dropdown.Item>
                              ))}
                            </>
                          ))}
                      </div>
                    </DropdownButton>
                  )}
                  {compDetails !== 'Journey Initiatives' && (
                    <DropdownButton
                      menuAlign="left"
                      id="Monthbutton"
                      title={filterDetails.selectedMonth ? filterDetails.selectedMonth : i18n.t('Select_Month')}
                      className={
                        filterDetails.selectedMonth !== '' && filterDetails.selectedMonth !== i18n.t('Select_Month')
                          ? 'custom-headerdropdown selected mr-3'
                          : 'custom-headerdropdown mr-3'
                      }
                      disabled={!monthData.length}
                    >
                      <div className="offering-droplistwrapper">
                        {monthData.map((each) => (
                          <Dropdown.Item href="javascript:void(0);" className={each === filterDetails.selectedMonth ? "active" : ""}  role="button"
                          tabIndex={0}
                          onClick={() => each === filterDetails.selectedMonth ? '' : this.MonthChange({ target: { value: each } })}
                          onKeyDown={() => each === filterDetails.selectedMonth ? '' : this.MonthChange({ target: { value: each } })}
                        >
                            <span
                              className='form-check-label'
                             >
                              {each}
                            </span>
                          </Dropdown.Item>
                        ))}
                      </div>
                    </DropdownButton>
                  )}
                </div>
                {compDetails && compDetails === 'Journey Initiatives' ? null : (
                  <div className="filterdata-options ml-auto d-flex align-items-center">
                    {parseInt(filterDetails.selectedOffering, 10)
                      && parseInt(filterDetails.selectedSubOffering, 10)
                      && parseInt(filterDetails.selectedLocation, 10)
                      && filterDetails.selectedMonth
                      && (QcOverallScore && QcOverallScore.QualityScoreDatas)
                      && QcOverallScore.QualityScoreDatas.OverallScore ? (
                        <div>
                          <p className="overallscore-link pr-3" tabIndex={0} role="button" onClick={this.viewOverallQCScore} onKeyDown={this.viewOverallQCScore}>
                            Overall QC Score
                            {' '}
                            <span
                              className={
                              QcOverallScore.QualityScoreDatas.OverallScore < 50
                                ? 'red-percentval'
                                : QcOverallScore.QualityScoreDatas.OverallScore >= 50
                                  && QcOverallScore.QualityScoreDatas.OverallScore <= 75
                                  ? 'orange-percentval'
                                  : 'green-percentval'
                            }
                            >
                              {QcOverallScore && QcOverallScore.QualityScoreDatas
                                ? newOverAllScore + QcOverallScore.QualityScoreDatas.Unit
                                : '0%'}
                            </span>
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                       {this.state.Client &&
                    <SnapshotProfile compDetails={compDetails} 
                     filterDetails={filterDetails} 
                     MaturityPhase={MaturityPhase} 
                     OperatingGropus={this.props.OperatingGropus} 
                     languageData={this.props.languageData} 
                     profileListParent={this.state.profileList} 
                     offeringData={this.state.offeringData} 
                     defaultSelectedProfileName = {this.state.defaultSelectedProfileName}
                     setDefaultSelectedProfileName = {this.setDefaultSelectedProfileName}
                     Client = {this.state.Client}
                     SelectedFilter = {this.props.SelectedFilter}
                     onTabChange={this.props.onTabChange}
                     navigatetoPulseScreen = {this.navigatetoPulseScreen}
                     getAllProfileAgainstUser = {this.getProfileListAPI}
                     {...this.props}
                     ></SnapshotProfile>
                    }
{/*                     {parseInt(filterDetails.selectedOffering, 10)
                      && parseInt(filterDetails.selectedSubOffering, 10)
                      && parseInt(filterDetails.selectedLocation, 10)
                      && filterDetails.selectedMonth
                      && MaturityPhase ? (
                        <div className="pl-3 d-flex align-items-center">
                          <p className="mr-2 pr-2 automation-text">
                            <img src={DatabaseWhite} alt="DatabaseWhite" className="mr-2" />
                            {IOJData.map((e) => {
                              if (e.IOJName === MaturityPhase) {
                                return e.IOJNameValue;
                              }
                            })}
                          </p>
                          <p>
                            {IOJData.map((e) => {
                              if (e.IOJName === MaturityPhase) {
                                return <p>{e.IOJDesc}</p>;
                              }
                            })}
                          </p>
                        </div>
                      ) : null} */}
                  </div>
                )}

                {this.props.compDetails === 'Journey Initiatives'
                  && this.props.history.location.pathname === '/admin/Configurator'
                   ? (
                    <>
                      <div className="filterdata-options ml-auto d-flex align-items-center">
                      {this.state.Client &&
                    <SnapshotProfile compDetails={compDetails} 
                     filterDetails={filterDetails} 
                     MaturityPhase={MaturityPhase} 
                     OperatingGropus={this.props.OperatingGropus} 
                     languageData={this.props.languageData} 
                     profileListParent={this.state.profileList} 
                     offeringData={this.state.offeringData} 
                     defaultSelectedProfileName = {this.state.defaultSelectedProfileName}
                     setDefaultSelectedProfileName = {this.setDefaultSelectedProfileName}
                     Client = {this.state.Client}
                     SelectedFilter = {this.props.SelectedFilter}
                     onTabChange={this.props.onTabChange}
                     navigatetoPulseScreen = {this.navigatetoPulseScreen}
                     getAllProfileAgainstUser = {this.getProfileListAPI}
                     {...this.props}
                     ></SnapshotProfile>}
                     {this.state.selectedOMIDs[0] !== "All Locations" &&
                      this.state.selectedOMIDs.length>0 &&
                        <div className="omid-enable-toggle" style={{ cursor: selectedOMIDs[0] !== 'All' && 'pointer' }}>
                          <span data-tip data-for="omid-toggle-tooltip" data-tip-disable={selectedOMIDs[0] === 'All'}>
                            OMID:
                            {' '}
                            {selectedOMIDs[0]}
                          </span>
                          {selectedOMIDs[0] !== 'All'
                          && isAccess
                          && omidsLengthGTOne === true
                          && (ActiveToggleCount > 1 || OMIDEnabled === false) && (
                            <span>
                              <div className="toggle-group selecttooltip">
                                <input
                                  type="checkbox"
                                  name="viewproject"
                                  id="viewproject"
                                  checked={OMIDEnabled}
                                  onClick={this.toggleClick}
                                />
                                <label htmlFor="viewproject">Connection Status</label>
                                <div className="onoffswitch" aria-hidden="true">
                                  <div className="onoffswitch-label">
                                    <div className="onoffswitch-inner" />
                                    <div className="onoffswitch-switch" />
                                  </div>
                                </div>
                                {OMIDEnabled === false ? (
                                  <span className="selecttooltiptext">Enable OM ID for this location</span>
                                ) : (
                                  <span className="selecttooltiptext">Disable OM ID for this location</span>
                                )}
                              </div>
                            </span>
                          )}
                          <ReactTooltip place="left" effect="solid" id="omid-toggle-tooltip"><span>{OMIDProcess}</span></ReactTooltip>
                        </div>
  }
                      </div>
                    </>
                  ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  QcOverallScore: { ...state.CurrentFilter.QcOverallScore },
  SelectedFilter: { ...state.CurrentFilter.SyncFilter },
  compDetails: state.CurrentFilter.Tab.title,
  IOJData: state.JourneyReducer.IOJData,
  OMIDEnabled: state.JourneyReducer.OMIDEnabled,
  ActiveToggleCount: state.JourneyReducer.ActiveToggleCount,
  OMIDDataList: state.JourneyReducer.OMIDDataList,
  isAccess: state.JourneyReducer.Datas.isAccess,
  clientID: state.CurrentFilter.ClientData.id,
  clientName: state.CurrentFilter.ClientData.name,
  FetchFromBAT: state.CurrentFilter.FetchFromBAT,
  snapshotProfilesRedux : [...state.CurrentFilter.snapshotProfile],
    roleData: state.roleReducers.roleReducers,
});

const mapDispatchToProps = (dispatch) => ({
  saveconfigofferings: (data) => {
    dispatch(saveconfigofferingData(data));
  },
  setMultiLevelCategoriesLimit: (data) => {
    dispatch({ type: 'SET_MULTILEVEL_CATEGORIES_LIMIT', payload: data });
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfiguratorFilters);
