/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { Tabs, Tab } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import i18n from "i18next";
import classNames from "classnames";
import AdoptionMetricsTab from "./AdoptionMetricsTab";
// IMPORT CUSTOM COMPONENTS
import InitiativeDetails from "./InitiativeDetails";
import MetricsImpacted from "./MetricsImpacted";
import TimelinesAndOutcomes from "./TimelinesAndOutcomes";
import AssociationsTab from "./AssociationsTab";
import SynopsLoader from "../../Shared/SynopsLoader";
import _, { parseInt, valuesIn } from "lodash";
import { getCurrentClientNameFromSession } from "../../Shared/Common";

// IMPORT CONSTANTS/FUNCTIONS
import axiosInstance from "../../Shared/interceptor";
import { trycatchAlertPopup } from "../../Shared/Constant";
import { LocalApiBaseUrl, regexForNumbersOnly } from "../../Shared/Constant";
import { updateSyncFilter } from "../../Shared/FiltersHelper";
import {
  sleep,
  validateIsAllowedDomainFromURL,
  fetchAllowedDomains,
  isValidText,
  InvalidDescription,
  getValidUserDetails,
  getErrorMessageByData,
} from "../../Shared/commonFunctions";
import { actionStatus } from "../../actions/JourneyActions";
/* eslint-disable-next-line */
const regexForURLLink =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
const regexForNumbersWithUptoTwoDecimalPlaces = /^[-]?[0-9]{1,10}(?:\.[0-9]{1,2})?$/;
//let clientName = JSON.parse(sessionStorage.getItem("clientSelection")) !== undefined && JSON.parse(sessionStorage.getItem("clientSelection")) !== null ? JSON.parse(sessionStorage.getItem("clientSelection")).name : ""
const AddEditInitiative = ({
  // REDUX PROPS
  CurrentFilter,
  languageData,
  JourneyData,
  languageId,
  clientData,
  syncFilter,
  journeyData,
  boiList,
  iiMetrics,
  // PROPS FROM PARENT COMPONENT
  initiativeFormSubmittedAs, // WHETHER THE FORM IS SUBMITTED AS DRAFT ("saveDraft") OR THE FINAL SAVE ("save")
  resetInitiativeFormSubmittedAs,
  handleClickSubmitInitiativeForm,
  handleAfterSubmittedInitiativeForm,
  GetIOJQcscore,
  formMode = "add", // WHETHER THE FORM IS OPENED UP IN "add" or "edit" MODE
  initiativeId = null,
  journeyStageId,
  viewData,
  formType,
  storeActionStatus,
  isInitativeManagement,
  allInitiativeData,
  initiativesSequenceData,
}) => {
  const mappedBOIMetrics = _.map(boiList, (eachItem) => ({
    label: eachItem.MetricName,
    label_en: eachItem.MetricName,
    value: String(eachItem.MetricID),
  }));

  const mappedKPIAndSLAMetrics = _.map(iiMetrics, (eachItem) => ({
    label: eachItem.MetricName,
    label_en: eachItem.MetricName,
    value: String(eachItem.KPIMappingID),
  }));

  const clientName = getCurrentClientNameFromSession();

  function getOptionObjFromDropdownValue(dropdownArray, selectedValue, fieldToCheckFor) {
    let found;
    if (!_.isEmpty(fieldToCheckFor)) {
      found = _.find(dropdownArray, [`${fieldToCheckFor}`, selectedValue]);
    } else {
      found = _.find(dropdownArray, ["value", selectedValue]);
    }
    return found;
  }
  const specialCharValidation = InvalidDescription();
  const formFieldsMapping = [
    // FORM FIELDS BELONGING TO "Initiative Details" STEP
    { fieldName: "initiativeName", belongsToStep: "initiativeDetails", initialValue: "" }, // API request field: Name
    { fieldName: "initiativeDescription", belongsToStep: "initiativeDetails", initialValue: "" }, // API request field: Description
    { fieldName: "initiativeImpacts", belongsToStep: "initiativeDetails", initialValue: [] }, // API request field: initiativeImpactIds
    { fieldName: "initiativeOwner", belongsToStep: "initiativeDetails", initialValue: "" }, // API request field: OwnerEmail
    { fieldName: "initiativeStatusID", belongsToStep: "initiativeDetails", initialValue: "" }, // API request field: InitiativeStatusID
    {
      fieldName: "initiativeName",
      belongsToStep: "initiativeDetails",
      initialValue: "",
    }, // API request field: Name
    {
      fieldName: "initiativeDescription",
      belongsToStep: "initiativeDetails",
      initialValue: "",
    }, // API request field: Description
    {
      fieldName: "ragReason",
      belongsToStep: "initiativeDetails",
      initialValue: "",
    },
    {
      fieldName: "initiativeImpacts",
      belongsToStep: "initiativeDetails",
      initialValue: [],
    }, // API request field: initiativeImpactIds
    {
      fieldName: "initiativeOwner",
      belongsToStep: "initiativeDetails",
      initialValue: "",
    }, // API request field: OwnerEmail
    {
      fieldName: "initiativeStatusID",
      belongsToStep: "initiativeDetails",
      initialValue: "",
    }, // API request field: InitiativeStatusID
    {
      fieldName: "rejectOnHoldCancelledReason",
      belongsToStep: "initiativeDetails",
      initialValue: "",
    }, // API request field: Reason
    { fieldName: "rag", belongsToStep: "initiativeDetails", initialValue: "" }, // API request field: RAG
    { fieldName: "initiativeSourceId", belongsToStep: "initiativeDetails", initialValue: "" }, // API request field: InitiativeSourceID
    { fieldName: "initiativeCategoryId", belongsToStep: "initiativeDetails", initialValue: "" }, // API request field: InitiativeCategoryID
    {
      fieldName: "journeyStageId",
      belongsToStep: "initiativeDetails",
      initialValue: String(journeyStageId),
    }, // API request field: IOJStageID
    { fieldName: "synopsLeverId", belongsToStep: "initiativeDetails", initialValue: "" }, // API request field: SOCategoryID
    { fieldName: "initiativeAssessments", belongsToStep: "initiativeDetails", initialValue: [] }, // API request field: initiativeAssessmentIds
    { fieldName: "sequence", belongsToStep: "initiativeDetails", initialValue: "" }, // API request field: Sequence

    //////// THE BELOW FIELD "metricsImpacted" IS NOT AN ACTUAL FORM FIELD ON THE UI BUT INSTEAD IS A SORT OF HIDDEN FIELD THAT WOULD HOLD THE ARRAY OF METRICS IMPACTED ADDED IN "METRICS IMPACTED" STEP. THIS FIELD IS ADDED IN FORMIK SO THAT VALIDATION RULE(S) CAN BE RUN AND FORM CAN BE PREVENTED FROM SUBMISSION
    { fieldName: "metricsImpacted", belongsToStep: "metricsImpacted", initialValue: [] }, // API request field: initiativeMetricsImpacted

    // FORM FIELDS BELONGING TO "Timelines And Outcomes" STEP
    { fieldName: "plannedStartDate", belongsToStep: "timelinesAndOutcomes", initialValue: "" }, // API request field: PlannedStartDate
    { fieldName: "actualStartDate", belongsToStep: "timelinesAndOutcomes", initialValue: "" }, // API request field: ActualStartDate
    { fieldName: "plannedEndDate", belongsToStep: "timelinesAndOutcomes", initialValue: "" }, // API request field: PlannedEndDate
    { fieldName: "revisedEndDate", belongsToStep: "timelinesAndOutcomes", initialValue: "" }, // API request field: RevisedEndDate
    { fieldName: "actualEndDate", belongsToStep: "timelinesAndOutcomes", initialValue: "" }, // API request field: ActualEndDate
    { fieldName: "escalationEmail", belongsToStep: "timelinesAndOutcomes", initialValue: "" }, // API request field: EscalationEmail
    { fieldName: "reportLink", belongsToStep: "timelinesAndOutcomes", initialValue: "" }, // API request field: ReportOut
    { fieldName: "successStoryLink", belongsToStep: "timelinesAndOutcomes", initialValue: "" }, // API request field: SuccessStory
    {
      fieldName: "initiativeOutcomeOrRemark",
      belongsToStep: "timelinesAndOutcomes",
      initialValue: "",
    }, // API request field: Outcome
    { fieldName: "fteBenefit", belongsToStep: "timelinesAndOutcomes", initialValue: "" }, // API request field: FTEBenefit
    { fieldName: "costBenefit", belongsToStep: "timelinesAndOutcomes", initialValue: "" }, // API request field: CostBenefit
  ];

  const formFieldsWithInitialValues = {
    ConfIntelOpsAdoptionMetricsModel: [
      {
        ID: 0,
        CurrentUser: "",
        TargetUser: "",
        ConfigurationIntelOpsInitiativeID: 0,
        ConfIntelOpsAdoptionMetricMaster_ID: 1,
        MonthYear: selectedMonth ? selectedMonth : currentMonthYear,
        Comment:"",
      },

    ],
    // AdoptionMonths:[
    //   {
    //     MonthYear: selectedMonth,
    //     values: ConfIntelOpsAdoptionMetricsModel
    //   }
    // ]
  };

  _.forEach(formFieldsMapping, (eachFormField) => {
    formFieldsWithInitialValues[eachFormField.fieldName] = eachFormField.initialValue;
});

  //////////////////////////////    STATES   //////////////////////////////////////////////

  const [formFieldsWithValues, setFormFieldsWithValues] = useState(formFieldsWithInitialValues);
  const [isAPILoading, setAPILoading] = useState(false);
  const [selectedStep, setSelectedStep] = useState("initiativeDetails"); // "initiativeDetails", "metricsImpacted", "timelinesAndOutcomes"
  const [associationData, setAssociationData] = useState({
    filteredData: {
      selectedOffering: "",
      selectedSubOffering: "",
      selectedLocation: "",
      selectedOfferingName: i18n.t("Offering"),
      selectedSubOfferingName: i18n.t("Sub_Offering"),
      selectedLocationName: i18n.t("Location"),
      selecetedOMIDName: i18n.t("Process_OM_ID"),
      selecetedOMID: "",
    },
    associationList: [],
    validationMessage: {
      selectedOffering: "",
      selectedSubOffering: "",
      selectedLocation: "",
      selecetedOMID: "",
      associationMessage: "",
    },
    omidList: [],
  });

  // const [adoptionFormValues, setAdoptionFormValues] = useState([
  //   {
  //     CurrentUser: '',
  //     TargetUser: '',
  //     ConfigurationIntelOpsInitiativeID: 0,

  //   },
  // ]);
  // WILL HOLD THE INITIATIVE DETAILS OBJECT FETCHED ON EXECUTING THE "GET" API WHEN IN "EDIT" MODE
  const [fetchedInitiativeDetails, setFetchedInitiativeDetails] = useState({});

  // WILL CONTAIN THE ARRAY OF METRICS IMPACTED ADDED FROM "Metrics Impacted" STEP
  const [metricsImpacted, setMetricsImpacted] = useState([]);

  // "Initiative Category" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  const [initiativeCategoryDropdownData, setInitiativeCategoryDropdownData] = useState([]);

  // "Journey Stage" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  const [journeyStageDropdownData, setJourneyStageDropdownData] = useState([]);

  // "Initiative Impact" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  const [initiativeImpactDropdownData, setInitiativeImpactDropdownData] = useState([]);

  const [propinitiativesSequenceData, setpropinitiativesSequenceData] = useState([]);

  // "Initiative Source" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  const [initiativeSourceDropdownData, setInitiativeSourceDropdownData] = useState([]);

  // "Initiative Status" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  const [initiativeStatusDropdownData, setInitiativeStatusDropdownData] = useState([]);

  // "Assessment Criteria" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  const [assessmentCriteriaDropdownData, setAssessmentCriteriaDropdownData] = useState([]);

  // "SynOps Lever" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  const [synopsLeverDropdownData, setSynOpsLeverDropdownData] = useState([]);

  // "Metric Type Impacted" DROPDOWN DATA DISPLAYED IN "metricsImpacted" STEP
  const [metricTypeImpactedDropdownData, setMetricTypeImpactedDropdownData] = useState([]);

  // "Unit" DROPDOWN DATA DISPLAYED IN "metricsImpacted" STEP
  const [unitDropdownData, setUnitDropdownData] = useState([]);

  const [successStoryLinkProps, setSuccessStoryLinkProps] = useState({
    isRequired: false,
  });

  const [rejectOnHoldCancelledReasonProps, setRejectOnHoldCancelledReasonProps] = useState({
    isRequired: false,
  });

  const [allowedDomainsArray, setAllowedDomainsArray] = useState([]);

  // function setAdoptionFormValues(values){
  //   setFormFieldsWithValues((o) =>({
  //     ...o,
  //     ConfIntelOpsAdoptionMetricsModel: [...values]
  //   }))
  // }z

  const [isApiLoading, setApiLoading] = useState(false);

  const [months, setMonths] = useState([]);
  const[adoptionLink,setAdoptionLink] =useState("");
  const[definition,setDefinition] =useState("");


  const [currentMonthYear, setCurrentMonthYear] = useState(moment(new Date()).format("MMMM-YYYY"));
  const [selectedMonth, setSelectedMonth] = useState(moment(new Date()).format("MMMM-YYYY"));
  const clientId = _.get(CurrentFilter, "ClientData.id", "");

  function handleChangeAdoptionLink(e){
    const adoptionValue= e.target.value;
    setAdoptionLink(adoptionValue);
    const regexUrl= /^(https?|ftp|file):\/\/[-a-zA-Z0-9+&@#\/%=_|$?!:,.]*[a-zA-Z0-9+&@#\/%=_|$]/
    let match = regexUrl.test(adoptionValue) || adoptionValue == null || adoptionValue == undefined || adoptionValue == "NA" || adoptionValue == ""
    if(!match){
      errors.adoptionLinkError= "Please enter valid Story Link"
    }
    else{
      errors.adoptionLinkError=null
      delete errors.adoptionLinkError;
    }
  }

  function handleDefinitionChange(e){
    setDefinition(e.target.value);
  }

  function handleChangeMonth(selectedMonthOption) {
    let SyncFilter = syncFilter;
    SyncFilter.Month = selectedMonthOption;
    updateSyncFilter(SyncFilter);
    setSelectedMonth(selectedMonthOption);
    onMonthChange(selectedMonthOption);
    // setAdoptionFormValues();
  }




  function onMonthChange(selectedMonth) {
    fetchInitiativeDetails(true, selectedMonth);
    const monthsArray =
      fetchedInitiativeDetails &&
      Array.isArray(fetchedInitiativeDetails.ConfIntelOpsAdoptionMetricsModel)
        ? fetchedInitiativeDetails.ConfIntelOpsAdoptionMetricsModel.filter(
            (x) => x.MonthYear === selectedMonth
          )
        : [{
          ID: 0,
          CurrentUser: "",
          TargetUser: "",
          ConfigurationIntelOpsInitiativeID: 0,
          MonthYear: selectedMonth ? selectedMonth : currentMonthYear,
          ConfIntelOpsAdoptionMetricMaster_ID: 1,
          Comment:"",

        }];

    setSelectedMonth(selectedMonth);

    
    setAdoptionFormValues(monthsArray);

  }

  function setAdoptionFormValues(values) {
    setFieldValue("ConfIntelOpsAdoptionMetricsModel", values, true);

  }
  ////////// BELOW BLOCK OF CODE IS FOR ADDING SOME CUSTOM VALIDATION METHODS TO "YUP" //////////////

  // USING CUSTOM TEST METHOD FOR CONDITIONALLY VALIDATING THE FIELD AS "REQUIRED" IF THE FORM IS SUBMITTED AS "FINAL SAVE" AND NOT AS "DRAFT"
  function checkFieldRequiredWhenSaveFinal(args) {
    const { message } = args;
    return this.test(
      "custom-string-required-when-save-final-validation",
      message,
      function (value) {
        const { path, createError } = this;

        const parsedValue = _.isString(value) ? _.trim(value) : value;

        if (initiativeFormSubmittedAs === "save" && _.isEmpty(parsedValue)) {
          return createError({ path, message });
        }
        return true;
      }
    );
  }
  function checkRagFieldWhenSaveFinal(args) {
    const { message } = args;

    return this.test("custom-rag-required-when-save-final-validation", message, function (value) {
      const { path, createError } = this;
      const { values } = formik;
      const parsedValue = _.trim(value);
      if (
        initiativeFormSubmittedAs === "save" &&
        _.isEmpty(parsedValue) &&
        (values.rag === "Amber" || values.rag === "Red")
      ) {
        return createError({ path, message });
      }
      return true;
    });
  }
  yup.addMethod(yup.string, "customFieldRequiredWhenSaveFinal", checkFieldRequiredWhenSaveFinal);
  yup.addMethod(yup.array, "customFieldRequiredWhenSaveFinal", checkFieldRequiredWhenSaveFinal);
  yup.addMethod(yup.object, "customFieldRequiredWhenSaveFinal", checkFieldRequiredWhenSaveFinal);
  yup.addMethod(yup.string, "customFieldRequiredForRAG", checkRagFieldWhenSaveFinal);

  // USING CUSTOM TEST METHOD FOR CHECKING IF THE DATE OBJECT IS A VALID `moment` OBJECT
  function checkCustomDateIsValidMomentObj(args) {
    // const { fieldLabel, message } = args;
    let message = "";
    return this.test("custom-date-is-valid-moment", message, function (value) {
      const { path, createError } = this;

      if (!_.isEmpty(value) && !value.isValid()) {
        return createError({ path, message: message || i18n.t("Invalid_date_value") });
      }
      return true;
    });
  }
  yup.addMethod(yup.object, "customDateIsValidMomentObj", checkCustomDateIsValidMomentObj);

  // USING CUSTOM TEST METHOD FOR CHECKING IF THE DATE IS LATER THAN THE OTHER FIELD
  function checkCustomDateIsAfter(args) {
    const { fieldLabel, compareWithFieldName, compareWithFieldLabel } = args;
    let message = "";
    return this.test("custom-date-is-after", message, function (value) {
      const compareWithFieldValue = _.get(this, `parent.${compareWithFieldName}`);
      const { path, createError } = this;

      if (
        !_.isEmpty(compareWithFieldValue) &&
        compareWithFieldValue.isValid() &&
        !_.isEmpty(value) &&
        value.isValid()
      ) {
        if (!value.isAfter(compareWithFieldValue)) {
          return createError({
            path,
            message:
              message ||
              `${fieldLabel} ${i18n.t("should_be_greater_than")} ${compareWithFieldLabel}`,
          });
        }
      }
      return true;
    });
  }
  yup.addMethod(yup.object, "customDateIsAfter", checkCustomDateIsAfter);

  // USING CUSTOM TEST METHOD FOR CHECKING IF THE DATE IS LATER THAN THE OTHER FIELD
  function checkCustomIsValidUserName(args) {
    // const { fieldLabel, compareWithFieldName, compareWithFieldLabel } = args;
    let message = "";
    return this.test("custom-is-valid-username", message, async function (value) {
      const { path, createError } = this;
      const parsedValue = _.isString(value) ? _.trim(value) : value;

      if (!_.isEmpty(parsedValue)) {
        // CHARACTERS, NUMBERS AND "."(DOT) ARE ALLOWED AND "." SHOULD IS NOT ALLOWED AT BEGINNING AND END OF THE STRING

        let userData = [...new Set([parsedValue])];
        let userValidData = await getValidUserDetails(userData, true);
        const { formValid, errorMessage } = getErrorMessageByData(userValidData, [parsedValue]);

        if (!formValid) {
          return createError({
            path,
            message: errorMessage,
          });
        }

        /*  if (/^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/.test(parsedValue) === false) {
          return createError({
            path,
            message: i18n.t("err_msg_chars_and_dot_allowed"),
          });
        }
        // STRING MUST CONTAIN AY LEAST ONE CHARACTER
        if (/[A-Za-z]/.test(parsedValue) === false) {
          return createError({
            path,
            message: i18n.t("err_msg_should_contain_at_least_one_char"),
          });
        } */
      }
      return true;
    });
  }
  yup.addMethod(yup.string, "customIsValidUserName", checkCustomIsValidUserName);

  // USING CUSTOM TEST METHOD FOR CHECKING IF THE DATE IS LATER THAN THE OTHER FIELD
  function checkCustomDisallowWhiteSpaces(args) {
    const { fieldLabel } = args;
    let message = "";
    return this.test("custom-disallow-white-spaces", message, function (value) {
      const { path, createError } = this;

      // IF THE VALUE CONTAINS ALL THE WHITE SPACES
      if (_.isEmpty(_.trim(value))) {
        return createError({
          path,
          message: message || `${fieldLabel} ${i18n.t("is_Required")}`,
        });
      }

      // IF THE STRING HAS WHITE SPACES AT THE START OR THE END, THEN SHOW ERROR
      // if (/^[^\s]+(\s+[^\s]+)*$/.test(value) === false) {
      //   return createError({
      //     path,
      //     message: i18n.t("no white spaces at the beginning and the end of the value are allowed"),
      //   });
      // }
      return true;
    });
  }
  yup.addMethod(yup.string, "customDisallowWhiteSpaces", checkCustomDisallowWhiteSpaces);

  // USING CUSTOM TEST METHOD FOR CHECKING IF THE DATE OBJECT IS A VALID `moment` OBJECT
  function checkCustomIsURLFromWhitelistedDomain(args) {
    let message = "";
    return this.test("check-custom-is-url-from-whitelisted-domain", message, function (value) {
      const { path, createError } = this;
      if (!_.isEmpty(value)) {
        const isAllowed = validateIsAllowedDomainFromURL(value, allowedDomainsArray);
        if (!isAllowed) {
          return createError({
            path,
            message: message || i18n.t("subdomain_or_domain_not_whitelisted"),
          });
        }
      }
      return true;
    });
  }
  yup.addMethod(
    yup.string,
    "customIsURLFromWhitelistedDomain",
    checkCustomIsURLFromWhitelistedDomain
  );

  const validationSchemaForSaveFinalFE = yup.object().shape({
    initiativeCategoryId: yup.string(),
    //   .customFieldRequiredWhenSaveFinal({ message: `${i18n.t("Initiative_Category")} ${i18n.t("is_Required")}` }),

    initiativeName: yup
      .string()
      .customDisallowWhiteSpaces({ fieldLabel: i18n.t("Initiative_Name") })
      .max(
        50,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .test(
        "is-initiativeName-exists",
        `${i18n.t("Initiative_Name")} ${i18n.t("already_exists")}`,
        function () {
          let { journeyStageId, initiativeName } = formik.values;
          let IOJStageID = parseInt(journeyStageId);
          let InitiativeName = initiativeName;
          let StageInitiativeNames = allInitiativeData
            .filter((a) => a.IOJStageID === IOJStageID)
            .map((a) => a.ProjectInitiativeName_En);
          if (
            formMode === "add" &&
            StageInitiativeNames.some(
              (a) => a.trim().toUpperCase() === InitiativeName.trim().toUpperCase()
            )
          )
            return false;
          else return true;
        }
      )
      .test("is-desc-special", specialCharValidation, function () {
        if (!isValidText(formik.values.initiativeName.trim())) {
          return false;
        }
        return true;
      }),

    initiativeDescription: yup
      .string()
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("Initiative_Description")} ${i18n.t("is_Required")}`,
      })
      .max(
        500,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .test("is-special", specialCharValidation, function () {
        if (!isValidText(formik.values.initiativeDescription.trim())) {
          return false;
        }
        return true;
      }),
    ragReason: yup
      .string()
      .customFieldRequiredForRAG({
        message: `${i18n.t("Reason for RAG")} ${i18n.t("is_Required")}`,
      })
      .max(
        200,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      ),
    initiativeImpacts: yup.array().customFieldRequiredWhenSaveFinal({
      message: `${i18n.t("Initiative_Impact")} ${i18n.t("is_Required")}`,
    }),

    initiativeOwner: yup
      .string()
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("Initiative_Owner")} ${i18n.t("is_Required")}`,
      })
      .max(
        100,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .customIsValidUserName(),

    initiativeStatusID: yup.string().customFieldRequiredWhenSaveFinal({
      message: `${i18n.t("Initiative_Status")} ${i18n.t("is_Required")}`,
    }),

    rejectOnHoldCancelledReason: yup
      .string()
      // Required when initiativeStatus = "On Hold", "Rejected" OR "Cancelled"
      .when("initiativeStatusID", {
        is: (initiativeStatusIDValue) =>
          !_.isEmpty(initiativeStatusIDValue) &&
          (initiativeStatusIDValue === "4" ||
            initiativeStatusIDValue === "5" ||
            initiativeStatusIDValue === "6"),
        then: yup.string().customFieldRequiredWhenSaveFinal({
          message: `${i18n.t("Reject_On_Hold_Cancelled_Reason")} ${i18n.t("is_Required")}`,
        }),
      })
      .max(
        150,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      ),

    rag: yup
      .string()
      .customFieldRequiredWhenSaveFinal({ message: `${i18n.t("RAG")} ${i18n.t("is_Required")}` }),

    initiativeSourceId: yup.string(),

    journeyStageId: yup.string().customDisallowWhiteSpaces({ fieldLabel: i18n.t("Journey_Stage") }),

    synopsLeverId: yup.string().customFieldRequiredWhenSaveFinal({
      message: `${i18n.t("SynOps_Lever")} ${i18n.t("is_Required")}`,
    }),

    initiativeAssessments: yup.array(),

    sequence: yup
      .string()
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("Display_Sequence")} ${i18n.t("is_Required")}`,
      })
      .matches(regexForNumbersOnly, {
        message: i18n.t("err_msg_only_non_negative_integers_allowed"),
        excludeEmptyString: true,
      }),
    ConfIntelOpsAdoptionMetricsModel: yup
      .array()
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("At_least")} ${i18n.t("one")} ${i18n.t("Metric_Impacted")} ${i18n.t(
          "is_Required"
        )}`,
      })
      .test("custom-check-metrics-impacted-data", "", function (metricsImpactedArr) {
        const { path, createError } = this;

        if (initiativeFormSubmittedAs === "save" && !_.isEmpty(metricsImpactedArr)) {
          let message =
            "Please fill Current User and Target User Values( Only Numeric Value upto Lakhs or 'NA' is valid)";

          const metricsWithError = _.filter(metricsImpactedArr, (eachMetric) => {
            let isCurrentMetricWithError = false;
            const regPattern = /^(NA|[0]{1,1}|[1-9]{1,1}[0-9]{0,6})$/;

            if (
              (!_.isEmpty(eachMetric.CurrentUser) && !regPattern.test(eachMetric.CurrentUser))
               || ( !_.isEmpty(eachMetric.TargetUser) && !regPattern.test(eachMetric.TargetUser) )
            ) {
              isCurrentMetricWithError = true;
            }
            return isCurrentMetricWithError;
          });

          if (!_.isEmpty(metricsWithError)) {
            return createError({
              path,
              message: message,
            });
          }
        }

        return true;
      }),

    //////////////// CHECKS FOR THE VALIDATION RULE(S) FOR "METRICS IMPACTED" (SORT OF HIDDEN FORM FIELD)
    metricsImpacted: yup
      .array()
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("At_least")} ${i18n.t("one")} ${i18n.t("Metric_Impacted")} ${i18n.t(
          "is_Required"
        )}`,
      })
      .test("custom-check-metrics-impacted-data", "", function (metricsImpactedArr) {
        const { path, createError } = this;

        if (initiativeFormSubmittedAs === "save" && !_.isEmpty(metricsImpactedArr)) {
          let message =
            "Metric Baseline Value, Target Value, Current Value and Unit is Required for all the BOIs & KPI/SLA/SLA+ Added";

          const metricsWithError = _.filter(metricsImpactedArr, (eachMetric) => {
            let isCurrentMetricWithError = false;

            // WHEN `metricTypeImpacted` IS "BOIs" OR "KPI/SLA/SLA+" AND THE MANDATORY FIELDS ARE EMPTY, THEN THE METRIC HAS ERROR
            if (
              (eachMetric.metricTypeImpacted === "1" || eachMetric.metricTypeImpacted === "2") &&
              (_.isEmpty(eachMetric.impactedMetricId) ||
                _.isEmpty(eachMetric.baseLineValue) ||
                _.isEmpty(eachMetric.currentValue) ||
                _.isEmpty(eachMetric.targetValue) ||
                _.isEmpty(eachMetric.unit))
            ) {
              isCurrentMetricWithError = true;
            }
            return isCurrentMetricWithError;
          });

          if (!_.isEmpty(metricsWithError)) {
            return createError({
              path,
              message: message,
            });
          }
        }

        return true;
      }),

    // FIELDS IN "TIMELINES AND OUTCOMES" STEP
    plannedStartDate: yup
      .object()
      .nullable(true)
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("Planned_Start_Date")} ${i18n.t("is_Required")}`,
      })
      .customDateIsValidMomentObj()
      // CHECK IF THE "PLANNED START DATE" IS LESS THAN BOTH "PLANNED END DATE" AND "REVISED END DATE"
      .test("planned-start-date-is-before", "", function (value) {
        const plannedEndDateValue = _.get(this, `parent.plannedEndDate`);
        const revisedEndDateValue = _.get(this, `parent.revisedEndDate`);
        const { path, createError } = this;

        if (!_.isEmpty(value) && value.isValid()) {
          if (
            !_.isEmpty(plannedEndDateValue) &&
            plannedEndDateValue.isValid() &&
            !value.isBefore(plannedEndDateValue)
          ) {
            return createError({
              path,
              message: `${i18n.t("Planned_Start_Date")} ${i18n.t("should_be_less_than")} ${i18n.t(
                "Planned_End_Date"
              )}`,
            });
          } else if (
            !_.isEmpty(revisedEndDateValue) &&
            revisedEndDateValue.isValid() &&
            !value.isBefore(revisedEndDateValue)
          ) {
            return createError({
              path,
              message: `${i18n.t("Planned_Start_Date")} ${i18n.t("should_be_less_than")} ${i18n.t(
                "Revised_End_Date"
              )}`,
            });
          }
        }
        return true;
      }),

    actualStartDate: yup
      .object()
      .nullable(true)
      .customDateIsValidMomentObj()
      // CHECK IF THE "Actual Start Date" IS LESS THAN "Actual End Date"
      .test(
        "custom-date-is-before",
        `${i18n.t("Actual_Start_Date")} ${i18n.t("should_be_less_than")} ${i18n.t(
          "Actual_End_Date"
        )}`,
        function (value) {
          const actualEndDateValue = _.get(this, `parent.actualEndDate`);

          if (
            !_.isEmpty(actualEndDateValue) &&
            actualEndDateValue.isValid() &&
            !_.isEmpty(value) &&
            value.isValid()
          ) {
            if (!value.isBefore(actualEndDateValue)) {
              return false;
            }
          }
          return true;
        }
      ),

    plannedEndDate: yup
      .object()
      .nullable(true)
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("Planned_End_Date")} ${i18n.t("is_Required")}`,
      })
      .customDateIsValidMomentObj()
      .customDateIsAfter({
        fieldLabel: i18n.t("Planned_End_Date"),
        compareWithFieldName: "plannedStartDate",
        compareWithFieldLabel: i18n.t("Planned_Start_Date"),
      }),

    revisedEndDate: yup
      .object()
      .nullable(true)
      .customDateIsValidMomentObj()
      .customDateIsAfter({
        fieldLabel: i18n.t("Revised_End_Date"),
        compareWithFieldName: "plannedStartDate",
        compareWithFieldLabel: i18n.t("Planned_Start_Date"),
      }),

    actualEndDate: yup
      .object()
      .nullable(true)
      .customDateIsValidMomentObj()
      .customDateIsAfter({
        fieldLabel: i18n.t("Actual_End_Date"),
        compareWithFieldName: "actualStartDate",
        compareWithFieldLabel: i18n.t("Actual_Start_Date"),
      }),

    initiativeOutcomeOrRemark: yup
      .string()
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("Initiative_Outcome_Remark")} ${i18n.t("is_Required")}`,
      })
      .max(
        150,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .test("is-outcome-special", specialCharValidation, function () {
        if (!isValidText(formik.values.initiativeOutcomeOrRemark.trim())) {
          return false;
        }
        return true;
      }),

    escalationEmail: yup
      .string()
      .max(
        100,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .customIsValidUserName(),

   

      
      successStoryLink: yup
      .string()
      // Required when initiativeStatus = Delivered
      .when("initiativeStatusID", {
        is: (initiativeStatusIDValue) =>
          !_.isEmpty(initiativeStatusIDValue) && initiativeStatusIDValue === "3",
        then: yup.string().customFieldRequiredWhenSaveFinal({
          message: `${i18n.t("Link_to_Success_Story")} ${i18n.t("is_Required")}`,
        }),
      })
      .max(
        5000,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .matches(regexForURLLink, {
        message: i18n.t("err_msg_invalid_URL_format"),
        excludeEmptyString: true,
      })
      .customIsURLFromWhitelistedDomain(),

    reportLink: yup
      .string()
      .max(
        5000,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .matches(regexForURLLink, {
        message: i18n.t("err_msg_invalid_URL_format"),
        excludeEmptyString: true,
      })
      .customIsURLFromWhitelistedDomain(),

    fteBenefit: yup.string().matches(regexForNumbersWithUptoTwoDecimalPlaces, {
      message: i18n.t("err_msg_numbers_upto_two_decimals_allowed"),
      excludeEmptyString: true,
    }),

    costBenefit: yup.string().matches(regexForNumbersWithUptoTwoDecimalPlaces, {
      message: i18n.t("err_msg_numbers_upto_two_decimals_allowed"),
      excludeEmptyString: true,
    }),
  });

  function fetchMonths() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetMonth`)
        .then((response) => {
          const responseData = response.data;
          if (!_.isEmpty(responseData)) {
            setApiLoading(false);
            if (!_.isEmpty(response.data)) {
              const monthsArray = _.map(response.data, (eachItem) =>
                moment(eachItem).format("MMMM-YYYY")
              );
              setMonths(monthsArray);
              resolve(monthsArray);

              // if (!_.isEmpty(monthForPreSelection)) {
              //   if (_.indexOf(monthsArray, monthForPreSelection) !== -1) {
              //     setSelectedMonth(monthForPreSelection);
              //   }
              //   setMonthForPreSelection('');
              // }
            }
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////

  // FORM WOULD BE VALIDATED AGAINST THESE VALIDATION RULES WHEN IT WOULD BE SAVED AS "SAVE" FOR FINAL SAVE OF ALL THE FIELDS WITH VALID VALUES
  const validationSchemaForSaveFinal = yup.object().shape({
    initiativeCategoryId: yup.string(),
    //   .customFieldRequiredWhenSaveFinal({ message: `${i18n.t("Initiative_Category")} ${i18n.t("is_Required")}` }),

    initiativeName: yup
      .string()
      .customDisallowWhiteSpaces({ fieldLabel: i18n.t("Initiative_Name") })
      .max(
        50,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .test(
        "is-initiativeName-exists",
        `${i18n.t("Initiative_Name")} ${i18n.t("already_exists")}`,
        function () {
          let { journeyStageId, initiativeName } = formik.values;
          let IOJStageID = parseInt(journeyStageId);
          let InitiativeName = initiativeName;
          let StageInitiativeNames = allInitiativeData
            .filter((a) => a.IOJStageID === IOJStageID)
            .map((a) => a.ProjectInitiativeName_En);
          if (
            formMode === "add" &&
            StageInitiativeNames.some(
              (a) => a.trim().toUpperCase() === InitiativeName.trim().toUpperCase()
            )
          )
            return false;
          else return true;
        }
      )
      .test("is-desc-special", specialCharValidation, function () {
        if (!isValidText(formik.values.initiativeName.trim())) {
          return false;
        }
        return true;
      }),

    initiativeDescription: yup
      .string()
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("Initiative_Description")} ${i18n.t("is_Required")}`,
      })
      .max(
        500,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .test("is-special", specialCharValidation, function () {
        if (!isValidText(formik.values.initiativeDescription.trim())) {
          return false;
        }
        return true;
      }),
    ragReason: yup
      .string()
      .customFieldRequiredForRAG({
        message: `${i18n.t("Reason for RAG")} ${i18n.t("is_Required")}`,
      })
      .max(
        200,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      ),
    initiativeImpacts: yup.array().customFieldRequiredWhenSaveFinal({
      message: `${i18n.t("Initiative_Impact")} ${i18n.t("is_Required")}`,
    }),

    initiativeOwner: yup
      .string()
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("Initiative_Owner")} ${i18n.t("is_Required")}`,
      })
      .max(
        100,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .customIsValidUserName(),

    initiativeStatusID: yup.string().customFieldRequiredWhenSaveFinal({
      message: `${i18n.t("Initiative_Status")} ${i18n.t("is_Required")}`,
    }),

    rejectOnHoldCancelledReason: yup
      .string()
      // Required when initiativeStatus = "On Hold", "Rejected" OR "Cancelled"
      .when("initiativeStatusID", {
        is: (initiativeStatusIDValue) =>
          !_.isEmpty(initiativeStatusIDValue) &&
          (initiativeStatusIDValue === "4" ||
            initiativeStatusIDValue === "5" ||
            initiativeStatusIDValue === "6"),
        then: yup.string().customFieldRequiredWhenSaveFinal({
          message: `${i18n.t("Reject_On_Hold_Cancelled_Reason")} ${i18n.t("is_Required")}`,
        }),
      })
      .max(
        150,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      ),

    rag: yup
      .string()
      .customFieldRequiredWhenSaveFinal({ message: `${i18n.t("RAG")} ${i18n.t("is_Required")}` }),

    initiativeSourceId: yup.string(),

    journeyStageId: yup.string().customDisallowWhiteSpaces({ fieldLabel: i18n.t("Journey_Stage") }),

    synopsLeverId: yup.string().customFieldRequiredWhenSaveFinal({
      message: `${i18n.t("SynOps_Lever")} ${i18n.t("is_Required")}`,
    }),

    initiativeAssessments: yup.array(),

    sequence: yup
      .string()
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("Display_Sequence")} ${i18n.t("is_Required")}`,
      })
      .matches(regexForNumbersOnly, {
        message: i18n.t("err_msg_only_non_negative_integers_allowed"),
        excludeEmptyString: true,
      }),
    // ConfIntelOpsAdoptionMetricsModel: yup
    //   .array()
    //   .customFieldRequiredWhenSaveFinal({
    //     message: `${i18n.t("At_least")} ${i18n.t("one")} ${i18n.t("Metric_Impacted")} ${i18n.t(
    //       "is_Required"
    //     )}`,
    //   })
    //   .test("custom-check-metrics-impacted-data", "", function (metricsImpactedArr) {
    //     const { path, createError } = this;

    //     if (initiativeFormSubmittedAs === "save" && !_.isEmpty(metricsImpactedArr)) {
    //       let message =
    //         "Please fill Current User and Target User Values( Only Numeric Value or 'NA' is valid)";

    //       const metricsWithError = _.filter(metricsImpactedArr, (eachMetric) => {
    //         let isCurrentMetricWithError = false;
    //         const regPattern = /^(NA|[0]{1,1}|[1-9]{1,1}[0-9]{0,6})$/;

    //         // WHEN `metricTypeImpacted` IS "BOIs" OR "KPI/SLA/SLA+" AND THE MANDATORY FIELDS ARE EMPTY, THEN THE METRIC HAS ERROR
    //         if (
    //           _.isEmpty(eachMetric.CurrentUser) ||
    //           _.isEmpty(eachMetric.TargetUser) ||
    //           !regPattern.test(eachMetric.CurrentUser) ||
    //           !regPattern.test(eachMetric.TargetUser)
    //         ) {
    //           isCurrentMetricWithError = true;
    //         }
    //         return isCurrentMetricWithError;
    //       });

    //       if (!_.isEmpty(metricsWithError)) {
    //         return createError({
    //           path,
    //           message: message,
    //         });
    //       }
    //     }

    //     return true;
    //   }),

    //////////////// CHECKS FOR THE VALIDATION RULE(S) FOR "METRICS IMPACTED" (SORT OF HIDDEN FORM FIELD)
    metricsImpacted: yup
      .array()
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("At_least")} ${i18n.t("one")} ${i18n.t("Metric_Impacted")} ${i18n.t(
          "is_Required"
        )}`,
      })
      .test("custom-check-metrics-impacted-data", "", function (metricsImpactedArr) {
        const { path, createError } = this;

        if (initiativeFormSubmittedAs === "save" && !_.isEmpty(metricsImpactedArr)) {
          let message =
            "Metric Baseline Value, Target Value, Current Value and Unit is Required for all the BOIs & KPI/SLA/SLA+ Added";

          const metricsWithError = _.filter(metricsImpactedArr, (eachMetric) => {
            let isCurrentMetricWithError = false;

            // WHEN `metricTypeImpacted` IS "BOIs" OR "KPI/SLA/SLA+" AND THE MANDATORY FIELDS ARE EMPTY, THEN THE METRIC HAS ERROR
            if (
              (eachMetric.metricTypeImpacted === "1" || eachMetric.metricTypeImpacted === "2") &&
              (_.isEmpty(eachMetric.impactedMetricId) ||
                _.isEmpty(eachMetric.baseLineValue) ||
                _.isEmpty(eachMetric.currentValue) ||
                _.isEmpty(eachMetric.targetValue) ||
                _.isEmpty(eachMetric.unit))
            ) {
              isCurrentMetricWithError = true;
            }
            return isCurrentMetricWithError;
          });

          if (!_.isEmpty(metricsWithError)) {
            return createError({
              path,
              message: message,
            });
          }
        }

        return true;
      }),

    // FIELDS IN "TIMELINES AND OUTCOMES" STEP
    plannedStartDate: yup
      .object()
      .nullable(true)
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("Planned_Start_Date")} ${i18n.t("is_Required")}`,
      })
      .customDateIsValidMomentObj()
      // CHECK IF THE "PLANNED START DATE" IS LESS THAN BOTH "PLANNED END DATE" AND "REVISED END DATE"
      .test("planned-start-date-is-before", "", function (value) {
        const plannedEndDateValue = _.get(this, `parent.plannedEndDate`);
        const revisedEndDateValue = _.get(this, `parent.revisedEndDate`);
        const { path, createError } = this;

        if (!_.isEmpty(value) && value.isValid()) {
          if (
            !_.isEmpty(plannedEndDateValue) &&
            plannedEndDateValue.isValid() &&
            !value.isBefore(plannedEndDateValue)
          ) {
            return createError({
              path,
              message: `${i18n.t("Planned_Start_Date")} ${i18n.t("should_be_less_than")} ${i18n.t(
                "Planned_End_Date"
              )}`,
            });
          } else if (
            !_.isEmpty(revisedEndDateValue) &&
            revisedEndDateValue.isValid() &&
            !value.isBefore(revisedEndDateValue)
          ) {
            return createError({
              path,
              message: `${i18n.t("Planned_Start_Date")} ${i18n.t("should_be_less_than")} ${i18n.t(
                "Revised_End_Date"
              )}`,
            });
          }
        }
        return true;
      }),

    actualStartDate: yup
      .object()
      .nullable(true)
      .customDateIsValidMomentObj()
      // CHECK IF THE "Actual Start Date" IS LESS THAN "Actual End Date"
      .test(
        "custom-date-is-before",
        `${i18n.t("Actual_Start_Date")} ${i18n.t("should_be_less_than")} ${i18n.t(
          "Actual_End_Date"
        )}`,
        function (value) {
          const actualEndDateValue = _.get(this, `parent.actualEndDate`);

          if (
            !_.isEmpty(actualEndDateValue) &&
            actualEndDateValue.isValid() &&
            !_.isEmpty(value) &&
            value.isValid()
          ) {
            if (!value.isBefore(actualEndDateValue)) {
              return false;
            }
          }
          return true;
        }
      ),

    plannedEndDate: yup
      .object()
      .nullable(true)
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("Planned_End_Date")} ${i18n.t("is_Required")}`,
      })
      .customDateIsValidMomentObj()
      .customDateIsAfter({
        fieldLabel: i18n.t("Planned_End_Date"),
        compareWithFieldName: "plannedStartDate",
        compareWithFieldLabel: i18n.t("Planned_Start_Date"),
      }),

    revisedEndDate: yup
      .object()
      .nullable(true)
      .customDateIsValidMomentObj()
      .customDateIsAfter({
        fieldLabel: i18n.t("Revised_End_Date"),
        compareWithFieldName: "plannedStartDate",
        compareWithFieldLabel: i18n.t("Planned_Start_Date"),
      }),

    actualEndDate: yup
      .object()
      .nullable(true)
      .customDateIsValidMomentObj()
      .customDateIsAfter({
        fieldLabel: i18n.t("Actual_End_Date"),
        compareWithFieldName: "actualStartDate",
        compareWithFieldLabel: i18n.t("Actual_Start_Date"),
      }),

    initiativeOutcomeOrRemark: yup
      .string()
      .customFieldRequiredWhenSaveFinal({
        message: `${i18n.t("Initiative_Outcome_Remark")} ${i18n.t("is_Required")}`,
      })
      .max(
        150,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .test("is-outcome-special", specialCharValidation, function () {
        if (!isValidText(formik.values.initiativeOutcomeOrRemark.trim())) {
          return false;
        }
        return true;
      }),

    escalationEmail: yup
      .string()
      .max(
        100,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .customIsValidUserName(),

    successStoryLink: yup
      .string()
      // Required when initiativeStatus = Delivered
      .when("initiativeStatusID", {
        is: (initiativeStatusIDValue) =>
          !_.isEmpty(initiativeStatusIDValue) && initiativeStatusIDValue === "3",
        then: yup.string().customFieldRequiredWhenSaveFinal({
          message: `${i18n.t("Link_to_Success_Story")} ${i18n.t("is_Required")}`,
        }),
      })
      .max(
        5000,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .matches(regexForURLLink, {
        message: i18n.t("err_msg_invalid_URL_format"),
        excludeEmptyString: true,
      })
      .customIsURLFromWhitelistedDomain(),

    reportLink: yup
      .string()
      .max(
        5000,
        i18n.t("Maximum_allowed_length_is") +
          " ${max} " /*eslint-disable-line no-template-curly-in-string*/ +
          i18n.t("characters")
      )
      .matches(regexForURLLink, {
        message: i18n.t("err_msg_invalid_URL_format"),
        excludeEmptyString: true,
      })
      .customIsURLFromWhitelistedDomain(),

    fteBenefit: yup.string().matches(regexForNumbersWithUptoTwoDecimalPlaces, {
      message: i18n.t("err_msg_numbers_upto_two_decimals_allowed"),
      excludeEmptyString: true,
    }),

    costBenefit: yup.string().matches(regexForNumbersWithUptoTwoDecimalPlaces, {
      message: i18n.t("err_msg_numbers_upto_two_decimals_allowed"),
      excludeEmptyString: true,
    }),
  });

  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formFieldsWithValues,
    // validate,

    validationSchema:
      clientName == "Future Enterprise"
        ? validationSchemaForSaveFinalFE
        : validationSchemaForSaveFinal,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, formikFunctions) => {
      // await new Promise((r) => setTimeout(r, 3000));
      if (clientName !== "Future Enterprise") {
        setFieldValue("ConfIntelOpsAdoptionMetricsModel", null, true);
      }
      let associationValidation = await validateAssociation(formikFunctions);
      if (associationValidation) {
        const status = await executeAPIAddEditInitiative(values);
        // alert(JSON.stringify(values, null, 2));
        formikFunctions.setSubmitting(false);

        if (status === "success") {
          // WHEN THE FORM HAS BEEN SUBMITTED THEN RESET THE PROP `initiativeFormSubmittedAs`

          handleAfterSubmittedInitiativeForm();
        } else {
          resetInitiativeFormSubmittedAs();
        }
      } else {
        setSelectedStep("associations");

        formikFunctions.setSubmitting(false);
        resetInitiativeFormSubmittedAs();
      }
    },
  });

  const {
    values,
    errors,
    isValidating,
    isValid,
    touched,
    // isSubmitting,
    // dirty,
    handleChange,
    handleBlur,
    // handleSubmit,
    setErrors,
    setTouched,
    setFieldValue,
    validateField,
  } = formik;

  //////////////////////////////    EFFECTS   //////////////////////////////////////////////

  // ON COMPONENT MOUNT CALL THE APIs
  useEffect(() => {
    setpropinitiativesSequenceData(initiativesSequenceData);
  }, [initiativesSequenceData]);

  useEffect(async () => {
    setAPILoading(true);
    const reqData = {
      OrgEntityID: clientData.id,
    };
    const allowedDomains = await fetchAllowedDomains(reqData);
    setAllowedDomainsArray(allowedDomains);
    setAPILoading(false);
    const monthyear = await fetchMonths();

    fetchAllDropdownData(monthyear);
  }, []);

  useEffect(() => {
    // IF THE FORM HAS ERRORS THEN RESET THE PROP `initiativeFormSubmittedAs` SO THAT THE ERRORS CAN BE CORRECTED AND THE FORM CAN BE SUBMITTED AGAIN
    if (!_.isEmpty(initiativeFormSubmittedAs) && !_.isEmpty(errors)) {
      const arrErrorFieldsWithFormStep = [];
      _.forEach(formFieldsMapping, (eachMappedFormField) => {
        const fieldName = eachMappedFormField.fieldName;
        const fieldError = _.get(errors, `${fieldName}`, {});
        if (!_.isEmpty(fieldError)) {
          arrErrorFieldsWithFormStep.push({
            fieldName,
            belongsToStep: eachMappedFormField.belongsToStep,
          });
        }
      });

      const foundFieldWithErrorInSelectedStep = _.find(arrErrorFieldsWithFormStep, [
        "belongsToStep",
        selectedStep,
      ]);
      if (_.isEmpty(foundFieldWithErrorInSelectedStep) && !_.isEmpty(arrErrorFieldsWithFormStep)) {
        setSelectedStep(arrErrorFieldsWithFormStep[0].belongsToStep);
      }
    }
  });

  useEffect(() => {
    if (!isValidating && !_.isEmpty(errors)) {
      resetInitiativeFormSubmittedAs();
    }
  }, [errors, isValidating]);

  // WHEN IN EDIT MODE, AFTER THE "GET" API IS RESOLVED THE DATA FETCHED FOR THE INITIATIVE WILL BE IN `fetchedInitiativeDetails`
  // SO PRE-FILL THE FORM WITH THE DATA
  useEffect(() => {
    if(formMode === "add" ){
      const formFieldsAndValues = {
      ConfIntelOpsAdoptionMetricsModel:
   
         [
            {
              ID: 0,
              CurrentUser: "",
              TargetUser: "",
              ConfigurationIntelOpsInitiativeID: 0,
              MonthYear:moment(new Date()).format("MMMM-YYYY") ,
              ConfIntelOpsAdoptionMetricMaster_ID: 1,
              Comment: "",
            },
          ]
        };
        setFormFieldsWithValues(formFieldsAndValues);
    }
    if (formMode === "edit" && !_.isEmpty(fetchedInitiativeDetails)) {
      const formFieldsAndValues = {
        ConfIntelOpsAdoptionMetricsModel:
          Array.isArray(fetchedInitiativeDetails.ConfIntelOpsAdoptionMetricsModel) &&
          fetchedInitiativeDetails.ConfIntelOpsAdoptionMetricsModel.length
            ? fetchedInitiativeDetails.ConfIntelOpsAdoptionMetricsModel
            : [
                {
                  ID: 0,
                  CurrentUser: "",
                  TargetUser: "",
                  ConfigurationIntelOpsInitiativeID: 0,
                  MonthYear: selectedMonth ? selectedMonth : currentMonthYear,
                  ConfIntelOpsAdoptionMetricMaster_ID: 1,
                  Comment: "",
                },
              ],
        initiativeName: !_.isEmpty(fetchedInitiativeDetails.Name)
          ? fetchedInitiativeDetails.Name
          : "",
        initiativeDescription: !_.isEmpty(fetchedInitiativeDetails.Description)
          ? fetchedInitiativeDetails.Description
          : "",
        initiativeImpacts: !_.isEmpty(fetchedInitiativeDetails.initiativeImpactIds)
          ? _.map(fetchedInitiativeDetails.initiativeImpactIds, (eachItem) => String(eachItem))
          : [],
        initiativeOwner: !_.isEmpty(fetchedInitiativeDetails.OwnerEmail)
          ? _.split(fetchedInitiativeDetails.OwnerEmail, "@")[0]
          : "",
        initiativeStatusID: !_.isNil(fetchedInitiativeDetails.InitiativeStatusID)
          ? String(fetchedInitiativeDetails.InitiativeStatusID)
          : "",
        rejectOnHoldCancelledReason: !_.isEmpty(fetchedInitiativeDetails.Reason)
          ? fetchedInitiativeDetails.Reason
          : "",
        rag: !_.isEmpty(fetchedInitiativeDetails.RAG) ? fetchedInitiativeDetails.RAG : "",
        ragReason: !_.isEmpty(fetchedInitiativeDetails.ReasonForRAG)
          ? fetchedInitiativeDetails.ReasonForRAG
          : "",
        initiativeSourceId: !_.isNil(fetchedInitiativeDetails.InitiativeSourceID)
          ? String(fetchedInitiativeDetails.InitiativeSourceID)
          : "",
        initiativeCategoryId: !_.isNil(fetchedInitiativeDetails.InitiativeCategoryID)
          ? String(fetchedInitiativeDetails.InitiativeCategoryID)
          : "",
        journeyStageId: String(journeyStageId),
        synopsLeverId: !_.isNil(fetchedInitiativeDetails.SOCategoryID)
          ? String(fetchedInitiativeDetails.SOCategoryID)
          : "",
        initiativeAssessments: !_.isEmpty(fetchedInitiativeDetails.initiativeAssessmentIds)
          ? _.map(fetchedInitiativeDetails.initiativeAssessmentIds, (eachItem) => String(eachItem))
          : [],
        sequence: !_.isNil(fetchedInitiativeDetails.Sequence)
          ? String(fetchedInitiativeDetails.Sequence)
          : "",

        metricsImpacted: [],

        plannedStartDate: !_.isEmpty(fetchedInitiativeDetails.PlannedStartDate)
          ? moment(fetchedInitiativeDetails.PlannedStartDate)
          : null,
        actualStartDate: !_.isEmpty(fetchedInitiativeDetails.ActualStartDate)
          ? moment(fetchedInitiativeDetails.ActualStartDate)
          : null,
        plannedEndDate: !_.isEmpty(fetchedInitiativeDetails.PlannedEndDate)
          ? moment(fetchedInitiativeDetails.PlannedEndDate)
          : null,
        revisedEndDate: !_.isEmpty(fetchedInitiativeDetails.RevisedEndDate)
          ? moment(fetchedInitiativeDetails.RevisedEndDate)
          : null,
        actualEndDate: !_.isEmpty(fetchedInitiativeDetails.ActualEndDate)
          ? moment(fetchedInitiativeDetails.ActualEndDate)
          : null,
        escalationEmail: !_.isEmpty(fetchedInitiativeDetails.EscalationEmail)
          ? _.split(fetchedInitiativeDetails.EscalationEmail, "@")[0]
          : "",
        reportLink: !_.isEmpty(fetchedInitiativeDetails.ReportOut)
          ? fetchedInitiativeDetails.ReportOut
          : "",
        successStoryLink: !_.isEmpty(fetchedInitiativeDetails.SuccessStory)
          ? fetchedInitiativeDetails.SuccessStory
          : "",
        initiativeOutcomeOrRemark: !_.isEmpty(fetchedInitiativeDetails.Outcome)
          ? fetchedInitiativeDetails.Outcome
          : "",
        fteBenefit: !_.isNil(fetchedInitiativeDetails.FTEBenefit)
          ? String(fetchedInitiativeDetails.FTEBenefit)
          : "",
        costBenefit: !_.isNil(fetchedInitiativeDetails.CostBenefit)
          ? String(fetchedInitiativeDetails.CostBenefit)
          : "",
      };
      setFormFieldsWithValues(formFieldsAndValues);

      const metricsImpacted = _.map(
        fetchedInitiativeDetails.initiativeMetricsImpacted,
        (eachMetric) => {
          const metricTypeImpacted = !_.isNil(eachMetric.MetricTypeImpacted)
            ? String(eachMetric.MetricTypeImpacted)
            : "";

          // FOR THE OLDER IOJ DATA THAT HAS BEEN MIGRATED, CHECK FOR THE VALUE IN THE FIELD IF THE VALUE IS EMPTY THEN CHECK FOR THE VALUE IN THE OTHER FILED WHICH CONTAINS THE MIGRATED VALUE
          let impactedMetricId = "";
          if (!_.isNil(eachMetric.ImpactedMetricId)) {
            impactedMetricId = String(eachMetric.ImpactedMetricId);

            // WHEN `metricTypeImpacted` IS "KPI/SLA/SLA+" THEN `MetricName` WOULD BE NON-EMPTY AND THEN GET THE `ID` VALUE OF THE DROPDOWN OPTION BY FINDING THE MATCHED METRIC NAME IN THE `mappedKPIAndSLAMetrics` DROPDOWN OPTIONS
            if (metricTypeImpacted === "2" && !_.isEmpty(_.get(eachMetric, "MetricName", ""))) {
              let impactedMetricName = eachMetric.MetricName;
              if (
                mappedKPIAndSLAMetrics.filter(
                  (kpim) =>
                    kpim.value &&
                    eachMetric.ImpactedMetricId &&
                    parseInt(kpim.value) === parseInt(eachMetric.ImpactedMetricId)
                ).length
              ) {
                impactedMetricName = "";
                impactedMetricId = "";
              } else {
                impactedMetricId = _.get(
                  getOptionObjFromDropdownValue(
                    mappedKPIAndSLAMetrics,
                    impactedMetricName,
                    "label"
                  ),
                  "value",
                  ""
                );
              }
            }
          } else if (
            !_.isEmpty(metricTypeImpacted) &&
            !_.isNil(eachMetric.BOIKPIImpacted) &&
            !_.isEmpty(eachMetric.BOIKPIImpacted)
          ) {
            const impactedMetricIdString = String(eachMetric.BOIKPIImpacted);

            if (metricTypeImpacted === "1") {
              // WHEN `metricTypeImpacted` IS "BOIs"
              if (
                mappedBOIMetrics.filter(
                  (boim) =>
                    boim.value &&
                    eachMetric.ImpactedMetricId &&
                    parseInt(boim.value) === parseInt(eachMetric.BOIKPIImpacted)
                ).length
              ) {
                impactedMetricId = "";
              } else {
                impactedMetricId = _.get(
                  getOptionObjFromDropdownValue(mappedBOIMetrics, impactedMetricIdString, "label"),
                  "value",
                  ""
                );
              }
            } else if (metricTypeImpacted === "2") {
              // WHEN `metricTypeImpacted` IS "KPI/SLA/SLA+"
              if (
                mappedKPIAndSLAMetrics.filter(
                  (kpim) =>
                    kpim.value &&
                    eachMetric.ImpactedMetricId &&
                    parseInt(kpim.value) === parseInt(eachMetric.ImpactedMetricId)
                ).length
              ) {
                impactedMetricId = "";
              } else {
                impactedMetricId = _.get(
                  getOptionObjFromDropdownValue(
                    mappedKPIAndSLAMetrics,
                    impactedMetricIdString,
                    "label"
                  ),
                  "value",
                  ""
                );
              }
            }
          }

          let targetValue = "";
          if (!_.isNil(eachMetric.TargetValue)) {
            targetValue = String(eachMetric.TargetValue);
          } else if (!_.isNil(eachMetric.OriginalTargetVal)) {
            targetValue = String(eachMetric.OriginalTargetVal);
          }

          let currentValue = "";
          if (!_.isNil(eachMetric.CurrentValue)) {
            currentValue = String(eachMetric.CurrentValue);
          } else if (!_.isNil(eachMetric.OriginalCurrentVal)) {
            currentValue = String(eachMetric.OriginalCurrentVal);
          }

          let unit = "";
          if (!_.isNil(eachMetric.Unit)) {
            unit = String(eachMetric.Unit);
          } else if (!_.isNil(eachMetric.BOIKPIUnit) && !_.isEmpty(eachMetric.BOIKPIUnit)) {
            let unitString = String(eachMetric.BOIKPIUnit);
            unit = _.get(
              getOptionObjFromDropdownValue(unitDropdownData, unitString, "label"),
              "value",
              ""
            );
          }
          return {
            uniqueId: uuidv4(),
            metricTypeImpacted,
            impactedMetricId,
            baseLineValue: !_.isNil(eachMetric.BaseLineValue)
              ? String(eachMetric.BaseLineValue)
              : "",
            targetValue,
            currentValue,
            unit,
          };
        }
      );
      setMetricsImpacted(metricsImpacted);

      // WHEN THE FORM LOADS IN THE "EDIT" MODE, SET TOUCHED THE `initiativeName` FORM FIELD AFTER A TIMEOUT SO THAT IF THE USER TRIES TO SUBMIT THE FORM STRAIGHT AWAY THEN HE CAN DO SO, AS WITHOUT ANY FORM FIELDS BEING TOUCHED FORM SUBMISSION WOULD NOT BE DONE (AS PER THE CONDITION WRITTEN IN `useEffect`)
      setTimeout(() => {
        setTouched({ initiativeName: true }, false);
      }, 2000);
    }
  }, [fetchedInitiativeDetails]);

  // EXECUTES ON "INITIATIVE STATUS DROPDOWN" CHANGE TO MANIPULATE THE "Success Story" AND "Reject /On Hold /Cancelled Reason" FIELDS
  useEffect(() => {
    let isRequiredSuccessStoryLink = false;
    let isRequiredRejectOnHoldCancelledReason = false;

    // MAKE THE "Success Story" MANDATORY WHEN initiativeStatus = "Delivered"
    if (values.initiativeStatusID === "3") {
      isRequiredSuccessStoryLink = true;
    } else if (
      // MAKE THE "Reject /On Hold /Cancelled Reason" MANDATORY WHEN initiativeStatus = "On Hold", "Rejected" OR "Cancelled"
      values.initiativeStatusID === "4" ||
      values.initiativeStatusID === "5" ||
      values.initiativeStatusID === "6"
    ) {
      // On Hold, Rejected, Cancelled
      isRequiredRejectOnHoldCancelledReason = true;
    }
    setSuccessStoryLinkProps({ isRequired: isRequiredSuccessStoryLink });
    setRejectOnHoldCancelledReasonProps({ isRequired: isRequiredRejectOnHoldCancelledReason });
    // setFieldValue("rejectOnHoldCancelledReason", "");
  }, [values.initiativeStatusID]);

  // WHEN THE STATE VALUE CHANGES THEN SET THE (SORT OF "HIDDEN")FORM FIELD ACCORDINGLY
  useEffect(async () => {
    setFieldValue("metricsImpacted", metricsImpacted);
    await sleep(50);
    validateField("metricsImpacted");
  }, [metricsImpacted]);

  // VALIDATE THE FORM WHEN THE "SAVE DRAFT" OR "SAVE" BUTTON WOULD BE CLICKED IN THE PARENT COMPONENT UPON WHICH THE VALUE OF THE PROP `initiativeFormSubmittedAs` WILL BE CHANGED FROM EMPTY STRING TO EITHER "saveDraft" OR "save"
  useEffect(() => {
    if (clientName !== "Future Enterprise") {
      setFieldValue("ConfIntelOpsAdoptionMetricsModel", null, true);
    }
    if (!_.isEmpty(initiativeFormSubmittedAs)) {
      formik.setStatus(initiativeFormSubmittedAs);
      // VALIDATE THE FORM
      formik.validateForm().then(() => {});
    }
  }, [initiativeFormSubmittedAs]);

  // SUBMIT THE FORM ONCE ALL THE REQUIRED CONDITIONS ARE MET
  useEffect(() => {
    if (clientName !== "Future Enterprise") {
      setFieldValue("ConfIntelOpsAdoptionMetricsModel", null, true);
    }

    // FORM IS VALID ONLY WHEN THERE ARE NO ERRORS (isValid = true) AND THE TOUCHED FIELDS ARE NOT EMPTY
    if (!_.isEmpty(initiativeFormSubmittedAs) && !isValidating && isValid && !_.isEmpty(touched)) {
      formik.submitForm();
    }
  }, [initiativeFormSubmittedAs, isValid, touched]);

  //////////////////////////////    FUNCTIONS FOR API CALLING   //////////////////////////////////////////////
  function fetchAllDropdownData(month) {
    setAPILoading(true);
    Promise.all([
      fetchInitiativeCategoryDropdownData(),
      fetchInitiativeImpactDropdownData(),
      fetchInitiativeSourceDropdownData(),
      fetchInitiativeStatusDropdownData(),
      fetchAssessmentCriteriaDropdownData(),
      fetchJourneyStageDropdownData(),
      fetchSynOpsLeverDropdownData(),
      fetchMetricTypeImpactedDropdownData(),
      fetchUnitDropdownData(),
    ])
      .then(() => {
        setAPILoading(false);
        if (formMode === "edit") {
          fetchInitiativeDetails(true, month[0]);
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        setAPILoading(false);
      });
  }

  // FETCH "Initiative Category" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  function fetchInitiativeCategoryDropdownData(manipulateAPILoader = false) {
    manipulateAPILoader && setAPILoading(true);

    return axiosInstance
      .get(`${LocalApiBaseUrl}IOJJourneyInitiative/GetIOJCategory?LanguageId=${languageId}`)
      .then((response) => {
        manipulateAPILoader && setAPILoading(false);
        const responseData = response.data;

        if (!_.isEmpty(responseData)) {
          const mappedDataForDropdown = _.map(responseData, (eachItem) => ({
            label: eachItem.IntelOpsCategoryName,
            label_en: eachItem.IntelOpsCategoryName_En,
            value: String(eachItem.IntelOpsCategoryId),
          }));
          setInitiativeCategoryDropdownData(mappedDataForDropdown);
        } else {
          throw new Error("RECEIVED EMPTY DATA FOR IMPACTED METRICS DROPDOWN");
        }
      })
      .catch((error) => {
        manipulateAPILoader && setAPILoading(false);
        trycatchAlertPopup(error);
      });
  }

  // FETCH "Initiative Impact" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  function fetchInitiativeImpactDropdownData(manipulateAPILoader = false) {
    manipulateAPILoader && setAPILoading(true);

    return axiosInstance
      .get(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetInitiativeImpactList?LanguageId=${languageId}`
      )
      .then((response) => {
        manipulateAPILoader && setAPILoading(false);
        const responseData = response.data;
        if (!_.isEmpty(responseData)) {
          const mappedDataForDropdown = _.sortBy(
            _.map(responseData, (eachItem) => ({
              label: eachItem.ImpactName,
              label_en: eachItem.ImpactName_En,
              value: String(eachItem.ID),
            })),
            ["value"]
          );
          setInitiativeImpactDropdownData(mappedDataForDropdown);
        } else {
          throw new Error("RECEIVED EMPTY DATA FOR INITIATIVE IMPACT DROPDOWN");
        }
      })
      .catch((error) => {
        manipulateAPILoader && setAPILoading(false);
        trycatchAlertPopup(error);
      });
  }

  // FETCH "Initiative Source" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  function GetInitiativeSourceList(IOJStageID, manipulateAPILoader) {
    manipulateAPILoader && setAPILoading(true);
    return axiosInstance
      .get(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetInitiativeSourceList?LanguageId=${languageId}&IOJStageID=${IOJStageID}`
      )
      .then((response) => {
        manipulateAPILoader && setAPILoading(false);
        const responseData = response.data;

        if (!_.isEmpty(responseData)) {
          const mappedDataForDropdown = _.map(responseData, (eachItem) => ({
            label: eachItem.SourceName,
            label_en: eachItem.SourceName_En,
            value: String(eachItem.ID),
          }));
          setInitiativeSourceDropdownData(mappedDataForDropdown);
        } else {
          throw new Error("RECEIVED EMPTY DATA FOR INITIATIVE SOURCE DROPDOWN");
        }
      })
      .catch((error) => {
        manipulateAPILoader && setAPILoading(false);
        trycatchAlertPopup(error);
      });
  }
  function GetMaxSequence(IOJStageID, manipulateAPILoader) {
    let maxSequence = "";
    let ConfigurationIntelOpsRoadmapID = JourneyData.ConfigurationIntelOpsRoadmapID;
    debugger;
    if (
      ConfigurationIntelOpsRoadmapID !== undefined &&
      ConfigurationIntelOpsRoadmapID !== 0 &&
      IOJStageID != 0
    ) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`${LocalApiBaseUrl}IOJJourneyInitiative/GetMaxSequence`, {
            params: {
              RoadmapId: ConfigurationIntelOpsRoadmapID,
              StageID: parseInt(IOJStageID),
              isInitiative: true,
            },
          })
          .then((response) => {
            if (response.data) {
              maxSequence = response.data;
              let outComeSequenceData = genarateArrayRange(1, maxSequence);
              outComeSequenceData = _.map(outComeSequenceData, (eachItem) => ({
                label: eachItem,
                label_en: eachItem,
                value: String(eachItem),
              }));

              setpropinitiativesSequenceData(outComeSequenceData);
              resolve();
            } else {
              setpropinitiativesSequenceData([]);
            }
          })
          .catch((error) => {
            reject(error);
            trycatchAlertPopup(error);
          });
      });
    }
  }
  function GetAssessmentCriteriaInitiativeDropdown(IOJStageID, manipulateAPILoader) {
    manipulateAPILoader && setAPILoading(true);
    return axiosInstance
      .get(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetAssessmentCriteriaInitiativeDropdown?LanguageID=${languageId}&IOJStageID=${IOJStageID}`,
        {
          params: {
            orgEntityID: clientData.id,
            offeringID: syncFilter.ProcessId,
            offeringName: syncFilter.OfferingName,
            locationID: syncFilter.LocationId,
            omid: syncFilter.LocationOMID[0] === "All" ? 0 : syncFilter.LocationOMID[0],
          },
        }
      )
      .then((response) => {
        manipulateAPILoader && setAPILoading(false);
        const responseData = response.data;

        if (!_.isEmpty(responseData)) {
          const mappedDataForDropdown = _.sortBy(
            _.map(responseData, (eachItem) => ({
              label: eachItem.CriteriaName,
              label_en: eachItem.CriteriaName_En,
              value: String(eachItem.ID),
            })),
            ["label_en"]
          );
          setAssessmentCriteriaDropdownData(mappedDataForDropdown);
        }
        // else {
        //   throw new Error("RECEIVED EMPTY DATA FOR ASSESSMENT CRITERIA DROPDOWN");
        // }
      })
      .catch((error) => {
        manipulateAPILoader && setAPILoading(false);
        trycatchAlertPopup(error);
      });
  }
  function fetchInitiativeSourceDropdownData(manipulateAPILoader = false) {
    let IOJStageID = journeyStageId;
    GetInitiativeSourceList(IOJStageID, manipulateAPILoader);
  }
  function fetchDataforIM(IOJStageID, manipulateAPILoader = false) {
    manipulateAPILoader && setAPILoading(true);
    GetInitiativeSourceList(IOJStageID, manipulateAPILoader);
    GetAssessmentCriteriaInitiativeDropdown(IOJStageID, manipulateAPILoader);
    GetMaxSequence(IOJStageID, manipulateAPILoader);
  }
  function genarateArrayRange(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }
  // FETCH "Initiative Status" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  function fetchInitiativeStatusDropdownData(manipulateAPILoader = false) {
    manipulateAPILoader && setAPILoading(true);

    return axiosInstance
      .get(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetInitiativeStatusList?LanguageId=${languageId}`
      )
      .then((response) => {
        manipulateAPILoader && setAPILoading(false);
        const responseData = response.data;

        if (!_.isEmpty(responseData)) {
          const mappedDataForDropdown = _.map(responseData, (eachItem) => ({
            label: eachItem.StatusName,
            label_en: eachItem.StatusName_En,
            value: String(eachItem.ID),
          }));
          setInitiativeStatusDropdownData(mappedDataForDropdown);
        } else {
          throw new Error("RECEIVED EMPTY DATA FOR INITIATIVE STATUS DROPDOWN");
        }
      })
      .catch((error) => {
        manipulateAPILoader && setAPILoading(false);
        const errMsg = "THERE WAS SOME ERROR WHILE FETCHING INITIATIVE STATUS DROPDOWN DATA";

        alert(errMsg);
      });
  }

  // FETCH "Assessment Criteria" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  function fetchAssessmentCriteriaDropdownData(manipulateAPILoader = false) {
    let IOJStageID = journeyStageId;
    GetAssessmentCriteriaInitiativeDropdown(IOJStageID, manipulateAPILoader);
  }
  // FETCH "SynOps Lever" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  function fetchSynOpsLeverDropdownData(manipulateAPILoader = false) {
    manipulateAPILoader && setAPILoading(true);

    return axiosInstance
      .get(`${LocalApiBaseUrl}SO/GetSOCategories?LanguageId=${languageId}`)
      .then((response) => {
        manipulateAPILoader && setAPILoading(false);
        let responseData = response.data;

        if (!_.isEmpty(responseData)) {
          // REMOVE "Business Outcomes" LEVER SO THAT IT DOES NOT DISPLAY IN THE DROPDOWN
          responseData = _.filter(
            responseData,
            (eachItem) => _.toLower(eachItem.SOCategoryName_En) !== "business outcomes"
          );
          const mappedDataForDropdown = _.sortBy(
            _.map(responseData, (eachItem) => ({
              label: eachItem.SOCategoryName,
              label_en: eachItem.SOCategoryName_En,
              value: String(eachItem.SOCategoryID),
            })),
            ["label_en"]
          );
          setSynOpsLeverDropdownData(mappedDataForDropdown);
        } else {
          throw new Error("RECEIVED EMPTY DATA FOR SYNOPS LEVER DROPDOWN");
        }
      })
      .catch((error) => {
        manipulateAPILoader && setAPILoading(false);
        trycatchAlertPopup(error);
      });
  }

  // FETCH "Journey Stage" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  function fetchJourneyStageDropdownData(manipulateAPILoader = false) {
    manipulateAPILoader && setAPILoading(true);

    return axiosInstance
      .get(`${LocalApiBaseUrl}IOJJourneyInitiative/GetJourneyStages?LanguageId=${languageId}`)
      .then((response) => {
        manipulateAPILoader && setAPILoading(false);
        const responseData = response.data;

        if (!_.isEmpty(responseData)) {
          const mappedDataForDropdown = _.map(responseData, (eachItem) => ({
            label: eachItem.IOJStageName,
            label_en: eachItem.IOJStageName_En,
            value: String(eachItem.IOJStageID),
          }));
          setJourneyStageDropdownData(mappedDataForDropdown);
        } else {
          throw new Error("RECEIVED EMPTY DATA FOR JOURNEY STAGE DROPDOWN");
        }
      })
      .catch((error) => {
        manipulateAPILoader && setAPILoading(false);
        trycatchAlertPopup(error);
      });
  }

  // FETCH "Metric Type Impacted" DROPDOWN DATA DISPLAYED IN "metricsImpacted" STEP
  function fetchMetricTypeImpactedDropdownData(manipulateAPILoader = false) {
    manipulateAPILoader && setAPILoading(true);

    return axiosInstance
      .get(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetImpactedMetricTypeList?LanguageId=${languageId}`
      )
      .then((response) => {
        manipulateAPILoader && setAPILoading(false);
        const responseData = response.data;

        if (!_.isEmpty(responseData)) {
          const mappedDataForDropdown = _.sortBy(
            _.map(responseData, (eachItem) => ({
              label: eachItem.MetricTypeName,
              label_en: eachItem.MetricTypeName_En,
              value: String(eachItem.ID),
            })),
            ["label_en"]
          );
          setMetricTypeImpactedDropdownData(mappedDataForDropdown);
        } else {
          throw new Error("RECEIVED EMPTY DATA FOR METRIC TYPE IMPACTED DROPDOWN");
        }
      })
      .catch((error) => {
        manipulateAPILoader && setAPILoading(false);
        trycatchAlertPopup(error);
      });
  }

  // FETCH "Unit" DROPDOWN DATA DISPLAYED IN "metricsImpacted" STEP
  function fetchUnitDropdownData(manipulateAPILoader = false) {
    manipulateAPILoader && setAPILoading(true);

    return axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetAllUnits?LanguageId=${languageId}`)
      .then((response) => {
        manipulateAPILoader && setAPILoading(false);
        const responseData = response.data;

        if (!_.isEmpty(responseData)) {
          const mappedDataForDropdown = _.sortBy(
            _.map(responseData, (eachItem) => ({
              label: eachItem.MetricUnit,
              label_en: eachItem.MetricUnit_En,
              value: String(eachItem.UnitId),
            })),
            ["label_en"]
          );
          setUnitDropdownData(mappedDataForDropdown);
        } else {
          throw new Error("RECEIVED EMPTY DATA FOR UNIT DROPDOWN");
        }
      })
      .catch((error) => {
        manipulateAPILoader && setAPILoading(false);
        trycatchAlertPopup(error);
      });
  }

  // FETCH "Initiative Details" FROM ID
  function fetchInitiativeDetails(manipulateAPILoader = false, selectedMonthYear) {
    manipulateAPILoader && setAPILoading(true);

    return axiosInstance
      .get(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetInitiativeDetails?LanguageId=${languageId}&InitiativeID=${initiativeId}&MonthYear=${selectedMonthYear}`
      )
      .then((response) => {
        manipulateAPILoader && setAPILoading(false);
        const responseData = response.data;

        if (!_.isEmpty(responseData)) {
          associationData.associationList =
            responseData.ConfIntelOpsInitAssociationsModel &&
            responseData.ConfIntelOpsInitAssociationsModel.length
              ? responseData.ConfIntelOpsInitAssociationsModel
              : [];

          setAssociationData(associationData);
          setFetchedInitiativeDetails(responseData);
          setAdoptionLink(responseData.StoryLink);
          setDefinition(responseData.Definition);
        } else {
          throw new Error("RECEIVED EMPTY DATA FOR INITIATIVE DETAILS");
        }
      })
      .catch((error) => {
        manipulateAPILoader && setAPILoading(false);
        trycatchAlertPopup(error);
      });
  }

  // EXECUTES THE API TO ADD/EDIT THE INITIATIVE AS "DRAFT"(`saveDraft`) OR "FINAL SAVE"(`save`)
  function executeAPIAddEditInitiative(formValues) {
    storeActionStatus({ isAction: true });
    return new Promise((resolve) => {
      let succMsg = "Initiative added successfully";
      let errMsg = "THERE WAS SOME ERROR WHILE ADDING INITIATIVE";

      let IsDraft = false;
      if (initiativeFormSubmittedAs === "saveDraft") {
        IsDraft = true;
        succMsg = "Initiative saved as draft successfully";
        errMsg = "THERE WAS SOME ERROR WHILE SAVING INITIATIVE AS DRAFT";
      }

      const initiativeStatusID = formValues.initiativeStatusID;
      let rejectOnHoldCancelledReason = "";

      // Only pass the value of `rejectOnHoldCancelledReason` as non-empty when initiativeStatus = "On Hold", "Rejected" OR "Cancelled"
      if (initiativeStatusID === "4" || initiativeStatusID === "5" || initiativeStatusID === "6") {
        rejectOnHoldCancelledReason = _.trim(formValues.rejectOnHoldCancelledReason);
      }
      associationData.associationList = associationData.associationList.map((each) => {
        each.InitiativeName = _.trim(formValues.initiativeName);
        return each;
      });
      const requestData = {
        // FIELDS FOR "INITIATIVE DETAILS" STEP
        Name: _.trim(formValues.initiativeName),
        Description: _.trim(formValues.initiativeDescription),
        ReasonForRAG: _.trim(formValues.ragReason),
        initiativeImpactIds: !_.isEmpty(formValues.initiativeImpacts)
          ? _.map(formValues.initiativeImpacts, (eachItem) => _.toNumber(eachItem))
          : [],
        OwnerEmail: !_.isEmpty(_.trim(formValues.initiativeOwner))
          ? `${_.trim(formValues.initiativeOwner)}@accenture.com`
          : "",
        InitiativeStatusID: !_.isEmpty(initiativeStatusID) ? _.toNumber(initiativeStatusID) : null,
        Reason: rejectOnHoldCancelledReason,
        RAG: formValues.rag,
        InitiativeSourceID: !_.isEmpty(formValues.initiativeSourceId)
          ? _.toNumber(formValues.initiativeSourceId)
          : null,
        InitiativeCategoryID: !_.isEmpty(formValues.initiativeCategoryId)
          ? _.toNumber(formValues.initiativeCategoryId)
          : null,
        IOJStageID: !_.isEmpty(formValues.journeyStageId)
          ? _.toNumber(formValues.journeyStageId)
          : null,
        SOCategoryID: !_.isEmpty(formValues.synopsLeverId)
          ? _.toNumber(formValues.synopsLeverId)
          : null,
        initiativeAssessmentIds: !_.isEmpty(formValues.initiativeAssessments)
          ? _.map(formValues.initiativeAssessments, (eachItem) => _.toNumber(eachItem))
          : [],
        Sequence: !_.isEmpty(_.trim(formValues.sequence))
          ? _.toNumber(_.trim(formValues.sequence))
          : 0,

        // FIELD FOR "METRICS IMPACTED" STEP
        initiativeMetricsImpacted: !_.isEmpty(formValues.metricsImpacted)
          ? _.map(formValues.metricsImpacted, (eachItem) => ({
              MetricTypeImpacted: !_.isEmpty(eachItem.metricTypeImpacted)
                ? _.toNumber(eachItem.metricTypeImpacted)
                : null,
              ImpactedMetricId: !_.isEmpty(eachItem.impactedMetricId)
                ? _.toNumber(eachItem.impactedMetricId)
                : null,
              BaseLineValue: !_.isEmpty(eachItem.baseLineValue)
                ? _.toNumber(eachItem.baseLineValue)
                : null,
              TargetValue: !_.isEmpty(eachItem.targetValue)
                ? _.toNumber(eachItem.targetValue)
                : null,
              CurrentValue: !_.isEmpty(eachItem.currentValue)
                ? _.toNumber(eachItem.currentValue)
                : null,
              Unit: !_.isEmpty(eachItem.unit) ? _.toNumber(eachItem.unit) : null,
            }))
          : [],

        // FIELDS FOR "TIMELINES AND OUTCOMES" STEP
        PlannedStartDate: !_.isEmpty(formValues.plannedStartDate)
          ? formValues.plannedStartDate.format()
          : null,
        ActualStartDate: !_.isEmpty(formValues.actualStartDate)
          ? formValues.actualStartDate.format()
          : null,
        PlannedEndDate: !_.isEmpty(formValues.plannedEndDate)
          ? formValues.plannedEndDate.format()
          : null,
        RevisedEndDate: !_.isEmpty(formValues.revisedEndDate)
          ? formValues.revisedEndDate.format()
          : null,
        ActualEndDate: !_.isEmpty(formValues.actualEndDate)
          ? formValues.actualEndDate.format()
          : null,
        EscalationEmail: !_.isEmpty(_.trim(formValues.escalationEmail))
          ? `${_.trim(formValues.escalationEmail)}@accenture.com`
          : "",
        ReportOut: !_.isEmpty(_.trim(formValues.reportLink)) ? _.trim(formValues.reportLink) : "",
        SuccessStory: !_.isEmpty(_.trim(formValues.successStoryLink))
          ? _.trim(formValues.successStoryLink)
          : "",
        Outcome: !_.isEmpty(_.trim(formValues.initiativeOutcomeOrRemark))
          ? _.trim(formValues.initiativeOutcomeOrRemark)
          : "",
        FTEBenefit: !_.isEmpty(formValues.fteBenefit) ? _.toNumber(formValues.fteBenefit) : null,
        CostBenefit: !_.isEmpty(formValues.costBenefit) ? _.toNumber(formValues.costBenefit) : null,

        // EXTRA DATA
        IsDraft,
        ConfigurationIntelOpsJourneyID: 0,
        ConfigurationIntelOpsRoadmapID: journeyData.ConfigurationIntelOpsRoadmapID,
        filters: {
          OrgEntityID: clientData.id,
          OfferingID: syncFilter.ProcessId,
          OfferingName: syncFilter.OfferingName,
          LocationID: syncFilter.LocationId,
          OMID: syncFilter.LocationOMID[0] === "All" ? 0 : syncFilter.LocationOMID[0],
        },
        ConfIntelOpsInitAssociationsModel: [...associationData.associationList],
        IsAdd: formType === "draftEdit" || formMode === "add" ? true : false,
        ConfIntelOpsAdoptionMetricsModel:
          clientName == "Future Enterprise" ? [...formValues.ConfIntelOpsAdoptionMetricsModel] : [],
          StoryLink:   clientName == "Future Enterprise" ? adoptionLink : null,
          Definition: clientName == "Future Enterprise" ? definition : null
      };

      if (formMode === "edit") {
        _.set(requestData, "ID", initiativeId);
      }

      if (formMode === "edit" && initiativeFormSubmittedAs !== "saveDraft") {
        succMsg = "Initiative updated successfully";
        errMsg = "THERE WAS SOME ERROR WHILE UPDATING INITIATIVE";
      }

      setAPILoading(true);
      axiosInstance
        .post(`${LocalApiBaseUrl}IOJJourneyInitiative/AddEditInitiatives`, requestData)
        .then((response) => {
          setAPILoading(false);
          window.alert(succMsg);
          const responseData = response.data;
          resolve("success");
        })
        .catch((error) => {
          setAPILoading(false);
          resolve("failure");
        });
      GetIOJQcscore();
    });
  }
  /* useEffect(() => {

    if(isInitativeManagement){
  customBlur();
}
  }, [formik.values.journeyStageId]) */

  const customBlur = async () => {
    const { initiativeName, journeyStageId } = formik.values;
    let isValid = true,
      error = "";
    let clonederrors = _.cloneDeep(formik.errors);
    if (!_.isEmpty(journeyStageId)) {
      const isDuplicateExists = await executeAPIToCheckDuplicateInitiativeName(initiativeName);
      if (isDuplicateExists) {
        error = `${i18n.t("Initiative_Name")} ${i18n.t("already_exists")}`;
        isValid = false;
      } else {
        error = "";
      }
    }
    if (_.isEmpty(error)) {
      _.unset(clonederrors, "initiativeName");
    } else {
      _.set(clonederrors, "initiativeName", error);
    }
    setErrors(clonederrors);

    return isValid;
  };
  // EXECUTES THE API TO CHECK WETHER THE "INITIATIVE NAME" HAS ALREADY BEEN ADDED PREVIOUSLY AND IS NOT DUPLICATE
  function executeAPIToCheckDuplicateInitiativeName(initiativeName) {
    return new Promise(async (resolve, reject) => {
      // ONLY SHOW THE LOADER WHEN THE FORM IS SUBMITTED AND NOT OTHERWISE
      !_.isEmpty(initiativeFormSubmittedAs) && setAPILoading(true);

      let isDuplicateExists;
      axiosInstance
        .get(
          `${LocalApiBaseUrl}IOJJourneyInitiative/IsInitiativeNameExist?ConfigurationIntelOpsRoadmapID=${journeyData.ConfigurationIntelOpsRoadmapID}&IOJStageID=${journeyStageId}&InitiativeName=${initiativeName}`
        )
        .then((response) => {
          const responseData = response.data;
          setAPILoading(false);
          isDuplicateExists = responseData;
          resolve(isDuplicateExists);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          setAPILoading(false);
          isDuplicateExists = true;
          resolve(isDuplicateExists);
        });
    });
  }

  //////////////////////////////    EVENT HANDLERS   //////////////////////////////////////////////
  function handleClickPreviousButton() {
    let selectedSection;
    if (clientName !== "Future Enterprise") {
      if (selectedStep === "metricsImpacted") {
        selectedSection = "initiativeDetails";
      } else if (selectedStep === "timelinesAndOutcomes") {
        selectedSection = "metricsImpacted";
      } else if (selectedStep === "associations") {
        selectedSection = "timelinesAndOutcomes";
      }
      setSelectedStep(selectedSection);
    } else {
      if (selectedStep === "metricsImpacted") {
        selectedSection = "initiativeDetails";
      } else if (selectedStep === "timelinesAndOutcomes") {
        selectedSection = "metricsImpacted";
      } else if (selectedStep === "associations") {
        selectedSection = "timelinesAndOutcomes";
      } else if (selectedStep === "adoptions") {
        selectedSection = "associations";
      }
      setSelectedStep(selectedSection);
    }
  }

  function handleClickNextButton() {
    let selectedSection;
    if (clientName !== "Future Enterprise") {
      if (selectedStep === "initiativeDetails") {
        selectedSection = "metricsImpacted";
      } else if (selectedStep === "metricsImpacted") {
        selectedSection = "timelinesAndOutcomes";
      } else if (selectedStep === "timelinesAndOutcomes") {
        selectedSection = "associations";
      }
      setSelectedStep(selectedSection);
    } else {
      if (selectedStep === "initiativeDetails") {
        selectedSection = "metricsImpacted";
      } else if (selectedStep === "metricsImpacted") {
        selectedSection = "timelinesAndOutcomes";
      } else if (selectedStep === "timelinesAndOutcomes") {
        selectedSection = "associations";
      } else if (selectedStep === "associations") {
        selectedSection = "adoptions";
      }
      setSelectedStep(selectedSection);
    }
  }

  // EVENT HANDLER THAT EXECUTES WHEN THE METRIC IMPACTED ARE ADDED/EDITED/DELETED IN THE "metricsImpacted" FORM STEP
  function handleModifyMetricsImpacted(action, metricObj) {
    setMetricsImpacted((prevMetricsArray) => {
      let modifiedMetricsImpactedArray = _.cloneDeep(prevMetricsArray);

      if (action === "add") {
        modifiedMetricsImpactedArray = _.concat(modifiedMetricsImpactedArray, [
          { uniqueId: uuidv4(), ...metricObj },
        ]);
      } else if (action === "edit") {
        _.remove(modifiedMetricsImpactedArray, { uniqueId: metricObj.uniqueId });
        modifiedMetricsImpactedArray = _.concat(modifiedMetricsImpactedArray, [{ ...metricObj }]);
      } else if (action === "delete") {
        _.remove(modifiedMetricsImpactedArray, { uniqueId: metricObj.uniqueId });
      }

      return modifiedMetricsImpactedArray;
    });
  }

  // EVENT HANDLER THAT EXECUTES WHEN THE ANY OPTION IS SELECTED IN A DROPDOWN FIELD
  // SETS THE FIELD VALUE IN FORMIK AND THEN VALIDATES THE FIELD
  async function handleSelectDropdownOption(fieldName, value) {
    if (isInitativeManagement && fieldName === "journeyStageId") {
      setFieldValue("initiativeSourceId", "");
      setFieldValue("initiativeAssessments", []);
      setFieldValue("sequence", "");
      setpropinitiativesSequenceData([]);
      fetchDataforIM(value, false);
    }
    setFieldValue(fieldName, value);
    await sleep(50);
    validateField(fieldName);
  }

  // VALIDATE THE FIELD ON BLUR
  function handleBlurFormField(event, customPassedFieldName) {
    let fieldName = "";
    if (!_.isEmpty(customPassedFieldName)) {
      fieldName = customPassedFieldName;
      setTouched({ [fieldName]: true }, false);
    } else if (!_.isEmpty(event)) {
      fieldName = event.target.id;
      handleBlur(event);
    }

    validateField(fieldName);
  }

  // EVENT HANDLER THAT EXECUTES WHEN THE ANY DATE IS SELECTED IN A DATEPICKER FIELD
  // SETS THE FIELD VALUE IN FORMIK
  function handleChangeDateField(fieldName, selectedDateObj) {
    let valueToSet = _.isDate(selectedDateObj) ? moment(selectedDateObj) : null;
    setFieldValue(fieldName, valueToSet);
  }

  // DEBOUNCED FUNCTION FOR THE API CALL TO IMPROVE PERFORMANCE
  const debouncedValidateField = _.debounce(validateField, 1000);
  const validateAssociation = async () => {
    const { associationList, filteredData } = associationData;

    let formValid = true;
    if (associationList.length && initiativeFormSubmittedAs === "save") {
      let formValidated = await axiosInstance
        .post(
          `${LocalApiBaseUrl}IOJJourneyInitiative/CheckValidationIOJAssociation?LanguageID=${languageId}&IsDraft=${
            formType === "draftEdit" ? true : false
          }`,
          associationList
        )
        .then((response) => {
          if (response.data) {
            let finalData = response.data;
            if (response.data.length) {
              finalData = response.data.map((each) => {
                each["isEditMode"] = false;
                return each;
              });
            }
            setAssociationData((prevState) => {
              return {
                ...prevState,
                filteredData: {
                  selectedOffering: "",
                  selectedSubOffering: "",
                  selectedLocation: "",
                  selectedOfferingName: i18n.t("Offering"),
                  selectedSubOfferingName: i18n.t("Sub_Offering"),
                  selectedLocationName: i18n.t("Location"),
                  selecetedOMIDName: i18n.t("Process_OM_ID"),
                  selecetedOMID: "",
                },
                associationList: [...finalData],
                validationMessage: {
                  selectedOffering: "",
                  selectedSubOffering: "",
                  selectedLocation: "",
                  selecetedOMID: "",
                  associationMessage: "",
                },
              };
            });
            if (
              [...finalData].filter(
                (each) =>
                  !each.StatusType && each.StatusMessage && each.StatusMessage !== "validation_ok"
              ).length
            ) {
              formValid = false;
            }
            return formValid;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
      if (formValidated) {
        return formValid;
      }
    } else {
      return true;
    }
  };
  const setselectedTab = (tabName) => {
    setSelectedStep(tabName);
  };
  const isEmptydata = () => {
    return (
      isInitativeManagement &&
      (_.isEmpty(formik.values.journeyStageId) || parseInt(formik.values.journeyStageId) <= 0)
    );
  };
  return (
    <>
      {/* IF API IS LOADING THEN SHOW THE LOADER */}
      {isAPILoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />}

      <form onSubmit={formik.handleSubmit}>
        {/* Initiative Form Start */}
        <div className="initiative-formsection">
          <h4 className={Object.keys(viewData) != 0 ? "initiativedetail-editcase" : ""}>
            {formMode === "add" ? i18n.t("Add_Initiative") : i18n.t("Edit_Initiative")}
          </h4>

          <div
            className={`${
              clientName !== "Future Enterprise"
                ? "initiative-formtabs IOJTabs"
                : "initiative-formtabs IOJTabsFE"
            }`}
          >
            {!isAPILoading && (
              <Tabs
                activeKey={selectedStep}
                onSelect={(selectedTabKey) => setselectedTab(selectedTabKey)}
              >
                <Tab eventKey="initiativeDetails" title={i18n.t("Initiative_Details")}>
                  {selectedStep === "initiativeDetails" && (
                    <InitiativeDetails
                      initiativesSequenceData={propinitiativesSequenceData}
                      isInitativeManagement={isInitativeManagement}
                      formMode={formMode}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      customBlur={customBlur}
                      errors={errors}
                      debouncedValidateField={debouncedValidateField}
                      setTouched={setTouched}
                      handleSelectDropdownOption={handleSelectDropdownOption}
                      handleBlurFormField={handleBlurFormField}
                      getOptionObjFromDropdownValue={getOptionObjFromDropdownValue}
                      initiativeCategoryDropdownData={initiativeCategoryDropdownData}
                      journeyStageDropdownData={journeyStageDropdownData}
                      initiativeImpactDropdownData={initiativeImpactDropdownData}
                      initiativeSourceDropdownData={
                        isEmptydata() ? [] : initiativeSourceDropdownData
                      }
                      initiativeStatusDropdownData={initiativeStatusDropdownData}
                      assessmentCriteriaDropdownData={
                        isEmptydata() ? [] : assessmentCriteriaDropdownData
                      }
                      synopsLeverDropdownData={synopsLeverDropdownData}
                      rejectOnHoldCancelledReasonProps={rejectOnHoldCancelledReasonProps}
                    />
                  )}
                </Tab>

                <Tab eventKey="metricsImpacted" title={i18n.t("Metrics_Impacted")}>
                  {selectedStep === "metricsImpacted" && (
                    <MetricsImpacted
                      metricsImpacted={metricsImpacted}
                      onModifyMetricsImpacted={handleModifyMetricsImpacted}
                      getOptionObjFromDropdownValue={getOptionObjFromDropdownValue}
                      metricTypeImpactedDropdownData={metricTypeImpactedDropdownData}
                      unitDropdownData={unitDropdownData}
                      parentFormErrors={errors}
                      mappedBOIMetrics={mappedBOIMetrics}
                      mappedKPIAndSLAMetrics={mappedKPIAndSLAMetrics}
                      iimetrics={iiMetrics}
                      boiMetrics={boiList}
                    />
                  )}
                </Tab>

                <Tab eventKey="timelinesAndOutcomes" title={i18n.t("Timelines_and_Outcomes")}>
                  {selectedStep === "timelinesAndOutcomes" && (
                    <TimelinesAndOutcomes
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      handleBlurFormField={handleBlurFormField}
                      handleChangeDateField={handleChangeDateField}
                      successStoryLinkProps={successStoryLinkProps}
                    />
                  )}
                </Tab>

                <Tab eventKey="associations" title={i18n.t("Associations")}>
                  {selectedStep === "associations" && (
                    <AssociationsTab
                      values={values}
                      associationData={associationData}
                      setAssociationData={setAssociationData}
                      formType={formType}
                      isInitativeManagement={isInitativeManagement}
                      journeyStageId={
                        isInitativeManagement ? formik.values.journeyStageId : journeyStageId
                      }
                      formMode={formMode}
                    />
                  )}
                </Tab>
                {clientName === "Future Enterprise" && (
                  <Tab eventKey="adoptions" title={i18n.t("Adoption")}>
                    {selectedStep === "adoptions" && (
                      <AdoptionMetricsTab
                        languageId={languageId}
                        initiativeId={initiativeId}
                        adoptionFormValues={values.ConfIntelOpsAdoptionMetricsModel}
                        setAdoptionFormValues={setAdoptionFormValues}
                        errors={errors}
                        handleChangeMonth={handleChangeMonth}
                        months={months}
                        currentMonthYear={currentMonthYear}
                        selectedMonth={selectedMonth}
                        adoptionLink={adoptionLink}
                        handleChangeAdoptionLink={handleChangeAdoptionLink}
                        handleDefinitionChange={handleDefinitionChange}
                        definition ={definition}
                      />
                    )}
                  </Tab>
                )}
              </Tabs>
            )}
          </div>
          {clientName !== "Future Enterprise" ? (
            <div className="initiative-navbtns">
              <button
                type="button"
                onClick={() => handleClickPreviousButton()}
                className={classNames(
                  "btn",
                  "btn-outline-primary",
                  { visible: selectedStep !== "initiativeDetails" },
                  { invisible: selectedStep === "initiativeDetails" }
                )}
              >
                <i className="fal fa-angle-left mr-2"></i> {i18n.t("Previous")}
              </button>

              <button
                type="button"
                onClick={() => handleClickNextButton()}
                className={classNames(
                  "btn",
                  "btn-primary",
                  { visible: selectedStep !== "associations" },
                  { invisible: selectedStep === "associations" }
                )}
              >
                {i18n.t("Next")} <i className="fal fa-angle-right ml-2"></i>
              </button>

              {selectedStep === "associations" && (
                <button
                  type="button"
                  onClick={() => handleClickSubmitInitiativeForm("save")}
                  className={classNames(
                    "btn",
                    "btn-primary",
                    { visible: selectedStep === "associations" },
                    { invisible: selectedStep !== "associations" }
                  )}
                >
                  {formMode === "add" ? i18n.t("Add") : i18n.t("Update")}
                </button>
              )}
            </div>
          ) : (
            <div className="initiative-navbtns">
              <button
                type="button"
                onClick={() => handleClickPreviousButton()}
                className={classNames(
                  "btn",
                  "btn-outline-primary",
                  { visible: selectedStep !== "initiativeDetails" },
                  { invisible: selectedStep === "initiativeDetails" }
                )}
              >
                <i className="fal fa-angle-left mr-2"></i> {i18n.t("Previous")}
              </button>

              <button
                type="button"
                onClick={() => handleClickNextButton()}
                className={classNames(
                  "btn",
                  "btn-primary",
                  { visible: selectedStep !== "adoptions" },
                  { invisible: selectedStep === "adoptions" }
                )}
              >
                {i18n.t("Next")} <i className="fal fa-angle-right ml-2"></i>
              </button>

              {selectedStep === "adoptions" && (
                <button
                  type="button"
                  onClick={() => handleClickSubmitInitiativeForm("save")}
                  className={classNames(
                    "btn",
                    "btn-primary",
                    { visible: selectedStep === "adoptions" },
                    { invisible: selectedStep !== "adoptions" }
                  )}
                >
                  {formMode === "add" ? i18n.t("Add") : i18n.t("Update")}
                </button>
              )}
            </div>
          )}
        </div>
        {/* Initiative Form End */}
      </form>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    storeActionStatus: (payload) => dispatch(actionStatus(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    languageId: state.CurrentFilter.languageData.id,
    clientData: state.CurrentFilter.ClientData,
    syncFilter: state.CurrentFilter.SyncFilter,
    SelectedFilter: { ...state.CurrentFilter.SyncFilter },
    journeyData: state.JourneyReducer.JourneyData,
    boiList: state.JourneyReducer.Datas.BoiList,
    iiMetrics: state.JourneyReducer.Datas.IImetrics,
    viewData: state.JourneyReducer.Datas.viewData,
    allInitiativeData: state.JourneyReducer.JourneyInitiatives,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditInitiative);
