import React, { Component } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import MetricDetails from './MetricDetails';
import { LocalApiBaseUrl } from '../Shared/Constant';
import BOPopUp from './BO2_popup';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import axiosInstance from '../Shared/interceptor';

export default class BOMetricDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metricDetails: [],
      viewmore: false,
      type: '',
      // AttributeIsrelator: [], unused
      responseMetricData: [],
    };
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    const { SODetails, filterDetails } = this.props;
    this.getKpiMetrics(SODetails, filterDetails);
    this.getKpiMAppingDetails();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getKpiMetrics(nextProps.SODetails, nextProps.filterDetails);
  }

  componentDidUpdate(prevProps) {
    const { SODetails, filterDetails } = this.props;

    if (prevProps.SODetails !== SODetails || prevProps.filterDetails !== filterDetails) {
      this.getKpiMetrics(SODetails, filterDetails);
    }
  }

  onClose() {
    // const { SODetails } = this.props;
    this.setState({ viewmore: false });
  }

  getKpiMetrics(
    SODetails = { SOCategoryID: 0 },
    // offeringIdparam = {
    //   Client: 0,
    //   process: 0,
    //   Locations: 0,
    //   Month: getPrevMonth(),
    // },
  ) {
    this.setState({
      metricDetails:
        String(SODetails.kpiData) !== String(undefined)
          ? SODetails.kpiData
          : null,
    });
  }

  getKpiMAppingDetails = () => {
    const { SODetails, filterDetails } = this.props;
    return new Promise(() => {
      axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
          params: {
            OrgEntityID: filterDetails.Client,
            LocationID: filterDetails.Locations,
            OfferingID: filterDetails.process,
            SOCategoryID: SODetails.SOCategoryID,
            MonthDT: filterDetails.Month,
          },
        })
        .then((response) => {
          this.setState({ responseMetricData: response.data });
        });
    });
  }

  dealpopup(type, e) {
    e.preventDefault();
    this.setState({ viewmore: true, type });
    e.preventDefault();
  }

  render() {
    const {
      metricDetails, responseMetricData, viewmore, type,
    } = this.state;
    const {
      SODetails, RecordStatus, isManintencneWindowOpen, roleReducers, opsgrp, isRelatorBlock, filterDetails, attributes,
    } = this.props;
    return (
      <div className="custom-tilecard">
        <div className="tilecard-wrapper">
          <h2>
            <span>{SODetails.SOCategoryName}</span>
            {(RecordStatus && RecordStatus[0] === 'Publish')
            || (RecordStatus && RecordStatus[1] === 'True' && !isManintencneWindowOpen)
            || (RecordStatus
              && RecordStatus[0] === 'Publish'
              && !isManintencneWindowOpen)
              || roleReducers.filter((each) => {
                let returnFlag = false;
                if (each.FeatureName === 'Configurator' && each.Operations.length) {
                  returnFlag = true;
                  return each;
                }
                return returnFlag;
              }).length === 0
            || (RecordStatus
              && RecordStatus[0] === 'Save'
              && roleReducers.filter((each) => {
                let returnFlag = false;
                if (each.FeatureName === 'Configurator' && each.Operations.filter((op) => op === 'Submit').length) {
                  returnFlag = true;
                  return each;
                }
                return returnFlag;
              }).length === 0)
            || (RecordStatus
              && RecordStatus[0] === 'Submit'
              && roleReducers.filter((each) => {
                let returnFlag = false;
                if (each.FeatureName === 'Configurator' && each.Operations.filter((op) => op === 'Publish').length) {
                  returnFlag = true;
                  return each;
                }
                return returnFlag;
              }).length === 0) ? (
                ''
              ) : (
                <span
                  onClick={(e) => {
                    this.dealpopup(SODetails.SOCategoryName, e);
                  }}
                  role="button"
                  tabIndex="0"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </span>
              )}
          </h2>
          {metricDetails.length !== 0 ? (
            <MetricDetails
              isRelatorBlock={isRelatorBlock}
              SOCategoryID={SODetails.SOCategoryID}
              SOCategoryName={SODetails.SOCategoryName}
              Details={metricDetails}
              filterDetails={filterDetails}
              attributes={attributes}
              RecordStatus={RecordStatus}
              isManintencneWindowOpen={isManintencneWindowOpen}
              responseMetricData={responseMetricData}
            />
          ) : null}

        </div>
        {viewmore ? (
          <BOPopUp
            isRelatorBlock={isRelatorBlock}
            SOCategoryID={SODetails.SOCategoryID}
            SOCategoryName={SODetails.SOCategoryName}
            show={viewmore}
            onClose={this.onClose}
            opsgrp={opsgrp}
            filterDetails={filterDetails}
            type={type}
            attributes={attributes}
            responseMetricData={responseMetricData}
          />
        ) : null}
      </div>
    );
  }
}

BOMetricDetails.propTypes = {
  SODetails: PropTypes.object.isRequired,
  filterDetails: PropTypes.object.isRequired,
  isRelatorBlock: PropTypes.bool.isRequired,
  roleReducers: PropTypes.array.isRequired,
  RecordStatus: PropTypes.array.isRequired,
  isManintencneWindowOpen: PropTypes.bool.isRequired,
  opsgrp: PropTypes.object.isRequired,
};
