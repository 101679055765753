import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import i18n from 'i18next';
import SynopsLoader from './SynopsLoader';
import { connect } from 'react-redux';
import { faCompressAlt, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Access_denied from '../Images/Access_denied.svg'


const SisenseGPHDashboard = (props) => {
    const { 
        url,
        toggleGPSStatsModalCall,
        toggleGPSStatsModal
    } = props;
    const { hasSisenseAccess, app, error, fetching} = props.clientOpriaSisenseDetailsFromRedux.sisenseObj;
    const [activeObj, setActiveObj] = useState({
      activeDahObj: null,
      activeWidgets: null,
      activeDivId: null
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [dashboardId, setDashboardL2] = useState('');
    const [gphStatModalFullscreen, setGphStatModalFullscreen] = useState(false);
    const [isFilterPanelVisible, toggleFilterPanel] = useState(false);
    const [shouldShowFilters, setShouldShowFilters] = useState(false);

    const getDashboardID = (dashboardUrl) =>{
        if (dashboardUrl.indexOf('/dashboards/') !== -1) {
          const splitPartsForURL = dashboardUrl.split('://');
          const protocol = splitPartsForURL[0];
          const domainName = splitPartsForURL[1].split('/')[0];
          const domainURL = `${protocol}://${domainName}`;
    
          const splitPartsForDashboardId = dashboardUrl.split('/dashboards/');
          let dashboardId = splitPartsForDashboardId[1];
          if (dashboardId.indexOf('?') !== -1) {
            const parts = dashboardId.split('?');
            dashboardId = parts[0];
          }
          if (dashboardId.indexOf('/') !== -1) {
            const parts = dashboardId.split('/');
            dashboardId = parts[0];
          }
          return [domainURL,dashboardId]
          
        } else {
          window.alert('Unable to find the dashboard id in the URL');
          onClose();
        }
    }
    useEffect(()=>{
        const dashboardL2 = url.split(',')[1];
        if (dashboardL2 && dashboardL2.indexOf('/dashboards/') !== -1){
            setDashboardL2(getDashboardID(dashboardL2)[1]);
        } else{
            window.alert('Unable to find the dashboard id in the URL');
            toggleGPSStatsModalCall();
        }
    },[url, toggleGPSStatsModalCall])

    const loadDash = (app) => {
        app.dashboards.load(dashboardId).then((dash) => {
          afterLoadDash(dash, dashboardId);
        }).catch((error) => {
         if (error.status === 403) {
          setErrorMessage("You don't have access to Dashboards.");;
         }
        });
    }
    const createRow = (i)=> {
        const row = document.createElement('div');
        row.className = 'row';
        row.style.marginLeft = '0px';
        row.style.marginRight = '0px';
        row.id = `row${i}`;
        return row;
    }

    const createLayout = (i, el, width, dashId) => {
        const headerDiv = document.createElement('div');
        headerDiv.id = `head${el.widgetid}`;
        headerDiv.style.padding = '5px 20px';
        headerDiv.style.color = 'white';
        headerDiv.style.borderTopRightRadius = '10px';
        headerDiv.style.borderTopLeftRadius = '10px';
        headerDiv.style.backgroundColor = '#0242C6';
    
        const height = el.height === '' || el.height === 512 ? '100%' : `${el.height.toString().replace('px', '')}px`;
        const div = document.createElement('div');
        div.id = el.widgetid;
        div.style.height = height;
        div.style.backgroundColor = '#2291FF';
        const pdiv = document.createElement('div');
        pdiv.className = `layout row${i}`;
        pdiv.style.width = `calc(${width}% - 4px)`;
        pdiv.style.margin = '2px';
    
        pdiv.appendChild(headerDiv);
        pdiv.appendChild(div);
    
        return pdiv;
      }

    const loadWgt =(db, widgets)=> {
        widgets.forEach((wgt, i) => {
          const div = document.getElementById(wgt);
          const oWgt = db.widgets.get(wgt);
    
          if (oWgt) {
            const head = document.getElementById(`head${wgt}`);
            if (oWgt.title.length > 0 && oWgt.title !== 'RICHTEXT_MAIN.TITLE') { head.innerHTML = oWgt.title; } else
            if (head) head.style.display = 'none';
    
            if (!oWgt.container) { 
              oWgt.container = div
            } 
             oWgt.refresh();
          }
          if(i === (widgets.length - 1)){
            setActiveObj({ activeDahObj: db, activeWidgets: widgets})
            db.refresh();
          }
        });
      }

      const applyForce10ClientFilter = (db, applyClientFilter = false, applyTypeFilter = false) => {
        const filterOptions = {
          save: true,
          refresh: true,
          unionIfSameDimensionAndSameType: false
        };
        let payload;
        if(applyClientFilter && !applyTypeFilter){
          payload = {
            jaql: {
              title: "Client",
              dim: "[Client.Client]",
              datatype: "text",
              filter : {
                members: ["FORCE02"],
                multiSelection: true,
                explicit: true
              }
            }
          };
        } else if(applyTypeFilter && !applyClientFilter){
          payload = {
            jaql: {
              title: "Type",
              dim: "[Client.Type]",
              datatype: "text",
              filter : {
                members: ["Demo"],
                multiSelection: true,
                explicit: true
              }
            }
          };
        } else if(applyClientFilter && applyTypeFilter){
          payload = [
            {
              levels: [
                {
                  dim: "[Client.Type]",
    
                  datatype: "text",
    
                  title: "Type",
    
                  filter: {
                    explicit: true,
    
                    userMultiSelect: false,
    
                    multiSelection: false,
    
                    members: ["Demo"],
                  },
                },
    
                {
                  dim: "[Client.Client]",
    
                  datatype: "text",
    
                  title: "Client",
    
                  filter: {
                    explicit: true,
    
                    multiSelection: true,
    
                    members: ["FORCE02"],
                  },
                },
              ],
            },
          ];
        }
        db.$$model.filters.update(payload, filterOptions);
    
      }

      const applyFilter = (filt, value, db) => {
        const mem = Array.isArray(value)?value:[value];
            
        const filterOptions = {
            save: true,
            refresh: true,
            unionIfSameDimensionAndSameType: false
        };
        const payload = {
            jaql: {
                title: filt,
                dim: "[Client." + filt + "]",
                datatype: "text",
                filter : {
                    members: mem,
                    multiSelection: true,
                    explicit: true
                }
            }
        };
        db.$$model.filters.update(payload, filterOptions);
      }

    const afterLoadDash = (db, divId) => {
        const filters = db.$$model.defaultFilters && db.$$model.defaultFilters.length  ? db.$$model.defaultFilters : [];
        const applyClientFilter = filters.length && filters.some((filterObj)=>filterObj.jaql && filterObj.jaql.dim && filterObj.jaql.dim === "[Client.Client]" || (filterObj.levels && filterObj.levels.some((levelObj)=>levelObj.dim && levelObj.dim === "[Client.Client]")));
        const applyTypeFilter = filters.length && filters.some((filterObj)=>filterObj.jaql && filterObj.jaql.dim && filterObj.jaql.dim === "[Client.Type]" || (filterObj.levels && filterObj.levels.some((levelObj)=>levelObj.dim && levelObj.dim === "[Client.Type]")) );
        setShouldShowFilters(!!filters.length);
        if(filters && filters.length && (applyClientFilter || applyTypeFilter)){
          const client = JSON.parse(sessionStorage.getItem('clientSelection'));
          if(client && client.name && client.name.length){
            if(client.name.trim().toLocaleLowerCase() === "force 10"){
              applyForce10ClientFilter(db, applyClientFilter, applyTypeFilter)
            } else if(applyClientFilter) {
              applyFilter("Client",client.name.trim(), db);
            }
          }
        }
       
        const widgets = [];
        const layout = db.$$model.layout.columns[0].cells;
        const wRoot = document.getElementById('gph-dashboard-container')
        
        if (wRoot) wRoot.innerHTML = '';
        let i = 0;
        layout.forEach((col) => {
            i += 1;
            const row = createRow(i);
            col.subcells.forEach((cell) => {
                const width = cell.width ? cell.width : 100;
                row.appendChild(createLayout(i, cell.elements[0], width));
                widgets.push(cell.elements[0].widgetid);
            });
            wRoot.appendChild(row);
            if (i === layout.length) setTimeout(() => loadWgt(db, widgets), 1000);
        });
        if(filters && filters.length){
          db.renderFilters(document.getElementById("gph-filter-panel"));
        }
    }

    useEffect(() => {
        if (!_.isEmpty(dashboardId) && !fetching && hasSisenseAccess && app) {
          loadDash(app)
        }
        if(!hasSisenseAccess){
          setErrorMessage("You don't have access to Dashboards.");
        }
        if(hasSisenseAccess && !fetching && error && error.length){
          setErrorMessage(error);
        }
      }, [dashboardId, fetching, hasSisenseAccess, app]);
    const renderErrorUI = () => {
        return (
          <div className="d-flex flex-column justify-content-center align-items-center access-denied-container">
            <img src={Access_denied} alt="Access Denied logo" />
            <h3 className="access-denied-header">Access Denied</h3>
            <p className="access-denied-description">
              You don't have access to Opria Dashboard.<br/>If you need access to Opria Dashboard,
              please send an email to SynOps.support.<br/>The team will reach-out to you with the next
              steps.
            </p>
          </div>
        ); 
      }
      const toggleModalSize = () => {
        setGphStatModalFullscreen(!gphStatModalFullscreen)
        loadWgt(activeObj.activeDahObj, activeObj.activeWidgets);
      }
    return (
        <Modal
        show={toggleGPSStatsModal}
        onHide={toggleGPSStatsModalCall}
        className={`modal fade summary-modal ${errorMessage ? "mr-5" : "gph-stat-modal-container" }`}
        
        dialogClassName={gphStatModalFullscreen?"modal-100w":"modal-90w"}
        backdrop="static"
      >
          <Modal.Body>
              <div className="summary-header gph-dashboard-header">
                <button type="button" class="close" onClick={toggleGPSStatsModalCall}>
                  <span aria-hidden="true">×</span>
                  <span class="sr-only">{i18n.t("Close")}</span>
                </button>
                { errorMessage && errorMessage.length ? null :<FontAwesomeIcon className='mr-5 pull-right'
                    style={{ cursor: "pointer" }}
                    onClick={toggleModalSize}
                    color={"#fff"}
                    title={!gphStatModalFullscreen ? "Maximize" : "Minimize"}
                    icon={gphStatModalFullscreen ? faCompressAlt : faExpandAlt} 
                />}
              </div>
              { errorMessage && errorMessage.length ? renderErrorUI() :
              shouldShowFilters ?<>
              <div id="gph-dashboard-container"></div>
              <div 
              onClick={()=>toggleFilterPanel(!isFilterPanelVisible)} 
              className={`filter-panel-toggle ${isFilterPanelVisible ? 'visible-filter-panel-toggle' : null}`}> 
              {isFilterPanelVisible ? '»' : '«'}
            </div>
            <div className={isFilterPanelVisible ?'visible-filter-panel' : 'hide-filter-panel'}>
              <div id="gph-filter-panel"></div>
            </div>
              </> : null}
              
          </Modal.Body>
      </Modal>
    )
}

const mapStateToProps = (state) => ({
    clientOpriaSisenseDetailsFromRedux: state.app.clientOpriaSisenseDetails,
  });
  
  export default connect(
    mapStateToProps,
    '',
  )(SisenseGPHDashboard);