import React from 'react';
import i18n from 'i18next';

function CustomMultiSelect(props) {
  const {
    selectAllChecked,
    hadleSelectAllChange,
    options,
    handleOptionChange,
    dispalySelectAll,
  } = props;

  return (
    <ul>
      {dispalySelectAll && (
        <li>
          <label className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={selectAllChecked}
              onChange={(e) => {
                hadleSelectAllChange(e);
              }}
            />
            <span className="checkmark" />
            <span className="form-check-label">{i18n.t('Select_All')}</span>
          </label>
        </li>
      )}

      {options.map((each, index) => (
        <li>
          <label className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={each.checked}
              onChange={(e) => {
                handleOptionChange(e, index, each);
              }}
            />
            <span className="checkmark" />
            <span className="form-check-label">{each.Name || each.label}</span>
          </label>
        </li>
      ))}
    </ul>
  );
}

export default CustomMultiSelect;
