import React from "react";
import ReactDOM from "react-dom";
import "svm-dashboard/dist/assets/css/Dashboard.css";
import "synops-intops-ui/src/assets/css/App.css";
import "babel-polyfill";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle";
//import './CSS/bootstrap.min.css';
import "./CSS/fontawesome.min.css";
import "react-data-grid/dist/react-data-grid.css";
import "./CSS/style.css";
import "./CSS/circle.css";
import "./CSS/customstyles.css";
import "./CSS/responsive.css";
import "./CSS/developer.css";
import "./CSS/tilecard.css";
// MSAL imports
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, EventType, InteractionType } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./msalConfig";

import { Loading } from "./Authorization/Loading";
import { ErrorComponent } from "./Authorization/ErrorComponent";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./reducers/configureStore";
import { TRACKING_MODULES, userTracker } from "./Shared/Common";

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

if (process.env.NODE_ENV !== "development") {
  ReactDOM.render(
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </MsalAuthenticationTemplate>
    </MsalProvider>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
