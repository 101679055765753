export const SET_BEFORE_AND_AFTER_MINDMAP_VIEW_DETAILS = 'SET_BEFORE_AND_AFTER_MINDMAP_VIEW_DETAILS';
export const SET_FORM_STATE = 'SET_FORM_STATE';
export const SET_MINDMAP_FORM_DATA = 'SET_MINDMAP_FORM_DATA';
export const SET_BEFORE_AND_AFTER_MINDMAP_CONFIG_DETAILS = 'SET_BEFORE_AND_AFTER_MINDMAP_CONFIG_DETAILS';
export const SET_BEFORE_AND_AFTER_MINDMAP_CONFIGURATION_STATUS = 'SET_BEFORE_AND_AFTER_MINDMAP_CONFIGURATION_STATUS';
export const SAVE_UPDATED_BEFORE_AND_AFTER_MINDMAP_STEP_CONFIGURATION_DATA = 'SAVE_UPDATED_BEFORE_AND_AFTER_MINDMAP_STEP_CONFIGURATION_DATA';
export const SAVE_UPDATED_BEFORE_AND_AFTER_MINDMAP_METRIC_CONFIGURATION_DATA = 'SAVE_UPDATED_BEFORE_AND_AFTER_MINDMAP_METRIC_CONFIGURATION_DATA';
export const DELETE_BEFORE_AND_AFTER_MINDMAP_METRIC_CONFIGURATION_DATA = 'DELETE_BEFORE_AND_AFTER_MINDMAP_METRIC_CONFIGURATION_DATA';
export const DELETE_BEFORE_AND_AFTER_MINDMAP_STEP_CONFIGURATION_DATA = 'DELETE_BEFORE_AND_AFTER_MINDMAP_STEP_CONFIGURATION_DATA';
// DRILLDOWN PAGE ACTIONS
export const SET_BEFORE_AND_AFTER_DRILLDOWN_SECTION_DATA = 'SET_BEFORE_AND_AFTER_DRILLDOWN_SECTION_DATA';
export const SET_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA = 'SET_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA';
export const SET_BEFORE_AND_AFTER_DRILLDOWN_METRICS_JOURNEY_DATA = 'SET_BEFORE_AND_AFTER_DRILLDOWN_METRICS_JOURNEY_DATA';
export const SET_BEFORE_AND_AFTER_DRILLDOWN_REASONFORGROWTH = 'SET_BEFORE_AND_AFTER_DRILLDOWN_REASONFORGROWTH';
export const SET_BEFORE_AND_AFTER_DRILLDOWN_METRICS_SERIES_JOURNEY_DATA = 'SET_BEFORE_AND_AFTER_DRILLDOWN_METRICS_SERIES_JOURNEY_DATA';
export const SET_BEFORE_AND_AFTER_DRILLDOWN_MINDMAP_JOURNEY = 'SET_BEFORE_AND_AFTER_DRILLDOWN_MINDMAP_JOURNEY';

export const REMOVE_BEFORE_AND_AFTER_DRILLDOWN_SECTION_DATA = 'REMOVE_BEFORE_AND_AFTER_DRILLDOWN_SECTION_DATA';
export const REMOVE_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA_TIMESERIES = 'REMOVE_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA_TIMESERIES';
export const REMOVE_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA_METRIC_TIMESERIES = 'REMOVE_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA_METRIC_TIMESERIES';
export const REMOVE_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA_METRIC = 'REMOVE_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA_METRIC';
export const SAVE_BEFORE_AND_AFTER_MINDMAP_USER_DETAILS = 'SAVE_BEFORE_AND_AFTER_MINDMAP_USER_DETAILS';
export const SAVE_BEFORE_AND_AFTER_MINDMAP_COMMENTS_USER_IMAGES = 'SAVE_BEFORE_AND_AFTER_MINDMAP_COMMENTS_USER_IMAGES';
export const SAVE_BEFORE_AND_AFTER_MINDMAP_COMMENTS_DATA = 'SAVE_BEFORE_AND_AFTER_MINDMAP_COMMENTS_DATA';


export function saveViewData(dataObj) {
  return {
    type: SET_BEFORE_AND_AFTER_MINDMAP_VIEW_DETAILS,
    payload: dataObj,
  };
}
export function saveBeforeAfterUsers(data) {
  return {
    type: SAVE_BEFORE_AND_AFTER_MINDMAP_USER_DETAILS,
    payload: data,
  };
}
export function saveConfigData(dataObj) {
  return {
    type: SET_BEFORE_AND_AFTER_MINDMAP_CONFIG_DETAILS,
    payload: dataObj,
  };
}
export function saveUpdatedConfigData(dataObj) {
  return {
    type: SAVE_UPDATED_BEFORE_AND_AFTER_MINDMAP_STEP_CONFIGURATION_DATA,
    payload: dataObj,
  };
}
export function deleteStepData(dataObj) {
  return {
    type: DELETE_BEFORE_AND_AFTER_MINDMAP_STEP_CONFIGURATION_DATA,
    payload: dataObj,
  };
}

export function saveUpdatedMetricConfigData(dataObj) {
  return {
    type: SAVE_UPDATED_BEFORE_AND_AFTER_MINDMAP_METRIC_CONFIGURATION_DATA,
    payload: dataObj,
  };
}
export function onDeleteMetric(dataObj) {
  return {
    type: DELETE_BEFORE_AND_AFTER_MINDMAP_METRIC_CONFIGURATION_DATA,
    payload: dataObj,
  };
}

export function handleForm(name, data) {
  return {
    type: SET_FORM_STATE,
    payload: { name, data },
  };
}

export function handleFormData(name, data) {
  return {
    type: SET_MINDMAP_FORM_DATA,
    payload: { name, data },
  };
}

export function setConfigureMode(data) {
  return {
    type: SET_BEFORE_AND_AFTER_MINDMAP_CONFIGURATION_STATUS,
    payload: data,
  };
}

// DRILLDOWN PAGE ACTIONS START

// FOR ADDING NEW SECTION/NODE DATA
export function setDrillDownSectionData(data) {
  return {
    type: SET_BEFORE_AND_AFTER_DRILLDOWN_SECTION_DATA,
    payload: data,
  };
}

// FOR ADDING NEW JOURNEY DATA FOR NON METRICS
export function setDrillDownJourneyData(data) {
  return {
    type: SET_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA,
    payload: data,
  };
}
// FOR ADDING NEW JOURNEY DATA FOR METRICS
export function setDrillDownMetricsJourneyData(data) {
  return {
    type: SET_BEFORE_AND_AFTER_DRILLDOWN_METRICS_JOURNEY_DATA,
    payload: data,
  };
}
// SETTING CHECKBOX CHECKED
export function setDrillDownReasonforGrowth(data) {
  return {
    type: SET_BEFORE_AND_AFTER_DRILLDOWN_REASONFORGROWTH,
    payload: data,
  };
}
// ADDING TIME SERIES DATA TO THE SELECTED METRIC FOR THE GIVEN FROM AND TO
export function setDrillDownMetricsSeriesJourneyData(data) {
  return {
    type: SET_BEFORE_AND_AFTER_DRILLDOWN_METRICS_SERIES_JOURNEY_DATA,
    payload: data,
  };
}
// REMOVING SECTION/NODE DRILLDOWN DATA
export function removeDrillDownSectionData(data) {
  return {
    type: REMOVE_BEFORE_AND_AFTER_DRILLDOWN_SECTION_DATA,
    payload: data,
  };
}
// REMOVING JOURNEY DATA
export function removeDrillDownJourneyDataTimeSeries(data) {
  return {
    type: REMOVE_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA_TIMESERIES,
    payload: data,
  };
}

// REMOVING TIMESERIES - TIMESERIES1,2,3 DATA FOR METRICS
export function removeDrillDownJourneyDataMetricTimeSeries(data) {
  return {
    type: REMOVE_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA_METRIC_TIMESERIES,
    payload: data,
  };
}

// REMOVING THE JOURNEY DATA ASSOCIATED WITH DELETED METRIC
export function removeDrillDownJourneyDataMetric(data) {
  return {
    type: REMOVE_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA_METRIC,
    payload: data,
  };
}

export function setMindMapJourneyData(data) {
  return {
    type: SET_BEFORE_AND_AFTER_DRILLDOWN_MINDMAP_JOURNEY,
    payload: data,
  };
}

export function saveCommentsUserIcons(data) {
  return {
    type: SAVE_BEFORE_AND_AFTER_MINDMAP_COMMENTS_USER_IMAGES,
    payload: data,
  };
}

export function saveCommentsData(data) {
  return {
    type: SAVE_BEFORE_AND_AFTER_MINDMAP_COMMENTS_DATA,
    payload: data,
  };
}



// DRILLDOWN PAGE ACTIONS END
