import React, { Component } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';

import i18n from 'i18next';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
// import { BATUserData } from "../actions/BATActions";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import axiosInstance from '../Shared/interceptor';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import SynopsLoader from '../Shared/SynopsLoader';
// import { event } from "jquery";

class SingleShareAssessment extends Component {
  constructor(props) {
    super(props);
    this.singleUserAccessPair = {
      OwnerUserName: this.props.ownerUserName,
      UserName: '',
      IsPropsData: false,
      AccessLevelName: '',
      isUserDropdownSearchable: false,
      isPermissionDropdownSearchable: false,
      AccessLevelID: '',
      BATSubOfferingName: this.props.assessmentDetails.BATSubOfferingName,
      BATAssessSubOfferingID: this.props.assessmentDetails.BATAssessSubOfferingID,
      errMsgUserNameField: '',
      errMsgAccessLevelField: '',
      isPermissionDropdownDisabled: true,
    };

    this.state = {
      selectedTab: this.props.tabName,
      formIsValid: false,
      areAnyDuplicateUserName: false,
      showNotification: false,
      showSharingModel: true,
      userAccessPair: [
        {
          ..._.cloneDeep(this.singleUserAccessPair),
        },
      ],
      IndustryOptions: [],
      filteredUserList: [],
      // searchTerm: "",
      userList: [], // coming from props
      PermissionList: [],
      isAPILoading: false,
      isUserListAPILoading: false,
    };

    // DEBOUNCED FUNCTION FOR THE API CALL TO IMPROVE PERFORMANCE
    this.debouncedFetchUsers = _.debounce(this.fetchUsers, 1000);
  }

  componentDidMount() {
    this.userAccessFromProps();
    // this.getUsers();
    this.getPermissionDetails();
    this.setState({
      isPermissionDropdownDisabled: true,
    });
  }

  getPermissionDetails = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}BATAssessment/GetAccessLevels`)
      .then((response) => {
        if (response.data) {
          this.setState({
            PermissionList: response.data,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        // this.setstate({
        //   isAPILoading: false,
        // });
      });
  };

  // TO FETCH ALREADY SELECTED USER ACCESS PAIRS FROM PROPS
  userAccessFromProps = () => {
    const { BATSubOfferingName, BATAssessSubOfferingID } = this.props.assessmentDetails;
    let userAccessPair = [];
    const userAccessPairFromProps = _.get(this.props.assessmentDetails, 'SharedUsers', []);
    if (!_.isEmpty(userAccessPairFromProps)) {
      userAccessPair = _.map(userAccessPairFromProps, (eachItem) => {
        const userAccess = {
          ..._.cloneDeep(this.singleUserAccessPair),
          UserName: eachItem.UserName,
          AccessLevelName: eachItem.AccessLevelName,
          AccessLevelID: eachItem.AccessLevelID,
          IsPropsData: true,
        };
        return userAccess;
      });
    } else {
      userAccessPair = [{ ..._.cloneDeep(this.state.userAccessPair[0]) }];
    }

    this.setState({
      BATSubOfferingName,
      BATAssessSubOfferingID,
      userAccessPair,
    });
  };

  // VALIDATION CHECK FOR EACH ROW
  validateModel() {
    const { userAccessPair } = this.state;
    const lastEle = userAccessPair[userAccessPair.length - 1];
    let formIsValid = true;
    let areAnyDuplicateUserName = false;

    const modifiedUserAccessPair = _.map(userAccessPair, (eachItem) => {
      eachItem.errMsgUserNameField = '';
      eachItem.errMsgAccessLevelField = '';
      if (!eachItem.UserName.trim()) {
        formIsValid = false;
        eachItem.errMsgUserNameField = 'User name is required';
      }

      if (!eachItem.AccessLevelName.trim()) {
        formIsValid = false;
        eachItem.errMsgAccessLevelField = 'Permission is required';
      }

      return eachItem;
    });
    // DUPLICATE USER NAME CHECK
    _.forEach(modifiedUserAccessPair, (userAccessPair, index) => {
      const modifiedUserName = String(userAccessPair.UserName).trim();

      const eqTypeField = _.find(modifiedUserAccessPair, (eachItem, indx) => {
        const foundUserName = String(eachItem.UserName).trim();

        if (
          indx > index
          && !_.isEmpty(modifiedUserName)
          && !_.isEmpty(foundUserName)
          && modifiedUserName === foundUserName
        ) {
          return true;
        }
      });
      if (eqTypeField) {
        areAnyDuplicateUserName = true;
      }
    });

    if (areAnyDuplicateUserName) {
      formIsValid = false;
    }
    this.setState({
      formIsValid,
      userAccessPair: modifiedUserAccessPair,
      areAnyDuplicateUserName,
    });

    return formIsValid;
    // return lastEle.UserName === "" || lastEle.AccessLevelName === "";
  }

  // DYNAMICALLY ADDS A NEW ROW FOR ADDING USER ACCESS PAIR
  addUserAccessPairRow = () => {
    const { formIsValid, userAccessPair, PermissionList } = this.state;
    const { tabName, actionToBePerformed, mode } = this.props;
    // if (!this.validateModel()) {
    if (this.validateModel()) {
      // if (userAccessPair.length < 10) {
      const clonedSingleUserAccessPair = _.cloneDeep(this.singleUserAccessPair);
      if (actionToBePerformed === 'request' && mode === 'reshare') {
        clonedSingleUserAccessPair.AccessLevelID = PermissionList[0].AccessLevelID;
        clonedSingleUserAccessPair.AccessLevelName = PermissionList[0].AccessLevelName;
      }
      const currentUserAccessPair = _.cloneDeep(userAccessPair);
      currentUserAccessPair.push(clonedSingleUserAccessPair);
      this.setState({
        userAccessPair: currentUserAccessPair,
      });
      // }
    }
  };

  // REMOVES THE SELECTED DYNAMICALLY ADDED USERACCESSPAIR
  removeUserAccessPairRow = (rowIndex) => {
    const { userAccessPair } = this.state;
    const remainingUserAccessPair = _.filter(userAccessPair, (eachItem, index) => index !== rowIndex);
    this.setState({
      userAccessPair: remainingUserAccessPair,
      areAnyDuplicateUserName: false,
      isPermissionDropdownDisabled: true,
    });
  };

  // TO POPULATE THE DROPDOWN MENU FOR SELECTED OPTION
  onOpenSearchableDropdown = (rowIndex, elementId) => {
    const { userAccessPair } = this.state;

    // var element = e.target;
    // let rowIndex = element.id.split("clientDropdown")[1] || element.id.split("permissionDropdown")[1];
    let modifiedUserAccessPair = {};

    if (elementId === `clientDropdown${rowIndex}`) {
      modifiedUserAccessPair = _.map(userAccessPair, (eachItem, index) => {
        if (parseInt(index) === parseInt(rowIndex)) {
          eachItem.isUserDropdownSearchable = !eachItem.isUserDropdownSearchable;
          eachItem.isPermissionDropdownSearchable = false;
        }
        return {
          ...eachItem,
        };
      });
    } else if (elementId === `permissionDropdown${rowIndex}`) {
      modifiedUserAccessPair = _.map(userAccessPair, (eachItem, index) => {
        if (parseInt(index) === parseInt(rowIndex)) {
          eachItem.isPermissionDropdownSearchable = !eachItem.isPermissionDropdownSearchable;
          eachItem.isUserDropdownSearchable = false;
        }
        return {
          ...eachItem,
        };
      });
    }

    this.setState({
      userAccessPair: modifiedUserAccessPair,
      filteredUserList: [],
    });
  };

  fetchUsers = (searchTerm) => {
    const { ownerUserName } = this.props;

    if (searchTerm.length >= 3) {
      this.setState({ isUserListAPILoading: true });
      axiosInstance
        .get(`${LocalApiBaseUrl}BATAssessment/GetAllActiveUsers?searchStringEID=${searchTerm}`)
        .then((response) => {
          const responseData = response.data;

          const filteredUserList = [];
          if (!_.isEmpty(responseData)) {
            const filteredResponse = responseData.filter(
              (res) => res.UserEnterpiseID !== ownerUserName,
            );
            const sortedObjList = filteredResponse.sort((a, b) => (a.UserEnterpiseID > b.UserEnterpiseID ? 1 : -1));

            sortedObjList.forEach((each) => {
              filteredUserList.push(each.UserEnterpiseID);
            });
          }

          this.setState({
            isUserListAPILoading: false,
            filteredUserList,
          });
        })
        .catch((error) => {
          this.setState({
            isUserListAPILoading: false,
            filteredUserList: [],
          });

          trycatchAlertPopup(error);
        });
    } else if (searchTerm.length === 0) {
      this.setState({ filteredUserList: [] });
    }
  };

  // EVENT HANDLER FOR "SEARCH USER" INPUT FIELD
  handleChangeSearchUser = (event) => {
    const searchTerm = event.target.value;
    this.debouncedFetchUsers(searchTerm);
  };

  // EXECUTES WHEN THE LIST ITEM IN THE USER LIST IS CLICKED
  handleClickUserListItem = (ClientObj, rowIndex, eachUserAccessPair) => {
    let userAccessPair = {};
    userAccessPair = _.map(this.state.userAccessPair, (eachItem, index) => {
      if (parseInt(index) === parseInt(rowIndex)) {
        eachItem.UserName = ClientObj.target.textContent;
        eachItem.isPermissionDropdownSearchable = false;
        eachItem.isUserDropdownSearchable = false;
        eachItem.errMsgUserNameField = '';

        // eachItem.errMsgAccessLevelField=""
      }
      return {
        ...eachItem,
      };
    });

    this.setState({
      userAccessPair,
      areAnyDuplicateUserName: false,
      isPermissionDropdownDisabled: false,
    });
  };

  // SELECTED PERMISSION CHANGE METHOD
  onPermissionChange = (ClientObj, rowIndex, eachUserAccessPair) => {
    let userAccessPair = {};
    const { PermissionList } = this.state;

    userAccessPair = _.map(this.state.userAccessPair, (eachItem, index) => {
      if (parseInt(index) === parseInt(rowIndex)) {
        eachItem.AccessLevelName = ClientObj.target.textContent;
        eachItem.AccessLevelID = PermissionList.filter(
          (e) => e.AccessLevelName === ClientObj.target.textContent,
        )[0].AccessLevelID;
        eachItem.isUserDropdownSearchable = false;
        eachItem.isPermissionDropdownSearchable = false;
        eachItem.errMsgAccessLevelField = '';
      }
      return {
        ...eachItem,
      };
    });

    this.setState({
      userAccessPair,
      areAnyDuplicateUserName: false,
    });
  };

  // MANUAL DROPDOWN CLOSING METHOD
  closeCustomDropdown = () => {
    const { userAccessPair } = this.state;

    const modifiedUserAccessPair = _.map(userAccessPair, (eachItem, index) => {
      eachItem.isUserDropdownSearchable = false;
      eachItem.isPermissionDropdownSearchable = false;

      return {
        ...eachItem,
      };
    });

    this.setState({
      userAccessPair: modifiedUserAccessPair,
    });
  };

  // PERMISSION OPTION LIST
  permissionSelectbar = (eachUserAccessPair, rowIndex) => {
    let { PermissionList } = this.state;
    const { tabName, actionToBePerformed, mode } = this.props;

    if (!_.isEmpty(tabName)) {
      if (actionToBePerformed === 'approve' && mode === 'share' && tabName === 'myAssessment') {
        PermissionList = PermissionList.filter(
          (data) => data.AccessLevelID === 1 || data.AccessLevelID === 2,
        );
      }
    }

    if (eachUserAccessPair !== undefined) {
      return (
        <ul>
          {_.map(PermissionList, (each, index) => (
            <li
              className={classNames({
                'bat-dropdown-li-active': each === eachUserAccessPair.AccessLevelName,
              })}
              id={each.AccessLevelID}
              onClick={(e) => {
                e.stopPropagation();
                this.onPermissionChange(e, rowIndex, eachUserAccessPair);
              }}
            >
              {each.AccessLevelName}
            </li>
          ))}
        </ul>
      );
    }
  };

  // MODEL SUBMISSION FOR SELECTED ROWS BASED ON ASSESSMENT TYPE
  submitSingleAssessmentModal = () => {
    const { formIsValid, userAccessPair, selectedTab } = this.state;
    const { tabName, actionToBePerformed, mode } = this.props;

    const UserData = [];
    userAccessPair.map((each) => {
      UserData.push({
        BATAssessSubOfferingID: each.BATAssessSubOfferingID,
        UserName: each.UserName,
        AccessLevelID: each.AccessLevelID,
      });
    });
    if (this.validateModel()) {
      // post api call
      this.setState({
        isAPILoading: true,
      });
      if (mode === 'share') {
        // if (selectedTab === "myAssessment" || tabName === "accessManagement") {
        axiosInstance
          .post(`${LocalApiBaseUrl}BATAssessment/AddEditShareBATAssessments`, UserData)
          .then((response) => {
            this.setState({
              showNotification: true,
              showSharingModel: false,
              isAPILoading: false,
            });
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            this.setState({
              isAPILoading: false,
            });
          });
      } else {
        const data = {};
        data.Status = actionToBePerformed === 'request' ? 'RequestAccess' : 'ApproveAccess';
        // data.Status = tabName === "collaboratedAssessment" ? "RequestAccess" : "ApproveAccess" ;
        data.RequestedUsers = UserData;

        axiosInstance
          .post(`${LocalApiBaseUrl}BATAssessment/AddEditReShareBATAssessments`, data)
          .then((response) => {
            this.setState({
              showNotification: true,
              showSharingModel: false,
              isAPILoading: false,
            });
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            this.setState({
              isAPILoading: false,
            });
          });
      }
    }
  };

  render() {
    const {
      BATSubOfferingName,
      // createdBy,
      // BATAssessSubOfferingID,
      userAccessPair,
      isAPILoading,
      showSharingModel,
      showNotification,
      areAnyDuplicateUserName,
      filteredUserList,
      isUserListAPILoading,
      isPermissionDropdownDisabled,
    } = this.state;

    const {
      onShareModalSuccClose,
      onShareModalCancelClose,
      // tabName,
      mode,
      actionToBePerformed,
      ownerUserName,
      clientDetails,
    } = this.props;

    return (
      <>
        {/* Single Share Assessment Modal */}
        {isAPILoading && <LoadingOverlay spinner={<SynopsLoader />} active />}

        <Modal
          show={showSharingModel}
          onHide={onShareModalCancelClose}
          className="modal fade shareSingleassessment-modal"
          backdrop="static"
        >
          <Modal.Body>
            <div className="modalheader-title">
              <h4>
                {!_.isEmpty(clientDetails.BATClientName) && `${clientDetails.BATClientName}:`}
                {' '}
                {BATSubOfferingName}
              </h4>
              <p className="requester-name">
                {' '}
                Created by
                {ownerUserName}
                {' '}

              </p>
              {/* {userAccessPair.length === 10 && <span className="requiredNotice">* Maximum 10 users can be added </span>} */}
              {areAnyDuplicateUserName && <span className="errorMsg">Duplicate User Name </span>}
            </div>

            {mode === 'reshare' && actionToBePerformed === 'approve' ? null : (
              <div className="title-with-icon mt-3 mb-1">
                <div className="list-icon">
                  <span id="rock" onClick={() => this.addUserAccessPairRow()}>
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                </div>
              </div>
            )}

            <div className="form-section">
              {_.map(userAccessPair, (eachUserAccessPair, index) => (
                <div className="row" key={index}>
                  <div className="col-sm-8">
                    <div className="dropdown-box">
                      {/* whole container  */}
                      <div className="custom-dropdown-search">
                        {/* search and name display */}
                        <div
                          className="custom-dropdownname"
                          onClick={() => {
                            if (!eachUserAccessPair.IsPropsData) {
                              this.onOpenSearchableDropdown(index, `clientDropdown${index}`);
                            }
                          }}
                        >
                          {!_.isEmpty(eachUserAccessPair.UserName) ? (
                            <span className="selectedItem">{eachUserAccessPair.UserName}</span>
                          ) : (
                            'Add People'
                          )}
                          {/* {eachUserAccessPair.UserName ? (
                              <span>
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </span>
                            ) : (
                              ""
                            )} */}
                        </div>

                        {eachUserAccessPair.isUserDropdownSearchable && (
                        // search bar div
                        <div className="search-select-list">
                          <div className="searchFilter">
                            <input
                                  type="search"
                                  id={`search${index}`}
                                  onChange={(e) => this.handleChangeSearchUser(e)}
                                  placeholder="Search..."
                                />
                          </div>

                          {!_.isEmpty(eachUserAccessPair) && (
                          <ul>
                                  {isUserListAPILoading && (
                                    <li className="disabled">Loading User List...</li>
                                  )}

                                  {!isUserListAPILoading
                                    && _.map(filteredUserList, (user, userIndex) => (
                                      <li
                                        key={userIndex}
                                        className={classNames({
                                          'bat-dropdown-li-active':
                                              user === eachUserAccessPair.UserName,
                                        })}
                                        onClick={(e) => this.handleClickUserListItem(
                                          e,
                                          index,
                                          eachUserAccessPair,
                                        )}
                                      >
                                        {user}
                                      </li>
                                    ))}
                                </ul>
                          )}
                        </div>
                        )}
                      </div>
                    </div>
                    <div className="errorMsg">{eachUserAccessPair.errMsgUserNameField}</div>
                  </div>

                  <div className="col-sm-4">
                    <div className="dropdown-box">
                      <div
                        className="custom-dropdown-search"
                        onClick={(event) => {
                          if (actionToBePerformed === 'request' && mode === 'reshare') {
                          } else {
                            {
                              !isPermissionDropdownDisabled
                                  && this.onOpenSearchableDropdown(
                                    index,
                                    `permissionDropdown${index}`,
                                  );
                            }
                          }
                        }}
                      >
                        <div className="custom-dropdownname">
                          {eachUserAccessPair.AccessLevelName !== '' ? (
                            <span className="selectedItem">
                              {eachUserAccessPair.AccessLevelName}
                            </span>
                          ) : (
                            'Permission'
                          )}
                        </div>

                        {eachUserAccessPair.isPermissionDropdownSearchable && (
                        <div className="search-select-list">
                          {/* {permissionList} */}
                          {this.permissionSelectbar(eachUserAccessPair, index)}
                        </div>
                        )}
                      </div>
                    </div>
                    <div className="errorMsg">{eachUserAccessPair.errMsgAccessLevelField}</div>

                    {index !== 0 && !eachUserAccessPair.IsPropsData && (
                    <span
                      className="bat-modal-del-row-icon"
                      onClick={() => this.removeUserAccessPairRow(index)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="modal-btn flex-btns">
              <button onClick={onShareModalCancelClose} className="bat-cancel btn-default mr-2">
                Cancel
              </button>
              <button
                className="batassessment-btn-purplebg"
                onClick={this.submitSingleAssessmentModal}
              >
                {actionToBePerformed === 'request' ? 'Request Access' : 'Share'}
              </button>
            </div>
          </Modal.Footer>

          {_.map(userAccessPair, (eachUserAccessPair, index) => (
            <>
              {(eachUserAccessPair.isUserDropdownSearchable
                  || eachUserAccessPair.isPermissionDropdownSearchable) && (
                  <div
                    className="custom-dropdown-search-overlay"
                    onClick={this.closeCustomDropdown}
                  />
              )}
            </>
          ))}
        </Modal>

        {/* Alert modal Single share assessment  */}
        <Modal
          show={showNotification}
          // onHide={() => onShareModalSuccClose()}
          className="modal fade singleSharedNotice-modal"
          backdrop="static"
        >
          {actionToBePerformed === 'request' ? (
            <Modal.Header onHide={() => onShareModalSuccClose()} closeButton className="singleSharedNotice-modalHeader">
              <div className="singleSharedNotice-title">
                <i className="fa fa-check-circle" aria-hidden="true" />
                <h4>Request Sent</h4>
              </div>
              <div className="desc-line">
                {' '}
                Access Request is raised successfully for
                {' '}
                {!_.isEmpty(clientDetails.BATClientName) && clientDetails.BATClientName}
                {' '}
                :
                {' '}
                {BATSubOfferingName}
                {' '}
                to
                {ownerUserName}
                {' '}
                for following users
              </div>
            </Modal.Header>
          ) : (
            <Modal.Header onHide={() => onShareModalSuccClose()} closeButton className="singleSharedNotice-modalHeader">
              <div className="singleSharedNotice-title">
                <i className="fa fa-check-circle" aria-hidden="true" />
                <h4>Shared Successfully</h4>
              </div>
              <div className="desc-line">
                {!_.isEmpty(clientDetails.BATClientName) && `${clientDetails.BATClientName}:`}
                {' '}
                {BATSubOfferingName}
                {' '}
                is successfully shared with following users
              </div>
            </Modal.Header>
          )}
          <Modal.Body>
            <div className="modal-userlist">
              <div className="table-heading">
                <div className="list-username-title titleTag">Users</div>
                <div className="list-permission-title titleTag">Permission</div>
              </div>
              <div className="table-body">
                {_.map(userAccessPair, (eachUserAccessPair, index) => (
                  <div className="table-body-item">
                    <div className="list-username listItem">{eachUserAccessPair.UserName}</div>
                    <div className="list-permission listItem">
                      {eachUserAccessPair.AccessLevelName}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>

          {actionToBePerformed === 'request' && (
            <Modal.Footer>
              <div className="singleSharedNotice-requestModalFooter">
                <p className="footer-desc-line">
                  {' '}
                  User will be able to access the assessments once the owner grant permission
                </p>
              </div>
            </Modal.Footer>
          )}
        </Modal>
      </>
    );
  }
}

SingleShareAssessment.propTypes = {
  onShareModalCancelClose: PropTypes.func.isRequired,
  assessmentDetails: PropTypes.object.isRequired,
  clientDetails: PropTypes.object.isRequired,
  tabName: PropTypes.string,
  onShareModalSuccClose: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  actionToBePerformed: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userData: state.BATUsersReducer.userData,
});

// const mapDispatchToProps = (dispatch) => {
//   return {
//     // addUsers: (payload) => dispatch(BATUserData(payload)),
//   };
// };

export default connect(mapStateToProps)(SingleShareAssessment);
