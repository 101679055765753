import React, { Component } from 'react';
import { Tabs, Tab } from "react-bootstrap";
import { LocalApiBaseUrl } from '../Shared/Constant';
import HumanMetricDetails from './HumanMetricDetails';
import ReactTooltip from "react-tooltip";
import i18n from "i18next";
import HumanPopUp from '../Configurator/Humanconf_popup';
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from "react-redux";
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import $ from "jquery";
import PyramidImage from '../Images/Pyramid.svg';
import CloseIcon from "../Images/close-icon.svg";
import {Overlay, Popover } from "react-bootstrap";
import RefreshIcon from "../Images/Refresh.svg";
import CheckIcon from "../Images/HM-Check.svg";
import CancelIcon from "../Images/HM-Cancel.svg";
import emptycomponent from "../Images/Empty State.svg";
import References from "../Shared/References"

const options = {
    loop: true,
    margin: 8,
    responsiveClass: "",
    dots: false,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 2,
        nav: false,
      },
      1000: {
        items: 3,
        nav: true,
        loop: false,
      },
    },
  };
  const optionsTwo = {
    loop: true,
    margin: 8,
    responsiveClass: "",
    dots: false,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 2,
        nav: false,
      },
      1000: {
        items: 2,
        nav: true,
        loop: false,
      },
    },
  };
class HumanMachineTalent extends Component {
    constructor() {
        super()
        this.state = {
           
            exploreLinkList: [],
            exploreDrop: "none",
            exploreDrop1: "", 
            type: "", 
            humanandmachineData: [], 
            HumanData: [], 
            MachineData: [], 
            metricDetails: "", 
            machineMetricDetails: "",
            exploreToolsList: [],
            offeringName: "",
            doesKPIMappingRecordsExistsForDeal: false,
            humanDataForHMJ: [],
            hybridNumerator:0,
            addReferenceStatus: false,
            viewReferenceStatus: false,
            adminReferences: false,
            referencesExist: false,
            hybridDenominator: 0,
            toggle:true,
            textView:false,
            showRefreshButton:false,
            showRatioUpdatedMessage:false,
            isShownFD:true,
            prevNum:0,
            prevDen:0,
            keyResource:0,
            selectedOption:0,
            humanandMachinedata:[],
            machinedata:[],
            machineWorkforceCat:"",
            filterdata:[],
            humanCatagoryName:"",
            machineCatagoryName:"",
            responseMetricData: [],
            kpiDataforEditSOcat: [],
            humanKpiResult: [],
            machineKpiResult: [],       
            kpiDataRespondonSO:[],
            updateFlag: false
        }
        this.maxLengthCheckThreshold=this.maxLengthCheckThreshold.bind(this);
        this.HybridWorkflowPopoverTarget = React.createRef();
        this.isHybridWorkflowPopoverClose = this.isHybridWorkflowPopoverClose.bind(this);
        this.isHybridWorkflowPopoverShow = this.isHybridWorkflowPopoverShow.bind(this);
        this.ToggleButton = this.ToggleButton.bind(this);
        this.onClose = this.onClose.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.handleSettingUpdatedValue=this.handleSettingUpdatedValue.bind(this);
        this.handleDiscardValue=this.handleDiscardValue.bind(this);
        this.handleOpenText=this.handleOpenText.bind(this);
        this.handleRefreshButton=this.handleRefreshButton.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleCloseIcon=this.handleCloseIcon.bind(this);
        this.getHybridRatio=this.getHybridRatio.bind(this);
        this.handleChangeNumerator=this.handleChangeNumerator.bind(this);
        this.handleChangeDenomenator=this.handleChangeDenomenator.bind(this);        
    }
    maxLengthCheckThreshold = (object) => {
      if (object.target.value.length > object.target.maxLength) {
       object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
      }
    dealpopup(soCategoryID, e) {
        e.preventDefault();
        const kpiResult = this.state.kpiDataRespondonSO.findIndex(i=> i.soCategoryID == soCategoryID) > -1 ?
         this.state.kpiDataRespondonSO.filter(f=> {return f.soCategoryID == soCategoryID})[0].responseMetricData : [];
        this.setState({ viewmore: true, type: soCategoryID, kpiDataforEditSOcat: kpiResult }, () => {
          this.updateFlagTrigger();
        });
    }
    updateFlagTrigger = () => {
      this.setState({ updateFlag: !this.state.updateFlag });
    }
    onClose(e) {
        this.setState({ viewmore: false });
    }
  isHybridWorkflowPopoverClose() {
    this.setState({
      isHybridWorkflowPopoverStatus: false, textView: false,
     
    });
   
    axiosInstance
    .get(`${LocalApiBaseUrl}SOFilter/GetHybridWorkDataRatio`, {
      params: {
        OrgEntityID: parseInt(this.props.filterDetails.Client),
        OfferingID: parseInt(this.props.filterDetails.process),
        LocationID: parseInt(this.props.filterDetails.Locations),
        PeriodDT: this.props.filterDetails.Month,
      },
    })
    .then((response) => { 
      //console.log(!isNaN(parseInt(response.data[0].NumeratorValue)));
      if (response.data.length > 0) {
        if(!isNaN(parseInt(response.data[0].NumeratorValue))){
      
          this.setState({
            hybridNumerator: parseInt(response.data[0].NumeratorValue),
            hybridDenominator: parseInt(response.data[0].MetricValue)
          });
        }else{
          this.setState({
            hybridNumerator: this.state.keyResource,
            hybridDenominator: parseInt(response.data[0].MetricValue)
          });
        }
      }
     
    })
    .catch((error) => {
      trycatchAlertPopup(error);
    });

  }
  isHybridWorkflowPopoverShow = () => {
    this.setState({ isHybridWorkflowPopoverStatus: true });
  }
    doesKPIMappingRecordsExistForDeal() {
        let filterClient = this.props.filterDetails.Client;
        let filterProcess = this.props.filterDetails.process;
        let filterLocations = this.props.filterDetails.Locations;
    
        axiosInstance
          .get(`${LocalApiBaseUrl}SOFilter/DoesKPIMappingRecordsExistForDeal`, {
            params: {
              OrgEntityID: filterClient,
              LocationID: filterLocations,
              OfferingID: filterProcess,
            },
          })
          .then((response) => {
            this.setState({ doesKPIMappingRecordsExistsForDeal: response.data });
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
    
      }
      
      ToggleButton() {
        const {toggle}=this.state;
        this.setState((prevState) => ({
          toggle: !prevState.toggle,
          isShownFD: !prevState.isShownFD,
        }));
        var dataHybrid = {
          OrgEntityID: parseInt(this.props.filterDetails.Client),
          OfferingID: parseInt(this.props.filterDetails.process),
          LocationID: parseInt(this.props.filterDetails.Locations),
          MonthDT: this.props.filterDetails.Month,
          IsReset: false
        }
          axiosInstance
            .post(`${LocalApiBaseUrl}SOFilter/EditHybridDataRatio`, dataHybrid,{
              params: {
                Numerator: this.state.hybridNumerator,
                Denominator: this.state.hybridDenominator,
                shownonFD: !this.state.isShownFD,
              },
            })
            .then((response) => {
              if (response.data.length > 0) {

                 this.setState({
                   toggle: !this.state.toggle,
                 })
              }
            })
            .catch((error) => {
              trycatchAlertPopup(error);
            });
        
      }
      getHybridRatio () {
        //calculate the sum of human tile metricvalues here - ratio numerator 
         axiosInstance
           .get(`${LocalApiBaseUrl}SOFilter/GetHybridWorkDataRatio`, {
             params: {
               OrgEntityID: parseInt(this.props.filterDetails.Client),
               OfferingID: parseInt(this.props.filterDetails.process),
               LocationID: parseInt(this.props.filterDetails.Locations),
               PeriodDT: this.props.filterDetails.Month,
             },
           })
           .then((response) => {
             if (response.data.length > 0) {
               this.setState({
                hybridNumerator: parseInt(response.data[0].NumeratorValue),
                hybridDenominator: parseInt(response.data[0].MetricValue),
                isShownFD: response.data[0].IsShownonFD,
                showRefreshButton: response.data[0].IsReset
               });
             }
           })
           .catch((error) => {
            trycatchAlertPopup(error);
           });
          
       }
     
       handleChange = event => {
        this.setState({
          selectedOption: event.target.value
        },()=>{
          this.filterData();
        });
      };


    displayHumanAndMachineDetails = () => {
        //Human....
        let human = [];
        let humanSOData =[];
        let Human = this.state.humanandmachineData.filter((x) => x.SOCategoryName == "Human");
        human = (this.state.humanandmachineData.filter((x) => x.ParentSOCategoryID == Human[0].SOCategoryID));
       if(human.length!=0){
        const selectedTab=this.state.selectedOption == 0 ? human[0].SOCategoryID : this.state.selectedOption;
        this.setState({humanandMachinedata:human,filterdata:this.state.humanandmachineData,selectedOption:selectedTab,humanKpiResult: humanSOData}, () => {
          this.filterData();
        });        
      }
        //Machine....
        let machineData = [];
        let machineSOData = [];
        let Machine = this.state.humanandmachineData.filter((x) => x.SOCategoryName === "Machine")
        let machineCatagory=0;
        Machine.map(item=>{
          if(item.SOCategoryID!=undefined){
          machineCatagory=item.SOCategoryID;
          }
        });
	      this.state.humanandmachineData.map((data) => {
        if(machineCatagory== data.ParentSOCategoryID){
        machineData.push(data);
        }
      });
      
        this.state.machinedata=machineData;
        machineData.map(cat=>{this.state.machineWorkforceCat = cat.SOCategoryID});
        
        let machinelist = [];
        let machinerowTheList = [];
        let responseMetricData = [];
        if(machineData[0] != undefined) { 
          responseMetricData = this.state.kpiDataRespondonSO.findIndex(f=> f.soCategoryID == machineData[0].SOCategoryID) > -1 ? 
          this.state.kpiDataRespondonSO.filter(f=> {return f.soCategoryID == machineData[0].SOCategoryID})[0].responseMetricData
          : [];
          machineData[0].kpiData.map((data, index) => {         
            machinerowTheList.push(data);
            this.setState({machineCatagoryName:machineData[0].SOCategoryName });//machineKpiResult: machineSOData

            if (machineData[0].kpiData.length - 1 == index) {
              
                return machinelist.push(<div className="custom-tilecard hm-tilecard"><div className="tilecard-wrapper no-carousel"> 
                <HumanMetricDetails machineWorkforceCat={this.state.machineWorkforceCat} RecordStatus={this.props.RecordStatus} isManintencneWindowOpen={this.props.isManintencneWindowOpen} CatagoryName={this.state.machineCatagoryName} SOCategoryID={machineData[0].SOCategoryID} Details={machinerowTheList} Name={machineData[0].SOCategoryID} filterDetails={this.props.filterDetails} 
                responseMetricData={responseMetricData} updateFlagTrigger={this.updateFlagTrigger} /> 
                <ReactTooltip place="bottom" effect="solid" className="tipcolor" /></div> </div>)}
          })
        }
        let kpiMappingID=false;
        if (machineData[0]) {
          machineData[0].kpiData.map(item=>{
            if(item.KPIMappingID === 0){
              kpiMappingID=true;
            }
            else
            kpiMappingID=false;
          });
        }
         let machinelistEmpty = (machineData[0] == undefined || machineData[0].kpiData.length == 0 || kpiMappingID)?
       machinelist.push(<div className="emptymetric-msg">
       <img alt="" src={emptycomponent} width="80px" height="80px" />
       <h6>{i18n.t("Please click on '+' to add metrics")}</h6>
     </div>):null

        this.state.machinelist=machinelist;

    }
  
    
    handleToggle = (index) => {
        let filterClient = this.props.filterDetails.Client;
        let filterProcess = this.props.filterDetails.process;
        let filterLocations = this.props.filterDetails.Locations;
        let SOCategoryID = 2
        let appID = this.state.exploreToolsList[index].ApplicationMappingID;
        axiosInstance.get(`${LocalApiBaseUrl}ClickableTools/IncludeExcludeTool`, {
            params: {
                OrgEntityID: filterClient,
                OfferingID: filterProcess,
                LocationID: filterLocations,
                AppId: appID,
                SOCatId: SOCategoryID
            }
        }).then(response => {
            if (response.data.IsSuccess === true) {
                let data = this.state.exploreToolsList;
                data[index].IsEnable = !data[index].IsEnable;
                this.setState({
                    exploreToolsList: data,
                })
            }
        })
        .catch((error) => {
            trycatchAlertPopup(error);
          });
  

    }
    viewMore() {


        let filterClient = this.props.filterDetails.Client;
        let filterProcess = this.props.filterDetails.process;
        let filterLocations = this.props.filterDetails.Locations;
        let SOCategoryID = 2;

        let displayStatus = this.state.exploreDrop;
        if (displayStatus == "none") {


            axiosInstance
                .get(`${LocalApiBaseUrl}ClickableTools/GetExploreTools`, {
                    params: {
                        OrgEntityID: filterClient,
                        OfferingID: filterProcess,
                        LocationID: filterLocations,
                        SOCategoryID: SOCategoryID,
                        IsAllTool: true
                    }
                })
                .then(res => {
                    
                    this.setState({

                        exploreToolsList: res.data,
                        exploreDrop1: res.data.length==0?i18n.t("Please add tools on Pulse Page"):"",
                    })


                  this.onClose();
                })
        .catch((error) => {
          trycatchAlertPopup(error)
        });
    }
    else {
      this.setState({ exploreDrop: "none" });
    }
  }


  handleChangeNumerator(event) {
   
    this.setState({
      hybridNumerator: event.target.value
    });
  }
  handleChangeDenomenator(event) {
 
    this.setState({
      hybridDenominator: event.target.value
    });
    
  }

  handleSettingUpdatedValue() {
    
    this.setState((prev)=>{
      return{
      textView: !prev.textView,
     
      showRatioUpdatedMessage:!prev.showRatioUpdatedMessage
      }
    });
   
    var dataHybrid = {
      OrgEntityID: parseInt(this.props.filterDetails.Client),
      OfferingID: parseInt(this.props.filterDetails.process),
      LocationID: parseInt(this.props.filterDetails.Locations),
      MonthDT: this.props.filterDetails.Month,
      IsReset: false
    }
     axiosInstance
      .post(`${LocalApiBaseUrl}SOFilter/EditHybridDataRatio`, dataHybrid,{
        params: {
          Numerator: this.state.hybridNumerator,
          Denominator: this.state.hybridDenominator,
          shownonFD: true,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
       
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
      axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetHybridWorkDataRatio`, {
        params: {
          OrgEntityID: parseInt(this.props.filterDetails.Client),
          OfferingID: parseInt(this.props.filterDetails.process),
          LocationID: parseInt(this.props.filterDetails.Locations),
          PeriodDT: this.props.filterDetails.Month,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
           showRefreshButton: false
          });
        }
      })
      .catch((error) => {
       trycatchAlertPopup(error);
      });
      setTimeout(()=> {
        $('#showRatioMessage').hide();
       
      }, 5000);
  }
  handleDiscardValue() {
    this.setState ({
      textView: false,
      hybridNumerator: null,
    });
    axiosInstance
    .get(`${LocalApiBaseUrl}SOFilter/GetHybridWorkDataRatio`, {
      params: {
        OrgEntityID: parseInt(this.props.filterDetails.Client),
        OfferingID: parseInt(this.props.filterDetails.process),
        LocationID: parseInt(this.props.filterDetails.Locations),
        PeriodDT: this.props.filterDetails.Month,
      },
    })
    .then((response) => {
       
      if (response.data.length > 0) {
        this.setState({
          hybridNumerator:this.state.keyResource,
         hybridDenominator: parseInt(response.data[0].MetricValue),
 
        });
      }
    })
    .catch((error) => {
      trycatchAlertPopup(error);
    });
  
  }
  handleRefreshButton = async () =>{
    var dataHybrid = {
      OrgEntityID: parseInt(this.props.filterDetails.Client),
      OfferingID: parseInt(this.props.filterDetails.process),
      LocationID: parseInt(this.props.filterDetails.Locations),
      MonthDT: this.props.filterDetails.Month,
      IsReset: true
    }
  
    await axiosInstance
    .post(`${LocalApiBaseUrl}SOFilter/EditHybridDataRatio`, dataHybrid,{
      params: {
        Numerator: this.state.keyResource,
        Denominator: this.state.prevDen,
        shownonFD: this.state.isShownFD,
      },
    })
    .then((response) => {
      if (response.data.length > 0) {
      }
    })
    .catch((error) => {
      trycatchAlertPopup(error);
    });
   
    await axiosInstance
    .get(`${LocalApiBaseUrl}SOFilter/GetHybridWorkDataRatio`, {
      params: {
        OrgEntityID: parseInt(this.props.filterDetails.Client),
        OfferingID: parseInt(this.props.filterDetails.process),
        LocationID: parseInt(this.props.filterDetails.Locations),
        PeriodDT: this.props.filterDetails.Month,
      },
    })
    .then((response) => {
      //console.log(parseInt(response.data[0].MetricValue));
      this.setState({
      hybridNumerator:this.state.keyResource,
      hybridDenominator: parseInt(response.data[0].MetricValue),
      showRefreshButton: true
    });
    })
    .catch((error) => {
      trycatchAlertPopup(error);
    });
        this.setState({
        // hybridNumerator:this.state.keyResource,
        // hybridDenominator: this.state.prevDen,
        showRatioUpdatedMessage:false
        });
       
  }
handleOpenText= () => {  
      this.setState((prevState) => ({
        textView: !prevState.textView,
      }));
  }
  
  
  handleCloseIcon(){
    this.setState({
      showRatioUpdatedMessage:false,
 
      textView:false
    });
  }
  filterData = () =>{
    let Human = this.state.filterdata.filter((x) => x.SOCategoryName === "Human");
    let human = (this.state.filterdata.filter((x) => x.ParentSOCategoryID === Human[0].SOCategoryID))
    let humanData = human.filter((x) => x.SOCategoryID == this.state.selectedOption);
    let list = [];
    let rowTheList = [];
    let kpiResult = [];
    
    if(humanData[0] != undefined){
      kpiResult = this.state.kpiDataRespondonSO.findIndex(f=> f.soCategoryID == humanData[0].SOCategoryID) > -1 ? 
      this.state.kpiDataRespondonSO.filter(f=> {return f.soCategoryID == humanData[0].SOCategoryID})[0].responseMetricData : [];
      humanData[0].kpiData.map((data, index) => {
        rowTheList.push(data);
        this.state.humanCatagoryName=humanData[0].SOCategoryName;
        if (humanData[0].kpiData.length - 1 == index) {
          return list.push(<div className="custom-tilecard hm-tilecard"><div className="tilecard-wrapper no-carousel">
          
            <HumanMetricDetails selectedOption={this.state.selectedOption} RecordStatus={this.props.RecordStatus} isManintencneWindowOpen={this.props.isManintencneWindowOpen} SOCategoryID={humanData[0].SOCategoryID} Details={rowTheList} Name={humanData[0].SOCategoryID} CatagoryName={this.state.humanCatagoryName} filterDetails={this.props.filterDetails} 
            responseMetricData={kpiResult} updateFlagTrigger={this.updateFlagTrigger} />
            <ReactTooltip place="bottom" effect="solid" className="tipcolor" /></div></div>)
        }
      })
    }
    let kpiMappingID=false;
    if (humanData[0] && humanData[0].kpiData.length>0) {
      humanData[0].kpiData.map(item=>{
        if(item.KPIMappingID === 0){
          kpiMappingID=true;
        }
        else
        kpiMappingID=false;
      });
    }
    let listEmpty = (humanData[0] == undefined || humanData[0].kpiData.length == 0 || kpiMappingID) ?
      list.push(<div className="emptymetric-msg">
        <img alt="" src={emptycomponent} width="80px" height="80px" />
        <h6>{i18n.t("Please click on '+' to add metrics")}</h6>
      </div>) : null
     

    // New Logic to fetch Numerator value from key resource
    var keyInsight = {};
    
    if (human[1] != undefined) {
      keyInsight = human[1];
    
        let sumOfMetricValues = 0;
      
        if (this.state.hybridNumerator != 0 && !isNaN(this.state.hybridNumerator)) {
          sumOfMetricValues = parseInt(this.state.hybridNumerator);
         
        }
        else {
                sumOfMetricValues = this.state.keyResource
        
        }
        this.setState({ hybridNumerator: sumOfMetricValues });
        
      }
 
    this.setState({prevNum: this.state.hybridNumerator, prevDen: this.state.hybridDenominator});
    this.setState({list});
  }
  componentDidMount() {    
    this.setState({ humanandmachineData: this.props.humanandmachineData }, () => {       
      let Human = this.state.humanandmachineData.filter((x) => x.SOCategoryName == "Human");    
      let Machine = this.state.humanandmachineData.filter((x) => x.SOCategoryName === "Machine");
      let human = (this.state.humanandmachineData.filter((x) => x.ParentSOCategoryID === Human[0].SOCategoryID))
      var keyInsight = {};
      keyInsight = human && human[1];
     
      keyInsight && keyInsight.kpiData && keyInsight.kpiData.map(i => {
        if (i.MetricName == "Total Resource" && i.MetricUnit == "Number") {
        this.state.keyResource = i.MetricValue;
        }
      });

      let human_machine = (this.state.humanandmachineData.filter((x) => x.ParentSOCategoryID == Human[0].SOCategoryID || x.ParentSOCategoryID == Machine[0].SOCategoryID));
      
      if(human_machine && human_machine.length > 0){
        let kpiDataRespondonSO = [...this.state.kpiDataRespondonSO];
        let response = [];
        human_machine.forEach(async (f, ind)=> {
          response = await this.getKpiMAppingDetails(f.SOCategoryID);
          const kIndex = kpiDataRespondonSO.findIndex(fi=> fi.soCategoryID == f.SOCategoryID);
          if(kIndex == -1){
            kpiDataRespondonSO.push({
              soCategoryID: f.SOCategoryID,
              responseMetricData: response
            })
          }
          else{
            kpiDataRespondonSO[kIndex] = {...kpiDataRespondonSO[kIndex], responseMetricData: response}
          }
          
          if(ind == human_machine.length - 1){
            this.setState({kpiDataRespondonSO}, () => {
              this.displayHumanAndMachineDetails();
            })
          }
        })  
      }        
    })
    this.doesKPIMappingRecordsExistForDeal();
    this.getHybridRatio();
   
    
}

componentDidUpdate(prevProps,prevState) { 

  if(prevState.updateFlag != this.state.updateFlag || prevProps.humanandmachineData != this.props.humanandmachineData){  
    this.setState({ humanandmachineData: this.props.humanandmachineData }, () => {    
      this.displayHumanAndMachineDetails();
    })
    
  }
  let Human = this.state.humanandmachineData.filter((x) => x.SOCategoryName == "Human");
      let human = (this.state.humanandmachineData.filter((x) => x.ParentSOCategoryID === Human[0].SOCategoryID))
      var keyInsight = {};
      keyInsight = human && human[1];
     let metricValue = '';
     keyInsight && keyInsight.kpiData && keyInsight.kpiData.map(i => {
        if (i.MetricName == "Total Resource" && i.MetricUnit == "Number") {
        metricValue = i.MetricValue;
        }
      });
      if(metricValue !== this.state.keyResource && this.state.showRefreshButton){
        this.setState({keyResource: metricValue,hybridNumerator:metricValue})
      }

    $(document).on('click', '.explore_tools_root', function (e) {
        e.stopPropagation();
    });

 }

  getKpiMAppingDetails = (SOCategoryID) => {   
      return axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
            params: {
                OrgEntityID: this.props.filterDetails.Client,
                LocationID: this.props.filterDetails.Locations,
                OfferingID: this.props.filterDetails.process,
                SOCategoryID: SOCategoryID,
                MonthDT: this.props.filterDetails.Month
            }
        })
        .then((response) => {
          return response.data;        
        })            
    }
    
    render() {
      let numeratorHeight = 0;
      let denomenatorHeight = 0;
      const { exploreToolsList, hybridNumerator, hybridDenominator } = this.state;
      if(hybridNumerator === null || hybridNumerator === 0) {
        numeratorHeight = 0;
        denomenatorHeight = 60;
      }else{
        numeratorHeight=(parseInt(hybridNumerator)*100/(parseInt(hybridNumerator)+parseInt(hybridDenominator)));
        denomenatorHeight=(parseInt(hybridDenominator)*100/(parseInt(hybridNumerator)+parseInt(hybridDenominator)));
      }
      
      
      return (
        <>
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-title clearfix references-dropdown dashboard-references-dropdown configurator-references-dropdown-tabs d-flex">
                {hybridDenominator !== 0 ?

                  <div className="hmratio-toggle-btn">
                    <p>Human Machine Ratio</p>
                    <div className="toggle-group toggle-tick custom-toggle-tickbtn" data-tip data-for="toggle-tooltip">
                      <input onClick={this.ToggleButton}
                        type="checkbox"
                        name="hmratio"
                        id="hmratio"
                        defaultChecked={this.state.isShownFD}
                      />
                      <label htmlFor="hmratio">Connection Status</label>
                      <div className="onoffswitch" aria-hidden="true">
                        <div className="onoffswitch-label">
                          <div className="onoffswitch-inner"></div>
                          <div className="onoffswitch-switch"></div>
                        </div>
                      </div>
                      {this.state.toggle ?
                        (<ReactTooltip place="bottom" effect="solid" id="toggle-tooltip">
                          <span>Click to toggle off the visibility of Human Machine Ratio on SynOps Applied Now</span>
                        </ReactTooltip>) :
                        (<ReactTooltip place="bottom" effect="solid" id="toggle-tooltip">
                          <span>Click to toggle on the visibility of Human Machine Ratio on SynOps Applied Now</span>
                        </ReactTooltip>)}
                    </div>
                  </div> : null}
                {this.state.isShownFD ?
                  <div>
                    {(hybridDenominator !== 0) ?
                      <div className="hybrid-link" ref={this.HybridWorkflowPopoverTarget}>
                        <span onClick={this.isHybridWorkflowPopoverShow} data-tip data-for="pyramid-tooltip"> 
                          <img src={PyramidImage} width="23px" height="23px" className="mr-3" />
                          <ReactTooltip place="bottom" effect="solid" id="pyramid-tooltip">
                          <p className="mb-0">Click to view/edit Human Machine Ratio</p>
                        </ReactTooltip>
                          </span>
                        <Overlay
                          target={this.HybridWorkflowPopoverTarget.current}
                          show={this.state.isHybridWorkflowPopoverStatus}
                          onHide={this.isHybridWorkflowPopoverClose}
                          placement="bottom-end"
                          container={this.HybridWorkflowPopoverTarget.current}
                          rootClose
                          rootCloseEvent="click"
                        >
                          <Popover className="hybridworkflow-popover">
                            <Popover.Content>
                              <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={this.isHybridWorkflowPopoverClose}
                              >
                                <img onClick={this.handleCloseIcon} src={CloseIcon} />
                              </button>
                              <h4>Hybrid Workforce</h4>
                              <div className="hm-ratio-display">
                                <p>Human Machine Ratio</p>
                                {this.state.textView ?
                                  <div className="ratioupdate-btns">
                                    <input className="input-radio-value mr-1" type="number" maxLength="4" onInput={this.maxLengthCheckThreshold} onChange={this.handleChangeNumerator} name="Numerator" value={this.state.hybridNumerator} />
                                    <input className="input-radio-value mr-1" type="number" maxLength="4" onInput={this.maxLengthCheckThreshold} onChange={this.handleChangeDenomenator} name="Denomenator" value={this.state.hybridDenominator} />
                                    {(this.state.hybridNumerator == 0 || this.state.hybridDenominator == 0) ?
                                    <img className="mr-1" style={{ opacity: '0.3', filter: 'grayscale(100%) '}} src={CheckIcon} /> :
                                    <img className="mr-1" onClick={this.handleSettingUpdatedValue} src={CheckIcon} />}
                                    <img className="mr-1" onClick={this.handleDiscardValue} src={CancelIcon} />
                                  </div>
                                  :
                                  <span class="ratio-update">
                                    
                                    <span  className="ratio-value"> {parseInt(this.state.hybridNumerator)}:{parseInt(this.state.hybridDenominator)}</span>
                                    {(!this.state.textView) && <span data-tip data-for="editicon-tooltip"><i onClick={this.handleOpenText} className="fas fa-edit fa-lg hmratioedit-btn">
                                    </i>
                                    <ReactTooltip place="bottom" effect="solid" id="editicon-tooltip">
                                          <span>Click here to edit Hybrid Ratio</span>
                                        </ReactTooltip>
                                    </span>
                                    }
                                    
                                    {(!this.state.showRefreshButton) &&
                                      <span data-tip data-for="refresh-tooltip">

                                        <img onClick={this.handleRefreshButton} src={RefreshIcon} className="ml-3 refresh-icon" />
                                        <ReactTooltip place="bottom" effect="solid" id="refresh-tooltip">
                                          <span>Sync Automated Ratio</span>
                                        </ReactTooltip>
                                      </span>}
                                    <br />
                                    {(this.state.showRatioUpdatedMessage) && <span id="showRatioMessage" className="ratio-updated-text">Ratio updated</span>}
                                  </span>}
                              </div>
                              
                              {/* Pyramid Chart start */}
                          
                              <div class="human-machine-labels">
                              <div class="human-label" style={{ height: numeratorHeight+"%" }}><span>Human <br /><span> {this.state.hybridNumerator}</span></span></div>
                                <div class="machine-label" style={{ height: denomenatorHeight+"%" }}><span>Machine <br /> <span>{this.state.hybridDenominator}</span></span></div>
                              </div>
                              <div id="pyramid-hybrid" class="pyramid-chart">
                                <div id="level1" style={{ height: numeratorHeight+"%" }}>
                                </div>
                                <div id="level2" style={{ height: denomenatorHeight+"%" }}>
                                </div>
                              </div>
                              {/* Pyramid Chart end */}

                            </Popover.Content>

                          </Popover>
                        </Overlay>

                      </div> : null}
                  </div> :
                  <div>
                    <img className="mr-3" style={{ opacity: '0.2', filter: 'grayscale(100%) ' }} src={PyramidImage} width="23px" height="23px" />
                  </div>}
                            <div className="dropdown">
                                
                                    
                                <a
                                    className="dropdown-toggle"
                                    data-toggle="dropdown"
                                    onClick={() => { this.viewMore() }}
                                >
                                    <span className="exploreTools">
                                    {i18n.t("Explore_Tools_Advisor")}
                                    <span>{i18n.t("Click on Explore Tools/Advisor to view Tools")}</span>
                                    </span>
                                <span className="caret"></span>
                                </a>

                                                                
                                <ul className="dropdown-menu explore_tools_root explore-tools-menu dropdown-menu-right custom-dropdown-menu">
                                   
                                    {exploreToolsList.length > 0 && exploreToolsList.map((eachLinks, index) => {

                                        return (
                                            <div className="explore_tools">
                                                <a className={eachLinks.IsEnable ? (eachLinks.URL ? "dropdown-item" : "dropdown-item explore_tools_noCursor") : "dropdown-item disabled"} href={eachLinks.URL ? (eachLinks.URL !== "NA" && eachLinks.URL !== "https://" ? eachLinks.URL : eachLinks.URL.substr) : "javascript:void(0)"} target={eachLinks.URL && "_blank"}>
                                                    {eachLinks.ApplicationName}

                                                </a>
                                                <div className="toggle-group toggle-tick">
                                                    <input
                                                        type="checkbox"
                                                        name={"datatypechange" + index}
                                                        id={"datatypechange" + index}
                                                        defaultChecked={true}
                                                        onChange={(e) => { this.handleToggle(index) }}
                                                        checked={eachLinks.IsEnable}
                                                    />
                                                    <label htmlFor={"datatypechange" + index}>Connection Status</label>
                                                    <div className="onoffswitch" aria-hidden="true">
                                                        <div className="onoffswitch-label">
                                                            <div className="onoffswitch-inner"></div>
                                                            <div className="onoffswitch-switch"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        );

                                    })
                                                                       
                                    }
                                </ul>
                            
                                
                            </div>
                            <div className="category-references dashboard-category-references">
                            {<References 
                            {...this.props} 
                            SOCatID={2}
                            leverName="Human + Machine Talent"
                            isConfig={true}
                            referenceScreen="HM"
                            />
                        }
                        </div>
                        </div>
                    </div>
                </div>
                
            
                <div className="row">
                    <div className="col-lg-12">
                    <div className="hmtab-layout">
             
              <Tabs defaultActiveKey="Human">

                  <Tab eventKey="Human" title="Human">
                    <div className="title-with-icon">
                      <div className="hmradio-btns">
                        {this.state.humanandMachinedata.map((Catagory) => {
                          return <>
                            {this.state.selectedOption == Catagory.SOCategoryID ?
                              <label className="form-check mr-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id={Catagory.SOCategoryID}
                                  name={Catagory.SOCategoryName}
                                  value={Catagory.SOCategoryID}
                                  checked={this.state.selectedOption == Catagory.SOCategoryID}
                                  onChange={this.handleChange}
                                />
                                <span className="checkmark"></span>
                                <span className="form-check-label active">{Catagory.SOCategoryName}</span>
                              </label> :
                              <label className="form-check mr-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id={Catagory.SOCategoryID}
                                  name={Catagory.SOCategoryName}
                                  value={Catagory.SOCategoryID}
                                  checked={this.state.selectedOption == Catagory.SOCategoryID}
                                  onChange={this.handleChange}
                                />
                                <span className="checkmark"></span>
                                <span className="form-check-label active">{Catagory.SOCategoryName}</span>
                              </label>}
                          </>

                        })}
                      </div>
                     
                      <div className="list-icon">
                        {this.state.humanandMachinedata.map((Catagory) => (
                          <>
                            {this.state.selectedOption == Catagory.SOCategoryID &&
                              <div>
                                {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] === "True" && !this.props.isManintencneWindowOpen) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0) ? "" :

                                  <span onClick={(e) => { this.dealpopup(Catagory.SOCategoryID, e) }}>
                                    <i className="far fa-plus plus-icon-fix"></i>

                                  </span>}
                              </div>}
                          </>))}
                      </div>

                    </div>
                    {this.state.list}

                </Tab>
                  <Tab eventKey="Machine" title="Machine">
                    <div className="title-with-icon">
                    {this.state.machinedata.map(item=>{
                      return <div className="hmradio-btns">
                      <h5>{item.SOCategoryName}</h5>
                    </div>
                    })}
                      {this.state.machinedata.map(machine => (
                        <div className="list-icon">
                          {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] === "True" && !this.props.isManintencneWindowOpen) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0) ? "" :

                            <span onClick={(e) => { this.dealpopup(machine.SOCategoryID, e) }}>
                              <FontAwesomeIcon icon={faPlus} />
                            </span>}
                        </div>))}
                    </div>
                    {this.state.machinelist}
                  </Tab>
              </Tabs>
            </div>
        </div>
              {this.state.viewmore && this.state.type == this.state.selectedOption ?
                <HumanPopUp   selectedOption={this.state.selectedOption} SOCategoryID={this.state.selectedOption} type={this.state.selectedOption} CatagoryName={this.state.humanCatagoryName} show={this.state.viewmore} onClose={this.onClose} opsgrp={this.props.opsgrp} filterDetails={this.props.filterDetails} responseMetricData={this.state.kpiDataforEditSOcat} updateFlag={this.updateFlagTrigger} />
                : null}
            
              {this.state.viewmore && this.state.type == this.state.machineWorkforceCat ?
                <HumanPopUp   SOCategoryID={this.state.machineWorkforceCat} machineWorkforceCat={this.state.machineWorkforceCat} CatagoryName={this.state.machineCatagoryName} type={this.state.machineWorkforceCat} show={this.state.viewmore} onClose={this.onClose} opsgrp={this.props.opsgrp} filterDetails={this.props.filterDetails} responseMetricData={this.state.kpiDataforEditSOcat} updateFlag={this.updateFlagTrigger} />:null} 

            </div>
        </>
      )
    }
}
const mapStateToProps = (state) => {
    return {
        roleReducers: state.roleReducers.roleReducers,
        humanandmachineData: state.humanReducers.humanandmachine
    };
};


export default connect(mapStateToProps, "")(HumanMachineTalent);
