import React, { Component } from "react";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import { DeleteInitiative } from "../actions/JourneyActions";
import DeleteModal from "./DeleteModal";
axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class InitiativeDraftList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteInitiativeDraftStatus: false,
      deleteInitiativeDataID: 0,
      isApiLoading: false,
      draftList: [],
    };
  }
  deleteIoj = (Id) => {
    this.setState({
      deleteInitiativeDataID: Id,
      deleteInitiativeDraftStatus: true,
    });
  };
  deleteItemModalClose = () => {
    this.setState({ deleteInitiativeDraftStatus: false });
  };
  deleteIOJApiCall = () => {
    this.deleteItemModalClose();
    const { deleteInitiativeDataID } = this.state;
    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .delete(
        `${LocalApiBaseUrl}IOJJourneyInitiative/DeleteInitiatives?ID=${deleteInitiativeDataID}`
      )
      .then((response) => {
        this.setState({
          isApiLoading: false,
        });
        axiosInstance
          .get(
            `${LocalApiBaseUrl}Configurator/GetIntelligentOperationsJourneyQualityScore`,
            {
              params: {
                LocationID: this.props.filterDetails.Locations,
                OrgEntityID: this.props.filterDetails.Client,
                OfferingID: this.props.filterDetails.process,
                OMID:
                  this.props.filterDetails.LocationOMID.length === 0 ||
                    this.props.filterDetails.LocationOMID[0] === "All"
                    ? 0
                    : this.props.filterDetails.LocationOMID[0],
                LanguageID: store.getState().CurrentFilter.languageData.id,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              store.dispatch({
                type: "SAVE_QC_SCORE_OF_EACH_TAB",
                payload: response.data,
              });
            }
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });

        this.getDraftInitiativeData();

        this.setState({
          showsuccessModal: true,
        });
      });
  };
  checkifOMIDisAvailable = (locationId, offeringId) => {
    if (String(locationId) !== "All" && String(offeringId) !== String(0)) {
      let status =
        this.props.OMIDDataList.filter(
          (each) => String(each.locID) === String(locationId)
        )[0].IOJOMIDDatas.length > 0
          ? true
          : false;
      return status;
    }
    if (String(offeringId) === String(0)) {
      return true;
    } else {
      let status = false;
      this.props.OMIDDataList.map((each) => {
        if (each.IOJOMIDDatas.length > 0) {
          status = true;
        }
      });
      return status;
    }
  };

  getDraftInitiativeData = () => {
    let configuratorStatus =
      this.props.history.location.pathname === "/admin/Configurator"
        ? true
        : false;
    const { currentFilter, clientData, initiativeStageId } = this.props;
    let LanguageID = store.getState().CurrentFilter.languageData.id;
    this.setState({
      isApiLoading: true,
    });
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(
          `${LocalApiBaseUrl}IOJJourneyInitiative/GetExpandedViewData?IOJStageID=${initiativeStageId}&LanguageId=${LanguageID}`,
          {
            OrgEntityID: clientData.id,
            OfferingID:
              currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,
            LocationID:
              currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
            OMID:
              currentFilter.LocationOMID[0] === "All"
                ? 0
                : currentFilter.LocationOMID[0],
            OfferingName: currentFilter.OfferingName
              ? currentFilter.OfferingName
              : "All",
            IsConfigurator: configuratorStatus,
          }
        )
        .then((response) => {
          this.setState({
            isApiLoading: false,
            draftList: response.data,
          });

          resolve();
        })
        .catch((error) => {
          this.setState({
            isApiLoading: false,
          });
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  };
  componentDidMount() {
    this.getDraftInitiativeData();
  }

  render() {
    const { enableDraftModalStatus, disableDraftModalStatus } = this.props;
    const { deleteInitiativeDraftStatus, isApiLoading, draftList } = this.state;
    return (
      <>
        {isApiLoading ? (
          <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
        ) : (
          ""
        )}
        {enableDraftModalStatus ? (
          <Modal
            show={enableDraftModalStatus}
            className="ioj-drafts-modal"
            animation={true}
          >
            <Modal.Header>
              <Modal.Title>Drafts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="drafts-wrapper">
                {draftList.length !== 0 ? (
                  draftList.map((each) => (
                    <div className="drafts-list">
                      <div className="list-name">
                        <div className="initiative-names">
                          {each.ProjectInitiativeName}
                        </div>
                      </div>
                      <div className="list-actions">
                        <span
                          className="editAccess"
                          onClick={() => {
                            this.props.onEditInitiative(
                              each.IntelOpsInitiativesID
                            );
                          }}
                        >
                          <i class="fal fa-edit"></i>
                        </span>
                        <span
                          className="deleteAccess"
                          onClick={() => {
                            this.deleteIoj(each.IntelOpsInitiativesID);
                          }}
                        >
                          <i class="fal fa-trash-alt delete-icon-fix"></i>
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <span>No initiative draft available.</span>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={disableDraftModalStatus}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
        {deleteInitiativeDraftStatus ? (
          <DeleteModal
            title={"Delete_Initiative"}
            Message={"Are_you_Sure_you_Want_to_Delete"}
            deleteAssessment={deleteInitiativeDraftStatus}
            deleteItemModalClose={this.deleteItemModalClose}
            deleteItem={this.deleteIOJApiCall}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
    currentFilter: state.CurrentFilter.SyncFilter,
    filter: state.CurrentFilter.Filter,
    clientData: state.CurrentFilter.ClientData,
    allInitiativeData: state.JourneyReducer.JourneyInitiatives,
    IOJGlidePathAllModels: state.JourneyReducer.IOJData,
    previousSelectedInitiative: state.JourneyReducer.Datas.viewData,
    Datas: state.JourneyReducer.Datas,
    OMIDDataList: state.JourneyReducer.OMIDDataList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteInitiative: (payload) => dispatch(DeleteInitiative(payload)),
  };
};

let conntedFunc = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withRouter,
  conntedFunc
)(InitiativeDraftList);
