/* eslint-disable no-const-assign */
/* eslint-disable no-self-assign */
import React from 'react';
import {
  Modal, Tooltip, OverlayTrigger, Form,
} from 'react-bootstrap';
import _ from 'lodash';
import i18n from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import {
  LocalApiBaseUrl, regexForNumbersOnlyMTC, regexForNegativeNumbers, trycatchAlertPopup, regexForTextAreas,
} from '../Shared/Constant';
import { store } from '../reducers/configureStore';
import axiosInstance from '../Shared/interceptor';

import KpiPopUp from './KpiPopUp';

import { FormatDate, isValidText, InvalidDescription } from '../Shared/commonFunctions';
import { MetricValueThousandFormat, thousandSeparatorFormat } from '../Shared/Common';

export default class DiversePopUp extends React.Component {
  constructor(props) {
    super(props);
    const { show, SelectValue, attributes } = this.props;
    this.state = {
      show,
      showCategory: false,
      apivalue: '',
      showaddCategory: false,
      iscatypedisable: false,
      isEdit: false,
      showaddCategoryType: false,
      showApproval: false,
      selectValue: SelectValue,
      selectDropValue: '',
      selectCattypeValue: '',
      Display: false,
      EditIndex: 0,
      categories: [],
      categoriesData: [],
      CategoryDetails: { CategoryType: '', CategoryName: '' },
      MetricNameOptions: [],
      commentsError: '',
      LeadingAndSimilarCheck: false,
      viewmore: false,
      CatNameID: 0,
      CatTypeID: 0,
      numeratorVal: 0,
      FormulaList: [],
      denomVal: 0,

      formIsValid: false,
      isAddCatPopup: false,
      valueConnectorEdit: false,
      valueConnector: false,
      allCategoryTypesAndNames: [],
      CategoryNameData: [],
      categoriesLimit: 30, // DEFAULT
      diverseData: {
        formulaStr: '',
        KPIMappingID: 0,
        MetricSeq: '',
        MetricID: '',
        MetricName: '',
        LeadingPractice: '',
        KpiMetricConnectorID: false,
        FrequencyID: '',
        MetricUnit: '',
        MetricImage: '',
        KPIDesp: '',
        MetricDataID: '',
        MetricDataDesp: '',
        MetricValue: '',
        RAGStatus: '',
        UpperThreshold: '',
        LowerThreshold: '',
        BaselineValue: '',
        BaseLineMonth: '',
        TargetValue: '',
        Month: '',
        ConnectorID: '',
        ConnectorNames: '',
        SourceFieldName: '',
        SourceName: '',
        IsAccentureOwned: false,
        Comments: '',
        metDataAttr: [
          {
            MetricAttributeID: '',
            MetricDataID: '',
            AttributeName:
              attributes.length > 0
                ? attributes.filter((z) => z.ApplicableForDD === true).length > 0
                  ? attributes.filter((z) => z.ApplicableForDD === true)[0].AttributeName1
                  : ''
                : '',
            AttributeValue: '',
            CreatedUser: null,
            CreatedTS: '',
            UpdatedUser: null,
            UpdatedTS: '',
          },
          {
            MetricAttributeID: 304,
            MetricDataID: 1275,
            AttributeName:
              attributes.length > 0
                ? attributes.filter((z) => z.ApplicableForDD === true).length > 0
                  ? attributes.filter((z) => z.ApplicableForDD === true)[0].AttributeName2
                  : ''
                : '',
            AttributeValue: '',
            CreatedUser: null,
            CreatedTS: '0001-01-01T00:00:00',
            UpdatedUser: null,
            UpdatedTS: '0001-01-01T00:00:00',
          },
        ],
        MetricDefination: [],
        MetricDefinitionID: '',
        CategoryDetails: [],
        IsMultiLevel: false,
        IsAggregation: false,
        IndustryName: '',
        IndustryID: '',
        IsGlobal: true,
        //  }
        // ]
      },

      errors: {
      },
      valueExist: false,
      BaselineMonthReadOnly: true,
    };

    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSimilarChange = this.onSimilarChange.bind(this);
    this.onLeadingChange = this.onLeadingChange.bind(this);
    this.onValueDescriptionChange = this.onValueDescriptionChange.bind(this);
    this.onUnitChange = this.onUnitChange.bind(this);
    this.onActualChange = this.onActualChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleRejectHide = this.handleRejectHide.bind(this);
    this.handleApproveHide = this.handleApproveHide.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onToolOwnerChange = this.onToolOwnerChange.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
    this.onSourceSystemChange = this.onSourceSystemChange.bind(this);
    this.onOtherSystemChange = this.onOtherSystemChange.bind(this);
    // MULTI LEVEL
    this.handleApprovalClose = this.handleApprovalClose.bind(this);
    this.handleCategoryClose = this.handleCategoryClose.bind(this);
    this.handleCategoryShow = this.handleCategoryShow.bind(this);
    this.handleaddCategoryNameClose = this.handleaddCategoryNameClose.bind(this);
    this.handleaddCategoryClose = this.handleaddCategoryClose.bind(this);
    this.CatTypeSelect = this.CatTypeSelect.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleaddCategoryShow = this.handleaddCategoryShow.bind(this);
    this.handleaddCategoryAdd = this.handleaddCategoryAdd.bind(this);
    this.handleCategoryEditShow = this.handleCategoryEditShow.bind(this);
    this.editCategoryName = this.editCategoryName.bind(this);
    this.handleCategoryEdit = this.handleCategoryEdit.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.deleteCategoryType = this.deleteCategoryType.bind(this);
    this.handleCategoryAdd = this.handleCategoryAdd.bind(this);
    this.handleCategoryTypeAddShow = this.handleCategoryTypeAddShow.bind(this);
    this.deleteCategoryName = this.deleteCategoryName.bind(this);
    this.onBaselineValueChange = this.onBaselineValueChange.bind(this);
    this.onBaseLineMonthChange = this.onBaseLineMonthChange.bind(this);
    this.onTargetValueChange = this.onTargetValueChange.bind(this);
  }

  onClose(e) {
    this.setState({ viewmore: false });
    this.props.onClose(e);
  }

  componentDidMount() {
    this.loadPopup();
    this.getCategoriesLimit();
  }

  componentDidUpdate(nextProps) {
    const { kpiMappingResponse } = this.props;
    if (String(kpiMappingResponse) !== String(nextProps.kpiMappingResponse)) {
      this.loadPopup();
    }
  }

  onBaselineValueChange(event) {
    const { diverseData } = this.state;
    const kpiData = diverseData;
    if (event.target.value === '') {
      kpiData.BaselineValue = event.target.value;
      kpiData.BaseLineMonth = '';
      this.setState({
        diverseData: kpiData,
        BaselineMonthReadOnly: true,
      });
    } else if (regexForNegativeNumbers.test(event.target.value)) {
      if (event.target.value === '-.') {
        kpiData.BaselineValue = '-0.';
      } else {
        kpiData.BaselineValue = event.target.value;
      }
      this.setState({
        diverseData: kpiData,
        BaselineMonthReadOnly: false,
      });
    }
  }

  onBaseLineMonthChange = (date) => {
    const kpiData = this.state.diverseData;
    kpiData.BaseLineMonth = date;
    this.setState({
      diverseData: kpiData,
    });
  }

  onTargetValueChange(event) {
    const { diverseData } = this.state;
    const kpiData = diverseData;
    if (event.target.value === '') {
      kpiData.TargetValue = event.target.value;
      this.setState({
        diverseData: kpiData,
      });
    } else if (regexForNumbersOnlyMTC.test(event.target.value)) {
      if (event.target.value === '-.') {
        kpiData.TargetValue = '-0.';
      } else {
        kpiData.TargetValue = event.target.value;
      }
      this.setState({
        diverseData: kpiData,
      });
    }
  }

  // async
  getKpiMAppingDetails = () => {
    const { diverseData, allCategoryTypesAndNames, data } = this.state;
    let valueConnectorEdit = false;
    let mappedConnectorName;

    const {
      kpiMappingResponse, kpiData, editDiverse, kpiID,
    } = this.props;
    const mappingData = kpiMappingResponse;

    if (mappingData && mappingData.length > 0) {
      let { FormulaList } = this.state;
      FormulaList = mappingData[0].FormulaList;
      this.setState({
        allCategoryTypesAndNames: mappingData[0].CategoryList,
        FormulaList,
      });
    }
    if (mappingData) {
      if (kpiData) {
        const mappedConnector = mappingData.filter((x) => String(x.KpiMappingID) === String(kpiData.KPIMappingID));
        if (mappedConnector.length > 0) {
          this.setState({
            KpiMetricConnectorID: parseInt(mappedConnector[0].KpiMetricConnectorID, 10) !== 0,
            isaccentureOwned: mappedConnector[0].IsAccentureOwned,
          });
          this.isConnectorMetricDataFound = mappedConnector[0].IsConnectorMetricDataFound;
          this.isSelectedMetricMappedToConnector = mappedConnector[0].IsConnector;
          this.selectedMetricConnectorLastUpdated = mappedConnector[0].ConnectorUpdatedTS;

          if (
            kpiData
            && (String(kpiData.ConnectorID) === String(undefined)
              || parseInt(kpiData.ConnectorID, 10) === 0
              || (kpiData.ConnectorNames == null || String(kpiData.ConnectorNames) === String(undefined)))
          ) {
            if (
              mappedConnector.length > 0
              && String(mappedConnector[0].ConnectorName) !== ''
              && mappedConnector[0].ConnectorName != null
            ) {
              mappedConnectorName = mappedConnector[0].ConnectorName;
              const mappedConnectID = mappedConnector[0].ConnectorID;

              kpiData.ConnectorNames = mappedConnectorName;
              kpiData.ConnectorID = mappedConnectID;
              if (diverseData) {
                const kpiData = diverseData;
                kpiData.ConnectorNames = mappedConnectorName != null && String(mappedConnectorName) !== String(undefined)
                  ? mappedConnectorName
                  : kpiData.ConnectorNames;
                kpiData.ConnectorID = mappedConnectID > 0 ? mappedConnectID : kpiData.ConnectorID;
                this.setState({
                  diverseData: kpiData,
                });
              }
            }
          }
        }

        if (mappedConnectorName != null) {
          valueConnectorEdit = true;
        }
      }

      if (editDiverse) {
        const kpiData = { ...this.props.editDiverse };
        const editDiverse = JSON.parse(JSON.stringify(this.props.editDiverse)); // Add this to avoid mutation
        const diverseEdit = editDiverse.filter((x) => String(x.ParentSOCategoryID) === '5')[0].kpiData;
        const diverseData = diverseEdit.filter((x) => String(x.KPIMappingID) === String(kpiID))[0];
        if (diverseData.CategoryDetails) {
          if (diverseData.CategoryDetails.length === 0) diverseData.IsMultiLevel = false;
        }

        const diversecat = diverseData.CategoryDetails && diverseData.CategoryDetails.length > 0
          ? diverseData.CategoryDetails[0]
          : 0;
        const CatTypeID = diversecat ? diversecat.OfferingMultiLevelCategoryID : 0;

        const CatType = diversecat ? diversecat.CategoryType : '';

        const CategoryNameData = CatType && String(CatType) !== ''
          ? allCategoryTypesAndNames.filter((eachValue) => String(eachValue.CategoryType) === String(CatType))
          : []; // FETCHING CATEGORY NAME

        const { CategoryDetails } = this.state;
        CategoryDetails.CategoryType = CatType;
        this.setState({
          ...this.state,
          CategoryNameData,
          CategoryDetails,
          BaselineMonthReadOnly: !diverseData.BaselineValue,
        });

        let LeadingAndSimilarCheck = false;
        const mappedConnector = mappingData.filter((x) => {
          let returnValue = false;
          if (String(x.KpiMappingID) === String(diverseData.KPIMappingID)) {
            returnValue = true;
            return x;
          }
          return returnValue;
        });
        if (parseInt(diverseData.ConnectorID, 10) === 0) {
          mappedConnectorName = mappedConnector[0].ConnectorName;
          diverseData.ConnectorNames = mappedConnectorName;
          const mappedConnectID = mappedConnector[0].ConnectorID;
          diverseData.ConnectorID = mappedConnectID;
        } else if (mappedConnector.length > 0 && mappedConnector[0].ConnectorName) {
          mappedConnectorName = mappedConnector[0].ConnectorName;
          diverseData.ConnectorNames = mappedConnectorName;
          const mappedConnectID = mappedConnector[0].ConnectorID;
          diverseData.ConnectorID = mappedConnectID;
          if (data) {
            const { kpiData } = data;
            kpiData.ConnectorNames = mappedConnectorName;
            kpiData.ConnectorID = mappedConnectID;
            this.setState({
              kpiData,
              KpiMetricConnectorID: parseInt(mappedConnector[0].KpiMetricConnectorID, 10) !== 0,
              isaccentureOwned: mappedConnector[0].IsAccentureOwned,
            });
          }
        } else {
          mappedConnectorName = kpiData.ConnectorNames;

          kpiData.ConnectorNames = mappedConnectorName;
        }

        if (
          diverseData.MetricDefination
          && diverseData.MetricDefination.length > 0
          && diverseData.MetricDefination.filter(
            (x) => String(x.KPIMetricDefinatoinID) === String(diverseData.MetricDefinitionID),
          ).length === 0
        ) {
          diverseData.MetricDefinitionID = 0;
        }

        if (diverseData && kpiMappingResponse.length) {
          kpiMappingResponse.forEach((each) => {
            if (
              each.KpiMappingID === diverseData.KPIMappingID
              && diverseData.metDataAttr
              && diverseData.metDataAttr.length
            ) {
              if (each.Attribute1Value) {
                diverseData.metDataAttr[0].AttributeValue = each.Attribute1Value;
                diverseData.metDataAttr[1].AttributeValue = each.Attribute2Value;
                LeadingAndSimilarCheck = true;
              }
            }
          });
        }

        this.setState({
          ...this.state,
          CatTypeID,
          iscatypedisable: true,
          diverseData,
          LeadingAndSimilarCheck,
          KpiMetricConnectorID: parseInt(mappedConnector[0].KpiMetricConnectorID) !== 0,
          isaccentureOwned: mappedConnector[0].IsAccentureOwned,
        });

        if (mappedConnectorName != null) {
          valueConnectorEdit = true;
        }
      }

      this.setState({
        ...this.state,
        MetricNameOptions: kpiMappingResponse, // response.data,
        valueConnectorEdit,
      });
    } else if (editDiverse) {
      const editDiverse = [...this.props.editDiverse];
      const diverseEdit = editDiverse.filter((x) => parseInt(x.ParentSOCategoryID) === '5')[0].kpiData;
      const diverseData = diverseEdit.filter((x) => String(x.KPIMappingID) === String(kpiID))[0];
      const valueExist = true;
      this.setState(
        {
          diverseData,
          errors: {},
          formIsValid: true,
          valueExist,
        },
        () => {
        },
      );
    }
  }

  handleValidation() {
    const diverseData = { ...this.state.diverseData };
    const errordescription = InvalidDescription();
    const errors = {};
    let formIsValid = true;
    let err = '';

    if (this.state.diverseData.Comments) {
      if (this.state.diverseData.Comments.length > 300) {
        err = i18n.t('commentsErrorM1');
        formIsValid = false;
      } else if (!isValidText(this.state.diverseData.Comments)) {
        err = errordescription;
        formIsValid = false;
      }
      this.setState({
        commentsError: err,
      });
    }
    if (this.state.diverseData.SourceFieldName) {
      if (!isValidText(this.state.diverseData.SourceFieldName)) {
        formIsValid = false;
        errors.SourceFieldName = errordescription;
      }
    }

    if (!diverseData.MetricName) {
      formIsValid = false;
      errors.MetricName = i18n.t('addWoErrorM1');
    }
    if (this.isSelectedMetricMappedToConnector && !this.isConnectorMetricDataFound) {
      formIsValid = false;
      errors.MetricConnectorDataNotFound = `${i18n.t('Data_not_available_in_source_system')}.`;
      errors.IntegratedMetricsMessage = `${i18n.t('Integrated_metrics_will_get_auto_populated')}.`;
      if (this.selectedMetricConnectorLastUpdated) {
        errors.MetricConnectorLastPull = `${i18n.t('Last_pull_occurred_on')} ${FormatDate(this.selectedMetricConnectorLastUpdated)}.`;
      } else {
        errors.MetricConnectorLastPull = `${i18n.t('Last_pull_occurred')} "${i18n.t('Date_Unavailable')}”.`;
      }
    }

    if (this.state.commentsError) {
      formIsValid = false;
    }

    if (!diverseData.MetricUnit) {
      formIsValid = false;
      errors.MetricUnit = i18n.t('Unit_is_required');
    }

    if (!diverseData.RAGStatus) {
      formIsValid = false;
      errors.RAGStatus = i18n.t('Status_is_Required');
    }

    if (!diverseData.ConnectorID) {
      formIsValid = false;
      errors.ConnectorID = i18n.t('Source_is_required');
    }
    if (!this.isSelectedMetricMappedToConnector) {
      if (!diverseData.MetricValue && diverseData.MetricValue !== 0) {
        formIsValid = false;
        errors.MetricValue = i18n.t('Actual_Value_is_Required');
      } else if (!(diverseData.MetricValue >= -999999999999 && diverseData.MetricValue <= 999999999999)) {
        formIsValid = false;
        errors.MetricValueRange = i18n.t('addBoErrorM6');
      }
    }

    if (!(diverseData.BaselineValue >= -99999999 && diverseData.BaselineValue <= 99999999)) {
      formIsValid = false;
      errors.BaselineValue = i18n.t('Baseline_validation');
    }

    if (!(diverseData.TargetValue >= -99999999 && diverseData.TargetValue <= 99999999)) {
      formIsValid = false;
      errors.TargetValue = i18n.t('target_validation');
    }

    // VALIDATE "METRIC DEFINITION" DROPDOWN FIELD
    if (diverseData.MetricDefination && diverseData.MetricDefination.length > 0 && !diverseData.MetricDefinitionID) {
      errors.MetricDefinitionID = 'Metric Definition is Required';
      formIsValid = false;
    }
    if (diverseData.IsMultiLevel) {
      if (diverseData.CategoryDetails.length === 0) {
        errors.MultiLevel = i18n.t('noCategories');
        formIsValid = false;
      }
    }
    this.setState({
      errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  onStatusChange(event) {
    const errors = {};
    this.setState({
      errors,
    });
    const diverseData = { ...this.state.diverseData };
    diverseData.RAGStatus = event.target.value;
    this.setState({
      diverseData,
    });
  }

  onToolOwnerChange(event) {
    const diverseData = { ...this.state.diverseData };
    diverseData.IsAccentureOwned = event.target.checked;
    this.setState({
      diverseData,
    });
  }

  onNameChange(event) {
    if (!event.target.value) {
      const errors = {};
      const diverseData = { ...this.state.diverseData };
      diverseData.KPIMappingID = event.target.value;
      diverseData.SourceName = '';
      diverseData.SourceFieldName = '';

      diverseData.KPIDesp = '';
      diverseData.MetricUnit = '';
      diverseData.MetricName = '';
      diverseData.MetricValue = '';
      diverseData.LeadingPractice = '';
      diverseData.IsMultiLevel = false;
      diverseData.IsAggregation = false;
      this.isConnectorMetricDataFound = true;
      this.isSelectedMetricMappedToConnector = false;
      this.selectedMetricConnectorLastUpdated = undefined;
      if (diverseData.metDataAttr && diverseData.metDataAttr.length > 0) {
        diverseData.metDataAttr[0].AttributeValue = '';
        diverseData.metDataAttr[1].AttributeValue = '';
      }
      // RESET THE METRIC DEFINITION DROPDOWN OPTIONS AND SELECTED METRIC DEFINITION
      diverseData.MetricDefination = [];
      diverseData.MetricDefinitionID = '';

      const kpiMetricDefinitions = _.get(this.state, 'diverseData.MetricDefination', []);
      const enabledMetricDefinitions = _.filter(
        kpiMetricDefinitions,
        (eachMetricDefinition) => String(eachMetricDefinition.IsEnabled) === 'true',
      );

      if (enabledMetricDefinitions.length === 0) {
        diverseData.MetricDefinitionID = -1;
      }

      this.setState({
        errors,
        diverseData,
      });
    } else {
      const errors = {};
      this.setState({
        errors,
      });
      const diverseData = { ...this.state.diverseData };
      diverseData.IsMultiLevel = false;
      diverseData.IsAggregation = false;
      diverseData.CategoryDetails = [];

      diverseData.KPIMappingID = event.target.value;
      diverseData.SourceName = '';
      diverseData.SourceFieldName = '';
      diverseData.MetricValue = ''; // CHANGED
      let LeadingAndSimilarCheck = false;
      const { MetricNameOptions } = this.state;
      if (diverseData.KPIMappingID !== '-1') {
        const selectedMetric = MetricNameOptions.filter((data) => String(data.KpiMappingID) === String(event.target.value))[0];
        this.isConnectorMetricDataFound = selectedMetric.IsConnectorMetricDataFound;
        this.isSelectedMetricMappedToConnector = selectedMetric.IsConnector;
        this.selectedMetricConnectorLastUpdated = selectedMetric.ConnectorUpdatedTS;
        diverseData.MetricValue = selectedMetric.IsConnector ? selectedMetric.MetricValue : '';
        diverseData.KPIDesp = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].MetricDescription;
        diverseData.MetricUnit = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].MetricUnit;
        diverseData.MetricName = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].MetricName;
        diverseData.LeadingPractice = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].LeadingPractice;
        diverseData.IndustryName = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].IndustryName; // Diverse Benchmark
        diverseData.IsGlobal = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].IsGlobal;
        diverseData.BaselineValue = this.state.MetricNameOptions.filter(
          (data) => data.KpiMappingID == event.target.value,
        )[0].BaselineValue;
        diverseData.BaseLineMonth = this.state.MetricNameOptions.filter(
          (data) => data.KpiMappingID == event.target.value,
        )[0].BaseLineMonth;
        const BaselineMonthReadOnly = !diverseData.BaselineValue;
        this.setState({ BaselineMonthReadOnly });
        diverseData.TargetValue = this.state.MetricNameOptions.filter(
          (data) => data.KpiMappingID == event.target.value,
        )[0].TargetValue;

        if (diverseData.metDataAttr && diverseData.metDataAttr.length > 0) {
          diverseData.metDataAttr[0].AttributeValue = MetricNameOptions.filter(
            (data) => String(data.KpiMappingID) === String(event.target.value),
          )[0].Attribute1Value;
          diverseData.metDataAttr[1].AttributeValue = MetricNameOptions.filter(
            (data) => String(data.KpiMappingID) === String(event.target.value),
          )[0].Attribute2Value;
          if (diverseData.metDataAttr[0].AttributeValue) {
            LeadingAndSimilarCheck = true;
          }
        }

        // SET KPI MULTIPLE DEFINITIONS
        diverseData.MetricDefination = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value),
        )[0].MetricDefination;

        const kpiMetricDefinitions = diverseData.MetricDefination;
        const enabledMetricDefinitions = _.filter(
          kpiMetricDefinitions,
          (eachMetricDefinition) => String(eachMetricDefinition.IsEnabled) === 'true',
        );

        // RESET THE SELECTED METRIC DEFINITION
        diverseData.MetricDefinitionID = '';

        if (enabledMetricDefinitions.length === 0) {
          diverseData.MetricDefinitionID = -1;
        }
      } else {
        this.setState({
          viewmore: true,
          show: false,
        });
      }
      let con = '';

      const metriName = MetricNameOptions.filter((eachValue) => String(eachValue.KpiMappingID) === String(event.target.value));
      let valueConnector = false;
      if (metriName.length > 0) {
        const connectorName = metriName[0].ConnectorName;
        const connectorId = metriName[0].ConnectorID;
        const criteria = metriName[0].Criteria;
        const { MetricValue } = metriName[0];
        const { IsAccentureOwned } = metriName[0];
        const { KpiMetricConnectorID } = metriName[0];

        if (connectorName == null) {
          con = false;
          diverseData.ConnectorNames = connectorName;
          diverseData.ConnectorID = connectorId;
          diverseData.SourceFieldName = criteria;
          diverseData.MetricValue = '';
          diverseData.IsAccentureOwned = IsAccentureOwned;
          diverseData.KpiMetricConnectorID = KpiMetricConnectorID;
        } else {
          con = true;
          diverseData.ConnectorNames = connectorName;
          diverseData.ConnectorID = connectorId;
          diverseData.SourceFieldName = criteria;
          diverseData.MetricValue = MetricValue;
          diverseData.IsAccentureOwned = IsAccentureOwned;
          diverseData.KpiMetricConnectorID = KpiMetricConnectorID;

          if (metriName[0].KpiMetricConnectorID !== 0) {
            valueConnector = true;
          }
        }
      }

      const connectorOptions = metriName.map((eachProcess) => (
        <option key={eachProcess.ConnectorID} value={eachProcess.ConnectorID}>
          {eachProcess.ConnectorName}
        </option>
      ));
      const criteriaOptions = metriName.map((eachProcess) => (
        <option key={eachProcess.Criteria} value={eachProcess.Criteria}>
          {eachProcess.Criteria}
        </option>
      ));

      this.setState({
        diverseData,
        con,
        connectorOptions,
        criteriaOptions,
        valueConnector,
        LeadingAndSimilarCheck,
        KpiMetricConnectorID: metriName.length ? (parseInt(metriName[0].KpiMetricConnectorID) !== 0) : false,
      });
    }
  }

  onDescriptionChange(event) {
    const errors = {};
    this.setState({
      errors,
    });
    const diverseData = { ...this.state.diverseData };
    diverseData.KPIDesp = event.target.value;
    this.setState({
      diverseData,
    });
  }

  onUnitChange(event) {
    const errors = {};
    this.setState({
      errors,
    });
    const diverseData = { ...this.state.diverseData };
    diverseData.MetricUnit = event.target.value;
    this.setState({
      diverseData,
    });
  }

  onActualChange(event) {
    const diverseData = { ...this.state.diverseData };
    if (event.target.value === '') {
      diverseData.MetricValue = event.target.value;
      this.setState({
        diverseData,
      });
    } else if (regexForNegativeNumbers.test(event.target.value)) {
      if (event.target.value === '-.') {
        diverseData.MetricValue = '-0.';
      } else {
        diverseData.MetricValue = event.target.value;
      }

      this.setState({
        diverseData,
      });
    }
  }

  onSimilarChange(event) {
    const diverseData = { ...this.state.diverseData };
    if (event.target.value === '') {
      diverseData.metDataAttr[1].AttributeValue = event.target.value;
      this.setState({
        diverseData,
      });
    } else if (regexForNumbersOnlyMTC.test(event.target.value)) {
      diverseData.metDataAttr[1].AttributeValue = event.target.value;
      this.setState({
        diverseData,
      });
    }
  }

  onLeadingChange(event) {
    const diverseData = { ...this.state.diverseData };
    if (event.target.value === '') {
      diverseData.metDataAttr[0].AttributeValue = event.target.value;
      this.setState({
        diverseData,
      });
    } else if (regexForNumbersOnlyMTC.test(event.target.value)) {
      diverseData.metDataAttr[0].AttributeValue = event.target.value;
      this.setState({
        diverseData,
      });
    }
  }

  onValueDescriptionChange(event) {
    const str = event.target.value;
    let err = this.state.commentsError;
    if (event.target.value) {
      if (regexForTextAreas.test(str)) {
        if (str && str.length < 300) {
          const diverseData = { ...this.state.diverseData };
          diverseData.Comments = event.target.value;
          err = '';
          this.setState({
            diverseData,
            commentsError: err,
          });
        } else {
          const diverseData = { ...this.state.diverseData };
          diverseData.Comments = event.target.value;
          err = i18n.t('commentsErrorM1');
          this.setState({
            commentsError: err,
            diverseData,
          });
        }
      } else {
        const diverseData = { ...this.state.diverseData };
        diverseData.Comments = event.target.value;
        err = i18n.t('commentsErrorM2');
        this.setState({
          diverseData,
          commentsError: err,
        });
      }
    } else {
      const diverseData = { ...this.state.diverseData };
      diverseData.Comments = event.target.value;
      err = '';
      this.setState({
        diverseData,
        commentsError: err,
      });
    }
  }

  onSourceChange(event) {
    const diverseData = { ...this.state.diverseData };
    diverseData.ConnectorID = event.target.value;
    const index = event.nativeEvent.target.selectedIndex;
    diverseData.ConnectorNames = event.nativeEvent.target[index].text;
    diverseData.SourceName = '';
    diverseData.SourceFieldName = '';
    this.setState({
      diverseData,
    });
  }

  onSourceSystemChange(event) {
    const diverseData = { ...this.state.diverseData };
    diverseData.SourceFieldName = event.target.value;
    this.setState({
      diverseData,
    });
  }

  onOtherSystemChange(event) {
    const diverseData = { ...this.state.diverseData };
    diverseData.SourceName = event.target.value;
    this.setState({
      diverseData,
    });
  }

  loadPopup = () => {
    setTimeout(() => {
      this.getKpiMAppingDetails();
    }, 100);
  }

  getCategoriesLimit = () => {
    const data = store.getState().CurrentFilter.multiLevelCategoriesLimit;
    this.setState({
      categoriesLimit: data,
    });
  }

  handleSave(e) {
    if (this.handleValidation()) {
      const diverseData = { ...this.state.diverseData };
      const { filterDetails } = this.props;
      diverseData.KPIMappingID = parseInt(diverseData.KPIMappingID, 10) === 0 ? Math.random() : diverseData.KPIMappingID;
      if (diverseData.BaseLineMonth != null && diverseData.BaseLineMonth !== '') {
        const baselinemonth = `${new Date(diverseData.BaseLineMonth).getFullYear()
        }-${
          new Date(diverseData.BaseLineMonth).getMonth() + 1 < 10
            ? `0${new Date(diverseData.BaseLineMonth).getMonth() + 1}`
            : new Date(diverseData.BaseLineMonth).getMonth() + 1}`;
        diverseData.BaseLineMonth = baselinemonth;
        this.setState({
          diverseData,
        });
      }
      diverseData.KPIMappingID = diverseData.KPIMappingID === 0 ? Math.random() : diverseData.KPIMappingID;
      const currentData = {
        ConfiguratorDataModels: { SOCategoryID: this.props.SOCategoryID, kpiData: diverseData },
        FiltersIDsVM: {
          OrgEntityID: filterDetails.Client,
          OfferingID: filterDetails.process,
          LocationID: filterDetails.Locations,
          MonthDT: filterDetails.Month,
        },
      };
      axiosInstance
        .post(`${LocalApiBaseUrl}Configurator/SaveConfiguratorDealMetrics`, currentData)
        .then((response) => {
          if (response.data.ConfiguratorDataModels.kpiData) {
            store.dispatch({
              type: 'ADD_DIVERSE',
              payload: response.data.ConfiguratorDataModels.kpiData,
            });
            const { filterDetails } = this.props;
            axiosInstance
              .get(`${LocalApiBaseUrl}Configurator/GetDiverseDataQualityScore`, {
                params: {
                  LocationID: filterDetails.Locations,
                  OrgEntityID: filterDetails.Client,
                  OfferingID: filterDetails.process,
                  MonthDT: filterDetails.Month,
                  LanguageID: store.getState().CurrentFilter.languageData.id,
                },
              })
              .then((response) => {
                if (response.data) {
                  store.dispatch({
                    type: 'SAVE_QC_SCORE_OF_EACH_TAB',
                    payload: response.data,
                  });
                }

                this.handleHide(e);
              })
              .catch((error) => {
                trycatchAlertPopup(error);
                this.handleHide(e);
              });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          window.alert(this.ssErrMsg + error);
          this.handleHide(e);
        });
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    const { onClose } = this.props;
    if (String(e) !== String(undefined)) {
      if (String(e.target.textContent) !== '×') {
        onClose(e);
      } else {
        onClose(e);
      }
    } else {
      onClose(e);
    }
  }

  handleRejectHide(e) {
    this.setState({ show: false });
    const { onRejectClose } = this.props;
    if (String(e) !== String(undefined)) {
      if (String(e.target.textContent) !== '×') {
        onRejectClose();
      }
    } else {
      onRejectClose();
    }
  }

  handleApproveHide(e) {
    const { onApproveClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {
      if (String(e.target.textContent) !== '×') {
        onApproveClose();
      }
    } else {
      onApproveClose();
    }
  }

  // WOULD BE EXECUTED WHEN THE "METRIC DEFINITION" DROPDOWN WOULD BE CHANGED
  handleChangeMetricDefinition = (e) => {
    const MetricDefinitionID = e.target.value;
    this.setState((prevState) => ({
      diverseData: {
        ...prevState.diverseData,
        MetricDefinitionID,
      },
    }));
  };

  // MULTILEVEL
  editCategoryName(item) {
    this.setState({
      showCategoryNameEditPopup: true,
    });
  }

  removeDuplicates(arr) {
    /* NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
    const map = new Map();
    arr.forEach((v) => map.set(v.CategoryType, v));
    return [...map.values()];
  }

  CatTypeSelect(e) {
    const CatTypeID = e.target.value;
    this.setState({ CatTypeID });

    setTimeout(() => {
      if (String(CatTypeID) === 'addnew') {
        CategoryDetails.CategoryType = '';
        CategoryDetails.CategoryName = '';
        this.setState({
          errors: {},
          showCategory: false,
          showaddCategoryType: true,
          CategoryDetails,
        });
      } else if (String(CatTypeID) === '0') {
        // CHANGE2
        CategoryDetails.CategoryType = '';
        CategoryDetails.CategoryName = '';
        this.setState({
          CatNameID: 0,
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true,
          CategoryDetails,
        });
      } else if (String(CatTypeID) === 'addnewcatname') {
        this.setState({
          showCategory: false,
          showaddCategory: true,
        });
      } else {
        this.setState({
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true,
        });
      }
    }, 500);
    const { allCategoryTypesAndNames } = this.state;
    const CatType = CatTypeID && String(CatTypeID) !== '0' && String(CatTypeID) !== 'addnew'
      ? allCategoryTypesAndNames.filter((eachValue) => String(eachValue.ID) === String(CatTypeID))[0].CategoryType
      : []; // FETCHING individual CATEGORY TYPE

    let { CategoryDetails } = this.state;
    CategoryDetails.CategoryType = CatType;
    this.setState({
      CategoryDetails,
    });

    const CategoryNameData = CatType && String(CatType) !== ''
      ? allCategoryTypesAndNames.filter((eachValue) => String(eachValue.CategoryType) === String(CatType))
      : []; // FETCHING CATEGORY NAME

    this.setState({ CategoryNameData });
  }

  handleDropdownChange(e) {
    const CatNameID = e.target.value;
    this.setState({ errors: {}, CatNameID });

    setTimeout(() => {
      if (String(CatNameID) === 'addnew') {
        this.setState({
          showCategory: false,
          showaddCategoryType: true,
        });
      } else if (String(CatNameID) === 'addnewcatname') {
        if (!this.state.CategoryDetails.CategoryType) {
          // category type is empty

          const error = {};
          error.CategoryType = i18n.t('catTypeReqd');

          this.setState({ errors: error, CatNameID: 0 });
        } else {
          CategoryDetails.CategoryName = '';
          this.setState({
            showCategory: false,
            showaddCategory: true,
            CategoryDetails,
          });
        }
      } else {
        this.setState({
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true,
        });
      }
    }, 500);
    const { allCategoryTypesAndNames } = this.state;
    const CatName = CatNameID && String(CatNameID) !== '0' && String(CatNameID) !== 'addnewcatname'
      ? allCategoryTypesAndNames.filter((eachValue) => String(eachValue.ID) === String(CatNameID))[0].CategoryName
      : [];

    let { CategoryDetails } = this.state;
    CategoryDetails.CategoryName = CatName;
    this.setState({
      CategoryDetails,
    });
  }

  // called when new category name is added -- on clicking on the add button
  handleaddCategoryAdd() {
    const data = this.state.CategoryDetails;
    const { filterDetails } = this.props;
    const param = {
      OfferingId: parseInt(this.props.filterDetails.process),
      CatType: data.CategoryType.toString().trim(),
      CatName: data.CategoryName.toString().trim(),
      LocationID: filterDetails.Locations,
      SOCategoryID: 5,
      OrgEntityID: filterDetails.Client,
    };
    const error = {};
    if (!data.CategoryType.trim()) {
      error.CategoryType = i18n.t('catTypeReqd');
    } else if (!isValidText(data.CategoryType.trim())) {
      error.CategoryType = InvalidDescription();
    }

    if (!data.CategoryName.trim()) {
      error.CategoryName = i18n.t('catNameReqd');
    } else if (!isValidText(data.CategoryName.trim())) {
      error.CategoryName = InvalidDescription();
    }

    this.setState({ errors: error });

    if (data.CategoryType.trim() && data.CategoryName.trim()) {
      axiosInstance
        .post(`${LocalApiBaseUrl}MultiLevelApproval/SendCategoryTypeNameForApproval`, param, {})
        .then((response) => {
          if (response.data.IsSuccess && String(response.data.StatusMessage) === 'Sent For Approval') {
            this.setState({
              showaddCategory: false,
              showaddCategoryType: false,
              showApproval: true,
            });
          } else {
            window.alert(response.data.StatusMessage);
            this.setState({ showLoaderOnPage: false });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          window.alert(this.ssErrMsg + error);
        });
    }
  }

  handleCategoryAdd() {
    const { diverseData } = this.state;
    const error = {};

    diverseData.CategoryDetails = diverseData.CategoryDetails === null ? [] : diverseData.CategoryDetails;
    const {
      numeratorVal, denomVal, CatTypeID, CatNameID, CategoryDetails, CategoryNameData, categoriesLimit, valueConnector, valueConnectorEdit,
    } = this.state;
    if (!numeratorVal) {
      error.NumeratorValue = i18n.t('numeratorIsReqd');
    } else if (!(parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999)) {
      error.NumeratorValueRange = i18n.t('addBoErrorM6');
    }
    if (diverseData.MetricUnit.trim() === '%') {
      if (!denomVal) {
        error.Denominator = i18n.t('denominatorIsReqd');
      } else if (parseFloat(denomVal) === 0) {
        error.Denominator = i18n.t('denomCannotZero');
      } else if (!(parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)) {
        error.DenominatorValueRange = i18n.t('addBoErrorM6');
      }
    } else if (!Number.isNaN(parseFloat(denomVal))) {
      if (parseFloat(denomVal) === 0) {
        error.Denominator = i18n.t('denomCannotZero');
      } else if (!(parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)) {
        error.DenominatorValueRange = i18n.t('addBoErrorM6');
      }
    }

    if (String(CatTypeID) === '0') {
      error.CategoryType = i18n.t('catTypeReqd');
    }

    if (String(CatNameID) === '0') {
      error.CategoryName = i18n.t('catNameReqd');
    }

    this.setState({ errors: error });

    if (
      diverseData.MetricUnit.trim() === '%'
        ? CategoryDetails.CategoryType
        && String(CatTypeID) !== '0'
        && CategoryDetails.CategoryName
        && String(CatNameID) !== '0'
        && numeratorVal
        && (parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999)
        && denomVal
        && parseFloat(denomVal) !== 0
        && (parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)
        : CategoryDetails.CategoryType
        && String(CatTypeID) !== '0'
        && CategoryDetails.CategoryName
        && String(CatNameID) !== '0'
        && numeratorVal
        && (parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999)
        && (!Number.isNaN(parseFloat(denomVal))
          ? parseFloat(denomVal) !== 0
          && parseFloat(denomVal) >= -999999999999
          && parseFloat(denomVal) <= 999999999999
          : true)
    ) {
      if (
        diverseData.CategoryDetails.filter((cat) => cat.CategoryName === CategoryDetails.CategoryName)
          .length === 0
      ) {
        const { CategoryName } = CategoryDetails;

        const Catid = CategoryName && CategoryName !== ''
          ? CategoryNameData.filter((cat) => cat.CategoryName === CategoryName)[0].ID
          : null;

        if (diverseData.CategoryDetails && diverseData.CategoryDetails.length < categoriesLimit) {
          diverseData.CategoryDetails.push({
            CategoryType: CategoryDetails.CategoryType,
            CategoryName: CategoryDetails.CategoryName,
            NumeratorValue: numeratorVal,
            Denominator: denomVal,
            ConfigurationDealMetricID: 8,
            OfferingMultiLevelCategoryID: Catid,
          });

          this.setState({ errors: error });
          this.setState({
            showCategory: false,
            diverseData,
          });
        } else {
          const error = {};
          error.CategoryName = `${i18n.t('A_maximum_of')} ${categoriesLimit} ${i18n.t('category_values_are')}`;

          this.setState({ showCategory: true, errors: error });
        }

        if (diverseData.IsAggregation && !valueConnectorEdit && !valueConnector) {
          // calculate the metric value on the fly when new values added/ edited/deleted
          this.calculateAggregateValueByFormula();
        }
      } else {
        const error = {};
        error.CategoryName = i18n.t('duplicateCatName');

        this.setState({ errors: error });
      }
    } else {
      this.setState({
        showCategory: true,
      });
    }
  }

  handleCategoryEdit() {
    const error = {};

    const { diverseData } = this.state;
    const {
      EditIndex, numeratorVal, denomVal, CatTypeID, CatNameID, CategoryDetails, CategoryNameData, valueConnector, valueConnectorEdit,
    } = this.state;
    if (!numeratorVal) {
      error.NumeratorValue = i18n.t('numeratorIsReqd');
    } else if (!(parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999)) {
      error.NumeratorValueRange = i18n.t('addBoErrorM6');
    }
    if (diverseData.MetricUnit.trim() === '%') {
      if (!denomVal) {
        error.Denominator = i18n.t('denominatorIsReqd');
      } else if (parseFloat(denomVal) === 0) {
        error.Denominator = i18n.t('denomCannotZero');
      } else if (!(parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)) {
        error.DenominatorValueRange = i18n.t('addBoErrorM6');
      }
    } else if (!Number.isNaN(parseFloat(denomVal))) {
      if (parseFloat(denomVal) === 0) {
        error.Denominator = i18n.t('denomCannotZero');
      } else if (!(parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)) {
        error.DenominatorValueRange = i18n.t('addBoErrorM6');
      }
    }
    if (!CategoryDetails.CategoryType) {
      error.CategoryType = i18n.t('catTypeReqd');
    }

    if (CatNameID === '0') {
      error.CategoryName = i18n.t('catNameReqd');
    }
    this.setState({ errors: error });

    if (
      diverseData.MetricUnit.trim() === '%'
        ? CategoryDetails.CategoryType
        && String(CatTypeID) !== '0'
        && CategoryDetails.CategoryName
        && String(CatNameID) !== '0'
        && numeratorVal
        && (parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999)
        && denomVal
        && parseFloat(denomVal) !== 0
        && (parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)
        : CategoryDetails.CategoryType
        && String(CatTypeID) !== '0'
        && CategoryDetails.CategoryName
        && String(CatNameID) !== '0'
        && numeratorVal
        && (parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999)
        && (!Number.isNaN(parseFloat(denomVal))
          ? parseFloat(denomVal) !== 0
          && parseFloat(denomVal) >= -999999999999
          && parseFloat(denomVal) <= 999999999999
          : true)
    ) {
      const index = EditIndex;
      if (
        diverseData.CategoryDetails.filter((cat) => cat.CategoryName === CategoryDetails.CategoryName)
          .length === 0
      ) {
        diverseData.CategoryDetails[index].CategoryName = CategoryDetails.CategoryName;
        diverseData.CategoryDetails[index].NumeratorValue = numeratorVal;
        diverseData.CategoryDetails[index].Denominator = denomVal;
        diverseData.CategoryDetails[index].ConfigurationDealMetricID = 8;
        const Catid = diverseData.CategoryDetails[index].CategoryName && diverseData.CategoryDetails[index].CategoryName !== ''
          ? CategoryNameData.filter(
            (cat) => cat.CategoryName === diverseData.CategoryDetails[index].CategoryName,
          )[0].ID
          : null;
        diverseData.CategoryDetails[index].OfferingMultiLevelCategoryID = Catid;
        this.setState({
          diverseData,
        });
        this.setState({
          showCategory: false,
        });
      } else if (
        diverseData.CategoryDetails.filter(
          (cat) => cat.CategoryName === diverseData.CategoryDetails[index].CategoryName,
        ).length === 1
      ) {
        if (
          diverseData.CategoryDetails[index].CategoryName.trim() === CategoryDetails.CategoryName.trim()
            ? false
            : diverseData.CategoryDetails.filter((cat) => cat.CategoryName === CategoryDetails.CategoryName)
              .length === 1
        ) {
          const error = {};
          error.CategoryName = i18n.t('duplicateCatName');

          this.setState({ errors: error });
        } else {
          diverseData.CategoryDetails[index].CategoryName = diverseData.CategoryDetails[index].CategoryName;
          diverseData.CategoryDetails[index].NumeratorValue = numeratorVal;
          diverseData.CategoryDetails[index].Denominator = denomVal;
          diverseData.CategoryDetails[index].ConfigurationDealMetricID = 8;
          diverseData.CategoryDetails[index].OfferingMultiLevelCategoryID = diverseData.CategoryDetails[index].OfferingMultiLevelCategoryID;

          this.setState({
            diverseData,
          });
          if (diverseData.IsAggregation && !valueConnectorEdit && !valueConnector) {
            // calculate the metric value on the fly when new values added/ edited/deleted
            this.calculateAggregateValueByFormula();
          }
          this.setState({
            showCategory: false,
          });
        }
      } else {
        this.setState({
          showCategory: false,
        });
      }
    }
  }

  handleCategoryTypeAddShow() {
    this.setState({ errors: {} });
    const { CatTypeID } = this.state;
    const { CategoryDetails } = this.state;
    CategoryDetails.CategoryName = '';
    this.setState({
      CategoryDetails,
      showCategory: true,
      isEdit: false,
      iscatypedisable: true,
      CatNameID: 0,
      CatTypeID,
      numeratorVal: '',
      denomVal: '',
    });
  }

  deleteCategoryType() {
    if (window.confirm(i18n.t('delete_Category_msg'))) {
      const { diverseData } = this.state;
      diverseData.CategoryDetails = [];
      if (diverseData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector) {
      // if there are no categories, clear out the value calculated using the formula
        diverseData.MetricValue = '';
      }

      this.setState({
        CatNameID: 0,
        CatTypeID: 0,
        CategoryNameData: [],
        diverseData,
      });
    }
  }

  handleCategoryEditShow(catNameToEdit) {
    this.setState({ errors: {} });
    const { diverseData, CategoryNameData, CatTypeID } = this.state;
    const allCategoriesList = diverseData.CategoryDetails;
    const index = allCategoriesList.findIndex((item) => item.CategoryName === catNameToEdit);
    const catnameid = CategoryNameData.filter((row) => row.CategoryName === catNameToEdit)[0].ID;
    const { CategoryName } = CategoryNameData.filter((row) => row.ID === catnameid)[0];
    const { CategoryDetails } = this.state;
    CategoryDetails.CategoryName = CategoryName;
    const numeratorVal = allCategoriesList[index].NumeratorValue;
    const denomVal = allCategoriesList[index].Denominator;
    this.setState({
      EditIndex: index,
      isEdit: true,
      showCategory: true,
      CatNameID: catnameid,
      CategoryDetails,
      CatTypeID,
      numeratorVal,
      denomVal,
    });
  }

  handleaddCategoryNameClose() {
    this.setState({
      showaddCategory: false,
      errors: {},
    });
  }

  handleaddCategoryClose() {
    this.setState({
      showaddCategoryType: false,
      errors: {},
    });
  }

  handleApprovalClose() {
    this.setState({ showApproval: false });
  }

  handleCategoryClose() {
    this.setState({
      showCategory: false,
      errors: {},
    });
  }

  handleaddCategoryShow() {
    this.setState({ showaddCategory: true });
  }

  onNumeratorChange = (e) => {
    const { errors } = this.state;
    errors.NumeratorValue = '';
    this.setState({ errors });
    if (e.target.value.length <= 12) {
      this.setState({ numeratorVal: e.target.value });
    }
  };

  onDenomChange = (e) => {
    const { errors } = this.state;
    errors.Denominator = '';
    this.setState({ errors });
    if (e.target.value.length <= 12) {
      this.setState({ denomVal: e.target.value });
    }
  };

  deleteCategoryName(catNameToDelete) {
    this.setState({ errors: {} });
    const { CatTypeID } = this.state;
    const { diverseData } = this.state;
    const allCategoriesList = this.state.diverseData.CategoryDetails;
    const { CategoryNameData } = this.state;
    allCategoriesList.splice(allCategoriesList.findIndex((item) => item.CategoryName === catNameToDelete), 1);
    if (allCategoriesList.length === 0) {
      diverseData.MetricValue = '';
    }

    this.setState({
      CatNameID: 0,
      CategoryNameData: allCategoriesList.length === 0 ? [] : CategoryNameData,
      CatTypeID, // CHANGED
      diverseData,
    });
    if (diverseData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector) {
      // calculate the metric value on the fly when new values added/ edited/deleted
      this.calculateAggregateValueByFormula();
    }
  }

  handleCategoryShow() {
    this.setState({ errors: {} });
    const { CategoryDetails } = this.state;
    CategoryDetails.CategoryType = '';
    CategoryDetails.CategoryName = '';
    this.setState({
      showCategory: true,
      iscatypedisable: false,
      CategoryDetails,
      isEdit: false,
      CategoryNameData: [],
      CatNameID: 0,
      CatTypeID: 0,
      numeratorVal: '',
      denomVal: '',
    });
  }

  calculateAggregateValueByFormula = () => {
    const { FormulaList } = this.state;
    const { diverseData } = this.state;
    const metricUnit = diverseData.MetricUnit;

    let temp = 0;
    let formulaStr = '';
    if (!FormulaList) FormulaList = [];
    if (metricUnit.trim() === '%') {
      if (FormulaList.length > 0) {
        formulaStr = FormulaList.filter((z) => z.IsPercentageUnit === true)[0].Formula;
      }
    } else {
      if (FormulaList.length > 0) {
        formulaStr = FormulaList.filter((z) => z.IsPercentageUnit === false)[0].Formula;
      }
      diverseData.formulaStr = formulaStr;
      this.setState({
        diverseData,
      });
    }
    if (diverseData.CategoryDetails && diverseData.CategoryDetails.length > 0) {
      let total = 0;
      let numeratorSum = 0;
      let denominatorSum = 0;
      switch (formulaStr.toUpperCase().trim()) {
        case 'SUM':
          diverseData.CategoryDetails.forEach((arrayItem) => {
            if (!arrayItem.Denominator) {
              temp = 1;
            } else {
              temp = arrayItem.Denominator;
            }
            total += arrayItem.NumeratorValue / temp;
          });

          break;
        case 'AVERAGE':
          diverseData.CategoryDetails.forEach((arrayItem) => {
            if (!arrayItem.Denominator) {
              temp = 1;
            } else {
              temp = arrayItem.Denominator;
            }
            total += arrayItem.NumeratorValue / temp;
          });

          total /= diverseData.CategoryDetails.length;
          break;
        case 'WEIGHTED AVERAGE':
          diverseData.CategoryDetails.forEach((arrayItem) => {
            if (!arrayItem.Denominator) {
              arrayItem.Denominator = 100;
            }
            numeratorSum += parseFloat(arrayItem.NumeratorValue);
            denominatorSum += parseFloat(arrayItem.Denominator);
          });

          total = (numeratorSum / denominatorSum) * 100;
          break;
        default:
          trycatchAlertPopup('Formula not supported currently');
      }
      if (formulaStr) {
        diverseData.MetricValue = total;
      }

      this.setState({
        diverseData,
      });
    }
  };

  bindAutoAggregationChange = (e) => {
    const { diverseData } = this.state;

    diverseData.IsAggregation = e.target.checked;

    this.setState({
      diverseData,
    });
    if (diverseData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector) {
      this.calculateAggregateValueByFormula();
    }
  };

  bindToolFieldChange = (e) => {
    const { id } = e.target;
    const data = this.state.CategoryDetails;
    switch (id) {
      case 'categoryType':
        data.CategoryType = e.target.value;
        break;
      case 'categoryName':
        data.CategoryName = e.target.value;
        break;
      default:
        break;
    }

    this.setState({
      CategoryDetails: data,
    });
  };

  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  handleClick() {
    const { diverseData } = this.state;
    diverseData.IsMultiLevel = !diverseData.IsMultiLevel;
    if (!diverseData.IsMultiLevel) {
      diverseData.IsAggregation = false;
    }

    this.setState({
      diverseData,
    });
  }

  render() {
    const currentDate = new Date();
    // list of all category types and names for this offering - to be displayed in add/edit popup

    const stateKpiData = this.state.diverseData;

    let items = [];
    if (stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0) {
      items = stateKpiData.CategoryDetails.map((item, key) => (
        <li>
          <span className="contentName">{item.CategoryName}</span>
          {stateKpiData.MetricUnit.trim() !== '%' && (
            <span className="contentValues">
              <span>
                {parseInt(thousandSeparatorFormat(item.NumeratorValue) % 1, 10) !== 0
                  ? MetricValueThousandFormat(stateKpiData.MetricUnit, item.NumeratorValue, 2)
                  : MetricValueThousandFormat(stateKpiData.MetricUnit, item.NumeratorValue, 2)}
              </span>
              {item.Denominator && <span> | </span>}
              <span>
                {item.Denominator
                  ? parseInt(thousandSeparatorFormat(item.Denominator) % 1, 10) !== 0
                    ? MetricValueThousandFormat(stateKpiData.MetricUnit, item.Denominator, 2)
                    : MetricValueThousandFormat(stateKpiData.MetricUnit, item.Denominator, 2)
                  : ''}
              </span>
              {' '}
            </span>
          )}

          {stateKpiData.MetricUnit.trim() === '%' && (
            <span className="contentValues">
              <span>
                {parseInt(thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator) % 1, 10) !== 0
                  ? thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator), 2)
                  : thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator), 2)}
              </span>
              {' '}
              {stateKpiData.MetricUnit}
            </span>
          )}

          <div className="item-list-hover">
            <span>
              <i onClick={() => this.deleteCategoryName(item.CategoryName)} className="far fa-times" role="button" tabIndex="0">
                {' '}
              </i>
              <i onClick={() => this.handleCategoryEditShow(item.CategoryName)} className="fas fa-edit" role="presentation" />
            </span>
          </div>
        </li>
      ));
    }
    let isScreenInEditMode = false;
    if (this.props.editDiverse != null) {
      if (!_.isEmpty(this.props.editDiverse[1])) {
        isScreenInEditMode = true;
      }
    }

    // FILTER OUT THE METRIC DEFINITIONS THAT ARE "ENABLED"
    const kpiMetricDefinitions = _.get(this.state, 'diverseData.MetricDefination', []);
    const enabledMetricDefinitions = _.filter(
      kpiMetricDefinitions,
      (eachMetricDefinition) => String(eachMetricDefinition.IsEnabled) === 'true',
    );
    const attributeLabelsForBO = this.props.attributes.filter((z) => z.ApplicableForDD === true);
    const {
      show, diverseData, showaddCategory, MetricNameOptions, errors, KpiMetricConnectorID, BaselineMonthReadOnly, commentsError, con, connectorOptions, showCategory, isEdit, iscatypedisable, CatTypeID, allCategoryTypesAndNames, CatNameID, CategoryNameData, numeratorVal, denomVal, showaddCategoryType, CategoryDetails, showApproval, viewmore,
    } = this.state;
    const {
      SOCategoryName, filtername, sourceOptions, edit, SOCategoryID, filterDetails,
    } = this.props;
    return (
      <>
        {/* <Header /> */}

        <Modal
          show={show}
          onHide={this.handleHide}
          className="modal fade bo-configurator-modal enhance"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">
              {i18n.t('ddPopupM1')}
              {' '}
              -
              {' '}
              {SOCategoryName}
              {/* If this heading needs to be changed to Japanese, update the uiliterlsmaster record for ddPopupM1 later */}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-7">
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                          {i18n.t('Name')}
                          {' '}
                          <span className="required">*</span>
                        </label>
                        <select
                          id="name"
                          disabled={isScreenInEditMode}
                          className="form-control"
                          ref="MetricNameDropdown"
                          onChange={this.onNameChange}
                          value={diverseData.KPIMappingID}
                        >
                          <option value="">
                            {' '}
                            {i18n.t('Select_Metric_Name')}
                            {' '}
                          </option>
                          {MetricNameOptions.map((row) => <option value={row.KpiMappingID}>{row.MetricName}</option>)}
                          <option value="-1">
                            &lt;
                            {i18n.t('Add_New')}
                            &gt;
                          </option>
                        </select>
                        <div className="errorMsg">{errors.MetricName}</div>
                      </div>

                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                          {i18n.t('Unit_of_Measure')}
                          {' '}
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          maxLength="50"
                          id=""
                          value={diverseData.MetricUnit}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label htmlFor="">
                          {i18n.t('Actual')}
                          {' '}
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          readOnly={stateKpiData.IsAggregation}
                          className="form-control"
                          maxLength="12"
                          id=""
                          onChange={this.onActualChange}
                          value={diverseData.MetricValue}
                          disabled={KpiMetricConnectorID}
                        />
                        <div className="errorMsg">{errors.MetricValue}</div>
                        <div className="errorMsg">
                          {errors.MetricConnectorDataNotFound}
                          {errors.MetricConnectorLastPull ? (
                            <div>
                              {' '}
                              {errors.MetricConnectorLastPull}
                              {' '}
                            </div>
                          ) : (
                            ''
                          )}
                          {errors.MetricConnectorDataNotFound ? (
                            <div>{errors.IntegratedMetricsMessage}</div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="errorMsg">{errors.MetricValueRange}</div>
                      </div>
                    </div>
                    {String(diverseData.KPIMappingID) !== ''
                      && (diverseData.IsGlobal
                        || (diverseData.IndustryName !== ''
                          && String(diverseData.IndustryName) !== String(undefined)
                          && diverseData.IndustryName != null)) && (
                          <div className="row">
                            {' '}
                            {/* new code */}
                            <div className="form-group col-lg-6">
                              <label htmlFor="">{i18n.t('Industry')}</label>
                              <input
                                type="text"
                                className="form-control"
                                maxLength="50"
                                id=""
                                disabled
                                value={diverseData.IndustryName}
                              />
                            </div>
                          </div>
                    )}

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                          {attributeLabelsForBO.length > 0 ? attributeLabelsForBO[0].AttributeName1 : ''}
                          {' '}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          maxLength="50"
                          id=""
                          onChange={this.onLeadingChange}
                          disabled
                          value={
                            diverseData.metDataAttr && diverseData.metDataAttr[0].AttributeValue
                          }
                        />
                        <div className="errorMsg">{errors.Leading}</div>
                        <div className="errorMsg">{errors.LeadingValueRange}</div>
                      </div>
                      <div className="form-group col-lg-6">
                        {filtername === 'Health & Public Services' ? (
                          <label htmlFor="">
                            {i18n.t('External_Benchmark')}
                            <span className="required">*</span>
                          </label>
                        ) : (
                          <label htmlFor="">
                            {attributeLabelsForBO.length > 0 ? attributeLabelsForBO[0].AttributeName2 : ''}
                            {' '}
                          </label>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          maxLength="50"
                          id=""
                          onChange={this.onSimilarChange}
                          disabled
                          value={
                            diverseData.metDataAttr && diverseData.metDataAttr[1].AttributeValue
                          }
                        />
                        <div className="errorMsg">{errors.Similar}</div>
                        <div className="errorMsg">{errors.SimilarValueRange}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label htmlFor="">{i18n.t('Description')}</label>
                        <input
                          type="text"
                          maxLength="255"
                          className="form-control"
                          id=""
                          value={diverseData.KPIDesp}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label>
                          {i18n.t('Metric_Definition')}
                          {' '}
                          <span className="required">*</span>
                          {' '}
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{i18n.t('addBOMess3')}</Tooltip>}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </OverlayTrigger>
                        </label>
                        {enabledMetricDefinitions.length > 0 ? (
                          <select
                            value={diverseData.MetricDefinitionID}
                            onChange={(e) => this.handleChangeMetricDefinition(e)}
                            className="form-control"
                          >
                            <option value="">
                              {i18n.t('Select_Metric_Definition')}
                              {' '}
                            </option>
                            {_.map(enabledMetricDefinitions, (eachMetricDefinition) => (
                              <option
                                value={eachMetricDefinition.KPIMetricDefinatoinID}
                                key={eachMetricDefinition.KPIMetricDefinatoinID}
                              >
                                {_.isEmpty(eachMetricDefinition.DenominatorDescription)
                                  ? eachMetricDefinition.NumeratorDescription
                                  : `${eachMetricDefinition.NumeratorDescription} / ${eachMetricDefinition.DenominatorDescription}`}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            value={i18n.t('Definition_Not_Available')}
                            disabled
                          />
                        )}
                        <div className="errorMsg">{errors.MetricDefinitionID}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t('Leading_Practice')}
                          {' '}
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{i18n.t('addBOMess2')}</Tooltip>}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </OverlayTrigger>
                        </label>
                        <input
                          type="text"
                          disabled
                          maxLength="50"
                          className="form-control"
                          value={diverseData.LeadingPractice}
                        />
                        <div className="errorMsg">{errors.LeadingPractice}</div>
                      </div>

                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                          {i18n.t('Status')}
                          {' '}
                          <span className="required">*</span>
                        </label>
                        <select
                          className="form-control"
                          value={diverseData.RAGStatus}
                          onChange={this.onStatusChange}
                        >
                          <option value="">
                            --
                            {i18n.t('Select')}
                            --
                          </option>
                          <option value="Grey">{i18n.t('Grey')}</option>
                          <option value="Green">{i18n.t('Green')}</option>
                          <option value="Red">{i18n.t('Red')}</option>
                          <option value="Amber">{i18n.t('Amber')}</option>
                        </select>
                        <div className="errorMsg">{errors.RAGStatus}</div>
                      </div>
                      <div className="form-group col-lg-4">
                        <label>{i18n.t('Baseline_Value')}</label>
                        <input
                          type="text"
                          maxLength="12"
                          onInput={this.maxLengthCheckThreshold}
                          className="form-control"
                          onChange={this.onBaselineValueChange}
                          value={diverseData.BaselineValue}
                        />
                        <span className="errorMsg">
                          {errors.BaselineValue}
                        </span>
                      </div>

                      <div className="form-group col-lg-4">
                        <label htmlFor="">{i18n.t('Baseline_Month')}</label>
                        <div className="datepicker-label-wrapper">
                          <DatePicker
                            className="form-control"
                            id="BaseLineMonth"
                            maxDate={currentDate}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            selected={
                              Date.parse(this.state.diverseData.BaseLineMonth)
                                ? new Date(Date.parse(this.state.diverseData.BaseLineMonth))
                                : null
                            }
                            onSelect={(date) => {
                              this.onBaseLineMonthChange(date);
                            }}
                            readOnly={this.state.BaselineMonthReadOnly}
                          />
                        </div>
                      </div>

                      <div className="form-group col-lg-4">
                        <label>{i18n.t('Target_Value')}</label>
                        <input
                          type="text"
                          maxLength="12"
                          onInput={this.maxLengthCheckThreshold}
                          className="form-control"
                          onChange={this.onTargetValueChange}
                          value={diverseData.TargetValue}
                        />
                        <span className="errorMsg">
                          {errors.TargetValue}
                        </span>
                      </div>
                      <div className="form-group col-lg-12">
                        <label htmlFor="">{i18n.t('Comments')}</label>
                        <textarea
                          rows="4"
                          maxLength="300"
                          name="comment"
                          className="form-control"
                          onChange={this.onValueDescriptionChange}
                          value={diverseData.Comments}
                        />
                        <span className="errorMsg">{commentsError}</span>
                      </div>
                    </div>

                    <div className="row border-box">
                      <div className="form-group col-lg-4">
                        <label htmlFor="" className="source-system-padding">
                          {i18n.t('Source_System')}
                          {' '}
                          <span className="required ">*</span>
                        </label>
                        {con ? (
                          <select
                            className="form-control"
                            disabled
                            onChange={this.onSourceChange}
                            value={diverseData.ConnectorID}
                          >
                            {connectorOptions}
                          </select>
                        ) : KpiMetricConnectorID ? (
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={diverseData.ConnectorNames}
                          />
                        ) : (
                          <select
                            className="form-control"
                            onChange={this.onSourceChange}
                            value={diverseData.ConnectorID}
                            disabled={KpiMetricConnectorID}
                          >
                            <option value="" />
                            {sourceOptions}
                            <option value="-1">{i18n.t('Others')}</option>
                            <option value="-2">{i18n.t('Manual')}</option>
                          </select>
                        )}
                        <div className="errorMsg">{errors.ConnectorID}</div>
                      </div>
                      <div className="form-group col-lg-4">
                        <label htmlFor="">
                          {i18n.t('Source_System_Fields')}
                          {' '}
                          <span className="required" />
                        </label>
                        {con || String(diverseData.ConnectorNames) === 'Manual' ? (
                          <input
                            onChange={this.onSourceSystemChange}
                            type="text"
                            maxLength="50"
                            className="form-control"
                            id=""
                            readOnly
                            value={diverseData.SourceFieldName}
                          />
                        ) : KpiMetricConnectorID ? (
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={diverseData.ConnectorNames}
                          />
                        ) : (
                          <input
                            onChange={this.onSourceSystemChange}
                            type="text"
                            maxLength="50"
                            className="form-control"
                            id=""
                            value={diverseData.SourceFieldName}
                          />
                        )}
                        <div className="errorMsg">{errors.SourceFieldName}</div>
                      </div>
                      <div className="form-group col-lg-4">
                        <label htmlFor="">
                          {' '}
                          {i18n.t('Other_Source_System')}
                        </label>
                        {String(diverseData.ConnectorNames) === 'Others' ? (
                          <input
                            onChange={this.onOtherSystemChange}
                            type="text"
                            maxLength="50"
                            className="form-control"
                            id=""
                            value={diverseData.SourceName}
                          />
                        ) : (
                          <input
                            onChange={this.onOtherSystemChange}
                            type="text"

                            readOnly
                            maxLength="50"
                            className="form-control"
                            id=""
                            value={diverseData.SourceName}
                          />
                        )}
                        <div className="errorMsg">{errors.KPIDesp}</div>
                      </div>
                      <div className="form-group col-lg-12 mt-2">
                        <label className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="toolowner"
                            defaultChecked={diverseData.IsAccentureOwned}
                            onChange={this.onToolOwnerChange}
                            checked={diverseData.IsAccentureOwned}
                          />
                          <span className="checkmark" />
                          <span className="form-check-label">{i18n.t('Accenture_Owned')}</span>
                        </label>
                        <small className="form-text text-muted">
                          <b>
                            {i18n.t('Note')}
                            :
                          </b>
                          {i18n.t('NoteMessage1')}
                        </small>
                      </div>
                    </div>
                  </div>
                  {/* start of multi level UI */}
                  <div className="form-group col-lg-5">
                    <div className="right-wrapper">
                      <div className="catergory-metric-sub">
                        <div className="sub-level">
                          <i className="fas fa-sitemap" />
                          {i18n.t('Category_Metric_Sub_Levels')}
                        </div>
                        <div className="toggle-switch">
                          <Form>
                            <Form.Check
                              onClick={this.handleClick}
                              disabled={!stateKpiData.MetricName}
                              checked={stateKpiData.IsMultiLevel}
                              type="switch"
                              id="custom-switch"
                              label=""
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="metric-sub-info">
                        <div className={stateKpiData.IsMultiLevel ? 'disable-sub-level-none' : 'disable-sub-level'}>
                          <i className="fas fa-sitemap" />
                          <p>{i18n.t('Enable_to_add_metric_categories_as_its_sub_level')}</p>
                        </div>
                        <div className={stateKpiData.IsMultiLevel ? 'enable-sub-level' : 'enable-sub-level-none'}>
                          <div className="check-box-info">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={stateKpiData.IsAggregation}
                                onChange={(e) => {
                                  this.bindAutoAggregationChange(e);
                                }}
                                id="customCheck"
                                name="example1"
                              />
                              <label className="custom-control-label" htmlFor="customCheck">
                                {i18n.t('Aggregate_category_values_to_metric_value')}
                              </label>
                            </div>
                          </div>
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={showCategory}
                            onHide={this.handleCategoryClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {isEdit ? i18n.t('Edit_Category_Details') : i18n.t('Add_Category_Details')}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                {!iscatypedisable ? (
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {' '}
                                      {i18n.t('Category_Type')}
                                      <span className="required"> *</span>
                                    </label>
                                    <select
                                      id="name"
                                      className="form-control"
                                      disabled={isEdit}
                                      onChange={this.CatTypeSelect.bind(this)}
                                      value={CatTypeID}
                                    >
                                      <option value="0">{i18n.t('Select_CategoryType')}</option>
                                      {this.removeDuplicates(allCategoryTypesAndNames).map((CT) => (
                                        <option value={CT.ID} key={CT.ID}>
                                          {CT.CategoryType}
                                        </option>
                                      ))}
                                      <option value="addnew">{i18n.t('Add_New')}</option>
                                    </select>
                                    <div className="errorMsg">{errors.CategoryType}</div>
                                  </div>
                                ) : (
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {i18n.t('Category_Type')}
                                      <span className="required"> *</span>
                                    </label>
                                    <select
                                      id="name"
                                      className="form-control"
                                      disabled={iscatypedisable}
                                      onChange={this.CatTypeSelect.bind(this)}
                                      value={CatTypeID}
                                    >
                                      <option value="0">{i18n.t('Select_CategoryType')}</option>
                                      {allCategoryTypesAndNames.map((CT) => <option value={CT.ID}>{CT.CategoryType}</option>)}
                                      <option value="addnew">{i18n.t('Add_New')}</option>
                                    </select>

                                    <div className="errorMsg">{errors.CategoryType}</div>
                                  </div>
                                )}
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t('Category_Name')}
                                    <span className="required"> *</span>
                                  </label>
                                  <select
                                    id="name"
                                    value={CatNameID}
                                    onChange={this.handleDropdownChange}
                                    className="form-control"
                                  >
                                    <option value="0">{i18n.t('Select_CategoryName')}</option>
                                    {CategoryNameData.map((CN) => <option value={CN.ID}>{CN.CategoryName}</option>)}
                                    <option value="addnewcatname">{i18n.t('Add_New')}</option>
                                  </select>
                                  <div className="errorMsg">{errors.CategoryName}</div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-lg-6">
                                  <label>
                                    {i18n.t('Numerator_Value')}
                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    type="number"
                                    maxLength="12"
                                    value={numeratorVal}
                                    onChange={this.onNumeratorChange}
                                    className="form-control"
                                  />
                                  <span className="errorMsg">{errors.NumeratorValue}</span>
                                  <span className="errorMsg">{errors.NumeratorValueRange}</span>
                                </div>
                                <div className="form-group col-lg-6">
                                  <label>
                                    {i18n.t('Denominator_Value')}
                                    {stateKpiData.MetricUnit && stateKpiData.MetricUnit.trim() === '%' && (
                                      <span className="required"> *</span>
                                    )}
                                  </label>
                                  <input
                                    type="number"
                                    maxLength="12"
                                    value={denomVal}
                                    onChange={this.onDenomChange}
                                    className="form-control"
                                  />
                                  <span className="errorMsg">{errors.Denominator}</span>
                                  <span className="errorMsg">{errors.DenominatorValueRange}</span>
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button className="btn btn-default btn-sm mr-2" onClick={this.handleCategoryClose}>
                                {i18n.t('Cancel')}
                              </button>
                              {isEdit ? (
                                <button className="btn btn-primary" onClick={this.handleCategoryEdit}>
                                  {i18n.t('Update')}
                                </button>
                              ) : (
                                <button className="btn btn-primary" onClick={this.handleCategoryAdd}>
                                  {i18n.t('Add')}
                                </button>
                              )}
                            </Modal.Footer>
                          </Modal>

                          <div className="add-category-infor-here">
                            <div
                              className={
                                stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0
                                  ? 'add-metric-head-none'
                                  : 'add-metric-head'
                              }
                            >
                              <div type="button" className="btn add-category-btn" onClick={this.handleCategoryShow}>
                                {i18n.t('Add_Category_Details')}
                              </div>
                              <div className="add-sub-level-metrics">
                                <i className="far fa-file-plus" />
                                <p>
                                  <span>*</span>
                                  {i18n.t('Add_category_details_as_sub_level_metrics')}
                                </p>
                              </div>
                            </div>
                            {stateKpiData.CategoryDetails
                              && stateKpiData.CategoryDetails.length > 0
                              && (
                              <div className="category-header">
                                <span className="category-headername">
                                  {i18n.t('Category')}
                                  {' '}
                                  -
                                  {' '}
                                  {stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0
                                    ? stateKpiData.CategoryDetails[0].CategoryType
                                    : ''}
                                </span>
                                <div className="category-actions">
                                  <div className="category-add" onClick={this.handleCategoryTypeAddShow} role="button" tabIndex="0">
                                    <i className="fal fa-plus-circle mr-3" />
                                  </div>
                                  <span onClick={() => this.deleteCategoryType()} role="button" tabIndex="0">
                                    <i className="fas fa-trash-alt delete-icon-fix" />
                                  </span>
                                </div>
                              </div>
                              )}
                            <div
                              className={
                                stateKpiData.CategoryDetails
                                  && stateKpiData.CategoryDetails.length > 0
                                  && stateKpiData.IsMultiLevel
                                  ? 'category-lists'
                                  : 'category-lists-none'
                              }
                            >
                              <ul>
                                {items}
                              </ul>
                            </div>
                          </div>

                          {/* {Add Category Type} */}
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={showaddCategoryType}
                            onHide={this.handleaddCategoryClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {i18n.t('Add_Category_Type')}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t('New_Category')}
                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    id="categoryType"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={CategoryDetails.CategoryType}
                                  />

                                  <span className="errorMsg">{errors.CategoryType}</span>
                                </div>
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t('Name')}

                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    id="categoryName"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={CategoryDetails.CategoryName}
                                  />

                                  <span className="errorMsg">{errors.CategoryName}</span>
                                </div>
                                <p className="text-right newlyadded">
                                  {i18n.t('Newly_added_category_type_will_be_sent_for_approval')}
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button type="button" className="btn btn-default btn-sm mr-2" onClick={this.handleaddCategoryClose}>
                                {i18n.t('Cancel')}
                              </button>
                              <button type="button" className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
                                {i18n.t('Add')}
                              </button>
                            </Modal.Footer>
                          </Modal>

                          {/* {Add Category Name} */}
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={showaddCategory}
                            onHide={this.handleaddCategoryNameClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {' '}
                                {i18n.t('Add_Category_Name')}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t('Category_Type')}
                                    <span className="required"> *</span>
                                  </label>

                                  <input
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    id=""
                                    value={CategoryDetails.CategoryType}
                                    disabled
                                  />
                                </div>

                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t('Name')}

                                    <span className="required"> *</span>
                                  </label>

                                  <input
                                    id="categoryName"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={CategoryDetails.CategoryName}
                                  />

                                  <div className="errorMsg">{errors.CategoryName}</div>
                                </div>

                                <p className="text-right newlyadded">
                                  {i18n.t('Newly_added_category_name_will_be_sent_for_approval')}
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                type="button"
                                className="btn btn-default btn-sm mr-2"
                                onClick={this.handleaddCategoryNameClose}
                              >
                                {i18n.t('Cancel')}
                              </button>
                              <button type="button" className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
                                {i18n.t('Add')}
                              </button>
                            </Modal.Footer>
                          </Modal>

                          {/* Approval Notification */}
                          <Modal
                            className="approvalModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={showApproval}
                            onHide={this.handleApprovalClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>{i18n.t('Approval_Notification')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                {CatTypeID === 'addnew' ? (
                                  <p className="approvalInfo">
                                    {i18n.t('Newly_added_category_type_is_sent_for_approval')}
                                  </p>
                                ) : (
                                  <p className="approvalInfo">
                                    {i18n.t('Newly_added_category_name_is_sent_for_approval')}
                                  </p>
                                )}
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>

                    <div className="errorMsg">{errors.MultiLevel}</div>
                  </div>
                  {/* end of multi level UI */}
                </div>
              </div>
            </form>

          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">

              <button type="button" onClick={this.handleHide} className="btn btn-default btn-sm mr-2">
                {i18n.t('Cancel')}
              </button>

              {!edit ? (
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value={i18n.t('Create')}
                />
              ) : (
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value={i18n.t('Update')}
                />
              )}
            </div>
          </Modal.Footer>
        </Modal>
        {viewmore ? (
          <KpiPopUp
            SOCategoryID={SOCategoryID}
            show={true}
            onClose={this.onClose.bind(this)}
            filterDetails={filterDetails}
            tabName="DD"
          />
        ) : null}
      </>
    );
  }
}
