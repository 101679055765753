import React, { Component } from 'react';
import { LocalApiBaseUrl } from '../Shared/Constant';
import { AgGridReact } from 'ag-grid-react';
import DealFilterPopUp from './DealFilterPopUp';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class DealFilterList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Fields: [], userList: [], roleList: [], clientList: [], viewmore: false, errors: {}, rowSelected: [], sourceOptions: [], DEIDealOptions: [],
            Connector: {
                DealID: null,
                ConnectorID: null,
            },
            columnDefs: [

                {


                    headerName: "Edit", suppressMovable: true,
                    width: 60,
                    cellRenderer: (params) => {
                        var link = document.createElement('span');
                        link.className = 'tbledit';
                        link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.dealpopup(e, params.data);
                        });
                        return link;
                    },
                    suppressFilter: true,
                },
                {
                    headerName: "Delete", suppressMovable: true,
                    width: 80,
                    cellRenderer: (params) => {
                        if (params.data.IsActive === true) {
                            var link = document.createElement('span');
                            link.className = 'tbldel';
                            link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
                            link.addEventListener('click', (e) => {
                                this.deleteLocation(e, params.data);
                            });
                            return link;
                        }
                    },
                    suppressFilter: true
                },
                { headerName: "Query Text", field: "QueryText", resizable: true, minWidth: 330, autoSizeColumns: true },

                {
                    headerName: "Is Active", field: "IsActive", resizable: true, suppressFilter: true,

                    cellRenderer: params => {
                        return `<label class="form-check">
                    <input class="form-check-input" disabled type="checkbox" ${params.value ? 'checked' : ''}>
                    <span class="checkmark"></span>
                     </label>`;
                    }
                },

            ],
            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'multiple',
                defaultColDef: {
                    sortable: true
                },
            }
        }
        this.onClose = this.onClose.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onSourceChange = this.onSourceChange.bind(this);
        this.onDEIDealChange = this.onDEIDealChange.bind(this);


    }



    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }





    dealpopup(e, data) {
        this.setState({ viewmore: false });


        if (this.state.Connector.ConnectorID > 0 && this.state.Connector.DealID > 0) {
            e.preventDefault();
            this.setState({ viewmore: true, rowSelected: data })

        }

    }

    onClose(e) {

        this.setState({ viewmore: false });
    }

    deleteLocation(e, data) {


        if (this.props.featureData.filter((data) => data.Submenu == "Deal Filter" && data.Operations.includes("Delete")).length > 0) {

            if (window.confirm('Are you sure you want to delete the item?')) {
                axiosInstance.delete(`${LocalApiBaseUrl}QueryBuilder/DealQueryDelete`, {
                    params: {
                        DealQueryID: this.state.DealQueryID//data.DealQueryID
                    }
                }).then((response) => {
                    window.alert(response.data.StatusMessage)
                    this.getDealConnector();

                })
                    .catch((error) => {
                        trycatchAlertPopup(error);
                    });
            }

        }
        else window.alert("Access Denied !!")

    }

    getDealConnector() {   //shows the data in the main grid
        axiosInstance.get(`${LocalApiBaseUrl}QueryBuilder/GetDealQuery?`, {
            params: {
                DealID: this.state.Connector.DealID,
                ConnectorID: this.state.Connector.ConnectorID

            }
        })
            .then((response) => {
                if (response.data.length > 0) {
                    this.setState({
                        rowData: response.data,//response.data[0].DealFilters,
                        DealQueryID: response.data[0].DealQueryID,
                        fullData: response.data

                    })

                }
                else {
                    this.setState({
                        rowData: response.data,
                        fullData: response.data


                    })
                }

            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });


    }




    getGetConnectorMaster() {   // connector dropdown
        let source = [];
        axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
            .then((response) => {
                source = response.data;
                let sourceValues = source.map((x) => <option key={x.ConnectorID} /*selected={index == 0 ? "" : ""}*/ value={x.ConnectorID}>{x.ConnectorName}</option>)
                this.setState({ sourceOptions: sourceValues });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });


    }

    GetDEIDeal() {             //Deal dropdown
        this.setState({ viewmore: false });
        let samp = {};
        var EnterpriseID = sessionStorage.getItem("EnterpriseID")
        if (EnterpriseID != undefined) {
            axiosInstance.get(`${LocalApiBaseUrl}Deal/GetDeals`, {
                params: { FeatureName: "Deal Filter" }
            })

                .then((response) => {
                    this.setState({ DEIDealOptions: response.data.filter(f => f.IsActive == true) });

                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                });



        }
    }



    getFields(ConnectorID) {
        debugger
        this.state.Fields = [""];

        axiosInstance.get(`${LocalApiBaseUrl}QueryBuilder/GetFieldNamesByConnectorId?ConnectorId=${ConnectorID}`,
            {
            })
            .then((response) => {

                response.data.map((row) => {
                    this.state.Fields.push(row.FieldName)
                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });

    }



    onSourceChange(event) {
        this.setState({ viewmore: false });
        let Connector = this.state.Connector;
        Connector.ConnectorID = event.target.value;
        var index = event.nativeEvent.target.selectedIndex;
        this.setState({
            Connector: Connector,
        })
        this.getFields(event.target.value)
        this.getDealConnector()
    }

    onDEIDealChange(event) {
        let soCatAtt = null;
        this.setState({ viewmore: false });
        this.setState({ errors: {} });
        const Connector = this.state.Connector;
        Connector.DealID = event.target.value;
        if (this.state.DEIDealOptions.filter((obj) => obj.DealID == this.state.Connector.DealID))
            soCatAtt = this.state.DEIDealOptions.filter((obj) => obj.DealID == this.state.Connector.DealID)
        {

        }
        this.setState({
            Connector: Connector,

        })
        this.getDealConnector()
    }


    componentWillMount() {
        let columnDefs = this.state.columnDefs;
        this.GetDEIDeal();
        this.getGetConnectorMaster();


    }


    componentDidMount() {
        let columnDefs = this.state.columnDefs;


        this.GetDEIDeal();
        this.getGetConnectorMaster();

        if (this.props.featureData.filter((data) => data.Submenu == "Deal Filter" && data.Operations.includes("Edit")).length == 0) {
            columnDefs = columnDefs.filter((data) => data.headerName != "Edit");
            this.setState({
                columnDefs: columnDefs
            })

        }


        if (this.props.featureData.filter((data) => data.Submenu == "Deal Filter" && data.Operations.includes("Delete")).length == 0) {
            columnDefs = columnDefs.filter((data) => data.headerName != "Delete");
            this.setState({
                columnDefs: columnDefs
            })

        }
    }



    handleSave(data) {

        axiosInstance.post(`${LocalApiBaseUrl}QueryBuilder/PostQueryCreated`, data)
            .then((response) => {
                window.alert(response.data.StatusMessage)
                this.getDealConnector();
            },
                (error) => {
                    

                    //console.log(error)
                })
            .catch((error) => {
                trycatchAlertPopup(error);
            });

        this.getDealConnector();
        this.setState({ viewmore: false });

    }

    render() {

        return (
            <div className="main-data">
                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <label>Source System<span className="required ">*</span></label>
                                <select className="form-control" onChange={this.onSourceChange} value={this.state.Connector.ConnectorID}>
                                    <option value="0">Select Source</option>
                                    {this.state.sourceOptions}
                                </select>
                                <div className="errorMsg">{this.state.errors.ConnectorID}</div>
                            </div>
                            <div className="form-group col-lg-3">
                                <label>Deal <span className="required">*</span></label>
                                <select id="OperatingGroup" className="form-control" ref="OperatingGroupDropDown" onChange={this.onDEIDealChange.bind(this)} value={this.state.Connector.DealID}>
                                    <option value="0">Select Deal</option>
                                    {this.state.DEIDealOptions.map((row) => {
                                        return <option value={row.DealID}>{row.ClientName + "  -  " + row.Process + "  -  " + row.City}</option>;
                                    })}
                                </select>
                                <div className="errorMsg">{this.state.errors.DealID}</div>
                            </div>
                            <div className="form-group col-lg-3 label-adjust">
                                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                            </div>
                            {this.props.featureData.filter((data) => data.Submenu == "Deal Filter" && data.Operations.includes("Add")).length > 0 &&
                                <div className="form-group col-lg-3 label-adjust">
                                    {this.state.rowData.length > 0
                                        ? null
                                        : <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.dealpopup(e) }}><span><FontAwesomeIcon icon={faPlusCircle} /></span> New Deal Filter</button>}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ag-theme-balham ag-table">
                            <AgGridReact
                                enableSorting={true}
                                enableFilter={true}
                                pagination={false}
                                paginationPageSize={50}
                                floatingFilter={true}
                                gridOptions={this.state.gridOptions}
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                            </AgGridReact>
                        </div>
                    </div>
                </div>
                {this.state.viewmore ? <DealFilterPopUp Fields={this.state.Fields} fullData={this.state.fullData} connectorData={this.state.Connector} rowData={this.state.rowData} data={this.state.rowSelected} show={this.state.viewmore} onClose={this.onClose} onSave={this.handleSave} /> : null}
            </div>
        )
    }
}
