import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { connect } from "react-redux";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faTrashAlt,
	faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import KpiPopUp from "../SuperAdmin/KpiPopUp";
import { DisplayMessage,isValidText,InvalidDescription } from "../Shared/commonFunctions";
import { regexForNumbersOnlyMTC, regexForFields } from "../Shared/Constant";
axiosInstance.defaults.headers.common[
	"Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


const maxLengthMetricDefinitionFields = 500;

function validateMetricDefinitionFieldValue(str) {
	return regexForFields.test(str);
}

// THE FIELDS FOR A SINGLE METRIC DEFINITION
const singleMetricDefinition = {
	isDisabledNumeratorRadioField: false,
	selectedDefinition: "numeratorDefinition",
	numeratorDefinition: "",
	isRequiredNumeratorDefinitionField: false,
	errMsgNumeratorDefinitionField: "",
	denominatorDefinition: "",
	isRequiredDenominatorDefinitionField: false,
	errMsgDenominatorDefinitionField: "",
	isEnabled: "yes",
};

class BO2_popup extends React.Component {
	constructor(props) {
		super(props);
		this.ERR_MSG_METRIC_DEF_INVALID_VALUE = i18n.t("MultiDefErrMsg1") + " " + `@ $ % & () \n \- _ , . ? ‘ ” \' \"/` + " " + i18n.t("MultiDefErrMsg2");

		this.state = {
			show: this.props.show,
			apivalue: "",
			message: "",
			flag: true,
			selectValue: this.props.SelectValue,
			Display: false,
			viewmore: false,
			MetricNameOptions: [],
			valueConnector: "",
			errors: {},
			formIsValid: false,
			attributeLabels: [],
			unitsData: [],
			checkFlag: false,
			seqValue: null,
			data: {
				SOCategoryID: 0,
				kpiData: {
					KPIMappingID: 0,
					MetricSeq: "",
					MetricID: null,
					MetricName: "",
					FrequencyID: null,
					MetricUnit: "",
					MetricImage: "",
					KPIDesp: "",
					MetricDataID: null,
					MetricDataDesp: "",
					MetricValue: "",
					RAGStatus: "",
					UpperThreshold: "",
					LowerThreshold: "",
					Month: "",
					Attribute1Value: "",
					Attribute2Value: "",
					metDataAttr: null,
					LeadingPractice: "",
				},
			},
			// MULTIPLE METRIC DEFINITIONS WHICH ARE DYNAMICALLY ADDED
			metricDefinitions: [
				{
					..._.cloneDeep(singleMetricDefinition),
					isRequiredNumeratorDefinitionField:
						props.defaultIsRequiredMetricDefinitionFields,
					isRequiredDenominatorDefinitionField:
						props.defaultIsRequiredMetricDefinitionFields,
				},
			],
			areAnyDuplicateMetricDefinitions: false,
			isAPILoading: false,
		};
		this.validateForm = this.validateForm.bind(this);
		this.onNameChange = this.onNameChange.bind(this);
		this.onDescriptionChange = this.onDescriptionChange.bind(this);
		this.onSequenceChange = this.onSequenceChange.bind(this);
		this.onAttribute1ValueChange = this.onAttribute1ValueChange.bind(this);
		this.onAttribute2ValueChange = this.onAttribute2ValueChange.bind(this);
		this.onUnitChange = this.onUnitChange.bind(this);
		this.handleHide = this.handleHide.bind(this);
		this.handleRejectHide = this.handleRejectHide.bind(this);
		this.handleApproveHide = this.handleApproveHide.bind(this);
		this.handleSave = this.handleSave.bind(this);
		let offeringValue = "";
		let value1 = [];
	}

	onClose(e) {
		this.setState({ viewmore: false });
		this.props.onClose(e);
	}

	componentDidMount() {
		const { defaultIsRequiredMetricDefinitionFields, kpiData } = this.props;

		let metricDefinitions = [];
		const metricDefinitionsFromProps = _.get(
			this.props,
			"kpiData.MetricDefination",
			[]
		);

		this.getKpiMAppingDetails();
		if (this.props.kpiData) {
			if (!_.isEmpty(metricDefinitionsFromProps)) {
				metricDefinitions = _.map(metricDefinitionsFromProps, (eachItem) => {
					const metricDef = {
						..._.cloneDeep(singleMetricDefinition),
						isDisabledNumeratorRadioField: kpiData.MetricUnit.trim() === "%",
						selectedDefinition: !_.isEmpty(eachItem.DenominatorDescription)
							? "numeratorAndDenominatorDefinition"
							: "numeratorDefinition",
						numeratorDefinition: eachItem.NumeratorDescription,
						isRequiredNumeratorDefinitionField: defaultIsRequiredMetricDefinitionFields,
						denominatorDefinition: eachItem.DenominatorDescription,
						isRequiredDenominatorDefinitionField: defaultIsRequiredMetricDefinitionFields,
						isEnabled: eachItem.IsEnabled ? "yes" : "",
						KPIMetricDefinatoinID: _.get(
							eachItem,
							"KPIMetricDefinatoinID",
							null
						),
					};
					return metricDef;
				});
			} else {
				metricDefinitions = [
					{ ..._.cloneDeep(this.state.metricDefinitions[0]) },
				];
			}

			this.setState({
				data: {
					kpiData: this.props.kpiData,
				},
				errors: {},
				formIsValid: true,
				metricDefinitions,
			}, () => {
				if (kpiData.MetricUnit.trim() === "%") {
					this.toggleRequiredMetricDefinitionFields();
				}
			});
		}

		//read attributelabels from the store

		var data = store.getState();
		let unitsData = this.props.unitsData;
		let labelsForBO = data.BOMasterReducers.attributeLabels.filter(z => z.ApplicableForDD === false);

		this.setState({ attributeLabels: labelsForBO[0], unitsData: unitsData });
	}
	componentDidUpdate(prevProps) {
		if (prevProps.unitsData !== this.props.unitsData) {
			this.setState({ unitsData: this.props.unitsData })
		}
	}
	getKpiMAppingDetails() {
		let samp = {};
		let valueConnector = "";
		axiosInstance
			.get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
				params: {
					OrgEntityID: this.props.filterDetails.Client,
					LocationID: this.props.filterDetails.Locations,
					OfferingID: this.props.filterDetails.process,
					SOCategoryID: this.props.SOCategoryID,
				},
			})
			.then((response) => {
				if (response.data.length != 0) {
					if (
						response.data[0].IsConnector == true &&
						response.data[0].IsTransaction == false
					) {
						valueConnector = true;
					}
				}

				this.setState({
					MetricNameOptions: response.data,
					valueConnector: valueConnector,
				});
			});
	}

	onNameChange(event) {
		const kpiData = this.state.data.kpiData;
		kpiData.MetricName = event.target.value;
		this.setState({
			data: {
				kpiData: kpiData,
			},
		});
	}

	onDescriptionChange(event) {
		const kpiData = this.state.data.kpiData;
		kpiData.KPIDesp = event.target.value;
		this.setState({
			data: {
				kpiData: kpiData,
			},
		});
	}
	onSequenceChange(event) {
		let errors = {};
		if (this.state.flag) {
			this.setState({
				seqValue: this.state.data.kpiData.MetricSeq,
				flag: false,
				checkFlag: true
			});
		}
		this.setState({
			errors: errors,
		});
		const kpiData = this.state.data.kpiData;
		kpiData.MetricSeq = event.target.value;
		this.setState({
			data: {
				kpiData: kpiData,
			},
		});
	}

	onAttribute1ValueChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		const kpiData = this.state.data.kpiData;
		kpiData.Attribute1Value = event.target.value;
		this.setState({
			data: {
				kpiData: kpiData,
			},
		});
	}

	onAttribute2ValueChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		const kpiData = this.state.data.kpiData;
		kpiData.Attribute2Value = event.target.value;
		this.setState({
			data: {
				kpiData: kpiData,
			},
		});
	}

	onUnitChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		const kpiData = this.state.data.kpiData;
		kpiData.MetricUnit = event.target.options[event.target.selectedIndex].value;
		this.setState(
			{
				data: {
					kpiData: kpiData,
				},
			},
			() => {
				this.toggleRequiredMetricDefinitionFields();
				this.clearMetricDefinitionFieldsErrors();
			}
		);
	}

	handleSave(e) {
		const { metricDefinitions } = this.state;
		if (this.validateForm()) {
			let filterProcess = this.props.filterDetails.process;
			const data = this.state.data;
			data.SOCategoryID = this.props.SOCategoryID;
			data.kpiData.SOCategoryID = this.props.SOCategoryID;

			// let SUCC_MSG = "Metric Template added successfully";
			let SUCC_MSG = i18n.t("SUCC_MSG1");

			// let ERR_MSG = "There was some error while adding Metric Template";
			let ERR_MSG = i18n.t("ERR_MSG1");

			if (!_.isEmpty(this.props.kpiData)) {
				// SUCC_MSG = "Metric Template updated successfully";
				SUCC_MSG = i18n.t("SUCC_MSG2");

				// ERR_MSG = "There was some error while updating Metric Template";
				ERR_MSG = i18n.t("ERR_MSG2");

			}

			// ADD MetricDefinitions to the request parameters
			const metricDefinitionsForRequest = _.map(
				metricDefinitions,
				(eachItem) => {
					const metricDef = {
						NumeratorDescription: eachItem.numeratorDefinition,
						DenominatorDescription:
							eachItem.selectedDefinition === "numeratorDefinition"
								? ""
								: eachItem.denominatorDefinition,
						IsEnabled: eachItem.isEnabled === "yes" ? "true" : "false",
					};
					if (
						!_.isEmpty(String(_.get(eachItem, "KPIMetricDefinatoinID", "")))
					) {
						// IF THE METRIC DEFINITION IS BEING EDITED
						metricDef.KPIMetricDefinatoinID = eachItem.KPIMetricDefinatoinID;
					}
					return metricDef;
				}
			);
			data.kpiData.MetricDefination = metricDefinitionsForRequest;
			this.setState({ isAPILoading: true });
			axiosInstance
				.post(
					`${LocalApiBaseUrl}Master/PostKpiMetricMasterTemplate`,
					data.kpiData,
					{
						params: {
							OfferingID: filterProcess,
							LanguageID: store.getState().CurrentFilter.languageData.id,
						},
					}
				)
				.then((response) => {
					store.dispatch({
						type: "ADD_BO_MASTER",
						payload: response.data.kPIdata,
					});
					this.setState({ isAPILoading: false }, () => {
						alert(SUCC_MSG);
						this.handleHide(e);
					});
				})
				.catch((error) => {
					this.setState({ isAPILoading: false }, () => {
				
						DisplayMessage();
						trycatchAlertPopup(error);
					});

				});
		}
	}

	handleHide(e) {
		this.setState({
			show: false,
			flag: true
		});
		if (this.state.checkFlag) {
			this.state.data.kpiData.MetricSeq = this.state.seqValue
			this.setState({
				checkFlag: false
			})
		}

		if (e != undefined) {
			if (e.target.textContent != "×") {
				this.props.onClose(e);
			} else {
				this.props.onClose(e);
			}
		} else {
			this.props.onClose(e);
		}
	}
	handleRejectHide(e) {
		this.setState({ show: false });
		if (e != undefined) {
			if (e.target.textContent != "×") {
				this.props.onRejectClose();
			}
		} else {
			this.props.onRejectClose();
		}
	}
	handleApproveHide(e) {
		this.setState({ show: false });
		if (e != undefined) {
			if (e.target.textContent != "×") {
				this.props.onApproveClose();
			}
		} else {
			this.props.onApproveClose();
		}
	}
	passToConfirmation(data) {
		this.props.enteredData(data.PoNumber);
	}

	validateForm() {
		const { metricDefinitions } = this.state;
		const { defaultIsRequiredLeadingPractice } = this.props;

		let kpiData = this.state.data.kpiData;
		let errors = {};
		let formIsValid = true;
		if (kpiData["MetricSeq"]) {
			kpiData["MetricSeq"] = parseInt(kpiData["MetricSeq"], 10);
		}
		if (!kpiData["MetricSeq"]) {
			formIsValid = false;
			// errors["MetricSeq"] = "Sequence is required";
			errors["MetricSeq"] = i18n.t("addWoErrorM5");

		} else if (!(kpiData["MetricSeq"] >= 0 && kpiData["MetricSeq"] <= 999999)) {
			formIsValid = false;
			// errors["MetricSeqRange"] ="Sequence Value must be in between 0 to 999999";
			errors["MetricSeqRange"] = i18n.t("addWoErrorM6");
		}
		if (kpiData["MetricName"]){
			kpiData["MetricName"] = kpiData["MetricName"].trim();
			if(!isValidText(kpiData["MetricName"])){
				errors["MetricName"] =  InvalidDescription();
				formIsValid = false;
			  }
		}
		if (!kpiData["MetricName"]) {
			formIsValid = false;
			// errors["MetricName"] = "Name is required";
			errors["MetricName"] = i18n.t("addWoErrorM1");

		}
		
		if (kpiData["KPIDesp"]){
			kpiData["KPIDesp"] = kpiData["KPIDesp"].trim();
			if(!isValidText(kpiData["KPIDesp"])){
				errors["KPIDesp"] =  InvalidDescription();
				formIsValid = false;
			  }
		}

		if (kpiData["MetricUnit"])
			kpiData["MetricUnit"] = kpiData["MetricUnit"];
		if (!kpiData["MetricUnit"]) {
			formIsValid = false;
			// errors["MetricUnit"] = "Unit is required";
			errors["MetricUnit"] = i18n.t("Unit_is_required");

		}

		// validation for attribute1value and attribute2value
		if (
			!kpiData["Attribute1Value"].trim() &&
			!kpiData["Attribute2Value"].trim()
		) {
			// attribute values can never be left empty - fill NA
			formIsValid = false;
			errors["Attribute1Value"] = i18n.t("AttributeValue_ErrMsg1");
			errors["Attribute2Value"] = i18n.t("AttributeValue_ErrMsg1");
		}

		if (kpiData["Attribute1Value"].trim()) {
			if (Number.isNaN(parseFloat(kpiData["Attribute1Value"].trim()))) {
				// it is a string

				if (kpiData["Attribute1Value"].toLowerCase().trim() !== "na") {
					formIsValid = false;
					// errors["Attribute1Value"] = "NA is the only permitted string value";
					errors["Attribute1Value"] = i18n.t("AttributeValue_ErrMsg2");

				}
			} else {
				//attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456

				if (!regexForNumbersOnlyMTC.test(kpiData["Attribute1Value"].trim())) {
					formIsValid = false;
					// errors["Attribute1Value"] = "NA is the only permitted string value";
					errors["Attribute1Value"] = i18n.t("AttributeValue_ErrMsg2");

				}

				if (
					!(
						parseFloat(kpiData["Attribute1Value"].trim()) >= 0 &&
						parseFloat(kpiData["Attribute1Value"].trim()) <= 999999
					)
				) {
					formIsValid = false;
					errors["Attribute1Value"] = `${this.state.attributeLabels.AttributeName1}` + " " + i18n.t("addBoErrorM2");
				}
			}
			//0 to 999999 validation for attribute1value

			if (!kpiData["Attribute2Value"].trim()) {
				errors["Attribute2Value"] =
					i18n.t("AttributeValue_ErrMsg3") + " " +
					`${this.state.attributeLabels.AttributeName2}`;

				formIsValid = false;
			}
		}
		// TODO: needs to be chnaged later when attribute labels are dynamic

		this.setState({
			errors: errors,
		});
		if (formIsValid) {
			this.setState({
				formIsValid: true,
			});
		} else {
			this.setState({
				formIsValid: false,
			});
		}

		if (kpiData["Attribute2Value"].trim()) {
			if (Number.isNaN(parseFloat(kpiData["Attribute2Value"].trim()))) {
				// it is a string

				if (kpiData["Attribute2Value"].toLowerCase().trim() !== "na") {
					formIsValid = false;
					// errors["Attribute2Value"] = "NA is the only permitted string value";
					errors["Attribute2Value"] = i18n.t("AttributeValue_ErrMsg2");

				}
			} else {
				//attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456

				if (!regexForNumbersOnlyMTC.test(kpiData["Attribute2Value"].trim())) {
					formIsValid = false;
					// errors["Attribute2Value"] = "NA is the only permitted string value";
					errors["Attribute2Value"] = i18n.t("AttributeValue_ErrMsg2");

				}

				//0 to 999999 validation for attribute2value
				if (
					!(
						parseFloat(kpiData["Attribute2Value"].trim()) >= 0 &&
						parseFloat(kpiData["Attribute2Value"].trim()) <= 999999
					)
				) {
					formIsValid = false;
					errors["Attribute2Value"] =
						`${this.state.attributeLabels.AttributeName2}` + " " +
						i18n.t("addBoErrorM2");
				}
			}

			if (!kpiData["Attribute1Value"].trim()) {
				errors["Attribute1Value"] =
					i18n.t("AttributeValue_ErrMsg3") + " " +
					`${this.state.attributeLabels.AttributeName1}`;
				formIsValid = false;
			}
			// TODO: needs to be chnaged later when attribute labels are dynamic
		}

		// VALIDATE "LEADING PRACTICE" FIELD
		const LeadingPractice = kpiData.LeadingPractice;
		if (defaultIsRequiredLeadingPractice && _.isEmpty(LeadingPractice)) {
			formIsValid = false;
			errors["LeadingPractice"] = i18n.t("Leading_Practice_ErrMsg");
		}

		// VALIDATE "METRIC DEFINITIONS"
		const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem) => {
			const selectedDefinition = eachItem.selectedDefinition;
			let errMsgNumeratorDefinitionField = "";
			let errMsgDenominatorDefinitionField = "";

			const numeratorDefinition = String(eachItem.numeratorDefinition).trim();
			const denominatorDefinition = String(
				eachItem.denominatorDefinition
			).trim();

			// FOR `numeratorDefinitionField` CHECK IF THE FIELD IS REQUIRED AND ITS VALUE IS NOT EMPTY
			if (
				eachItem.isRequiredNumeratorDefinitionField &&
				_.isEmpty(numeratorDefinition)
			) {
				errMsgNumeratorDefinitionField = i18n.t("NumeratorDefReqd");

			} else if (
				!_.isEmpty(numeratorDefinition) &&
				!validateMetricDefinitionFieldValue(numeratorDefinition)
			) {
				errMsgNumeratorDefinitionField = this.ERR_MSG_METRIC_DEF_INVALID_VALUE;
			}

			if (selectedDefinition === "numeratorAndDenominatorDefinition") {
				if (
					(eachItem.isRequiredDenominatorDefinitionField &&
						_.isEmpty(denominatorDefinition)) ||
					(!_.isEmpty(numeratorDefinition) && _.isEmpty(denominatorDefinition))
				) {
					// errMsgDenominatorDefinitionField ="Denominator Definition is required";
					errMsgDenominatorDefinitionField = i18n.t("DenominatorDefReqd");
				}
				// `denominatorDefinition` IS NOT EMPTY BUT `numeratorDefinition` IS EMPTY
				if (
					!_.isEmpty(denominatorDefinition) &&
					_.isEmpty(numeratorDefinition)
				) {
					// errMsgNumeratorDefinitionField = "Numerator Definition is required";
					errMsgNumeratorDefinitionField = i18n.t("NumeratorDefReqd");

				}
			}

			if (
				!_.isEmpty(denominatorDefinition) &&
				!validateMetricDefinitionFieldValue(denominatorDefinition)
			) {
				errMsgDenominatorDefinitionField = this.ERR_MSG_METRIC_DEF_INVALID_VALUE;
			}
			eachItem.errMsgNumeratorDefinitionField = errMsgNumeratorDefinitionField;
			eachItem.errMsgDenominatorDefinitionField = errMsgDenominatorDefinitionField;

			// IF THERE WAS SOME ERROR IN DYNAMICALLY ADDED METRIC DEFINITIONS THEN SET THE FORM TO INVALID
			if (
				!_.isEmpty(errMsgNumeratorDefinitionField) ||
				!_.isEmpty(errMsgDenominatorDefinitionField)
			) {
				formIsValid = false;
			}

			eachItem.numeratorDefinition = numeratorDefinition;
			eachItem.denominatorDefinition = denominatorDefinition;
			return eachItem;
		});

		// CHECK FOR DUPLICATE METRIC DEFINITIONS
		let areAnyDuplicateMetricDefinitions = false;
		_.forEach(modifiedMetricDefinitions, function (eachMetDef, index) {
			const modifiedNumeratorDefinition = String(
				eachMetDef.numeratorDefinition
			).trim();
			const modifiedDenominatorDefinition = String(
				eachMetDef.denominatorDefinition
			).trim();

			// FOR ALL THE SELECTED RADIO OPTIONS "Numerator Definition", CHECK FOR DUPLICATE VALUES
			const eqNumField = _.find(modifiedMetricDefinitions, function (
				eachItem,
				indx
			) {
				const foundNumeratorDefinition = String(
					eachItem.numeratorDefinition
				).trim();

				if (
					indx > index &&
					eachMetDef.selectedDefinition === "numeratorDefinition" &&
					eachItem.selectedDefinition === "numeratorDefinition" &&
					!_.isEmpty(modifiedNumeratorDefinition) &&
					!_.isEmpty(foundNumeratorDefinition) &&
					modifiedNumeratorDefinition === foundNumeratorDefinition
				) {
					return true;
				}
			});
			if (eqNumField) {
				areAnyDuplicateMetricDefinitions = true;
			}

			// FOR ALL THE SELECTED RADIO OPTIONS "Numerator & Denominator Definition", CHECK FOR DUPLICATE VALUES
			const eqNumDenoField = _.find(modifiedMetricDefinitions, function (
				eachItem,
				indx
			) {
				const foundNumeratorDefinition = String(
					eachItem.numeratorDefinition
				).trim();
				const foundDenominatorDefinition = String(
					eachItem.denominatorDefinition
				).trim();

				if (
					indx > index &&
					eachMetDef.selectedDefinition ===
					"numeratorAndDenominatorDefinition" &&
					eachItem.selectedDefinition === "numeratorAndDenominatorDefinition" &&
					!_.isEmpty(modifiedNumeratorDefinition) &&
					!_.isEmpty(foundNumeratorDefinition) &&
					modifiedNumeratorDefinition === foundNumeratorDefinition &&
					!_.isEmpty(modifiedDenominatorDefinition) &&
					!_.isEmpty(foundDenominatorDefinition) &&
					modifiedDenominatorDefinition === foundDenominatorDefinition
				) {
					return true;
				}
			});
			if (eqNumDenoField) {
				areAnyDuplicateMetricDefinitions = true;
			}
		});
		// IF THERE WERE ANY DUPLICATE METRIC DEFINITIONS THEN SET THE FORM VALID STATUS TO FALSE
		if (areAnyDuplicateMetricDefinitions) {
			formIsValid = false;
		}

		this.setState({
			errors: errors,
			metricDefinitions: modifiedMetricDefinitions,
			areAnyDuplicateMetricDefinitions,
		});

		if (formIsValid) {
			this.setState({
				formIsValid: true,
			});
		} else {
			this.setState({
				formIsValid: false,
			});
		}
		return formIsValid;
	}

	// EXECUTED WHEN THE "LeadingPractice" FIELD VALUE WOULD BE CHANGED
	handleChangeLeadingPractice = (e) => {
		const LeadingPractice = e.target.value;
		this.setState((prevState) => ({
			data: {
				...prevState.data,
				kpiData: {
					...prevState.data.kpiData,
					LeadingPractice,
					//LeadingPractice_En,
				},
			},
		}));
	};

	/**
	 * When the unit of metric is "%" definition of both numerator & denominator should be mandatory.
	 * Numerator and Denominator fields are mandatory fields for BO, II & DD page. For  H&M & WO page keep them optional.
	 * So Disable "numerator" (radio) selection based on above condition.
	 */
	toggleRequiredMetricDefinitionFields = () => {
		const { defaultIsRequiredMetricDefinitionFields } = this.props;
		const {
			data: { kpiData },
			metricDefinitions,
		} = this.state;
		const MetricUnit = kpiData.MetricUnit;

		let areRequiredMetricDefinitionFields = defaultIsRequiredMetricDefinitionFields;

		let isDisabledNumeratorDefinitionField = false;
		if (MetricUnit.trim() === _.trim("%")) {
			areRequiredMetricDefinitionFields = true;
			isDisabledNumeratorDefinitionField = true;
		}

		// FOR ALL THE DYNAMICALLY ADDED METRIC DEFINITIONS, TOGGLE DISABLE OF THE "NUMERATOR RADIO" FIELD
		const modifiedMetricDefinitions = _.map(
			metricDefinitions,
			(eachItem, index) => {
				eachItem.isDisabledNumeratorRadioField = isDisabledNumeratorDefinitionField;
				// IF THE "NUMERATOR RADIO" FIELD IS TO BE DISABLED, THEN SET SELECTED "NUMERATOR AND DENOMINATOR RADIO" FIELD
				if (isDisabledNumeratorDefinitionField) {
					eachItem.selectedDefinition = "numeratorAndDenominatorDefinition";
				}
				eachItem.isRequiredNumeratorDefinitionField = areRequiredMetricDefinitionFields;
				eachItem.isRequiredDenominatorDefinitionField = areRequiredMetricDefinitionFields;
				return {
					...eachItem,
				};
			}
		);
		this.setState({
			metricDefinitions: modifiedMetricDefinitions,
		});
	};

	// DYNAMICALLY ADDS A NEW ROW FOR ADDING METRIC DEFINITION
	addMetricDefinitionRow = () => {
		const { defaultIsRequiredMetricDefinitionFields } = this.props;
		const { metricDefinitions } = this.state;
		const clonedSingleMetricDefinition = _.cloneDeep(singleMetricDefinition);

		clonedSingleMetricDefinition.isRequiredNumeratorDefinitionField = defaultIsRequiredMetricDefinitionFields;
		clonedSingleMetricDefinition.isRequiredDenominatorDefinitionField = defaultIsRequiredMetricDefinitionFields;

		const currentMetricDefinitions = _.cloneDeep(metricDefinitions);
		currentMetricDefinitions.push(clonedSingleMetricDefinition);
		this.setState(
			{
				metricDefinitions: currentMetricDefinitions,
			},
			() => {
				this.toggleRequiredMetricDefinitionFields();
			}
		);
	};

	// REMOVES THE SELECTED DYNAMICALLY ADDED METRIC DEFINITION SECTION
	removeMetricDefinitionRow = (rowIndex) => {
		const { metricDefinitions } = this.state;
		const remainingMetricDefinitions = _.filter(
			metricDefinitions,
			(eachItem, index) => {
				return index !== rowIndex;
			}
		);
		this.setState({
			metricDefinitions: remainingMetricDefinitions,
		});
	};

	// EXECUTES WHEN THE ANY OF THE FIELD'S VALUE OF DYNAMICALLY ADDED METRIC DEFINITION WOULD BE CHANGED
	handleChangeMetricDefinitionField = (e, rowIndex, fieldName) => {
		const { metricDefinitions } = this.state;
		let fieldValue = e.target.value;
		if (fieldName === "isEnabled") {
			fieldValue = e.target.checked ? "yes" : "";
		}
		const modifiedMetricDefinitions = _.map(
			metricDefinitions,
			(eachItem, index) => {
				if (index === rowIndex) {
					eachItem[fieldName] = fieldValue;
				}
				return {
					...eachItem,
				};
			}
		);
		this.setState({
			metricDefinitions: modifiedMetricDefinitions,
		});
	};

	// REMOVES THE ERRORS OF DYNAMICALLY ADDED METRIC DEFINITIONS
	clearMetricDefinitionFieldsErrors = () => {
		const { metricDefinitions } = this.state;
		const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem) => {
			return {
				...eachItem,
				errMsgNumeratorDefinitionField: "",
				errMsgDenominatorDefinitionField: "",
			};
		});

		this.setState({
			metricDefinitions: modifiedMetricDefinitions,
		});
	};

	render() {

		const { defaultIsRequiredLeadingPractice } = this.props;
		const {
			metricDefinitions,
			areAnyDuplicateMetricDefinitions,
			data: { kpiData },
			isAPILoading,
		} = this.state;

		let isScreenInEditMode = false;
		if (!_.isEmpty(this.props.kpiData)) {
			isScreenInEditMode = true;
		}

		return (
			<>
				{/* <Header /> */}
				{/* IF API IS LOADING THEN SHOW THE LOADER */}
				{isAPILoading && (
					<LoadingOverlay
						fadeSpeed={0}
						spinner={<SynopsLoader />}
						active
					/>
				)}
				<Modal
					show={this.props.ShowPop}
					onHide={this.handleHide}
					className="modal fade master-template-configurator"
					bsSize="medium"
					id="dealpopup"
					backdrop="static"
				>
					<Modal.Header closeButton>
						<h4 className="modal-title">
							<span>
								{i18n.t("Metric_Template")} - {i18n.t("Business_Outcomes")}
							</span>
						</h4>
					</Modal.Header>
					<Modal.Body>
						<div className="form-section">
							<h3>
								{" "}
								<span>
									{i18n.t("Insight_Data")}{" - "}
									{this.state.data.kpiData.MetricName
										? this.state.data.kpiData.MetricName
										: ""}
								</span>
							</h3>

							<div className="row">
								<div className="form-group col-lg-6">
									<label>
										{i18n.t("Name")} <span className="required">*</span>
									</label>
									<input
										type="text"
										maxLength="100"
										className="form-control"
										onChange={this.onNameChange}
										value={this.state.data.kpiData.MetricName}
										disabled={
											isScreenInEditMode &&
											store.getState().CurrentFilter.languageData.id === 1
										}
									/>
									<span className="errorMsg">
										{this.state.errors.MetricName}
									</span>
								</div>

								<div className="form-group col-lg-6">
									<label>
										{i18n.t("Unit_of_Measure")} <span className="required">*</span>
									</label>
									{isScreenInEditMode ? <input
										type="text"
										maxLength="100"
										className="form-control"

										value={this.state.data.kpiData.MetricUnit}
										disabled={isScreenInEditMode}
									/> :
										<select className="form-control" value={this.state.data.kpiData.MetricUnit} onChange={this.onUnitChange} disabled={isScreenInEditMode}>
											<option value="">{i18n.t("Please_Select_Unit")}</option>
											{this.state.unitsData.map((each) => {
												return <option value={each.MetricUnit_En}>{each.MetricUnit}</option>
											})}
										</select>}

									<div className="errorMsg">{this.state.errors.MetricUnit}</div>
								</div>
							</div>

							<div className="row">
								<div className="form-group col-lg-6">
									<label>
										{i18n.t("Sequence")} <span className="required">*</span>
									</label>
									<input
										type="number"
										className="form-control"
										onChange={this.onSequenceChange}
										value={this.state.data.kpiData.MetricSeq}
									/>
									<div className="errorMsg">{this.state.errors.MetricSeq}</div>
									<div className="errorMsg">
										{this.state.errors.MetricSeqRange}
									</div>
								</div>

								<div className="form-group col-lg-6">
									<label>
										{i18n.t("Leading_Practice")}
										{defaultIsRequiredLeadingPractice && (
											<span className="required"> *</span>
										)}
										&nbsp;
										<OverlayTrigger
											key="top"
											placement="top"
											overlay={
												<Tooltip id="tooltip-top">
													{i18n.t("addBOMess2")}
												</Tooltip>
											}
										>
											<FontAwesomeIcon icon={faInfoCircle} />
										</OverlayTrigger>
									</label>
									<select
										value={kpiData.LeadingPractice}
										disabled={store.getState().CurrentFilter.languageData.id !== 1}
										onChange={(e) => this.handleChangeLeadingPractice(e)}
										className="form-control"
									>
										<option value="">
											{i18n.t("Select_Leading_Practice")}
										</option>
										<option value={i18n.t("Maximum")}>
											{i18n.t("Maximum")}
										</option>
										<option value={i18n.t("Minimum")}>
											{i18n.t("Minimum")}
										</option>
									</select>
									<div className="errorMsg">
										{this.state.errors.LeadingPractice}
									</div>
								</div>
							</div>

							<div className="row">
								<div className="form-group col-lg-12">
									<label>{i18n.t("Description")} </label>
									<input
										type="text"
										maxLength="255"
										className="form-control"
										onChange={this.onDescriptionChange}
										value={this.state.data.kpiData.KPIDesp}
									/>
									<div className="errorMsg">{this.state.errors.KPIDesp}</div>
								</div>
							</div>

							{/* NEW ROW FOR 2 DYNAMIC FIELDS */}
							<div className="row">
						
									<div className="form-group col-lg-6">
									
										<label>
										{this.state.attributeLabels && this.state.attributeLabels.length !== 0 &&
											<label>{this.state.attributeLabels.AttributeName1}{" "}</label>}
											<span className="required">*</span>
										</label>
										<div>
											<input
												type="text"
												className="form-control"
												disabled={store.getState().CurrentFilter.languageData.id !== 1}
												onChange={this.onAttribute1ValueChange}
												value={this.state.data.kpiData.Attribute1Value.trim()}
											/>
										</div>
										<div className="errorMsg">
											{this.state.errors.Attribute1Value}
										</div>
									</div>
								
									<div className="form-group col-lg-6">
									
										<label>
										{this.state.attributeLabels && this.state.attributeLabels.length !== 0 &&
										<label>	{this.state.attributeLabels.AttributeName2}{" "}</label>}
											<span className="required">*</span>
										</label>
										<input
											type="text"
											className="form-control"
											disabled={store.getState().CurrentFilter.languageData.id !== 1}
											onChange={this.onAttribute2ValueChange}
											value={this.state.data.kpiData.Attribute2Value.trim()}
										/>
										<div className="errorMsg">
											{this.state.errors.Attribute2Value}
										</div>
									</div>
							</div>

							{/* START - METRIC DEFINITION */}
							{areAnyDuplicateMetricDefinitions && (
								<div className="row">
									<div className="col">
										<Alert variant="danger">
											{i18n.t("DuplicateMetricDefinitionAlert")}
										</Alert>
									</div>
								</div>
							)}

							<div className="title-with-icon mt-3 mb-1">
								<h3 className="border-0">{i18n.t("Metric_Definition")}
									{" "}
									<OverlayTrigger
										key="top"
										placement="top"
										overlay={
											<Tooltip id="tooltip-top">
												{i18n.t("addBOMess3")}
											</Tooltip>
										}
									>
										<FontAwesomeIcon icon={faInfoCircle} />
									</OverlayTrigger>
								</h3>
								<div className="list-icon">
									{store.getState().CurrentFilter.languageData.id === 1 ? (
										<span
											id="rock"
											onClick={() => this.addMetricDefinitionRow()}
										>
											<FontAwesomeIcon icon={faPlus} />
										</span>
									) : (
										""
									)}
								</div>
							</div>

							<div>
								{_.map(metricDefinitions, (eachMetricDefinition, index) => {
									const selectedDefinition =
										eachMetricDefinition.selectedDefinition;
									const isVisibleDenominatorDefinition =
										selectedDefinition === "numeratorAndDenominatorDefinition";
								
									return (
										<div className="mb-3" key={`definition_${index}`}>
											<div className="row">
												<div className="form-group col-lg-12 mb-1">
													<label className="form-check mr-5">
														<input
															type="radio"
															name={`definition_${index}`}
															value="numeratorDefinition"
															onChange={(e) =>
																this.handleChangeMetricDefinitionField(
																	e,
																	index,
																	"selectedDefinition"
																)
															}
															checked={
																selectedDefinition === "numeratorDefinition"
															}
															disabled={eachMetricDefinition.isDisabledNumeratorRadioField || store.getState().CurrentFilter.languageData.id !== 1}
															className="form-check-input"
														/>
														<span className="checkmark" />
														<span className="form-check-label">
															{i18n.t("Numerator_Definition")}
														</span>
													</label>

													<label className="form-check">
														<input
															type="radio"
															name={`definition_${index}`}
															value="numeratorAndDenominatorDefinition"
															onChange={(e) =>
																this.handleChangeMetricDefinitionField(
																	e,
																	index,
																	"selectedDefinition"
																)
															}
															checked={
																selectedDefinition ===
																"numeratorAndDenominatorDefinition"
															}
															disabled={store.getState().CurrentFilter.languageData.id !== 1}

															className="form-check-input"
														/>
														<span className="checkmark" />
														<span className="form-check-label">
															{i18n.t("Numerator&Denominator_Definition")}
														</span>
													</label>

													{index !== 0 && (
														<>
															{_.isNil(
																_.get(
																	eachMetricDefinition,
																	"KPIMetricDefinatoinID",
																	null
																)
															) &&
																(store.getState().CurrentFilter.languageData
																	.id === 1 ? (
																	<span
																		className="del-row-icon list-icon"
																		onClick={() =>
																			this.removeMetricDefinitionRow(index)
																		}
																	>
																		<FontAwesomeIcon icon={faTrashAlt} />
																	</span>
																) : (
																	""
																))}

															{/* Toggle */}
															{store.getState().CurrentFilter.languageData.id === 1 && (
																<div className="toggle-group">
																	<input
																		type="checkbox"
																		value="yes"
																		checked={
																			String(eachMetricDefinition.isEnabled) ===
																			"yes"
																		}
																		onChange={(e) =>
																			this.handleChangeMetricDefinitionField(
																				e,
																				index,
																				"isEnabled"
																			)
																		}
																		id={`isEnabled_${index}`}
																	/>
																	<label htmlFor={`isEnabled_${index}`} />
																	<div className="onoffswitch" aria-hidden="true">
																		<div className="onoffswitch-label">
																			<div className="onoffswitch-inner" />
																			<div className="onoffswitch-switch" />
																		</div>
																	</div>
																</div>
															)}
														</>
													)}
												</div>
											</div>

											<div className="row">
												<div
													className={classNames(
														"form-group",
														{ "col-lg-6": isVisibleDenominatorDefinition },
														{ col: !isVisibleDenominatorDefinition }
													)}
												>
													<label>
														{i18n.t("Numerator_Definition")}
														{eachMetricDefinition.isRequiredNumeratorDefinitionField && (
															<span className="required"> *</span>
														)}
													</label>
													<input
														type="text"
														value={eachMetricDefinition.numeratorDefinition}
														onChange={(e) =>
															this.handleChangeMetricDefinitionField(
																e,
																index,
																"numeratorDefinition"
															)
														}
														className="form-control"
														maxLength={maxLengthMetricDefinitionFields}
													/>
													<div className="errorMsg">
														{
															eachMetricDefinition.errMsgNumeratorDefinitionField
														}
													</div>
												</div>

												{isVisibleDenominatorDefinition && (
													<div className="form-group col-lg-6">
														<label>
															{i18n.t("Denominator_Definition")}
															{eachMetricDefinition.isRequiredDenominatorDefinitionField && (
																<span className="required"> *</span>
															)}
														</label>
														<input
															type="text"
															value={eachMetricDefinition.denominatorDefinition}
															onChange={(e) =>
																this.handleChangeMetricDefinitionField(
																	e,
																	index,
																	"denominatorDefinition"
																)
															}
															className="form-control"
															maxLength={maxLengthMetricDefinitionFields}
														/>
														<div className="errorMsg">
															{
																eachMetricDefinition.errMsgDenominatorDefinitionField
															}
														</div>
													</div>
												)}
											</div>
										</div>
									);
								})}
							</div>
							{/* END - METRIC DEFINITION */}
						</div>
					</Modal.Body>

					<Modal.Footer>
						<div className="modal-btn">
							<button
								onClick={this.handleHide}
								className="btn btn-default btn-sm mr-2"
							>
								{i18n.t("Cancel")}
							</button>

							<input
								type="Button"
								className="btn btn-primary btn-sm"
								onClick={() => this.handleSave()}
								value={isScreenInEditMode ? i18n.t("Update") : i18n.t("Create")}
							/>
						</div>
					</Modal.Footer>
				</Modal>

				{this.state.viewmore ? (
					<KpiPopUp
						SOCategoryID={this.props.SOCategoryID}
						show={true}
						onClose={this.onClose.bind(this)}
						filterDetails={this.props.filterDetails}
					/>
				) : null}
			</>
		);
	}
}

BO2_popup.defaultProps = {
	screenName: "BusinessOutcomes",
	// Keep "Leading Practice" as non-mandatory for H&M and WO, mandatory for BO, II & DD
	defaultIsRequiredLeadingPractice: true,
	defaultIsRequiredMetricDefinitionFields: true,
};
const mapStateToProps = (state) => {
	return ({
		languageData: state.CurrentFilter.languageData,
		unitsData: state.BOMasterReducers.unitsData
	})
}
export default connect(mapStateToProps)(BO2_popup);
