import React, { Component } from "react";
import { displayApprovalFeatureData } from "../Shared/Constant";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { store } from "../reducers/configureStore";
import MasterContainer from "./MasterContainer";
import i18n from "i18next";
import axiosInstance from "../Shared/interceptor";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class MasterList extends Component {
  constructor() {
    super();
    this.state = {
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      rowSelected: [],
      featureData: [],
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isDealClicked: false,
      isLocationClicked: false,
      isCategoryClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isClickableToolClicked: false,
      isClientRegionClicked: false,
      isOneTimeSetupClicked: false,
      isDeliveryRegionClicked: false,
      isToolClicked: false,
      pageHeader: "",
    };
    this.onClose = this.onClose.bind(this);
    this.onMDetailClick = this.onMDetailClick.bind(this);
    this.onIIClick = this.onIIClick.bind(this);
    this.onWOClick = this.onWOClick.bind(this);
    this.onDealClick = this.onDealClick.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onClientClick = this.onClientClick.bind(this);
    this.onLocationClick = this.onLocationClick.bind(this);
    this.onIndustryClick = this.onIndustryClick.bind(this);
    this.onOfferingClick = this.onOfferingClick.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.getRoleData = this.getRoleData.bind(this);
    this.onCategoryClick = this.onCategoryClick.bind(this);
    this.setDefaultTabOnLoad = this.setDefaultTabOnLoad.bind(this);
    this.onClickableToolClick = this.onClickableToolClick.bind(this);
    this.onClientRegionClick = this.onClientRegionClick.bind(this);
    this.onDeliveryRegionClick = this.onDeliveryRegionClick.bind(this);
    this.onOneTimeSetupClick = this.onOneTimeSetupClick.bind(this);
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }
  dealpopup(e, data) {
    e.preventDefault();
    this.setState({ viewmore: true, rowSelected: data });
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onClientRegionClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: false,
      isCategoryClicked: false,
      isClientRegionClicked: true,
      isDeliveryRegionClicked: false,
      isToolClicked: false,
    });
  }

  onDeliveryRegionClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: false,
      isCategoryClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: true,
      isToolClicked: false,
    });
  }

  onMDetailClick(e) {
    this.setState({
      isMDetailClicked: true,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isOneTimeSetupClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isCategoryClicked: false,
    });
  }

  onIIClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: true,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isOneTimeSetupClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isCategoryClicked: false,
    });
  }

  onLocationClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: true,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isCategoryClicked: false,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isClickableToolClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onClientClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: true,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isCategoryClicked: false,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isClickableToolClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onIndustryClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: true,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isCategoryClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onClickableToolClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: true,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
    });
  }

  onToolClick = (e) => {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: false,
      isToolClicked: true,
      isCategoryClicked: false,
      isClientRegionClicked: false,
    });
  };
  onOfferingClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isCategoryClicked: false,
      isOfferingClicked: true,
      isDealClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onDealClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isDealClicked: true,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isCategoryClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onWOClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: true,
      isDealClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isClickableToolClicked: false,
      isCategoryClicked: false,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isOneTimeSetupClicked: false,
    });
  }
  onCategoryClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isDealClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isCategoryClicked: true,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isOneTimeSetupClicked: false,
    });
  }
  onOneTimeSetupClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isDealClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isClickableToolClicked: false,
      isCategoryClicked: false,
      isOneTimeSetupClicked: true,
    });
  }

  getRoleData() {
    var data = store.getState();
    let roleData1 = data.roleReducers["roleReducers"];
    this.setState({ featureData: roleData1 });
    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 });
    });
  }

  setDefaultTabOnLoad() {
    const { featureData } = this.state;
    if (featureData.filter((data) => String(data.Submenu) === "Metric" && data.Operations.length !== 0).length > 0) {
      this.setState({
        isMDetailClicked: true,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "Metric Group" && data.Operations.length !== 0).length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,

        isIIClicked: true,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "Work Orchestration Step" && data.Operations.length !== 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,

        isWOClicked: true,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "Deal Approval" && data.Operations.length !== 0).length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,

        isDealClicked: true,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "Location Approval" && data.Operations.length !== 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,
        isLocationClicked: true,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "Client Approval" && data.Operations.length !== 0).length >
      0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,

        isClientClicked: true,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "Industry Approval" && data.Operations.length !== 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,
        isIndustryClicked: true,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "Offering Approval" && data.Operations.length !== 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,
        isOfferingClicked: true,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "ClickableTool Approval" && data.Operations.length !== 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,
        isClickableToolClicked: true,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "CustomTool Approval" && data.Operations.length !== 0)
        .length > 0
    ) {
      this.setState({
        isToolClicked: true,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "Category Approval" && data.Operations.length !== 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isOneTimeSetupClicked: false,
        isCategoryClicked: true,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "Client Region" && data.Operations.length !== 0).length > 0
    ) {
      this.setState({
        isClientRegionClicked: true,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "Delivery Region" && data.Operations.length !== 0).length >
      0
    ) {
      this.setState({
        isDeliveryRegionClicked: true,
      });
    } else if (
      featureData.filter((data) => String(data.Submenu) === "OneTimeSetup Approval" && data.Operations.length !== 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: true,
      });
    }
  }

  setTabOnLoad = (tabName) => {
    const { featureData } = this.state;
    let tabData = [];

    switch (tabName) {
      case "Category_Approval":
        tabData = featureData.filter(
          (data) => String(data.Submenu) === "Category Approval" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onCategoryClick();
        }
        break;
      case "Client_Approval":
        break;
      case "Client_Region":
        tabData = featureData.filter(
          (data) => String(data.Submenu) === "Client Region" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onClientRegionClick();
        }
        break;
      case "CustomTool_Approval":
        tabData = featureData.filter(
          (data) => String(data.Submenu) === "CustomTool Approval" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onToolClick();
        }
        break;
      case "Deal_Approval":
        tabData = featureData.filter(
          (data) => String(data.Submenu) === "Deal Approval" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onDealClick();
        }
        break;
      case "Delivery_Region":
        tabData = featureData.filter(
          (data) => String(data.Submenu) === "Delivery Region" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onDeliveryRegionClick();
        }
        break;
      case "Industry_Approval":
        tabData = featureData.filter(
          (data) => String(data.Submenu) === "Industry Approval" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onIndustryClick();
        }
        break;
      case "Location_Approval":
        tabData = featureData.filter(
          (data) => String(data.Submenu) === "Location Approval" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
        }
        break;
      case "Metric":
        tabData = featureData.filter((data) => String(data.Submenu) === "Metric" && data.Operations.length !== 0);
        if (tabData.length > 0) {
          this.onMDetailClick();
        }
        break;
      case "Metric_Group":
        tabData = featureData.filter((data) => String(data.Submenu) === "Metric Group" && data.Operations.length !== 0);
        if (tabData.length > 0) {
          this.onIIClick();
        }
        break;
      case "Offering_Approval":
        tabData = featureData.filter(
          (data) => String(data.Submenu) === "Offering Approval" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onOfferingClick();
        }
        break;
      case "OneTimeSetup_Approval":
        tabData = featureData.filter(
          (data) => String(data.Submenu) === "OneTimeSetup Approval" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onOneTimeSetupClick();
        }
        break;
      case "Work_Orchestration_Step":
        tabData = featureData.filter(
          (data) => String(data.Submenu) === "Work Orchestration Step" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onWOClick();
        }
        break;
      default:
        
        break;
    }

    let pageHeader = "";
    const pageHeaderStatic = this.props.location.state
      ? this.props.location.state.detail
      : tabData && tabData.length > 0
        ? tabData[0].Submenu
        : "";
    const titleData = displayApprovalFeatureData.filter((f) => {
      return String(f.name) === String(pageHeaderStatic);
    });
    pageHeader =
      titleData.length > 0 && String(titleData[0].displayName) !== "" ? titleData[0].displayName : pageHeaderStatic;

    this.setState({
      pageHeader,
    });
  };

  loadTabBasedOnURL = () => {
    const reqPath = this.props.location.pathname;
    const reqPathArr = reqPath.split("/");
    if (reqPath.startsWith("/admin/Approval") && reqPathArr.length === 4) {
      this.setTabOnLoad(reqPathArr[3]);
    }
  };

  componentWillMount() {
    this.getRoleData();
  }

  componentDidMount() {
    this.getRoleData();
    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 });

      this.loadTabBasedOnURL();
    });

    this.loadTabBasedOnURL();
  }

  componentDidUpdate(nextProps) {
    if (String(this.props.location.pathname) !== nextProps.location.pathname) this.loadTabBasedOnURL();
  }

  render() {
    const { filterDetails } = this.props;
    const {
      pageHeader,
      isMDetailClicked,
      isIIClicked,
      isWOClicked,
      isLocationClicked,
      isClientClicked,
      isDealClicked,
      isIndustryClicked,
      isOfferingClicked,
      isClickableToolClicked,
      isCategoryClicked,
      isClientRegionClicked,
      isDeliveryRegionClicked,
      isToolClicked,
      isOneTimeSetupClicked,
    } = this.state;
    return (
      <div className="main-component">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="grid-heading">{i18n.t("Approval") + " > " + i18n.t(pageHeader)}</h3>
              <div className="tab-list"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <MasterContainer
                filterDetails={filterDetails}
                detailClicked={isMDetailClicked}
                iiClicked={isIIClicked}
                woClicked={isWOClicked}
                LocationClicked={isLocationClicked}
                ClientClicked={isClientClicked}
                DealClicked={isDealClicked}
                IndustryClicked={isIndustryClicked}
                OfferingClicked={isOfferingClicked}
                ClickableToolClicked={isClickableToolClicked}
                CategoryClicked={isCategoryClicked}
                ClientRegionClicked={isClientRegionClicked}
                DeliveryRegionClicked={isDeliveryRegionClicked}
                ToolClicked={isToolClicked}
                OneTimeSetupClicked={isOneTimeSetupClicked}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
