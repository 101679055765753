import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { addMonths, subMonths } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import * as BeforeAfterActions from '../actions/BeforAfterMindMapActions';
import axiosInstance from '../Shared/interceptor';
import { LocalApiBaseUrl } from '../Shared/Constant';
import DeleteIcon from '../Images/exclamation-triangle-grad.svg';

class BeforeAfterListDropdown extends Component {
  constructor() {
    super();
    this.state = {
      currentSectionData: {},
      deletedTimeseriesIndexObjOriginal: [],
      deletedTimeseriesIndex: [],
      mindMapJourney: [],
      MetricError: {
        MetricName: null,
        ValueUnit: null,
        RAGStatus: null,
        Sequence: null,
        BaselineYear: null,
        BaselineValue: null,
        BaselineIOJStage: null,
        CurrentYear: null,
        CurrentValue: null,
        CurrentIOJStage: null,
        TargetYear: null,
        TargetValue: null,
        TargetIOJStage: null,
        TargetYear2: '',
        TargetValue2: '',
        TargetIOJStage2: null,
        IsDeleted: false,
        NodeName: null,
        TimeseriesData: [],
      },
      MetricData: {
        Id: 0,
        KPIMappingID: null,
        MetricName: '',
        MetricUnit: '',
        RAGStatus: '',
        Sequence: null,
        BaselineYear: '',
        BaselineIOJStage: null,
        BaselineValue: '',
        CurrentYear: new Date(new Date().setDate(10)).toISOString(),
        CurrentValue: '',
        CurrentIOJStage: null,
        TargetYear: '',
        TargetValue: '',
        TargetIOJStage: null,
        TargetYear2: '',
        TargetValue2: '',
        TargetIOJStage2: null,
        IsDeleted: false,
        TimeseriesData: [],
      },
      formValidated: false,
      Units: ['Percentage', 'Count', 'Range'],
      RAGStatus: [
        { id: 'R', name: 'Red', class: 'redColor' },
        { id: 'A', name: 'Amber', class: 'amberColor' },
        { id: 'G', name: 'Green', class: 'greenColor' },
        { id: 'Y', name: 'Grey', class: 'greyColor' },
      ],
      hasMetrices: ['BO', 'QA', 'COMP'],
      IODropdown: ['Foundation', 'Automated', 'Insights Driven', 'Intelligent'],
      selectedDropdownData: [],
      dropdownList: [],
      selectedDropdownName: '',
      ragClass: '',
      leverDropdown: [
        { name: 'Business Outcome', keyWord: 'BO' },
        { name: 'Human + Machine Talent', keyWord: 'HM' },
        { name: 'Work Orchestration', keyWord: 'WO' },
        { name: 'Insights & Intelligence', keyWord: 'II' },
        { name: 'Diverse Data', keyWord: 'DD' },
        { name: 'Add Custom', keyWord: 'AC' },
      ],
      selectedLever: '',
    };
  }

  componentDidMount() {
    const {
      name, isEditMode, configrationData, editFormData, configrationDataMindMapJourney,
    } = this.props;
    const {
      hasMetrices, MetricError, deletedTimeseriesIndexObjOriginal, RAGStatus,
    } = this.state;
    let { mindMapJourney } = this.state;
    mindMapJourney = _.cloneDeep(configrationDataMindMapJourney);
    const MetricData = _.cloneDeep(editFormData.editFormData);
    const currentSectionData = _.cloneDeep(configrationData[name]);
    if (!currentSectionData.CurrentYear) {
      currentSectionData.CurrentYear = new Date(new Date().setDate(10)).toISOString();
    }
    if (!currentSectionData.DisplayName) {
      currentSectionData.DisplayName = currentSectionData.SectionName;
    }
    if (editFormData.isEditMode && hasMetrices.includes(name)) {
      this.setState({ MetricData }, () => {
        const tempRAG = RAGStatus.filter((rag) => rag.id === editFormData.editFormData.RAGStatus);
        this.setState({
          ragClass: tempRAG[0].class,
        });
        if (
          this.state.MetricData.TimeseriesData
          && this.state.MetricData.TimeseriesData.length > 0
        ) {
          this.state.MetricData.TimeseriesData.forEach((each) => {
            if (!each.isDeleted) {
              deletedTimeseriesIndexObjOriginal.push(each);
            }

            MetricError.TimeseriesData.push({
              Year: null,
              Value: null,
              IOJStage: null,
            });
          });
        }
      });
    }
    this.setState({ currentSectionData, deletedTimeseriesIndexObjOriginal, mindMapJourney }, () => {
      if (
        this.state.currentSectionData.TimeseriesData
        && this.state.currentSectionData.TimeseriesData.length > 0
      ) {
        this.state.currentSectionData.TimeseriesData.forEach((each) => {
          if (!hasMetrices.includes(name) && !each.isDeleted) {
            deletedTimeseriesIndexObjOriginal.push(each);
          }
          MetricError.TimeseriesData.push({
            Year: null,
            Value: null,
            IOJStage: null,
          });
        });
      }
      this.setState({
        deletedTimeseriesIndexObjOriginal,
      });
    });
    if (name === 'BO') {
      this.handleLeverDropdown({ name: 'Business Outcome', keyWord: 'BO' });
    }
    // const tempFormData = [...formData];
    // const tempIndex = isFormOpen.findIndex((a) => a.name === name);
    // if (isFormOpen[tempIndex].FormData.length > 0) {
    //   tempFormData = isFormOpen[tempIndex].FormData;
    //   tempcurrentFormData.ragStatus = null;
    //   if (tempcurrentFormData.BaselineYear !== null) {
    //     const baselineYear = new Date(tempcurrentFormData.BaselineYear);
    //     tempcurrentFormData.BaselineYear = baselineYear;
    //   }
    //   if (tempcurrentFormData.TargetYear !== null) {
    //     const targetYear = new Date(tempcurrentFormData.TargetYear);
    //     tempcurrentFormData.TargetYear = targetYear;
    //   }
    //   if (tempcurrentFormData.TimeseriesData.length > 0) {
    //     tempcurrentFormData.TimeseriesData.forEach((a, index) => {
    //       if (a.Year !== null) {
    //         const timeseriesYear = new Date(a.Year);
    //         tempcurrentFormData.TimeseriesData[index].Year = timeseriesYear;
    //       }
    //     });
    //   }
    // }
    // this.setState({
    //   formData: tempFormData,
    // });
  }

  // componentDidUpdate(prevProps) {
  //   const {
  //     isFormOpen, name,
  //   } = this.props;
  //   const { formData, hasMetrices } = this.state;
  //   const currentSectionData = { ...configrationData[name] };
  //   // {
  //   //   Id:0, Year: '', Value: '', IOJStage: null, IsDeleted: false,
  //   // }
  //   if (prevProps.isFormOpen.state !== isFormOpen.state) {
  //     let tempFormData = [...formData];
  //     const tempIndex = isFormOpen.findIndex((a) => a.name === name);
  //     if (isFormOpen[tempIndex].FormData !== []) {
  //       tempFormData = isFormOpen[tempIndex].FormData;
  //     }
  //     this.setState({
  //       formData: tempFormData,
  //     });
  //   }
  // }
  onNodeNameChange = (event) => {
    const { currentSectionData } = this.state;
    currentSectionData.DisplayName = event.target.value;
    this.setState({
      currentSectionData,
    });
  };

  handleUnitSelection(item) {
    const {
      MetricData, currentSectionData, hasMetrices, MetricError,
    } = this.state;
    const { name } = this.props;
    if (hasMetrices.includes(name)) {
      MetricData.MetricUnit = item;
    } else {
      currentSectionData.ValueUnit = item;
    }
    MetricError.ValueUnit = null;
    this.setState({
      currentSectionData,
      MetricData,
      MetricError,
    });
  }

  handleRAGSelection(item) {
    const { MetricData, currentSectionData, hasMetrices } = this.state;
    const { name } = this.props;
    if (hasMetrices.includes(name)) {
      MetricData.RAGStatus = item.id;
    } else {
      currentSectionData.RAGStatus = item.id;
    }
    this.setState({
      ragClass: item.class,
      currentSectionData,
      MetricData,
    });
  }

  addTimeseries = () => {
    const {
      MetricData, currentSectionData, hasMetrices, MetricError,
    } = this.state;
    const { name } = this.props;
    const tempMetricError = {
      MetricName: null,
      ValueUnit: null,
      RAGStatus: null,
      Sequence: null,
      BaselineYear: null,
      BaselineValue: null,
      BaselineMachineValue: null,
      CurrentYear: null,
      CurrentValue: null,
      CurrentMachineValue: null,
      TargetYear: null,
      TargetValue: null,
      TargetMachineValue: null,
      TargetYear2: '',
      TargetValue2: '',
      TargetMachineValue2: null,
      IsDeleted: false,
      TimeseriesData: [],
    };
    if (hasMetrices.includes(name)) {
      MetricData.TimeseriesData.push({
        Id: 0,
        Year: '',
        Value: '',
        IOJStage: null,
        isDeleted: false,
        uniqueId: uuidv4(),
      });
      MetricData.TimeseriesData.sort((a, b) => a.isDeleted - b.isDeleted);
    } else {
      currentSectionData.TimeseriesData.push({
        Id: 0,
        Year: '',
        Value: '',
        IOJStage: null,
        isDeleted: false,
        uniqueId: uuidv4(),
      });
      currentSectionData.TimeseriesData.sort((a, b) => a.isDeleted - b.isDeleted);
    }

    this.setState({
      currentSectionData,
      MetricData,
      MetricError: tempMetricError,
      formValidated: false,
    });
  };

  handleIODropdownValue(item, type, index) {
    const {
      MetricData, currentSectionData, hasMetrices, MetricError,
    } = this.state;
    const tempMetricError = { ...MetricError };
    const isMetricData = hasMetrices.includes(name);
    if (type === 'Baseline') {
      if (isMetricData) {
        MetricData.BaselineIOJStage = item;
      } else {
        currentSectionData.BaselineIOJStage = item;
      }
      tempMetricError.BaselineIOJStage = null;
    } else if (type === 'Current') {
      if (isMetricData) {
        MetricData.CurrentIOJStage = item;
      } else {
        currentSectionData.CurrentIOJStage = item;
      }
      tempMetricError.CurrentIOJStage = null;
    } else if (type === 'Target') {
      if (isMetricData) {
        MetricData.TargetIOJStage = item;
      } else {
        currentSectionData.TargetIOJStage = item;
      }
      tempMetricError.TargetIOJStage = null;
    } else if (type === 'Target2') {
      if (isMetricData) {
        MetricData.TargetIOJStage2 = item;
      } else {
        currentSectionData.TargetIOJStage2 = item;
      }
      tempMetricError.TargetIOJStage2 = null;
    } else if (isMetricData) {
      MetricData.TimeseriesData[index].IOJStage = item;
      // tempMetricError.TimeseriesData[index].IOJStage = null;
    } else {
      currentSectionData.TimeseriesData[index].IOJStage = item;
      // tempMetricError.TimeseriesData[index].IOJStage = null;
    }
    this.setState({
      currentSectionData,
      MetricData,
      MetricError: tempMetricError,
    });
  }

  isNumber = (evt) => {
    if (/^\d+\.?\d*$/.test(evt) || evt === '') {
      return true;
    }
    return false;
  };

  handleValueChange = (event, type, index) => {
    const {
      MetricData, currentSectionData, hasMetrices, MetricError,
    } = this.state;
    const { name } = this.props;
    const tempMetricError = { ...MetricError };
    const isMetricData = hasMetrices.includes(name);
    if (type === 'Baseline') {
      if (isMetricData) {
        MetricData.BaselineValue = event.target.value;
      } else if (name === 'HnM') {
        if (this.isNumber(event.target.value)) {
          let splitValueBaseline = currentSectionData.BaselineValue.split('-');
          if (splitValueBaseline.length) {
            splitValueBaseline[0] = event.target.value;
            splitValueBaseline = splitValueBaseline.join('-');
          } else {
            splitValueBaseline = `${event.target.value}-`;
          }
          currentSectionData.BaselineValue = splitValueBaseline;
        }
      } else {
        currentSectionData.BaselineValue = event.target.value;
      }
      tempMetricError.BaselineValue = null;
    } else if (type === 'BaselineMachine') {
      if (this.isNumber(event.target.value)) {
        let splitValueBaselineMachine = currentSectionData.BaselineValue.split('-');
        if (splitValueBaselineMachine.length > 1) {
          splitValueBaselineMachine[1] = event.target.value;
          splitValueBaselineMachine = splitValueBaselineMachine.join('-');
        } else {
          splitValueBaselineMachine = `${currentSectionData.BaselineValue}-${event.target.value}`;
        }
        currentSectionData.BaselineValue = splitValueBaselineMachine;
      }
    } else if (type === 'CurrentMachine') {
      if (this.isNumber(event.target.value)) {
        let splitValueCurrentMachine = currentSectionData.CurrentValue.split('-');
        if (splitValueCurrentMachine.length > 1) {
          splitValueCurrentMachine[1] = event.target.value;
          splitValueCurrentMachine = splitValueCurrentMachine.join('-');
        } else {
          splitValueCurrentMachine = `${currentSectionData.CurrentValue}-${event.target.value}`;
        }
        currentSectionData.CurrentValue = splitValueCurrentMachine;
      }
    } else if (type === 'TargetMachine') {
      if (this.isNumber(event.target.value)) {
        let splitValueTargetMachine = currentSectionData.TargetValue.split('-');
        if (splitValueTargetMachine.length > 1) {
          splitValueTargetMachine[1] = event.target.value;
          splitValueTargetMachine = splitValueTargetMachine.join('-');
        } else {
          splitValueTargetMachine = `${currentSectionData.TargetValue}-${event.target.value}`;
        }
        currentSectionData.TargetValue = splitValueTargetMachine;
      }
    } else if (type === 'TargetMachine2') {
      if (this.isNumber(event.target.value)) {
        let splitValueTargetMachine = currentSectionData.TargetValue2.split('-');
        if (splitValueTargetMachine.length > 1) {
          splitValueTargetMachine[1] = event.target.value;
          splitValueTargetMachine = splitValueTargetMachine.join('-');
        } else {
          splitValueTargetMachine = `${currentSectionData.TargetValue2}-${event.target.value}`;
        }
        currentSectionData.TargetValue2 = splitValueTargetMachine;
      }
    } else if (type === 'Current') {
      if (isMetricData) {
        MetricData.CurrentValue = event.target.value;
      } else if (name === 'HnM') {
        if (this.isNumber(event.target.value)) {
          let splitValueCurrent = currentSectionData.CurrentValue.split('-');
          if (splitValueCurrent.length) {
            splitValueCurrent[0] = event.target.value;
            splitValueCurrent = splitValueCurrent.join('-');
          } else {
            splitValueCurrent = `${event.target.value}-`;
          }
          currentSectionData.CurrentValue = splitValueCurrent;
        }
      } else {
        currentSectionData.CurrentValue = event.target.value;
      }
      tempMetricError.CurrentValue = null;
    } else if (type === 'Target') {
      if (isMetricData) {
        MetricData.TargetValue = event.target.value;
      } else if (name === 'HnM') {
        if (this.isNumber(event.target.value)) {
          let splitValueTarget = currentSectionData.TargetValue.split('-');
          if (splitValueTarget.length) {
            splitValueTarget[0] = event.target.value;
            splitValueTarget = splitValueTarget.join('-');
          } else {
            splitValueTarget = `${event.target.value}-`;
          }
          currentSectionData.TargetValue = splitValueTarget;
        }
      } else {
        currentSectionData.TargetValue = event.target.value;
      }
      tempMetricError.TargetValue = null;
    } else if (type === 'Target2') {
      if (isMetricData) {
        MetricData.TargetValue2 = event.target.value;
      } else if (name === 'HnM') {
        if (this.isNumber(event.target.value)) {
          let splitValueTarget = currentSectionData.TargetValue2.split('-');
          if (splitValueTarget.length) {
            splitValueTarget[0] = event.target.value;
            splitValueTarget = splitValueTarget.join('-');
          } else {
            splitValueTarget = `${event.target.value}-`;
          }
          currentSectionData.TargetValue2 = splitValueTarget;
        }
      } else {
        currentSectionData.TargetValue2 = event.target.value;
      }
      tempMetricError.TargetValue2 = null;
    } else if (type === 'Timeseries') {
      if (isMetricData) {
        MetricData.TimeseriesData[index].Value = event.target.value;
      } else if (name === 'HnM') {
        if (this.isNumber(event.target.value)) {
          let splitValueTimeseries = currentSectionData.TimeseriesData[index].Value.split('-');
          if (splitValueTimeseries.length) {
            splitValueTimeseries[0] = event.target.value;
            splitValueTimeseries = splitValueTimeseries.join('-');
          } else {
            splitValueTimeseries = `${event.target.value}-`;
          }
          currentSectionData.TimeseriesData[index].Value = splitValueTimeseries;
        }
      } else {
        currentSectionData.TimeseriesData[index].Value = event.target.value;
      }
    } else if (type === 'TimeseriesMachine') {
      if (this.isNumber(event.target.value)) {
        let splitValueTimeseriesMachine = currentSectionData.TimeseriesData[index].Value.split('-');
        if (splitValueTimeseriesMachine.length > 1) {
          splitValueTimeseriesMachine[1] = event.target.value;
          splitValueTimeseriesMachine = splitValueTimeseriesMachine.join('-');
        } else {
          splitValueTimeseriesMachine = `${currentSectionData.TimeseriesData[index].Value}-${event.target.value}`;
        }
        currentSectionData.TimeseriesData[index].Value = splitValueTimeseriesMachine;
      }
    }

    this.setState({
      currentSectionData,
      MetricData,
      MetricError: tempMetricError,
    });
  };

  handleSequenceChange = (event) => {
    const { MetricData, MetricError } = this.state;
    const tempMetricData = { ...MetricData };
    const tempMetricError = { ...MetricError };
    tempMetricError.Sequence = null;
    tempMetricData.Sequence = event.target.value;
    this.setState({
      MetricData: tempMetricData,
      MetricError: tempMetricError,
    });
  };

  handleDateChange = (tempdate, type, index) => {
    const {
      MetricData, currentSectionData, hasMetrices, MetricError,
    } = this.state;
    const { name } = this.props;
    const tempMetricError = { ...MetricError };
    const isMetricData = hasMetrices.includes(name);
    const date = tempdate ? new Date(new Date(tempdate).setDate(10)).toISOString() : null;
    if (type === 'Baseline') {
      if (isMetricData) {
        MetricData.BaselineYear = date;
      } else {
        currentSectionData.BaselineYear = date;
      }
      tempMetricError.BaselineYear = null;
    } else if (type === 'Current') {
      if (isMetricData) {
        MetricData.CurrentYear = date;
      } else {
        currentSectionData.CurrentYear = date;
      }
      tempMetricError.CurrentYear = null;
    } else if (type === 'Target') {
      if (isMetricData) {
        MetricData.TargetYear = date;
      } else {
        currentSectionData.TargetYear = date;
      }
      tempMetricError.TargetYear = null;
    } else if (type === 'Target2') {
      if (isMetricData) {
        MetricData.TargetYear2 = date;
      } else {
        currentSectionData.TargetYear2 = date;
      }
      currentSectionData.TargetIOJStage2 = !_.isEmpty(currentSectionData.TargetYear2) ? currentSectionData.TargetIOJStage2 : '';
      tempMetricError.TargetYear2 = null;
    } else if (isMetricData) {
      MetricData.TimeseriesData[index].Year = date;
      if (tempMetricError.TimeseriesData[index]) {
        tempMetricError.TimeseriesData[index].Year = null;
      }
    } else {
      if (tempMetricError.TimeseriesData[index]) {
        tempMetricError.TimeseriesData[index].Year = null;
      }
      currentSectionData.TimeseriesData[index].Year = date;
    }
    this.setState({
      currentSectionData,
      MetricData,
      MetricError: tempMetricError,
    });
  };

  handleDropdownValue(item) {
    const { MetricData } = this.state;
    const tempMetricData = {
      Id: 0,
      KPIMappingID: null,
      MetricName: '',
      MetricUnit: '',
      RAGStatus: '',
      Sequence: '',
      BaselineYear: '',
      BaselineIOJStage: null,
      BaselineValue: '',
      CurrentYear: new Date(new Date().setDate(10)).toISOString(),
      CurrentValue: '',
      CurrentIOJStage: null,
      TargetYear: '',
      TargetValue: '',
      TargetIOJStage: null,
      TargetYear2: null,
      TargetValue2: null,
      TargetIOJStage2: null,
      IsDeleted: false,
      TimeseriesData: [],
    };
    const tempMetricError = {
      MetricName: null,
      ValueUnit: null,
      RAGStatus: null,
      Sequence: null,
      BaselineYear: null,
      BaselineValue: null,
      BaselineMachineValue: null,
      CurrentYear: null,
      CurrentValue: null,
      CurrentMachineValue: null,
      TargetYear: null,
      TargetValue: null,
      TargetMachineValue: null,
      TargetYear2: null,
      TargetValue2: null,
      TargetMachineValue2: null,
      IsDeleted: false,
      TimeseriesData: [],
    };
    this.setState({
      selectedDropdownName: item.MetricName,
    });
    tempMetricData.KPIMappingID = item.KpiMappingId;
    tempMetricData.MetricName = item.MetricName;
    this.setState({
      MetricData: tempMetricData,
      MetricError: tempMetricError,
    });
  }

  handleForm = (name) => {
    const { handleForm } = this.props;
    handleForm(name, false);
  };

  formSubmit = () => {
    const {
      name,
      closeForm,
      saveUpdatedConfigData,
      editFormData,
      saveUpdatedMetricConfigData,
      removeDrillDownJourneyDataTimeSeries,
      removeDrillDownJourneyDataMetricTimeSeries,
      setMindMapJourneyData,
    } = this.props;
    const {
      MetricData,
      currentSectionData,
      hasMetrices,
      MetricError,
      deletedTimeseriesIndex,
      deletedTimeseriesIndexObjOriginal,
      mindMapJourney,
    } = this.state;
    const isMetricData = hasMetrices.includes(name);
    const tempMetricError = {
      MetricName: null,
      ValueUnit: null,
      RAGStatus: null,
      Sequence: null,
      BaselineYear: null,
      BaselineValue: null,
      BaselineMachineValue: null,
      CurrentYear: null,
      CurrentValue: null,
      CurrentMachineValue: null,
      TargetYear: null,
      TargetValue: null,
      TargetMachineValue: null,
      TargetYear2: null,
      TargetValue2: null,
      TargetMachineValue2: null,
      IsDeleted: false,
      TimeseriesData: [],
    };

    if (hasMetrices.includes(name)) {
      if (!MetricData.CurrentYear) {
        MetricData.CurrentYear = new Date();
      }
    } else if (!currentSectionData.CurrentYear) {
      currentSectionData.CurrentYear = new Date();
    }
    this.setState(
      {
        formValidated: false,
        MetricError: tempMetricError,
        currentSectionData,
        MetricData,
      },
      () => {
        if (this.validateForm()) {
          MetricData.TimeseriesData.sort((a, b) => a.isDeleted - b.isDeleted);
          currentSectionData.TimeseriesData.sort((a, b) => a.isDeleted - b.isDeleted);
          setMindMapJourneyData(mindMapJourney);
          if (isMetricData) {
            saveUpdatedMetricConfigData({ MetricData, name });
          } else {
            saveUpdatedConfigData({ currentSectionData, name });
          }
          /*         //DELETE TIME SERIES LOGIC
        const originalLength =  deletedTimeseriesIndexObjOriginal.length
        const currentTimeseriesLength = hasMetrices.includes(name) ?
        MetricData.TimeseriesData.filter((a) => !a.isDeleted).length : currentSectionData.TimeseriesData.filter((a) => !a.isDeleted).length;
        const SectionId = currentSectionData.SectionId;
        if(originalLength > currentTimeseriesLength)
        { //TIME SERIES IS DELETED
          const diff = (originalLength - currentTimeseriesLength);
          const indexArray = Array.from(Array(originalLength).keys());
          const loopElement = indexArray.slice(Math.max(indexArray.length - diff, 0));
          loopElement.forEach((i) => {
            const payload = {
              DeleteIndex: i,
              SectionId,
            };
            if (hasMetrices.includes(name)) {
              const MetricID = MetricData.Id;
              const KPIMappingID = MetricData.KPIMappingID !== null ? MetricData.KPIMappingID : 0;
              const DeleteIndex = i;
              const { MetricName } = MetricData;
              const metricpayload = {
                SectionId,
                MetricID,
                KPIMappingID,
                DeleteIndex,
                MetricName,
              };
              if(hasMetrices.includes(name))
              {
                const MetricID = MetricData.Id;
                const KPIMappingID = MetricData.KPIMappingID !== null ? MetricData.KPIMappingID : 0;
                const DeleteIndex = i;
                const MetricName = MetricData.MetricName;
                let metricpayload = {
                  SectionId,
                  MetricID,
                  KPIMappingID,
                  DeleteIndex,
                  MetricName,
                };
                removeDrillDownJourneyDataMetricTimeSeries(metricpayload);
              }
              else
              {
                removeDrillDownJourneyDataTimeSeries(payload);
              }

            })
        } */

          /*         deletedTimeseriesIndex.forEach((item) => {
          let payload = {
            DeleteIndex:"",
            SectionId:""
          };
          payload.DeleteIndex = item;
          if (hasMetrices.includes(name)) {
            payload.SectionId = MetricData.SectionId;
          } else {
            payload.SectionId = currentSectionData.SectionId;
          }
          removeDrillDownJourneyDataTimeSeries(payload);
        }); */
          closeForm('');
          this.setState({
            formValidated: false,
          });
        }
      },
    );
  };

  validateForm = () => {
    const {
      MetricData, currentSectionData, hasMetrices, MetricError, selectedLever,
    } = this.state;
    const stringValueTypes = ['DSP', 'WC', 'NPS'];
    const { name } = this.props;
    const { configrationData } = this.props;
    const tempMetricError = { ...MetricError };
    let tempFormData = {};
    let isFormValid = true;
    if (hasMetrices.includes(name)) {
      tempFormData = { ...MetricData };
    } else {
      tempFormData = { ...currentSectionData };
      if (configrationData[name].IsNameEditable) {
        if (currentSectionData.DisplayName) {
          Object.keys(configrationData).map((each) => {
            if (
              each !== name
              && configrationData[each].IsNameEditable
              && configrationData[each].DisplayName
              && currentSectionData.DisplayName === configrationData[each].DisplayName
            ) {
              isFormValid = false;
              tempMetricError.NodeName = 'Display Name Already Exist';
            } else if (each !== name && currentSectionData.DisplayName === configrationData[each].DisplayName) {
              isFormValid = false;
              tempMetricError.NodeName = 'Display Name Already Exist';
            }
          });
        } else {
          isFormValid = false;
          tempMetricError.NodeName = 'Display Name Is Required';
        }
      }
    }

    if (name !== 'IO') {
      if (hasMetrices.includes(name)) {
        if (!tempFormData.MetricUnit) {
          tempMetricError.ValueUnit = 'Unit is required';
          isFormValid = false;
        }
      } else if (!tempFormData.ValueUnit) {
        tempMetricError.ValueUnit = 'Unit is required';
        isFormValid = false;
      }
    }

    if (selectedLever === 'Add Custom') {
      if (!tempFormData.MetricName) {
        tempMetricError.MetricName = 'Name is required';
        isFormValid = false;
      }
    }

    if (hasMetrices.includes(name)) {
      if (!tempFormData.Sequence) {
        tempMetricError.Sequence = 'Sequence is required';
        isFormValid = false;
      } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(tempFormData.Sequence)) {
        tempMetricError.Sequence = 'Sequence must be integer';
        isFormValid = false;
      } else if (
        parseFloat(tempFormData.Sequence) < 1
        || parseFloat(tempFormData.Sequence) > 999999
      ) {
        tempMetricError.Sequence = 'Sequence value must be in between 1 to 999999';
        isFormValid = false;
      }
      if (!tempFormData.RAGStatus) {
        tempMetricError.RAGStatus = 'RAG status is required';
        isFormValid = false;
      }
    }

    if (name === 'IO') {
      if (!tempFormData.BaselineIOJStage) {
        tempMetricError.BaselineIOJStage = 'Stage is required';
        isFormValid = false;
      }
      if (!tempFormData.CurrentIOJStage) {
        tempMetricError.CurrentIOJStage = 'Stage is required';
        isFormValid = false;
      }
      if (!tempFormData.TargetIOJStage) {
        tempMetricError.TargetIOJStage = 'Stage is required';
        isFormValid = false;
      }
      if (tempFormData.TargetYear2 && !tempFormData.TargetIOJStage2) {
        tempMetricError.TargetIOJStage2 = 'Stage is required';
        isFormValid = false;
      }
    }

    if (!tempFormData.BaselineYear) {
      tempMetricError.BaselineYear = 'Baseline Year is required';
      isFormValid = false;
    } else {
      const baseYear = new Date(tempFormData.BaselineYear);
      const targetYear = new Date(tempFormData.TargetYear);
      const currentYear = new Date();
      if (tempFormData.BaselineYear && tempFormData.TargetYear && baseYear > targetYear) {
        tempMetricError.BaselineYear = 'Baseline year should be less than target year';
        isFormValid = false;
      } else {
        const baseYear = new Date(tempFormData.BaselineYear);
        const targetYear = new Date(tempFormData.TargetYear);
        const currentYear = new Date(tempFormData.CurrentYear);
        if (tempFormData.TargetYear) {
          if (baseYear > targetYear) {
            tempMetricError.BaselineYear = 'Baseline year should be less than target year';
            isFormValid = false;
          }
        }
        if (baseYear > currentYear) {
          tempMetricError.BaselineYear = 'Baseline year should be less than current year';
          isFormValid = false;
        }
      }
    }
    if (name !== 'HnM') {
      if (name === 'IO') {
        tempFormData.BaselineValue = '1';
      }
      if (!tempFormData.BaselineValue) {
        tempMetricError.BaselineValue = 'Baseline Value is required';
        isFormValid = false;
      } else if (stringValueTypes.includes(name) && (tempFormData.MetricUnit === 'String' || tempFormData.ValueUnit === 'String')) {
        isFormValid = isFormValid;
      } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(tempFormData.BaselineValue)) {
        tempMetricError.BaselineValue = 'Value should be integer';
        isFormValid = false;
      } else if (
        parseFloat(tempFormData.BaselineValue, 10) < -99999999
        || parseFloat(tempFormData.BaselineValue, 10) > 99999999
      ) {
        tempMetricError.BaselineValue = 'Value must be between -99999999 and 99999999';
        isFormValid = false;
      }
    } else {
      const splitValue = tempFormData.BaselineValue.split('-');
      if (!splitValue[0]) {
        tempMetricError.BaselineValue = 'Value is required';
        isFormValid = false;
      } else if (
        parseFloat(splitValue[0], 10) < -99999999
        || parseFloat(splitValue[0], 10) > 99999999
      ) {
        tempMetricError.BaselineValue = 'Value must be between 0 and 99999999';
        isFormValid = false;
      } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(splitValue[0])) {
        tempMetricError.BaselineValue = 'Value should be integer';
        isFormValid = false;
      }
      if (!splitValue[1]) {
        tempMetricError.BaselineMachineValue = 'Value is required';
        isFormValid = false;
      } else if (parseFloat(splitValue[1], 10) > 99999999) {
        tempMetricError.BaselineMachineValue = 'Value must be between 0 and 99999999';
        isFormValid = false;
      } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(splitValue[1])) {
        tempMetricError.BaselineMachineValue = 'Value should be integer';
        isFormValid = false;
      }
    }

    if (name !== 'HnM') {
      if (name === 'IO') {
        tempFormData.CurrentValue = '1';
      }
      if (!tempFormData.CurrentValue) {
        tempMetricError.CurrentValue = 'Current Value is required';
        isFormValid = false;
      } else if (stringValueTypes.includes(name) && (tempFormData.MetricUnit === 'String' || tempFormData.ValueUnit === 'String')) {
        isFormValid = isFormValid;
      } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(tempFormData.CurrentValue)) {
        tempMetricError.CurrentValue = 'Value should be integer';
        isFormValid = false;
      } else if (
        parseFloat(tempFormData.CurrentValue, 10) < -99999999
        || parseFloat(tempFormData.CurrentValue, 10) > 99999999
      ) {
        tempMetricError.CurrentValue = 'Value must be between -99999999 and 99999999';
        isFormValid = false;
      }
    } else {
      const splitValue = tempFormData.CurrentValue.split('-');
      if (!splitValue[0]) {
        tempMetricError.CurrentValue = 'Value is required';
        isFormValid = false;
      } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(splitValue[0])) {
        tempMetricError.CurrentValue = 'Value must be integer';
        isFormValid = false;
      } else if (parseFloat(splitValue[0], 10) > 99999999) {
        tempMetricError.CurrentValue = 'Value must be between 0 and 99999999';
        isFormValid = false;
      }
      if (!splitValue[1]) {
        tempMetricError.CurrentMachineValue = 'Value is required';
        isFormValid = false;
      } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(splitValue[1])) {
        tempMetricError.CurrentMachineValue = 'Value must be integer';
        isFormValid = false;
      } else if (parseFloat(splitValue[1], 10) > 99999999) {
        tempMetricError.CurrentMachineValue = 'Value must be between 0 and 99999999';
        isFormValid = false;
      }
    }
    if (!tempFormData.TargetYear) {
      tempMetricError.TargetYear = 'Target 1 Year is required';
      isFormValid = false;
    } else {
      const targetYear = new Date(tempFormData.TargetYear);
      const currentYear = new Date(tempFormData.CurrentYear);
      if (targetYear < currentYear) {
        tempMetricError.TargetYear = 'Target 1 year should be greater than current year';
        isFormValid = false;
      }
    }
    if (!tempFormData.TargetYear2 && (tempFormData.TargetValue2 || tempFormData.TargetIOJStage2)) {
      tempMetricError.TargetYear2 = 'Target 2 Year  is required';
      isFormValid = false;
    } else if (tempFormData.TargetYear2) {
      const targetYear = new Date(tempFormData.TargetYear);
      const targetYear2 = new Date(tempFormData.TargetYear2);
      if (targetYear2 <= targetYear) {
        tempMetricError.TargetYear2 = 'Target 2 year  should be greater than target 1 year ';
        isFormValid = false;
      }
    }
    if (name !== 'HnM') {
      if (name === 'IO') {
        tempFormData.TargetValue = '1';
      }
      if (!tempFormData.TargetValue) {
        tempMetricError.TargetValue = 'Target 1 Value is required';
        isFormValid = false;
      } else if (stringValueTypes.includes(name) && (tempFormData.MetricUnit === 'String' || tempFormData.ValueUnit === 'String')) {
        isFormValid = isFormValid;
      } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(tempFormData.TargetValue)) {
        tempMetricError.TargetValue = 'Value should be integer';
        isFormValid = false;
      } else if (
        parseFloat(tempFormData.TargetValue, 10) < -99999999
        || parseFloat(tempFormData.TargetValue, 10) > 99999999
      ) {
        tempMetricError.TargetValue = 'Value must be between -99999999 and 99999999';
        isFormValid = false;
      }
    } else {
      const splitValue = tempFormData.TargetValue.split('-');
      if (!splitValue[0]) {
        tempMetricError.TargetValue = 'Value is required';
        isFormValid = false;
      } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(splitValue[0])) {
        tempMetricError.TargetValue = 'Value should be integer';
        isFormValid = false;
      } else if (parseFloat(splitValue[0], 10) > 99999999) {
        tempMetricError.TargetValue = 'Value must be between 0 and 99999999';
        isFormValid = false;
      }
      if (!splitValue[1]) {
        tempMetricError.TargetMachineValue = 'Value is required';
        isFormValid = false;
      } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(splitValue[1])) {
        tempMetricError.TargetValue = 'Value should be integer';
        isFormValid = false;
      } else if (parseFloat(splitValue[1], 10) > 99999999) {
        tempMetricError.TargetMachineValue = 'Value must be between 0 and 99999999';
        isFormValid = false;
      }
    }

    if (name !== 'HnM') {
      if (name === 'IO' && tempFormData.TargetYear2) {
        tempFormData.TargetValue2 = '1';
      }
      if (tempFormData.TargetYear2) {
        if (!tempFormData.TargetValue2) {
          tempMetricError.TargetValue2 = 'Target 2 Value is required';
          isFormValid = false;
        } else if (stringValueTypes.includes(name) && (tempFormData.MetricUnit === 'String' || tempFormData.ValueUnit === 'String')) {
          isFormValid = isFormValid;
        } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(tempFormData.TargetValue2)) {
          tempMetricError.TargetValue2 = 'Value should be integer';
          isFormValid = false;
        } else if (
          parseFloat(tempFormData.TargetValue2, 10) < -99999999
        || parseFloat(tempFormData.TargetValue2, 10) > 99999999
        ) {
          tempMetricError.TargetValue2 = 'Value must be between -99999999 and 99999999';
          isFormValid = false;
        }
      }
    } else if (tempFormData.TargetYear2) {
      const splitValue = tempFormData.TargetValue2.split('-');
      if (!splitValue[0]) {
        tempMetricError.TargetValue2 = 'Value is required';
        isFormValid = false;
      } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(splitValue[0])) {
        tempMetricError.TargetValue2 = 'Value should be integer';
        isFormValid = false;
      } else if (parseFloat(splitValue[0], 10) > 99999999) {
        tempMetricError.TargetValue2 = 'Value must be between 0 and 99999999';
        isFormValid = false;
      }
      if (!splitValue[1]) {
        tempMetricError.TargetMachineValue2 = 'Value is required';
        isFormValid = false;
      } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(splitValue[1])) {
        tempMetricError.TargetValue2 = 'Value should be integer';
        isFormValid = false;
      } else if (parseFloat(splitValue[1], 10) > 99999999) {
        tempMetricError.TargetMachineValue2 = 'Value must be between 0 and 99999999';
        isFormValid = false;
      }
    }

    const tempTimeseriesData = tempFormData.TimeseriesData.filter((a) => a.isDeleted === false);

    if (tempTimeseriesData.length > 0) {
      tempTimeseriesData.forEach((item, index) => {
        if (!item.isDeleted) {
          tempMetricError.TimeseriesData.push({
            Year: null,
            Value: null,
            IOJStage: null,
            MachineValue: null,
          });
          if (name === 'IO') {
            if (!item.IOJStage) {
              tempMetricError.TimeseriesData[index].IOJStage = 'Stage is required';
              isFormValid = false;
            }

            if (!item.Year) {
              tempMetricError.TimeseriesData[index].Year = `Timeseries ${index} Year is required`;
              isFormValid = false;
            } else if (item.Year && index === 0) {
              const baseYear = new Date(tempFormData.BaselineYear);
              const targetYear = new Date(tempFormData.TargetYear);
              const currentYear = new Date(tempFormData.CurrentYear);
              const temptimeseriesYear = new Date(tempFormData.TimeseriesData[index].Year);
              if (tempFormData.BaselineYear && baseYear >= temptimeseriesYear) {
                tempMetricError.TimeseriesData[index].Year = 'Year should be greater than baseline year';
                isFormValid = false;
              }
              if (tempFormData.TargetYear && temptimeseriesYear >= targetYear) {
                tempMetricError.TimeseriesData[index].Year = 'Year should be less than target year';
                isFormValid = false;
              }
              if (temptimeseriesYear >= currentYear) {
                tempMetricError.TimeseriesData[index].Year = 'Year should be less than current year';
                isFormValid = false;
              }
            } else if (item.Year && index > 0) {
              const temptargetYear = new Date(tempFormData.TargetYear);
              const tempCurrentTimeYear = new Date(tempFormData.TimeseriesData[index].Year);
              const currentYear = new Date(tempFormData.CurrentYear);
              const prevCurrentTimeYear = new Date(tempFormData.TimeseriesData[index - 1].Year);
              if (
                tempFormData.TimeseriesData[index - 1]
                && prevCurrentTimeYear >= tempCurrentTimeYear
              ) {
                tempMetricError.TimeseriesData[index].Year = 'Year should be greater than previous timeseries';
                isFormValid = false;
              }
              if (tempFormData.TargetYear && temptargetYear <= tempCurrentTimeYear) {
                tempMetricError.TimeseriesData[index].Year = 'Year should be less than target year';
                isFormValid = false;
              }
              if (currentYear <= tempCurrentTimeYear) {
                tempMetricError.TimeseriesData[index].Year = 'Year should be less than current year';
                isFormValid = false;
              }
            }
          }
          if (name !== 'HnM') {
            if (name === 'IO') {
              item.Value = '1';
            }
            if (!item.Value) {
              tempMetricError.TimeseriesData[index].Value = `Timeseries ${
                index + 1
              } value is required`;
              isFormValid = false;
            } else if (stringValueTypes.includes(name) && (tempFormData.MetricUnit === 'String' || tempFormData.ValueUnit === 'String')) {
              isFormValid = isFormValid;
            } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(item.Value)) {
              tempMetricError.TimeseriesData[index].Value = 'Value should be integer';
              isFormValid = false;
            } else if (
              parseFloat(item.Value, 10) < -99999999
              || parseFloat(item.Value, 10) > 99999999
            ) {
              tempMetricError.TimeseriesData[index].Value = 'Value must be between -99999999 and 99999999';
              isFormValid = false;
            }
          } else {
            const splitValue = item.Value.split('-');
            if (!splitValue[0]) {
              tempMetricError.TimeseriesData[index].Value = `Timeseries ${
                index + 1
              } value is required`;
              isFormValid = false;
            } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(splitValue[0])) {
              tempMetricError.TimeseriesData[index].Value = 'Value should be integer';
              isFormValid = false;
            } else if (parseFloat(splitValue[0], 10) > 99999999) {
              tempMetricError.TimeseriesData[index].Value = 'Value must be between 0 and 99999999';
              isFormValid = false;
            }
            if (!splitValue[1]) {
              tempMetricError.TimeseriesData[index].MachineValue = `Timeseries ${
                index + 1
              } value is required`;
              isFormValid = false;
            } else if (!/^-?(0|[0-9]\d*)(\.\d+)?$/.test(splitValue[1])) {
              tempMetricError.TimeseriesData[index].Value = 'Value should be integer';
              isFormValid = false;
            } else if (parseFloat(splitValue[1], 10) > 99999999) {
              tempMetricError.TimeseriesData[index].MachineValue = 'Value must be between 0 and 99999999';
              isFormValid = false;
            }
          }

          if (!item.Year) {
            tempMetricError.TimeseriesData[index].Year = `Timeseries ${index + 1} Year is required`;
            isFormValid = false;
          } else if (item.Year && index === 0) {
            const baseYear = new Date(tempFormData.BaselineYear);
            const targetYear = new Date(tempFormData.TargetYear);
            const currentYear = new Date(tempFormData.CurrentYear);
            const temptimeseriesYear = new Date(tempFormData.TimeseriesData[index].Year);
            if (tempFormData.BaselineYear && baseYear >= temptimeseriesYear) {
              tempMetricError.TimeseriesData[index].Year = 'Year should be greater than baseline year';
              isFormValid = false;
            }
            if (tempFormData.TargetYear && temptimeseriesYear >= targetYear) {
              tempMetricError.TimeseriesData[index].Year = 'Year should be less than target year';
              isFormValid = false;
            }
            if (temptimeseriesYear >= currentYear) {
              tempMetricError.TimeseriesData[index].Year = 'Year should be less than current year';
              isFormValid = false;
            }
          } else if (item.Year && index > 0) {
            const temptargetYear = new Date(tempFormData.TargetYear);
            const tempCurrentTimeYear = new Date(tempFormData.TimeseriesData[index].Year);
            const currentYear = new Date(tempFormData.CurrentYear);
            const prevCurrentTimeYear = new Date(tempFormData.TimeseriesData[index - 1].Year);
            if (
              tempFormData.TimeseriesData[index - 1]
              && prevCurrentTimeYear >= tempCurrentTimeYear
            ) {
              tempMetricError.TimeseriesData[index].Year = 'Year should be greater than previous timeseries';
              isFormValid = false;
            }
            if (tempFormData.TargetYear && temptargetYear <= tempCurrentTimeYear) {
              tempMetricError.TimeseriesData[index].Year = 'Year should be less than target year';
              isFormValid = false;
            }
            if (currentYear <= tempCurrentTimeYear) {
              tempMetricError.TimeseriesData[index].Year = 'Year should be less than current year';
              isFormValid = false;
            }
          }
        }
      });
    }

    this.setState({
      MetricError: tempMetricError,
      formValidated: true,
    });
    return isFormValid;
  };

  handleTimeseriesDelete(index, obj) {
    const {
      MetricData, currentSectionData, hasMetrices, deletedTimeseriesIndex,
    } = this.state;
    let { mindMapJourney } = this.state;
    const {
      name,
      removeDrillDownJourneyDataTimeSeries,
      removeDrillDownJourneyDataMetricTimeSeries,
      configrationData,
    } = this.props;
    let tempSection = 0;
    let tempFormData = {};
    if (hasMetrices.includes(name)) {
      tempFormData = MetricData;
    } else {
      tempFormData = currentSectionData;
    }
    if (tempFormData.TimeseriesData[index].Id === 0) {
      tempFormData.TimeseriesData.splice(index, 1);
    } else {
      tempFormData.TimeseriesData[index].isDeleted = true;
      tempFormData.TimeseriesData.sort((a, b) => a.isDeleted - b.isDeleted);
    }

    tempSection = currentSectionData.SectionId;

    deletedTimeseriesIndex.push(index);
    let journeyExist = false;
    [...mindMapJourney].forEach((node) => {
      if (parseInt(node.SectionId) === parseInt(tempSection)) {
        if (!hasMetrices.includes(name)) {
          const sectionJourney = [...node.SectionJourney];
          sectionJourney.forEach((a) => {
            if (
              a.FromTimeseriesUniqueId === obj.uniqueId
              || a.ToTimeseriesUniqueId === obj.uniqueId
            ) {
              journeyExist = true;
            }
          });
        } else {
          const metrics = node.MetricsData;
          [...metrics].forEach((metr) => {
            const kpiMappingID = MetricData.KPIMappingID !== null ? MetricData.KPIMappingID : 0;
            if (
              parseInt(metr.CustomMetricId) === parseInt(MetricData.Id)
              && metr.KpiMappingID === kpiMappingID
              && metr.MetricName.toLowerCase() === MetricData.MetricName.toLowerCase()
            ) {
              const metricJourney = metr.MetricJourney;
              [...metricJourney].forEach((a) => {
                if (
                  a.FromTimeseriesUniqueId === obj.uniqueId
                  || a.ToTimeseriesUniqueId === obj.uniqueId
                ) {
                  journeyExist = true;
                }
              });
            }
          });
        }
      }
    });
    if (journeyExist && !hasMetrices.includes(name)) {
      mindMapJourney = [...mindMapJourney].map((node) => {
        if (parseInt(node.SectionId) === parseInt(tempSection)) {
          // REMOVING ALL THE TIMESERIES WITH GIVEN INDEX AND CHANING THE INDEX
          // 0 1 2
          // IF 0 IS DELETED -> THEN 0 IS REMOVED AND INDEX CHANGE 1->0 AND 2->1
          let sectionJourney = [...node.SectionJourney];
          sectionJourney = sectionJourney.filter(
            (a) => !(
              a.FromMilestone.includes(`Timeseries${index}`)
                || a.ToMilestone.includes(`Timeseries${index}`)
            ),
          );
          sectionJourney = sectionJourney.map((a) => {
            if (a.FromMilestone.includes('Timeseries')) {
              const currentIndex = parseInt(a.FromMilestone.split('Timeseries')[1]);
              if (currentIndex > index) {
                a.FromMilestone = `Timeseries${(currentIndex - 1).toString()}`;

                a.FromTimeseriesID = a.IsNewTimeseriesJourney
                  ? currentIndex - 1
                  : a.FromTimeseriesID;
              }

              return a;
            }
            if (a.ToMilestone.includes('Timeseries')) {
              const currentIndex = parseInt(a.ToMilestone.split('Timeseries')[1]);
              if (currentIndex > index) {
                a.ToMilestone = `Timeseries${(currentIndex - 1).toString()}`;
                a.ToTimeseriesID = a.IsNewTimeseriesJourney ? currentIndex - 1 : a.ToTimeseriesID;
              }
              return a;
            }
            return a;
          });
          node.SectionJourney = sectionJourney;
          return node;
        }
        return node;
      });
    } else if (journeyExist && hasMetrices.includes(name)) {
      mindMapJourney = [...mindMapJourney].map((node) => {
        if (parseInt(node.SectionId) === parseInt(tempSection)) {
          const { MetricsData } = node;
          node.MetricsData = [...MetricsData].map((metr) => {
            if (
              parseInt(metr.KpiMappingID) === parseInt(MetricData.KPIMappingID)
              && metr.CustomMetricId === MetricData.Id
              && metr.MetricName.toLowerCase() === MetricData.MetricName.toLowerCase()
            ) {
              let metricJourney = metr.MetricJourney;
              metricJourney = [...metricJourney].filter(
                (jour) => !(
                  jour.FromMilestone.includes(`Timeseries${index}`)
                    || jour.ToMilestone.includes(`Timeseries${index}`)
                ),
              );
              metricJourney = metricJourney.map((a) => {
                if (a.FromMilestone.includes('Timeseries')) {
                  const currentIndex = parseInt(a.FromMilestone.split('Timeseries')[1]);
                  if (currentIndex > index) {
                    a.FromMilestone = `Timeseries${(currentIndex - 1).toString()}`;
                    a.FromTimeseriesID = a.IsNewTimeseriesJourney
                      ? currentIndex - 1
                      : a.FromTimeseriesID;
                  }

                  return a;
                }
                if (a.ToMilestone.includes('Timeseries')) {
                  const currentIndex = parseInt(a.ToMilestone.split('Timeseries')[1]);
                  if (currentIndex > index) {
                    a.ToMilestone = `Timeseries${(currentIndex - 1).toString()}`;
                    a.ToTimeseriesID = a.IsNewTimeseriesJourney
                      ? currentIndex - 1
                      : a.ToTimeseriesID;
                  }
                  return a;
                }
                return a;
              });
              metr.MetricJourney = [...metricJourney];
            }
            return metr;
          });
        }
        return node;
      });
    }
    /*     configrationData.MindMapJourney.forEach((node) => {
     if(!hasMetrices.includes(name))
     {
       node.SectionJourney.forEach((jour))
     }
   })
*/

    if (hasMetrices.includes(name)) {
      this.setState({
        MetricData: tempFormData,
        deletedTimeseriesIndex,
        mindMapJourney,
      });
    } else {
      this.setState({
        currentSectionData: tempFormData,
        deletedTimeseriesIndex,
        mindMapJourney,
      });
    }
  }

  handleCustomMetricName = (event) => {
    const { MetricData } = this.state;
    const tempMetricData = { ...MetricData };
    tempMetricData.MetricName = event.target.value;
    this.setState({
      MetricData: tempMetricData,
    });
  };

  cancelMetric = () => {
    this.setState({
      selectedDropdownName: '',
    });
  };

  cancelLever = () => {
    this.setState({
      selectedLever: '',
    });
  };

  handleLeverDropdown(item) {
    const { clientData } = this.props;
    const { dropdownList } = this.state;
    this.setState({
      selectedLever: item.name,
      selectedDropdownName: '',
    });
    if (item.keyWord !== 'AC') {
      axiosInstance
        .get(`${LocalApiBaseUrl}BeforeAndAfterMindmap/GetMindmapMetricDropdownData`, {
          params: {
            SOCat: item.keyWord,
            OrgEntID: clientData.id,
          },
        })
        .then((response) => {
          const { data } = response;
          let tempDropdownData = [...dropdownList];
          tempDropdownData = data;
          this.setState({
            dropdownList: tempDropdownData,
          });
        })
        .catch((err) => {
          trycatchAlertPopup(error);
        });
    } else {
      const tempMetricData = {
        Id: 0,
        KPIMappingID: null,
        MetricName: '',
        MetricUnit: '',
        RAGStatus: '',
        Sequence: '',
        BaselineYear: '',
        BaselineIOJStage: null,
        BaselineValue: '',
        CurrentYear: new Date(new Date().setDate(10)).toISOString(),
        CurrentValue: '',
        CurrentIOJStage: null,
        TargetYear: '',
        TargetValue: '',
        TargetIOJStage: null,
        TargetYear2: '',
        TargetValue2: '',
        TargetIOJStage2: null,
        IsDeleted: false,
        TimeseriesData: [],
      };
      const tempMetricError = {
        MetricName: null,
        ValueUnit: null,
        RAGStatus: null,
        Sequence: null,
        BaselineYear: null,
        BaselineValue: null,
        BaselineMachineValue: null,
        CurrentYear: null,
        CurrentValue: null,
        CurrentMachineValue: null,
        TargetYear: null,
        TargetValue: null,
        TargetMachineValue: null,
        TargetYear2: null,
        TargetValue2: null,
        TargetMachineValue2: null,
        IsDeleted: false,
        TimeseriesData: [],
      };
      this.setState({
        MetricData: tempMetricData,
        MetricError: tempMetricError,
      });
    }
  }

  deleteTimline = (name) => {
    const { currentSectionData } = this.state;
    const clonedcurrentSectionData = _.cloneDeep(currentSectionData);
    const { deleteStepData, closeForm } = this.props;
    clonedcurrentSectionData.DisplayName = clonedcurrentSectionData.SectionName;
    clonedcurrentSectionData.BaselineIOJStage = '';
    clonedcurrentSectionData.BaselineValue = '';
    clonedcurrentSectionData.BaselineYear = null;
    clonedcurrentSectionData.CurrentIOJStage = '';
    clonedcurrentSectionData.CurrentValue = '';
    clonedcurrentSectionData.CurrentYear = null;
    clonedcurrentSectionData.Metrics = [];
    clonedcurrentSectionData.RAGStatus = '';
    clonedcurrentSectionData.TargetIOJStage = '';
    clonedcurrentSectionData.TargetValue = '';
    clonedcurrentSectionData.TargetYear = null;
    clonedcurrentSectionData.TargetIOJStage2 = '';
    clonedcurrentSectionData.TargetValue2 = '';
    clonedcurrentSectionData.TargetYear2 = null;
    clonedcurrentSectionData.TimeseriesData = clonedcurrentSectionData.TimeseriesData.length > 0 ? clonedcurrentSectionData.TimeseriesData.map((each) => ({ ...each, isDeleted: true })) : [];
    clonedcurrentSectionData.ValueUnit = '';
    clonedcurrentSectionData.isDeleted = false;
    clonedcurrentSectionData.isNewComment = false;
    deleteStepData({ currentSectionData: clonedcurrentSectionData, name });
    closeForm('');
  };

  render() {
    const {
      formData,
      metricData,
      Units,
      RAGStatus,
      hasMetrices,
      dropdownList,
      selectedDropdownName,
      selectedLever,
      leverDropdown,
      IODropdown,
      MetricData,
      currentSectionData,
      MetricError,
      formValidated,
      ragClass,
    } = this.state;
    const {
      name, closeForm, editFormData, unitsData,
    } = this.props;
    let count = 1;
    const currentFormData = hasMetrices.includes(name) ? MetricData : currentSectionData;
    const currentDate = new Date();
    let calculateValidDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    calculateValidDate = calculateValidDate.setDate(1);
    const currentBaseLineYear = currentFormData.BaselineYear;

    const isMetricData = hasMetrices.includes(name);
    const stringValueTypes = ['DSP', 'WC', 'NPS'];
    return (
      <>
        {hasMetrices.includes(name) && !editFormData.isEditMode && (
          <ul className="submenu-treelinks">
            {name !== 'BO' && (
              <li>
                <div className="mindmaplevel-dropdown">
                  <DropdownButton
                    id="mindmap-offering3"
                    title={selectedLever === '' ? 'Select Lever' : selectedLever}
                  >
                    <div className="offering-droplistwrapper">
                      {leverDropdown.map((item) => (
                        <Dropdown.Item
                          href="javascript:void(0);"
                          onClick={() => this.handleLeverDropdown(item)}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </DropdownButton>
                </div>
                {selectedLever !== '' && selectedLever !== 'Add Custom' && (
                  <ul className="submenu-treeleveldropdown">
                    <li>
                      <div className="mindmaplevel-dropdown">
                        <DropdownButton
                          id="mindmap-offering3"
                          title={
                            selectedDropdownName === '' ? 'Select Metric' : selectedDropdownName
                          }
                        >
                          <div className="offering-droplistwrapper">
                            {dropdownList.length === 0 ? (
                              <Dropdown.Item href="javascript:void(0);">
                                No Data Available
                              </Dropdown.Item>
                            ) : (
                              dropdownList.map((item) => (
                                <Dropdown.Item
                                  href="javascript:void(0);"
                                  onClick={() => this.handleDropdownValue(item)}
                                >
                                  {item.MetricName}
                                </Dropdown.Item>
                              ))
                            )}
                          </div>
                        </DropdownButton>
                      </div>
                    </li>
                  </ul>
                )}
              </li>
            )}
            {name === 'BO' && (
              <li>
                <div className="mindmaplevel-dropdown">
                  <DropdownButton
                    id="mindmap-offering3"
                    title={selectedDropdownName === '' ? 'Select Metric' : selectedDropdownName}
                  >
                    <div className="offering-droplistwrapper">
                      {dropdownList.length === 0 ? (
                        <Dropdown.Item href="javascript:void(0);">No Data Available</Dropdown.Item>
                      ) : (
                        dropdownList.map((item) => (
                          <Dropdown.Item
                            href="javascript:void(0);"
                            onClick={() => this.handleDropdownValue(item)}
                          >
                            {item.MetricName}
                          </Dropdown.Item>
                        ))
                      )}
                    </div>
                  </DropdownButton>
                </div>
              </li>
            )}
          </ul>
        )}

        {(!hasMetrices.includes(name)
          || (!editFormData.isEditMode && selectedDropdownName !== '')
          || (editFormData.isEditMode && !selectedDropdownName)
          || (!editFormData.isEditMode && selectedLever === 'Add Custom')) && (
          <div
            className={
              editFormData.isEditMode
                ? 'timelinevalues-form editMetric-Form'
                : selectedLever === 'Add Custom'
                  ? 'timelinevalues-form addCustom-Form'
                  : name === 'COMP'
                    ? 'timelinevalues-form compliance-Form'
                    : name === 'IO'
                      ? 'timelinevalues-form IOScore-timelineForm'
                      : 'timelinevalues-form'
            }
          >
            <div className="timelinevalues-formheading">
              <h5>Select the following values</h5>
              <div className="mindmaplevel-dropdownaction">
                {!hasMetrices.includes(name) && (
                  <span onClick={() => this.deleteTimline(name)}>
                    <i className="fal fa-trash-alt" />
                  </span>
                )}
                <span onClick={() => closeForm('')}>
                  <i className="fal fa-times" />
                </span>
                <span onClick={() => this.formSubmit(name)}>
                  <i className="far fa-check" />
                </span>
              </div>
            </div>
            <div className="timelinevalues-formfilelds">
              {selectedLever === 'Add Custom' && (
                <div className="timelinevalues-formrow">
                  <div className="timelinevalues-formcollabel">
                    <label>Metric Name:</label>
                  </div>
                  <div className="timelinevalues-formcol">
                    <div className="timelinevalues-input w-100">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Metric Name"
                        value={currentFormData.MetricName}
                        name="MetricName"
                        maxLength={100}
                        onChange={this.handleCustomMetricName}
                      />
                    </div>
                  </div>
                </div>
              )}
              {MetricError.MetricName && (
                <span className="beforeAfterErrormsg">
                  {' '}
                  {MetricError.MetricName}
                  {' '}
                </span>
              )}
              {currentFormData.IsNameEditable ? (
                <div className="timelinevalues-formrow">
                  <div className="timelinevalues-formcollabel">
                    <label>Display Name:</label>
                  </div>
                  <div className="timelinevalues-formcol">
                    <div className="timelinevalues-input">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Node Name"
                        value={currentFormData.DisplayName}
                        name="NodeName"
                        maxLength={50}
                        onChange={(event) => this.onNodeNameChange(event)}
                      />
                    </div>
                    {MetricError.NodeName && (
                      <span className="beforeAfterErrormsg">
                        {' '}
                        {MetricError.NodeName}
                        {' '}
                      </span>
                    )}
                  </div>
                </div>
              ) : null}
              {name !== 'IO' && (
                <div className="timelinevalues-formrow">
                  <div className="timelinevalues-formcollabel">
                    {hasMetrices.includes(name) ? <label>Unit & RAG:</label> : <label>Unit:</label>}
                  </div>
                  <div className="timelinevalues-formcol">
                    <div className="timelinevalues-input">
                      <DropdownButton
                        id="mindmap-offering3"
                        title={
                          !isMetricData ? currentFormData.ValueUnit : currentFormData.MetricUnit
                        }
                        value={
                          !isMetricData ? currentFormData.ValueUnit : currentFormData.MetricUnit
                        }
                      >
                        <div className="offering-droplistwrapper">
                          {unitsData.map((item) => (
                            <Dropdown.Item
                              href="javascript:void(0);"
                              value={item.MetricUnit_En}
                              onClick={() => this.handleUnitSelection(item.MetricUnit_En)}
                            >
                              {item.MetricUnit_En}
                            </Dropdown.Item>
                          ))}
                          {stringValueTypes.includes(name) ? (
                            <Dropdown.Item
                              href="javascript:void(0);"
                              value="String"
                              onClick={() => this.handleUnitSelection('String')}
                            >
                              String
                            </Dropdown.Item>
                          ) : null }
                        </div>
                      </DropdownButton>
                      {MetricError.ValueUnit && (
                        <span className="beforeAfterErrormsg">
                          {' '}
                          {MetricError.ValueUnit}
                          {' '}
                        </span>
                      )}
                    </div>

                    {hasMetrices.includes(name) && (
                      <div className="timelinevalues-input">
                        <DropdownButton
                          id="mindmap-offering3"
                          title={currentFormData.RAGStatus === '' && 'RAG Status'}
                          className={
                            currentFormData.RAGStatus === ''
                              ? 'dropdown-titleValue'
                              : `ragstatus-circle ${ragClass}`
                          }
                        >
                          <div className="offering-droplistwrapper">
                            {RAGStatus.map((rag) => (
                              <Dropdown.Item
                                href="javascript:void(0);"
                                value={rag.id}
                                onClick={() => this.handleRAGSelection(rag)}
                              >
                                {rag.name}
                              </Dropdown.Item>
                            ))}
                          </div>
                        </DropdownButton>
                        {MetricError.RAGStatus && (
                          <span className="beforeAfterErrormsg">
                            {' '}
                            {MetricError.RAGStatus}
                            {' '}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="timelinevalues-formrow">
                <div className="timelinevalues-formcollabel">
                  <label>Baseline:</label>
                </div>
                <div
                  className={
                    name === 'HnM'
                      ? 'timelinevalues-formcol timelinevalue-formcol-hm'
                      : 'timelinevalues-formcol'
                  }
                >
                  <div className="timelinevalues-input">
                    <div className="datepicker-label-wrapper">
                      <label className='datepicker-label'>
                      <DatePicker
                        popperProps={{
                          positionFixed: true,
                        }}
                        selected={
                          currentFormData.BaselineYear ? new Date(currentFormData.BaselineYear) : ''
                        }
                        onChange={(date) => this.handleDateChange(date, 'Baseline')}
                        maxDate={new Date()}
                        placeholderText="Timeline (Year)"
                        dateFormat="MMM'' yy"
                        showMonthYearPicker
                      />
                      <span className="calender-placment">
                        <i className="fal fa-calendar-alt" />
                      </span>
                      </label>
                    </div>
                    {MetricError.BaselineYear && (
                      <span className="beforeAfterErrormsg">
                        {' '}
                        {MetricError.BaselineYear}
                        {' '}
                      </span>
                    )}
                  </div>
                  {name !== 'IO' ? (
                    <div
                      className={
                        name === 'HnM'
                          ? 'timelinevalues-input timelinevalue-hm'
                          : 'timelinevalues-input'
                      }
                    >
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          maxLength={(currentFormData.ValueUnit === 'String' || currentFormData.MetricUnit === 'String') ? 30 : 12}
                          placeholder={name === 'HnM' ? 'Human' : 'Value'}
                          value={
                            name !== 'HnM'
                              ? currentFormData.BaselineValue
                              : String(currentFormData.BaselineValue).split('-')[0]
                          }
                          name="BaselineValue"
                          onChange={(event) => this.handleValueChange(event, 'Baseline')}
                        />
                        {MetricError.BaselineValue && (
                          <span className="beforeAfterErrormsg">
                            {' '}
                            {MetricError.BaselineValue}
                            {' '}
                          </span>
                        )}
                      </div>
                      {name === 'HnM' && (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Machine"
                            value={String(currentFormData.BaselineValue).split('-')[1]}
                            name="BaselineValue"
                            maxLength={12}
                            onChange={(event) => this.handleValueChange(event, 'BaselineMachine')}
                          />
                          {MetricError.BaselineMachineValue && (
                            <span className="beforeAfterErrormsg">
                              {' '}
                              {MetricError.BaselineMachineValue}
                              {' '}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  ) : null}

                  {name === 'IO' && (
                    <div className="timelinevalues-input">
                      <DropdownButton
                        id="mindmap-offering3"
                        title={
                          currentFormData.BaselineIOJStage === ''
                            ? 'IOJ Stage'
                            : currentFormData.BaselineIOJStage
                        }
                        className={currentFormData.BaselineIOJStage === '' && 'dropdown-titleValue'}
                      >
                        <div className="offering-droplistwrapper">
                          {IODropdown.map((a) => (
                            <Dropdown.Item
                              href="javascript:void(0);"
                              value={a}
                              onClick={() => this.handleIODropdownValue(a, 'Baseline')}
                            >
                              {a}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </DropdownButton>
                      {MetricError.BaselineIOJStage && (
                        <span className="beforeAfterErrormsg">
                          {' '}
                          {MetricError.BaselineIOJStage}
                          {' '}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="timelinevalues-formrow">
                <div className="timelinevalues-formcollabel">
                  <label>Current:</label>
                </div>

                <div
                  className={
                    name === 'HnM'
                      ? 'timelinevalues-formcol timelinevalue-formcol-hm'
                      : 'timelinevalues-formcol'
                  }
                >
                  <div className='timelinevalues-input'>
                   <div className="datepicker-label-wrapper">
                        <label className='datepicker-label'>
                          <DatePicker
                            popperProps={{
                              positionFixed: true,
                            }}
                            selected={
                              currentFormData.CurrentYear ? new Date(currentFormData.CurrentYear) : ''
                            }
                            onChange={(date) => this.handleDateChange(date, 'Current')}
                            maxDate={new Date(new Date().getFullYear(), new Date().getMonth()+1, 1)}
                            placeholderText="Timeline (Year)"
                            dateFormat="MMM'' yy"
                            showMonthYearPicker
                          />
                          <span className="calender-placment">
                            <i className="fal fa-calendar-alt" />
                          </span>
                        </label>
                  </div>
                  </div> 
                  {name !== 'IO' ? (
                    <div
                      className={
                        name === 'HnM'
                          ? 'timelinevalues-input timelinevalue-hm'
                          : 'timelinevalues-input'
                      }
                    >
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={name === 'HnM' ? 'Human' : 'Value'}
                          value={
                            name !== 'HnM'
                              ? currentFormData.CurrentValue
                              : String(currentFormData.CurrentValue).split('-')[0]
                          }
                          name="CurrentValue"
                          maxLength={(currentFormData.ValueUnit === 'String' || currentFormData.MetricUnit === 'String') ? 30 : 12}
                          onChange={(event) => this.handleValueChange(event, 'Current')}
                        />
                        {MetricError.CurrentValue && (
                          <span className="beforeAfterErrormsg">
                            {' '}
                            {MetricError.CurrentValue}
                            {' '}
                          </span>
                        )}
                      </div>
                      {name === 'HnM' && (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Machine"
                            value={String(currentFormData.CurrentValue).split('-')[1]}
                            name="CurrentValue"
                            maxLength={12}
                            onChange={(event) => this.handleValueChange(event, 'CurrentMachine')}
                          />
                          {MetricError.CurrentMachineValue && (
                            <span className="beforeAfterErrormsg">
                              {' '}
                              {MetricError.CurrentMachineValue}
                              {' '}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  ) : null}

                  {name === 'IO' && (
                    <div className="timelinevalues-input">
                      <DropdownButton
                        id="mindmap-offering3"
                        title={
                          currentFormData.CurrentIOJStage === ''
                            ? 'IOJ Stage'
                            : currentFormData.CurrentIOJStage
                        }
                        className={currentFormData.CurrentIOJStage === '' && 'dropdown-titleValue'}
                      >
                        <div className="offering-droplistwrapper">
                          {IODropdown.map((a) => (
                            <Dropdown.Item
                              href="javascript:void(0);"
                              value={a}
                              onClick={() => this.handleIODropdownValue(a, 'Current')}
                            >
                              {a}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </DropdownButton>
                      {MetricError.CurrentIOJStage && (
                        <span className="beforeAfterErrormsg">
                          {' '}
                          {MetricError.CurrentIOJStage}
                          {' '}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="timelinevalues-formrow">
                <div className="timelinevalues-formcollabel">
                  <label>Target 1:</label>
                </div>
                <div
                  className={
                    name === 'HnM'
                      ? 'timelinevalues-formcol timelinevalue-formcol-hm'
                      : 'timelinevalues-formcol'
                  }
                >
                  <div className="timelinevalues-input">
                    <div className="datepicker-label-wrapper">
                      <label className='datepicker-label'>
                      <DatePicker
                        popperProps={{
                          positionFixed: true,
                        }}
                        selected={
                          currentFormData.TargetYear ? new Date(currentFormData.TargetYear) : ''
                        }
                        onChange={(date) => this.handleDateChange(date, 'Target')}
                        minDate={calculateValidDate}
                        maxDate={null}
                        placeholderText="Timeline (Year)"
                        dateFormat="MMM'' yy"
                        showMonthYearPicker
                      />
                      <span className="calender-placment">
                        <i className="fal fa-calendar-alt" />
                      </span>
                      </label>
                    </div>
                    {MetricError.TargetYear && (
                      <span className="beforeAfterErrormsg">
                        {' '}
                        {MetricError.TargetYear}
                        {' '}
                      </span>
                    )}
                  </div>

                  {name !== 'IO' ? (
                    <div
                      className={
                        name === 'HnM'
                          ? 'timelinevalues-input timelinevalue-hm'
                          : 'timelinevalues-input'
                      }
                    >
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={name === 'HnM' ? 'Human' : 'Value'}
                          value={
                            name !== 'HnM'
                              ? currentFormData.TargetValue
                              : String(currentFormData.TargetValue).split('-')[0]
                          }
                          name="TargetValue"
                          maxLength={(currentFormData.ValueUnit === 'String' || currentFormData.MetricUnit === 'String') ? 30 : 12}
                          onChange={(event) => this.handleValueChange(event, 'Target')}
                        />
                        {MetricError.TargetValue && (
                          <span className="beforeAfterErrormsg">
                            {' '}
                            {MetricError.TargetValue}
                            {' '}
                          </span>
                        )}
                      </div>
                      {name === 'HnM' && (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Machine"
                            maxLength={12}
                            value={String(currentFormData.TargetValue).split('-')[1]}
                            name="TargetValue"
                            onChange={(event) => this.handleValueChange(event, 'TargetMachine')}
                          />
                          {MetricError.TargetMachineValue && (
                            <span className="beforeAfterErrormsg">
                              {' '}
                              {MetricError.TargetMachineValue}
                              {' '}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  ) : null}

                  {name === 'IO' && (
                    <div className="timelinevalues-input">
                      <DropdownButton
                        id="mindmap-offering3"
                        title={
                          currentFormData.TargetIOJStage === ''
                            ? 'IOJ Stage'
                            : currentFormData.TargetIOJStage
                        }
                        className={currentFormData.TargetIOJStage === '' && 'dropdown-titleValue'}
                      >
                        <div className="offering-droplistwrapper">
                          {IODropdown.map((a) => (
                            <Dropdown.Item
                              href="javascript:void(0);"
                              value={a}
                              onClick={() => this.handleIODropdownValue(a, 'Target')}
                            >
                              {a}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </DropdownButton>
                      {MetricError.TargetIOJStage && (
                        <span className="beforeAfterErrormsg">
                          {' '}
                          {MetricError.TargetIOJStage}
                          {' '}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="timelinevalues-formrow">
                <div className="timelinevalues-formcollabel">
                  <label>Target 2:</label>
                </div>
                <div
                  className={
                    name === 'HnM'
                      ? 'timelinevalues-formcol timelinevalue-formcol-hm'
                      : 'timelinevalues-formcol'
                  }
                >
                  <div className="timelinevalues-input">
                    <div className="datepicker-label-wrapper">
                    <label className='datepicker-label'>
                      <DatePicker
                        popperProps={{
                          positionFixed: true,
                        }}
                        selected={
                          currentFormData.TargetYear2 ? new Date(currentFormData.TargetYear2) : ''
                        }
                        onChange={(date) => this.handleDateChange(date, 'Target2')}
                        minDate={calculateValidDate}
                        maxDate={null}
                        placeholderText="Timeline (Year)"
                        dateFormat="MMM'' yy"
                        showMonthYearPicker
                      />
                      <span className="calender-placment">
                        <i className="fal fa-calendar-alt" />
                      </span>
                      </label>
                    </div>
                    {MetricError.TargetYear2 && (
                      <span className="beforeAfterErrormsg">
                        {' '}
                        {MetricError.TargetYear2}
                        {' '}
                      </span>
                    )}
                  </div>

                  {name !== 'IO' ? (
                    <div
                      className={
                        name === 'HnM'
                          ? 'timelinevalues-input timelinevalue-hm'
                          : 'timelinevalues-input'
                      }
                    >
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={name === 'HnM' ? 'Human' : 'Value'}
                          value={
                            name !== 'HnM'
                              ? currentFormData.TargetValue2
                              : String(currentFormData.TargetValue2).split('-')[0]
                          }
                          name="TargetValue"
                          maxLength={(currentFormData.ValueUnit === 'String' || currentFormData.MetricUnit === 'String') ? 30 : 12}
                          onChange={(event) => this.handleValueChange(event, 'Target2')}
                        />
                        {MetricError.TargetValue2 && (
                          <span className="beforeAfterErrormsg">
                            {' '}
                            {MetricError.TargetValue2}
                            {' '}
                          </span>
                        )}
                      </div>
                      {name === 'HnM' && (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Machine"
                            maxLength={12}
                            value={String(currentFormData.TargetValue2).split('-')[1]}
                            name="TargetValue2"
                            onChange={(event) => this.handleValueChange(event, 'TargetMachine2')}
                          />
                          {MetricError.TargetMachineValue2 && (
                            <span className="beforeAfterErrormsg">
                              {' '}
                              {MetricError.TargetMachineValue2}
                              {' '}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  ) : null}

                  {name === 'IO' && (
                    <div className="timelinevalues-input">
                      <DropdownButton
                        id="mindmap-offering3"
                        title={
                          currentFormData.TargetIOJStage2 === ''
                            ? 'IOJ Stage'
                            : currentFormData.TargetIOJStage2
                        }
                        className={currentFormData.TargetIOJStage2 === '' && 'dropdown-titleValue'}
                      >
                        <div className="offering-droplistwrapper">
                          {IODropdown.map((a) => (
                            <Dropdown.Item
                              href="javascript:void(0);"
                              value={a}
                              onClick={() => this.handleIODropdownValue(a, 'Target2')}
                            >
                              {a}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </DropdownButton>
                      {MetricError.TargetIOJStage2 && (
                        <span className="beforeAfterErrormsg">
                          {' '}
                          {MetricError.TargetIOJStage2}
                          {' '}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {hasMetrices.includes(name) && (
                <div className="timelinevalues-formrow">
                  <div className="timelinevalues-formcollabel">
                    <label>Sequence:</label>
                  </div>
                  <div className="timelinevalues-formcol">
                    <div className="timelinevalues-input">
                      <input
                        type="number"
                        step="1"
                        min="1"
                        max="99999999"
                        className="form-control"
                        placeholder="Sequence"
                        value={currentFormData.Sequence}
                        name="Sequence"
                        onChange={this.handleSequenceChange}
                      />
                      {MetricError.Sequence && (
                        <span className="beforeAfterErrormsg">
                          {' '}
                          {MetricError.Sequence}
                          {' '}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {currentFormData.TimeseriesData
                ? currentFormData.TimeseriesData.map(
                  (eachVal, index) => !eachVal.isDeleted && (
                  <div className="timelinevalues-formrow">
                    <div className="timelinevalues-formcollabel">
                      <label>{`Timeseries ${count++}:`}</label>
                    </div>
                    <div
                      className={
                              name === 'HnM'
                                ? 'timelinevalues-formcol timeseries-labelrow timelinevalue-formcol-hm'
                                : 'timelinevalues-formcol timeseries-labelrow'
                            }
                    >
                      <div className="timelinevalues-input">
                        <div className="datepicker-label-wrapper">
                        <label className='datepicker-label'>
                          <DatePicker
                            popperProps={{
                              positionFixed: true,
                            }}
                            selected={eachVal.Year ? new Date(eachVal.Year) : ''}
                            onChange={(date) => this.handleDateChange(date, 'Timeseries', index)}
                            minDate={addMonths(
                              new Date(currentFormData.BaselineYear).setDate(1),
                              1,
                            )}
                            maxDate={new Date().setDate(1)}
                            placeholderText="Timeline (Year)"
                            dateFormat="MMM'' yy"
                            showMonthYearPicker
                          />
                          <span className="calender-placment">
                            <i className="fal fa-calendar-alt" />
                          </span>
                          </label>
                        </div>
                        {formValidated && MetricError.TimeseriesData[index].Year && (
                        <span className="beforeAfterErrormsg">
                          {' '}
                          {MetricError.TimeseriesData[index].Year}
                          {' '}
                        </span>
                        )}
                      </div>
                      {name !== 'IO' ? (
                        <div
                          className={
                                  name === 'HnM'
                                    ? 'timelinevalues-input timelinevalue-hm'
                                    : 'timelinevalues-input'
                                }
                        >
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={
                                      name === 'HnM' ? 'Human Value' : 'Value'
                                    }
                              value={
                                      name !== 'HnM'
                                        ? eachVal.Value
                                        : String(eachVal.Value).split('-')[0]
                                    }
                              name={index}
                              maxLength={(currentFormData.ValueUnit === 'String' || currentFormData.MetricUnit === 'String') ? 30 : 12}
                              onChange={(event) => this.handleValueChange(event, 'Timeseries', index)}
                            />
                            {formValidated && MetricError.TimeseriesData[index].Value && (
                            <span className="beforeAfterErrormsg">
                              {' '}
                              {MetricError.TimeseriesData[index].Value}
                              {' '}
                            </span>
                            )}
                          </div>

                          {name === 'HnM' && (
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Machine Value"
                              maxLength={12}
                              value={String(eachVal.Value).split('-')[1]}
                              name="TimeseriesMachine"
                              onChange={(event) => this.handleValueChange(event, 'TimeseriesMachine', index)}
                            />
                            {formValidated
                                      && MetricError.TimeseriesData[index].MachineValue && (
                                        <span className="beforeAfterErrormsg">
                                          {' '}
                                          {MetricError.TimeseriesData[index].MachineValue}
                                          {' '}
                                        </span>
                            )}
                          </div>
                          )}
                        </div>
                      ) : null}

                      {name === 'IO' && (
                      <div className="timelinevalues-input">
                        <DropdownButton
                          id="mindmap-offering3"
                          title={eachVal.IOJStage === '' ? 'IOJ Stage' : eachVal.IOJStage}
                          className={eachVal.IOJStage === '' && 'dropdown-titleValue'}
                        >
                          <div className="offering-droplistwrapper">
                            {IODropdown.map((eachIOj) => (
                              <Dropdown.Item
                                href="javascript:void(0);"
                                value={eachIOj}
                                onClick={() => this.handleIODropdownValue(eachIOj, 'Timeseries', index)}
                              >
                                {eachIOj}
                              </Dropdown.Item>
                            ))}
                          </div>
                        </DropdownButton>
                        {formValidated && MetricError.TimeseriesData[index].IOJStage && (
                        <span className="beforeAfterErrormsg">
                          {' '}
                          {MetricError.TimeseriesData[index].IOJStage}
                          {' '}
                        </span>
                        )}
                      </div>
                      )}
                    </div>
                    <div
                      className="timeseries-deleterow"
                      onClick={() => this.handleTimeseriesDelete(index, eachVal)}
                    >
                      <span>
                        <i className="far fa-minus" />
                      </span>
                    </div>
                  </div>
                  ),
                )
                : null}
            </div>
            {currentFormData.TimeseriesData && count <= 3 ? (
              <div className="timelinevalues-formrow timeline-clicktext">
                <span onClick={() => this.addTimeseries()}>
                  <i className="far fa-plus plus-icon-fix" />
                  Click here to add Timeseries with Interventions.
                </span>
              </div>
            ) : null}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isFormOpen: state.BeforeAfterMindMapReducer.isFormOpen,
  configrationData: state.BeforeAfterMindMapReducer.configrationData.MindMapData,
  configrationDataMindMapJourney: state.BeforeAfterMindMapReducer.configrationData.MindMapJourney,
  clientData: state.CurrentFilter.ClientData,
  unitsData: state.BOMasterReducers.unitsData,
});

const mapDispatchToProps = (dispatch) => ({
  handleForm: (name, data) => dispatch(BeforeAfterActions.handleForm(name, data)),
  handleFormData: (name, data) => dispatch(BeforeAfterActions.handleFormData(name, data)),
  saveConfigData: (data) => dispatch(BeforeAfterActions.saveConfigData(data)),
  saveUpdatedConfigData: (data) => dispatch(BeforeAfterActions.saveUpdatedConfigData(data)),
  deleteStepData: (data) => dispatch(BeforeAfterActions.deleteStepData(data)),
  saveUpdatedMetricConfigData: (data) => dispatch(BeforeAfterActions.saveUpdatedMetricConfigData(data)),
  removeDrillDownJourneyDataTimeSeries: (data) => dispatch(BeforeAfterActions.removeDrillDownJourneyDataTimeSeries(data)),
  removeDrillDownJourneyDataMetricTimeSeries: (data) => dispatch(BeforeAfterActions.removeDrillDownJourneyDataMetricTimeSeries(data)),
  setMindMapJourneyData: (data) => dispatch(BeforeAfterActions.setMindMapJourneyData(data)),
});
BeforeAfterListDropdown.propTypes = {
  handleForm: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  saveUpdatedConfigData: PropTypes.func.isRequired,
  deleteStepData: PropTypes.func.isRequired,
  unitsData: PropTypes.array.isRequired,
  closeForm: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(BeforeAfterListDropdown);
