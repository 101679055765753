import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "../Images/close-icon.svg";
import { Dropdown } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import i18n from "i18next";

const namesJson ={
  "Green":"On_Track",
  "Amber":"At_Risk",
  "Red":"Delayed"
}

export default function PerformancePopup({
  modalClose,
  showPerformanceModal,
  performanceData,
  onSelectInitaitve,
  performanceStageID,
}) {
  const [InitativeData, setInitativeData] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [status, setstatus] = useState("Green");

  const performanceModalClose = () => {
    modalClose();
  };
  const handleSelect = (data) => {
    onSelectInitaitve(data, performanceStageID);
  };
  const handleStatusChange = (data) => {
    let filterdata=[];
    if(data !== "Green")
    {
      filterdata = InitativeData.filter((e) => e.RAGStatus.toLowerCase() === data.toLowerCase());
    }
    else{
      filterdata = InitativeData.filter((e) => e.RAGStatus.toLowerCase() === data.toLowerCase() || e.RAGStatus.toLowerCase() === "Grey".toLowerCase());
    }
     
    setfilteredData(filterdata);
    setstatus(data);
  };

  const handleChar = (data)=>{
    let charlen=22;
    switch(data){
      case "Work Orchestration":
        charlen = 26;
        break;
      case "Human + Machine Talent":
        charlen = 22;
        break;
      case "Insights & Intelligence":
        charlen = 22;
        break;
      case "Diverse Data":
       charlen = 34;
       break;
       default:
         charlen=22;

    }
    return charlen;
  }

  useEffect(() => {
    let filterdata = performanceData.filter((e) => e.RAGStatus.toLowerCase() === status.toLowerCase() || e.RAGStatus.toLowerCase() === "Grey".toLowerCase());

    setInitativeData(performanceData);
    setfilteredData(filterdata);
  }, []);
  return (
    <div>
      {/* Performance Modal Popup Start*/}
      <Modal
        show={showPerformanceModal}
        onHide={performanceModalClose}
        className="iojperformance-modal"
        backdrop="static"
      >
        <Modal.Body className="performance-body">
          <div className={`ioj-performancemodal ${status.toLowerCase()}performance`}>
            <div className="performance-modalheader">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={performanceModalClose}
              >
                <img src={CloseIcon} alt="close" />
              </button>
              <Dropdown
                className="mr-3"
                onSelect={(data) => {
                  handleStatusChange(data);
                }}
              >
                <Dropdown.Toggle>
                  {i18n.t(namesJson[status])}<span>({filteredData.length})</span>
                </Dropdown.Toggle>
                <Dropdown.Menu onChange>
                <Dropdown.Item className="dropdownitem-green" active={status === "Green"} eventKey="Green">
                  {i18n.t("On_Track")}
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdownitem-amber" active={status === "Amber"} eventKey="Amber">
                  {i18n.t("At_Risk")}
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdownitem-red" active={status === "Red"} eventKey="Red">
                  {i18n.t("Delayed")}
                  </Dropdown.Item>
                  {/* <Dropdown.Item className="dropdownitem-grey" active={status === "Grey"} eventKey="Grey">
                    Grey
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
              <span className="performance-breadcrumbnav">{i18n.t("Initiative_Details")}</span>
            </div>
            <div className="performance-initiativelist customenhancedscroll">
              <ul className="performance-parent-ul">
                {filteredData !== null &&
                  filteredData.length > 0 ?
                  filteredData.map((each, index) => {
                    let impactdata =
                      each.InitiativeImpacts !== null && each.InitiativeImpacts.length > 0
                        ? each.InitiativeImpacts.map((d) => d.ImpactName).join(", ")
                        : "";
                    let impacts = impactdata.length > handleChar(each.SOCategoryName) ? impactdata.slice(0, (handleChar(each.SOCategoryName)-3)) + "..." : impactdata;
                    let iniName =
                      each.ProjectInitiativeName.length > 45
                        ? each.ProjectInitiativeName.slice(0, 42) + "..."
                        : each.ProjectInitiativeName;
                    return (
                      <li
                        className={`performance-parent performance-pointer initiative-${each.RAGStatus}`}
                        onClick={() => {
                          handleSelect(each);
                        }}
                      >
                        <div className="perfomance-initiativename">
                          <h6 data-tip data-for={"initiatveName" + index}>
                            {iniName}
                            {each.ProjectInitiativeName.length > 45 && (
                              <ReactTooltip
                                place="bottom"
                                effect="solid"
                                id={"initiatveName" + index}
                                className="performance-tooltip"
                              >
                                <span>{each.ProjectInitiativeName}</span>
                              </ReactTooltip>
                            )}
                          </h6>
                          <p>
                            <span className="mr-2">
                              <i class="far fa-bullseye-pointer"></i>
                            </span>
                            <ul>
                              <li>{each.SOCategoryName && each.SOCategoryName}</li>
                              {
                                impactdata !== "" &&<li data-tip data-for={"Tooltip_desc" + index}>
                                {impacts}
                                {impactdata.length > handleChar(each.SOCategoryName) && (
                                  <ReactTooltip
                                    place="bottom"
                                    effect="solid"
                                    id={"Tooltip_desc" + index}
                                    className="performance-tooltip"
                                  >
                                    <span>{impactdata}</span>
                                  </ReactTooltip>
                                )}
                              </li>
                              }
                              
                            </ul>
                          </p>
                        </div>
                      </li>
                    );
                  }):<h5 className="performance-empty">
                    {i18n.t("performance_empty")}
                    </h5>}
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Performance Modal Popup End*/}
    </div>
  );
}
