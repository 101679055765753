import React from 'react';
import PropTypes from 'prop-types';

const MentionComponent = ({ mention, children, className }) => (
  <span className={className} spellCheck={false} data-role-id={mention.id}>
    {children}
  </span>
);

MentionComponent.propTypes = {
  mention: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default MentionComponent;
