import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import {
  LocalApiBaseUrl, formatDate, trycatchAlertPopup,
} from '../Shared/Constant';
import PastAndFutureTrendsConfigration from './PastAndFutureTrendsConfigration';
import BoiTrends from './BoiTrends';
import { truncateTwoDecimal } from '../Shared/commonFunctions';
import axiosInstance from '../Shared/interceptor';
import SynopsLoader from '../Shared/SynopsLoader';

const BoiTrendsWrapper = ({ ...props }) => {
  const [showEditData, setShowEditData] = useState(false);
  const [boiApiResponseData, setBoiApiResponseData] = useState([]);
  const [pastTrend, setPastTrend] = useState([]);
  const [futureTrend, setFutureTrend] = useState([]);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [boiTrendsData, setBoiTrendsData] = useState({ labels: [], datasets: [] });
  const [boiTrendsDates, setBoiTrendsDates] = useState({
    startDate: '',
    endDate: '',
    startDateValue: '',
    endDateValue: '',
  });

  const createDateSetsForBoiTrends = (response, onEditClick) => {
    const { Month, kpiMappingId } = props;
    const responseData = _.cloneDeep(response);
    let EndDate = new Date(Month);
    EndDate = new Date(EndDate.setDate(EndDate.getDate() + 11));
    let endDateValue = moment(new Date(Month), 'MMM YY');
    endDateValue = formatDate(endDateValue);
    const PastFutureData = responseData.PastFutureData
      ? responseData.PastFutureData
      : [];
    const trendsData = responseData.CurrentMetricData
      ? responseData.CurrentMetricData
      : [];
    if (trendsData.length) {
      let startDateValue = moment(new Date(trendsData[0].PeriodDT), 'MMM YY');
      startDateValue = formatDate(startDateValue);
      setBoiTrendsDates({
        startDate: new Date(trendsData[0].PeriodDT),
        endDate: EndDate,
        startDateValue,
        endDateValue,
      });
    }

    let pastTrends = [];
    let futureTrends = [];
    let DealId = 0;
    PastFutureData.forEach((eachData) => {
      const cloneEachData = eachData;
      if (!eachData.IsFutureValue) {
        cloneEachData.ActualValueDate = eachData.ValueDate;
        cloneEachData.ValueDate = new Date(eachData.ValueDate);
        cloneEachData.error = '';
        cloneEachData.valueError = '';
        cloneEachData.showPicker = false;
        pastTrends.push(cloneEachData);
      } else if (eachData.IsFutureValue) {
        cloneEachData.ActualValueDate = eachData.ValueDate;
        cloneEachData.ValueDate = new Date(eachData.ValueDate);
        cloneEachData.error = '';
        cloneEachData.valueError = '';
        cloneEachData.showPicker = false;
        futureTrends.push(cloneEachData);
      }
      DealId = eachData.DealId;
    });
    pastTrends = _.sortBy(pastTrends, 'ValueDate');
    pastTrends = pastTrends.reverse();
    futureTrends = _.sortBy(futureTrends, 'ValueDate');
    const updatedPastTrends = [...pastTrends];
    const updatedFutureTrends = [...futureTrends];
    if (futureTrends.length < 3) {
      for (let i = 0; i < 3 - futureTrends.length; i += 1) {
        updatedFutureTrends.push({
          TrendId: 0,
          KPIMappingID: kpiMappingId,
          PeriodDT: Month,
          ValueDate: '',
          MetricValue: '',
          DealId: DealId || 0,
          IsFutureValue: true,
        });
      }
    }
    if (pastTrends.length < 3) {
      for (let i = 0; i < 3 - pastTrends.length; i += 1) {
        updatedPastTrends.push({
          TrendId: 0,
          KPIMappingID: kpiMappingId,
          PeriodDT: Month,
          ValueDate: '',
          MetricValue: '',
          DealId: DealId || 0,
          IsFutureValue: false,
        });
      }
    }

    const labels = [''];
    const CompleteDatasets = [];
    const pastTrendsData = [null];
    const TrendsData = [null];
    const FutureTendsData = [null];
    const pointcolors = ['rgb(0, 186, 255)'];
    pastTrends = pastTrends.filter((each) => new Date(each.ValueDate) < new Date(trendsData[0].PeriodDT));
    if (pastTrends.length) {
      pastTrends.reverse().forEach((each, index) => {
        const graphLables = moment(new Date(each.ValueDate), 'MMM YY');
        // labels.push(formatDate(graphLables));
        // pastTrendsData.push(truncateTwoDecimal(parseFloat(each.MetricValue)));
        // pastTrendsData.push(parseInt(trendsData[0].MetricValue));
        // TrendsData.push(null);
        // FutureTendsData.push(null);
        if (index === pastTrends.length - 1) {
          if (new Date(each.ValueDate) < new Date(trendsData[0].PeriodDT)) {
            labels.push(formatDate(graphLables));
            pastTrendsData.push(truncateTwoDecimal(parseFloat(each.MetricValue)));
            pastTrendsData.push(truncateTwoDecimal(parseFloat(trendsData[0].MetricValue)));
            TrendsData.push(null);
            FutureTendsData.push(null);
            pointcolors.push('rgb(161, 0, 255)');
            pointcolors.push('rgb(0, 186, 255)');
          } else if (each.ActualValueDate === trendsData[0].PeriodDT) {
            pastTrendsData.push(truncateTwoDecimal(parseFloat(trendsData[0].MetricValue)));
            pointcolors.push('rgb(0, 186, 255)');
          // TrendsData.push(null);
          // FutureTendsData.push(null);
          } else if (new Date(each.ValueDate) > new Date(trendsData[0].PeriodDT)) {
            // empty block please remove if not requiered
          } else {
            labels.push(formatDate(graphLables));
            pastTrendsData.push(truncateTwoDecimal(parseFloat(each.MetricValue)));
            TrendsData.push(null);
            FutureTendsData.push(null);
            pointcolors.push('rgb(161, 0, 255)');
          }
        } else if (new Date(each.ValueDate) > new Date(trendsData[0].PeriodDT)) {
          // empty block please remove if not requiered
        } else {
          labels.push(formatDate(graphLables));
          pastTrendsData.push(truncateTwoDecimal(parseFloat(each.MetricValue)));
          TrendsData.push(null);
          FutureTendsData.push(null);
          pointcolors.push('rgb(161, 0, 255)');
        }
      });

      CompleteDatasets.push({
        label: i18n.t('Past_Trends'),
        data: pastTrendsData,
        fill: true,
        lineTension: 0,
        backgroundColor: 'rgba(161, 0, 255, 0.4)',
        borderColor: 'rgb(161, 0, 255)',
        borderWidth: 2,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        floating: true,
        pointDotRadius: 8,
        pointBorderColor: pointcolors,
        pointBackgroundColor: 'white',
        pointBorderWidth: 2,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: pointcolors,
        pointHoverBorderColor: pointcolors,
        pointHoverBorderWidth: 2,
        pointRadius: 6,
        pointHitRadius: 20,
        spanGaps: true,
      });
    }
    if (trendsData.length) {
      trendsData.forEach((each) => {
        const periodDate = each.PeriodDT;
        const graphLables = moment(new Date(periodDate), 'MMM YY');
        // TrendsData.push(parseInt(each.MetricValue));
        // labels.push(formatDate(graphLables));

        // if(index === 0 && pastTrends.length){
        //   //console.log(pastTrends[pastTrends.length-1].ValueDate, new Date(each.PeriodDT))
        //   if(new Date(pastTrends[pastTrends.length-1].ActualValueDate) < new Date(each.PeriodDT) ){
        //     TrendsData.pop()
        //     TrendsData.push(truncateTwoDecimal(parseFloat(pastTrends[pastTrends.length-1].MetricValue)));
        //     TrendsData.push(truncateTwoDecimal(parseFloat(each.MetricValue)));
        //     labels.push(formatDate(graphLables));
        //     FutureTendsData.push(null);
        //   }else if(pastTrends[pastTrends.length-1].ActualValueDate === each.PeriodDT){
        //     TrendsData.pop()
        //     TrendsData.push(truncateTwoDecimal(parseFloat(each.MetricValue)));
        //   }else{
        //     TrendsData.push(truncateTwoDecimal(parseFloat(each.MetricValue)));
        //     FutureTendsData.push(null);
        //     labels.push(formatDate(graphLables));
        //   }
        // }else{
        TrendsData.push(truncateTwoDecimal(parseFloat(each.MetricValue)));
        FutureTendsData.push(null);
        labels.push(formatDate(graphLables));
      // }
      });
      CompleteDatasets.push({
        label: i18n.t('Current_Trends'),
        data: TrendsData,
        fill: true,
        lineTension: 0.1,
        borderWidth: 2,
        backgroundColor: 'rgba(0, 186, 255,0.4)',
        borderColor: 'rgb(0, 186, 255)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        floating: true,
        pointDotRadius: 8,
        pointBorderColor: 'rgb(0, 186, 255)',
        pointBackgroundColor: 'white',
        pointBorderWidth: 2,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: 'rgb(0, 186, 255)',
        pointHoverBorderColor: 'rgb(0, 186, 255)',
        pointHoverBorderWidth: 2,
        pointRadius: 6,
        pointHitRadius: 20,
        spanGaps: true,
      });
    }
    if (futureTrends.length) {
      futureTrends.forEach((each, index) => {
        const graphLables = moment(new Date(each.ValueDate), 'MMM YY');
        if (index === 0 && trendsData.length) {
          if (new Date(trendsData[trendsData.length - 1].PeriodDT) < new Date(each.ActualValueDate)) {
            FutureTendsData.pop();
            FutureTendsData.push(truncateTwoDecimal(parseFloat(trendsData[trendsData.length - 1].MetricValue)));
            FutureTendsData.push(truncateTwoDecimal(parseFloat(each.MetricValue)));
            labels.push(formatDate(graphLables));
          } else if (trendsData[trendsData.length - 1].PeriodDT === each.ActualValueDate) {
            FutureTendsData.pop();
            FutureTendsData.push(truncateTwoDecimal(parseFloat(each.MetricValue)));
          } else {
            FutureTendsData.push(truncateTwoDecimal(parseFloat(each.MetricValue)));
            labels.push(formatDate(graphLables));
          }
        } else {
          FutureTendsData.push(truncateTwoDecimal(parseFloat(each.MetricValue)));
          labels.push(formatDate(graphLables));
        }
      });
      CompleteDatasets.push({
        label: i18n.t('Future_Trends'),
        data: FutureTendsData,
        fill: true,
        lineTension: 0,
        borderWidth: 2,
        backgroundColor: 'rgba(161, 0, 255, 0.4)',
        borderColor: 'rgb(161, 0, 255)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        floating: true,
        pointDotRadius: 8,
        pointBorderColor: 'rgb(161, 0, 255)',
        pointBackgroundColor: 'white',
        pointBorderWidth: 2,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: 'rgb(161, 0, 255)',
        pointHoverBorderColor: 'rgb(161, 0, 255)',
        pointHoverBorderWidth: 2,
        pointRadius: 6,
        pointHitRadius: 20,
        spanGaps: true,
      });
    }
    setShowEditData(!!onEditClick);
    setBoiTrendsData(() => ({ labels, datasets: CompleteDatasets }));
    setPastTrend(() => updatedPastTrends);
    setFutureTrend(() => updatedFutureTrends);
    setIsApiLoading(false);
  };
  const onShowEditDataClick = (cancelType) => {
    setShowEditData(!showEditData);
    if (cancelType === true) {
      createDateSetsForBoiTrends(_.cloneDeep(boiApiResponseData));
    } else if (cancelType === 'edit') {
      createDateSetsForBoiTrends(_.cloneDeep(boiApiResponseData), true);
    }
  };
  const getBoiTrends = () => {
    const { Month, kpiMappingId } = props;
    let EndDate = new Date(Month);
    EndDate = new Date(EndDate.setDate(EndDate.getDate() + 11));
    const startDateTemp = new Date(EndDate);
    startDateTemp.setMonth(startDateTemp.getMonth() - 11);
    const startDate = new Date(startDateTemp.toLocaleDateString());
    setIsApiLoading(true);
    axiosInstance
      .get(`${LocalApiBaseUrl}Analytics/GetMetricTrendGraph`, {
        params: {
          kpiMappingID: kpiMappingId,
          varStartDate: startDate,
          varEndDate: EndDate,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (responseData) {
          createDateSetsForBoiTrends(_.cloneDeep(responseData));
          setBoiApiResponseData(() => _.cloneDeep(responseData));
        }
        setIsApiLoading(false);
      })
      .catch((error) => {
        setIsApiLoading(false);
        trycatchAlertPopup(error);
      });
  };
  useEffect(() => {
    getBoiTrends();
  }, []);

  const postBoiTrends = (pastTrendData, FutureTendData) => {
    const PastAndFutureTrend = [];
    const {
      ClientData, FilterDetails, Month, kpiMappingId,
    } = props;
    const clonedPastTrendData = _.cloneDeep(pastTrendData);
    const clonedFutureTendData = _.cloneDeep(FutureTendData);
    clonedPastTrendData.forEach((each, index) => {
      if (each.ValueDate) {
        const DateValue = new Date(each.ValueDate);
        clonedPastTrendData[index].ValueDate = new Date(DateValue.setDate(DateValue.getDate() + 1));
        PastAndFutureTrend.push(each);
      }
    });
    clonedFutureTendData.forEach((each, index) => {
      if (each.ValueDate) {
        const DateValue = new Date(each.ValueDate);
        clonedFutureTendData[index].ValueDate = new Date(DateValue.setDate(DateValue.getDate() + 1));
        PastAndFutureTrend.push(each);
      }
    });
    setIsApiLoading(true);
    axiosInstance
      .post(`${LocalApiBaseUrl}Analytics/AddEditMetricTrends?KPIMappingID=${kpiMappingId}&PeriodDT=${Month}`, {
        PastAndFutureTrend,
        OfferingID: FilterDetails.ProcessId,
        OrgEntityID: ClientData.id,
        LocationID: FilterDetails.LocationId,
      })
      .then((response) => {
        if (response.data.IsSuccess) {
          setIsApiLoading(false);
          getBoiTrends();
        } else {
          alert(response.data.StatusMessage);
          setIsApiLoading(false);
        }
      })
      .catch(() => {
        setIsApiLoading(false);
      });
  };

  const { roleReducers } = props;
  const configuratorAccess = roleReducers
      && roleReducers.length
      && roleReducers.filter((eachRole) => eachRole.FeatureName === 'Configurator')
        .length
    ? roleReducers.filter(
      (eachRole) => eachRole.FeatureName === 'Configurator',
    )[0].Operations
    : [];
  const showBoiEditButton = !!(configuratorAccess
  && configuratorAccess.length
  && (configuratorAccess.includes('Publish')
    || configuratorAccess.includes('Submit')));
  return (
    <>
      { isApiLoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />}
      <BoiTrends showEditData={showEditData} showBoiEditButton={showBoiEditButton} onShowEditDataClick={onShowEditDataClick} boiTrendsData={(_.cloneDeep(boiTrendsData))} />
      {showEditData && <PastAndFutureTrendsConfigration boiTrendsDates={boiTrendsDates} pastTrend={pastTrend} futureTrend={futureTrend} onShowEditDataClick={onShowEditDataClick} postBoiTrends={postBoiTrends} />}

    </>
  );
};
BoiTrendsWrapper.propTypes = {
  roleReducers: PropTypes.array.isRequired,
  Month: PropTypes.string.isRequired,
  kpiMappingId: PropTypes.number.isRequired,
  ClientData: PropTypes.object.isRequired,
  FilterDetails: PropTypes.object.isRequired,
};
export default BoiTrendsWrapper;
