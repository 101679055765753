import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import MetricInsightDetails from './MetricInsightDetails';
import MetricInfoModal from './MetricInfoModal';
import SynopsLoader from '../Shared/SynopsLoader';
import axiosInstance from '../Shared/interceptor';
import {
  trycatchAlertPopup,
  LocalApiBaseUrl,
  ROUTE_CONSTANTS,
  BATOfferingIcons,
  formatDate,
  BATDefaultStartDateMoment,
  BATDefaultEndDateMoment,
  idTokenstring
} from '../Shared/Constant';

import { screeNamesForUserTracking, logUserTrackingData } from '../Shared/commonFunctions';
import BatCommonHeader from './BatCommonHeader';
import OfferingsSubOfferingsSelectionModal from './OfferingsSubOfferingsSelectionModal';

// IMPORT STYLES
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import './CSS/batstyle.css';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;


class BatView extends Component {
  constructor(props) {
    super(props);
    const batViewScreenData = _.get(props, 'location.state.batViewScreenData', {});
    const offeringName = _.get(batViewScreenData, 'offeringName', '');
    const offeringId = _.get(batViewScreenData, 'offeringId', '');
    const offeringType = _.get(batViewScreenData, 'offeringType', '');
    const subOfferingId = _.get(batViewScreenData, 'subOfferingId', '');
    const subOfferingName = _.get(batViewScreenData, 'subOfferingName', '');
    const offerings = _.get(batViewScreenData, 'offerings', '');
    const functionalOfferings = _.get(batViewScreenData, 'functionalOfferings', '');
    const industryOfferings = _.get(batViewScreenData, 'industryOfferings', '');
    const clientCount = _.get(batViewScreenData, 'clientCount', '');

    this.state = {
      clientCount,
      isAPILoading: false,
      functionalOfferings,
      industryOfferings,
      offerings,
      offeringId,
      offeringName,
      offeringType,
      subOfferingName,
      subOfferingId,
      dataByMetricCategories: [],
      isVisibleMetricInsightDetailsModal: false,
      currentlySelectedMetricData: {},
      currentlySelectedMetricDataForInfo: {},
      isVisibleOfferingSubOfferingSelectionModal: false,
      formattedStartDate: formatDate(BATDefaultStartDateMoment),
      formattedEndDate: formatDate(BATDefaultEndDateMoment),
      expandedAccordionsKeysArray: ['all'], // KEEP ALL THE ACCORDIONS OPEN ON SCREEN LOAD
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const offeringsFromPreviousScreen = _.get(this.props, 'location.state.batViewScreenData.offerings', '');

    // IF THE `offerings` ARE EMPTY (WHEN THIS SCREEN IS LOADED DIRECTLY BY THE URL) NOT PASSED IN `location.state`, THEN REDIRECT TO THE LANDING SCREEN
    if (_.isEmpty(offeringsFromPreviousScreen)) {
      const location = {
        pathname: `/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`,
      };
      history.push(location);
    } else {
      this.executeRequestLogUserTrackingData();
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { subOfferingName } = this.state;

    // WHEN THE SUB-OFFERING SELECTION IS CHANGED THEN EXECUTE THE API TO LOG USER TRACKING DATA AND AGAIN EXPAND ALL THE ACCORDIONS
    if (prevState.subOfferingName !== subOfferingName) {
      this.executeRequestLogUserTrackingData();
      // AGAIN EXPAND ALL THE ACCORDIONS
      this.setState({
        expandedAccordionsKeysArray: ['all'],
      });
    }
  }

  executeRequestLogUserTrackingData = () => {
    const { clientData, languageData } = this.props;
    const { offeringName, subOfferingName } = this.state;
    const data = {
      PageName: screeNamesForUserTracking.BAT_LANDING_SCREEN,
      Client: _.get(clientData, 'name', ''),
      Language: languageData.langCode,
      Navigation1: offeringName,
      Navigation2: subOfferingName,
    };
    logUserTrackingData(data);
  };

  fetchData = () => {
    const {
      offeringName,
      subOfferingName,
      formattedStartDate,
      formattedEndDate,
      clientCount,
    } = this.state;
    this.setState({ isAPILoading: true });

    const requestData = {
      offeringName,
      towerName: subOfferingName,
      timeFrameStartDate: formattedStartDate,
      timeFrameEndDate: formattedEndDate,
      clientCount: _.toNumber(clientCount),
    };

    // FETCH THE DATA FROM THE API
    axiosInstance
      .post(`${LocalApiBaseUrl}BAT/GetOfferingBenchmarkDetails`, requestData)
      .then((response) => {
        let uniqueMetricCategories = [];
        let metricDetails = [];
        let dataByMetricCategories = [];

        if (!_.isEmpty(response.data)) {
          const responseData = response.data;
          metricDetails = responseData.metricDetails;

          // FOR THE METRICS, SEPARATE OUT METRIC VALUE AND ITS UNIT
          metricDetails = _.map(metricDetails, (eachMetric) => {
            let metricValue = parseFloat(eachMetric.aggregatedAverage);

            // FOR THE "Talent & HR" OFFERING THE METRIC VALUES ARE TO BE CORRECTED UP TO TWO DECIMAL PLACES FOR FLOATING POINT VALUE
            if (offeringName === 'Talent & HR') {
              metricValue = _.isInteger(metricValue)
                ? metricValue
                : metricValue.toFixed(2);
            }

            const metricUnit = _.toLower(eachMetric.metricUnit);
            let metricUnitFormatted = '';
            if (metricUnit === 'percentage') {
              metricUnitFormatted = '%';
            } else if (metricUnit === 'days') {
              metricUnitFormatted = 'Days';
            }

            // SPLIT THE METRIC CATEGORY NAME (BUSINESS OUTCOME) AND THE VALUE DRIVER NAME WHICH ARE SEPARATED BY COLON (:) AND ADD THE ATTRIBUTE `valueDriver` FOR EACH METRIC
            const arrayAfterSplit = _.split(eachMetric.metricCategory, ':', 2);
            const metricCategory = _.trim(arrayAfterSplit[0]);
            const valueDriver = !_.isEmpty(arrayAfterSplit[1]) ? _.trim(arrayAfterSplit[1]) : '';

            return {
              ...eachMetric,
              metricValue,
              metricUnitFormatted,
              metricCategory,
              valueDriver,
            };
          });

          // SORT THE METRICS BY `metricSequence` ATTRIBUTE
          metricDetails = _.sortBy(metricDetails, ['metricSequence']);

          // FILTER OUT UNIQUE METRIC CATEGORIES
          uniqueMetricCategories = _.uniq(_.map(metricDetails, 'metricCategory'));

          // GROUP THE METRICS BY THE UNIQUE CATEGORIES
          dataByMetricCategories = _.map(
            uniqueMetricCategories,
            (eachUniqueMetricCategory) => ({
              metricCategory: eachUniqueMetricCategory,
              metrics: _.filter(
                metricDetails,
                (
                  eachMetric,
                ) => eachMetric.metricCategory === eachUniqueMetricCategory,
              ),
            }),
          );
        }

        this.setState({
          dataByMetricCategories,
          isAPILoading: false,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ isAPILoading: false });
      });
  };

  // WOULD BE EXECUTED WHEN THE METRIC TILE WOULD BE CLICKED, WOULD OPEN UP THE "METRIC INSIGHT DETAILS" MODAL
  displayMetricBenchmarkChart = (metricData) => {
    this.setState({
      currentlySelectedMetricData: metricData,
      isVisibleMetricInsightDetailsModal: true,
    });
  };

  handleCloseMetricInsightDetailsModal = () => {
    this.setState({
      isVisibleMetricInsightDetailsModal: false,
    });
  };

  handleClickMetricInfoIcon = (metricData) => {
    const currentlySelectedMetricDataForInfo = metricData;
    this.setState({
      currentlySelectedMetricDataForInfo,
      isVisibleMetricInfoModal: true,
    });
  };

  handleMetricInfoModalHide = () => {
    this.setState({
      currentlySelectedMetricDataForInfo: {},
      isVisibleMetricInfoModal: false,
    });
  };

  handleHideOfferingSubOfferingSelectionModal = (
    offeringObj, subOfferingObj,
  ) => {
    this.setState(
      {
        isVisibleOfferingSubOfferingSelectionModal: false,
      },
      () => {
        if (!_.isEmpty(offeringObj) && !_.isEmpty(subOfferingObj)) {
          this.setState({
            // subOfferings: selectedSubOfferings,
            offeringId: offeringObj.offeringId,
            offeringName: offeringObj.offering,
            offeringType: offeringObj.offeringType,
            subOfferingId: subOfferingObj.id,
            subOfferingName: subOfferingObj.name,
          }, () => this.fetchData());
        }
      },
    );
  };

  handleCallbackHeaderStateChange = (headerStateObj) => {
    if (typeof headerStateObj === 'boolean') {
      this.setState({
        isVisibleOfferingSubOfferingSelectionModal: true,
      });
    } else if (Object.keys(headerStateObj).length < 3) {
      this.setState(
        {
          formattedStartDate: headerStateObj.formattedStartDate,
          formattedEndDate: headerStateObj.formattedEndDate,
        },
        () => {
          this.fetchData();
        },
      );
    } else {
      this.setState(
        {
          offerings: headerStateObj.offerings,
          functionalOfferings: headerStateObj.functionalOfferings,
          industryOfferings: headerStateObj.industryOfferings,
          formattedStartDate: headerStateObj.startDate,
          formattedEndDate: headerStateObj.endDate,
          offeringName: headerStateObj.offeringName,
          offeringId: headerStateObj.offeringId,
          offeringType: headerStateObj.offeringType,
          subOfferingName: headerStateObj.subOfferingName,
          clientCount: headerStateObj.clientCount,
        },
        () => {
          this.fetchData();
        },
      );
    }
  };

  // WOULD EXPAND/COLLAPSE ALL THE ACCORDIONS
  expandCollapseAllAccordions = (action) => {
    // IF THE ACTION IS "collapse" THEN COLLAPSE ALL THE ACCORDIONS AND IF THE ACTION IS "expand" THEN EXPAND ALL THE ACCORDIONS
    this.setState({
      expandedAccordionsKeysArray: action === 'collapse' ? [] : ['all'],
    });
  };

  // WOULD BE EXECUTED WHEN THE ACCORDION HEADER WOULD BE CLICKED. THIS WOULD TOGGLE THE OPEN/CLOSE OF THE ACCORDION BODY SECTION
  toggleAccordionSection = (currentEventKey, arrayOfAllAccordionKeys) => {
    const { expandedAccordionsKeysArray } = this.state;
    let modifiedExpandedAccordionsKeysArray = _.cloneDeep(
      expandedAccordionsKeysArray,
    );

    // WHEN CLICKED ON CURRENT ACCORDION HEADER AND ALL WERE EXPANDED, THEN JUST COLLAPSE THE CURRENT ONE AND KEEP THE REST EXPANDED
    if (_.indexOf(modifiedExpandedAccordionsKeysArray, 'all') !== -1) {
      modifiedExpandedAccordionsKeysArray = arrayOfAllAccordionKeys;
      modifiedExpandedAccordionsKeysArray = _.without(modifiedExpandedAccordionsKeysArray, 'all', currentEventKey);
    } else if (
      _.indexOf(
        modifiedExpandedAccordionsKeysArray,
        currentEventKey,
      ) === -1) {
      // IF THE CURRENT ACCORDION EVENT KEY IS NOT PRESENT IN THE ARRAY THEN ADD IT SO THAT THE CURRENT ACCORDION WOULD BE EXPANDED, ELSE REMOVE IT FROM THE ARRAY SO THAT THE CURRENT ACCORDION WOULD BE COLLAPSED
      modifiedExpandedAccordionsKeysArray.push(currentEventKey);
    } else {
      modifiedExpandedAccordionsKeysArray = _.without(
        modifiedExpandedAccordionsKeysArray,
        currentEventKey,
      );
    }

    this.setState({
      expandedAccordionsKeysArray: modifiedExpandedAccordionsKeysArray,
    });
  };

  render() {
    const offeringsFromPreviousScreen = _.get(this.props, 'location.state.batViewScreenData.offerings', '');

    // IF THE `offerings` ARE EMPTY (WHEN THIS SCREEN IS LOADED DIRECTLY BY THE URL) NOT PASSED IN `location.state`, THEN REDIRECT TO THE LANDING SCREEN
    if (_.isEmpty(offeringsFromPreviousScreen)) {
      return <Redirect to={`/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`} />;
    }
    const options = {
      loop: false,
      margin: 16,
      responsiveClass: '',
      dots: false,
      autoWidth: true,
      nav: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        600: {
          items: 2,
          nav: true,
        },
        1000: {
          items: 3,
          nav: true,
          loop: false,
        },
        1280: {
          items: 4,
          nav: true,
          loop: false,
        },
        1440: {
          items: 4,
          nav: true,
          loop: false,
        },
        1600: {
          items: 4,
          nav: true,
          loop: false,
        },
        1800: {
          items: 5,
          nav: true,
          loop: false,
        },
      },
    };

    const {
      isAPILoading,
      offerings,
      offeringId,
      subOfferingId,
      isVisibleMetricInsightDetailsModal,
      currentlySelectedMetricData,
      offeringName,
      subOfferingName,
      dataByMetricCategories,
      currentlySelectedMetricDataForInfo,
      isVisibleMetricInfoModal,
      isVisibleOfferingSubOfferingSelectionModal,
      functionalOfferings,
      industryOfferings,
      formattedStartDate,
      formattedEndDate,
      offeringType,
      clientCount,
      expandedAccordionsKeysArray,
    } = this.state;

    let tabContent;
    if (_.isEmpty(dataByMetricCategories)) {
      tabContent = (
        <div className="outcome-highlight no-whiteShadow no-table-layout">
          <h2>No data is available for the selected time frame</h2>
        </div>
      );
    } else {
      // FOR THE METRICS GROUPED BY "METRIC CATEGORIES" (BUSINESS OUTCOMES), CHECK IF THE METRICS HAVE ASSOCIATED "VALUE DRIVERS" AND IF IT IS SO THEN CHANGE THE DATA HIERARCHY SO THAT THE METRICS ARE GROUPED UNDER "VALUE DRIVERS" AND THEY IN TURN ARE GROUPED UNDER "METRIC CATEGORIES" (BUSINESS OUTCOMES)
      const modifiedDataByMetricCategories = _.map(
        dataByMetricCategories,
        (eachItem) => {
          let { metrics } = eachItem;
          const uniqueValueDrivers = _.uniq(_.map(metrics, 'valueDriver'));
          let dataByValueDrivers = [];
          if (!_.isEmpty(_.first(uniqueValueDrivers))) {
            dataByValueDrivers = _.map(
              uniqueValueDrivers,
              (eachValueDriver) => {
                const metricsOfValueDriver = _.filter(
                  metrics,
                  { valueDriver: eachValueDriver },
                );

                return {
                  valueDriver: eachValueDriver,
                  metrics: metricsOfValueDriver,
                };
              },
            );
            metrics = [];
          }

          return {
            metricCategory: eachItem.metricCategory,
            metrics,
            dataByValueDrivers,
          };
        },
      );

      // RETURNS THE LAYOUT FOR METRICS
      const metricsLayout = (metrics) => (
        <div className="custom-carousel-padding bat-carousel-padding">
          <OwlCarousel options={options} className="owl-carousel">
            {_.map(metrics, (eachMetric) => {
              const { metricValue } = eachMetric;
              const { metricUnitFormatted } = eachMetric;

              return (
                <div className="item gray-color" data-place="bottom" currentitem="false" key={eachMetric.metricName}>
                  <div className="item-left">
                    <p className="tile-metric-value">
                      {metricValue}
                      <span>
&nbsp;
                        {metricUnitFormatted}
                      </span>
                    </p>
                    <p className="tile-metric-desc">{eachMetric.metricName}</p>
                  </div>
                  <div className="item-right">
                    <div className="metric-details-icon" data-tip data-for={eachMetric.metricName}>
                      {eachMetric.isExternalBenchmarksAvailable && <i className="fal fa-bullseye-pointer" />}
                      <ReactTooltip place="left" effect="solid" id={eachMetric.metricName}>
                        <span>External Benchmark Available</span>
                      </ReactTooltip>
                    </div>

                    <div className="tilequartile-performance">
                      <p>Top quartile performance </p>
                      <span>{eachMetric.topQuartilePerformance}</span>
                    </div>
                  </div>

                  <div className="dropdown dropleft">
                    <span
                      type="button"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      data-tip
                      data-for="moreActionTooltip"
                    >
                      <ReactTooltip place="bottom" effect="solid" id="moreActionTooltip">
                        <span>More Actions</span>
                      </ReactTooltip>
                    </span>

                    <div className="dropdown-menu">
                      <a
                        href="javascript:void(0)"
                        className="dropdown-item"
                        onClick={
                          () => this.handleClickMetricInfoIcon(eachMetric)
                        }
                      >
                        <span className="info-icon" />
                        Info
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="dropdown-item"
                        onClick={
                          () => this.displayMetricBenchmarkChart(eachMetric)
                        }
                      >
                        <span className="insights-new-icon" />
                        Insights
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      );

      const arrayOfAllAccordionKeys = [];
      tabContent = _.map(modifiedDataByMetricCategories, (eachItem, index) => {
        const accordionEventKey = String(`metCat-${index}`);
        arrayOfAllAccordionKeys.push(accordionEventKey);
        const isAccordionExpanded = _.indexOf(
          expandedAccordionsKeysArray,
          accordionEventKey,
        ) !== -1
          || _.indexOf(expandedAccordionsKeysArray, 'all') !== -1;
        const accordionActiveKey = isAccordionExpanded ? accordionEventKey : '';

        return (
          <div className="bat-category-accordion" key={accordionEventKey}>
            <div className="custom-tilecard">
              <div className="tilecard-wrapper">
                <Accordion
                  activeKey={accordionActiveKey}
                  key={accordionEventKey}
                  className={classNames('bat-main-accordion', {
                    'bat-accordion-only-level-one': _.isEmpty(eachItem.dataByValueDrivers),
                  })}
                >
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={accordionEventKey}
                      onClick={
                        () => this.toggleAccordionSection(
                          accordionEventKey,
                          arrayOfAllAccordionKeys,
                        )
                      }
                      className={classNames('card-link', { collapsed: !isAccordionExpanded })}
                    >
                      {eachItem.metricCategory}
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey={accordionEventKey}>
                      <Card.Body>
                        <>
                          {/* IF THE METRICS ARE DIRECTLY UNDER THE METRIC CATEGORY THEN GENERATE THE LAYOUT FOR METRICS */}
                          {!_.isEmpty(eachItem.metrics)
                            && isAccordionExpanded
                            && metricsLayout(eachItem.metrics)}

                          {/* IF THE METRIC CATEGORY HAS "VALUE DRIVERS" UNDER IT THEN GENERATE HIERARCHICAL LAYOUT OF METRICS UNDER EACH "VALUE DRIVER" */}
                          {isAccordionExpanded
                            && !_.isEmpty(eachItem.dataByValueDrivers)
                            && _.map(
                              eachItem.dataByValueDrivers,
                              (eachData, valDriverIndex) => {
                                const currentAccordionEventKey = String(
                                  `valDriver-${valDriverIndex}`,
                                );
                                arrayOfAllAccordionKeys.push(
                                  currentAccordionEventKey,
                                );
                                const isCurrentAccordionExpanded = _.indexOf(
                                  expandedAccordionsKeysArray,
                                  currentAccordionEventKey,
                                ) !== -1
                                || _.indexOf(expandedAccordionsKeysArray, 'all') !== -1;
                                const currentAccordionActiveKey = isCurrentAccordionExpanded ? currentAccordionEventKey : '';
                                return (
                                  <Accordion
                                    activeKey={currentAccordionActiveKey}
                                    key={currentAccordionEventKey}
                                    className="bat-sub-accordion"
                                  >
                                    <Card>
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={currentAccordionEventKey}
                                        onClick={
                                          () => this.toggleAccordionSection(
                                            currentAccordionEventKey,
                                            arrayOfAllAccordionKeys,
                                          )
                                        }
                                        className={classNames('card-link', { collapsed: !isCurrentAccordionExpanded })}
                                      >
                                        {eachData.valueDriver}
                                      </Accordion.Toggle>

                                      <Accordion.Collapse
                                        eventKey={currentAccordionEventKey}
                                      >
                                        <Card.Body>
                                          {isCurrentAccordionExpanded
                                            && metricsLayout(eachData.metrics)}
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  </Accordion>
                                );
                              },
                            )}
                        </>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <>
        {/* IF API IS LOADING THEN SHOW THE LOADER */}
        {isAPILoading && <LoadingOverlay spinner={<SynopsLoader />} active />}
        <BatCommonHeader
          offeringName={offeringName}
          subOfferingName={subOfferingName}
          offeringType={offeringType}
          subOfferingId={subOfferingId}
          clientCount={clientCount}
          callbackHeaderStateChange={
            (headerStateObj) => this.handleCallbackHeaderStateChange(
              headerStateObj,
            )
          }
          renderedFromScreen="BatView"
          renderedFromScreenData={{
            offeringName,
            offeringId,
            offeringType,
            subOfferingId,
            subOfferingName,
            offerings,
            functionalOfferings,
            industryOfferings,
            clientCount,
          }}
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="px-40 pt-60 batView offering-details-screen">
                {/* NEW OFFERING CATEGORIES TITLE START */}
                <div className="batOffering-category-title">
                  <div>
                    {offeringName}
                    {' '}
                    -
                    {subOfferingName}
                  </div>
                  <div className="collapse-btn-wrap">
                    <button
                      type="button"
                      className="collapse-btn"
                      onClick={() => {
                        let action = 'collapse';
                        // IF NO ACCORDIONS ARE EXPANDED, THEN SET THE ACTION TO "expand" TO EXPAND ALL THE ACCORDIONS
                        if (_.isEmpty(expandedAccordionsKeysArray)) {
                          action = 'expand';
                        }
                        this.expandCollapseAllAccordions(action);
                      }}
                    >
                      {_.isEmpty(expandedAccordionsKeysArray) ? 'Expand All' : 'Collapse All'}
                    </button>
                  </div>
                </div>

                <div className="outcome-highlight no-whiteShadow no-table-layout tilecard-border bat-accordion-view">
                  {tabContent}
                </div>
                {/* NEW OFFERING CATEGORIES TITLE END */}
              </div>
            </div>
          </div>
        </div>

        {/* METRIC INSIGHT DETAILS MODAL WHICH DISPLAYS THE GRAPH */}
        {isVisibleMetricInsightDetailsModal && (
          <MetricInsightDetails
            offeringName={offeringName}
            offeringType={offeringType === 'Industrial' ? 'industry' : _.toLower(offeringType)}
            subOfferingName={subOfferingName}
            metricData={currentlySelectedMetricData}
            startDate={formattedStartDate}
            endDate={formattedEndDate}
            onModalClose={() => this.handleCloseMetricInsightDetailsModal()}
            clientCount={clientCount}
          />
        )}

        {/* MODAL TO SHOW METRIC INFO WHEN CLICKED ON INFO ICON */}
        {isVisibleMetricInfoModal && (
          <MetricInfoModal
            title={currentlySelectedMetricDataForInfo.metricName}
            description={currentlySelectedMetricDataForInfo.metricDescription}
            onClose={() => this.handleMetricInfoModalHide()}
          />
        )}

        {isVisibleOfferingSubOfferingSelectionModal && (
          <div>
            <OfferingsSubOfferingsSelectionModal
              offerings={offerings}
              functionalOfferings={functionalOfferings}
              industryOfferings={industryOfferings}
              BATOfferingIcons={BATOfferingIcons}
              onModalHide={
                (
                  offeringObj,
                  subOfferingObj,
                ) => this.handleHideOfferingSubOfferingSelectionModal(
                  offeringObj,
                  subOfferingObj,
                )
              }
              selectedOfferingId={String(offeringId)}
              selectedSubOfferingName={subOfferingName}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  clientData: state.CurrentFilter.ClientData,
});

BatView.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  clientData: PropTypes.object.isRequired,
  languageData: PropTypes.shape({
    langCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps)(BatView));
