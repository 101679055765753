export let intelligentReducers = (state = {
    IOJ: [],
    Datas:{viewData:[],StageData:[],BoiList:[]}
}, action) => {
    switch (action.type) {
        case "INITATIVEDEATILS":{
            state = { ...state }
            state["Datas"] = action.payload
            return state
        }
        case "INITIALISEIOJ": {
            state = { ...state }
            state["IOJ"] = action.payload
            return state
        }

        case "ADD_IOJ": {
            
            state = { ...state }
                if( state["IOJ"].filter((data)=> data.ID== action.payload.ID && data.ProjectInitiativeName == action.payload.ProjectInitiativeName).length >0 ){
                 state["IOJ"].filter((data)=> data.ID== action.payload.ID)[0] = action.payload;
            }

              else{
                state["IOJ"].push(action.payload)
              }
         
         state["IOJ"].sort((a, b) => (a.BOSeqNumber > b.BOSeqNumber) ? 1 : -1)
        
        return state}

        case "REMOVE_IOJ": {
            

            state = { ...state }


            let iojRemove = state["IOJ"].filter((x) => x.ID == action.payload.id && x.ProjectInitiativeName == action.payload.type && x.MaturityPhase == action.payload.maturity)[0]
            var index = state["IOJ"].indexOf(iojRemove)
            state["IOJ"].splice(index, 1)
            return state

        }

        case "UPDATE_IOJ": {
            

            state = { ...state }


            let iojRemove = state["IOJ"].filter((x) => x.ID == action.payload.id && x.ProjectInitiativeName == action.payload.type && x.MaturityPhase == action.payload.maturity)[0]
            var index = state["IOJ"].indexOf(iojRemove)
            state["IOJ"].splice(index, 1)
            return state

        }
            
        default: return state
    }
}
