import React, { Component } from "react";

export default class FrontField extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render(){
        
        return(
            <>
            {this.props.data.FrontSequence != 0 ? <span>{this.props.data.FrontSequence}</span>: <span></span>}
            
            </>
        )
    }
}
