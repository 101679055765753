import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { store } from '../reducers/configureStore';
import { InitialiseState, SaveClick } from '../actions/mindMapAction';

class MindMapListDropdown extends Component {
  constructor() {
    super();
    this.state = {
      selectedItem: '',
      selectedObj: [],
    };
  }

  componentDidMount() {
    const { metricName } = this.props;
    this.setState({
      selectedItem: metricName,
    });
  }

  componentDidUpdate() {
    const { SaveClick: propsSaveClick, Sequence, listIndex } = this.props;
    const { selectedObj } = this.state;

    if (propsSaveClick) {
      this.onItemSelect(Sequence, selectedObj, listIndex);
    }
  }

  onItem = (data) => {
    const tempArr = [];
    tempArr.push(data);

    this.setState({
      selectedObj: tempArr,
      selectedItem: data.metricName,
    });
  }

  onItemSelect = (index, data, listIndex) => {
    const { selectedItem } = this.state;
    const { MindMapData, SaveClick: SaveClickFromProps, handleSave } = this.props;
    if (selectedItem !== 'Select') {
      const tempItem = [...MindMapData];
      if (data.length > 0) {
        if (!tempItem[index].listData.find((item) => item.metricName === data[0].metricName)) {
          if (tempItem[index].listData.length <= 5) {
            if (data !== '') {
              if (listIndex === 'New') {
                const deletedIndex = tempItem[index].deletedData.findIndex((a) => a.metricID === data[0].metricID);
                if (deletedIndex > -1) {
                  const item = tempItem[index].deletedData.splice(deletedIndex, 1);
                  tempItem[index].listData.push({
                    ...data[0],
                    metricName: data[0].metricName,
                    ID: item[0].ID,
                    metricID: data[0].metricID,
                  });
                } else {
                  tempItem[index].listData.push({
                    ...data[0],
                    metricName: data[0].metricName,
                    ID: 0,
                    metricID: data[0].metricID,
                    temp: false,
                  });
                  tempItem[index].addedData.push({
                    ID: 0,
                    metricID: data[0].metricID,
                  });
                }
              } else {
                const selectedID = tempItem[index].listData[listIndex].ID;
                const selectedMetric = tempItem[index].listData[listIndex].metricID;
                if (selectedID > 0) {
                  tempItem[index].updatedData.push({
                    ID: selectedID,
                    metricID: data[0].metricID,
                  });
                } else {
                  const foundItem = tempItem[index].addedData.findIndex((a) => a.metricID === selectedMetric);
                  tempItem[index].addedData[foundItem].metricID = data[0].metricID;
                }
                tempItem[index].listData[listIndex].ragStatus = data[0].ragStatus;
                tempItem[index].listData[listIndex].metricName = data[0].metricName;
                tempItem[index].listData[listIndex].metricID = data[0].metricID;
                tempItem[index].listData[listIndex].temp = false;
              }
              tempItem[index].addButtonEnable = true;
              tempItem[index].active = false;
              store.dispatch(InitialiseState(tempItem));
              store.dispatch(SaveClick(false));
              this.setState({
                selectedItem: '',
              });
            } else {
              window.alert('You must select an item from the dropdown');
            }
          }
        } else {
          window.alert('This item is already in the list');
        }
      } else {
        tempItem[index].addButtonEnable = true;
        tempItem[index].active = false;
        tempItem[index].listData[listIndex].temp = false;
        store.dispatch(InitialiseState(tempItem));
        this.setState({
          selectedItem: '',
        });
      }
    } else {
      window.alert('You must select an item');
    }
    if (SaveClickFromProps) {
      handleSave();
      store.dispatch(SaveClick(false));
    }
  }

  clearItem = (sequence, listIndex) => {
    const { MindMapData } = this.props;
    const tempItem = _.cloneDeep(MindMapData);
    if (listIndex === 'New') {
      tempItem[sequence].active = false;
    } else {
      tempItem[sequence].listData[listIndex].temp = false;
    }
    tempItem[sequence].addButtonEnable = true;
    store.dispatch(InitialiseState(tempItem));
    this.setState({
      selectedItem: '',
      selectedObj: [],
    });
  }

  render() {
    const { dropdownData, listIndex } = this.props;
    const { selectedItem, selectedObj } = this.state;
    return (
      <>
        <div className="mindmaplevel-dropdown">
          <Dropdown id="mindmap-offering3">
            <div className="offering-droplistwrapper">
              <Dropdown.Toggle><span>{selectedItem}</span></Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="droplist-wrapper">
                  {
                dropdownData.dropdownData.map((item) => (
                  <Dropdown.Item
                    href="javascript:void(0);"
                    active={selectedItem === item.metricName}
                    key={item.metricID}
                    value={item.metricName}
                    onClick={() => { this.onItem(item); }}
                  >
                    {item.metricName}
                  </Dropdown.Item>
                ))
              }
                </div>
              </Dropdown.Menu>
            </div>
          </Dropdown>
          <div className="mindmaplevel-dropdownaction">
            <span onClick={() => { this.onItemSelect(dropdownData.sequence, selectedObj, listIndex); }} role="button" tabIndex="0" aria-label="Select"><i className="far fa-check" /></span>
            <span onClick={() => this.clearItem(dropdownData.sequence, listIndex)} role="button" tabIndex="0" aria-label="Dropdown item"><i className="fal fa-times" /></span>
          </div>

        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  MindMapData: state.mindMapReducer.InitialData,
  SaveClick: state.mindMapReducer.saveClick,
});
MindMapListDropdown.propTypes = {
  SaveClick: PropTypes.bool.isRequired,
  Sequence: PropTypes.number.isRequired,
  listIndex: PropTypes.string.isRequired,
  MindMapData: PropTypes.array.isRequired,
  metricName: PropTypes.string.isRequired,
  dropdownData: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
};
const conntedFunc = connect(
  mapStateToProps,
);
export default compose(
  withRouter,
  conntedFunc,
)(MindMapListDropdown);
