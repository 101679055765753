import React from 'react';
import BeforeAfterTimeline from './BeforeAfterTimeline';
import BeforeAfterLandingPageComments from './BeforeAfterLandingPageComments';

const BeforeAfterCommentsAndTimeseriesWrapper = ({
  sectionData, node, timeLineData, stepName, showAddComments, suggestions, userName, onShowDoubleCommentsboxes, handleNodeClick, ...props
}) => (
  <div className="mapping-comments no-commentsblock">
    {(node && sectionData[node] && sectionData[node].BaselineYear ) ? (
      <BeforeAfterTimeline timeLineData={sectionData[node]} stepName={node} userName={userName} />
    ) : null}
    {stepName ? <BeforeAfterTimeline timeLineData={timeLineData} stepName={stepName} /> : null}
    {showAddComments && <BeforeAfterLandingPageComments handleNodeClick={handleNodeClick} onShowDoubleCommentsboxes={onShowDoubleCommentsboxes} userName={userName} currentNode={node} suggestions={suggestions} />}
  </div>
);

export default BeforeAfterCommentsAndTimeseriesWrapper;
