import React, { Component } from "react";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, Tab, Table, Modal, Button, Alert } from "react-bootstrap";
import { faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { store } from "../reducers/configureStore";
import { LocalApiBaseUrl } from "../Shared/Constant";
import {
  Sub_So_Categories,
  Metric_List,
  fieldNamesByConnectorID,
} from "./QueryBuilderAPIs";
import ReviewMetricStep from "./ReviewMetricStep";
import LoadingOverlay from "react-loading-overlay";
import KeyPadPanelComponent from "./KeyPadPanelComponent";
import MultiSelect from "react-multi-select-component";
import SynopsLoader from "../Shared/SynopsLoader";
import _ from 'lodash';

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;

const operandArr = ["A", "B", "C", "D", "E"];

const overrideStrings = {
  selectSomeItems: "Select",
  allItemsAreSelected: "All items selected.",
  selectAll: "Select All ",
  search: "Search",
};

export default class QueryBuilderPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      synOpsOptions: [],
      subSoCategoryOptions: [],
      metricOptions: [],
      selectedMetricName: "",
      metricSelecObj: {
        metricName: "",
        id: "",
        metricDescription: "",
        metricPublishedVal: "",
        metricUnit: "",
      },
      isActive:
        this.props.metricType === "UPDATE"
          ? this.props.updateMetricObj.ISActive
          : true,
      showFormulaBuilder:
        this.props.metricType === "UPDATE"
          ? this.props.updateMetricObj.IsFormula
          : true,
      sourceOptions: [],
      dynamicConditions: [],
      tabsArr: [],
      finalArr: [],
      definitionError: false,
      defineMetricObj: {
        synopsSelected: 0,
        synopsSubCatSelected: 0,
        selectedMetric: 0,
      },
      activeStep: 1,
      formulaString: "",
      HasMaster: false,
      fieldValueOptions: [],
      renderReview: false,
      reviewStepObject: {},
      showAlert: false,
      showFetchedRecords: false,
      fieldValObject: {},
      showLoaderOnPage: false,
      result: "",
      isDisabledA: false,
      isDisabledOp: this.props.metricType === "UPDATE" ? false : true,
      isDisabledNumber: false,
      isNumberAdded: false,
      testOperandArr: ["A", "B", "C", "D", "E"],
      KPIUnit: "",
      selectedVariable: "calcA",
    };
  }

  /* API calls & initial data load starts */

  //set Initial Values
  componentWillMount() {
    let parsedResponse = store.getState().CurrentFilter.SOCategories;
    let synOpsOptions = parsedResponse.map((dropValue, index) => {
      return (
        <option key={dropValue.SOCategoryID} value={dropValue.SOCategoryID}>
          {dropValue.SOCategoryName}
        </option>
      );
    });
    synOpsOptions.unshift(
      <option key={0} value="0">
        Select SOCategory
      </option>
    );
    let sourceList = store.getState().CurrentFilter.sourceConnectionList;
    let tabsArr = _.cloneDeep(this.state.tabsArr);
    let def1 = {
      OperandName: "A",
      OperandValue: "",
      Operators: "",
      SourceConnection: "",
      ConnectorId: "",
      ResultFieldName: "",
      ResultFieldDataType: "",
      AppliedFunction: "",
      ResultQuery: "",
      CountResultQuery: "",
      ResultQueryValue: "",
      CountOfRowsAffected: "",
      ConditionFields: [
        {
          ConditionFieldName: "",
          ConditionOperator: "",
          ConditionValue: "",
          ConditionalGate: "",
          HasMasterKey: false,
          fieldValueOptions: [],
        },
      ],
      ConnectorFieldmaster: [],
    };
    tabsArr.push(def1);
    this.setState({ tabsArr: tabsArr });
    this.setState({ synOpsOptions, sourceOptions: sourceList });
  }

  //set Initial Data for Update Case
  componentDidMount() {
    if (this.props.metricType === "UPDATE") {
      let tempValues = [];
      let valueOptions = [];
      let updateArr = _.cloneDeep(this.props.updateMetricObj.Formulavariables);
      const SortFields = function (obj1, obj2) {
        if (obj1.ConditionFieldSequence > obj2.ConditionFieldSequence) return 1;
        if (obj1.ConditionFieldSequence < obj2.ConditionFieldSequence) return -1;
        return 0;
      };
      if (updateArr !== null) {
        updateArr = updateArr.filter((val) => val.OperandValue !== "Constant");
        updateArr.map((val) => val.ConditionFields.sort(SortFields));
        let KPIUnit = this.props.updateMetricObj.KPIUnit;
        let testOperandArr = this.props.updateMetricObj.IsFormula ? this.state.testOperandArr.slice(updateArr.length) : this.state.testOperandArr;
        updateArr.map((Operand, i) => {
          Operand.ConditionFields.map((val, j) => {
            if (val.ConditionOperator === "IN") {
              tempValues = [];
              valueOptions = [];
              let ConditionValue = val.ConditionValue.split(',');
              //Update fieldValueOptionsTemp to show for multiselect
              val.fieldValueOptions.forEach(val => {
                let newItem = { label: val.MasterValue.toUpperCase(), value: val.MasterValue.toUpperCase(), OperandName: Operand.OperandName, FieldName: Operand.ConditionFields[j].ConditionFieldName, index: j };
                valueOptions.push(newItem);
              })
              val.fieldValueOptionsTemp = [...valueOptions];
              //Update Selected Values to show in Multiselect    
              ConditionValue.map((val) => {
                tempValues.push({ label: val.toUpperCase(), value: val.toUpperCase(), OperandName: Operand.OperandName, FieldName: Operand.ConditionFields[j].ConditionFieldName, index: j })
              })
              let selectedValues = tempValues.filter((tempval) => tempval.index == j && tempval.FieldName == val.ConditionFieldName);
              val.ConditionValueTemp = [...selectedValues];
            }
            else {
              val.ConditionValueTemp = "";
              val.fieldValueOptionsTemp = "";
            }

          })
        })
        this.setState({
          tabsArr: updateArr,
          showFormulaBuilder: this.props.updateMetricObj.IsFormula,
          result: this.props.updateMetricObj.FormulaText,
          testOperandArr,
          isDisabledA: this.props.updateMetricObj.IsFormula,
          KPIUnit,
          isActive: this.props.updateMetricObj.ISActive,
        });
      }
    }
  }

  getFieldNamesAPI = (sourceID, OperandName, i) => {
    let tabsArr = _.cloneDeep(this.state.tabsArr);
    let finalArr = _.cloneDeep(this.state.finalArr);
    axiosInstance
      .get(`${LocalApiBaseUrl}QueryBuilder/GetConnectorFieldMasterData_QB`, {
        params: { connectorid: sourceID },
      })
      .then((response) => {
        tabsArr.find((operand, i) => {
          if (operand.OperandName === OperandName) {
            tabsArr[i] = { ...tabsArr[i], ConnectorFieldmaster: response.data };
          }
        });
        finalArr.find((operand, i) => {
          if (operand.OperandName === OperandName) {
            finalArr[i] = {
              ...finalArr[i],
              ConnectorFieldmaster: response.data,
            };
          }
        });
        this.setState({ tabsArr: tabsArr, finalArr: finalArr });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        tabsArr.find((operand, i) => {
          if (operand.OperandName === OperandName) {
            tabsArr[i].ConnectorFieldmaster = [];
          }
        });
        finalArr.find((operand, i) => {
          if (operand.OperandName === OperandName) {
            finalArr[i].ConnectorFieldmaster = [];
          }
        });
        this.setState({ tabsArr: tabsArr, finalArr: finalArr });
      });
  };

  getMetricDetails = (orgID, offerID, locID, subSoCategoryID) => {
    let DealID =
      this.props.metricType === "UPDATE"
        ? this.props.selectedConfMetricObj.DealID
        : this.props.dealQueryInfo.DealID;
    axiosInstance
      .post(
        `${LocalApiBaseUrl}QueryBuilder/GetKPIMappingQB?DealId=${DealID}&SOSubCategoryId=${subSoCategoryID}`
      )
      .then((response) => {
        let metricData = response.data;
        this.setState({ metricOptions: metricData });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  /* API calls & initial data load ends */

  closePopup = () => {
    this.resetFormulaValues();
    this.props.closeConnection();
  };
  /* Keypad Panel Function starts */

  //Keypad Enable/Disable and Handling Clicks
  onKeyPadClick = (button) => {
    if (button === "+" || button === "-" || button === "*" || button === "/") {
      this.setState({
        isDisabledOp: true,
        isDisabledA: false,
        isDisabledNumber: false,
      });
    }
    //Handling Numbers
    else if (
      button === "0" ||
      button === "1" ||
      button === "2" ||
      button === "3" ||
      button === "4" ||
      button === "5" ||
      button === "6" ||
      button === "7" ||
      button === "8" ||
      button === "9"
    ) {
      this.handleVariables(true);
      this.setState({
        isDisabledNumber: false,
        isNumberAdded: true,
        isDisabledOp: false,
      });
    }
    else {
      this.setState({ isDisabledOp: false });
    }

    //Handling Variables
    switch (button) {
      case "A": {
        this.setState({
          isDisabledA: true,
          // isDisabledNumber: true,
          // isDisabledOp: false,
        });
        this.updateOperandArr(button, "BTN_ADD");
        break;
      }
      case "B": {
        this.setState({
          isDisabledA: true,
          // isDisabledNumber: true,
          // isDisabledOp: false,
        });
        this.handleAddVariables(button);
        this.updateOperandArr(button, "BTN_ADD");
        break;
      }
      case "C": {
        this.setState({
          isDisabledA: true,
          // isDisabledNumber: true,
          // isDisabledOp: false,
        });
        this.handleAddVariables(button);
        this.updateOperandArr(button, "BTN_ADD");
        break;
      }
      case "D": {
        this.setState({
          isDisabledA: true,
          // isDisabledNumber: true,
          // isDisabledOp: false,
        });
        this.handleAddVariables(button);
        this.updateOperandArr(button, "BTN_ADD");
        break;
      }
      case "E": {
        this.setState({
          isDisabledA: true,
          // isDisabledNumber: true,
          // isDisabledOp: false,
        });
        this.handleAddVariables(button);
        this.updateOperandArr(button, "BTN_ADD");
        break;
      }
      default: this.setState({ isDisabledA: false });
    }

    this.setState({
      result: this.state.result + button,
    });
  };

  //to Enable disable Keyboard Panel Variables
  handleVariables = (isNumberAdded) => {
    isNumberAdded
      ? this.setState({ isDisabledA: true })
      : this.setState({ isDisabledA: false });
  };

  //Adding New Variable on Formula Builder
  handleAddVariables = (button) => {
    let tabsArr = _.cloneDeep(this.state.tabsArr);
    if (this.state.tabsArr.length < 5) {
      let dummyDef = {
        OperandName: button,
        OperandValue: "",
        Operators: "+",
        SourceConnection: "",
        ConnectorId: "",
        ResultFieldName: "",
        ResultFieldDataType: "",
        AppliedFunction: "",
        ResultQuery: "",
        CountResultQuery: "",
        ResultQueryValue: "",
        CountOfRowsAffected: "",
        ConditionFields: [
          {
            ConditionFieldName: "",
            ConditionOperator: "",
            ConditionValue: "",
            ConditionalGate: "",
            HasMasterKey: false,
            fieldValueOptions: [],
          },
        ],
        ConnectorFieldmaster: [],
      };
      tabsArr.push(dummyDef);
      this.setState({ tabsArr });
    } else {
      alert("Maximum 5 Variables allowed");
      //e.preventDefault();
    }
  };

  // To update Variables available for use [A,B,C,D,E]
  updateOperandArr = (button, type, variable) => {
    let initialArr = [...this.state.testOperandArr];
    if (type === "BTN_DLT") {
      if (variable == "A") {
        let tabsArr = [];
        let dummyDef = {
          OperandName: button,
          OperandValue: "",
          Operators: "+",
          SourceConnection: "",
          ConnectorId: "",
          ResultFieldName: "",
          ResultFieldDataType: "",
          AppliedFunction: "",
          ResultQuery: "",
          CountResultQuery: "",
          ResultQueryValue: "",
          CountOfRowsAffected: "",
          ConditionFields: [
            {
              ConditionFieldName: "",
              ConditionOperator: "",
              ConditionValue: "",
              ConditionalGate: "",
              HasMasterKey: false,
              fieldValueOptions: [],
            },
          ],
          ConnectorFieldmaster: [],
        };
        tabsArr.push(dummyDef);
        this.setState({ tabsArr });

      }
      initialArr.unshift(button);
    } else {
      initialArr.shift();
    }

    this.setState({
      testOperandArr: initialArr,
    });
  };

  //Removing Variables from Array
  handleRemoveOperand = () => {
    let tabsArr = _.cloneDeep(this.state.tabsArr);
    tabsArr.pop();
    this.setState({ tabsArr });
  };

  //Reset Formula
  resetFormulaValues = (event) => {
    let tabsArr = [];
    let def1 = {
      OperandName: "A",
      OperandValue: "",
      Operators: "",
      SourceConnection: "",
      ConnectorId: "",
      ResultFieldName: "",
      ResultFieldDataType: "",
      AppliedFunction: "",
      ResultQuery: "",
      CountResultQuery: "",
      ResultQueryValue: "",
      CountOfRowsAffected: "",
      ConditionFields: [
        {
          ConditionFieldName: "",
          ConditionOperator: "",
          ConditionValue: "",
          ConditionalGate: "",
          HasMasterKey: false,
          fieldValueOptions: [],
        },
      ],
      ConnectorFieldmaster: [],
    };
    tabsArr.push(def1);
    this.setState({
      tabsArr: tabsArr,
      finalArr: [],
      isDisabledNumber: false,
      isDisabledOp: true,
      selectedVariable: "calcA",
    });
  };

  //backspace
  backspace = () => {
    let deletedBtn = this.state.result.charAt(this.state.result.length - 1);
    let prevDelBtn = this.state.result.charAt(this.state.result.length - 2);
    let disableProp = false,
      isDisabledOp = false,
      isDisabledNumber = false;
    if (
      prevDelBtn === "A" ||
      prevDelBtn === "B" ||
      prevDelBtn === "C" ||
      prevDelBtn === "D" ||
      prevDelBtn === "E"
    ) {
      disableProp = true;
    } else if (
      prevDelBtn === "+" ||
      prevDelBtn === "-" ||
      prevDelBtn === "*" ||
      prevDelBtn === "/"
    ) {
      isDisabledOp = true;
    }
    switch (deletedBtn) {
      case "A": {
        this.updateOperandArr(deletedBtn, "BTN_DLT", "A");
        break;
      }
      case "B": {
        this.handleRemoveOperand();
        this.updateOperandArr(deletedBtn, "BTN_DLT");
        break;
      }
      case "C": {
        this.handleRemoveOperand();
        this.updateOperandArr(deletedBtn, "BTN_DLT");
        break;
      }
      case "D": {
        this.handleRemoveOperand();
        this.updateOperandArr(deletedBtn, "BTN_DLT");
        break;
      }
      case "E": {
        this.handleRemoveOperand();
        this.updateOperandArr(deletedBtn, "BTN_DLT");
        break;
      }
    }
    this.setState({
      result: this.state.result.slice(0, -1),
      isDisabledA: disableProp,
      isDisabledOp: isDisabledOp,
      isDisabledNumber: isDisabledNumber,
    });
  };

  //formula clear
  clearFormula = () => {
    this.resetFormulaValues(this.state.showFormulaBuilder);
    this.setState({
      result: "",
      formulaString: "",
      testOperandArr: ["A", "B", "C", "D", "E"],
      isDisabledA: !this.state.showFormulaBuilder,
    });
  };

  /* Keypad Panel Function ends */

  /*screen 1 starts*/

  handleSynopsCategoryChange(event) {
    let selectedSOCategory = event.target.value != undefined ? event.target.value : 0;
    if (selectedSOCategory != "0")
      axiosInstance
        .get(`${LocalApiBaseUrl}ConnectorMapping/GetSubData`, {
          params: {
            OrgEntityID: this.props.dealfilterObj.orgID,
            OfferingID: this.props.dealfilterObj.subOfferID,
            LocationID: this.props.dealfilterObj.locID,
            SOCategoryID: selectedSOCategory,
          },
        })
        .then((response) => {
          let sub_so_category_list = response.data; // adding dummy data;
          this.setState({ subSoCategoryOptions: sub_so_category_list });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });

    let defineMetricObj = { ...this.state.defineMetricObj };
    defineMetricObj.synopsSelected = selectedSOCategory;
    this.setState({ defineMetricObj });
    if (selectedSOCategory == "0") {
      this.setState({ subSoCategoryOptions: [] });
    }
    this.setState({
      metricSelecObj: {
        metricName: "",
        id: "",
        metricDescription: "",
        metricPublishedVal: "",
        metricUnit: "",
      },
      metricOptions: [],
    });
  }

  handleSubSOCategoryChange(event) {
    let subSoCategoryID =
      event.target.value !== "" && event.target.value !== undefined
        ? event.target.value
        : 0;

    let index = event.nativeEvent.target.selectedIndex;
    let subSoCategoryName = event.nativeEvent.target[index].text;
    if (subSoCategoryID != "0")
      this.getMetricDetails(
        this.props.dealfilterObj.orgID,
        this.props.dealfilterObj.subOfferID,
        this.props.dealfilterObj.locID,
        subSoCategoryID
      );

    let defineMetricObj = { ...this.state.defineMetricObj };
    defineMetricObj.synopsSubCatSelected = subSoCategoryID;
    this.setState({ defineMetricObj });
    if (subSoCategoryID == "0") {
      this.setState({ metricOptions: [] });
    }
    this.setState({
      metricSelecObj: {
        metricName: "",
        id: "",
        metricDescription: "",
        metricPublishedVal: "",
        metricUnit: "",
      },
    });
  }

  handleMetricNameChange(event) {
    let index = event.nativeEvent.target.selectedIndex;
    let selectedID =
      event.target.value !== "" && event.target.value !== undefined
        ? event.target.value
        : 0;
    let metricObj = { ...this.state.metricSelecObj };
    metricObj.metricName = event.nativeEvent.target[index].text;
    //metricObj.id = selectedID;
    for (let i = 0; i < this.state.metricOptions.length; i++) {
      if (this.state.metricOptions[i].MetricID === Number(selectedID)) {
        metricObj.metricDescription = this.state.metricOptions[
          i
        ].MetricDescription;
        metricObj.metricPublishedVal = this.state.metricOptions[
          i
        ].LastPublishedValue;
        metricObj.metricUnit = this.state.metricOptions[i].MetricUnit;
        metricObj.id = this.state.metricOptions[i].KpiMappingID;
      }
    }

    let defineMetricObj = { ...this.state.defineMetricObj };
    defineMetricObj.selectedMetric = selectedID;
    this.setState({ defineMetricObj });
    if (event.target.value == "0") {
      this.setState({
        metricSelecObj: {
          metricName: "",
          id: "",
          metricDescription: "",
          metricPublishedVal: "",
          metricUnit: "",
        },
      });
    } else {
      this.setState({
        metricSelecObj: metricObj,
      });
    }
  }

  handleConnectionStatusChange(event) {
    this.setState({ isActive: event.target.checked });
  }

  //Create Final Formula Array
  createFinalFormulaArr = () => {
    let finalArr = [];
    let tabsArr = _.cloneDeep(this.state.tabsArr);
    let result = this.state.result;
    let res = result.split(/([*\/\-\+\(\)])/g);
    let res1 = result.split(/([*\/\-\+])/g);
    if (res) {
      res.map((num) => {
        if (
          num == "*" ||
          num == "/" ||
          num == "+" ||
          num == "-" ||
          num == "(" ||
          num == ")"
        ) {
          return;
        } else if (isNaN(num)) {
          tabsArr.find((variable) => {
            if (num === variable.OperandName) {
              let dummyDef = variable;
              finalArr.push(dummyDef);
            }
          });
        } else {
          if (/^[0-9]+$/.test(num)) {
            let dummyDef = {
              OperandName: num,
              OperandValue: "Constant",
              Operators: "",
              SourceConnection: "",
              ConnectorId: "",
              ResultFieldName: "",
              ResultFieldDataType: "",
              AppliedFunction: "",
              ResultQuery: "",
              CountResultQuery: "",
              ResultQueryValue: "",
              CountOfRowsAffected: "",
              ConditionFields: [
                {
                  ConditionFieldName: "",
                  ConditionOperator: "",
                  ConditionValue: "",
                  ConditionalGate: "",
                  HasMasterKey: false,
                  fieldValueOptions: [],
                },
              ],
              ConnectorFieldmaster: [],
            };
            finalArr.push(dummyDef);
            finalArr =
              finalArr.length > 0 &&
              finalArr.map((each) => {
                let name = each.OperandName;
                res.map((e, i) => {
                  if (e.includes(name)) {
                    if (i === 0) {
                      each["OperandText"] = e;
                    } else {
                      each["OperandText"] = e;
                      each.Operators = res[i - 1];
                    }
                  }
                });
                return each;
              });
          }
        }
      });
      finalArr.map((each) => {
        let name = each.OperandName;
        res1.map((e, i) => {
          if (e.includes(name)) {
            if (i === 0) {
              each["OperandText"] = e;
            } else {
              each["OperandText"] = e;
              each.Operators = res1[i - 1];
            }
          }
        });
        return each;
      });
    } else {
      finalArr = tabsArr;
    }

    this.setState({ finalArr: finalArr });
    return finalArr;
  };

  //Handling Variables definition/substituted value
  handleOperandDefinition(e, opeVal, i) {
    const { value } = e.target;
    let tabsArr = _.cloneDeep(this.state.tabsArr);
    tabsArr.find((Operand, i) => {
      if (Operand.OperandName === opeVal) {
        tabsArr[i] = {
          ...tabsArr[i],
          OperandName: opeVal,
          OperandValue: value,
        };
      }
    });
    this.setState({ tabsArr: tabsArr });
  }

  //Calculation
  calculate = () => {
    let checkResult = "";
    let errorObj = {
      error: false,
      errMsg: "",
    };
    let queryString = "";

    if (this.state.result === "") {
      errorObj.error = true;
      errorObj.errMsg =
        "You have not created any formula. Either turn off the formula builder or create a formula.";
    } else if (this.state.testOperandArr.length > 4) {
      errorObj.error = true;
      errorObj.errMsg = "Minimum 1 variable required to create a formula.";
      queryString = this.state.result;
    } else {
      try {
        let testStr = this.state.result.split("");
        let test = this.state.result.split("");
        let regex = /^[0-9]+$/gm;
        for (let i = 0; i < testStr.length; i++) {
          if (
            testStr[i] === "A" ||
            testStr[i] === "B" ||
            testStr[i] === "C" ||
            testStr[i] === "D" ||
            testStr[i] === "E"
          ) {
            test[i] = "1";
          }
          else if (regex.test(testStr[i])) {
            if (testStr[i - 1] == "A" ||
              testStr[i - 1] == "B" ||
              testStr[i - 1] == "C" ||
              testStr[i - 1] == "D" ||
              testStr[i - 1] == "E"
            ) {
              test[i] = "Wrong";
            } else if (testStr[i + 1] === "A" ||
              testStr[i + 1] === "B" ||
              testStr[i + 1] === "C" ||
              testStr[i + 1] === "D" ||
              testStr[i + 1] === "E") {
              test[i] = "Wrong";
            }
          }

          checkResult = checkResult + test[i];
        }
        eval(checkResult);
        errorObj.error = false;
        errorObj.errMsg = "";

      } catch (e) {
        errorObj.error = true;
        errorObj.errMsg = "Invalid Expression.";
      }
      queryString = this.state.result;
    }
    this.addFormulaStringToObject(queryString);
    this.setState({ formulaString: queryString });
    return errorObj;
  };
  /*screen 1 ends*/

  /* Screen 2 Start */

  handleSourceConnectionChange = (event, OperandName, i) => {
    let finalArr = _.cloneDeep(this.state.finalArr);
    let tabsArr = _.cloneDeep(this.state.tabsArr);
    //Updating TabsArr fields
    if (event.target.value != "0") {
      let sourceID = Number(event.target.value);
      let index = event.nativeEvent.target.selectedIndex;
      let sourceName = event.nativeEvent.target[index].text;
      tabsArr.find((Operand, i) => {
        if (Operand.OperandName === OperandName) {
          if (event.target.value != "0") {
            tabsArr[i] = {
              ...tabsArr[i],
              SourceConnection: sourceName,
              ConnectorId: sourceID,
              ConnectorFieldmaster: [],
            };
          } else {
            tabsArr[i] = { ...tabsArr[i], ConnectorFieldmaster: [] };
          }
          for (let j = 0; j < tabsArr[i].ConditionFields.length; j++) {
            tabsArr[i].ConditionFields[j].ConditionValue = "";
            tabsArr[i].ConditionFields[j].fieldValueOptions = [];
            tabsArr[i].ConditionFields[j].ConditionFieldName = "";
            tabsArr[i].ConditionFields[j].ConditionOperator = "";
            tabsArr[i].ConditionFields[j].HasMasterKey = false;
          }
        }
      });
      this.setState({ tabsArr: tabsArr }, () =>
        this.getFieldNamesAPI(sourceID, OperandName, i)
      );
    }

    //Maintaing Final Array
    finalArr.find((operand, i) => {
      if (operand.OperandName === OperandName) {
        if (event.target.value != "0") {
          let sourceID = Number(event.target.value);
          let index = event.nativeEvent.target.selectedIndex;
          let sourceName = event.nativeEvent.target[index].text;
          finalArr[i] = {
            ...finalArr[i],
            SourceConnection: sourceName,
            ConnectorId: sourceID,
            ConnectorFieldmaster: [],
          };
        } else {
          finalArr[i] = { ...finalArr[i], ConnectorFieldmaster: [] };
        }
        for (let j = 0; j < finalArr[i].ConditionFields.length; j++) {
          finalArr[i].ConditionFields[j].ConditionValue = "";
          finalArr[i].ConditionFields[j].fieldValueOptions = [];
          finalArr[i].ConditionFields[j].ConditionFieldName = "";
          finalArr[i].ConditionFields[j].ConditionOperator = "";
          finalArr[i].ConditionFields[j].HasMasterKey = false;
        }
      }
    });
    this.setState({ tabsArr: tabsArr, finalArr: finalArr });
  };

  handleFieldNameChange = (event, OperandName, j, param, tabIndex) => {
    if (event.target.value != "0") {
      const { value } = event.target;
      let connectorID;
      //updating TabsArr Fields
      let tempArr = [...this.state.tabsArr];
      tempArr.find((Operand, i) => {
        if (Operand.OperandName === OperandName) {
          Operand.ConditionFields[j].ConditionFieldName = value;
          connectorID = Operand.ConnectorId;
          if (param === "ROW_FIELD") {
            tempArr[i].ConditionFields[j].fieldValueOptions = [];
            this.setState({ tabsArr: tempArr }, function () {
              this.fetchValueFieldData(connectorID, value, j, i, OperandName);
            });
          }
        }
      });

      //Maintaing Final Array
      let finalArr = _.cloneDeep(this.state.finalArr);
      finalArr.find((Operand) => {
        if (Operand.OperandName === OperandName) {
          Operand.ConditionFields[j].ConditionFieldName = value;
          connectorID = Operand.ConnectorId;
          if (param === "ROW_FIELD") {
            Operand.ConditionFields[j].fieldValueOptions = [];
            this.setState({ finalArr: finalArr });
          }
        }
      });
      this.setState({ finalArr: finalArr });
    }
  };

  fetchValueFieldData(connectorID, fieldValue, j, tabIndex, OperandName) {
    let DealID = this.props.metricType === "UPDATE" ? this.props.selectedConfMetricObj.DealID : this.props.dealQueryInfo.DealID;
    let tabsArray = _.cloneDeep(this.state.tabsArr);
    let finalArr = _.cloneDeep(this.state.finalArr);
    let valueOptions = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}QueryBuilder/GetMasterKeyData`, {
        params: {
          ConnectorID: connectorID,
          FieldName: fieldValue,
          DealId: DealID,
        },
      })
      .then((response) => {
        tabsArray.find((Operand) => {
          if (Operand.OperandName === OperandName) {
            Operand.ConditionFields[j].HasMasterKey =
              response.data[0].HasMaster;
            Operand.ConditionFields[j].ConditionValue = "";
            Operand.ConditionFields[j].ConditionValueTemp = "";
            Operand.ConditionFields[j].fieldValueOptions = response.data[0].ConnectorMasterKey;
            if (Operand.ConditionFields[j].ConditionOperator == "IN") {
              response.data[0].ConnectorMasterKey.forEach(val => {
                let newItem = { label: val.MasterValue, value: val.MasterValue, OperandName: Operand.OperandName, FieldName: Operand.ConditionFields[j].ConditionFieldName };
                if (valueOptions.indexOf(newItem) === -1) {
                  valueOptions.push(newItem);
                }
                Operand.ConditionFields[j].fieldValueOptionsTemp = valueOptions;
              })
            } else {
              Operand.ConditionFields[j].ConditionValueTemp = ""
              Operand.ConditionFields[j].fieldValueOptionsTemp = ""
            }
          }
        });

        finalArr.find((operand, i) => {
          if (operand.OperandName === OperandName) {
            operand.ConditionFields[j].HasMasterKey =
              response.data[0].HasMaster;
            operand.ConditionFields[j].fieldValueOptions =
              response.data[0].ConnectorMasterKey;
            operand.ConditionFields[j].ConditionValue = "";
            operand.ConditionFields[j].ConditionValueTemp = "";
          }
        });
        this.setState({
          tabsArr: tabsArray,
          finalArr: finalArr,
        });

      })
      .catch((error) => {
        trycatchAlertPopup(error);
        tabsArray.find((Operand) => {
          if (Operand.OperandName === OperandName) {
            Operand.ConditionFields[j].HasMasterKey = false;
            Operand.ConditionFields[j].fieldValueOptions = [];
            Operand.ConditionFields[j].ConditionValue = "";
            Operand.ConditionFields[j].ConditionValueTemp = "";
          }
        });
        finalArr.find((operand, i) => {
          if (operand.OperandName === OperandName) {
            operand.ConditionFields[j].HasMasterKey = false;
            operand.ConditionFields[j].fieldValueOptions = [];
            operand.ConditionFields[j].ConditionValue = "";
            operand.ConditionFields[j].ConditionValueTemp = "";
          }
        });
        this.setState({ tabsArr: tabsArray, finalArr: finalArr });
      });
  }

  handleFieldOperatorChange = (event, OperandName, j) => {
    const { value } = event.target;
    let valueOptions = [];
    let tempArr = [...this.state.tabsArr];
    let finalArr = _.cloneDeep(this.state.finalArr);
    tempArr.find((Operand) => {
      if (Operand.OperandName === OperandName) {
        Operand.ConditionFields[j].ConditionOperator = value;
        if (value == "IN") {
          Operand.ConditionFields[j].fieldValueOptions.forEach(val => {
            let newItem = { label: val.MasterValue, value: val.MasterValue, OperandName: Operand.OperandName, FieldName: Operand.ConditionFields[j].ConditionFieldName, index: j };
            if (valueOptions.indexOf(newItem) === -1) {
              valueOptions.push(newItem);
            }
          })
          Operand.ConditionFields[j].fieldValueOptionsTemp = valueOptions;
        } else {
          Operand.ConditionFields[j].ConditionValueTemp = ""
          Operand.ConditionFields[j].fieldValueOptionsTemp = ""

        }
        Operand.ConditionFields[j].ConditionValue = ""
      }
    });
    finalArr.find((Operand) => {
      if (Operand.OperandName === OperandName) {
        Operand.ConditionFields[j].ConditionOperator = value;
      }
    });
    this.setState({
      tabsArr: tempArr,
      finalArr: finalArr,
    });

  };

  handleLogicalGateChange = (event, selectedTab, j) => {
    let gateValue = event.target.checked ? "AND" : "OR";
    let tempArr = [...this.state.tabsArr];
    let tempfinalArr = [...this.state.finalArr]
    for (let i = 0; i < tempArr.length; i++) {
      if (tempArr[i].OperandName === selectedTab) {
        tempArr[i].ConditionFields[j].ConditionalGate = gateValue;
      }
    }
    for (let i = 0; i < tempfinalArr.length; i++) {
      if (tempfinalArr[i].OperandName === selectedTab) {
        tempfinalArr[i].ConditionFields[j].ConditionalGate = gateValue;
      }
    }
    this.setState({ tabsArr: tempArr, finalArr: tempfinalArr });
  };

  handleMultiselectValueChange = (event, OperandName, j) => {
    let tempArr = _.cloneDeep(this.state.tabsArr);
    let finalArr = _.cloneDeep(this.state.finalArr);
    tempArr.find((Operand) => {
      if (Operand.OperandName === OperandName) {
        Operand.ConditionFields[j].ConditionValue = event.map((val) => val.value).join("||");
        Operand.ConditionFields[j].ConditionValueTemp = [...event];
      }
    });
    finalArr.find((Operand) => {
      if (Operand.OperandName === OperandName) {
        Operand.ConditionFields[j].ConditionValue = event.map((val) => val.value).join("||");
      }
    });
    this.setState({ tabsArr: tempArr, finalArr: finalArr, selectedValue: [...event] })
  }

  handleValueFieldChange(event, OperandName, j) {
    const { value } = event.target;
    let tempArr = [...this.state.tabsArr];
    let finalArr = _.cloneDeep(this.state.finalArr);
    tempArr.find((Operand) => {
      if (Operand.OperandName === OperandName) {
        Operand.ConditionFields[j].ConditionValue = value;
      }
    });
    finalArr.find((Operand) => {
      if (Operand.OperandName === OperandName) {
        Operand.ConditionFields[j].ConditionValue = value;
      }
    });
    this.setState({ tabsArr: tempArr, finalArr: finalArr });
  }

  handleResultFieldChange = (event, OperandName, i) => {
    let index = event.nativeEvent.target.selectedIndex;
    const { value } = event.target;
    let tabsArr = _.cloneDeep(this.state.tabsArr);
    let finalArr = _.cloneDeep(this.state.finalArr);
    let dataType = tabsArr[i].ConnectorFieldmaster[index - 1].DataType;
    tabsArr[i] = {
      ...tabsArr[i],
      ResultFieldName: value,
      ResultFieldDataType: dataType,
    };
    finalArr.find((Operand, i) => {
      if (Operand.OperandName === OperandName) {
        finalArr[i] = {
          ...finalArr[i],
          ResultFieldName: value,
          ResultFieldDataType: dataType,
        };
      }
    });
    this.setState({ tabsArr: tabsArr, finalArr: finalArr });
  };

  //on Validate Click
  handleResultBtnClick = (e, selectedTab, i) => {
    let tabsArr = _.cloneDeep(this.state.tabsArr);
    let errorVal = this.validateEachTab(e, selectedTab, i);
    if (errorVal) {
      alert("All fields are mandatory in condition set");
    } else {
      this.setState({ showLoaderOnPage: true });
      let payloadArr = [];
      tabsArr.find((Operand) => {
        if (Operand.OperandName === selectedTab) {
          payloadArr.push(Operand);
        }
      });
      if (selectedTab != undefined) {
        let DealID =
          this.props.metricType === "UPDATE"
            ? this.props.selectedConfMetricObj.DealID
            : this.props.dealQueryInfo.DealID;
        axiosInstance
          .post(
            `${LocalApiBaseUrl}QueryBuilder/ExecuteFilterConditions?DealId=${DealID}`,
            payloadArr
          )
          .then((response) => {
            let tabsArr = _.cloneDeep(this.state.tabsArr);
            let finalArr = _.cloneDeep(this.state.finalArr);
            tabsArr.find((Operand, i) => {
              if (Operand.OperandName === selectedTab) {
                let data = response.data[0];
                for (let Operand in data) {
                  data.ConditionFields.map((ConditionValue, j) => {
                    ConditionValue.fieldValueOptionsTemp = tabsArr[i].ConditionFields[j].fieldValueOptionsTemp;
                    ConditionValue.ConditionValueTemp = tabsArr[i].ConditionFields[j].ConditionValueTemp;
                  })
                }
                tabsArr[i] = data;
              }
            });
            finalArr.find((Operand, i) => {
              if (Operand.OperandName === selectedTab) {
                finalArr[i] = response.data[0];
              }
            });
            this.setState({
              tabsArr,
              finalArr,
              showFetchedRecords: true,
              showLoaderOnPage: false,
              showAlert: false,
            });
          })
          .catch((error) => {
            let tabsArr = _.cloneDeep(this.state.tabsArr);
            let finalArr = _.cloneDeep(this.state.finalArr);
            tabsArr.find((Operand, i) => {
              if (Operand.OperandName === selectedTab) {
                tabsArr[i].CountOfRowsAffected = "";
              }
            });
            finalArr.find((Operand, i) => {
              if (Operand.OperandName === selectedTab) {
                finalArr[i].CountOfRowsAffected = "";
              }
            });
            let msg = "An Error has occured. Please try again.";
            if (error.response && error.response.status === 406) {
              msg =
                "Deal Filter condition is not configured for this Deal. Please configure the Deal Filter or contact Admin.";
            }
            this.setState({ showLoaderOnPage: false });
            this.setState({
              alertClass: "danger",
              responseMessage: msg,
              showAlert: true,
              tabsArr,
              finalArr,
            });
            trycatchAlertPopup(error);

          });
      }
    }
  };

  handleAppliedFuncChange = (event, OperandName, i) => {
    const { value } = event.target;
    let tabsArr = _.cloneDeep(this.state.tabsArr);
    let finalArr = _.cloneDeep(this.state.finalArr);
    tabsArr.find((Operand, i) => {
      if (Operand.OperandName === OperandName) {
        tabsArr[i] = { ...tabsArr[i], AppliedFunction: value };
      }
    });
    finalArr.find((Operand, i) => {
      if (Operand.OperandName === OperandName) {
        finalArr[i] = { ...finalArr[i], AppliedFunction: value };
      }
    });
    this.setState({ tabsArr: tabsArr, finalArr: finalArr });
  };

  //Handle Add Icon on Calculation Screen
  handleAddCondition(index, tabName) {
    let tempArr = _.cloneDeep(this.state.tabsArr);
    let finalArr = _.cloneDeep(this.state.finalArr);
    tempArr.find((Operand) => {
      if (Operand.OperandName === tabName) {
        Operand.ConditionFields.push({
          ConditionFieldName: "",
          ConditionOperator: "",
          ConditionValue: "",
          ConditionalGate: "AND",
          HasMasterKey: false,
          fieldValueOptions: [],
        });
      }
    });
    finalArr.find((Operand) => {
      if (Operand.OperandName === tabName) {
        Operand.ConditionFields.push({
          ConditionFieldName: "",
          ConditionOperator: "",
          ConditionValue: "",
          ConditionalGate: "AND",
          HasMasterKey: false,
          fieldValueOptions: [],
        });
      }
    });
    this.setState({ tabsArr: tempArr, finalArr: finalArr });
    //this.setState(prevState => ({ dynamicConditions: [...prevState.dynamicConditions, {tabNo:index, tabName}]}))
  }

  //Handle Delete Icon on Calculation Screen/Screen 2
  handleDeleteCondition(index, tabName) {
    let finalArr = _.cloneDeep(this.state.finalArr);
    let tempArr = _.cloneDeep(this.state.tabsArr);
    tempArr.find((Operand) => {
      if (Operand.OperandName === tabName) {
        let index = Operand.ConditionFields.indexOf((val, i) => i == index)
        Operand.ConditionFields.splice(index, 1);
      }
    });
    finalArr.find((Operand) => {
      if (Operand.OperandName === tabName) {
        let index = Operand.ConditionFields.indexOf((val, i) => i == index)
        Operand.ConditionFields.splice(index, 1);
      }
    });
    this.setState({ tabsArr: tempArr, finalArr: finalArr });
  }

  //Setting Object Values for Const & Variables
  addFormulaStringToObject = (queryString) => {
    let tabsArr = _.cloneDeep(this.state.tabsArr);
    let a1 = queryString.split(/([*\/\-\+])/g);
    let updatedTabArr = tabsArr.map((each) => {
      let name = each.OperandName;
      a1.map((e, i) => {
        if (e.includes(name)) {
          if (i === 0) {
            each["OperandText"] = e;
          } else {
            each["OperandText"] = e;
            each.Operators = a1[i - 1];
          }
        }
      });
      return each;
    });

    this.setState({
      tabsArr: updatedTabArr,
    });
  };

  //Tab Change Handlers starts
  handleNextTabChange = (event, selectedVariable) => {
    switch (selectedVariable) {
      case "calcA": {
        this.setState({ selectedVariable: "calcB" });
        break;
      }
      case "calcB": {
        this.setState({ selectedVariable: "calcC" });
        break;
      }
      case "calcC": {
        this.setState({ selectedVariable: "calcD" });
        break;
      }
      case "calcD": {
        this.setState({ selectedVariable: "calcE" });
        break;
      }
      case "calcE": {
        this.setState({ selectedVariable: "calcE" });
        break;
      }
      default:
        this.setState({ selectedVariable: "calcA" });
    }
  };

  handlePrevTabChange = (event, selectedVariable) => {
    switch (selectedVariable) {
      case "calcA": {
        this.setState({ selectedVariable: "calcA" });
        break;
      }
      case "calcB": {
        this.setState({ selectedVariable: "calcA" });
        break;
      }
      case "calcC": {
        this.setState({ selectedVariable: "calcB" });
        break;
      }
      case "calcD": {
        this.setState({ selectedVariable: "calcC" });
        break;
      }
      case "calcE": {
        this.setState({ selectedVariable: "calcD" });
        break;
      }
      default:
        this.setState({ selectedVariable: "calcA" });
    }
  };

  handleTabChange = (index, label) => {
    this.setState({ selectedVariable: index });
  };
  //Tab Change Handlers starts

  /* Screen 2 End */

  /*Popup Functions start*/

  //Popup Next Button Handler
  handleNextBtnClick = (event, activeStep) => {
    let validationResult = { error: false, errMsg: "" };
    let stepNo = this.state.activeStep;
    if (activeStep === 1) {
      let finalArr = this.createFinalFormulaArr();
      validationResult = this.validateStep1();
      if (!validationResult.error) {
        if (stepNo !== 3) {
          stepNo++;
          this.setState({ activeStep: stepNo });
        }
        this.setState({ finalArr: finalArr });
      }
    }
    if (activeStep === 2) {
      validationResult = this.validateStep2();
      if (!validationResult.error) {
        this.setState({ showLoaderOnPage: true });
        let payload = {};
        payload.ISActive = this.state.isActive;
        payload.IsFormula = this.state.showFormulaBuilder;
        payload.FormulaText = this.state.formulaString;
        payload.FormulaValue = "";
        payload.MetricID =
          this.props.metricType === "UPDATE"
            ? this.props.selectedConfMetricObj.KPIMappingId
            : this.state.metricSelecObj.id;
        payload.MetricDefinition =
          this.props.metricType === "UPDATE"
            ? this.props.selectedConfMetricObj.MetricDefinition
            : this.state.metricSelecObj.metricDescription;
        payload.Formulavariables = !this.state.showFormulaBuilder ? [...this.state.tabsArr] : [...this.state.finalArr];
        payload.DealID =
          this.props.metricType === "UPDATE"
            ? this.props.selectedConfMetricObj.DealID
            : this.props.dealQueryInfo.DealID;

        axiosInstance
          .post(
            `${LocalApiBaseUrl}QueryBuilder/ExecuteMetricConfiguration`,
            payload
          )
          .then((response) => {
            let reviewObject = response.data;
            this.setState({
              renderReview: true,
              reviewStepObject: reviewObject,
              showLoaderOnPage: false,
              showAlert: false,
            });
            if (stepNo !== 3) {
              stepNo++;
              this.setState({ activeStep: stepNo });
            }
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      }
    }
    if (validationResult.error) {
      alert(validationResult.errMsg);
    }
    if (activeStep === 3) {
      this.addMetricConfigurationData();
    }
    this.setState({ selectedVariable: "calcA" })
  };

  //Popup Prev Button Handler
  handlePreviousBtnClick = (event) => {
    let stepNo = this.state.activeStep;
    stepNo--;
    if (stepNo !== 0) {
      this.setState({ activeStep: stepNo });
    }
  };

  resetFormula = (flag) => {
    if (
      this.props.metricType === "UPDATE" &&
      flag === this.props.updateMetricObj.IsFormula
    ) {
      const { updateMetricObj } = this.props;
      const filteredUpdateMetricObj = updateMetricObj;
      const filteredUpdateArr = updateMetricObj.Formulavariables.filter((each) => {
        return operandArr.includes(each.OperandName)
      })
      let KPIUnit = updateMetricObj.KPIUnit;
      let testOperandArr = updateMetricObj.IsFormula
        ? this.state.testOperandArr.slice(
          updateMetricObj.Formulavariables.length
        )
        : this.state.testOperandArr;
      this.setState({
        tabsArr: filteredUpdateArr,
        showFormulaBuilder: flag,
        result: updateMetricObj.FormulaText,
        testOperandArr,
        isDisabledA: updateMetricObj.IsFormula,
        KPIUnit,
        isActive: updateMetricObj.ISActive,
      });
    } else {
      let tabsArr = [];
      let def1 = {
        OperandName: "A",
        OperandValue: "",
        Operators: "",
        SourceConnection: "",
        ConnectorId: "",
        ResultFieldName: "",
        ResultFieldDataType: "",
        AppliedFunction: "",
        ResultQuery: "",
        CountResultQuery: "",
        ResultQueryValue: "",
        CountOfRowsAffected: "",
        ConditionFields: [
          {
            ConditionFieldName: "",
            ConditionOperator: "",
            ConditionValue: "",
            ConditionalGate: "",
            HasMasterKey: false,
            fieldValueOptions: [],
          },
        ],
        ConnectorFieldmaster: [],
      };
      tabsArr.push(def1);
      this.setState({
        tabsArr: tabsArr,
        finalArr: [],
        isDisabledNumber: false,
        isDisabledOp: true,
        selectedVariable: "calcA",
        showFormulaBuilder: flag,
        result: "",
        testOperandArr: ["A", "B", "C", "D", "E"],
        isDisabledA: !flag,
      });
    }
  };
  //Formula Builder Section Enable/Disable
  handleShowFormulaBuilder = (event) => {
    this.resetFormula(event.target.checked);
    // this.setState({
    //   result: "",
    //   showFormulaBuilder: event.target.checked,
    //   testOperandArr: ["A", "B", "C", "D", "E"],
    //   isDisabledA: !event.target.checked,
    // });
  };

  //Validations Start
  validateStep1 = () => {
    let definitionArr = [...this.state.tabsArr];
    let defineMetricObj = { ...this.state.defineMetricObj };
    let errorObj = {
      error: false,
      errMsg: "",
    };
    // let queryString = "";
    if (
      (defineMetricObj.synopsSelected == 0 ||
        defineMetricObj.synopsSubCatSelected == 0 ||
        defineMetricObj.selectedMetric == 0) &&
      this.props.metricType === "CREATE"
    ) {
      errorObj.error = true;
      errorObj.errMsg = "All fields are mandatory on Define Metric Step";
    }
    if (this.state.showFormulaBuilder) {
      for (let i = 0; i < definitionArr.length; i++) {
        /* if(definitionArr[i].Operators === null || i === 0){
                             definitionArr[i].Operators = "";
                         }*/
        // queryString = queryString+definitionArr[i].Operators+definitionArr[i].OperandName;
        if (
          definitionArr[i].OperandValue === "" ||
          definitionArr[i].OperandValue === null ||
          definitionArr[i].OperandValue === undefined
        ) {
          errorObj.error = true;
          errorObj.errMsg = "All fields are mandatory on Define Metric Step";
          break;
        }
      }
      // queryString = this.state.result === "" ? "(A+B)" : this.state.result;
      let strValidationErrObj = this.calculate();
      if (strValidationErrObj.error) {
        errorObj.error = strValidationErrObj.error;
        errorObj.errMsg = strValidationErrObj.errMsg;
      }
      // if(!error){
      //    this.addFormulaStringToObject(queryString);
      // }
    }
    //this.setState({formulaString:queryString});
    return errorObj;
  };

  validateStep2 = () => {
    let tabsArr = this.state.tabsArr;
    let errorObj = {
      error: false,
      errMsg: "",
    };
    for (let i = 0; i < tabsArr.length; i++) {
      if (
        tabsArr[i].SourceConnection === "" ||
        tabsArr[i].ResultFieldName === "" ||
        tabsArr[i].AppliedFunction === ""
      ) {
        errorObj.error = true;
        errorObj.errMsg = "All fields are mandatory on Data Calculation Step";
        break;
      }
      let tempArr = tabsArr[i].ConditionFields;
      for (let j = 0; j < tempArr.length; j++) {
        if (
          tempArr[j].ConditionFieldName === "" ||
          tempArr[j].ConditionOperator === "" ||
          tempArr[j].ConditionValue === ""
        ) {
          errorObj.error = true;
          errorObj.errMsg = "All fields are mandatory on Data Calculation Step";
          break;
        }
      }
    }
    return errorObj;
  };

  validateEachTab = (e, selectedTab, i) => {
    let tabsArr = _.cloneDeep(this.state.tabsArr);
    let currentArr = tabsArr.find((Operand) => {
      if (Operand.OperandName === selectedTab) {
        return Operand;
      }
    });
    let errorVal = false;
    if (
      currentArr.SourceConnection === "" ||
      currentArr.ResultFieldName === "" ||
      currentArr.AppliedFunction === ""
    ) {
      errorVal = true;
    }
    let tempArr = currentArr.ConditionFields;
    for (let k = 0; k < tempArr.length; k++) {
      if (
        tempArr[k].ConditionFieldName === "" ||
        tempArr[k].ConditionOperator === "" ||
        tempArr[k].ConditionValue === ""
      ) {
        errorVal = true;
        break;
      }
    }
    return errorVal;
  };
  //Validations End

  //Add Formula in QB
  addMetricConfigurationData = () => {
    this.setState({ showLoaderOnPage: true });
    let KPIMappingId =
      this.props.metricType === "UPDATE"
        ? this.props.selectedConfMetricObj.KPIMappingId
        : this.state.metricSelecObj.id;
    let DealID =
      this.props.metricType === "UPDATE"
        ? this.props.selectedConfMetricObj.DealID
        : this.props.dealQueryInfo.DealID;
    let addPayload = this.state.reviewStepObject;
    addPayload.MetricUnit = "";
    axiosInstance
      .post(
        `${LocalApiBaseUrl}QueryBuilder/AddMetricConfiguration?DealId=${DealID}&KPIMappingId=${KPIMappingId}`,
        addPayload
      )
      .then((response) => {
        let data = response.data;
        let alertCls;
        if (data.IsSuccess) {
          alertCls = "success";
        } else {
          alertCls = "danger";
        }
        this.setState({
          alertClass: alertCls,
          responseMessage: data.StatusMessage,
          showAlert: true,
          showLoaderOnPage: false,
        });
        // make an API call here to fetch the list of metric configurations
        this.props.getConfiguredMetricList();
        let that = this;
        setTimeout(() => this.closePopup()
          , 500);
      })
      .catch((error) => {
        trycatchAlertPopup(error);

      });
  };

  //Alert message show/hide
  hideShow = (boolVal) => {
    this.setState({
      showAlert: boolVal,
    });
  };

  /*Popup Functions start*/

  render() {
    let source = [
      { id: "SUM", DataType: "SUM" },
      { id: "MIN", DataType: "MIN" },
      { id: "MAX", DataType: "MAX" },
      { id: "AVG", DataType: "AVG" },
    ];

    let dataTypesList = source.map((item, i) => {
      return (
        <option key={item.id} value={item.DataType}>
          {item.DataType}
        </option>
      );
    }, this);
    let synopsDisabled = false,
      synopsDisabledCls = "form-group border-group";
    let addBtnText = "Add";
    if (this.props.metricType === "UPDATE") {
      synopsDisabled = true;
      synopsDisabledCls = "form-group border-group disable";
      addBtnText = "Update";
    }

    return (
      <Modal
        show={this.props.connection}
        onHide={() => this.closePopup()}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
        className="queryBuilderModal"
      >
        <LoadingOverlay
          className="custom-loader"
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={this.state.showLoaderOnPage}
        // text="Loading SynOps …"
        ></LoadingOverlay>

        <Modal.Body className="px-3 pt-4">
          <Alert
            variant={this.state.alertClass}
            dismissible
            show={this.state.showAlert}
            onClose={() => this.hideShow(false)}
          >
            {this.state.responseMessage}
          </Alert>
          <div className="toggle-form tabsSmall">
            <Tabs
              className={
                this.state.activeStep == 1
                  ? ""
                  : this.state.activeStep == 2
                    ? "previousActive"
                    : "lastActive"
              }
              defaultActiveKey={1}
              activeKey={this.state.activeStep} /*"metric"*/
            >
              <Tab
                eventKey={1}
                /*"metric"*/ title="Define Metric"
                className="large-tabs"
              >
                <div className="interLabel">
                  <p class="fields-notification">
                    <span>*</span> All fields are mandatory. Select all fields
                    to move to next step
                  </p>
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-section">
                          <div className={synopsDisabledCls}>
                            <label htmlFor="category" className="form-label">
                              SynOps Category<span className="required">*</span>
                            </label>
                            <select
                              id="category"
                              className="form-control"
                              value={this.state.defineMetricObj.synopsSelected}
                              onChange={(eve) =>
                                this.handleSynopsCategoryChange(eve)
                              }
                              disabled={synopsDisabled}
                            >
                              {this.props.metricType === "UPDATE" ? (
                                <option
                                  selected
                                  value={
                                    this.props.selectedConfMetricObj.SOCategory
                                  }
                                >
                                  {this.props.selectedConfMetricObj.SOCategory}
                                </option>
                              ) : (
                                this.state.synOpsOptions
                              )}
                            </select>
                          </div>
                          <div className={synopsDisabledCls}>
                            <label htmlFor="category" className="form-label">
                              SynOps Sub Category{" "}
                              <span className="required">*</span>
                            </label>
                            <select
                              className="form-control"
                              value={
                                this.state.defineMetricObj.synopsSubCatSelected
                              }
                              onChange={(eve) =>
                                this.handleSubSOCategoryChange(eve)
                              }
                              disabled={synopsDisabled}
                            >
                              {this.props.metricType === "UPDATE" ? (
                                <option
                                  selected
                                  value={
                                    this.props.selectedConfMetricObj
                                      .SOSubCategory
                                  }
                                >
                                  {
                                    this.props.selectedConfMetricObj
                                      .SOSubCategory
                                  }
                                </option>
                              ) : (
                                <>
                                  <option value="0">
                                    Select Sub-SOCategory
                                  </option>
                                  {this.state.subSoCategoryOptions.map(
                                    (row) => {
                                      return (
                                        <option value={row.SubSOCategoryID}>
                                          {row.SubSOCategory}
                                        </option>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </select>
                          </div>
                          <div className={synopsDisabledCls}>
                            <label htmlFor="category" className="form-label">
                              Metric <span className="required">*</span>
                            </label>
                            <select
                              id="metric_name"
                              className="form-control"
                              value={this.state.defineMetricObj.selectedMetric}
                              ref="MetricNameDropdown"
                              onChange={(eve) =>
                                this.handleMetricNameChange(eve)
                              }
                              disabled={synopsDisabled}
                            >
                              {this.props.metricType === "UPDATE" ? (
                                <option
                                  selected
                                  value={
                                    this.props.selectedConfMetricObj.MetricName
                                  }
                                >
                                  {this.props.selectedConfMetricObj.MetricName}
                                </option>
                              ) : (
                                <>
                                  <option value="0">Select Metric</option>
                                  {this.state.metricOptions.map((row) => {
                                    return (
                                      <option value={row.MetricID}>
                                        {row.MetricName}
                                      </option>
                                    );
                                  })}
                                </>
                              )}
                            </select>
                          </div>
                          <div className="form-group border-group disable">
                            <label htmlFor="category" className="form-label">
                              Last Published Value
                            </label>
                            <input
                              type="text"
                              disabled={true}
                              className="form-control"
                              value={
                                this.props.metricType === "UPDATE"
                                  ? this.props.updateMetricObj
                                    .LastPublishedValue
                                  : this.state.metricSelecObj.metricPublishedVal
                              }
                            />
                          </div>
                          <div className="form-group border-group disable">
                            <label htmlFor="category" className="form-label">
                              Metric Description
                            </label>
                            <input
                              type="text"
                              disabled={true}
                              className="form-control"
                              value={
                                this.props.metricType === "UPDATE"
                                  ? this.props.selectedConfMetricObj
                                    .MetricDefinition
                                  : this.state.metricSelecObj.metricDescription
                              }
                            />
                          </div>
                          <div className="form-group toggle-check">
                            <label>Connection Status</label>
                            <div className="toggle-group ml-4">
                              <input
                                type="checkbox"
                                name="connection"
                                id="connection"
                                defaultChecked={this.state.isActive}
                                onChange={(event) =>
                                  this.handleConnectionStatusChange(event)
                                }
                              />
                              <label htmlFor="connection">
                                Connection Status
                              </label>
                              <div className="onoffswitch" aria-hidden="true">
                                <div className="onoffswitch-label">
                                  <div className="onoffswitch-inner inact"></div>
                                  <div className="onoffswitch-switch"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="card formulaBuild formula-builder">
                          <h5 className="card-header px-3">
                            Formula Builder
                            <div className="toggle-group ml-4">
                              <input
                                type="checkbox"
                                name="formula"
                                id="formula"
                                defaultChecked={this.state.showFormulaBuilder}
                                onChange={(event) =>
                                  this.handleShowFormulaBuilder(event)
                                }
                              />
                              <label htmlFor="formula">Connection Status</label>
                              <div className="onoffswitch" aria-hidden="true">
                                <div className="onoffswitch-label">
                                  <div className="onoffswitch-inner"></div>
                                  <div className="onoffswitch-switch"></div>
                                </div>
                              </div>
                            </div>
                          </h5>
                          {this.state.showFormulaBuilder ? (
                            <div className="card-body px-3 py-2">
                              <div className="clearformula-section">
                                <small className="card-text">
                                  This formula will be used to calculate data
                                  for selected metric
                                </small>
                                <small className="card-text">
                                  <a
                                    href="#"
                                    onClick={() => this.clearFormula()}
                                  >
                                    Clear Formula
                                  </a>
                                </small>
                              </div>

                              <div className="formulaBar">
                                {/*this.createUIForFormula()*/}
                                <KeyPadPanelComponent
                                  onKeyPadClick={this.onKeyPadClick}
                                  isDisabledA={this.state.isDisabledA}
                                  isDisabledOp={this.state.isDisabledOp}
                                  isDisabledNumber={this.state.isDisabledNumber}
                                  testOperandArr={this.state.testOperandArr}
                                  backspace={this.backspace}
                                  result={this.state.result}
                                />
                                {/* <KeyPadComponent onKeyPadClick={this.onKeyPadClick} isDisabledA={this.state.isDisabledA} testOperandArr={this.state.testOperandArr} backspace={this.backspace} isDisabledOp={this.state.isDisabledOp} /> */}
                                {/*<button className='addIconCircle' type='button' onClick={this.handleAddVariables}>+</button>*/}
                              </div>
                              <div className="form-section">
                                {this.state.tabsArr.length > 0 &&
                                  this.state.tabsArr.map((el, i) => {
                                    return (
                                      <div
                                        className="form-group border-group"
                                        key={"TextFields" + i}
                                      >
                                        <label
                                          htmlFor=""
                                          className="form-label"
                                        >
                                          Definition of {operandArr[i]}{" "}
                                          <span className="required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          maxLength="100"
                                          id={"definition_" + operandArr[i]}
                                          value={
                                            this.state.tabsArr[i].OperandValue
                                          }
                                          onChange={(e) =>
                                            this.handleOperandDefinition(
                                              e,
                                              operandArr[i],
                                              i
                                            )
                                          }
                                        ></input>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Tab>
              {this.state.tabsArr.length > 0 && (
                <Tab
                  eventKey={2}
                  /*"data"*/ title="Data Calculation"
                  className="large-tabs"
                >
                  <div className="lineTabs px-3 tabSteps sub-tabslist">
                    <Tabs
                      defaultActiveKey={
                        "calc" + this.state.tabsArr[0].OperandName
                      }
                      onSelect={(index, label) =>
                        this.handleTabChange(index, label)
                      }
                      activeKey={this.state.selectedVariable}
                    >
                      {this.state.tabsArr.length > 0 &&
                        this.state.tabsArr.map((obj, index) => {
                          return (
                            <Tab
                              eventKey={"calc" + obj.OperandName}
                              title={
                                !this.state.showFormulaBuilder
                                  ? "Calculation"
                                  : "Calculation " + obj.OperandName
                              }
                              key={index}
                              label={obj.OperandName}
                            >
                              {!this.state.showFormulaBuilder ? (
                                <p></p>
                              ) : (
                                <p>Calculation for {obj.OperandValue}</p>
                              )}
                              <div className="field-condition-set">
                                <h6>Condition Set {index + 1}</h6>
                                <p className="fields-notification">
                                  <span>*</span> All fields are mandatory.
                                  Select all fields to move to next step
                                </p>
                              </div>

                              <div className="conditionBox mb-4" key={index}>
                                <div className="rowWrapper qb-rowWrapper">
                                  {obj.ConditionFields && obj.ConditionFields.length > 0 &&
                                    obj.ConditionFields.map((rowObj, j) => {
                                      return (
                                        <div className="row">
                                          <div
                                            className={
                                              j === 0
                                                ? "col-lg-10"
                                                : "col-lg-10"
                                            }
                                            key={obj.OperandName + j}
                                          >
                                            <div className="form-section">
                                              <div className="row">
                                                {j === 0 ? (
                                                  <div className="col-lg-3">
                                                    <div className="form-group border-group">
                                                      <label className="form-label">
                                                        Source Connection
                                                      </label>
                                                      <select
                                                        className="form-control"
                                                        onChange={(event) =>
                                                          this.handleSourceConnectionChange(
                                                            event,
                                                            obj.OperandName,
                                                            j
                                                          )
                                                        }
                                                        value={obj.ConnectorId}
                                                      >
                                                        <option value="0">
                                                          Select Source
                                                        </option>
                                                        {this.state.sourceOptions.map(
                                                          (srcObj, ii) => {
                                                            return (
                                                              <option
                                                                key={
                                                                  srcObj.ConnectorID
                                                                }
                                                                value={
                                                                  srcObj.ConnectorID
                                                                }
                                                              >
                                                                {
                                                                  srcObj.ConnectorName
                                                                }
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </select>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div className="form-group toggle-check col-lg-3">
                                                    {/* <div className="toggle-rdio-checkbtns">
                                                            <label className="form-check mr-4">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="logicalGate"
                                                                    id={
                                                                        obj.OperandName +
                                                                        "logicalGate" +
                                                                        j
                                                                    }
                                                                    defaultChecked={true}
                                                                    onChange={(event) =>
                                                                        this.handleLogicalGateChange(
                                                                            event,
                                                                            obj.OperandName,
                                                                            j
                                                                        )
                                                                    }
                                                                />
                                                                <span className="checkmark" />
                                                                <span className="form-check-label">
                                                                    And
                                                                </span>
                                                                </label>
                                                                <label className="form-check">
                                                                    <input
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        name="logicalGate"
                                                                        id={
                                                                            obj.OperandName +
                                                                            "logicalGate" +
                                                                            j
                                                                        }
                                                                        onChange={(event) =>
                                                                            this.handleLogicalGateChange(
                                                                                event,
                                                                                obj.OperandName,
                                                                                j
                                                                            )
                                                                        }
                                                                    />
                                                                    <span className="checkmark" />
                                                                    <span className="form-check-label">
                                                                        OR
                                                                    </span>
                                                                </label>
                                                                </div> */}
                                                    {/* <label></label> */}
                                                    <div className="toggle-group ml-4">
                                                      <input
                                                        type="checkbox"
                                                        name="logicalGate"
                                                        id={
                                                          obj.OperandName +
                                                          "logicalGate" +
                                                          j
                                                        }
                                                        defaultChecked={this.props.metricType === "UPDATE" ? (obj.ConditionFields[j].ConditionalGate == "AND" ? true : false) : true}
                                                        onChange={(event) =>
                                                          this.handleLogicalGateChange(
                                                            event,
                                                            obj.OperandName,
                                                            j
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        htmlFor={
                                                          obj.OperandName +
                                                          "logicalGate" +
                                                          j
                                                        }
                                                      ></label>
                                                      <div
                                                        className="onoffswitch"
                                                        aria-hidden="true"
                                                      >
                                                        <div className="onoffswitch-label">
                                                          <div className="onoffswitch-inner andor"></div>
                                                          <div className="onoffswitch-switch"></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                                <div className="col-lg-3">
                                                  <div className="form-group border-group">
                                                    <label className="form-label">
                                                      Field Name
                                                    </label>
                                                    <select
                                                      className="form-control"
                                                      onChange={(event) =>
                                                        this.handleFieldNameChange(
                                                          event,
                                                          obj.OperandName,
                                                          j,
                                                          "ROW_FIELD",
                                                          index
                                                        )
                                                      }
                                                      value={
                                                        obj.ConditionFields[j]
                                                          .ConditionFieldName
                                                      }
                                                    >
                                                      <option value="0">
                                                        Select Field Name
                                                      </option>
                                                      {obj.ConnectorFieldmaster.map(
                                                        (item) => {
                                                          return (
                                                            <option
                                                              key={
                                                                item.ConnectorFieldMasterDataID
                                                              }
                                                              value={
                                                                item.FieldName
                                                              }
                                                            >
                                                              {item.FieldName}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                    </select>
                                                  </div>
                                                </div>
                                                <div className="col-lg-3">
                                                  <div className="form-group border-group">
                                                    <label className="form-label">
                                                      Operator
                                                    </label>
                                                    <select
                                                      className="form-control"
                                                      onChange={(event) =>
                                                        this.handleFieldOperatorChange(
                                                          event,
                                                          obj.OperandName,
                                                          j
                                                        )
                                                      }
                                                      value={
                                                        obj.ConditionFields[j]
                                                          .ConditionOperator
                                                      }
                                                    >
                                                      <option value="0">
                                                        Select Operator
                                                      </option>
                                                      <option value={"<"}>
                                                        {"<"}
                                                      </option>
                                                      <option value={">"}>
                                                        {">"}
                                                      </option>
                                                      <option value={"="}>
                                                        =
                                                      </option>
                                                      <option value={"!="}>
                                                        !=
                                                      </option>
                                                      <option value={"IN"}>
                                                        IN
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div className="col-lg-3">
                                                  <div className="form-group border-group">
                                                    <label className="form-label">
                                                      Value
                                                    </label>
                                                    {obj.ConditionFields[j].HasMasterKey &&
                                                      obj.ConditionFields[j].fieldValueOptions.length > 0 ? (
                                                      obj.ConditionFields[j].fieldValueOptionsTemp && obj.ConditionFields[j].fieldValueOptionsTemp.length > 0 ?
                                                        <div className="custom-multi-select qb-custom-multiselect">
                                                        
                                                          <React.Fragment>
                                                            <MultiSelect
                                                              options={(obj.ConditionFields[j].fieldValueOptionsTemp && obj.ConditionFields[j].fieldValueOptionsTemp.length > 0) && obj.ConditionFields[j].fieldValueOptionsTemp}
                                                              value={obj.ConditionFields[j].ConditionValueTemp && obj.ConditionFields[j].ConditionValueTemp}
                                                              onChange={(event) => this.handleMultiselectValueChange(event, obj.OperandName, j)}
                                                              labelledBy="Value"
                                                              overrideStrings={overrideStrings}
                                                              hasSelectAll={true}
                                                            />
                                                          </React.Fragment>
                                                        </div>
                                                        :
                                                        <select
                                                          className="form-control"
                                                          onChange={(event) => this.handleValueFieldChange(event, obj.OperandName, j)}
                                                          value={obj.ConditionFields[j].ConditionValue}
                                                          id={"selectField" + obj.OperandName + j}
                                                        >
                                                          <option value="0">
                                                            Select Value
                                                          </option>
                                                          {obj.ConditionFields[j].fieldValueOptions.map(
                                                            (item) => {
                                                              return (
                                                                <option key={item.MasterValue} value={item.MasterValue}>
                                                                  {item.MasterValue}
                                                                </option>
                                                              );
                                                            }
                                                          )}
                                                        </select>
                                                    ) : (
                                                      <input
                                                        type="text"
                                                        maxLength="100"
                                                        className="form-control"
                                                        placeholder={this.state.tabsArr[index].ConditionFields[j].ConditionOperator == "IN" && `e.g – abc,123,0.94,XYZ`}
                                                        onChange={(e) =>
                                                          this.handleValueFieldChange(
                                                            e,
                                                            obj.OperandName,
                                                            j
                                                          )
                                                        }
                                                        id={obj.OperandName + j}
                                                        value={
                                                          this.state.tabsArr[
                                                            index
                                                          ].ConditionFields[j]
                                                            .ConditionValue
                                                        }
                                                      ></input>
                                                    )}
                                                    {/* <label for="">
                                                      {
                                                        this.state.friendlyNames
                                                          .cl
                                                      }
                                                    </label>
                                                    <div className="custom-multi-select">
                                                      {clientMultipleselectbar}
                                                    </div> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className={
                                              j === 0
                                                ? "col-lg-2 align-self-center"
                                                : "col-lg-2 align-self-center"
                                            }
                                          >
                                            <button
                                              className="addIconCircle ml-0 mb-3 mr-3"
                                              type="button"
                                              id={
                                                "calc" + obj.OperandName + index
                                              }
                                              onClick={this.handleAddCondition.bind(
                                                this,
                                                index,
                                                obj.OperandName
                                              )}
                                            >
                                              <i class="fas fa-plus"></i>
                                            </button>
                                            {j ===
                                              obj.ConditionFields.length - 1 &&
                                              j !== 0 ? (
                                              <button
                                                className="addIconCircle ml-0 mb-3"
                                                type="button"
                                                id={
                                                  "delete_innerTab_" +
                                                  index +
                                                  "line_" +
                                                  j
                                                }
                                                onClick={this.handleDeleteCondition.bind(
                                                  this,
                                                  j,
                                                  obj.OperandName
                                                )}
                                              >-</button>
                                              /* <i class="fas fa-trash-alt delete-icon-fix"></i> */

                                            ) : null}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  {/* <div className="row">
                                    <div className="col-lg-10">
                                      <p className="setup-description">
                                        <span>
                                          <i class="far fa-info-circle"></i>
                                        </span>
                                        Source System Tools displayed are
                                        already configured. To view details and
                                        configure more tools, visit{" "}
                                        <span>One Time Setup</span>
                                      </p>
                                    </div>
                                  </div> */}
                                </div>

                                <div className="resultSet">
                                  <p>Result</p>
                                  <div className="resultSetClone">
                                    <div className="row">
                                      <div className="col-lg-3">
                                        <div className="form-group border-group">
                                          <label className="form-label">
                                            Field Name
                                          </label>
                                          <select
                                            className="form-control"
                                            onChange={(event) =>
                                              this.handleResultFieldChange(
                                                event,
                                                obj.OperandName,
                                                index,
                                                "RESULT_FIELD"
                                              )
                                            }
                                            value={obj.ResultFieldName}
                                          >
                                            <option value="0">
                                              Select Field Name
                                            </option>
                                            {obj.ConnectorFieldmaster.map(
                                              (item) => {
                                                return (
                                                  <option
                                                    key={
                                                      item.ConnectorFieldMasterDataID
                                                    }
                                                    value={item.FieldName}
                                                  >
                                                    {item.FieldName}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-3">
                                        <div className="form-group border-group">
                                          <label className="form-label">
                                            Applied Function
                                          </label>
                                          <select
                                            className="form-control"
                                            onChange={(event) =>
                                              this.handleAppliedFuncChange(
                                                event,
                                                obj.OperandName,
                                                index
                                              )
                                            }
                                            value={obj.AppliedFunction}
                                          >
                                            <option value="0">
                                              Select Function
                                            </option>
                                            {obj.ResultFieldDataType ===
                                              "int" ||
                                              obj.ResultFieldDataType ==
                                              "float" ||
                                              obj.ResultFieldDataType ===
                                              "decimal"
                                              ? // <option value="Sum">SUM</option>
                                              dataTypesList
                                              : null}
                                            <option value="Count">COUNT</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-3">
                                        <Button
                                          variant="primary"
                                          className="btn-sm validate-btn"
                                          onClick={(e) =>
                                            this.handleResultBtnClick(
                                              e,
                                              obj.OperandName,
                                              index
                                            )
                                          }
                                        >
                                          Validate
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {obj.CountOfRowsAffected !== null &&
                                  obj.CountOfRowsAffected !== undefined &&
                                  obj.CountOfRowsAffected !== "" ? (
                                  <p className="result-calc">
                                    &nbsp;&nbsp;
                                    <span>{obj.CountOfRowsAffected}</span>
                                    &nbsp;&nbsp;records found for the result
                                    calculation
                                  </p>
                                ) : null}
                              </div>
                            </Tab>
                          );
                        })}
                    </Tabs>
                    <div className="custom-tab-change">
                      <span
                        className={
                          "left" +
                          (this.state.selectedVariable == "calcA"
                            ? " disabled"
                            : "")
                        }
                        onClick={(e) =>
                          this.handlePrevTabChange(
                            e,
                            this.state.selectedVariable
                          )
                        }
                      ></span>
                      <span
                        className={
                          this.state.selectedVariable ==
                            `calc${this.state.tabsArr[this.state.tabsArr.length - 1].OperandName}`
                            ? "right disabled"
                            : "right"
                        }
                        onClick={(e) =>
                          this.handleNextTabChange(
                            e,
                            this.state.selectedVariable
                          )
                        }
                      ></span>
                    </div>
                  </div>
                </Tab>
              )}
              <Tab
                eventKey={3}
                /*"review"*/ title="Review"
                className="large-tabs"
              >
                {/* this.state.renderReview ?
                                <ReviewMetricStep reviewStepObject={this.state.reviewStepObject} metricSelecObj={this.state.metricSelecObj}/>
                            : null */}
                <ReviewMetricStep
                  reviewStepObject={this.state.reviewStepObject}
                  metricSelecObj={this.state.metricSelecObj}
                  selectedConfMetricObj={this.props.selectedConfMetricObj}
                  metricType={this.props.metricType}
                  KPIUnit={this.state.KPIUnit}
                />
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn querybuilder-footer">
            <Button
              variant="default"
              className="btn-sm mr-2"
              onClick={() => this.closePopup()}
            >
              Cancel
            </Button>
            {/* <Button variant="outline-secondary" className='btn-sm mr-auto'>Save &amp; Exit</Button> */}
            <div>
              <Button
                variant="outline-secondary"
                className={
                  this.state.activeStep === 1
                    ? "btn-sm mr-2 disabled"
                    : "btn-sm mr-2"
                }
                disabled={this.state.activeStep === 1 ? true : false}
                onClick={(e) => this.handlePreviousBtnClick(e)}
              >
                Previous
              </Button>
              <Button
                variant="primary"
                className="btn-sm"
                onClick={(e) =>
                  this.handleNextBtnClick(e, this.state.activeStep)
                }
              >
                {this.state.activeStep === 3 ? addBtnText : "Next"}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
