import React from 'react';
import { Modal } from 'react-bootstrap';
import { LocalApiBaseUrl } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { regexForConfMasterFields } from '../Shared/Constant';
import {regexForTextAreas} from '../Shared/Constant';
import { isValidText,InvalidDescription } from "../Shared/commonFunctions";

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;




export default class ConnectorFieldMasterPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            specialCharmessage:InvalidDescription(),
            show: false, apivalue: "", selectValue: this.props.SelectValue, Display: false,connectorListValues:[],
            ConnectorFieldMasterData: {
                ConnectorFieldMasterDataID: 0,
                ConnectorID:0,
                FieldName:"",
                DataType:"",
                Description:"",
                ColumnName:"",
               TableName:"",
              CreatedUser:null,
              CreatedTS:null,
            UpdatedTS:null,
          UpdatedUser:null,
          IsActive: false,
          HasMaster:false
            },
            errors: [],
            formIsValid: false,


        }

        this.onStatusChange = this.onStatusChange.bind(this);
        this.onMasterStatusChange=this.onMasterStatusChange.bind(this);
        this.onColumnNameChange = this.onColumnNameChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onDataTypeChange = this.onDataTypeChange.bind(this);
        this.onConnectorNameChange= this.onConnectorNameChange.bind(this);
        this.onFieldNameChange = this.onFieldNameChange.bind(this);

    }


    onStatusChange(event) {
        
        const location = this.state.ConnectorFieldMasterData;
        location.IsActive = event.target.checked;
        this.setState({
            ConnectorFieldMasterData: location
        })
    }

    onMasterStatusChange(event) {
        
        const location = this.state.ConnectorFieldMasterData;
        location.HasMaster = event.target.checked;
        this.setState({
            ConnectorFieldMasterData: location
        })
    }

    onColumnNameChange(event){
        const location = this.state.ConnectorFieldMasterData;
        location.ColumnName = event.target.value;
        this.setState({
            ConnectorFieldMasterData: location
        })

    }

    onDescriptionChange(event){

     
        const location = this.state.ConnectorFieldMasterData;
        location.Description = event.target.value;
        this.setState({
            ConnectorFieldMasterData: location
        })

    }

    onDataTypeChange(event){


        const location = this.state.ConnectorFieldMasterData;
        location.DataType = event.target.value;
        this.setState({
            ConnectorFieldMasterData: location
        })

    }

    onConnectorNameChange(event){
        const location = this.state.ConnectorFieldMasterData;
        location.ConnectorID = parseInt(event.target.value);


        this.setState({
            ConnectorFieldMasterData: location
        })
     
    }
    onFieldNameChange(event){
        const location = this.state.ConnectorFieldMasterData;
        location.FieldName = event.target.value;
        this.setState({
            ConnectorFieldMasterData: location
        })

    }
    
    


    handleSave(e) {
    
        if (this.validateForm()) {
       

        
            this.props.onSave(this.state.ConnectorFieldMasterData)

            this.handleHide();
        }
    }
    handleHide(e) {
       
        this.setState({ show: false });
        if (e != undefined) {

            if ((e.target.textContent != "×")) {
                this.props.onClose(e);
            }
            else {
                this.props.onClose(e);
            }
        } else {
            this.props.onClose(e);
        }
        this.props.onSave()

    }
    handleRejectHide(e) {
   

        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onRejectClose();
            }
        } else {
            this.props.onRejectClose();
        }

    }
    handleApproveHide(e) {

        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onApproveClose();
            }
        } else {
            this.props.onApproveClose();
        }

    }
    passToConfirmation(data) {
 

        this.props.enteredData(data.PoNumber)
    }


    componentDidMount() {
        let connectorList = [];
    let connectorListValues=[];


        axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
        .then((response) => {
            connectorList = response.data;
            connectorListValues  = connectorList.length>0 && connectorList.map((x) =>{return(<option key={x.ConnectorID} /*selected={index == 0 ? "" : ""}*/ value={x.ConnectorID}>{x.ConnectorName}</option>)},this);
            this.setState({ connectorListValues: connectorListValues });
        })
        .catch((error) => {
            trycatchAlertPopup(error);
          });
     
        this.setState({
            show: this.props.show
        })
    }

    componentWillMount() {
       
        if (this.props.data) {
            this.setState({
                ConnectorFieldMasterData: this.props.data,
            })
        }

  
    }

    validateForm() {
        const{specialCharmessage}=this.state;
        const location = this.state.ConnectorFieldMasterData;
        let errors = {};
        let formIsValid = true;
        let data = this.props.rowData;
        
         if (data.length > 0) 
         {
 
            let oldData = data.map(el =>({ ConnectorFieldMasterDataID:el.ConnectorFieldMasterDataID , ConnectorName: el.ConnectorName, FieldName: el.FieldName }));
            
            if (oldData.filter(function(e) { return (e.ConnectorName === location.ConnectorName && e.FieldName === location.FieldName && e.ConnectorFieldMasterDataID!==location.ConnectorFieldMasterDataID); }).length > 0) {


       
                formIsValid = false;
                errors["ConnectorName"] = "Connector Name - Field Name combination already exists";
                errors["FieldName"] = "Connector Name - Field Name combination already exists";
             }
         }

         if (location["ConnectorID"]===0) {
            formIsValid = false;
            errors["ConnectorName"] = "Connector Name is required";

        }

        if (!location["FieldName"].trim()) {
            formIsValid = false;
            errors["FieldName"] = "Field Name is required";

        }
        else{

            
            let isValid = regexForConfMasterFields.test(location["FieldName"]);
            if (!isValid) {
                formIsValid = false;
                errors["FieldName"] = "Field Name contains Special characters";
            }
            else{
                if(!isValidText(location["FieldName"].trim())) {
                    formIsValid = false;
                    errors["FieldName"] = specialCharmessage;
                }
            }
        }

        if(location["Description"])
        {

         

            let isValid= regexForTextAreas.test(location["Description"]);

            if (!isValid) {
                formIsValid = false;
                errors["Description"] = "Special Characters not allowed";
            }
            else{
                if(!isValidText(location["Description"].trim())) {
                    formIsValid = false;
                    errors["Description"] = specialCharmessage;
                }
            }
     

        }

        if (location["DataType"]==="0" || location["DataType"]==="") {
            formIsValid = false;
            errors["DataType"] = "Data Type is required";

        }

        if (!location["ColumnName"].trim()) {
            formIsValid = false;
            errors["ColumnName"] = "Column Name is required";

        }
        else {
            let isValid = regexForConfMasterFields.test(location["ColumnName"]);
            if (!isValid) {
                formIsValid = false;
                errors["ColumnName"] = "Column Name contains Special characters";
            }
            else{
                if(!isValidText(location["ColumnName"].trim())) {
                    formIsValid = false;
                    errors["ColumnName"] = specialCharmessage;
                }
            }
        }
        if (location["Description"].trim()) {
            if(!isValidText(location["Description"].trim())) {
                formIsValid = false;
                errors["Description"] = specialCharmessage;
            }
        }

        this.setState({
            errors: errors
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;

    }


 

    render() {
   //list of data types to be permitted in SQL table
        let source = [{ "id": "DateTime", "DataType": "DateTime" }, { "id": "Float", "DataType": "float" }, { "id": "Integer", "DataType": "int" }, { "id": "Decimal", "DataType": "decimal" }, { "id": "String", "DataType": "String" }];
   

  
    let dataTypesList =  source.map((item, i) => {
		return (
			<option key={item.id} value={item.DataType}>{item.DataType}</option>
		)
    }, this);
    

    


        return (
            <>
                {/* <Header /> */}
                <Modal show={this.state.show} onHide={this.handleHide.bind(this)} backdrop="static" className="modal fade" size="medium" id="dealpopup">
                    <Modal.Header closeButton>
                        {!this.props.data ?
                            <h4 className="modal-title" id="dealprofile">Add Connector Field Master Data</h4>
                            : <h4 className="modal-title" id="dealprofile">Edit Connector Field Master Data</h4>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        
                            <form>
                                <div className="form-section">

                                    <div className="row">
                                    {this.props.data ?
                                        <div className="form-group col-lg-6">
                                            <label for="">Connector<span className="required">*</span></label>
                                            <select disabled className="form-control" id="name" onChange={this.onConnectorNameChange} value={this.state.ConnectorFieldMasterData.ConnectorID}>
                                            {this.state.connectorListValues}
                                                </select>
                                            <span className="errorMsg">{this.state.errors.ConnectorName}</span>
                                        </div> 
                                        : <div className="form-group col-lg-6">
                                        <label for="">Connector<span className="required">*</span></label>
                                        <select className="form-control" id="name" onChange={this.onConnectorNameChange} value={this.state.ConnectorFieldMasterData.ConnectorID}>
                                            <option value="0">Select Connector Name</option>
                                            {this.state.connectorListValues}
                                                </select>
                                        <span className="errorMsg">{this.state.errors.ConnectorName}</span>
                                    </div> }

                                    {this.props.data ?
                                        <div className="form-group col-lg-6">
                                            <label for="">Field Name<span className="required">*</span></label>
                                            <input type="text" readOnly className="form-control" id="name" onChange={this.onFieldNameChange} 
                                            maxLength="50"
                                            value={this.state.ConnectorFieldMasterData.FieldName} />
                                            <span className="errorMsg">{this.state.errors.FieldName}</span>
                                        </div> 
                                        : <div className="form-group col-lg-6">
                                        <label for="">Field Name<span className="required">*</span></label>
                                        <input type="text" className="form-control" id="name" onChange={this.onFieldNameChange}
                                          maxLength="50"
                                        value={this.state.ConnectorFieldMasterData.FieldName} />
                                        <span className="errorMsg">{this.state.errors.FieldName}</span>
                                    </div> }


                                    {this.props.data ?
                                        <div className="form-group col-lg-6">
                                            <label for="">Data Type<span className="required">*</span></label>
                                            <select disabled className="form-control" id="name" onChange={this.onDataTypeChange} value={this.state.ConnectorFieldMasterData.DataType}>
                                            <option value="0">Select Data Type</option>
                                            {dataTypesList}
                                                </select>
                                            <span className="errorMsg">{this.state.errors.DataType}</span>
                                        </div> 
                                        : <div className="form-group col-lg-6">
                                        <label for="">Data Type<span className="required">*</span></label>
                                        <select className="form-control" id="name" onChange={this.onDataTypeChange} value={this.state.ConnectorFieldMasterData.DataType} >
                                        <option value="0">Select Data Type</option>
                                            {dataTypesList}
                                            </select>
                                        <span className="errorMsg">{this.state.errors.DataType}</span>
                                    </div> }

                                    {this.props.data ?
                                        <div className="form-group col-lg-6">
                                            <label for="">Column Name<span className="required">*</span></label>
                                            <input type="text" readOnly className="form-control" id="name" onChange={this.onLocationChange} value={this.state.ConnectorFieldMasterData.ColumnName}
                                               maxLength="100"
                                            />
                                            <span className="errorMsg">{this.state.errors.ColumnName}</span>
                                        </div> 
                                        : <div className="form-group col-lg-6">
                                        <label for="">Column Name<span className="required">*</span></label>
                                        <input type="text" className="form-control" id="name" onChange={this.onColumnNameChange}
                                        maxLength="100"
                                        value={this.state.ConnectorFieldMasterData.ColumnName} />
                                        <span className="errorMsg">{this.state.errors.ColumnName}</span>
                                    </div> }

                            {/* Keeping description editable in both add and edit popups */}
                               
                                     <div className="form-group col-lg-6">
                                        <label for="">Brief Description</label>
                                        <textarea rows="10" maxLength="100"  className="textAreaHeight" className="form-control textAreaHeight" value={this.state.ConnectorFieldMasterData.Description}  onChange={this.onDescriptionChange.bind(this)} />
                                        <span className="errorMsg">{this.state.errors.Description}</span>
                                    </div> 
                          
                               
                                        
                                                 <div className="form-group col-lg-6 label-adjust">
                                                 <div className="inline-inputs">
                                                <label className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" onChange={this.onStatusChange} defaultChecked={this.state.ConnectorFieldMasterData.IsActive} />
                                                    <span className="checkmark"></span>
                                                    <span className="form-check-label">Is Active</span>
                                                </label>
                                                <label className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" onChange={this.onMasterStatusChange} defaultChecked={this.state.ConnectorFieldMasterData.HasMaster} />
                                                    <span className="checkmark"></span>
                                                    <span className="form-check-label">Has Master</span>
                                                </label>

</div>

                                            </div>
                                    </div>

                                </div>


                            </form>
                        
                    </Modal.Body>
                    {<Modal.Footer>
                        <div className="modal-btn">
                                {!this.props.data ?
                                    <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Create" />
                                    :  <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Update" />
                                }
                        </div>
                    </Modal.Footer>}
                </Modal>
            </>
        )
    }
}