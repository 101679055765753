export const BAT_SET_CLIENT_SIMULATION_FLOW_INITIATED_FROM_SCREEN = 'BAT_SET_CLIENT_SIMULATION_FLOW_INITIATED_FROM_SCREEN';
export const BAT_UNSET_CLIENT_SIMULATION_FLOW_INITIATED_FROM_SCREEN = 'BAT_UNSET_CLIENT_SIMULATION_FLOW_INITIATED_FROM_SCREEN';
export const BAT_SET_OFFERINGS_DATA = 'BAT_SET_OFFERINGS_DATA';
export const BAT_UNSET_OFFERINGS_DATA = 'BAT_UNSET_OFFERINGS_DATA';
export const BAT_USER_DATA = 'BAT_USER_DATA';
export const BATDATA_FILTER = 'BATDATA_FILTER';

export function BATSetClientSimulationFlowInitiatedFromScreen(screenName, screenData) {
  return {
    type: BAT_SET_CLIENT_SIMULATION_FLOW_INITIATED_FROM_SCREEN,
    payload: { screenName, screenData },
  };
}

export function BATUnsetClientSimulationFlowInitiatedFromScreen() {
  return {
    type: BAT_UNSET_CLIENT_SIMULATION_FLOW_INITIATED_FROM_SCREEN,
    payload: {},
  };
}

export function BATSetOfferingsData(offeringsData) {
  return {
    type: BAT_SET_OFFERINGS_DATA,
    payload: { offeringsData },
  };
}

export function BATUnsetOfferingsData() {
  return {
    type: BAT_UNSET_OFFERINGS_DATA,
    payload: {},
  };
}

export function BATUserData(userData) {
  return {
    type: BAT_USER_DATA,
    payload: { userData },
  };
}
// ACTION FOR THE BAT-DiverseData//
export function updateBATFilter(BATData) {
  return {
    type: BATDATA_FILTER,
    payload: BATData,
  };
}
