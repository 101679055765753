import React, { Component } from 'react'
import { Modal } from "react-bootstrap";
import Success from "../../Images/Success.svg";


export default class HelpSectionMessageNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      message: "",
    }
  }
  componentDidMount() {
    const { show, message } = this.props;
    this.setState({
      show: show,
      message: message,
    })
  }
  onClose = () => {
    this.props.onClose();
  }

  render() {

    const { message, show } = this.state;
    return (
      <Modal
        show={show}
        onHide={this.onClose}
        className="helpfeatureNotification-modal centered"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="helpfeaturenotification-body">
            <div className="helpfeaturenotification-icon">
              <span>
                <img alt="" src={Success} width="40px" height="40px" />
              </span>
            </div>
            <div className="helpfeaturenotification-data">
              <p>{message}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
